/* @flow */

import * as React from 'react';
import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';

type Props = {
    useCurrentColor?: boolean,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    const {useCurrentColor, ...otherProps} = validProps;

    return (
        <svg
            {...otherProps}
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            x='0px'
            y='0px'
            viewBox='0 0 41 41.5'
        >
            <path
                style={{fill: useCurrentColor ? 'currentColor' : colors.onboardingHelp}}
                d='M20.5,6.3c8,0,14.5,6.5,14.5,14.5s-6.5,14.5-14.5,14.5S6,28.8,6,20.8C6,12.8,12.5,6.3,20.5,6.3 M20.5,3.3 C10.8,3.3,3,11.1,3,20.8s7.8,17.5,17.5,17.5S38,30.4,38,20.8S30.2,3.3,20.5,3.3C20.5,3.3,20.5,3.3,20.5,3.3z'
            />
            <path
                style={{fill: useCurrentColor ? 'currentColor' : colors.onboardingHelp}}
                d='M22.4,10.8c1.1,0,2.1,0.9,2.1,2.1c0,0.6-0.2,1.1-0.6,1.5C23,15,21.7,14.8,21,13.9c-0.6-0.8-0.6-1.8,0-2.6 C21.4,11,21.9,10.8,22.4,10.8z M23.3,17.3l-2.9,10c-0.1,0.4-0.2,0.7-0.2,1.1c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1 c0.2,0,0.4-0.1,0.5-0.2c0.6-0.6,1.1-1.2,1.5-1.9L23,27c-1.4,2.5-2.9,3.7-4.5,3.7c-0.5,0-1.1-0.1-1.5-0.5c-0.4-0.3-0.6-0.8-0.5-1.3 c0-0.5,0.1-0.9,0.2-1.3l2-6.8c0.2-0.5,0.3-1,0.3-1.5c0-0.2-0.1-0.4-0.3-0.5c-0.2-0.2-0.5-0.3-0.7-0.2h-0.5l0.2-0.6l4.8-0.8 L23.3,17.3z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'info';

export const InfoIcon = svgIconWrapper(SvgComponent);
