import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'61 -6.3 34.1 57.7'}>
            <path d='M93.8 12.2c-1.6 1.5-4.2 1.5-5.8 0l-6.1-5.4v41.9c0 2.1-1.7 2.6-3.9 2.6s-3.9-.5-3.9-2.6V6.8L68 12.2c-1.6 1.5-4.2 1.5-5.8 0-1.6-1.5-1.6-3.9 0-5.4L74.9-5l.2-.2.3-.3c.1-.1.3-.2.4-.3.3-.2.6-.3.9-.4.2 0 .3-.1.5-.1.3 0 .5-.1.8-.1.4 0 .8.1 1.2.2.5.1 1 .4 1.4.7l.3.3.1.1 12.9 12c1.5 1.4 1.5 3.8-.1 5.3z' />
        </svg>
    );
}
SvgComponent.iconName = 'arrows-asc';

export const ArrowsAscIcon = svgIconWrapper(SvgComponent);
