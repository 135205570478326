/* @flow */

import {reducer} from './visual-pipeline-reducer';
import * as constants from './visual-pipeline-constants';
import * as actions from './visual-pipeline-actions';
import * as selectors from './visual-pipeline-selectors';

export type {
    VisualPipelineColumnState,
    VisualPipelineAction,
    DragItemLeadCard,
    StageTransition,
    CloseType,
    LeadClosingConfirmation,
} from './visual-pipeline-types';
export type {VisualPipelineState} from './visual-pipeline-reducer';

export const VisualPipeline = {
    reducer,
    ...constants,
    ...actions,
    ...selectors,
};
