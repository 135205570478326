/* @flow */

import * as React from 'react';
import {colors} from '../../colors';
import {getInstanceCurrencyPrefix} from '../../utils/currency';
import {svgIconWrapper} from '../svg-icon-wrapper';
import LeadIconDollar from './lead-icon-dollar.svg';
import LeadIconPound from './lead-icon-pound.svg';
import LeadIconEuro from './lead-icon-euro.svg';

type Props = {
    fill?: string,
};
function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    const defaultInstanceCurrencySymbol = getInstanceCurrencyPrefix();

    switch (defaultInstanceCurrencySymbol) {
        case '£':
            return <LeadIconPound {...validProps} fill={props.fill || colors.lead} />;
        case '€':
            return <LeadIconEuro {...validProps} fill={props.fill || colors.lead} />;
        case '$':
        default:
            return <LeadIconDollar {...validProps} fill={props.fill || colors.lead} />;
    }
}
SvgComponent.iconName = 'lead';

export const LeadIcon = svgIconWrapper(SvgComponent);
