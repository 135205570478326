/* @flow */

import * as ramda from 'ramda';
import {deserializeLegacyEncoding} from './deserialize-relationship';
import type {FilterObject} from './types';

export function convertToArray(urlFilter: Object): FilterObject[] {
    if (!urlFilter) return [];

    return ramda.flatten(
        Object.keys(urlFilter).map((key) => {
            // Make certain that we are working on an array
            const keyFilters = ramda.flatten([urlFilter[key]]);

            return keyFilters.map((value) => ({[key]: value}));
        })
    );
}

export function convertToObj(filterArray: Array<Object>) {
    if (!filterArray || !Array.isArray(filterArray)) return {};

    return filterArray.reduce((filterObjAccum, filterObj) => {
        const filterKey = Object.keys(filterObj)[0];
        const filterValue = filterObj[filterKey];

        // No pre-existing key in the object
        if (!filterObjAccum[filterKey]) {
            filterObjAccum[filterKey] = filterValue;

            return filterObjAccum;
        }

        // Already an array, so concat this new value
        if (Array.isArray(filterObjAccum[filterKey])) {
            filterObjAccum[filterKey] = filterObjAccum[filterKey].concat(filterValue);

            return filterObjAccum;
        }

        // Convert to array
        filterObjAccum[filterKey] = [filterObjAccum[filterKey], filterValue];

        return filterObjAccum;
    }, {});
}

/**
 * Safely gets the filter value from an array of filter values,
 * based on a key
 *
 * @param  {FilterObject[]} filters     Array of filter objects to search within
 * @param  {string} key                 Filter key to lookup, i.e. `closedTime`, `owner`
 * @return {[string]}                   Filter value to return, if it exists
 */
export function safelyGetFilterByKey(
    filters: Array<FilterObject>,
    key: string
): Object | string | void {
    const filterObject = filters.find((filterObj) => {
        return key === Object.keys(filterObj)[0];
    });

    return filterObject ? filterObject[key] : undefined;
}

/**
 * Safely gets relationship filter ids from a RelationshipFilterObject,
 * regardless of its type being a string or an object.
 *
 * @param  {Object} filterObject     - Filter object
 * @return {string[]}                - Array of string ids
 */
export function safelyGetRelationshipFilterIds(filterObject: FilterObject): string[] {
    const filterName = Object.keys(filterObject)[0];
    const filter = filterObject[filterName];

    return safelyGetRelationshipFilterIdsFromFilterValue(filter);
}

/**
 * Helper to safely get filter ids from a relationship filter. This is essentially
 * the method above (which uses this), but expects just the filter _value_,
 * not some object with the key of the filter.
 *
 * @param  {Object} filter          - Filter value
 * @return {string[]}               - Array of model ids (strings)
 */
export function safelyGetRelationshipFilterIdsFromFilterValue(filter: Object): string[] {
    let filterObj = filter;
    // Transform filter into new object shape if in legacy encoding
    if (typeof filter === 'string' && filter.length) {
        filterObj = deserializeLegacyEncoding(filter);
        // If string but no length, just return an empty array
    } else if (typeof filter === 'string') {
        return [];
    }

    return filterObj && filterObj.data
        ? filterObj.data.filter((item) => item && item.data).map((item) => item.data)
        : [];
}

/**
 * Updates a single filter param given an existing set of filters, the existing
 * filter to update, and its replacement.
 *
 * @param  {Object[]} selectedFilters      - Existing array of filter objects
 * @param  {Object} options.oldFilter      - Existing filter to update
 * @param  {Object} options.newFilter      - New filter to replace existing
 *
 * @return {Object[]}                      - New, updated array of filter objects
 */
export function handleFilterUpdate(
    selectedFilters: FilterObject[],
    {oldFilter, newFilter}: {oldFilter: FilterObject, newFilter: FilterObject}
) {
    // $FlowFixMe upgrading Flow to v0.92.1
    return selectedFilters.map((selectedFilter) =>
        selectedFilter === oldFilter ? newFilter : selectedFilter
    );
}

/**
 * Removes filters from a given set of already existing filters
 *
 * @param  {Object[]} selectedFilters      - Existing array of filter objects
 * @param  {Object[]} removedFilters       - Array of filter objects to remove
 *
 * @return {Object[]}                      - New set of filters
 */
export function handleFilterRemove(
    selectedFilters: FilterObject[],
    removedFilters: FilterObject[]
) {
    // $FlowFixMe upgrading Flow to v0.92.1
    return ramda.difference(selectedFilters, removedFilters);
}
