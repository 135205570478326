import moment from 'moment';

/**
 * Check if a date is before today
 *
 * @param  {[type]}  targetDate        Moment date object to check
 * @param  {[type]}  [today=moment()]  Moment date object for today's datetime
 * @return {Boolean}                   Is the target date before today?
 */
export function isBeforeToday(targetDate, today = moment()) {
    return targetDate.isBefore(today, 'day');
}
