/* @flow */

import * as React from 'react';
import {EntityPill} from './entity-pill';
import './entity-pill-select-wrapper.css';

type Props = {
    data: Object,
    onRemove?: () => void,
    /** This is important to prevent the react-select input focus from being called and blocking a removal click.  see NUT-11991 */
    onMouseDown?: Function,
};

export class EntityPillSelectWrapper extends React.Component<Props> {
    render() {
        return (
            <div styleName='entity-pill-select-wrapper' onMouseDown={this.props.onMouseDown}>
                <EntityPill {...this.props.data} onRemove={this.props.onRemove} />
            </div>
        );
    }
}
