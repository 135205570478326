/* @flow */

import * as React from 'react';

import type {EventFragmentSparse, LeadStatus, SessionUser} from 'nutshell-graphql-types';

import {LinkedEntity} from 'shells/linked-entity';

import {mapLeadStatusApiToString} from '../consts';
import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {|event: EventFragmentSparse, newStatus: string, currentUser: SessionUser|};

export function TimelineBannerLeadStatus(props: Props) {
    const {event, newStatus, currentUser} = props;

    const statusString = mapLeadStatusApiToString(newStatus);

    const getAction = (status: LeadStatus, name: string, htmlUrl: string, value?: string) => {
        const linkedEntity = (
            <strong>
                <LinkedEntity
                    type='leads'
                    useEntityColor={true}
                    shouldClientRoute={true}
                    name={name}
                    htmlUrl={htmlUrl}
                    allowWrap={true}
                    displayInline={true}
                    greenLeads={true}
                />
            </strong>
        );

        const valueString = value ? ` worth ${value}` : undefined;

        switch (status) {
            case 'WON':
                return (
                    <span>
                        won the lead&nbsp;
                        {linkedEntity}
                        {valueString}
                    </span>
                );
            case 'LOST':
                return (
                    <span>
                        lost the lead&nbsp;
                        {linkedEntity}
                        {valueString}
                    </span>
                );
            case 'CANCELLED':
                return (
                    <span>
                        canceled the lead&nbsp;
                        {linkedEntity}
                        {valueString}
                    </span>
                );
            case 'OPEN':
                return (
                    <span>
                        opened the lead&nbsp;
                        {linkedEntity}
                        {valueString}
                    </span>
                );
            case 'PENDING':
                return (
                    <span>
                        marked&nbsp;
                        {linkedEntity}
                        {valueString}
                        &nbsp;as pending
                    </span>
                );
            default:
                return <></>;
        }
    };

    const getIconVariant = (status: LeadStatus) => {
        switch (status) {
            case 'LOST':
                return 'lead-lost';
            case 'WON':
                return 'lead-won';
            case 'CANCELLED':
                return 'lead-cancelled';
            case 'PENDING':
                return 'lead-pending';
            case 'OPEN':
            default:
                return 'lead-opened';
        }
    };

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'Lead' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (event.payload.__typename === 'Lead') {
        const name = event.payload.name;
        const htmlUrl = event.payload.htmlUrl;
        const value = event.payload.value ? event.payload.value.formatted : undefined;

        return (
            <TimelineBanner
                id={event.id}
                iconVariant={getIconVariant(statusString)}
                timestamp={event.changeTime}
                actor={event.actor}
                action={getAction(statusString, name, htmlUrl, value)}
                reactableBanner={{
                    currentUser,
                    reactions: event.reactions,
                }}
            />
        );
    }

    return null;
}
