/* @flow */

import * as React from 'react';
import './marketing-header.css';

type Props = {
    children?: React.Node,
    as: string,
};

MarketingHeader.defaultProps = {
    as: 'div',
};

/**
 * This component represents the copy of a marketing header,
 * usually the inner page title of an integrations page or
 * large empty state.
 *
 * There is a default 16px margin-bottom built into the component
 * so that other marketing-based tyopgraphy components can
 * lay themselves out nicely.
 *
 * If you'd like to use a similar style typography without the
 * margin, prefer the `Header` component.
 */
export function MarketingHeader({children, as: Tag}: Props) {
    return <Tag styleName='marketing-header'>{children}</Tag>;
}
