/* @flow */

import * as React from 'react';

import {RemoveButton} from 'shells/button';

import {ScrollableContainerWithShadow} from '../../../layout';
import {Avatar} from '../../../avatar';
import {RelativeTimestamp} from '../../../timestamp';
import {Dialog} from '../../../dialog';
import {AddReactionButton, ReactionBar} from '../../reaction-bar';
import type {Reaction} from '../../reaction-bar/types';
import type {Comment} from './types';

import './timeline-comment.css';

type Props = {
    comment: Comment,
    onDeleteComment?: () => any,
    onToggleReaction?: (reaction: Reaction) => any,
    currentUserId?: string,
    limitCommentHeight?: boolean,
};

export function TimelineComment(props: Props) {
    const {onDeleteComment, onToggleReaction, currentUserId, limitCommentHeight} = props;
    const {creatorUser, bodyHtml, createdTime, reactions} = props.comment;

    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState<boolean>(false);
    const deleteButtonRef = React.useRef(null);

    const displayableBodyHtml = (
        <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: bodyHtml,
            }}
        />
    );

    return (
        <div styleName='timeline-comment'>
            <div styleName='avatar-container'>
                <Avatar
                    initials={creatorUser.initials}
                    avatarUrl={creatorUser.avatarUrl}
                    type='users'
                    circular={true}
                    size={24}
                />
            </div>
            <div styleName='commenter-content-container'>
                <div>{creatorUser.name}</div>
                <div styleName='content'>
                    {limitCommentHeight ? (
                        <ScrollableContainerWithShadow
                            direction='vertical'
                            maxContainerSize={150}
                            showReverseGradientShadow={true}
                        >
                            {displayableBodyHtml}
                        </ScrollableContainerWithShadow>
                    ) : (
                        displayableBodyHtml
                    )}
                </div>
                {reactions && Object.keys(reactions).length && onToggleReaction && currentUserId ? (
                    <div styleName='reaction-bar-container'>
                        <ReactionBar
                            hideAddButton={true}
                            currentUserId={currentUserId}
                            reactions={reactions}
                            onToggleReaction={onToggleReaction}
                            isSmall={true}
                        />
                    </div>
                ) : (
                    undefined
                )}
            </div>
            <div styleName='timestamp-delete-button-container'>
                <RelativeTimestamp datetime={createdTime} />
                {onToggleReaction ? (
                    <div styleName='add-reaction-container'>
                        <AddReactionButton onSelectReaction={onToggleReaction} />
                    </div>
                ) : (
                    undefined
                )}
                {onDeleteComment ? (
                    <div styleName='remove-button'>
                        <RemoveButton
                            size={12}
                            tooltip='Delete'
                            onClick={() => setIsConfirmingDelete(true)}
                            getButtonRef={deleteButtonRef}
                        />
                    </div>
                ) : (
                    undefined
                )}
            </div>
            {isConfirmingDelete && onDeleteComment ? (
                <Dialog
                    anchor={deleteButtonRef.current}
                    question='Are you sure you want to delete this comment?'
                    primaryText='Yes, delete comment'
                    secondaryText='No, don’t delete'
                    onPrimary={() => {
                        onDeleteComment().then(setIsConfirmingDelete(false));
                    }}
                    onSecondary={() => setIsConfirmingDelete(false)}
                    isWarning={true}
                />
            ) : (
                undefined
            )}
        </div>
    );
}
