/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {ReduxFormSelectPickerGeneric, ReduxFormSelectPickerMulti} from 'shells/redux-form-fields';

type Props = {
    ...FieldProps,
    enumOptions: string[],
    isMulti?: boolean,
    isRequired?: boolean,
};

export function DecisionFieldSelect(props: Props) {
    const options = props.enumOptions.map((opt) => {
        return {
            label: opt,
            value: opt,
        };
    });

    const selectedValue = options.find((field) => field.value === props.input.value);

    if (props.isMulti) {
        return (
            // $FlowIgnore this is because of the way we're passing the props through
            <ReduxFormSelectPickerMulti
                {...props}
                options={options}
                placeholder='Select'
                value={selectedValue}
                autoFocus={false}
                onChange={(option) => {
                    props.input.onChange(option.map((opt) => opt.value));
                }}
            />
        );
    }

    return (
        // $FlowIgnore this is because of the way we're passing the props through
        <ReduxFormSelectPickerGeneric
            {...props}
            options={options}
            placeholder='Select'
            value={selectedValue}
            autoFocus={false}
        />
    );
}
