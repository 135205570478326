/* @flow */
import {createAction} from 'redux-act';

export type ListRowSelectedAction = {
    type: 'LIST_ROW_SELECTED',
    payload: {
        entityId: string,
    },
};

export type ShiftListRowSelectedAction = {
    type: 'SHIFT_LIST_ROW_SELECTED',
    payload: {
        entityId: string,
        listItems: {id: string}[],
    },
};

export const selectListRowItem: (
    payload: $PropertyType<ListRowSelectedAction, 'payload'>
) => ListRowSelectedAction = createAction('LIST_ROW_SELECTED');
export const shiftSelectListRowItem: (
    payload: $PropertyType<ShiftListRowSelectedAction, 'payload'>
) => ShiftListRowSelectedAction = createAction('SHIFT_LIST_ROW_SELECTED');
export const selectAllRows = createAction('LIST_SELECT_ALL_ROWS');
export const resetSelection = createAction('LIST_SELECTION_RESET');
export const resetLastSelectedItem = createAction('RESET_LAST_SELECTED_ITEM');
