/* @flow */

type SetEmailAction = {type: 'TINY_INTRO_SET_EMAIL', payload: string};
type SetSignupTokenAction = {type: 'TINY_INTRO_SET_SIGNUP_TOKEN', payload: string};
type SetProvisionStatusAction = {type: 'TINY_INTRO_SET_PROVISION_RESPONSE', payload: Object};
type SetProvisionErrorMessageAction = {
    type: 'TINY_INTRO_SET_PROVISION_ERROR_MESSAGE',
    payload: ?string,
};

export type TinyIntroAction =
    | SetEmailAction
    | SetSignupTokenAction
    | SetProvisionStatusAction
    | SetProvisionErrorMessageAction;

export const ActionTypes = {
    TINY_INTRO_SET_EMAIL: 'TINY_INTRO_SET_EMAIL',
    TINY_INTRO_SET_SIGNUP_TOKEN: 'TINY_INTRO_SET_SIGNUP_TOKEN',
    TINY_INTRO_SET_PROVISION_RESPONSE: 'TINY_INTRO_SET_PROVISION_RESPONSE',
    TINY_INTRO_SET_PROVISION_ERROR_MESSAGE: 'TINY_INTRO_SET_PROVISION_ERROR_MESSAGE',
};

export const setEmail = (email: string): SetEmailAction => ({
    type: ActionTypes.TINY_INTRO_SET_EMAIL,
    payload: email,
});

export const setSignupToken = (token: string): SetSignupTokenAction => ({
    type: ActionTypes.TINY_INTRO_SET_SIGNUP_TOKEN,
    payload: token,
});

export const setProvisionStatus = (provisionResponse: Object): SetProvisionStatusAction => ({
    type: ActionTypes.TINY_INTRO_SET_PROVISION_RESPONSE,
    payload: provisionResponse,
});

export const setProvisionErrorMessage = (
    errorMessage: ?string
): SetProvisionErrorMessageAction => ({
    type: ActionTypes.TINY_INTRO_SET_PROVISION_ERROR_MESSAGE,
    payload: errorMessage,
});
