/* @flow */

import * as React from 'react';

import type {EventFragmentSparse} from 'nutshell-graphql-types';

import {LinkedEntity} from 'shells/linked-entity';

import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {|event: EventFragmentSparse|};

export function TimelineBannerCompanyDetailsAdded(props: Props) {
    const {event} = props;

    const getAction = (name: string, htmlUrl: string) => {
        const linkedEntity = (
            <strong>
                <LinkedEntity
                    type='accounts'
                    useEntityColor={true}
                    shouldClientRoute={true}
                    name={name}
                    htmlUrl={htmlUrl}
                    allowWrap={true}
                    displayInline={true}
                    greenLeads={true}
                />
            </strong>
        );

        return (
            <span>
                added new details to&nbsp;
                {linkedEntity}&nbsp;with <strong>Nutshell Intelligence</strong>
            </span>
        );
    };

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'Account' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (event.payload.__typename === 'Account') {
        const name = event.payload.name;
        const htmlUrl = event.payload.htmlUrl;

        return (
            <TimelineBanner
                id={event.id}
                iconVariant='company-details-added'
                timestamp={event.changeTime}
                actor={event.actor}
                action={getAction(name, htmlUrl)}
            />
        );
    }

    return null;
}
