/* @flow */

import * as React from 'react';

import {useRefObserver} from '../utils/hooks/use-ref-observer';
import {getLocation, type Props as PopoverProps, NUB_OFFSET} from '../popover/popover';
import {Popover} from '../popover';

import hoverPopoverStyles from './hover-popover.css';

// Not going to support 'custom' location for now
export type HoverPopoverLocationEnum =
    | 'top'
    | 'top-left'
    | 'left'
    | 'bottom-left'
    | 'bottom'
    | 'bottom-right'
    | 'right'
    | 'top-right';

type Props = {|
    ...PopoverProps,
    anchorRef: any,
    // Override some of the popover props
    location: HoverPopoverLocationEnum,
    anchor?: null, // No longer accepted since we're passing the entire ref
    onBlur?: null, // No longer accepted since we're handling this internally
    disableOverlayClickToBlur?: boolean, // Not actually required
    offsetPosition?: number, // Not actually required
|};

export function HoverPopover(props: Props) {
    const {children, anchorRef, ...popoverProps} = props;

    const [actualLocation, setActualLocation] = React.useState<?HoverPopoverLocationEnum>(null);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const popoverContentRef = React.useRef(null);

    const {isHovered} = useRefObserver(anchorRef, ['isHovered']);

    const anchorRect = anchorRef.current ? anchorRef.current.getBoundingClientRect() : undefined;

    const hoverBufferStyle = {};
    if (anchorRect && actualLocation) {
        const bufferSize = parseInt(hoverPopoverStyles['buffer-size']);
        switch (actualLocation) {
            case 'top':
            case 'top-left':
            case 'top-right':
                hoverBufferStyle.paddingBottom = bufferSize + anchorRect.height;
                break;
            case 'right':
                hoverBufferStyle.left = (bufferSize + anchorRect.width) * -1;
                hoverBufferStyle.paddingRight = bufferSize + anchorRect.width;
                break;
            case 'left':
                hoverBufferStyle.paddingLeft = bufferSize + anchorRect.width;
                break;
            case 'bottom':
            case 'bottom-left':
            case 'bottom-right':
                hoverBufferStyle.top = (bufferSize + anchorRect.height) * -1;
                hoverBufferStyle.paddingTop = bufferSize + anchorRect.height;
                break;
        }
    }

    React.useLayoutEffect(() => {
        if (popoverContentRef.current && anchorRect) {
            // Need to use same location calculation as popover to ensure accurate padding
            const location = getLocation(
                popoverContentRef.current.getBoundingClientRect(),
                anchorRect,
                popoverProps.location,
                NUB_OFFSET
            );

            if (location.name !== 'custom') {
                setActualLocation(location.name);
            }
        }
    }, [anchorRect, popoverProps.location]);

    React.useEffect(() => {
        if (isHovered) {
            setIsOpen(true);
        }
    }, [isHovered]);

    React.useEffect(() => {
        const anchor = anchorRef ? anchorRef.current : undefined;

        const onClickHandler = () => {
            setIsOpen(false);
        };

        if (anchor) {
            anchor.addEventListener('click', onClickHandler);
        }

        return () => {
            if (anchor) {
                anchor.removeEventListener('click', onClickHandler);
            }
        };
    }, [anchorRef]);

    React.useEffect(() => {
        if (anchorRef && anchorRef.current) {
            if (isOpen) {
                // Preserve initial z-index before raising it
                if (anchorRef.current.initialZIndex === undefined) {
                    anchorRef.current.initialZIndex = getComputedStyle(anchorRef.current).zIndex;
                }
                // Raise the anchor above the popover overlay
                anchorRef.current.style.zIndex =
                    parseInt(hoverPopoverStyles['overlay-z-index']) + 1;
            } else if (anchorRef.current.initialZIndex) {
                // Lower it back to the initial
                anchorRef.current.style.zIndex = anchorRef.current.initialZIndex;
            }
        }
    }, [anchorRef, isOpen]);

    return isOpen ? (
        <Popover
            {...popoverProps}
            anchor={anchorRef.current}
            // Used as a backup in case hover buffer target is missed
            onBlur={() => {
                setIsOpen(false);
            }}
            className='hover-popover-container'
        >
            <div styleName='hover-popover' ref={popoverContentRef}>
                <div className='hover-popover-content'>{children}</div>
                <div
                    styleName='hover-buffer'
                    style={hoverBufferStyle}
                    onMouseOut={(e) => {
                        if (
                            // Covers children within popover content
                            e.relatedTarget.parentNode.classList.value.includes(
                                'hover-popover-content'
                            ) ||
                            // Covers the popover container within the buffer zone
                            e.relatedTarget.classList.value.includes('hover-popover-container')
                        ) {
                            return;
                        }

                        setIsOpen(false);
                    }}
                    onClick={() => {
                        setIsOpen(false);
                    }}
                />
            </div>
        </Popover>
    ) : null;
}
