/* @flow */

import {createSelector} from 'reselect';
import {getUIState} from '../ui-selectors';

const getLimitUIState = createSelector([getUIState], (uiState) => uiState.limits);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getLimitsModalType = createSelector(
    [getLimitUIState],
    (limitsState) => limitsState.type
);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getIsAllLimitsModalOpen = createSelector(
    [getLimitUIState],
    (limitsState) => limitsState.isAllLimitsModalOpen
);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getShouldLinkToBilling = createSelector(
    [getLimitUIState],
    (limitsState) => limitsState.allLimitsModalShouldLinkToBilling
);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getTitleText = createSelector(
    [getLimitUIState],
    (limitsState) => limitsState.allLimitsModalTitleText
);
