/* @flow */

import * as React from 'react';

export function FormsAddOnPlaceholder() {
    return (
        <svg
            width='469'
            height='341'
            viewBox='0 0 469 341'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                opacity='0.6'
                d='M246 0H4C1.79086 0 0 1.79086 0 4V17C0 19.2091 1.79086 21 4 21H246C248.209 21 250 19.2091 250 17V4C250 1.79086 248.209 0 246 0Z'
                fill='currentColor'
            />
            <path
                opacity='0.6'
                d='M406 60H4C1.79086 60 0 61.7909 0 64V70C0 72.2091 1.79086 74 4 74H406C408.209 74 410 72.2091 410 70V64C410 61.7909 408.209 60 406 60Z'
                fill='currentColor'
            />
            <path
                opacity='0.6'
                d='M73 113H4C1.79086 113 0 114.791 0 117V123C0 125.209 1.79086 127 4 127H73C75.2091 127 77 125.209 77 123V117C77 114.791 75.2091 113 73 113Z'
                fill='currentColor'
            />
            <path
                opacity='0.6'
                d='M465 136H4C1.79086 136 0 137.791 0 140V165C0 167.209 1.79086 169 4 169H465C467.209 169 469 167.209 469 165V140C469 137.791 467.209 136 465 136Z'
                fill='currentColor'
            />
            <path
                opacity='0.6'
                d='M73 208H4C1.79086 208 0 209.791 0 212V218C0 220.209 1.79086 222 4 222H73C75.2091 222 77 220.209 77 218V212C77 209.791 75.2091 208 73 208Z'
                fill='currentColor'
            />
            <path
                opacity='0.6'
                d='M465 231H4C1.79086 231 0 232.791 0 235V260C0 262.209 1.79086 264 4 264H465C467.209 264 469 262.209 469 260V235C469 232.791 467.209 231 465 231Z'
                fill='currentColor'
            />
            <path
                opacity='0.6'
                d='M465 303H405C402.791 303 401 304.791 401 307V337C401 339.209 402.791 341 405 341H465C467.209 341 469 339.209 469 337V307C469 304.791 467.209 303 465 303Z'
                fill='currentColor'
            />
        </svg>
    );
}
