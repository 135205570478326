/* @flow */

import React from 'react';

import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';
import GlossaryBookmark from './icon-glossary-bookmark.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <GlossaryBookmark {...validProps} color={validProps.color || colors.onboardingHelp} />;
}

SvgComponent.iconName = 'glossary-bookmark';

export const GlossaryBookmarkIcon = svgIconWrapper(SvgComponent);
