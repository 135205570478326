/* @flow */

import * as React from 'react';

/**
 * This hook is used to debounce the value of a 'saving' variable in the case that the state manager (ex. redux forms)
 * marks the value of 'submitting' or 'saving' as 'false' before updating an error state if one occurs.
 *
 * In this case, a slight delay when changing the value of 'saving' from true to false will allow the parent
 * component to handle errors without the save being falsely marked as completed.
 *
 * It is similar to the useDebounce hook, except this specific case we do not want to debounce the value
 * being set to 'true', we also don't /really/ care what the timeout is, 500ms is more than enough time for
 * redux form states to get caught up and back in sync.
 */
export function useDebouncedSaveValue(isSaving: boolean) {
    const [debouncedSaveValue, setDebouncedSaveValue] = React.useState<boolean>(false);
    const debounceTimeoutRef = React.useRef();

    React.useEffect(() => {
        if (isSaving && !debouncedSaveValue) {
            // If the form is saving, set the debounced value to true without any delay
            setDebouncedSaveValue(isSaving);
        }

        if (!isSaving && debouncedSaveValue) {
            // When isSaving changes to false, set a short timeout to wait in case there is an error
            debounceTimeoutRef.current = setTimeout(() => {
                setDebouncedSaveValue(isSaving);
            }, 500);
        }
    }, [isSaving]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        return () => clearTimeout(debounceTimeoutRef.current); // Clean up for timer
    }, []);

    return debouncedSaveValue;
}
