/* @flow */

import * as React from 'react';
import {EntityPill} from './entity-pill';

type PillProps = {
    values: string[],
    onRemove: (string) => void,
    emptyState: React.Node,
};

Pillset.defaultProps = {
    emptyState: null,
};

export function Pillset(props: PillProps) {
    if (!props.values.length) {
        return props.emptyState;
    }

    return (
        <div>
            {props.values.map((value) => (
                <EntityPill
                    key={value}
                    name={value}
                    type='light'
                    isLinkable={false}
                    onRemove={() => {
                        props.onRemove(value);
                    }}
                    isSmall={true}
                />
            ))}
        </div>
    );
}
