import moment from 'moment';

/**
 * Check if a date is within the past week
 *
 * @param  {[type]}  targetDate        Moment date object to check
 * @param  {[type]}  [today=moment()]  Moment date object for today's datetime
 * @return {Boolean}                   Is the target date within the past week?
 */
export function isWithinPastWeek(targetDate, today = moment()) {
    return targetDate.isAfter(
        today
            .clone()
            .subtract(7, 'days')
            .startOf('day')
    );
}
