/* @flow */

import {combineReducers, type Reducer} from 'redux';
import * as ramda from 'ramda';
import type {AccountId, ListItem} from '../types';
import {reduceListItemsByEntityId} from '../list-items/list-items-utils';
import * as Contacts from '../contacts';
import {addItemsToByIdState} from '../reducer-utils';
import {type Account, type AccountFormValues} from './accounts-types';
import {ActionTypes, type AccountsAction} from './accounts-actions';

/*
 * `byId`
 */
type ByIdState = $PropertyType<AccountsState, 'byId'>;
const byIdReducer = (
    state: ByIdState = {},
    action: Contacts.ContactsAction | AccountsAction
): ByIdState => {
    switch (action.type) {
        case ActionTypes.ACCOUNTS_FETCH_BY_ID_SUCCESS:
        case Contacts.ActionTypes.CONTACTS_FETCH_BY_ID_SUCCESS: {
            if (action.payload.accounts) {
                return addItemsToByIdState(action.payload.accounts, state);
            }

            return state;
        }
        default:
            return state;
    }
};

/*
 * `tempByName`
 *
 * This maintains a list of new accounts which haven't been created within Nutshell.
 */

type TempByNameState = $PropertyType<AccountsState, 'tempByName'>;
const tempByNameReducer = (
    state: TempByNameState = {},
    action: Contacts.ContactsAction | AccountsAction
): TempByNameState => {
    switch (action.type) {
        case ActionTypes.ACCOUNTS_ACCOUNT_QUEUED_FOR_CREATION:
            return {...state, [action.payload.name.toLowerCase()]: action.payload};
        case ActionTypes.ACCOUNTS_ACCOUNT_UNQUEUED_FOR_CREATION:
            return ramda.omit([action.payload], state);
        case ActionTypes.ACCOUNTS_QUEUED_ACCOUNTS_CLEARED:
            return {};
        default:
            return state;
    }
};

/*
 * `listItems`
 */
type ListItemsState = {
    [id: AccountId]: ListItem,
};
const listItemsByIdReducer = (
    state: ListItemsState = {},
    action: AccountsAction
): ListItemsState => {
    switch (action.type) {
        case ActionTypes.ACCOUNTS_FETCH_LIST_SUCCESS: {
            return reduceListItemsByEntityId({...state}, action.payload.listItems);
        }
        default:
            return state;
    }
};

export type AccountsState = {
    byId: {[id: AccountId]: Account},
    tempByName: {[name: string]: AccountFormValues},
    listItemsById: ListItemsState,
};

export const reducer: Reducer<
    AccountsState,
    Contacts.ContactsAction | AccountsAction
> = combineReducers({
    byId: byIdReducer,
    tempByName: tempByNameReducer,
    listItemsById: listItemsByIdReducer,
});
