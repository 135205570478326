/* @flow */

import * as React from 'react';
import {Field} from 'redux-form';

import {Body} from 'shells/typography';
import {Stack} from 'shells/layout';

import {PipelineSelectSingle} from './pipeline-select-single';

export function LeadWonForm() {
    return (
        <Stack spacing={8}>
            <Body>Which pipeline?</Body>
            <Field name='leadWon' component={PipelineSelectSingle} />
        </Stack>
    );
}
