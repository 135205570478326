/* @flow */

import moment from 'moment';
import startsWith from 'underscore.string/startsWith';
import type {DateRange} from './types';
import {
    isRange,
    isMagicDateValue,
    isCurrentPeriodValue,
    isExactYearValue,
    isToDatePeriodValue,
    isLastPeriodValue,
    lastPeriodRegex,
} from './utils/period-checks';
import {getDurationString} from './utils/get-duration-string';
import {getQuarterRelativeToDate} from './utils/get-quarter-relative-to-date';
import {getWeekStart, getWeekEnd} from './utils/get-work-week-relative-to-date';
import {getFiscalYearRelativeToDate} from './utils/get-fiscal-year-relative-to-date';

const DEFAULT_DATE_RANGE = {
    startDate: moment().startOf('month'),
    endDate: moment(),
};

/**
 * Calculates and returns a date range for the given date filter
 *
 * @param  {string} filter                     Date filter string to process
 * @param  {[Moment]} relativeToDate           Start the date range from a different date other
 *                                             than the current day
 * @param  {string} [fiscalYearStart]          optional start Of Fiscal year, {MONTH-DAY}
 * @param  {string} [workWeekStart]            optional start Of work week, {DAYOFWEEK}
 *
 * @return {object}                 Object with startDate and endDate keys
 */
export function getDateRangeFromDateFilter(
    filter: string,
    relativeToDate: Object = moment(),
    fiscalYearStart?: string = '1-1',
    workWeekStart?: string = '7'
): DateRange {
    // Default to month-to-date, in case the filter doesn't match. Seems like
    // a reasonable fallback.
    let {startDate, endDate} = DEFAULT_DATE_RANGE;

    if (isRange(filter)) {
        const dateSplit = filter.split(' TO ');
        startDate = moment.unix(Number(dateSplit[0]));
        endDate = moment.unix(Number(dateSplit[1]));
    } else if (isMagicDateValue(filter)) {
        if (startsWith(filter, '-')) {
            startDate = relativeToDate
                .clone()
                .subtract(filter.substring(2), getDurationString(filter.substring(1, 2)));
            endDate = relativeToDate.clone();
        } else if (startsWith(filter, '+')) {
            startDate = relativeToDate.clone();
            endDate = relativeToDate
                .clone()
                .add(filter.substring(2), getDurationString(filter.substring(1, 2)));
        }
    } else if (isCurrentPeriodValue(filter)) {
        if (filter === 'q') {
            const {startOfQuarter, endOfQuarter} = getQuarterRelativeToDate(
                relativeToDate.clone(),
                fiscalYearStart
            );
            startDate = startOfQuarter;
            endDate = endOfQuarter;
        } else if (filter === 'w') {
            startDate = getWeekStart(relativeToDate, workWeekStart);
            endDate = getWeekEnd(relativeToDate, workWeekStart);
        } else {
            startDate = relativeToDate.clone().startOf(getDurationString(filter));
            endDate = relativeToDate.clone().endOf(getDurationString(filter));
        }
    } else if (isToDatePeriodValue(filter)) {
        if (filter === 'qtd') {
            const {startOfQuarter} = getQuarterRelativeToDate(
                relativeToDate.clone(),
                fiscalYearStart
            );
            startDate = startOfQuarter;
            endDate = relativeToDate.clone();
        } else {
            startDate = relativeToDate.clone().startOf(getDurationString(filter.slice(0, 1)));
            endDate = relativeToDate.clone();
        }
    } else if (isExactYearValue(filter)) {
        startDate = moment(`${filter}-01-02`)
            .utc()
            .startOf('year');
        endDate = moment(`${filter}-01-02`)
            .utc()
            .endOf('year');
    } else if (filter === 'yesterday') {
        startDate = relativeToDate.clone().subtract(1, 'days');
        endDate = relativeToDate.clone().subtract(1, 'days');
    } else if (filter === 'q-1') {
        const {startOfQuarter, endOfQuarter} = getQuarterRelativeToDate(
            relativeToDate
                .clone()
                .subtract(3, 'months')
                .startOf('month'),
            fiscalYearStart
        );
        startDate = startOfQuarter;
        endDate = endOfQuarter;
    } else if (isLastPeriodValue(filter)) {
        const {lastPeriodUnit, lastPeriodQty} = parseLastPeriod(filter);
        if (lastPeriodUnit && lastPeriodQty) {
            const durationString = getDurationString(lastPeriodUnit);
            const adjustedRelativeToDate = relativeToDate
                .clone()
                .subtract(lastPeriodQty, durationString);

            // if our filter is for week, we need to offset for the instance's work week start day
            startDate =
                lastPeriodUnit === 'w'
                    ? getWeekStart(adjustedRelativeToDate, workWeekStart)
                    : adjustedRelativeToDate.clone().startOf(durationString);
            endDate =
                lastPeriodUnit === 'w'
                    ? getWeekEnd(adjustedRelativeToDate, workWeekStart)
                    : adjustedRelativeToDate.clone().endOf(durationString);
        }
    } else if (filter === 'w+1') {
        const offsetRelativeToDate = relativeToDate.clone().add(1, 'weeks');
        startDate = getWeekStart(offsetRelativeToDate, workWeekStart);
        endDate = getWeekEnd(offsetRelativeToDate, workWeekStart);
    } else if (filter === 'm+1') {
        startDate = relativeToDate
            .clone()
            .add(1, 'months')
            .startOf('month');
        endDate = relativeToDate
            .clone()
            .add(1, 'months')
            .endOf('month');
    } else if (filter === 'q+1') {
        const {startOfQuarter, endOfQuarter} = getQuarterRelativeToDate(
            relativeToDate
                .clone()
                .add(3, 'months')
                .startOf('month'),
            fiscalYearStart
        );
        startDate = startOfQuarter;
        endDate = endOfQuarter;
    } else if (filter === 'y+1') {
        startDate = relativeToDate
            .clone()
            .add(1, 'years')
            .startOf('year');
        endDate = relativeToDate
            .clone()
            .add(1, 'years')
            .endOf('year');
    } else if (filter === 'fy') {
        const {startOfFiscalYear, endOfFiscalYear} = getFiscalYearRelativeToDate(
            relativeToDate.clone(),
            fiscalYearStart
        );
        startDate = startOfFiscalYear;
        endDate = endOfFiscalYear;
    } else if (filter === 'fy-1') {
        const {startOfFiscalYear, endOfFiscalYear} = getFiscalYearRelativeToDate(
            relativeToDate.clone().subtract(1, 'years'),
            fiscalYearStart
        );
        startDate = startOfFiscalYear;
        endDate = endOfFiscalYear;
    } else if (filter === 'fy+1') {
        const {startOfFiscalYear, endOfFiscalYear} = getFiscalYearRelativeToDate(
            relativeToDate.clone().add(1, 'years'),
            fiscalYearStart
        );
        startDate = startOfFiscalYear;
        endDate = endOfFiscalYear;
    }

    return {startDate, endDate};
}

export function getPreviousDateRangeFromDateFilter(
    filter: string,
    fiscalYearStart?: string,
    workWeekStart?: string = '7'
): DateRange {
    if (isRange(filter)) {
        const dateSplit = filter.split(' TO ');
        const rangeStartDate = moment.unix(Number(dateSplit[0]));
        const rangeEndDate = moment.unix(Number(dateSplit[1]));

        const periodLengthInDays = rangeEndDate.diff(rangeStartDate, 'days') + 1;
        const startDate = rangeStartDate.clone().subtract(periodLengthInDays, 'days');
        const endDate = rangeEndDate.clone().subtract(periodLengthInDays, 'days');

        return {startDate, endDate};
    } else if (isMagicDateValue(filter)) {
        if (startsWith(filter, '-')) {
            const relativeToDate = moment()
                .clone()
                .subtract(filter.substring(2), getDurationString(filter.substring(1, 2)))
                .subtract(1, 'days');

            return getDateRangeFromDateFilter(filter, relativeToDate);
        } else if (startsWith(filter, '+')) {
            const relativeToDate = moment()
                .clone()
                .subtract(filter.substring(2), getDurationString(filter.substring(1, 2)))
                .subtract(1, 'days');

            return getDateRangeFromDateFilter(filter, relativeToDate);
        }
    } else if (isCurrentPeriodValue(filter)) {
        if (filter === 'q') {
            return getDateRangeFromDateFilter(
                filter,
                moment().subtract(3, 'months'),
                fiscalYearStart
            );
        } else {
            return getDateRangeFromDateFilter(
                filter,
                moment().subtract(1, getDurationString(filter)),
                fiscalYearStart,
                workWeekStart
            );
        }
    } else if (isToDatePeriodValue(filter)) {
        if (filter === 'qtd') {
            return getDateRangeFromDateFilter(
                filter,
                moment().subtract(3, 'months'),
                fiscalYearStart
            );
        } else {
            return getDateRangeFromDateFilter(
                filter,
                moment().subtract(1, getDurationString(filter.slice(0, 1))),
                fiscalYearStart,
                workWeekStart
            );
        }
    } else if (filter === 'fy') {
        return getDateRangeFromDateFilter(filter, moment().subtract(1, 'years'), fiscalYearStart);
    } else if (filter === 'w+1') {
        return getDateRangeFromDateFilter('w', moment(), fiscalYearStart, workWeekStart);
    } else if (filter === 'm+1') {
        return getDateRangeFromDateFilter('m');
    } else if (filter === 'q+1') {
        return getDateRangeFromDateFilter('q', moment(), fiscalYearStart);
    } else if (filter === 'y+1') {
        return getDateRangeFromDateFilter('y');
    } else if (filter === 'fy+1') {
        return getDateRangeFromDateFilter('fy', moment(), fiscalYearStart);
    } else if (isLastPeriodValue(filter)) {
        const {lastPeriodUnit, lastPeriodQty} = parseLastPeriod(filter);
        if (lastPeriodUnit && lastPeriodQty) {
            return getDateRangeFromDateFilter(
                `${lastPeriodUnit}-${lastPeriodQty + 1}`,
                moment(),
                fiscalYearStart,
                workWeekStart
            );
        }
    }

    return DEFAULT_DATE_RANGE;
}

export function getPreviousYearDateRangeFromDateFilter(
    filter: string,
    fiscalYearStart?: string,
    workWeekStart?: string = '7'
): DateRange {
    if (isRange(filter)) {
        const dateSplit = filter.split(' TO ');
        const startDate = moment.unix(Number(dateSplit[0])).subtract(1, 'years');
        const endDate = moment.unix(Number(dateSplit[1])).subtract(1, 'years');

        return {startDate, endDate};
    } else {
        return getDateRangeFromDateFilter(
            filter,
            moment().subtract(1, 'years'),
            fiscalYearStart,
            workWeekStart
        );
    }
}

/**
 * Get the period string (d, m, q, y) and number from a last-period filter value
 * @param  {string} filterValue A last-period filter value
 * @return {Object}             Object containing lastPeriodUnit and lastPeriodQty if found
 */
export function parseLastPeriod(
    filterValue: string
): {lastPeriodUnit?: string, lastPeriodQty?: number} {
    if (!isLastPeriodValue(filterValue)) return {};

    const match = filterValue.match(lastPeriodRegex);
    if (match) {
        return {
            lastPeriodUnit: match[1],
            lastPeriodQty: Number(match[2]),
        };
    }

    return {};
}
