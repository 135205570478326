/* @flow */

import {Quill} from 'react-quill';

const Embed = Quill.import('blots/embed');

export type Placeholder = {
    id: string,
    label: string,
};

export class PlaceholderBlot extends Embed {
    static blotName = 'placeholder';
    static tagName = 'span';
    static className = 'ql-placeholder-blot';

    placeholderValue: ?Placeholder;

    constructor(domNode: Node, value?: Placeholder) {
        super(domNode, value);
        this.placeholderValue = value;
    }

    static create(value: Placeholder) {
        const node: HTMLElement = super.create(value);
        node.setAttribute('data-id', value.id);
        node.setAttribute('data-label', value.label);
        node.setAttribute('spellcheck', 'false');

        const label = value.label;
        node.innerHTML = label;

        return node;
    }

    value() {
        return this.placeholderValue ? `{${this.placeholderValue.id}}` : null;
    }

    static value(domNode: HTMLElement) {
        return domNode.dataset;
    }

    length(): number {
        return 1;
    }
}
