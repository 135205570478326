/* @flow */
import {reducer} from './stages-reducer';
import * as actions from './stages-actions';
import * as selectors from './stages-selectors';

export type {StagesState, StagesAction} from './stages-types';

export const Stages = {
    reducer,
    ...actions,
    ...selectors,
};
