/* @flow */

export const getDurationMinutesText = (durationMinutes: number): string => {
    let durationText;
    if (durationMinutes <= 59) {
        durationText = `${durationMinutes} minute${durationMinutes === 1 ? '' : 's'}`;
    } else {
        const hours = Math.floor(durationMinutes / 60);
        const minutes = durationMinutes % 60;
        durationText = `${hours} hour${hours === 1 ? '' : 's'}${
            minutes > 0 ? ` ${minutes} minutes` : ''
        }`;
    }

    return durationText;
};
