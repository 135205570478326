/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import type {TagType} from 'nutshell-graphql-types';

import {GraphQLSelectTags} from '../../../core/select/graphql-select-tags';

import './forms.css';

type Props = {
    ...FieldProps,
    tagType: TagType,
};

export function TagSelectSingle(props: Props) {
    return (
        <GraphQLSelectTags
            onChange={(newTags) => {
                props.input.onChange(newTags);
            }}
            selectedValues={props.input.value || []}
            tagType={props.tagType}
            onClearValue={() => {
                props.input.onChange(null);
            }}
        />
    );
}
