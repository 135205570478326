/* @flow */

import * as React from 'react';
import {FeatureUsageBox} from './feature-usage-box';

import './feature-usage.css';

type Props = {
    currentUsage: number,
    currentPlanMaxUsage: ?number,
    hasCustomLimit: boolean,
    nextPlanMaxUsage: ?number,
};

export function FeatureUsage(props: Props) {
    return (
        <div styleName='feature-usage'>
            <FeatureUsageBox
                currentUsage={props.currentUsage}
                maxUsage={props.currentPlanMaxUsage}
                hasCustomLimit={props.hasCustomLimit}
                isCurrentPlan={true}
            />
            <FeatureUsageBox
                currentUsage={props.currentUsage}
                maxUsage={props.nextPlanMaxUsage}
                hasCustomLimit={props.hasCustomLimit}
                isCurrentPlan={false}
            />
        </div>
    );
}
