/* @flow */

import * as React from 'react';

import './bold-label.css';

type Props = {
    children?: React.Node,
    as?: string,
};

export function BoldLabel(props: Props) {
    const {children, as = 'label'} = props;
    const Tag = as;

    return <Tag styleName='bold-label'>{children}</Tag>;
}
