import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Add from './icon-add.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Add {...validProps} />;
}
SvgComponent.iconName = 'add';

export const AddIcon = svgIconWrapper(SvgComponent);
