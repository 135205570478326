import PropTypes from 'prop-types';
import * as React from 'react';
import {ChevronLeftIcon, ChevronRightIcon} from '../../icon';
import './scroll-button.css';

export class ScrollButton extends React.Component {
    static propTypes = {
        orientation: PropTypes.string.isRequired,
        className: PropTypes.string,
        iconClassName: PropTypes.string,
        onClick: PropTypes.func,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
    };

    render() {
        return (
            <button
                onClick={this.props.onClick}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
                className={this.props.className}
                styleName={this.props.orientation === 'left' ? 'button-left' : 'button-right'}
            >
                {this.props.orientation === 'left' ? (
                    <ChevronLeftIcon className={this.props.iconClassName} />
                ) : (
                    <ChevronRightIcon className={this.props.iconClassName} />
                )}
            </button>
        );
    }
}
