import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';
import _ from 'underscore';
import {REHYDRATE} from 'redux-persist';

import * as actions from './dashboard-actions';
import {
    getDashDefaults,
    getDashTypeByEntityName,
    getDashTypeByReportType,
    ERROR_TYPE_FETCHING,
    ERROR_TYPE_DELETING,
    ACTIVITIES,
} from './dashboard-constants';
import {HIDDEN, MONTH, WEEK} from './sidebar/activity-constants';
import {
    activitiesReducer,
    sidebarActivitiesConfigReducer,
} from './sidebar/activities/activities-reducer';
import {todosReducer, sidebarTodosConfigReducer} from './sidebar/todos/todos-reducer';
import {feedConfigReducer} from './sidebar/feed/feed-reducer';
import {configReducer} from './dash-grid/dash-config/config-reducer';

const defaultDashesState = {
    isRequestingDashes: false,
    dashesErrorMessage: undefined,
    items: {},
};

const defaultDashState = {
    id: undefined,
    isRequestingComponentData: false,
    error: undefined,
    errorWasDismissed: false,
    lastUpdated: 0,
    didInvalidate: false,
    type: undefined,
    name: undefined,
    value: undefined,
    data: {},
};

const defaultUiState = {
    activeView: ACTIVITIES,
    rightPaneWidth: 400,
    rightPaneIsSnappedOpen: false,
    rightPaneIsTwoColumn: false,
    leftScrollTop: 0,
    reorderingDashesErrorMessage: undefined,
    dashIdBeingConfigured: undefined,
    dashIdBeingConfirmedToDelete: undefined,
    dashIdBeingDeleted: undefined,
    dashOrderById: [],
    isSelectingNewDashType: false,
    todoListIsRequesting: false,
    todoListErrorMessage: undefined,
    activityCalendarDisplayMode: HIDDEN,
    activityListIsRequesting: false,
    activityListErrorMessage: undefined,
    activityCalendarIsLoading: false,
    activityCalendarErrorMessage: undefined,
    activityListItems: [],
    activityCalendarItems: [],
    activityOverdueListIsRequesting: false,
    activityOverdueItems: [],
    activityOverdueListIsExpanded: false,
    hasImportantActivities: false,
};

/*
Create and export a dashboard reducer function

In its simplest form, a reducer function in redux takes an existing state object
and a dispatched action, and returns a new state.  In this case, this function
is being generated for us from redux-act's `createReducer` function.  The API
for `createReducer` is:

    createReducer(handlers, [defaultState])

`handlers` is an object whose keys are action creators and values are reducing
functions.  These functions receive the previous state, as well as the payload
of the action, if there is one.

As an example:

```
// action creator:
// (this is the normal kind, but it also works for action creators created with
// redux-act's `createAction` method)
const actionCreator = (id) => ({ type: 'ACTION_PERFORMED', payload: id });

// reducer creator:
const reducer = createReducer({
    [actionCreator]: (state, payload) => ({...state, id: payload.id}),
}, defaultState)

// returned `reducer` is equivilent to:
function reducer(state = defaultState, action) {
    switch(action.type) {
    case 'ACTION_PERFORMED':
        return {...state, id: action.payload.id};
    default:
        return state;
    }
}
```

This reducer is then provided to redux when the store is created with `createStore`
in master-dashboard.js.  Any actions that are dispatched to the store will pass
through the dashboardReducer.
 */

export const schema = createReducer(
    {
        [actions.updateDashboardSchema]: (state, payload) => payload,
        [REHYDRATE]: (state, persistedState) => simpleHydrate(state, persistedState, 'schema'),
    },
    {}
);

export const leadsSchema = createReducer(
    {
        [actions.updateLeadsSchema]: (state, payload) => payload,
        [REHYDRATE]: (state, persistedState) => simpleHydrate(state, persistedState, 'leadsSchema'),
    },
    {}
);

export const contactsSchema = createReducer(
    {
        [actions.updateContactsSchema]: (state, payload) => payload,
        [REHYDRATE]: (state, persistedState) =>
            simpleHydrate(state, persistedState, 'contactsSchema'),
    },
    {}
);

export const accountsSchema = createReducer(
    {
        [actions.updateAccountsSchema]: (state, payload) => payload,
        [REHYDRATE]: (state, persistedState) =>
            simpleHydrate(state, persistedState, 'accountsSchema'),
    },
    {}
);

export const isLoading = createReducer(
    {
        [actions.updateDashboardData]: () => false,
    },
    true
);

export const savedlistsReducer = createReducer(
    {
        [actions.requestSavedListsData]: (state) => {
            return {...state, isLoading: true};
        },
        [actions.updateSavedListsData]: (state, payload) => {
            return {...state, isLoading: false, items: payload};
        },
    },
    {isLoading: false, items: []}
);

export const dashReducer = createReducer(
    {
        [actions.updateDashBaseData]: (state, payload) => {
            return {
                ...state,
                isRequestingComponentData: true,
                error: undefined,
                errorWasDismissed: false,
                lastUpdated: new Date().getTime(),
                didInvalidate: false,
                id: payload.id,
                type: payload.type,
                name: payload.name,
                value: payload.value,
            };
        },
        [actions.requestDashComponentData]: (state) => ({
            ...state,
            isRequestingComponentData: true,
            error: undefined,
        }),
        [actions.failDashComponentData]: (state, payload) => {
            return {
                ...state,
                error: payload,
                isRequestingComponentData: false,
                errorWasDismissed: false,
            };
        },
        [actions.dismissDashComponentFailure]: (state) => ({...state, errorWasDismissed: true}),
        [actions.updateDashComponentData]: (state, payload) => {
            return {...state, isRequestingComponentData: false, data: payload};
        },
    },
    defaultDashState
);

export const dashesReducer = createReducer(
    {
        [actions.requestDashesData]: (state) => {
            return {...state, isRequestingDashes: true};
        },
        [actions.updateDashesData]: (state, dashes) => {
            let fetchedDashes = dashes;

            if (!NutClientConfig.hasQuotas) {
                fetchedDashes = fetchedDashes.filter((dash) => {
                    return dash.reportType !== 'dashboard_quotas';
                });
            }

            if (!NutClientConfig.hasHotLeads) {
                fetchedDashes = fetchedDashes.filter((dash) => dash.name !== 'Hot leads');
            }

            const newDashes = fetchedDashes.reduce((newState, current) => {
                current.type = getDashTypeByReportType(current.reportType);
                newState[current.id] = dashReducer(
                    state.items[current.id],
                    actions.updateDashBaseData(current)
                );

                return newState;
            }, {});

            return {...state, isRequestingDashes: false, items: newDashes};
        },
        [actions.failDashesData]: (state, payload) => {
            return {...state, isRequestingDashes: false, dashesErrorMessage: payload.statusText};
        },
        [actions.failDeleteDashById]: (state, payload) => {
            const dashesWithError = {
                ...state.items,
                [payload.dashId]: dashReducer(
                    state.items[payload.dashId],
                    actions.failDashComponentData({
                        msg: 'Unable to delete card.',
                        type: ERROR_TYPE_DELETING,
                    })
                ),
            };

            return {...state, items: dashesWithError};
        },
        [actions.requestDashComponentDataById]: (state, dashId) => {
            const newDashes = {
                ...state.items,
                [dashId]: dashReducer(state.items[dashId], actions.requestDashComponentData()),
            };

            return {...state, items: newDashes};
        },
        [actions.updateDashComponentDataById]: (state, payload) => {
            const newDashes = {
                ...state.items,
                [payload.dashId]: dashReducer(
                    state.items[payload.dashId],
                    actions.updateDashComponentData(payload)
                ),
            };

            return {...state, items: newDashes};
        },
        [actions.failDashComponentDataById]: (state, payload) => {
            let newDashes = state.items;
            let errorText = 'Something went wrong';
            let status;
            const error =
                Array.isArray(payload.errors) && payload.errors.length > 0 && payload.errors[0];
            if (Array.isArray(payload.errors) && payload.errors.length > 0 && error.title) {
                errorText = error.title;
                status = error.status;
            }
            if (
                state.items[payload.dashId].type === 'quotas' &&
                errorText === 'Invalid dashboard quotas filter query params'
            ) {
                newDashes = {
                    ...state.items,
                    [payload.dashId]: dashReducer(
                        state.items[payload.dashId],
                        actions.updateDashComponentData({
                            ...state.items[payload.dashId].data,
                            dashboardQuotas: [],
                        })
                    ),
                };
            } else {
                newDashes = {
                    ...state.items,
                    [payload.dashId]: dashReducer(
                        state.items[payload.dashId],
                        actions.failDashComponentData({
                            msg: errorText,
                            type: ERROR_TYPE_FETCHING,
                            status,
                        })
                    ),
                };
            }

            return {...state, items: newDashes};
        },
        [actions.dismissDashComponentFailureById]: (state, dashId) => {
            const newDashes = {
                ...state.items,
                [dashId]: dashReducer(state.items[dashId], actions.dismissDashComponentFailure()),
            };

            return {...state, items: newDashes};
        },
        [actions.toggleConfigureDash]: (state, payload) => {
            // If this is a new dash being added, seed dashes.items with defaults
            if (payload && payload.startsWith('new-')) {
                const type = getDashTypeByEntityName(payload.split('-')[1]);
                const newDash = {...getDashDefaults(type), id: payload, type: type};
                const newDashes = {
                    ...state.items,
                    [payload]: dashReducer(undefined, actions.updateDashBaseData(newDash)),
                };

                return {...state, items: newDashes};
            } else {
                return state;
            }
        },
        [REHYDRATE]: (state, persistedState) => {
            if (!persistedState || !persistedState.dashboard || !persistedState.dashboard.dashes) {
                return state;
            }

            // If there is a dashesErrorMessage, it means we never correctly fetched the dashes, so start over from scratch
            if (persistedState.dashboard.dashes.dashesErrorMessage) {
                return state;
            }

            const dashKeys = Object.keys(persistedState.dashboard.dashes.items);
            const dashesWithoutErrors = dashKeys.reduce((newItems, dashKey) => {
                const dashItem = persistedState.dashboard.dashes.items[dashKey];
                if (dashItem.error) {
                    dashItem.data = {};
                    dashItem.error = undefined;
                    dashItem.isRequestingComponentData = true;
                }

                return {...newItems, [dashKey]: dashItem};
            }, {});

            return {
                ...state,
                items: dashesWithoutErrors,
                isRequestingDashes: false,
                dashesErrorMessage: undefined,
            };
        },
    },
    defaultDashesState
);

export const uiReducer = createReducer(
    {
        [actions.changeRightPaneActiveView]: (state, payload) => ({...state, activeView: payload}),
        [actions.resizeRightPaneWidth]: (state, payload) => ({...state, rightPaneWidth: payload}),
        [actions.changeRightPaneColumnCount]: (state, columnCount) => {
            const isTwoCol = columnCount === 2;

            return {...state, rightPaneIsTwoColumn: isTwoCol};
        },
        [actions.saveLeftScrollPosition]: (state, payload) => ({...state, leftScrollTop: payload}),
        [actions.toggleConfigureDash]: (state, payload) => {
            const dashIdBeingConfigured =
                state.dashIdBeingConfigured === payload ? undefined : payload;

            return {
                ...state,
                dashIdBeingConfigured: dashIdBeingConfigured,
                isSelectingNewDashType: false,
            };
        },
        [actions.toggleDeleteDash]: (state, payload) => {
            const dashIdBeingConfirmedToDelete =
                state.dashIdBeingConfirmedToDelete === payload ? undefined : payload;

            return {...state, dashIdBeingConfirmedToDelete: dashIdBeingConfirmedToDelete};
        },
        [actions.requestDeleteDash]: (state, payload) => ({...state, dashIdBeingDeleted: payload}),
        [actions.updateDeleteDash]: (state) => ({
            ...state,
            dashIdBeingConfirmedToDelete: undefined,
            dashIdBeingDeleted: undefined,
        }),
        [actions.failDeleteDashById]: (state) => ({
            ...state,
            dashIdBeingDeleted: undefined,
            dashIdBeingConfirmedToDelete: undefined,
        }),
        [actions.addNewDash]: (state) => ({
            ...state,
            isSelectingNewDashType: true,
            dashIdBeingConfigured: undefined,
        }),
        [actions.updateDashesData]: (state, dashes) => {
            const dashOrderById = _.pluck(dashes, 'id');

            return {...state, dashOrderById: dashOrderById};
        },
        [actions.updateDashOrder]: (state, payload) => ({...state, dashOrderById: payload}),
        [actions.failDashOrder]: (state, payload) => {
            return {
                ...state,
                reorderingDashesErrorMessage: payload.errorMessage,
                dashOrderById: payload.dashOrderById,
            };
        },
        [actions.hoverDash]: (state, payload) => ({
            ...state,
            dashOrderById: insert(payload.id, state.dashOrderById, payload.index),
        }),
        [actions.toggleActivityOverdueListIsExpanded]: (state) => {
            return {...state, activityOverdueListIsExpanded: !state.activityOverdueListIsExpanded};
        },
        [actions.updateActivityCalendarDisplayMode]: (state) => {
            let newDisplayMode = HIDDEN;
            if (state.activityCalendarDisplayMode === HIDDEN) {
                newDisplayMode = MONTH;
            } else if (state.activityCalendarDisplayMode === MONTH) {
                newDisplayMode = WEEK;
            }

            return {
                ...state,
                activityCalendarDisplayMode: newDisplayMode,
            };
        },
        [actions.requestTodosForDay]: (state) => {
            return {...state, todoListIsRequesting: true};
        },
        [actions.requestActivitiesForDay]: (state) => {
            return {...state, activityListIsRequesting: true};
        },
        [actions.failTodosForDay]: (state, payload) => {
            return {...state, todoListIsRequesting: false, todoListErrorMessage: payload};
        },
        [actions.clearTodoListError]: (state) => {
            return {...state, todoListErrorMessage: undefined};
        },
        [actions.clearActivityListError]: (state) => {
            return {...state, activityListErrorMessage: undefined};
        },
        [actions.failActivitiesForDay]: (state, payload) => {
            return {...state, activityListIsRequesting: false, activityListErrorMessage: payload};
        },
        [actions.updateActivitiesForDay]: (state, {activities, meta}) => {
            const activitiesById = _.pluck(activities, 'id');
            const newActivitiesById = _.union(state.activityListItems, activitiesById);

            return {
                ...state,
                hasImportantActivities: meta ? meta.hasImportantActivities : false,
                activityListIsRequesting: false,
                activityListErrorMessage: undefined,
                activityListItems: newActivitiesById,
            };
        },
        [actions.updateActivityDataById]: (state, payload) => {
            const newActivityListItems = _.union(state.activityListItems, [payload.id]);
            const newActivityCalendarItems = _.union(state.activityCalendarItems, [payload.id]);
            const newActivityOverdueItems = _.union(state.activityOverdueItems, [payload.id]);

            return {
                ...state,
                activityListItems: newActivityListItems,
                activityCalendarItems: newActivityCalendarItems,
                activityOverdueItems: newActivityOverdueItems,
            };
        },
        [actions.updateActivityDisplayDate]: (state) => {
            return {
                ...state,
                activityListItems: [],
                activityOverdueListIsExpanded: false,
            };
        },
        [actions.updateTodosForDay]: (state) => {
            return {
                ...state,
                todoListIsRequesting: false,
                todoListErrorMessage: undefined,
            };
        },
        [actions.updateActivitiesForMonth]: (state, activities) => {
            const activitiesById = _.pluck(activities, 'id');

            return {...state, activityCalendarItems: activitiesById};
        },
        [actions.requestOverdueActivities]: (state) => {
            return {...state, activityOverdueListIsRequesting: true};
        },
        [actions.updateOverdueActivities]: (state, activities) => {
            const overdueActivitiesById = _.pluck(activities, 'id');

            return {
                ...state,
                activityOverdueItems: overdueActivitiesById,
                activityOverdueListIsRequesting: false,
            };
        },
        [actions.failOverdueActivities]: (state) => {
            return {...state, activityOverdueListIsRequesting: false};
        },
        [actions.updateActivityFilter]: (state) => {
            return {
                ...state,
                activityListItems: [],
            };
        },
        [actions.updateTodoFilter]: (state) => {
            return {
                ...state,
                todoListErrorMessage: undefined,
            };
        },
        [actions.snapSidebarOpen]: (state) => {
            return {
                ...state,
                rightPaneIsSnappedOpen: true,
            };
        },
        [actions.unsnapSidebarOpen]: (state) => {
            return {
                ...state,
                rightPaneIsSnappedOpen: false,
            };
        },
        [REHYDRATE]: (state, persistedState) => {
            if (!persistedState || !persistedState.dashboard || !persistedState.dashboard.ui) {
                return state;
            }

            let dashOrderById = persistedState.dashboard.ui.dashOrderById;
            if (
                typeof NutshellFrontendSettings !== 'undefined' &&
                NutshellFrontendSettings.isDemoSilo
            ) {
                dashOrderById = persistedState.dashboard.ui.dashOrderById.filter((id) => {
                    return id !== 'onboarding01-dashes';
                });
            }

            return {
                ...state,
                ...persistedState.dashboard.ui,
                dashOrderById: dashOrderById,
                isSelectingNewDashType: false,
                dashIdBeingConfigured: undefined,
                todoListErrorMessage: undefined,
                activityListErrorMessage: undefined,
                activityListItems: [],
                activityOverdueItems: [],
            };
        },
    },
    defaultUiState
);

export const dashboardReducer = combineReducers({
    contactsSchema,
    leadsSchema,
    accountsSchema,
    schema,
    isLoading,
    dashes: dashesReducer,
    savedLists: savedlistsReducer,
    ui: uiReducer,
    activities: activitiesReducer,
    activitiesConfig: sidebarActivitiesConfigReducer,
    feedConfig: feedConfigReducer,
    todos: todosReducer,
    todosConfig: sidebarTodosConfigReducer,
    config: configReducer,
});

function insert(item, arr, idx) {
    const newArr = _.without(arr, item);
    const arrStart = newArr.slice(0, idx);
    const arrEnd = newArr.slice(idx);

    return arrStart.concat(item).concat(arrEnd);
}

/**
 * Rehydrate from persisted state, if available.
 *
 * Caveat: this expects only one level of nesting under `dashboard`.
 *
 * @param  {Object} state          - The previous state
 * @param  {Object} persistedState - Persisted state, if available
 * @param  {string} key            - Name of the subreducer
 * @return {Object}                - New state
 */
export function simpleHydrate(state, persistedState, key) {
    if (!persistedState || !persistedState.dashboard || !persistedState.dashboard[key]) {
        return state;
    }

    return {...state, ...persistedState.dashboard[key]};
}
