/* @flow */

import * as React from 'react';
import {FieldArray} from 'redux-form';

import type {TagType} from 'nutshell-graphql-types';

import {Body} from 'shells/typography';
import {Stack} from 'shells/layout';
import {TagSelect} from './tag-select';

import './forms.css';

type Props = {
    tagType: TagType,
    shouldAnd?: boolean,
};

export function AddTagForm(props: Props) {
    return (
        <Stack spacing={8}>
            <Body>Which tag?</Body>
            <FieldArray
                name='tags'
                component={TagSelect}
                tagType={props.tagType}
                shouldAnd={props.shouldAnd}
            />
        </Stack>
    );
}
