/* @flow */

import * as React from 'react';

import type {EventFragmentSparse} from 'nutshell-graphql-types';

import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

const ACCOUNT_TYPE_ID = 'account_type_id';

type Props = {|event: EventFragmentSparse|};

export function TimelineBannerCompanyType(props: Props) {
    const {event} = props;

    const companyName = event.payload.name ? event.payload.name : undefined;
    const typeChange = event.changes.find((change) => change.attribute === ACCOUNT_TYPE_ID);
    const oldType = typeChange ? typeChange.oldValue : undefined;
    const newType = typeChange ? typeChange.newValue : undefined;

    // A company always has a company type so oldType and newType will always exist
    // so this is just a check to appease flow
    if (companyName && typeof companyName === 'string' && oldType && newType) {
        const action = (
            <span>
                <span>
                    changed the company type for <strong>{companyName}</strong> from{' '}
                    <strong>{oldType}</strong> to <strong>{newType}</strong>
                </span>
            </span>
        );

        return (
            <TimelineBanner
                id={event.id}
                iconVariant='company-type-changed'
                timestamp={event.changeTime}
                actor={event.actor}
                action={action}
            />
        );
    }

    return null;
}
