/* @flow */

const defaultSettings =
    typeof window.NutshellFrontendSettings !== 'undefined' ? window.NutshellFrontendSettings : null;

export function isProUser(settings: ?{isPro: string} = defaultSettings) {
    // Not all environments use NutshellFrontendSettings, returning false isn't ideal,
    // so let's throw a console error to let the developer know something's wrong
    if (!settings) {
        console.error('Cannot determine whether user is Pro'); // eslint-disable-line no-console

        return false;
    }

    return Boolean(settings && settings.isPro);
}
