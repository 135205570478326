import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import UserGroup from './icon-user-group.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <UserGroup {...validProps} />;
}
SvgComponent.iconName = 'userGroup';

export const UserGroupIcon = svgIconWrapper(SvgComponent);
