/* @flow */

import type {Dispatch} from 'redux';

import {NON_NULL_ERROR_MESSAGE} from 'shells/form/validation/non-null';

import {pushFlashMessage} from '../creator/creator-actions';

export function handleFormSubmissionError(dispatch: Dispatch<*>, jqXHRError: any, errors?: Object) {
    const fallbackErrorMessage =
        'Something went wrong trying to submit this form. Please try again.';

    try {
        if (errors) {
            // If there are field level errors, then we don't want to show the generic error message
            if (isShowingFieldLevelError(errors)) {
                return;
            }
        }

        const errorObjects = JSON.parse(jqXHRError.responseText).errors.map((error) => {
            return {
                message: error.detail || fallbackErrorMessage,
            };
        });

        errorObjects.forEach((error) => {
            dispatch(
                pushFlashMessage({
                    message: error.message,
                    type: 'error',
                })
            );
        });
    } catch (e) {
        dispatch(
            pushFlashMessage({
                message: fallbackErrorMessage,
                type: 'error',
            })
        );
    }
}

/**
 * If errors contains the error string, then we don't want to show any other error messages
 * because the field level error will be shown.
 */
function isShowingFieldLevelError(errors: Object): boolean {
    for (const error of Object.values(errors)) {
        if (error === NON_NULL_ERROR_MESSAGE) {
            return true;
        } else if (error && typeof error === 'object') {
            for (const fieldError of Object.values(error)) {
                if (fieldError === NON_NULL_ERROR_MESSAGE) {
                    return true;
                }
            }
        }
    }

    return false;
}
