import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'61 -6.3 34.1 57.7'}>
            <path d='M93.8 38.2L81 50.1l-.1.1-.3.3c-.4.3-.9.6-1.4.7-.4.1-.8.2-1.2.2-.3 0-.6 0-.8-.1-.2 0-.3-.1-.5-.1-.3-.1-.6-.2-.9-.4-.1-.1-.3-.2-.4-.3l-.3-.3-.2-.2-12.7-11.8c-1.6-1.5-1.6-3.9 0-5.4 1.6-1.5 4.2-1.5 5.8 0l6.1 5.4V-3.7c0-2.1 1.7-2.6 3.9-2.6 2.3 0 3.9.5 3.9 2.6v41.9l6.1-5.4c1.6-1.5 4.2-1.5 5.8 0 1.6 1.5 1.6 3.9 0 5.4z' />
        </svg>
    );
}
SvgComponent.iconName = 'arrows-desc';

export const ArrowsDescIcon = svgIconWrapper(SvgComponent);
