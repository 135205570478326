/* @flow */

import * as React from 'react';

import isEqual from 'lodash/isEqual';

// State values that can be observed by this hook
type ObservableState = 'isHovered' | 'isFocused';

// State values that can be returned by this hook
type RefObserver = {
    isHovered: ?boolean,
    isFocused: ?boolean,
};

/**
 * Generic ref observer hook, pass in the state or states for the hook to observe, and the necessary
 * event listeners will be added and the state values will be returned.
 *
 * @param ref
 * @param observeStates
 * @param forceDefault
 * @returns {{isHovered: boolean, isFocused: boolean}}
 */
export const useRefObserver = (
    ref: any,
    observeStates: ObservableState | ObservableState[],
    forceDefault?: boolean
): RefObserver => {
    const [observeStatesList, setObserveStatesList] = React.useState(
        Array.isArray(observeStates) ? observeStates : [observeStates]
    );

    const [isHovered, setIsHovered] = React.useState<?boolean>(undefined);
    const [isFocused, setIsFocused] = React.useState<?boolean>(undefined);

    const observeEventTypes = React.useMemo(() => {
        const eventTypes = [];

        observeStatesList.forEach((state) => {
            switch (state) {
                case 'isHovered':
                    eventTypes.push('mouseenter', 'mouseleave');
                    break;
                case 'isFocused':
                    eventTypes.push('focus', 'blur');
                    break;
            }
        });

        return eventTypes;
    }, [observeStatesList]);

    React.useEffect(() => {
        if (!isEqual(observeStatesList, observeStates)) {
            setObserveStatesList(Array.isArray(observeStates) ? observeStates : [observeStates]);
        }
    }, [observeStates, observeStatesList]);

    React.useEffect(() => {
        const eventHandler = (event) => {
            switch (event.type) {
                case 'mouseenter':
                    setIsHovered(true);
                    break;
                case 'mouseleave':
                    setIsHovered(false);
                    break;
                case 'focus':
                    setIsFocused(true);
                    break;
                case 'blur':
                    setIsFocused(false);
                    break;
            }
        };

        const componentRef = ref ? ref.current : undefined;

        if (componentRef) {
            observeEventTypes.forEach((eventType) => {
                componentRef.addEventListener(eventType, eventHandler);
            });
        }

        return () => {
            if (componentRef) {
                observeEventTypes.forEach((eventType) => {
                    componentRef.removeEventListener(eventType, eventHandler);
                });
            }
        };
    }, [ref, observeEventTypes]);

    React.useEffect(() => {
        if (forceDefault) {
            // Force all states to default
            setIsHovered(undefined);
            setIsFocused(undefined);
        }
    }, [forceDefault]);

    return {isHovered, isFocused};
};
