/* @flow */
import {combineReducers, createStore, type StoreEnhancer} from 'redux';
// It seems that eslint-plugin-import is not liking this, but it works fine
import type {Store} from 'redux';

import {Stages} from '../stages';
import {VisualPipeline} from '../visual-pipeline';
import {SavedFilters} from '../saved-filters';
import * as Leads from '../leads';
import {Outcomes} from '../outcomes';
import {Collections} from '../collections';

/**
 * @param {Function} combineFunction so we can use `redux-persist`’s persistCombineReducer function
 * @param {Object}   appReducer An object that will be combined with our default
 *                              reducer set to create the actual application
 *                              reducer
 *
 * @param {Function} enhancer   The enhancer to apply to the redux store
 *                              frequently this is primarily middleware
 *
 * @return {Redux.store}        The redux store for the given application
 */
export function configure(
    combineFunction?: Function,
    appReducer?: Object = {},
    enhancer?: StoreEnhancer<*, *>
): Store<*, *> {
    const combineReducersFunction = combineFunction || combineReducers;

    let baseReducer = appReducer;
    let appEnhancer = enhancer;

    if (typeof appReducer === 'function') {
        baseReducer = {};
        appEnhancer = appReducer;
    }

    const reducer = combineReducersFunction({
        outcomes: Outcomes.reducer,
        stages: Stages.reducer,
        savedFilters: SavedFilters.reducer,
        visualPipeline: VisualPipeline.reducer,
        leads: Leads.reducer,
        accountTypes: Collections.createGenericCollectionReducerForType('accountTypes'),
        activityTypes: Collections.createGenericCollectionReducerForType('activityTypes'),
        emailSequenceTemplateGroups: Collections.createGenericCollectionReducerForType(
            'emailSequenceTemplateGroups'
        ),
        competitors: Collections.createGenericCollectionReducerForType('competitors'),
        industries: Collections.createGenericCollectionReducerForType('industries'),
        users: Collections.createGenericCollectionReducerForType('users'),
        teams: Collections.createGenericCollectionReducerForType('teams'),
        tags: Collections.createGenericCollectionReducerForType('tags'),
        sources: Collections.createGenericCollectionReducerForType('sources'),
        products: Collections.createGenericCollectionReducerForType('products'),
        territories: Collections.createGenericCollectionReducerForType('territories'),
        stagesets: Collections.createGenericCollectionReducerForType('stagesets'),
        markets: Collections.createGenericCollectionReducerForType('markets'),
        origins: Collections.createGenericCollectionReducerForType('origins'),
        ...baseReducer,
    });

    return createStore(reducer, appEnhancer);
}
