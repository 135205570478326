/* @flow */

import * as React from 'react';

import {Stack, type StackSpacing} from '../layout';
import {Card} from '../card';
import type {ColorName} from '../colors';

type Props = {|
    barHeight?: number,
    spacing?: StackSpacing,
    numBars?: number,
    padding?: number,
    backgroundColor?: ColorName,
|};

export function ListViewLoadingBars(props: Props) {
    const {
        barHeight = 70,
        spacing = 16,
        numBars = 4,
        padding = 16,
        backgroundColor = 'offWhiteDk',
    } = props;

    return (
        <div style={{padding}}>
            <Stack spacing={spacing}>
                {[...Array(numBars)].map((_, index) => (
                    <Card
                        // eslint-disable-next-line react/no-array-index-key
                        key={`placeholder-${index}`}
                        fitHeight={true}
                        backgroundColor={backgroundColor}
                        hasBorder={false}
                    >
                        <div style={{height: barHeight}} />
                    </Card>
                ))}
            </Stack>
        </div>
    );
}
