/* @flow */

import _ from 'underscore';
import type {FlashMessage} from './types';

export const ActionTypes = {
    GLOBAL_CREATE_NEW_FLASH_BANNER_PUSHED: 'GLOBAL_CREATE_NEW_FLASH_BANNER_PUSHED',
    GLOBAL_CREATE_NEW_FLASH_BANNER_REMOVED: 'GLOBAL_CREATE_NEW_FLASH_BANNER_REMOVED',
};

type CreateNewFlashBannerPushedAction = {
    type: 'GLOBAL_CREATE_NEW_FLASH_BANNER_PUSHED',
    payload: {id: string, flashMessage: FlashMessage},
};
type CreateNewFlashBannerRemovedAction = {
    type: 'GLOBAL_CREATE_NEW_FLASH_BANNER_REMOVED',
    payload: string,
};

export type CreatorAction = CreateNewFlashBannerPushedAction | CreateNewFlashBannerRemovedAction;

/*
 * Action creators
 *
 * These functions will be called by our app to create an action which can be dispatched.
 */
export const pushFlashMessage = (payload: FlashMessage): CreateNewFlashBannerPushedAction => {
    return {
        type: ActionTypes.GLOBAL_CREATE_NEW_FLASH_BANNER_PUSHED,
        payload: {
            flashMessage: payload,
            id: _.uniqueId(),
        },
    };
};

export const removeFlashMessage = (messageId: string): CreateNewFlashBannerRemovedAction => ({
    type: ActionTypes.GLOBAL_CREATE_NEW_FLASH_BANNER_REMOVED,
    payload: messageId,
});
