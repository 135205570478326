/* @flow */

import * as React from 'react';
import * as ramda from 'ramda';

import type {SpecialState} from '../table/types';
import {ErrorState} from '../error-state';
import {NothingFoundEmptyState} from '../empty-states';
import type {ColorName} from '../colors';

import {GridListLoadingCards} from './grid-list-loading-cards';
import {GradientWrapper} from './gradient-wrapper';

import './grid-list.css';

type Props = {
    numColumns?: number,
    /* If itemWidth is passed, we'll default to automatically
    wrapping columns based on width */
    itemWidth?: number,
    customFillRule?: string,
    gridGap?: number,
    children: React.Node,
    isLoading?: boolean,
    /**
     * Use to customize or override the default loading state rendered by this component, by default, if
     * this prop is undefined, the built-in loading state will be rendered whenever isLoading is true
     */
    loadingState?: {
        // customize values for built-in loading state
        itemHeight?: number, // height of loading state list item
        numItems?: number, // number of items to appear in loading state
        itemBackgroundColor?: ColorName, // background color of loading state list item
        // display custom component instead of built-in loading state
        customComponent?: React.Node,
    },
    /**
     * Use to customize or override the default nothing found state rendered by this component, by default, if
     * this prop is undefined, the built-in nothing found state will be rendered when the following conditions are met:
     *   isLoading: false
     *   isError: false
     *   isFiltering: true
     *   children.length === 0
     */
    nothingFoundState?: {
        title?: string,
        subtitle?: string,
    },
    error?: ?string,
    isFiltering?: boolean,
    emptyStateComponent?: React.Node,
    hasGradient?: boolean,
    specialStates?: SpecialState[],
};

export function GridList(props: Props) {
    const {numColumns = 3} = props;

    let children = props.children;
    let specialState;
    if (props.isLoading && !React.Children.count(props.children)) {
        // only want to show this if it might be the initial load
        if (props.loadingState && props.loadingState.customComponent) {
            specialState = {
                component: props.loadingState.customComponent,
            };
        } else {
            // want to render the loading state cards inside the grid container
            children = (
                <GridListLoadingCards
                    key='loading-cards'
                    numCards={
                        ((props.loadingState && props.loadingState.numItems) || numColumns) * 2
                    }
                    cardHeight={props.loadingState && props.loadingState.itemHeight}
                    cardWidth={props.itemWidth}
                    cardBackgroundColor={
                        props.loadingState && props.loadingState.itemBackgroundColor
                    }
                />
            );
        }
    } else if (props.error) {
        specialState = {
            component: <ErrorState errorMessage={props.error} />,
        };
    } else if (
        props.isFiltering &&
        !props.isLoading &&
        !props.error &&
        !React.Children.count(props.children)
    ) {
        specialState = {
            component: (
                <NothingFoundEmptyState
                    title={props.nothingFoundState ? props.nothingFoundState.title : undefined}
                    subtitle={
                        props.nothingFoundState ? props.nothingFoundState.subtitle : undefined
                    }
                />
            ),
        };
    } else if (
        props.emptyStateComponent &&
        !props.isFiltering &&
        !props.isLoading &&
        !props.error &&
        !React.Children.count(props.children)
    ) {
        specialState = {
            component: props.emptyStateComponent,
        };
    } else {
        const specialStates = props.specialStates || [];
        const findSpecialState = ramda.find((state: SpecialState) => {
            return state.shouldRender();
        });

        specialState = findSpecialState(specialStates);
    }

    const getGridTemplateColumns = () => {
        if (props.customFillRule) {
            return props.customFillRule;
        } else if (props.itemWidth) {
            return `repeat(auto-fill, ${props.itemWidth}px)`;
        } else {
            return `repeat(${numColumns}, 1fr)`;
        }
    };

    const gridStyles = {
        gridTemplateColumns: getGridTemplateColumns(),
        gridGap: props.gridGap,
    };

    const gridList = (
        <>
            <div styleName='grid-list-container' style={gridStyles}>
                {children}
            </div>
            {specialState ? specialState.component : undefined}
        </>
    );

    if (props.hasGradient) {
        return <GradientWrapper>{gridList}</GradientWrapper>;
    }

    return gridList;
}
