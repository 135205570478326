/* @flow */

import * as React from 'react';
import {Button} from 'shells/button';

import {TickCircleIcon} from 'shells/icon';
import './complete-task-button.css';

type Props = {
    isActive: boolean,
    disabled?: boolean,
    onClick: () => any,
};

type State = {
    isActive: boolean,
};

export class CompleteTaskButton extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isActive: props.isActive,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.props.isActive !== nextProps.isActive) {
            this.setState({isActive: nextProps.isActive});
        }
    }

    render() {
        const styleName = this.state.isActive ? 'complete--active' : 'complete';

        return (
            <div styleName='complete-task-button'>
                <Button
                    variant='secondary'
                    size='big'
                    disabled={this.props.disabled}
                    onClick={this.handleClick}
                >
                    <div styleName={styleName}>
                        <TickCircleIcon size={15} />
                        Complete
                    </div>
                </Button>
            </div>
        );
    }

    handleClick = () => {
        // Immediately update state so we don't have a janky double transition.
        this.setState((prevState) => ({isActive: !prevState.isActive}));
        this.props.onClick();
    };
}
