/* @flow */

import {unexpectedCase} from 'nutshell-core/reducer-utils';
import {REHYDRATE} from 'redux-persist';
import {ActionTypes, type EmailReplyAction} from './email-reply-actions';

export type EmailReplyState = {
    isOpen: boolean,
    replyToEmailId: ?string,
    isReplyingAll: boolean,
    onReplySuccess?: () => void,
};

export const defaultEmailReplyState = {
    isOpen: false,
    replyToEmailId: null,
    isReplyingAll: false,
};

export const emailReplyReducer = (
    state: EmailReplyState = defaultEmailReplyState,
    action: EmailReplyAction
): EmailReplyState => {
    switch (action.type) {
        case ActionTypes.EMAIL_REPLY_OPENED:
            return {
                ...state,
                isOpen: true,
                replyToEmailId: action.payload.replyToEmailId,
                isReplyingAll: action.payload.isReplyingAll,
                onReplySuccess: action.payload.onReplySuccess,
            };
        case ActionTypes.EMAIL_REPLY_CLOSED:
            return defaultEmailReplyState;
        case REHYDRATE: {
            const persistedState = action.payload;
            if (!persistedState || !persistedState.ui || !persistedState.ui.emailReplyModal) {
                return state;
            }

            return persistedState.ui.emailReplyModal;
        }
        default:
            unexpectedCase(action.type);

            return state;
    }
};
