/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {CheckboxButton} from 'shells/button';
import {FlameIcon} from 'shells/icon';

export function ReduxFormPriorityToggle(props: FieldProps) {
    const {input, ...rest} = props;

    return (
        <CheckboxButton
            {...input}
            {...rest}
            isLarge={true}
            icon={FlameIcon}
            label='This lead is hot'
            checked={input.value}
        />
    );
}
