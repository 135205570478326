/* @flow */

import * as React from 'react';
import backgroundImage from './info-banner-bg-stripes_2x.png';
import InfoIconSVG from './info-banner-icon.svg';
import './info-banner.css';

type Props = {
    children: React.Node,
};

/**
Use this component to provide information to the user, typically some kind of onboarding or training.

If you want them to *do* something, use a CTABanner.

To *alert* them to something that just happened, use a FlashBanner.
*/
export class InfoBanner extends React.Component<Props> {
    render() {
        return (
            <div styleName='info-banner' style={{backgroundImage: `url(${backgroundImage})`}}>
                <div styleName='icon-container'>
                    <InfoIconSVG />
                </div>
                <div styleName='content-wrapper'>{this.props.children}</div>
            </div>
        );
    }
}
