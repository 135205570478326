/* @flow */

import * as React from 'react';

import './relative-overlay.css';

type Props = {
    children?: React.Node,
    isOpaque: boolean,
};

export class RelativeOverlay extends React.Component<Props> {
    static defaultProps = {
        isOpaque: false,
    };

    render() {
        return (
            <div styleName={this.props.isOpaque ? 'container-opaque' : 'container'}>
                {this.props.children}
            </div>
        );
    }
}
