/* @flow */

import * as React from 'react';
import * as ramda from 'ramda';
import classnames from 'classnames';

import {measureScrollbarWidth} from '../utils/scrollbar';
import {type StackSpacing} from '../layout';
import {ErrorState} from '../error-state';
import {NothingFoundEmptyState} from '../empty-states';
import type {ColorName} from '../colors';
import {LoadingPage} from '../loading-page/loading-page';

import {ListViewLoadingBars} from './list-view-loading-bars';
import {renderSpecialState} from './helpers';

import './list-view.css';

export type SpecialState = {
    shouldRender: () => boolean,
    component: React.Node,
    centerVertically?: boolean,
};

export type ListViewProps = {|
    collection: Object[],
    renderRow: (item: Object, index: number) => React.Node,
    itemSpacing?: 8 | 16,
    listPadding?: 8 | 16,
    specialStates?: SpecialState[],
    isLoading?: boolean,
    /**
     * Use to customize or override the default loading state rendered by this component, by default, if
     * this prop is undefined, the built-in loading state will be rendered whenever isLoading is true
     */
    loadingState?: {
        // customize values for built-in loading state
        itemHeight?: number, // height of loading state list item
        spacing?: StackSpacing, // spacing between list items
        numItems?: number, // number of items to appear in loading state
        listPadding?: number, // padding around loading state list
        backgroundColor?: ColorName, // background color of loading state list
        // display custom component instead of built-in loading state
        customComponent?: React.Node,
    },
    /**
     * Use to display a loading overlay when the list is being updated. Differs from isLoading in that
     * the underlying list items are still visible. Requires the parent component to have a relative position.
     */
    isUpdating?: boolean,
    shouldScrollToTopWhenUpdating?: boolean,
    isError?: boolean,
    /**
     * Use to customize or override the default error state rendered by this component, by default, if
     * this prop is undefined, the built-in error state will be rendered whenever isError is true
     */
    errorState?: {
        // customize values for built-in error state
        errorMessage?: ?string,
        size?: 'big' | 'small',
        // display custom component instead of built-in error state
        customComponent?: React.Node,
    },
    isFiltering?: boolean,
    /**
     * Use to customize or override the default nothing found state rendered by this component, by default, if
     * this prop is undefined, the built-in nothing found state will be rendered when the following conditions are met:
     *   isLoading: false
     *   isError: false
     *   isFiltering: true
     *   collection.length === 0
     */
    nothingFoundState?: {
        title?: string,
        subtitle?: string,
    },
    /**
     * Component to render under the following conditions:
     *   isLoading: false
     *   isError: false
     *   isFiltering: false
     *   collection.length === 0
     */
    emptyStateComponent?: React.Node,
    isTable?: boolean,
    shouldfadeBottom?: boolean,
    header?: React.Node,
    footer?: React.Node,
    getListViewRef?: (?HTMLElement) => void,
    isFullHeight?: boolean,
    isOverflowAuto?: boolean,
|};

const findSpecialState = ramda.find((state: SpecialState) => {
    return state.shouldRender();
});

/**
This is a generic, building-block component used to render a list of
items.  An array of items is specified as a `collection`, and each
item in the array is mapped into a `renderRow` function.  Additionally,
header and footer elements can also be provided, if desired, and they
will be added to the start and end of the list, respectively.

Finally, an array of `specialStates` can be provided.  These must
be objects with both a `shouldRender` function that returns true
or false, and a `component` prop which is a react element to display
if the `shouldRender` evaluates to `true`.
 */
export function ListView(props: ListViewProps) {
    const {
        collection = [],
        specialStates = [],
        getListViewRef,
        isUpdating,
        shouldScrollToTopWhenUpdating,
    } = props;

    const listViewRef = React.useRef(null);

    const scrollbarWidth: number = measureScrollbarWidth();

    const Node = props.isTable ? 'table' : 'div';

    let specialState;
    if (props.isLoading && collection.length === 0) {
        // only want to show this if it might be the initial load
        specialState = {
            shouldRender: () => true,
            component:
                props.loadingState && props.loadingState.customComponent ? (
                    props.loadingState.customComponent
                ) : (
                    <ListViewLoadingBars
                        barHeight={props.loadingState ? props.loadingState.itemHeight : undefined}
                        spacing={props.loadingState ? props.loadingState.spacing : undefined}
                        numBars={props.loadingState ? props.loadingState.numItems : undefined}
                        padding={props.loadingState ? props.loadingState.listPadding : undefined}
                        backgroundColor={
                            props.loadingState ? props.loadingState.backgroundColor : undefined
                        }
                    />
                ),
        };
    } else if (props.isError) {
        specialState = {
            shouldRender: () => true,
            component:
                props.errorState && props.errorState.customComponent ? (
                    props.errorState.customComponent
                ) : (
                    <ErrorState
                        errorMessage={props.errorState ? props.errorState.errorMessage : undefined}
                        size={props.errorState ? props.errorState.size : undefined}
                    />
                ),
        };
    } else if (props.isFiltering && !props.isLoading && !props.isError && !collection.length) {
        specialState = {
            shouldRender: () => true,
            component: (
                <NothingFoundEmptyState
                    title={props.nothingFoundState ? props.nothingFoundState.title : undefined}
                    subtitle={
                        props.nothingFoundState ? props.nothingFoundState.subtitle : undefined
                    }
                />
            ),
        };
    } else if (
        props.emptyStateComponent &&
        !props.isFiltering &&
        !props.isLoading &&
        !props.isError &&
        !collection.length
    ) {
        specialState = {component: props.emptyStateComponent, shouldRender: () => true};
    } else {
        // The above common special states should not exist in specialStates list
        specialState = findSpecialState(specialStates);
    }

    const header = props.isTable ? <thead>{props.header}</thead> : props.header;

    const getList = (renderRow, itemSpacing) => {
        if (props.isTable) {
            return <tbody>{collection.map(renderRow)}</tbody>;
        }

        return (
            <ul className={props.listPadding ? `pad-${props.listPadding}` : undefined}>
                {collection.map((row, index) => {
                    return (
                        <li
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            className={
                                itemSpacing && index !== props.collection.length - 1
                                    ? `mb-${itemSpacing}`
                                    : undefined
                            }
                        >
                            {renderRow(row, index)}
                        </li>
                    );
                })}
            </ul>
        );
    };

    const footer = props.isTable ? <tfoot>{props.footer}</tfoot> : props.footer;

    const styleNames = classnames({
        'list-view-loading': props.isLoading,
        'list-view-loading--no-background-color':
            props.isLoading && props.loadingState && props.loadingState.backgroundColor,
        'fade-full': props.shouldfadeBottom && !scrollbarWidth,
        'fade-with-scrollbar': props.shouldfadeBottom && scrollbarWidth,
    });

    const classNames = classnames({
        'full-height': (specialState && specialState.centerVertically) || props.isFullHeight,
        'overflow-auto': props.isOverflowAuto,
    });

    React.useLayoutEffect(() => {
        if (listViewRef.current && getListViewRef) {
            getListViewRef(listViewRef.current);
        }
    }, [getListViewRef]);

    React.useLayoutEffect(() => {
        if (listViewRef.current && isUpdating && shouldScrollToTopWhenUpdating) {
            listViewRef.current.scrollTop = 0;
        }
    }, [isUpdating, shouldScrollToTopWhenUpdating]);

    return (
        <Node ref={listViewRef} styleName={styleNames} className={classNames}>
            {props.header ? header : null}
            {specialState
                ? renderSpecialState(specialState)
                : getList(props.renderRow, props.itemSpacing)}
            {props.footer ? footer : null}
            {props.isUpdating && collection.length > 0 && (
                <div className='full-height full-width' styleName='updating-overlay'>
                    <div className='mt-64'>
                        <LoadingPage />
                    </div>
                </div>
            )}
        </Node>
    );
}
