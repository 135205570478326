import {createSelector} from 'reselect';
import {getDashById, getDashes, getDashIdBeingConfigured} from '../../dashboard-selectors';

const getState = (state) => state;
const getConfigState = (state) => state.dashboard.config;

const getConfigPreviewState = createSelector([getConfigState], (configState) => {
    return configState.preview;
});

export const getSelectedDash = createSelector(
    [getState, getDashIdBeingConfigured],
    (state, dashIdBeingConfigured) => {
        return getDashById(state, dashIdBeingConfigured);
    }
);

export const getHasFunnelDash = createSelector([getDashes], (dashes) => {
    const funnelDash = dashes.find((dash) => {
        return dash.type === 'funnel';
    });

    return Boolean(funnelDash);
});

export const getDashboardPreview = createSelector([getConfigPreviewState], (configPreviewState) => {
    return configPreviewState.preview;
});

export const getPreviewIsLoading = createSelector([getConfigPreviewState], (configPreviewState) => {
    return configPreviewState.isLoading;
});

export const getPreviewIsRefreshing = createSelector(
    [getConfigPreviewState],
    (configPreviewState) => {
        return configPreviewState.isRefreshing;
    }
);

export const getPreviewErrorMessage = createSelector(
    [getConfigPreviewState],
    (configPreviewState) => {
        return configPreviewState.errorMessage;
    }
);
