/* @flow */

import {
    FileAudioIcon,
    FileExcelIcon,
    FileIcon,
    FileImageIcon,
    FilePdfIcon,
    FilePowerpointIcon,
    FileTextIcon,
    FileVideoIcon,
    FileWordIcon,
    FileZipIcon,
} from './index';

export function getFileIcon(filetype: string): React.ComponentType<*> {
    switch (filetype) {
        case 'archive':
            return FileZipIcon;
        case 'audio':
            return FileAudioIcon;
        case 'spreadsheet':
            return FileExcelIcon;
        case 'image':
            return FileImageIcon;
        case 'pdf':
            return FilePdfIcon;
        case 'powerpoint':
            return FilePowerpointIcon;
        case 'text':
            return FileTextIcon;
        case 'keynote':
        case 'video':
            return FileVideoIcon;
        case 'doc':
            return FileWordIcon;
        case 'unknown':
        default:
            return FileIcon;
    }
}
