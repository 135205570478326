/* @flow */

import {createSelector} from 'reselect';

import type {NutshellState} from '../../store';
import {getUIState} from '../ui-selectors';

import type {OpenableModal, OpenableModalId} from './openable-modals';

const getOpenableModalsState = createSelector([getUIState], (uiState) => uiState.openableModals);

export const getIsModalOpen = (state: NutshellState, modalId: OpenableModalId): boolean => {
    const openModals = getOpenableModalsState(state).openModals;

    return Boolean(
        Array.isArray(openModals) &&
            openModals.length &&
            openModals.find((openModal) => openModal.id === modalId)
    );
};

export const getOpenModals = (state: NutshellState): OpenableModal[] => {
    return getOpenableModalsState(state).openModals;
};
