/* @flow */

import React from 'react';

import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';
import AccountParentSVG from './icon-account-parent.svg';

type Props = {
    color?: string,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <AccountParentSVG {...validProps} color={props.color || colors.company} />;
}
SvgComponent.iconName = 'accountParent';

export const AccountParentIcon = svgIconWrapper(SvgComponent);
