/* @flow */

import * as React from 'react';

import type {ReactionType} from 'nutshell-graphql-types';

import {Card} from 'shells/card';
import {TimelineEntryToolbar, type TimelineEntryToolbarProps} from 'shells/timeline/entry/toolbar';
import {TimelineComments, type TimelineCommentsProps} from 'shells/timeline/entry/comments';
import type {Reactions} from 'shells/timeline/reaction-bar/types';
import {
    TimelineEntryFooter,
    type TimelineEntryFooterProps,
} from 'shells/timeline/entry/email-engagement-reaction-bar';

import {
    TimelineEntryContent,
    type Props as TimelineEntryContentProps,
} from './timeline-entry-content';

import './timeline-entry.css';

type Props = {|
    currentUserId?: string,
    changeLogId: string,
    // Content props
    ...TimelineEntryContentProps,
    // Toolbar props
    ...TimelineEntryToolbarProps,
    // Timeline footer bar props
    ...TimelineEntryFooterProps,
    // Timeline comment props
    ...TimelineCommentsProps,
    onToggleReaction?: (
        changeLogId: string,
        reactionType: ReactionType,
        filteredReactions: Reactions,
        parentChangeLogId?: string
    ) => any,
    filteredEntryReactions?: Reactions,
    // Email props
    customBorderProps?: {
        hasDottedBorder?: boolean,
        borderColor?: string,
    },
    disableComments?: boolean,
    disableReactions?: boolean,
    leftLineColor: string,
|};

export function TimelineEntryCard(props: Props) {
    const {
        changeLogId: entryChangeLogId,
        subject,
        subjectTitle,
        content,
        truncatedContent,
        status,
        filteredEntryReactions,
        comments,
        participants,
        relatedLeads,
        metadata,
        currentUserId,
        onToggleReaction,
        onAddComment,
        onDeleteComment,
        onClickOpen,
        customBorderProps,
        emailEngagement,
        zoomMeetingData,
        threadData,
        disableComments,
        disableReactions,
        actionMenu,
        additionalToolbarOptions,
        leftLineColor,
        isExpanded,
        zoomTranscriptSummary,
    } = props;

    const [shouldShowToolbar, setShouldShowToolbar] = React.useState<boolean>(false);
    const [isAddingComment, setIsAddingComment] = React.useState<boolean>(false);

    const onToggleEntryReaction =
        onToggleReaction && filteredEntryReactions
            ? (reactionType: ReactionType) => {
                  onToggleReaction(entryChangeLogId, reactionType, filteredEntryReactions);
              }
            : undefined;
    const onToggleCommentReaction = onToggleReaction
        ? (changeLogId: string, reactionType: ReactionType, commentReactions: Reactions) =>
              onToggleReaction(changeLogId, reactionType, commentReactions, entryChangeLogId)
        : undefined;

    return (
        <div
            styleName='timeline-entry-content'
            onMouseEnter={() => setShouldShowToolbar(true)}
            onMouseLeave={() => setShouldShowToolbar(false)}
        >
            <Card
                hasBorder={true}
                hasShadow={true}
                {...customBorderProps}
                leftLineColor={leftLineColor}
            >
                <TimelineEntryContent
                    changeLogEntryId={entryChangeLogId}
                    content={content}
                    truncatedContent={truncatedContent}
                    subject={subject}
                    subjectTitle={subjectTitle}
                    status={status}
                    participants={participants}
                    relatedLeads={relatedLeads}
                    metadata={metadata}
                    onClickOpen={onClickOpen}
                    isExpanded={isExpanded}
                    zoomTranscriptSummary={zoomTranscriptSummary}
                />
                <TimelineEntryFooter
                    disableReactions={disableReactions}
                    reactions={filteredEntryReactions}
                    currentUserId={currentUserId}
                    onToggleReaction={onToggleEntryReaction}
                    emailEngagement={emailEngagement}
                    zoomMeetingData={zoomMeetingData}
                    threadData={threadData}
                />
                <TimelineComments
                    comments={comments}
                    onToggleReaction={onToggleCommentReaction}
                    currentUserId={currentUserId}
                    onAddComment={onAddComment}
                    onDeleteComment={onDeleteComment}
                    isAddingComment={isAddingComment}
                    cancelAddingComment={() => setIsAddingComment(false)}
                />
            </Card>
            {shouldShowToolbar ? (
                <div styleName='toolbar-container'>
                    <TimelineEntryToolbar
                        onToggleReaction={onToggleEntryReaction}
                        handleComment={onAddComment ? () => setIsAddingComment(true) : undefined}
                        onClickOpen={onClickOpen}
                        disableComments={disableComments}
                        actionMenu={actionMenu}
                        additionalToolbarOptions={additionalToolbarOptions}
                        disableReactions={disableReactions}
                    />
                </div>
            ) : (
                undefined
            )}
        </div>
    );
}
