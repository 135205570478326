/* @flow */

import {createSelector} from 'reselect';
import {getContactsState} from './contacts-selectors';
import type {State as ContactsState} from './contacts-reducer';

// The root state will include more keys, but this is what we really care about here
type ContactsModuleState = {contacts: ContactsState};

/*
 * Get the `relatedLeads` portion of the contacts state
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getRelatedLeadsState = createSelector(
    [getContactsState],
    (contactsState) => contactsState.relatedLeads
);

/*
 * Retrieve a single contact's related lead ids, by passing a contactId as a second argument
 */
export const getRelatedLeadIds = (state: ContactsModuleState, contactId: string) => {
    if (!contactId) return [];
    const relatedLeadsState = getRelatedLeadsState(state)[contactId];

    return relatedLeadsState ? relatedLeadsState.items : [];
};

/*
 * Returns a boolean indicating whether leads are being fetched for a given contact
 */
export const getIsLoadingLeads = (state: ContactsModuleState, contactId: string): boolean => {
    const relatedLeadsState = getRelatedLeadsState(state)[contactId];

    return relatedLeadsState ? relatedLeadsState.isLoading : false;
};
