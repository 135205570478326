import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import DragHandle from './drag-handle-icon.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <DragHandle {...validProps} />;
}
SvgComponent.iconName = 'drag-handle';

export const DragHandleIcon = svgIconWrapper(SvgComponent);
