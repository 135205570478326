/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import {CaretUp, CaretDown} from '../icon';

import './select-new.css';

type SelectProps = {
    selectValues: any[], // Object, but we suck at flow
    value: any, // Object, {normally {id: string, name: string}
    valueKey?: string,
    labelKey?: string,
    disabled?: boolean,
    onChange?: Function,
    isInline?: boolean,
    isTiny?: boolean,
};

export class SelectNew extends React.Component<SelectProps> {
    static displayName = 'SelectNew';
    selectInputRef: ?HTMLElement;

    static defaultProps = {
        value: {},
        labelKey: 'name',
        valueKey: 'id',
    };

    render() {
        const mappedSelectOptions = this.props.selectValues.map((option) => {
            return (
                <option
                    disabled={option.disabled}
                    key={option[this.props.valueKey]}
                    label={option[this.props.labelKey]}
                    value={option[this.props.valueKey]}
                >
                    {option[this.props.labelKey]}
                </option>
            );
        });

        const containerStyle = classnames('container', {
            'container-disabled': this.props.disabled,
            'container--inline': this.props.isInline,
        });

        const selectStyle = classnames('select', {
            'select--tiny': this.props.isTiny,
        });

        const arrowUpStyle = classnames('arrowUp', {
            'arrowUp--tiny': this.props.isTiny,
        });

        const arrowDownStyle = classnames('arrowDown', {
            'arrowDown--tiny': this.props.isTiny,
        });

        const selectValue = this.props.value[this.props.valueKey] || '';

        return (
            <div styleName={containerStyle}>
                <div styleName='select-container'>
                    <select
                        styleName={selectStyle}
                        disabled={this.props.disabled}
                        value={selectValue}
                        ref={(node) => {
                            this.selectInputRef = node;
                        }}
                        onChange={this.handleChange}
                    >
                        {mappedSelectOptions}
                    </select>
                    <CaretUp styleName={arrowUpStyle} />
                    <CaretDown styleName={arrowDownStyle} />
                </div>
            </div>
        );
    }

    handleChange = (e: {target: {value: string}}) => {
        const newValue = this.props.selectValues.find((val) => {
            return String(val[this.props.valueKey]) === e.target.value;
        });

        if (typeof this.props.onChange === 'function') {
            this.props.onChange(newValue);
        }
    };
}
