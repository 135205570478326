/* @flow */

import type {EntityType} from 'nutshell-graphql-types';
import type {EntityFormType} from '../types';

export function translateEntityFormTypeToFieldEntityType(entityType: EntityFormType): EntityType {
    switch (entityType) {
        case 'contacts':
            return 'CONTACT';
        case 'accounts':
            return 'ACCOUNT';
        case 'leads':
        default:
            return 'LEAD';
    }
}
