/* @flow */

import * as React from 'react';

import {Divider} from '../../../layout';
import {Body} from '../../../typography';

// ThreadDataProps
export type Props = {|
    messageCount: number,
    replyCount: number,
|};

export const ThreadData = (props: Props) => {
    const {messageCount, replyCount} = props;

    return (
        <div className='flex'>
            {messageCount > 0 && (
                <Body size='small'>
                    {messageCount} {messageCount === 1 ? 'message' : 'messages'}
                </Body>
            )}
            {messageCount > 0 && replyCount > 0 && <Divider variant='line--vertical' spacing={8} />}
            {replyCount > 0 && (
                <Body size='small'>
                    {replyCount} {replyCount === 1 ? 'reply' : 'replies'}
                </Body>
            )}
        </div>
    );
};
