/* @flow */

import startsWith from 'underscore.string/startsWith';
import endsWith from 'underscore.string/endsWith';
import {isRange} from './utils/period-checks';

type StringOrNumberReturn = {|
    selected: string,
    data?: Array<string> | string,
|};

export function deserializeStringOrNumber(value: string): StringOrNumberReturn {
    if (value === '*') return {selected: 'any'};
    else if (value === '-') return {selected: 'null'};
    else if (startsWith(value, '>')) {
        return {
            selected: 'Greater than',
            data: value.slice(1),
        };
    } else if (startsWith(value, '<')) {
        return {
            selected: 'Less than',
            data: value.slice(1),
        };
    } else if (isWrappedInQuotes(value)) {
        return {
            selected: 'Equals',
            data: value.replace(/^"(.*)"$/, '$1'),
        };
    } else if (isRange(value)) {
        const splitStr = value.split(' ');

        return {
            selected: 'Between',
            data: [splitStr[0], splitStr[2]],
        };
    } else {
        return {
            selected: typeof value === 'number' ? 'Equals' : 'Contains',
            data: value,
        };
    }
}

function isWrappedInQuotes(str) {
    return startsWith(str, '"') && endsWith(str, '"');
}
