/* @flow */

import * as React from 'react';

type Props = {
    type: 'info' | 'warning' | 'success' | 'error',
    size: 'small' | 'medium', // Optional
};

export class NotificationIcon extends React.Component<Props> {
    static defaultProps = {
        size: 'medium',
    };

    render() {
        const {type, size} = this.props;
        const classes = `ui-notification-icon ui-notification-icon--${type} ui-notification-icon--${size}`;

        return <div className={classes} />;
    }
}
