import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import AlarmClock from './icon-alarm-clock.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <AlarmClock {...validProps} />;
}
SvgComponent.iconName = 'alarm-clock';

export const AlarmClockIcon = svgIconWrapper(SvgComponent);
