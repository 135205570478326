/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';
import {TextField} from 'shells/textfield';

type Props = FieldProps;

export function ReduxFormTextField(props: Props) {
    const {input, ...restProps} = props;
    const {name, value, onBlur, onChange, onFocus} = input;

    return (
        <TextField
            {...restProps}
            name={name}
            value={value}
            onBlur={onBlur}
            onChange={(newVal) => {
                onChange(newVal);
            }}
            onFocus={(e: any) => {
                onFocus(e);
            }}
        />
    );
}
