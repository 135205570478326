/* @flow */

export const ActionTypes = {
    SIDEBAR_CLOSED: 'SIDEBAR_CLOSED',
    SIDEBAR_OPENED: 'SIDEBAR_OPENED',
    GLOSSARY_BUTTON_TOGGLE: 'GLOSSARY_BUTTON_TOGGLE',
};

type SidebarClosedAction = {type: 'SIDEBAR_CLOSED'};
type SidebarOpenedAction = {
    type: 'SIDEBAR_OPENED',
    payload: {
        glossaryKey: string,
    },
};
type GlossaryButtonToggleAction = {
    type: 'GLOSSARY_BUTTON_TOGGLE',
    payload: {
        isExpanded: boolean,
    },
};

export type GlossaryAction = SidebarOpenedAction | SidebarClosedAction | GlossaryButtonToggleAction;

/*
 * Action creators
 */
export const closeSidebar = (): SidebarClosedAction => ({
    type: ActionTypes.SIDEBAR_CLOSED,
});

export const openSidebar = (glossaryKey: string): SidebarOpenedAction => ({
    type: ActionTypes.SIDEBAR_OPENED,
    payload: {
        glossaryKey,
    },
});

export const toggleExpandButton = (isExpanded: boolean): GlossaryButtonToggleAction => ({
    type: ActionTypes.GLOSSARY_BUTTON_TOGGLE,
    payload: {
        isExpanded,
    },
});

// Expands the glossary button for 2 seconds, then collapses it
export function expandButtonWithTimeout() {
    return function(dispatch: Function) {
        dispatch(toggleExpandButton(true));

        setTimeout(() => {
            dispatch(toggleExpandButton(false));
        }, 2000);
    };
}
