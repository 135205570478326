import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Zoom from './icon-zoom.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Zoom {...validProps} />;
}
SvgComponent.iconName = 'zoom';

export const ZoomIcon = svgIconWrapper(SvgComponent);
