/* @flow */

import * as React from 'react';

import {type TimelineEntryIconVariant} from '../../gutter/types';
import {TimelineEntryIcon} from '../../gutter/timeline-entry-icon';
import {TimelineHeader, type TimelineHeaderProps} from '../header';

import './timeline-modal-header.css';

type Props = {|
    iconVariant: TimelineEntryIconVariant,
    ...TimelineHeaderProps,
|};

export function TimelineModalHeader(props: Props) {
    const {iconVariant, ...timelineHeaderProps} = props;

    return (
        <div styleName='timeline-modal-header'>
            <TimelineHeader {...timelineHeaderProps} showEmailAddressTooltip={true} />
            <TimelineEntryIcon variant={iconVariant} />
        </div>
    );
}
