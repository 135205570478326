import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 88 72.3'}>
            <path
                className='surface'
                d='M32.7,72.3c-0.7,0-1.4-0.3-1.8-0.8L0.8,41.3c-1-1-1-2.7,0-3.7l0,0L15,23.4c1-1,2.7-1,3.7,0l14,14 L69.3,0.7c1-1,2.7-1,3.7,0L87.2,15c1,1,1,2.7,0,3.7l0,0L34.5,71.5C34.1,72,33.4,72.3,32.7,72.3z'
            />
            <path
                className='crack'
                d='M57.2,28.4l1.9-6.5L56,21.8l0.2-4.5L54,16.5l-1.2,7.7l3,0.1l-1.6,5.3l2.8,1.5l-2.6,4.7 c-0.2,0.3-0.2,0.7-0.1,1c0.2,0.5,0.7,0.8,1.2,0.6h0c0.4,0,0.9-0.2,1.1-0.5l3.8-6.9L57.2,28.4z'
            />
            <path
                className='border'
                d='M32.7,72.3c-0.7,0-1.4-0.3-1.8-0.8L0.8,41.3c-1-1-1-2.7,0-3.7l0,0L15,23.4c1-1,2.7-1,3.7,0l14,14 L69.3,0.7c1-1,2.7-1,3.7,0L87.2,15c1,1,1,2.7,0,3.7l0,0L34.5,71.5C34.1,72,33.4,72.3,32.7,72.3z M3.2,39.5l29.5,29.6l52.1-52.2 L71.1,3.1L34.5,39.8c-1,1-2.7,1-3.7,0c0,0,0,0,0,0l0,0l-14-14L3.2,39.5z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'todo-error-state-icon';

export const TodoErrorStateIcon = svgIconWrapper(SvgComponent);
