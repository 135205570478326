/* @flow */

import * as React from 'react';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import type {
    Task as TaskFragment,
    GetTask as GetTaskQuery,
    GetTaskVariables as GetTaskQueryVariables,
} from 'nutshell-graphql-types';
import {makeDataSafe} from 'nutshell-core/utils/graphql-errors';
import {TaskForm} from '../entity-form/forms/task-form';
import {EntityFormLoadingScreen} from '../entity-form/loading-screen';

type OwnProps = {|
    form: string, // required and used by redux-form
    taskId: ?string, // eslint-disable-line react/no-unused-prop-types
    isReadOnly: boolean,
    onCancelClick: () => any,
    onSaveCallback: (entity: Object, jumpToUrl: boolean) => any,
|};

type GraphQLProps = {|
    isLoading: boolean,
    task: ?TaskFragment,
    refetchTask: () => any,
|};

type Props = {...OwnProps, ...GraphQLProps};

class TaskFormWrapperComponent extends React.PureComponent<Props> {
    render() {
        if (this.props.isLoading) {
            return <EntityFormLoadingScreen />;
        }

        return (
            <TaskForm
                form={this.props.form}
                isReadOnly={this.props.isReadOnly}
                task={this.props.task}
                onUpdateTask={this.props.refetchTask}
                onCancelClick={this.props.onCancelClick}
                onSaveCallback={this.handleSaveCallback}
            />
        );
    }

    handleSaveCallback = (entity: Object, jumpToUrl: boolean) => {
        this.props.onSaveCallback(entity, jumpToUrl);

        // Refetch our local task
        if (this.props.task) {
            this.props.refetchTask();
        }
    };
}

// Task properties we need specifically to display this form
const TASK = gql`
    fragment Task on Task {
        id
        type
        href
        title
        description
        dueTime
        assignee {
            id
            type
            name
        }
        createdTime
        completedTime
        deletedTime
        relatedEntity {
            id
            name
            ... on Lead {
                type
                htmlUrl
            }
            ... on Assignable {
                type
            }
            ... on Contact {
                type
                htmlUrl
            }
            ... on Account {
                type
                htmlUrl
            }
        }
    }
`;

// Basic task query to get task and base related entity info
const GET_TASK = gql`
    query GetTask($taskId: ID!) {
        task(id: $taskId) {
            ...Task
        }
    }
    ${TASK}
`;

class TaskFormQuery extends Query<GetTaskQuery, GetTaskQueryVariables> {}

export const TaskFormWrapper = (props: OwnProps) => (
    <TaskFormQuery
        query={GET_TASK}
        skip={!props.taskId}
        variables={{taskId: props.taskId || ''}}
        fetchPolicy={'network-only'}
    >
        {({data, loading, refetch}) => {
            const {task} = makeDataSafe(data);

            return (
                <TaskFormWrapperComponent
                    {...props}
                    task={task}
                    isLoading={loading}
                    refetchTask={refetch}
                />
            );
        }}
    </TaskFormQuery>
);
