/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {EntityPillSelectWrapperSmall} from 'shells/entity-pill';

import {GraphQLSelectAccounts} from '../../select/graphql-select-accounts';

type Props = {
    ...FieldProps,
    isMulti: boolean,
    isReadOnly?: boolean,
};

export function AccountsSelect(props: Props) {
    if (props.isReadOnly) {
        return (
            <EntityPillSelectWrapperSmall
                type='accounts'
                value={{id: 'none', name: 'Company name'}}
            />
        );
    }

    return (
        <GraphQLSelectAccounts
            isMulti={props.isMulti}
            onChange={(newAccounts) => {
                props.input.onChange(newAccounts);
            }}
            onClearValue={() => {
                props.input.onChange(null);
            }}
            selectedValues={props.input.value || []}
            clearable={!props.isMulti}
        />
    );
}
