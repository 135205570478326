/* @flow */

// Action Types
export type ToggleSidebarDismissAction = {
    type: 'TOGGLE_SIDEBAR_DISMISSED',
    payload: {
        isOnboardingSidebarDismissed: boolean,
    },
};

export const ActionTypes = {
    TOGGLE_SIDEBAR_DISMISSED: 'TOGGLE_SIDEBAR_DISMISSED',
};

// Actions
export const toggleSidebarDismissed = (
    isOnboardingSidebarDismissed: boolean
): ToggleSidebarDismissAction => ({
    type: ActionTypes.TOGGLE_SIDEBAR_DISMISSED,
    payload: {
        isOnboardingSidebarDismissed,
    },
});
