/* @flow */

import {unexpectedCase} from 'nutshell-core/reducer-utils';
import type {LimitedType} from 'nutshell-graphql-types';

import {ActionTypes, type LimitAction} from './limits-actions';

export type LimitsState = {
    type: ?LimitedType,
    isAllLimitsModalOpen: boolean,
    allLimitsModalShouldLinkToBilling: boolean,
    allLimitsModalTitleText?: string,
};

const defaultLimitsState = {
    type: null,
    isAllLimitsModalOpen: false,
    allLimitsModalShouldLinkToBilling: true,
};

export const limitsReducer = (
    state: LimitsState = defaultLimitsState,
    action: LimitAction
): LimitsState => {
    switch (action.type) {
        case ActionTypes.LIMIT_MODAL_TOGGLED:
            return {
                ...state,
                type: action.payload,
            };
        case ActionTypes.ALL_LIMITS_MODAL_TOGGLED:
            return {
                ...state,
                isAllLimitsModalOpen: action.payload.isAllLimitsModalOpen,
                allLimitsModalShouldLinkToBilling: action.payload.allLimitsModalShouldLinkToBilling,
                allLimitsModalTitleText: action.payload.allLimitsModalTitleText,
            };
        default:
            unexpectedCase(action.type);

            return state;
    }
};
