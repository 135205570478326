/* @flow */

import * as ramda from 'ramda';

import type {LeadStage, ListApiResponseLeads, FormattedValue} from '../types';
import {addNumbersWithMetricPrefix, subtractNumbersWithMetricPrefix} from '../utils';

import {STAGES_UPDATED} from '../stages/stages-actions';
import type {StagesAction} from '../stages';
import type {
    LeadClosingConfirmation,
    StageTransition,
    VisualPipelineAction,
    VisualPipelineColumnState,
} from './visual-pipeline-types';
import {ActionTypes} from './visual-pipeline-constants';

export type VisualPipelineState = {
    cardIdBeingDragged: ?string,
    itemsByStage: {
        [stageId: string]: VisualPipelineColumnState,
    },
    activeStageTransition: StageTransition,
    activeLeadClosingConfirmation: LeadClosingConfirmation,
    errorMessage?: string,
};

const visualPipelineDefaultState: VisualPipelineState = {
    itemsByStage: {},
    cardIdBeingDragged: null,
    activeStageTransition: {id: null, toStage: null, fromStage: null},
    activeLeadClosingConfirmation: {id: null, closeType: null},
    errorMessage: undefined,
};

const visualPipelineColumnDefaultState: VisualPipelineColumnState = {
    isRequesting: false,
    items: [],
    errorMessage: undefined,
    count: 0,
    value: {
        value: 0,
        formatted: '0',
        prefix: '',
        suffix: '',
    },
    pagesRequested: 0,
};

type Action = StagesAction | VisualPipelineAction;
export const reducer = (
    state: VisualPipelineState = visualPipelineDefaultState,
    action: Action
): VisualPipelineState => {
    switch (action.type) {
        case STAGES_UPDATED: {
            const newItems = action.payload.reduce(
                (newState, stage: LeadStage) => {
                    newState[stage.id] = {
                        ...visualPipelineColumnDefaultState,
                        ...state.itemsByStage[stage.id],
                    };

                    return newState;
                },
                {...state.itemsByStage}
            );

            // $NutshellClientFlowIgnore upgrading Flow to v0.92.1
            return {...state, itemsByStage: newItems};
        }
        case ActionTypes.VISUAL_PIPELINE_LIST_ITEM_REMOVED_BY_ID: {
            const removedId = action.payload.links.entity.id;
            const formattedValue = action.payload.fields.value.formattedValue;
            const newItems = ramda.map(
                (stageState) => removeListItemById(stageState, removedId, formattedValue),
                state.itemsByStage
            );

            return {...state, itemsByStage: newItems};
        }
        case ActionTypes.VISUAL_PIPELINE_LIST_ITEMS_REQUESTED_FOR_STAGE: {
            const stageId = action.payload;
            const newItems = {
                ...state.itemsByStage,
                [stageId]: setColumnToRequesting(state.itemsByStage[stageId]),
            };

            return {...state, itemsByStage: newItems};
        }
        case ActionTypes.VISUAL_PIPELINE_LIST_ITEMS_REPLACED_FOR_STAGE: {
            const stageId: string = action.payload.stageId;
            const response: ListApiResponseLeads = action.payload.response;

            const newItems = {
                ...state.itemsByStage,
                [stageId]: replaceListItemsInColumn(state.itemsByStage[stageId], response),
            };

            return {...state, itemsByStage: newItems};
        }
        case ActionTypes.VISUAL_PIPELINE_LIST_ITEMS_ADDED_FOR_STAGE: {
            const stageId = action.payload.stageId;
            const response = action.payload.response;
            const newItems = {
                ...state.itemsByStage,
                [stageId]: addListItemsToColumn(state.itemsByStage[stageId], response),
            };

            return {...state, itemsByStage: newItems};
        }
        case ActionTypes.VISUAL_PIPELINE_LIST_ITEMS_FAILED_FOR_STAGE: {
            const stageId = action.payload;
            const newItems = {
                ...state.itemsByStage,
                [stageId]: markColumnAsErrored(state.itemsByStage[stageId]),
            };

            return {...state, itemsByStage: newItems};
        }
        case ActionTypes.VISUAL_PIPELINE_LEAD_STAGE_TRANSITION_UPDATED: {
            return {
                ...state,
                activeStageTransition: {
                    id: action.payload.leadId,
                    toStage: action.payload.newStageId,
                    fromStage: action.payload.oldStageId,
                },
            };
        }
        case ActionTypes.VISUAL_PIPELINE_LEAD_STAGE_TRANSITION_COMPLETED: {
            const {id, toStage, fromStage} = state.activeStageTransition;
            if (!id || !toStage || !fromStage) {
                return state;
            }

            const entityId = action.payload.links.entity.id;
            const entityValue = action.payload.fields.value.formattedValue;

            const newItems = {
                ...state.itemsByStage,
                [toStage]: addListItemToColumn(state.itemsByStage[toStage], entityId, entityValue),
                [fromStage]: removeListItemById(
                    state.itemsByStage[fromStage],
                    entityId,
                    entityValue
                ),
            };

            return {
                ...state,
                itemsByStage: newItems,
                activeStageTransition: {
                    id: null,
                    toStage: null,
                    fromStage: null,
                },
            };
        }
        case ActionTypes.VISUAL_PIPELINE_CARD_IS_DRAGGING_TOGGLED: {
            return {
                ...state,
                cardIdBeingDragged:
                    action.payload === state.cardIdBeingDragged ? null : action.payload,
            };
        }
        case ActionTypes.VISUAL_PIPELINE_LEAD_CLOSING_CONFIRMATION_UPDATED: {
            return {
                ...state,
                activeLeadClosingConfirmation: {
                    id: action.payload.leadId,
                    closeType: action.payload.closeType,
                },
            };
        }
        default:
            return state;
    }
};

/**
 * Removes a list item and its value from a column
 *
 * @param  {Object} [priorState]   An existing column state
 * @param  {string} removedId      The entity api id being removed
 * @param  {Object} formattedValue The value of the list item being removed
 * @return {Object}                The new column state
 */
export const removeListItemById = (
    priorState: VisualPipelineColumnState = visualPipelineColumnDefaultState,
    removedId: string,
    formattedValue: FormattedValue
): VisualPipelineColumnState => {
    // If the listItem doesn't exist, don't try to manipuate any of the
    // counts, values, etc.
    if (!priorState.items.includes(removedId)) return priorState;

    return {
        ...priorState,
        items: priorState.items.filter((leadId) => leadId !== removedId),
        count: priorState.count - 1,
        value: subtractNumbersWithMetricPrefix(priorState.value, formattedValue),
    };
};

/**
 * Adds a list item and its value to a column.  We add in the value in the client
 * so that we can immediately update the column total value without waiting for
 * a solr index on the server (and trying to guess how long that's going to take
 * before requesting the new value).
 *
 * @param  {Object} [priorState]   An existing column state
 * @param  {string} addedId        The entity api id being added
 * @param  {Object} formattedValue The value of the list item being added
 * @return {Object}                The new column state
 */
export const addListItemToColumn = (
    priorState: VisualPipelineColumnState = visualPipelineColumnDefaultState,
    addedId: string,
    formattedValue: FormattedValue
): VisualPipelineColumnState => ({
    ...priorState,
    items: [addedId, ...priorState.items],
    count: priorState.count + 1,
    value: addNumbersWithMetricPrefix(priorState.value, formattedValue),
});

/**
 * Sets `isRequesting` of a column to true
 *
 * @param  {Object} [priorState] Existing state of the column
 * @return {Object}              Updated state of the column with `isRequesting` true
 */
export const setColumnToRequesting = (
    priorState: VisualPipelineColumnState = visualPipelineColumnDefaultState
): VisualPipelineColumnState => ({...priorState, isRequesting: true});

/**
 * Sets an error message on a column
 *
 * @param  {Object} [priorState] Existing state of the column
 * @return {Object}              Updated state of the column with `isRequesting` true
 */
export const markColumnAsErrored = (
    priorState: VisualPipelineColumnState = visualPipelineColumnDefaultState
): VisualPipelineColumnState => ({
    ...priorState,
    isRequesting: false,
    errorMessage: 'Error message!',
});

/**
 * Replaces all of the items in a column with new items from an api response.
 * Also sets isRequesting to false, clears out any error messages, and updates
 * the column summary data.
 *
 * @param  {Object}  priorState                   Existing state of the column
 * @param  {Object}  leadsListItemsResponse       A response from the leads list api
 * @return {Object}                               Updated state of the column
 */
export const replaceListItemsInColumn = (
    priorState: VisualPipelineColumnState = visualPipelineColumnDefaultState,
    leadsListItemsResponse: ListApiResponseLeads
): VisualPipelineColumnState => ({
    ...priorState,
    isRequesting: false,
    errorMessage: undefined,
    items: leadsListItemsResponse.listItems.map((listItem) => {
        return listItem.links.entity.id;
    }),
    value: leadsListItemsResponse.meta.stats.value.sum,
    count: leadsListItemsResponse.meta.count,
    total: leadsListItemsResponse.meta.total,
});

/**
 * Adds new items from an api response into a column.
 *
 * We use this when we are dealing with pagination, and we can't just replace
 * all of the items, because they're not all in the response.
 *
 * Also sets isRequesting to false, clears out any error messages, and updates
 * the column summary data (we treat api response as source of truth, so we don't
 * try to add the values to the existing values).
 *
 * @param  {Object}  priorState                   Existing state of the column
 * @param  {Object}  leadsListItemsResponse       A response from the leads list api
 * @return {Object}                               Updated state of the column
 */
export const addListItemsToColumn = (
    priorState: VisualPipelineColumnState = visualPipelineColumnDefaultState,
    leadsListItemsResponse: ListApiResponseLeads
): VisualPipelineColumnState => {
    const newListItems = leadsListItemsResponse.listItems;
    const newListItemIds = newListItems.map((listItem) => listItem.links.entity.id);

    return {
        ...priorState,
        isRequesting: false,
        errorMessage: undefined,
        items: priorState.items.concat(newListItemIds),
        value: leadsListItemsResponse.meta.stats.value.sum,
        count: leadsListItemsResponse.meta.count,
        total: leadsListItemsResponse.meta.total,
    };
};
