import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} xmlns='http://www.w3.org/2000/svg' viewBox={'0 0 30 24'}>
            <path d='M22.5,17l1.9,2.5A12.16,12.16,0,0,1,15,24,12,12,0,0,1,3,12a3.4,3.4,0,0,1,.1-1H0L4.5,5,9,11H6.1A3.4,3.4,0,0,0,6,12a9,9,0,0,0,9,9,9.06,9.06,0,0,0,7.5-4Zm4.4-6A12,12,0,0,0,15,0,12.26,12.26,0,0,0,6.4,3.6L8.3,6A8.63,8.63,0,0,1,15,3a9,9,0,0,1,8.9,8H21l4.5,6L30,11Z' />
        </svg>
    );
}
SvgComponent.iconName = 'recurring';

export const RecurringIcon = svgIconWrapper(SvgComponent);
