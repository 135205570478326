/* @flow */
import {createAction} from 'redux-act';

import type {FilterObject} from 'nutshell-core/types';

export type SiteAnalyticsReportDataRequestedAction = {
    type: 'SITE_ANALYTICS_REPORT_DATA_REQUESTED',
    payload: {
        reportParams: Object,
        filters: FilterObject[],
    },
};

// Site analytics report
export const requestSiteAnalyticsReportData: (
    payload: $PropertyType<SiteAnalyticsReportDataRequestedAction, 'payload'>
) => SiteAnalyticsReportDataRequestedAction = createAction('SITE_ANALYTICS_REPORT_DATA_REQUESTED');
export const updateSiteAnalyticsReportTableData = createAction(
    'SITE_ANALYTICS_REPORT_TABLE_DATA_UPDATED'
);
export const updateSiteAnalyticsReportChartData = createAction(
    'SITE_ANALYTICS_REPORT_CHART_DATA_UPDATED'
);
export const updateAnalyticsSummaryStats = createAction(
    'SITE_ANALYTICS_REPORT_SUMMARY_DATA_UPDATED'
);
export const updateSiteAnalyticsReportMaps = createAction('SITE_ANALYTICS_REPORT_MAPS_UPDATED');
export const failSiteAnalyticsReportChartData = createAction(
    'SITE_ANALYTICS_REPORT_CHART_DATA_FAILED'
);
