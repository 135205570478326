/* @flow */

import * as React from 'react';
import './marketing-body.css';

type Props = {
    children?: React.Node,
    as: string,
};

MarketingBody.defaultProps = {
    as: 'p',
};

export function MarketingBody({children, as: Tag}: Props) {
    return <Tag styleName='body'>{children}</Tag>;
}
