/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import CompanyContact from './icon-company-contact.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <CompanyContact {...validProps} />;
}

SvgComponent.iconName = 'company-contact';

export const CompanyContactIcon = svgIconWrapper(SvgComponent);
