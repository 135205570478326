import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import HtmlItalic from './icon-html-italic.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <HtmlItalic {...validProps} />;
}
SvgComponent.iconName = 'html-italic';

export const HtmlItalicIcon = svgIconWrapper(SvgComponent);
