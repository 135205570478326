import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Unpin from './unpin.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Unpin {...validProps} />;
}
SvgComponent.iconName = 'unpin';

export const UnpinIcon = svgIconWrapper(SvgComponent);
