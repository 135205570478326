/* @flow */

/**
 * To add a new OpenableModal:
 *  1. Add a const and type to the individual openable modal ids and types
 *  2. Add the new const to the all openable modals const
 *  3. Add the new type to the all openable modals type
 *  4. Add the modal component to OpenableModalsWrapper
 *
 * (please try to add the new modal in alphabetical order)
 */

/*
 * Individual openable modal ids and types
 */
export const ADTECH_FX_INTEREST_MODAL = 'adtech_fx_interest_modal';
export type AdTechFXInterestModal = 'adtech_fx_interest_modal';
export const ANALYTICS_ONBOARDING_MODAL = 'analytics_onboarding_modal';
export type AnalyticsOnboardingModal = 'analytics_onboarding_modal';
export const ATTRIBUTION_ONBOARDING_MODAL = 'attribution_onboarding_modal';
export type AttributionOnboardingModal = 'attribution_onboarding_modal';
export const BROADCAST_INFO_MODAL = 'broadcast_info_modal';
export type BroadcastInfoModal = 'broadcast_info_modal';
export const CAMPAIGNS_ONBOARDING_MODAL = 'campaigns_onboarding_modal';
export type CampaignsOnboardingModal = 'campaigns_onboarding_modal';
export const COMPETITOR_IQ_INFO_MODAL = 'competitor_iq_info_modal';
export type CompetitorIqInfoModal = 'competitor_iq_info_modal';
export const CONNECTED_FORMS_INFO_MODAL = 'connected_forms_info_modal';
export type ConnectedFormsInfoModal = 'connected_forms_info_modal';
export const DESIGN_PIPELINE_WIZARD = 'design_pipeline_wizard';
export type DesignPipelineWizard = 'design_pipeline_wizard';
export const DRIP_SEQUENCE_INFO_MODAL = 'drip_sequence_info_modal';
export type DripSequenceInfoModal = 'drip_sequence_info_modal';
export const EMBED_ANALYTICS_MODAL = 'embed_analytics_modal';
export type EmbedAnalyticsModal = 'embed_analytics_modal';
export const EMBED_CHAT_MODAL = 'embed_chat_modal';
export type EmbedChatModal = 'embed_chat_modal';
export const FORMS_INFO_MODAL = 'forms_info_modal';
export type FormsInfoModal = 'forms_info_modal';
export const IFRAME_MODAL = 'iframe_modal';
export type IframeModal = 'iframe_modal';
export const IMPORT_MODAL = 'import_modal';
export type ImportModal = 'import_modal';
export const SMS_SIDEBAR = 'sms_sidebar';
export type SmsSidebar = 'sms_sidebar';
export const CHAT_SIDEBAR = 'chat_sidebar';
export type ChatSidebar = 'chat_sidebar';
export const INVITE_USERS_MODAL = 'invite_users_modal';
export type InviteUsersModal = 'invite_users_modal';
export const LANDING_PAGES_INFO_MODAL = 'landing_pages_info_modal';
export type LandingPagesInfoModal = 'landing_pages_info_modal';
export const NEWSLETTER_INFO_MODAL = 'newsletter_info_modal';
export type NewsletterInfoModal = 'newsletter_info_modal';
export const SCHEDULER_ONBOARDING_MODAL = 'scheduler_onboarding_modal';
export type SchedulerOnboardingModal = 'scheduler_onboarding_modal';
export const VISITOR_IQ_ONBOARDING_MODAL = 'visitor_iq_onboarding_modal';
export type VisitorIqOnboardingModal = 'visitor_iq_onboarding_modal';
export const YOUTUBE_MODAL = 'youtube_modal';
export type YouTubeModal = 'youtube_modal';

/*
 * All openable modals const
 */
export const OpenableModals = {
    ADTECH_FX_INTEREST_MODAL,
    ANALYTICS_ONBOARDING_MODAL,
    ATTRIBUTION_ONBOARDING_MODAL,
    BROADCAST_INFO_MODAL,
    CAMPAIGNS_ONBOARDING_MODAL,
    COMPETITOR_IQ_INFO_MODAL,
    CONNECTED_FORMS_INFO_MODAL,
    DESIGN_PIPELINE_WIZARD,
    DRIP_SEQUENCE_INFO_MODAL,
    EMBED_ANALYTICS_MODAL,
    EMBED_CHAT_MODAL,
    FORMS_INFO_MODAL,
    IFRAME_MODAL,
    IMPORT_MODAL,
    SMS_SIDEBAR,
    CHAT_SIDEBAR,
    INVITE_USERS_MODAL,
    LANDING_PAGES_INFO_MODAL,
    NEWSLETTER_INFO_MODAL,
    SCHEDULER_ONBOARDING_MODAL,
    VISITOR_IQ_ONBOARDING_MODAL,
    YOUTUBE_MODAL,
};

/*
 * All openable modals type
 */
export type OpenableModalId =
    | AdTechFXInterestModal
    | AnalyticsOnboardingModal
    | AttributionOnboardingModal
    | BroadcastInfoModal
    | CampaignsOnboardingModal
    | CompetitorIqInfoModal
    | ConnectedFormsInfoModal
    | DesignPipelineWizard
    | DripSequenceInfoModal
    | EmbedAnalyticsModal
    | EmbedChatModal
    | FormsInfoModal
    | IframeModal
    | ImportModal
    | SmsSidebar
    | ChatSidebar
    | InviteUsersModal
    | LandingPagesInfoModal
    | NewsletterInfoModal
    | SchedulerOnboardingModal
    | VisitorIqOnboardingModal
    | YouTubeModal;

export type OpenableModal = {id: OpenableModalId, options?: Object};
