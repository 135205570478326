import PropTypes from 'prop-types';
import * as React from 'react';

import moment from 'moment';
import _ from 'underscore';

import {Popover} from '../popover';
import {RegularLookingTextField} from '../floating-label-textfield/regular-looking-textfield';
import {DateTimePicker} from './date-time-picker';

import styles from './date-time-input.css';

const DATE_FORMAT = 'dddd MMMM Do YYYY';
const TIME_FORMAT = 'h:mm A';

export class DateTimeInputTextfield extends React.Component {
    static propTypes = {
        displayFormat: PropTypes.string,
        disabled: PropTypes.bool,
        isValid: PropTypes.bool,
        name: PropTypes.string.isRequired,
        value: PropTypes.any, // This can be Moment type, which is not supported by PropTypes
        placeholder: PropTypes.string,
        defaultDate: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        shouldOmitTime: PropTypes.bool,
        persistOnSelect: PropTypes.bool,
    };

    static defaultProps = {
        displayFormat: `${DATE_FORMAT} [at] ${TIME_FORMAT}`,
        defaultDate: () => new Date(),
        isValid: true,
    };

    constructor(props) {
        super(props);

        let {value} = props;
        if (_.isNumber(value)) {
            value = moment.unix(value);
        } else if (_.isString(value) && !_.isEmpty(value)) {
            value = moment(value);
        }

        this.state = {
            isDatePickerOpen: false,
            value: this.getValue(value),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            this.props.value !== nextProps.value ||
            this.props.displayFormat !== nextProps.displayFormat
        ) {
            _.defer(() => this.setState({value: this.getValue(nextProps.value)}));
        }
    }

    render() {
        return (
            <div>
                <RegularLookingTextField
                    {...this.props}
                    autoComplete='off'
                    persistActionsOnBlur={true}
                    textFieldRef={(node) => {
                        this.textFieldRef = node;
                    }}
                    showActions={false}
                    value={this.state.value}
                    onBlur={this.handleBlur}
                    onCancel={() => this.handleSelectDate(null)}
                    onChange={(val) => this.setState({value: val})}
                    onConfirm={() =>
                        this.datePickerRef.selectDate({persist: false, allowEmpty: true})
                    }
                    onFocus={() => this.setState({isDatePickerOpen: true})}
                    onKeyDown={this.handleKeyDown}
                    placeholder={this.props.placeholder}
                />

                {this.renderDatePickerMenu()}
            </div>
        );
    }

    renderDatePickerMenu = () => {
        if (!this.state.isDatePickerOpen) return null;

        return (
            <Popover
                anchor={this.textFieldRef}
                className={styles.popover}
                location='custom'
                noDefaultStyling={true}
                disableOverlayClickToBlur={true}
                disableFocusTrapZone={true}
            >
                <div onMouseDown={this.handleMouseDownDatePicker}>
                    <DateTimePicker
                        ref={(c) => {
                            this.datePickerRef = c;
                        }}
                        value={this.state.value || this.getFormattedDate(this.props.defaultDate())}
                        onSelectDate={this.handleSelectDate}
                        hasYearPicker={false}
                        shouldOmitTime={this.props.shouldOmitTime}
                        persistOnSelect={this.props.persistOnSelect}
                    />
                </div>
            </Popover>
        );
    };

    getValue = (value) => {
        return value && !_.isString(value) ? this.getFormattedDate(value) : null;
    };

    handleSelectDate = (date, persistDatePicker) => {
        this.setState({
            isDatePickerOpen: Boolean(persistDatePicker),
            value: date ? this.getFormattedDate(date) : '',
        });

        this.textFieldRef.focus();
        this.props.onChange(date);
    };

    getFormattedDate = (date) => {
        return moment(date).format(this.props.displayFormat);
    };

    KEYS = [Nut.keys.ESC, Nut.keys.RETURN];

    handleKeyDown = (e) => {
        if (this.KEYS.includes(e.which) && !e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();

            if (this.datePickerRef) this.datePickerRef.selectDate();
        }
    };

    persistDatePickerOnInputBlur = false;
    handleMouseDownDatePicker = () => {
        this.persistDatePickerOnInputBlur = true;
        // Focus after the current call stack has cleared to better guarantee a
        // focus will occur. otherwise it is intermittent and breaks the UI.
        _.defer(this.focus);
    };

    handleBlur = () => {
        this.setState({isDatePickerOpen: this.persistDatePickerOnInputBlur});
        this.persistDatePickerOnInputBlur = false;
    };

    focus = () => {
        if (this.textFieldRef) {
            this.textFieldRef.focus();
        }
    };
}
