/* @flow */

import * as React from 'react';
import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';

type Props = {
    fill?: string,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} fill={props.fill || colors.grey} viewBox='0 0 22 22'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11 19a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm5.436-8a5.436 5.436 0 1 1-10.872 0 5.436 5.436 0 0 1 10.872 0Zm1.23 0a6.667 6.667 0 1 1-13.333 0 6.667 6.667 0 0 1 13.333 0Z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'radio';

export const RadioIcon = svgIconWrapper(SvgComponent);
