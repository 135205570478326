/* @flow */
import {useQuery} from '@apollo/react-hooks';

import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';
import type {GetUnlockInfo as GetUnlockInfoQuery} from 'nutshell-graphql-types';

import GET_UNLOCK_INFO from '../queries/get-unlock-info.graphql';

export type UnlockInfo = {
    unlockLimit: number,
    unlockedThisMonth: number,
    unlocksRemaining: number,
};

export const useGetUnlockInfo = (): {
    isLoading: boolean,
    error: ?string,
    unlockInfo: ?UnlockInfo,
} => {
    const {data, loading: isLoading, error} = useQuery<GetUnlockInfoQuery, {}>(GET_UNLOCK_INFO, {});

    let unlockInfo;
    if (data && data.unlockInfo) {
        unlockInfo = {
            unlockLimit: data.unlockInfo.unlockLimit,
            unlockedThisMonth: data.unlockInfo.unlockedThisMonth,
            unlocksRemaining: data.unlockInfo.unlockLimit - data.unlockInfo.unlockedThisMonth,
        };
    } else {
        unlockInfo = null;
    }

    return {
        isLoading,
        error: safelyGetGraphQLErrorMessage(error),
        unlockInfo,
    };
};
