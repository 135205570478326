/* @flow */

import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {analyticsClient} from '../../../../analytics-client';
import type {NutshellState} from '../../../../store';
import {closeSidebar} from './redux/actions';
import {getIsOpen, getVisitorId} from './redux/selectors';
import {CompanyVisitorsSidebar} from './company-visitors-sidebar';

export function VisitorsSidebarWrapper() {
    const isOpen = useSelector((state: NutshellState) => getIsOpen(state));
    const visitorId = useSelector((state: NutshellState) => getVisitorId(state));
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (isOpen) {
            analyticsClient.track(analyticsClient.EVENTS.TRACK_VIQ_SIDEBAR_OPENED);
        }
    }, [isOpen]);

    const handleClose = () => {
        dispatch(closeSidebar());
    };

    if (!isOpen || !visitorId) {
        return null;
    }

    return <CompanyVisitorsSidebar visitorId={visitorId} onClose={handleClose} />;
}
