/* @flow */

import * as React from 'react';
import {Link as RoutedLink} from 'react-router-dom';
import {getApiTypeFromGraphQLTypename} from 'nutshell-core/utils';

import type {SessionUser, EventFragmentSparse_actor as Actor} from 'nutshell-graphql-types';

import {colors} from 'shells/colors';
import {Link} from 'shells/link';
import {TimelineBannerCommentCard} from 'shells/timeline/banner/timeline-banner-comment-card';
import {LinkedEntity} from 'shells/linked-entity';

import type {EventForTimeline} from '../types';
import {getActorAsParticipant} from '../helpers';

import {TimelineBanner} from './timeline-banner';

type Props = {|event: EventForTimeline, currentUser: SessionUser|};

export function TimelineBannerComment(props: Props) {
    const {event, currentUser} = props;

    if (event.payload.__typename === 'Comment') {
        if (!event.payload.parentEntity || !event.payload.bodyHtml) {
            return null;
        }

        // We use an alias, commentId, for the id since it conflicts with other comment id
        const commentApiId = event.payload.commentId;
        const parentEntity = event.payload.parentEntity;
        // Return null if actor can not be returned as participant (shouldn't ever happen
        // but need to check) and flow flips out if this statement is grouped with the other
        // check above, or the above variable declarations.
        const actorAsParticipant = getActorAsParticipant(event.actor);
        if (!actorAsParticipant) {
            return null;
        }

        let commentAssetPath;
        let parentEntityCommentedOn;

        switch (parentEntity.__typename) {
            case 'Note':
                if (
                    parentEntity.entity &&
                    parentEntity.entity.htmlUrl &&
                    parentEntity.entity.name &&
                    parentEntity.entity.__typename
                ) {
                    commentAssetPath = `/note/${Number.parseInt(parentEntity.id)}#${commentApiId}`;
                    parentEntityCommentedOn = (
                        <>
                            a note on&nbsp;
                            <LinkedEntity
                                // $FlowIgnore - it does not believe this exists, when clearly I am checking above
                                type={getApiTypeFromGraphQLTypename(parentEntity.entity.__typename)}
                                // $FlowIgnore - it does not believe this exists, when clearly I am checking above
                                htmlUrl={parentEntity.entity.htmlUrl}
                                // $FlowIgnore - it does not believe this exists, when clearly I am checking above
                                name={parentEntity.entity.name}
                                useEntityColor={true}
                                shouldClientRoute={true}
                                allowWrap={true}
                                displayInline={true}
                                greenLeads={true}
                            />
                        </>
                    );
                }
                break;
            case 'Activity':
                commentAssetPath = parentEntity.htmlUrlPath;
                parentEntityCommentedOn = (
                    <>
                        an activity:&nbsp;
                        <Link as={RoutedLink} variant='secondary' to={commentAssetPath}>
                            {parentEntity.name}
                        </Link>
                    </>
                );
                break;
            case 'Email':
                commentAssetPath = `/email/${Number.parseInt(parentEntity.id)}#${commentApiId}`;
                parentEntityCommentedOn = (
                    <>
                        {parentEntity.isTicketMessage ? 'a ticket' : 'an email:'}&nbsp;
                        <Link as={RoutedLink} variant='secondary' to={commentAssetPath}>
                            {parentEntity.subject}
                        </Link>
                    </>
                );
                break;
        }

        const CommentCard = () => (
            <TimelineBannerCommentCard
                avatar={actorAsParticipant}
                content={
                    <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            // $FlowIgnore - it does not believe this exists, when clearly I am checking above
                            __html: event.payload.bodyHtml,
                        }}
                    />
                }
            />
        );

        let andAdditionalActors;
        const additionalActors: Actor[] = event.additionalActors ? event.additionalActors : [];

        if (additionalActors.length) {
            // If there is one additional actor, we will display their name
            if (additionalActors.length === 1) {
                const additionalActorAsParticipant = getActorAsParticipant(additionalActors[0]);

                if (additionalActorAsParticipant) {
                    andAdditionalActors = (
                        <span>
                            and&nbsp;
                            <b>
                                {additionalActorAsParticipant.id === currentUser.id ? (
                                    <span style={{color: colors.textUsers}}>you</span>
                                ) : (
                                    <LinkedEntity
                                        type={additionalActorAsParticipant.type}
                                        htmlUrl={additionalActorAsParticipant.htmlUrl}
                                        name={additionalActorAsParticipant.name}
                                        useEntityColor={true}
                                        shouldClientRoute={true}
                                        allowWrap={true}
                                        displayInline={true}
                                        greenLeads={true}
                                    />
                                )}
                            </b>
                            &nbsp;
                        </span>
                    );
                }
            } else {
                andAdditionalActors = <span>and {additionalActors.length} others&nbsp;</span>;
            }
        }

        return (
            <TimelineBanner
                id={event.id}
                iconVariant='comment'
                timestamp={event.changeTime}
                actor={event.actor}
                action={
                    <span>
                        {andAdditionalActors}commented on {parentEntityCommentedOn}
                    </span>
                }
                bannerBody={
                    // Structured like this because the comment asset path in theory could be undefined,
                    // but since this whole linking thing will be deprecated in favor of opening the entry
                    // modals, don't think this really matters all that much
                    commentAssetPath ? (
                        <Link as={RoutedLink} to={commentAssetPath} preventUnderline={true}>
                            <CommentCard />
                        </Link>
                    ) : (
                        <CommentCard />
                    )
                }
            />
        );
    } else {
        return null;
    }
}
