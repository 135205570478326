/* @flow */
import * as React from 'react';
import type {FieldArrayProps, FieldProps} from 'redux-form';

import {SelectPickerGeneric} from 'shells/select-picker-generic';
import {Label} from 'shells/form';
import {OptionCardBuilderField} from 'shells/redux-form-fields';

import './trigger-lead-fields.css';

const closedStatusOptions = [
    {label: 'Won', value: 'won'},
    {label: 'Lost', value: 'lost'},
    {label: 'Cancelled', value: 'cancelled'},
];

type Props = {
    fields: $PropertyType<FieldArrayProps, 'fields'>,
    meta: $PropertyType<FieldArrayProps, 'meta'>,
    formType: 'stageEntered' | 'leadClosed',
    optionFields: any[],
};

export function TriggerLeadFields(props: Props) {
    const [selectedField, setSelectedField] = React.useState<?Object>(null);

    if (props.fields.length === 0) {
        // If we haven't selected any pipelines, add an empty value
        props.fields.push(null);
    }

    const currentFields = props.fields.getAll();

    const filterFields = (fields) => {
        return fields ? fields.filter((el) => el !== null) : undefined;
    };

    const stageEnteredStageId =
        props.formType === 'stageEntered' && currentFields ? currentFields[0] : undefined;

    const getStageEnteredPipeline = () => {
        const stageEnteredPipeline = stageEnteredStageId
            ? props.optionFields.find((f) => f.stages.some((s) => s.id === stageEnteredStageId))
            : undefined;

        return stageEnteredPipeline;
    };
    const stageEnteredPipeline = getStageEnteredPipeline();

    const getStageEnteredStage = () => {
        let stage;
        if (stageEnteredPipeline) {
            stage = stageEnteredPipeline.stages.find((s) => s.id === stageEnteredStageId);
        }

        return stage;
    };
    const stageEnteredStage = getStageEnteredStage();

    const getClosedStatusPipeline = () => {
        let closedStatusPipeline;
        const filteredFields = filterFields(currentFields);
        const closedStatusPipelineId =
            props.formType === 'leadClosed' && filteredFields && filteredFields.length
                ? filteredFields[0].stageset[0]
                : undefined;

        if (closedStatusPipelineId) {
            closedStatusPipeline = props.optionFields.find((f) => f.id === closedStatusPipelineId);
        }

        return closedStatusPipeline;
    };
    const closedStatusPipeline = getClosedStatusPipeline();

    const getLeadClosedStatus = () => {
        const filteredFields = filterFields(currentFields);

        const status =
            props.formType === 'leadClosed' && filteredFields && filteredFields.length
                ? filteredFields[0].status
                : undefined;

        const closedStatusOption = status
            ? closedStatusOptions.find((option) => option.value === status)
            : undefined;

        const leadClosedStatus = closedStatusOption ? closedStatusOption.label : undefined;

        return leadClosedStatus;
    };
    const leadClosedStatus = getLeadClosedStatus();

    const getCardData = (selected: Object) => {
        if (stageEnteredPipeline && stageEnteredStage) {
            return {
                topRow: stageEnteredPipeline.name,
                bottomRow: stageEnteredStage.name,
            };
        }

        if (closedStatusPipeline && leadClosedStatus) {
            return {
                topRow: closedStatusPipeline.name,
                bottomRow: leadClosedStatus,
            };
        }

        let topRowText = '';
        let bottomRowText = '';

        if (props.formType === 'stageEntered') {
            const selectedPipeline = props.optionFields.find((f) =>
                f.stages.some((s) => s.id === selected.stage)
            );

            const selectedStage =
                selectedPipeline && selectedPipeline.stages.find((s) => s.id === selected.stage);

            topRowText = selectedPipeline ? selectedPipeline.name : '';
            bottomRowText = selectedStage ? selectedStage.name : '';
        }

        if (props.formType === 'leadClosed') {
            const selectedPipeline = props.optionFields.find((f) => f.id === selected.stageset);

            const closedStatus = selected.status;
            const closedStatusOption = closedStatusOptions.find(
                (status) => status.value === closedStatus
            );

            topRowText = selectedPipeline ? selectedPipeline.name : '';
            bottomRowText = closedStatusOption ? closedStatusOption.label : '';
        }

        return {
            topRow: topRowText,
            bottomRow: bottomRowText,
        };
    };

    const cardCreator = (cardProps: {
        meta: $PropertyType<FieldProps, 'meta'>,
        input: $PropertyType<FieldProps, 'input'>,
        onRemove: () => any,
    }) => {
        const options = props.optionFields.map((option) => ({
            value: option.id,
            label: option.name,
            name: option.name,
            isDisabled: !option.canAccess,
            stages: option.stages,
        }));

        return (
            <div styleName='trigger-lead-field'>
                <SelectPickerGeneric
                    autoFocus={true}
                    value={
                        options
                            ? options.filter(
                                  (option) => selectedField && option.name === selectedField.name
                              )
                            : undefined
                    }
                    options={options ? options : []}
                    placeholder='Select a pipeline'
                    onChange={onFieldSelect()}
                />
                {selectedField && props.formType === 'stageEntered' ? (
                    <div styleName='enum-option'>
                        <Label htmlFor='trigger-field-enum-option'>Stage is:</Label>
                        <SelectPickerGeneric
                            id='trigger-field-enum-option'
                            autoFocus={true}
                            options={selectedField.stages.map((stage) => ({
                                value: stage.id,
                                label: stage.name,
                            }))}
                            placeholder='Select a pipeline stage'
                            onChange={onOptionSelect({input: cardProps.input})}
                        />
                    </div>
                ) : null}
                {selectedField && props.formType === 'leadClosed' ? (
                    <div styleName='enum-option'>
                        <Label htmlFor='trigger-field-enum-option'>Closed status is:</Label>
                        <SelectPickerGeneric
                            id='trigger-field-enum-option'
                            autoFocus={true}
                            options={closedStatusOptions}
                            placeholder='Select a closed status'
                            onChange={onOptionSelect({input: cardProps.input})}
                        />
                    </div>
                ) : null}
            </div>
        );
    };

    const onFieldSelect = () => (selected: {value: Object}) => {
        const pipelineField = selected;

        if (pipelineField) {
            setSelectedField(pipelineField);
        }
    };

    const onOptionSelect = (field: {input: $PropertyType<FieldProps, 'input'>}) => (selected: {
        value: string,
        label: string,
    }) => {
        const pipelineField = selectedField;
        if (!pipelineField) return;

        const selection = {};
        if (props.formType === 'stageEntered') {
            selection.stage = selected.value;
        }

        if (props.formType === 'leadClosed') {
            selection.stageset = pipelineField.value;
            selection.status = selected.value;
        }

        field.input.onChange(selection);

        setSelectedField(null);
    };

    return (
        <div styleName='card-container'>
            <OptionCardBuilderField
                fields={props.fields}
                divider='And'
                hideAddButton={true}
                meta={props.meta}
                getCardData={getCardData}
                cardCreator={cardCreator}
            />
        </div>
    );
}
