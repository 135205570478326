/* @flow */

import {api} from '../api';

/**
 * Fetches a single lead given a lead api id
 *
 * @param {string} leadId    - Api Id of the lead to fetch
 * @return {Promise} Deferred object that should resolve to Lead API response
 */
export function fetchLead(leadId: string) {
    return api.get(`leads/${leadId}`).then((response) => response.json());
}
