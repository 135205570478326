import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import HourglassHalf from './icon-hourglass-half.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <HourglassHalf {...validProps} />;
}
SvgComponent.iconName = 'hourglassHalf';

export const HourglassHalfIcon = svgIconWrapper(SvgComponent);
