/* @flow */

import * as React from 'react';
import './radio-group.css';

type RadioGroupContextProps = {|
    name: string,
    selectedValue: ?string,
    // $FlowFixMe upgrading Flow to v0.110.1
    onChange: (newValue: $ReadOnly<string>) => void,
    inline?: boolean,
|};

export type RadioGroupProps = {|
    ...RadioGroupContextProps,
    children: React.Node[],
    size?: 'normal' | 'big',
|};

const defaultContextValue = {name: 'default', selectedValue: null, onChange: () => {}};

export const RadioGroupContext = React.createContext<RadioGroupContextProps>(defaultContextValue);

export class RadioGroup extends React.Component<RadioGroupProps> {
    static defaultProps = {
        size: 'normal',
    };

    render() {
        const {name, onChange, selectedValue, inline} = this.props;

        return (
            <RadioGroupContext.Provider value={{name, onChange, selectedValue, inline}}>
                <div
                    styleName={inline ? 'radio-group-inline' : 'radio-group'}
                    role='radiogroup'
                    className={this.props.size === 'big' ? 'font-size-normal' : ''}
                >
                    {this.props.children}
                </div>
            </RadioGroupContext.Provider>
        );
    }
}
