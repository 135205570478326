/* @flow */

export {Header} from './header';
export {Title} from './title';
export {Subheader} from './subheader';
export {Body} from './body';
export {DescriptionBody} from './description-body';
export {SectionLabel} from './section-label';
export {UppercaseLabel} from './uppercase-label';
export {BoldLabel} from './bold-label';
export {LabelWithContent} from './label-with-content';
export {TitleWithBody} from './title-with-body';

/* Marketing variantions */
export {MarketingTitle} from './marketing-title';
export {MarketingHeader} from './marketing-header';
export {MarketingBody} from './marketing-body';

/* Modals */
export {ModalHeader} from './modal-header';
export {ModalHeaderBreadcrumbs} from './modal-header-breadcrumbs';
export type {ModalHeaderBreadcrumb} from './modal-header-breadcrumbs';
export {ModalSubheader} from './modal-subheader';
export {ModalTitle} from './modal-title';
export {ModalBody} from './modal-body';
