/* @flow */
import * as React from 'react';

export const useInterval = (callback: any, delayMs: number) => {
    const savedCallback = React.useRef();

    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    React.useEffect(() => {
        const tick = () => {
            // $FlowIgnore
            savedCallback.current();
        };
        if (delayMs !== null) {
            const id = setInterval(tick, delayMs);

            return () => clearInterval(id);
        }
    }, [delayMs]);
};
