import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import StopCircleIconSvg from './icon-stop-circle.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <StopCircleIconSvg {...validProps} />;
}
SvgComponent.iconName = 'stop-circleIcon';

export const StopCircleIcon = svgIconWrapper(SvgComponent);
