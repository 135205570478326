/* @flow */

import * as React from 'react';
import {Query} from 'react-apollo';
import type {GetAppMessages as GetAppMessagesQuery} from 'nutshell-graphql-types';
import {connect} from 'react-redux';

import {MessageContainer} from 'shells/message';
import {FlashBanner} from 'shells/flash-banner';
import type {NutshellState} from '../../store';
import {dismissMessage} from './messages-actions';
import {getDismissedMessages} from './messages-selectors';
import GET_APP_MESSAGES from './graphql/queries/app-messages-query.graphql';

type StateProps = {|
    dismissedMessageIds: string[],
|};
type DispatchProps = {|
    dismissMessage: (string) => void,
|};
type GraphqlProps = {|
    appMessages: ?$PropertyType<GetAppMessagesQuery, 'appMessages'>,
|};
type OwnProps = {|
    type: 'Imports' | 'BulkEdits' | 'BulkCreateLeads',
|};
// Too many prop types here. This is for the connector
type ComponentProps = {|...GraphqlProps, ...OwnProps|};

type Props = {...GraphqlProps, ...DispatchProps, ...StateProps, ...OwnProps};

function mapStateToProps(state: NutshellState): StateProps {
    return {
        dismissedMessageIds: getDismissedMessages(state),
    };
}

function mapDispatchToProps(dispatch): DispatchProps {
    return {
        dismissMessage: (id: string) => {
            dispatch(dismissMessage(id));
        },
    };
}

export class AppMessagesProviderComponent extends React.Component<Props> {
    render() {
        if (this.props.appMessages) {
            return (
                <MessageContainer direction='reverse'>
                    {this.props.appMessages
                        .filter((message) => {
                            return !this.props.dismissedMessageIds.includes(message.id);
                        })
                        .filter((message) => {
                            return message.id.includes(this.props.type);
                        })
                        .map((message) => {
                            return (
                                <FlashBanner
                                    key={message.id}
                                    type='info'
                                    onHidden={() => {
                                        this.props.dismissMessage(message.id);
                                    }}
                                    animate={true}
                                    hasBorder={true}
                                >
                                    {message.message}
                                </FlashBanner>
                            );
                        })}
                </MessageContainer>
            );
        }

        return null;
    }
}

const connector = connect<Props, ComponentProps, _, _, _, _>(mapStateToProps, mapDispatchToProps);
const AppMessagesProviderConnected = connector(AppMessagesProviderComponent);

class MessageQuery extends Query<GetAppMessagesQuery, {}> {}

export function AppMessagesProvider(props: OwnProps) {
    return (
        <MessageQuery query={GET_APP_MESSAGES}>
            {({data}) => (
                <AppMessagesProviderConnected
                    appMessages={data ? data.appMessages : null}
                    type={props.type}
                />
            )}
        </MessageQuery>
    );
}
