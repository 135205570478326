/* @flow */

import type {NutshellState} from '../../store';
import {getUIState as getNutshellUiState} from '../ui-selectors';
import type {SalesAutomationItemType} from '../../sales-automation/types';

export const getUiState = (state: NutshellState) => getNutshellUiState(state).salesAutomation;

export const getOnboardingState = (state: NutshellState) => {
    return getUiState(state).onboardingWizard;
};

export const getActiveItem = (state: NutshellState) => {
    return getUiState(state).activeItem;
};

export const getHoveredStageId = (state: NutshellState) => {
    return getUiState(state).hoveredStageId;
};

export const getIsSidebarFormSaving = (state: NutshellState) => {
    return getUiState(state).isSidebarFormSaving;
};

/*
 * Determines if there is a new active item, meaning an item is being created
 */
export const isActiveItemNew = (state: NutshellState): boolean => {
    const activeItem = getUiState(state).activeItem;

    return Boolean(activeItem && !activeItem.entityId);
};

export const getIsExpandedForType = (state: NutshellState, type: SalesAutomationItemType) => {
    return !getUiState(state).collapsedSections.includes(type);
};

export const isOptionChosen = (state: NutshellState, option: string) => {
    const onboardingState = getOnboardingState(state);

    return Boolean(onboardingState[option]);
};
