/* @flow */

import * as React from 'react';

export function EmailMessage() {
    return (
        <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 176 172'>
            <g clipPath='url(#clip0)'>
                <path
                    d='M12.211 96.022l9.991-14.431 4.995 21.091 23.867 11.101 41.628 13.876 58.834-49.399-.555-13.32 9.435 7.215 12.8 73.757-147.119 21.154-13.876-71.044zM124.329 43.293L74.931 12.21 43.59 54.424l80.738-11.131z'
                    fill='#DBDBF2'
                />
                <path
                    d='M28.293 103.158l-7.26-44.96 51.571-8.081 73.808-9.163 5.752 37.318-23.217 20.22-36.099 29.091-38.473-12.562-26.082-11.863z'
                    fill='#FAC942'
                />
                <path
                    d='M10.16 95.818c2.323 24.15 8.75 47.654 13.333 71.523.481 2.791 4.867 2.247 4.386-.545-4.583-23.869-11.01-47.373-13.333-71.522-.349-2.808-4.602-2.28-4.386.544z'
                    fill='#0C0F33'
                />
                <path
                    d='M27.43 169.703c17.626-.423 35.8-5.124 53.261-7.97 29.206-4.984 58.877-7.31 87.967-13.229 2.758-.614 2.227-4.893-.548-4.412-28.574 5.719-57.631 8.648-86.521 12.913-18.025 2.646-36.55 7.796-54.707 8.286-2.825.079-2.277 4.491.547 4.412z'
                    fill='#0C0F33'
                />
                <path
                    d='M159.262 72.827c5.796 23.854 7.488 48.353 9.695 72.653.216 2.824 4.601 2.28 4.386-.545-2.207-24.299-3.899-48.799-9.695-72.653-.614-2.775-5-2.23-4.386.545zM29.772 165.746c10.501-15.423 20.271-31.299 32.682-45.466 2.127-2.436-1.76-5.483-3.887-3.047-12.428 14.033-22.181 30.043-32.682 45.466-1.844 2.537 2.043 5.584 3.887 3.047z'
                    fill='#0C0F33'
                />
                <path
                    d='M125.451 108.115c14.535 11.636 27.658 24.941 43.24 35.226 2.359 1.608 5.034-1.847 2.675-3.455-15.582-10.284-28.721-23.723-43.24-35.226-2.242-1.758-4.917 1.697-2.675 3.455zM18.353 60.724c3.122 14.275 3.868 28.98 7.654 43.173.747 2.759 4.718 1.044 3.97-1.715-3.586-13.674-4.283-27.979-7.371-41.986-.465-2.658-4.868-2.247-4.253.528z'
                    fill='#0C0F33'
                />
                <path
                    d='M21.194 59.692c41.2-5.115 82.218-11.701 123.419-16.816 2.791-.346 2.243-4.759-.548-4.412-41.2 5.115-82.218 11.7-123.419 16.816-2.924.363-2.376 4.775.548 4.412z'
                    fill='#0C0F33'
                />
                <path
                    d='M144.43 41.405l4.333 34.897c.182 1.471 4.568.926 4.386-.544l-4.333-34.897c-.481-2.792-4.734-2.264-4.386.544zM16.755 92.42c1.994-3.506 4.154-6.761 6.463-9.9 1.745-2.252-1.694-4.948-3.439-2.695-2.675 3.455-4.918 7.128-7.011 10.917-1.33 2.338 2.657 4.015 3.987 1.678zM44.984 53.48C53.292 38.872 66.2 27.63 75.705 13.96c1.595-2.37-1.843-5.066-3.455-2.83-9.737 13.971-22.746 25.497-31.27 40.54-1.445 2.487 2.675 4.148 4.004 1.81z'
                    fill='#0C0F33'
                />
                <path
                    d='M73.43 14.106c15.2 9.382 30.266 18.78 45.35 28.311 2.342 1.475 5.017-1.98 2.675-3.454a5044.382 5044.382 0 00-45.35-28.312c-2.476-1.457-5.15 1.998-2.675 3.455zM150.641 65.344c.798.987 1.711 1.824 2.625 2.66 1.229 1.206 2.475 2.545 4.036 3.165 2.559 1.04 4.353-2.984 1.662-4.007-.565-.202.15.117-.149-.118-.299-.234-.598-.468-.881-.569-.614-.602-1.212-1.071-1.827-1.674a33.921 33.921 0 01-2.01-2.058c-.781-.854-2.043-1.24-3.024-.44-.996.667-1.213 2.188-.432 3.04zM10.69 96.838c13.24 5.552 25.135 13.307 38.474 18.575 13.323 5.135 26.713 9.717 40.302 13.733 2.658.756 4.452-3.269 1.661-4.008-12.89-3.83-25.583-8.228-38.324-13.028-5.947-2.248-12.028-4.48-17.775-7.296-7.492-3.822-14.702-8.63-22.676-11.984-2.558-1.04-4.22 2.968-1.662 4.008z'
                    fill='#0C0F33'
                />
                <path
                    d='M93.486 128.918c11.846-5.679 20.967-14.822 30.687-23.496 12.145-10.875 24.656-20.982 36.336-32.343 2.06-1.885-.681-5.481-2.592-3.48-10.351 10.11-21.349 19.35-32.315 28.858-10.849 9.357-20.569 20.203-33.693 26.448-2.692 1.149-.965 5.279 1.577 4.013z'
                    fill='#0C0F33'
                />
            </g>
            <defs>
                <clipPath id='clip0'>
                    <path
                        fill='#fff'
                        transform='rotate(-7.077 157.032 9.71)'
                        d='M0 0h157.63v153.19H0z'
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
