/* @flow */

import {api} from '../api';
import * as Schema from '../schema';
import type {AccountId, AccountsApiResponse} from '../types';

export function fetchAccountById(accountId: AccountId) {
    return api
        .get(`accounts/${accountId}`)
        .then((response: Response): Promise<AccountsApiResponse> => response.json());
}

export function fetchSchema() {
    return Schema.fetchSchema('accounts/list/fields');
}
