/* @flow */

import * as React from 'react';
import {useQuery} from '@apollo/react-hooks';

import type {
    GetEvents as GetEventsQuery,
    GetEventsVariables as GetEventsQueryVariables,
    EventFragmentSparse,
} from 'nutshell-graphql-types';

import {useInterval} from 'shells/utils/hooks';
import {makeDataSafe, safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import GET_EVENTS from '../queries/get-events.graphql';

export const GET_EVENTS_LIMIT = 20;
const POLL_INTERVAL_MILLISECONDS = 300000; // 5 minutes

export const useGetTimelineEvents = (
    queryVariables: GetEventsQueryVariables
): ({
    events: EventFragmentSparse[],
    refetchEvents: () => Promise<*>,
    error: ?string,
    isLoading: boolean,
    isFirstLoad: boolean,
    fetchMoreEvents: () => Promise<*>,
    hasNextPage: boolean,
    fetchMostRecentEvents: () => Promise<*>,
}) => {
    const [initialStartCursor, setInitialStartCursor] = React.useState<?string>(null);

    const {
        data,
        loading: isLoading,
        networkStatus,
        error,
        refetch: refetchEvents,
        fetchMore,
    } = useQuery<GetEventsQuery, GetEventsQueryVariables>(GET_EVENTS, {
        variables: {...queryVariables, first: GET_EVENTS_LIMIT},
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
    });

    // This shows first load and also loading with new filters
    const isFirstLoad = networkStatus === 1 || networkStatus === 2;
    const {events: eventsData} = makeDataSafe(data);
    const pageInfo = eventsData ? eventsData.pageInfo : {};
    const {startCursor} = pageInfo;

    React.useEffect(() => {
        if (isFirstLoad) {
            setInitialStartCursor(null);
        } else if (!initialStartCursor && !isLoading) {
            // If no cursor currently set, set the initial start cursor
            setInitialStartCursor(startCursor);
        }
    }, [initialStartCursor, startCursor, isLoading, isFirstLoad]);

    useInterval(() => {
        if (initialStartCursor) {
            fetchMostRecentEvents(initialStartCursor);
        }
    }, POLL_INTERVAL_MILLISECONDS);

    const events = React.useMemo(() => {
        return eventsData
            ? eventsData.edges.map((edge) => {
                  return {...edge.node, pinnedInfo: edge.pinnedInfo};
              })
            : [];
    }, [eventsData]);

    const fetchMoreEvents = () => {
        // $FlowIgnore - Unsure what error flow is showing, but this works
        return fetchMore({
            variables: {
                first: GET_EVENTS_LIMIT,
                after: eventsData ? eventsData.pageInfo.endCursor : null,
                filters: queryVariables.filters,
            },
            updateQuery: (prev: GetEventsQuery, newResults: {fetchMoreResult: GetEventsQuery}) => {
                const oldEvents = prev ? prev.events : {};
                const fetchedEvents = newResults.fetchMoreResult.events;
                const newEvents = {
                    ...oldEvents,
                    pageInfo: fetchedEvents.pageInfo,
                    edges: oldEvents.edges.concat(fetchedEvents.edges),
                };

                return {events: newEvents};
            },
        });
    };

    // Called when polling - gets any new events and puts them at the top of the timeline
    const fetchMostRecentEvents = (beforeCursor: ?string) => {
        if (!beforeCursor) {
            // no-op if no beforeCursor is passed
            return new Promise((resolve) => {
                resolve();
            });
        }

        // $FlowIgnore - Unsure what error flow is showing, but this works
        return fetchMore({
            variables: {
                first: 20,
                before: beforeCursor,
                filters: queryVariables.filters,
            },
            updateQuery: (prev: GetEventsQuery, newResults: {fetchMoreResult: GetEventsQuery}) => {
                const oldEvents = prev ? prev.events : {};
                const mostRecentEvents = newResults.fetchMoreResult.events;
                const newEvents = {
                    ...oldEvents,
                    // Put new events at the top of the timeline
                    edges: mostRecentEvents.edges.concat(oldEvents.edges),
                };

                // Update the startCursor if it exists if no new events
                // the start cursor will be null
                if (mostRecentEvents.pageInfo.startCursor) {
                    setInitialStartCursor(mostRecentEvents.pageInfo.startCursor);
                }

                return {events: newEvents};
            },
        });
    };

    const hasNextPage = eventsData ? eventsData.pageInfo.hasNextPage : false;

    return {
        events,
        refetchEvents,
        error: safelyGetGraphQLErrorMessage(error),
        isLoading,
        isFirstLoad,
        fetchMoreEvents,
        fetchMostRecentEvents: () => {
            return fetchMostRecentEvents(initialStartCursor);
        },
        hasNextPage,
    };
};
