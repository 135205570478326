/* @flow */

import * as React from 'react';

import {AccountChildOverlayIcon} from '../icon';
import {Avatar} from '.';

import './account-avatar.css';

const CHILD_AVATAR_SIZE_TO_CONTAINER_SIZE_RATIO = 0.875;
const MAX_CHILD_COUNT_DISPLAYED = 99;

const CHILD_PATTERN = /parent=[0-9]+/;
const PARENT_PATTERN = /children=[0-9]+/;

type Props = {
    avatarUrl: ?string,
    size?: number,
};

export const AccountAvatar = (props: Props) => {
    const {size = 32} = props;
    const overlaySize = size / 2;

    let isChild = false;
    let isParent = false;
    let childrenCount = null;
    let maxChildCountReached = false;
    if (props.avatarUrl) {
        const avatarUrl: string = props.avatarUrl;

        isChild = CHILD_PATTERN.test(avatarUrl);
        isParent = PARENT_PATTERN.test(avatarUrl);

        if (isParent) {
            const match = avatarUrl.match(PARENT_PATTERN);

            if (match) {
                const keyValuePair = match[0].split('=');
                childrenCount = Number(keyValuePair[1]);
                maxChildCountReached = childrenCount > MAX_CHILD_COUNT_DISPLAYED;
            }
        }
    }

    return (
        <div styleName='container' style={{width: size, height: size}}>
            {isParent || isChild ? (
                <>
                    {isParent ? (
                        <>
                            <Avatar
                                avatarUrl={props.avatarUrl}
                                type='accounts'
                                isParent={true}
                                size={size}
                            />
                            <div
                                styleName='child-count-overlay'
                                style={{
                                    width: overlaySize,
                                    height: overlaySize,
                                    fontSize: maxChildCountReached ? 7 : null,
                                }}
                            >
                                {maxChildCountReached ? '99+' : childrenCount}
                            </div>
                        </>
                    ) : (
                        <div styleName='child-container'>
                            <Avatar
                                avatarUrl={props.avatarUrl}
                                type='accounts'
                                size={size * CHILD_AVATAR_SIZE_TO_CONTAINER_SIZE_RATIO}
                            />
                            <div styleName='child-overlay'>
                                <AccountChildOverlayIcon size={overlaySize} />
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <Avatar avatarUrl={props.avatarUrl} type='accounts' size={size} />
            )}
        </div>
    );
};
