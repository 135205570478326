/* @flow */

import * as React from 'react';

import {components, type OptionProps} from 'react-select';

import {colors} from '../colors';

import './color-named-option.css';

export function ColorNamedOption(props: OptionProps) {
    return (
        <components.Option {...props}>
            <div className='flex align-center'>
                <div
                    styleName='color-name-option-dot'
                    style={{backgroundColor: colors[props.data.colorName]}}
                />
                <div>{props.label}</div>
            </div>
        </components.Option>
    );
}
