/* @flow */

// Type Constants
export const ActionTypes = {
    LEADS_UPDATED: 'LEADS_UPDATED',
    LEADS_SCHEMA_REQUESTED: 'LEADS_SCHEMA_REQUESTED',
    LEADS_SCHEMA_UPDATED: 'LEADS_SCHEMA_UPDATED',
    LEADS_FETCH_LIST_FOR_CONTACT_SUCCESS: 'LEADS_FETCH_LIST_FOR_CONTACT_SUCCESS',
    LEADS_FETCH_LIST_FOR_CONTACT_REQUEST: 'LEADS_FETCH_LIST_FOR_CONTACT_REQUEST',
    LEADS_FETCH_LIST_FOR_CONTACT_FAILURE: 'LEADS_FETCH_LIST_FOR_CONTACT_FAILURE',
    LEADS_FETCH_LIST_SUCCESS: 'LEADS_FETCH_LIST_SUCCESS',
    LEADS_FETCH_BY_ID_REQUEST: 'LEADS_FETCH_BY_ID_REQUEST',
    LEADS_FETCH_BY_ID_SUCCESS: 'LEADS_FETCH_BY_ID_SUCCESS',
    LEADS_FETCH_BY_ID_FAILURE: 'LEADS_FETCH_BY_ID_FAILURE',
};
