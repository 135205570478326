/* @flow */
import param from 'jquery-param';
import {httpErrorCheck} from '../utils/errors';
import {defaultOptions} from './default-options';

export function get(baseUrl: string, search?: Object, options?: RequestOptions) {
    const url = `${baseUrl}${search ? `?${param(search)}` : ''}`;

    return fetch(url, {...defaultOptions, ...options}).then(httpErrorCheck);
}
