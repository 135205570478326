/* @flow */

import * as React from 'react';

import {Card} from '../card';
import {Button} from '../button';
import {Link} from '../link';
import {PlugErrorState} from '../illustrations';

import './error-state-small.css';

type Props = {
    message?: ?string,
    hasBorder?: boolean,
};

export function ErrorStateSmall(props: Props) {
    return (
        <Card
            hasBorder={Boolean(props.hasBorder)}
            hasShadow={Boolean(props.hasBorder)}
            fitHeight={true}
            fitContent={true}
        >
            <div styleName='error-state-small'>
                <PlugErrorState />
                <div>
                    <strong>{props.message ? props.message : 'Oh no, something went wrong'}</strong>
                    <div styleName='subtitle'>
                        Please{' '}
                        <Button
                            size='small'
                            variant='text-primary'
                            onClick={() => {
                                location.reload();
                            }}
                        >
                            try again
                        </Button>{' '}
                        or{' '}
                        <Link
                            size='small'
                            href='https://www.nutshell.com/support'
                            variant='primary'
                        >
                            contact support
                        </Link>
                        .
                    </div>
                </div>
            </div>
        </Card>
    );
}
