/* @flow */

import * as React from 'react';
import Select, {mergeStyles, type SelectBaseProps, type MultiValueProps} from 'react-select';

import {colors} from 'shells/colors';

import {ValueComponent, type TokenStyle} from '../select-inline/value-component';
import type {ActionMeta} from './select-picker-generic-creatable';
import {selectPickerGenericStyles} from './select-picker-generic-styles';
import type {FooterButtonProps} from './select-picker-generic';
import {getCustomMenuList} from './custom-menu-list';

import '../select-inline/select-inline.css';

type Props = {|
    ...$Exact<SelectBaseProps>,
    value?: ?(Object[]),
    options: Object[],
    isMulti: boolean,
    valueKey?: string,
    labelKey?: string,
    hasError?: ?boolean,
    onClear?: () => void,
    onChange?: (newValue: Object, actionMeta: ActionMeta) => void,
    tokenizedStyle?: TokenStyle,
    footerButton?: FooterButtonProps,
|};

export function SelectPickerGenericMulti(props: Props) {
    const {styles, hasError, onClear, onChange, footerButton, ...restProps} = props;

    const componentStyles = {
        input: (base) => ({
            ...base,
            padding: 0,
            marginBottom: 6,
        }),
        control: (base, state) => {
            let controlStyles = {
                ...base,
                border: hasError ? `1px solid ${colors.rose}` : `1px solid ${colors.offWhiteDk}`,
                paddingTop: '5px',
                height: 'unset',
            };

            if (state.isDisabled || props.isDisabled) {
                controlStyles = {
                    ...controlStyles,
                    color: 'var(--grey-lt)',
                    backgroundColor: 'var(--off-white)',
                    opacity: 0.6,
                };
            }

            return controlStyles;
        },
        indicatorsContainer: (base) => ({
            ...base,
            marginTop: -5,
        }),
        placeholder: (base) => ({
            ...base,
            // offsets the paddingTop needed for the value
            marginTop: -3,
            color: base.color,
            transition: 'color 200ms linear',
        }),
        indicatorSeparator: (base, state) => ({
            ...base,
            display: onClear && state.hasValue ? undefined : 'none',
        }),
        multiValueRemove: (base) => ({
            ...base,
            '&:hover': {
                backgroundColor: '#e6e6e6;',
                color: 'black',
            },
        }),
    };

    let mergedStyles = mergeStyles(selectPickerGenericStyles, componentStyles);

    // Add in styles from props
    if (styles) {
        mergedStyles = mergeStyles(mergedStyles, styles);
    }

    const handleChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'clear' || newValue === null || typeof newValue === 'undefined') {
            if (onClear) {
                onClear();
            }
        } else if (onChange) {
            onChange(newValue, actionMeta);
        }
    };

    const getMultiValueComponent = (propsFromReactSelect: MultiValueProps) => {
        return (
            <ValueComponent
                data={propsFromReactSelect.data}
                labelKey={props.labelKey || 'label'}
                valueKey={props.valueKey || 'value'}
                tokenizedStyle={props.tokenizedStyle || 'light'}
                onRemove={propsFromReactSelect.removeProps.onClick}
            />
        );
    };

    return (
        <Select
            {...restProps}
            isMulti={true}
            onChange={handleChange}
            styles={mergedStyles}
            components={{
                MultiValue: getMultiValueComponent,
                MenuList: getCustomMenuList(footerButton),
            }}
        />
    );
}
