import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Revenue from './icon-revenue.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Revenue {...validProps} />;
}
SvgComponent.iconName = 'revenue';

export const RevenueIcon = svgIconWrapper(SvgComponent);
