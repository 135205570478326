/* @flow */

import {addItemsToByIdState} from '../reducer-utils';
import type {CollectionModel} from './collections-types';
import type {CollectionAction} from './collections-actions';
import {ActionTypes} from './collections-actions';

export type CollectionState<T: CollectionModel> = {
    isLoading: boolean,
    byId: {
        [collectionModelId: string]: T,
    },
    errorMessage: ?string,
};

const collectionDefaultState = {
    isLoading: false,
    byId: {},
    errorMessage: null,
};

export function createGenericCollectionReducerForType(collectionName: string) {
    return function collectionReducer(
        state: CollectionState<*> = collectionDefaultState,
        action: CollectionAction
    ): CollectionState<*> {
        if (!action.meta || collectionName !== action.meta.collectionType) {
            return state;
        }

        switch (action.type) {
            case ActionTypes.COLLECTIONS_FETCH_BY_ID_REQUEST:
            case ActionTypes.COLLECTION_REQUESTED: {
                return {...state, isLoading: true, errorMessage: null};
            }
            case ActionTypes.COLLECTIONS_FETCH_BY_ID_SUCCESS: {
                const response = action.payload;
                const {collectionType} = action.meta;
                const newCollections = addItemsToByIdState(response[collectionType], state.byId);

                return {
                    ...state,
                    byId: newCollections,
                    isLoading: false,
                };
            }
            case ActionTypes.COLLECTION_UPDATED: {
                const response = action.payload;
                const {collectionType} = action.meta;
                const newCollections = addItemsToByIdState(response[collectionType], {});

                return {
                    ...state,
                    byId: newCollections,
                    isLoading: false,
                };
            }
            case ActionTypes.COLLECTIONS_FETCH_BY_ID_FAILURE:
            case ActionTypes.COLLECTION_FAILED: {
                // TODO: do a better job of getting the error from the response
                // (action.payload)
                return {
                    ...state,
                    errorMessage: 'Something went wrong.',
                    isLoading: false,
                };
            }
            default:
                return state;
        }
    };
}
