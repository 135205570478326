import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Import from './icon-import.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Import {...validProps} />;
}
SvgComponent.iconName = 'iconImport';

export const ImportIcon = svgIconWrapper(SvgComponent);
