/* @flow */

import * as React from 'react';

import {Button} from '../button';

import './sidebar-header.css';

type Props = {
    headerTitle: string,
    subtitle?: string,
    onClose: () => any,
    onSave?: () => any,
    saveText: string,
    isSaving?: boolean,
    submitButton?: boolean,
    submitDisabled?: boolean,
    closeButton?: boolean,
    // Used to get ref for button rendered when either onSave or submitButton
    // are passed - does nothing if both are missing.
    getButtonRef?: any,
};

SidebarHeader.defaultProps = {
    saveText: 'Save',
};

export function SidebarHeader(props: Props) {
    return (
        <div styleName='header-bar'>
            {props.closeButton ? (
                <div styleName='close-button'>
                    <Button variant='text-secondary' size='big' onClick={props.onClose}>
                        Cancel
                    </Button>
                </div>
            ) : (
                undefined
            )}
            <div styleName='title-container'>
                <h1>{props.headerTitle}</h1>
                <div styleName='subtitle-container'>{props.subtitle}</div>
            </div>
            {props.onSave || props.submitButton ? (
                <div styleName='save-button'>
                    <Button
                        variant='text-primary'
                        size='big'
                        disabled={props.submitDisabled || props.isSaving}
                        getButtonRef={props.getButtonRef}
                        type={props.submitButton ? 'submit' : 'button'}
                        onClick={!props.isSaving && props.onSave ? props.onSave : undefined}
                    >
                        {props.isSaving ? 'Saving…' : props.saveText}
                    </Button>
                </div>
            ) : (
                undefined
            )}
        </div>
    );
}
