/* @flow */

import * as React from 'react';
import {useMutation} from '@apollo/react-hooks';

import type {
    SelectCreateTag as SelectCreateTagMutation,
    SelectCreateTagVariables as SelectCreateTagMutationVariables,
    TagType,
} from 'nutshell-graphql-types';

import {type ColorName, getColorNameFromTagColor} from 'shells/colors';
import {ColorNamedOption} from 'shells/select-option-components';

import {useGetTags} from './hooks/use-get-tags';
import CREATE_TAG from './mutations/create-tag.graphql';
import {GraphQLSelectCreatable} from './graphql-select-creatable';

type Option = {id: string, name: string, colorName?: ColorName};

type Props = {
    tagType: TagType,
    selectedValues: Option[],
    onChange: (values: Option[]) => any,
    onClearValue?: () => void,
};

export const GraphQLSelectTags = (props: Props) => {
    const {fetchMore} = useGetTags({
        tagType: props.tagType,
        searchTerm: '',
    });

    const [createTag] = useMutation<SelectCreateTagMutation, SelectCreateTagMutationVariables>(
        CREATE_TAG
    );

    const handleCreate = (newTag: string) => {
        // Immediately add a temp tag to the list of selected values
        const tempTag = {id: newTag, name: newTag, colorName: 'offWhiteDk'};
        props.onChange([...props.selectedValues, tempTag]);

        createTag({
            variables: {
                tag: {tag: {name: newTag, entityType: props.tagType}},
            },
        }).then((result) => {
            const createdTag = result.data && result.data.createTag && result.data.createTag.tag;
            const createdTagValue = createdTag
                ? {
                      id: createdTag.id,
                      name: createdTag.name,
                      colorName: getColorNameFromTagColor(createdTag.color),
                  }
                : null;

            // Add the real created tag to the list of selected values
            if (createdTagValue) {
                props.onChange([...props.selectedValues, createdTagValue]);
            }
        });
    };

    return (
        <GraphQLSelectCreatable
            placeholder='Create or select tags'
            tokenizedStyle='colorName'
            value={props.selectedValues}
            onClearValue={props.onClearValue}
            loadOptions={(search: string) => {
                return fetchMore(search, props.tagType).then(({data}) => {
                    return data.tags.edges.map((edge) => ({
                        ...edge.node,
                        colorName: getColorNameFromTagColor(edge.node.color),
                    }));
                });
            }}
            onChange={props.onChange}
            onBlur={() => {
                props.onChange(props.selectedValues);
            }}
            onCreate={handleCreate}
            components={{
                Option: ColorNamedOption,
            }}
        />
    );
};
