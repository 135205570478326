import {createReducer} from 'redux-act';
import {REHYDRATE} from 'redux-persist';

import * as actions from '../../dashboard-actions';
import {simpleHydrate} from '../../dashboard-reducer';

const defaultFeedConfigState = {
    filter: '',
};

export const feedConfigReducer = createReducer(
    {
        [actions.updateFeedFilter]: (state, payload) => ({...state, filter: payload}),
        [REHYDRATE]: (state, persistedState) => simpleHydrate(state, persistedState, 'feedConfig'),
    },
    defaultFeedConfigState
);
