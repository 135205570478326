/* @flow */
import * as ramda from 'ramda';
import type {Assignable} from './assignables/assignables-types';

/**
 * Manipulates an already ordered assignables collection by shoving the
 * disabled entities to the bottom. Optionally takes a currentUserId and
 * shoves it at the top.
 * @param  {Array} assignables User and teams collection. This method is not
 * opinionated in its sorting, so the collection should be pre-sorted
 * @param  {string} [startWithIds] optional array of users and team ids to shove to the top of the list
 * @return {Array} Final sorted array, with disabled U&Ts at the bottom
 */

export function orderUsersAndTeams(
    assignables: Assignable[] = [],
    startWithIds: Array<string> = []
): Assignable[] {
    const uniqueStatsWithIds = ramda.uniq(startWithIds);
    let enabledUsersAndTeams: Assignable[] = [];
    let disabledUsersAndTeams: Assignable[] = [];

    assignables.forEach((model) => {
        if (model.isEnabled) {
            enabledUsersAndTeams.push(model);
        } else {
            disabledUsersAndTeams.push(model);
        }
    });

    // If startWithIds are passed…
    //
    // 1) Filter them out from our current assignables array
    // 2) Run a unique check on our id array
    // 3) Map our id strings into user or team objects
    // 4) Ensure valid ids were passed with a check filter undefined check
    // 5) Concat our filtered assignables array onto our startWith array
    if (startWithIds.length) {
        enabledUsersAndTeams = enabledUsersAndTeams.filter((userOrTeam = {}) => {
            return !startWithIds.includes(userOrTeam.id);
        });

        disabledUsersAndTeams = disabledUsersAndTeams.filter((userOrTeam = {}) => {
            return !startWithIds.includes(userOrTeam.id);
        });

        const startWithUsersAndTeams = assignables.filter((userOrTeam) => {
            return uniqueStatsWithIds.includes(userOrTeam.id);
        });

        enabledUsersAndTeams = startWithUsersAndTeams.concat(enabledUsersAndTeams);
    }

    return enabledUsersAndTeams.concat(disabledUsersAndTeams);
}
