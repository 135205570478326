/* @flow */
import * as React from 'react';

import {UpgradeArrowIcon} from '../icon';
import {Body} from '../typography';
import {colors} from '../colors';

import './upgrade-pill-button.css';

type Props = {
    onClick: () => void,
    size?: 'small' | 'normal',
};

export function UpgradePillButton(props: Props) {
    const {size = 'normal'} = props;

    return (
        <button styleName='pill-wrapper' onClick={props.onClick}>
            <UpgradeArrowIcon
                wrapWithDiv={false}
                size={size === 'normal' ? 20 : 14}
                color={colors.blue}
                alignCenter={true}
            />
            <Body color='blue' size={size}>
                Upgrade
            </Body>
        </button>
    );
}
