/* @flow */

import * as React from 'react';
import {motion} from 'framer-motion';
import classnames from 'classnames';

import {colors} from '../colors';

import './switch.css';

type Props = {
    isOn: boolean,
    onChange: () => void,
    isDisabled?: boolean,
    isRounded?: boolean,
    shouldAnimate?: boolean,
};

const containerVariants = {
    active: {
        backgroundColor: colors.blue,
        color: '#ffffff',
        borderColor: colors.blue,
    },
    inactive: {
        backgroundColor: colors.offWhite,
        color: colors.grey,
        borderColor: colors.offWhiteDk,
    },
};

const getSliderVariants = (isRounded) => {
    return {
        active: {
            left: isRounded ? 20 : 59,
            width: isRounded && 18,
            height: isRounded && 18,
            top: isRounded && 1,
            backgroundColor: isRounded && colors.white,
        },
        inactive: {
            left: isRounded ? 4 : 6,
            backgroundColor: isRounded ? colors.formBorder : colors.white,
            width: isRounded && 12,
            height: isRounded && 11,
            top: isRounded && 4,
        },
    };
};

/**
 * Use this to indicate to a user if a setting is on or off,
 * and allow them to change the value. Its basically a really fancy checkbox.
 */
export function Switch(props: Props) {
    const {isOn, isDisabled, isRounded, onChange, shouldAnimate = true} = props;
    const styleNames = classnames({
        container: true,
        'container--disabled': isDisabled,
        'container--round': isRounded,
    });

    // Get the current styles based on state
    const currentContainerStyle = containerVariants[isOn ? 'active' : 'inactive'];
    const currentSliderStyle = getSliderVariants(isRounded)[isOn ? 'active' : 'inactive'];

    return shouldAnimate ? (
        <motion.div
            onClick={!isDisabled ? onChange : undefined}
            styleName={styleNames}
            disabled={isDisabled}
            animate={isOn ? 'active' : 'inactive'}
            variants={containerVariants}
        >
            <div styleName='inner-container'>
                {!isRounded ? (
                    <>
                        <div styleName='label'>On</div>
                        <div styleName='label'>Off</div>
                    </>
                ) : (
                    <div style={{width: '40px'}} />
                )}
            </div>
            <motion.div
                styleName={isRounded ? 'slider--round' : 'slider'}
                animate={isOn ? 'active' : 'inactive'}
                variants={getSliderVariants(isRounded)}
                initial={false}
                transition={{type: 'tween', ease: 'easeInOut', duration: 0.2}}
            />
        </motion.div>
    ) : (
        <div
            onClick={!isDisabled ? onChange : undefined}
            styleName={styleNames}
            style={currentContainerStyle}
        >
            <div styleName='inner-container'>
                {!isRounded ? (
                    <>
                        <div styleName='label'>On</div>
                        <div styleName='label'>Off</div>
                    </>
                ) : (
                    <div style={{width: '40px'}} />
                )}
            </div>
            <div styleName={isRounded ? 'slider--round' : 'slider'} style={currentSliderStyle} />
        </div>
    );
}
