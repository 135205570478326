/* @flow */

import * as React from 'react';

import {ChevronDownIcon} from 'shells/icon';
import {Popover} from 'shells/popover';
import {StatusHeader} from 'shells/status-button/status-header';
import type {MessageType} from 'shells/message';

import {Button} from '../button';

import './dynamic-status-button.css';

export type Props = {
    icon: ?React.Element<*>,
    text: string,
    popoverContent?: React.Node,
    headerType?: MessageType,
    headerContent?: React.Element<*>,
    isDialogOpen: boolean,
    setIsDialogOpen: (isDialogOpen: boolean) => void,
};

export function DynamicStatusButton(props: Props) {
    const {headerType = 'info'} = props;
    const buttonRef = React.useRef();

    return (
        <>
            <Button
                variant='secondary'
                size='big'
                getButtonRef={buttonRef}
                onClick={() => props.setIsDialogOpen(true)}
            >
                <div styleName='status-button-container'>
                    {props.icon}
                    <div className='mr-8'>{props.text}</div>
                    <ChevronDownIcon />
                </div>
            </Button>
            {props.isDialogOpen ? (
                <Popover
                    anchor={buttonRef.current}
                    onBlur={() => props.setIsDialogOpen(false)}
                    location='bottom'
                    displayUnderModal={true}
                >
                    {props.headerContent ? (
                        <div styleName='status-popover'>
                            <StatusHeader
                                type={headerType}
                                icon={props.icon}
                                content={props.headerContent}
                            />
                            {props.popoverContent}
                        </div>
                    ) : (
                        props.popoverContent
                    )}
                </Popover>
            ) : (
                undefined
            )}
        </>
    );
}
