/* @flow */

import type {ReportGap, FilterObject} from 'nutshell-core/types';
import {serializeFilters} from 'nutshell-core';

export type AudienceMembershipChartParams = {
    gap: ReportGap,
    audienceId: string,
    reportType: string,
};

export type AudienceMembershipReportPostParams = {
    filter: Object,
    gap: ReportGap,
    audienceId: string,
    reportType: string,
};

export function getMembershipReportPostParams(
    reportParams: AudienceMembershipChartParams,
    filters: FilterObject[]
): AudienceMembershipReportPostParams {
    const {gap, audienceId, reportType} = reportParams;

    return {
        filter: serializeFilters(filters),
        gap,
        reportType,
        audienceId,
    };
}
