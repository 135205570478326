/* @flow */

import * as React from 'react';

export function SchedulerAddOnPlaceholder() {
    return (
        <svg
            width='469'
            height='316'
            viewBox='0 0 469 316'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect opacity='0.6' x='362' y='71' width='107' height='40' rx='4' fill='currentColor' />
            <rect
                opacity='0.6'
                x='362'
                y='119'
                width='107'
                height='40'
                rx='4'
                fill='currentColor'
            />
            <rect
                opacity='0.6'
                x='362'
                y='167'
                width='107'
                height='40'
                rx='4'
                fill='currentColor'
            />
            <rect
                opacity='0.6'
                x='362'
                y='215'
                width='107'
                height='40'
                rx='4'
                fill='currentColor'
            />
            <rect
                opacity='0.6'
                x='362'
                y='265'
                width='107'
                height='40'
                rx='4'
                fill='currentColor'
            />
            <rect opacity='0.6' width='306' height='47' rx='4' fill='currentColor' />
            <rect opacity='0.6' y='102' width='354' height='214' rx='4' fill='currentColor' />
            <rect opacity='0.6' y='71' width='354' height='23' rx='4' fill='currentColor' />
        </svg>
    );
}
