/* @flow */

export function getTimezoneAbbreviation(timestamp: number): ?string {
    const timezoneName = new Intl.DateTimeFormat(undefined, {timeZoneName: 'short'})
        .formatToParts(new Date(timestamp))
        .find((part) => part.type === 'timeZoneName');

    // Ex: returns timezone in abbreviated format such as: EST, PDT, etc.
    return timezoneName ? timezoneName.value : null;
}
