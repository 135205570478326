/* @flow */

import {httpErrorCheck} from '../utils/errors';
import {defaultOptions} from './default-options';

export function deleteRequest(url: string, options?: RequestOptions = {}) {
    const headers: Object = {
        ...defaultOptions.headers,
        ...options.headers,
        'Content-Type': 'application/json',
    };

    return fetch(url, {
        ...defaultOptions,
        ...options,
        method: 'DELETE',
        headers,
    }).then(httpErrorCheck);
}
