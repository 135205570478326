/* @flow */

import * as React from 'react';
import {Field, reduxForm} from 'redux-form';
import type {FormProps} from 'redux-form/lib/types.js.flow';

import type {Task as TaskFragment} from 'nutshell-graphql-types';

import {ReduxFormTextField, ReduxFormDateTime} from 'shells/redux-form-fields';
import {nonNull} from 'shells/form/validation';
import {TickCircleIcon} from 'shells/icon';

import get from 'lodash/get';

import {FormWrapper} from '../form-wrapper/form-wrapper';
import {DefaultFooter} from '../default-footer/default-footer';
import {TaskFooter} from '../footers/task-footer';

import type {FlashMessage} from '../../creator/types';
import {AssigneeSelectField} from '../../core/form/fields/assignee-select-field';
import {EntitiesSelect} from '../../core/form/fields';
import {FormField} from '../../../client_modules/shells/react/components/form';

import '../form-common.css';

type Props = {
    // This will only exist when viewing the task page tasks/{slug}
    task: ?TaskFragment,
    isReadOnly: boolean,
    onCancelClick: () => any,
    onUpdateTask: () => any,

    handleSubmit: $PropertyType<FormProps, 'handleSubmit'>,
    reset: $PropertyType<FormProps, 'reset'>,
    submitting: $PropertyType<FormProps, 'submitting'>,
    submitFailed: $PropertyType<FormProps, 'submitFailed'>,

    pushFlashMessage: (FlashMessage) => void,

    // `form` is used by redux-form
    form: string, // eslint-disable-line react/no-unused-prop-types
};

export function TaskFormConnectedComponent(props: Props) {
    function handleCancelClick() {
        props.onCancelClick();
        props.reset();
    }

    const handleToggleTaskCompletion = (isCompleting: boolean) => {
        props.pushFlashMessage({
            message: `This task was ${isCompleting ? 'completed' : 'updated'} successfully.`,
        });

        // Callback to refetch our task when updated
        props.onUpdateTask();
    };

    const footerComponent =
        props.task && props.isReadOnly ? (
            <TaskFooter
                task={props.task}
                onToggleTaskCompletion={handleToggleTaskCompletion}
                onUpdateTask={props.onUpdateTask}
            />
        ) : (
            <DefaultFooter
                onCancel={handleCancelClick}
                isSaving={props.submitting}
                errorSaving={props.submitFailed}
                buttonText={props.task ? undefined : 'Create task'}
                savingText={props.task ? undefined : 'Creating task…'}
            />
        );

    return (
        <form styleName='form' onSubmit={props.handleSubmit}>
            <FormWrapper icon={TickCircleIcon} reset={props.reset} footer={footerComponent}>
                <Field
                    name='title'
                    title='Name'
                    disabled={props.isReadOnly}
                    component={ReduxFormTextField}
                    isRequired={true}
                    autoFocus={!NutshellFrontendSettings.isDemoSilo}
                />
                {shouldIncludeField('dueTime') ? (
                    <Field
                        name='dueTime'
                        title='Due date'
                        disabled={props.isReadOnly}
                        component={ReduxFormDateTime}
                    />
                ) : null}
                {shouldIncludeField('links.assignee') ? (
                    <AssigneeSelectField
                        fieldKey='links.assignee'
                        isRequired={true}
                        validate={[nonNull]}
                        isDisabled={props.isReadOnly}
                    />
                ) : null}
                {shouldIncludeField('links.relatedEntity') ? (
                    <FormField
                        key='links.relatedEntity'
                        innerId='links.relatedEntity'
                        label='Related to'
                    >
                        <Field
                            name='links.relatedEntity'
                            component={EntitiesSelect}
                            isMulti={false}
                            disabled={props.isReadOnly}
                        />
                    </FormField>
                ) : null}
                {shouldIncludeField('description') ? (
                    <Field
                        name='description'
                        title='Notes'
                        disabled={props.isReadOnly}
                        multiline={true}
                        component={ReduxFormTextField}
                    />
                ) : null}
            </FormWrapper>
        </form>
    );

    function shouldIncludeField(fieldName: string) {
        // If we're in edit mode, always include the field
        if (!props.isReadOnly) return true;

        // If we somehow opened the form in readOnly without a task, hide everything.  :(
        if (!props.task) return false;

        if (fieldName === 'links.assignee') {
            return props.task.assignee;
        }

        if (fieldName === 'links.relatedEntity') {
            return props.task.relatedEntity;
        }

        // If read only mode, we only want to show non-empty fields
        return get(props.task, fieldName);
    }
}

export const TaskFormConnected = reduxForm()(TaskFormConnectedComponent);
