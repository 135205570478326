/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import {useManageSaveState} from '../../saved-setting-toast';
import {SavingIcon} from '../../icon';
import {Button, type ButtonStyleProps} from '../button';

import './save-button.css';

type Props = {
    isSaving: boolean,
    onClick?: (e: SyntheticInputEvent<*>) => Promise<*> | void,
    buttonText: string, // (optional) default: 'Save'
    savingText: string, // (optional) default: 'Saving…'
    successText: string, // (optional) default: 'Success!'
    onClearSaveComplete?: () => any, // Action to complete after successful save
    saveCompleteTimeout?: number, // Time to wait before performing onClearSaveComplete action
    errorSaving?: boolean, // If there was an error saving, will reset button to initial state
    resetOnComplete?: boolean, // Reset button to initial state after save completes
    justifyCenter?: boolean, // (optional) default: false
    ...ButtonStyleProps,
};

SaveButton.defaultProps = {
    saveCompleteTimeout: 3000,
    buttonText: 'Save',
    savingText: 'Saving',
    successText: 'Success!',
    variant: 'save',
    size: 'big',
    getButtonRef: () => {},
};

/**
 * This component acts as a wrapper around the Button component and manages the button text and saving icon that
 * appears within the Button it renders based on the 'isSaving' prop.
 *
 * Parent can manage an error state, passing 'errorSaving' boolean to this component to reset to initial state on error.
 */
export function SaveButton(props: Props) {
    const timeout = React.useRef();

    const {
        onClick,
        isSaving,
        buttonText,
        savingText,
        successText,
        onClearSaveComplete,
        saveCompleteTimeout,
        errorSaving,
        resetOnComplete,
        variant,
        justifyCenter,
        ...restProps
    } = props;

    // Hook used to manage the saveState, the customSaveState returns as saveButtonText and uses the values of
    // buttonText, savingText, and successText passed to this component so the saveState can be displayed
    // directly in the UI as the button text
    // Save failed
    // Hook used to manage the saveState
    const {
        // The current saveState as the corresponding string passed to this component so the
        // save state can be directly displayed in the UI as the button text
        customSaveState: saveButtonText,
        // A function to call if there was an error saving, which will reset the saveState to initial
        saveFailed,
    } = useManageSaveState(
        // The isSaving state managed by the parent component
        isSaving,
        // If the save button should be reset to initial after a save has completed successfully - timeout is
        // one second longer than the saveCompleteTimeout to ensure the onClearSaveComplete action was completed
        resetOnComplete ? saveCompleteTimeout + 1000 : undefined,
        // Using the buttonText, savingText, and successText as the custom save text
        {
            initial: buttonText,
            saving: savingText,
            complete: successText,
        }
    );

    // saveCompleted will be true if the saveButtonText is equal to the successText (since it could be a custom value)
    const saveCompleted = saveButtonText === successText;

    /** Effect runs when errorSaving changes between true and false */
    React.useEffect(() => {
        // If there was an error saving, we want to call the saveFailed function provided by the useManageSaveState
        // hook to reset the state back to initial to ensure the 'complete' state is never reached
        if (errorSaving) {
            saveFailed();
        }
    }, [errorSaving]); // eslint-disable-line react-hooks/exhaustive-deps

    /** Effect runs when saveCompleted variable changes between true and false */
    React.useEffect(() => {
        // If the save is complete, and there is an action to perform after clearing the save complete state
        if (saveCompleted && onClearSaveComplete) {
            timeout.current = setTimeout(() => {
                if (typeof onClearSaveComplete === 'function') {
                    onClearSaveComplete();
                }
            }, saveCompleteTimeout);
        }
    }, [saveCompleted]); // eslint-disable-line react-hooks/exhaustive-deps

    /** Effect that runs when component unmounts */
    React.useEffect(() => {
        // Component cleanup to clear timeout if running
        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, []);

    /** Function handles when the saveButton is clicked */
    function handleClick(e: SyntheticInputEvent<*>) {
        // If currently saving, or save has already completed, we don't want to trigger a save or submit again
        if (isSaving || saveCompleted) {
            e.preventDefault();
        } else if (typeof onClick === 'function') {
            // onClick is not required as saveButton is a submit button by default
            onClick(e);
        }
    }

    // text-add variant isn't directly compatible because the '+' added by Button is a pseudo element
    const buttonVariant = variant === 'text-add' ? 'text-primary' : variant;
    const classNames = classnames('flex', {
        'justify-center': !restProps.isFullWidth || justifyCenter,
    });

    return (
        <Button type='submit' {...restProps} variant={buttonVariant} onClick={handleClick}>
            <div className={classNames} styleName={variant === 'text-add' ? 'text-add' : ''}>
                <span>{saveButtonText}</span>
                {saveButtonText !== buttonText ? (
                    <div styleName='save-icon'>
                        <SavingIcon size={16} isSaving={isSaving} fill='currentColor' />
                    </div>
                ) : (
                    undefined
                )}
            </div>
        </Button>
    );
}
