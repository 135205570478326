/* @flow */

import * as React from 'react';

import type {EventFragmentSparse} from 'nutshell-graphql-types';

import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {|event: EventFragmentSparse|};

export function TimelineBannerMailchimp(props: Props) {
    const {event} = props;

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'Contact' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (event.payload.__typename === 'Contact') {
        if (event.changes[0] && event.changes[0].newValue) {
            const mailChimpData = JSON.parse(event.changes[0].newValue);

            const getAction = () => {
                if (
                    mailChimpData.event === 'subscribed' ||
                    mailChimpData.event === 'unsubscribed'
                ) {
                    return (
                        <span>
                            {getVerb(mailChimpData.event)}&nbsp;<i>{mailChimpData.list_name}</i>
                            &nbsp;with&nbsp;
                            <i>{mailChimpData.email}</i>
                        </span>
                    );
                } else if (mailChimpData.event === 'hard') {
                    return (
                        <span>
                            {getVerb(mailChimpData.event)}&nbsp;<i>{mailChimpData.campaign_name}</i>
                            &nbsp;to&nbsp;
                            <i>{mailChimpData.email}</i>
                        </span>
                    );
                } else {
                    return (
                        <span>
                            {getVerb(mailChimpData.event)}&nbsp;
                            <i>{mailChimpData.campaign_name}</i>&nbsp;via&nbsp;
                            <i>{mailChimpData.email}</i>
                        </span>
                    );
                }
            };

            const actor = mailChimpData.event === 'hard' ? undefined : event.actor;

            return (
                <TimelineBanner
                    id={event.id}
                    iconVariant='mailchimp'
                    timestamp={event.changeTime}
                    actor={actor}
                    action={getAction()}
                    omitActor={mailChimpData.event === 'hard'}
                />
            );
        }
    }

    return null;
}

function getVerb(eventType: string) {
    switch (eventType) {
        case 'open':
            return 'opened';
        case 'click':
            return 'clicked';
        case 'sent':
            return 'was sent';
        case 'subscribed':
            return 'subscribed to';
        case 'unsubscribed':
            return 'unsubscribed from';
        case 'hard':
            return 'Email hard bounced while sending';
        default:
            return 'was sent';
    }
}
