/* @flow */

import * as React from 'react';
import {Checkbox} from '../checkbox/checkbox';
import {TextField} from '../textfield';

import './textfield-with-checkbox.css';

type Props = {
    id: string,
    name: string,
    textfieldValue: string,
    onTextfieldChange: (newValue: string) => void,
    placeholder: string,
    isReadOnly?: boolean,
    autoFocus?: boolean,
    hasError?: boolean,
    checkbox?: {
        label: string,
        checked: boolean,
        onChange: () => void,
    },
};

TextfieldWithCheckbox.defaultProps = {
    textfieldValue: '',
};

/* Responsible for rendering a checkbox with a label inside of
of a textfield, floated to the right. When the checkbox is checked
the textfield is automatically disabled. */
export function TextfieldWithCheckbox(props: Props) {
    const checkboxContainerRef = React.createRef<HTMLElement>();
    const textFieldRef = React.useRef<?HTMLInputElement>();

    React.useLayoutEffect(() => {
        if (
            checkboxContainerRef &&
            checkboxContainerRef.current &&
            textFieldRef &&
            textFieldRef.current
        ) {
            const textField: HTMLInputElement = textFieldRef.current;

            const width = checkboxContainerRef.current.getBoundingClientRect().width;

            textField.style.paddingRight = `${width + 30}px`;
            textField.style.textOverflow = 'ellipsis';
        }
    }, [checkboxContainerRef, textFieldRef]);

    return (
        <div styleName='textfield-with-checkbox'>
            <TextField
                id={props.id}
                name={props.name}
                autoFocus={props.autoFocus}
                value={props.textfieldValue}
                placeholder={props.placeholder}
                onChange={props.onTextfieldChange}
                disabled={(props.checkbox && props.checkbox.checked) || props.isReadOnly}
                hasError={props.hasError}
                textFieldRef={textFieldRef}
            />
            {props.checkbox ? (
                <div styleName='checkbox-container' ref={checkboxContainerRef}>
                    <Checkbox
                        name={props.name}
                        isLarge={true}
                        label={props.checkbox.label}
                        onChange={props.checkbox.onChange}
                        checked={props.checkbox.checked}
                        disabled={props.isReadOnly}
                    />
                </div>
            ) : (
                undefined
            )}
        </div>
    );
}
