import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import HtmlList from './icon-html-list.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <HtmlList {...validProps} />;
}
SvgComponent.iconName = 'html-list';

export const HtmlListIcon = svgIconWrapper(SvgComponent);
