/* @flow */

import * as React from 'react';

import {useDispatch} from 'react-redux';

import type {McfxCompanyFragment as McfxCompany} from 'nutshell-graphql-types';

import {VisitorSidebar} from 'shells/visitor-sidebar';
import {getSessionDetails} from 'shells/visitor-sidebar/helpers';
import type {Company} from 'shells/visitor-sidebar/visitor-content/visitor-content';

import {addNotification} from '../../../../notifications/index';
import {appHistory} from '../../../../history';
import {useGetMcfxViqContacts} from '../../../people-iq/graphql/hooks/use-get-mcfx-viq-contacts';
import {useUpdateMcfxViqCompany} from '../graphql/hooks/use-update-mcfx-viq-company';
import {useGetUnlockInfo} from '../../../people-iq/graphql/hooks/use-get-unlock-info';
import * as actions from '../companies-list/companies-actions';
import {useGetMcfxCompany} from '../graphql/hooks/use-get-mcfx-company';
import {VisitorTimeline} from './visitor-timeline';
import {AddCompanyPopover} from './add-company-popover';

type Props = {|
    visitorId: string,
    onClose: () => void,
|};

export function CompanyVisitorsSidebar(props: Props) {
    const [isConfirmingAdd, setIsConfirmingAdd] = React.useState<boolean>(false);

    const dispatch = useDispatch();

    const {mcfxCompany} = useGetMcfxCompany(props.visitorId);
    const {unlockInfo} = useGetUnlockInfo();
    const {
        mcfxViqContacts,
        refetch: refetchPeople,
        isRefetching: isRefetchingPeople,
    } = useGetMcfxViqContacts(mcfxCompany && mcfxCompany.id);
    const {updateMcfxViqCompany, isLoading: isUpdatingCompany} = useUpdateMcfxViqCompany();

    const confirmationPopoverRef = React.useRef({
        anchor: null,
        selectedPeopleIds: [],
        onConfirmAdd: () => {},
    });

    const resetRef = React.useCallback(() => {
        confirmationPopoverRef.current = {
            anchor: null,
            selectedPeopleIds: [],
            onConfirmAdd: () => {},
        };
    }, []);

    return (
        <>
            <VisitorSidebar
                onClose={props.onClose}
                company={
                    mcfxCompany && {
                        ...getCompanyForSidebar(mcfxCompany),
                        sessionsTimeline: (
                            <VisitorTimeline
                                companyId={mcfxCompany.id}
                                companyName={mcfxCompany.name}
                            />
                        ),
                    }
                }
                people={
                    mcfxViqContacts &&
                    mcfxViqContacts.map((mcfxViqContact) => ({
                        id: mcfxViqContact.id,
                        name: mcfxViqContact.fullName,
                        location: mcfxViqContact.location,
                        jobTitle: mcfxViqContact.jobTitle,
                        position: mcfxViqContact.position,
                        hasEmail: mcfxViqContact.hasEmail,
                        hasPhone: mcfxViqContact.hasPhone,
                        hasLinkedinUrl: mcfxViqContact.hasLinkedinUrl,
                        contact: mcfxViqContact.contact
                            ? {
                                  htmlUrlPath: mcfxViqContact.contact.htmlUrlPath,
                                  email:
                                      mcfxViqContact.contact &&
                                      mcfxViqContact.contact.emailAddresses.primary &&
                                      mcfxViqContact.contact.emailAddresses.primary.value.address,
                                  phone:
                                      mcfxViqContact.contact &&
                                      mcfxViqContact.contact.phones.primary &&
                                      mcfxViqContact.contact.phones.primary.value.numberFormatted,
                              }
                            : undefined,
                    }))
                }
                onAddPeople={(selectedPeopleIds) =>
                    updateMcfxViqCompany({
                        mcfxCompanyId: mcfxCompany.id,
                        mcfxViqContactIds: selectedPeopleIds,
                        createRelatedLead: false,
                    })
                        .then(() => refetchPeople())
                        .then(() => {
                            resetRef();
                        })
                }
                onAddCompany={(selectedPeopleIds, buttonRef, onConfirmAdd) => {
                    confirmationPopoverRef.current = {
                        anchor: buttonRef,
                        selectedPeopleIds,
                        onConfirmAdd,
                    };
                    setIsConfirmingAdd(true);
                }}
                isDisabled={isUpdatingCompany || isRefetchingPeople}
                unlocksRemaining={unlockInfo && unlockInfo.unlocksRemaining}
            />
            {isConfirmingAdd && mcfxCompany && confirmationPopoverRef.current.anchor && (
                <AddCompanyPopover
                    anchor={confirmationPopoverRef.current.anchor}
                    name={mcfxCompany.name}
                    selectedPeopleIds={confirmationPopoverRef.current.selectedPeopleIds}
                    onClose={() => setIsConfirmingAdd(false)}
                    onCreate={(stagesetId: ?string) =>
                        updateMcfxViqCompany({
                            mcfxCompanyId: mcfxCompany.id,
                            mcfxViqContactIds: confirmationPopoverRef.current.selectedPeopleIds,
                            createRelatedLead: Boolean(stagesetId),
                            stagesetId,
                        }).then((response) => {
                            const updatedCompany =
                                response &&
                                response.data &&
                                response.data.mcfxViqCompanyUpdate &&
                                response.data.mcfxViqCompanyUpdate.mcfxCompany;

                            if (updatedCompany) {
                                dispatch(
                                    actions.updateMcfxCompaniesListItems({
                                        updatedCompanies: [updatedCompany],
                                    })
                                );

                                const companyHtmlUrlPath = updatedCompany.relatedAccount
                                    ? updatedCompany.relatedAccount.htmlUrlPath
                                    : undefined;

                                if (companyHtmlUrlPath) {
                                    addNotification('Added new company', {
                                        body: updatedCompany.name,
                                        icon: 'check',
                                        onClick: () => {
                                            appHistory.push(companyHtmlUrlPath);
                                            props.onClose();
                                        },
                                    });
                                }
                            }

                            refetchPeople();
                            confirmationPopoverRef.current.onConfirmAdd();
                            resetRef();
                        })
                    }
                    unlockInfo={unlockInfo}
                />
            )}
        </>
    );
}

const getCompanyForSidebar = (mcfxCompany: McfxCompany): ?Company => {
    const mcfxCompanyLead =
        mcfxCompany.relatedAccount &&
        mcfxCompany.relatedAccount.leads.edges &&
        mcfxCompany.relatedAccount.leads.edges[0] &&
        mcfxCompany.relatedAccount.leads.edges[0].node;

    let relatedPerson, relatedCompany;
    if (mcfxCompanyLead) {
        if (mcfxCompanyLead.contacts.primaryEdge) {
            relatedPerson = {
                type: 'contacts',
                id: mcfxCompanyLead.contacts.primaryEdge.node.id,
                name: mcfxCompanyLead.contacts.primaryEdge.node.name,
                htmlUrl: mcfxCompanyLead.contacts.primaryEdge.node.htmlUrl,
            };
        }

        if (mcfxCompanyLead.accounts.primaryEdge) {
            relatedCompany = {
                type: 'accounts',
                id: mcfxCompanyLead.accounts.primaryEdge.node.id,
                name: mcfxCompanyLead.accounts.primaryEdge.node.name,
                htmlUrl: mcfxCompanyLead.accounts.primaryEdge.node.htmlUrl,
            };
        }
    }

    return {
        details: {
            name: mcfxCompany.name,
            timestamp:
                mcfxCompany.convertedTime || mcfxCompany.lastSeenTime || mcfxCompany.createdTime,
            htmlUrlPath: mcfxCompany.relatedAccount
                ? mcfxCompany.relatedAccount.htmlUrlPath
                : undefined,
            wasConvertedAutomatically: mcfxCompany.wasConvertedAutomatically,
            url: mcfxCompany.url,
            location: mcfxCompany.primaryAddress && mcfxCompany.primaryAddress.region,
            industry: mcfxCompany.industry && mcfxCompany.industry.name,
            revenue: mcfxCompany.annualRevenue && mcfxCompany.annualRevenue.amount,
            numEmployees: mcfxCompany.numEmployees,
        },
        lead: mcfxCompanyLead
            ? {
                  leadName: mcfxCompanyLead.name,
                  htmlUrl: mcfxCompanyLead.htmlUrl,
                  htmlUrlPath: mcfxCompanyLead.htmlUrlPath,
                  leadStatus: mcfxCompanyLead.status,
                  avatarUrl: mcfxCompanyLead.avatarUrl,
                  relatedPerson,
                  relatedCompany,
                  formattedValue: mcfxCompanyLead.value
                      ? {
                            formatted: mcfxCompanyLead.value.formattedValue.formatted,
                            prefix: mcfxCompanyLead.value.formattedValue.prefix,
                            suffix: mcfxCompanyLead.value.formattedValue.suffix,
                            value: mcfxCompanyLead.value.formattedValue.value,
                        }
                      : undefined,
              }
            : undefined,
        session: getSessionDetails(mcfxCompany.numVisits, mcfxCompany.totalVisitDuration),
    };
};
