/* @flow */

import * as React from 'react';

import {SelectPickerGeneric} from 'shells/select-picker-generic';

import {getDefaultStagesetId} from '../../../../util/get-default-stageset-id';
import {useGetStagesets} from '../../select/hooks/use-get-stagesets';
import {AUTO_DISTRIBUTE_OPTION} from './consts';

type Props = {|
    selectedPipeline: ?string,
    onSelectPipeline: (string) => void,
    minHeight?: number,
    autoselectDefault?: boolean,
    menuPlacement?: 'auto' | 'top' | 'bottom',
    useAutoDistribute?: boolean,
|};

export function PipelineSelectPicker(props: Props) {
    const {stagesets, isLoading} = useGetStagesets();
    const {selectedPipeline, onSelectPipeline, autoselectDefault} = props;

    const options = stagesets.map((stageset) => {
        return {
            isDisabled: !stageset.canAccess,
            label: stageset.name,
            value: stageset.id,
        };
    });

    if (props.useAutoDistribute) {
        options.unshift({...AUTO_DISTRIBUTE_OPTION, isDisabled: false});
    }

    const value = options.find((opt) => opt.value === selectedPipeline);
    const defaultPipeline = options.find((opt) => opt.value === getDefaultStagesetId());

    const onChange = React.useCallback(
        (option) => {
            if (option && !Array.isArray(option)) {
                onSelectPipeline(option.value);
            }
        },
        [onSelectPipeline]
    );

    React.useEffect(() => {
        if (autoselectDefault && defaultPipeline && !value) {
            onChange(defaultPipeline);
        }
    }, [autoselectDefault, defaultPipeline, value, onChange]);

    return (
        <SelectPickerGeneric
            minHeight={props.minHeight}
            placeholder='Select a pipeline…'
            isLoading={isLoading}
            autoFocus={false}
            openMenuOnFocus={true}
            options={options}
            onChange={onChange}
            value={value}
            menuPlacement={props.menuPlacement}
        />
    );
}
