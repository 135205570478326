/* @flow */

import * as React from 'react';

import {AccentBorder} from '../accent-border';
import {TwoLine} from '../two-line/two-line';

import {Card} from './card';

type Props = {|
    topRow: string | React.Node,
    bottomRow: string | React.Node,
    isLargeWithSmallBottomRow?: boolean,
    accentColor?: string,
|};

export function CardTwoLine(props: Props) {
    return (
        <Card hasBorder={true} backgroundColor='white' fitHeight={true}>
            <div className='flex align-center justify-sb'>
                <AccentBorder color={props.accentColor || 'transparent'}>
                    <div className='pad-16'>
                        <TwoLine
                            topRow={props.topRow}
                            bottomRow={props.bottomRow}
                            isLargeWithSmallBottomRow={props.isLargeWithSmallBottomRow}
                        />
                    </div>
                </AccentBorder>
            </div>
        </Card>
    );
}
