/* @flow */

import {defaultOptions} from '../api/default-options';
import type {Coupon} from './billing-types';

type CheckCouponResponse = {
    coupon: ?Coupon,
};

export function checkCoupon(couponCode: string): Promise<CheckCouponResponse> {
    const formData = new FormData();
    formData.set('couponCode', couponCode);

    return fetch('/setup/billing/check-coupon.json', {
        method: 'POST',
        body: formData,
        ...defaultOptions,
    }).then((response) => response.json());
}
