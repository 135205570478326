import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 24.1 40.8'}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                fill='#1FB790'
                d='M19.8 17.7c-2.3 1.6-4.9 2.3-7.6 2-1 2.6-.2 6.3.2 7.6h4.9c.8 0 1.2.7 1.1 1.5l-2.1 10.5c-.2.8-.9 1.5-1.7 1.5H7.2c-.8 0-1.5-.7-1.7-1.5L3.4 28.7c-.2-.8.3-1.5 1.1-1.5h4.6c.2-2 .8-6.5 1.9-9.1 1.3-6.6 6.5-10.7 12.7-9.6.3.1.6.4.5.8-.6 3.3-2.1 6.3-4.4 8.4zm-12 .6C.8 15.2-1.8 7.6 1.2.3c.2-.4.7-.4 1-.3C5.3 1.4 8 4 9.4 7.2c0 0 0 .1.1.1 1.1 3.5.7 7.3-.7 10.6-.2.6-.7.6-1 .4z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'plant-filled-medium';

export const PlantMediumFilledIcon = svgIconWrapper(SvgComponent);
