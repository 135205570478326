/* @flow */

import * as React from 'react';

import type {Tab} from 'shells/control-bar';

type Props = {
    selectedTab: string,
    tabOptions: Tab[],
};

export function TabbedContent(props: Props) {
    const {selectedTab, tabOptions} = props;
    const selectedTabContent = tabOptions.find((option) => option.id === selectedTab);

    return selectedTabContent && selectedTabContent.component ? (
        selectedTabContent.component
    ) : (
        <></>
    );
}
