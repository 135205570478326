/* @flow */

import moment from 'moment';
import {isToday, isYesterday, isTomorrow} from 'nutshell-core/date-time';

/**
 * Return a text string to use in a date header
 *
 * @param  {Object} date                      A moment.js date object
 * @param  {string} [customFormat='MMMM Do']  The format to use for dates.  Defaults to 'MMMM Do'
 * @param  {Object} [now=moment()]            A moment.js object to override the current date (mostly for testing)
 * @return {string}                           Formatted string
 */
export function formatDateForHeader(
    date: moment$Moment,
    customFormat: string = 'MMMM Do',
    now: moment$Moment = moment()
) {
    let formattedDate = date.format(customFormat);

    if (isToday(date, now)) {
        formattedDate = `Today: ${date.format('MMMM Do')}`;
    } else if (isTomorrow(date, now)) {
        formattedDate = `Tomorrow: ${date.format('MMMM Do')}`;
    } else if (isYesterday(date, now)) {
        formattedDate = `Yesterday: ${date.format('MMMM Do')}`;
    }

    return formattedDate;
}
