/* @flow */

import moment from 'moment';
import {getDurationString} from './get-duration-string';

/**
 * Gets the current quarter based on the relative date based, and the fiscal year
 * start setting.
 * @param  {Moment} relativeToDate     Moment object to get relative current quarter
 * @param  {string} workWeekOffset     Setting-like string of workWeekOffset (1-7)
 * @return {Moment}                    Moment object for start of work week relative to `relativeToDate`
 */
export function getWeekStart(relativeToDate: Object, workWeekOffset: string): moment {
    if (workWeekOffset === '1') {
        // Monday, shift forward 1 day
        return relativeToDate
            .clone()
            .startOf(getDurationString('w'))
            .add(1, 'days');
    } else if (workWeekOffset === '6') {
        // Saturday, shift back one day
        return relativeToDate
            .clone()
            .startOf(getDurationString('w'))
            .subtract(1, 'days');
    } else {
        return relativeToDate.clone().startOf(getDurationString('w'));
    }
}

/**
 * Gets the current quarter based on the relative date based, and the fiscal year
 * start setting.
 * @param  {Moment} relativeToDate     Moment object to get relative current quarter
 * @param  {string} workWeekOffset     Setting-like string of workWeekOffset (1-7)
 * @return {Moment}                    Moment object for end of work week relative to `relativeToDate`
 */
export function getWeekEnd(relativeToDate: Object, workWeekOffset: string): moment {
    if (workWeekOffset === '1') {
        // Monday, shift forward 1 day
        return relativeToDate
            .clone()
            .endOf(getDurationString('w'))
            .add(1, 'days');
    } else if (workWeekOffset === '6') {
        // Saturday, shift back one day
        return relativeToDate
            .clone()
            .endOf(getDurationString('w'))
            .subtract(1, 'days');
    } else {
        return relativeToDate.clone().endOf(getDurationString('w'));
    }
}
