/* @flow */

import * as React from 'react';

import type {LeadAssociationInput as LeadAssociation} from 'nutshell-graphql-types';

import type {Lead} from 'shells/timeline/types';
import {LeadIcon} from 'shells/icon';
import {IconButton} from 'shells/button';
import {MiniListPopover} from 'shells/mini-list-popover/mini-list-popover';
import {LeadTwoLine} from 'shells/lead';

type Props = {
    relatedLeads: Lead[],
    updateEmailRelatedLeadAssociations: (updatedLeadAssociations: LeadAssociation[]) => Promise<*>,
};

export function EmailRelatedLeadsButton(props: Props) {
    const {relatedLeads} = props;

    const [isRelatedLeadsPopoverOpen, setIsRelatedLeadsPopoverOpen] = React.useState<boolean>(
        false
    );
    const [isSaving, setIsSaving] = React.useState<boolean>(false);

    const relatedLeadsButtonRef = React.useRef();
    const isMounted = React.useRef<boolean>(false);

    const handleUpdateRelatedLeads = (updatedRelatedLeads: Lead[]) => {
        const updatedLeadAssociations = getLeadAssociationsFromRelatedLeads(updatedRelatedLeads);

        // Check if lead associations have actually been updated
        if (
            hasLeadAssociationChanged(
                getLeadAssociationsFromRelatedLeads(relatedLeads),
                updatedLeadAssociations
            )
        ) {
            setIsSaving(true);

            // Moved to parent so entire entry re-renders
            props.updateEmailRelatedLeadAssociations(updatedLeadAssociations).then(() => {
                if (isMounted.current) {
                    setIsSaving(false);
                    setIsRelatedLeadsPopoverOpen(false);
                }
            });
        } else {
            setIsRelatedLeadsPopoverOpen(false);
        }
    };

    React.useEffect(() => {
        // Necessary for component cleanup if lead is removed while on that lead's timeline
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <>
            {/* $FlowIgnore - the lead icon props are different from our other icons */}
            <IconButton
                icon={LeadIcon}
                getButtonRef={relatedLeadsButtonRef}
                tooltipText='Related leads'
                onClick={() => {
                    setIsRelatedLeadsPopoverOpen(true);
                }}
            />
            {isRelatedLeadsPopoverOpen ? (
                <MiniListPopover
                    anchor={relatedLeadsButtonRef}
                    headerText='Show email on selected leads:'
                    onBlur={handleUpdateRelatedLeads}
                    isSaving={isSaving}
                    collection={relatedLeads}
                    renderRow={(lead) => (
                        <LeadTwoLine
                            leadName={lead.name}
                            leadNumber={lead.number}
                            avatarUrl={lead.avatarUrl}
                            htmlUrl={lead.htmlUrl}
                            leadStatus={lead.status}
                            priority={lead.priority}
                            relatedPerson={lead.relatedPerson}
                            relatedCompany={lead.relatedCompany}
                            withLessPadding={true}
                            isLinkable={true}
                            newTab={true}
                        />
                    )}
                />
            ) : (
                undefined
            )}
        </>
    );
}

function hasLeadAssociationChanged(
    currentAssociations: LeadAssociation[],
    updatedAssociations: LeadAssociation[]
): boolean {
    return JSON.stringify(currentAssociations) !== JSON.stringify(updatedAssociations);
}

function getLeadAssociationsFromRelatedLeads(relatedLeads: Lead[]): LeadAssociation[] {
    return relatedLeads.map((lead) => ({leadId: lead.id, isMapped: Boolean(lead.isChecked)}));
}
