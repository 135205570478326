/* @flow */

const NEW_LINE_REGEX = /<p><br><\/p>/g;

export function isRichTextareaEmpty(htmlValue: string, plaintextValue: string): boolean {
    return !(htmlValue.replace(NEW_LINE_REGEX, '').length && plaintextValue.trim().length);
}

// By default, editor.getText() returns all string insert operations
// and joins them together to produce the "plaintext" version of the
// quill editor contents.
// However, we insert custom `mention` embed that get filtered out,
// but we want a @[users:2-users] representation of that stored in our
// text version of that in the database. This method simply extends
// the root `getText()` call here:
// https://github.com/quilljs/quill/blob/master/core/editor.js#L143-L148
// To also look for `insert: mention` operations and grab the value
// from those.
export function getTextValueWithCustomEmbeds(editor: any) {
    return editor
        .getContents()
        .ops.map((op) => {
            if (typeof op.insert === 'string') {
                return op.insert;
            }
            if (op.insert.mention) {
                const id = op.insert.mention.id;
                const parts = id.split('-');

                if (parts.length === 2) {
                    // Turning `1-users` into `@[Users:1-users]`
                    return `@[${parts[1]}:${id}]`;
                }

                return '';
            }

            return null;
        })
        .filter((value) => value)
        .join('');
}
