/* @flow */
import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox='0 0 76 76'>
            <path d='M37.9 76C17.134 76 .3 59.166.3 38.4.3 17.634 17.134.8 37.9.8c20.766 0 37.6 16.834 37.6 37.6C75.5 59.166 58.666 76 37.9 76zM11 23.2l7 14.9c.1.3.1.6 0 .9l-6.9 14.7c-.4.8 0 1.7.8 2 .2.1.4.1.6.1H54c1.3 0 2.5-.7 3.1-1.8l7.5-14.1c.5-.9.5-2 0-2.9l-7.5-14.1c-.6-1.1-1.8-1.8-3.1-1.8H12.5c-.9 0-1.6.7-1.6 1.5l.1.6zm32.9 17.7c.1.4.2.8.3 1.1 0 1.4-.5 2.6-1.4 3.6-.8.9-2 1.5-3.4 1.8v2.3c0 .1-.1.3-.2.4-.1.1-.2.2-.4.2H37c-.1 0-.3-.1-.4-.2-.1-.1-.2-.2-.2-.4v-2.3c-.5-.1-1.1-.2-1.6-.4-.6-.2-1-.4-1.4-.6-.3-.2-.7-.4-1-.6-.3-.2-.5-.4-.6-.5-.2-.1-.3-.1-.3-.2-.2-.2-.2-.5 0-.7l1.4-1.8c.1-.1.2-.2.4-.2s.4.1.5.2c1 .8 2.1 1.4 3.2 1.6 1 .2 2 .1 2.8-.4.6-.4.8-.9.8-1.5 0-.2-.1-.4-.2-.6-.1-.2-.3-.4-.4-.5-.1-.2-.4-.3-.7-.5-.6-.3-1.2-.6-1.9-.8h-.1c-.4-.1-.6-.2-.8-.3-.2 0-.4-.1-.8-.3-.4-.1-.7-.3-.9-.4-.2-.1-.5-.3-.8-.5-.3-.2-.5-.4-.7-.6-.2-.2-.4-.4-.6-.7-.2-.3-.4-.5-.5-.8l-.3-.9c-.1-.4-.1-.7-.1-1.1 0-1.3.5-2.4 1.4-3.3.9-.9 2-1.5 3.4-1.8v-2.4c0-.1.1-.3.2-.4.1-.1.2-.2.4-.2H39c.1 0 .3.1.4.2.1.1.2.2.2.4v2.3c.5.1 1 .2 1.4.3.4.1.8.2 1.2.4.3.1.6.3.9.5.1.1.3.3.5.4l.2.2c.2.2.2.5.1.7l-1.1 1.9c-.1.2-.3.3-.5.3-.2.1-.3 0-.5-.1l-.2-.2c-.1 0-.3-.2-.5-.3-.3-.1-.5-.3-.8-.4-.3-.1-.6-.2-1-.3-.4-.1-.7-.1-1.1-.1-.8 0-1.5.1-2 .5-.5.3-.7.8-.7 1.3 0 .2 0 .4.1.6.1.2.2.4.4.5.1.1.3.3.5.4.2.2.4.3.7.4.3.1.6.2.8.3.2.1.5.2.9.4.5.2.8.3 1.1.4l1 .5c.3.2.7.4 1 .6.3.2.5.5.8.7.3.3.5.6.7.9.2.3.3.6.4 1z' />
        </svg>
    );
}
SvgComponent.iconName = 'leads-circle';

export const LeadsCircleIcon = svgIconWrapper(SvgComponent);
