import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import AlignCenter from './icon-align-center.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <AlignCenter {...validProps} />;
}
SvgComponent.iconName = 'alignCenter';

export const AlignCenterIcon = svgIconWrapper(SvgComponent);
