/* @flow */
import * as React from 'react';
import type {FieldArrayProps} from 'redux-form';
import type {TagType} from 'nutshell-graphql-types';
import type {ListOption} from 'shells/select-option-components/option';
import {GraphQLSelectTagsProvider} from '../../select/graphql-select-tags-provider';
import {CreateableField} from './createable-field';
import {useSetNewItem} from './use-set-new-item';

import './forms.css';

type Props = {
    ...FieldArrayProps,
    shouldAnd?: boolean,
    tagType: TagType,
    autoFocus?: boolean,
    isRequired?: boolean,
};

export function TagSelect(props: Props): React.Node {
    const [inputValue, setInputValue] = React.useState<string>('');
    const [tagOptions, setTagOptions] = React.useState<ListOption[]>([]);
    const [newTag, setNewTag] = React.useState<?string>(null);
    const {fields} = props;
    if (fields.length === 0) {
        // If we don't have any tags, add an empty value
        fields.push(null);
    }
    useSetNewItem(newTag, setNewTag, fields);

    return (
        <GraphQLSelectTagsProvider tagType={props.tagType} searchTerm={inputValue}>
            {({options, isLoading, refetch, fetchMoreTags}) => {
                // if the user searches or fetches more and we fetch a new option not included in tagOptions, add it to the list
                if (options.length && tagOptions.length !== options.length) {
                    const filteredOptions = options.filter(
                        (option) => !tagOptions.find((opt) => opt.value === option.value)
                    );
                    if (filteredOptions.length) {
                        setTagOptions(tagOptions.concat(filteredOptions));
                    }
                }
                // if we have a selected field and it doesn't come back in the first query (say it's tag #501 and we fetch the first 500), we fetch more until we find the one that is selected so we can display it
                const selectedFields = fields.getAll() || [];
                selectedFields.forEach((fieldId) => {
                    if (fieldId && !tagOptions.find((option) => option.value === fieldId)) {
                        fetchMoreTags();
                    }
                });

                return (
                    <CreateableField
                        {...props}
                        setNewItem={(newValue: ?string) => setNewTag(newValue)}
                        setOptions={(newValue: ListOption[]) => setTagOptions(newValue)}
                        onInputChange={(searchTerm: string) => setInputValue(searchTerm || '')}
                        options={tagOptions}
                        refetch={refetch}
                        isLoading={isLoading}
                        type='tags'
                    />
                );
            }}
        </GraphQLSelectTagsProvider>
    );
}
