import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Subheader from './icon-subheader.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Subheader {...validProps} />;
}
SvgComponent.iconName = 'subheader';

export const SubheaderIcon = svgIconWrapper(SvgComponent);
