import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Explore from './icon-explore.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Explore {...validProps} />;
}
SvgComponent.iconName = 'explore';

export const ExploreIcon = svgIconWrapper(SvgComponent);
