/* @flow */

import * as React from 'react';
import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';

type Props = {
    fill?: string,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} fill={props.fill || colors.grey} viewBox='0 0 22 22'>
            <path d='M7.567 12.009H3.17v4.727H.657V5.198H3.17v4.596h4.397V5.198h2.512v11.538H7.567V12.01ZM20.454 16.736h-8.166v-2.264l3.554-3.042c.32-.275.59-.523.81-.744.22-.231.397-.452.53-.661.142-.22.242-.435.297-.645.066-.209.099-.435.099-.677V8.47a1.212 1.212 0 0 0-.48-.992 1.496 1.496 0 0 0-.495-.247 1.818 1.818 0 0 0-.546-.083c-.275 0-.518.044-.727.132-.199.088-.375.21-.53.364a1.951 1.951 0 0 0-.363.512c-.099.188-.176.391-.231.612l-2.166-.827c.133-.396.314-.771.546-1.124.231-.363.518-.677.86-.942.352-.275.765-.49 1.24-.645A5.167 5.167 0 0 1 16.287 5c.629 0 1.185.088 1.67.264a3.54 3.54 0 0 1 1.223.728c.342.308.6.678.777 1.107a3.5 3.5 0 0 1 .265 1.372c0 .496-.083.948-.248 1.356a4.746 4.746 0 0 1-.678 1.157 6.97 6.97 0 0 1-.975 1.008c-.375.32-.766.645-1.174.975l-2.149 1.686h5.455v2.083Z' />
        </svg>
    );
}
SvgComponent.iconName = 'h2';

export const H2Icon = svgIconWrapper(SvgComponent);
