import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SidebarIconOnboarding from './sidebar-icon-onboarding.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SidebarIconOnboarding {...validProps} />;
}
SvgComponent.iconName = 'sidebarIconOnboarding';

export const SidebarIconOnboardingIcon = svgIconWrapper(SvgComponent);
