/* @flow */

import * as React from 'react';

import {Link} from '../../../link';
import {DetailsList} from '../../details-list/details-list';

import {CompanyLead, type Lead} from './company-lead';

export type CompanyInfo = {|
    url: ?string,
    location: ?string,
    industry: ?string,
    revenue: ?number,
    numEmployees: ?number,
|};

type Props = {|
    ...CompanyInfo,
    lead: ?Lead,
    onClose: () => void,
|};

export function CompanyDetails(props: Props) {
    const details = [];
    if (props.url) {
        details.push({
            key: 'url',
            name: 'URL',
            value: (
                <Link href={`https://${props.url}`} variant='secondary' newTab={true}>
                    {props.url}
                </Link>
            ),
        });
    }
    if (props.location) {
        details.push({key: 'location', name: 'Location', value: props.location});
    }
    if (props.industry) {
        details.push({key: 'industry', name: 'Industry', value: props.industry});
    }
    if (props.revenue) {
        details.push({
            key: 'revenue',
            name: 'Revenue',
            value: `$${props.revenue.toLocaleString()}`,
            isFullWidth: false,
        });
    }
    if (props.numEmployees) {
        details.push({
            key: 'employees',
            name: 'Employees',
            value: props.numEmployees,
            isFullWidth: false,
        });
    }

    return (
        <>
            {props.lead && <CompanyLead {...props.lead} onClose={props.onClose} />}
            <DetailsList details={details} />
        </>
    );
}
