import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import LinkedIn from './icon-linked-in.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <LinkedIn {...validProps} />;
}
SvgComponent.iconName = 'linkedIn';

export const LinkedInIcon = svgIconWrapper(SvgComponent);
