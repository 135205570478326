/* @flow */

/*
 * This is a stopgap function that takes a response from our list api and converts it into
 * normal string characters so we don't need to use dangerouslySetInnerHtml.
 */
export function temporaryHtmlToString(htmlString: string) {
    const parser = new DOMParser();
    const dom = parser.parseFromString(`<!doctype html><body>${htmlString}`, 'text/html');

    return dom.body && dom.body.textContent;
}
