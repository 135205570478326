/* @flow */

import type {ContactId, Task, TaskId, TasksApiResponse} from '../types';

export const ActionTypes = {
    TASKS_FETCH_FOR_CONTACT_SUCCESS: 'TASKS_FETCH_FOR_CONTACT_SUCCESS',
    TASKS_FETCH_FOR_CONTACT_REQUEST: 'TASKS_FETCH_FOR_CONTACT_REQUEST',
    TASKS_FETCH_FOR_CONTACT_FAILURE: 'TASKS_FETCH_FOR_CONTACT_FAILURE',
    TASKS_FETCH_BY_ID_SUCCESS: 'TASKS_FETCH_BY_ID_SUCCESS',
    TASKS_FETCH_BY_ID_REQUEST: 'TASKS_FETCH_BY_ID_REQUEST',
    TASKS_FETCH_BY_ID_FAILURE: 'TASKS_FETCH_BY_ID_FAILURE',
    TASKS_CREATE_TASK_REQUEST: 'TASKS_CREATE_TASK_REQUEST',
    TASKS_CREATE_TASK_SUCCESS: 'TASKS_CREATE_TASK_SUCCESS',
    TASKS_CREATE_TASK_FAILURE: 'TASKS_CREATE_TASK_FAILURE',
    TASKS_COMPLETE_TASK_REQUEST: 'TASKS_COMPLETE_TASK_REQUEST',
    TASKS_COMPLETE_TASK_SUCCESS: 'TASKS_COMPLETE_TASK_SUCCESS',
    TASKS_COMPLETE_TASK_FAILURE: 'TASKS_COMPLETE_TASK_FAILURE',
    TASKS_UNCOMPLETE_TASK_REQUEST: 'TASKS_UNCOMPLETE_TASK_REQUEST',
    TASKS_UNCOMPLETE_TASK_SUCCESS: 'TASKS_UNCOMPLETE_TASK_SUCCESS',
    TASKS_UNCOMPLETE_TASK_FAILURE: 'TASKS_UNCOMPLETE_TASK_FAILURE',
};

export type FetchTasksForContactRequestAction = {
    type: 'TASKS_FETCH_FOR_CONTACT_REQUEST',
    payload: ContactId,
};
type FetchTasksForContactSuccessAction = {
    type: 'TASKS_FETCH_FOR_CONTACT_SUCCESS',
    payload: {response: TasksApiResponse, contactId: ContactId},
};
type FetchTasksForContactFailureAction = {
    type: 'TASKS_FETCH_FOR_CONTACT_FAILURE',
    payload: ?Object,
    error: true,
};
export type FetchByIdRequestAction = {type: 'TASKS_FETCH_BY_ID_REQUEST', payload: TaskId};
type FetchByIdSuccessAction = {type: 'TASKS_FETCH_BY_ID_SUCCESS', payload: TasksApiResponse};
type FetchByIdFailureAction = {
    type: 'TASKS_FETCH_BY_ID_FAILURE',
    payload: {taskId: TaskId, response?: Object},
    error: true,
};
export type CreateTaskRequestAction = {type: 'TASKS_CREATE_TASK_REQUEST', payload: Object}; // TODO: stronger type than `Object`
export type CreateTaskSuccessAction = {
    type: 'TASKS_CREATE_TASK_SUCCESS',
    payload: TasksApiResponse,
};
type CreateTaskFailureAction = {
    type: 'TASKS_CREATE_TASK_FAILURE',
    payload: {error: ?Object},
    error: true,
};
export type CompleteTaskRequestAction = {type: 'TASKS_COMPLETE_TASK_REQUEST', payload: Task};
type CompleteTaskSuccessAction = {type: 'TASKS_COMPLETE_TASK_SUCCESS', payload: TasksApiResponse};
type CompleteTaskFailureAction = {
    type: 'TASKS_COMPLETE_TASK_FAILURE',
    payload: ?Object,
    error: true,
};
export type UncompleteTaskRequestAction = {type: 'TASKS_UNCOMPLETE_TASK_REQUEST', payload: Task};
type UncompleteTaskSuccessAction = {
    type: 'TASKS_UNCOMPLETE_TASK_SUCCESS',
    payload: TasksApiResponse,
};
type UncompleteTaskFailureAction = {
    type: 'TASKS_UNCOMPLETE_TASK_FAILURE',
    payload: ?Object,
    error: true,
};

export type TasksAction =
    | FetchTasksForContactRequestAction
    | FetchTasksForContactSuccessAction
    | FetchTasksForContactFailureAction
    | FetchByIdRequestAction
    | FetchByIdSuccessAction
    | FetchByIdFailureAction
    | CreateTaskRequestAction
    | CreateTaskSuccessAction
    | CreateTaskFailureAction
    | CompleteTaskRequestAction
    | CompleteTaskSuccessAction
    | CompleteTaskFailureAction
    | UncompleteTaskRequestAction
    | UncompleteTaskSuccessAction
    | UncompleteTaskFailureAction;

export const fetchTasksForContact = (payload: ContactId): FetchTasksForContactRequestAction => ({
    type: ActionTypes.TASKS_FETCH_FOR_CONTACT_REQUEST,
    payload,
});
export const resolveFetchTasksForContact = (
    response: TasksApiResponse,
    contactId: ContactId
): FetchTasksForContactSuccessAction => ({
    type: ActionTypes.TASKS_FETCH_FOR_CONTACT_SUCCESS,
    payload: {response, contactId},
});
export const failFetchTasksForContact = (payload?: Object): FetchTasksForContactFailureAction => ({
    type: ActionTypes.TASKS_FETCH_FOR_CONTACT_FAILURE,
    payload,
    error: true,
});

export const fetchById = (payload: TaskId): FetchByIdRequestAction => ({
    type: ActionTypes.TASKS_FETCH_BY_ID_REQUEST,
    payload,
});
export const resolveFetchById = (payload: TasksApiResponse): FetchByIdSuccessAction => ({
    type: ActionTypes.TASKS_FETCH_BY_ID_SUCCESS,
    payload,
});
export const failFetchById = (taskId: TaskId, response?: Object): FetchByIdFailureAction => ({
    type: ActionTypes.TASKS_FETCH_BY_ID_FAILURE,
    payload: {taskId, response},
    error: true,
});

export const createTask = (payload: Object): CreateTaskRequestAction => ({
    type: ActionTypes.TASKS_CREATE_TASK_REQUEST,
    payload,
});
export const resolveCreateTask = (response: TasksApiResponse): CreateTaskSuccessAction => ({
    type: ActionTypes.TASKS_CREATE_TASK_SUCCESS,
    payload: response,
});
export const failCreateTask = (error?: Object): CreateTaskFailureAction => ({
    type: ActionTypes.TASKS_CREATE_TASK_FAILURE,
    payload: {error},
    error: true,
});

export const requestCompleteTask = (payload: Task): CompleteTaskRequestAction => ({
    type: ActionTypes.TASKS_COMPLETE_TASK_REQUEST,
    payload,
});
export const resolveRequestCompleteTask = (
    response: TasksApiResponse
): CompleteTaskSuccessAction => ({
    type: ActionTypes.TASKS_COMPLETE_TASK_SUCCESS,
    payload: response,
});
export const failRequestCompleteTask = (payload?: Object): CompleteTaskFailureAction => ({
    type: ActionTypes.TASKS_COMPLETE_TASK_FAILURE,
    payload,
    error: true,
});
export const requestUncompleteTask = (payload: Task): UncompleteTaskRequestAction => ({
    type: ActionTypes.TASKS_UNCOMPLETE_TASK_REQUEST,
    payload,
});
export const resolveRequestUncompleteTask = (
    response: TasksApiResponse
): UncompleteTaskSuccessAction => ({
    type: ActionTypes.TASKS_UNCOMPLETE_TASK_SUCCESS,
    payload: response,
});
export const failRequestUncompleteTask = (payload?: Object): UncompleteTaskFailureAction => ({
    type: ActionTypes.TASKS_UNCOMPLETE_TASK_FAILURE,
    payload,
    error: true,
});
