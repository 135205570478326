/* @flow */

import * as React from 'react';
import {motion} from 'framer-motion';
import {colors} from '../colors';
import './slideshow-control-dot.css';

type Props = {
    state: 'inactive' | 'active' | 'viewed',
    onDotClick?: () => void,
};

const ringVariants = {
    active: {
        scale: 1.5,
        stroke: colors.blue,
    },
    inactive: {
        scale: 1.5,
        stroke: colors.offWhiteDk,
    },
    viewed: {
        scale: 1.5,
        stroke: colors.blue,
    },
};
const dotVariants = {
    active: {
        fill: colors.blue,
    },
    inactive: {
        fill: colors.offWhiteDk,
    },
    viewed: {
        fill: colors.blue,
    },
};

/**
 * This component is used in slideshows to indicate which page the user is viewing,
 * and provides a way to jump to a different slide.
 */
export function SlideshowControlDot(props: Props) {
    const isActive = props.state === 'active';
    const canClick = Boolean(props.onDotClick);
    const styleName = isActive || !canClick ? 'dot--noclick' : 'dot';

    return (
        <div styleName={styleName} onClick={isActive ? undefined : props.onDotClick}>
            <svg width='24px' height='24px' viewBox='0 0 14 14'>
                <motion.circle
                    styleName='outer-circle'
                    variants={ringVariants}
                    animate={props.state}
                    transition={{type: 'spring', stiffness: 200, damping: 15}}
                    initial='inactive'
                    fill='none'
                    strokeWidth='0.5'
                    scale='1.5'
                    cx='7'
                    cy='7'
                    r='3.5'
                />
                <motion.circle
                    styleName='center-dot'
                    variants={dotVariants}
                    animate={props.state}
                    initial='inactive'
                    stroke='none'
                    cx='7'
                    cy='7'
                    r='3.5'
                />
            </svg>
        </div>
    );
}
