/* @flow */

import * as React from 'react';
import './modal-subheader.css';

type Props = {
    children?: React.Node,
    as: string,
};

ModalSubheader.defaultProps = {
    as: 'p',
};

export function ModalSubheader({children, as: Tag}: Props) {
    return <Tag styleName='modal-subheader'>{children}</Tag>;
}
