/* @flow */

import type {StagesetId} from 'nutshell-core/types';

/**
 * Gets the default stageset id from NutshellFrontendSettings (if it exists).
 * Note: The default stagesetId is not the first non-deleted instance, it's
 * actually a setting set by the user.
 *
 * If the default stageset doesn't exist, we'll return `1-stagesets`,
 * which is what each instance is seeded with.
 *
 * @return {string} - Default stagesetId
 */
export function getDefaultStagesetId(): StagesetId {
    return typeof NutshellFrontendSettings !== 'undefined'
        ? NutshellFrontendSettings.defaultStagesetId
        : '1-stagesets';
}
