/* @flow */

import * as React from 'react';
import './uppercase-label.css';

type Props = {
    children?: React.Node,
    as: string,
};

UppercaseLabel.defaultProps = {
    as: 'label',
};

/*
 * This component is responsible for rendering a 12px all uppercased
 * label that we use on the new lead pages.
 */
export function UppercaseLabel({children, as: Tag}: Props) {
    return <Tag styleName='uppercase-label'>{children}</Tag>;
}
