/* @flow */

export const ActionTypes = {
    APP_SET_API_VERSION: 'APP_SET_API_VERSION',
    APP_SET_APP_VERSION: 'APP_SET_APP_VERSION',
};

export type ActionSetApiVersion = {type: 'APP_SET_API_VERSION', payload: string};
export type ActionSetAppVersion = {type: 'APP_SET_APP_VERSION', payload: string};

export type AppAction = ActionSetApiVersion | ActionSetAppVersion;

export const setApiVersion = (apiVersion: string) => ({
    type: 'APP_SET_API_VERSION',
    payload: apiVersion,
});
export const setAppVersion = (appVersion: string) => ({
    type: 'APP_SET_APP_VERSION',
    payload: appVersion,
});
