/* @flow */

import * as React from 'react';

import type {EventFragmentSparse} from 'nutshell-graphql-types';

import {Link} from 'shells/link';

import {appHistory} from '../../../history';
import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {|event: EventFragmentSparse|};

export function TimelineBannerEmailInteraction(props: Props) {
    const {event} = props;

    const getIconVariant = (type) => {
        switch (type) {
            case 'VIEWED':
                return 'outreach-opened';
            case 'CLICKED':
            default:
                return 'outreach-clicked';
        }
    };

    const getVerb = (type) => {
        switch (type) {
            case 'VIEWED':
                return 'opened';
            case 'CLICKED':
            default:
                return 'clicked';
        }
    };

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'EmailInteraction' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (event.payload && event.payload.__typename === 'EmailInteraction') {
        const entity = event.payload;
        const eventCount = event.count;
        const emailDelivery = entity.emailDelivery;
        const interactionType = entity.type;
        const emailTitle =
            // The title is the name of the edition OR the subject of the email
            // eslint-disable-next-line no-nested-ternary
            emailDelivery && emailDelivery.edition
                ? emailDelivery.edition.name
                : emailDelivery && emailDelivery.email
                ? emailDelivery.email.subject
                : undefined;
        const link = interactionType === 'CLICKED' && entity.link ? entity.link : undefined;
        const htmlUrlPath =
            // In a banner context we want to link to the email itself so that the
            // specific email can open in a modal. The edition's htmlUrlPath links
            // to the generic pre-sent broadcast/drip sequence which we don't want here.
            emailDelivery && emailDelivery.email ? emailDelivery.email.htmlUrlPath : undefined;

        /**
         * In this case, we want to display the primary contact on the email interaction
         * as the actor so the banner displays properly
         */
        const actor = emailDelivery ? emailDelivery.primaryContact : undefined;

        const linkComponent =
            link && htmlUrlPath ? (
                <span>
                    <span
                        styleName='target--button'
                        role='button'
                        tabIndex='0'
                        onClick={() => appHistory.push(`#${htmlUrlPath}`)}
                    >
                        <Link href={link} variant='primary'>
                            {link}
                        </Link>
                    </span>
                    &nbsp;from&nbsp;
                </span>
            ) : (
                <span>a link from&nbsp;</span>
            );

        const action = (
            <span>
                {getVerb(interactionType)}&nbsp;
                {interactionType === 'CLICKED' ? linkComponent : undefined}
                {emailTitle && htmlUrlPath ? (
                    <span
                        styleName='target--button'
                        role='button'
                        tabIndex='0'
                        onClick={() => appHistory.push(`#${htmlUrlPath}`)}
                    >
                        {emailTitle}
                    </span>
                ) : (
                    <span>a Nutshell Campaigns email</span>
                )}
                {eventCount && eventCount > 1 ? <span>&nbsp;{eventCount} times</span> : undefined}
            </span>
        );

        return (
            <TimelineBanner
                id={event.id}
                iconVariant={getIconVariant(interactionType)}
                timestamp={event.changeTime}
                actor={actor}
                action={action}
            />
        );
    }

    return null;
}
