/* @flow */

import * as React from 'react';
import {useQuery} from '@apollo/react-hooks';

import type {
    GetContactFormSubmissions as GetContactFormSubmissionsQuery,
    GetContactFormSubmissionsVariables as GetContactFormSubmissionsQueryVariables,
} from 'nutshell-graphql-types';
import {makeDataSafe} from 'nutshell-core/utils/graphql-errors';

import {SectionLabel} from 'shells/typography';
import {Button} from 'shells/button';

import GET_CONTACT_FORM_SUBMISSIONS from '../../entity-details/graphql/queries/get-contact-form-submissions.graphql';

import {ViewFormSubmissionModal} from './view-form-submission-modal';
import {FormSubmissionTimestamp} from './form-submission-timestamp';

import './form-submissions-section.css';

const MAX_NUM_SHOWN = 2;

type Props = {
    contactId: string,
};

export function FormSubmissionsSection(props: Props) {
    // Check the URL for a submission to open by default
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const submissionId = urlParams.get('openFormSubmission');

    // If there is a submission to open by default, this resets the URL without refreshing the page.
    if (submissionId) {
        window.history.replaceState({}, '', window.location.pathname);
    }

    const [selectedSubmissionId, setSelectedSubmissionId] = React.useState<?string>(submissionId);
    const [shouldShowAllSubmissions, setShouldShowAllSubmissions] = React.useState<boolean>(false);

    const {data} = useQuery<
        GetContactFormSubmissionsQuery,
        GetContactFormSubmissionsQueryVariables
    >(GET_CONTACT_FORM_SUBMISSIONS, {
        variables: {id: props.contactId},
        notifyOnNetworkStatusChange: true,
    });

    const {contact} = makeDataSafe(data);

    const submissions =
        contact && contact.submissions ? contact.submissions.edges.map((edge) => edge.node) : [];

    if (!contact || !submissions || !submissions.length) {
        return null;
    }

    const selectedSubmission = selectedSubmissionId
        ? submissions.find((submission) => submission.id === selectedSubmissionId)
        : undefined;

    let numHiddenSubmissions;

    if (submissions && submissions.length) {
        // Only hiding submissions if there are more than one
        if (submissions.length > MAX_NUM_SHOWN + 1) {
            numHiddenSubmissions = submissions.length - MAX_NUM_SHOWN;
        } else if (!shouldShowAllSubmissions) {
            setShouldShowAllSubmissions(true);
        }
    }

    return (
        <>
            <div styleName='form-submissions-section'>
                <SectionLabel>Form submissions</SectionLabel>
                {submissions
                    .filter(
                        (submission, index) => shouldShowAllSubmissions || index < MAX_NUM_SHOWN
                    )
                    .map((submission) => (
                        <div key={submission.id} styleName='submission'>
                            <Button
                                variant='text-primary'
                                size='small'
                                onClick={() => {
                                    setSelectedSubmissionId(submission.id);
                                }}
                            >
                                {submission.formVersion.form.name}
                            </Button>
                            <FormSubmissionTimestamp submissionTime={submission.submissionTime} />
                        </div>
                    ))}
                {numHiddenSubmissions ? (
                    <Button
                        variant='text-secondary'
                        size='small'
                        onClick={() => {
                            setShouldShowAllSubmissions(
                                (prevShouldShowAllSubmissions) => !prevShouldShowAllSubmissions
                            );
                        }}
                    >
                        <i styleName='more-submissions-container'>
                            {!shouldShowAllSubmissions
                                ? `+${numHiddenSubmissions} more forms`
                                : 'Show less'}
                        </i>
                    </Button>
                ) : (
                    undefined
                )}
            </div>
            {selectedSubmission ? (
                <ViewFormSubmissionModal
                    onClose={() => setSelectedSubmissionId(undefined)}
                    submitter={{
                        type: 'contacts',
                        name: contact.name,
                        htmlUrlPath: contact.htmlUrlPath,
                        initials: contact.initials,
                        avatarUrl: contact.avatarUrl,
                    }}
                    submissionTime={selectedSubmission.submissionTime}
                    submissionId={selectedSubmission.id}
                    form={{
                        __typename: 'Form',
                        id: selectedSubmission.formVersion.form.id,
                        name: selectedSubmission.formVersion.form.name,
                        htmlUrlPath: selectedSubmission.formVersion.form.htmlUrlPath,
                    }}
                />
            ) : (
                undefined
            )}
        </>
    );
}

export function getValueForField(
    fieldValue: ?{value: string} | ?{values: string[]} | ?{subfieldValues: Object[]}
): ?(string | Array<string> | Array<Object>) {
    if (fieldValue) {
        if (fieldValue.value && typeof fieldValue.value === 'string') {
            return fieldValue.value;
        } else if (
            fieldValue.values &&
            Array.isArray(fieldValue.values) &&
            fieldValue.values.every((val) => typeof val === 'string')
        ) {
            // $FlowIgnore - literally just checked that this is array of strings... but ok.
            return fieldValue.values;
        } else if (
            fieldValue.subfieldValues &&
            Array.isArray(fieldValue.subfieldValues) &&
            fieldValue.subfieldValues.every((val) => typeof val === 'object')
        ) {
            // $FlowIgnore
            return fieldValue.subfieldValues;
        }
    }

    return null;
}
