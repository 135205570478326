
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CommentFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Comment"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"bodyHtml"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"changeLogEntry"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"reactions"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ReactionFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"creatorUser"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"avatarUrl"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"initials"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"htmlUrl"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"createdTime"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isDeleteable"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":354}};
    doc.loc.source = {"body":"#import \"../../../reactions/graphql/fragments/reaction-fragment.graphql\"\n\nfragment CommentFragment on Comment {\n    id\n    bodyHtml\n    changeLogEntry {\n        id\n    }\n    reactions {\n        ...ReactionFragment\n    }\n    creatorUser {\n        id\n        name\n        avatarUrl\n        initials\n        htmlUrl\n    }\n    createdTime\n    isDeleteable\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../../../reactions/graphql/fragments/reaction-fragment.graphql").definitions));


      module.exports = doc;
    
