/* @flow */

import type {NotifyMessage} from '../types';

const NOTIFY_EXPORT_TYPES = [
    'lead export',
    'company export',
    'contacts export',
    'accounts export',
    'export',
];

/**
 * Determines if any of the given notifyMessages contains a non-completed
 * export, that is also unackownledged (not dismissed)
 *
 * @param  {Object[]}  notifyMessages - Array of notifyMessage objects
 * @return {Boolean}                  - If there are one or more processing, unacknowledged exports
 */
export function hasQueuedExports(notifyMessages: NotifyMessage[]): boolean {
    const firstQueuedExport = notifyMessages.find((notify) => {
        return (
            notify.status !== 'complete' &&
            !notify.acknowledged &&
            NOTIFY_EXPORT_TYPES.includes(notify.type)
        );
    });

    return Boolean(firstQueuedExport);
}
