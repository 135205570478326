import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import UpgradeArrow from './icon-upgrade-arrow.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <UpgradeArrow {...validProps} />;
}
SvgComponent.iconName = 'upgrade-arrow';

export const UpgradeArrowIcon = svgIconWrapper(SvgComponent);
