/* @flow */

import * as React from 'react';

export type IconProps = {
    size: null | string | number,
    className?: string,
    style?: Object,
    wrapWithDiv?: boolean,
    alignCenter?: boolean,
};

export function svgIconWrapper(Component: React.ComponentType<*>) {
    const DEFAULT_SIZE = 40;

    class Icon extends React.PureComponent<IconProps> {
        static defaultProps = {
            wrapWithDiv: true,
        };

        render() {
            const {size, className, style, wrapWithDiv, alignCenter, ...otherProps} = this.props;

            if (wrapWithDiv) {
                return (
                    <div
                        // $FlowFixMe we're shoving a property on a react component in a weird way
                        className={`react-icon svg-icon-container svg-${Component.iconName ||
                            'iconName-is-undefined'} ${className || ''}`}
                        style={{
                            ...style,
                            height:
                                alignCenter && typeof size === 'number'
                                    ? `${size || DEFAULT_SIZE}px`
                                    : undefined,
                        }}
                    >
                        <Component
                            width={size === null ? undefined : size || DEFAULT_SIZE}
                            height={size === null ? undefined : size || DEFAULT_SIZE}
                            {...otherProps}
                        />
                    </div>
                );
            }

            return (
                <Component
                    width={size === null ? undefined : size || DEFAULT_SIZE}
                    height={size === null ? undefined : size || DEFAULT_SIZE}
                    {...otherProps}
                />
            );
        }
    }

    return Icon;
}
