/* @flow */

import * as React from 'react';

import {EyeIcon, LinkClickIcon, HtmlAttachmentIcon} from 'shells/icon';
import {Popover} from '../../../popover';
import {IconButton} from '../../../button/icon-button/icon-button';
import {AttachmentList, type Attachment} from '../../../attachment-list';

import './left-side.css';

// EmailEngagementProps
export type Props = {|
    attachments: Attachment[],
    opens: number,
    clicks: number,
|};

export const EmailEngagement = (props: Props) => {
    const {attachments, opens, clicks} = props;
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const popoverAnchor = React.useRef();

    return (
        <>
            <div className='flex'>
                {attachments.length > 0 ? (
                    <IconButton
                        icon={HtmlAttachmentIcon}
                        text={attachments.length}
                        onClick={() => {
                            setIsPopoverOpen(true);
                        }}
                        getButtonRef={popoverAnchor}
                        noHoverBackground={true}
                        tooltipText={`${attachments.length} ${
                            attachments.length > 1 ? 'attachments' : 'attachment'
                        }`}
                    />
                ) : (
                    undefined
                )}
                {opens > 0 ? (
                    <div styleName='container' data-tip={opens === 1 ? '1 open' : `${opens} opens`}>
                        <EyeIcon size={17} wrapWithDiv={false} />
                        <span styleName='count'>{opens}</span>
                    </div>
                ) : (
                    undefined
                )}
                {clicks > 0 ? (
                    <div
                        styleName='container'
                        data-tip={clicks === 1 ? '1 click' : `${clicks} clicks`}
                    >
                        <LinkClickIcon size={17} wrapWithDiv={false} />
                        <span styleName='count'>{clicks}</span>
                    </div>
                ) : (
                    undefined
                )}
            </div>
            {isPopoverOpen ? (
                <Popover
                    anchor={popoverAnchor.current}
                    location='bottom'
                    onBlur={() => {
                        setIsPopoverOpen(false);
                    }}
                >
                    <AttachmentList attachments={attachments} />
                </Popover>
            ) : (
                undefined
            )}
        </>
    );
};
