import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Unlogged from './unlogged-icon.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Unlogged {...validProps} />;
}
SvgComponent.iconName = 'unlogged-icon';

export const UnloggedIcon = svgIconWrapper(SvgComponent);
