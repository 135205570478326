/* @flow */

import * as React from 'react';
import {components, type ControlProps, type OptionProps} from 'react-select';

import {ContactIcon} from 'shells/icon';
import {PeepTwoLine} from 'shells/peep/peep-two-line';

import {GraphQLSelectCreatable, type Option} from './graphql-select-creatable';
import {useGetContacts} from './hooks/use-get-contacts';
import {useCreateContact} from './hooks/use-create-contact';
import {ControlWithIcon} from './control-with-icon';

type Props = {
    selectedValues: Option[],
    onChange: (values: Option | Option[]) => any,
    onClearValue?: () => void,
    isMulti: boolean,
    clearable?: boolean,
};

export const GraphQLSelectContacts = (props: Props) => {
    const {fetchMore, isLoading} = useGetContacts();
    const createContact = useCreateContact();

    const handleCreate = (newContact: string) => {
        // Immediately add a temp contact to the list of selected values
        const tempContact = {id: newContact, name: newContact};
        if (props.isMulti && Array.isArray(props.selectedValues)) {
            props.onChange([...props.selectedValues, tempContact]);
        } else {
            props.onChange(tempContact);
        }

        createContact({contact: {name: newContact}}).then((result) => {
            const createdContact =
                result.data && result.data.contactCreate && result.data.contactCreate.contact;
            const createdContactValue = createdContact
                ? {id: createdContact.id, name: createdContact.name}
                : null;

            // Add the real created contact to the list of selected values
            if (createdContactValue) {
                if (props.isMulti && Array.isArray(props.selectedValues)) {
                    props.onChange([...props.selectedValues, createdContactValue]);
                } else {
                    props.onChange(createdContactValue);
                }
            }
        });
    };

    const handleLoadOptions = (search: ?string) => {
        return fetchMore(search).then(({data}) => {
            const quickResults = data.search ? data.search.quickResults : null;

            let contactResults = quickResults ? quickResults.contactResults : [];

            if (contactResults.length === 0) {
                const recentResults =
                    quickResults && quickResults.recentResults ? quickResults.recentResults : [];
                if (recentResults && recentResults.length > 0) {
                    contactResults = recentResults;
                }
            }

            return Promise.resolve(contactResults);
        });
    };

    return (
        <GraphQLSelectCreatable
            placeholder={props.isMulti ? 'Select or create people' : 'Select or create a person'}
            value={props.selectedValues}
            multi={props.isMulti}
            onClearValue={props.onClearValue}
            loadOptions={handleLoadOptions}
            onChange={props.onChange}
            onBlur={() => {
                props.onChange(props.selectedValues);
            }}
            noOptionsMessage={({inputValue}) => {
                if (isLoading) {
                    return 'Getting recent results…';
                }
                if (inputValue.length) {
                    return `No people found matching '${inputValue}'`;
                }
                if (inputValue.length === 0) {
                    return 'No recently viewed people. Type to search all people…';
                }

                return null;
            }}
            formatCreateLabel={(inputValue) => `Create new person named '${inputValue}'`}
            onCreate={handleCreate}
            components={{Control: ControlComponent, Option: OptionComponent}}
            labelKey='name'
            valueKey='id'
            clearable={props.clearable}
        />
    );
};

const OptionComponent = (props: OptionProps) => {
    // This is a hack to get around limitations in our old version of flow
    const spreadableProps: $Exact<OptionProps> = (props: any);

    if (props.data.__isNew__) {
        return (
            // $FlowFixMe upgrading Flow to v0.92.1 on web
            <components.Option {...spreadableProps}>{props.data.name}</components.Option>
        );
    }

    return (
        // $FlowFixMe upgrading Flow to v0.92.1 on web
        <components.Option {...spreadableProps}>
            <PeepTwoLine
                name={props.data.name}
                avatarUrl={props.data.avatarUrl}
                initials={props.data.initials}
                htmlUrl={props.data.htmlUrl}
                type='contacts'
                relatedEntityName={
                    props.data.accounts && props.data.accounts.edges && props.data.accounts.edges[0]
                        ? props.data.accounts.edges[0].node.name
                        : undefined
                }
                isLinkable={false}
            />
        </components.Option>
    );
};

const ControlComponent = ({children, ...props}: {...ControlProps}) => (
    <components.Control {...props}>
        <ControlWithIcon icon={ContactIcon}>{children}</ControlWithIcon>
    </components.Control>
);
