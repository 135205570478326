import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import LeadPending from './icon-lead-pending.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <LeadPending {...validProps} />;
}
SvgComponent.iconName = 'leadPending';

export const LeadPendingIcon = svgIconWrapper(SvgComponent);
