import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SidebarIconHelp from './sidebar-icon-help.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SidebarIconHelp {...validProps} />;
}
SvgComponent.iconName = 'sidebarIconHelp';

export const SidebarIconHelpIcon = svgIconWrapper(SvgComponent);
