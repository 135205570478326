/* @flow */

import * as React from 'react';
import uniqueId from 'lodash/uniqueId';

import {Link} from '../link';
import {CopyButton} from '../button';
import {Label} from '../form';

import './copyable-link.css';

type Props = {
    label?: string,
    name: string,
    value: string,
    size: 'big' | 'xlarge',
    isWhite?: boolean,
    onCopy?: () => void,
    // The copyable link appears as a link that opens in a new tab when clicked
    isLinkClickable?: boolean,
};

export function CopyableLink(props: Props) {
    const copyableLinkRef = React.useRef(null);
    const idRef = React.useRef<string>(uniqueId('input_'));

    return (
        <React.Fragment>
            {props.label ? <Label htmlFor={idRef.current}>{props.label}</Label> : undefined}
            <div styleName={props.isWhite ? 'copyable-link--white' : 'copyable-link'}>
                <input
                    id={idRef.current}
                    name={props.name}
                    type='text'
                    ref={copyableLinkRef}
                    value={props.value}
                    onChange={() => {}}
                    hidden={props.isLinkClickable}
                />
                {props.isLinkClickable ? (
                    <div className='flex align-center flex-grow'>
                        <Link href={props.value} newTab={true} size='small' variant='primary'>
                            {props.value}
                        </Link>
                    </div>
                ) : (
                    undefined
                )}
                <CopyButton
                    variant='primary'
                    size={props.size}
                    target={copyableLinkRef}
                    onCopy={props.onCopy}
                />
            </div>
        </React.Fragment>
    );
}
