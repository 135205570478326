/* @flow */

import * as React from 'react';

import {RichTextarea} from '../../../rich-textarea';
import {FORMATS} from '../../../email-editor/configuration';
import {ScrollableContainerWithShadow} from '../../../layout';
import {LoadingPage} from '../../../loading-page';

import './expanded-timeline-entry-content.css';

const DEFAULT_MAX_HEIGHT = 300;

type Props = {
    isLoading: boolean,
    content: ?(string | React.Node),
    htmlContent?: ?string,
    maxHeight?: number,
};

export function ExpandedTimelineEntryContent(props: Props) {
    const {content, htmlContent, maxHeight, isLoading} = props;

    // If there is no content or htmlContent, we just return
    if (!content && !htmlContent) return null;

    let displayedContent = <LoadingPage />;
    if (!isLoading) {
        displayedContent = htmlContent ? (
            // Favor htmlContent over content
            <RichTextarea
                id='expanded-content--rich-textarea'
                value={htmlContent}
                readOnly={true}
                noPadding={true}
                config={{
                    modules: {
                        toolbar: false,
                    },
                    formats: FORMATS,
                    images: true,
                    formatLinks: true,
                    useDivs: true,
                    placeholders: false,
                }}
            />
        ) : (
            <div>{content}</div>
        );
    }

    return (
        <div styleName='expanded-timeline-entry-content'>
            <ScrollableContainerWithShadow
                direction='vertical'
                maxContainerSize={maxHeight ? maxHeight : DEFAULT_MAX_HEIGHT}
                showReverseGradientShadow={true}
                gradientShadowSize={60}
            >
                {displayedContent}
            </ScrollableContainerWithShadow>
        </div>
    );
}
