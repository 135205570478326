/* @flow */

import * as React from 'react';

import {CopyIconButton} from '../button/copy-button/copy-icon-button';
import {useResizeObserver} from '../utils/hooks';

import {Link} from './link';

const COPY_BUTTON_OFFSET = 16;

type Props = {|
    link: string,
    size?: 'big' | 'small',
    shouldTruncate?: boolean,
|};

export function LinkWithCopyButton(props: Props) {
    const {size = 'big'} = props;

    const containerRef = React.useRef(null);
    const linkContainerRef = React.useRef(null);

    const [width] = useResizeObserver(containerRef.current);

    const [linkWidth, setLinkWidth] = React.useState<?number>(null);

    React.useLayoutEffect(() => {
        if (linkContainerRef.current) {
            setLinkWidth(linkContainerRef.current.clientWidth);
        }
    }, []);

    React.useEffect(() => {
        if (typeof width === 'number' && linkContainerRef.current) {
            setLinkWidth(
                Math.min(width - COPY_BUTTON_OFFSET, linkContainerRef.current.clientWidth)
            );
        }
    }, [width]);

    return (
        <div className='flex align-center gap-4' ref={containerRef}>
            <div className='truncate' ref={linkContainerRef}>
                <Link
                    variant='secondary'
                    size={size}
                    newTab={true}
                    href={props.link}
                    onClick={(e) => e.stopPropagation()}
                    style={props.shouldTruncate ? {maxWidth: linkWidth} : {}}
                >
                    {props.link}
                </Link>
            </div>
            <CopyIconButton as='div' text={props.link} size={size} />
        </div>
    );
}
