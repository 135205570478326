import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 14.4 14.4'}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M13.9 11.7L9.4 7.2l4.4-4.4c.6-.6.6-1.6 0-2.2-.6-.6-1.6-.6-2.2 0L7.2 5 2.7.5C2.1-.1 1.1-.1.5.5c-.6.6-.6 1.6 0 2.2L5 7.2.5 11.7c-.6.6-.6 1.6 0 2.2.6.6 1.6.6 2.2 0l4.5-4.5 4.5 4.5c.6.6 1.6.6 2.2 0 .7-.6.7-1.6 0-2.2z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'close';

export const CloseIcon = svgIconWrapper(SvgComponent);
