/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import _ from 'underscore';

import {TimesCircleIcon, TickCircleIcon} from '../icon';
import './floating-label-textfield.css';

type Props = {
    label: string,
    name: string,
    value: string | number,
    debounce?: number,

    autoFocus?: boolean,
    disabled?: boolean,
    autocomplete?: boolean,
    isValid?: boolean,
    multiline?: boolean,
    persistActionsOnBlur?: boolean,
    required?: boolean,
    showActions?: boolean,

    textFieldRef?: (?HTMLElement) => void,
    onBlur: (SyntheticInputEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    onCancel: () => void,
    onChange: (newValue: string, e: SyntheticEvent<*>) => void,
    onConfirm: () => void,
    onFocus?: (SyntheticInputEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
};

type State = {
    hasFocus: boolean,
    isActionsMenu: boolean,
    value?: string | number,
};

export class FloatingLabelTextField extends React.Component<Props, State> {
    inputRef: ?HTMLInputElement | ?HTMLTextAreaElement;

    static defaultProps = {
        value: '',

        disabled: false,
        autoFocus: false,
        isValid: true,
        multiline: false,
        persistActionsOnBlur: false,
        showActions: false,
        required: false,

        onBlur: () => {},
        onCancel: () => {},
        onConfirm: () => {},
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            hasFocus: false,
            isActionsMenu: false,
            value: props.value,
        };
    }

    componentDidMount() {
        if (this.props.autoFocus && !this.props.disabled && this.inputRef) {
            this.inputRef.focus();
        }

        if (this.props.multiline && this.inputRef) {
            this.resizeTextArea(this.props.value);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.value !== this.state.value) {
            this.setState({value: nextProps.value});
        }
    }

    render() {
        const NodeType = this.props.multiline ? 'textarea' : 'input';
        const isActive = this.props.value || this.props.value === 0 || this.state.hasFocus;

        const styleName = classnames({
            [NodeType]: !isActive,
            [`${NodeType}--active`]: isActive,
            'input--invalid': !this.props.isValid,
        });

        return (
            <div styleName='container'>
                <NodeType
                    name={this.props.name}
                    value={this.props.debounce ? this.state.value || '' : this.props.value || ''}
                    required={this.props.required}
                    ref={this.handleRefAssignment}
                    styleName={styleName}
                    disabled={this.props.disabled}
                    autoComplete={this.props.autocomplete ? 'on' : 'off'}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                />

                <label
                    htmlFor={this.props.name}
                    styleName={this.props.required ? 'label--required' : 'label'}
                >
                    {this.props.label}
                </label>

                {this.renderActionButtons()}
            </div>
        );
    }

    renderActionButtons = () => {
        const {showActions} = this.props;

        const styleName =
            this.state.isActionsMenu && showActions
                ? 'actions-container--active'
                : 'actions-container';

        return (
            <div styleName={styleName}>
                <button
                    onMouseDown={this.handleMouseDownConfirmButton}
                    styleName='button-confirm'
                    tabIndex={-1}
                    type='button'
                >
                    <TickCircleIcon style={{height: 20}} size={20} />
                </button>
                {this.props.required ? (
                    undefined
                ) : (
                    <button
                        onMouseDown={this.handleMouseDownCancelButton}
                        styleName='button-cancel'
                        tabIndex={-1}
                        type='button'
                    >
                        <TimesCircleIcon />
                    </button>
                )}
            </div>
        );
    };

    handleChangeDebounced = _.debounce((newValue, e) => {
        this.props.onChange(newValue, e);
    }, this.props.debounce || 0);

    handleChange = (e: SyntheticInputEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (this.props.debounce) {
            this.setState({value: e.target.value});
            this.handleChangeDebounced(e.target.value, e);
        } else {
            this.props.onChange(e.target.value, e);
        }

        if (this.props.multiline) {
            this.resizeTextArea(e.target.value);
        }
    };

    handleFocus = (e: SyntheticInputEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        // When tabbing into the field, the text starts out selected.  This is normally fine, but breaks
        // clicking on canned options of the date-time picker.  We're using persistActionsOnBlur as a hacky
        // check whether this is a date-time-picker field, since that's the only time it's true.
        if (this.inputRef && this.props.persistActionsOnBlur) {
            this.inputRef.selectionEnd = this.inputRef.selectionStart;
        }

        this.setState({
            hasFocus: true,
            isActionsMenu: true,
        });
        if (this.props.onFocus) {
            this.props.onFocus(e);
        }
    };

    handleBlur = (e: SyntheticInputEvent<*>) => {
        this.setState({
            isActionsMenu: this.props.persistActionsOnBlur,
            hasFocus: false,
        });
        this.props.onBlur(e);
    };

    handleMouseDownConfirmButton = () => {
        this.setState({isActionsMenu: false}, this.props.onConfirm);
    };

    handleMouseDownCancelButton = (e: SyntheticEvent<*>) => {
        this.props.onChange('', e);

        this.setState(
            {
                isActionsMenu: false,
                value: '',
            },
            this.props.onCancel
        );
    };

    handleRefAssignment = (node: ?HTMLInputElement | ?HTMLTextAreaElement) => {
        this.inputRef = node;
        if (this.props.textFieldRef) {
            this.props.textFieldRef(node);
        }
    };

    resizeTextArea = (fieldValue: string | number) => {
        if (!this.inputRef) return;
        if (fieldValue) {
            this.inputRef.style.height = 'auto';
            this.inputRef.style.height = `${this.inputRef.scrollHeight + 5}px`;
        } else {
            this.inputRef.style.removeProperty('height');
        }
    };

    focus = () => {
        if (this.inputRef) this.inputRef.focus();
    };

    blur = () => {
        if (this.inputRef) this.inputRef.blur();
    };
}
