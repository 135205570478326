/* @flow */

import * as React from 'react';

import type {SessionUser} from 'nutshell-graphql-types';

import {EditIcon, ExpandIcon, CollapseIcon, PinIcon, UnpinIcon} from 'shells/icon';
import {TimelineEntryActionMenu} from 'shells/timeline/entry/toolbar/timeline-entry-action-menu';
import {MAX_ELEMENT_HEIGHT as TRUNCATION_THRESHOLD} from 'shells/timeline/entry/content/truncated-timeline-entry-content';

import {appHistory} from '../../../history';
import {useGetSessionUser} from '../../core/use-get-session-user';
import {getEntryTypeFromPayload} from '../helpers';
import type {EventForTimeline} from '../types';
import {useCreateComment, useDeleteComment} from '../comments/timeline-comment-mutations';
import {TimelineEntry} from './timeline-entry';

import './timeline-entry-content.css';

const MAX_PINS_PER_ENTITY = 5;

type Props = {
    event: EventForTimeline,
    currentUser: SessionUser,
    entityPageId?: ?string,
    entityPageName?: ?string,
    hasGutterLine?: boolean,
    onDeleteEvent?: (eventId: string) => Promise<*>,
    onSetPinnedStatus?: ({
        entityToPinId: string,
        pinToEntityId: string,
        status: boolean,
        userName: string,
        changeLogId: string,
    }) => Promise<*>,
    numPinnedEvents?: number,
};

export function TimelineEntryNote(props: Props) {
    const {
        id: changeLogId,
        changeType,
        changeTime,
        actor,
        payload,
        reactions,
        comments,
        pinnedInfo,
    } = props.event;
    const {
        currentUser,
        entityPageId,
        entityPageName,
        onDeleteEvent,
        onSetPinnedStatus,
        numPinnedEvents,
        hasGutterLine,
    } = props;

    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
    const [isExpandable, setIsExpandable] = React.useState<boolean>(false);
    const {user} = useGetSessionUser();
    const contentRef = React.useRef(undefined);

    const createComment = useCreateComment();
    const deleteComment = useDeleteComment();

    React.useLayoutEffect(() => {
        if (contentRef && contentRef.current) {
            if (contentRef.current.clientHeight > TRUNCATION_THRESHOLD) {
                setIsExpandable(true);
            }
        }
    }, []);

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'Note' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (payload.__typename === 'Note') {
        const actionMenuOptions = [];

        // Non-admin users can only unpin their own pins
        const canUnpin =
            pinnedInfo &&
            ((user && user.permissions.canAccessSetup) ||
                pinnedInfo.pinnedByUser.id === currentUser.id);

        if (payload.isEditable) {
            actionMenuOptions.push({
                label: 'Edit',
                icon: <EditIcon size={15} wrapWithDiv={false} />,
                handleSelect: () => {
                    if (payload.hasMentionedEntities) {
                        window.location.href = payload.htmlUrl;
                    } else {
                        // TODO: Change this behavior to open the modal for editing
                        window.location.href = payload.htmlUrl;
                    }
                },
            });
        }

        const additionalToolbarOptions = [];
        if (isExpandable) {
            if (isExpanded) {
                additionalToolbarOptions.push({
                    id: 'collapse',
                    icon: CollapseIcon,
                    onClick: () => setIsExpanded(false),
                    tooltipText: 'Collapse',
                });
            } else {
                additionalToolbarOptions.push({
                    id: 'expand',
                    icon: ExpandIcon,
                    onClick: () => setIsExpanded(true),
                    tooltipText: 'Expand',
                });
            }
        }

        if (entityPageId && entityPageName && onSetPinnedStatus) {
            if (!pinnedInfo && numPinnedEvents === MAX_PINS_PER_ENTITY) {
                additionalToolbarOptions.push({
                    id: 'limit',
                    icon: PinIcon,
                    onClick: () => {},
                    tooltipText: `You can only pin up to ${MAX_PINS_PER_ENTITY} notes.`,
                    isDisabled: true,
                });
            } else if (!pinnedInfo) {
                additionalToolbarOptions.push({
                    id: 'pin',
                    icon: PinIcon,
                    onClick: () =>
                        onSetPinnedStatus({
                            entityToPinId: payload.id,
                            pinToEntityId: entityPageId,
                            status: true,
                            userName: currentUser.name,
                            changeLogId,
                        }),
                    tooltipText: `Pin to ${entityPageName}`,
                });
            } else if (pinnedInfo && canUnpin) {
                additionalToolbarOptions.push({
                    id: 'unpin',
                    icon: UnpinIcon,
                    onClick: () =>
                        onSetPinnedStatus({
                            entityToPinId: payload.id,
                            pinToEntityId: entityPageId,
                            status: false,
                            userName: currentUser.name,
                            changeLogId,
                        }),
                    tooltipText: `Unpin from ${entityPageName}`,
                });
            }
        }

        const actionMenu = (
            <TimelineEntryActionMenu
                id={changeLogId}
                entryType={getEntryTypeFromPayload(payload)}
                handleDelete={onDeleteEvent}
                customActionMenuOptions={actionMenuOptions}
            />
        );

        return (
            <TimelineEntry
                id={changeLogId}
                iconVariant='note'
                entryType={getEntryTypeFromPayload(payload)}
                timestamp={changeTime}
                actor={actor}
                content={
                    <div
                        ref={(r) => {
                            contentRef.current = r;
                        }}
                        styleName='entry-html-body'
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: payload.bodyHtml,
                        }}
                    />
                }
                entity={payload.entity}
                changeType={changeType}
                reactions={reactions}
                comments={comments}
                onAddComment={(value) =>
                    createComment({
                        changeLogId,
                        parentId: payload.id,
                        value,
                        user: props.currentUser,
                    })
                }
                onDeleteComment={(commentId) => deleteComment({changeLogId, commentId})}
                currentUser={currentUser}
                actionMenu={actionMenu}
                additionalToolbarOptions={additionalToolbarOptions}
                onClickOpen={(e: SyntheticEvent<*>) => {
                    // if there is a hyperlink in the note, open it on click, if not open the modal
                    // $FlowIgnore, e.target is hard for flow
                    if (e.target && !e.target.href) {
                        appHistory.push(`#${payload.htmlUrlPath}`);
                    }
                }}
                isExpanded={isExpanded}
                pinnedInfo={pinnedInfo}
                hasGutterLine={hasGutterLine}
            />
        );
    }

    return null;
}
