import React from 'react';

import {svgIconWrapper} from '../svg-icon-wrapper';
import ContactAssigneeIconSvg from './icon-contact-assignee.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <ContactAssigneeIconSvg
            {...validProps}
            color={validProps.color ? validProps.color : '#bf4200'}
        />
    );
}
SvgComponent.iconName = 'contactAssigneeIcon';

export const ContactAssigneeIcon = svgIconWrapper(SvgComponent);
