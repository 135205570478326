import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ArrowUpIconSvg from './icon-arrow-up.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ArrowUpIconSvg {...validProps} />;
}
SvgComponent.iconName = 'arrowUpIcon';

export const ArrowUpIcon = svgIconWrapper(SvgComponent);
