import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

import styles from './loading-icon.css';

function LoadingComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg className={styles['svg-loading']} viewBox={'0 0 50 50'} {...validProps}>
            <path d='M43.935 25.145c0-10.318-8.364-18.683-18.683-18.683-10.318 0-18.683 8.365-18.683 18.683h4.067c0-8.07 6.543-14.615 14.615-14.615s14.615 6.543 14.615 14.615h4.068z' />
        </svg>
    );
}
LoadingComponent.iconName = 'loading';

export const LoadingIcon = svgIconWrapper(LoadingComponent);
