/* @flow */
import {createSelector} from 'reselect';
import {hasQueuedExports} from './has-queued-exports';
import {REDUCER_KEY, type SessionModuleState} from './session-types';

const EXPORT_DEFAULT = 'export';
const EXPORT_LEADS = 'lead export';
const EXPORT_COMPANIES = 'company export';
const EXPORT_PEOPLE_API_TYPE = 'contacts export';
const EXPORT_COMPANIES_API_TYPE = 'accounts export';
const LIST_DELETE = 'list delete';

const validNotificationTypes = [
    EXPORT_DEFAULT,
    EXPORT_LEADS,
    EXPORT_COMPANIES,
    EXPORT_COMPANIES_API_TYPE,
    EXPORT_PEOPLE_API_TYPE,
    LIST_DELETE,
];

/**
 * Returns the session-relevant substate of the larger application state.
 *
 * @param {object} state The full application state object
 *
 * @return {object} The state object that relates to our larger application
 *   session, like which user is logged in. Note, this is an area where it would
 *   be nice to have flow's tooling set up so I could describe the structure of
 *   the session type. See the session-reducer to view the `defaultSessionState`
 *   to get a feel for the type's structure.
 */
export const getSessionState = (state: SessionModuleState) => state[REDUCER_KEY];

/**
 * Returns the unique identifier for the session state's user object.
 *
 * @param {object} sessionState The full application state object
 *
 * @return {?string} The opqaue unique identifier for the logged-in user of our
 *   session state object, or null if it's not available.
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getSessionStateUserId = createSelector([getSessionState], (sessionState) => {
    return sessionState && sessionState.user ? sessionState.user.id : null;
});

// $FlowFixMe upgrading Flow to v0.92.1
export const getSessionStateUserName = createSelector([getSessionState], (sessionState) => {
    return sessionState && sessionState.user ? sessionState.user.name : null;
});

// $FlowFixMe upgrading Flow to v0.92.1
export const getSessionStateUser = createSelector([getSessionState], (sessionState) => {
    return sessionState && sessionState.user ? sessionState.user : null;
});

const getNotifyMessages = createSelector([getSessionStateUser], (sessionStateUser) => {
    if (!sessionStateUser) return [];

    return sessionStateUser.notifyMessages || [];
});

// $FlowFixMe upgrading Flow to v0.92.1
export const getUserPermissions = createSelector([getSessionStateUser], (sessionStateUser) => {
    return sessionStateUser ? sessionStateUser.permissions : null;
});

/**
 * Returns a boolean of whether or not the current session user has any queued
 * (incomplete and unacknowledged) exports.
 *
 * @param {object} sessionStateUser The full logged-in-user model object
 *
 * @return {boolean} Does that user have queued exports?
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getSessionStateUserHasQueuedExports = createSelector(
    [getNotifyMessages],
    (notifyMessages) => {
        return hasQueuedExports(notifyMessages);
    }
);

/**
 * Returns a filtered list of notifyMessages pertaining to the logged-in-user.
 * This notifyMessages are completed notifications (i.e. finished exports), that
 * have _not yet_ been acknowledged by the user.
 *
 * As an example, a completed export that has not been downloaded nor dismissed
 * would be returned here.
 *
 * @param {object} sessionStateUser The full logged-in-user model object
 *
 * @return {object[]} Unacknowledged list of notifications
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getSessionStateUserUnacknowedgedExportNotifications = createSelector(
    [getNotifyMessages],
    (notifyMessages) => {
        return notifyMessages.filter((notify) => {
            return (
                validNotificationTypes.includes(notify.type) &&
                (notify.status === 'complete' || !notify.acknowledged)
            );
        });
    }
);
