/* @flow */

import type {FilterObject, EntityListType, DefaultQueryParams, ListApiResponse} from '../types';
import {serializeFields} from '../serialize-fields';
import {serializeFilters} from '../serialize-filters';

import {api} from '../api';

export const DEFAULT_PAGE_LIMIT = 50;

export type ListQueryParams = {|
    fields?: string[],
    bare?: boolean,
    map?: boolean,
|};

export type ListQuery = {...DefaultQueryParams, ...ListQueryParams};

export type ListDefaultQuery = {
    fields: string[],
    filter: FilterObject[],
    sort: string,
    page: {
        limit: number,
        page: number,
    },
    bare?: boolean,
    q?: string,
    map?: boolean,
};

export type ListApiQuery = {
    fields: {[coreEntityType: string]: string},
    filter: FilterObject,
    sort: string,
    page: {
        limit: number,
        page: number,
    },
    bare?: boolean,
    q?: string,
    map?: boolean,
};

const defaultQuery = {
    fields: [],
    filter: [],
    sort: 'name',
    page: {
        limit: DEFAULT_PAGE_LIMIT,
        page: 0,
    },
    bare: false,
    map: false,
};

/**
 * Fetches list items from the server based upon provided query parameters
 * @param {CoreEntityType} entityType - The entity type to load. Accounts, contacts, or leads
 * @param {ListQuery} query - Query details for retrieval of list
 * @param {string[]} [query.fields=[]] - Array of fields to return in ListItem
 * @param {FilterObject[]} [query.filter=[]] - Array of fitlers to apply
 * @param {string} [query.sort=name] - Name of field to sort the results on
 * @param {PageInfo} [query.page] - Paging information for the list
 * @param {number} [query.page.limit=50] - Maximum number of ListItems to return
 * @param {number} [query.page.page=0] - Page number to return
 * @param {boolean} [query.bare] - Should the query return "bare" results (basic info for each item, no fields)
 *
 * @return {Promise<ListApiResponse>} Deferred object that should resolve to  list API response
 */
export function fetchList(
    entityType: EntityListType,
    query: ListQuery | ListDefaultQuery = {}
): Promise<ListApiResponse> {
    return api
        .get(
            `${entityType}/list`,
            serializeQueryParts(entityType, applyDefaultQueryParameters(query))
        )
        .then((response) => response.json());
}

/**
 * Applies default query parameters to a ListQuery object. It does this without overriding
 * any already existing properties, ala in a "safe" way.
 * @param  {Object} query     - Some form of ListQuery object, doesn't gaurentee all properties
 * @return {Object}           - A fully-fleshed out ListQuery object, contains all required defaults
 */
export function applyDefaultQueryParameters(query: ListQuery | ListDefaultQuery): ListDefaultQuery {
    return {
        fields: query.fields || defaultQuery.fields,
        filter: query.filter || defaultQuery.filter,
        sort: query.sort || defaultQuery.sort,
        page: {
            ...defaultQuery.page,
            ...query.page,
        },
        bare: query.bare,
        q: query.q,
        map: query.map,
    };
}

/**
 * Run before actually querying the server - serializes the fields and filters
 * of the query object, so that we're passing big 'ole filter objects and fields instead
 * of actually passing an array.
 * @param  {Object} entityType     - CoreEntityType for request
 * @param  {Object} query          - Full lead list query object, can gaurentee every
 *                                   necessary property has a value.
 * @return {Object}                - API-consumable ListQuery object
 */
export function serializeQueryParts(entityType: string, query: ListDefaultQuery): ListApiQuery {
    return {
        fields: serializeFields({[entityType]: query.fields}),
        filter: serializeFilters(query.filter),
        sort: query.sort,
        page: query.page,
        bare: query.bare,
        q: query.q,
        map: query.map,
    };
}
