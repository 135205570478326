/* @flow */

import * as React from 'react';
import {colors} from '../colors';

type Props = {
    insetPixels?: number,
};

/*
Utility component for a single-pixel hairline
border for when a CSS border doesn't work
 */
export function HairlineBorder(props: Props) {
    const styles = {
        height: '1px',
        backgroundColor: colors.offWhiteDk,
        width: props.insetPixels ? `calc(100% - ${props.insetPixels * 2}px)` : '100%',
        margin: props.insetPixels ? `0 ${props.insetPixels}px` : 0,
    };

    return <div style={styles} />;
}
