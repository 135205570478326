/* @flow */

const magicDateRegex = /^[>,<]?[-,+]r?[Mhdwmy][1-9][0-9]*/;
const relativeDateRegex = /^[>,<]?[-,+]r[Mhdwmy][1-9][0-9]*/;
export const lastPeriodRegex = /^([dwmqy])-(\d+)$/;

export function isRange(str: string): boolean {
    if (typeof str !== 'string') return false;
    const splitStr = str.split(' ');

    return Boolean(
        splitStr.length === 3 && splitStr[1] === 'TO' && splitStr[0].length && splitStr[2].length
    );
}

export function isMagicDateValue(str: string): boolean {
    if (typeof str !== 'string') return false;

    return Boolean(str.match(magicDateRegex));
}

export function isRelativeDateValue(str: string): boolean {
    if (typeof str !== 'string') return false;

    return Boolean(str.match(relativeDateRegex));
}

export function isCurrentPeriodValue(str: string): boolean {
    return (
        str === 'y' ||
        str === 'm' ||
        str === 'q' ||
        str === 'w' ||
        str === 'd' ||
        str === 'h' ||
        str === 'M'
    );
}

export function isExactYearValue(str: string): boolean {
    const fourDigitsRegex = /^\d{4}$/;

    return fourDigitsRegex.test(str);
}

export function isLastPeriodValue(str: string): boolean {
    return Boolean(str.match(lastPeriodRegex));
}

export function isToDatePeriodValue(str: string): boolean {
    if (typeof str !== 'string') return false;

    return str.slice(1) === 'td';
}
