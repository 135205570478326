import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import OutboundArrow from './icon-outbound-arrow.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <OutboundArrow {...validProps} />;
}
SvgComponent.iconName = 'outboundArrow';

export const OutboundArrowIcon = svgIconWrapper(SvgComponent);
