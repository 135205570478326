import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import DripSequence from './icon-drip-sequence.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <DripSequence {...validProps} />;
}
SvgComponent.iconName = 'drip-sequence';

export const DripSequenceIcon = svgIconWrapper(SvgComponent);
