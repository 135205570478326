/* @flow */

import * as React from 'react';

import {Card} from '../../../card';
import {IconButton} from '../../../button';
import {CommentIcon, OpenIcon} from '../../../icon';
import {AddReactionButton} from '../../reaction-bar/add-reaction-button';
import type {Reaction} from '../../reaction-bar/types';

import {renderOptionForToolbar} from './helpers';

import './timeline-entry-toolbar.css';

export type ToolbarOption =
    | {|
          id: string,
          icon: React.ComponentType<*>,
          onClick: () => any,
          tooltipText: string,
          isDisabled?: boolean,
      |}
    | {|
          // For cases where option icon button is more complex
          component: React.Node,
          id: string,
      |};

export type Props = {|
    onClickOpen?: (e: SyntheticEvent<*>) => void,
    handleComment?: () => void,
    disableComments?: boolean,
    disableReactions?: boolean,
    actionMenu?: React.Node,
    additionalToolbarOptions?: ToolbarOption[],
|};

type OwnProps = {|
    ...Props,
    onToggleReaction?: (reactionType: Reaction) => any,
|};

export function TimelineEntryToolbar(props: OwnProps) {
    const {
        onClickOpen,
        onToggleReaction,
        handleComment,
        disableComments,
        disableReactions,
        actionMenu,
        additionalToolbarOptions,
    } = props;

    // Don't show a tiny little empty card if it's an empty toolbar
    if (
        disableReactions &&
        disableComments &&
        !additionalToolbarOptions &&
        !actionMenu &&
        !onClickOpen
    ) {
        return null;
    }

    return (
        <Card hasBorder={true} fitContent={true}>
            <div styleName='container'>
                {!disableReactions && onToggleReaction ? (
                    <AddReactionButton onSelectReaction={onToggleReaction} />
                ) : (
                    undefined
                )}
                {!disableComments && handleComment ? (
                    <IconButton icon={CommentIcon} onClick={handleComment} tooltipText='Comment' />
                ) : (
                    undefined
                )}
                {additionalToolbarOptions
                    ? additionalToolbarOptions.map((option) => renderOptionForToolbar(option))
                    : undefined}
                {onClickOpen ? (
                    <IconButton icon={OpenIcon} onClick={onClickOpen} tooltipText='Open' />
                ) : (
                    undefined
                )}
                {actionMenu}
            </div>
        </Card>
    );
}
