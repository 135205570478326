/* @flow */

import * as React from 'react';

type Props = {
    height?: number | string,
};

export function BalloonMan(props: Props) {
    return (
        <svg
            height={props.height || 170}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 119 288'
        >
            <path
                d='M25.477 276.277c2.527.32 4.761 2.266 7.417 2.471 1.259.098 2.09-.076 2.858-1.049 2.222-2.798-1.547-19.529-2.468-24.092-.225-1.126-2.177-7.502-.647-7.975l48.753-17.618c1.886 1.233 11.707 22.943 12.569 24.782 2.799 5.963 5.973 11.769 8.392 17.911 1.296 3.299 4.963 10.155 2.902 14.029-.219.412-.395.801-.868 1.054-2.113 1.135-5.204-1.646-6.39-2.598-7.652-6.13-12.298-14.567-19.606-21.02-1.64-1.447-3.456-2.862-5.761-3.396a18.31 18.31 0 0 0-3.49-.589 11.812 11.812 0 0 0-3.95.978c-.182.078-.355.166-.53.255a24.238 24.238 0 0 0-3.522 2.466c-1.849 1.756-3.27 3.934-4.49 6.236-2.133 4.028-4.08 8.11-6.94 11.688-2.099 2.624-4.3 6.166-7.652 7.335-1.975.682-4.196.453-6.225-.239-4.058-1.362-7.494-3.816-10.33-6.975-1.787-1.944-3.344-4.071-.022-3.654Z'
                fill='#F2F0F0'
            />
            <path
                d='M57.813 241.95c-4.99 1.972-25.477 7.545-25.477 7.545l.494 1.944c.459-.12 2.947-.866 3.455-1.002 2.442-.661 4.88-1.329 7.311-2.028 2.325-.668 12.983-3.492 20.028-6.781 4.42-2.066 17.189-8.876 17.83-9.303.642-.427 1.235-.954 1.918-1.319l-.649-1.133-.323-.566c-.064-.109-.18-.446-.306-.496-.247-.095-.83.365-1.037.489-.728.437-1.427.885-2.16 1.317-.785.463-1.566.927-2.345 1.393-1.572.935-3.14 1.875-4.719 2.8-3.15 1.847-6.338 3.638-9.643 5.212a66.123 66.123 0 0 1-4.377 1.928Z'
                fill='#F2F0F0'
            />
            <path
                d='M82.936 229.221c2.981 4.787 5.277 10.018 7.666 15.106 2.39 5.088 4.69 10.121 7.158 15.133 2.06 4.175 3.897 8.411 5.736 12.678 1.217 2.825 2.577 5.761 2.843 8.848.19 2.208-.367 5.159-3.161 5.247-2.794.089-5.344-2.538-7.213-4.188-7.158-6.317-11.828-14.916-19.38-20.829a12.934 12.934 0 0 0-6.677-2.753c-3.648-.413-7.252 2.085-9.483 4.678-2.927 3.404-4.571 7.673-6.778 11.509a49.098 49.098 0 0 1-3.766 5.603c-1.363 1.756-2.649 3.619-4.345 5.1-2.532 2.209-5.758 2.863-9.046 2.066-3.93-.954-7.503-3.318-10.317-6.083-.79-.775-4.92-4.465-2.192-5.2 1.437-.384 3.105.184 4.404.716 1.829.77 5.746 3.158 7.083.46 1.109-2.235.36-5.744.082-8.077-.765-6.359-2.197-12.606-3.589-18.844-.05-.239 1.017-.415 1.076-.153 1.153 5.179 2.313 10.346 3.133 15.591.424 2.736.829 5.52.807 8.292-.013 1.569-.045 3.48-1.63 4.377-2.697 1.526-5.886-.606-8.347-1.525-.74-.274-2.747-1.128-2.92.053-.077.532.74 1.4 1.027 1.777a25.732 25.732 0 0 0 4.228 4.186c4.154 3.292 10.576 6.441 15.246 2.324 1.656-1.46 2.913-3.28 4.248-5.01a49.4 49.4 0 0 0 3.789-5.593c3.803-6.601 6.871-15.45 15.797-16.636 8.172-1.085 14.353 7.574 18.731 12.881 2.962 3.6 5.89 7.266 9.38 10.422a42.34 42.34 0 0 0 3.576 2.955c.637.477 1.368.997 2.135 1.245 1.876.601 2.649-.744 2.962-2.123.644-2.974-.693-6.247-1.809-8.964-1.372-3.339-2.757-6.679-4.292-9.963-2.864-6.102-5.86-12.144-8.713-18.251-2.634-5.643-5.142-11.497-8.454-16.812-.14-.238.886-.46 1.024-.238l-.02-.005Z'
                fill='#0C0F33'
            />
            <path
                d='M45.443 254.937c.32 2.087-.282 4.177-1.713 6.009-.435.556-.37 1.3.427 1.41.861.121 1.802-.609 2.26-1.193 1.871-2.386 2.651-5.172 2.222-7.891-.215-1.391-3.404.391-3.209 1.67l.013-.005Z'
                fill='#D4D1CF'
            />
            <path
                d='M46.312 254.072c.397 2.569-.418 5.057-2.024 7.108-.23.294-1.291.239-.973-.162 1.54-1.968 2.303-4.332 1.92-6.791-.061-.401 1.012-.558 1.077-.155Z'
                fill='#0C0F33'
            />
            <path
                d='M81.008 225.55a19.745 19.745 0 0 1-.175-.391c-.549-1.062-.906-1.866-1.481-2.92-4.404-8.237-8.936-16.433-14.81-23.78l-.06-.074c-.397-.496-.797-.98-1.196-1.459l-.126-.153c.859-1.787 1.634-3.502 2.493-5.288a8.806 8.806 0 0 1-2.587-1.16c-.755-.503-1.31-1.359-2.046-1.808-.299.93-.686 1.775-1.728 2.121-1.014.331-1.908.148-2.883-.091-2.18-.534-4.376-1.872-6.096-3.273-1.975-1.603-3.444-2.786-6.142-2.659-2.902.136-5.494 1.507-7.562 3.425-3.172 2.939-4.937 7.032-6.06 11.066-.89 3.206-1.303 7.013-3.996 9.369-.763.668-1.62 1.236-2.313 1.968l3.298 2.386c1.046.751.827.954.768 2.104-.082 1.6.227 3.191.276 4.787.081 2.757.27 5.51.526 8.256.52 5.584 1.33 11.139 2.142 16.697.207 1.41.417 2.822.644 4.229l.037.227.074.477s21.664-6.338 28.553-9.274c5.838-2.488 21.923-11.765 21.923-11.765-.466-.947-.965-2.03-1.473-3.017Z'
                fill='#fff'
            />
            <path
                d='M33.496 248.733c8.336-2.483 16.846-4.606 24.991-7.633 7.682-2.863 14.645-7.715 21.704-11.743.373-.213 1.942-.12 1.21.298-7.159 4.088-14.22 8.985-22.013 11.891-8.268 3.084-16.905 5.247-25.368 7.764-.687.205-1.583-.253-.524-.568v-.009Z'
                fill='#0C0F33'
            />
            <path
                d='M34.195 250.753c8.614-2.385 17.307-4.735 25.71-7.757 8.001-2.879 15.48-7.433 22.828-11.581.6-.339 1.856.157.936.677-7.368 4.158-14.854 8.683-22.859 11.605-8.5 3.101-17.322 5.462-26.05 7.872-.79.219-1.611-.535-.565-.826v.01Z'
                fill='#0C0F33'
            />
            <path
                d='m43.493 202.045-5.707-1.67c-1.802-.527-4.045-.84-5.714-1.67-1.565-.782-.632-2.147-.108-3.609a23.704 23.704 0 0 1 1.703-3.757c1.37-2.385 3.416-4.427 6.035-5.512 2.014-.838 6.138-1.799 7.728 0 .987 1.13 2.386 1.853 3.756 2.526 1.333.653 3.37 1.164 4.5 2.08 1.13.916.163 2.037-.45 3.265a21.352 21.352 0 0 1-2.823 4.28c-.962 1.133-2.152 2.227-3.65 2.464-1.499.236-3.27-.78-3.246-2.245l-2.024 3.848Z'
                fill='#fff'
            />
            <path
                d='M55.64 192.808c-.867 2.2-2.047 4.21-3.758 5.906-.493.478-1.48 1.525-2.194 2.052l-3.43-2.808c-.549-.446-1.501.148-1.711.673-.517 1.272-1.032 2.544-1.545 3.817l-9.565-3.34c-.908-.317-2.31 1.209-1.16 1.613l10.628 3.699c.568.198 1.313-.255 1.518-.763.46-1.131.918-2.262 1.375-3.394l1.767 1.445c.894.728 1.33 1.236 2.469.556 3.468-2.08 6.074-5.785 7.488-9.384.484-1.198-1.53-.969-1.883-.072Z'
                fill='#F1F1F1'
            />
            <path
                d='M56.143 192.164c-.847 2.347-2.039 4.504-3.804 6.331-.844.875-2.09 2.335-3.258 2.757a.721.721 0 0 1-.63-.059l-3.702-3.189a.243.243 0 0 1-.082-.107.23.23 0 0 1-.011-.132l.096-.26c.148-.398 1.234-.42 1.064.021l-1.913 5.153c-.116.315-.593.379-.877.272l-10.144-3.85c-.597-.227.294-.716.691-.566l10.145 3.85-.88.272c.64-1.717 1.277-3.435 1.914-5.152l1.064.021c-.07.191-.208.031-.094.103.129.083.245.184.345.298.403.392.83.759 1.279 1.1l1.353 1.164c.083.074.404.255.427.367l.145.127c.196.159 0 .154-.59-.015.185-.365.955-.794 1.271-1.068.66-.56 1.283-1.158 1.869-1.789a16.974 16.974 0 0 0 3.26-5.67c.144-.399 1.222-.42 1.062.024v-.003Z'
                fill='#0C0F33'
            />
            <path
                d='M81.549 228.551c-5.584-10.825-11.108-22.098-19.006-31.561a.269.269 0 0 1 0-.286c.842-1.758 1.651-3.53 2.493-5.288.091.155.18.312.269.477-2.118-.637-3.162-1.83-4.853-3.08l.97-.238c-1.685 4.806-7.13 1.67-10.048-.215-.494-.31.494-.744.874-.496 2.19 1.407 6.785 4.437 8.108.666.126-.363.676-.444.968-.239 1.515 1.121 2.517 2.302 4.428 2.877.234.069.382.238.269.477-.696 1.453-2.78 4.305-1.869 5.744.852 1.34 1.997 2.562 2.92 3.866 2.098 2.951 4.016 6.007 5.833 9.108 3.438 5.837 6.55 11.841 9.65 17.849.247.477-.792.759-1.007.341v-.002Z'
                fill='#0C0F33'
            />
            <path
                d='M62.888 190.409c1.175-4.055 3.054-7.893 4.014-12.008.987-4.272.493-8.649-.81-12.812-1.48-4.713-3.971-9.047-6.718-13.167-1.301-1.951-2.664-3.862-4.014-5.78-.834-1.192-2.56-2.795-1.21-4.162.213-.215 1.131-.143 1.05-.062-1.002 1.016-.264 1.908.414 2.896.68.987 1.415 2.008 2.12 3.012 1.294 1.842 2.58 3.688 3.787 5.584 2.537 3.977 4.751 8.185 6 12.719 1.158 4.208 1.336 8.468.183 12.69-1.022 3.748-2.683 7.297-3.764 11.028-.07.238-1.118.298-1.052.062Z'
                fill='#0C0F33'
            />
            <path
                d='M44.236 169.556c-5.29-9.978-14.121-15.817-19.732-13.041-5.61 2.777-5.864 13.12-.577 23.095.123.239.74 1.381.948 1.789 2.932 5.532 5.464 9.146 7.737 11.407 4.673 4.649 12.747 3.559 12.747 3.559s5.82-5.725 5.003-11.552c-.447-3.185-2.01-7.488-5.184-13.468-.204-.394-.814-1.534-.942-1.789Z'
                fill='#DBAFAB'
            />
            <path
                d='M39.837 184.109c-.4.463 1.538 1.388 1.79 1.477.881.307 1.903.21 2.594-.477.55-.547.79-1.415.847-2.264.027-.453.027-2.056-.346-2.345-.373-.288-1.48 1.412-1.728 1.67-.849.825-1.858 1.55-2.962 1.839a.346.346 0 0 0-.167.076.074.074 0 0 0-.028.024Z'
                fill='#0C0F33'
            />
            <path
                d='M46.104 207.608c-.165 3.749-.345 7.499-.493 11.251-.168 4.439-1.178 8.549-3.251 12.02.462 1.264 3.63 5.31 3.727 6.679.871-.792 4.24-4.227 5.12-5.071 1.76-1.694.318-13.284-.356-16.683-.336-1.711-1.637-9.482-3.3-9.458h-.608c-.476 0-.853.527-.844 1.159a.379.379 0 0 1 .005.103Z'
                fill='#DDD'
            />
            <path
                d='M46.2 207.18c.158 3.752.302 7.505.494 11.254.217 4.437-.434 8.611-2.199 12.24.568 1.221 4.073 5.009 4.29 6.359.8-.859 3.86-4.554 4.662-5.467 1.61-1.828-.829-13.263-1.792-16.595-.493-1.67-2.45-9.317-4.104-9.16l-.608.057c-.47.046-.804.592-.74 1.222a.37.37 0 0 0-.002.09Z'
                fill='#3B9ED7'
            />
            <path
                d='M46.738 207.104c.262 6.268 1.294 12.974-.16 19.161a26.227 26.227 0 0 1-1.148 3.528c-.456 1.164.047 1.52.79 2.488 1.074 1.398 2.666 2.937 3.093 4.675l-.958-.131c1.042-1.142 2.017-2.342 3.011-3.523.716-.849 1.701-1.67 1.945-2.776.662-2.963-.283-6.584-.836-9.499a89.768 89.768 0 0 0-1.795-7.502 44.517 44.517 0 0 0-1.56-4.73 10.689 10.689 0 0 0-.878-1.806c-.425-.646-1.602-1.364-1.496.115.034.505-1.04.651-1.076.152-.139-1.908 2.186-2.371 3.275-1.049 1.71 2.08 2.31 5.364 3.016 7.872a80.243 80.243 0 0 1 1.82 8.024c.463 2.664 1.233 5.889.582 8.587-.247 1.04-1.079 1.765-1.76 2.572-1.123 1.333-2.221 2.695-3.401 3.985-.232.256-.864.239-.958-.131-.432-1.758-2.187-3.375-3.26-4.797-.358-.477-.95-1.068-1.027-1.669-.065-.528.664-1.501.856-2.033.22-.61.407-1.231.57-1.858 1.632-6.25.553-13.138.287-19.503-.028-.505 1.046-.646 1.068-.152Z'
                fill='#0C0F33'
            />
            <path
                d='M45.645 202.467c.18-.374.479-.587 1.039-.308.797.401 1.493 1.267 2.108 1.887.508.516.777.892-.047 1.284-.728.346-1.669.477-2.451.691-1.249.351-1.089-1.762-.96-2.371a5.43 5.43 0 0 1 .31-1.183Z'
                fill='#3B9ED7'
            />
            <path
                d='M45.114 202.474c.874-1.491 2.54-.267 3.374.579.383.387 1.205.983 1.306 1.546.101.563-.647.885-1.086 1.052a12.91 12.91 0 0 1-2.45.639c-.465.074-.973.019-1.26-.381-.622-.864-.247-2.555.116-3.435.131-.325 1.2-.36 1.06 0a5.488 5.488 0 0 0-.388 2.049c-.008.276.025.551.098.818.166.522.494.394.968.286.474-.107 1.042-.186 1.48-.417.541-.287.433-.489.09-.873-.203-.239-2.049-2.197-2.244-1.863-.195.334-1.236.296-1.064 0ZM27.442 212.33c-1.876 3.144-3.732 6.317-6 9.217-2.269 2.901-4.937 5.234-7.9 7.395-2.433 1.755-11.143 8.272-13.54 3.172-.054-.114.958-.4 1.074-.152 2.429 5.171 13.977-4.678 16.266-6.918 3.759-3.666 6.402-8.208 9.048-12.642.138-.239 1.123-.198 1.05-.074l.002.002Z'
                fill='#0C0F33'
            />
            <path
                d='M32.867 194.141c-1.076 2.386-1.772 4.836-2.387 7.352-.474 1.932-.987 3.924-2.135 5.606a10.103 10.103 0 0 1-2.088 2.147c-.528.429-1.234.663-1.04 1.345.095.348 1.378.995 1.647 1.193.654.477 1.728.992 1.975 1.815.284.885-.037 2.116.027 3.044.76 11.029 1.89 21.928 3.66 32.858.077.477-.987.63-1.076.155-1.088-6.853-2.108-13.723-2.791-20.614-.336-3.385-.432-6.772-.677-10.16-.1-1.412-.103-2.836-.2-4.238-.128-1.863-2.54-2.92-3.988-3.965a.284.284 0 0 1-.098-.225.282.282 0 0 1 .117-.216c1.235-1.207 2.735-2.106 3.68-3.578.833-1.3 1.294-2.779 1.681-4.251.76-2.9 1.462-5.746 2.716-8.499.182-.408 1.234-.312.987.222l-.01.009Z'
                fill='#0C0F33'
            />
            <path
                d='M22.16 179.014c-.887-.716-6.151-6.045-5.569-13.633.44-5.725 4.314-10.443 9.792-12.63 9.813-3.919 15.908 7.258 18.98 9.956.395.349-2.334 1.026-2.334 1.026s2.594 2.941 4.418 3.316c1.824.374-8.447 4.489-10.609 4.909-7.822 1.491-14.136-6.56-13.27-3.922 1.736 5.279-1.409 10.978-1.409 10.978Z'
                fill='#3D405C'
            />
            <path
                d='M43.69 169.635c-2.164-4.01-5.008-7.796-8.745-10.572-2.744-2.04-6.962-4.21-10.346-2.304-3.061 1.724-3.9 5.605-3.95 8.754-.056 4.055 1.072 8.132 2.735 11.841a69.483 69.483 0 0 0 6.376 11.244c1.942 2.777 4.124 5.396 7.451 6.636a17.89 17.89 0 0 0 7.983.955c.355-.039-.452.355-.247.059.172-.238.424-.453.62-.677a21.79 21.79 0 0 0 2.347-3.256c1.96-3.34 2.303-6.644 1.253-10.322-1.234-4.334-3.379-8.368-5.491-12.351-.094-.179.915-.456 1.076-.155 2.172 4.1 4.393 8.265 5.6 12.747 1.005 3.736.454 7.039-1.582 10.365-.716 1.166-1.957 3.551-3.436 3.976-2.085.594-5.084.062-7.116-.425-3.905-.932-6.52-3.268-8.811-6.357a67.627 67.627 0 0 1-6.958-12.015c-1.975-4.293-3.296-9.171-2.75-13.89.353-3.05 1.656-6.247 4.714-7.602 3.21-1.431 6.88-.386 9.747 1.312 4.69 2.777 8.106 7.245 10.614 11.882.094.176-.913.455-1.084.155Z'
                fill='#0C0F33'
            />
            <path
                d='M90.44 82.803c15.773-15.243 15.773-39.958 0-55.202-15.774-15.244-41.348-15.244-57.121 0-15.774 15.244-15.774 39.959 0 55.202 15.773 15.244 41.347 15.244 57.12 0Z'
                fill='#BD9633'
            />
            <path
                d='M54.528 102.229a.853.853 0 0 1-.513-.16c-.264-.291.316-.875.469-1.171l.673-1.307c.494-.954.988-1.917 1.481-2.887l1.797-3.463c.296-.577.677-2.092 1.52-1.57.556.344 1.057 1.453 1.482 1.963a23.135 23.135 0 0 0 3.702 3.53c.494.37 1.002.514.664 1.136-.31.533-.767.973-1.32 1.27-2.568 1.402-5.379 2.53-8.363 2.623-.53.046-1.061.058-1.592.036Z'
                fill='#FAC942'
            />
            <path
                d='M59.154 96.028v31.524c0 2.481.015 4.962-.143 7.44-.15 2.329-.267 4.919-.987 7.156-.635 1.956-2.693 2.77-3.51 4.604-.647 1.446-.511 3.101.632 4.275.362.372 1.767-.155 1.367-.563-1.728-1.763-.72-4.091.864-5.541 1.402-1.286 2.093-2.433 2.429-4.294.856-4.771.832-9.589.832-14.412V95.865c0-.608-1.481-.314-1.481.172l-.003-.01Z'
                fill='#0C0F33'
            />
            <path
                d='M54.701 101.626c-.153.083-.281.119-.365.069-.247-.141-.077-.389 0-.568.44-1.192 1.2-2.33 1.78-3.456a719.08 719.08 0 0 0 2.22-4.32c.19-.372.282-.873.593-1.174.43-.41 1.163-.283 1.63 0 .347.245.655.537.915.869.602.701 1.123 1.43 1.666 2.173.494.684 1.261 1.216 1.804 1.894.282.35.598.966-.088 1.111-.465.098-1.138-.076-1.63-.064-2.61.077-5.135.917-7.241 2.409-.223.165-.845.814-1.284 1.057Z'
                fill='#BD9633'
            />
            <path
                d='M102.734 56.459c0 20.13-18.194 36.45-40.638 36.45s-35.41-16.32-35.41-36.45c0-20.13 17.306-36.448 35.41-36.448 22.444 0 40.638 16.318 40.638 36.448Z'
                fill='#FAC942'
            />
            <path
                d='M57.167 94.17c-.728 1.43-1.503 2.862-2.256 4.293-.336.635-.657 1.274-.987 1.908-.19.38-.674.988-.711 1.432-.15 1.782 4.406.82 5.349.615 2.354-.506 5.56-1.515 7.29-3.213.603-.59 1.072-1.32.445-2.025-.975-1.1-2.411-1.7-3.033-3.13l-.815.683c16.676-.342 32.008-10.534 37.922-25.679 5.959-15.235 1.407-32.822-11.245-43.556C76.518 14.785 57.804 12.67 42.928 20.16c-14.876 7.49-23.71 23.25-21.946 39.373 1.768 16.187 14.24 30 30.557 33.982 2.028.49 4.09.837 6.171 1.038l-.444-.561-.09.177c-.352.703 1.134.67 1.403.133l.089-.176c.155-.313-.139-.53-.44-.561C42 91.973 28.081 80.711 23.698 65.51c-4.37-15.16 1.414-31.816 14.451-41.267 13.141-9.522 31.505-10.019 45.238-1.34 13.576 8.587 20.529 24.673 17.35 40.054-3.17 15.348-16.214 27.756-32.246 30.285-1.947.302-3.914.47-5.886.505-.262 0-.975.3-.81.685.359.741.861 1.41 1.48 1.968.265.262.539.515.828.751.553.453.516.239.4 1.121-.129.997-.615 1.071-1.481 1.496-.39.195-.784.382-1.183.558-1.052.472-2.15.847-3.275 1.119-.627.145-2.893.715-3.515.312-.424-.281-.212-.031-.11-.515.08-.386.441-.844.626-1.216.968-1.923 2.029-3.8 2.999-5.725.36-.702-1.126-.668-1.397-.132Z'
                fill='#0C0F33'
            />
        </svg>
    );
}
