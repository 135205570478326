/* @flow */
import type {PostalAddress} from '../types';

export function convertAddressToString(address: PostalAddress): string {
    const components = [];

    const hasState = Boolean(address.state);

    if (address.address_1) components.push(address.address_1);
    if (address.address_2) components.push(address.address_2);
    if (address.address_3) components.push(address.address_3);

    if (address.city) components.push(hasState ? `${address.city},` : address.city);
    if (address.state) components.push(address.state);
    if (address.country) components.push(address.country);
    if (address.postalCode) components.push(address.postalCode);

    return components.join(' ');
}
