/* @flow */
import {createAction} from 'redux-act';

import type {McfxCompanyFragment} from 'nutshell-graphql-types';
import type {FilterObject} from 'nutshell-core/types';

export type McfxCompaniesListRequestedAction = {
    type: 'MCFXCOMPANIES_LIST_DATA_REQUESTED',
    payload: {
        filters: FilterObject[],
        pageNum: number,
        columns: string[],
        sort: string,
        q: string,
    },
};

export type McfxCompaniesListItemsUpdatedAction = {
    type: 'MCFXCOMPANIES_LIST_ITEMS_UPDATED',
    payload: {
        updatedCompanies: McfxCompanyFragment[],
    },
};

export const toggleShowFilterDrawer = createAction('MCFXCOMPANIES_LIST_FILTER_DRAWER_TOGGLED');

// Companies list
export const requestMcfxCompaniesListData: (
    payload: $PropertyType<McfxCompaniesListRequestedAction, 'payload'>
) => McfxCompaniesListRequestedAction = createAction('MCFXCOMPANIES_LIST_DATA_REQUESTED');
export const updateMcfxCompaniesListData = createAction('MCFXCOMPANIES_LIST_DATA_UPDATED');
export const failMcfxCompaniesListData = createAction('MCFXCOMPANIES_LIST_DATA_FAILED');

export const updateMcfxCompaniesListItems: (
    payload: $PropertyType<McfxCompaniesListItemsUpdatedAction, 'payload'>
) => McfxCompaniesListItemsUpdatedAction = createAction('MCFXCOMPANIES_LIST_ITEMS_UPDATED');

// McfxCompanies schema
export const requestMcfxCompaniesSchema = createAction('MCFXCOMPANIES_SCHEMA_REQUESTED');
export const updateMcfxCompaniesSchema = createAction('MCFXCOMPANIES_SCHEMA_UPDATED');
export const failMcfxCompaniesSchema = createAction('MCFXCOMPANIES_SCHEMA_FAILED');
