/* @flow */

import * as React from 'react';

import classnames from 'classnames';

import {RadioGroupContext} from './radio-group';

import './radio.css';

type Props = {
    disabled?: boolean,
    value: string,
    children?: React.Node,
    size?: number,
    shouldTopAlignInput?: boolean,
    shouldWidthFitContent?: boolean,
};

export class Radio extends React.Component<Props> {
    render() {
        return (
            <RadioGroupContext.Consumer>
                {({name, selectedValue, inline, onChange}) => {
                    const checked = this.props.value === selectedValue;
                    const radioId = `${name}-${this.props.value}`;

                    const labelStyleNames = classnames({
                        radio: !inline,
                        'radio--inline': inline,
                        'radio--top-align': this.props.shouldTopAlignInput,
                        'radio--width-fit-content': this.props.shouldWidthFitContent,
                    });

                    return (
                        <label styleName={labelStyleNames} htmlFor={radioId}>
                            <input
                                id={radioId}
                                name={name}
                                style={
                                    this.props.size && {
                                        width: this.props.size,
                                        height: this.props.size,
                                    }
                                }
                                type='radio'
                                role='radio'
                                checked={checked}
                                aria-checked={checked}
                                disabled={this.props.disabled}
                                onChange={(e) => this.handleChange(e, onChange)}
                            />
                            <span>{this.props.children}</span>
                        </label>
                    );
                }}
            </RadioGroupContext.Consumer>
        );
    }

    handleChange = (e: SyntheticInputEvent<*>, onChange: Function) => {
        e.stopPropagation();

        onChange(this.props.value);
    };
}
