/* @flow */

import * as React from 'react';

import {Checkbox} from '../checkbox';

import './list-item.css';

type Props = {
    id: string,
    content: React.Node,
    isChecked?: boolean,
    // If passed, component will be selectable with a checkbox
    onSelect?: (leadId: string) => void,
    isDisabled?: boolean,
};

export function ListItem(props: Props) {
    const {id, content, isChecked, onSelect, isDisabled} = props;

    return (
        <div styleName='list-item'>
            {onSelect ? (
                <Checkbox
                    onChange={() => onSelect(id)}
                    checked={isChecked}
                    disabled={isDisabled}
                    label={content}
                />
            ) : (
                content
            )}
        </div>
    );
}
