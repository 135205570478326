/* @flow */

import type {LeadStage} from '../types';
import {STAGES_REQUESTED, STAGES_UPDATED, STAGES_FAILED} from './stages-actions';
import type {StagesState, StagesAction} from './stages-types';

const stagesDefaultState = {
    isLoading: false,
    byStageset: {},
    errorMessage: undefined,
};

export const reducer = (
    state: StagesState = stagesDefaultState,
    action: StagesAction
): StagesState => {
    switch (action.type) {
        case STAGES_REQUESTED: {
            return {...state, isLoading: true, errorMessage: undefined};
        }
        case STAGES_UPDATED: {
            const newStages = action.payload.reduce((accum, stage: LeadStage) => {
                if (!accum[stage.links.stageset]) {
                    accum[stage.links.stageset] = {};
                }

                accum[stage.links.stageset][stage.id] = stage;

                return accum;
            }, {});

            return {
                ...state,
                byStageset: newStages,
                loadingErrorMessage: undefined,
                isLoading: false,
            };
        }
        case STAGES_FAILED: {
            return {
                ...state,
                errorMessage: 'We’re having trouble getting your stages right now.',
                isLoading: false,
            };
        }
        default:
            return state;
    }
};
