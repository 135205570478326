/* @flow */

import {createSelector, type InputSelector, type OutputSelector} from 'reselect';
import * as ramda from 'ramda';

import type {CollectionState} from './collections-reducer';
import type {NutshellCollectionsState, CollectionName, CollectionModel} from './collections-types';

/*
 * This selector pulls out the state for a particular collection, given its name.
 * The flow typing is a little complicated, but the intent is to ensure that the returned
 * state has the proper typing for the collection being used.
 */
function getCollectionState<S: {+[string]: mixed}, N: $Keys<S>>(
    state: S,
    collectionName: N
): $ElementType<S, N> {
    return state[collectionName];
}

/*
 * This will return the `byId` map for a collection of a given type.
 */
export function getAllOfType(state: NutshellCollectionsState, collectionName: CollectionName) {
    const collectionState = getCollectionState(state, collectionName);

    return collectionState ? collectionState.byId : {};
}

/*
 * -----------------------------------------------
 * Selectors for individual CollectionState pieces of state
 * -----------------------------------------------
 */

/*
 * Pull out the `byId` state from an individual CollectionState
 */
// $FlowFixMe Not sure why it's complaining about an incompatible union type for M, but it seems to work
export const getById = <M, S: CollectionState<M>>(state: S): {[apiId: string]: M} => state.byId;

/*
 * Selector to get an array of all the models contained in the `byId` key
 * of a collection.
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getModels = createSelector([getById], (byIdState) => ramda.values(byIdState));

/*
 * Selector to get the `isLoading` portion of a collection state.
 */
export const getIsLoading = (state: CollectionState<*>) => state.isLoading;

/*
 * Selector to get the `isLoading` portion of a collection state.
 */
export const getErrorMessage = (state: CollectionState<*>) => state.errorMessage;

/*
 * This is a selector factory function which takes a selector as an argument,
 * and returns a new selector which will behave like a normal selector, taking
 * the global `state` and returning an array of models.
 *
 * The selector provided as an argument should take the global state, and return
 * a collection state slice, like `markets` or `users`.
 */
export function makeGetModels<
    M: CollectionModel,
    T: InputSelector<NutshellCollectionsState, null, CollectionState<M>>
>(collectionStateGetter: T): OutputSelector<*, null, M[]> {
    return createSelector(
        [collectionStateGetter],
        // $FlowFixMe upgrading Flow to v0.92.1.
        (collectionState) => getModels(collectionState)
    );
}
