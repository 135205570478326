/* @flow */

import * as React from 'react';
import {ButtonIcon, ColumnPickerIcon} from '../../icon';
import './column-picker-button.css';

type Props = {
    // TODO: Make this work, maybe needs newer than flow 0.53?
    // ...HTMLButtonElement,
    withBackground?: boolean,
};

export function ColumnPickerButton(props: Props) {
    const {withBackground, ...htmlButtonProps} = props;

    return (
        <div styleName='container'>
            <button
                {...htmlButtonProps}
                styleName={withBackground ? 'button--background' : 'button'}
                type='button'
            >
                <ButtonIcon size='normal' variant='text-primary' icon={ColumnPickerIcon} /> Change
                columns
            </button>
        </div>
    );
}
