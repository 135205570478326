/* @flow */

import * as React from 'react';

import type {NotifyMessage} from 'nutshell-core/types';

import {CloudDownloadIcon} from '../icon';
import {RemoveButton} from '../button';

import './notify-complete.css';

type Props = {
    notify: NotifyMessage,
    onAcknowledgeExport: (notify: NotifyMessage) => any,
    onAcknowledgeExportDownload: () => any,
};

export function NotifyComplete(props: Props) {
    const {name, hash, filename} = props.notify;

    const handleAcknowledgeExport = () => {
        props.onAcknowledgeExport(props.notify);
    };

    if (hash && filename) {
        return (
            <li key={name} styleName='notify-complete'>
                <RemoveButton size={10} onClick={handleAcknowledgeExport} />
                <a
                    styleName='complete-text-container'
                    onClick={props.onAcknowledgeExportDownload}
                    href={`/file/export?hash=${hash}&filename=${filename}&notifyId=${name}`}
                >
                    <span styleName='notify-text'>{filename}</span>
                    <div styleName='icon'>
                        <CloudDownloadIcon size={17} />
                    </div>
                </a>
            </li>
        );
    }

    return null;
}
