/* @flow */

export const isFileTypeAccepted = (file: {name: string}, accept: ?string): boolean => {
    // fileType needs to be the extension of the file to match our accept list format
    const fileType = file.name.split('.')[1];

    // Split the accept string into an array of accepted file types
    const acceptedFileTypes = accept ? accept.split(',') : undefined;

    // If accept is not set, accept all file types
    return acceptedFileTypes ? acceptedFileTypes.includes(`.${fileType}`) : true;
};
