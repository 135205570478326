/* @flow */

import {type OutputSelector} from 'reselect';
import type {Product} from '../types';
import {Collections, type CollectionState} from '../collections';

type ProductsModuleState = {products: CollectionState<Product>};

/*
 * Given the entire redux state, pull out just the piece of state for the products reducer.
 */
const getProductsState = (state: ProductsModuleState) => state.products;

/*
 * Get an array of all products models
 */
export const getAll: OutputSelector<*, *, Product[]> = Collections.makeGetModels(getProductsState);

/*
 * Get the `byId` portion of the products state
 */
export const getById = (state: ProductsModuleState) => {
    const productsState = getProductsState(state);

    return Collections.getById(productsState);
};
