/* @flow */

import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';

import * as actions from './entity-list-actions';

const isFilterDrawerOpen = createReducer(
    {
        [actions.toggleShowFilterDrawer]: (state) => !state,
    },
    false
);

const defaultPersistedParamsState = {
    leads: null,
    contacts: null,
    accounts: null,
};
const persistedParams = (state = defaultPersistedParamsState, action) => {
    switch (action.type) {
        case 'ENTITY_LIST_PERSIST_PARAMS':
            return {
                ...state,
                [action.payload.entityType]: action.payload.urlQuery,
            };
        case 'ENTITY_LIST_LOAD_PERSISTED_PARAMS':
            return action.payload;
        default:
            return state;
    }
};

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const entityListReducer = combineReducers({
    isFilterDrawerOpen,
    persistedParams,
});

export type EntityListState = {
    isFilterDrawerOpen: boolean,
    persistedParams: {
        leads: ?string,
        contacts: ?string,
        accounts: ?string,
    },
};
