/* @flow */

import * as React from 'react';

import {SubSidebarHeader} from '../../sidebar';

import type {Person} from '../visitor-content/company-people/company-people';
import {DetailsList} from '../details-list/details-list';

type Props = {|
    person: Person,
    onClickBack: () => void,
|};

export function PersonDetailsContent(props: Props) {
    return (
        <>
            <SubSidebarHeader
                title={props.person.name}
                onClickBack={props.onClickBack}
                noShadow={true}
            />
            <DetailsList
                padding={24}
                details={[
                    {
                        key: 'job-title',
                        name: 'Job Title',
                        value: props.person.jobTitle || <i>Unknown</i>,
                    },
                    {
                        key: 'position',
                        name: 'Position',
                        value: props.person.position || <i>Unknown</i>,
                    },
                    {
                        key: 'region',
                        name: 'Region',
                        value: props.person.location || <i>Unknown</i>,
                    },
                    {
                        key: 'email',
                        name: 'Email',
                        value: props.person.hasEmail ? 'Add to view email' : <i>Unknown</i>,
                    },
                    {
                        key: 'phone',
                        name: 'Phone',
                        value: props.person.hasPhone ? 'Add to view phone' : <i>Unknown</i>,
                    },
                    {
                        key: 'linkedin',
                        name: 'LinkedIn profile',
                        value: props.person.hasLinkedinUrl ? (
                            'Add to view LinkedIn profile'
                        ) : (
                            <i>Unknown</i>
                        ),
                    },
                ]}
            />
        </>
    );
}
