/* @flow */

import {useMutation} from '@apollo/react-hooks';

import type {
    SaveTranscriptSummary as SaveTranscriptSummaryMutation,
    SaveTranscriptSummaryVariables as SaveTranscriptSummaryMutationVariables,
} from 'nutshell-graphql-types';

import ACTIVITY_FRAGMENT from '../../../../timeline/graphql/fragments/activity-fragment.graphql';
import SAVE_TRANSCRIPT_SUMMARY from '../mutations/save-transcript-summary.graphql';

export function useSaveTranscriptSummary(): {
    saveTranscriptSummary: (activityId: string, replace?: boolean) => Promise<*>,
} {
    const [saveTranscriptSummary] = useMutation<
        SaveTranscriptSummaryMutation,
        SaveTranscriptSummaryMutationVariables
    >(SAVE_TRANSCRIPT_SUMMARY);

    return {
        saveTranscriptSummary: (activityId: string, replace: boolean = false) => {
            return saveTranscriptSummary({
                variables: {input: {activityId, replace}},
                update: (proxy, mutationResults) => {
                    const updatedActivity =
                        mutationResults &&
                        mutationResults.data &&
                        mutationResults.data.transcriptSummarySave &&
                        mutationResults.data.transcriptSummarySave.activity;

                    if (!updatedActivity) return;

                    proxy.writeFragment({
                        id: activityId,
                        fragment: ACTIVITY_FRAGMENT,
                        fragmentName: 'ActivityFragment',
                        data: updatedActivity,
                    });
                },
            });
        },
    };
}
