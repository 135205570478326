/* @flow */

import {isBetween} from '../date-time/is-between';
import {getFiscalYearRelativeToDate} from './get-fiscal-year-relative-to-date';

/**
 * Gets the current quarter based on the relative date based, and the fiscal year
 * start setting.
 * @param  {Moment} relativeToDate     Moment object to get relative current quarter
 * @param  {string} [fiscalYearStart]  Setting-like string of fiscalYearStart (10-1)
 * @return {Object}                    Tuple object of start and end date moment objects
 */
export function getQuarterRelativeToDate(relativeToDate: Object, fiscalYearStart?: string): Object {
    const quarters = getQuarters(relativeToDate, fiscalYearStart);
    const quarterRelativeToDate = quarters.find((quarter) => {
        const {startOfQuarter, endOfQuarter} = quarter;

        return isBetween(relativeToDate, startOfQuarter, endOfQuarter);
    });

    return quarterRelativeToDate ? quarterRelativeToDate : quarters[0];
}

/**
 * Returns 4 quarters based on the relative date and the fiscal year start, where the first
 * quarter is the first quarter of the given fiscal year
 * @param  {Moment} relativeToDate     Moment object to get relative current quarter
 * @param  {string} [fiscalYearStart]  Setting-like string of fiscalYearStart (10-1)
 * @return {Object[]}                  Array of date-range moment objects {startDate, endDate}
 */
function getQuarters(relativeToDate: Object, fiscalYearStart?: string = '1-1') {
    const {startOfFiscalYear} = getFiscalYearRelativeToDate(relativeToDate, fiscalYearStart);

    return [0, 1, 2, 3].map((quarter) => {
        const startOfQuarter = startOfFiscalYear.clone().add(3 * quarter, 'months');

        const endOfQuarter = startOfQuarter
            .clone()
            .add(3, 'months')
            .subtract(1, 'days')
            .endOf('day');

        return {startOfQuarter, endOfQuarter};
    });
}
