/* @flow */

const defaultSettings = typeof window.localeData !== 'undefined' ? window.localeData : null;

export function getInstanceDefaultCurrencyCode(
    settings: ?{currencyIsoCodeInstanceDefault: string} = defaultSettings
) {
    if (!settings) {
        // Default to USD as a backup so that the UI doesn't break in cases where we need this
        return 'USD';
    }

    return settings.currencyIsoCodeInstanceDefault;
}
