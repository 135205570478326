/* @flow */

import numeral from 'numeral';
import type {FormattedValue} from '../types';

/**
 * Rounds and abbreviates a number by adding a metric prefix at the end, given a
 * raw value and a prefix. Example: 5000 -> 5k
 *
 * Note: this will work for any value (as it's just a number input), but the
 * specific formatting is based on how we show _lead_ values in Nutshell.
 *
 * @param  {number}         value     - Value to format into a FormattedValue
 * @param  {string}         prefix    - Prefix to use for the new formatted value
 * @return {FormattedValue}           - New formatted value
 */
export function formatNumberWithMetrixPrefix(value: number, prefix: string): FormattedValue {
    // Default format - might expand method to include format as third param
    const format = '0.[0]a';

    // Rounding here in the case of numbers below 1000 (they should not
    // have any decimal places)
    const roundedValue = Math.round(value);
    const formatted = numeral(roundedValue).format(format);

    return {
        prefix: prefix,
        // We don't know if we have a suffix or not, so we need to check
        suffix: formatted.charAt(formatted.length - 1).match(/[a-z]/i)
            ? formatted.charAt(formatted.length - 1)
            : '',
        // We don't know if we have a suffix or not, so we need to check
        formatted: formatted.charAt(formatted.length - 1).match(/[a-z]/i)
            ? formatted.slice(0, formatted.length - 1)
            : formatted,
        value: value,
    };
}

/**
 * Adds two formatted numbers together and re-formats into another
 * FormattedValue object, maintaining the passed prefix of firstVal.
 *
 * @param {FormattedValue} firstVal       - First value to add
 * @param {FormattedValue} secondVal      - Second value to add
 *
 * @return {FormattedValue}               - Summed up and reformatted value
 */
export function addNumbersWithMetricPrefix(
    firstVal: FormattedValue,
    secondVal: FormattedValue
): FormattedValue {
    const rawValue = firstVal.value + secondVal.value;

    return formatNumberWithMetrixPrefix(rawValue, firstVal.prefix);
}

/**
 * Subtract one formatted number from another and re-formats into another
 * FormattedValue object, maintaining the passed prefix of baseVal.
 *
 * @param {FormattedValue} baseVal         - Base value
 * @param {FormattedValue} toSubtract      - Value to subtract
 *
 * @return {FormattedValue}                - Reformatted value
 */
export function subtractNumbersWithMetricPrefix(
    baseVal: FormattedValue,
    toSubtract: FormattedValue
): FormattedValue {
    const rawValue = baseVal.value - toSubtract.value;

    return formatNumberWithMetrixPrefix(rawValue, baseVal.prefix);
}
