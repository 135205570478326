/* @flow */

import type Moment from 'moment';

/*
Takes two moment dates and prints them in a human readable format with as
little redundant information as possible
 */
export function prettifyDateRange(startDate: Moment, endDate: Moment) {
    if (startDate.isSame(endDate, 'minute')) {
        return startDate.format('MMM Do, h:mma');
    }

    if (startDate.isSame(endDate, 'hour')) {
        return `${startDate.format('MMM Do, h:mma')} – ${endDate.format('h:mma')}`;
    }

    if (startDate.isSame(endDate, 'day')) {
        return startDate.format('MMM Do, YYYY');
    }

    if (startDate.isSame(endDate, 'month')) {
        return `${startDate.format('MMM Do')} – ${endDate.format('Do, YYYY')}`;
    }

    if (startDate.isSame(endDate, 'year')) {
        return `${startDate.format('MMM Do')} – ${endDate.format('MMM Do, YYYY')}`;
    }

    return `${startDate.format('MMM Do, YYYY')} – ${endDate.format('MMM Do, YYYY')}`;
}
