/* @flow */

import {Observable} from 'rxjs/Observable';
import type {ActionsObservable} from 'redux-observable';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/of';

import {checkCoupon} from './billing-api';
import {
    ActionTypes,
    resolveCheckCoupon,
    failCheckCoupon,
    type BillingAction,
    type CheckCouponRequestAction,
} from './billing-actions';

export const CheckCouponEpic = (action$: ActionsObservable<BillingAction>) =>
    action$
        .ofType(ActionTypes.BILLING_CHECK_COUPON_REQUEST)
        .debounceTime(200)
        .switchMap((action: CheckCouponRequestAction) => {
            const couponCode = action.payload;

            return Observable.fromPromise(checkCoupon(couponCode))
                .map((response) => resolveCheckCoupon(response.coupon))
                .catch(() => Observable.of(failCheckCoupon()));
        });
