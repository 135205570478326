/* @flow */

import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';

import classnames from 'classnames';

import {colors} from '../colors';
import {GoalLeadInfoIcon, WarningIcon, ErrorCircleIcon, ConfirmIcon, LockIcon} from '../icon';
import {HoverPopover} from '../hover-popover';

import './status-badge.css';

export type Level =
    | 'info'
    | 'info--purple'
    | 'info--navy'
    | 'info--grey'
    | 'warning'
    | 'alert'
    | 'alert--text'
    | 'success'
    | 'hidden';

export type StatusBadgeProps = {
    level: Level,
    text: string,
    detail?: string | React.Node,
    detailMaxWidth?: number,
    includeIcon?: boolean,
    isInteractive?: boolean,
    size?: 'small' | 'big',
};

type Props = {
    ...StatusBadgeProps,
};

export function StatusBadge(props: Props) {
    const {includeIcon, level, detail, text, size, isInteractive} = props;

    const statusBadgeRef = React.useRef(null);

    React.useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    const statusStyleName = classnames({
        'status-badge': true,
        'status-badge--small': size === 'small',
        'status-badge--interactive': isInteractive,
        'status-badge--warning': level === 'warning',
        'status-badge--alert': level === 'alert',
        'status-badge--alert-text': level === 'alert--text',
        'status-badge--info': level === 'info',
        'status-badge--success': level === 'success',
        'status-badge--hidden': level === 'hidden',
        'status-badge--info-purple': level === 'info--purple',
        'status-badge--info-navy': level === 'info--navy',
        'status-badge--info-grey': level === 'info--grey',
    });

    let icon = null;

    if (includeIcon) {
        const Icon = getIcon(level);
        icon = <Icon wrapWithDiv={false} size={15} fill={getFillColor(level)} />;
    }

    let reactTooltipProps = {};
    let detailHoverPopover;
    if (detail) {
        switch (typeof detail) {
            case 'string':
                reactTooltipProps = {
                    ...(props.detailMaxWidth
                        ? {
                              'data-html': true,
                              'data-tip': ReactDOMServer.renderToString(
                                  <div style={{maxWidth: props.detailMaxWidth}}>{detail}</div>
                              ),
                          }
                        : {'data-tip': detail}),
                    'data-effect': 'solid',
                    'data-delay-show': '2',
                    'data-event': 'mouseenter focus',
                    'data-event-off': 'mouseleave blur click',
                };
                break;
            case 'object':
                detailHoverPopover = (
                    <HoverPopover
                        anchorRef={statusBadgeRef}
                        location='top'
                        bodyColor='dark'
                        customStyles={{maxWidth: props.detailMaxWidth}}
                    >
                        {detail}
                    </HoverPopover>
                );
                break;
        }
    }

    return (
        <>
            <div
                styleName={statusStyleName}
                tabIndex={detail || isInteractive ? 0 : -1}
                ref={statusBadgeRef}
                {...reactTooltipProps}
            >
                {icon}
                <span>{text}</span>
            </div>
            {detailHoverPopover}
        </>
    );
}

export function getFillColor(level: Level) {
    switch (level) {
        case 'info':
            return 'hsla(195, 71%, 37%, 1)';
        case 'warning':
            return 'hsla(20, 81%, 56%, 1)';
        case 'alert':
        case 'alert--text':
            return 'hsla(360, 95%, 64%, 1)';
        case 'success':
            return 'hsla(145, 71%, 26%, 1)';
        case 'hidden':
            return colors.greyDk;
        default:
            return colors.grey;
    }
}

function getIcon(level: Level) {
    switch (level) {
        case 'info':
            return GoalLeadInfoIcon;
        case 'warning':
            return WarningIcon;
        case 'alert':
        case 'alert--text':
            return ErrorCircleIcon;
        case 'success':
            return ConfirmIcon;
        case 'hidden':
            return LockIcon;
        default:
            return GoalLeadInfoIcon;
    }
}
