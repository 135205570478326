import Backbone from 'backbone';
import _ from 'underscore';

class EmailTemplate extends Backbone.Model {
    constructor(attributes, options) {
        super(attributes, options);
        this.toJSON = this.toJSON.bind(this);
    }

    parse(data) {
        if (data) {
            return data.emailTemplates ? _.first(data.emailTemplates) : data;
        }

        return {};
    }

    toJSON() {
        return {data: this.attributes};
    }
}

export default EmailTemplate;
