/* @flow */

import type {FilterObject} from './types';
import * as Schema from './schema';
import {Routing} from './routing';
import {deserializeUrlParam} from './deserialize-url-param';

export function createNutshellFilterObjectFromUrl(url: string, schema: Schema.Properties) {
    const deparamedUrlObject = Routing.deparam(url);

    // I.e. sort, fields, filter
    const topLevelUrlKeys = Object.keys(deparamedUrlObject);
    if (!topLevelUrlKeys.includes('filter')) return {};

    return createNutshellFilterObject(deparamedUrlObject.filter, schema);
}

/**
 * Parses object of filters and returns an object of key'd
 * opinionated Nutshell filter objects
 * @param  {object} filters Key'd object of url query strings for each filter
 * @param  {object} schema  Schema of filter objects
 * @return {object}         Key'd object of opinionated Nutshell filter objects
 */
function createNutshellFilterObject(filters: FilterObject, schema: Schema.Properties) {
    const filterKeys = Object.keys(filters);
    const nutshellFilters = {};

    filterKeys.forEach((filterKey) => {
        // If our schema properties doesn't have any information for the
        // Filter key, return nothing
        if (!schema[filterKey]) return;

        // Grab string from url for the given filter key
        const filterString = filters[filterKey];

        // Get the field type for the key (i.e. `relationship`, `date-time`)
        const filterType = schema[filterKey].type;

        // Send our filterString with its type through our deserializer
        const deserializedFilterObject = deserializeUrlParam(filterString, filterType);

        // Tack on our nutshell filter object to our big object of filters
        nutshellFilters[filterKey] = deserializedFilterObject;
    });

    return nutshellFilters;
}
