import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 31.5 47'}>
            <path
                fill='#D4D4D4'
                d='M12.9 1.1c3 2 5.2 5 6.1 8.2v.2c.5 3.4-.3 7-2.3 10.2-3.1-2-5.1-4.9-5.9-8.3-.7-3.4 0-7 2.1-10.3m16 14.5c.5 0 1 0 1.5.1-.5 3-1.9 5.6-3.9 7.4-1.7 1.2-3.7 1.8-5.8 1.8-.3 0-.7 0-1-.1l-.5-.1V24.4c1.1-5.2 4.9-8.8 9.7-8.8M4.6 17.9c1.7 0 3.4.4 4.8 1.1h.2c2.4 1.5 4.2 4 5.1 7-1.1.3-2.2.5-3.3.5-4.7 0-8.6-3.2-10.2-8.2 1-.2 2.2-.4 3.4-.4m15.2 16.6H24.5s.1.1 0 .3l-2.1 10.5c-.1.4-.4.7-.7.7h-7.3c-.3 0-.6-.3-.7-.7l-2.1-10.5v-.3h8.2zM12.7 0c-.2 0-.4.1-.6.3-4.6 7.2-3 15.8 4.1 20.3.2.1.4.2.6.2.2 0 .4-.1.6-.3 2.1-3.3 3.2-7.3 2.6-11.2v-.2c-.9-3.7-3.5-6.9-6.6-8.9-.2-.1-.5-.2-.7-.2zm16.2 14.6c-5.2 0-9.5 3.9-10.6 9.8-1 2.6-1.6 7.1-1.9 9.1h-4.9c-.8 0-1.2.7-1.1 1.5l2.1 10.5c.2.8.9 1.5 1.7 1.5h7.3c.8 0 1.5-.7 1.7-1.5L25.4 35c.2-.8-.3-1.5-1.1-1.5h-4.6c-.4-1.3-1.1-5-.2-7.6.4 0 .8.1 1.1.1 2.3 0 4.5-.7 6.4-2 2.3-2 3.8-5.1 4.3-8.3.1-.4-.2-.7-.5-.8-.6-.2-1.2-.3-1.9-.3zM4.6 16.9c-1.4 0-2.7.2-4 .6-.3.1-.7.4-.5.8 1.6 5.7 6 9.2 11.2 9.2 1.2 0 2.5-.2 3.8-.6.3-.1.7-.4.5-.8-.9-3.2-2.8-6.2-5.6-8 0 0-.1 0-.1-.1-1.7-.7-3.5-1.1-5.3-1.1z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'plant-outlined-large';

export const PlantBigOutlinedIcon = svgIconWrapper(SvgComponent);
