/* @flow */

import {useQuery} from '@apollo/react-hooks';

import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import type {
    GetIndustries_industries as Industry,
    GetIndustries as GetIndustriesQuery,
} from 'nutshell-graphql-types';

import GET_INDUSTRIES from '../queries/get-industries.graphql';

export const useGetIndustries = (): {
    isLoading: boolean,
    errorMessage: ?string,
    industries: Industry[],
} => {
    const {loading, data, error} = useQuery<GetIndustriesQuery, {}>(GET_INDUSTRIES, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
    });

    return {
        isLoading: loading,
        errorMessage: safelyGetGraphQLErrorMessage(error),
        industries: data && data.industries ? data.industries : [],
    };
};
