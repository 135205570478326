/* @flow */

import * as React from 'react';
import {Button} from 'shells/button';
import {FlashBanner} from 'shells/flash-banner';
import './retryable-error.css';

type Props = {
    message: string,
    details: ?string,
    retry: () => Promise<*>,
};

export function RetryableError(props: Props) {
    const [showErrorDetails, setShowErrorDetails] = React.useState<boolean>(false);

    const detailsMarkup = showErrorDetails ? (
        <div>{props.details}</div>
    ) : (
        <Button variant='text-secondary' onClick={() => setShowErrorDetails(true)} size='big'>
            Show error details
        </Button>
    );

    return (
        <FlashBanner type='error' leftAlignContent={true} hasBorder={true}>
            <div>
                {props.message}
                <div styleName='error-actions'>
                    <div styleName='error-details' className='flex'>
                        <Button
                            variant='text-primary'
                            onClick={(e, closeMessage) => {
                                if (closeMessage) {
                                    closeMessage().then(() => {
                                        setShowErrorDetails(false);
                                        props.retry();
                                    });
                                } else {
                                    setShowErrorDetails(false);
                                    props.retry();
                                }
                            }}
                            size='big'
                        >
                            Try again
                        </Button>
                        {props.details ? detailsMarkup : null}
                    </div>
                </div>
            </div>
        </FlashBanner>
    );
}
