/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {FormField} from 'shells/form';
import {EntityPillSelectWrapperSmall} from 'shells/entity-pill';
import {TextField, type Props as TextFieldProps} from 'shells/textfield/textfield';

type Props = {
    ...$Exact<FieldProps>,
    ...$Exact<TextFieldProps>,
    title?: string,
    placeholder?: string,
    helperText?: string,
    multiline?: boolean,
    isRequired?: boolean,
    isReadOnly?: boolean,
    relatedEntity?: 'contacts' | 'accounts',
    focusOnMount?: boolean,
    noMargin?: boolean,
};

export function LeadNameField(props: Props) {
    const {
        input,
        meta,
        title,
        helperText,
        isRequired,
        isReadOnly,
        relatedEntity,
        noMargin,
        ...restProps
    } = props;

    const getRelatedEntityName = () => {
        switch (relatedEntity) {
            case 'contacts':
                return 'Person';
            case 'accounts':
                return 'Company';
            default:
                return '';
        }
    };

    const Component =
        isReadOnly && relatedEntity ? (
            <EntityPillSelectWrapperSmall
                type={relatedEntity}
                value={{id: 'none', name: `${getRelatedEntityName()} name`}}
            />
        ) : (
            <TextField {...input} {...restProps} />
        );

    return (
        <FormField
            innerId={input.name}
            label={title}
            helperText={helperText}
            errorMessage={meta.error && meta.submitFailed ? meta.error : null}
            isRequired={isRequired}
            noMargin={noMargin}
        >
            {Component}
        </FormField>
    );
}
