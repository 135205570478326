/* @flow */

type MapParams = {
    bounds: {
        _southWest: {
            lat: string,
            lng: string,
        },
        _northEast: {
            lat: string,
            lng: string,
        },
    },
    latitude: string,
    longitude: string,
    radius: string,
};

export function serializeMap({bounds, latitude, longitude, radius}: MapParams) {
    return {
        southWest: `${bounds._southWest.lat},${bounds._southWest.lng}`,
        northEast: `${bounds._northEast.lat},${bounds._northEast.lng}`,
        latitude: latitude,
        longitude: longitude,
        radius: radius,
    };
}
