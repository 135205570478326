/* @flow */

import * as React from 'react';
import './marketing-title.css';

type Props = {
    children?: React.Node,
    as: string,
};

MarketingTitle.defaultProps = {
    as: 'div',
};

/**
 * This component represents the copy of a marketing title
 * used for in-app copy.
 *
 * There is a default 16px margin-bottom built into the component
 * so that other marketing-based tyopgraphy components can
 * lay themselves out nicely.
 *
 * If you'd like to use a similar style typography without the
 * margin, prefer the `Title` component.
 */
export function MarketingTitle({children, as: Tag}: Props) {
    return <Tag styleName='marketing-title'>{children}</Tag>;
}
