/* @flow */

import * as React from 'react';

export function AnalyticsData() {
    return (
        <svg width='180' height='253' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='36' height='218' rx='4' fill='#0C0F33' />
            <rect x='48' y='19' width='36' height='199' rx='4' fill='#3B9ED7' />
            <rect x='96' y='100' width='36' height='118' rx='4' fill='#3D3B8A' />
            <rect x='144' y='146' width='36' height='72' rx='4' fill='#FAC842' />
            <path
                d='M106.7 237.7c-.938 0-1.7.763-1.7 1.7v5.1c0 .938.762 1.7 1.7 1.7h.85v1.275c0 .162.09.308.234.38a.428.428 0 0 0 .446-.04l2.154-1.615h3.966c.938 0 1.7-.762 1.7-1.7v-5.1c0-.937-.762-1.7-1.7-1.7h-7.65Zm7.65 9.35h-2.55v.85c0 .938.762 1.7 1.7 1.7h3.116l2.154 1.615c.128.096.3.112.446.04a.42.42 0 0 0 .234-.38V249.6h.85c.938 0 1.7-.762 1.7-1.7v-5.1c0-.937-.762-1.7-1.7-1.7h-3.4v3.4a2.55 2.55 0 0 1-2.55 2.55Z'
                fill='#0C0F33'
            />
            <g clipPath='url(#a)'>
                <path
                    d='M167.812 242.905a6.885 6.885 0 0 1-1.328 4.074l4.203 4.206a1.064 1.064 0 0 1-1.504 1.504l-4.203-4.206a6.867 6.867 0 0 1-4.073 1.327 6.903 6.903 0 0 1-6.905-6.905 6.904 6.904 0 0 1 6.905-6.905 6.904 6.904 0 0 1 6.905 6.905Zm-10.358.266v2.125c0 .441.356.796.797.796a.794.794 0 0 0 .797-.796v-2.125a.795.795 0 0 0-.797-.797.795.795 0 0 0-.797.797Zm2.656-3.187v5.312c0 .441.355.796.797.796a.795.795 0 0 0 .797-.796v-5.312a.795.795 0 0 0-.797-.797.795.795 0 0 0-.797.797Zm2.656 2.124v3.188c0 .441.355.796.797.796a.795.795 0 0 0 .797-.796v-3.188a.795.795 0 0 0-.797-.796.794.794 0 0 0-.797.796Z'
                    fill='#0C0F33'
                />
            </g>
            <g clipPath='url(#b)'>
                <path
                    d='M71.812 242.905a6.89 6.89 0 0 1-1.328 4.074l4.203 4.206a1.064 1.064 0 0 1-1.504 1.504l-4.203-4.206a6.87 6.87 0 0 1-4.073 1.327 6.904 6.904 0 0 1-6.905-6.905A6.904 6.904 0 0 1 64.907 236a6.904 6.904 0 0 1 6.905 6.905Zm-6.241-3.452a.665.665 0 0 0-.664-.664.665.665 0 0 0-.664.664v.464a2.554 2.554 0 0 0-.737.283c-.462.275-.86.757-.856 1.457.003.674.398 1.099.82 1.351.365.219.82.359 1.181.465l.057.017c.418.126.724.225.93.355.169.106.192.179.195.272.004.166-.06.266-.196.349-.166.102-.428.166-.71.156-.369-.014-.714-.13-1.165-.283a10.041 10.041 0 0 0-.24-.079.665.665 0 0 0-.84.418.666.666 0 0 0 .419.84c.063.02.133.043.203.07.275.096.594.205.936.279v.491c0 .365.298.664.664.664a.666.666 0 0 0 .664-.664v-.458c.265-.057.53-.15.77-.299.475-.296.833-.8.823-1.494-.01-.674-.388-1.109-.817-1.381-.381-.239-.86-.385-1.231-.498l-.023-.007c-.425-.129-.728-.222-.94-.348-.173-.103-.176-.163-.176-.223 0-.122.047-.215.206-.308.18-.107.451-.17.714-.166.318.003.67.073 1.035.172a.666.666 0 0 0 .342-1.285 9.423 9.423 0 0 0-.7-.156v-.454Z'
                    fill='#0C0F33'
                />
            </g>
            <path
                d='M16.31 239.812h-3.498v5.198l-2.806-2.077c.047-.531.32-1.023.753-1.342l.647-.481v-1.298c0-.776.63-1.406 1.406-1.406h2.245l1.462-1.081c.284-.211.627-.325.981-.325.355 0 .697.114.981.322l1.462 1.084h2.244c.777 0 1.407.63 1.407 1.406v1.298l.647.481c.434.319.706.811.753 1.342l-2.806 2.077v-5.198H16.31ZM10 250.125v-6.032l6.375 4.722c.325.241.72.373 1.125.373.404 0 .8-.129 1.125-.373L25 244.093v6.032c0 1.034-.84 1.875-1.875 1.875h-11.25A1.877 1.877 0 0 1 10 250.125Zm5.156-8.437h4.688a.47.47 0 0 1 0 .937h-4.688a.47.47 0 0 1 0-.937Zm0 1.874h4.688a.47.47 0 0 1 .468.469.47.47 0 0 1-.468.469h-4.688a.47.47 0 0 1-.469-.469.47.47 0 0 1 .47-.469Z'
                fill='#0C0F33'
            />
            <defs>
                <clipPath id='a'>
                    <path fill='#fff' transform='translate(154 236)' d='M0 0h17v17H0z' />
                </clipPath>
                <clipPath id='b'>
                    <path fill='#fff' transform='translate(58 236)' d='M0 0h17v17H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
