/* @flow */

import * as React from 'react';

import {Button} from 'shells/button';
import {PopoverInfo} from 'shells/popover-info';

import './modal-header-breadcrumbs.css';

export type ModalHeaderBreadcrumb = {
    text: string,
    helpInfo?: React.Node,
    onClick?: () => void,
};

type Props = {
    breadcrumbs: ModalHeaderBreadcrumb[],
};

export function ModalHeaderBreadcrumbs(props: Props) {
    const isBreadcrumb = (index: number) => {
        return index < props.breadcrumbs.length - 1;
    };

    const renderBreadcrumb = (breadcrumb: ModalHeaderBreadcrumb) => (
        <div className='flex align-center'>
            <Button
                variant='text-secondary'
                size='big'
                onClick={breadcrumb.onClick}
                noPadding={true}
            >
                <p styleName='title--breadcrumb'>{breadcrumb.text}</p>
            </Button>
            {breadcrumb.helpInfo ? <PopoverInfo size={17} info={breadcrumb.helpInfo} /> : undefined}
            <span styleName='arrow'>&gt;</span>
        </div>
    );

    const renderTitle = (breadcrumb: ModalHeaderBreadcrumb) => (
        <div styleName='title'>{breadcrumb.text}</div>
    );

    return (
        <div className='flex align-center'>
            {props.breadcrumbs.map((breadcrumb, index) => (
                <div key={breadcrumb.text}>
                    {isBreadcrumb(index) ? renderBreadcrumb(breadcrumb) : renderTitle(breadcrumb)}
                </div>
            ))}
        </div>
    );
}
