/* @flow */

import * as React from 'react';

import {FlashBanner} from '../flash-banner';
import type {MessageType} from '../message';

import './status-header.css';

type Props = {
    type: MessageType,
    icon?: ?React.Element<*>,
    content: React.Element<*>,
};

export function StatusHeader(props: Props) {
    return (
        <FlashBanner type={props.type} animate={false} noRightPadding={true}>
            {props.icon ? props.icon : undefined}
            <span styleName='header-text'>{props.content}</span>
        </FlashBanner>
    );
}
