import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import GoalLeadInfo from './icon-goal-lead-info.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <GoalLeadInfo {...validProps} />;
}
SvgComponent.iconName = 'goal-lead-info';

export const GoalLeadInfoIcon = svgIconWrapper(SvgComponent);
