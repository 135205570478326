/* @flow */

import * as React from 'react';

import {Card} from '../card';
import {Switch} from '../switch';
import {Body} from '../typography';

import './toggleable-section.css';

type Props = {
    isOn: boolean,
    onToggle: () => void,
    title: string,
    subtitle: string,
    content: React.Node,
};

export function ToggleableSection(props: Props) {
    return (
        <Card hasBorder={true}>
            <div styleName='header'>
                <div className='mr-16'>
                    <div className='mb-4'>
                        <Body color='dark' isBold={true}>
                            {props.title}
                        </Body>
                    </div>
                    <Body color='dark' size='small' isItalic={true}>
                        {props.subtitle}
                    </Body>
                </div>
                <Switch isOn={props.isOn} onChange={props.onToggle} />
            </div>
            <div className='pad-16'>{props.content}</div>
        </Card>
    );
}
