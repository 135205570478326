/* @flow */

import * as React from 'react';

import type {EventFragmentSparse} from 'nutshell-graphql-types';

import {LinkedEntity} from 'shells/linked-entity';

import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {|event: EventFragmentSparse, newStage: string|};

export function TimelineBannerStageChange(props: Props) {
    const {event, newStage} = props;

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'Lead' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    const action =
        event.payload.__typename === 'Lead' ? (
            <span>
                moved{' '}
                <strong>
                    <LinkedEntity
                        type='leads'
                        useEntityColor={true}
                        shouldClientRoute={true}
                        name={event.payload.name}
                        htmlUrl={event.payload.htmlUrl}
                        allowWrap={true}
                        displayInline={true}
                        greenLeads={true}
                    />
                </strong>{' '}
                to the <strong>{newStage}</strong> stage
            </span>
        ) : (
            undefined
        );

    if (action) {
        return (
            <TimelineBanner
                id={event.id}
                iconVariant='lead-stage-advanced'
                timestamp={event.changeTime}
                actor={event.actor}
                action={action}
            />
        );
    }

    return null;
}
