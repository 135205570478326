/* @flow */

import type {ColorSwatch} from '../color-swatch-picker';
import type {ColorName} from './colors';

/**
 * Mapping of tag color enum to ColorNames for the tag in order of how they will appear in
 * the color picker:
 * 0  1  2  3  4  5  6 (lighter shades above darker shades)
 * 7  8  9  10 11 12 13 (darker shades below lighter shades
 *
 * @type {{[COLOR_ENUM]: ColorName}}
 */
export const TAG_COLOR_NAMES_MAP: {
    [key: string]: {|
        colorName: ColorName,
    |},
} = {
    // 0  1  2  3  4  5  6 (lighter shades above darker shades)
    COLOR_DEFAULT: {
        colorName: 'tagGreyLt',
    },
    COLOR_RED_LT: {
        colorName: 'tagRedLt',
    },
    COLOR_ORANGE_LT: {
        colorName: 'tagOrangeLt',
    },
    COLOR_YELLOW_LT: {
        colorName: 'tagYellowLt',
    },
    COLOR_GREEN_LT: {
        colorName: 'tagGreenLt',
    },
    COLOR_BLUE_LT: {
        colorName: 'tagBlueLt',
    },
    COLOR_PURPLE_LT: {
        colorName: 'tagPurpleLt',
    },
    // 7  8  9  10 11 12 13 (darker shades below lighter shades
    COLOR_GREY: {
        colorName: 'tagGrey',
    },
    COLOR_RED: {
        colorName: 'tagRed',
    },
    COLOR_ORANGE: {
        colorName: 'tagOrange',
    },
    COLOR_YELLOW: {
        colorName: 'tagYellow',
    },
    COLOR_GREEN: {
        colorName: 'tagGreen',
    },
    COLOR_BLUE: {
        colorName: 'tagBlue',
    },
    COLOR_PURPLE: {
        colorName: 'tagPurple',
    },
};

export const getColorNameFromTagColor = (tagColor: ?string): ColorName => {
    return (
        (tagColor && TAG_COLOR_NAMES_MAP[tagColor].colorName) ||
        TAG_COLOR_NAMES_MAP.COLOR_DEFAULT.colorName
    );
};

export const getTagColorSwatches = (): ColorSwatch[] => {
    return Object.keys(TAG_COLOR_NAMES_MAP).map((tagColor: string) => ({
        id: tagColor,
        colorName: TAG_COLOR_NAMES_MAP[tagColor].colorName,
    }));
};
