/* @flow */

import * as React from 'react';

import type {EventFragmentSparse} from 'nutshell-graphql-types';

import {appHistory} from '../../../history';
import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {|event: EventFragmentSparse|};

export function TimelineBannerOutreachSent(props: Props) {
    const {event} = props;

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'Email' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (event.payload && event.payload.__typename === 'Email') {
        // The title is the name of the edition but if we don't have that for some reason,
        // we default to the subject of the email
        const emailTitle = event.payload.edition
            ? event.payload.edition.name
            : event.payload.subject;

        /**
         * In this case, we want to display the first recipient in the 'to' array
         * as the actor so the banner displays properly
         */
        const recipient =
            event.payload.recipients &&
            event.payload.recipients.length &&
            event.payload.recipients[0].avatarEntity &&
            event.payload.recipients[0].avatarEntity.__typename === 'Contact'
                ? event.payload.recipients[0].avatarEntity
                : undefined;

        const htmlUrlPath = event.payload.htmlUrlPath;

        const action = (
            <span>
                was sent&nbsp;
                {emailTitle ? (
                    <span
                        styleName='target--button'
                        role='button'
                        tabIndex='0'
                        onClick={() => appHistory.push(`#${htmlUrlPath}`)}
                    >
                        {emailTitle}
                    </span>
                ) : (
                    <span>a Nutshell Campaigns email</span>
                )}
            </span>
        );

        return (
            <TimelineBanner
                id={event.id}
                iconVariant='outreach-sent'
                timestamp={event.changeTime}
                // $FlowIgnore it is proving impossible to get these types to line up perfectly
                actor={recipient}
                action={action}
            />
        );
    }

    return null;
}
