/* @flow */

import * as React from 'react';
import {formEncodedApi} from 'nutshell-core/api';
import {OnboardingBody} from 'shells/onboarding-page/onboarding-body';
import './placeholder-popover.css';
import {TextField} from '../textfield';
import {Button} from '../button';
import {CheckIcon} from '../icon';

type Props = {
    onSave: (string) => any,
    onDismiss: () => any,
    position: {
        top: number,
        left: number,
    },
    shouldOnlyShowOnboarding: boolean,
};
type State = {
    currentValue: string,
    isSaving: boolean,
    shouldShowOnboarding: boolean,
};

export class PlaceholderPopover extends React.PureComponent<Props, State> {
    textfieldRef: ?HTMLInputElement;
    onboardingButtonRef: ?HTMLElement;

    constructor(props: Props) {
        super(props);
        this.state = {
            isSaving: false,
            currentValue: '',
            shouldShowOnboarding:
                NutClientConfig.shouldShowCustomPlaceholderOnboarding ||
                props.shouldOnlyShowOnboarding,
        };
    }

    componentDidMount() {
        // Defer one tick to avoid placeholder blot getting focus
        setTimeout(() => {
            if (this.textfieldRef) {
                this.textfieldRef.focus();
            }

            if (this.onboardingButtonRef) {
                this.onboardingButtonRef.focus();
            }
        });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // If transitioning from onboarding to regular input mode,
        // focus new textField that just mounted
        if (
            prevState.shouldShowOnboarding &&
            !this.state.shouldShowOnboarding &&
            this.textfieldRef
        ) {
            this.textfieldRef.focus();
        }
    }

    render() {
        if (this.state.shouldShowOnboarding) {
            return (
                <div styleName='onboarding-container'>
                    <div onClick={this.dismissOnboarding} styleName='blur-overlay' />
                    <div styleName='onboarding-body'>
                        <OnboardingBody title='What are placeholders?'>
                            <p>
                                Placeholders (these orange things) allow you to add content to your
                                email templates that you’ll be required to fill in later. It’s a
                                great way to keep your messages personalized, but still on topic!
                                <br />
                                <br />
                                And don’t worry, we won’t let you send an email before you replace
                                your placeholders.
                            </p>
                            <Button
                                getButtonRef={(ref) => {
                                    this.onboardingButtonRef = ref;
                                }}
                                variant='text-primary'
                                onClick={this.dismissOnboarding}
                                style={{padding: '4px 0'}} // left-align button with text above
                            >
                                Done
                            </Button>
                        </OnboardingBody>
                    </div>
                </div>
            );
        }

        return (
            <div styleName='container'>
                <div onClick={this.handleCancel} styleName='blur-overlay' />
                <div styleName='textfield-container'>
                    <TextField
                        placeholder='Placeholder text'
                        onChange={(newValue) => this.setState({currentValue: newValue})}
                        value={this.state.currentValue}
                        textFieldRef={(ref) => {
                            this.textfieldRef = ref;
                        }}
                        onEnter={(e: SyntheticKeyboardEvent<*>) => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.handleSave();
                        }}
                        onEscape={this.handleCancel}
                    />
                </div>
                <Button variant='primary' size='small' onClick={this.handleSave}>
                    <CheckIcon size={18} />
                </Button>
            </div>
        );
    }

    handleSave = () => {
        this.setState({isSaving: true});
        this.props.onSave(this.state.currentValue);
    };

    handleCancel = () => {
        // We need this to bypass calling cancel when the
        // popover is closed
        if (!this.state.isSaving) {
            this.props.onDismiss();
        }
    };

    dismissOnboarding = () => {
        persistHasViewedCustomPlaceholderOnboarding();
        this.setState({shouldShowOnboarding: false});
        // If we should only show the onboarding, we should dismiss this popover
        // This is when the user is in the template editor, and clicks a custom
        // placeholder, so we show the onboarding, but not the name field
        if (this.props.shouldOnlyShowOnboarding) {
            this.props.onDismiss();
        }
    };
}

function persistHasViewedCustomPlaceholderOnboarding() {
    formEncodedApi
        .post('/my-account/save-user-setting.json', {
            setting: 'has_viewed_custom_placeholder_onboarding_NUT-10739',
            value: 1,
        })
        .then(() => {
            NutClientConfig.shouldShowCustomPlaceholderOnboarding = false;
        });
}
