/* @flow */

import React, {useEffect} from 'react';
import TextareaAutosize from 'react-autosize-textarea';

import {ErrorMessage} from '../form/error-message';

import './editable-label-wrapping.css';

type Props = {
    value: string,
    onSaveNewValue: (string) => Promise<*>,
    placeholder: string,
    customStyle?: Object,
    required?: boolean,
    shouldFocusOnMount: boolean,
    onFocusedOnMount?: () => void,
    errorMessage?: ?string,
};

export function EditableLabelWrapping(props: Props) {
    const {value: propsValue} = props;

    const {shouldFocusOnMount, onFocusedOnMount} = props;
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<string>(propsValue);

    const inputRef = React.useRef();

    // This seems unnecessary, but if the props.value changes, we need to manually
    // set the value in state to get that change or the TextareaAutosize won't get the
    // new value from props.
    React.useEffect(() => {
        setValue(propsValue);
    }, [propsValue]);

    useEffect(() => {
        if ((isEditing || shouldFocusOnMount) && inputRef && inputRef.current) {
            inputRef.current.select();

            // If we focused on mount, set editing to true, but tell
            // parent component that we don't want to re-focus after blurring
            if (shouldFocusOnMount) {
                setIsEditing(true);
                // callback to parent to set that state to false
                onFocusedOnMount && onFocusedOnMount();
            }
        }
    }, [inputRef, isEditing, shouldFocusOnMount, onFocusedOnMount]);

    return (
        <div styleName='container'>
            <div className='flex flex-dir-col full-width'>
                {isEditing || shouldFocusOnMount ? (
                    <TextareaAutosize
                        styleName='input'
                        style={{...props.customStyle, resize: 'none'}}
                        value={value}
                        autoFocus={true}
                        placeholder={props.placeholder}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                        onBlur={() => {
                            if (value) {
                                props.onSaveNewValue(value);
                                setIsEditing(false);
                            }
                        }}
                        ref={(node) => {
                            inputRef.current = node;
                        }}
                    />
                ) : (
                    <button
                        onClick={() => setIsEditing(true)}
                        styleName={props.required ? 'button--required' : 'button'}
                        style={props.customStyle}
                    >
                        {value}
                    </button>
                )}
                {props.errorMessage ? <ErrorMessage>{props.errorMessage}</ErrorMessage> : undefined}
            </div>
        </div>
    );
}
