/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import './footer-bar.css';

type Props = {
    left?: React.Node,
    center?: React.Node,
    right?: React.Node,
    hasBorder?: boolean,
    absolutePosition?: boolean,
    noPadding?: boolean,
    noMargin?: boolean,
    fitHeight?: boolean,
};

FooterBar.defaultProps = {
    left: <div />,
    center: null,
    right: <div />,
};

export function FooterBar(props: Props) {
    const styleName = classnames({
        'footer-bar': !props.hasBorder,
        'footer-bar--bordered': props.hasBorder,
        'absolute-position': props.absolutePosition,
        'no-padding': props.noPadding,
        'fit-height': props.fitHeight,
        'no-margin': props.noMargin,
    });

    return (
        <div styleName={styleName}>
            {props.left}
            {props.center}
            {props.right}
        </div>
    );
}
