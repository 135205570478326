/* @flow */

import * as React from 'react';
import {ButtonCard} from '../card/button-card';

import './two-line-button-card.css';

type Props = {
    children: React.Node,
    onClick?: () => void,
    isSelected?: boolean,
    isDisabled?: boolean,
};

TwoLineButtonCard.defaultProps = {
    as: 'button',
};

export function TwoLineButtonCard(props: Props) {
    const buttonCardStyle = {
        width: '100%',
        height: 'auto',
    };

    return (
        <ButtonCard
            style={buttonCardStyle}
            onClick={props.onClick}
            isSelected={Boolean(props.isSelected)}
            isDisabled={Boolean(props.isDisabled)}
        >
            <div styleName='two-line-button-card'>{props.children}</div>
        </ButtonCard>
    );
}
