/* @flow */

// Just a simple helper function to encapsulate how we determine
// from the API's `priority` attribute whether a given lead
// is hot 🔥 or not ❄️
export function leadPriorityIsHot(priority?: string | number): boolean {
    if (!priority) return false;

    return Boolean(
        (typeof priority === 'string' && priority.toLowerCase() === 'hot') ||
            (typeof priority === 'number' && priority === 1)
    );
}
