/* @flow */

import * as React from 'react';

import {MarketingTitle, MarketingBody} from '../typography';

import './empty-state-popover.css';

type Props = {
    title: string,
    subtitle: string,
    cta?: React.Node,
};

export function EmptyStatePopover(props: Props) {
    return (
        <div styleName='container'>
            <div styleName='empty-text'>
                <MarketingTitle>{props.title}</MarketingTitle>
                <MarketingBody>{props.subtitle}</MarketingBody>
            </div>
            {props.cta}
        </div>
    );
}
