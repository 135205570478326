/* @flow */

import type {Dispatch} from 'redux';
import type {LeadStage} from '../types';

import {api} from '../api';
import type {StagesAction, StagesUpdatedAction} from './stages-types';

// Type Constants
export const STAGES_REQUESTED = 'STAGES_REQUESTED';
export const STAGES_UPDATED = 'STAGES_UPDATED';
export const STAGES_FAILED = 'STAGES_FAILED';

// Actions
export const requestStages = (): StagesAction => ({type: STAGES_REQUESTED});
export const updateStages = (stages: Array<LeadStage>): StagesUpdatedAction => {
    return {type: STAGES_UPDATED, payload: stages};
};
export const failStages = (errorMsg?: string): StagesAction => ({
    type: STAGES_FAILED,
    payload: errorMsg,
    error: true,
});

/**
 * Gets all stage models for instance, regardless of stageset. If this
 * request becomes too slow, we can break it apart by stagesetId, but for
 * now it's perfectly fast enough
 *
 * @return {Function} Thunk to request stages and dispatch corresponding
 *                    actions during the process
 */
export function fetchStages() {
    return function(dispatch: Dispatch<*>) {
        dispatch(requestStages());

        api.get('stages')
            .then((response) => response.json())
            .then((res) => dispatch(updateStages(res.stages)))
            .catch((err) => dispatch(failStages(err.message)));
    };
}
