import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import WebForm from './icon-web-form.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <WebForm {...validProps} color={validProps.color ? validProps.color : '#000'} />;
}
SvgComponent.iconName = 'webForm';

export const WebFormIcon = svgIconWrapper(SvgComponent);
