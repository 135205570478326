/* @flow */

import React from 'react';

import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SolidMessageCircle from './solid-message-circle.svg';

type Props = {
    color?: string,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SolidMessageCircle {...validProps} color={props.color || colors.orange} />;
}
SvgComponent.iconName = 'message-circle-icon';

export const SolidMessageCircleIcon = svgIconWrapper(SvgComponent);
