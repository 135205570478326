import React from 'react';

import {svgIconWrapper} from '../svg-icon-wrapper';
import DoorOpen from './icon-door-open.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <DoorOpen {...validProps} />;
}
SvgComponent.iconName = 'door-open';

export const DoorOpenIcon = svgIconWrapper(SvgComponent);
