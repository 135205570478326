/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import type {Assignable} from 'nutshell-core/types';

import {AssigneeSelect as AssigneeSelectShell} from 'shells/assignee-select';
import {type SelectPickerGenericProps} from 'shells/select-picker-generic';

import {useGetSessionUser} from '../../use-get-session-user';

type OwnProps = {|
    ...SelectPickerGenericProps,
    options: Assignable[],
    isDisabled?: boolean,
|};

type Props = {...$Exact<FieldProps>, ...OwnProps};

function getSelectedValue(options, input, user) {
    const value = input.value;
    let inputId;
    // input.value is sometimes a string, sometimes an object
    // if it is a string, we want to use it directly
    // if it is an object, we want to use .id as the value
    if (value && typeof value === 'string') {
        inputId = value;
    } else if (value && typeof value === 'object' && value.id) {
        inputId = value.id;
    }
    let selectedValue = options.find((field) => field.id === inputId);

    // if we don't have a selected value, use the current user
    if (!selectedValue) {
        selectedValue = options.find((opt) => user && opt.id === user.id);
    }

    input.onChange(selectedValue);

    return selectedValue;
}

export function AssigneeSelect(props: Props) {
    const {user} = useGetSessionUser();
    const {input, ...restProps} = props;

    const selectedValue = getSelectedValue(props.options, input, user);
    const isDisabled = props.isDisabled || (user && !user.permissions.canAssignEntities);

    return (
        // $FlowIgnore exact prop types are tough
        <AssigneeSelectShell
            {...input}
            {...restProps}
            onBlur={() => {
                // Who can explain it, who can tell why. Somehow, this
                // works. If the params are passed through, the value for
                // this field is reset on blur :shrug:
                input.onBlur();
            }}
            value={selectedValue && selectedValue.id}
            onChange={(option) => {
                input.onChange(option);
            }}
            isClearable={false}
            isDisabled={isDisabled}
        />
    );
}
