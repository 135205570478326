/* @flow */

import * as React from 'react';
import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';

type Props = {
    fill?: string,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} fill={props.fill || colors.grey} viewBox='0 0 22 22'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.79 3A2.79 2.79 0 0 0 3 5.79v10.42A2.79 2.79 0 0 0 5.79 19h10.42A2.79 2.79 0 0 0 19 16.21V5.79A2.79 2.79 0 0 0 16.21 3H5.79Zm-.166 8.368 3.6 3.6a.554.554 0 0 0 .783 0l6.37-6.37a.554.554 0 0 0 0-.783l-.784-.783a.554.554 0 0 0-.783 0l-5.195 5.194L7.19 9.801a.554.554 0 0 0-.783 0l-.783.784a.554.554 0 0 0 0 .783Z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'checkbox';

export const CheckboxIcon = svgIconWrapper(SvgComponent);
