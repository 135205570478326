/* @flow */

import capitalize from 'underscore.string/capitalize';
import type {CoreEntityType} from 'nutshell-core/types';
import {api} from 'nutshell-core/api';

// This tells redux-select how to populate a list of select options asyncronously.
export function fetchOptions(collections: (CoreEntityType | 'users')[]) {
    return (query: string) => {
        return api
            .get('searchresults', {
                filter: {entityType: collections.map(capitalize)},
                q: query,
            })
            .then((response) => response.json())
            .then((json: {searchResults: ?(Object[])}) => {
                if (!json.searchResults) return;

                // $FlowFixMe upgrading Flow to v0.92.1 on web
                return json.searchResults.map((item: Object) => {
                    // Only return the keys that we need for now.
                    return {
                        avatarUrl: item.avatarUrl,
                        name: item.displayName,
                        id: item.links.entity.id,
                        initials: item.initials,
                        type: item.links.entity.type,
                    };
                });
            });
    };
}
