/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import './grey-section.css';

type Props = {
    content: React.Node,
    isFullHeight?: boolean,
    centerContent?: boolean,
    noMargin?: boolean,
};

export function GreySection(props: Props) {
    const {isFullHeight, centerContent, noMargin} = props;

    const styleName = classnames('section-container', {
        'section-container--full-height': isFullHeight,
        'section-container--center-content': centerContent,
        'section-container--no-margin': noMargin,
    });

    return <div styleName={styleName}>{props.content}</div>;
}
