/* @flow */

import * as React from 'react';

import {Modal, FooterBar} from '../../modal';
import {GridList} from '../../grid-list';
import {InfoBanner} from '../../info-banner';
import {Button, SaveButton} from '../../button';
import {ButtonMover} from '../../button-mover';
import {ButtonCard} from '../../card';
import {ModalBody, ModalHeader} from '../../typography';

import './merge-items-modal.css';

type Item<I> = {
    id: string,
    ...I,
};

type Props<I> = {|
    title: string,
    subtitle: string,
    items: Item<I>[],
    renderItem: (item: Item<I>) => React.Node,
    infoText: string,
    mergeButtonText: ((selectedItem: ?Item<I>) => string) | string,
    mergingText?: string,
    onMergeItems: (selectedItem: Item<I>) => any,
    isMerging: boolean,
    onClose: () => any,
    maxHeight?: number,
|};

export function MergeItemsModal<I>(props: Props<I>) {
    const [selectedItem, setSelectedItem] = React.useState<?Item<I>>(null);

    return (
        <Modal
            isOpen={true}
            onClose={props.onClose}
            closeButton={false}
            maxHeight={props.maxHeight}
        >
            <div styleName='merge-items-modal-content'>
                <div styleName='heading'>
                    <ModalHeader>{props.title}</ModalHeader>
                    <ModalBody>{props.subtitle}</ModalBody>
                </div>
                <div styleName='items-container'>
                    <GridList numColumns={2}>
                        {props.items.map((item) => (
                            <ButtonCard
                                key={item.id}
                                onClick={() =>
                                    props.isMerging ? undefined : setSelectedItem(item)
                                }
                                isSelected={Boolean(selectedItem && selectedItem.id === item.id)}
                                isFullWidth={true}
                                fitHeight={true}
                                showUnselectedOverlay={Boolean(selectedItem)}
                            >
                                {props.renderItem(item)}
                            </ButtonCard>
                        ))}
                    </GridList>
                </div>
                <InfoBanner>{props.infoText}</InfoBanner>
                <FooterBar
                    left={
                        <ButtonMover size='big' direction='left'>
                            <Button variant='text' size='big' onClick={props.onClose}>
                                Cancel
                            </Button>
                        </ButtonMover>
                    }
                    right={
                        <SaveButton
                            onClick={() =>
                                selectedItem ? props.onMergeItems(selectedItem) : undefined
                            }
                            isSaving={props.isMerging}
                            buttonText={
                                typeof props.mergeButtonText === 'string'
                                    ? props.mergeButtonText
                                    : props.mergeButtonText(selectedItem)
                            }
                            savingText={props.mergingText}
                            disabled={!selectedItem || props.isMerging}
                        />
                    }
                    noPadding={true}
                    fitHeight={true}
                />
            </div>
        </Modal>
    );
}
