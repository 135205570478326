/* @flow */

import * as ramda from 'ramda';

/**
 * Takes an object with a key of a core entity type (leads|contacts|accounts)
 * and returns another object with the same key, containing a single encoded
 * string of field names, separated by commas.
 * @param  {Object} fields   Object containing array of field names
 * @return {Object}          Object with encoded field names under a single
 *                                  core entity type key
 */
export function serializeFields(fields: {[coreEntityType: string]: Array<string>}) {
    // Babel 7 needed in email extensions to use
    // return ramda.map<string[], string>((keyFields) => serializeFieldNames(keyFields), fields);
    // $FlowFixMe
    return ramda.map((keyFields) => serializeFieldNames(keyFields), fields);
}

/**
 * Converts an array of field names into a encoded url string, separated by
 * commas
 *
 * @param {string[]} fields - An array of field names to be encoded and joined
 * @return {string} -         Encoded url string of fields
 */
export function serializeFieldNames(fields: Array<string>): string {
    return fields.map((x) => encodeURIComponent(x)).join(',');
}
