/* @flow */

import {useQuery} from '@apollo/react-hooks';

import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import type {
    GetTagsBasic_tags_edges_node as Tag,
    GetTagsBasic as GetTagsBasicQuery,
    TagType,
} from 'nutshell-graphql-types';

import GET_TAGS from '../queries/tags.graphql';

export const GET_TAGS_LIMIT = 1000;

export const useGetTags = ({
    searchTerm,
    tagType,
}: {
    searchTerm?: string,
    tagType: TagType,
}): ({
    isLoading: boolean,
    errorMessage: ?string,
    tags: Tag[],
    totalCount: number,
    hasNextPage: boolean,
    refetch: () => Promise<*>,
    fetchMore: (searchTerm: string, tagType: TagType) => Promise<*>,
}) => {
    const {loading, data, refetch, fetchMore, error} = useQuery<GetTagsBasicQuery, {}>(GET_TAGS, {
        notifyOnNetworkStatusChange: true,
        variables: {
            first: GET_TAGS_LIMIT,
            input: {
                searchTerm,
                entityType: tagType,
            },
        },
        fetchPolicy: 'cache-and-network',
    });

    const hasNextPage = data && data.tags && data.tags.pageInfo && data.tags.pageInfo.hasNextPage;
    const endCursor = data && data.tags && data.tags.pageInfo && data.tags.pageInfo.endCursor;
    const totalCount = data && data.tags ? data.tags.totalCount : 0;

    return {
        isLoading: loading,
        errorMessage: safelyGetGraphQLErrorMessage(error),
        tags: data && data.tags && data.tags.edges ? data.tags.edges.map((edge) => edge.node) : [],
        totalCount,
        hasNextPage: hasNextPage || false,
        refetch: refetch,
        // this hook is being used in multiple places but the endCursor is only used in one place, but it breaks the fetch in other places so we need to pass it as an optional parameter
        fetchMore: (search: string, type: TagType, shouldPaginate?: boolean) => {
            // $FlowIgnore
            return fetchMore({
                query: GET_TAGS,
                variables: {
                    first: GET_TAGS_LIMIT,
                    after: shouldPaginate && endCursor,
                    input: {
                        entityType: type,
                        searchTerm: search,
                    },
                },
                updateQuery: (previousResult, {fetchMoreResult}) => {
                    if (!fetchMoreResult || !previousResult) return;

                    return {
                        tags: {
                            __typename: 'TagsConnection',
                            pageInfo: fetchMoreResult.tags.pageInfo,
                            edges: [...previousResult.tags.edges, ...fetchMoreResult.tags.edges],
                        },
                    };
                },
            });
        },
    };
};
