/* @flow */

export const CHANNEL_UNKNOWN = 'UNKNOWN';
export const CHANNEL_SEARCH_ORGANIC = 'SEARCH_ORGANIC';
export const CHANNEL_SEARCH_PAID = 'SEARCH_PAID';
export const CHANNEL_SOCIAL_ORGANIC = 'SOCIAL_ORGANIC';
export const CHANNEL_SOCIAL_PAID = 'SOCIAL_PAID';
export const CHANNEL_EMAIL = 'EMAIL';
export const CHANNEL_REFERRER = 'REFERRER';
export const CHANNEL_TRADITIONAL = 'TRADITIONAL';
export const CHANNEL_DIRECT_TO_WEBSITE = 'DIRECT_TO_WEBSITE';

export const CHANNEL_VALUES = [
    CHANNEL_UNKNOWN,
    CHANNEL_SEARCH_ORGANIC,
    CHANNEL_SEARCH_PAID,
    CHANNEL_SOCIAL_ORGANIC,
    CHANNEL_SOCIAL_PAID,
    CHANNEL_EMAIL,
    CHANNEL_DIRECT_TO_WEBSITE,
    CHANNEL_REFERRER,
    CHANNEL_TRADITIONAL,
];

export const CHANNEL_VALUES_FOR_TABLES = [
    CHANNEL_SEARCH_ORGANIC,
    CHANNEL_SEARCH_PAID,
    CHANNEL_SOCIAL_ORGANIC,
    CHANNEL_SOCIAL_PAID,
    CHANNEL_EMAIL,
    CHANNEL_DIRECT_TO_WEBSITE,
    CHANNEL_REFERRER,
    CHANNEL_TRADITIONAL,
];

export const CHANNELS = {
    [CHANNEL_SEARCH_ORGANIC]: {
        value: CHANNEL_SEARCH_ORGANIC,
        title: 'Organic search',
        article: 'an',
        description: 'Searched Google or other search engine',
    },
    [CHANNEL_SEARCH_PAID]: {
        value: CHANNEL_SEARCH_PAID,
        title: 'Paid search',
        article: 'a',
        description: 'Clicked your ad on Google or other search engines',
    },
    [CHANNEL_SOCIAL_ORGANIC]: {
        value: CHANNEL_SOCIAL_ORGANIC,
        title: 'Organic social',
        article: 'an',
        description: 'Visited your site from Twitter, Facebook, etc.',
    },
    [CHANNEL_SOCIAL_PAID]: {
        value: CHANNEL_SOCIAL_PAID,
        title: 'Paid social',
        article: 'a',
        description: 'Clicked your ad on Twitter, Facebook, etc.',
    },
    [CHANNEL_EMAIL]: {
        value: CHANNEL_EMAIL,
        title: 'Email',
        article: 'an',
        description: 'Clicked a link in your email campaign',
    },
    [CHANNEL_DIRECT_TO_WEBSITE]: {
        value: CHANNEL_DIRECT_TO_WEBSITE,
        title: 'Direct traffic',
        article: 'a',
        description: 'Navigated directly to your website',
    },
    [CHANNEL_REFERRER]: {
        value: CHANNEL_REFERRER,
        title: 'Referral traffic',
        article: 'a',
        description: 'Arrived from a third-party website',
    },
    [CHANNEL_TRADITIONAL]: {
        value: CHANNEL_TRADITIONAL,
        title: 'Traditional',
        article: 'a',
        description: 'Saw a billboard, connected at a tradeshow, etc.',
    },
    [CHANNEL_UNKNOWN]: {
        value: CHANNEL_UNKNOWN,
        title: 'No channel',
        article: 'an',
        description: 'Unknown',
    },
};
