import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Phone from './icon-cell-phone.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Phone {...validProps} />;
}
SvgComponent.iconName = 'alignRight';

export const CellPhoneIcon = svgIconWrapper(SvgComponent);
