/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import type {ActionMeta, SelectBaseProps} from 'react-select';

import {colors} from '../colors';
import {SelectPickerGeneric} from '../select-picker-generic';

import type {
    NoOptionsMessageProps,
    FooterButtonProps,
} from '../select-picker-generic/select-picker-generic';
import {IconAndLabel, type RemoveButtonProps} from './icon-and-label';

import './select-two-line-icon.css';

export type TwoLineOptionWithIcon = {
    value: string,
    label: string,
    sublabel: string,
    icon: React.ComponentType<*>,
    isDisabled?: boolean,
};

export type Props = {|
    ...$Exact<SelectBaseProps>,
    value?: ?TwoLineOptionWithIcon,
    options: TwoLineOptionWithIcon[],
    onChange: (value: TwoLineOptionWithIcon, action: ActionMeta) => void,
    // This prop makes the SelectTwoLineIcon have styles matching SelectPickerGeneric
    hasGenericStyles?: boolean,
    height?: number,
    hideDropdownIndicator?: boolean,
    shouldWrap?: boolean,
    customNoOptionsMessage?: NoOptionsMessageProps,
    footerButton?: FooterButtonProps,
    removeButton?: RemoveButtonProps,
|};

export function SelectTwoLineIcon(props: Props) {
    const {
        // eslint-disable-next-line no-unused-vars
        isMulti,
        // eslint-disable-next-line no-unused-vars
        isClearable,
        hasGenericStyles,
        height,
        hideDropdownIndicator,
        onBlur,
        styles: customStyles,
        shouldWrap,
        removeButton,
        ...restProps
    } = props;

    const formatOptionLabel = (option: TwoLineOptionWithIcon) => {
        const isSelected = option === props.value;

        const styleName = classnames({
            'select-two-line-icon-option': !isSelected,
            'select-two-line-icon-option--selected': isSelected,
            'select-two-line-icon-option--disabled': option.isDisabled,
        });

        const onRemoveClick = (e) => {
            if (removeButton) {
                e.stopPropagation();
                removeButton.onClick(option.value);
            }
        };

        return (
            <div styleName={styleName}>
                <IconAndLabel
                    icon={option.icon}
                    label={option.label}
                    sublabel={option.sublabel}
                    isSelected={isSelected}
                    shouldWrap={shouldWrap}
                    onRemoveClick={onRemoveClick}
                />
            </div>
        );
    };

    const getBorderColor = (state) => {
        if (state.isFocused) {
            if (hasGenericStyles) {
                return `${colors.blue} !important`;
            } else {
                return colors.greyLt;
            }
        } else {
            return colors.offWhiteDk;
        }
    };

    const styles = {
        ...customStyles,
        control: (base, state) => ({
            ...base,
            '&:hover': {
                borderColor: colors.greyLt,
            },
            borderColor: getBorderColor(state),
            borderWidth: hasGenericStyles ? '1px' : '2px',
            borderRadius: 3,
            boxShadow: 'none',
            top: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: height ? `${height}px` : '38px',
            minHeight: height ? `${height}px` : '38px',
        }),
        indicatorsContainer: (base) => ({
            ...base,
            height: '100%',
        }),
        dropdownIndicator: (base) => ({
            ...base,
            display: hideDropdownIndicator ? 'none' : 'flex',
        }),
    };

    return (
        <div styleName='select-two-line-icon'>
            <SelectPickerGeneric
                {...restProps}
                onChange={(option, action) => {
                    props.onChange(option, action);
                }}
                options={props.options}
                styles={styles}
                formatOptionLabel={formatOptionLabel}
                isSearchable={false}
                // Who can explain it, who can tell why. Somehow, this
                // works. The onBlur must be explicitly set here.
                onBlur={onBlur}
            />
        </div>
    );
}
