import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 86 86'}>
            <path
                className='surface'
                d='M13.57,86H72.41C79.87,86,86,80.2,86,73.1V12.91C86,5.82,79.89,0,72.42,0H13.59C6.12,0,0,5.8,0,12.9V73.09C0,80.18,6.11,86,13.57,86Zm60-14.84a2.43,2.43,0,0,1-2.44,2.38H14.76a2.77,2.77,0,0,1-2.21-2.38l0-47.28a2.43,2.43,0,0,1,2.44-2.38H71.32a2.24,2.24,0,0,1,2.2,2.38Z'
            />
            <path
                className='border'
                d='M13.59,3H72.42a10.86,10.86,0,0,1,7.52,3A9.52,9.52,0,0,1,83,12.91V73.1c0,5.46-4.75,9.9-10.58,9.9H13.57a10.86,10.86,0,0,1-7.52-3A9.52,9.52,0,0,1,3,73.09V12.9C3,7.44,7.76,3,13.59,3h0m57.5,73.52h0a5.41,5.41,0,0,0,5.44-5.38V23.87a5.5,5.5,0,0,0-1.36-3.67,5.07,5.07,0,0,0-3.84-1.71H14.93A5.41,5.41,0,0,0,9.5,23.86l0,47.28a5.71,5.71,0,0,0,4.64,5.32l.28.06H71.09M13.59,0C6.12,0,0,5.8,0,12.9V73.09C0,80.18,6.11,86,13.57,86H72.41C79.87,86,86,80.2,86,73.1V12.91C86,5.82,79.89,0,72.42,0H13.59Zm57.5,73.52H14.76a2.77,2.77,0,0,1-2.21-2.38l0-47.28a2.43,2.43,0,0,1,2.44-2.38H71.32a2.24,2.24,0,0,1,2.2,2.38V71.14a2.43,2.43,0,0,1-2.44,2.38Z'
            />
            <path
                className='dots'
                d='M46,33.5H40a3,3,0,0,0-3,3v6a3,3,0,0,0,3,3h6a3,3,0,0,0,3-3v-6A3,3,0,0,0,46,33.5Zm0,16H40a3,3,0,0,0-3,3v6a3,3,0,0,0,3,3h6a3,3,0,0,0,3-3v-6A3,3,0,0,0,46,49.5Zm-16,0H24a3,3,0,0,0-3,3v6a3,3,0,0,0,3,3h6a3,3,0,0,0,3-3v-6A3,3,0,0,0,30,49.5Zm32-16H56a3,3,0,0,0-3,3v6a3,3,0,0,0,3,3h6a3,3,0,0,0,3-3v-6A3,3,0,0,0,62,33.5Z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'activity-empty-state-icon';

export const ActivityEmptyStateIcon = svgIconWrapper(SvgComponent);
