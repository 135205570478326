import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        // We only set 'currentColor' if we are passing a color because in some places
        // we manually set the color with a fill property in CSS
        // eslint-disable-next-line react/prop-types
        <svg {...validProps} viewBox={'0 0 55 55'} fill={props.color ? 'currentColor' : undefined}>
            <path d='M27.5 0A27.5 27.5 0 1 0 55 27.5 27.5 27.5 0 0 0 27.5 0zm15.58 21.35L28 36.59l-.08.1-3.37 3.41a1.79 1.79 0 0 1-2.54 0l-3.37-3.41-6.69-6.76a1.82 1.82 0 0 1 0-2.55l3.37-3.41a1.77 1.77 0 0 1 2.53 0l5.47 5.52 13.86-14.1a1.77 1.77 0 0 1 2.53 0l3.37 3.41a1.82 1.82 0 0 1 0 2.55z' />
        </svg>
    );
}
SvgComponent.iconName = 'tick-circle';

export const TickCircleIcon = svgIconWrapper(SvgComponent);
