/* @flow */

import * as React from 'react';
import {FieldArray} from 'redux-form';

import {Body} from 'shells/typography';

import {PipelineFields} from './pipeline-fields';

export function LeadClosedForm() {
    return (
        <div>
            <Body>Which pipeline?</Body>
            <FieldArray
                name='leadClosed'
                rerenderOnEveryChange={true}
                component={PipelineFields}
                formType='leadClosed'
            />
        </div>
    );
}
