/* @flow */

import * as React from 'react';
import {Field, type FieldArrayProps} from 'redux-form';

import type {Market} from 'nutshell-core/types';

import {nonNull} from 'shells/form/validation';
import {ReduxFormProductPicker} from 'shells/redux-form-fields';
import {AddAnotherButton} from 'shells/form';
import {Stack} from 'shells/layout';

type Props = {
    ...FieldArrayProps,
    autoFocus?: boolean,
    isRequired?: boolean,
    currentMarket: ?Market,
};

export function ProductSelect(props: Props): React.Node {
    const {isRequired, fields} = props;

    if (fields.length === 0) {
        // If we don't have any products, add an empty value
        fields.push(null);
    }

    // We show the add another button once a field has been added
    const shouldShowAddAnother = fields.get(fields.length - 1);

    // Fixes an issue where deleting the first field made the second one disappear
    // because the name (member) changes when the index changes
    const getKey = (index) => {
        const field = fields.get(index);

        return field && field.links && field.links.product ? field.links.product : index;
    };

    return (
        <Stack spacing={8}>
            <div>
                {fields.map((member, index) => (
                    <div key={getKey(index)}>
                        <Field
                            name={member}
                            component={ReduxFormProductPicker}
                            autoFocus={props.autoFocus}
                            onRemove={() => {
                                fields.remove(index);
                            }}
                            selectedOptions={fields.getAll()}
                            validate={isRequired ? [nonNull] : undefined}
                            currentMarket={props.currentMarket}
                        />
                    </div>
                ))}
            </div>
            {shouldShowAddAnother ? (
                <AddAnotherButton
                    text='+ Add another product'
                    onClick={() => {
                        fields.push(null);
                    }}
                />
            ) : (
                undefined
            )}
        </Stack>
    );
}
