import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import EnvelopeOpenText from './icon-envelope-open-text.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <EnvelopeOpenText {...validProps} />;
}
SvgComponent.iconName = 'envelopeOpenText';

export const EnvelopeOpenTextIcon = svgIconWrapper(SvgComponent);
