/* @flow */

import * as React from 'react';
import {type ActionMeta} from 'react-select';

import {SelectInlineCreatable} from 'shells/select-inline/select-inline-creatable';
import type {TokenStyle} from 'shells/select-inline/value-component';
import {colors, type ColorName} from 'shells/colors';

export type Option = {id: string, name: string, colorName?: ColorName};

type Props = {|
    placeholder: string,
    value: Option[],
    onChange: (newValue: any, actionMeta: ActionMeta) => void,
    onBlur: () => void,
    onCreate: (string) => void,
    onClearValue?: () => void,
    loadOptions: (search: string) => Promise<Option[]>,
    noOptionsMessage?: ({inputValue: string}) => string | null,
    formatCreateLabel?: (inputValue: string) => React.Node,
    multi?: boolean,
    components?: Object,
    labelKey?: string,
    valueKey?: string,
    clearable?: boolean,
    tokenizedStyle?: TokenStyle,
|};

export function GraphQLSelectCreatable(props: Props) {
    const {multi = true, tokenizedStyle = 'light'} = props;

    const styles = {
        valueContainer: (base) => ({
            ...base,
            padding: '2px 0',
            marginLeft: '8px',
        }),
        // Carousel has a max z-index of 21
        menu: (base) => ({...base, zIndex: 22}),
        control: (base, state) => ({
            ...base,
            '&:hover': {
                border: state.isFocused ? `1px solid ${colors.blue}` : `1px solid ${colors.greyLt}`,
            },
            border: state.isFocused ? `1px solid ${colors.blue}` : `1px solid ${colors.offWhiteMd}`,
            boxShadow: 'none',
            backgroundColor: '#fff',
            paddingTop: 5,
            marginLeft: 0,
            minHeight: 26,
            cursor: state.isDisabled ? 'default' : 'pointer',
        }),
    };

    return (
        <SelectInlineCreatable
            {...props}
            multi={multi}
            defaultOptions={true}
            tokenizedStyle={tokenizedStyle}
            transparentBackground={true}
            styles={styles}
            hasDropdownIndicator={true}
            isValidNewOption={(inputValue) => inputValue.length > 2} // Don't allow creating until 3+ characters are entered
            menuPlacement='auto'
        />
    );
}
