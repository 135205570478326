/* @flow */

import React from 'react';

import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';
import AccountCircleIconSVG from './icon-account-circle.svg';

type Props = {
    color?: string,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <AccountCircleIconSVG {...validProps} color={props.color || colors.company} />;
}

SvgComponent.iconName = 'accounts-circle';

export const AccountsCircleIcon = svgIconWrapper(SvgComponent);
