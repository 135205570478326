/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {ReduxFormSelectPickerGeneric} from 'shells/redux-form-fields';
import {ReduxFormSelectPickerMulti} from '../../../../client_modules/shells/react/components/redux-form-fields/select-picker-generic/select-picker-multi';

type Props = {
    ...FieldProps,
    fetchFunction: () => Object, // Could be an Enum
    relationshipKey: string, // Could be an Enum
    placeholder: string,
    isMulti?: boolean,
    isRequired?: boolean,
};

export function GraphQLRelationshipSelect(props: Props) {
    const queryResults = props.fetchFunction();
    const collection = queryResults[props.relationshipKey];

    const options = collection
        ? collection.map((model) => {
              return {
                  label: model.name,
                  value: model.id,
              };
          })
        : [];

    const selectedValue = options.find((field) => field.value === props.input.value);

    const Component = props.isMulti ? ReduxFormSelectPickerMulti : ReduxFormSelectPickerGeneric;
    // For multiselect, we save an array of strings
    const onChange = props.isMulti
        ? (option) => {
              props.input.onChange(option.map((opt) => opt.value));
          }
        : props.input.onChange;

    return (
        // $FlowIgnore this is because of the way we're passing the props through
        <Component
            {...props}
            options={options}
            placeholder={props.placeholder}
            onRemove={() => props.input.onChange(null)}
            value={selectedValue}
            autoFocus={false}
            isMulti={props.isMulti}
            onChange={onChange}
        />
    );
}
