/* @flow */

import * as React from 'react';

import {Modal} from '../../../modal';
import {ModalHeader, ModalBody} from '../../../typography';
import {ScrollableContainerWithShadow} from '../../../layout';
import {SummaryContent} from './summary-content';

type Props = {
    transcript: string,
    transcriptionSummary: ?{
        id: string,
        summary: ?string,
    },
    onClose: () => void,
};

export function TranscriptModal(props: Props) {
    return (
        <Modal isOpen={true} onClose={props.onClose}>
            <ModalHeader>Meeting transcript</ModalHeader>
            {props.transcriptionSummary && props.transcriptionSummary.summary ? (
                <div className='mb-16'>
                    <SummaryContent
                        title='Summary'
                        content={props.transcriptionSummary.summary}
                        popoverContent='This summary uses artificial intelligence to analyze
                                    your meeting transcript, highlight important moments, and provide
                                    key insights.'
                    />
                </div>
            ) : (
                undefined
            )}
            <ScrollableContainerWithShadow
                direction='vertical'
                maxContainerSize={380}
                showReverseGradientShadow={true}
                gradientShadowSize={60}
            >
                <ModalBody>{props.transcript}</ModalBody>
            </ScrollableContainerWithShadow>
        </Modal>
    );
}
