/* @flow */

import * as React from 'react';

import type {
    EventFragmentSparse_actor as Actor,
    ReactionFragment as Reaction,
    ReactionType,
    SessionUser,
} from 'nutshell-graphql-types';

import {TimelineEntryIcon} from 'shells/timeline/gutter/timeline-entry-icon';
import {TimelineBannerContent} from 'shells/timeline/banner/timeline-banner-content';
import type {TimelineEntryIconVariant} from 'shells/timeline/gutter/types';
import {AddReactionButton} from 'shells/timeline/reaction-bar';

import {getFilteredReactions, getToggleAction} from '../reactions/helpers';
import {TimelineReactionBar} from '../reactions/timeline-reaction-bar';
import {useToggleReaction} from '../reactions/reaction-mutations';
import {TimelineEntryContext} from '../timeline';

import './timeline-banner.css';

type ReactableBanner = {
    currentUser: SessionUser,
    reactions: Reaction[],
};

type Props = {|
    id: string,
    iconVariant: TimelineEntryIconVariant,
    timestamp: number,
    actor: ?Actor,
    action: React.Element<*>,
    reactableBanner?: ReactableBanner,
    bannerBody?: React.Element<*>,
    omitActor?: boolean,
|};

export function TimelineBanner(props: Props) {
    const {
        id,
        iconVariant,
        timestamp,
        actor,
        action,
        reactableBanner,
        bannerBody,
        omitActor,
    } = props;

    const timelineBannerRef = React.useRef();

    let reactions, currentUser;
    if (reactableBanner) {
        reactions = reactableBanner.reactions;
        currentUser = reactableBanner.currentUser;
    }

    const toggleReaction = useToggleReaction();

    // Get filtered reactions for reactions list
    const filteredReactions = reactions ? getFilteredReactions(reactions) : undefined;
    const reactionToggleAction =
        filteredReactions && currentUser
            ? (reaction) => getToggleAction(reaction, filteredReactions, currentUser.id)
            : undefined;

    const onToggleReaction =
        reactionToggleAction && currentUser
            ? (reactionType: ReactionType) => {
                  toggleReaction({
                      changeLogId: id,
                      reactionType,
                      user: currentUser,
                      toggleAction: reactionToggleAction(reactionType),
                  });
              }
            : undefined;

    const isLastEntry = Boolean(React.useContext(TimelineEntryContext).lastEntryId === id);
    const isTimelineSmall = Boolean(React.useContext(TimelineEntryContext).isTimelineSmall);

    return (
        <div
            styleName={isLastEntry ? 'banner-container--last-entry' : 'banner-container'}
            ref={(ref) => {
                timelineBannerRef.current = ref;
            }}
        >
            <div className='flex full-width'>
                <TimelineEntryIcon
                    variant={iconVariant}
                    gutterContainerRef={timelineBannerRef}
                    isSmall={isTimelineSmall}
                    isLastEntry={isLastEntry}
                />
                <div styleName='content'>
                    <TimelineBannerContent
                        timestamp={timestamp}
                        actor={actor}
                        action={action}
                        isSmall={isTimelineSmall}
                        omitActor={omitActor}
                    />
                    {filteredReactions && onToggleReaction && currentUser ? (
                        <>
                            {Object.keys(filteredReactions).length ? (
                                <div styleName='reaction-bar-container'>
                                    <TimelineReactionBar
                                        reactions={filteredReactions}
                                        currentUserId={currentUser.id}
                                        onToggleReaction={onToggleReaction}
                                    />
                                </div>
                            ) : (
                                <div styleName='reaction-button-container'>
                                    <AddReactionButton onSelectReaction={onToggleReaction} />
                                </div>
                            )}
                        </>
                    ) : (
                        undefined
                    )}
                </div>
            </div>
            {bannerBody ? (
                <div styleName={isTimelineSmall ? 'banner-body--small' : 'banner-body'}>
                    {bannerBody}
                </div>
            ) : (
                undefined
            )}
        </div>
    );
}
