/* @flow */

import React from 'react';

import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';
import AccountParentCircleSVG from './icon-account-parent-circle.svg';

type Props = {
    color?: string,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <AccountParentCircleSVG {...validProps} color={props.color || colors.company} />;
}
SvgComponent.iconName = 'accountParentCircle';

export const AccountParentCircleIcon = svgIconWrapper(SvgComponent);
