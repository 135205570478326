import moment from 'moment';
import {
    isToday,
    isYesterday,
    isBeforeYesterday,
    isTomorrow,
    isAfterTomorrow,
} from 'nutshell-core/date-time';

export function getEmptyToDoSidebarWords(date, format = 'MMMM Do', currentDate) {
    const targetDate = moment(date);
    const targetDateFormatted = targetDate.format(format);
    const today = moment(currentDate);
    let header, subheader;

    if (isToday(targetDate, today)) {
        header = 'Nothing due today';
        subheader = 'Take the day off!';
    } else if (isYesterday(targetDate, today)) {
        header = 'Nothing overdue from yesterday';
        subheader = 'Nice work!';
    } else if (isBeforeYesterday(targetDate, today)) {
        header = `Nothing overdue from ${targetDateFormatted}`;
        subheader = "You're on top of things";
    } else if (isTomorrow(targetDate, today)) {
        header = 'Nothing due tomorrow';
        subheader = 'Kick back, relax, and enjoy the weather';
    } else if (isAfterTomorrow(targetDate, today)) {
        header = `Nothing due on ${targetDateFormatted}`;
        subheader = 'Kick back, relax, and enjoy the weather';
    }

    return {header, subheader};
}

export function getEmptyActivitySidebarWords(date, format = 'MMMM Do', currentDate) {
    const targetDate = moment(date);
    const targetDateFormatted = targetDate.format(format);
    const today = moment(currentDate);
    let header, subheader;

    if (isToday(targetDate, today)) {
        header = 'No activities scheduled for today';
        subheader = 'Take the day off!';
    } else if (isYesterday(targetDate, today)) {
        header = 'There were no activities yesterday';
        subheader = "Must've been nice";
    } else if (isBeforeYesterday(targetDate, today)) {
        header = `There were no activities on ${targetDateFormatted}`;
        subheader = "Must've been nice";
    } else if (isTomorrow(targetDate, today)) {
        header = 'No activities scheduled tomorrow';
        subheader = 'Better get working';
    } else if (isAfterTomorrow(targetDate, today)) {
        header = `No activities scheduled for ${targetDateFormatted}`;
        subheader = 'Better get working';
    }

    return {header, subheader};
}

export function removeItemAndShiftIndices(currentIndices, indexToRemove) {
    return Object.keys(currentIndices).reduce((indices, index) => {
        if (index < indexToRemove) {
            indices[index] = currentIndices[index];
        } else if (index === indexToRemove) {
            return indices;
        } else {
            indices[index - 1] = currentIndices[index];
        }

        return indices;
    }, {});
}
