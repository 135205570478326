/* @flow */

import * as React from 'react';
import {Body} from '../typography';
import './list-view-special-state-text.css';

type Props = {
    text: string,
    cta?: React.Node,
};

export function ListViewSpecialStateText(props: Props) {
    return (
        <div styleName='list-view-special-state-text'>
            <Body>
                {props.text}&nbsp;{props.cta}
            </Body>
        </div>
    );
}
