/* @flow */

import * as React from 'react';
import {Button, type ButtonVariant} from '../button';
import {ErrorMessage} from '../form';
import {SpacerGroup} from '../spacer';

import {TextField} from './textfield';
import './textfield-with-apply.css';

type Props = {|
    buttonText: $PropertyType<HTMLInputElement, 'type'>,
    buttonVariant: ButtonVariant,
    initialValue: string,
    disabled: boolean,
    onApply: (inputVal: string) => void | Promise<*>,
    onEscape?: () => void,
    placeholder?: string,
    errorMessage?: string,
    autoFocus?: boolean,
    type: string,
|};

TextfieldWithApply.defaultProps = {
    type: 'text',
    buttonText: 'Apply',
    buttonVariant: 'primary',
    initialValue: '',
    disabled: false,
};

/**
 * This is intended to be used to add items to a list, by
 * typing them one at a time and pressing the button to submit the item
 * and clear it from the field.
 */
export function TextfieldWithApply(props: Props) {
    const [inputValue, setInputValue] = React.useState<string>(props.initialValue);
    const [isDisabled, setDisabled] = React.useState<boolean>(props.disabled);
    const [buttonIsDisabled, setButtonIsDisabled] = React.useState<boolean>(
        props.type === 'email' && !inputValue.length
    );
    const fieldRef = React.useRef<?HTMLInputElement>(null);

    const {disabled} = props;
    React.useEffect(() => {
        setDisabled(disabled);
    }, [disabled]);

    // When our value changes, check to see if we can
    // still submit it (only applies to type='email')
    React.useEffect(() => {
        if (fieldRef && props.type === 'email') {
            // If no input, disable submitting
            if (!inputValue.length) {
                setButtonIsDisabled(true);
            } else {
                // $FlowIgnore this exists!
                setButtonIsDisabled(!fieldRef.current.validity.valid);
            }
        }
    }, [inputValue, props.type]);

    function handleApply() {
        // Don't submit an empty value
        if (!inputValue || buttonIsDisabled) return;

        const application = props.onApply(inputValue);

        if (application instanceof Promise) {
            setDisabled(true);
            // If the onApply prop returned a promise, wait for it to resolve before clearing the input
            application.then(() => {
                setInputValue('');
                setDisabled(props.disabled);
                if (fieldRef.current) {
                    fieldRef.current.focus();
                }
            });
        } else {
            setInputValue('');
        }
    }

    const textFieldWithApply = (
        <div styleName='textfield-with-apply'>
            <TextField
                type={props.type}
                disabled={isDisabled}
                value={inputValue}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
                onChange={(val) => setInputValue(val)}
                onEnter={handleApply}
                onEscape={props.onEscape}
                textFieldRef={fieldRef}
            />
            <Button
                variant={props.buttonVariant}
                disabled={isDisabled || buttonIsDisabled}
                onClick={handleApply}
            >
                {props.buttonText}
            </Button>
        </div>
    );

    return props.errorMessage ? (
        <React.Fragment>
            <SpacerGroup mb={8}>{textFieldWithApply}</SpacerGroup>
            <ErrorMessage>{props.errorMessage}</ErrorMessage>
        </React.Fragment>
    ) : (
        textFieldWithApply
    );
}
