import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Message from './icon-message.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Message {...validProps} />;
}
SvgComponent.iconName = 'message-icon';

export const MessageIcon = svgIconWrapper(SvgComponent);
