/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {DateTimeInputTextfield} from '../date-time-picker/date-time-input-textfield';
import {FormField} from '../form';

type OwnProps = {|
    title?: string,
    placeholder?: string,
    helperText?: string,
    isRequired?: boolean,
    shouldOmitTime?: string,
    persistOnSelect?: boolean,
|};

type Props = {...$Exact<FieldProps>, ...OwnProps};

export function ReduxFormDateTime({
    title,
    helperText,
    isRequired,
    input,
    meta,
    shouldOmitTime,
    persistOnSelect,
    ...restProps
}: Props) {
    return (
        <FormField
            innerId={input.name}
            label={title}
            helperText={helperText}
            errorMessage={meta.error && meta.submitFailed ? meta.error : null}
            isRequired={isRequired}
        >
            <DateTimeInputTextfield
                {...input}
                {...restProps}
                shouldOmitTime={shouldOmitTime}
                displayFormat={shouldOmitTime ? 'dddd, MMMM Do, YYYY' : undefined}
                persistOnSelect={persistOnSelect}
            />
        </FormField>
    );
}
