/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import {ChevronLeftIcon, ChevronRightIcon} from '../icon';

import type {Direction} from './slideshow-container';

import './slideshow-arrow.css';

type Props = {|
    direction: Direction,
    selectedSlide: number,
    slideCount: number,
    onSlideChange: (newSlideNum: number) => void,
    isCircular?: boolean,
|};

export function SlideshowArrow(props: Props) {
    const {direction, selectedSlide, slideCount, isCircular, onSlideChange} = props;

    const canScroll =
        direction === 'left'
            ? isCircular || selectedSlide > 0
            : isCircular || selectedSlide < slideCount - 1;

    const styleNames = classnames({
        'slideshow-arrow': canScroll,
        'slideshow-arrow--hidden': !canScroll,
        // For some incredible reason, the font-awesome icons are 1px off center in opposite
        // directions. So these styleNames are needed to adjust each one accordingly.
        'slideshow-arrow--left': direction === 'left',
        'slideshow-arrow--right': direction === 'right',
    });

    return (
        <button
            styleName={styleNames}
            onClick={() => {
                if (!canScroll) return;

                const newSlideNum = direction === 'left' ? selectedSlide - 1 : selectedSlide + 1;

                onSlideChange(
                    isCircular
                        ? ((newSlideNum % slideCount) + slideCount) % slideCount
                        : newSlideNum
                );
            }}
        >
            {direction === 'left' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </button>
    );
}
