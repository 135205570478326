import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import AlignRight from './icon-align-right.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <AlignRight {...validProps} />;
}
SvgComponent.iconName = 'alignRight';

export const AlignRightIcon = svgIconWrapper(SvgComponent);
