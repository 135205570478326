/* @flow */

import {combineReducers} from 'redux';
import * as ramda from 'ramda';
import type {Task, TaskId} from '../types';
import {ActionTypes, type TasksAction} from './tasks-actions';

export type ByIdState = {
    [id: TaskId]: Task,
};
const byIdReducer = (state: ByIdState = {}, action: TasksAction): ByIdState => {
    switch (action.type) {
        case ActionTypes.TASKS_FETCH_BY_ID_SUCCESS: {
            const newTasks = action.payload.tasks.reduce(
                (acc, task) => {
                    acc[task.id] = task;

                    return acc;
                },
                {...state}
            );

            // $NutshellClientFlowIgnore upgrading Flow to v0.92.1
            return newTasks;
        }
        case ActionTypes.TASKS_FETCH_FOR_CONTACT_SUCCESS: {
            const newTasks = action.payload.response.tasks.reduce((acc, task) => {
                acc[task.id] = task;

                return acc;
            }, {});

            return newTasks;
        }
        case ActionTypes.TASKS_COMPLETE_TASK_SUCCESS:
        case ActionTypes.TASKS_UNCOMPLETE_TASK_SUCCESS: {
            const updatedTask = action.payload.tasks[0];
            if (!updatedTask) return state;

            return {...state, [updatedTask.id]: updatedTask};
        }
        default:
            return state;
    }
};

type LoadingState = $PropertyType<TasksState, 'loading'>;
const loadingReducer = (state: LoadingState = [], action: TasksAction): LoadingState => {
    switch (action.type) {
        case ActionTypes.TASKS_FETCH_BY_ID_REQUEST:
            return [...state, action.payload];
        case ActionTypes.TASKS_FETCH_BY_ID_SUCCESS: {
            const taskIds = action.payload.tasks.map((t) => t.id);

            return ramda.without(taskIds, state);
        }
        case ActionTypes.TASKS_FETCH_BY_ID_FAILURE:
            return ramda.without([action.payload.taskId], state);
        default:
            return state;
    }
};

type ErrorsState = $PropertyType<TasksState, 'errors'>;
const errorsReducer = (state: ErrorsState = {}, action: TasksAction): ErrorsState => {
    switch (action.type) {
        case ActionTypes.TASKS_FETCH_BY_ID_REQUEST:
            return ramda.omit([action.payload], state);
        case ActionTypes.TASKS_FETCH_BY_ID_SUCCESS:
            return ramda.omit(
                action.payload.tasks.map((task) => task.id),
                state
            );
        case ActionTypes.TASKS_FETCH_BY_ID_FAILURE:
            // TODO: Make a better default error
            return {...state, [action.payload.taskId]: action.payload.response || {}};
        default:
            return state;
    }
};

export type TasksState = {
    byId: ByIdState,
    loading: TaskId[],
    errors: {[TaskId]: Object},
};

// $FlowFixMe upgrading Flow to v0.92.1
export const reducer = combineReducers({
    byId: byIdReducer,
    loading: loadingReducer,
    errors: errorsReducer,
});
