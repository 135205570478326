/* @flow */

import {Observable} from 'rxjs/Observable';
import type {ActionsObservable} from 'redux-observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import type {UsersApiResponse} from '../types';
import {fetch as fetchSessionFromApi} from './session-api';
import {
    resolveFetchSession,
    failFetchSession,
    addHackyDOMUser,
    fetchUserFromDOMAsAHackToQuicklyUpdateOurSession,
} from './session-actions';
import {ActionTypes, type SessionAction} from './session-types';

// This needs to be a constructor, so we can provide a custom fetch service in the gmail extension,
// which cannot hit the api directly, but rather has to get passed through the background.js script.
export const makeFetchSessionEpic = (fetchSession: () => Promise<UsersApiResponse>) => (
    action$: ActionsObservable<SessionAction>
) =>
    action$.ofType(ActionTypes.SESSION_FETCH_SESSION_REQUEST).switchMap(() => {
        // Return two observable streams of actions, one for the hacky dom session grabbing
        // when we are on the web, and the other for normal `session` api requests
        return Observable.merge(
            Observable.fromPromise(
                fetchUserFromDOMAsAHackToQuicklyUpdateOurSession()
            ).map((response) => addHackyDOMUser(response)),
            Observable.fromPromise(fetchSession())
                .map((response: UsersApiResponse) => resolveFetchSession(response))
                .catch((error) => Observable.of(failFetchSession(error)))
        );
    });

export const fetchSessionEpic = makeFetchSessionEpic(fetchSessionFromApi);
