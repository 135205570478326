import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ReplyClock from './icon-reply-clock.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ReplyClock {...validProps} />;
}
SvgComponent.iconName = 'reply-clock';

export const ReplyClockIcon = svgIconWrapper(SvgComponent);
