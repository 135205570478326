/* @flow */

import type {OpenableModalId} from './openable-modals';

export const ActionTypes = {
    MODAL_OPENED: 'MODAL_OPENED',
    MODAL_CLOSED: 'MODAL_CLOSED',
};

type OpenableModalsOpenAction = {type: 'MODAL_OPENED', id: OpenableModalId, options?: Object};
type OpenableModalsCloseAction = {type: 'MODAL_CLOSED', id: OpenableModalId};

export type OpenableModalsAction = OpenableModalsOpenAction | OpenableModalsCloseAction;

/*
 * Action creators
 */
export const openModal = (
    modalId: OpenableModalId,
    options?: Object
): OpenableModalsOpenAction => ({
    type: ActionTypes.MODAL_OPENED,
    id: modalId,
    options,
});

export const closeModal = (modalId: OpenableModalId): OpenableModalsCloseAction => ({
    type: ActionTypes.MODAL_CLOSED,
    id: modalId,
});
