/* @flow */

import {requestApiComplete, requestApiUncomplete, fetch} from './tasks-api';

import * as actions from './tasks-actions';
import {
    makeFetchTasksForContactEpic,
    makeFetchTaskByIdEpic,
    makeRequestCompleteTaskEpic,
    makeRequestUncompleteTaskEpic,
    makeCreateTaskEpic,
} from './tasks-epics';
import * as selectors from './tasks-selectors';
import {reducer} from './tasks-reducer';
import {REDUCER_KEY} from './tasks-types';

const epics = [
    makeFetchTasksForContactEpic(),
    makeFetchTaskByIdEpic(),
    makeRequestCompleteTaskEpic(),
    makeRequestUncompleteTaskEpic(),
    makeCreateTaskEpic(),
];

export const Tasks = {
    ...actions,
    ...selectors,
    epics,
    reducer,
    requestApiComplete,
    requestApiUncomplete,
    fetch,
    REDUCER_KEY,
};

export type {TasksState} from './tasks-reducer';
export type {Task, TasksApiResponse, TasksModuleState} from './tasks-types';
