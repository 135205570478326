/* @flow */

import {combineReducers, type Reducer} from 'redux';
import * as ramda from 'ramda';

import type {Contact, ContactId, EmailAddress, ListItem} from '../types';
import * as Schema from '../schema';
import {reduceListItemsByEntityId} from '../list-items/list-items-utils';
import {relatedLeadsReducer, type RelatedLeadsState} from './contacts-related-leads-reducer';
import {ActionTypes, type ContactsAction, type ContactListSchemaAction} from './contacts-actions';

/*
 * `byId`
 */
type ByIdState = {
    [id: ContactId]: Contact,
};
const byIdReducer = (state: ByIdState = {}, action: ContactsAction): ByIdState => {
    switch (action.type) {
        case ActionTypes.CONTACTS_FETCH_BY_EMAIL_ADDRESS_SUCCESS: {
            const newContacts: Contact[] = ramda.values(action.payload.contacts);
            const newContactsById = newContacts.reduce((accum, contact) => {
                accum[contact.id] = contact;

                return accum;
            }, {});

            return {...state, ...newContactsById};
        }
        case ActionTypes.CONTACTS_FETCH_BY_ID_SUCCESS: {
            const newContacts = action.payload.contacts.reduce((accum, contact) => {
                accum[contact.id] = contact;

                return accum;
            }, {});

            return {...state, ...newContacts};
        }
        case ActionTypes.CONTACTS_CREATE_CONTACT_SUCCESS: {
            const {
                response: {contacts},
            } = action.payload;

            const updatedContacts = contacts.reduce(
                (contactsById, contact) => ({...contactsById, [contact.id]: contact}),
                state
            );

            return updatedContacts;
        }
        case ActionTypes.CONTACTS_EDIT_CONTACT_SUCCESS: {
            const {
                response: {contacts},
            } = action.payload;

            return contacts.reduce(
                (contactsById, contact) => ({
                    ...contactsById,
                    [contact.id]: {
                        ...contactsById[contact.id],
                        ...contact,
                    },
                }),
                state
            );
        }
        case ActionTypes.CONTACTS_FETCH_CUSTOM_FIELDS_SUCCESS: {
            const {
                contactId,
                response: {customFields},
            } = action.payload;

            return {...state, [contactId]: {...state[contactId], customFields}};
        }
        default:
            return state;
    }
};

/*
 * `unknownEmailAddresses`
 *
 * This will store the `unknown` key from a response from /rest/internal/searchemail
 * It is used by the email extension client to prompt for creation of new contacts.
 */
type UnknownEmailAddressesState = EmailAddress[];
const unknownEmailAddressesReducer = (
    state: UnknownEmailAddressesState = [],
    action: ContactsAction
): UnknownEmailAddressesState => {
    switch (action.type) {
        case ActionTypes.CONTACTS_FETCH_BY_EMAIL_ADDRESS_SUCCESS: {
            return action.payload.unknown;
        }
        default:
            return state;
    }
};

/*
 * `isLoading`
 */
type IsLoadingState = boolean;
const isLoadingReducer = (
    state: IsLoadingState = false,
    action: ContactsAction
): IsLoadingState => {
    switch (action.type) {
        case ActionTypes.CONTACTS_FETCH_BY_EMAIL_ADDRESS_REQUEST:
            return true;
        case ActionTypes.CONTACTS_FETCH_BY_EMAIL_ADDRESS_SUCCESS:
        case ActionTypes.CONTACTS_FETCH_BY_EMAIL_ADDRESS_FAILURE:
            return false;
        default:
            return state;
    }
};

type UnloadedSchemaState = {|status: 'not-loaded', properties: null|};
type LoadingSchemaState = {|
    status: 'is-loading',
    properties: ?{[name: string]: Schema.FieldProperties},
|};
type LoadedSchemaState = {|status: 'loaded', properties: {[name: string]: Schema.FieldProperties}|};
type ErrorLoadingSchemaState = {|
    status: 'error',
    properties: ?{[name: string]: Schema.FieldProperties},
    error: ?Object,
|};

type SchemaState =
    | UnloadedSchemaState
    | LoadingSchemaState
    | LoadedSchemaState
    | ErrorLoadingSchemaState;

const defaultSchemaState = {status: 'not-loaded', properties: null};
const schemaReducer = (
    state: SchemaState = defaultSchemaState,
    action: ContactListSchemaAction
): SchemaState => {
    switch (action.type) {
        case ActionTypes.CONTACTS_LIST_FETCH_SCHEMA_REQUEST:
            return {status: 'is-loading', properties: state.properties};
        case ActionTypes.CONTACTS_LIST_FETCH_SCHEMA_SUCCESS:
            return {status: 'loaded', properties: action.payload.properties};
        case ActionTypes.CONTACTS_LIST_FETCH_SCHEMA_FAILURE:
            return {status: 'error', properties: state.properties, error: action.payload};
        default:
            return state;
    }
};

const listReducer = combineReducers({
    schema: schemaReducer,
});

/*
 * `listItems`
 */
type ListItemsState = {
    [id: ContactId]: ListItem,
};
const listItemsByIdReducer = (
    state: ListItemsState = {},
    action: ContactsAction
): ListItemsState => {
    switch (action.type) {
        case ActionTypes.CONTACTS_FETCH_LIST_SUCCESS: {
            return reduceListItemsByEntityId({...state}, action.payload.listItems);
        }
        default:
            return state;
    }
};

export type State = {
    byId: ByIdState,
    unknownEmailAddresses: UnknownEmailAddressesState,
    relatedLeads: RelatedLeadsState,
    isLoading: IsLoadingState,
    list: {schema: SchemaState},
    listItemsById: ListItemsState,
};

export const reducer: Reducer<State, ContactsAction | ContactListSchemaAction> = combineReducers({
    byId: byIdReducer,
    unknownEmailAddresses: unknownEmailAddressesReducer,
    relatedLeads: relatedLeadsReducer,
    isLoading: isLoadingReducer,
    list: listReducer,
    listItemsById: listItemsByIdReducer,
});
