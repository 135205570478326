/* @flow */

import * as React from 'react';

import {ButtonMover} from '../../button-mover';
import {Button} from '../../button';
import {Stack} from '../../layout';
import {ChevronLeftIcon} from '../../icon';

import './sub-sidebar-header.css';

type Props = {|
    title: string,
    subtitle?: string | React.Node,
    onClickBack: () => void,
    getRef?: any,
    noShadow?: boolean,
|};

export function SubSidebarHeader(props: Props) {
    return (
        <div
            styleName={props.noShadow ? 'sub-sidebar-header--no-shadow' : 'sub-sidebar-header'}
            ref={props.getRef}
        >
            <ButtonMover size='big' direction={['left', 'up']}>
                <Button onClick={props.onClickBack} variant='text-secondary' size='big'>
                    <Stack spacing={8} isHorizontal={true} alignCenter={true}>
                        <ChevronLeftIcon size={15} wrapWithDiv={false} />
                        <div>Back</div>
                    </Stack>
                </Button>
            </ButtonMover>
            <div styleName='header-text-container'>
                <div styleName='title'>{props.title}</div>
                {props.subtitle && <div styleName='subtitle'>{props.subtitle}</div>}
            </div>
        </div>
    );
}
