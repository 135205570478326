/* @flow */

import * as React from 'react';

import {Link as RoutedLink} from 'react-router-dom';

import {Popover} from 'shells/popover';
import {PopoverForm} from 'shells/popover-form';
import {FormField, ConditionalCheckboxInput} from 'shells/form';
import {getPeopleText} from 'shells/visitor-sidebar/helpers';
import {Link} from 'shells/link';
import {LoadingPage} from 'shells/loading-page';
import {DescriptionBody, Body} from 'shells/typography';
import {Stack} from 'shells/layout';

import {PipelineSelectPicker} from '../../../core/form/fields/pipeline-select-picker';

import {type UnlockInfo} from '../../../people-iq/graphql/hooks/use-get-unlock-info';

type Props = {
    anchor: any,
    name: string,
    selectedPeopleIds: string[],
    onClose: () => void,
    onCreate: (stagesetId: ?string) => Promise<*>,
    unlockInfo: ?UnlockInfo,
};

export function AddCompanyPopover(props: Props) {
    const [isSaving, setIsSaving] = React.useState<boolean>(false);
    const [isAddLeadChecked, setIsAddLeadChecked] = React.useState<boolean>(true);
    const [selectedStagesetId, setSelectedStagesetId] = React.useState<?string>(null);

    const {unlockInfo} = props;

    const unlocksNeeded = props.selectedPeopleIds.length + 1;

    const onSubmit = () => {
        setIsSaving(true);
        props.onCreate(isAddLeadChecked ? selectedStagesetId : null).then(() => {
            setIsSaving(false);
            props.onClose();
        });
    };

    const peopleText = getPeopleText(props.selectedPeopleIds.length);
    const isUnlockRestricted = Boolean(
        unlockInfo &&
            (unlockInfo.unlocksRemaining === 0 || unlocksNeeded > unlockInfo.unlocksRemaining)
    );

    let saveButtonText = 'Add company';
    if (peopleText && isAddLeadChecked) {
        saveButtonText = 'Add company, people, & lead';
    } else if (peopleText && !isAddLeadChecked) {
        saveButtonText = 'Add company & people';
    } else if (peopleText && isAddLeadChecked) {
        saveButtonText = 'Add company & lead';
    }

    return (
        <Popover
            anchor={props.anchor}
            onBlur={props.onClose}
            location='top'
            customStyles={!unlockInfo && {width: 240, height: 198}}
        >
            {!unlockInfo ? (
                <LoadingPage padding={0} />
            ) : (
                <>
                    <PopoverForm
                        width={340}
                        allowTitleWrapping={true}
                        onClose={props.onClose}
                        title={
                            getUnlockRestrictedPopoverContent(
                                'title',
                                unlocksNeeded,
                                unlockInfo
                            ) || (
                                <>
                                    Add <u>{props.name}</u>
                                    {peopleText && ` and ${peopleText}`} to Nutshell
                                </>
                            )
                        }
                        isSaving={isSaving}
                        saveButtonSavingText='Adding…'
                        saveButtonSuccessText='Added!'
                        saveButtonText={saveButtonText}
                        onSubmit={onSubmit}
                        hideSubmitButton={isUnlockRestricted}
                        formBodyStyles={{margin: '16px 0px 48px 0'}}
                    >
                        {getUnlockRestrictedPopoverContent('body', unlocksNeeded, unlockInfo) || (
                            <Stack spacing={24}>
                                <DescriptionBody>
                                    This will use {unlocksNeeded} of your{' '}
                                    {unlockInfo.unlocksRemaining.toLocaleString()} remaining{' '}
                                    {unlockInfo.unlocksRemaining === 1 ? 'credit' : 'credits'}
                                </DescriptionBody>
                                <FormField innerId='createNewLead'>
                                    <ConditionalCheckboxInput
                                        checked={isAddLeadChecked}
                                        isConditionalAlignedWithLabel={false}
                                        label='Also create new lead'
                                        onChange={(newValue) => {
                                            if (!newValue) {
                                                // Unset the previously selected stageset id if the option is unchecked
                                                setSelectedStagesetId(null);
                                            }
                                            setIsAddLeadChecked(newValue);
                                        }}
                                    >
                                        <PipelineSelectPicker
                                            minHeight={39}
                                            autoselectDefault={true}
                                            selectedPipeline={selectedStagesetId}
                                            onSelectPipeline={(id: string) =>
                                                setSelectedStagesetId(id)
                                            }
                                        />
                                    </ConditionalCheckboxInput>
                                </FormField>
                            </Stack>
                        )}
                    </PopoverForm>
                </>
            )}
        </Popover>
    );
}

const getUnlockRestrictedPopoverContent = (
    contentType: 'title' | 'body',
    unlocksNeeded: number,
    unlockInfo: UnlockInfo
): ?React.Node => {
    if (unlockInfo.unlocksRemaining === 0) {
        return contentType === 'title' ? (
            `All ${unlockInfo.unlockLimit.toLocaleString()} credits have been used this month`
        ) : (
            <Body>
                Come back on the 1st or{' '}
                <Link
                    as={RoutedLink}
                    to={
                        NutClientConfig.hasNewBillingPage
                            ? '/setup/billing-checkout'
                            : '/setup/billing'
                    }
                    newTab={true}
                    size='small'
                >
                    purchase additional credits
                </Link>{' '}
                to get more now.
            </Body>
        );
    } else if (unlocksNeeded > unlockInfo.unlocksRemaining) {
        return contentType === 'title' ? (
            `${unlockInfo.unlockedThisMonth.toLocaleString()} credits have been used this month`
        ) : (
            <Body>
                You can use <strong>{unlockInfo.unlocksRemaining}</strong> more{' '}
                {unlockInfo.unlocksRemaining === 1 ? 'credit' : 'credits'} out of the available{' '}
                {unlockInfo.unlockLimit.toLocaleString()} in your plan this month.
            </Body>
        );
    }

    return undefined;
};
