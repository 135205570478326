import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import GoalEmail from './icon-goal-email.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <GoalEmail {...validProps} />;
}
SvgComponent.iconName = 'goal-email';

export const GoalEmailIcon = svgIconWrapper(SvgComponent);
