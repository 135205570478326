/* @flow */

import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';
import type {ReportRowData, TimeseriesReportData} from 'nutshell-core/types';
import type {SiteAnalyticsStats} from '../../../reports/report-summary';

import * as actions from './analytics-actions';

type SiteAnalyticsReportTableData = {
    rows: ?(ReportRowData[]),
    totals: ?ReportRowData,
    hasError: boolean,
};
type SiteAnalyticsReportState = {
    timeseriesChart: {
        isLoading: boolean,
        isTimedOut: boolean,
        isErrored: boolean,
        chartData: ?TimeseriesReportData,
        prefix: string,
    },
    tableData: SiteAnalyticsReportTableData,
};
const siteAnalyticsReportDefaultState = {
    timeseriesChart: {
        isLoading: true,
        isTimedOut: false,
        isErrored: false,
        chartData: null,
        prefix: '$',
    },
    tableData: {rows: undefined, totals: undefined},
};

const defaultStat = {value: 0, formatted: '0', prefix: '$', suffix: ''};
const defaultAnalyticsSummaryStats: SiteAnalyticsStats = {
    views: {mean: defaultStat, sum: defaultStat},
    visitors: {mean: defaultStat, sum: defaultStat},
    viewsPerVisit: {mean: defaultStat, sum: defaultStat},
};

type SummaryStatsState = {
    isLoading: boolean,
    stats: SiteAnalyticsStats,
};
const summaryStatsDefaultState = {
    isLoading: true,
    stats: defaultAnalyticsSummaryStats,
};

const siteAnalyticsReportReducer = createReducer(
    {
        [actions.updateSiteAnalyticsReportTableData]: (
            state,
            payload: SiteAnalyticsReportTableData
        ) => {
            return {...state, tableData: payload};
        },
        // $FlowIgnore - this works correctly from redux-act
        [actions.requestSiteAnalyticsReportData]: (state) => {
            const newChartState = {
                ...state.timeseriesChart,
                isLoading: true,
                isTimedOut: false,
                isErrored: false,
            };

            const newTableState = {
                ...state.tableData,
                isLoading: true,
                isTimedOut: false,
                isErrored: false,
            };

            return {...state, timeseriesChart: newChartState, tableData: newTableState};
        },
        [actions.updateSiteAnalyticsReportChartData]: (state, {chartData}) => {
            const newChartState = {
                ...state.timeseriesChart,
                isLoading: false,
                isTimedOut: false,
                isErrored: false,
                chartData,
            };

            return {...state, timeseriesChart: newChartState};
        },
        [actions.failSiteAnalyticsReportChartData]: (state, err) => {
            if (err) {
                if (err.status === 504 || err.status === 502) {
                    const newChartState = {
                        ...state.timeseriesChart,
                        chartData: null,
                        isLoading: false,
                        isTimedOut: true,
                        isErrored: false,
                    };

                    return {...state, timeseriesChart: newChartState};
                } else {
                    const newChartState = {
                        ...state.timeseriesChart,
                        chartData: null,
                        isLoading: false,
                        isTimedOut: false,
                        isErrored: true,
                    };

                    return {
                        ...state,
                        tableData: {...state.tableData, hasError: true},
                        timeseriesChart: newChartState,
                    };
                }
            }

            return state;
        },
    },
    siteAnalyticsReportDefaultState
);

export const summaryStatsReducer = createReducer(
    {
        // $FlowIgnore - this works correctly from redux-act
        [actions.requestSiteAnalyticsReportData]: (state) => {
            return {...state, isLoading: true};
        },
        [actions.updateAnalyticsSummaryStats]: (state, {stats}) => {
            return {...state, isLoading: false, stats: stats};
        },
    },
    summaryStatsDefaultState
);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const siteAnalyticsReducer = combineReducers({
    siteAnalyticsReport: siteAnalyticsReportReducer,
    summaryStats: summaryStatsReducer,
});

export type SiteAnalyticsState = {
    siteAnalyticsReport: SiteAnalyticsReportState,
    summaryStats: SummaryStatsState,
};
