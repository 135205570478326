/* @flow */

import * as React from 'react';

import {Modal} from './modal';

import './image-modal.css';

type Props = {|
    sidebar: {
        image: React.Element<any>,
        backgroundColor: string,
    },
    children: React.Node,
    isOpen: boolean,
    onClose: () => void,
|};

export class ImageModal extends React.PureComponent<Props> {
    render() {
        const {children, sidebar, ...otherProps} = this.props;

        return (
            <Modal {...otherProps} noPadding={true}>
                <div styleName='container'>
                    <div styleName='left' style={{backgroundColor: sidebar.backgroundColor}}>
                        {sidebar.image}
                    </div>
                    <div styleName='right'>{children}</div>
                </div>
            </Modal>
        );
    }
}
