/* @flow */

import * as React from 'react';

import {Card} from '../../card';
import {Avatar} from '../../avatar';
import type {Participant} from '../types';

import './timeline-banner-comment-card.css';

const SINGLE_LINE_COMMENT_HEIGHT = 24;

type Props = {|
    avatar: Participant,
    content: React.Node,
|};

export function TimelineBannerCommentCard(props: Props) {
    const {avatar, content} = props;

    const commentContainerRef = React.useRef(undefined);
    const [isMultilineComment, setIsMultilineComment] = React.useState<boolean>(false);

    React.useLayoutEffect(() => {
        if (commentContainerRef && commentContainerRef.current) {
            if (commentContainerRef.current.clientHeight > SINGLE_LINE_COMMENT_HEIGHT) {
                setIsMultilineComment(true);
            }
        }
    }, []);

    return (
        <Card hasBorder={true} hasShadow={true} fitHeight={true}>
            <div styleName='content-container'>
                <div styleName='avatar-container'>
                    <Avatar
                        size={20}
                        avatarUrl={avatar.avatarUrl}
                        type={avatar.type}
                        initials={avatar.initials || ''}
                        circular={true}
                        border={true}
                    />
                </div>
                <div
                    styleName={
                        isMultilineComment ? 'comment-container--multiline' : 'comment-container'
                    }
                    ref={(r) => {
                        commentContainerRef.current = r;
                    }}
                >
                    {content}
                </div>
            </div>
        </Card>
    );
}
