import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ReplyAll from './icon-reply-all.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ReplyAll {...validProps} />;
}
SvgComponent.iconName = 'reply-all';

export const ReplyAllIcon = svgIconWrapper(SvgComponent);
