import PropTypes from 'prop-types';
import React from 'react';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import styles from './slide-in.css';

export class SlideIn extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        direction: PropTypes.oneOf(['left', 'right', 'up', 'down']),
    };

    static defaultProps = {
        direction: 'left',
    };

    render() {
        const {direction, ...other} = this.props;

        // Add a custom className to every child
        const children = React.Children.map(this.props.children, (child) => {
            const childClass = child.props.className
                ? `${child.props.className} ${styles['transition-slide-in-child']}`
                : styles['transition-slide-in-child'];

            return React.cloneElement(child, {className: childClass});
        });

        return (
            <ReactCSSTransitionGroup
                {...other}
                styleName={`styles.transition-slide-in--${direction}`}
                transitionName={{
                    enter: styles.enter,
                    enterActive: styles['enter-active'],
                    leave: styles.leave,
                    leaveActive: styles['leave-active'],
                }}
                component='div'
                transitionAppearTimeout={250}
                transitionEnterTimeout={250}
                transitionLeaveTimeout={250}
            >
                {children}
            </ReactCSSTransitionGroup>
        );
    }
}
