/* @flow */

import type {FilterObject} from './types';
import {Routing} from './routing';
import * as Schema from './schema';
import {serializeFilterObject} from './serialize-filter-object';

// Defining this here, as we don't really want to globally support a jQuery
// `$` constant, but we're using it here and I don't feel like changing up
// existing shared-code behavior.
declare var $: any;

export function createUrlFromNutshellFilterObject(
    filters: FilterObject,
    schemaProps: Schema.Properties
) {
    const filterKeys = Object.keys(filters);
    const serializedFilterObjects = {};

    filterKeys.forEach((filterKey) => {
        // If our schema properties doesn't have any information for the
        // Filter key, return nothing
        if (!schemaProps[filterKey]) return;

        // Grab individual filter object from `filters` object
        const filterObject = filters[filterKey];

        // Get the field type for the key (i.e. `relationship`, `date-time`)
        const filterType = schemaProps[filterKey].type;

        // Send our nutshell filter object with its type through our serializer
        const serializedFilterObject = serializeFilterObject(filterObject, filterType);

        serializedFilterObjects[filterKey] = serializedFilterObject;
    });

    return generateUrlFromSerializedFilterObjects(serializedFilterObjects);
}

function generateUrlFromSerializedFilterObjects(filterObjects) {
    const objectToParam = {filter: filterObjects};
    const paramString = Routing.param(objectToParam);

    return paramString
        .replace(/%5B/g, '[')
        .replace(/%5D/g, ']')
        .replace(/%2C/g, ',');
}
