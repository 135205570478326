/* @flow */

import {unexpectedCase} from 'nutshell-core/reducer-utils';
import {ActionTypes, type ToggleSidebarDismissAction} from './onboarding-actions';

export type OnboardingState = {
    isOnboardingSidebarDismissed: boolean,
};

export const defaultOnboardingState = {
    isOnboardingSidebarDismissed: false,
};

export const onboardingReducer = (
    state: OnboardingState = defaultOnboardingState,
    action: ToggleSidebarDismissAction
): OnboardingState => {
    switch (action.type) {
        case ActionTypes.TOGGLE_SIDEBAR_DISMISSED:
            return {
                isOnboardingSidebarDismissed: action.payload.isOnboardingSidebarDismissed,
            };
        default:
            unexpectedCase(action.type);

            return state;
    }
};
