import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import GoogleMeet from './icon-google-meet.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <GoogleMeet {...validProps} />;
}
SvgComponent.iconName = 'googleMeet';

export const GoogleMeetIcon = svgIconWrapper(SvgComponent);
