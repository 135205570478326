/* @flow */

import * as React from 'react';

import {Title, Body} from '.';

import './title-with-body.css';

type Props = {title: string, body: string};

export function TitleWithBody(props: Props) {
    return (
        <div styleName='container'>
            <Title>{props.title}</Title>
            <Body>{props.body}</Body>
        </div>
    );
}
