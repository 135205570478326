/* @flow */

// feature detect for URL constructor
// Inspired by https://github.com/webcomponents/URL/blob/36bb757bbce9390c13ce8639d097566b8b2acc35/url.js#L10-L21
export function hasWorkingUrl() {
    try {
        const u = new URL('b', 'http://a');
        u.pathname = 'c%20d';

        return u.href === 'http://a/c%20d';
    } catch (e) {
        return false;
    }
}
