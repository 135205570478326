import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';
import * as actions from '../../dashboard-actions';

const defaultPreviewState = {
    preview: {},
    isLoading: true,
    isRefreshing: false,
    errorMessage: undefined,
};

export const configPreviewReducer = createReducer(
    {
        [actions.clearDashboardPreview]: (state) => {
            return {
                ...state,
                preview: {},
                isLoading: true,
                isRefreshing: false,
                errorMessage: undefined,
            };
        },
        [actions.requestDashboardPreview]: (state) => {
            return {...state, isRefreshing: true};
        },
        [actions.updateDashboardPreview]: (state, payload) => {
            return {
                ...state,
                preview: payload,
                isLoading: false,
                isRefreshing: false,
                errorMessage: undefined,
            };
        },
        [actions.failDashboardPreview]: (state, payload) => {
            if (
                payload.dash.type === 'quotas' &&
                payload.errors &&
                payload.errors[0].title === 'Invalid dashboard quotas filter query params'
            ) {
                return {
                    ...state,
                    preview: {dashboardQuotas: []},
                    isLoading: false,
                    isRefreshing: false,
                };
            }

            return {
                ...state,
                isLoading: false,
                isRefreshing: false,
                errorMessage: 'There was an error, please try changing your settings.',
            };
        },
        [actions.updateDashboardPreviewName]: (state, payload) => {
            const newPreview = {...state.preview, name: payload};

            return {...state, preview: newPreview};
        },
    },
    defaultPreviewState
);

export const configReducer = combineReducers({
    preview: configPreviewReducer,
});
