import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ThumbsUp from './icon-thumbs-up.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ThumbsUp {...validProps} />;
}
SvgComponent.iconName = 'thumbs-up';

export const ThumbsUpIcon = svgIconWrapper(SvgComponent);
