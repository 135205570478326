/* @flow */

import {useQuery} from '@apollo/react-hooks';

import type {
    McfxViqContactFragment as McfxViqContact,
    GetMcfxViqContacts as GetMcfxViqContactsQuery,
    GetMcfxViqContactsVariables as GetMcfxViqContactsQueryVariables,
} from 'nutshell-graphql-types';

import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import GET_MCFX_VIQ_CONTACTS from '../queries/get-mcfx-viq-contacts.graphql';

export function useGetMcfxViqContacts(
    mcfxCompanyId: ?string
): {
    mcfxViqContacts: ?(McfxViqContact[]),
    refetch: () => Promise<*>,
    isLoading: boolean,
    isRefetching: boolean,
    error: ?string,
} {
    const {data, error, loading, refetch, networkStatus} = useQuery<
        GetMcfxViqContactsQuery,
        GetMcfxViqContactsQueryVariables
    >(GET_MCFX_VIQ_CONTACTS, {
        variables: {
            id: mcfxCompanyId || '',
        },
        skip: !mcfxCompanyId,
        fetchPolicy: 'cache-and-network',
    });

    return {
        mcfxViqContacts: data ? data.mcfxViqContacts : undefined,
        refetch,
        isLoading: loading,
        isRefetching: networkStatus === 4,
        error: safelyGetGraphQLErrorMessage(error),
    };
}
