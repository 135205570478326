/* @flow */

import React from 'react';

import {colors, type ColorName} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ProspectorSVG from './icon-prospector.svg';

type Props = {
    color?: ColorName,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ProspectorSVG {...validProps} color={props.color || colors.greyDk} />;
}

SvgComponent.iconName = 'prospector';

export const ProspectorIcon = svgIconWrapper(SvgComponent);
