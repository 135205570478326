import React from 'react';

import {svgIconWrapper} from '../svg-icon-wrapper';
import DoorClosed from './icon-door-closed.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <DoorClosed {...validProps} />;
}
SvgComponent.iconName = 'door-closed';

export const DoorClosedIcon = svgIconWrapper(SvgComponent);
