/* @flow */

import {createAction} from 'redux-act';
import moment from 'moment';
import type {Dispatch} from 'redux';

import {jsonToModelProp, jsonToModelPropArray} from 'nutshell-core';
import {api} from 'nutshell-core/api';
import {Session} from 'nutshell-core/session';
import {getCalendarSixWeeks} from 'nutshell-core/get-calendar-six-weeks';

import type {NutshellState} from '../../../store';
import * as selectors from '../sidebar/activities/activities-selectors';

/*------------------------------------------------------------------------------
Action Creators
------------------------------------------------------------------------------*/

export const updateActivityListScrollTop = createAction(
    'DASHBOARD_RIGHT_PANE_ACTIVITY_LIST_SCROLL_TOP_UPDATED'
);
export const updateActivityDisplayDate = createAction('DASHBOARD_RIGHT_PANE_ACTIVITY_DATE_UPDATED');
export const updateActivityCalendarHeight = createAction(
    'DASHBOARD_RIGHT_PANE_ACTIVITY_CALENDAR_HEIGHT_UPDATED'
);
export const updateActivityCalendarDisplayMode = createAction(
    'DASHBOARD_RIGHT_PANE_ACTIVITY_CALENDAR_DISPLAY_MODE_UPDATED'
);

export const requestActivitiesForDay = createAction(
    'DASHBOARD_RIGHT_PANE_ACTIVITIES_DAY_REQUESTED'
);
export const updateActivitiesForDay = createAction('DASHBOARD_RIGHT_PANE_ACTIVITIES_DAY_UPDATED');
export const failActivitiesForDay = createAction('DASHBOARD_RIGHT_PANE_ACTIVITIES_DAY_FAILED');

export const requestActivitiesForMonth = createAction(
    'DASHBOARD_RIGHT_PANE_ACTIVITIES_MONTH_REQUESTED'
);
export const updateActivitiesForMonth = createAction(
    'DASHBOARD_RIGHT_PANE_ACTIVITIES_MONTH_UPDATED'
);
export const failActivitiesForMonth = createAction('DASHBOARD_RIGHT_PANE_ACTIVITIES_MONTH_FAILED');

export const requestOverdueActivities = createAction(
    'DASHBOARD_RIGHT_PANE_OVERDUE_ACTIVITIES_REQUESTED'
);
export const updateOverdueActivities = createAction(
    'DASHBOARD_RIGHT_PANE_OVERDUE_ACTIVITIES_UPDATED'
);
export const failOverdueActivities = createAction('DASHBOARD_RIGHT_PANE_OVERDUE_ACTIVITIES_FAILED');

export const requestActivityData = createAction('DASHBOARD_RIGHT_PANE_ACTIVITY_DATA_REQUESTED');
export const updateActivityData = createAction('DASHBOARD_RIGHT_PANE_ACTIVITY_DATA_UPDATED');
export const failActivityData = createAction('DASHBOARD_RIGHT_PANE_ACTIVITY_DATA_FAILED');

export const toggleActivityOverdueListIsExpanded = createAction(
    'DASHBOARD_ACTIVITY_OVERDUE_LIST_IS_EXPANDED_TOGGLED'
);
export const updateActivityFilter = createAction('DASHBOARD_ACTIVITY_FILTER_UPDATED');
export const updateActivityTotalOverdueCount = createAction(
    'DASHBOARD_ACTIVITY_TOTAL_OVERDUE_COUNT_UPDATED'
);
export const clearActivityListError = createAction('DASHBOARD_ACTIVITY_LIST_ERROR_CLEARED');

export const updateActivityDataById = createAction('DASHBOARD_ACTIVITY_DATA_BY_ID_UPDATED');
export const updateActivityListScrolledToDate = createAction(
    'DASHBOARD_ACTIVITY_LIST_SCROLLED_TO_DATE_UPADTED'
);

/*------------------------------------------------------------------------------
Async Action Creators
------------------------------------------------------------------------------*/

type StateGetter = () => NutshellState;

export function fetchOverdueActivities() {
    return function(dispatch: Dispatch<*>, getState: StateGetter) {
        const state = getState();
        const filterObj = {...selectors.getDeparamedFilters(state)};
        // We don't need the full compound docs to satisfy our UI requirements, so this is faster
        const compoundDocumentFields = 'name,htmlUrl,avatarUrl,initials';
        const fields = {
            accounts: compoundDocumentFields,
            contacts: compoundDocumentFields,
            leads: compoundDocumentFields,
            users: compoundDocumentFields,
        };

        // Hack since our filters need to have Sentence case text, but our
        // query needs lowercase
        filterObj.status = 'overdue';

        const paramedQueries = paramQueries(filterObj, 100, fields);

        dispatch(requestOverdueActivities());
        api.get('activities', paramedQueries)
            .then((res) => res.json())
            .then(
                (json) => {
                    if (json.meta) {
                        dispatch(updateActivityTotalOverdueCount(json.meta.count));
                    }

                    const modelJson = jsonToModelPropArray(json.activities, json, 'activities');
                    dispatch(updateOverdueActivities(modelJson));
                },
                (err) => {
                    dispatch(failOverdueActivities(err.statusText));
                }
            );
    };
}

export function fetchActivitiesByDate(date: number) {
    return function(dispatch: Dispatch<*>, getState: StateGetter) {
        const state = getState();
        const filterObj = getActivityFiltersFromState(state, date);
        const paramedQueries = paramQueries(filterObj);

        return fetchAndUpdateActivities(dispatch, 'activities', paramedQueries);
    };
}

export function fetchMoreActivities() {
    return function(dispatch: Dispatch<*>, getState: StateGetter) {
        const state = getState();
        const nextActivityUrl = selectors.getActivityNextLink(state);

        // If we have a next activity url, forget generating filters, our filters
        // live within the nextUrl, so we'll just use that
        if (nextActivityUrl) {
            const nextBaseUrl = `activities${nextActivityUrl.split('/rest/activities')[1]}`;

            return fetchAndUpdateActivities(dispatch, nextBaseUrl);
        }

        const date = selectors.getDisplayDate(state);
        const filterObj = getActivityFiltersFromState(state, date);
        const paramedQueries = paramQueries(filterObj);

        return fetchAndUpdateActivities(dispatch, 'activities', paramedQueries);
    };
}

export function fetchActivitiesForMonth(date: number) {
    return function(dispatch: Dispatch<*>, getState: StateGetter) {
        const state = getState();
        const dateTimestamp = date || selectors.getDisplayDate(state);
        const sixWeekArray = getCalendarSixWeeks(moment(dateTimestamp));

        const filterObj = {...selectors.getDeparamedFilters(state)};

        filterObj.dateMin = sixWeekArray[0][0].format();
        filterObj.dateMax = sixWeekArray[5][6].format();

        const paramedQueries = paramQueries(filterObj, 1000, {activities: 'startTime'});

        dispatch(requestActivitiesForMonth());
        api.get('activities', paramedQueries)
            .then((res) => res.json())
            .then(
                (json) => {
                    const modelJson = jsonToModelPropArray(json.activities, json, 'activities');
                    dispatch(updateActivitiesForMonth(modelJson));
                },
                (err) => {
                    dispatch(failActivitiesForMonth(err.statusText));
                }
            );
    };
}

export function refetchActivity(id: string) {
    return function(dispatch: Dispatch<*>, getState: StateGetter) {
        api.get(`activities/${id}`)
            .then((res) => res.json())
            .then(
                (json) => {
                    const activity = jsonToModelProp(id, json);
                    dispatch(updateActivityDataById(activity));
                },
                () => {
                    // Ignore error for now
                }
            );

        // Refetch our overdue count in case this activity update changed that number
        fetchOverdueCount(dispatch, getState);
    };
}

/*------------------------------------------------------------------------------
Helper functions
------------------------------------------------------------------------------*/

function fetchOverdueCount(dispatch, getState) {
    const state = getState();
    const filterObj = {...selectors.getDeparamedFilters(state)};

    filterObj.status = 'overdue';

    const paramedQueries = paramQueries(filterObj, 1, {activities: 'startTime'});

    api.get('activities', paramedQueries)
        .then((res) => res.json())
        .then(
            (json) => {
                const meta = json.meta;
                if (meta) {
                    dispatch(updateActivityTotalOverdueCount(meta.count));
                }
            },
            () => {
                // Ignore error for now
            }
        );
}

/**
 *
 * @param {Object} filterObj Object that maps filter names to values. Filters which entities to request from the server.
 * @param {int} limit The number of entities to request from the server.
 * @param {Object|null} sparseFields An object that maps entity names to an array of fields to request. Only those
 *                                   fields will be included in the response from the server.
 * @returns {string} A stringified version of the parameters, ready to use in an ajax request.
 */
function paramQueries(filterObj, limit = 25, sparseFields = null) {
    return {
        filter: {...filterObj},
        limit: limit,
        fields: sparseFields || null,
    };
}

function fetchAndUpdateActivities(dispatch, url, paramedQueries) {
    dispatch(requestActivitiesForDay());

    return api
        .get(url, paramedQueries)
        .then((res) => res.json())
        .then(
            (json) => {
                const modelJson = jsonToModelPropArray(json.activities, json, 'activities');
                dispatch(
                    updateActivitiesForDay({
                        meta: json.meta,
                        activities: modelJson,
                    })
                );
            },
            (err) => {
                dispatch(failActivitiesForDay(err.statusText));
            }
        );
}

/**
 * Helper function to grab our activity filters from state and manually
 * manipulate them until they're useable for our query
 * @param  {object} state Our Redux app state
 * @param  {number} date  Date timestamp. This is a unique param because we
 * don't necessarily want to always use our state's displayDate value, thus
 * it needs to be passed in externally.
 * @return {object}       Filter object that we'll param and use as our query
 */
function getActivityFiltersFromState(state, date) {
    const dateObj = new Date(date);

    const filterObj = {...selectors.getDeparamedFilters(state)};
    if (!filterObj.participant) {
        filterObj.participant = Session.getSessionStateUserId(state);
    }

    filterObj.dateMin = moment(dateObj)
        .startOf('day')
        .format();

    return filterObj;
}
