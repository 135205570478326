/* @flow */
import {api} from '../api';

/**
 * Changes a leads stageset to the desired stagesetId.
 *
 * @param  {string} leadId      - The ID of the lead.
 * @param  {string} stagesetId  - Stageset ID to change to
 *
 * @return {Promise}    - Deferred object that should resolve to API response
 */
export function changeStageset(leadId: string, stagesetId: string) {
    return api
        .post(`leads/${leadId}/stageset`, {
            stageset: stagesetId,
        })
        .then((response) => response.json());
}
