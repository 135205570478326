import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Company from './icon-company.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Company {...validProps} />;
}
SvgComponent.iconName = 'company';

export const CompanyIcon = svgIconWrapper(SvgComponent);
