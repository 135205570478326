/* @flow */

import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';
import type {ReportRowData, TimeseriesReportData} from 'nutshell-core/types';
import type {CustomLeadStats, ForecastStats, ActivityStats, EmailStats} from './report-summary';
import type {LeadsReportKey} from './report-types';
import {activityReportReducer, type ActivityReportState} from './activity/activity-report-reducer';
import {emailReportReducer, type EmailReportState} from './activity/email-report-reducer';
import {FORECAST, ACTIVITY, EMAIL} from './report-constants';
import * as actions from './reports-actions';

type LeadsListState = {
    isLoading: boolean,
    isFirstLoad: boolean,
    errorMessage?: string,
    listItems: Object[], // TODO (@ianvs): define this type
    meta: Object, // TODO (@ianvs): define this type
};
const leadsListDefaultState = {
    isLoading: true,
    isFirstLoad: true,
    errorMessage: undefined,
    listItems: [],
    meta: {},
};

const defaultStat = {value: 0, formatted: '0', prefix: '$', suffix: ''};
const defaultCustomSummaryStats: CustomLeadStats = {
    value: {mean: defaultStat, sum: defaultStat},
    openedTime: {mean: defaultStat},
    winRate: {value: defaultStat},
};
const defaultForecastSummaryStats: ForecastStats = {
    sales: {total: defaultStat, count: defaultStat},
    projected: {total: defaultStat, count: defaultStat},
    pipeline: {total: defaultStat, count: defaultStat},
    quota: {total: defaultStat, count: defaultStat},
};
const defaultActivitySummaryStats: ActivityStats = {
    activities: {mean: defaultStat, sum: defaultStat},
    duration: {mean: defaultStat, sum: defaultStat},
};
const defaultEmailSummaryStats: EmailStats = {
    emails: {mean: defaultStat, sum: defaultStat},
};

type SummaryStatsState = {
    isLoading: boolean,
    stats: CustomLeadStats | ForecastStats | ActivityStats | EmailStats,
};
const summaryStatsDefaultState = {
    isLoading: true,
    stats: defaultCustomSummaryStats,
};

const isFilterDrawerOpen = createReducer(
    {
        [actions.toggleShowFilterDrawer]: (state) => !state,
    },
    false
);

export const leadsListReducer = createReducer(
    {
        // $FlowIgnore - this works correctly from redux-act
        [actions.requestReportLeadsListData]: (state) => {
            return {...state, isLoading: true, errorMessage: undefined};
        },
        [actions.updateReportLeadsListData]: (state, {meta, listItems}) => {
            return {
                ...state,
                isLoading: false,
                isFirstLoad: false,
                meta: meta,
                listItems: listItems,
                errorMessage: undefined,
            };
        },
        [actions.failReportLeadsListData]: (state, err) => {
            return {...state, isLoading: false, isFirstLoad: false, errorMessage: err};
        },
    },
    leadsListDefaultState
);

export const summaryStatsReducer = createReducer(
    {
        [actions.updateReportLeadsListSummaryData]: (state, {meta}) => {
            return {...state, isLoading: false, stats: meta.stats};
        },
        // $FlowIgnore - this works correctly from redux-act
        [actions.requestReportLeadsListData]: (state, {reportKey}) => {
            return reportKey !== FORECAST
                ? {...state, isLoading: true, stats: defaultCustomSummaryStats}
                : state;
        },
        // $FlowIgnore - this works correctly from redux-act
        [actions.requestLeadsReportData]: (state, {reportKey}) => {
            if (reportKey === FORECAST) {
                return {...state, isLoading: true, stats: defaultForecastSummaryStats};
            } else if (reportKey === ACTIVITY) {
                return {...state, isLoading: true, stats: defaultActivitySummaryStats};
            } else if (reportKey === EMAIL) {
                return {...state, isLoading: true, stats: defaultEmailSummaryStats};
            } else {
                return state;
            }
        },
        [actions.updateForecastSummaryStats]: (state, stats) => {
            return {...state, isLoading: false, stats};
        },
        [actions.updateActivitySummaryStats]: (state, {stats}) => {
            return {...state, isLoading: false, stats: stats};
        },
        [actions.updateEmailSummaryStats]: (state, {stats}) => {
            return {...state, isLoading: false, stats: stats};
        },
        [actions.updateAnalyticsSummaryStats]: (state, {stats}) => {
            return {...state, isLoading: false, stats: stats};
        },
    },
    summaryStatsDefaultState
);

type LeadsReportTableData = {
    rows: ?Array<ReportRowData>,
    totals: ?ReportRowData,
};
type LeadsReportState = {
    tableData: LeadsReportTableData,
    reportKey: ?LeadsReportKey,
    timeseriesChart: {
        isLoading: boolean,
        isTimedOut: boolean,
        isErrored: boolean,
        chartData: ?TimeseriesReportData,
        prefix: string,
    },
};
const leadsReportDefaultState = {
    tableData: {rows: null, totals: null},
    reportKey: null,
    timeseriesChart: {
        isLoading: true,
        isTimedOut: false,
        isErrored: false,
        chartData: null,
        prefix: '$',
    },
};

const leadsReport = createReducer(
    {
        [actions.updateLeadsReportTableData]: (state, payload: LeadsReportTableData) => {
            return {...state, tableData: payload};
        },
        // $FlowIgnore - this works correctly from redux-act
        [actions.requestLeadsReportData]: (state, {reportKey}) => {
            const newChartState = {
                ...state.timeseriesChart,
                isLoading: true,
                isTimedOut: false,
                isErrored: false,
            };
            // If we are on a new report, delete the existing data right away
            if (reportKey !== state.reportKey) {
                newChartState.chartData = null;
            }

            return {...state, reportKey, timeseriesChart: newChartState};
        },
        [actions.updateLeadsReportChartData]: (state, {chartData}) => {
            const newChartState = {
                ...state.timeseriesChart,
                isLoading: false,
                isTimedOut: false,
                isErrored: false,
                chartData,
            };

            return {...state, timeseriesChart: newChartState};
        },
        [actions.failLeadsReportChartData]: (state, err) => {
            if (err) {
                if (err.status === 504 || err.status === 502) {
                    const newChartState = {
                        ...state.timeseriesChart,
                        chartData: null,
                        isLoading: false,
                        isTimedOut: true,
                        isErrored: false,
                    };

                    return {...state, timeseriesChart: newChartState};
                } else {
                    const newChartState = {
                        ...state.timeseriesChart,
                        chartData: null,
                        isLoading: false,
                        isTimedOut: false,
                        isErrored: true,
                    };

                    return {...state, timeseriesChart: newChartState};
                }
            }

            return state;
        },
    },
    leadsReportDefaultState
);

type ReportMapsState = {
    [id: string]: {
        id: string,
        name: string,
        key: string,
        prefix: string,
    },
};
const reportMaps = createReducer(
    {
        [actions.updateReportMaps]: (state, payload) => payload,
    },
    {}
);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const reportsReducer = combineReducers({
    activity: activityReportReducer,
    email: emailReportReducer,
    summaryStats: summaryStatsReducer,
    isFilterDrawerOpen,
    leadsList: leadsListReducer,
    leadsReport,
    reportMaps,
});

export type ReportsState = {
    activity: ActivityReportState,
    email: EmailReportState,
    summaryStats: SummaryStatsState,
    isFilterDrawerOpen: boolean,
    leadsList: LeadsListState,
    leadsReport: LeadsReportState,
    reportMaps: ReportMapsState,
};
