import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Voicemail from './icon-voicemail.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Voicemail {...validProps} />;
}
SvgComponent.iconName = 'voicemail';

export const VoicemailIcon = svgIconWrapper(SvgComponent);
