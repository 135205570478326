/* @flow */

import * as React from 'react';
import './subheader.css';

type Props = {
    children?: React.Node,
    as: string,
};

Subheader.defaultProps = {
    as: 'span',
};

export function Subheader({children, as: Tag}: Props) {
    return <Tag styleName='header'>{children}</Tag>;
}
