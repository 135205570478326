/* @flow */

import {createAction} from 'redux-act';

import type {SMSReportDataRequestedAction} from '../types';

/*
 * Action creators
 */
export const requestReportData: (
    payload: $PropertyType<SMSReportDataRequestedAction, 'payload'>
) => SMSReportDataRequestedAction = createAction('SMS_REPORT_DATA_REQUESTED');
export const updateReportChartData = createAction('SMS_REPORT_CHART_DATA_UPDATED');
export const updateReportMaps = createAction('SMS_REPORT_MAPS_UPDATED');
export const failReportChartData = createAction('SMS_REPORT_CHART_DATA_FAILED');
