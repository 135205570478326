/* @flow */
import {Observable} from 'rxjs/Observable';
import type {ActionsObservable} from 'redux-observable';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';

import {transformDataForReport} from 'nutshell-core/json-to-report-data';

import {updateReportChartData, failReportChartData} from './connected-forms-actions';

import type {ReportDataRequestedAction} from './types';
import {getReportPostParams, type ConnectedFormReportPostParams} from './utils';

export const requestConnectedFormsReportDataEpic = (action$: ActionsObservable<*>) =>
    action$
        .ofType('CONNECTED_FORMS_REPORT_DATA_REQUESTED')
        .switchMap((action: ReportDataRequestedAction) => {
            const {reportParams, filters} = action.payload;
            const params = getReportPostParams(reportParams, filters);
            const requestStream = Observable.fromPromise(fetchData(params));
            const reportDataStream = requestStream.map(transformDataForReport);

            const reportChartDataStream = reportDataStream
                .map((data) => data.chartData)
                .filter((chartData) => Boolean(chartData))
                .map((chartData) => updateReportChartData({chartData}));

            return reportChartDataStream.catch((err) => Observable.of(failReportChartData(err)));
        });

function fetchData(params: ConnectedFormReportPostParams) {
    return Promise.all([fetchConnectedFormReportData(params)]);
}

function fetchConnectedFormReportData(params: ConnectedFormReportPostParams): Promise<any> {
    const url = '/rest/forms/report';
    const ajaxParams = {
        url,
        dataType: 'json',
        data: params,
    };

    return ($.ajax(ajaxParams): any); // This is how we have to convince flow that the response is a ReportsResponse
}
