/* @flow */

import {unexpectedCase} from 'nutshell-core/reducer-utils';
import {REHYDRATE} from 'redux-persist';

import type {McfxViqContactFragment as Contact} from 'nutshell-graphql-types';

import {ActionTypes, type SidebarAction} from './actions';

export type PeopleIqState = {
    accountId: ?string,
    accountName: ?string,
    leadId: ?string,
    contacts: Contact[],
    dismissedAccounts: string[],
};

export const defaultPeopleIqState = {
    accountId: undefined,
    accountName: undefined,
    leadId: undefined,
    contacts: [],
    dismissedAccounts: [],
};

export const peopleIqReducer = (
    state: PeopleIqState = defaultPeopleIqState,
    action: SidebarAction
): PeopleIqState => {
    switch (action.type) {
        case ActionTypes.SIDEBAR_OPENED:
            return {
                ...state,
                ...action.payload,
            };
        case ActionTypes.SIDEBAR_CLOSED:
            return defaultPeopleIqState;
        case ActionTypes.DISMISS_FOR_ACCOUNT:
            return {
                ...state,
                dismissedAccounts: [...state.dismissedAccounts, action.payload.accountId],
            };
        case REHYDRATE: {
            const persistedState = action.payload;
            if (!persistedState || !persistedState.ui || !persistedState.ui.peopleIq) {
                return state;
            }

            return persistedState.ui.peopleIq;
        }
        default:
            unexpectedCase(action.type);

            return state;
    }
};
