/* @flow */

import * as React from 'react';
import type {NotifyMessage} from 'nutshell-core/types';

import {ListView} from '../list-view';
import {NotifyComplete} from './notify-complete';
import {NotifyProcessing} from './notify-processing';

import './notifies-list.css';

type Props = {
    notifyMessages: NotifyMessage[],
    types: string,
    onAcknowledgeExport: (notify: NotifyMessage) => any,
    onAcknowledgeExportDownload: () => any,
};

export const NotifiesList = (props: Props) => {
    const {notifyMessages, types} = props;

    return (
        <div styleName='list'>
            <ListView
                header={<div styleName='header'>Your {types}</div>}
                collection={notifyMessages}
                renderRow={(notify: NotifyMessage) => {
                    const {status, name} = notify;

                    return status === 'complete' ? (
                        <NotifyComplete
                            key={name}
                            notify={notify}
                            onAcknowledgeExport={props.onAcknowledgeExport}
                            onAcknowledgeExportDownload={props.onAcknowledgeExportDownload}
                        />
                    ) : (
                        <NotifyProcessing
                            key={name}
                            notify={notify}
                            onAcknowledgeExport={props.onAcknowledgeExport}
                        />
                    );
                }}
            />
        </div>
    );
};
