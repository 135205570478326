/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SuccessMessage from './icon-success-message.svg';

type Props = {
    fill?: string,
};
function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SuccessMessage {...validProps} fill={props.fill} />;
}
SvgComponent.iconName = 'success-message';

export const SuccessMessageIcon = svgIconWrapper(SvgComponent);
