/* @flow */

import * as React from 'react';

import {Avatar} from 'shells/avatar';
import {colors} from 'shells/colors';

import './option-with-icon.css';

export type ListOptionWithIcon = {
    value: string,
    label: string,
    icon?: React.ComponentType<*>,
    avatarUrl?: string,
    initials?: string,
};

type Props = {
    label: string,
    icon?: React.ComponentType<*>,
    avatarUrl?: string,
    initials?: string,
};

/**
 * Option component for a GraphQL select object that contains
 * an icon and label for each item in the dropdown.
 */
export function OptionWithIcon(props: Props) {
    return (
        <div styleName='option'>
            <div styleName='icon'>{renderIcon(props.icon, props.avatarUrl, props.initials)}</div>
            <div styleName='label'>{props.label}</div>
        </div>
    );
}

const renderIcon = (icon, avatarUrl, initials) => {
    if (icon) {
        const Icon = icon;

        return <Icon size={20} fill={colors.orangeDk} />;
    }

    if (avatarUrl || initials) {
        return <Avatar size={20} type='users' avatarUrl={avatarUrl} initials={initials} />;
    }

    return null;
};
