/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import type {ActivityType} from 'nutshell-core/types';

import {GraphQLSelectActivityTypes} from '../../select/graphql-select-activity-types';

type Props = {
    ...FieldProps,
};

export function ActivityTypeSelect(props: Props) {
    return (
        <GraphQLSelectActivityTypes
            onChange={(activityType: ActivityType) => {
                props.input.onChange(activityType);
            }}
            selectedId={props.input.value ? props.input.value.id : undefined}
            labelKey='name'
            valueKey='id'
            autoFocus={false}
            includeManageOption={false}
        />
    );
}
