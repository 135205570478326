/* @flow */

import * as React from 'react';

import {CloudUploadIcon} from '../../icon';

import {isFileTypeAccepted} from './helpers';

import './file-upload-button.css';

type Props = {
    id: string,
    name: string,
    onChange?: (file: any) => void,
    // This is passed for custom on-click behavior (override browser default)
    onClick?: () => void,
    accept?: string,
    enableDropping?: boolean,
};

export function FileUploadButton(props: Props) {
    const [errorMessage, setErrorMessage] = React.useState('');

    // Wait until the element is rendered to add the event listener
    React.useLayoutEffect(() => {
        if (props.enableDropping) {
            const inputElement = document.querySelector("input[type='file']");

            if (inputElement) {
                inputElement.addEventListener('drop', handleDrop);
            }

            return () => {
                if (inputElement) {
                    inputElement.removeEventListener('drop', handleDrop);
                }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.enableDropping]);

    // prevent browser default behavior when dragging
    function handleDrag(event: SyntheticInputEvent<*>) {
        if (!props.enableDropping) {
            return;
        }

        if (errorMessage) {
            setErrorMessage('');
        }

        event.preventDefault();
        event.stopPropagation();
    }

    // triggers when file is dropped
    function handleDrop(event: any) {
        if (!props.enableDropping) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        if (
            event.dataTransfer &&
            event.dataTransfer.files &&
            event.dataTransfer.files[0] &&
            props.onChange
        ) {
            const droppedFile = event.dataTransfer.files[0];
            const isAcceptedFileType = isFileTypeAccepted(droppedFile, props.accept);

            const inputElement = document.querySelector("input[type='file']");
            if (inputElement && isAcceptedFileType) {
                const list = new DataTransfer();
                list.items.add(droppedFile);
                const fileList = list.files;

                // Set the new file as the value of the input element
                // $FlowIgnore the input element will have a files attrubute
                inputElement.files = fileList;
                // Trigger the change event to update the form value
                inputElement.dispatchEvent(new Event('change', {bubbles: true}));
            }

            // If the file is not an accepted file type, alert the user
            if (!isAcceptedFileType) {
                setErrorMessage('This file type is not supported.');
            }
        }
    }

    function handleUpload(event: SyntheticInputEvent<*>) {
        if (errorMessage) {
            setErrorMessage('');
        }

        if (props.onChange) {
            props.onChange(event.target.files[0]);
        }
    }

    function handleClick(event: SyntheticInputEvent<*>) {
        event.preventDefault();
        if (props.onClick) {
            props.onClick();
        }
    }

    return (
        <>
            <label htmlFor={props.id} styleName='file-upload-wrapper'>
                <input
                    accept={props.accept}
                    type='file'
                    id={props.id}
                    name={props.name}
                    onChange={handleUpload}
                    onClick={props.onClick ? handleClick : undefined}
                />
                <div
                    styleName='contents'
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                >
                    <CloudUploadIcon size={48} /> <span>Click here to upload a file</span>
                </div>
            </label>
            {errorMessage && <div styleName='error'>{errorMessage}</div>}
        </>
    );
}
