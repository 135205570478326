/* @flow */

/* keyCode is now deprecated - we should be using key instead,
BUT these consts won't work with key!
https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode */

export const LEFT = 37;
export const UP = 38;
export const RIGHT = 39;
export const DOWN = 40;
export const DEL = 46;
export const BACKSPACE = 8;
export const TAB = 9;
export const RETURN = 13;
export const ESC = 27;
export const PAGEUP = 33;
export const PAGEDOWN = 34;
export const SHIFT = 16;
export const SPACE = 32;
export const CTRL = 17;
export const ALT = 18;
export const A = 65;
export const F = 70;
export const S = 83;
export const O = 79;
