/* @flow */

import * as React from 'react';
import List from 'react-virtualized/dist/es/List';
import AutoSizer from 'react-virtualized/dist/es/AutoSizer';
import InfiniteLoader from 'react-virtualized/dist/es/InfiniteLoader';
import CellMeasurer, {CellMeasurerCache} from 'react-virtualized/dist/es/CellMeasurer';

import {ActivityErrorStateIcon, ActivityEmptyStateIcon} from '../icon';

import {SidebarSpecialState} from '../sidebar-special-state';
import {ListViewInfiniteLoading} from './list-view-infinite-loading';

import './list-view-infinite-activities.css';

const LOAD_THRESHOLD = 5;

export type Indices = {
    startIndex: number,
    stopIndex: number,
};

type RowRenderer = {
    index: number,
    parent: any,
    key: string,
    style: Object,
};

type LoadedRow = {
    index: number,
};

type Props = {
    isRequesting: boolean,
    width?: number,
    onFetchMoreRows: () => Promise<*>,
    hasNextPage: boolean,
    onClearListError: () => any,
    collection: Object[],
    hasError: boolean,
    emptyState: string | any,
    userHasCalendarSyncEnabled?: boolean,
    onRowsRenderedCallback?: (indices: Indices) => void,
    renderListRow: (index: number, onResize: () => void) => any,
};

/*
 * Component responible for rendering an "infinitely loading" list.
 *
 * This means that we'll render a list of items, and when the user
 * scrolls to the bottom, they'll see a loading spinner and we'll
 * automatically load in another page (if there are more items).
 */
export class ListViewInfiniteActivities extends React.PureComponent<Props> {
    list: List;
    onRowsRendered: (Indices) => void;
    cache: CellMeasurerCache;

    constructor() {
        super();

        this.cache = new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 62,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.collection.length !== this.props.collection.length) {
            this.clearHeightCacheAndRemeasure();
        }

        if (
            (this.props.hasError && !nextProps.hasError) ||
            (!this.props.hasNextPage && !nextProps.isRequesting && nextProps.hasNextPage)
        ) {
            this.fetchRows();
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.width !== this.props.width) {
            this.clearHeightCacheAndRemeasure();
        }
    }

    render() {
        // We add an additional row if there's a `nextPage`
        // and render a loading spinner for that row
        const rowCount = this.props.hasNextPage
            ? this.props.collection.length + 1
            : this.props.collection.length;

        return (
            <div styleName='list-view-infinite-scroll-container'>
                {this.props.hasError ? this.renderErrorOverlay() : undefined}
                <InfiniteLoader
                    isRowLoaded={this.isRowLoaded}
                    loadMoreRows={this.loadRows}
                    rowCount={rowCount}
                >
                    {({onRowsRendered}) => {
                        this.onRowsRendered = onRowsRendered;

                        return (
                            <AutoSizer onResize={this.handleResize}>
                                {({height, width}) => (
                                    <List
                                        ref={(c) => {
                                            this.list = c;
                                        }}
                                        width={width}
                                        height={height}
                                        rowCount={rowCount}
                                        deferredMeasurementCache={this.cache}
                                        rowHeight={this.cache.rowHeight}
                                        overscanRowCount={15}
                                        rowRenderer={this.renderRow}
                                        onRowsRendered={this.handleRowsRendered}
                                        noRowsRenderer={this.noRowsRenderer}
                                        collection={this.props.collection}
                                    />
                                )}
                            </AutoSizer>
                        );
                    }}
                </InfiniteLoader>
            </div>
        );
    }

    /**
     * Callback from react-virtualized to render
     * a specific row.
     *
     * We'll basically just render a component,
     * but we wrap it in a cell measurer so
     * that the height/width is correct.
     *
     * @param  {Object} row        - Row contents
     * @return {React$Component}     Row component
     */
    renderRow = (row: RowRenderer) => {
        return (
            <CellMeasurer
                cache={this.cache}
                columnIndex={0}
                key={row.key}
                rowIndex={row.index}
                parent={row.parent}
            >
                <div style={row.style}>
                    {this.renderRowContent(row, () => {
                        this.cache.clear(row.index, 0);
                        this.list.recomputeRowHeights();
                    })}
                </div>
            </CellMeasurer>
        );
    };

    /**
     * Helper function to render the contents of a list
     * row. Defers to the passed prop unless its
     * a loading row, in which case we load our default
     * loading row.
     *
     * @param  {Object} row        - Row contents
     * @param  {Function} onResize - Callback to calculate row resize
     * @return {React$Component}     Row content component
     */
    renderRowContent = (row: RowRenderer, onResize: () => void) => {
        return this.isRowLoaded(row) ? (
            this.props.renderListRow(row.index, onResize)
        ) : (
            <div className='pad-16'>
                <ListViewInfiniteLoading />
            </div>
        );
    };

    /**
     * Helper function to have CellMeasurer clear its height cache,
     * and then have List remeasure its rows.
     * @return {void}
     */
    clearHeightCacheAndRemeasure = () => {
        this.cache.clearAll();

        if (this.list) {
            this.list.recomputeRowHeights();
        }
    };

    /**
     * Callback _after_ a row has been rendered.
     *
     * This is usually used for container components to react to
     * content scrolled, and change up a header, or something.
     *
     * For example, in our activity list, we change the date header
     * to reflect the most recently scrolled rows.
     *
     * @param  {Object} indices      - start/end indices of scrolled rows
     * @return {void}
     */
    handleRowsRendered = (indices: Indices) => {
        if (this.props.onRowsRenderedCallback) {
            this.props.onRowsRenderedCallback(indices);
        }

        this.onRowsRendered(indices);
    };

    /**
     * Determines if a given row is loaded
     * @param  {number} row    Row to check if it is loaded
     * @return {boolean}       Has the row been loaded?
     */
    isRowLoaded = (row: LoadedRow) => {
        return !this.props.hasNextPage || row.index < this.props.collection.length;
    };

    /**
     * Called whenever the list has scrolled to a row that
     * needs to be loaded (determined by `isRowLoaded`)
     *
     * InfiniteLoader looks at a range of rows, and is designed to do "by index"
     * fetching, meaning it expects the server to load rows by index instead
     * of by page.
     *
     * Because we're doing "by page" fetching here, we have to tweak that
     * implementation to just check to see if we're past the threshold, and
     * then load that entire page, thus the "hacky" equality check here.
     *
     * @param  {Object} indices - start and end indices of the scrolled range
     * @return {Function}         Function to load more rows
     */
    loadRows = (indices: {stopIndex: number}) => {
        if (
            this.props.isRequesting ||
            this.props.hasError ||
            indices.stopIndex + LOAD_THRESHOLD <= this.props.collection.length
        ) {
            return;
        }

        return this.fetchRows();
    };

    /**
     * Helper to fetch more rows, and then resize the
     * list if necessary. Reused, and thus its own function
     *
     * @return {Function}     Function to fetch more rows
     */
    fetchRows = () => {
        return this.props.onFetchMoreRows().then(() => {
            this.handleResize();
        });
    };

    handleResize = () => {
        this.clearHeightCacheAndRemeasure();
    };

    renderErrorOverlay = () => {
        return (
            <div styleName='list-view-infinite-special-state-container'>
                <SidebarSpecialState
                    headerText='Whoops!'
                    subheaderText='That didn’t quite go to plan'
                    iconComponent={<ActivityErrorStateIcon size={70} />}
                    primaryCTA={{
                        ctaText: 'Try again',
                        onClick: this.props.onClearListError,
                        buttonVariant: 'secondary',
                    }}
                />
            </div>
        );
    };

    noRowsRenderer = () => {
        if (typeof this.props.emptyState === 'string') {
            const shouldShowSyncCta = !this.props.userHasCalendarSyncEnabled;

            return (
                <div styleName='list-view-infinite-special-state-container'>
                    <SidebarSpecialState
                        headerText={this.props.emptyState}
                        subheaderText='Stay on top of your relationships by scheduling and logging activities with your contacts.'
                        iconComponent={<ActivityEmptyStateIcon size={70} />}
                        primaryCTA={{
                            ctaText: 'Schedule an activity',
                            to: {
                                hash: '/create/activities',
                                search: location.search,
                                state: {modal: true},
                            },
                        }}
                        secondaryCTA={
                            shouldShowSyncCta
                                ? {
                                      ctaText: 'Connect your calendar',
                                      to: '/my-account/calendar',
                                  }
                                : undefined
                        }
                    />
                </div>
            );
        } else {
            return <div>{this.props.emptyState}</div>;
        }
    };
}
