import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Header from './icon-header.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Header {...validProps} />;
}
SvgComponent.iconName = 'header';

export const HeaderIcon = svgIconWrapper(SvgComponent);
