/* @flow */

import * as React from 'react';

import type {
    EventFragmentSparse,
    ChangeType,
    SessionUser,
    EventFragmentSparse_changes as Change,
} from 'nutshell-graphql-types';

import {
    TimelineBannerCompanyType,
    TimelineBannerEntityModified,
    TimelineBannerLeadPriority,
    TimelineBannerLeadStatus,
    TimelineBannerStageChange,
    TimelineBannerMailchimp,
    TimelineBannerCompanyDetailsAdded,
    TimelineBannerEntityMerged,
} from './';

const PRIORITY = 'priority';
const STATUS = 'status';
const STAGE = 'stage';
const LEAD_OPENED_STATUS = '0';

export function getContactBanner(input: {changeType: ChangeType, event: EventFragmentSparse}) {
    const {changeType, event} = input;

    if (changeType === 'MERGED') {
        return <TimelineBannerEntityMerged key={event.id} event={event} />;
    }

    if (changeType === 'MAILCHIMP') {
        return <TimelineBannerMailchimp key={event.id} event={event} />;
    }

    return <TimelineBannerEntityModified key={event.id} event={event} />;
}

export function getAccountBanner(input: {changeType: ChangeType, event: EventFragmentSparse}) {
    const {changeType, event} = input;

    if (changeType === 'ADDED' || changeType === 'DELETED' || changeType === 'UNDELETED') {
        return <TimelineBannerEntityModified key={event.id} event={event} />;
    }

    if (changeType === 'MERGED') {
        return <TimelineBannerEntityMerged key={event.id} event={event} />;
    }

    if (changeType === 'ENRICHMENT_ACCEPTED') {
        return <TimelineBannerCompanyDetailsAdded key={event.id} event={event} />;
    }

    return <TimelineBannerCompanyType key={event.id} event={event} />;
}

export function getLeadBanner(input: {
    changeType: ChangeType,
    event: EventFragmentSparse,
    currentUser: SessionUser,
}) {
    const {changeType, event, currentUser} = input;

    // Possible lead changes that we display in the timeline
    const leadChange: ?Change =
        event.changes.find((change) => change.attribute === STATUS) ||
        event.changes.find((change) => change.attribute === PRIORITY) ||
        event.changes.find((change) => change.attribute === STAGE);

    if (!leadChange || !leadChange.newValue) {
        // When a lead is opened, there is no change type
        if (changeType === 'ADDED') {
            return (
                <TimelineBannerLeadStatus
                    key={event.id}
                    event={event}
                    currentUser={currentUser}
                    newStatus={LEAD_OPENED_STATUS}
                />
            );
        }

        if (changeType === 'MERGED') {
            return <TimelineBannerEntityMerged key={event.id} event={event} />;
        }

        return null;
    }

    switch (changeType) {
        case 'EDITED':
            if (leadChange.attribute === PRIORITY) {
                return (
                    <TimelineBannerLeadPriority
                        key={event.id}
                        event={event}
                        newPriority={leadChange.newValue}
                    />
                );
            }

            return (
                <TimelineBannerLeadStatus
                    key={event.id}
                    event={event}
                    currentUser={currentUser}
                    newStatus={leadChange.newValue}
                />
            );
        case 'STAGE_CHANGED':
            return (
                <TimelineBannerStageChange
                    key={event.id}
                    event={event}
                    newStage={JSON.parse(leadChange.newValue).name}
                />
            );
        default:
            // Should not ever happen
            return null;
    }
}
