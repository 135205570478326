import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import HtmlUnderline from './icon-html-underline.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <HtmlUnderline {...validProps} />;
}
SvgComponent.iconName = 'html-underline';

export const HtmlUnderlineIcon = svgIconWrapper(SvgComponent);
