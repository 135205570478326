import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 48 48.1'}>
            <path d='M40.4 0H7.6C3.4 0 0 3.2 0 7.2v33.7c0 4 3.4 7.2 7.6 7.2h32.8c4.2 0 7.6-3.2 7.6-7.2V7.2c0-4-3.4-7.2-7.6-7.2zm-.8 41H8.2c-.6-.1-1.2-.7-1.2-1.3V13.4c0-.8.6-1.4 1.3-1.4h31.4c.7 0 1.2.6 1.2 1.3v26.3c.1.8-.5 1.4-1.3 1.4z' />
            <rect x='11' y='29.4' width='25' height='5.5' />
        </svg>
    );
}
SvgComponent.iconName = 'activity-minimize';

export const ActivityMinimizeIcon = svgIconWrapper(SvgComponent);
