/* @flow */
import * as React from 'react';

import {ChevronLeftIcon} from '../../icon';

import './back-button.css';

type Props = {
    backText: string,
    as: string | React.ComponentType<*>,
    getRef?: any,
    to?: string
};

export function BackButton(props: Props) {
    const {as = 'button', backText = 'Back', getRef, ...restProps} = props;

    const Component = as;
    const htmlProps = Component === 'button' ? {type: 'button', ...restProps} : restProps;

    return (
        <Component {...htmlProps} styleName='container' ref={getRef}>
            <ChevronLeftIcon /> &nbsp;{backText}
        </Component>
    );

}
