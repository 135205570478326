/* @flow */

import * as React from 'react';

import type {EmailFragmentSparse as Email, SessionUser} from 'nutshell-graphql-types';

import {
    DownloadIcon,
    EmailIcon,
    HtmlLinkIcon,
    EyeIcon,
    ReplyAllIcon,
    ReplyIcon,
    TrashIcon,
} from 'shells/icon';
import {TimelineEntryActionMenu} from 'shells/timeline/entry/toolbar/timeline-entry-action-menu';

import {DeleteEmailPopover} from '../core/email/delete-email-popover';
import {AddBlocklistedEmailConfirmation} from '../core/email/add-blocklisted-email-confirmation';
import {useUpdateEmailBlocklist} from '../core/email/graphql/use-update-email-blocklist';
import {addNotification} from '../../notifications/index';
import {appHistory} from '../../history';
import {getEntryTypeFromPayload} from './helpers';

type Props = {
    changeLogId: string,
    email: Email,
    onDeleteEmail: (emailId: string, allByAddress?: ?string) => any,
    onUpdateEmail: (string, boolean) => Promise<*>,
    onRetryFailedEmail: (string) => Promise<*>,
    isViewingAttachments: boolean,
    setIsViewingAttachments: (boolean) => void,
    onReply: () => void,
    onReplyAll: () => void,
    currentUser: SessionUser,
};

export function EmailEventActionMenu(props: Props) {
    const {
        changeLogId,
        email,
        onDeleteEmail,
        onUpdateEmail,
        onRetryFailedEmail,
        isViewingAttachments,
        setIsViewingAttachments,
        onReply,
        onReplyAll,
        currentUser,
    } = props;
    const [selectedAddress, setSelectedAddress] = React.useState<?string>(null);
    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState<boolean>(false);
    const [isConfirmingAddToBlocklist, setIsConfirmingAddToBlocklist] = React.useState<boolean>(
        false
    );
    const buttonRef = React.useRef(null);

    const isReplyable = !email.isFailed;
    const isShareable = email.isShareable;
    const isShared = email.isShared;
    const hasAttachments = email.attachments.length;
    const isFailed = email.isFailed;

    const {onBlocklistEmail} = useUpdateEmailBlocklist();

    const actionMenuOptions = [];
    if (isReplyable) {
        actionMenuOptions.push({
            label: 'Reply',
            icon: <ReplyIcon size={15} wrapWithDiv={false} />,
            handleSelect: onReply,
        });
    }
    if (isReplyable && email.recipients.length > 1) {
        actionMenuOptions.push({
            label: 'Reply all',
            icon: <ReplyAllIcon size={15} wrapWithDiv={false} />,
            handleSelect: onReplyAll,
        });
    }
    actionMenuOptions.push({
        label: 'Copy link',
        icon: <HtmlLinkIcon size={15} wrapWithDiv={false} />,
        handleSelect: () => {
            navigator.clipboard.writeText(email.htmlUrl);
        },
    });
    if (isShareable && !isShared) {
        actionMenuOptions.push({
            label: 'Share with everyone',
            icon: <EyeIcon size={15} wrapWithDiv={false} />,
            handleSelect: () => onUpdateEmail(email.id, true),
        });
    }
    if (isShareable && isShared) {
        actionMenuOptions.push({
            label: 'Hide',
            icon: <EyeIcon size={15} wrapWithDiv={false} />,
            handleSelect: () => onUpdateEmail(email.id, false),
        });
    }
    if (hasAttachments) {
        actionMenuOptions.push({
            label: 'Download attachments',
            icon: <DownloadIcon size={15} wrapWithDiv={false} />,
            handleSelect: () => setIsViewingAttachments(true),
        });
    }
    if (isFailed) {
        actionMenuOptions.push({
            label: 'Retry sending',
            icon: <EmailIcon size={15} wrapWithDiv={false} />,
            handleSelect: () => onRetryFailedEmail(email.id),
            variant: 'destructive',
        });
    }

    actionMenuOptions.push({
        label: 'Delete',
        icon: <TrashIcon size={15} wrapWithDiv={false} />,
        handleSelect: () => setIsConfirmingDelete(true),
        variant: 'destructive',
    });

    return (
        <>
            <TimelineEntryActionMenu
                id={changeLogId}
                entryType={getEntryTypeFromPayload(email)}
                attachmentsToView={isViewingAttachments ? email.attachments : undefined}
                setIsViewingAttachments={setIsViewingAttachments}
                customActionMenuOptions={actionMenuOptions}
                getButtonRef={buttonRef}
            />
            {isConfirmingDelete ? (
                <DeleteEmailPopover
                    anchor={buttonRef.current}
                    email={email}
                    currentUser={currentUser}
                    onCancel={() => {
                        setIsConfirmingDelete(false);
                    }}
                    onDeleteEmail={onDeleteEmail}
                    setSelectedAddress={setSelectedAddress}
                    selectedAddress={selectedAddress}
                    setIsConfirmingAddToBlocklist={setIsConfirmingAddToBlocklist}
                />
            ) : (
                undefined
            )}
            {isConfirmingAddToBlocklist && selectedAddress ? (
                <AddBlocklistedEmailConfirmation
                    email={email}
                    onCancel={() => {
                        setIsConfirmingDelete(false);
                    }}
                    onDeleteEmail={onDeleteEmail}
                    onBlocklistEmail={(address: string) =>
                        onBlocklistEmail({address}).then(() => {
                            addNotification('Add to blocklist', {
                                body: `${address} has been added to the company blocklist.`,
                                onClick: () => appHistory.push(`/setup/email-security`),
                            });
                        })
                    }
                    selectedAddress={selectedAddress}
                />
            ) : (
                undefined
            )}
        </>
    );
}
