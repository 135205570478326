/* @flow */

import type {
    ComparisonRangeType,
    FilterObject,
    ReportFacet,
    ReportGap,
    ReportMap,
    SortDirection,
} from 'nutshell-core/types';

export type {ReportFacet, ReportGap} from 'nutshell-core/types';

export type ReportSegment =
    | 'assignees'
    | 'stages'
    | 'products'
    | 'outcomes'
    | 'sources'
    | 'tags'
    | 'competitors'
    | 'territories'
    | 'age'
    | 'value'
    | 'channels'
    | 'createdTime'
    | 'closedTime'
    | 'activityType'
    | 'subscriptionStatus'
    | 'interactionType';

export type DateRangeType = 'createdTime' | 'closedTime' | 'period';

type Losses = 'losses';
type NewLeads = 'newleads';
type Sales = 'sales';
type Forecast = 'forecast';
type Attribution = 'attribution';
type Funnel = 'funnel';
type Custom = 'custom';
type Activity = 'activity';
type Email = 'email';
type AudienceMembership = 'audienceMembership';
type EditionEngagement = 'editionEngagement';
type DripEditionEngagement = 'dripEditionEngagement';
type FormEngagement = 'formEngagement';
type SiteAnalytics = 'siteAnalytics';
type McfxCompanies = 'mcfxcompanies';
type Scheduler = 'scheduler';
type Competitors = 'competitors';
type Dashboard = 'dashboard';
type LandingPages = 'landingPages';

export type LeadsReportKey = Losses | NewLeads | Sales | Forecast | Attribution | Custom;
export type FunnelReportKey = Funnel;
export type ReportKey =
    | LeadsReportKey
    | FunnelReportKey
    | Activity
    | Email
    | AudienceMembership
    | EditionEngagement
    | DripEditionEngagement
    | FormEngagement
    | SiteAnalytics
    | McfxCompanies
    | Scheduler
    | Competitors
    | Dashboard
    | LandingPages;

/* Types used by PHP to define which type of report is being saved */
type ReportActivitySavedType = 'report_activity';
type ReportEmailSavedType = 'report_email';
type ReportForecastSavedType = 'report_forecast';
type ReportAttributionSavedType = 'report_attribution';
type ReportFunnelSavedType = 'report_funnel';
type ReportLossesSavedType = 'report_losses';
type ReportNewLeadsSavedType = 'report_newleads';
type ReportSalesSavedType = 'report_sales';
type ReportCustomSavedType = 'report_custom';

export type ReportSavedTypeKey =
    | ReportActivitySavedType
    | ReportEmailSavedType
    | ReportForecastSavedType
    | ReportAttributionSavedType
    | ReportFunnelSavedType
    | ReportLossesSavedType
    | ReportNewLeadsSavedType
    | ReportSalesSavedType
    | ReportCustomSavedType;

export const LOSSES_SAVED_TYPE = 'report_losses';
export const NEW_LEADS_SAVED_TYPE = 'report_newleads';
export const SALES_SAVED_TYPE = 'report_sales';
export const FORECAST_SAVED_TYPE = 'report_forecast';
export const ATTRIBUTION_SAVED_TYPE = 'report_attribution';
export const FUNNEL_SAVED_TYPE = 'report_funnel';
export const CUSTOM_SAVED_TYPE = 'report_custom';
export const ACTIVITY_SAVED_TYPE = 'report_activity';
export const EMAIL_SAVED_TYPE = 'report_email';
/* End PHP saved report types */

export const LOSSES = 'losses';
export const NEW_LEADS = 'newleads';
export const SALES = 'sales';
export const FORECAST = 'forecast';
export const FUNNEL = 'funnel';
export const CUSTOM = 'custom';
export const ACTIVITY = 'activity';
export const EMAIL = 'email';
export const AUDIENCE_MEMBERSHIP = 'audienceMembership';
export const EDITION_ENGAGEMENT = 'editionEngagement';
export const DRIP_EDITION_ENGAGEMENT = 'dripEditionEngagement';
export const FORM_ENGAGEMENT = 'formEngagement';
export const SITE_ANALYTICS = 'siteAnalytics';
export const ATTRIBUTION = 'attribution';
export const MCFXCOMPANIES = 'mcfxcompanies';
export const SCHEDULER = 'scheduler';
export const COMPETITORS = 'competitors';
export const DASHBOARD = 'dashboard';
export const LANDING_PAGES = 'landingPages';

export type ActivityReportPjaxResponseType = {
    success: boolean,
    isExportable: boolean,
    table: string,
    numPages: number,
};

type LeadReportTab = 'details' | 'leads';

// URL match types come directly from what we tell ReactRouter to put into
// their url 'match' object. Therefore, we can use exact object typing here.
export type LeadReportUrlMatch = {
    params: {|
        report: ReportKey,
        tab: LeadReportTab,
    |},
};

type LeadListExportKey = 'leadlist';
type ReportDataExportKey = 'reportdata';
type ReportChartExportKey = 'reportchart';
type ReportActivityDetailsExportKey = 'activitydetails';
type ReportActivityOverviewExportKey = 'activityoverview';

export type ExportKey =
    | LeadListExportKey
    | ReportDataExportKey
    | ReportChartExportKey
    | ReportActivityDetailsExportKey
    | ReportActivityOverviewExportKey;

export const LEAD_LIST_EXPORT_KEY = 'leadlist';
export const REPORT_DATA_EXPORT_KEY = 'reportdata';
export const REPORT_CHART_EXPORT_KEY = 'reportchart';
export const REPORT_ACTIVITY_DETAILS_EXPORT_KEY = 'activitydetails';
export const REPORT_ACTIVITY_OVERVIEW_EXPORT_KEY = 'activityoverview';

export const QUOTA_NAME = 'Quota';
export const QUOTA_REPORT_KEY = 'quota';

type ReportId = string;

export type ChartReportParams = {
    id?: ReportId,
    gap: ReportGap,
    facet: ReportFacet,
    dateRangeType: DateRangeType,
    reportType?: string,
    segment?: ReportSegment,
    useConfidence?: string,
    compareTo?: ComparisonRangeType,
    groupByField?: ReportSegment,
};

export type ChartListParams<T> = {
    page: string,
    sort: string,
    fields: {|
        [key: T]: string,
    |},
};

export type SortParams = {
    by: string,
    direction: SortDirection,
};

export type VersionParam = 'a' | 'b' | 'both';

export type ReportURLParams<T> = {
    filter: FilterObject,
    report: ChartReportParams,
    list?: ChartListParams<T>,
    page?: string,
    sort?: SortParams,
    q?: string,
    version?: VersionParam,
};

export type ODRReportData = {
    chartData: Array<*>,
    exportLink?: string,
};

export type ReportInfo = {
    reports: Array<ReportMap>,
    referenceReports?: Array<ReportMap>,
};
