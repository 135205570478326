import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SidebarIconDashboard from './sidebar-icon-dashboard.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SidebarIconDashboard {...validProps} />;
}
SvgComponent.iconName = 'sidebarIconDashboard';

export const SidebarIconDashboardIcon = svgIconWrapper(SvgComponent);
