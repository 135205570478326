/* @flow */

import type {ReportGap, FilterObject} from 'nutshell-core/types';
import {serializeFilters} from 'nutshell-core';

export type ChartParams = {
    gap: ReportGap,
};

export type ReportPostParams = {
    filter: Object,
    gap: ReportGap,
};

export function getReportPostParams(
    reportParams: ChartParams,
    filters: FilterObject[]
): ReportPostParams {
    const {gap} = reportParams;

    return {
        filter: serializeFilters(filters),
        gap,
    };
}
