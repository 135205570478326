/* @flow */

import * as React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Flame from './flame.svg';

function SvgComponent(props: any) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Flame {...validProps} />;
}
SvgComponent.iconName = 'flame';

export const FlameIcon = svgIconWrapper(SvgComponent);
