/* @flow */

import {combineReducers} from 'redux';
import type {Lead, LeadId, ListItem} from '../types';
import type {ListSchemaResponse} from '../schema/schema-types';
import {reduceListItemsByEntityId} from '../list-items/list-items-utils';
import {addItemsToByIdState} from '../reducer-utils';
import {jsonToModelPropArray} from '../json-to-model-prop-array';
import {ActionTypes as VisualPipelineActionTypes} from '../visual-pipeline/visual-pipeline-constants';
import type {VisualPipelineAction} from '../visual-pipeline/visual-pipeline-types';
import type {LeadsAction} from './leads-types';
import {ActionTypes} from './leads-constants';

type SchemaState = ?ListSchemaResponse;
const schemaReducer = (state: SchemaState = null, action: LeadsAction): SchemaState => {
    switch (action.type) {
        case ActionTypes.LEADS_SCHEMA_UPDATED:
            return action.payload;
        default:
            return state;
    }
};

type ByIdState = {[LeadId]: Lead};
const byIdReducer = (state: ByIdState = {}, action: LeadsAction): ByIdState => {
    switch (action.type) {
        case ActionTypes.LEADS_FETCH_BY_ID_SUCCESS:
        case ActionTypes.LEADS_UPDATED: {
            const leads = jsonToModelPropArray(action.payload.leads, action.payload, 'leads');

            return addItemsToByIdState(leads, state);
        }
        default:
            return state;
    }
};

/*
 * `listItems`
 */
type ListItemsState = {
    [id: LeadId]: ListItem,
};
const listItemsByIdReducer = (
    state: ListItemsState = {},
    action: LeadsAction | VisualPipelineAction
): ListItemsState => {
    switch (action.type) {
        case ActionTypes.LEADS_FETCH_LIST_FOR_CONTACT_SUCCESS: {
            const listItems = action.payload.response.listItems;

            return reduceListItemsByEntityId({...state}, listItems);
        }
        case ActionTypes.LEADS_FETCH_LIST_SUCCESS: {
            return reduceListItemsByEntityId({...state}, action.payload.listItems);
        }
        case VisualPipelineActionTypes.VISUAL_PIPELINE_LIST_ITEMS_REPLACED_FOR_STAGE:
        case VisualPipelineActionTypes.VISUAL_PIPELINE_LIST_ITEMS_ADDED_FOR_STAGE: {
            return reduceListItemsByEntityId({...state}, action.payload.response.listItems);
        }
        default:
            return state;
    }
};

export type State = {
    byId: ByIdState,
    schema: SchemaState,
    listItemsById: ListItemsState,
};

// $FlowFixMe upgrading Flow to v0.92.1
export const reducer = combineReducers({
    byId: byIdReducer,
    schema: schemaReducer,
    listItemsById: listItemsByIdReducer,
});
