/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import './scroll-shadow.css';

type Props = {
    isActive: boolean,
    orientation: 'top' | 'bottom',
};

export function ScrollShadow(props: Props) {
    const styleNames = classnames({
        'scroll-shadow-container--top': props.orientation === 'top',
        'scroll-shadow-container--bottom': props.orientation === 'bottom',
        'scroll-shadow-container--active': props.isActive,
    });

    return (
        <div styleName={styleNames}>
            <div styleName='shadow' />
            <div styleName='shadow-blocker' />
        </div>
    );
}
