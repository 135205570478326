/* @flow */

import {tokenize, find as findLinks} from 'linkifyjs';
import QuillDelta from 'quill-delta';
import whitespaceRegex from 'whitespace-regex';

type Op = {
    // only one property out of {insert, delete, retain} will be present
    insert?: string | Object,
    delete?: number,
    retain?: number,

    attributes?: Object,
};

// Adapted from https://github.com/quilljs/delta/blob/10e5cb7ecb4bb0325dba3858fffb818da4857a39/src/Delta.ts#L9
type Delta = {
    ops: Op[],
    diff: (other: Delta, cursor?: number) => Delta,
    slice: (start?: number, end?: number) => Delta,
};

type Source = 'api' | 'user' | 'silent';

// Adapted from https://github.com/DefinitelyTyped/DefinitelyTyped/blob/124fddf61c04a77de864a6ec3a0f07cbd27f81a8/types/quill/index.d.ts#L98
type Quill = {
    getContents: (index?: number, length?: number) => Delta,
    updateContents: (delta: Delta, source?: Source) => Delta,
};

/*
 * Convert a string into an array of quill Delta operations, making links as needed
 */
function linkifyForDelta(text) {
    return tokenize(text).map((token) =>
        token.isLink
            ? {insert: token.toString(), attributes: {link: token.toHref('http')}}
            : {insert: token.toString()}
    );
}

export function makeTypedTextLinkifier(quill: Quill) {
    return function(delta: Delta, oldDelta: Delta, source: string) {
        if (source !== 'user') return;
        if (
            delta.ops.length === 2 &&
            delta.ops[0].retain &&
            whitespaceRegex().test(delta.ops[1].insert)
        ) {
            // We don't care about the whole editor, just up to the point we were typing in
            const endRetain = delta.ops[0].retain;
            const contents = quill.getContents().slice(0, endRetain);
            const lastOp = contents.ops[contents.ops.length - 1];
            const lastInsert = lastOp.insert;

            // If the lastInsert was a mention blot don't try
            // and insert any links
            if (lastInsert && lastInsert.mention) {
                return;
            }

            // If we can't find any links, don't do anything
            if (!findLinks(lastInsert).length) return;

            // Using the text of the final insert operation, make link operations.
            // Since we're using the last of the original ops, any existing links will
            // be left alone, which is important because the text of those links might
            // not look like links, and would otherwise be lost.
            const linkifiedOps = linkifyForDelta(lastInsert);

            // Replace the old final op with our new linkifiedOps
            const newOps = [...contents.ops.slice(0, contents.ops.length - 1), ...linkifiedOps];
            // Find the difference between the existing content, and our new, merged diff
            // with added links.
            const ops = contents.diff(new QuillDelta(newOps)).ops;
            quill.updateContents(new QuillDelta(ops));
        }
    };
}

export function linkifyPastedText(node: CharacterData, delta: Delta) {
    // Ensure we're dealing with strings
    if (typeof node.data !== 'string') return delta;

    // If we can't find any links, don't do anything
    if (!findLinks(node.data).length) return delta;

    delta.ops = linkifyForDelta(node.data);

    return delta;
}
