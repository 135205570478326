import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SidebarIconAccounts from './sidebar-icon-accounts.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SidebarIconAccounts {...validProps} />;
}
SvgComponent.iconName = 'sidebarIconAccounts';

export const SidebarIconAccountsIcon = svgIconWrapper(SvgComponent);
