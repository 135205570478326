import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import LeadHot from './lead-hot.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <LeadHot {...validProps} />;
}
SvgComponent.iconName = 'leadHot';

export const LeadHotIcon = svgIconWrapper(SvgComponent);
