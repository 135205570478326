/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import CalendarX from './icon-calendar-x.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <CalendarX {...validProps} />;
}
SvgComponent.iconName = 'calendar-x';

export const CalendarXIcon = svgIconWrapper(SvgComponent);
