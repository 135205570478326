/* @flow */

import * as React from 'react';
import {CheckboxGroupContext} from './checkbox-group';

import './checkbox-button.css';

type Props = {
    disabled?: boolean,
    value: string,
    children?: React.Node,
};

export class CheckboxButton extends React.Component<Props> {
    render() {
        return (
            <CheckboxGroupContext.Consumer>
                {({name, selectedValues, onChange}) => {
                    const checked = selectedValues.includes(this.props.value);
                    const checkboxId = `${name}-${this.props.value}`;

                    return (
                        <div styleName='input-button'>
                            <input
                                id={checkboxId}
                                name={name}
                                type='checkbox'
                                role='checkbox'
                                checked={checked}
                                aria-checked={checked}
                                disabled={this.props.disabled}
                                onChange={(e) => this.handleChange(e, selectedValues, onChange)}
                            />
                            <label htmlFor={checkboxId}>{this.props.children}</label>
                        </div>
                    );
                }}
            </CheckboxGroupContext.Consumer>
        );
    }

    handleChange = (e: SyntheticInputEvent<*>, previousValues: string[], onChange: Function) => {
        e.stopPropagation();

        if (e.target.checked) {
            onChange(previousValues.concat([this.props.value]));
        } else {
            onChange(previousValues.filter((item) => item !== this.props.value));
        }
    };
}
