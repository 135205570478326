/* @flow */

import * as React from 'react';
import ReactDOM from 'react-dom';

import './modal-lightbox.css';

type Props = {
    children: React.Node,
    onClose: () => void,
};

export class ModalLightbox extends React.Component<Props> {
    el: HTMLElement;

    constructor(props: Props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        const modalRoot = document.getElementById('modal-root');
        if (modalRoot) {
            modalRoot.appendChild(this.el);
        }
        document.addEventListener('keydown', this.handleKeyDown);

        const body = document.querySelector('body');
        if (body) {
            body.style.overflow = 'hidden';
        }
    }

    componentWillUnmount() {
        const modalRoot = document.getElementById('modal-root');
        if (modalRoot) {
            modalRoot.removeChild(this.el);
        }
        document.removeEventListener('keydown', this.handleKeyDown);

        const body = document.querySelector('body');
        if (body) {
            body.style.overflow = '';
        }
    }

    handleKeyDown: KeyboardEventListener = (e) => {
        // Not using Nut.keys here since it isn't in scope within shells
        // keyCode 27 = Esc
        if (e.keyCode === 27 && !e.defaultPrevented) this.props.onClose();
    };

    render() {
        return ReactDOM.createPortal(
            <div styleName='modal'>
                <div styleName='body'>{this.props.children}</div>
                <div styleName='underlay' onClick={this.props.onClose} />
            </div>,
            this.el
        );
    }
}
