import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import VirtualMeeting from './icon-virtual-meeting.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <VirtualMeeting {...validProps} />;
}
SvgComponent.iconName = 'meetingVirtual';

export const VirtualMeetingIcon = svgIconWrapper(SvgComponent);
