import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Meeting from './icon-meeting.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Meeting {...validProps} />;
}
SvgComponent.iconName = 'meeting';

export const MeetingIcon = svgIconWrapper(SvgComponent);
