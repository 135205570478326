import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ThumbsDown from './icon-thumbs-down.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ThumbsDown {...validProps} />;
}
SvgComponent.iconName = 'thumbs-down';

export const ThumbsDownIcon = svgIconWrapper(SvgComponent);
