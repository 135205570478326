import React from 'react';
import {Device} from 'twilio-client';

import {analyticsClient} from '../../analytics-client';

const TWILIO_CONFIG = {closeProtection: true};

const C2C_KB_ARTICLE =
    'https://support.nutshell.com/en/articles/8428904-click-to-call-call-contacts-from-your-browser-nutshell-pro';

export class Phone {
    constructor() {
        this.twilioDevice = new Device();
    }

    /**
     * Listen for events from the Twilio Device. The listener needs to implement onReady and
     * onIncomingCall methods to properly hadnle the events.
     *
     * @param {Object} callListener - The listener to be registered
     *
     * @returns {jquery.Deferred} - A promise that will resolve to the phonecall object when it is
     * received
     */
    listen = (callListener) => {
        analyticsClient.track(analyticsClient.EVENTS.TRACK_C2C_CALL_ATTEMPTED);
        this.twilioDevice.on('ready', (device) => callListener.onReady(device));
        this.twilioDevice.on('incoming', (connection) => callListener.onIncomingCall(connection));

        return $.ajax({
            type: 'POST',
            dataType: 'json',
            url: '/rest/phonecalls',
            data: {data: {foo: 'bar'}}, // FIXME: This content of body isn't actually important, but call fails at the moment without one
        })
            .then((response) => {
                const phoneCall = response.phonecalls[0]; // Only ever expect one phone call to be returned
                // TWILIO_CONFIG.debug = true; // someday this may help somebody.
                // I also like: `window.twilioTesting = this.twilioDevice` for easy introspection.
                try {
                    this.twilioDevice.setup(phoneCall.token, TWILIO_CONFIG);
                } catch (e) {
                    Nut.error('Twilio error', JSON.stringify(e));
                    phoneCall.error = (
                        <span>
                            Something went wrong. <a href={`${C2C_KB_ARTICLE}#faq`}>Learn more</a>
                        </span>
                    );
                    this.twilioDevice.destroy();
                }

                return phoneCall;
            })
            .fail((e) => {
                Nut.error('Phone call REST error', JSON.stringify(e));

                // TODO: return something useful, like "Too many calls in trial" or whatever.
                return {error: 'Something went wrong'};
            });
    };

    /**
     * Stop listening to Device events. This will also destroy the device for
     * proper cleanup.
     *
     * @returns {undefined}
     */
    stopListening = () => {
        this.twilioDevice.disconnectAll();
        this.twilioDevice.destroy();
    };
}
