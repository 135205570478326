/* @flow */

import {createReducer} from 'redux-act';
import * as ramda from 'ramda';

import type {SavedFilter, SavedReportType, SavedListType, SavedFilterId} from '../types';
import {indexById} from '../utils/index-by-id';

import * as actions from './saved-filters-actions';
import {isSavedReport, isSavedList} from './saved-filters-utils';

export type SavedFiltersModuleState = {
    savedFilters: SavedFiltersState,
};

export type SavedFiltersState = {
    items: {[id: SavedFilterId]: SavedFilter<*>},
    isLoading: boolean,
    isSavingNew: boolean,
    isSavingSharePreferences: boolean,
    isResaving: boolean,
    loadingErrorMessage?: string,
    actionErrorMessage?: string,
};

const defaultState = {
    items: {},
    isLoading: true,
    isSavingNew: false,
    isSavingSharePreferences: false,
    isResaving: false,
    loadingErrorMessage: undefined,
    actionErrorMessage: undefined,
};

type Reducer = (?SavedFiltersState, Object) => SavedFiltersState;

export const reducer: Reducer = createReducer(
    {
        [actions.requestSavedReports]: (state) => {
            return {...state, isLoading: true, loadingErrorMessage: undefined};
        },
        [actions.requestSavedLists]: (state) => {
            return {...state, isLoading: true, loadingErrorMessage: undefined};
        },
        [actions.replaceSavedReports]: (state, payload: SavedFilter<SavedReportType>[]) => {
            const prevNonReportItems = ramda.filter((item) => !isSavedReport(item), state.items);
            const newReportItems = indexById(payload);

            return {
                ...state,
                items: {...prevNonReportItems, ...newReportItems},
                loadingErrorMessage: undefined,
                isLoading: false,
            };
        },
        [actions.replaceSavedLists]: (state, payload: SavedFilter<SavedListType>[]) => {
            const prevNonListItems = ramda.filter((item) => !isSavedList(item), state.items);
            const newListItems = indexById(payload.concat(prevNonListItems));

            return {
                ...state,
                items: {...prevNonListItems, ...newListItems},
                loadingErrorMessage: undefined,
                isLoading: false,
            };
        },
        [actions.replaceSavedFilter]: (state, payload: SavedFilter<*>) => {
            return {...state, items: {...state.items, [payload.id]: payload}};
        },
        [actions.updateSavedFilters]: (state, payload: SavedFilter<*>[]) => {
            const newReports = indexById(payload);

            return {
                ...state,
                items: {...state.items, ...newReports},
                loadingErrorMessage: undefined,
                isLoading: false,
            };
        },
        [actions.failSavedFilters]: (state, payload) => {
            return {...state, loadingErrorMessage: payload, isLoading: false};
        },
        [actions.clearActionError]: (state) => {
            return {...state, actionErrorMessage: undefined};
        },
        [actions.requestSaveNewFilter]: (state) => {
            return {...state, isSavingNew: true, actionErrorMessage: undefined};
        },
        [actions.updateSaveNewFilter]: (state) => {
            return {...state, isSavingNew: false, actionErrorMessage: undefined};
        },
        [actions.failSaveNewFilter]: (state) => {
            return {...state, isSavingNew: false, actionErrorMessage: 'Failed to save report'};
        },
        [actions.requestSaveSharePreferences]: (state) => {
            return {...state, isSavingSharePreferences: true, actionErrorMessage: undefined};
        },
        [actions.updateSaveSharePreferences]: (state) => {
            return {...state, isSavingSharePreferences: false};
        },
        [actions.failSaveSharePreferences]: (state) => {
            return {...state, actionErrorMessage: 'Failed to update report'};
        },
        [actions.requestResaveFilter]: (state) => {
            return {...state, isResaving: true, actionErrorMessage: undefined};
        },
        [actions.updateResaveFilter]: (state) => {
            return {...state, isResaving: false};
        },
        [actions.failResaveFilter]: (state) => {
            return {...state, actionErrorMessage: 'Failed to update report'};
        },
        [actions.requestDeleteSavedFilter]: (state) => {
            return {...state, actionErrorMessage: undefined};
        },
        [actions.failDeleteSavedFilter]: (state, payload) => {
            return {...state, actionErrorMessage: `Failed to delete: ${payload.name}`};
        },
        [actions.removeSavedFilter]: (state, id) => {
            const newItems = ramda.omit([id], state.items);

            return {...state, items: newItems};
        },
        'persist/REHYDRATE': (state, persistedState) => {
            if (!persistedState || !persistedState.savedFilters) {
                return state;
            }

            return {...state, ...defaultState, items: persistedState.savedFilters.items};
        },
    },
    defaultState
);
