import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ArrowRightIconSvg from './icon-arrow-right.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ArrowRightIconSvg {...validProps} />;
}
SvgComponent.iconName = 'arrowRightIcon';

export const ArrowRightIcon = svgIconWrapper(SvgComponent);
