import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 10 12'}>
            <g id='Layer_2' data-name='Layer 2'>
                <g id='Layer_1-2' data-name='Layer 1'>
                    <rect className='cls-1' width='4' height='12' />
                    <rect className='cls-1' x='6' width='4' height='12' />
                </g>
            </g>
        </svg>
    );
}
SvgComponent.iconName = 'pause';

export const PauseIcon = svgIconWrapper(SvgComponent);
