/* @flow */

import * as React from 'react';

import {Link} from 'shells/link';
import {Title} from 'shells/typography';

import './popover-list-header.css';

type Props = {title: string, viewAllLink: string};

export function PopoverListHeader(props: Props) {
    return (
        <div styleName='header'>
            <Title>{props.title}</Title>
            <Link newTab={true} variant='primary' href={props.viewAllLink}>
                View all
            </Link>
        </div>
    );
}
