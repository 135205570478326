/* @flow */
import {createSelector} from 'reselect';
import * as ramda from 'ramda';

import type {CoreEntityType, SavedFilterId, SavedFilter, SavedListType} from '../types';
import type {SavedFiltersModuleState} from './saved-filters-reducer';
import {
    isSavedReport,
    isSavedList,
    getSavedListReportTypeFromEntityType,
} from './saved-filters-utils';

export const getSavedFiltersState = (state: SavedFiltersModuleState) => state.savedFilters;

// $FlowFixMe upgrading Flow to v0.92.1
export const getSavedFilters = createSelector([getSavedFiltersState], (savedFiltersState) => {
    return savedFiltersState.items;
});

// $FlowFixMe upgrading Flow to v0.92.1
export const getSavedReports = createSelector([getSavedFilters], (savedFilters) => {
    return ramda.filter(isSavedReport, savedFilters);
});

// $FlowFixMe upgrading Flow to v0.92.1
export const getSavedLists = createSelector([getSavedFilters], (savedFilters) => {
    return ramda.filter(isSavedList, savedFilters);
});

// TODO: memoize this
export const getSavedListsForEntityType = (
    entityType: CoreEntityType,
    state: SavedFiltersModuleState
): SavedFilter<SavedListType>[] => {
    const reportType = getSavedListReportTypeFromEntityType(entityType);

    return ramda
        .values<{[id: SavedFilterId]: SavedFilter<*>}>(getSavedLists(state))
        .filter((list) => list.reportType === reportType);
};

// $FlowFixMe upgrading Flow to v0.92.1
export const getLoadingErrorMessage = createSelector(
    [getSavedFiltersState],
    (savedFiltersState) => {
        return savedFiltersState.loadingErrorMessage;
    }
);

// $FlowFixMe upgrading Flow to v0.92.1
export const getActionErrorMessage = createSelector([getSavedFiltersState], (savedFiltersState) => {
    return savedFiltersState.actionErrorMessage;
});

// $FlowFixMe upgrading Flow to v0.92.1
export const getIsLoading = createSelector([getSavedFiltersState], (savedFiltersState) => {
    return savedFiltersState.isLoading;
});

// $FlowFixMe upgrading Flow to v0.92.1
export const getIsSavingNew = createSelector([getSavedFiltersState], (savedFiltersState) => {
    return savedFiltersState.isSavingNew;
});

// $FlowFixMe upgrading Flow to v0.92.1
export const getIsResaving = createSelector([getSavedFiltersState], (savedFiltersState) => {
    return savedFiltersState.isResaving;
});

// $FlowFixMe upgrading Flow to v0.92.1
export const getIsSavingSharePreferences = createSelector(
    [getSavedFiltersState],
    (savedFiltersState) => {
        return savedFiltersState.isSavingSharePreferences;
    }
);
