import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Team from './icon-team.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Team {...validProps} />;
}
SvgComponent.iconName = 'iconTeam';

export const TeamIcon = svgIconWrapper(SvgComponent);
