/* @flow */

import * as React from 'react';
import {Link as RouterLink, type LocationShape} from 'react-router-dom';
import {Button, type ButtonVariant} from '../button';
import {Link} from '../link';
import {Line} from '../divider';
import './sidebar-special-state.css';

type CtaConfig = {|
    ctaText: string,
    onClick?: () => void,
    buttonVariant?: ButtonVariant,
    to?: string | LocationShape,
|};

type Props = {|
    headerText: string,
    subheaderText: string,
    primaryCTA?: CtaConfig,
    secondaryCTA?: CtaConfig,
    iconComponent: React.Node,
|};

export class SidebarSpecialState extends React.Component<Props> {
    render() {
        return (
            <div styleName='container'>
                <div styleName='icon'>{this.props.iconComponent}</div>
                <div styleName='text-container'>
                    <div styleName='header'>{this.props.headerText}</div>
                    <div styleName='subheader'>{this.props.subheaderText}</div>
                    {this.renderPrimaryCTA()}
                    {this.renderSecondaryCTA()}
                </div>
            </div>
        );
    }

    renderPrimaryCTA = () => {
        if (!this.props.primaryCTA) return null;

        if (this.props.primaryCTA.to) {
            return (
                <Link
                    as={RouterLink}
                    to={this.props.primaryCTA.to}
                    onClick={this.props.primaryCTA.onClick}
                    variant='button-primary'
                    size='big'
                >
                    {this.props.primaryCTA.ctaText}
                </Link>
            );
        }

        if (this.props.primaryCTA.onClick) {
            return (
                <Button
                    onClick={this.props.primaryCTA.onClick}
                    variant={this.props.primaryCTA.buttonVariant || 'primary'}
                    size='big'
                >
                    {this.props.primaryCTA.ctaText}
                </Button>
            );
        }

        return null;
    };

    renderSecondaryCTA = () => {
        if (!this.props.secondaryCTA) return null;

        if (this.props.secondaryCTA.to) {
            return (
                <React.Fragment>
                    <div styleName='divider'>
                        <Line text='or' />
                    </div>
                    <Link
                        as={RouterLink}
                        to={this.props.secondaryCTA.to}
                        onClick={this.props.secondaryCTA.onClick}
                        variant='secondary'
                    >
                        {this.props.secondaryCTA.ctaText}
                    </Link>
                </React.Fragment>
            );
        }

        if (this.props.secondaryCTA.onClick) {
            return (
                <React.Fragment>
                    <div styleName='divider'>
                        <Line text='or' />
                    </div>
                    <Button
                        onClick={this.props.secondaryCTA.onClick}
                        variant='text-secondary'
                        size='big'
                    >
                        {this.props.secondaryCTA.ctaText}
                    </Button>
                </React.Fragment>
            );
        }

        return null;
    };
}
