/* @flow */

import * as ramda from 'ramda';

/*
 * A partially applied function which can be used to convert a list of objects
 * into the format we use for the `items` state, which is an object whose keys
 * are ids and values are the original objects in the list.
 */
// $FlowFixMe (@ianvs) Flow doesn't realize that there will always be an `id` prop
export const indexById: <T: {id: string}>(objects: T[]) => {[id: string]: T} = ramda.indexBy(
    ramda.prop('id')
);
