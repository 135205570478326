/* @flow */

import {createAction} from 'redux-act';
import type {Dispatch} from 'redux';

import {api} from '../api';
import type {SavedFilter, SavedFilterType, CoreEntityType} from '../types';
import type {NutshellSharedState} from '../store';

import {getSavedFilters} from './saved-filters-selectors';
import {getSavedListReportTypeFromEntityType} from './saved-filters-utils';

export const requestSavedReports = createAction('SAVED_REPORTS_REQUESTED');
export const replaceSavedReports = createAction('SAVED_REPORTS_REPLACED');
export const loadSavedReport = createAction('SAVED_REPORT_LOADED');

export const requestSavedLists = createAction('SAVED_LISTS_REQUESTED');
export const replaceSavedLists = createAction('SAVED_LISTS_REPLACED');
export const loadSavedList = createAction('SAVED_LIST_LOADED');

export const updateSavedFilters = createAction('SAVED_FILTERS_UPDATED');
export const replaceSavedFilter = createAction('SAVED_FILTER_REPLACED');
export const removeSavedFilter = createAction('SAVED_FILTER_REMOVED');
export const failSavedFilters = createAction('SAVED_FILTERS_FAILED');
export const clearActionError = createAction('SAVED_FILTERS_ACTION_ERROR_CLEARED');

export const requestResaveFilter = createAction('SAVED_FILTER_RESAVE_REQUESTED');
export const updateResaveFilter = createAction('SAVED_FILTER_RESAVE_UPDATED');
export const failResaveFilter = createAction('SAVED_FILTER_RESAVE_FAILED');

export const requestSaveSharePreferences = createAction(
    'SAVED_FILTER_SAVE_SHARE_PREFERENCES_REQUESTED'
);
export const updateSaveSharePreferences = createAction(
    'SAVED_FILTER_SAVE_SHARE_PREFERENCES_UPDATED'
);
export const failSaveSharePreferences = createAction('SAVED_FILTER_SAVE_SHARE_PREFERENCES_FAILED');

export const requestDeleteSavedFilter = createAction('SAVED_FILTER_DELETE_REQUESTED');
export const failDeleteSavedFilter = createAction('SAVED_FILTER_DELETE_FAILED');

export function fetchSavedReports() {
    return function(dispatch: Dispatch<*>) {
        dispatch(requestSavedReports());

        api.get('filters', {reportType: 'report_*'})
            .then((response) => response.json())
            .then((res) => {
                dispatch(replaceSavedReports(res.filters));
            })
            .catch((err) => {
                dispatch(failSavedFilters(err.message));
            });
    };
}

export function fetchSavedLists(entityType: ?CoreEntityType) {
    return function(dispatch: Dispatch<*>) {
        dispatch(requestSavedLists());

        const reportTypeOpts = entityType
            ? {reportType: getSavedListReportTypeFromEntityType(entityType)}
            : undefined;

        api.get('filters', reportTypeOpts)
            .then((response) => response.json())
            .then((res) => {
                dispatch(replaceSavedLists(res.filters));
            })
            .catch((err) => {
                dispatch(failSavedFilters(err.message));
            });
    };
}

/**
 * Helper to make PUT request and update SavedReport
 *
 * @param  {object} params      - Params to update
 * @return {function}           - jQuery.XHR Promise object
 */
function updateSavedFilter(params: SavedFilter<*>) {
    if (!params.href) {
        throw new Error('No href');
    }

    return api.put(params.href, {data: params});
}

/**
 * Resave current report, and dispatch actions depending on the success
 * of the re-save
 *
 * @param  {string} id                - Id of the saved filter to update
 * @param  {object} params            - Params to update
 * @return {function}                 - Function to execute request and dispatch actions
 */
export function resaveFilter(id: string, params: {|value: string|}) {
    return function(dispatch: Dispatch<*>, getState: () => NutshellSharedState) {
        dispatch(requestResaveFilter());
        const state = getState();
        const savedFilters = getSavedFilters(state);
        const filterToUpdate = savedFilters[id];
        const newFilter = {...filterToUpdate, ...params};

        updateSavedFilter(newFilter)
            .then(() => {
                dispatch(replaceSavedFilter(newFilter));
                dispatch(updateResaveFilter());
            })
            .catch(() => {
                dispatch(failResaveFilter());
            });
    };
}

/**
 * Save share preferences of current report, and dispatch actions
 * depending on the success of saving those preferences
 *
 * @param  {string} id                - Id of the saved filter to update
 * @param  {object} params            - Share-related params to update
 * @return {function}                 - Function to execute request and dispatch actions
 */
export function updateSharePreferences(id: string, params: {|isShared: boolean, links: Object|}) {
    return function(dispatch: Dispatch<*>, getState: () => NutshellSharedState) {
        dispatch(requestSaveSharePreferences());
        const state = getState();
        const savedFilters = getSavedFilters(state);
        const filterToUpdate = savedFilters[id];
        const newFilter = {...filterToUpdate, ...params};

        return updateSavedFilter(newFilter)
            .then(() => {
                dispatch(replaceSavedFilter(newFilter));
                dispatch(updateSaveSharePreferences());
            })
            .catch(() => {
                dispatch(failSaveSharePreferences());
            });
    };
}

export const requestSaveNewFilter = createAction('SAVE_NEW_FILTER_REQUESTED');
export const updateSaveNewFilter = createAction('SAVE_NEW_FILTER_UPDATED');
export const failSaveNewFilter = createAction('SAVE_NEW_FILTER_FAILED');

export function saveNewFilter(
    name: string,
    reportType: SavedFilterType,
    value: string,
    isShared: boolean
) {
    return function(dispatch: Dispatch<*>) {
        dispatch(requestSaveNewFilter());

        return api
            .post('filters', {
                filters: [
                    {
                        name: name,
                        reportType: reportType,
                        value: value,
                        isShared: isShared,
                    },
                ],
            })
            .then((response) => response.json())
            .then((res) => {
                dispatch(updateSavedFilters(res.filters));
                dispatch(updateSaveNewFilter());

                // Since we currently don't update our URL from redux,
                // we need to return the newly saved filter id to our
                // component so it can update the URL that way.
                //
                // In a perfect world, we'd update the URL from here.
                return res.filters[0].id;
            })
            .catch(() => {
                dispatch(failSaveNewFilter(name));
            });
    };
}

export function deleteSavedFilter(savedFilter: SavedFilter<*>) {
    return function(dispatch: Dispatch<*>) {
        dispatch(requestDeleteSavedFilter());

        if (!savedFilter.href) return dispatch(failDeleteSavedFilter());

        return api
            .delete(savedFilter.href)
            .then((response) => response.json())
            .then(() => {
                dispatch(removeSavedFilter(savedFilter.id));

                return savedFilter;
            })
            .catch(() => {
                dispatch(failDeleteSavedFilter(savedFilter));
            });
    };
}
