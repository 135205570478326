import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Unlock from './icon-unlock.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Unlock {...validProps} />;
}
SvgComponent.iconName = 'unlock';

export const UnlockIcon = svgIconWrapper(SvgComponent);
