/* @flow */

import * as React from 'react';

import {Link as RoutedLink} from 'react-router-dom';

import {MagnifyingGlassEmptyState} from '../../../illustrations';
import {Link} from '../../../link';
import {ButtonCard} from '../../../card';
import {Stack} from '../../../layout';

import {PersonCardContent} from './person-card-content';

import './addable-people.css';

export type AddablePerson = {|
    id: string,
    name: string,
    location: ?string,
    jobTitle: ?string,
    position: ?string,
    hasPhone: boolean,
    hasEmail: boolean,
    hasLinkedinUrl: boolean,
|};

type Props = {|
    people: AddablePerson[],
    selectedPersonIds: string[],
    updateSelectedPersonIds: (personId: string) => void,
    onClickName?: (string) => void,
    onClose: () => void,
    adjustFiltersUrl: string,
|};

export function AddablePeople(props: Props) {
    return (
        <div>
            <div styleName='list-header-container'>
                <div styleName='list-header'>Select people to add to Nutshell</div>
                <Link
                    as={RoutedLink}
                    to={props.adjustFiltersUrl}
                    onClick={props.onClose}
                    variant='primary'
                    size='small'
                >
                    Adjust filters
                </Link>
            </div>
            {props.people.length ? (
                <Stack spacing={8}>
                    {props.people.map((person) => {
                        const isSelected = props.selectedPersonIds.includes(person.id);

                        return (
                            <ButtonCard
                                key={person.id}
                                isFullWidth={true}
                                isSelected={isSelected}
                                onClick={() => {
                                    props.updateSelectedPersonIds(person.id);
                                }}
                            >
                                <PersonCardContent
                                    {...person}
                                    onClickName={props.onClickName}
                                    isSelected={isSelected}
                                />
                            </ButtonCard>
                        );
                    })}
                </Stack>
            ) : (
                <div styleName='empty-state'>
                    <div styleName='illustration'>
                        <MagnifyingGlassEmptyState />
                    </div>
                    <div>
                        No people were found. Your filters may be too specific, or there are no
                        trackable employees at this company.
                    </div>
                    <Link
                        as={RoutedLink}
                        to={props.adjustFiltersUrl}
                        onClick={props.onClose}
                        variant='button-primary'
                        size='big'
                    >
                        Adjust filters
                    </Link>
                </div>
            )}
        </div>
    );
}
