/* @flow */

import * as React from 'react';

type Props = {
    id: string,
};

type State = {
    entity: Object, // Backbone model, not sure how to type correctly
};

export default class FetchedBackboneModel extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const modelType = props.id.split('-')[1];
        const modelClass = modelType.charAt(0).toUpperCase() + modelType.slice(1);

        this.state = {
            entity: Nut.Model[modelClass].findOrCreate({id: props.id}),
        };
    }

    UNSAFE_componentWillMount() {
        this.state.entity.fetch().done(() => this.forceUpdate());
    }

    render() {
        const entityName = this.state.entity.get('name') || '…';

        return <span>{entityName}</span>;
    }
}
