/* @flow */

import * as React from 'react';
import './spacer.css';

type Props = {
    mb: 2 | 4 | 8 | 12 | 16 | 24 | 32,
    children: React.Node,
};

SpacerGroup.defaultProps = {
    mb: 16,
};

export function SpacerGroup(props: Props) {
    return <div styleName={`spacer-bottom-${props.mb}`}>{props.children}</div>;
}
