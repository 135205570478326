import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Sparkle from './icon-sparkle.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Sparkle {...validProps} />;
}
SvgComponent.iconName = 'sparkle';

export const SparkleIcon = svgIconWrapper(SvgComponent);
