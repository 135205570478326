/* @flow */

import * as React from 'react';
import {Link} from 'react-router-dom';

import {CompanyContactIcon, AccountIcon, ExternalLinkIcon} from '../../../icon';
import {TwoLine} from '../../../two-line';
import {RelativeTimestamp} from '../../../timestamp';
import {IconAvatar} from '../../../avatar';
import {colors} from '../../../colors';

import './company-header.css';

export type CompanyHeaderDetails = {|
    name: string,
    timestamp: number,
    wasConvertedAutomatically: boolean,
    htmlUrlPath?: string,
|};

type Props = {|
    ...CompanyHeaderDetails,
|};

export function CompanyHeader(props: Props) {
    let Icon,
        iconColor,
        backgroundColor,
        iconSize = 28,
        companyName = props.name,
        timestampText;

    if (props.htmlUrlPath) {
        Icon = AccountIcon;
        iconColor = colors.white;
        backgroundColor = colors.company;
        iconSize = 24;
        companyName = (
            <Link to={props.htmlUrlPath} target='_blank' rel='noopener noreferrer'>
                {props.name}
                <ExternalLinkIcon size={13} wrapWithDiv={false} />
            </Link>
        );
        timestampText = 'Added';
    } else {
        Icon = CompanyContactIcon;
        timestampText = 'Seen';
    }

    return (
        <div styleName='company-header-container'>
            <TwoLine
                topRow={
                    <div styleName='company-name' title={props.name}>
                        {companyName}
                    </div>
                }
                bottomRow={
                    <div styleName='company-timestamp'>
                        {props.wasConvertedAutomatically ? (
                            'This company already existed in Nutshell'
                        ) : (
                            <>
                                <div>{timestampText}&nbsp;</div>
                                <RelativeTimestamp datetime={props.timestamp} />
                            </>
                        )}
                    </div>
                }
                avatar={
                    <IconAvatar
                        icon={Icon}
                        avatarSize={48}
                        iconSize={iconSize}
                        backgroundColor={backgroundColor}
                        iconColor={iconColor}
                    />
                }
            />
        </div>
    );
}
