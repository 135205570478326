import PropTypes from 'prop-types';
import * as React from 'react';
import './calendar-day-quick-view.css';

export class CalendarDayQuickView extends React.Component {
    static propTypes = {
        isSelected: PropTypes.bool,
        quickViews: PropTypes.array.isRequired,
    };

    render() {
        return (
            <div styleName={this.props.isSelected ? 'container-selected' : 'container'}>
                {this.generateQuickViewElements()}
            </div>
        );
    }

    /**
     * Helper function that takes in an array of calendar items' "due" times
     * in minutes into the day (i.e. 8am = 480), and returns an array of 24
     * booleans, each index representing whether _one or more_ calendar item
     * exists for that 30-minute segment.
     *
     * The array is length 24 to represent a 12-hour period in the day.
     * @param  {object[]} todoTimes       Array of "minutes into the day" ints
     * @return {boolean[]}                Array of length 24 filled with booleans
     *                                    with keys from 420-1140, in intervals of 30
     */
    indexTimes = (todoTimes) => {
        const indexedTimes = [];
        for (let i = 420; i < 1140; i = i + 30) {
            if (todoTimes.indexOf(i) > -1) {
                const index = i / 30 - 14;
                indexedTimes[index] = true;
            }
        }

        return indexedTimes;
    };

    /**
     * Generates quick view elements from the passed quickView elements. Quick
     * view elements are generated for a 12-hour span, in 30-minute incremenets.
     * This means that a quick view element will be generated for that 30-minute
     * increment if _any_ passed calendar item has a dueTime within that
     * 30-minute window.
     *
     * Also generates an "early" and "late" quickView element, if _any_ calendar
     * item is due before 7am or after 7pm, respectively.
     *
     * @return {object[]}       Array of quick view elements
     */
    generateQuickViewElements = () => {
        let hasEarlyTodo = false;
        let hasLateTodo = false;

        const todoTimes = this.props.quickViews.map((todo) => {
            const todoTimeUnformatted = todo.type === 'activities' ? todo.startTime : todo.dueTime;

            const date = new Date(todoTimeUnformatted * 1000);
            const minutes = date.getHours() * 60 + date.getMinutes();
            const todoTime = Math.floor(minutes / 30) * 30;

            // Set early and late todo booleans to true if todo time meets requirements.
            if (!hasEarlyTodo && todoTime < 420) hasEarlyTodo = true;
            if (!hasLateTodo && todoTime >= 1140) hasLateTodo = true;

            return todoTime;
        });

        const indexedTimes = this.indexTimes(todoTimes);
        const quickViews = [this.generateQuickViewElement('early', hasEarlyTodo)]
            .concat(
                Array.from({length: 24}, (unused, i) =>
                    this.generateQuickViewElement(i, indexedTimes[i])
                )
            )
            .concat([this.generateQuickViewElement('late', hasLateTodo)]);

        return quickViews;
    };

    generateQuickViewElement = (key, isActive) => {
        return <span key={key} styleName={isActive ? 'quick-view-active' : 'quick-view'} />;
    };
}
