/* @flow */

import {type StylesConfig} from 'react-select';
import {colors} from '../colors';

export const selectPickerGenericStyles: StylesConfig = {
    control: (base, state) => ({
        ...base,
        '&:hover': {
            border: state.isFocused ? `1px solid ${colors.blue}` : `1px solid ${colors.greyLt}`,
        },
        cursor: state.isDisabled ? 'default' : 'pointer',
        borderRadius: 3,
        border: state.isFocused ? `1px solid ${colors.blue}` : `1px solid ${colors.offWhiteDk}`,
        borderColor: state.isFocused ? `${colors.blue} !important` : `${colors.offWhiteDk}`,
        boxShadow: 'none',
        fontWeight: 'normal',
        height: 31,
        minHeight: 31,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }),
    clearIndicator: (base, state) => ({
        ...base,
        '&:hover': {color: state.isFocused ? colors.grey : colors.buttonSecondaryDk},
        paddingTop: 5,
        paddingBottom: 5,
    }),
    singleValue: (base) => ({
        ...base,
        color: colors.grey,
    }),
    multiValue: (base) => ({
        ...base,
        color: colors.grey,
    }),
    option: (base, state) => ({
        ...base,
        ':active': {backgroundColor: state.isSelected ? colors.listSelected : colors.listHovered},
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: state.isSelected
            ? colors.listSelected
            : state.isFocused
            ? colors.listHovered
            : base.backgroundColor,
        color: state.isSelected || state.isFocused ? colors.greyDk : colors.grey,
        opacity: state.isDisabled ? 0.6 : 1,
        '&:disabled': {color: colors.greyLt, opacity: 0.6},
    }),
    dropdownIndicator: (base) => ({
        ...base,
        paddingTop: 5,
        paddingBottom: 5,
    }),
};
