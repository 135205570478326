/* @flow */

import * as React from 'react';
import {Helmet} from 'react-helmet';

import './pre-auth-layout.css';

type Props = {
    children: React.Node,
    bodyId: string,
    backgroundColor?: string,
    imageLeft?: React.Node,
};

PreAuthLayout.defaultProps = {
    bodyId: 'pre-auth',
};

export function PreAuthLayout({imageLeft, backgroundColor, children, bodyId}: Props) {
    // Set the page's body id when first mounting.
    React.useEffect(() => {
        const pageBody = document.querySelector('body');
        if (pageBody && pageBody.id !== bodyId) {
            pageBody.id = bodyId;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div styleName='pre-auth-layout'>
            <Helmet>
                <meta name='viewport' content='width=device-width,initial-scale=1' />
            </Helmet>
            <div
                styleName={backgroundColor ? 'left-column--with-background' : 'left-column'}
                style={backgroundColor ? {backgroundColor: backgroundColor} : undefined}
            >
                {imageLeft}
            </div>
            <div styleName='right-column'>
                <div styleName='logo'>
                    <a href='https://www.nutshell.com/home'>
                        <img src='/include/images/nutshell-logo-full-color.svg' alt='' />
                    </a>
                </div>
                {children}
            </div>
        </div>
    );
}
