import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import MultiplePipeline from './multiple-pipeline-icon.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <MultiplePipeline {...validProps} />;
}
SvgComponent.iconName = 'multiplePipeline';

export const MultiplePipelineIcon = svgIconWrapper(SvgComponent);
