/* @flow */

type PageInfo = {
    __typename: 'PageInfo',
    startCursor: ?string,
    endCursor: ?string,
    hasNextPage: boolean,
    hasPreviousPage: boolean,
};

type ConnectionObject<Edge> = {
    edges: Edge[],
    pageInfo: PageInfo,
    totalCount: number,
};

export function translateConnectionObjectToArray(connectionObj: ConnectionObject<*>): Object[] {
    return connectionObj.edges.map((edge) => {
        return edge.node;
    });
}
