/* @flow */

import type {CollectionState} from '../collections';
import type {Team} from '../types';
import * as selectors from './teams-selectors';

export type TeamsState = CollectionState<Team>;

export const Teams = {
    ...selectors,
};
