/* @flow */

import * as React from 'react';

import {AddIcon, CheckmarkIcon} from 'shells/icon';
import {ButtonGroup} from 'shells/button-group';
import {Button} from 'shells/button';

import './transcript-summary.css';

type Props = {onAdd: () => Promise<*>, onReplace: ?() => Promise<*>, onCancel: () => void};

export function SummaryButtons(props: Props) {
    return (
        <div styleName='buttons'>
            <ButtonGroup>
                <Button
                    variant='ai-blue'
                    icon={AddIcon}
                    onClick={(e: SyntheticEvent<*>) => {
                        e.stopPropagation();
                        props.onAdd();
                    }}
                    tooltipText='Add to activity note'
                >
                    Add
                </Button>
                {props.onReplace ? (
                    <Button
                        variant='ai-blue'
                        icon={CheckmarkIcon}
                        onClick={(e: SyntheticEvent<*>) => {
                            e.stopPropagation();
                            // $FlowIgnore checked above
                            props.onReplace();
                        }}
                        tooltipText='Replace activity note'
                    >
                        Replace
                    </Button>
                ) : (
                    undefined
                )}
                <Button
                    onClick={(e: SyntheticEvent<*>) => {
                        e.stopPropagation();
                        props.onCancel();
                    }}
                >
                    Cancel
                </Button>
            </ButtonGroup>
        </div>
    );
}
