/* @flow */

export const TINY_INTRO_REDUX_FORM = 'TINY_INTRO_REDUX_FORM';
export const SIGNUP_REDUX_FORM = 'SIGNUP_REDUX_FORM';
export const LOGIN_REDUX_FORM = 'login';
export const FORGOT_PASSWORD_REDUX_FORM = 'forgot-password';
export const SET_NEW_PASSWORD_REDUX_FORM = 'set-new-password';

// Comes from the server, duplicated here. See AuthController
export const AUTHENTICATOR_MFA_REQUIRED_VALIDATION_MESSAGE = 'AUTHENTICATOR_MFA_REQUIRED';
export const PHONE_MFA_REQUIRED_VALIDATION_MESSAGE = 'PHONE_MFA_REQUIRED';
export const MFA_NOT_CONFIGURED_BUT_ENFORCED_MESSAGE = 'MFA_SETUP_REQUIRED';

// Nut_Office365_Auth.php
export const MICROSOFT_OAUTH_URL_KEY = 'microsoft_oauth_url';
