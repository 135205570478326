/* @flow */

import * as React from 'react';
import {Helmet} from 'react-helmet';
import './tiny-intro-layout.css';

type Props = {
    children: React.Node,
    backgroundColor?: string,
    leftComponent?: React.Node,
};

export function TinyIntroLayout({backgroundColor, leftComponent, children}: Props) {
    const mainContent = leftComponent ? (
        <>
            <div styleName='left-column' style={{backgroundColor: backgroundColor}}>
                {leftComponent}
            </div>
            <div styleName='right-column'>{children}</div>
        </>
    ) : (
        <div styleName='single-column'>{children}</div>
    );

    return (
        <div styleName={leftComponent ? 'tiny-intro-layout' : 'tiny-intro-layout--full-width'}>
            <Helmet>
                <meta name='viewport' content='width=device-width,initial-scale=1' />
            </Helmet>
            {mainContent}
        </div>
    );
}
