/* @flow */

import * as React from 'react';

import {ChevronRightIcon} from '../../../icon';
import {DetailsList} from '../../details-list/details-list';

import './session-details.css';

export type Session = {|
    numVisits: number,
    totalVisitDuration: string,
|};

type Props = {|
    ...Session,
    onExpandDetails: () => void,
|};

export function SessionDetails(props: Props) {
    return (
        <button styleName='session-details' onClick={props.onExpandDetails}>
            <DetailsList
                details={[
                    {
                        key: 'time-on-site',
                        name: 'Time on site',
                        value: props.totalVisitDuration,
                        isFullWidth: false,
                    },
                    {
                        key: 'page-visits',
                        name: 'Page visits',
                        value: props.numVisits,
                        isFullWidth: false,
                    },
                ]}
            />
            <div styleName='icon-container'>
                <ChevronRightIcon size={15} wrapWithDiv={false} />
            </div>
        </button>
    );
}
