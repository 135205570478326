/* @flow */

export const ActionTypes = {
    EMAIL_REPLY_CLOSED: 'EMAIL_REPLY_CLOSED',
    EMAIL_REPLY_OPENED: 'EMAIL_REPLY_OPENED',
};

type EmailReplyClosedAction = {type: 'EMAIL_REPLY_CLOSED'};
type EmailReplyOpenedAction = {
    type: 'EMAIL_REPLY_OPENED',
    payload: {
        replyToEmailId: string,
        isReplyingAll: boolean,
        onReplySuccess?: () => void,
    },
};

export type EmailReplyAction = EmailReplyOpenedAction | EmailReplyClosedAction;

/*
 * Action creators
 */
export const closeReplyModal = (): EmailReplyClosedAction => ({
    type: ActionTypes.EMAIL_REPLY_CLOSED,
});

export const openReplyModal = (input: {
    replyToEmailId: string,
    isReplyingAll?: boolean,
    onReplySuccess?: () => void,
}): EmailReplyOpenedAction => ({
    type: ActionTypes.EMAIL_REPLY_OPENED,
    payload: {
        replyToEmailId: input.replyToEmailId,
        isReplyingAll: input.isReplyingAll || false,
        onReplySuccess: input.onReplySuccess,
    },
});
