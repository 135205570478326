/* @flow */

import type {MentionConfig} from 'shells/rich-textarea';

import type {GetTeams_teams as Team} from 'nutshell-graphql-types';

import {getEntities} from './mentions-utils';

export function getMentionsConfig(): MentionConfig {
    return {
        enabled: true,
        getMentions: (searchTerm: string, teams: ?(Team[])) => {
            return getEntities(searchTerm, teams).then((response) => {
                return {matches: response};
            });
        },
    };
}
