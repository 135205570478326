import React from 'react';

import {svgIconWrapper} from '../svg-icon-wrapper';
import Tablet from './icon-tablet.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Tablet {...validProps} />;
}
SvgComponent.iconName = 'tablet';

export const TabletIcon = svgIconWrapper(SvgComponent);
