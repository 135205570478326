/* @flow */

export type LocationType = 'country' | 'state' | 'postalCode' | 'areaCode' | 'city' | 'coordinates';

export function getLocationType(type: LocationType): number {
    switch (type) {
        case 'country':
            return 0;
        case 'state':
            return 1;
        case 'postalCode':
            return 2;
        case 'areaCode':
            return 3;
        case 'city':
            return 4;
        case 'coordinates':
            return 5;
        default:
            return 0;
    }
}
