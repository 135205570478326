/* @flow */
import {createAction} from 'redux-act';

import type {FilterObject} from 'nutshell-core/types';
import {EntityList} from '../../entity-list';

import type {ChartReportParams, LeadsReportKey} from './report-types';

export const toggleShowFilterDrawer = createAction('REPORTS_FILTER_DRAWER_TOGGLED');

export type LeadsReportDataRequestedAction = {
    type: 'REPORTS_LEADS_REPORT_DATA_REQUESTED',
    payload: {
        reportKey: LeadsReportKey,
        reportParams: ChartReportParams,
        filters: FilterObject[],
    },
};

export type LeadsReportListRequestedAction = {
    type: 'REPORT_LEADS_LIST_DATA_REQUESTED',
    payload: {
        filters: FilterObject[],
        pageNum: number,
        columns: string[],
        sort: string,
        reportKey: LeadsReportKey,
    },
};

export const requestLeadsReportData: (
    payload: $PropertyType<LeadsReportDataRequestedAction, 'payload'>
) => LeadsReportDataRequestedAction = createAction('REPORTS_LEADS_REPORT_DATA_REQUESTED');
export const updateLeadsReportTableData = createAction('REPORTS_LEADS_REPORT_TABLE_DATA_UPDATED');
export const updateLeadsReportChartData = createAction('REPORTS_LEADS_REPORT_CHART_DATA_UPDATED');
export const updateReportMaps = createAction('REPORTS_REPORT_MAPS_UPDATED');
export const failLeadsReportChartData = createAction('REPORTS_LEADS_REPORT_CHART_DATA_FAILED');

export const requestReportLeadsListData: (
    payload: $PropertyType<LeadsReportListRequestedAction, 'payload'>
) => LeadsReportListRequestedAction = createAction('REPORT_LEADS_LIST_DATA_REQUESTED');
export const updateReportLeadsListData = createAction('REPORT_LEADS_LIST_DATA_UPDATED');
export const updateReportLeadsListSummaryData = createAction(
    'REPORT_LEADS_LIST_SUMMARY_DATA_UPDATED'
);
export const updateForecastSummaryStats = createAction(
    'REPORT_LEADS_LIST_FORECAST_SUMMARY_STATS_UPDATED'
);
export const failReportLeadsListData = createAction('REPORT_LEADS_LIST_DATA_FAILED');

// Activity Report Stats actions
export const updateActivitySummaryStats = createAction('REPORT_ACTIVITY_SUMMARY_STATS_UPDATED');

export const updateEmailSummaryStats = createAction('REPORT_EMAIL_SUMMARY_STATS_UPDATED');

// Analytics Report Stats actions
export const updateAnalyticsSummaryStats = createAction('REPORT_ANALYTICS_SUMMARY_STATS_UPDATED');

/**
 * [Thunk] Async action to export the leads list on a given report, given the active
 * filters for that report.
 * @param  {Object} filter       Object of filters to be passed in as params
 * @param  {Object} [fields]     The fields to include in the export
 * @return {Function}            Thunk to leverage dispatch and getState
 */
export function exportLeadList(filter: Object, fields?: {leads: string}) {
    return EntityList.exportList('leads', filter, fields);
}
