/* @flow */

import * as React from 'react';
import {useMutation} from '@apollo/react-hooks';

import type {
    EventFragmentSparse,
    SessionUser,
    RetryFailedEmail as RetryFailedEmailMutation,
    RetryFailedEmailVariables as RetryFailedEmailMutationVariables,
} from 'nutshell-graphql-types';

import {colors} from 'shells/colors';
import {TimelineEntryActionMenu} from 'shells/timeline/entry/toolbar/timeline-entry-action-menu';
import {ExpandIcon, CollapseIcon} from 'shells/icon';

import {appHistory} from '../../../history';
import RETRY_QUEUED_EMAIL from '../../email/mailbox/graphql/mutations/retry-queued-email.graphql';
import {
    getEntryTypeFromPayload,
    getEmailIconVariant,
    getParticipantsForEmail,
    getActorForEmail,
    getSubjectForEmail,
    getStatusForQueuedEmail,
} from '../helpers';
import {TimelineEntry} from './timeline-entry';

type Props = {
    event: EventFragmentSparse,
    currentUser: SessionUser,
    onDeleteEvent?: (eventId: string) => Promise<*>,
};

export function TimelineEntryEmailQueued(props: Props) {
    const {id, changeType, changeTime, payload} = props.event;
    const {currentUser, onDeleteEvent} = props;

    const [isViewingAttachments, setIsViewingAttachments] = React.useState<boolean>(false);
    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

    const [retry] = useMutation<RetryFailedEmailMutation, RetryFailedEmailMutationVariables>(
        RETRY_QUEUED_EMAIL
    );

    // Make this expandable !

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'EmailQueued' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (payload.__typename === 'EmailQueued') {
        const onRetry = () => {
            return retry({
                variables: {
                    payload: {
                        emailId: payload.id,
                    },
                },
            });
        };

        const participants = getParticipantsForEmail(payload.to);

        return (
            <TimelineEntry
                id={id}
                iconVariant={getEmailIconVariant(payload)}
                entryType={getEntryTypeFromPayload(payload)}
                timestamp={changeTime}
                subject={getSubjectForEmail(payload)}
                actor={getActorForEmail(payload.from)}
                status={getStatusForQueuedEmail(payload, onRetry)}
                participants={participants}
                content={payload.bodyExcerpt}
                changeType={changeType}
                reactions={[]}
                currentUser={currentUser}
                customBorderProps={payload.isFailed ? {borderColor: colors.red} : undefined}
                emailEngagement={{
                    attachments: payload.attachments,
                    clicks: 0,
                    opens: 0,
                }}
                disableComments={true}
                disableReactions={true}
                actionMenu={
                    <TimelineEntryActionMenu
                        id={id}
                        entryType='EmailQueued'
                        attachmentsToView={isViewingAttachments ? payload.attachments : undefined}
                        setIsViewingAttachments={setIsViewingAttachments}
                        handleDelete={
                            payload.isFailed && payload.canRetry ? onDeleteEvent : undefined
                        }
                    />
                }
                additionalToolbarOptions={[
                    isExpanded
                        ? {
                              id: 'collapse',
                              icon: CollapseIcon,
                              onClick: () => setIsExpanded(false),
                              tooltipText: 'Collapse',
                          }
                        : {
                              id: 'expand',
                              icon: ExpandIcon,
                              onClick: () => setIsExpanded(true),
                              tooltipText: 'Expand',
                          },
                ]}
                onClickOpen={() => {
                    appHistory.push(payload.htmlUrl);
                }}
                isExpanded={isExpanded}
            />
        );
    }

    return null;
}
