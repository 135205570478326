/* @flow */
import * as React from 'react';
import ReactDOM from 'react-dom';
import {Provider, connect} from 'react-redux';

import {Session} from 'nutshell-core/session';
import type {NotifyMessage} from 'nutshell-core/types';

import {store, type NutshellState} from './store';
import {Notifies} from './ui/entity-list/footer/notifies';

type OwnProps = {||};

type StateProps = {|
    validNotifies: NotifyMessage[],
|};

type DispatchProps = {|
    onAcknowledgeExportDownload: () => any,
    pollForQueuedExports: () => any,
|};

type Props = {...OwnProps, ...StateProps, ...DispatchProps};

type State = {
    showNotifies: boolean,
};

function mapStateToProps(state: NutshellState): StateProps {
    return {
        validNotifies: Session.getSessionStateUserUnacknowedgedExportNotifications(state),
    };
}

function mapDispatchToProps(dispatch): DispatchProps {
    return {
        onAcknowledgeExportDownload: () => {
            dispatch(Session.requestFetchSession());
        },
        pollForQueuedExports: () => {
            dispatch(Session.pollForQueuedExports());
        },
    };
}

class QueuedDownloadsComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showNotifies: Boolean(props.validNotifies.length),
        };
    }

    UNSAFE_componentWillMount() {
        this.props.pollForQueuedExports();
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        // If we have no downloads unmount
        if (!nextProps.validNotifies.length) {
            this.setState({showNotifies: false});
        } else if (!this.props.validNotifies.length) {
            this.setState({showNotifies: true});
        }
    }

    render() {
        return this.state.showNotifies ? (
            <Notifies
                notifies={this.props.validNotifies}
                onAcknowledgeExportDownload={this.props.onAcknowledgeExportDownload}
            />
        ) : null;
    }
}

const connector = connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps);
const QueuedDownloads = connector(QueuedDownloadsComponent);

export function pollForNotifications() {
    const container = document.createElement('div');
    container.classList.add('notifies-container');

    if (document.body) {
        document.body.append(container);

        ReactDOM.render(
            <Provider store={store}>
                <QueuedDownloads />
            </Provider>,
            container
        );
    }
}
