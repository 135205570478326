import React from 'react';

import {svgIconWrapper} from '../svg-icon-wrapper';
import MobilePhone from './icon-mobile-phone.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <MobilePhone {...validProps} />;
}
SvgComponent.iconName = 'mobile-phone';

export const MobilePhoneIcon = svgIconWrapper(SvgComponent);
