/* @flow */

import * as React from 'react';

type Props = {
    label: string,
    isRequired: boolean,
    value: ?(string | string[] | Object[]),
};

export function FieldRow(props: Props) {
    return (
        <div>
            <dt>
                {props.label}
                {props.isRequired ? <span>*</span> : undefined}
            </dt>
            <dd>{getFieldValue(props.value)}</dd>
        </div>
    );
}

function getFieldValue(value: ?(string | string[])) {
    if (!value) {
        return <span>No response</span>;
    } else if (Array.isArray(value)) {
        // Format will be: This, That, The other thing
        return value.join(', ');
    }

    // Otherwise, the value is already a string and we can just return it
    return value;
}
