import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import EyeSlash from './icon-eye-slash.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <EyeSlash {...validProps} />;
}
SvgComponent.iconName = 'eye-slash';

export const EyeSlashIcon = svgIconWrapper(SvgComponent);
