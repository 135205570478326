/* @flow */
import moment from 'moment';

/**
 * Compare two native dates day/month/year while ignoring the time.
 *
 * @param  {Date} d1 A native date object.
 * @param  {Date} d2 A native date object.
 * @return {Boolean} Return the equality of day/month/year between two dates.
 */
export function isEqualDate(d1: Date | moment, d2: Date | moment): boolean {
    const m1 = moment(d1);
    const m2 = moment(d2);

    if (!(m1.isValid() && m2.isValid())) return false;

    return m1.isSame(m2, 'day');
}
