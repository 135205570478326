/* @flow */
import * as React from 'react';

import {Timestamp} from './timestamp';
import {RelativeTimestampTimer} from './relative-timestamp-timer';

type Props = {
    datetime: ?number,
    format?: 'fromNow' | 'toNow' | 'calendar' | 'hourHybrid',
    hideAgoString?: boolean,
    lowercase?: boolean,
    className?: string,
};

export class RelativeTimestamp extends React.PureComponent<Props> {
    render() {
        return (
            <RelativeTimestampTimer {...this.props}>
                {({formattedTimestamp}) => {
                    return formattedTimestamp ? (
                        <Timestamp
                            timestamp={formattedTimestamp}
                            className={this.props.className}
                        />
                    ) : (
                        <div />
                    );
                }}
            </RelativeTimestampTimer>
        );
    }
}
