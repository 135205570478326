import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Calendar from './icon-calendar.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Calendar {...validProps} color={validProps.color ? validProps.color : '#000'} />;
}
SvgComponent.iconName = 'calendar';

export const CalendarIcon = svgIconWrapper(SvgComponent);
