/* @flow */

export type GraphQLErrorObject = {
    graphQLErrors: {
        message: string,
        debugMessage?: string,
        extensions?: {
            category?: string,
        },
    }[],
    networkError: Error | null,
    message: string,
};

export const DEFAULT_GRAPHQL_ERROR_MESSAGE = 'Oh no! Something went wrong.';

/**
 * Helper function to safely get an error message from a GraphQL mutation
 * Promise error block.
 *
 * Since our error messages can sometimes be "graphQL" errors, and sometimes
 * are straight server errors, this is nice to have so we know we can safely
 * present an error to the user.
 *
 * @param  {Object} graphQLErrorObject - Error object from GraphQL
 * @return {string}					   - Error message to display
 */
export function safelyGetGraphQLErrorMessage<T: GraphQLErrorObject>(graphQLErrorObject?: T) {
    if (!graphQLErrorObject) return null;

    if (typeof window.trackJs !== 'undefined') {
        window.trackJs.track(graphQLErrorObject);
    }

    if (graphQLErrorObject.graphQLErrors && graphQLErrorObject.graphQLErrors.length) {
        const firstError = graphQLErrorObject.graphQLErrors[0];
        if (
            firstError.extensions &&
            (firstError.extensions.category === 'graphql' ||
                firstError.extensions.category === 'internal')
        ) {
            // The graphql error message formatting isn't well-formatted for user display. For now
            // we'll show a generic message here.
            //
            // NOTE: Error category is a graphql-php specific error extension. The `graphql`
            // category covers graphql-php validation errors that happen outside of the resolver.
            //
            // https://github.com/webonyx/graphql-php/blob/master/docs/error-handling.md
            console.error(firstError.debugMessage || firstError.message, firstError); // eslint-disable-line no-console

            return DEFAULT_GRAPHQL_ERROR_MESSAGE;
        } else {
            return firstError.debugMessage || firstError.message;
        }
    }

    if (graphQLErrorObject.networkError) {
        return graphQLErrorObject.networkError.message;
    }
}

/*
 * DEPRECATED
 *
 * Up to now, we have been treating `data` as if it always exists, and maybe in version 2.0.1 of
 * react-apollo it always did, and was turned into an empty object if an error happened in a query.
 * Or it was blowing up and we just didn't realize it.  Either way, data is nullable in version 2.5.x,
 * so we need to check for it before destructuring like we've been doing.
 *
 * We want to know if this is happening, but we don't want it to completely crash the app or change
 * the behavior of our components (for now), so we'll log it off, and then return an empty object
 * that can be safely destructured, as before.
 */
export function makeDataSafe<D>(data: ?D | void): D | any {
    if (!data) {
        // turns out this happens quite a bit, particlarly when `skip={true}` and there's nothing in the
        // cache.  It's not just during errors.  So, we're not really doing much here, and we should
        // probably just move away from using this function and manually check for `data` truthiness
        // in each component directly, since it plays nicer with flow.
        return {};
    }

    return data;
}
