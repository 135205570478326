/* @flow */

import * as React from 'react';
import {Link} from 'react-router-dom';
import {BackButton} from 'shells/button';

import './page-header.css';

type Props = {
    backButton?: {
        to?: string,
        onClick?: () => void,
        text?: string,
        getRef?: (node: ?HTMLElement) => void,
    },
    title: string,
    subtitle?: string | React.Node,
    actionButtons?: React.Node,
};

export function PageHeader(props: Props) {
    const {backButton, subtitle, actionButtons} = props;

    return (
        <header styleName='container'>
            {backButton ? (
                <div styleName='left'>
                    <BackButton
                        as={backButton.to ? Link : 'button'}
                        to={backButton.to}
                        onClick={backButton.onClick}
                        backText={backButton.text ? backButton.text : 'Back'}
                        getRef={backButton.getRef}
                    />
                </div>
            ) : (
                // Need a div here, or we wont space our other elements
                // correctly
                <div styleName='left' />
            )}
            <div styleName='title-container'>
                <div styleName='title'>{props.title}</div>
                {subtitle ? <div styleName='subtitle'>{subtitle}</div> : undefined}
            </div>
            {actionButtons ? (
                <div styleName='right'>{actionButtons}</div>
            ) : (
                <div styleName='right' />
            )}
        </header>
    );
}
