/* @flow */

import {useMutation} from '@apollo/react-hooks';

import type {
    GenerateEntitySummary as GenerateEntitySummaryMutation,
    GenerateEntitySummaryVariables as GenerateEntitySummaryMutationVariables,
    EntitySummaryGenerateInput,
} from 'nutshell-graphql-types';

import {nutApolloClient} from '../../../../../apollo-client';

import GENERATE_ENTITY_SUMMARY from '../mutations/generate-entity-summary.graphql';
import GET_ENTITY_SUMMARY from '../queries/get-entity-summary.graphql';
import ENTITY_SUMMARY_FRAGMENT from '../fragments/entity-summary-fragment.graphql';

export function useGenerateEntitySummary(): {
    generateEntitySummary: (
        input: EntitySummaryGenerateInput,
        entitySummaryId?: string
    ) => Promise<*>,
    isLoading: boolean,
} {
    const [generateEntitySummary, {loading}] = useMutation<
        GenerateEntitySummaryMutation,
        GenerateEntitySummaryMutationVariables
    >(GENERATE_ENTITY_SUMMARY);

    return {
        generateEntitySummary: (input: EntitySummaryGenerateInput, entitySummaryId?: string) => {
            const entitySummary =
                entitySummaryId &&
                nutApolloClient.readFragment({
                    id: entitySummaryId,
                    fragment: ENTITY_SUMMARY_FRAGMENT,
                    fragmentName: 'EntitySummaryFragment',
                });

            return generateEntitySummary({
                variables: {input},
                optimisticResponse: entitySummary
                    ? {
                          __typename: 'Mutation',
                          entitySummaryGenerate: {
                              __typename: 'EntitySummaryGeneratePayload',
                              entitySummary: {
                                  ...entitySummary,
                                  summary: null,
                                  status: 'IN_PROGRESS',
                              },
                          },
                      }
                    : undefined,
                update: (proxy, mutationResults) => {
                    const updatedEntitySummary =
                        mutationResults &&
                        mutationResults.data &&
                        mutationResults.data.entitySummaryGenerate &&
                        mutationResults.data.entitySummaryGenerate.entitySummary;

                    if (updatedEntitySummary) {
                        proxy.writeQuery({
                            query: GET_ENTITY_SUMMARY,
                            variables: {id: input.entityId},
                            data: {
                                entitySummary: updatedEntitySummary,
                            },
                        });
                    }
                },
            });
        },
        isLoading: loading,
    };
}
