/* @flow */

import * as React from 'react';

import {ErrorMessage} from 'shells/form';
import {Button, SaveButton} from '../button';
import {ButtonGroup} from '../button-group';
import {Header, Subheader} from '../typography';

import './form-wrapper-with-buttons.css';

type Props = {
    title: string,
    subtitle?: string,
    errorMessage?: string,
    onSaveClick: (e: SyntheticInputEvent<*>) => void,
    onCancel: (e?: SyntheticEvent<*>) => void,
    isSaving: boolean,
    buttonText?: string,
    savingText?: string,
    successText?: string,
    saveButtonDisabled?: boolean,
    onClearSaveComplete?: () => any,
    errorSaving?: boolean,
    resetOnSaveComplete?: boolean,
    children: React.Node,
};

export function FormWrapperWithButtons(props: Props) {
    return (
        <section styleName='form-wrapper' className='flex align-center justify-center flex-dir-col'>
            <div styleName='labels' className='flex flex-dir-col mb-16'>
                <div className='mb-8'>
                    <Header>{props.title}</Header>
                </div>
                <Subheader>{props.subtitle}</Subheader>
                {props.errorMessage ? <ErrorMessage>{props.errorMessage}</ErrorMessage> : undefined}
            </div>
            {props.children}
            <div styleName='buttons' className='flex'>
                <ButtonGroup>
                    <Button size='big' onClick={props.onCancel}>
                        Cancel
                    </Button>
                    <SaveButton
                        isSaving={props.isSaving}
                        onClearSaveComplete={
                            props.onClearSaveComplete ? props.onClearSaveComplete : undefined
                        }
                        savingText={props.savingText}
                        successText={props.successText}
                        onClick={props.onSaveClick}
                        disabled={props.saveButtonDisabled}
                        errorSaving={props.errorSaving}
                        // This is to avoid empty quotes from being used, mostly in storybook
                        buttonText={props.buttonText || undefined}
                        saveCompleteTimeout={1000}
                        resetOnComplete={props.resetOnSaveComplete}
                    />
                </ButtonGroup>
            </div>
        </section>
    );
}
