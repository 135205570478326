/* @flow */

import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';

import * as actions from './list-selection-actions';

export const listSelectionDefaultState = {
    items: [],
    isExcluded: false,
    lastSelectedItem: null,
};

function selectListRowItem(state, entityId) {
    if (state.items.includes(entityId)) {
        return {
            ...state,
            items: state.items.filter((item) => item !== entityId),
            lastSelectedItem: entityId,
        };
    }

    return {...state, items: state.items.concat(entityId), lastSelectedItem: entityId};
}

export const listSelectionActionsReducer = createReducer(
    {
        // $FlowIgnore - this works correctly from redux-act
        [actions.selectListRowItem]: (state, {entityId}) => {
            return selectListRowItem(state, entityId);
        },
        [actions.selectAllRows]: (state) => {
            if (state.items.length > 0) {
                // If we have toggled some items, toggling select all should
                // always select all
                return {...state, items: [], isExcluded: true, lastSelectedItem: null};
            }

            // If we haven't toggled any items, this should just toggle between
            // selecting all items and unselecting all items
            return {...state, items: [], isExcluded: !state.isExcluded, lastSelectedItem: null};
        },
        // $FlowIgnore - this works correctly from redux-act
        [actions.shiftSelectListRowItem]: (state, {entityId, listItems}) => {
            if (!state.lastSelectedItem) {
                return selectListRowItem(state, entityId);
            }

            const listIds = listItems.map((item) => item.id);

            const lowerIndex = listIds.indexOf(state.lastSelectedItem);
            const upperIndex = listIds.indexOf(entityId);

            if (lowerIndex === -1 || upperIndex === -1) {
                // This should never happen, but if it does, reset the last
                // selected item
                return {...state, lastSelectedItem: null};
            }

            // Get the array of items between the 2 selection points
            const newSelectedItems =
                lowerIndex < upperIndex
                    ? listIds.slice(lowerIndex, upperIndex + 1)
                    : listIds.slice(upperIndex, lowerIndex + 1);

            if (state.items.includes(entityId)) {
                // If the item we just clicked is already in the list of items,
                // so we want to remove all the items from our state.
                return {
                    ...state,
                    items: state.items.filter((item) => !newSelectedItems.includes(item)),
                    lastSelectedItem: entityId,
                };
            }

            return {
                ...state,
                items: state.items.concat(
                    newSelectedItems.filter((item) => !state.items.includes(item))
                ),
                lastSelectedItem: entityId,
            };
        },
        [actions.resetSelection]: () => {
            return {...listSelectionDefaultState};
        },
        [actions.resetLastSelectedItem]: (state) => {
            return {...state, lastSelectedItem: null};
        },
    },
    listSelectionDefaultState
);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const listSelectionReducer = combineReducers({
    selectedItems: listSelectionActionsReducer,
});

// This is the type for list selection. When the list is unselected, and
// individual items are selected, we add the id of those to the items list. If
// the whole list is selected, using the select all checkbox, we should change
// the isExcluded value to true, and empty the list of items. As rows are
// unselected, we add them to the list of items.
export type PeopleListSelectionState = {
    selectedItems: {
        items: string[],
        isExcluded: boolean,
        lastSelectedItem: ?string,
    },
};
