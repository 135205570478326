/* @flow */

import * as React from 'react';
import {WEEK, MONTH} from './calendar-constants';
import './day-of-week-bar.css';

export const ONE_DIGIT_DAY_FORMAT = 'd';
export const THREE_DIGIT_DAY_FORMAT = 'ddd';

const getDaysOfTheWeek = (startInt: number) => {
    const orderedDates = [];
    const daysOfTheWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];

    for (let i = 0; i < 7; i++) {
        const index = (7 + i + startInt) % 7;
        orderedDates.push(daysOfTheWeek[index]);
    }

    return orderedDates;
};

export type DisplayMode = 'WEEK' | 'MONTH';
export type DayDisplayFormat = 'd' | 'ddd' | 'dddd';

type Props = {
    displayMode: DisplayMode,
    startOfWeekInt: number,
    dateFormat?: DayDisplayFormat,
    customContainerStyles?: Object,
};

export class DayOfWeekBar extends React.Component<Props> {
    static defaultProps = {
        startOfWeekInt: 0,
        displayMode: MONTH,
    };

    render() {
        const dateFormat = this.getDateFormat();

        return (
            <div
                style={this.props.customContainerStyles}
                styleName={this.props.displayMode === WEEK ? 'container-week' : 'container'}
            >
                {getDaysOfTheWeek(this.props.startOfWeekInt).map((dayOfWeek) => {
                    let text = dayOfWeek;
                    if (dateFormat === ONE_DIGIT_DAY_FORMAT) {
                        text = dayOfWeek.substring(0, 1);
                    } else if (dateFormat === THREE_DIGIT_DAY_FORMAT) {
                        text = dayOfWeek.substring(0, 3);
                    }

                    return (
                        <div key={dayOfWeek} styleName='text'>
                            {text}
                        </div>
                    );
                })}
            </div>
        );
    }

    getDateFormat = () => {
        if (this.props.dateFormat) {
            return this.props.dateFormat;
        }

        return this.props.displayMode === WEEK ? THREE_DIGIT_DAY_FORMAT : ONE_DIGIT_DAY_FORMAT;
    };
}
