/* @flow */

import * as React from 'react';
import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';

type Props = {
    fill?: string,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} fill={props.fill || colors.grey} viewBox='0 0 22 22'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2 5a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H2Zm11.593 6.667a.74.74 0 0 0 .74-.741v-.74a.74.74 0 0 0-.74-.741H6.186a.74.74 0 0 0-.741.74v.741c0 .41.331.74.74.74h7.408Z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'shortText';

export const ShortTextIcon = svgIconWrapper(SvgComponent);
