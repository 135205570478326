/* @flow */

import * as uiActions from './ui-actions';
import * as uiSelectors from './ui-selectors';
import {uiReducer} from './ui-reducer';

export const SalesAutomationUi = {
    uiReducer,
    ...uiSelectors,
    ...uiActions,
};

export type {SalesAutomationUiState} from './ui-reducer';
