/* @flow */

import * as React from 'react';

import {Modal} from './modal';

import './two-pane-modal.css';

type Props = {
    rightSection: React.Node,
    leftSection: React.Node,
    footerBar?: React.Node,
    rightPaneWidth?: number,
};

export function TwoPaneModal(props: Props) {
    const {rightSection, leftSection, footerBar, rightPaneWidth, ...otherProps} = props;

    return (
        <Modal {...otherProps} minHeight={500}>
            <div styleName='container'>
                <div
                    styleName='left-container'
                    style={{width: rightPaneWidth ? `calc(100% - ${rightPaneWidth}px` : '50%'}}
                >
                    {leftSection}
                </div>
                <div styleName='right-container' style={{width: rightPaneWidth || '50%'}}>
                    {rightSection}
                </div>
            </div>
            <div styleName='footer-container'>{footerBar}</div>
        </Modal>
    );
}
