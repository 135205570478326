/* @flow */

import * as React from 'react';

import {NothingFoundEmptyState} from 'shells/empty-states/nothing-found-empty-state';
import {EmptyTimeline} from 'shells/empty-states/empty-timeline';
import {Button} from 'shells/button';

import './timeline-empty-state.css';

type Props = {
    onRemoveAllFilters?: () => void,
};

export function TimelineEmptyState(props: Props) {
    return (
        <div styleName='empty-state'>
            <div styleName='empty-state-msg'>
                <NothingFoundEmptyState
                    title='No matching entries'
                    subtitle={
                        <Button
                            variant='text-primary'
                            styleName='no-padding'
                            onClick={props.onRemoveAllFilters}
                        >
                            Remove filters
                        </Button>
                    }
                />
            </div>

            <EmptyTimeline />
        </div>
    );
}
