/* @flow */

import numeral from 'numeral';
import * as ramda from 'ramda';
import type {
    ReportBucket,
    ReportFacet,
    ReportsResponse,
    TimeseriesReportData,
    ChartBucket,
    ChartBucketData,
    ChartBucketSegments,
    ChartBucketSummary,
    ChartBucketReportData,
    ForecastReportSummary,
    LeadReportSummaryValue,
    ReportRowData,
    ReportCellData,
    Report,
    ReportMap,
    CountAndTotalValues,
} from './types';

export const QUOTA_REPORT_KEY = 'quota';
export const QUOTA_REPORT_NAME = 'Quota';
export const PIPELINE_WEIGHTED_REPORT_KEY = 'pipeline_weighted';
export const PIPELINE_REPORT_KEY = 'pipeline';
export const SALES_REPORT_KEY = 'sales';
const OTHER_BUCKET_NAME = 'Other';
const NONE_BUCKET_NAME = 'None';

/**
 * Maps report json into report-ready mappable buckets and keys for our
 * charting library to consume.
 *
 * @param  {object} json  Full json-payload from a reports response
 * @return {object}       Object containing buckets and corresponding
 *                        name/prefix maps for keys found in those buckets.
 */
export function jsonToTimeseriesReportData(json: ReportsResponse): TimeseriesReportData {
    const reportMaps: Array<ReportMap> = json.reports.map((report) => ({
        id: report.id,
        name: report.name,
        prefix: report.prefix,
        key: report.key,
    }));

    return {
        buckets: consolidateReportsIntoBuckets(json.reports),
        summaries: json.reports.reduce((reportAccum, report) => {
            reportAccum[report.id] = {
                count: report.summary.count.value,
                total: report.summary.total && report.summary.total.value,
                segments: extractSegmentCountsAndTotals(report.summary.segments),
            };

            return reportAccum;
        }, {}),
        reports: reportMaps,
    };
}

/**
 * Maps an array of reports into consumable data for our charting library.
 *
 * Returns an array of buckets, where each bucket contains data for that period
 * of time.
 *
 * @param  {object[]} reports   Array of reports, each containing an array
 *                              of buckets to be consolidated with other reports
 * @return {object[]}           Array of ChartBuckets (see flow type definition)
 */
// $FlowFixMe (@ianvs): I've been scratching my head on this for 45 minutes with zero luck.  It has to do with getForecastReportIds taking Report and ReportMap arrays
function consolidateReportsIntoBuckets(reports: Array<Report>): Array<ChartBucket> {
    // First, lets loop through the reports and combine them together per bucket
    // What we end up with will be close to the final format, but missing summary data
    // and it will be object map by bucket name, rather than an array.
    const reportBucketData = reports.reduce((reportAccum, report: Report) => {
        report.data.forEach((bucket: ReportBucket) => {
            const bucketSegments = extractSegmentCountsAndTotals(bucket.segments);
            const reportData: ChartBucketReportData = {
                [report.id]: {
                    total: bucket.total && bucket.total.value,
                    count: bucket.count.value,
                    segments: bucketSegments,
                },
            };

            if (reportAccum[bucket.bucket]) {
                reportAccum[bucket.bucket].reportData = {
                    ...reportAccum[bucket.bucket].reportData,
                    ...reportData,
                };
            } else {
                reportAccum[bucket.bucket] = {
                    filters: bucket.filters,
                    reportData,
                };
            }
        });

        return reportAccum;
    }, {});

    // The second step is to add summary data for each bucket.
    // If this is a forecast result, we need to total Sales + Pipeline as well as
    // Sales + Pipeline Weighted.
    // If it's any other report, we should only have one non-quota report, so we can
    // just use that for the summary.

    // Look for the report IDs of a forecast report.
    const {salesReportId, pipelineReportId, pipelineWeightedReportId} = getForecastReportIds(
        reports
    );

    // If we find the forecast report ids, then it's a forecast report and we need to add up the reports.
    const isForecast = Boolean(salesReportId && pipelineReportId && pipelineWeightedReportId);
    if (isForecast) {
        const bucketNames = Object.keys(reportBucketData);

        return bucketNames.map((bucketName) => {
            let summary;
            let summaryWeighted;
            const bucket: ChartBucketData = reportBucketData[bucketName];
            // I really wish flow didn't forget that we _just_ checked these.  :anger:
            if (salesReportId && pipelineReportId && pipelineWeightedReportId) {
                const salesReportData = bucket.reportData[salesReportId];
                const pipelineReportData = bucket.reportData[pipelineReportId];
                const pipelineWeightedReportData = bucket.reportData[pipelineWeightedReportId];
                summary = {
                    total: salesReportData.total + pipelineReportData.total,
                    count: salesReportData.count + pipelineReportData.count,
                    segments: ramda.mergeWith(
                        addCountsAndTotals,
                        salesReportData.segments,
                        pipelineReportData.segments
                    ),
                };
                summaryWeighted = {
                    total: salesReportData.total + pipelineWeightedReportData.total,
                    count: salesReportData.count + pipelineWeightedReportData.count,
                    segments: ramda.mergeWith(
                        addCountsAndTotals,
                        salesReportData.segments,
                        pipelineWeightedReportData.segments
                    ),
                };
            }

            // Add the summaries to the bucket data we already have
            const bucketData: ChartBucketData = {
                ...bucket,
                summary,
                summaryWeighted,
            };

            // Add the name to the bucket data, creating the final shape we want
            return {
                name: bucketName,
                bucketData,
            };
        });
    }

    // This isn't a forecast report, so we just need to find the non-quota report
    // and save its bucket reportData off as the `summary`
    let nonQuotaReportId;
    const bucketNames = Object.keys(reportBucketData);
    const nonQuotaReports = reports.filter((report) => report.key !== QUOTA_REPORT_KEY);
    if (nonQuotaReports.length === 1) {
        nonQuotaReportId = nonQuotaReports[0].id;
    }

    return bucketNames.map((bucketName) => {
        const bucket: ChartBucketData = reportBucketData[bucketName];

        // Add the summaries to the bucket data we already have
        const bucketData: ChartBucketData = {
            ...bucket,
            summary: nonQuotaReportId ? bucket.reportData[nonQuotaReportId] : undefined,
        };

        // Add the name to the bucket data, creating the final shape we want
        return {
            name: bucketName,
            bucketData,
        };
    });
}

/**
 * Given two objects containing counts and totals, add them together.
 * Meaning, return a new object containing the sum `total` and sum `count`.
 *
 * @param {Object} obj1 - Object to be added
 * @param {Object} obj2 - Object to be added
 * @return {Object}     - Sum of two objects
 */
function addCountsAndTotals(obj1: CountAndTotalValues, obj2: CountAndTotalValues) {
    if (obj1.total !== null && obj2.total !== null) {
        return {count: obj1.count + obj2.count, total: obj1.total + obj2.total};
    }

    return {count: obj1.count + obj2.count, total: null};
}

/**
 * Given an array of reports, pull out the ids that are specific to the forecast
 * report.
 *
 * @param  {Object[]} reports     - Array of report objects to find forecast report ids
 * @return {Object}               - Object containing up to three forecast id strings,
 *                                  with matching keys and values
 */
function getForecastReportIds(reports?: Array<ReportMap>) {
    if (!reports)
        return {salesReportId: null, pipelineReportId: null, pipelineWeightedReportId: null};

    const salesReport = reports.find((report) => report.key === SALES_REPORT_KEY);
    const salesReportId = salesReport && salesReport.id;
    const pipelineReport = reports.find((report) => report.key === PIPELINE_REPORT_KEY);
    const pipelineReportId = pipelineReport && pipelineReport.id;
    const pipelineWeightedReport = reports.find(
        (report) => report.key === PIPELINE_WEIGHTED_REPORT_KEY
    );
    const pipelineWeightedReportId = pipelineWeightedReport && pipelineWeightedReport.id;

    return {salesReportId, pipelineReportId, pipelineWeightedReportId};
}

/**
 * Create a map of segments to their raw totals and counts.
 *
 * @param  {Object[]} segments - Array of segments from a report response.
 * @return {Object}            - Map of segment name to their `total` and `count` values
 */
function extractSegmentCountsAndTotals(
    segments: Array<ReportBucket>
): {[segmentName: string]: CountAndTotalValues} {
    return segments.reduce((accum, segment) => {
        accum[segment.bucket] = {
            total: segment.total && segment.total.value,
            count: segment.count.value,
        };

        return accum;
    }, {});
}

type SegmentInfo = {[segmentName: string]: CountAndTotalValues};

/**
 * Get the summaries of the segments in the main reports.  The main report is normally
 * the non-quota report, but in the case of forecast report, they are the sales and
 * pipeline reports, which we combine together here.
 * @param  {Object} summaries  - The summaries of each report, keyed by report id
 * @param  {Object[]} reports  - List of basic report information
 * @return {Object|null}         Total count and value of each segment present in the main reports
 */
function getOverallSegmentSummaries(
    summaries?: {[reportId: string]: ChartBucketSummary},
    reports?: Array<ReportMap>
): ?SegmentInfo {
    if (!summaries || !reports) return null;

    let summarySegments: ?SegmentInfo;
    const {salesReportId, pipelineReportId} = getForecastReportIds(reports);

    if (salesReportId && pipelineReportId) {
        // Since this is apparently a forecast report, we need to combine the sales and pipeline segments
        const salesReportSummary = summaries[salesReportId];
        const pipelineReportSummary = summaries[pipelineReportId];
        summarySegments = ramda.mergeWith(
            addCountsAndTotals,
            salesReportSummary.segments,
            pipelineReportSummary.segments
        );
    } else {
        const nonQuotaReports = reports.filter((report) => report.key !== QUOTA_REPORT_KEY);
        if (nonQuotaReports.length === 1) {
            const nonQuotaReportId = nonQuotaReports[0].id;
            summarySegments = summaries[nonQuotaReportId].segments;
        }
    }

    if (!summarySegments || !Object.keys(summarySegments).length) {
        return null;
    }

    return summarySegments;
}

/**
 * This is used to attempt to get segment summaries from the current data,
 * and if that isn't possible (perhaps because there is no current data, and we
 * only have reference data), then we will fall back to getting segment summaries
 * from the reference data.
 *
 * @param  {Object} timeseriesReportData Report data that has already been run through consolidateReportsIntoBuckets
 *                                       and possibly combined with reference results as well
 * @return {Object|null}                 An object containg overall count and value for each segment.
 */
export function getCurrentOrReferenceSegmentSummaries(
    timeseriesReportData?: TimeseriesReportData
): ?SegmentInfo {
    if (!timeseriesReportData) return null;

    let summarySegments = getOverallSegmentSummaries(
        timeseriesReportData.summaries,
        timeseriesReportData.reports
    );

    // Fall back to reference segments if there weren't any segments
    if (!summarySegments) {
        summarySegments = getOverallSegmentSummaries(
            timeseriesReportData.referenceSummaries,
            timeseriesReportData.referenceReports
        );
    }

    return summarySegments;
}

/**
 * Given timeseries report data, limit the number of segments to some specified max,
 * and group the others together into an "Other" segment. Segments named "None" are not
 * effected, and so are not included in the max.
 *
 * For example, if you have 4 segments, "a", "b", "c", and "None", and specify a max of 2,
 * you will get something like "a", "Other", and "None".
 *
 * If you have those same segments, and specify a max of 3, no bucketing will be done and
 * the result will be the same as the input, "a", "b", "c", and "None".
 *
 * The segments to include will be chosen based on the highest counts or values across all
 * buckets.
 *
 * @param  {Object} timeseriesReportData   - Report data that has already been run through consolidateReportsIntoBuckets.
 * @param  {number} maxSegments            - The max number of segments to show, including "Other".
 * @param  {string} [orderByFacet='count'] - Should the segments be ordered by total or count.  This impacts which get grouped into Other.
 * @param  {Ojbect} [segmentInfo]          - Segment summaries from getOverallSegmentSummaries.  If provided, will be used instead of being calculated internally.
 * @return {Object[]}                      - New ChartBuckets, with segments grouped into "Other" as needed.
 */
export function groupOtherSegments(
    timeseriesReportData: TimeseriesReportData,
    maxSegments: number,
    orderByFacet: ReportFacet = 'count',
    segmentInfo?: ?SegmentInfo
): {buckets: Array<ChartBucket>, sortedSegmentNames?: Array<string>} {
    // We need a way to judge which segments to keep.
    // We do that by looking at the total count or value across all buckets,
    // and in the case of forecast reports, we also add the sales and pipeline reports together.
    const summarySegments =
        segmentInfo || getCurrentOrReferenceSegmentSummaries(timeseriesReportData);

    // We weren't able to find any segments at all, so just return the buckets as they are.
    if (!summarySegments) {
        return {buckets: timeseriesReportData.buckets};
    }

    // Unlikely, but if we ever group by total in the future and then provide
    // summary segments that don't have totals (like in an activity report) we
    // need some protection.  We'll just group by count instead, and throw a log.
    if (
        orderByFacet === 'total' &&
        ramda.values(summarySegments).some((seg) => seg.total === null)
    ) {
        /* eslint-disable no-param-reassign */
        orderByFacet = 'count';
        /* eslint-enable no-param-reassign */
        if (typeof window.trackJ !== 'undefined') {
            window.trackJs.track(
                'Changed the orderByFacet when grouping `other` segments in a chart.'
            );
        }
    }

    const segmentNames = Object.keys(summarySegments);

    const segmentCount = segmentNames.length;

    // Do we have a "None" segment?
    const containsNone = segmentNames.includes(NONE_BUCKET_NAME);
    const adjustedSegmentCount = containsNone ? segmentCount - 1 : segmentCount;

    // Determine which segments to keep, based on the orderByFacet.
    const sortedSegmentNames = segmentNames.sort((a, b) => {
        // This is mostly just satisfying flow.  We _should_ always have a summarySegments here, and the segments are all in it.
        if (
            !summarySegments ||
            !summarySegments[b] ||
            !summarySegments[a] ||
            summarySegments[b][orderByFacet] === undefined ||
            summarySegments[b][orderByFacet] === null ||
            summarySegments[a][orderByFacet] === undefined ||
            summarySegments[a][orderByFacet] === null
        )
            return 0;

        return summarySegments[b][orderByFacet] - summarySegments[a][orderByFacet];
    });

    // If we have fewer segments than the maximum number of segments
    // we're allowing, just return the buckets as they are. There is no need
    // for an "Other" bucket, as no segments are being hidden
    if (adjustedSegmentCount <= maxSegments) {
        // We want to override the sort order of activity lead outcomes
        const activityLeadOutcomes = ['Won', 'Lost/Cancelled', 'Unknown'];
        const isLeadOutcome =
            ramda.difference(sortedSegmentNames, activityLeadOutcomes).length === 0;
        if (timeseriesReportData.reports[0].name === 'Activities' && isLeadOutcome) {
            return {
                buckets: timeseriesReportData.buckets,
                sortedSegmentNames: activityLeadOutcomes,
            };
        }

        return {buckets: timeseriesReportData.buckets, sortedSegmentNames};
    }

    const truncatedSegmentNames = sortedSegmentNames.slice(0, maxSegments - 1);

    // If we have more segments than the maximum number we're allowing, we do
    // need to tack on an additional "Other" bucket name, since we know
    // we'll have additional "hidden" segment data rolled up into that bucket
    truncatedSegmentNames.push(OTHER_BUCKET_NAME);
    if (containsNone) {
        // Move 'None' to the end
        truncatedSegmentNames.filter((name) => name !== NONE_BUCKET_NAME).push(NONE_BUCKET_NAME);
    }

    // Generate a list of segments to "keep", i.e., _not_ always show in the UI
    // and omit from our accumlation of an "Other" bucket.
    //
    // We'll always display the "None" segment, so we tack that on here.
    const segmentNamesToKeep = truncatedSegmentNames.concat(NONE_BUCKET_NAME);

    // Now, we need to loop through our bucket and reference bucket data,
    // and accumulate values for our "Other" segments, since we determined
    // above that we're unable to show all the segments at once
    const buckets = timeseriesReportData.buckets.map((bucket) => {
        // Save these for later
        const bucketReportData = bucket.bucketData && bucket.bucketData.reportData;
        const bucketReportIds = bucketReportData ? Object.keys(bucketReportData) : undefined;
        const referenceBucketReportData =
            bucket.referenceBucketData && bucket.referenceBucketData.reportData;
        const referenceBucketReportIds = referenceBucketReportData
            ? Object.keys(referenceBucketReportData)
            : undefined;

        // Accumulate summary segments
        let summary;
        if (bucket.bucketData) {
            const existingSummary = bucket.bucketData.summary;
            const newBucketSummarySegments = segmentNames.reduce(
                accumulateOtherSegments(bucket.bucketData.summary.segments, segmentNamesToKeep),
                {}
            );
            summary = {...existingSummary, segments: newBucketSummarySegments};
        }
        // Accumulate reference summary segments
        let referenceSummary;
        if (bucket.referenceBucketData) {
            const oldReferenceSummary = bucket.referenceBucketData.summary;
            const newReferenceBucketSummarySegments = segmentNames.reduce(
                accumulateOtherSegments(
                    bucket.referenceBucketData.summary.segments,
                    segmentNamesToKeep
                ),
                {}
            );
            referenceSummary = {
                ...oldReferenceSummary,
                segments: newReferenceBucketSummarySegments,
            };
        }

        // Accumulate summaryWeighted segments
        let summaryWeighted;
        if (bucket.bucketData && bucket.bucketData.summaryWeighted) {
            const existingSummaryWeighted = bucket.bucketData.summaryWeighted;
            const newBucketSummaryWeightedSegments = segmentNames.reduce(
                accumulateOtherSegments(
                    bucket.bucketData.summaryWeighted.segments,
                    segmentNamesToKeep
                ),
                {}
            );
            summaryWeighted = {
                ...existingSummaryWeighted,
                segments: newBucketSummaryWeightedSegments,
            };
        }
        // Accumulate reference summaryWeighted segments
        let referenceSummaryWeighted;
        if (bucket.referenceBucketData && bucket.referenceBucketData.summaryWeighted) {
            const oldReferenceSummaryWeighted = bucket.referenceBucketData.summaryWeighted;
            const newReferenceBucketSummaryWeightedSegments = segmentNames.reduce(
                accumulateOtherSegments(
                    bucket.referenceBucketData.summaryWeighted.segments,
                    segmentNamesToKeep
                ),
                {}
            );
            referenceSummaryWeighted = {
                ...oldReferenceSummaryWeighted,
                segments: newReferenceBucketSummaryWeightedSegments,
            };
        }

        // Accumulate segments for each report in reportData
        let reportData: ?ChartBucketReportData;
        if (bucketReportIds && bucketReportData) {
            reportData = bucketReportIds.reduce((reportAccum, reportId) => {
                const reportSegments = bucketReportData[reportId].segments;
                const segments = segmentNames.reduce(
                    accumulateOtherSegments(reportSegments, segmentNamesToKeep),
                    {}
                );
                reportAccum[reportId] = {...bucketReportData[reportId], segments};

                return reportAccum;
            }, {});
        }

        let referenceReportData: ?ChartBucketReportData;
        if (referenceBucketReportIds && referenceBucketReportData) {
            referenceReportData = referenceBucketReportIds.reduce((reportAccum, reportId) => {
                const reportSegments = referenceBucketReportData[reportId].segments;
                const segments = segmentNames.reduce(
                    accumulateOtherSegments(reportSegments, segmentNamesToKeep),
                    {}
                );
                reportAccum[reportId] = {...referenceBucketReportData[reportId], segments};

                return reportAccum;
            }, {});
        }

        // Build the new bucketData with changed summary and reportData
        const bucketData =
            bucket.bucketData && summary && reportData
                ? {...bucket.bucketData, summary, summaryWeighted, reportData}
                : undefined;
        const referenceBucketData =
            bucket.referenceBucketData && referenceSummary && referenceReportData
                ? {
                      ...bucket.referenceBucketData,
                      summary: referenceSummary,
                      summaryWeighted: referenceSummaryWeighted,
                      reportData: referenceReportData,
                  }
                : undefined;

        // Return the bucket with the new bucketData
        return {...bucket, bucketData, referenceBucketData};
    });

    return {buckets, sortedSegmentNames: truncatedSegmentNames};
}

/**
 * This is a thunk which returns a function that can be used inside a reduce
 * of segment names.
 *
 * The returned function will add up the total and count for any segments that are
 * not in `segmentNamesToKeep`, putting them together into an "Other" segment.
 *
 * @param  {object} segments             - An object with keys of segment names and values of count & total objects
 * @param  {string[]} segmentNamesToKeep - An array of segment names that should not be accumulated
 * @return {function}                    - A function that can be used in a reduce of segment names
 */
export function accumulateOtherSegments(
    segments: ChartBucketSegments,
    segmentNamesToKeep: Array<string>
) {
    return function(accum: ChartBucketSegments, segmentName: string) {
        if (segmentNamesToKeep.includes(segmentName)) {
            accum[segmentName] = segments[segmentName];
        } else {
            const segmentsHaveTotalFacet = ramda
                .values(segments)
                .every((seg) => seg.total !== null);
            if (!accum[OTHER_BUCKET_NAME])
                accum[OTHER_BUCKET_NAME] = {total: segmentsHaveTotalFacet ? 0 : null, count: 0};

            if (segments[segmentName]) {
                accum[OTHER_BUCKET_NAME] = {
                    total: segments[segmentName].total
                        ? accum[OTHER_BUCKET_NAME].total + segments[segmentName].total
                        : accum[OTHER_BUCKET_NAME].total,
                    count: accum[OTHER_BUCKET_NAME].count + segments[segmentName].count,
                };
            }
        }

        return accum;
    };
}

/**
 * For each bucket in a TimeseriesReportData object, add in the bucket and report data from
 * another TimeseriesReportData object from another time period.
 *
 * @param {object}   chartData        - The original (current) timeseries data
 * @param {Object}   referenceData    - Timeseries data from a different time range that is being compared
 * @return {Object}                   - Timeseries data with reference buckets added in
 */
export function addReferenceData(
    chartData: TimeseriesReportData,
    referenceData: TimeseriesReportData
): TimeseriesReportData {
    // When adding reference data, we're unable to gaurentee that the number
    // of reference buckets is equal to the number of chartData buckets.
    // (i.e. comparing a range of 4 weeks with a range of 5 weeks)
    //
    // We'll use an iterator function to ensure we're still building data
    // while either data source still has values
    const combine = (index) => {
        const chartBucket = chartData.buckets[index];
        const referenceBucket = referenceData.buckets[index];

        if (chartBucket && referenceBucket) {
            return [
                {
                    ...chartBucket,
                    referenceBucketData: referenceBucket.bucketData,
                    referenceName: referenceBucket.name,
                },
                index + 1,
            ];
        }

        if (chartBucket) {
            return [chartBucket, index + 1];
        }

        if (referenceBucket) {
            return [
                {
                    referenceBucketData: referenceBucket.bucketData,
                    referenceName: referenceBucket.name,
                },
                index + 1,
            ];
        }

        // No chartBucket or referenceBucket, so stop iterating
        return false;
    };

    const bucketsWithReference = ramda.unfold(combine, 0);

    return {
        ...chartData,
        buckets: bucketsWithReference,
        referenceReports: referenceData.reports,
        referenceSummaries: referenceData.summaries,
    };
}

/**
 * Exported function to essentially flip our report response into a "by row"
 * structure that can consumed by tables. Also includes a convenient `reports`
 * array that has ids and names of the reports.
 *
 * @param  {object} json  Full json-payload from a reports response
 * @return {object}       Object containing tabular row data and corresponding
 *                        name/prefix maps for keys found in those buckets.
 */
export function jsonToReportTableData(json: ReportsResponse): ?Array<ReportRowData> {
    if (!json || !json.reports) return null;

    return mapReportDataForTable(json.reports, false);
}

export function jsonToReportTableTotalsData(json: ReportsResponse): ReportRowData {
    return mapReportDataForTable(json.reports, true)[0];
}

/**
 * Maps an array of reports into consumable data for a "by-row" table
 *
 * Returns an array of rows, with each row potentially containing "child" rows
 * that act as segments. This array looks like such:
 *
 * [{
 *     title: 'Feb 5 2017',
 *     totalsByReport: [{
 *         id: 5898f6ce24c2b-reports,
 *         type: 'reports',
 *         name: 'Sales',
 *         prefix: '$',
 *         total: {
 *             formatted: '24.0',
 *             prefix: '$',
 *             suffix: 'k',
 *             value: 24000,
 *         },
 *         count: {
 *             formatted: '17',
 *             prefix: '',
 *             suffix: '',
 *             value: 3,
 *         },
 *     }, {
 *        ...however many more reports
 *     }],
 *     segments: [{
 *         title: 'Sabre Laserwriter',
 *         totalsByReport: [{
 *             id: 5898f6ce24c2b-reports-Sabre Laserwriter,
 *             type: 'products',
 *             name: Sabre Laserwriter,
 *             prefix: '$',
 *             count: {
 *                 formatted: '18.1',
 *                 prefix: '$',
 *                 suffix: 'k',
 *                 value: 18102,
 *             },
 *             total: {
 *                 formatted: '12',
 *                 prefix: '',
 *                 suffix: '',
 *                 value: 12,
 *             }
 *         }, {
 *             ...however many more reports
 *         }],
 *     }, {
 *         ...however many more segments
 *     }]
 * },
 * {
 *     title: 'Feb 12 2017',
 *     ...etc
 * }, ...],
 *
 *
 * @param  {Report[]} reports   Array of reports, each containing an array
 *                              of buckets to be consolidated with other reports
 * @param  {boolean} shouldBeSummary If true, summary (totals) will be returned.
 * @return {ReportRowData[]}           Array of report buckets (see above)
 */
function mapReportDataForTable(
    reports: Array<Report>,
    shouldBeSummary: boolean
): Array<ReportRowData> {
    const segmentedBuckets = [];

    reports.forEach((report) => {
        if (!shouldBeSummary && (!report.data || !report.data.length)) return;
        if (shouldBeSummary && !report.summary) return;

        let reportBuckets;
        if (shouldBeSummary && report.summary) {
            reportBuckets = [report.summary];
        } else if (report.data && report.data.length) {
            reportBuckets = report.data;
        } else {
            return;
        }

        reportBuckets.forEach((bucket) => {
            // We know for each bucket, we'll need an object of "rolled-up"
            // values, i.e. summary of all segments in the report.
            const reportSegment = {
                id: report.id,
                type: report.type,
                name: report.name,
                prefix: report.prefix,
                total: bucket.total,
                count: bucket.count,
                benchmark: bucket.benchmark,
                avatarUrl: bucket.avatarUrl,
            };

            // If we already have data (from other reports) pertaining to this
            // bucket, we want to tack on our new data, instead of creating
            // an entirely new row.
            const existingSegmentedBucket = segmentedBuckets.find((segmentedBucket) => {
                return segmentedBucket.title === bucket.bucket;
            });

            // If this _is_ a new row (i.e. the first time we've seen this
            // bucket), we'll generate our top-level row object, and tack on
            // the report's information (the cellData)
            if (!existingSegmentedBucket) {
                const segmentedBucketData = {
                    title: bucket.bucket,
                    avatarUrl: bucket.avatarUrl,
                    totalsByReport: [reportSegment],
                    // Since this is the first time we're encountering this
                    // bucket, we have to go through the same process with the
                    // array of segments.
                    segments: generateNewSegmentsRowForReport(report, bucket),
                };

                // Add our new bucket to our big 'ole array of buckets
                segmentedBuckets.push(segmentedBucketData);

                // If this is _not_ a new bucket, just tack on our new reportSegment,
                // and loop through our bucket's segments, tacking on our new report's
                // data for _each_ segment in the array.
                //
                // Example: we're adding 'Pipeline' values to each row of segmented data.
            } else {
                existingSegmentedBucket.totalsByReport.push(reportSegment);

                if (existingSegmentedBucket.segments) {
                    existingSegmentedBucket.segments.forEach((segment, index) => {
                        if (bucket.segments[index]) {
                            segment.totalsByReport.push(
                                generateSegmentCellData(report, bucket.segments[index])
                            );
                        }
                    });
                }
            }
        });
    });

    return segmentedBuckets;
}

/**
 * Helper method to loop through a report bucket's segments, and generate an array
 * of cell data for each segment. This therefore returns _row_ data
 * @param  {Report} report - Report that contains the bucket's we're looping through
 * @param  {ReportBucket} bucket - Report bucket that loop through and grab the segments from
 * @return {ReportRowData[]}        A row of segmented cell data
 */
function generateNewSegmentsRowForReport(
    report: Report,
    bucket: ReportBucket
): Array<ReportRowData> {
    const segmentBuckets = [];

    bucket.segments.forEach((segment) => {
        const newSegmentBucket = {
            title: segment.bucket,
            totalsByReport: [generateSegmentCellData(report, segment)],
            avatarUrl: segment.avatarUrl,
        };

        segmentBuckets.push(newSegmentBucket);
    });

    return segmentBuckets;
}

/**
 * Helper to generate an object of segment cell data
 * @param  {Report} report -         Report that this segmented cell is part of
 * @param  {ReportBucket}  segment - Bucket that this segment is a part of
 * @return {ReportCellData}          Generated cell data
 */
function generateSegmentCellData(report: Report, segment: ReportBucket): ReportCellData {
    return {
        id: `${report.id}-${segment.bucket}`,
        type: segment.bucketType,
        name: report.name,
        prefix: report.prefix,
        total: segment.total,
        count: segment.count,
        startTime: segment.startTime,
        endTime: segment.endTime,
        benchmark: segment.benchmark,
    };
}

/**
 * Helper function to sum up all values of a Report. This will likely be replaced by server-side logic.
 *
 * @param {Report} report The report to summarize
 * @return {FormattedValue} An object representing the summed values.
 */
function leadReportToSummary(report: Report): LeadReportSummaryValue {
    const initial = {
        // This is fine so long as this is only used for leads reports, which
        // is the case as of June 2018
        total: {
            value: 0,
            formatted: '0',
            prefix: report.prefix,
            suffix: '',
        },
        count: {
            value: 0,
            formatted: '0',
            prefix: '',
            suffix: '',
        },
    };

    return report.data.reduce((memo, bucket) => {
        // As long as this is used for leads reports, this will always be true
        if (bucket.total) {
            memo.total.value += Math.round(bucket.total.value);
            memo.total.formatted = String(memo.total.value);
        }
        memo.count.value += Math.round(bucket.count.value);
        memo.count.formatted = String(memo.count.value);

        return memo;
    }, initial);
}

/**
 * Looks up a leads report by its `key` property and converts it to a LeadsReportSummaryValue.
 *
 * @param {string} reportKey The key property to look up in the leads report object.
 * @param {Array<Report>} reports The reports array.
 * @return {ReportSummaryValue} The summary object. Default value if the report is not found.
 */
function getSummaryForLeadReportKey(
    reportKey: string,
    reports: Array<Report>
): LeadReportSummaryValue {
    const defaultValue = {
        total: {value: 0, formatted: '0', prefix: '', suffix: ''},
        count: {value: 0, formatted: '0', prefix: '', suffix: ''},
    };
    const report = reports.find((r) => r.key === reportKey);
    if (!report) {
        return defaultValue;
    }

    return leadReportToSummary(report);
}

/**
 * For a reports API response, return an object that can be consumed by summary components.
 *
 * @param {ReportsResponse} result The API response from the server.
 * @return {ReportSummary} An object that can be consumed by summary components.
 */
export function jsonToForecastSummaryData(result: ReportsResponse): ForecastReportSummary {
    const reports = result.reports;
    const sales = getSummaryForLeadReportKey('sales', reports);
    const pipeline = getSummaryForLeadReportKey('pipeline', reports);
    const pipelineWithConf = getSummaryForLeadReportKey('pipeline_weighted', reports);
    const quota = getSummaryForLeadReportKey('quota', reports);

    const projectedTotal = sales.total.value + pipeline.total.value;
    const projectedCount = sales.count.value + pipeline.count.value;
    const projectedWithConfTotal = sales.total.value + pipelineWithConf.total.value;
    const projectedWithConfCount = sales.count.value + pipelineWithConf.count.value;

    const projectedTotalFormatted = numeral(projectedTotal).format('0.00 a');
    const projectedCountFormatted = numeral(projectedCount).format('0.00 a');
    const projectedWithConfTotalFormatted = numeral(projectedWithConfTotal).format('0.00 a');
    const projectedWithConfCountFormatted = numeral(projectedWithConfCount).format('0.00 a');

    const forecastStats = {
        sales,
        pipeline,
        pipelineWithConf,
        quota,
        projected: {
            total: {
                value: projectedTotal,
                formatted: projectedTotalFormatted.split(' ')[0],
                prefix: sales.total.prefix,
                suffix: projectedTotalFormatted.split(' ')[1],
            },
            count: {
                value: projectedCount,
                formatted: projectedCountFormatted.split(' ')[0],
                prefix: '',
                suffix: projectedCountFormatted.split(' ')[1],
            },
        },
        projectedWithConf: {
            total: {
                value: projectedWithConfTotal,
                formatted: projectedWithConfTotalFormatted.split(' ')[0],
                prefix: sales.total.prefix,
                suffix: projectedTotalFormatted.split(' ')[1],
            },
            count: {
                value: projectedWithConfCount,
                formatted: projectedWithConfCountFormatted.split(' ')[0],
                prefix: '',
                suffix: projectedWithConfCountFormatted.split(' ')[1],
            },
        },
    };

    return forecastStats;
}

export function getExportLinkFromResult(result: ReportsResponse) {
    return result.meta.exportLink;
}

export function transformDataForReport(results: ReportsResponse[]) {
    let chartData;
    const currentResult = results[0];
    if (!currentResult || !Array.isArray(currentResult.reports))
        return {chartData: null, tableData: null};
    const comparisonResult = results.length > 1 && results[1];

    const currentChartData = jsonToTimeseriesReportData(currentResult);
    if (comparisonResult) {
        const referenceData = jsonToTimeseriesReportData(comparisonResult);
        chartData = addReferenceData(currentChartData, referenceData);
    } else {
        chartData = currentChartData;
    }

    const rows = jsonToReportTableData(currentResult);
    const totals = jsonToReportTableTotalsData(currentResult);
    const tableData = {rows, totals};

    return {chartData, tableData};
}
