import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Magic from './magic-icon.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Magic {...validProps} />;
}
SvgComponent.iconName = 'magic';

export const MagicIcon = svgIconWrapper(SvgComponent);
