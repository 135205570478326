/* @flow */

import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';
import {REHYDRATE} from 'redux-persist';
import type {ListItem} from 'nutshell-core/types';
import * as Schema from 'nutshell-core/schema';

import {simpleHydrate} from '../../../master-dashboard/dashboard-reducer';

import * as actions from './companies-actions';

type McfxCompaniesListState = {
    isLoading: boolean,
    isFirstLoad: boolean,
    errorMessage?: string,
    listItems: Object[],
    meta: Object,
};

const mcfxCompaniesListDefaultState = {
    isLoading: true,
    isFirstLoad: true,
    errorMessage: undefined,
    listItems: [],
    meta: {},
};

const isFilterDrawerOpen = createReducer(
    {
        [actions.toggleShowFilterDrawer]: (state) => !state,
    },
    false
);

export const mcfxCompaniesListReducer = createReducer(
    {
        // $FlowIgnore - this works correctly from redux-act
        [actions.requestMcfxCompaniesListData]: (state) => {
            return {...state, isLoading: true, errorMessage: undefined};
        },
        [actions.updateMcfxCompaniesListData]: (state, {meta, listItems}) => {
            return {
                ...state,
                isLoading: false,
                isFirstLoad: false,
                meta: meta,
                listItems: listItems
                    ? listItems.map((item: ListItem) => {
                          return {
                              ...item,
                              id: item.links ? item.links.entity.id : item.id,
                          };
                      })
                    : undefined,
                errorMessage: undefined,
            };
        },
        [actions.failMcfxCompaniesListData]: (state, err) => {
            return {...state, isLoading: false, isFirstLoad: false, errorMessage: err};
        },
    },
    mcfxCompaniesListDefaultState
);

export const mcfxCompaniesSchemaReducer = createReducer(
    {
        [actions.updateMcfxCompaniesSchema]: (state, payload) => payload,
        [REHYDRATE]: (state, persistedState) =>
            simpleHydrate(state, persistedState, 'mcfxCompaniesSchema'),
    },
    {}
);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const mcfxCompaniesReducer = combineReducers({
    mcfxCompaniesList: mcfxCompaniesListReducer,
    mcfxCompaniesSchema: mcfxCompaniesSchemaReducer,
    isFilterDrawerOpen,
});

export type McfxCompaniesState = {
    mcfxCompaniesList: McfxCompaniesListState,
    mcfxCompaniesSchema: Schema.Properties,
    isFilterDrawerOpen: boolean,
};
