/* @flow */

import * as ramda from 'ramda';
import type {SavedFilter, SavedListType, CoreEntityType} from '../types';

const SAVED_REPORT_TYPES = [
    'report_activity',
    'report_email',
    'report_funnel',
    'report_newleads',
    'report_sales',
    'report_losses',
    'report_forecast',
    'report_custom',
    'report_attribution',
];

const SAVED_LIST_TYPES = ['entity_list_accounts', 'entity_list_contacts', 'entity_list_leads'];

/**
 * Returns true if the provided saved filter is a saved report.
 *
 * @param  {Object}  savedFilter  - The saved filter object to check
 * @return {Boolean}                True if the saved filter is a saved report
 */
export const isSavedReport = (savedFilter: SavedFilter<*>) =>
    SAVED_REPORT_TYPES.includes(savedFilter.reportType);

/**
 * Returns true if the provided saved filter is a saved list.
 *
 * @param  {Object}  savedFilter  - The saved filter object to check
 * @return {Boolean}                True if the saved filter is a saved list
 */
export const isSavedList = (savedFilter: SavedFilter<*>) =>
    SAVED_LIST_TYPES.includes(savedFilter.reportType);

/**
 * Gets the "Core" entity type from a saved list
 * @param  {Object} savedFilter    - Full saved filter modal to get type from
 * @return {string}                - "Core" entity type
 */
export const getEntityTypeFromSavedList = (
    savedFilter: SavedFilter<SavedListType>
): ?CoreEntityType => {
    const entityType = savedFilter.reportType.split('_')[2];
    if (entityType === 'leads' || entityType === 'accounts' || entityType === 'contacts') {
        return entityType;
    }
};

/**
 * Given a core entity type, translate it to the corresponding saved list type
 * that its associated with
 * @param  {string} entityType        - Core entity type to get SavedListType for
 * @return {string}                   - Corresponding SavedList type
 */
export const getSavedListReportTypeFromEntityType = (entityType: CoreEntityType): SavedListType => {
    if (entityType === 'leads') return 'entity_list_leads';
    else if (entityType === 'accounts') return 'entity_list_accounts';
    else return 'entity_list_contacts';
};

/**
 * Buckets saved filters by their report type. Will always propagate the
 * passed entity type to the top.
 *
 * @param  {Object[]} savedFilters    - Array of saved filters
 * @param  {string} entityType        - Core entity type string
 * @return {Object[]}                 - Bucketed list of saved filters
 */
export function bucketSavedListsByType(
    savedFilters: SavedFilter<SavedListType>[],
    entityType: CoreEntityType
): SavedFilter<SavedListType>[] {
    // Just in case an invalid entity type is passed, return the unsorted list
    if (!['leads', 'accounts', 'contacts'].includes(entityType)) {
        return savedFilters;
    }

    // Group saved lists by their reportType key. This looks something like…
    // {
    //   'entity_list_leads': [Array],
    //   'entity_list_contacts': [Array],
    //   'entity_list_accounts': [Array],
    // }
    const groupByReportType = ramda.groupBy((filter) => filter.reportType);
    const groupedEntityList = groupByReportType(savedFilters);

    // Determine the report_type string associated with the passed
    // entityType parameter
    const prioritizedReportType = getSavedListReportTypeFromEntityType(entityType);

    // Grab the prioritized reports, or default to an empty array if there
    // aren't any.
    const prioritizedReports = groupedEntityList[prioritizedReportType] || [];

    // Now delete them from the groupedEntityList
    delete groupedEntityList[prioritizedReportType];

    // Grab the remaining type's arrays, and flatten them into one array.
    // $FlowFixMe (@ianvs)
    const remainingReports = ramda.flatten(ramda.values(groupedEntityList));

    // Concat the remaining arrays onto the prioritized reports, and return the
    // final sorted list.
    return prioritizedReports.concat(remainingReports);
}
