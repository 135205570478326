/* @flow */

import * as React from 'react';

import type {EventFragmentSparse, LeadPriority} from 'nutshell-graphql-types';

import {LinkedEntity} from 'shells/linked-entity';

import {mapLeadPriorityApiToString} from '../consts';
import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {|event: EventFragmentSparse, newPriority: string|};

export function TimelineBannerLeadPriority(props: Props) {
    const {event, newPriority} = props;

    const priorityString = mapLeadPriorityApiToString(newPriority);

    const getAction = (priority: LeadPriority, name: string, htmlUrl: string) => {
        const linkedEntity = (
            <strong>
                <LinkedEntity
                    type='leads'
                    useEntityColor={true}
                    shouldClientRoute={true}
                    name={name}
                    htmlUrl={htmlUrl}
                    allowWrap={true}
                    displayInline={true}
                    greenLeads={true}
                />
            </strong>
        );

        switch (priority) {
            case 'HOT':
                return (
                    <span>
                        marked&nbsp;
                        {linkedEntity}&nbsp;as a hot lead
                    </span>
                );
            case 'NORMAL':
                return (
                    <span>
                        marked&nbsp;
                        {linkedEntity}&nbsp;as no longer hot
                    </span>
                );
            default:
                return <></>;
        }
    };

    const getIconVariant = (priority: LeadPriority) => {
        switch (priority) {
            case 'HOT':
                return 'lead-hot';
            case 'NORMAL':
            default:
                return 'lead-normal';
        }
    };

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'Lead' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (event.payload.__typename === 'Lead') {
        const name = event.payload.name;
        const htmlUrl = event.payload.htmlUrl;

        return (
            <TimelineBanner
                id={event.id}
                iconVariant={getIconVariant(priorityString)}
                timestamp={event.changeTime}
                actor={event.actor}
                action={getAction(priorityString, name, htmlUrl)}
            />
        );
    }

    return null;
}
