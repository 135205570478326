import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 86 86'}>
            <path
                className='surface'
                d='M13.6,86h58.8C79.9,86,86,80.2,86,73.1V12.9C86,5.8,79.9,0,72.4,0H13.6C6.1,0,0,5.8,0,12.9v60.2 C0,80.2,6.1,86,13.6,86z M73.6,71.2c0,1.3-1.1,2.4-2.4,2.4H14.8c-1.2-0.2-2.1-1.2-2.2-2.4V23.9c0-1.3,1.1-2.4,2.4-2.4h56.3 c1.2,0,2.2,1,2.2,2.3c0,0,0,0,0,0c0,0,0,0.1,0,0.1L73.6,71.2z'
            />
            <path
                className='border'
                d='M13.6,3h58.8c2.8,0,5.5,1.1,7.5,3c1.9,1.8,3,4.3,3.1,6.9v60.2c0,5.5-4.8,9.9-10.6,9.9H13.6 C10.8,83,8,81.9,6,80c-1.9-1.8-3-4.3-3-6.9V12.9C3,7.4,7.8,3,13.6,3L13.6,3 M71.1,76.5L71.1,76.5c3,0,5.4-2.4,5.4-5.4c0,0,0,0,0,0 l0,0V23.9c0-1.3-0.5-2.7-1.4-3.7c-1-1.1-2.4-1.7-3.8-1.7H14.9c-3,0-5.4,2.4-5.4,5.4v47.3c0.1,2.6,2.1,4.8,4.6,5.3l0.3,0.1H71.1 M13.6,0C6.1,0,0,5.8,0,12.9v60.2C0,80.2,6.1,86,13.6,86h58.8C79.9,86,86,80.2,86,73.1V12.9C86,5.8,79.9,0,72.4,0H13.6z M71.1,73.5 H14.8c-1.2-0.2-2.1-1.2-2.2-2.4V23.9c0-1.3,1.1-2.4,2.4-2.4h56.3c1.2,0,2.2,1,2.2,2.3c0,0,0,0,0,0c0,0,0,0.1,0,0.1v47.3 C73.5,72.5,72.4,73.5,71.1,73.5L71.1,73.5z'
            />
            <path
                className='face'
                d='M31.6,34c1.9,0,3.4,1.5,3.4,3.4s-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4S29.7,34,31.6,34z M55.4,34 c1.9,0,3.4,1.5,3.4,3.4s-1.5,3.4-3.4,3.4S52,39.3,52,37.4S53.5,34,55.4,34z M43.5,51c-4.7,0-8.5,3.8-8.5,8.5c0,0,0,0,0,0l0,0h17 C52,54.8,48.2,51,43.5,51z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'activity-error-state-icon';

export const ActivityErrorStateIcon = svgIconWrapper(SvgComponent);
