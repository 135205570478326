/* @flow */

import {api} from './api';
import type {FilterObject} from './types';
import * as Schema from './schema';
import {createUrlFromNutshellFilterObject} from './create-url-from-nutshell-filter-object';

export function fetchFunnelReportData(filters: FilterObject, schema: Schema.Properties) {
    let queryString = createUrlFromNutshellFilterObject(filters, schema);
    if (queryString.length) queryString = `?${queryString}`;

    const funnelReportHref = `dashboards/funnel${queryString}`;

    return api.get(funnelReportHref).then((response) => response.json());
}
