import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import InboundCall from './icon-inbound-call.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <InboundCall {...validProps} />;
}
SvgComponent.iconName = 'inboundCall';

export const InboundCallIcon = svgIconWrapper(SvgComponent);
