/* @flow */

import type {LeadStatus, LeadPriority} from 'nutshell-graphql-types';

export const mapLeadStatusApiToString = (status: string): LeadStatus => {
    switch (status) {
        case '0':
            return 'OPEN';
        case '1':
            return 'PENDING';
        case '10':
            return 'WON';
        case '11':
            return 'LOST';
        case '12':
            return 'CANCELLED';
        default:
            return 'OPEN';
    }
};

export const mapLeadPriorityApiToString = (priority: string): LeadPriority => {
    switch (priority) {
        case '1':
            return 'HOT';
        case '0':
        default:
            return 'NORMAL';
    }
};
