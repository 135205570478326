/* @flow */

import * as React from 'react';
import './title.css';

type Props = {
    children?: React.Node,
    as: string,
};

Title.defaultProps = {
    as: 'div',
};

export function Title({children, as: Tag}: Props) {
    return <Tag styleName='title'>{children}</Tag>;
}
