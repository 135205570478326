/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import type {SavedSettingToastState} from '../saved-setting-toast';
import {FormField} from '../form';
import {RadioGroup, Radio} from '../radio';

type OwnProps = {|
    title: string,
    subtitle?: string | React.Node,
    options: {
        value: string,
        title: string,
    }[],
    isRequired?: boolean,
    labelIsTwoLines?: boolean,
    saveToastState?: SavedSettingToastState,
    errorMessage?: string,
|};

type Props = {...$Exact<FieldProps>, ...OwnProps};

export function ReduxFormRadioGroup(props: Props) {
    return (
        <FormField
            innerId={props.input.name}
            label={props.title}
            sublabel={props.subtitle}
            errorMessage={
                props.errorMessage ||
                (props.meta.error && props.meta.submitFailed ? props.meta.error : null)
            }
            isRequired={props.isRequired}
            labelIsTwoLines={props.labelIsTwoLines}
            saveToastState={props.saveToastState}
        >
            <RadioGroup
                name={props.input.name}
                selectedValue={props.input.value}
                onChange={props.input.onChange}
            >
                {props.options.map((option) => {
                    return (
                        <Radio key={option.value} value={option.value}>
                            {option.title}
                        </Radio>
                    );
                })}
            </RadioGroup>
        </FormField>
    );
}
