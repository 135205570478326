import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import PlayButtonIconSvg from './icon-play-button.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <PlayButtonIconSvg {...validProps} />;
}
SvgComponent.iconName = 'play-buttonIcon';

export const PlayButtonIcon = svgIconWrapper(SvgComponent);
