/* @flow */

import * as React from 'react';

import {TruncatedSessionContent, SessionMetadata} from 'shells/timeline/entry/content/session';
import {LoadingPage} from 'shells/loading-page';

import {TimelineEntry} from '../../../timeline/entry/timeline-entry';
import {getSessionDeviceTypeFromSessionBrowser} from '../../../timeline/helpers';
import {useGetMcfxCompanySessions} from '../graphql/hooks/use-get-mcfx-company-sessions';

type Props = {|
    companyId: string,
    companyName: string,
|};

export function VisitorTimeline(props: Props) {
    const {mcfxCompanySessions} = useGetMcfxCompanySessions({id: props.companyId});

    return (
        <div className='pad-16'>
            {mcfxCompanySessions ? (
                mcfxCompanySessions.map((session, index) => {
                    const totalDuration = session.visits.totalDuration || 0;

                    const pages = session.visits.edges.map((edge) => ({
                        id: edge.node.id,
                        path: edge.node.url.path,
                        fullyQualifiedUrl: edge.node.url.fullyQualifiedUrl,
                    }));

                    return (
                        <TimelineEntry
                            key={session.id}
                            id={session.id}
                            iconVariant='session'
                            entryType='Session'
                            changeType='ADDED'
                            timestamp={session.startTime}
                            actor={{
                                __typename: 'Origin',
                                id: props.companyId,
                                name: props.companyName,
                            }}
                            entity={session.url}
                            numVisits={session.visits.totalCount}
                            subject={`Via ${session.source.source}`}
                            status={{primary: {level: 'info--grey', text: 'Site visit'}}}
                            content={
                                <TruncatedSessionContent
                                    durationSeconds={totalDuration}
                                    pages={pages}
                                />
                            }
                            metadata={
                                <SessionMetadata
                                    locationSummary={session.locationSummary}
                                    deviceType={getSessionDeviceTypeFromSessionBrowser(
                                        session.browser
                                    )}
                                    platformType={
                                        session.browser && session.browser.platform
                                            ? session.browser.platform
                                            : undefined
                                    }
                                />
                            }
                            disableComments={true}
                            disableReactions={true}
                            isLastEntry={index === mcfxCompanySessions.length - 1}
                            isTimelineSmall={true}
                        />
                    );
                })
            ) : (
                <LoadingPage padding={0} />
            )}
        </div>
    );
}
