/* @flow */

import * as React from 'react';

import {SlideshowControlDot} from './slideshow-control-dot';
import {SlideshowArrow} from './slideshow-arrow';

type Props = {|
    selectedSlide: number,
    slideCount: number,
    onControlClick?: (number) => void,
    showArrows?: boolean,
    isCircular?: boolean,
|};

export function SlideshowControls(props: Props) {
    const {selectedSlide, slideCount, onControlClick, showArrows, isCircular} = props;

    return (
        <div className='flex align-center'>
            {showArrows && onControlClick ? (
                <SlideshowArrow
                    direction='left'
                    selectedSlide={selectedSlide}
                    slideCount={slideCount}
                    onSlideChange={onControlClick}
                    isCircular={isCircular}
                />
            ) : (
                undefined
            )}
            {[...Array(slideCount).keys()].map((slideNum) => {
                let state = 'inactive';
                if (selectedSlide === slideNum) {
                    state = 'active';
                } else if (selectedSlide > slideNum) {
                    state = 'viewed';
                }

                return (
                    <SlideshowControlDot
                        key={slideNum}
                        state={state}
                        onDotClick={
                            onControlClick
                                ? () => {
                                      onControlClick(slideNum);
                                  }
                                : undefined
                        }
                    />
                );
            })}
            {showArrows && onControlClick ? (
                <SlideshowArrow
                    direction='right'
                    selectedSlide={selectedSlide}
                    slideCount={slideCount}
                    onSlideChange={onControlClick}
                    isCircular={isCircular}
                />
            ) : (
                undefined
            )}
        </div>
    );
}
