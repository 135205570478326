/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import {Link} from '../link';
import {Dialog} from '../dialog';
import {ErrorMessage} from '../form';
import {LinkedEntity} from '../linked-entity';
import {Button, RemoveButton} from '../button';
import {RelativeTimestamp} from '../timestamp';
import {DownloadIcon} from '../icon';
import {FileIcon} from './file-icon';

import './file.css';

type Props = {
    id: string,
    filename: string,
    filetype: string,
    href: string,
    creatorName?: string,
    createdTime?: number,
    isProcessing?: boolean,
    hasTimedOut?: boolean,
    onConfirmDelete?: () => any,
    onReprocessFile?: () => any,
    withDownloadIcon?: boolean,
    withNoBorder?: boolean,
};

export function File(props: Props) {
    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState<boolean>(false);
    const deleteRef = React.useRef(null);

    const styleName = classnames('file', {
        'file--no-border': props.withNoBorder,
        'file--removable': props.onConfirmDelete,
    });

    return (
        <li styleName={styleName} id={`file-${props.id}`}>
            <FileIcon href={props.href} filename={props.filename} filetype={props.filetype} />
            <div styleName='file-contents'>
                <div styleName='file-row'>
                    {props.withDownloadIcon ? (
                        <p styleName='file-name' title={props.filename}>
                            {props.filename}
                        </p>
                    ) : (
                        <Link variant='secondary' href={props.href} title={props.filename}>
                            {props.filename}
                        </Link>
                    )}
                </div>
                {props.creatorName || props.createdTime ? (
                    <div styleName='file-row'>
                        {props.creatorName ? (
                            <LinkedEntity type='users' name={props.creatorName} />
                        ) : (
                            <div />
                        )}
                        {props.createdTime ? (
                            <div styleName='timestamp'>
                                <RelativeTimestamp datetime={props.createdTime} />
                            </div>
                        ) : (
                            undefined
                        )}
                    </div>
                ) : (
                    undefined
                )}
            </div>
            {props.isProcessing || props.hasTimedOut ? <div styleName='overlay' /> : undefined}
            {props.isProcessing ? (
                <div styleName='overlay-text'>
                    <span styleName='processing'>Processing…</span>
                </div>
            ) : (
                undefined
            )}
            {props.hasTimedOut ? (
                <div styleName='overlay-text'>
                    <ErrorMessage>Failed</ErrorMessage>
                    <Button variant='text-primary' size='big' onClick={props.onReprocessFile}>
                        Retry?
                    </Button>
                </div>
            ) : (
                undefined
            )}
            {props.withDownloadIcon ? (
                <div styleName='download-button'>
                    <Link variant='secondary' href={props.href}>
                        <DownloadIcon size={17} />
                    </Link>
                </div>
            ) : (
                undefined
            )}
            {props.onConfirmDelete ? (
                <div styleName='remove-button'>
                    <RemoveButton
                        size={12}
                        getButtonRef={deleteRef}
                        onClick={() => setIsConfirmingDelete(true)}
                    />
                </div>
            ) : (
                undefined
            )}
            {isConfirmingDelete ? (
                <Dialog
                    anchor={deleteRef.current}
                    location='left'
                    question='Are you sure you want to delete this file?'
                    onPrimary={props.onConfirmDelete}
                    onSecondary={() => setIsConfirmingDelete(false)}
                />
            ) : (
                undefined
            )}
        </li>
    );
}
