/* @flow */
import type {Dispatch} from 'redux';
import type {LeadId, ContactId, LeadsApiResponse, ListApiResponseLeads} from '../types';
import type {ListSchemaResponse} from '../schema/schema-types';
import {fetchLead} from './fetch-lead';
import {fetchSchema} from './fetch-schema';
import type {
    FetchByIdFailureAction,
    FetchByIdRequestAction,
    FetchByIdSuccessAction,
    FetchLeadListForContactFailureAction,
    FetchLeadListSuccessAction,
    LeadsAction,
    LeadsSchemaRequestedAction,
    LeadsSchemaUpdatedAction,
    LeadsUpdatedAction,
} from './leads-types';
import {ActionTypes} from './leads-constants';

// Action Creators
export const updateLeads = (leadsResponse: LeadsApiResponse): LeadsUpdatedAction => ({
    type: ActionTypes.LEADS_UPDATED,
    payload: leadsResponse,
});
export const requestLeadsSchema = (): LeadsSchemaRequestedAction => ({
    type: 'LEADS_SCHEMA_REQUESTED',
});
export const updateLeadsSchema = (
    schemaResponse: ListSchemaResponse
): LeadsSchemaUpdatedAction => ({type: 'LEADS_SCHEMA_UPDATED', payload: schemaResponse});
export const fetchLeadListForContact = (payload: ContactId): LeadsAction => ({
    type: ActionTypes.LEADS_FETCH_LIST_FOR_CONTACT_REQUEST,
    payload,
});
export const resolveFetchLeadListForContact = (
    response: ListApiResponseLeads,
    contactId: ContactId
): LeadsAction => ({
    type: ActionTypes.LEADS_FETCH_LIST_FOR_CONTACT_SUCCESS,
    payload: {response, contactId},
});
export const failFetchLeadListForContact = (
    response: ?Object,
    contactId: ContactId
): FetchLeadListForContactFailureAction => ({
    type: ActionTypes.LEADS_FETCH_LIST_FOR_CONTACT_FAILURE,
    payload: {response, contactId},
    error: true,
});

export const resolveFetchListItems = (
    listApiResponse: ListApiResponseLeads
): FetchLeadListSuccessAction => ({
    type: ActionTypes.LEADS_FETCH_LIST_SUCCESS,
    payload: listApiResponse,
});

export const fetchById = (payload: LeadId): FetchByIdRequestAction => ({
    type: ActionTypes.LEADS_FETCH_BY_ID_REQUEST,
    payload,
});
export const resolveFetchById = (payload: LeadsApiResponse): FetchByIdSuccessAction => ({
    type: ActionTypes.LEADS_FETCH_BY_ID_SUCCESS,
    payload,
});
export const failFetchById = (payload?: Object): FetchByIdFailureAction => ({
    type: ActionTypes.LEADS_FETCH_BY_ID_FAILURE,
    payload,
    error: true,
});

/**
 * Fetches a single lead given a lead api id
 *
 * @param {string} leadId        - Api Id of the lead to fetch
 * @return {jQuery.Deferred} Deferred object that should resolve to Lead API response
 */
export function fetchLeadById(leadId: LeadId) {
    return function(dispatch: Dispatch<*>) {
        return fetchLead(leadId).then((res: LeadsApiResponse) => {
            dispatch(updateLeads(res));
        });
    };
}

/**
 * Fetches leads schema and fires corresponding actions to update our state
 *
 * @return {Function}            - Function to make API request and update state
 */
export function fetchSchemaAction() {
    return function(dispatch: Dispatch<*>) {
        dispatch(requestLeadsSchema());

        return fetchSchema().then((json) => {
            dispatch(updateLeadsSchema(json));
        });
    };
}
