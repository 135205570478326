import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'-259 357 80 80'}>
            <path d='M-237.8 406.6c-1.3 1.3-1.3 3.3 0 4.6s3.4 1.3 4.6 0c7.8-7.8 20.5-7.8 28.3 0 .6.6 1.5 1 2.3 1s1.7-.3 2.3-1c1.3-1.3 1.3-3.3 0-4.6-10.3-10.4-27.1-10.4-37.5 0zM-230.8 388.1c2.6 0 4.6-2.1 4.6-4.6 0-2.6-2.1-4.6-4.6-4.6-2.6 0-4.6 2.1-4.6 4.6s2.1 4.6 4.6 4.6zM-207.2 388.1c2.6 0 4.6-2.1 4.6-4.6 0-2.6-2.1-4.6-4.6-4.6-2.6 0-4.6 2.1-4.6 4.6-.1 2.5 2 4.6 4.6 4.6z' />
            <path d='M-219 357c-22.1 0-40 17.9-40 40s17.9 40 40 40 40-17.9 40-40-17.9-40-40-40zm0 74c-18.7 0-34-15.3-34-34s15.3-34 34-34 34 15.3 34 34-15.3 34-34 34z' />
        </svg>
    );
}
SvgComponent.iconName = 'frowny';

export const FrownyIcon = svgIconWrapper(SvgComponent);
