import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Trash from './icon-trash.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Trash {...validProps} />;
}
SvgComponent.iconName = 'trash';

export const TrashIcon = svgIconWrapper(SvgComponent);
