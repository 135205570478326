/* @flow */

import React from 'react';
import {Button} from 'shells/button';
import {ButtonMover} from 'shells/button-mover';

import './add-another-button.css';

type Props = {
    text: string,
    onClick: () => void,
};

export function AddAnotherButton(props: Props) {
    return (
        <div styleName='button-container'>
            <ButtonMover direction='left' size='normal'>
                <Button variant='text-primary' size='normal' onClick={props.onClick}>
                    {props.text}
                </Button>
            </ButtonMover>
        </div>
    );
}
