/* @flow */

// These are Quill "formats", many (but not all) of which are used by
// the toolbar module.
// see: https://quilljs.com/docs/modules/toolbar/
export const FORMATS = [
    'bold',
    'italic',
    'underline',
    'list',
    'bullet',
    'link',
    'image',
    'width', // width is needed here to resize images
    'style', // for alginment of preivously-saved images
    'token',
    'placeholder',
    'placeholder-custom',
    'placeholder-custom-placeholder',
    'placeholder-suggestion',
    'blockquote',
];

// These are the Quill "modules", along with config blobs
// see: https://quilljs.com/docs/modules/toolbar/
export const MODULES = {
    // Default config is an empty object
    // see: https://github.com/kensnyder/quill-image-resize-module
    imageResize: {},
    // Default config is jsut the boolean `true`
    // see: https://github.com/kensnyder/quill-image-drop-module
    imageDrop: true,
    placeholder: {
        placeholders: [
            {id: 'firstName', label: 'First name'},
            {id: 'lastName', label: 'Last name'},
            {id: 'companyName', label: 'Company name'},
        ],
    },
};
