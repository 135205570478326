import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 55 55'}>
            <path
                d='M27.5,1C42.1,1,54,12.9,54,27.5S42.1,54,27.5,54S1,42.1,1,27.5S12.9,1,27.5,1 M23.3,28.1l-4.8-4.8l0,0l0,0
                c-0.5-0.5-1.2-0.8-1.9-0.8c-0.8,0-1.5,0.3-2,0.8l-3.4,3.4c-1.1,1.1-1.1,2.9,0,4l6.7,6.8l3.4,3.4c0.5,0.5,1.2,0.8,2,0.8
                c0.7,0,1.5-0.3,2-0.8l3.4-3.4l0,0l0,0l0-0.1l15-15.2c1.1-1.1,1.1-2.9,0-4l-3.4-3.4l0,0l0,0c-0.5-0.5-1.2-0.8-1.9-0.8
                c-0.8,0-1.5,0.3-2,0.8L23.3,28.1 M27.5,0C12.3,0,0,12.3,0,27.5S12.3,55,27.5,55S55,42.7,55,27.5S42.7,0,27.5,0L27.5,0z M23.3,29.5
                l13.9-14.1c0.3-0.4,0.8-0.5,1.3-0.5c0.4,0,0.9,0.2,1.2,0.5c0,0,0,0,0,0l3.4,3.4c0.7,0.7,0.7,1.8,0,2.5L28,36.6l-0.1,0.1l-3.4,3.4
                c-0.3,0.4-0.8,0.5-1.3,0.5c-0.5,0-0.9-0.2-1.3-0.5c0,0,0,0,0,0l-3.4-3.4l-6.7-6.8c-0.7-0.7-0.7-1.8,0-2.6l3.4-3.4
                c0.3-0.4,0.8-0.5,1.3-0.5c0.4,0,0.9,0.2,1.2,0.5c0,0,0,0,0,0L23.3,29.5L23.3,29.5z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'tick-circle-outline';

export const TickCircleOutlineIcon = svgIconWrapper(SvgComponent);
