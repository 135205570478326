/* @flow */

import * as React from 'react';

import {EmptyStateSmall} from 'shells/empty-states';
import {MagnifyingGlassEmptyState} from 'shells/illustrations';

type Props = {
    title?: string,
    subtitle?: React.Node | string,
};

export function NothingFoundEmptyState(props: Props) {
    return (
        <EmptyStateSmall
            title={props.title || 'Nothing found'}
            subtitle={props.subtitle || 'Try loosening your search!'}
            icon={
                <div style={{width: '75px'}}>
                    <MagnifyingGlassEmptyState />
                </div>
            }
        />
    );
}
