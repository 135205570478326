import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SidebarIconNotifications from './sidebar-icon-notifications.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SidebarIconNotifications {...validProps} />;
}
SvgComponent.iconName = 'sidebarIconNotifications';

export const SidebarIconNotificationsIcon = svgIconWrapper(SvgComponent);
