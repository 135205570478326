/* @flow */

/**
 * This helper function takes an api id (e.g. `1-leads`, `5-users`, etc.), and
 * returns the model name associated with that id.
 *
 * @param  {string} apiId An API ID
 * @return {string}       The model name associated with that id
 */
export function getTypeFromApiId(apiId: string) {
    const idAndType = apiId.split('-');
    if (idAndType.length !== 2) return null;

    // On the backend, there are some special cases handled, but they don't
    // seem relavant to what we are doing here.
    // https://github.com/nutshellcrm/nutshell/blob/3308a76bda32d873c356493bee0587c1c8165235/library/Nut/Inflector.php#L40
    return idAndType[1];
}
