/* @flow */

import type Moment from 'moment';

/**
 * Gets an array of 7 days to display for a calendar. Will always return
 * the date passed, preceded and followed by 3 days.
 *
 * @param  {moment} date                     Moment object of date in desired month
 * @param  {boolean} alwaysStartOnSunday     Should always start on Sunday
 * @return {array}                           Array of 7 moment objects to display as a "week" of dates
 */
export function getCalendarSevenDays(date: Moment, alwaysStartOnSunday?: boolean) {
    const days = [];
    const dateMarker = alwaysStartOnSunday
        ? date.clone().startOf('week')
        : date.clone().subtract(3, 'days');

    for (let i = 0; i < 7; i++) {
        days.push(dateMarker.clone());
        dateMarker.add(1, 'days');
    }

    return days;
}
