/* @flow */
import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {TwoRowItemCard} from '../../two-row-item';

import type {CardData} from './option-card-builder-field';

type Props<T> = {
    meta: $PropertyType<FieldProps, 'meta'>,
    input: $PropertyType<FieldProps, 'input'>,
    cardCreator: ({
        meta: $PropertyType<FieldProps, 'meta'>,
        input: $PropertyType<FieldProps, 'input'>,
        onRemove: () => any,
    }) => any,
    getCardData: (T) => $Exact<CardData>,
    onRemove: () => any,
};

export function OptionCardField<T>(props: Props<T>) {
    if (!props.input.value) {
        const OptionCardCreator = props.cardCreator;

        return (
            <OptionCardCreator meta={props.meta} input={props.input} onRemove={props.onRemove} />
        );
    }

    const cardData = props.getCardData(props.input.value);

    return (
        <TwoRowItemCard
            topRow={cardData.topRow}
            // $FlowFixMe upgrading Flow to v0.92.1 on web
            bottomRow={cardData.bottomRow}
            onRemove={props.onRemove}
        />
    );
}
