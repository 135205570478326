import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox='0 0 197.98 249.99'>
            <path
                className='cls-2'
                d='M132.75,8a3.41,3.41,0,0,1,3.41,3.41V28.92H190V46H8V28.92H61.82V11.41A3.41,3.41,0,0,1,65.23,8h67.51m45.62,54.5V224.22A17.75,17.75,0,0,1,160.61,242H37.39a17.77,17.77,0,0,1-17.77-17.77V62.5H178.36M132.75,4H65.23a7.42,7.42,0,0,0-7.41,7.41V24.92H4V50H194V24.92H140.16V11.41A7.42,7.42,0,0,0,132.75,4Zm49.62,54.5H15.62V224.22A21.8,21.8,0,0,0,37.39,246H160.61a21.79,21.79,0,0,0,21.76-21.77V58.5Z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'trash-empty';

export const TrashEmptyIcon = svgIconWrapper(SvgComponent);
