/* @flow */

import {api} from '../api';
import type {Task, TasksApiResponse} from './tasks-types';

export function fetch(taskId: string): Promise<TasksApiResponse> {
    return api.get(`tasks/${taskId}`).then((response) => response.json());
}

export function fetchTasksByRelatedId(relatedId: string): Promise<TasksApiResponse> {
    return api.get(`tasks?filter[relatedEntity]=${relatedId}`).then((response) => response.json());
}

// TODO: newTask should be typed stronger than just `Object`, but it can differ
// right now between various clients
export function create(newTask: Object): Promise<TasksApiResponse> {
    return api.post('tasks', {data: newTask}).then((response) => response.json());
}

export function requestApiComplete(task: Task): Promise<TasksApiResponse> {
    const patchArray = [
        {
            op: 'replace',
            path: '/tasks/0/isCompleted',
            value: true,
        },
    ];

    return api.patch(task.href, patchArray).then((response) => response.json());
}

export function requestApiUncomplete(task: Task): Promise<TasksApiResponse> {
    const patchArray = [
        {
            op: 'replace',
            path: '/tasks/0/isCompleted',
            value: false,
        },
    ];

    return api.patch(task.href, patchArray).then((response) => response.json());
}
