/* @flow */
import PropTypes from 'prop-types';
import * as React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import capitalize from 'underscore.string/capitalize';
import {Modal as ReactModal} from 'shells/modal';

import {addNotification} from '../../notifications/index';

import {store} from '../../store';
import * as dashboardActions from '../master-dashboard/dashboard-actions';

import {Phone} from './phone';
import {PhoneCallView} from './phone-call-view';

type Props = {
    entityId: string,
    activityId?: string,
    phoneNumber: {
        E164: string,
        numberFormatted: string,
    },
    shouldRecord?: boolean,
    onClose: () => void,
};

type TwilioEvent =
    | 'accept'
    | 'disconnect'
    | 'error'
    | 'mute'
    | 'reconnecting'
    | 'reconnected'
    | 'ringing'
    | 'sample'
    | 'volume'
    | 'warning'
    | 'warning-cleared';

type TwilioConnection = {
    isMuted: () => boolean,
    on: (TwilioEvent, Function) => void,
    accept: () => void,
    disconnect: () => void,
    mute: (?boolean) => void,
    sendDigits: (string) => void,
    status: () => string,
};

type State = {
    isActivityFormMinimized: boolean,
    isCallConnected: boolean,
    isOpening: boolean,
    isClosing: boolean,
    isKeypadActive: boolean,
    isMuted: boolean,
    isSaving: boolean,
    isRetryingSave: boolean,

    activityTypes: Object[],
    keypadNumber: string,
    phoneCall: {
        activityTypeId: string,
        id?: string,
        href?: string,
        note?: string,
        serializedNote?: string,
        error?: string,
        // Is this instance a customer but they haven't manually verified yet?
        requiresCustomerVerification?: boolean,
    },
    status: string,
};

/*
 * Stateful container for active phone call interface. It will provide all props,
 * including actions, for the actual rendered components, and manage the call
 * with Twilio via the interface
 */
export class PhoneCallContainer extends React.Component<Props, State> {
    connection: ?TwilioConnection;
    phone: Phone;
    previousBeforeUnloadHandler: Function;

    constructor(props: Props) {
        super(props);

        this.state = {
            isActivityFormMinimized: false,
            isCallConnected: false,
            isOpening: true,
            isClosing: false,
            isKeypadActive: false,
            isMuted: false,
            isSaving: false,
            isRetryingSave: false,

            activityTypes: [],
            keypadNumber: '',
            phoneCall: {
                activityTypeId: 'automatic',
            },
            status: 'pending',
        };
        this.phone = new Phone();
    }

    componentDidMount() {
        this.previousBeforeUnloadHandler = window.onbeforeunload;
        window.onbeforeunload = this.warnBeforeUnload;

        $.ajax({
            dataType: 'json',
            url: '/rest/activitytypes',
        }).then((response) => this.setState({activityTypes: response.activityTypes}));

        this.phone
            .listen(this)
            .then((phoneCall) => {
                this.setState((prevState) => ({
                    phoneCall: {...prevState.phoneCall, ...phoneCall},
                }));
            })
            .fail((jqXHR) => {
                if (jqXHR.status === 429) {
                    Nut.error('Phone call initiation error');
                    const errorMsg =
                        'You’ve reached your call limit. Unlimited calling is available in Nutshell Pro.';

                    this.setState((prevState) => ({
                        phoneCall: {
                            ...prevState.phoneCall,
                            error: errorMsg,
                            requiresCustomerVerification:
                                jqXHR.responseText ===
                                '"Twilio calls max reached for unverified customer instance"',
                        },
                    }));
                } else if (typeof window.trackJs !== 'undefined') {
                    window.trackJs.track('Attempted to register multiple call listeners');
                    this.props.onClose();
                }
            });
    }

    componentWillUnmount() {
        window.onbeforeunload = this.previousBeforeUnloadHandler;
        this.connection = null;
        this.phone.stopListening();
    }

    render() {
        const {isActivityFormMinimized, keypadNumber} = this.state;
        const {phoneNumber} = this.props;

        return (
            <PhoneCallView
                activityTypes={this.state.activityTypes}
                entityId={this.props.entityId}
                phoneNumber={`${phoneNumber.numberFormatted} ${keypadNumber}`}
                isActivityFormMinimized={isActivityFormMinimized}
                isCallConnected={this.state.isCallConnected}
                isOpening={this.state.isOpening}
                isClosing={this.state.isClosing}
                isKeypadActive={this.state.isKeypadActive}
                isMuted={this.state.isMuted}
                isSaving={this.state.isSaving}
                phoneCall={this.state.phoneCall}
                status={this.state.status}
                onActivityTypeSelected={this.handleActivtyTypeSelected}
                onDoNotLog={this.handleDoNotLog}
                onHangup={this.handleHangup}
                onNotesChanged={this.handleNotesChanged}
                onSave={this.handleSave}
                onSendDigit={this.handleSendDigit}
                onToggleActivityForm={() =>
                    this.setState({isActivityFormMinimized: !isActivityFormMinimized})
                }
                onToggleKeypad={() =>
                    this.setState((prevState) => ({isKeypadActive: !prevState.isKeypadActive}))
                }
                onToggleMute={this.state.isMuted ? this.handleUnmute : this.handleMute}
            />
        );
    }

    warnBeforeUnload = () => {
        return 'Navigating away from this page will end your call. Are you sure?';
    };

    onReady = () => {
        if (!this.state.phoneCall.href) return;

        // $FlowIgnore - going to be converted away from jquery ajax
        $.ajax({
            type: 'PUT',
            dataType: 'json',
            url: this.state.phoneCall.href,
            data: {
                data: {
                    activityId: this.props.activityId,
                    phoneNumber: this.props.phoneNumber.E164,
                    relatedEntity: this.props.entityId,
                    shouldRecord: this.props.shouldRecord ? 1 : 0,
                },
            },
        }).fail((jqXHR) => {
            Nut.error('Phone call initiation error', JSON.stringify(jqXHR));

            let errorMsg = 'Something went wrong';
            if (jqXHR.status === 429) {
                errorMsg =
                    'You’ve reached your call limit. Unlimited calling is available in Nutshell Pro.';
            }

            this.setState((prevState) => ({
                phoneCall: {
                    ...prevState.phoneCall,
                    error: errorMsg,
                    requiresCustomerVerification:
                        jqXHR.responseText ===
                        '"Twilio calls max reached for unverified customer instance"',
                },
            }));
        });
    };

    onIncomingCall = (connection: TwilioConnection) => {
        if (this.connection) {
            if (typeof window.trackJs !== 'undefined') {
                window.trackJs.track('Attempted received incoming call when call open');
            }

            return;
        }

        this.connection = connection;

        // When Twilio tells us something has happened update state
        connection.on('accept', this.updateConnectionState);
        connection.on('disconnect', this.updateConnectionState);
        connection.on('mute', this.updateConnectionState);
        connection.on('error', this.handleError);

        // And now accept the call
        connection.accept();
    };

    onClose = () => {
        // Pass isClosing to the view so it can toggle a class that will handle
        // the styles that animate out the UI.
        this.setState({isClosing: true});

        // None of our animations should last longer than 1 second, so this a
        // decent place to start (until we have onAnimation[Start, End] etc. events \o/).
        _.delay(this.props.onClose, 1000);
    };

    updateConnectionState = () => {
        this.setState((prevState) => {
            const status = this.connection ? this.connection.status() : '';
            const isActivityFormMinimized =
                status === 'closed' ? false : prevState.isActivityFormMinimized;

            return {
                status: status,
                isMuted: this.connection ? this.connection.isMuted() : false,
                isCallConnected: status === 'open',
                isActivityFormMinimized: isActivityFormMinimized,
            };
        });
    };

    handleError = (error: Object) => {
        Nut.error('Twilio error', JSON.stringify(error));

        this.setState((prevState) => ({
            isActivityFormMinimized: false,
            isCallConnected: false,
            phoneCall: {
                ...prevState.phoneCall,
                error: error
                    ? JSON.stringify(error)
                    : 'Oh no! Something went wrong – please try again',
            },
        }));
    };

    handleMute = () => {
        if (this.connection) this.connection.mute(true);
    };

    handleUnmute = () => {
        if (this.connection) this.connection.mute(false);
    };

    handleSendDigit = (digit: string) => {
        if (this.connection) this.connection.sendDigits(digit);

        const {keypadNumber} = this.state;
        this.setState({
            keypadNumber: keypadNumber ? `${keypadNumber}${digit}` : digit,
        });
    };

    handleHangup = () => {
        if (this.connection) this.connection.disconnect();
    };

    handleActivtyTypeSelected = (activityTypeId: string) => {
        this.setState((prevState) => ({
            phoneCall: {...prevState.phoneCall, activityTypeId: activityTypeId},
        }));
    };

    handleNotesChanged = (note: string, serializedNote: string) => {
        this.setState((prevState) => ({
            phoneCall: {...prevState.phoneCall, note, serializedNote},
        }));
    };

    handleDoNotLog = () => {
        $.ajax({
            type: 'PUT',
            dataType: 'json',
            // $FlowIgnore - going to be converted away from jquery ajax
            url: this.state.phoneCall.href,
            data: {
                data: {
                    doNotLog: true,
                },
            },
        });

        this.onClose();
    };

    handleSave = () => {
        this.setState({isSaving: true});
        const {activityId} = this.props;
        const {activityTypeId, serializedNote} = this.state.phoneCall;

        if ((activityTypeId && activityTypeId !== 'automatic') || serializedNote) {
            const data =
                activityTypeId === 'automatic'
                    ? {activityId, note: serializedNote}
                    : {activityId, activityTypeId, note: serializedNote};

            if (!this.state.phoneCall.href) return;

            $.ajax({
                type: 'PUT',
                dataType: 'json',
                url: this.state.phoneCall.href,
                data: {
                    data,
                },
            })
                .then((response) => {
                    const isDashboardPage = location.pathname.startsWith('/dashboard');
                    if (isDashboardPage && response) {
                        const phonecall = response.phonecalls[0];
                        if (phonecall.links && phonecall.links.activity) {
                            store.dispatch(
                                dashboardActions.refetchActivity(phonecall.links.activity)
                            );
                        }
                    }

                    this.sendActivityLoggedNotification();
                })
                // Delay triggering the actual node removal in case the animation
                // hasn't completed yet
                .then(() => {
                    _.delay(this.props.onClose, 1000);
                })
                .fail((response) => {
                    this.sendActivitSaveFailedNotification();
                    if (this.state.isRetryingSave) _.delay(this.props.onClose, 1000);
                    Nut.error(
                        'Error saving phone call',
                        JSON.stringify({
                            response,
                            userId: $('body').data('loggedInUser'),
                            phonecall: this.state.phoneCall,
                        })
                    );
                });
        } else {
            this.sendActivityLoggedNotification();
            _.delay(this.props.onClose, 1000);
        }

        // Pass isClosing to the view so it can toggle a class that will handle
        // the styles that animate out the UI. We want to hold on to the UI for
        // now in case of an error
        this.setState({isClosing: true});
    };

    sendActivityLoggedNotification = () => {
        const {
            activityTypes,
            phoneCall: {activityTypeId},
        } = this.state;
        const entityId = this.props.entityId;

        const activityType = activityTypes.find((type) => type.id === activityTypeId);
        const activityTypeName = activityType ? activityType.name : 'Phone call';
        const entityType = entityId.split('-')[1];
        const entity = Nut.Model[capitalize(entityType)].findOrCreate({id: entityId});

        addNotification('Activity logged', {
            icon: 'check',
            body: `${activityTypeName} with ${entity.get('name')}`,
            onClick: () => {
                if (!this.state.phoneCall.id) return;

                Modal.open({
                    url: `/activity/view/${this.state.phoneCall.id}`,
                    error: () => {
                        Modal.close();
                        this.showActivityPending();
                    },
                });
            },
        });
    };

    showActivityPending = () => {
        const mountPoint = document.createElement('div');
        const body = document.getElementsByTagName('body')[0];
        body.appendChild(mountPoint);

        const onClose = () => {
            ReactDOM.unmountComponentAtNode(mountPoint);
            body.removeChild(mountPoint);
        };

        ReactDOM.render(<ActivityPendingModal onClose={onClose} />, mountPoint);
    };

    sendActivitSaveFailedNotification = () => {
        addNotification('Activity failed to save', {
            icon: 'frowny',
            body: this.state.isRetryingSave ? 'Try contacting support.' : 'Try again?',
            onClick: () => {
                if (!this.state.isRetryingSave) {
                    this.setState({
                        isOpening: true,
                        isClosing: false,
                        isSaving: false,
                        isRetryingSave: true,
                    });
                }
            },
        });
    };
}

ActivityPendingModal.prototype.propTypes = {
    onClose: PropTypes.func.isRequired,
};

function ActivityPendingModal(props) {
    return (
        <ReactModal className='ui-modal--activity-is-pending' isOpen={true} onClose={props.onClose}>
            <div>The activity isn’t quite ready yet. Check back in a couple minutes.</div>
        </ReactModal>
    );
}
