/* @flow */

/**
 * Measures the width of the vertical scrollbar (if it exists)
 * @return {number}   - Width of the scrollbar (in pixels)
 */
export function measureScrollbarWidth(): number {
    const body = document.body;
    if (!body) return 0; // If there's no body, there's no scrollbars!!!
    // Create the measurement node
    const scrollDiv = document.createElement('div');
    scrollDiv.setAttribute(
        'style',
        'width: 100px; height: 100px; overflow: scroll; position: absolute; top: -1000px;'
    );
    scrollDiv.id = 'test-div';

    body.appendChild(scrollDiv);

    // Get the scrollbar width
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

    // Delete the DIV
    body.removeChild(scrollDiv);

    return scrollbarWidth;
}
