/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {GraphQLSelectEntities} from '../../select/graphql-select-entities';

type Props = {
    ...FieldProps,
    isMulti: boolean,
    disabled?: boolean,
};

export function EntitiesSelect(props: Props) {
    return (
        <GraphQLSelectEntities
            isMulti={props.isMulti}
            onChange={(newEntity) => {
                props.input.onChange(newEntity);
            }}
            selectedValues={props.input.value || []}
            clearable={!props.isMulti}
            onClearValue={() => {
                props.input.onChange(null);
            }}
            disabled={props.disabled}
        />
    );
}
