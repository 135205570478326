/* @flow */

import * as React from 'react';

import {svgIconWrapper} from '../svg-icon-wrapper';
import WebChat from './icon-web-chat.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <WebChat {...validProps} />;
}
SvgComponent.iconName = 'webChat';

export const WebChatIcon = svgIconWrapper(SvgComponent);
