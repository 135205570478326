import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import GoalClick from './icon-goal-click.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <GoalClick {...validProps} />;
}
SvgComponent.iconName = 'goal-click';

export const GoalClickIcon = svgIconWrapper(SvgComponent);
