import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ConstantContact from './icon-constant-contact.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ConstantContact {...validProps} />;
}
SvgComponent.iconName = 'constantContact';

export const ConstantContactIcon = svgIconWrapper(SvgComponent);
