import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Archive from './icon-archive.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Archive {...validProps} />;
}
SvgComponent.iconName = 'archive';

export const ArchiveIcon = svgIconWrapper(SvgComponent);
