/* @flow */

import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';

import type {ReactingUser, Reaction} from './types';
import {REACTIONS} from './consts';
import {hasUserReacted, getListOfReactingUsers} from './helpers';

import './reaction-button.css';

type Props = {|
    reaction: Reaction,
    reactingUsers: ReactingUser[],
    currentUserId: string,
    onClick: () => void,
    isSmall?: boolean,
|};

export function ReactionButton(props: Props) {
    React.useEffect(() => {
        ReactTooltip.rebuild();

        return () => ReactTooltip.hide();
    }, []);

    return (
        <button
            styleName={classnames({
                'reaction-button': true,
                'reaction-button--reacted': hasUserReacted(
                    props.reactingUsers,
                    props.currentUserId
                ),
                'reaction-button--small': props.isSmall,
            })}
            onClick={props.onClick}
            data-tip={getListOfReactingUsers(props.reactingUsers, props.currentUserId)}
            data-delay-show='300'
            data-event='mouseenter focus'
            data-event-off='mouseleave blur click'
        >
            <div>{props.reactingUsers.length}</div>
            <div>{REACTIONS[props.reaction].unicode}</div>
        </button>
    );
}
