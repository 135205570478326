/* @flow */

import * as React from 'react';

import {SlideIn} from '../transition-group';

import {SlideshowControls} from './slideshow-controls';

import './slideshow-container.css';

export type Direction = 'right' | 'left';

type Props = {|
    children: any,
    showControls?: boolean,
    showArrows?: boolean,
    isCircular?: boolean,
    passPropsToChildren?: boolean,
    onDismiss?: Function,
    // Automatically go to the next slide after number of ms
    slideChangeTimer?: number,
    // Base height of the slideshow container, not counting the controls at the bottom
    // if one is not provided here, then the parent of this component needs a set height,
    // due to CSS animations used for slides.
    baseHeight?: number,
|};

const SLIDESHOW_CONTROL_HEIGHT = 36;

SlideshowContainer.defaultProps = {
    showControls: true,
    passPropsToChildren: true,
    onDismiss: () => {},
};

export function SlideshowContainer(props: Props) {
    const {slideChangeTimer, isCircular, baseHeight} = props;

    const [slideNum, setSlideNum] = React.useState<number>(0);
    const [transitionDirection, setTransitionDirection] = React.useState<Direction>('right');

    const slideChangeTimeoutId = React.useRef(undefined);

    const slides = props.passPropsToChildren
        ? React.Children.map(props.children, (child, index) => {
              return React.cloneElement(child, {
                  onDismiss: () => {
                      props.onDismiss ? props.onDismiss() : undefined;
                      handleSlideChange(index + 1);
                  },
                  onPrevious: () => handleSlideChange(index - 1),
                  onNext: () => handleSlideChange(index + 1),
                  slideNum: index,
              });
          })
        : props.children;

    const handleSlideChange = React.useCallback(
        (newSlideNum: number) => {
            setTransitionDirection(newSlideNum < slideNum ? 'right' : 'left');
            setSlideNum(newSlideNum);
        },
        [slideNum]
    );

    const clearSlideChangeTimeout = React.useCallback(() => {
        if (slideChangeTimeoutId.current) {
            clearTimeout(slideChangeTimeoutId.current);
        }
    }, []);

    const setSlideChangeTimeout = React.useCallback(() => {
        // Clear existing timeout if there is one
        clearSlideChangeTimeout();

        if (isCircular || slideNum + 1 < slides.length) {
            // Set a new timeout, update current timeout id
            slideChangeTimeoutId.current = setTimeout(() => {
                handleSlideChange(
                    (((slideNum + 1) % slides.length) + slides.length) % slides.length
                );
            }, slideChangeTimer);
        }
    }, [
        isCircular,
        clearSlideChangeTimeout,
        handleSlideChange,
        slideChangeTimer,
        slideNum,
        slides.length,
    ]);

    React.useEffect(() => {
        if (slideChangeTimer) setSlideChangeTimeout();

        return () => {
            clearSlideChangeTimeout();
        };
    }, [slideChangeTimer, setSlideChangeTimeout, clearSlideChangeTimeout]);

    if (!slides || !slides.length) {
        return null;
    }

    return (
        <div
            style={{
                height: baseHeight
                    ? getHeight(baseHeight, Boolean(props.showControls) && slides.length > 1)
                    : undefined,
            }}
            styleName='slideshow-container'
            onMouseOver={() => {
                // If there's a timeout, clear it while the user is mousing over content
                if (slideChangeTimer) clearSlideChangeTimeout();
            }}
            onMouseLeave={() => {
                // Reset timer when user mouses out of content
                if (slideChangeTimer) setSlideChangeTimeout();
            }}
        >
            <div styleName='slide-container'>
                <SlideIn direction={transitionDirection}>
                    <div key={slideNum}>{slides[slideNum]}</div>
                </SlideIn>
            </div>
            {props.showControls && slides.length > 1 ? (
                <SlideshowControls
                    selectedSlide={slideNum}
                    slideCount={slides.length}
                    onControlClick={handleSlideChange}
                    showArrows={props.showArrows}
                    isCircular={props.isCircular}
                />
            ) : (
                undefined
            )}
        </div>
    );
}

function getHeight(baseHeight: number, includeControlHeight: boolean) {
    return includeControlHeight ? baseHeight + SLIDESHOW_CONTROL_HEIGHT : baseHeight;
}
