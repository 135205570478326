/* @flow */

import {api} from './api';
import analyticsConstants from './analytics-constants.json';

const ANALYTICS_ENDPOINT = 'internal/event';

/*
 * Service class which acts as an interface for clients to track events
 * across various platforms.
 */
export class NutAnalyticsClient {
    EVENTS: analyticsConstants;

    constructor() {
        this.EVENTS = analyticsConstants;
    }

    // I can't properly type this event to own of our analyticsConstants
    // paylods that comes from our imported JSON file above, but it needs
    // to match.
    track(event: string, properties: Object = {}) {
        const payload = {
            event,
            properties,
        };

        // TODO: Ensure this has {headers: {'Content-Type': 'application/json'}}
        return api.post(ANALYTICS_ENDPOINT, payload);
    }
}
