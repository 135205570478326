/* @flow */

/*
 * Round a date to nearest qurarter hour.
 * i.e. 12:10 becomes 12:15, 12:35 becomes 12:30 and so on.
 */
export function getDateRoundedToQuarterHour(date: Date): Date {
    let rounded = (Math.round(date.getMinutes() / 15) * 15) % 60;
    if (date.getMinutes() > 52) {
        // Don't round hours up if it would move to a new day
        if (date.getHours() === 23) {
            rounded = 59;
        } else {
            date.setHours(date.getHours() + 1);
        }
    }
    date.setMinutes(rounded);

    return date;
}
