/* @flow */

import type {DateRange, DateRangeType, ComparisonRangeType} from './types';

import {
    getPreviousDateRangeFromDateFilter,
    getPreviousYearDateRangeFromDateFilter,
} from './get-date-range-from-date-filter';

/**
 * Calculates and returns a date range for the given date filter
 *
 * @param  {string} filter                         Date filter string to process
 * @param  {ComparisonRangeType} comparisonType    Type of comparison to get for range
 * @param  {string} fiscalYearStart                Optional param to use custom fiscalYearStart setting
 * @param  {string} workWeekStart                  Optional param to use custom workWeekStart setting
 * @return {DateRange}                             Object with startDate and endDate keys
 */
export function getComparisonDatesFromRange(
    filter: string,
    comparisonType: ComparisonRangeType,
    fiscalYearStart: string = '1-1',
    workWeekStart?: string = '7'
): DateRange {
    if (comparisonType === 'prevYear') {
        return getPreviousYearDateRangeFromDateFilter(filter, fiscalYearStart, workWeekStart);
    } else {
        return getPreviousDateRangeFromDateFilter(filter, fiscalYearStart, workWeekStart);
    }
}

/**
 * Given a set of filters and the date range key (dateRangeType), return a new
 * set of filters for which the date range is adjusted for a comparison range type.
 *
 * @param  {DateRangeType} dateRangeType            The key of the date range filter object
 * @param  {Object} filters                         A filter object
 * @param  {ComparisonRangeType} comparisonType     The type of comparison range to use
 * @return {Object}                                 A new set of filters, with an adjusted date range
 */
export function getComparisonPeriodFilters(
    dateRangeType: DateRangeType,
    filters: Object,
    comparisonType: ComparisonRangeType
) {
    return {...filters, [dateRangeType]: `${comparisonType}(${filters[dateRangeType]})`};
}
