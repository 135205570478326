import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ChatMessage from './icon-chat-message.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ChatMessage {...validProps} />;
}
SvgComponent.iconName = 'chat-message';

export const ChatMessageIcon = svgIconWrapper(SvgComponent);
