/* @flow */

import * as ramda from 'ramda';

import type {FilterObject} from './types';

/**
 * Converts an array of filter objects to a format appropriate for an ajax
 * request.
 *
 * @param {Object[]} filters - An array of objects where each item represents a single
 *                      filter
 * @return {Object} - An object representation of the filters where each filter
 *                  is represented by a single key.
 */
export function serializeFilters(filters: Array<FilterObject>) {
    return filters.reduce((serFilter, filter) => {
        // Basic assumption here that there is only a single key per filter
        const filterName = Object.keys(filter)[0];
        serFilter[filterName] = ramda.has(filterName)(serFilter)
            ? ramda.flatten([serFilter[filterName], filter[filterName]])
            : filter[filterName];

        return serFilter;
    }, {});
}
