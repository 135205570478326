import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 18.4 31.1'}>
            <path
                fill='#D4D4D4'
                d='M17.2 1h.1c-.2 2.7-1.2 5.2-2.8 6.9-1.7 1.5-3.8 2.3-6.1 2.4H8v-.1C8.6 4.8 12.3 1 17.2 1M8.6 18.6H14s.1.1 0 .3l-2.1 10.4c-.1.4-.5.7-.7.7H3.8c-.2 0-.6-.3-.7-.7L1 18.9v-.3h7.6zM17.2 0c-5.4 0-9.6 4.2-10 10.1-.6 2.1-.7 5.4-.8 7.5H1.1c-.8 0-1.2.7-1.1 1.5l2.1 10.4c.2.8.9 1.5 1.7 1.5h7.3c.8 0 1.5-.7 1.7-1.5L15 19.1c.2-.8-.3-1.5-1.1-1.5H8.6c-.3-1.5-.6-4.2-.1-6.3 2.5-.1 4.9-1 6.8-2.7 1.9-2.1 3-5.1 3.1-8 0-.4-.3-.6-.6-.6h-.6z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'plant-outlined-small';

export const PlantSmallOutlinedIcon = svgIconWrapper(SvgComponent);
