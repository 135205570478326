/* @flow */
import {createSelector} from 'reselect';
import * as ramda from 'ramda';
import startsWith from 'underscore.string/startsWith';
import type {StageId, StagesetId} from '../types';

import type {NutshellSharedState} from '../store';
import {getAllStages} from '../stages/stages-selectors';
import * as Leads from '../leads';

export const getVisualPipelineState = (state: NutshellSharedState) => state.visualPipeline;

// $FlowFixMe upgrading Flow to v0.92.1
export const getListItemsByStage = createSelector(
    [getVisualPipelineState],
    (visualPipelineState) => {
        return visualPipelineState.itemsByStage;
    }
);

// $FlowFixMe upgrading Flow to v0.92.1
export const getActiveStageTransition = createSelector(
    [getVisualPipelineState],
    (visualPipelineState) => {
        return visualPipelineState.activeStageTransition;
    }
);

// $FlowFixMe upgrading Flow to v0.92.1
export const getLeadClosingConfirmation = createSelector(
    [getVisualPipelineState],
    (visualPipelineState) => {
        return visualPipelineState.activeLeadClosingConfirmation;
    }
);

// $FlowFixMe upgrading Flow to v0.92.1
export const getIsCardBeingDragged = createSelector(
    [getVisualPipelineState],
    (visualPipelineState) => {
        return Boolean(visualPipelineState.cardIdBeingDragged);
    }
);

// $FlowFixMe upgrading Flow to v0.92.1
export const getLeadIdBeingDragged = createSelector(
    [getVisualPipelineState],
    (visualPipelineState) => {
        return visualPipelineState.cardIdBeingDragged;
    }
);

// $FlowFixMe upgrading Flow to v0.92.1
export const getCanDragCards = createSelector(
    [getActiveStageTransition, getLeadClosingConfirmation],
    (stageTransition, closingConfirmation) => {
        return stageTransition.id === null && closingConfirmation.id === null;
    }
);

// $FlowFixMe upgrading Flow to v0.92.1
export const getCardIdChangingState = createSelector(
    [getActiveStageTransition, getLeadClosingConfirmation],
    (stageTransition, closingConfirmation) => {
        return stageTransition.id || closingConfirmation.id;
    }
);

/**
 * Returns selector to see if the current pipeline has any leads in it at all.
 * This is normally just used for onboarding, as it will always return true
 * if _any_ lead has been created for this pipeline, regardless of if it passes
 * the current filter set.
 *
 * @param  {Object}        - Entire Nutshell State
 * @return {Function}      - Selector to get whether or not pipeline is empty
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getDoesPipelineHaveNoLeads = createSelector([getListItemsByStage], (itemsByStage) => {
    return ramda.values(itemsByStage).every((columnState) => {
        return columnState.total === 0 && !columnState.isRequesting;
    });
});

/**
 * Returns selector to see if the current pipeline has any leads that match
 * the current filter set. This is equivalent to the `count` attribute, as the
 * count always dictates the total for the given filter set.
 *
 * @param  {Object}        - Entire Nutshell State
 * @return {Function}      - Selector to get whether or not pipeline has any
 *                           filtered results
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getDoesPipelineHaveNoFilteredResults = createSelector(
    [getListItemsByStage, getDoesPipelineHaveNoLeads],
    (itemsByStage) => {
        return ramda.values(itemsByStage).every((columnState) => {
            return columnState.count === 0 && !columnState.isRequesting;
        });
    }
);

type ListItemsForStageArg = {stageId: StageId, selectedSort: string};

/**
 * Memoized selector that returns an array of listItems that are part of the
 * passed stageId, sorted by their sortKey property.
 *
 * Has a custom memoize hash function as we have multiple inputs passed in the
 * first parameter as an object
 *
 * @param  {string} stageId             - Id of stage to get associated column
 * @return {Function}                   - Selector that returns an array of ListItems
 *                                        related to the given stage given the current state
 */
export const getListItemsForStage = ramda.memoizeWith<ListItemsForStageArg, Function, string>(
    (args) => args.stageId + args.selectedSort,
    ({stageId, selectedSort}: ListItemsForStageArg) => {
        return createSelector(
            [getListItemsByStage, Leads.getLeadListItemMap],
            (itemsByStage, listItems) => {
                const listItemIds = itemsByStage[stageId] ? itemsByStage[stageId].items : [];
                const uniqueListItemIds = ramda.uniq(listItemIds);
                const unsortedItems = uniqueListItemIds.map((listItemId) => {
                    return listItems[listItemId];
                });

                // We'll use the listItem's sortKey property to determine
                // the order of the items. This sortKey automatically updates
                // based on the sortField given
                // Note, this `getSortKey` is a bit of a hack to work around a bad
                // flow-typed definition for ramda.prop
                // (https://github.com/flowtype/flow-typed/issues/1176)
                const getSortKey: ({sortKey: string}) => ?string = ramda.prop('sortKey');
                const ascendingSortedItems = ramda.sortBy(getSortKey)(unsortedItems);

                // If our selected sort starts with a `-`, we know it's a descending sort
                return startsWith(selectedSort, '-')
                    ? ascendingSortedItems.reverse()
                    : ascendingSortedItems;
            }
        );
    }
);

/**
 * Memoized selector that returns the total count of listItems for the
 * passed stageId
 * @param  {string} stageId             - Id of stage to get associated column
 * @return {Function}                   - Selector that returns the total # of leads
 *                                        for given stage given the current state
 */
export const getLeadCountForStage = ramda.memoizeWith<StageId, Function, StageId>(
    ramda.identity,
    (stageId: StageId) => {
        return createSelector([getListItemsByStage], (itemsByStage) => {
            return itemsByStage[stageId] ? itemsByStage[stageId].count : 0;
        });
    }
);

/**
 * Memoized selector that returns the formatted money value of listItems for the
 * passed stageId
 * @param  {string} stageId             - Id of stage to get associated column
 * @return {Function}                   - Selector that returns the current value
 *                                        (as a FormattedValue) for all the leads
 *                                        in a given stage given the current state
 */
export const getTotalValueForStage = ramda.memoizeWith<StageId, Function, StageId>(
    ramda.identity,
    (stageId: StageId) => {
        return createSelector([getListItemsByStage], (itemsByStage) => {
            return itemsByStage[stageId]
                ? itemsByStage[stageId].value
                : {value: 0, formatted: '0', prefix: '', suffix: ''};
        });
    }
);

/**
 * Memoized selector that returns an array of stages that pertain to the
 * passed stageset id.
 * @param  {string} stageId             - Id of stage to get associated column
 * @return {boolean}                    - Is column requesting?
 */
export const getIsLoadingForStage = ramda.memoizeWith<StageId, Function, StageId>(
    ramda.identity,
    (stageId: StageId) => {
        return createSelector([getListItemsByStage], (itemsByStage) =>
            itemsByStage[stageId] ? itemsByStage[stageId].isRequesting : true
        );
    }
);

/**
 * Selector to determine if any column is loading (based on a stageset id).
 * Will return true if any column is still loading their stages.
 *
 * @param  {string} stagesetId      - StagesetId of the columns to check if
 *                                    they're loading
 * @return {Function}               - Selector that returns whether or not we have
 *                                    any columns that are loading
 */
export const getIsAnyColumnLoading = ramda.memoizeWith<StagesetId, Function, StagesetId>(
    ramda.identity,
    (stagesetId: StagesetId) => {
        return createSelector([getAllStages, getListItemsByStage], (allStages, itemsByStage) => {
            const stages = ramda.values(allStages[stagesetId]);

            return stages.some((stage) => {
                return itemsByStage[stage.id] && itemsByStage[stage.id].isRequesting;
            });
        });
    }
);

/**
 * Selector to determine which stages can still load more cards (i.e. have
 * more cards still available to fetch - we limit each request to 100).
 *
 * @param  {string} stagesetId      - StagesetId of the columns to check if
 *                                    they have more stages to fetch
 * @return {Function}               - Selector that returns whether or not we have
 *                                    any columns that have more items
 */
export const getStageIdsThatCanLoadMoreLeads = ramda.memoizeWith<StagesetId, Function, StagesetId>(
    ramda.identity,
    (stagesetId: StagesetId) => {
        return createSelector([getAllStages, getListItemsByStage], (allStages, itemsByStage) => {
            const stages = ramda.values(allStages[stagesetId]);

            const stagesWithMoreLeads = stages.filter((stage) => {
                return (
                    itemsByStage[stage.id] &&
                    itemsByStage[stage.id].items.length < itemsByStage[stage.id].count
                );
            });

            // Note, this `getId` is a bit of a hack to work around a bad
            // flow-typed definition for ramda.prop
            function getId({id}: {id: string}) {
                return id;
            }

            // Equivalent to ramda.pluck('id', stagesWithMoreLeads), but the flow def for pluck is bad
            return ramda.map(getId, stagesWithMoreLeads);
        });
    }
);
