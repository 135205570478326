/* @flow */
import {parse, stringify} from 'qs';

export function param(parameters: Object): string {
    return stringify(parameters, {arrayFormat: 'indices'});
}

export function deparam(search: string, options?: Object): Object {
    return parse(search, {ignoreQueryPrefix: true, arrayLimit: 200, ...options});
}
