import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Task from './icon-task.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Task {...validProps} />;
}
SvgComponent.iconName = 'task';

export const TaskIcon = svgIconWrapper(SvgComponent);
