/* @flow */

import * as React from 'react';

import {TwoLine} from '../../two-line';

import './details-list.css';

type Detail = {|
    name: any,
    value: any,
    key: string,
    isFullWidth?: boolean,
|};

type Props = {|
    details: Detail[],
    padding?: number,
|};

export function DetailsList(props: Props) {
    return (
        <div styleName='details-list' style={{padding: props.padding}}>
            {props.details.map((detail) => {
                const {name, value, key, isFullWidth = true} = detail;

                return (
                    <div key={key} styleName={isFullWidth ? 'detail--full' : 'detail--half'}>
                        <TwoLine
                            topRow={<div styleName='detail-name'>{name}</div>}
                            bottomRow={<div styleName='detail-value'>{value}</div>}
                        />
                    </div>
                );
            })}
        </div>
    );
}
