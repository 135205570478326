/* @flow */

import * as actions from './collections-actions';
import * as selectors from './collections-selectors';
import {createGenericCollectionReducerForType} from './collections-reducer';
import * as types from './collections-types';
import * as utils from './collections-utils';

export const Collections = {
    createGenericCollectionReducerForType,
    ...actions,
    ...selectors,
    ...types,
    ...utils,
};

export type {CollectionState} from './collections-reducer';
export type {
    NutshellCollectionsState,
    CollectionApiResponse,
    CollectionName,
} from './collections-types';
