import PropTypes from 'prop-types';
import * as React from 'react';
import {SpinnerIcon} from 'shells/icon';
import {EmailTemplates} from '../../email/email-templates';

const NO_TEMPLATE_SELECTED = '0';
const MANAGE_TEMPLATES = 'Manage Templates';

export class EmailTemplateSelector extends React.Component {
    static propTypes = {
        userId: PropTypes.string.isRequired,
        selectedId: PropTypes.string,
        clearForm: PropTypes.func.isRequired,
        getPreview: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedId: props.selectedId,
            loading: true,
            templates: new EmailTemplates([], {userId: props.userId}),
        };

        this.state.templates.fetch().then(() => this.setState({loading: false}));
    }

    componentDidMount() {
        this.state.templates.on('add remove reset change', () => this.forceUpdate());
    }

    componentWillUnmount() {
        this.state.templates.off('add remove reset change');
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({selectedId: newProps ? newProps.selectedId : undefined});
    }

    render() {
        return (
            <div>
                <select
                    className='email-template-selector'
                    value={this.state.selectedId || NO_TEMPLATE_SELECTED}
                    onChange={(e) => this.handleChange(e)}
                >
                    <option value={NO_TEMPLATE_SELECTED}>Choose a template&hellip;</option>
                    {this.state.templates.map((template) => {
                        const label = template.get('name') || template.get('subject');

                        return (
                            <option key={template.id} value={template.id}>
                                {label}
                            </option>
                        );
                    })}
                    <option value={MANAGE_TEMPLATES}>Manage templates&hellip;</option>
                </select>
                {this.state.loading ? <SpinnerIcon /> : undefined}
            </div>
        );
    }

    handleChange(e) {
        const selectBox = e.currentTarget;
        const selectedValue = selectBox.options[selectBox.selectedIndex].value;
        if (selectedValue === MANAGE_TEMPLATES) {
            window.location = '/email/templates';
        }

        if (selectedValue === this.state.selectedId) return;
        this.setState({
            loading: true,
            selectedId: selectedValue,
        });
        const template = this.state.templates.get(selectedValue);
        if (!template) {
            this.props.clearForm(true);
            this.setState({loading: false});

            return;
        }

        this.props.getPreview(template, () => {
            this.setState({loading: false});
        });
    }
}
