/* @flow */

import * as React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import LeadIconChevronSVG from './lead-icon-chevron.svg';

type Props = {
    fill?: string,
};
function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <LeadIconChevronSVG
            {...validProps}
            fill={props.fill || '#33c252'}
            stroke={props.fill || '#33c252'}
        />
    );
}
SvgComponent.iconName = 'lead-chevron';

export const LeadIconChevron = svgIconWrapper(SvgComponent);
