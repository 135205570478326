import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ArrowDownIconSvg from './icon-arrow-down.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ArrowDownIconSvg {...validProps} />;
}
SvgComponent.iconName = 'arrowDownIcon';

export const ArrowDownIcon = svgIconWrapper(SvgComponent);
