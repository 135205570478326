import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Retry from './icon-retry.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Retry {...validProps} />;
}
SvgComponent.iconName = 'retry';

export const RetryIcon = svgIconWrapper(SvgComponent);
