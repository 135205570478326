/* @flow */

import * as ramda from 'ramda';
import type {Dispatch} from 'redux';
import type {
    ListApiResponseLeads,
    FilterObject,
    ListItem,
    LeadId,
    OutcomeId,
    StageId,
} from '../types';

import {isQueryPaginationRequest} from '../utils/is-query-pagination-request';
import * as Leads from '../leads';
import {
    fetchList,
    applyDefaultQueryParameters,
    DEFAULT_PAGE_LIMIT,
    type ListQuery,
} from '../entities/fetch-list';
import type {NutshellSharedState} from '../store';
import {safelyGetRelationshipFilterIds} from '../filter';
import type {CloseType, VisualPipelineAction} from './visual-pipeline-types';
import {ActionTypes} from './visual-pipeline-constants';
import {getListItemsByStage} from './visual-pipeline-selectors';

// Actions
export const requestListItemsForStage = (stageId: StageId): VisualPipelineAction => ({
    type: ActionTypes.VISUAL_PIPELINE_LIST_ITEMS_REQUESTED_FOR_STAGE,
    payload: stageId,
});
export const addListItemsForStage = ({
    stageId,
    response,
}: {
    stageId: StageId,
    response: ListApiResponseLeads,
}): VisualPipelineAction => ({
    type: ActionTypes.VISUAL_PIPELINE_LIST_ITEMS_ADDED_FOR_STAGE,
    payload: {stageId, response},
});
export const replaceListItemsForStage = ({
    stageId,
    response,
}: {
    stageId: StageId,
    response: ListApiResponseLeads,
}): VisualPipelineAction => ({
    type: ActionTypes.VISUAL_PIPELINE_LIST_ITEMS_REPLACED_FOR_STAGE,
    payload: {stageId, response},
});
export const failListItemsForStage = (stageId: StageId): VisualPipelineAction => ({
    type: ActionTypes.VISUAL_PIPELINE_LIST_ITEMS_FAILED_FOR_STAGE,
    payload: stageId,
});
export const removeListItemById = (listItem: ListItem): VisualPipelineAction => ({
    type: ActionTypes.VISUAL_PIPELINE_LIST_ITEM_REMOVED_BY_ID,
    payload: listItem,
});
export const updateLeadStageTransition = ({
    leadId,
    oldStageId,
    newStageId,
}: {
    leadId: ?LeadId,
    oldStageId: ?StageId,
    newStageId: ?StageId,
}): VisualPipelineAction => ({
    type: ActionTypes.VISUAL_PIPELINE_LEAD_STAGE_TRANSITION_UPDATED,
    payload: {leadId, oldStageId, newStageId},
});
export const completeLeadStageTransition = (listItem: ListItem): VisualPipelineAction => ({
    type: ActionTypes.VISUAL_PIPELINE_LEAD_STAGE_TRANSITION_COMPLETED,
    payload: listItem,
});
export const toggleCardIsDragging = (leadId: LeadId): VisualPipelineAction => ({
    type: ActionTypes.VISUAL_PIPELINE_CARD_IS_DRAGGING_TOGGLED,
    payload: leadId,
});
export const updateLeadClosingConfirmation = ({
    leadId,
    closeType,
}: {
    leadId: ?LeadId,
    closeType: ?CloseType,
}): VisualPipelineAction => ({
    type: ActionTypes.VISUAL_PIPELINE_LEAD_CLOSING_CONFIRMATION_UPDATED,
    payload: {leadId, closeType},
});

/**
 * Gets all stage models for instance, regardless of stageset. If this
 * request becomes too slow, we can break it apart by stagesetId, but for
 * now it's perfectly fast enough
 *
 * @param {string} stageId   - Only request leads from this stage
 * @param {Object} query     - Query to use in the subsequent fetchList call
 * @param {boolean} isBoardView     - Whether or not we are on the board view
 *
 * @return {Function} Thunk to request stages and dispatch corresponding
 *                    actions during the process
 */
export function fetchLeadsByStage(
    stageId: StageId,
    query: ListQuery = {},
    isBoardView?: boolean = false
) {
    return function(dispatch: Dispatch<*>) {
        dispatch(requestListItemsForStage(stageId));

        // Tack on (or replace) our milestone filter with the passed stageId
        query.filter = applyStageFilter(query.filter, stageId);
        if (isBoardView) {
            // Clear out the fields requested when we're on the board view so we populate from settings
            query.fields = [];
        }

        // Now fetch the list using our query
        fetchList('leads', query)
            .then((listApiResponse: ListApiResponseLeads) => {
                // If we're not loading page 0, tack on new items instead
                // of replacing them
                if (isQueryPaginationRequest(query)) {
                    dispatch(addListItemsForStage({stageId, response: listApiResponse}));
                    // Otherwise, assume we have a new query, and replace items
                    // with our replace action
                } else {
                    dispatch(replaceListItemsForStage({stageId, response: listApiResponse}));
                }
            })
            .catch(() => {
                dispatch(failListItemsForStage(stageId));
            });
    };
}

/**
 * Async action that loops through an array of given stageIds that would
 * like to request their next page of leads.
 *
 * It will determine the number of the page based on the number of listItems
 * already in that stage (using the pageLimit), and then call our other async
 * action `fetchLeadsByStage`
 *
 * @param  {string[]} stageIds               - Array of stageIds to request
 *                                             their next page
 * @param  {Object} query                    - Full leads list query to use
 *                                             for the subsequent page
 * @param  {boolean} isBoardView              - Whether or not we are on the board view
 * @return {Function}                        - Thunk to loop through and
 *                                             generate the request for the
 *                                             next page.
 */
export function fetchMoreLeadsForStages(
    stageIds: StageId[],
    query: ListQuery,
    isBoardView?: boolean = false
) {
    return function(dispatch: ThunkDispatch, getState: () => NutshellSharedState) {
        const itemsByStage = getListItemsByStage(getState());
        const pageLimit = (query.page && query.page.page) || DEFAULT_PAGE_LIMIT;

        stageIds.forEach((stageId) => {
            if (itemsByStage[stageId]) {
                const numFetched = itemsByStage[stageId].items.length;
                const pageToFetch = Math.floor(numFetched / pageLimit);
                const adjustedQuery = {
                    ...query,
                    page: {
                        limit: pageLimit,
                        page: pageToFetch,
                    },
                };

                dispatch(fetchLeadsByStage(stageId, adjustedQuery, isBoardView));
            }
        });
    };
}

/**
 * Async action that handles the aftermath of a lead successfully changing
 * stages. We'll clear our states "activeStageTransition" (so we're not
 * blocking any user interactions, showing loadings spinners, etc), then
 * go refetch the list item that changed stage.
 *
 * @param  {string} leadId        - leadId of the listItem that just changed stages
 * @param  {string} query         - Lead list query
 * @return {Function}             - Thunk to dispatch actions to clear our active
 *                                  stage transition, as well as update our listItem
 *                                  once its been refetched
 */
export function onLeadStageTransitionComplete(leadId: LeadId, query: ListQuery = {}) {
    return function(dispatch: Dispatch<*>, getState: () => {leads: Leads.State}) {
        const listItem = Leads.getLeadListItemMap(getState())[leadId];

        // We need to re-request our list item so the avatarUrl updates
        // (among other things)
        requestAndUpdateListItemById(leadId, query, dispatch).then(() =>
            dispatch(completeLeadStageTransition(listItem))
        );
    };
}

/**
 * Thunk that takes a leadId and outcomeId (/w optional additional data such
 * as competitors lost to, for example) and returns a Promise of the winning
 * lead async call.
 *
 * We return a promise here because our components are maintaining the error
 * and loading state, but we still need to fire related actions such as
 * updating our state to tell it "hey we're not longer in our closing-lead flow".
 *
 * @param  {string} leadId         - API-id of the the lead to close
 * @param  {string} outcomeId      - API-id of the outcome of the closed lead
 * @param  {object} query          - Query to re-request a lead
 * @param  {Object} data           - Additional data for the close request, such as
 *                                   competitorMaps/productMaps that are associated
 *                                   with the lead.
 *
 * @return {Function}              - Thunk that returns a Promise
 */
export function onConfirmCloseLead(
    leadId: LeadId,
    outcomeId: OutcomeId,
    query: ListQuery = {},
    data: Object = {}
) {
    return function(dispatch: Dispatch<*>) {
        return Leads.closeLead(leadId, outcomeId, data).then(() => {
            dispatch(
                updateLeadClosingConfirmation({
                    leadId: null,
                    closeType: null,
                })
            );

            // Re-request our listItem since we have a new status/avatarUrl
            // we need to update in the UI
            requestAndUpdateListItemById(leadId, query, dispatch);
        });
    };
}

/**
 * Helper function request the listItem representation of a lead by its API-id,
 * and dispatch an action to update that listItem within our store.
 *
 * Note, we're refetching a _list item_ here, not a lead, which is why
 * we're calling `Entities.fetchList` with a single `id` filter. Sort of a
 * hack, but works really well.
 *
 * @param {string} leadId       - API-id of the lead
 * @param {object} query        - Lead list query - we'll override parts of this,
 *                                but necessary to determine if re-fetched lead passes
 *                                the given filter set
 * @param {Dispatch} dispatch   - Dispatch function from redux
 *
 * @return {void}
 */
function requestAndUpdateListItemById(
    leadId: LeadId,
    query: ListQuery = {},
    dispatch: Dispatch<*>
) {
    // Safely apply defaults to our query (doesn't override properties)
    const queryWithDefaults = applyDefaultQueryParameters(query);
    const individualLeadFilter = {id: leadId};

    // Tack on an individual lead filter (we only care about one item)
    queryWithDefaults.filter = queryWithDefaults.filter.concat([individualLeadFilter]);

    return fetchList('leads', queryWithDefaults).then(
        (listApiResponse: ListApiResponseLeads) => {
            dispatch(Leads.resolveFetchListItems(listApiResponse));

            // Side effect to potentially dispatch an action
            // that will remove this item from state if it
            // doesn't match the current filter set
            checkIfUpdatedListItemShouldBeRemoved(
                dispatch,
                listApiResponse.listItems[0],
                queryWithDefaults.filter
            );
        },
        () => {
            // Fail silently, only a few visual updates happen on success,
            // not worth a user-facing error state
        }
    );
}

/**
 * Since solr is slow, it hasn't reindexed yet, and we need to manually
 * check client-side if the updated list item matches the existing filter
 * set. For example, if we're filtering on open leads and we just won
 * the lead, it won't match, and we'll remove it from our state.
 *
 * Currently, we're just checking if status matches.
 *
 * @param  {Dispatch} dispatch         - Dispatch function from redux
 * @param  {Object} [listItem]         - Full ListItem object
 * @param  {Object[]} filters          - Array of applied filters
 * @return {void}
 */
function checkIfUpdatedListItemShouldBeRemoved(
    dispatch: Dispatch<*>,
    listItem: ?ListItem,
    filters: FilterObject[]
) {
    const exisitingStatusFilter = ramda.find(ramda.has('status'), filters);

    if (exisitingStatusFilter && listItem && typeof exisitingStatusFilter === 'object') {
        const appliedStatuses = safelyGetRelationshipFilterIds(exisitingStatusFilter);

        if (
            listItem.fields.status &&
            !appliedStatuses.includes(listItem.fields.status.value.value)
        ) {
            dispatch(removeListItemById(listItem));
        }
    }
}

/**
 * Takes an array of FilterObjects and replaces (or adds) a single id'd stage
 * filter.
 * @param  {object[]} filters      - Array of existing filter objects
 * @param  {string} stageId        - stageId to use in the new filter
 * @return {object[]}              - New array of filter objects
 */
function applyStageFilter(filters?: Array<FilterObject>, stageId: StageId) {
    const stageFilter = {milestone: stageId};

    if (!filters) {
        return [stageFilter];
    }

    return filters
        .filter((filterObj) => Object.keys(filterObj)[0] !== 'milestone')
        .concat(stageFilter);
}
