/* @flow */

import * as React from 'react';

import {colors} from '../colors';

import './icon-avatar.css';

const DEFAULT_SIZE = 30;

type Props = {|
    icon?: React.ComponentType<*>,
    customIcon?: React.Node | string,
    avatarSize?: number,
    iconSize?: number,
    backgroundColor?: string,
    iconColor?: string,
    hasBorder?: boolean,
    borderColor?: string,
|};

/**
 * Renders the given icon inside a colored circle (Avatar).
 *
 * Default Avatar (circle) size is 30px and the default Icon size is half the avatar size (15px).
 * Default backgroundColor is offWhiteDk and default iconColor is grey.
 * Provided icon Component should take a size, wrapWithDiv and fill/color prop.
 */
export function IconAvatar(props: Props) {
    const {
        icon: Icon,
        customIcon,
        avatarSize = DEFAULT_SIZE,
        iconSize = Math.floor(DEFAULT_SIZE / 2),
        backgroundColor = colors.offWhiteDk,
        iconColor = colors.grey,
        hasBorder = false,
        borderColor = colors.offWhiteDk,
    } = props;

    const style = {
        width: avatarSize,
        height: avatarSize,
        borderRadius: avatarSize,
        backgroundColor,
        color: iconColor,
        border: hasBorder ? `1px solid ${borderColor}` : 'none',
    };

    const getContent = () => {
        if (customIcon) {
            if (typeof customIcon === 'string') {
                return <div style={{fontSize: iconSize}}>{customIcon}</div>;
            }

            return customIcon;
        }

        return Icon ? (
            <Icon size={iconSize} wrapWithDiv={false} fill={iconColor} color={iconColor} />
        ) : null;
    };

    return (
        <div style={style} styleName='icon-avatar'>
            {getContent()}
        </div>
    );
}
