/* @flow */

import * as React from 'react';
import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';

type Props = {
    fill?: string,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} fill={props.fill || colors.grey} viewBox='0 0 22 22'>
            <path d='M7.91 11.81H3.512v4.728H1V5h2.513v4.595h4.396V5h2.513v11.538H7.909V11.81ZM13.242 16.538v-2h3.108V6.802h-.149l-2.347 3.074-1.587-1.24L15.027 5h3.803v9.538h2.446v2h-8.034Z' />
        </svg>
    );
}
SvgComponent.iconName = 'h1';

export const H1Icon = svgIconWrapper(SvgComponent);
