import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Reply from './icon-reply.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Reply {...validProps} />;
}
SvgComponent.iconName = 'reply';

export const ReplyIcon = svgIconWrapper(SvgComponent);
