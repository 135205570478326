import React from 'react';

import {svgIconWrapper} from '../svg-icon-wrapper';
import Desktop from './icon-desktop.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Desktop {...validProps} />;
}
SvgComponent.iconName = 'desktop';

export const DesktopIcon = svgIconWrapper(SvgComponent);
