/* @flow */

import * as React from 'react';

import {PowerAiUsagePopover} from '../../../../../../../ui/power-ai/power-ai-usage-popover/power-ai-usage-popover';
import {useGenerateEntitySummary} from '../../../../../../../ui/power-ai/timeline-summary/graphql/hooks/use-generate-entity-summary';

import {SparkleIcon, SparkleOpenIcon, MemoIcon, VirtualMeetingIcon} from '../../../icon';
import {DynamicButtonContent} from '../../../dynamic-button-content';
import {Link} from '../../../link';
import {Divider} from '../../../layout';
import {colors} from '../../../colors';
import {TranscriptModal} from '../zoom-transcript/transcript-modal';

import './zoom-data.css';

// ZoomMeetingDataProps
export type Props = {|
    zoomMeetingId: string,
    recordingUrl: ?string,
    transcript: ?string,
    transcriptionSummary: ?{
        id: string,
        summary: ?string,
    },
    setIsShowingTranscriptSummary: (boolean) => void,
|};

export const ZoomMeetingData = (props: Props) => {
    const [isShowingTranscript, setIsShowingTranscript] = React.useState(false);
    const {recordingUrl, transcript, setIsShowingTranscriptSummary} = props;
    const buttonContainerRef = React.useRef(null);
    const buttonRef = React.useRef(null);

    const {generateEntitySummary} = useGenerateEntitySummary();

    return (
        <div className='flex align-center'>
            {recordingUrl ? (
                <div styleName='container'>
                    <Link href={recordingUrl} newTab={true}>
                        <VirtualMeetingIcon size={18} alignCenter={true} />
                    </Link>
                </div>
            ) : (
                undefined
            )}
            {recordingUrl && transcript ? <Divider variant='dot' /> : undefined}
            {transcript ? (
                <>
                    <div styleName='container' onClick={() => setIsShowingTranscript(true)}>
                        <MemoIcon size={16} wrapWithDiv={false} alignCenter={true} />
                    </div>
                    {NutClientConfig.enableAi ? (
                        <>
                            <Divider variant='dot' />
                            <div ref={buttonContainerRef}>
                                <button
                                    ref={buttonRef}
                                    styleName='lightbulb-container'
                                    onClick={() => {
                                        if (buttonRef.current && buttonRef.current.disabled) {
                                            return;
                                        }
                                        if (!props.transcriptionSummary) {
                                            generateEntitySummary({entityId: props.zoomMeetingId});
                                        }
                                        setIsShowingTranscriptSummary(true);
                                    }}
                                >
                                    <DynamicButtonContent
                                        buttonRef={buttonRef}
                                        defaultContent={
                                            <SparkleOpenIcon size={18} alignCenter={true} />
                                        }
                                        hoverContent={
                                            <SparkleIcon
                                                color={colors.greyDk}
                                                size={18}
                                                alignCenter={true}
                                            />
                                        }
                                    />
                                </button>
                            </div>
                            <PowerAiUsagePopover
                                popoverAnchor={buttonContainerRef}
                                buttonRef={buttonRef}
                                variant='zoom'
                            />
                        </>
                    ) : (
                        undefined
                    )}
                </>
            ) : (
                undefined
            )}
            {transcript && isShowingTranscript ? (
                <TranscriptModal
                    transcript={transcript}
                    transcriptionSummary={props.transcriptionSummary}
                    onClose={() => setIsShowingTranscript(false)}
                />
            ) : (
                undefined
            )}
        </div>
    );
};
