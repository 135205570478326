/* @flow */

import * as React from 'react';
import ReactDOMServer from 'react-dom/server';

import {colors} from '../colors';
import {LeadIconChevron} from '../icon';

import './lead-count-avatar.css';

type Props = {
    leads: {id: string, name: string}[],
    size: number,
};

export function LeadCountAvatar(props: Props) {
    const {leads, size} = props;

    return (
        <div
            styleName='lead-chevron-container'
            style={{width: size, height: size, borderRadius: size}}
            data-html={true}
            data-tip={ReactDOMServer.renderToString(
                <div>
                    {leads.map((lead) => (
                        <div key={lead.name}>{lead.name}</div>
                    ))}
                </div>
            )}
            data-place='bottom'
        >
            <LeadIconChevron wrapWithDiv={false} size={Math.ceil(size / 1.4)} fill={colors.white} />
            <div
                styleName='count-container'
                style={{width: size, height: size, fontSize: Math.ceil(size / 2.6)}}
            >
                <div>{leads.length}</div>
            </div>
        </div>
    );
}
