/* @flow */

import * as React from 'react';
import {Avatar} from '../avatar';
import {colors} from '../colors';
import {LeadIconChevron} from '../icon';
import './lead-avatar.css';

type LoadingProps = {
    isLoading: true,
    includeNumber?: boolean,
};

type LoadedProps = {
    isLoading?: false,
    avatarUrl: string,
    leadNumber?: ?number,
};

type Props = LoadedProps | LoadingProps;

export function LeadAvatar(props: Props) {
    if (props.isLoading) {
        return (
            <div styleName='avatar'>
                <LeadIconChevron
                    wrapWithDiv={false}
                    size={props.includeNumber ? 24 : 32}
                    fill={colors.offWhiteDk}
                />
                {props.includeNumber ? <div styleName='number' /> : null}
            </div>
        );
    }

    return (
        <div styleName='avatar'>
            <Avatar type='leads' avatarUrl={props.avatarUrl} size={props.leadNumber ? 24 : 32} />
            {props.leadNumber ? <span styleName='number'>{props.leadNumber}</span> : null}
        </div>
    );
}
