/* @flow */
import {get as getRequest} from './get';
import {post as postRequest} from './post';
import {put as putRequest} from './put';
import {patch as patchRequest} from './patch';
import {deleteRequest} from './delete';

import {defaultOptions} from './default-options';
import {mergeHeaders} from './merge-headers';

/*
 * Utility helper to ensure that a string does not start with a slash
 */
function stripLeadingSlash(path: string) {
    return path.startsWith('/') ? path.substr(1) : path;
}

/*
 * Class for interacting with the Netshell API. It applies
 * sensible default configuration and error handling, and can be configured with
 * a baseUrl to prepend to all requests.
 */
export class Api {
    baseUrl: string;
    options: RequestOptions;

    constructor(baseUrl?: string) {
        if (baseUrl && typeof baseUrl === 'string') {
            this.setBaseUrl(baseUrl);
        } else {
            this.baseUrl = '/rest/';
        }
        this.options = defaultOptions;
    }

    // Change the base url added to all requests.
    setBaseUrl(baseUrl: string) {
        if (baseUrl.endsWith('/')) {
            this.baseUrl = baseUrl;
        } else {
            this.baseUrl = `${baseUrl}/`;
        }
    }

    getBaseUrl() {
        return this.baseUrl;
    }

    // Merge any new options in with the default options
    setOptions(options: RequestOptions) {
        this.options = {...defaultOptions, ...options};
    }

    resetOptions() {
        this.options = defaultOptions;
    }

    getOptions() {
        return this.options;
    }

    get(path: string, search?: Object, options?: RequestOptions) {
        return getRequest(this.createUrl(path), search, this.mergeOptions(options));
    }

    post(path: string, body: Object, options?: RequestOptions) {
        return postRequest(this.createUrl(path), body, this.mergeOptions(options));
    }

    put(path: string, body: Object, options?: RequestOptions) {
        return putRequest(this.createUrl(path), body, this.mergeOptions(options));
    }

    patch(path: string, body: Object[], options?: RequestOptions) {
        return patchRequest(this.createUrl(path), body, this.mergeOptions(options));
    }

    delete(path: string, options?: RequestOptions) {
        return deleteRequest(this.createUrl(path), this.mergeOptions(options));
    }

    createUrl(path: string) {
        // It might already be fully qualified, if so, use it directly
        if (path.startsWith('http')) return path;

        return `${this.baseUrl}${stripLeadingSlash(path)}`;
    }

    mergeOptions(options?: RequestOptions) {
        if (!options) return this.options;

        return {
            ...this.options,
            ...options,
            headers: mergeHeaders(options.headers, this.options.headers),
        };
    }
}

// A singleton with default configuration, for interacting with the `/rest`
// Nutshell endpoint.
export const api = new Api();

// A singleton for form-encoded api calls
export const formEncodedApi = new Api('/');
const headers = new Headers({
    'Content-Type': 'application/x-www-form-urlencoded',
    accept: '*/*',
    'X-Requested-With': 'XMLHttpRequest',
});
formEncodedApi.setOptions({headers});
