/* @flow */

import * as React from 'react';

import classnames from 'classnames';

import './section-label.css';

type Props = {
    children?: React.Node,
    as: string,
    noBottomBorder?: boolean,
    noBottomMargin?: boolean,
    htmlFor?: string,
};

SectionLabel.defaultProps = {
    as: 'div',
};

/*
 * This component is responsible for rendering a 12px all uppercased
 * section label that we use on peep and lead pages.
 */
export function SectionLabel({children, as: Tag, noBottomBorder, noBottomMargin, htmlFor}: Props) {
    const styleNames = classnames('section-label', {
        'no-bottom-border': noBottomBorder,
        'no-bottom-margin': noBottomMargin,
    });

    return (
        <Tag styleName={styleNames} htmlFor={htmlFor}>
            {children}
        </Tag>
    );
}
