/* @flow */

import * as React from 'react';

import {colors} from '../colors';

type Props = {
    variant: 'dot' | 'line--vertical' | 'line--horizontal',
    spacing?: number,
    color?: string,
};

Divider.defaultProps = {
    color: colors.offWhiteDk,
};

export function Divider(props: Props) {
    const {variant, spacing, color} = props;

    // Variants 'dot' and 'line--vertical' are displayed inline, 'line--horizontal' is block
    const Tag = variant === 'line--horizontal' ? 'div' : 'span';

    let padding;
    let margin;
    if (spacing) {
        switch (variant) {
            case 'line--horizontal':
                padding = `${spacing}px 0 0 0`;
                margin = `${spacing}px 0 0 0`;
                break;
            case 'line--vertical':
                padding = `0 ${spacing}px 0 0`;
                margin = `0 ${spacing}px 0 0`;
                break;
            case 'dot':
                padding = `0 ${spacing}px`;
                break;
        }
    }

    return (
        <Tag
            style={{
                padding,
                margin,
                color,
                borderColor: color,
                borderTop: variant === 'line--horizontal' ? '1px solid' : undefined,
                borderRight: variant === 'line--vertical' ? '1px solid' : undefined,
            }}
        >
            {variant === 'dot' ? '•' : undefined}
        </Tag>
    );
}
