/* @flow */
import * as React from 'react';

import {AttributionReportDescription} from './attribution/attribution-report-description';

import {
    SALES,
    NEW_LEADS,
    LOSSES,
    CUSTOM,
    FORECAST,
    ACTIVITY,
    EMAIL,
    ATTRIBUTION,
} from './report-constants';

export const getAttributionReportDescription = () => <AttributionReportDescription />;

// Helper function to keep all of this logic out of the root component
export function shouldShowReportControls(reportKey: string): boolean {
    return (
        reportKey === SALES ||
        reportKey === NEW_LEADS ||
        (reportKey === EMAIL && NutClientConfig.hasEmailReport) ||
        (reportKey === ATTRIBUTION && NutClientConfig.hasAttributionReport) ||
        (reportKey === ACTIVITY && NutClientConfig.hasActivityReport) ||
        ((reportKey === FORECAST || reportKey === CUSTOM || reportKey === LOSSES) &&
            NutClientConfig.hasOnDemandReporting)
    );
}
