/* @flow */

import * as React from 'react';
import {Field} from 'redux-form';
import {useSelector} from 'react-redux';

import {Assignables} from 'nutshell-core/assignables';

import {FormField} from 'shells/form';

import {AUTO_RESOLVE_ID} from './consts';
import {AssigneeSelect} from './assignee-select';

type Props = {|
    fieldKey: string,
    isRequired: boolean,
    validate: Function,
    isDisabled: boolean,
    showAutoAssign?: boolean,
|};

const AUTO_ASSIGN_OPTION = {
    id: AUTO_RESOLVE_ID, // need a unique id which we set to null before sending it to the backend
    name: 'Auto assign',
    type: 'users',
    avatarUrl: '/include/images/icon-auto-assign_2x.png',
    isEnabled: true,
};

export function AssigneeSelectField(props: Props) {
    const assignables = useSelector(Assignables.getAllEnabled);

    const options = props.showAutoAssign ? [AUTO_ASSIGN_OPTION, ...assignables] : assignables;

    return (
        <FormField
            key={props.fieldKey}
            innerId={props.fieldKey}
            isRequired={props.isRequired}
            label='Assignee'
        >
            <Field
                name={props.fieldKey}
                component={AssigneeSelect}
                options={options}
                placeholder='Select an assignee'
                {...props}
            />
        </FormField>
    );
}
