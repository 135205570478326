/* @flow */

import * as React from 'react';
import {type LocationEnum} from '../popover';
import {Dialog} from './dialog';

type Props = {
    anchor: ?HTMLElement,
    location?: LocationEnum,
    message: string,
    onClose: () => void,
};

export class ErrorDialog extends React.Component<Props, *> {
    static defaultProps = {
        onClose: function() {},
    };

    render() {
        return (
            <Dialog
                anchor={this.props.anchor}
                question={this.props.message}
                location={this.props.location}
                isError={true}
                primaryText='Okay'
                onPrimary={this.props.onClose}
            />
        );
    }
}
