/* @flow */

import * as React from 'react';

import ReactTooltip from 'react-tooltip';

import {ContactIcon, ExternalLinkIcon} from '../../../icon';
import {ContactMethodStatuses} from '../../../contact-method-statuses';

import './person-card-content.css';

type Props = {
    id: string,
    name: string,
    location: ?string,
    jobTitle: ?string,
    position: ?string,
    // added contact only
    email?: ?string,
    phone?: ?string,
    // addable person only
    hasEmail?: boolean,
    hasPhone?: boolean,
    hasLinkedinUrl?: boolean,
    isSelected?: boolean,
    onClickName?: ?(string) => any,
};

export function PersonCardContent(props: Props) {
    const isContact =
        typeof props.hasEmail === 'undefined' && typeof props.hasPhone === 'undefined';

    React.useEffect(() => {
        ReactTooltip.rebuild();

        return () => ReactTooltip.hide();
    });

    return (
        <div styleName='person-card-content'>
            {isContact ? (
                <ContactIcon size={15} wrapWithDiv={false} />
            ) : (
                <input type='checkbox' checked={props.isSelected} readOnly={true} />
            )}
            <div styleName='details'>
                {props.onClickName ? (
                    <div styleName='name-button-container'>
                        {/* $FlowIgnore checked above */}
                        <div onClick={() => props.onClickName(props.id)}>{props.name}</div>
                    </div>
                ) : (
                    <div>{props.name}</div>
                )}
                {isContact && props.email && <div>{props.email}</div>}
                {isContact && props.phone && <div>{props.phone}</div>}
                {props.jobTitle && <div>{props.jobTitle}</div>}
                {props.position && <div>{props.position}</div>}
                {props.location && <div>{props.location}</div>}
            </div>
            <div>
                {isContact ? (
                    <ExternalLinkIcon size={13} wrapWithDiv={false} />
                ) : (
                    <ContactMethodStatuses
                        hasEmail={props.hasEmail}
                        hasLinkedinUrl={props.hasLinkedinUrl}
                        hasPhone={props.hasPhone}
                    />
                )}
            </div>
        </div>
    );
}
