import classNames from 'classnames';

function getClassSet(classString) {
    const classObj = {};

    if (classString) {
        classString.split(' ').forEach((className) => {
            classObj[className] = true;
        });
    }

    return classObj;
}

export default function getClasses(initialClasses, additionalClassObj) {
    let classString = '';
    const className = this.props.className;

    // Initialize the classString with the classNames that were passed in
    if (className && className.length) classString += ` ${this.props.className}`;

    // Add in initial classes
    if (typeof initialClasses === 'object') {
        classString += ` ${classNames(initialClasses)}`;
    } else {
        classString += ` ${initialClasses}`;
    }

    // Add in additional classes
    if (additionalClassObj) classString += ` ${classNames(additionalClassObj)}`;

    // Convert the class string into an object and run it through the class set
    return String.prototype.trim.apply(classNames(getClassSet(classString)));
}
