/* @flow */

import type {McfxViqContactFragment as Contact} from 'nutshell-graphql-types';

export const ActionTypes = {
    SIDEBAR_CLOSED: 'SIDEBAR_CLOSED',
    SIDEBAR_OPENED: 'SIDEBAR_OPENED',
    DISMISS_FOR_ACCOUNT: 'DISMISS_FOR_ACCOUNT',
};

type PeopleIqSidebarOpenedPayload = {
    accountId: string,
    accountName: string,
    leadId: ?string,
    contacts: Contact[],
};

type PeopleIqDismissForAccountPayload = {
    accountId: string,
};

type SidebarClosedAction = {type: 'SIDEBAR_CLOSED'};
type SidebarOpenedAction = {
    type: 'SIDEBAR_OPENED',
    payload: PeopleIqSidebarOpenedPayload,
};
type PeopleIqDismissForAccountAction = {
    type: 'DISMISS_FOR_ACCOUNT',
    payload: PeopleIqDismissForAccountPayload,
};

export type SidebarAction =
    | SidebarOpenedAction
    | SidebarClosedAction
    | PeopleIqDismissForAccountAction;

/*
 * Action creators
 */
export const closeSidebar = (): SidebarClosedAction => ({
    type: ActionTypes.SIDEBAR_CLOSED,
});

export const openSidebar = (payload: PeopleIqSidebarOpenedPayload): SidebarOpenedAction => ({
    type: ActionTypes.SIDEBAR_OPENED,
    payload,
});

export const dismissForAccount = (
    payload: PeopleIqDismissForAccountPayload
): PeopleIqDismissForAccountAction => ({
    type: ActionTypes.DISMISS_FOR_ACCOUNT,
    payload,
});
