/* @flow */

import * as React from 'react';
import {Link as RoutedLink} from 'react-router-dom';

import type {FormattedValue} from 'nutshell-core/types';

import {Link} from '../../../link';
import {LeadTwoLine} from '../../../lead';

import './company-lead.css';

export type Lead = {|
    leadName: string,
    htmlUrlPath: string,
    htmlUrl: string,
    leadStatus: string,
    avatarUrl: string,
    formattedValue?: FormattedValue,
    relatedPerson?: ?{
        id: string,
        type: string,
        htmlUrl: string,
        name: string,
    },
    relatedCompany?: ?{
        id: string,
        type: string,
        htmlUrl: string,
        name: string,
    },
|};

type Props = {|
    ...Lead,
    onClose: () => void,
|};

export function CompanyLead(props: Props) {
    return (
        <div styleName='detail--full'>
            <div styleName='detail-name'>Lead</div>
            <div styleName='company-lead'>
                <Link
                    as={RoutedLink}
                    to={props.htmlUrlPath}
                    onClick={props.onClose}
                    size='small'
                    preventUnderline={true}
                >
                    <LeadTwoLine
                        avatarUrl={props.avatarUrl}
                        leadName={props.leadName}
                        leadStatus={props.leadStatus}
                        relatedPerson={props.relatedPerson}
                        relatedCompany={props.relatedCompany}
                        formattedValue={props.formattedValue}
                        htmlUrl={props.htmlUrl} // unused - but crashes app if not provided
                        // Hardcoded - we don't want to show the number or link within the lead card
                        leadNumber={null}
                        isLinkable={false}
                    />
                </Link>
            </div>
        </div>
    );
}
