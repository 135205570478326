import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Pin from './pin.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Pin {...validProps} />;
}
SvgComponent.iconName = 'pin';

export const PinIcon = svgIconWrapper(SvgComponent);
