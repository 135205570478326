/* @flow */

import * as React from 'react';
import type {FieldArrayProps} from 'redux-form';

import type {ListOptionWithCount} from 'shells/select-option-components/option-with-count';

import {GraphQLSelectAudiencesProvider} from '../../select/graphql-select-audiences-provider';
import {CreateableField} from './createable-field';
import {useSetNewItem} from './use-set-new-item';

import './forms.css';

type Props = {
    ...FieldArrayProps,
    shouldAnd: boolean,
};

export function AudienceSelect(props: Props): React.Node {
    const [audienceOptions, setAudienceOptions] = React.useState<ListOptionWithCount[]>([]);
    const [newAudience, setNewAudience] = React.useState<?string>(null);

    const {fields} = props;

    if (fields.length === 0) {
        // If we don't have any audiences, add an empty value
        fields.push(null);
    }

    useSetNewItem(newAudience, setNewAudience, fields);

    return (
        <GraphQLSelectAudiencesProvider>
            {({options, isLoading, refetch}) => {
                if (options.length > 0 && !audienceOptions.length) {
                    setAudienceOptions(options);
                }

                return (
                    <CreateableField
                        {...props}
                        setNewItem={(newValue: ?string) => setNewAudience(newValue)}
                        setOptions={(newValue: ListOptionWithCount[]) =>
                            setAudienceOptions(newValue)
                        }
                        options={audienceOptions}
                        refetch={refetch}
                        shouldAnd={props.shouldAnd}
                        isLoading={isLoading}
                        type='audiences'
                    />
                );
            }}
        </GraphQLSelectAudiencesProvider>
    );
}
