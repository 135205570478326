/* @flow */

import * as React from 'react';
import {Field} from 'redux-form';

import {FormField} from 'shells/form';
import {ReduxFormPriorityToggle} from 'shells/redux-form-fields';

import {PipelineSelectSingle} from '../../core/form/fields';

import './stageset-priority-fields.css';

type Props = {
    fieldKey: string,
    title: string,
    isRequired: boolean,
    validate: Function,
    hasPriorityField: boolean,
};

export function StagesetPriorityFields(props: Props) {
    const stagesetField = (
        <FormField
            key={props.fieldKey}
            innerId={props.fieldKey}
            label={props.title}
            isRequired={props.isRequired}
            validate={props.validate}
        >
            <Field
                name={props.fieldKey}
                component={PipelineSelectSingle}
                autoFocus={false}
                isRequired={props.isRequired}
                includeAutoDistribute={true}
            />
        </FormField>
    );

    if (props.hasPriorityField) {
        const priorityToggle = (
            <Field key='priority' name='priority' component={ReduxFormPriorityToggle} />
        );

        return (
            <div className='flex'>
                <div styleName='stageset'>{stagesetField}</div>
                <div styleName='priority'>{priorityToggle}</div>
            </div>
        );
    } else {
        return stagesetField;
    }
}
