/* @flow */

import * as React from 'react';
import {connect} from 'react-redux';

import capitalize from 'underscore.string/capitalize';
import _ from 'underscore';

import {Session} from 'nutshell-core/session';
import type {NotifyMessage} from 'nutshell-core/types';

import {CloudDownloadIcon, LoadingIcon} from 'shells/icon';
import {Popover} from 'shells/popover';
import {NotifiesList} from 'shells/notifies-list/notifies-list';

import './notifies.css';

type OwnProps = {
    notifies: NotifyMessage[],
    onAcknowledgeExportDownload: () => any,
};
type DispatchProps = {|
    onAcknowledgeExport: (notify: NotifyMessage) => any,
    onAcknowledgeExportDownload: () => any,
|};

type Props = {...OwnProps, ...DispatchProps};
type State = {
    popoverOpen: boolean,
};

function mapDispatchToProps(dispatch): DispatchProps {
    return {
        onAcknowledgeExport: (notify) => {
            dispatch(Session.acknowledgeExport(notify));
        },
        onAcknowledgeExportDownload: () => {
            dispatch(Session.requestFetchSession());
        },
    };
}

class NotifiesComponent extends React.Component<Props, State> {
    popoverNode: ?HTMLElement;

    constructor() {
        super();
        this.state = {
            popoverOpen: false,
        };
    }

    render() {
        const {notifies} = this.props;
        const completedNotifyActions = notifies.filter((notify) => notify.status === 'complete');
        const notifyTypes = _.uniq(
            _.pluck(
                notifies.filter((notify) => notify.status !== 'complete'),
                'type'
            )
        )
            .map((notifyType) => capitalize(`${notifyType}`))
            .join(' and ');

        const completedCount = completedNotifyActions.length;

        const types = _.uniq(
            _.pluck(notifies, 'type').map((notifyType) => `${_.last(notifyType.split(' '))}s`)
        ).join(' and ');

        return (
            <div
                ref={(c) => {
                    this.popoverNode = c;
                }}
                styleName={completedCount ? 'container-ready' : 'container'}
                onClick={this.handleClick}
            >
                {completedCount && notifies.length === completedCount ? (
                    <div>
                        <div styleName='notifies-icon'>
                            <CloudDownloadIcon />
                        </div>
                        <div styleName='notifies-text'>Download ready</div>
                    </div>
                ) : (
                    <div>
                        <div styleName='notifies-icon'>
                            <LoadingIcon />
                        </div>
                        <div styleName='notifies-text'>{notifyTypes} processing…</div>
                    </div>
                )}
                {this.state.popoverOpen ? (
                    <Popover onBlur={this.closePopover} anchor={this.popoverNode}>
                        <div styleName='popover'>
                            <NotifiesList
                                notifyMessages={notifies}
                                types={types}
                                onAcknowledgeExport={this.props.onAcknowledgeExport}
                                onAcknowledgeExportDownload={this.props.onAcknowledgeExportDownload}
                            />
                        </div>
                    </Popover>
                ) : (
                    undefined
                )}
            </div>
        );
    }

    closePopover = (e: SyntheticEvent<>) => {
        // This prevents bubbling clicks and re-popping the popover
        // in the method below. For a reason I can't understand,
        // the handleClick method gets called anytime _any_ part
        // of the screen is clicked while this popover is open.
        e.stopPropagation();

        this.setState({popoverOpen: false});
    };

    handleClick = () => {
        const completedExports = this.props.notifies.filter(
            (notify) => notify.status === 'complete'
        );
        const totalExports = this.props.notifies.filter((notify) => !notify.acknowledged);

        if (completedExports.length === 1 && totalExports.length === 1) {
            const {hash, filename, name} = completedExports[0];

            // TODO (@ianvs 2017-06-13): Remove the `if` once we fix flow type to
            // know that these are not null when status === 'complete'
            if (hash && filename) {
                this.props.onAcknowledgeExportDownload();

                // Download the export
                window.location = `/file/export?hash=${hash}&filename=${filename}&notifyId=${name}`;
            }
        } else {
            this.setState((prevState) => ({popoverOpen: !prevState.popoverOpen}));
        }
    };
}

const connector = connect<Props, OwnProps, _, _, _, _>(undefined, mapDispatchToProps);

export const Notifies = connector(NotifiesComponent);
