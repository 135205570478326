/* @flow */

import type {Item} from './types';

export function getUpdatedItemsList(currentItemsList: Item[], updatedItemId: string): Item[] {
    const updatedList = [...currentItemsList];

    const updateItemIndex = updatedList.findIndex((item) => item.id === updatedItemId);

    if (updateItemIndex !== -1) {
        updatedList[updateItemIndex] = {
            ...updatedList[updateItemIndex],
            isChecked: !updatedList[updateItemIndex].isChecked,
        };
    }

    return updatedList;
}

export function getBulkUpdatedItemsList(currentItemsList: Item[], isSelectingAll: boolean): Item[] {
    const updatedList = [...currentItemsList];

    return updatedList.map((item) => ({...item, isChecked: isSelectingAll}));
}
