/* @flow */

import * as React from 'react';
import {Header} from '../typography';
import './onboarding-body.css';

type Props = {
    title: string | React.Element<'span'>,
    children: React.Node,
    style?: any,
};

export function OnboardingBody({title, children, style}: Props) {
    return (
        <div styleName='main-content' style={style}>
            <Header>{title}</Header>
            {children}
        </div>
    );
}
