/* @flow */

import type {AppState} from './app-reducer';
import {REDUCER_KEY} from './app-types';

// The root state will include more keys, but this is what we really care about here
type AppModuleState = {app: AppState};

/*
 * Given the entire redux state, pull out just the piece of state for the app reducer.
 */
export const getAppState = (state: AppModuleState): AppState => state[REDUCER_KEY];

export const getAppVersion = (state: AppModuleState) => {
    return getAppState(state).appVersion;
};

export const getApiVersion = (state: AppModuleState) => {
    return getAppState(state).apiVersion;
};
