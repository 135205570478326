/* @flow */

import {jsonToModelProp, jsonToModelPropArray} from 'nutshell-core';
import {api} from 'nutshell-core/api';

import type {DashType} from '../types';
import {getDashEntityName, getDashEndpoint, getDashEntityIsArray} from '../dashboard-constants';

export function fetchDashData(dashType: DashType, dashValue: string) {
    const entityName = getDashEntityName(dashType);
    const dashEndpoint = getDashEndpoint(dashType);

    return api
        .get(`${dashEndpoint}?${dashValue}`)
        .then((res) => res.json())
        .then((json: Object): Object => {
            if (entityName === 'lists') {
                const models = jsonToModelPropArray(
                    json[entityName][0].listItems,
                    json[entityName][0],
                    'listItems'
                );

                return {
                    list: {
                        id: json[entityName][0].htmlUrl,
                        meta: json[entityName][0].meta,
                        htmlUrl: json[entityName][0].htmlUrl,
                        value: json[entityName][0].value,
                        reportType: json[entityName][0].reportType,
                        listItems: models,
                    },
                };
            }

            if (entityName === 'funnel') {
                const models = jsonToModelPropArray(
                    json.dashboards[0].milestones,
                    json.dashboards[0],
                    'milestones'
                );

                return {
                    [entityName]: models,
                    stagesetName: json.dashboards[0].stagesetName,
                };
            }

            if (getDashEntityIsArray(dashType)) {
                const models = jsonToModelPropArray(json[entityName], json, entityName);

                return {
                    [entityName]: models,
                };
            }

            return jsonToModelProp(json[entityName][0].id, json, entityName) || {};
        });
}
