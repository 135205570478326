export function writeLocalStorage(key, data) {
    try {
        localStorage.setItem(key, data);

        return true;
    } catch (e) {
        return false;
    }
}

export function readLocalStorage(key) {
    try {
        return localStorage && localStorage.getItem(key);
    } catch (e) {
        return undefined;
    }
}

export function removeLocalStorageItem(key) {
    try {
        localStorage.removeItem(key);

        return true;
    } catch (e) {
        return false;
    }
}
