/* @flow */

import * as React from 'react';
import {connect} from 'react-redux';
import * as ramda from 'ramda';
import {FlashBanner} from 'shells/flash-banner';
import type {NutshellState} from '../../store';
import type {FlashMessage} from '../creator/types';
import {getFlashMessages} from '../creator/creator-selectors';
import * as createNewActions from '../creator/creator-actions';
import './flash-messages.css';

type StateProps = {|
    flashMessages: {[messageId: string]: FlashMessage},
|};
type DispatchProps = {|
    removeFlashMessage: (string) => void,
|};
type Props = {...StateProps, ...DispatchProps};

type State = {
    flashMessageComponents: React.Element<any>[],
};

function mapStateToProps(state: NutshellState): StateProps {
    return {
        flashMessages: getFlashMessages(state),
    };
}

function mapDispatchToProps(dispatch): DispatchProps {
    return {
        removeFlashMessage: (messageId) => {
            dispatch(createNewActions.removeFlashMessage(messageId));
        },
    };
}

export class FlashMessagesComponent extends React.PureComponent<Props, State> {
    constructor() {
        super();
        this.state = {
            flashMessageComponents: [],
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const newFlashMessageIds = ramda.difference(
            Object.keys(nextProps.flashMessages),
            Object.keys(this.props.flashMessages)
        );
        if (newFlashMessageIds.length) {
            this.setState((state) => {
                const flashMessageComponents = newFlashMessageIds.reduce((components, newId) => {
                    const newFlashMessage = nextProps.flashMessages[newId];
                    const key = newId;
                    const onHidden = () => {
                        this.setState((prevState) => {
                            const otherFlashMessages = prevState.flashMessageComponents.filter(
                                (banner) => banner.key !== newId
                            );

                            return {flashMessageComponents: otherFlashMessages};
                        });

                        // Remove the hidden message from redux as well
                        this.props.removeFlashMessage(newId);
                    };

                    components.push(
                        <FlashBanner
                            duration={
                                typeof newFlashMessage.duration !== 'undefined'
                                    ? newFlashMessage.duration
                                    : 3500
                            }
                            type={newFlashMessage.type}
                            key={key}
                            onHidden={onHidden}
                        >
                            {newFlashMessage.message}
                        </FlashBanner>
                    );

                    return components;
                }, state.flashMessageComponents);

                return {flashMessageComponents};
            });
        }
    }

    render() {
        return <div styleName='flash-messages'>{this.state.flashMessageComponents}</div>;
    }
}

const connector = connect<Props, {||}, _, _, _, _>(mapStateToProps, mapDispatchToProps);
export const FlashMessages = connector(FlashMessagesComponent);
