/* @flow */

import * as React from 'react';
import './button-mover.css';

type Size = 'small' | 'normal' | 'big' | 'xlarge';
type Direction = 'up' | 'down' | 'left' | 'right';

type Props = {
    /** Same sizes as our Button shell */
    size: Size,
    direction: Direction | Direction[],
    children: React.Node,
};

/**
 * This component deals with aligning a Button shell with adjoining elements.
 * It's needed because buttons have padding, and we want to keep the padding for the larger
 * click targets they give us.  Ideally we'd do what we do in the email clients and use a pseudo
 * element that gives a bigger target while leaving the element in the right spot.
 * But this is what we have to live with for now.
 */
export function ButtonMover(props: Props) {
    const {direction, size} = props;
    const directions: Direction[] = typeof direction === 'string' ? [direction] : direction;
    const moves = directions.map<{dir: Direction, px: number}>((dir) => ({
        dir,
        px: pxMap[size][dir],
    }));
    const styleName = moves.map(({dir, px}) => `move-${dir}-${px}`).join(' ');

    return <div styleName={styleName}>{props.children}</div>;
}

const pxMap: {[size: Size]: {[dir: Direction]: number}} = {
    small: {
        up: 4,
        down: 3,
        left: 10,
        right: 10,
    },
    normal: {
        up: 4,
        down: 4,
        left: 10,
        right: 10,
    },
    big: {
        up: 7,
        down: 7,
        left: 12,
        right: 12,
    },
    xlarge: {
        up: 12,
        down: 12,
        left: 24,
        right: 24,
    },
};
