/* @flow */

const defaultHeadersObject = {
    Accept: 'application/vnd.api+json, application/json',
};

export const defaultOptions: RequestOptions = {
    credentials: 'same-origin',
    headers: defaultHeadersObject,
};

export const defaultHeaders = new Headers(defaultHeadersObject);
