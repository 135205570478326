/* @flow */

import {useQuery} from '@apollo/react-hooks';
import {makeDataSafe} from 'nutshell-core/utils/graphql-errors';
import type {GetMcfxCompany as GetMcfxCompanyQuery} from 'nutshell-graphql-types';

import GET_MCFX_COMPANY from '../queries/get-mcfx-company.graphql';

export const useGetMcfxCompany = (visitorId: string) => {
    const {data, loading: isLoading} = useQuery<GetMcfxCompanyQuery, {}>(GET_MCFX_COMPANY, {
        variables: {
            id: visitorId,
        },
    });

    const {mcfxCompany} = makeDataSafe(data);

    return {mcfxCompany, isLoading};
};
