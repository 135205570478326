/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import type {SavedSettingToastState} from '../../saved-setting-toast';
import {SelectPickerGenericMulti, type SelectPickerGenericProps} from '../../select-picker-generic';
import {FormField} from '../../form';

type OwnProps = {|
    title?: string,
    helperText: string,
    autoFocus?: boolean,
    isRequired?: boolean,
    onRemove?: () => void,
    saveToastState?: SavedSettingToastState,
    errorMessage?: string,
    ...SelectPickerGenericProps,
|};

type Props = {...$Exact<FieldProps>, ...OwnProps};

export function ReduxFormSelectPickerMulti(props: Props) {
    const {
        autoFocus = true,
        title,
        helperText,
        isRequired,
        input,
        meta,
        onChange,
        onRemove,
        saveToastState,
        errorMessage,
        ...restProps
    } = props;

    return (
        <FormField
            innerId={input.name}
            label={title}
            helperText={helperText}
            errorMessage={errorMessage || (meta.error && meta.submitFailed ? meta.error : null)}
            isRequired={isRequired}
            saveToastState={saveToastState}
        >
            {/* $FlowIgnore I can't get this to work because of the exact prop types */}
            <SelectPickerGenericMulti
                autoFocus={autoFocus}
                {...input}
                {...restProps}
                onBlur={() => {
                    // Who can explain it, who can tell why. Somehow, this
                    // works. If the params are passed through, the value for
                    // this field is reset on blur :shrug:
                    input.onBlur();
                }}
                onChange={onChange ? onChange : input.onChange}
                onClear={() => {
                    input.onChange(null);
                    if (onRemove) {
                        onRemove();
                    }
                }}
            />
        </FormField>
    );
}
