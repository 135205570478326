/* @flow */
import * as React from 'react';

type Props = {
    className?: string,
    timestamp: string,
};

export function Timestamp({className, timestamp}: Props) {
    const classes = className ? `${className} timestamp` : 'timestamp';

    return <div className={classes}>{timestamp}</div>;
}
