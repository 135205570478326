/* @flow */

import * as React from 'react';

import type {EmailFragmentSparse as Email, SessionUser} from 'nutshell-graphql-types';

import {Popover} from 'shells/popover';
import {PopoverForm} from 'shells/popover-form';
import {RadioGroup, Radio} from 'shells/radio';
import {SelectPickerGeneric} from 'shells/select-picker-generic';

type Props = {|
    anchor: ?HTMLElement,
    email: Email,
    currentUser: ?SessionUser,
    onCancel: (e?: SyntheticEvent<*>) => void,
    onDeleteEmail: (emailId: string, allByAddress?: ?string) => any,
    setIsConfirmingAddToBlocklist: (boolean) => void,
    selectedAddress: ?string,
    setSelectedAddress: (any) => void,
|};

export function DeleteEmailPopover(props: Props) {
    const [isSaving, setIsSaving] = React.useState(false);
    const [deleteOptionValue, setDeleteOption] = React.useState('delete');

    const {
        anchor,
        email,
        onDeleteEmail,
        currentUser,
        setIsConfirmingAddToBlocklist,
        selectedAddress,
        setSelectedAddress,
    } = props;

    const onSubmitDelete = () => {
        const address = deleteOptionValue === 'deleteAll' ? selectedAddress : null;
        onDeleteEmail(email.id, address);
        setIsSaving(true);

        if (deleteOptionValue === 'deleteAll') {
            setIsConfirmingAddToBlocklist(true);
        }
    };

    const userAddresses = currentUser
        ? currentUser.emailAddresses.map((userEmail) => userEmail.address.toLowerCase())
        : [];
    const addresses = [email.from, ...(email.recipients || [])]
        .map((recipient) => recipient.address.toLowerCase())
        .filter((address) => {
            return address && !userAddresses.includes(address);
        });

    // Select the first address automatically if the picker is showing
    React.useEffect(() => {
        if (deleteOptionValue === 'deleteAll' && !selectedAddress) {
            setSelectedAddress(addresses[0]);
        }
    }, [deleteOptionValue, addresses, selectedAddress, setSelectedAddress]);

    const options = [{value: 'delete', text: 'Remove just this email'}];
    if (currentUser && currentUser.isAdministrator) {
        if (addresses.length === 1) {
            options.push({
                value: 'deleteAll',
                text: (
                    <div>
                        Remove all emails to and from <strong>{addresses[0]}</strong>
                    </div>
                ),
            });
        } else if (addresses.length > 1) {
            options.push({
                value: 'deleteAll',
                text: 'Remove all emails to and from the selected address',
            });
        }
    }

    return (
        <Popover anchor={anchor} onBlur={props.onCancel}>
            <PopoverForm
                width={340}
                allowTitleWrapping={true}
                onClose={props.onCancel}
                title='Remove email from Nutshell'
                formBodyStyles={{marginTop: '10px'}}
                saveButtonDisabled={deleteOptionValue === 'deleteAll' && !selectedAddress}
                isSaving={isSaving}
                saveButtonSavingText='Removing'
                saveButtonSuccessText='Done!'
                onSubmit={onSubmitDelete}
                saveButtonText={
                    deleteOptionValue === 'deleteAll' ? 'Remove emails' : 'Remove email'
                }
                cancelButtonText='Cancel'
                resetOnSaveComplete={true}
                saveCompleteTimeout={1000}
                shouldHideCloseButton={true}
                saveButtonVariant='danger'
            >
                <>
                    <div className='pb-16'>
                        <RadioGroup
                            name='radiobuttons'
                            selectedValue={deleteOptionValue}
                            onChange={(newVal) => setDeleteOption(newVal)}
                        >
                            {options.map((option) => {
                                return (
                                    <Radio key={option.value} value={option.value}>
                                        {option.text}
                                    </Radio>
                                );
                            })}
                        </RadioGroup>
                    </div>
                    {deleteOptionValue === 'deleteAll' && addresses.length > 1 ? (
                        <div className='pb-16'>
                            <SelectPickerGeneric
                                placeholder='Select email address'
                                options={addresses.map((address) => ({
                                    label: address,
                                    value: address,
                                }))}
                                menuPlacement='auto'
                                value={
                                    selectedAddress
                                        ? {label: selectedAddress, value: selectedAddress}
                                        : null
                                }
                                onChange={(address) => {
                                    setSelectedAddress(address.value);
                                }}
                                isDisabled={deleteOptionValue !== 'deleteAll'}
                            />
                        </div>
                    ) : (
                        undefined
                    )}
                </>
            </PopoverForm>
        </Popover>
    );
}
