/* @flow */

import * as React from 'react';

import type {EventFragmentSparse, SessionUser} from 'nutshell-graphql-types';
import {TimelineEntryActionMenu} from 'shells/timeline/entry/toolbar/timeline-entry-action-menu';

import {appHistory} from '../../../history';
import {
    getEntryTypeFromPayload,
    getActorForChat,
    getParticipantsForChat,
    getRelatedLeadsForChat,
} from '../helpers';
import {useCreateComment, useDeleteComment} from '../comments/timeline-comment-mutations';
import {useGetRelatedLeads} from '../graphql/hooks/use-get-related-leads';
import {TimelineEntry} from './timeline-entry';
import {ChatItems} from './chat-items';

type Props = {
    event: EventFragmentSparse,
    currentUser: SessionUser,
    onDeleteEvent?: (eventId: string) => Promise<*>,
};

export function TimelineEntryChat(props: Props) {
    const {id, changeType, changeTime, payload, reactions, comments} = props.event;
    const createComment = useCreateComment();
    const deleteComment = useDeleteComment();

    const {relatedLeads} = useGetRelatedLeads({changeLogId: id});

    const actionMenu = (
        <TimelineEntryActionMenu
            id={id}
            entryType={getEntryTypeFromPayload(payload)}
            handleDelete={props.onDeleteEvent}
        />
    );

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'Chat' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (payload.__typename === 'Chat') {
        return (
            <TimelineEntry
                id={id}
                iconVariant='chat'
                entryType={getEntryTypeFromPayload(payload)}
                changeType={changeType}
                timestamp={changeTime}
                content={<ChatItems chatItems={payload.chatItems} />}
                status={{
                    primary: {
                        level: 'info--grey',
                        text: 'Chat',
                    },
                }}
                actor={getActorForChat(payload.participants, props.event.actor)}
                reactions={reactions}
                comments={comments}
                onAddComment={(value) =>
                    createComment({
                        changeLogId: id,
                        parentId: payload.id,
                        value,
                        user: props.currentUser,
                    })
                }
                onDeleteComment={(commentId) => deleteComment({changeLogId: id, commentId})}
                currentUser={props.currentUser}
                relatedLeads={relatedLeads ? getRelatedLeadsForChat(relatedLeads) : undefined}
                participants={getParticipantsForChat(payload.participants)}
                actionMenu={actionMenu}
                onClickOpen={() => {
                    // The htmlUrlPath for chats is the same structure as for activities -
                    // which is /chat/open-modal/[id] and we want this to be /chat/[id]
                    appHistory.push(`#${payload.htmlUrlPath.replace(/\/open-modal/g, '')}`);
                }}
            />
        );
    }

    return null;
}
