/* @flow */

import * as React from 'react';

export type Status = 'OPEN' | 'WON' | 'LOST' | 'CANCELLED' | 'PENDING';

export const LeadStatusIcon = ({status, width}: {status: Status, width?: number}) => {
    const iconWidth = width || 24;

    switch (status) {
        case 'OPEN':
            return <img src='/avatar/leads/v1/0/1/1/0' width={iconWidth} />;
        case 'WON':
            return <img src='/include/images/lead-icons/won.svg' width={iconWidth} />;
        case 'LOST':
            return <img src='/include/images/lead-icons/lost.svg' width={iconWidth} />;
        case 'CANCELLED':
            return <img src='/include/images/lead-icons/cancelled.svg' width={iconWidth} />;
        case 'PENDING':
            return <img src='/include/images/lead-icons/pending.svg' width={iconWidth} />;
        default: {
            return null;
        }
    }
};
