const DEFAULT_CURRENCY_PREFIX = '$';

export function getUserCurrencyPrefix() {
    if (
        typeof NutshellFrontendSettings !== 'undefined' &&
        typeof NutshellFrontendSettings.defaultInstanceCurrencySymbol === 'string'
    ) {
        return NutshellFrontendSettings.defaultCurrencySymbol;
    }

    return getInstanceCurrencyPrefix();
}

export function getInstanceCurrencyPrefix() {
    return typeof NutshellFrontendSettings !== 'undefined' &&
        typeof NutshellFrontendSettings.defaultInstanceCurrencySymbol === 'string'
        ? NutshellFrontendSettings.defaultInstanceCurrencySymbol
        : DEFAULT_CURRENCY_PREFIX;
}
