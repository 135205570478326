import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SortDown from './icon-sort-down.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SortDown {...validProps} />;
}
SvgComponent.iconName = 'sort-down';

export const SortDownIcon = svgIconWrapper(SvgComponent);
