import React from 'react';
import {colors} from 'shells/colors';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Stop from './icon-stop.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Stop {...validProps} color={validProps.color ? validProps.color : colors.black} />;
}
SvgComponent.iconName = 'stop';

export const StopIcon = svgIconWrapper(SvgComponent);
