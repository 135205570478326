/* @flow */

import * as React from 'react';
import {UpgradeArrowIcon} from '../icon';
import {Link} from '../link';
import {Popover} from '../popover';

import styles from './upgrade-arrow.css';

type Props = {
    featureName: string,
    title?: string,
};

export function UpgradeArrow(props: Props) {
    const popoverRef = React.useRef(null);
    const [isPopoverShown, setIsPopoverShown] = React.useState<boolean>(false);

    return (
        <>
            <div ref={popoverRef} onClick={() => setIsPopoverShown(true)}>
                <UpgradeArrowIcon className={styles['upgrade-arrow']} size={16} />
            </div>
            {isPopoverShown ? (
                <Popover
                    isNubDark={true}
                    bodyColor='dark'
                    onBlur={() => setIsPopoverShown(false)}
                    anchor={popoverRef.current}
                >
                    <div styleName='upgrade-arrow-popover'>
                        <div>
                            {props.title
                                ? props.title
                                : 'This feature isn’t available in your current plan.'}
                        </div>
                        <Link newTab={true} size='small' variant='primary' href='/setup/billing'>
                            Learn more about {props.featureName} in our pricing plans.
                        </Link>
                    </div>
                </Popover>
            ) : (
                undefined
            )}
        </>
    );
}
