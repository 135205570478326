/* @flow */

import {Quill} from 'react-quill';

const MentionBlot = Quill.import('formats/mention');

export type Mention = {
    id: string,
    mentionText: string,
};

export class CustomMentionBlot extends MentionBlot {
    static blotName = 'mention';
    static tagName = 'span';
    static className = 'ql-mention-blot';

    mentionValue: ?Mention;

    constructor(domNode: Node, value?: Mention) {
        super(domNode, value);
        this.mentionValue = value;
    }

    static create(value: Mention) {
        const entity = value.id.split('-')[1];
        const entityName = entity.charAt(0).toUpperCase() + entity.slice(1);

        const node: HTMLElement = super.create(value);
        node.setAttribute('data-id', value.id);
        node.setAttribute('data-entity-name', entityName);
        node.setAttribute('data-mention-text', value.mentionText);
        node.setAttribute('spellcheck', 'false');

        // https://github.com/quill-mention/quill-mention/issues/178#issuecomment-1099325620
        node.contentEditable = 'false';

        const mentionText = value.mentionText;
        node.innerHTML = mentionText;

        return node;
    }
}
