import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'28 -40 100 100'}>
            <path d='M123.8-40H115c-4.2 0-4.2 0-4.2 4.2v91.7c0 4.2 0 4.2 4.2 4.2h8.8c4.2 0 4.2 0 4.2-4.2v-91.7c0-4.2 0-4.2-4.2-4.2zm-82.9 0h-8.8C28-40 28-40 28-35.8v91.7c0 4.2 0 4.2 4.2 4.2H41c4.2 0 4.2 0 4.2-4.2v-91.7c-.1-4.2-.1-4.2-4.3-4.2zm42.5 0H72.6c-4.2 0-4.2 0-4.2 4.2v91.7c0 4.2 0 4.2 4.2 4.2h10.8c4.2 0 4.2 0 4.2-4.2v-91.7c0-4.2 0-4.2-4.2-4.2z' />
        </svg>
    );
}
SvgComponent.iconName = 'columns';

export const ColumnsIcon = svgIconWrapper(SvgComponent);
