import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox='0 0 55 55'>
            <path d='M55,48.6v5.5H5.5H0v-1.8v-3.7V0.9h5.5v47.7H55z M34.8,17.4h-9.2v25.7h9.2V17.4z M49.5,0.9h-9.2v42.2h9.2V0.9z M20.2,32.1H11v11h9.2V32.1z' />
        </svg>
    );
}
SvgComponent.iconName = 'on-demand-reports';

export const OnDemandReportsIcon = svgIconWrapper(SvgComponent);
