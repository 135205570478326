import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ComingSoon from './icon-coming-soon.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ComingSoon {...validProps} />;
}
SvgComponent.iconName = 'coming-soon';

export const ComingSoonIcon = svgIconWrapper(SvgComponent);
