/* @flow */

import * as React from 'react';
import {FocusTrapZone} from '@fluentui/react/lib/FocusTrapZone';
import _ from 'underscore';

import {portal} from '../portal/portal';
import {SidebarHeader} from './sidebar-header';

import './sidebar.css';

// This is the max tim in ms that we spend animating in.
const ANIMATE_IN_MS = 300;

type Props = {
    children?: any,
    headerTitle?: string,
    subtitle?: string,
    isLeftAligned?: boolean,
    customPositionAttributes?: Object,
    obscurePage?: boolean,
    trapFocus?: boolean,
    closeButton?: boolean,
    isOpen?: boolean,
    canEscapeToClose?: boolean,
    canClickOutsideToClose?: boolean,
    preventBodyScroll?: boolean,
    onBeforeClose: () => any,
    onClose: () => any,
    onSave?: () => any,
    isSaving?: boolean,
    saveText?: string,
    submitDisabled?: boolean,
    headerSubmitButton?: boolean,
    getButtonRef?: any,
};

type State = {
    isClosing: boolean,
};

class SidebarComponent extends React.Component<Props, State> {
    bodyRef: ?HTMLElement;
    delay: ?Function;

    static displayName = 'Modal';

    static defaultProps = {
        closeButton: true,
        isOpen: false,
        obscurePage: true,
        canEscapeToClose: true,
        canClickOutsideToClose: true,
        trapFocus: true,
        preventBodyScroll: true,
        saveText: 'Save',

        onBeforeClose: () => {},
        onClose: () => {},
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            isClosing: false,
        };
    }

    componentDidMount() {
        // Handle auto-mounted/auto-opened instances.
        if (this.props.isOpen) {
            this.handleOpen();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.isOpen && !nextProps.isOpen) {
            this.handleClose();
        }

        if (!this.props.isOpen && nextProps.isOpen) {
            if (typeof this.delay === 'number') {
                clearTimeout(this.delay);
            }

            this.handleOpen();
        }
    }

    componentWillUnmount() {
        // ensure we re-enable body scrolling before unmounting
        this.performClose();
    }

    render() {
        if (!this.props.isOpen) return null;
        const styleName = this.state.isClosing ? 'container--animate-out' : 'container';

        return (
            <div styleName={styleName}>
                <FocusTrapZone
                    style={{height: '100%'}}
                    isClickableOutsideFocusTrap={true}
                    forceFocusInsideTrap={this.props.trapFocus}
                >
                    {this.renderSidebar()}
                </FocusTrapZone>
                <div
                    onClick={() => {
                        if (this.props.canClickOutsideToClose) this.handleClose();
                    }}
                    styleName={this.props.obscurePage ? 'overlay' : 'overlay--none'}
                />
            </div>
        );
    }

    handleKeyDown: KeyboardEventListener = (e) => {
        // Not using Nut.keys here since it isn't in scope within shells
        // keyCode 27 = Esc
        if (e.keyCode === 27 && !e.defaultPrevented && this.props.canEscapeToClose)
            this.handleClose();
    };

    renderSidebar = () => {
        const positionAttributes = this.props.customPositionAttributes || {
            left: this.props.isLeftAligned ? 0 : 'auto',
            right: this.props.isLeftAligned ? 'auto' : 0,
            top: 0,
        };

        const style = {
            position: 'absolute',
            ...positionAttributes,
        };

        return (
            <div
                ref={(node) => {
                    this.bodyRef = node;
                }}
                style={style}
                styleName='body'
            >
                {this.props.headerTitle ? (
                    <SidebarHeader
                        saveText={this.props.saveText}
                        isSaving={this.props.isSaving}
                        closeButton={this.props.closeButton}
                        onClose={this.handleClose}
                        onSave={this.props.onSave}
                        headerTitle={this.props.headerTitle}
                        subtitle={this.props.subtitle}
                        submitDisabled={this.props.submitDisabled}
                        submitButton={this.props.headerSubmitButton}
                        getButtonRef={this.props.getButtonRef}
                    />
                ) : null}
                {this.props.children}
            </div>
        );
    };

    handleOpen = () => {
        document.addEventListener('keydown', this.handleKeyDown);
        const body = document.querySelector('body');
        if (body && this.props.preventBodyScroll) {
            body.style.overflow = 'hidden';
        }
        // Disable animation now that we've animated in.
        // This is necessary to remove the `translate` so that react-beautiful-dnd
        // works correctly.  See https://github.com/atlassian/react-beautiful-dnd/issues/204
        this.disableAnimation();
    };

    handleClose = () => {
        const beforeClose = this.props.onBeforeClose();
        if (_.isBoolean(beforeClose) && beforeClose) {
            this.performClose();
        } else if (beforeClose && beforeClose.then) {
            beforeClose.then((proceedWithClose) => {
                if (proceedWithClose) this.performClose();
            });
        } else {
            this.performClose();
        }
    };

    performClose = () => {
        const body = document.querySelector('body');
        if (body && this.props.preventBodyScroll) {
            body.style.overflow = '';
        }
        document.removeEventListener('keydown', this.handleKeyDown);
        this.enableAnimation();
        this.setState({isClosing: true}, this.closeAfterAnimationDelay);
    };

    closeAfterAnimationDelay = () => {
        this.delay = _.delay(this.close, 250);
    };

    close = () => {
        this.delay = undefined;
        this.props.onClose();
    };

    disableAnimation() {
        setTimeout(() => {
            if (this.bodyRef) {
                this.bodyRef.style.animation = 'unset';
            }
        }, ANIMATE_IN_MS);
    }

    enableAnimation() {
        if (this.bodyRef) {
            this.bodyRef.style.animation = '';
        }
    }
}

export const Sidebar = portal(SidebarComponent);
