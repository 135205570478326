/* @flow */

// Constant used by React-DnD to match up DropTarget and DropSource
// which must match, and also be unique from other drop types on the
// page.
export const LEAD_CARD_DND_TYPE = 'lead-card';

// Drop target constants that we use to identify which _type_ of target
// a lead card was dropped onto. This dictates what type of payload
// to look for when getting the information from the given drop target
export const DROP_TARGET_VISUAL_PIPELINE_COLUMN = 'PIPELINE_COLUMN';
export const DROP_TARGET_VISUAL_PIPELINE_CLOSE_BUTTON = 'CLOSE_BUTTON';

// Type Constants
export const ActionTypes = {
    VISUAL_PIPELINE_LIST_ITEMS_REQUESTED_FOR_STAGE:
        'VISUAL_PIPELINE_LIST_ITEMS_REQUESTED_FOR_STAGE',
    VISUAL_PIPELINE_LIST_ITEMS_REPLACED_FOR_STAGE: 'VISUAL_PIPELINE_LIST_ITEMS_REPLACED_FOR_STAGE',
    VISUAL_PIPELINE_LIST_ITEMS_ADDED_FOR_STAGE: 'VISUAL_PIPELINE_LIST_ITEMS_ADDED_FOR_STAGE',
    VISUAL_PIPELINE_LIST_ITEMS_FAILED_FOR_STAGE: 'VISUAL_PIPELINE_LIST_ITEMS_FAILED_FOR_STAGE',
    VISUAL_PIPELINE_LIST_ITEM_REMOVED_BY_ID: 'VISUAL_PIPELINE_LIST_ITEM_REMOVED_BY_ID',
    VISUAL_PIPELINE_LEAD_STAGE_TRANSITION_UPDATED: 'VISUAL_PIPELINE_LEAD_STAGE_TRANSITION_UPDATED',
    VISUAL_PIPELINE_LEAD_STAGE_TRANSITION_COMPLETED:
        'VISUAL_PIPELINE_LEAD_STAGE_TRANSITION_COMPLETED',
    VISUAL_PIPELINE_LEAD_CLOSING_CONFIRMATION_UPDATED:
        'VISUAL_PIPELINE_LEAD_CLOSING_CONFIRMATION_UPDATED',
    VISUAL_PIPELINE_CARD_IS_DRAGGING_TOGGLED: 'VISUAL_PIPELINE_CARD_IS_DRAGGING_TOGGLED',
};
