import React from 'react';
import {colors} from 'shells/colors';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ConnectedForms from './icon-connected-forms.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <ConnectedForms
            {...validProps}
            color={validProps.color ? validProps.color : colors.black}
        />
    );
}
SvgComponent.iconName = 'formMapping';

export const ConnectedFormsIcon = svgIconWrapper(SvgComponent);
