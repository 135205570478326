import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Compass from './icon-compass.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Compass {...validProps} />;
}
SvgComponent.iconName = 'Compass';

export const CompassIcon = svgIconWrapper(SvgComponent);
