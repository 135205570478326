/* @flow */

import * as React from 'react';
import {ConfirmIcon} from 'shells/icon';
import getClasses from '../../mixins/get-classes';

type Props = {
    className?: string,
    getButtonRef?: (?HTMLElement) => void,
    disabled?: boolean,
    onClick?: () => any,
    onMouseOver?: () => any,
    onMouseOut?: () => any,
};

export class CheckmarkSubmitButton extends React.Component<Props> {
    buttonRef: ?HTMLButtonElement;
    getClasses = getClasses;

    render() {
        // eslint-disable-next-line no-unused-vars
        const {getButtonRef, ...htmlButtonProps} = this.props;

        return (
            <button
                {...htmlButtonProps}
                ref={this.handleRef}
                type='submit'
                className={this.getClasses('ui-btn ui-btn__checkmark-submit ui-btn--icon')}
            >
                <ConfirmIcon size={30} />
            </button>
        );
    }

    handleRef = (node: ?HTMLButtonElement) => {
        this.buttonRef = node;

        if (this.props.getButtonRef) {
            this.props.getButtonRef(node);
        }
    };

    handleClick = (...args: any) => {
        if (this.props.onClick) {
            this.props.onClick(...args);
        }

        if (this.buttonRef) {
            this.buttonRef.blur();
        }
    };
}
