/* @flow */

import * as React from 'react';

export function PlugErrorState() {
    return (
        <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 284 254'>
            <path
                d='M73.7 233.9c-6-1.2-77.8-12.1-72.5-92.5C6.5 60.9 13.1 36.7 41 27.9c27.9-8.8 95.8-50.7 149-10.9 53.2 39.8 110.7 75.5 87.9 139.1-22.8 63.6-26.5 84.8-86 81.5-59.5-3.3-89.8 2-118.2-3.7z'
                fill='#F7F7F7'
            />
            <path
                d='M159 204.5c-.8-.3-1.1-.7-.7-1.1 2.7-3.1 14.8-3.1 20.7-3.2 7.4-.1 15.1-.2 21.8.4 2.8.3 9.1.5 10.2 1.5 2.8 2.7-17.3 3.7-20.6 3.7-5.5.2-26.8.5-31.4-1.3z'
                fill='#EBE8E8'
            />
            <path
                d='M184.2 170.6H81.9c-5.1 0-9.3-4.2-9.3-9.3V59.1c0-5.1 4.2-9.3 9.3-9.3h102.3c5.1 0 9.3 4.2 9.3 9.3v102.3c0 5.1-4.2 9.2-9.3 9.2z'
                fill='#fff'
            />
            <path
                d='M170.1 170.2H81.9c-5-.2-8.5-4.4-8.5-9.2V69.8c0-3.6-.1-7.1 0-10.7.1-4.9 3.8-8.8 8.8-8.8h102c4.9.1 8.7 4.1 8.8 9 .1 4.2 0 8.4 0 12.6v76.7c0 .6 1.3.4 1.3-.1V58.9c-.1-5.4-4.6-9.5-9.9-9.5h-91c-3.7 0-7.5-.1-11.2 0-5.4.1-9.9 4.3-10 9.8v102c0 4.7 3.2 8.8 8 9.7 1.4.3 3 .2 4.4.2h85.6c.5 0 .7-.9-.1-.9z'
                fill='#0C0F33'
                stroke='#0C0F33'
            />
            <path
                d='M148.9 108.3a3.9 3.9 0 100-7.8 3.9 3.9 0 000 7.8zm-28.4 0a3.9 3.9 0 100-7.8 3.9 3.9 0 000 7.8zm13.8 23.4c2.154 0 3.9-3.806 3.9-8.5s-1.746-8.5-3.9-8.5-3.9 3.806-3.9 8.5 1.746 8.5 3.9 8.5z'
                fill='#0C0F33'
            />
            <path
                d='M188.4 43.7c-.4-5-.5-10-.2-15 0-.3-1.2-.1-1.2.3-.2 5-.2 10 .2 15 0 .4 1.3.1 1.2-.3zm6.9 3.5c2.7-3.7 5.7-7.2 9.2-10.2.6-.6-.4-1-.8-.6-3.5 3.1-6.7 6.6-9.4 10.4-.5.6.6.8 1 .4zm4.9 5.8c3.4 0 6.8-.3 10.1-1.1.7-.2.6-.7-.1-.5-3.1.7-6.3 1-9.5 1-.4-.1-1.4.6-.5.6z'
                fill='#0C0F33'
                stroke='#0C0F33'
            />
            <path
                d='M169.5 253.1c-.6 0-1.1-.4-1.3-1.1-1.8-9.2-13.9-9-22.8-8.8-1.4 0-2.7.1-3.9 0-1.8 0-3.6 0-5.5.1-6.5.1-13.3.3-19.3-1.8s-10.3-6.8-10.9-12.1c-.5-3.9.5-7.2 3-9.8 3-3.1 8-5.2 15-6.1 6.1-.8 12.2-.6 15.4-.3 6.5.5 13.1 1 19.4 1.5 18.3 1.5 37.1 3 55.7 2.8 11-.1 18.8-2.2 23.3-6.2 2.4-2.2 3.8-5.2 3.7-8.2-.1-2.5-1.2-4.7-3.3-6.3-5.4-4.2-11.4-5.7-18.4-7.4-3.3-.8-6.8-1.7-10.4-2.9-.7-.2-1-1-.8-1.6.2-.7 1-1 1.6-.8 3.5 1.2 6.9 2 10.2 2.8 6.9 1.7 13.5 3.3 19.3 7.8 2.7 2.1 4.2 5 4.3 8.2.1 3.7-1.6 7.5-4.6 10.2-5 4.5-13.2 6.7-25 6.9-18.7.2-37.6-1.3-56-2.8-6.4-.5-12.9-1.1-19.4-1.5-3-.2-9-.5-14.8.3-6.4.8-10.9 2.6-13.5 5.3-1.9 2-2.7 4.6-2.3 7.7.4 3.2 3 7.8 9.2 9.9 5.5 1.9 12 1.8 18.4 1.7 1.9 0 3.7-.1 5.5-.1h3.8c9.3-.2 23.3-.5 25.4 10.9.1.7-.3 1.4-1 1.5.1.2 0 .2 0 .2z'
                fill='#DBDBF2'
            />
            <path
                d='M179.9 199.1c11.5 3.3 25.7.9 30.2-14.9 4.5-15.9-6.3-25.3-17.8-28.6-11.5-3.3-23.9 40.2-12.4 43.5z'
                fill='#DBDBF2'
            />
            <path
                d='M184.3 200.4c8.1 1.1 16.7-.9 22-7.4 6-7.3 7.6-18.8 2.3-26.9-2.7-4.2-6.9-7.2-11.4-9.2-.3-.2-1.5.4-1 .6 7.6 3.4 13.7 9.7 14.3 18.3.6 8.9-4 18.9-12.4 22.5-4.1 1.8-8.7 2.1-13.1 1.5-.3-.1-1.4.5-.7.6z'
                fill='#0C0F33'
                stroke='#0C0F33'
            />
            <path
                d='M171.5 201.1c-3.4-1-2.8-13.3 1.3-27.6s10.1-25.1 13.5-24.1l6.3 1.8h.2c3.4 1 2.8 13.3-1.3 27.6s-10.1 25.1-13.5 24.1h-.1l-6.4-1.8z'
                fill='#F7F7F7'
            />
            <path
                d='M184.817 177.144c4.077-14.286 4.669-26.641 1.323-27.596-3.346-.955-9.363 9.852-13.439 24.138-4.077 14.286-4.669 26.641-1.324 27.596 3.346.955 9.363-9.852 13.44-24.138z'
                fill='#fff'
            />
            <path
                d='M179 158.3c1-2.1 2.2-4.2 3.6-6.1.7-.9 1.9-2.6 3.1-2.5.8.1 1.6.5 2.4.7 1.1.3 2.2.6 3.3 1 .6.2 1.1.3 1.6.8.6.7.8 1.8 1 2.7.6 3.3.2 6.8-.2 10.1-1.2 9.2-3.8 18.4-7.5 26.8-1.4 3.1-2.9 6.2-5 8.9-.6.7-1.4 1.8-2.3 2.1-.5.2-.9 0-1.4-.2-1.3-.4-2.5-.7-3.8-1.1-.7-.2-1.8-.3-2.4-.8-1.1-.8-1.3-2.6-1.4-3.9-.3-2.5-.1-5 .1-7.5 0-.4-1.2-.1-1.2.2-.2 2.4-.4 4.8-.2 7.2.1 1.4.3 3 1.2 4.1.5.6 1.2.8 1.9 1 1.3.4 2.6.7 3.9 1.1 1.7.5 2.9.9 4.5-.2 2.3-1.5 3.9-4.4 5.2-6.8 4.8-9 7.8-19.4 9.4-29.5.6-3.8 1.1-7.9.5-11.7-.2-1-.4-2.1-1.1-3-.5-.6-1.1-.7-1.8-.9-1.4-.4-2.9-.8-4.3-1.2-.9-.2-1.7-.6-2.6-.4-1.5.3-2.6 1.4-3.6 2.5-1.7 2.1-3 4.5-4.2 6.9-.1.1 1.1 0 1.3-.3z'
                fill='#0C0F33'
                stroke='#0C0F33'
            />
            <path
                d='M171.6 180.6c1.1-4.8 2.4-9.6 4.1-14.3.2-.4-1.1-.3-1.2.2-1.7 4.7-3 9.4-4.1 14.3-.2.4 1.1.2 1.2-.2zm-.5 21c2.1.5 3.9-1.5 5.1-3 2.5-3.3 4.3-7.2 5.8-11 2.1-5.3 3.8-10.8 5.1-16.3 1.1-4.8 2-9.9 2-14.9 0-2.1.1-6.5-2.5-7.4-.5-.2-1.5.6-.7.8 2 .6 1.9 4.6 1.9 6.2.1 4.2-.6 8.4-1.4 12.6-1.8 9.2-4.7 18.7-9.4 26.8-.8 1.4-1.7 2.8-2.9 4.1-.5.5-1.5 1.6-2.3 1.4-.5-.3-1.5.5-.7.7z'
                fill='#0C0F33'
                stroke='#0C0F33'
            />
            <path
                d='M201.276 183.964a2.7 2.7 0 10-1.763-5.105 2.7 2.7 0 001.763 5.105z'
                fill='#fff'
                stroke='#0C0F33'
            />
            <path
                d='M200.2 183.7c-.9-.3-1.7-1.1-1.8-2.1-.2-1.4.8-2.9 2.3-2.5 2.5.7 2.2 5.2-.6 4.5-.4-.1-1.5.5-.8.6 1.5.4 3.4-.2 4.1-1.7.8-1.6-.1-3.6-1.8-4.1-1.5-.4-3.4.2-4.1 1.6-.9 1.7 0 3.6 1.7 4.2.3.2 1.5-.3 1-.5z'
                fill='#0C0F33'
                stroke='#0C0F33'
            />
            <path
                d='M180.3 166l-20.2-5.8c-1.3-.4-2.1-1.8-1.7-3.1.4-1.3 1.8-2.1 3.1-1.7l20.2 5.8c1.3.4 1.5 1.6 1.1 2.9-.4 1.4-1.2 2.3-2.5 1.9zm-6.5 22.7l-20.2-5.8c-1.3-.4-2.1-1.8-1.7-3.1.4-1.3 1.8-2.1 3.1-1.7l20.2 5.8c1.3.4 1.5 1.6 1.1 2.9-.4 1.3-1.1 2.3-2.5 1.9z'
                fill='#fff'
            />
            <path
                d='M181.5 165.9c-6.4-1.8-12.8-3.7-19.2-5.5-1-.3-2.3-.5-2.9-1.3-1.1-1.4-.1-3.6 1.8-3.3l.9.3c3.6 1 7.2 2.1 10.8 3.1l9.3 2.7c.5.1 1.5-.5.7-.8-6.8-1.9-13.6-3.9-20.4-5.8-1.5-.4-3.1-.4-4.2.9-1.4 1.7-.4 4 1.6 4.6 3.4 1.1 6.8 1.9 10.2 2.9 3.5 1 7.1 2 10.6 3 .5.1 1.5-.6.8-.8zm-6.5 22.7c-6.4-1.8-12.8-3.7-19.2-5.5-1-.3-2.3-.5-2.9-1.3-1-1.4-.1-3.7 1.8-3.3l.9.3c3.6 1 7.2 2.1 10.8 3.1l9.3 2.7c.4.1 1.5-.5.8-.7-6.8-1.9-13.6-3.9-20.4-5.8-1.5-.4-3.1-.4-4.2.9-1.4 1.7-.4 3.9 1.6 4.5 3.4 1.1 6.8 1.9 10.2 2.9 3.5 1 7.1 2 10.6 3 .4 0 1.4-.6.7-.8z'
                fill='#0C0F33'
                stroke='#0C0F33'
            />
        </svg>
    );
}
