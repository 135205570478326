/* @flow */

import * as React from 'react';

export function ParachutingEnvelopes() {
    return (
        <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 294 287'>
            <g clipPath='url(#clip0)'>
                <path
                    d='M48.999 35.244h94.861c.463-9.124-4.057-18.26-12.124-22.505-8.067-4.244-18.561-3.34-25.789 2.224C99.487 9.38 92.768 3.65 84.611 1.131 76.454-1.387 66.399.173 61.362 7.073c-3.48 4.774-3.955 11.301-2.25 16.96-7.39-.042-14.775.135-22.155.53-4.21.232-9.945 1.462-10.157 5.694-.313 6.311 22.2 4.987 22.2 4.987zm195.56 4.763a8.148 8.148 0 00-5.68-.657c-1.176.268-2.286.684-3.426 1.034-2.168.668-2.352 1.238-3.238-.656-.968-2.063.215-4.185-1.674-6.339a7.211 7.211 0 00-7.565-2.126c-1.431-3.363-3.07-6.597-5.814-9.222-5.417-5.219-13.68-7.4-20.744-4.472a22.054 22.054 0 00-8.177 6.071 26.627 26.627 0 00-3.061 4.405c-.784 1.419-1.141 3.265-2.093 4.55-2.09-.785-4.014-1.827-6.19-2.428-4.261-1.179-8.898-1.407-12.626 1.375-3.065 2.283-5.006 6.41-4.014 10.217.561 2.165 1.568 3.765 3.771 3.796 3.093.043 6.19 0 9.282 0h76.658s-2.23-3.85-5.409-5.548zM.266 70.57c-1.01-5.313.91-11.16 4.83-14.94 4.904-4.716 12.379-6.68 18.772-4.048a19.96 19.96 0 017.397 5.502 24.04 24.04 0 012.771 3.984c.718 1.285 1.035 2.955 1.894 4.118 1.89-.71 3.633-1.65 5.601-2.196 3.858-1.065 8.052-1.273 11.427 1.242 2.814 2.098 4.574 5.894 3.594 9.395-.345 1.234-1.254 3.258-2.638 3.376-.392.035-.819 0-1.223 0H4.101c-.82 0-.926-.154-1.412-.818A13.814 13.814 0 01.266 70.57z'
                    fill='#D9EDF7'
                />
                <path
                    d='M219.271 89.7a38.23 38.23 0 017.603-12.885 38.116 38.116 0 0111.958-8.967 38.03 38.03 0 0142.051 6.222 38.184 38.184 0 018.863 12.049 38.276 38.276 0 011.283 29.332c1.407-3.93-2.426-8.484-8.392-10.61-5.966-2.126-11.944-.707-13.34 3.222 1.019-4.444-4.029-9.486-11.583-12.181-7.628-2.751-14.872-2.134-16.809 2.05 1.408-3.929-2.446-8.963-8.412-11.104-5.966-2.142-11.803-1.08-13.206 2.853l-.016.02z'
                    fill='#fff'
                />
                <path
                    d='M220.102 89.594c5.386-14.775 19.682-25.031 35.475-24.717 15.68.315 29.752 10.771 34.801 25.593a38.38 38.38 0 01-.196 25.071l1.662-.185c1.8-5.701-5.056-9.922-9.564-11.285-4.614-1.399-11.732-1.152-13.837 4.098l1.662-.184c1.301-6.681-7.484-11.094-12.501-12.791-5.017-1.698-14.468-3.29-17.498 2.695l1.607-.05c1.795-5.671-4.763-10.218-9.306-11.683-4.383-1.43-12.046-1.78-13.982 3.635-.22.613 1.45.412 1.662-.185 1.881-5.254 10.066-3.34 13.688-1.513 3.469 1.757 7.652 5.588 6.272 9.93-.212.664 1.395.365 1.607-.051 2.893-5.718 12.622-2.892 17.036-1.018 4.171 1.769 10.815 5.733 9.749 11.215-.149.755 1.462.315 1.662-.184 2.097-5.231 9.764-3.848 13.684-1.965 3.477 1.65 7.785 5.187 6.413 9.509-.204.649 1.458.393 1.662-.184 5.421-15.487.427-33.135-12.524-43.269-12.646-9.89-30.466-10.81-44.201-2.625A39.217 39.217 0 00218.44 89.78c-.219.62 1.466.424 1.662-.185z'
                    fill='#0D0F33'
                />
                <path
                    d='M290.539 115.294l-42.409 21.188-5.97 2.979c-.98.491.537.534.98.314l42.425-21.188 5.966-2.983c.984-.491-.533-.53-.98-.31h-.012zm-18.435-13.569c3.026-11.247 3.63-23.605-2.207-34.073-.321-.578-1.917 0-1.525.699 5.731 10.28 5.025 22.509 2.062 33.535-.192.719 1.49.503 1.67-.161z'
                    fill='#0D0F33'
                />
                <path
                    d='M243.159 139.362l23.579-27.507 3.367-3.929c.07-.083-1.309-.071-1.631.302l-23.578 27.507c-1.121 1.31-2.244 2.62-3.367 3.93-.071.082 1.309.071 1.63-.303zm.455-46.298c4.457-10.315 11.336-20.646 22.057-25.118 1.055-.44.114-1.23-.705-.884-11.082 4.621-18.306 15.18-22.924 25.86-.356.83 1.306.759 1.568.142h.004z'
                    fill='#0D0F33'
                />
                <path
                    d='M243.007 139.37l-1.118-36.384-.156-5.136c-.024-.786-1.69-.495-1.67.161l1.117 36.38.157 5.136c.023.786 1.689.495 1.67-.161v.004z'
                    fill='#0D0F33'
                />
                <path
                    d='M218.444 89.92l19.753 43.54 2.744 6.071c.321.7 1.96.216 1.658-.444l-19.752-43.54c-.918-2.022-1.832-4.046-2.744-6.07-.318-.7-1.96-.217-1.655.443h-.004z'
                    fill='#0D0F33'
                />
                <path
                    d='M202.333 172.228l10.331-35.809 56.469 16.372-10.331 35.808-56.469-16.371z'
                    fill='#fff'
                />
                <path d='M212.925 136.71l55.874 16.139-34.059 13.918-21.815-30.057z' fill='#fff' />
                <path
                    d='M211.847 136.564a53732.556 53732.556 0 01-10.329 35.799.178.178 0 00.025.146.182.182 0 00.124.082l19.023 5.521 30.387 8.81 7.056 2.043c.369.106 1.36-.02 1.494-.483 3.002-10.416 6.008-20.835 9.016-31.256l1.285-4.456c.13-.448-1.505-.224-1.642.255l-9.016 31.256c-.426 1.485-.854 2.971-1.286 4.456l1.494-.483-39.442-11.423-10.854-3.144c-1.874-.542-3.881-1.403-5.818-1.686-.513-.075-.254.169-.109-.188.364-.912.556-1.934.827-2.877l3.253-11.27c2.052-7.112 4.104-14.225 6.158-21.338.13-.451-1.505-.227-1.646.256v-.02z'
                    fill='#0D0F33'
                />
                <path
                    d='M268.819 152.467l-30.148 12.3-4.312 1.753h1.094l-19.306-26.659-2.716-3.752-1.282.75 19.031 5.521 30.387 8.81 7.056 2.044c.498.145 1.936-.602 1.023-.865l-19.023-5.521-30.387-8.81-7.056-2.043c-.314-.091-1.654.235-1.282.75a74297.232 74297.232 0 0022.022 30.411c.2.275.862.091 1.09 0l30.148-12.284 4.312-1.748c1.062-.444.082-.94-.651-.657z'
                    fill='#0D0F33'
                />
                <path
                    d='M241.238 138.993c-7.502 1.882-14.041 6.055-18.462 12.468-4.038 5.859-7.511 14.147-6.045 21.373a10.623 10.623 0 002.854 5.529c1.521 1.454 3.528 1.824 5.566 1.934.647.035 1.756-.904.639-.963-2.932-.157-5.096-1.372-6.425-4.044a13.618 13.618 0 01-1.27-6.397c.102-4.382 1.568-8.708 3.469-12.622 4.038-8.323 10.898-14.166 19.902-16.421.999-.252.784-1.109-.228-.857z'
                    fill='#0D0F33'
                />
                <path
                    d='M241.811 139.708c6.456 5.843 10.101 14.233 10.717 22.878a35.49 35.49 0 01-1.419 12.877 31.925 31.925 0 01-2.352 5.671c-.639 1.202-3.704 6.248-5.096 3.261-.196-.42-1.799.142-1.654.444 1.223 2.601 4.684.711 6.138-.688 2.646-2.546 4.101-6.868 5.006-10.338a36.563 36.563 0 00-1.909-23.703 32.214 32.214 0 00-7.875-11.003c-.333-.307-1.834.322-1.568.573l.012.028z'
                    fill='#0D0F33'
                />
                <path
                    d='M238.871 143.099a8.633 8.633 0 013.359-3.297c1.008-.526.086-1.178-.678-.762a10.537 10.537 0 00-4.237 3.996c-.416.704 1.25.59 1.568.075l-.012-.012z'
                    fill='#0D0F33'
                />
                <path
                    d='M243.057 144.286a5.952 5.952 0 00-.125-5.332c-.29-.558-1.897 0-1.654.448a5.606 5.606 0 01.125 5.108c-.227.487 1.407.318 1.654-.212v-.012z'
                    fill='#0D0F33'
                />
                <path
                    d='M10.584 130.819a34.484 34.484 0 011.7-26.279 34.407 34.407 0 018.146-10.656 34.301 34.301 0 0111.594-6.72 34.246 34.246 0 0126.214 1.705 34.38 34.38 0 0117.334 19.788c-1.212-3.568-6.452-4.618-11.858-2.775-5.405 1.843-8.8 6.225-7.593 9.793-1.764-3.71-8.141-4.425-14.982-2.095-6.91 2.358-11.68 6.858-10.705 10.893-1.211-3.564-6.738-4.943-12.152-3.1-5.413 1.843-8.921 5.867-7.698 9.431v.015z'
                    fill='#fff'
                />
                <path
                    d='M11.403 130.599c-4.37-13.179-.439-27.998 10.192-37 10.92-9.243 26.87-10.174 38.948-2.65a34.331 34.331 0 0114.182 17.928l1.654-.444c-1.936-4.999-8.96-4.366-13.022-2.936-4.06 1.431-9.556 5.38-8.086 10.398l1.654-.444c-2.87-5.482-11.505-3.863-16.15-2.252-4.457 1.544-12.387 5.521-11.176 11.498l1.655-.448c-1.96-5.045-9.126-4.715-13.309-3.261-3.794 1.324-9.866 5.128-8.196 10.055.212.629 1.862.165 1.654-.444-1.34-3.965 3.054-7.159 6.166-8.444 3.658-1.509 10.243-2.048 12.03 2.542.236.609 1.788.204 1.655-.448-1.016-4.998 5.338-8.425 9.121-9.898 4.077-1.572 12.293-3.482 14.92 1.528.293.562 1.85.208 1.654-.444-1.176-4.043 2.775-7.407 6.083-8.798 3.528-1.482 9.969-2.279 11.713 2.224.239.621 1.866.165 1.654-.444a35.268 35.268 0 00-12.518-16.88A35.132 35.132 0 0044.005 84.8c-14.425-.267-27.992 8.488-33.488 21.892a35.054 35.054 0 00-.76 24.336c.2.648 1.85.18 1.646-.429z'
                    fill='#0D0F33'
                />
                <path
                    d='M74.745 108.786c-6.01 12.926-12.03 25.848-18.06 38.766-.846 1.833-1.694 3.655-2.543 5.466-.161.337 1.426.196 1.642-.268a47015.01 47015.01 0 0118.055-38.765c.85-1.818 1.697-3.639 2.544-5.462.16-.342-1.423-.196-1.638.263zm-19.914 1.132c-4.194-9.714-10.693-18.795-20.728-22.929-.627-.26-1.928.644-.98 1.033 9.706 3.997 16.029 12.968 20.07 22.34.29.672 1.929.185 1.654-.444h-.016z'
                    fill='#0D0F33'
                />
                <path
                    d='M55.522 152.754c.415-10.885.83-21.767 1.242-32.647l.176-4.656c0-.216-1.634-.024-1.654.444-.415 10.885-.83 21.767-1.242 32.647l-.177 4.656c0 .216 1.639.02 1.655-.444zm-25.668-33.071c-2.638-9.859-3.528-21.063 1.568-30.313.427-.786-1.247-.73-1.568-.141-5.186 9.43-4.335 20.827-1.646 30.898.192.707 1.846.267 1.658-.444h-.012z'
                    fill='#0D0F33'
                />
                <path
                    d='M55.302 152.68l-21.14-25.032-2.975-3.536c-.435-.515-2.019.11-1.525.699l21.14 25.031 2.975 3.537c.435.515 2.019-.114 1.525-.699z'
                    fill='#0D0F33'
                />
                <path
                    d='M9.933 131.287l38.282 19.609 5.343 2.75c.607.311 2.073-.522 1.281-.931l-38.281-19.624-5.34-2.751c-.607-.31-2.077.523-1.285.931v.016z'
                    fill='#0D0F33'
                />
                <path
                    d='M32.042 167.48l48.965-20.182 12.736 31.052-48.965 20.182-12.736-31.052z'
                    fill='#fff'
                />
                <path d='M32.324 167.647l48.704-20.103-16.342 29.106-32.363-9.003z' fill='#fff' />
                <path
                    d='M31.226 167.702l11.14 27.162a205.181 205.181 0 001.596 3.882c.14.346.917.122 1.129.031l42.907-17.683 6.08-2.499c.121-.051.615-.259.525-.479-3.703-9.03-7.409-18.062-11.117-27.095l-1.587-3.87c-.196-.476-1.823.043-1.655.444l11.09 27.094 1.587 3.871.526-.476-42.908 17.683-6.06 2.5 1.133.031-11.117-27.157a313.558 313.558 0 00-1.595-3.883c-.192-.475-1.819.04-1.654.444h-.02z'
                    fill='#0D0F33'
                />
                <path
                    d='M80.248 147.284l-14.394 25.59-2.054 3.658 1.278-.428-28.384-7.918-4.131-1.152c-.063.252-.126.503-.192.751l42.891-17.675 6.06-2.496c1.07-.444.09-.939-.642-.636l-42.892 17.671-6.037 2.507c-.392.153-.784.586-.192.751l28.396 7.914 4.132 1.151c.344.094 1.09-.09 1.278-.428l14.382-25.593 2.058-3.659c.344-.652-1.302-.464-1.557-.008z'
                    fill='#0D0F33'
                />
                <path
                    d='M53.17 153.446c-6.166 8.103-7.244 17.86-4.1 27.428 2.28 6.959 8.858 15.868 16.784 10.252.914-.644-.497-.892-1.034-.51-7.895 5.595-13.512-7.309-14.947-13.164-2.093-8.555-.502-16.929 4.849-23.971.455-.597-1.211-.491-1.568-.047l.016.012z'
                    fill='#0D0F33'
                />
                <path
                    d='M54.283 153.124c8.044.522 15.491 4.652 20.74 10.692a31.537 31.537 0 016.021 10.327 26.686 26.686 0 011.274 5.317c.07.565.18 5.501-1.302 4.762-.439-.212-1.771.472-1.567.578 3.202 1.572 4.782-2.248 4.613-4.826-.317-4.9-2.453-9.796-5.217-13.789-5.382-7.776-14.022-13.152-23.52-13.753-.372-.028-1.893.648-1.066.699l.024-.007z'
                    fill='#0D0F33'
                />
                <path
                    d='M54.51 157.643a8.347 8.347 0 01.592-4.437c.337-.786-1.317-.625-1.568-.079a9.215 9.215 0 00-.694 4.964c.079.609 1.74.224 1.655-.448h.015z'
                    fill='#0D0F33'
                />
                <path
                    d='M58.171 156.255a5.444 5.444 0 00-3.17-3.78 1.368 1.368 0 00-1.177.157c-.208.13-.517.48-.149.641a4.842 4.842 0 012.834 3.426c.134.566 1.792.146 1.658-.444h.004z'
                    fill='#0D0F33'
                />
                <path
                    d='M99.856 150.312a48.322 48.322 0 0114.43-33.843 48.08 48.08 0 0134.033-13.804 48.091 48.091 0 0133.846 14.261 48.323 48.323 0 0113.975 34.034c.035-5.281-6.429-9.093-14.433-9.148-8.005-.055-14.527 4.185-14.563 9.467-.635-5.726-8.753-9.624-18.89-9.691-10.234-.071-18.623 3.686-19.184 9.482.036-5.281-6.663-9.659-14.66-9.71-7.997-.051-14.504 3.651-14.554 8.932v.02z'
                    fill='#FAC942'
                />
                <path
                    d='M100.687 150.221c.227-19.648 12.512-37.704 31.01-44.502 18.815-6.908 40.375-.648 52.942 14.873a48.376 48.376 0 0110.67 30.462l1.662-.184c-.168-7.38-9.655-9.655-15.523-9.565-5.868.091-14.876 2.924-15.134 10.064l1.662-.315c-.498-3.611-3.528-6.024-6.664-7.466-4.312-1.965-9.212-2.605-13.912-2.507-6.358.134-18.153 1.965-19.157 10.08l1.663-.185c-.181-7.572-9.941-10.268-16.033-10.123-5.488.134-14.794 2.535-14.845 9.549 0 .743 1.659.421 1.663-.185.047-6.531 8.623-8.448 13.696-8.346 5.295.106 13.7 2.751 13.856 9.29 0 .742 1.568.42 1.663-.185.964-7.796 12.653-9.003 18.635-8.877 5.982.126 16.746 1.863 17.78 9.396.083.601 1.643.224 1.659-.315.243-6.68 8.545-9.01 13.986-8.853 5.096.149 13.202 2.358 13.347 8.723 0 .743 1.662.417 1.662-.184.035-20.387-12.732-38.993-31.873-46.031-19.141-7.038-41.315-1.136-54.486 14.402a49.075 49.075 0 00-11.591 31.165c-.008.747 1.654.425 1.662-.181z'
                    fill='#0D0F33'
                />
                <path
                    d='M195.384 150.996l-16.072 16.547-25.613 26.419-5.919 6.118c-.392.393 1.204.247 1.517-.071l16.052-16.547c8.546-8.805 17.09-17.61 25.633-26.415a3111.15 3111.15 0 015.934-6.122c.392-.393-1.203-.248-1.513.071h-.019zm-27.663-8.249c-1.059-14.539-5.445-29.609-16.699-39.535-.51-.452-2.07.216-1.525.699 11.195 9.875 15.515 24.82 16.57 39.296.047.672 1.709.295 1.654-.444v-.016z'
                    fill='#0D0F33'
                />
                <path
                    d='M148.956 199.891l16.695-42.805a850.624 850.624 0 002.325-5.961c.102-.252-1.466-.114-1.631.303a32539.013 32539.013 0 00-16.71 42.804l-2.329 5.958c-.098.255 1.47.114 1.635-.299h.015zm-18.815-55.592c1.011-14.303 5.017-29.617 16.04-39.523.745-.672-.87-.869-1.368-.421-11.176 10.044-15.315 25.59-16.342 40.082-.059.805 1.619.538 1.67-.15v.012z'
                    fill='#0D0F33'
                />
                <path
                    d='M148.721 199.816l-16.522-42.981c-.769-1.997-1.536-3.991-2.301-5.985-.263-.68-1.905-.204-1.658.444l16.522 42.982 2.301 5.984c.263.68 1.905.205 1.658-.444z'
                    fill='#0D0F33'
                />
                <path
                    d='M99.095 150.673l16.072 16.815 25.585 26.768 5.852 6.138c.494.519 2.066-.145 1.521-.715l-16.071-16.819-25.589-26.78-5.849-6.126c-.498-.519-2.07.145-1.52.719z'
                    fill='#0D0F33'
                />
                <path
                    d='M106.658 210.133l82.646-4.603 2.904 52.41-82.645 4.603-2.905-52.41z'
                    fill='#61B2DE'
                />
                <path
                    d='M106.657 210.131l82.643-4.601-39.258 34.733-43.385-30.132z'
                    fill='#61B2DE'
                />
                <path
                    d='M105.837 210.367l2.552 45.976.353 6.406c0 .172.392.176.486.169l28.047-1.572 44.322-2.468 10.274-.574c.302 0 1.196-.165 1.176-.613a32164.73 32164.73 0 01-2.54-45.85l-.353-6.39c-.023-.393-1.685-.094-1.654.448l2.54 45.878.353 6.386 1.176-.617-28.047 1.572-44.326 2.467-10.27.57.486.173-2.552-45.976-.353-6.401c-.023-.393-1.685-.094-1.658.444l-.012-.028z'
                    fill='#0D0F33'
                />
                <path
                    d='M188.641 205.412l-34.322 30.364-4.935 4.366 1.43-.197-37.976-26.371-5.413-3.761-1.098.786 28.047-1.572 44.323-2.467 10.274-.57c.584-.036 1.76-.963.654-.9l-28.043 1.572-44.326 2.448-10.274.574c-.169 0-1.588.428-1.098.766l37.976 26.375c1.809 1.25 3.613 2.502 5.414 3.757.232.11.491.15.745.115a1.32 1.32 0 00.686-.316l34.314-30.363 4.94-4.366c.701-.621-.933-.582-1.318-.24z'
                    fill='#0D0F33'
                />
                <path
                    d='M145.64 200.469c-9.459 6.213-16.177 15.274-18.788 26.356-2.164 9.199-2.697 20.85 2.242 29.279a15.516 15.516 0 005.523 5.666c2.964 1.713 6.147 1.403 9.314.472.98-.287.784-1.148-.231-.853-3.43 1.01-6.437.786-9.294-1.474a16.149 16.149 0 01-4.242-5.328c-2.05-4.008-2.853-8.614-3.046-13.078-.415-9.431 1.49-19.317 6.476-27.397 3.301-5.344 7.84-9.698 13.077-13.132.937-.613-.49-.869-1.031-.511z'
                    fill='#0D0F33'
                />
                <path
                    d='M147.122 200.472c17.949 7.506 29.54 27.115 28.948 46.417a36.004 36.004 0 01-1.242 8.916 9.938 9.938 0 01-1.267 2.778 6.191 6.191 0 01-1.43 1.65c-.918.633-1.568.319-2.246-.42-.302-.314-1.835.283-1.568.578 3.041 3.214 6.997-1.478 7.965-4.291 1.787-5.203 1.772-11.365 1.117-16.764a51.492 51.492 0 00-13.861-29.161 44.917 44.917 0 00-15.06-10.363c-.372-.153-1.87.448-1.356.66z'
                    fill='#0D0F33'
                />
                <path
                    d='M144.346 206.988a13.09 13.09 0 013.199-6.35c.678-.727-.929-.786-1.345-.334a14.207 14.207 0 00-3.527 6.872c-.138.68 1.536.429 1.662-.188h.011z'
                    fill='#0D0F33'
                />
                <path
                    d='M150.462 206.831a8.36 8.36 0 00-2.646-7.073c-.424-.393-1.96.235-1.541.644a7.857 7.857 0 012.532 6.653c-.066.652 1.6.346 1.655-.212v-.012zm-13.602 18.304c6.448 5.819 18.423 5.128 24.436-1.046.725-.746-.866-1.029-1.387-.495-5.394 5.557-16.072 5.718-21.776.582-.548-.495-1.987.314-1.273.959zm2.147-6.889a1.417 1.417 0 00-.408-1.958 1.41 1.41 0 00-1.954.409 1.416 1.416 0 00.408 1.958 1.41 1.41 0 001.954-.409zm21.893-1.878a1.41 1.41 0 00-1.752-1.291 1.412 1.412 0 00.42 2.776 1.41 1.41 0 001.332-1.485z'
                    fill='#0D0F33'
                />
                <path
                    d='M162.495 229.339l28.584 17.023m-81.714 6.248l27.651-21.306-27.651 21.306z'
                    stroke='#0D0F33'
                    strokeWidth='2.774'
                    strokeMiterlimit='10'
                />
                <path
                    d='M246.381 162.401l14.504 19.255M42.939 192.891l11.367-19.094-11.367 19.094zm27.364-26.238l20.85 5.572-20.85-5.572zm134.527-1.108l23.52-6.287-23.52 6.287z'
                    stroke='#0D0F33'
                    strokeWidth='2.312'
                    strokeMiterlimit='10'
                />
            </g>
            <defs>
                <clipPath id='clip0'>
                    <path fill='#fff' d='M0 0h294v287H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
