/* @flow */

export const ActionTypes = {
    EMAIL_COMPOSER_MINIMIZED: 'EMAIL_COMPOSER_MINIMIZED',
    EMAIL_COMPOSER_ENLARGED: 'EMAIL_COMPOSER_ENLARGED',
    EMAIL_COMPOSER_CLOSED: 'EMAIL_COMPOSER_CLOSED',
    EMAIL_COMPOSER_OPENED_WITH_ID: 'EMAIL_COMPOSER_OPENED_WITH_ID',
    EMAIL_COMPOSER_OPENED_WITH_ADDRESS: 'EMAIL_COMPOSER_OPENED_WITH_ADDRESS',
    EMAIL_COMPOSER_OPENED_WITH_NEW_EMAIL: 'EMAIL_COMPOSER_OPENED_WITH_NEW_EMAIL',
    EMAIL_COMPOSER_SEQUENCE_SELECTED: 'EMAIL_COMPOSER_SEQUENCE_SELECTED',
    EMAIL_COMPOSER_TEMPLATE_SELECTED: 'EMAIL_COMPOSER_TEMPLATE_SELECTED',
    EMAIL_COMPOSER_OVERRIDE_PROMPTED: 'EMAIL_COMPOSER_OVERRIDE_PROMPTED',
    EMAIL_COMPOSER_OVERRIDE_CANCELLED: 'EMAIL_COMPOSER_OVERRIDE_CANCELLED',
};

type EmailComposerMinimizedAction = {type: 'EMAIL_COMPOSER_MINIMIZED', payload: {entityId: string}};
type EmailComposerEnlargedAction = {type: 'EMAIL_COMPOSER_ENLARGED'};
type EmailComposerClosedAction = {type: 'EMAIL_COMPOSER_CLOSED'};
type EmailComposerOpenedWithIdAction = {
    type: 'EMAIL_COMPOSER_OPENED_WITH_ID',
    payload: {
        entityId: string,
        showEntityLink: boolean,
    },
};
type EmailComposerOpenedWithNewEmailAction = {type: 'EMAIL_COMPOSER_OPENED_WITH_NEW_EMAIL'};
type EmailComposerOverridePromptedAction = {
    type: 'EMAIL_COMPOSER_OVERRIDE_PROMPTED',
    payload: {entityId: string},
};
type EmailComposerOverrideCancelledAction = {type: 'EMAIL_COMPOSER_OVERRIDE_CANCELLED'};
type EmailComposerOpenedWithAddressAction = {
    type: 'EMAIL_COMPOSER_OPENED_WITH_ADDRESS',
    payload: {
        entityId: string,
        emailAddress: string,
    },
};
type EmailComposerSequenceSelectedAction = {
    type: 'EMAIL_COMPOSER_SEQUENCE_SELECTED',
    payload: string,
};
type EmailComposerTemplateSelectedAction = {
    type: 'EMAIL_COMPOSER_TEMPLATE_SELECTED',
    payload: string,
};

export type EmailComposerAction =
    | EmailComposerMinimizedAction
    | EmailComposerEnlargedAction
    | EmailComposerClosedAction
    | EmailComposerOpenedWithIdAction
    | EmailComposerOpenedWithAddressAction
    | EmailComposerOpenedWithNewEmailAction
    | EmailComposerSequenceSelectedAction
    | EmailComposerOverridePromptedAction
    | EmailComposerOverrideCancelledAction
    | EmailComposerTemplateSelectedAction;

/*
 * Action creators
 */
export const minimizeComposer = (payload: {entityId: string}): EmailComposerMinimizedAction => ({
    type: ActionTypes.EMAIL_COMPOSER_MINIMIZED,
    payload,
});

export const closeComposer = (): EmailComposerClosedAction => ({
    type: ActionTypes.EMAIL_COMPOSER_CLOSED,
});

export const selectSequenceId = (sequenceId: string): EmailComposerSequenceSelectedAction => ({
    type: ActionTypes.EMAIL_COMPOSER_SEQUENCE_SELECTED,
    payload: sequenceId,
});

export const selectTemplateId = (templateId: string): EmailComposerTemplateSelectedAction => ({
    type: ActionTypes.EMAIL_COMPOSER_TEMPLATE_SELECTED,
    payload: templateId,
});

export const enlargeComposer = (): EmailComposerEnlargedAction => ({
    type: ActionTypes.EMAIL_COMPOSER_ENLARGED,
});

export const openComposerWithId = (
    entityId: string,
    showEntityLink: boolean = false
): EmailComposerOpenedWithIdAction => ({
    type: ActionTypes.EMAIL_COMPOSER_OPENED_WITH_ID,
    payload: {entityId, showEntityLink},
});

export const openComposerWithNewEmail = (): EmailComposerOpenedWithNewEmailAction => ({
    type: ActionTypes.EMAIL_COMPOSER_OPENED_WITH_NEW_EMAIL,
});

export const promptOverrideMinimizedEmail = (
    entityId: string
): EmailComposerOverridePromptedAction => ({
    type: ActionTypes.EMAIL_COMPOSER_OVERRIDE_PROMPTED,
    payload: {entityId},
});

export const cancelOverrideMinimizedEmail = (): EmailComposerOverrideCancelledAction => ({
    type: ActionTypes.EMAIL_COMPOSER_OVERRIDE_CANCELLED,
});

export const openComposerWithEmailAddress = ({
    emailAddress,
    entityId,
}: {
    emailAddress: string,
    entityId: string,
}): EmailComposerOpenedWithAddressAction => ({
    type: ActionTypes.EMAIL_COMPOSER_OPENED_WITH_ADDRESS,
    payload: {emailAddress, entityId},
});
