/* @flow */

import {useMutation} from '@apollo/react-hooks';

import type {
    EmailLeadAssociationsUpdateInput,
    UpdateEmailLeadAssociations as UpdateEmailLeadAssociationsMutation,
    UpdateEmailLeadAssociationsVariables as UpdateEmailLeadAssociationsMutationVariables,
} from 'nutshell-graphql-types';

import GET_RELATED_LEADS from '../graphql/queries/get-related-leads.graphql';
import UPDATE_EMAIL_LEAD_ASSOCIATIONS from './graphql/mutations/update-email-lead-associations.graphql';

export function useUpdateEmailLeadAssociations(
    changeLogId: string
): (EmailLeadAssociationsUpdateInput) => Promise<*> {
    const [updateEmailLeadAssociations] = useMutation<
        UpdateEmailLeadAssociationsMutation,
        UpdateEmailLeadAssociationsMutationVariables
    >(UPDATE_EMAIL_LEAD_ASSOCIATIONS);

    return (input: EmailLeadAssociationsUpdateInput) =>
        updateEmailLeadAssociations({
            variables: {input},
            update: (proxy) => {
                const emailRelatedLeadsQuery = proxy.readQuery({
                    query: GET_RELATED_LEADS,
                    variables: {changeLogId},
                });

                if (emailRelatedLeadsQuery) {
                    const updatedEmailRelatedLeads = {
                        ...emailRelatedLeadsQuery.event.payload.emailRelatedLeads,
                    };

                    input.leadAssociations.forEach((updatedAssociation) => {
                        const associationIndex = updatedEmailRelatedLeads.edges.findIndex(
                            (leadEdge) => updatedAssociation.leadId === leadEdge.node.id
                        );

                        if (associationIndex !== -1) {
                            updatedEmailRelatedLeads.edges[associationIndex].isMapped =
                                updatedAssociation.isMapped;
                        }
                    });

                    proxy.writeQuery({
                        query: GET_RELATED_LEADS,
                        variables: {changeLogId},
                        data: {
                            ...emailRelatedLeadsQuery,
                            event: {
                                ...emailRelatedLeadsQuery.event,
                                payload: {
                                    ...emailRelatedLeadsQuery.event.payload,
                                    emailRelatedLeads: {...updatedEmailRelatedLeads},
                                },
                            },
                        },
                    });
                }
            },
        });
}
