import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import CircleMinus from './icon-circle-minus.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <CircleMinus {...validProps} />;
}
SvgComponent.iconName = 'circleMinus';

export const CircleMinusIcon = svgIconWrapper(SvgComponent);
