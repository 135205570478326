/* @flow */

import * as React from 'react';
import {Query} from 'react-apollo';

import type {GetActivityTypes as GetActivityTypesQuery} from 'nutshell-graphql-types';
import {makeDataSafe} from 'nutshell-core/utils/graphql-errors';

import {appHistory} from '../../../history';
import {useGetSessionUser} from '../use-get-session-user';
import {GraphQLSelect} from './graphql-select';
import GET_ACTIVITY_TYPES from './queries/get-activity-types.graphql';

class ActivityTypeQuery extends Query<GetActivityTypesQuery, {}> {}

type Props = {
    inputId?: string,
    selectedId: ?string,
    onChange: (newValue: Object) => any,
    onClearValue?: () => void,
    labelKey?: string,
    valueKey?: string,
    autoFocus?: boolean,
    autoBlur?: boolean,
    isLoading?: boolean,
    noResultsText?: string,
    styles?: Object,
    menuPortalTarget?: HTMLElement,
    defaultsToFirstItem?: boolean,
    includeManageOption?: boolean,
    controlIcon?: React.ComponentType<*>,
};

export const GraphQLSelectActivityTypes = (props: Props) => {
    const {user} = useGetSessionUser();

    return (
        <ActivityTypeQuery query={GET_ACTIVITY_TYPES}>
            {({data}) => {
                const {activityTypes} = makeDataSafe(data);

                return (
                    <GraphQLSelect
                        {...props}
                        onChange={(selection) => {
                            return props.onChange(selection);
                        }}
                        placeholder='Activity types…'
                        models={activityTypes || []}
                        footerButton={
                            props.includeManageOption && user && user.permissions.canAccessSetup
                                ? {
                                      label: 'Manage types',
                                      onClick: () => {
                                          appHistory.push('/setup/activity-type');
                                      },
                                  }
                                : undefined
                        }
                    />
                );
            }}
        </ActivityTypeQuery>
    );
};
