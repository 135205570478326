/* @flow */

import * as React from 'react';
import {Title} from '../typography';
import {CloseButton, SaveButton, Button, type ButtonVariant} from '../button';
import './popover-form.css';

type Props = {
    children: React.Node,
    width: number,
    height?: number,
    title: React.Node,
    onClose: () => void,
    onSubmit: () => void,
    saveButtonDisabled: boolean,
    isSaving: boolean,
    errorSaving?: boolean,
    onClearSaveComplete?: () => void,
    saveButtonText?: string,
    saveButtonSavingText?: string,
    saveButtonSuccessText?: string,
    shouldHideCloseButton?: boolean,
    cancelButtonText: string,
    allowTitleWrapping?: boolean,
    resetOnSaveComplete?: boolean,
    saveCompleteTimeout?: number,
    hideSubmitButton?: boolean,
    formBodyStyles?: Object,
    saveButtonVariant?: ButtonVariant,
};

PopoverForm.defaultProps = {
    width: 300,
    isSaving: false,
    saveButtonDisabled: false,
    cancelButtonText: 'Cancel',
};

export function PopoverForm(props: Props) {
    const onSubmit = (e: SyntheticFocusEvent<*>) => {
        e.preventDefault();
        props.onSubmit();
    };

    return (
        <div styleName='popover-form' style={{width: props.width, height: props.height}}>
            <div styleName='top'>
                <div styleName={props.allowTitleWrapping ? 'title' : 'title--no-wrap'}>
                    <Title>{props.title}</Title>
                </div>
                {props.shouldHideCloseButton ? undefined : <CloseButton onClick={props.onClose} />}
            </div>
            <form onSubmit={onSubmit}>
                <div styleName='form-body' style={props.formBodyStyles}>
                    {props.children}
                </div>
                <div styleName='buttons'>
                    <Button variant='text' onClick={props.onClose} size='big'>
                        {props.cancelButtonText}
                    </Button>
                    {props.hideSubmitButton ? (
                        undefined
                    ) : (
                        <SaveButton
                            isSaving={props.isSaving}
                            buttonText={props.saveButtonText}
                            disabled={props.saveButtonDisabled}
                            savingText={props.saveButtonSavingText}
                            successText={props.saveButtonSuccessText}
                            onClearSaveComplete={props.onClearSaveComplete}
                            errorSaving={props.errorSaving}
                            resetOnComplete={props.resetOnSaveComplete}
                            saveCompleteTimeout={props.saveCompleteTimeout}
                            variant={props.saveButtonVariant}
                        />
                    )}
                </div>
            </form>
        </div>
    );
}
