/* @flow */

import * as React from 'react';

import {Button} from 'shells/button';
import {CogIcon} from 'shells/icon';
import {UpgradeArrowIcon} from '../icon/svg/upgrade-arrow-icon';
import {colors} from '../colors';

import './customize-button.css';

type Props = {
    onClick: () => void,
    gated: boolean,
};

export function CustomizeButton(props: Props) {
    const IconComponent = props.gated ? UpgradeIcon : CogIcon;

    return (
        <div id='for-intercom-customize-button' styleName='action-button'>
            <Button icon={IconComponent} onClick={props.onClick} size='big'>
                Customize fields
            </Button>
        </div>
    );
}

function UpgradeIcon() {
    return <UpgradeArrowIcon size={18} wrapWithDiv={false} color={colors.blue} />;
}
