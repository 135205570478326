/* @flow */

import {defaultHeaders} from './default-options';

type ProvidedHeaders = {[key: string]: string} | Headers;

/**
 * Utility function to merge in a Headers object or plain object of headers with
 * a set of default headers.  Any non-default headers provided will completely
 * overwrite the default header value for that key.
 *
 * If no arguments at all are provided, the default headers will be returned
 *
 * @param  {Headers|Object} headers    Headers to merge into the defaults
 * @param  {Headers}        defaults   A default set of headers
 * @return {Headers}                   A new Headers object
 */
export function mergeHeaders(
    headers?: ?ProvidedHeaders = {},
    defaults?: ProvidedHeaders = defaultHeaders
): Headers {
    const mergedHeaders = new Headers();
    // Add default headers

    if (defaults instanceof Headers) {
        for (const pair of defaults.entries()) {
            mergedHeaders.set(...pair);
        }
    } else {
        // An existing headers object was provided
        Object.keys(defaults).forEach((key) => {
            // `defaults` is an object, but Flow thinks it's still a `Headers`,
            // so we can cast it back to an Object
            mergedHeaders.set(key, (defaults: Object)[key]);
        });
    }

    if (headers) {
        // Normalize the provided headers into a Headers object
        const providedHeaders = headers instanceof Headers ? headers : new Headers(headers);
        // Merge provided headers into the defaults
        for (const pair of providedHeaders.entries()) {
            mergedHeaders.set(...pair);
        }
    }

    return mergedHeaders;
}
