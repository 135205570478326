/* @flow */

import React from 'react';
import {LoadingIcon} from '../icon';
import './list-view-infinite-loading.css';

export function ListViewInfiniteLoading() {
    return (
        <div styleName='list-view-infinite-loading'>
            <LoadingIcon size={32} />
        </div>
    );
}
