/* @flow */
import type {FilterFieldType} from './serialize';
import {serializeDate} from './serialize-date';
import {serializeLocation} from './serialize-location';
import {serializeMap} from './serialize-map';
import {serializeRelationship} from './serialize-relationship';
import {serializeStringOrNumber} from './serialize-string-or-number';

/**
 * Takes in some filter value and returns a serialized form that could be used
 * within a HTTP API filter query, i.e., serialized to a URL-param-ready value.
 *
 * TODO: @mudetroit enumerate out the valid filter types somewhere
 *
 * @param {*} filter The value of the filter that needs to be serialized to a URL
 *     param.
 * @param {string} fieldType The type of field that's being filtered by the
 *     value, e.g., whether or not this is filtering on a phone number, custom
 *     field, entity relationship, boolean, etc.
 *
 * @returns {*} The value serialized for a URL param, which needs to _not_ be
 *     falsey as there is filter code throughout users of this function that make
 *     that expectation.
 */
export function serializeFilterObject(filter: any, fieldType: FilterFieldType): any {
    switch (fieldType) {
        case 'string':
        case 'email':
        case 'currency':
        case 'number':
        case 'rating':
        case 'integer':
        case 'customField':
        case 'text':
        case 'phone':
            return serializeStringOrNumber(filter);
        case 'relationship':
        case 'enum':
            /*
                TODO @mudetroit improve the typing through here. We know that
                this will always be the right type, but haven't given Flow enough
                information to know that
            */
            return serializeRelationship(filter);
        case 'date-time':
            return serializeDate(filter);
        case 'location':
        case 'state':
            return serializeLocation(filter);
        case 'map':
            return serializeMap(filter);
        case 'boolean':
            if (typeof filter === 'string') return String(filter === 'true');
            else if (typeof filter === 'number') return String(Boolean(filter));
            else return JSON.stringify(filter);
        default:
            return filter;
    }
}
