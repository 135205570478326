/* @flow */

import * as React from 'react';
import moment from 'moment';
import _ from 'underscore';

import {isEqualTime, getDateRoundedToQuarterHour} from 'nutshell-core/date-time';
import {TickCircleIcon} from '../icon';
import './options.css';

const TIME_FORMAT = 'h:mm A';
const OPTION_ID_PREFIX = 'time-options';
const CUSTOM_OPTION_ID_PREFIX = 'custom-options';

type Props = {
    type: 'lean' | 'creator-modal' | 'popover-picker',
    date: Date,
    maxDate?: Date,
    minDate?: Date,
    onSelect: (Date) => void,
};

type Option = {
    id: string,
    label: string,
    value: Date,
};

const generateOptionId = (date, prefix = OPTION_ID_PREFIX) => {
    return `${prefix}-${date.getHours()}-${date.getMinutes()}`;
};

const generateOptionSchema = (date, prefix = OPTION_ID_PREFIX): Option => {
    return {
        id: generateOptionId(date, prefix),
        label: moment(date).format(TIME_FORMAT),
        value: date,
    };
};

const generateBaseOptions: (Date) => Option[] = _.memoize((date) => {
    const startOfDay = moment(date).startOf('day');

    return Array.from(Array(24 * 4)).map((val, index) => {
        const dateOption = startOfDay
            .clone()
            .add(index * 15, 'minutes')
            .toDate();

        return generateOptionSchema(dateOption);
    });
});

const getNearestOptionIndex = (date, options) => {
    const roundedQtrHourDate = getDateRoundedToQuarterHour(new Date(date));
    const nearestOptionId = generateOptionId(roundedQtrHourDate);
    const nearestOptionIndex = _.findIndex(options, (option) => option.id === nearestOptionId);

    // If there isn't a match just append the custom option to the end of the stack.
    // This can happen for times which would normally be rounded up to the next day
    if (nearestOptionIndex === -1) return options.length;

    // Make sure this index is the right placement for our custom option.
    return date.getTime() > roundedQtrHourDate.getTime()
        ? nearestOptionIndex + 1
        : nearestOptionIndex;
};

const generateOptionsCollection = _.memoize((date: Date) => {
    const options = generateBaseOptions(date);

    // If we dont have a canned quarter hour option match, create a
    // custom option and splice it into the collection.
    const cannedOption: ?Option = options.find((option) => {
        return isEqualTime(option.value, date, true);
    });

    const customOption = cannedOption
        ? undefined
        : generateOptionSchema(date, CUSTOM_OPTION_ID_PREFIX);

    if (customOption) {
        const nearestOptionIndex = getNearestOptionIndex(customOption.value, options);
        options.splice(nearestOptionIndex, 0, customOption);
    }

    return options;
});

/**
 * For choosing a time, used in the date picker mostly.
 */
export class TimePicker extends React.Component<Props> {
    scrollContainerRef: ?HTMLElement;
    selectedOptionRef: ?HTMLElement;

    static defaultProps = {
        // Lean styling for standalone time-picker, outside of the normal
        // date-time picker.
        type: 'lean',
    };

    componentDidMount() {
        this.scrollToSelectedOption();
    }

    shouldComponentUpdate(nextProps: Props) {
        // Only update component if the time has changed.
        return nextProps.date.getTime() !== this.props.date.getTime();
    }

    componentDidUpdate() {
        // Wait till the current call stack is cleared before scrolling.
        // This prevents calculating scroll position from the prev selected option.
        _.defer(this.scrollToSelectedOption);
    }

    render() {
        const options = generateOptionsCollection(this.props.date);

        return (
            <div styleName={`container type-${this.props.type}`}>
                <div
                    ref={(node) => {
                        this.scrollContainerRef = node;
                    }}
                    styleName='scroll-container'
                >
                    {options.map(this.renderOption)}
                </div>
            </div>
        );
    }

    renderOption = (option: Option) => {
        const {id, label, value} = option;
        const isSelected = isEqualTime(this.props.date, value, true);
        const momentValue = moment(value);
        const isDisabled =
            (this.props.maxDate && momentValue.isAfter(this.props.maxDate)) ||
            (this.props.minDate && momentValue.isBefore(this.props.minDate));

        return (
            <div
                key={option.id}
                ref={(node) => this.assignOptionRef(node, isSelected)}
                styleName={isSelected ? 'option--selected' : 'option'}
            >
                <input
                    checked={isSelected}
                    disabled={isDisabled}
                    id={id}
                    name={id}
                    onChange={this.handleSelect}
                    styleName='radio'
                    type='radio'
                    value={value}
                />
                <label styleName={isDisabled ? 'label--disabled' : 'label'} htmlFor={id}>
                    <span>{label}</span>
                    {isSelected ? <TickCircleIcon styleName='check-icon' size={20} /> : undefined}
                </label>
            </div>
        );
    };

    handleSelect = (e: SyntheticInputEvent<*>) => {
        this.props.onSelect(new Date(e.target.value));
    };

    scrollToSelectedOption = () => {
        if (!this.selectedOptionRef || !this.scrollContainerRef) return;

        const {offsetHeight: containerHeight, scrollTop} = this.scrollContainerRef;
        const {offsetHeight: nodeHeight, offsetTop} = this.selectedOptionRef;

        const isNodeVisible =
            offsetTop >= scrollTop && offsetTop + nodeHeight <= scrollTop + containerHeight;

        if (isNodeVisible) return;

        this.scrollContainerRef.scrollTop = offsetTop - nodeHeight;
    };

    assignOptionRef = (node: ?HTMLElement, isSelected: boolean) => {
        if (isSelected) {
            this.selectedOptionRef = node;
        }
    };
}
