/* @flow */

/**
 * Busts our collectionTimes cache so that the managedCollection
 * refetches itself without needing a page refresh
 *
 * @param  {string} collectionName    - Collection name to refresh (i.e. emailSequenceTemplates)
 * @return void
 */
export function bustManagedCollectionCache(collectionName: string) {
    const managedCollectionName = collectionName[0].toUpperCase() + collectionName.slice(1);
    const managedCollection = Nut.managedCollections[managedCollectionName];

    if (managedCollection) {
        // get current timestamp to bust the collections cache
        const currentTimestamp = Math.floor(new Date().getTime() / 1000);
        window.collectionTimes[collectionName] = currentTimestamp;
        managedCollection.fetch();
    }
}
