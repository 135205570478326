/* @flow */

import * as React from 'react';

import './animating-bars.css';

const MIN_HEIGHT = 5;
const MULTIPLIER = 10;
const MIDDLE = -10;
const NUM_BARS = 12;

export function AnimatingBars() {
    const [volume, setVolume] = React.useState(0);
    const animationFrameId = React.useRef(null);
    const lastRenderMs = React.useRef<number>(0);
    const barsRef = React.useRef([]);

    React.useEffect(() => {
        if (navigator.mediaDevices && barsRef.current.length !== 0) {
            navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
                const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

                const analyser = audioCtx.createAnalyser();
                analyser.fftSize = 512;

                const bufferLength = analyser.frequencyBinCount;
                const dataArray = new Uint8Array(bufferLength);

                const source = audioCtx.createMediaStreamSource(stream);

                // Connect the source to be analysed
                source.connect(analyser);

                const render = (currentRenderMs: number) => {
                    if (currentRenderMs - lastRenderMs.current > 100) {
                        analyser.getByteFrequencyData(dataArray);

                        // Volume can be between 0 and 255, but realistic volume levels are 0 - 50
                        // Divide by 50 to get a number between 0 and 1
                        const microphoneVolume =
                            dataArray.reduce((acc, val) => acc + val, 0) / bufferLength / 50;

                        setVolume(microphoneVolume);

                        lastRenderMs.current = currentRenderMs;
                    }

                    animationFrameId.current = requestAnimationFrame(render);
                };

                animationFrameId.current = requestAnimationFrame(render);
            });
        }

        return () => {
            if (animationFrameId.current) {
                cancelAnimationFrame(animationFrameId.current);
                animationFrameId.current = null;
            }
        };
    }, []);

    return (
        <div styleName='bar-container'>
            {Array.from({length: NUM_BARS}).map((_, index) => {
                const heightWithVolume = MIN_HEIGHT + MULTIPLIER * volume; // number between 5 - 15
                const variance = 0.5 - Math.random() * 0.5; // number between 0 - 5
                const displayedHeight = heightWithVolume + variance; // number between 5 - 20
                const middleForVolume = MIDDLE + displayedHeight / 2;

                return (
                    <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        styleName='bar'
                        style={{
                            transform: `scaleY(${volume * 2 + variance})`,
                            top: `${middleForVolume}px`,
                        }}
                        ref={(node) => {
                            barsRef.current[index] = node;
                        }}
                    />
                );
            })}
        </div>
    );
}
