/* @flow */

import * as React from 'react';

/**
 * This hook is used to focus the passed in input and then clear the should focus state
 */
export function useSetNewItem(newItem: ?string, setNewItem: (?string) => void, fields: any) {
    React.useEffect(() => {
        if (newItem) {
            // We need to remove the last value (null or temporary id) before adding the new value
            fields.remove(-1);
            fields.push(newItem);
            setNewItem(null);
        }
    }, [newItem, setNewItem, fields]);
}
