/* @flow */

import cloneDeep from 'lodash/cloneDeep';
import type {SavedFilter, SavedReportType, ReportFacet, FilterObject} from 'nutshell-core/types';
import {serializeFilters} from 'nutshell-core';
import {Routing} from 'nutshell-core/routing';
import {getReportDefaultState} from './report-constants';
import type {
    ReportKey,
    ReportGap,
    ReportSegment,
    DateRangeType,
    ChartReportParams,
} from './report-types';

export type ReportPostParams = {
    filter: Object, // Filter object
    groupByField: ?DateRangeType,
    pivotFacet: ?ReportFacet,
    reportType: ReportKey,
    gap: ReportGap,
    useConfidence: ?string,
    segmentByField: ?ReportSegment,
};

export function getCustomizeLinkForReportFromSearch(search: string) {
    return `/leads${search}&view=explore`;
}

export function getPostParams(
    reportKey: ReportKey,
    reportParams: ChartReportParams,
    filters: Array<FilterObject>,
    format: ?string
): ReportPostParams {
    const {gap, facet, segment, useConfidence, dateRangeType} = reportParams;

    return {
        filter: serializeFilters(filters),
        groupByField: dateRangeType,
        pivotFacet: facet,
        reportType: reportKey,
        gap,
        useConfidence,
        segmentByField: segment,
        format,
    };
}

/**
 * Strips out urlParams that we don't want to save to the server. For example,
 * it's necessary to have the `report.id` in the url, but we don't actually
 * want that to be saved to the `value` column in the database
 *
 * @param  {object} urlParams  - urlParams object from url to strip report.id and list.page
 * @return {string}            - Param'd urlString that does not contain blacklisted params
 */
export function getSafeUrlParams(urlParams: Object): string {
    const safeParams = cloneDeep(urlParams);
    if (safeParams.report) {
        delete safeParams.report.id;
    }

    if (safeParams.list) {
        delete safeParams.list.page;
    }

    return Routing.param(safeParams);
}

/**
 * Check our current report's value with either the default state of the
 * current report (i.e. the default `Sales` report url), or the persisted
 * saved value of the report.
 *
 * This method is slightly complicated because the URL doesn't match up
 * exactly with the saved value of the report, since we purposefully remove
 * both the report id and the lists' page param when saving to the DB, so
 * we can't just liberally compare the url with the report's value
 *
 * @param  {string}  reportKey     - Unique report key
 * @param  {Object}  urlParams     - Entire urlParam object to check dirtyness
 * @param  {Object}  savedReport   - Are we comparing the dirtyness of saved report?
 *
 * @return {boolean} Is this report dirty?
 */
export function isReportDirty(
    reportKey: ReportKey,
    urlParams: Object,
    savedReport: ?SavedFilter<SavedReportType>
) {
    const currentParams = getSafeUrlParams(urlParams);

    if (savedReport) {
        return currentParams !== savedReport.value;
    }

    const defaultReportState = getReportDefaultState(reportKey);

    const urlParamString = Routing.deparam(defaultReportState.search.slice(1));
    const safeUrlParamString = getSafeUrlParams(urlParamString);

    return currentParams !== safeUrlParamString;
}
