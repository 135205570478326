/* @flow */

import * as React from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {LoadingIcon} from '../icon';
import {colors} from '../colors';
import './saved-setting-toast.css';

export type SavedSettingToastState = 'saving' | 'complete' | 'initial';

type Props = {
    children?: React.Node,
    state: SavedSettingToastState,
    toastStyles?: Object,
    savedText: string,
};

const spinnerVariants = {
    visible: {opacity: 1},
    hidden: {opacity: 0},
};

export function SavedSettingToast(props: Props) {
    return (
        <div className='flex align-center'>
            {props.children}
            <motion.div
                styleName='spinner'
                style={props.toastStyles}
                aria-hidden={true}
                initial='hidden'
                animate={props.state === 'saving' ? 'visible' : 'hidden'}
                variants={spinnerVariants}
                transition={{duration: 0.2}}
            >
                <LoadingIcon size={null} wrapWithDiv={false} fill={colors.green} />
            </motion.div>
            <AnimatePresence>
                {props.state === 'complete' && (
                    <motion.div
                        key='complete'
                        styleName='save-complete'
                        style={props.toastStyles}
                        initial={{y: 0, x: props.children ? -10 : -5, opacity: 0}}
                        animate={{y: 0, x: props.children ? -5 : -10, opacity: 1}}
                        exit={{y: -10, x: props.children ? -5 : -10, opacity: 0}}
                        transition={{duration: 0.2}}
                    >
                        {props.savedText}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

SavedSettingToast.defaultProps = {
    savedText: 'Saved',
};
