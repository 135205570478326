/* @flow */

import {unexpectedCase, getRehydratedValueForDateProperty} from 'nutshell-core/reducer-utils';
import moment from 'moment';

import {ACTIVITIES} from '../master-dashboard/dashboard-constants';
import type {ActivitiesTabKey, TodosTabKey} from '../master-dashboard/dashboard-constants';
import {ActionTypes as SidebarActionTypes, type SidebarAction} from './sidebar-actions';

export type SidebarState = {
    activeView: ActivitiesTabKey | TodosTabKey,
    displayDate: number, // unix milliseconds
    lastUpdated: number, // unix milliseconds
};

const sidebarDefaultState = {
    activeView: ACTIVITIES,
    displayDate: moment()
        .startOf('day')
        .valueOf(),
    lastUpdated: 0,
};

// This reducer is responsible for ui-level concerns, such as the calendar date,
// which tab in the calendar is selected, etc.
export const sidebarReducer = (
    state: SidebarState = sidebarDefaultState,
    action: SidebarAction
): SidebarState => {
    switch (action.type) {
        case SidebarActionTypes.SIDEBAR_CALENDAR_ACTIVE_VIEW_UPDATED: {
            return {
                ...state,
                activeView: action.payload,
            };
        }
        case SidebarActionTypes.SIDEBAR_CALENDAR_DISPLAY_DATE_UPDATED: {
            return {
                ...state,
                displayDate: action.payload,
                lastUpdated: new Date().getTime(),
            };
        }
        // $FlowFixMe upgrading Flow to v0.92.1 on web
        case 'persist/REHYDRATE': {
            const persistedState = action.payload;

            if (!persistedState || !persistedState.ui || !persistedState.ui.sidebar) {
                return state;
            }

            return {
                ...state,
                displayDate: getRehydratedValueForDateProperty(
                    persistedState.ui.sidebar,
                    'displayDate'
                ),
                lastUpdated: persistedState.ui.sidebar.lastUpdated,
                activeView: persistedState.ui.sidebar.activeView,
            };
        }
        default:
            unexpectedCase(action.type);

            return state;
    }
};
