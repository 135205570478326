/* @flow */
import {createAction} from 'redux-act';

import type {FilterObject} from 'nutshell-core/types';

export type EditionRecipientListRequestedAction = {
    type: 'EDITION_RECIPIENT_LIST_DATA_REQUESTED',
    payload: {
        filters: FilterObject[],
        pageNum: number,
        columns: string[],
        sort: string,
        q: string,
    },
};

export type EditionEngagementReportDataRequestedAction = {
    type: 'EDITION_ENGAGEMENT_REPORT_DATA_REQUESTED',
    payload: {
        reportParams: Object,
        filters: FilterObject[],
    },
};

export const toggleShowFilterDrawer = createAction('EDITION_RECIPIENT_LIST_FILTER_DRAWER_TOGGLED');

// Edition recipient list
export const requestEditionRecipientListData: (
    payload: $PropertyType<EditionRecipientListRequestedAction, 'payload'>
) => EditionRecipientListRequestedAction = createAction('EDITION_RECIPIENT_LIST_DATA_REQUESTED');
export const updateEditionRecipientListData = createAction('EDITION_RECIPIENT_LIST_DATA_UPDATED');
export const failEditionRecipientListData = createAction('EDITION_RECIPIENT_LIST_DATA_FAILED');

// Edition engagement report
export const requestEditionEngagementReportData: (
    payload: $PropertyType<EditionEngagementReportDataRequestedAction, 'payload'>
) => EditionEngagementReportDataRequestedAction = createAction(
    'EDITION_ENGAGEMENT_REPORT_DATA_REQUESTED'
);
export const updateEditionEngagementReportTableData = createAction(
    'EDITION_ENGAGEMENT_REPORT_TABLE_DATA_UPDATED'
);
export const updateEditionEngagementReportChartData = createAction(
    'EDITION_ENGAGEMENT_REPORT_CHART_DATA_UPDATED'
);
export const updateEditionEngagementReportMaps = createAction(
    'EDITION_ENGAGEMENT_REPORT_MAPS_UPDATED'
);
export const failEditionEngagementReportChartData = createAction(
    'EDITION_ENGAGEMENT_REPORT_CHART_DATA_FAILED'
);
