import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 18.4 31.1'}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                fill='#1FB790'
                d='M15.3 8.7c-1.9 1.7-4.3 2.6-6.8 2.7-.5 2.1-.2 4.8.1 6.3h5.3c.8 0 1.2.7 1.1 1.5l-2.1 10.4c-.2.8-.9 1.5-1.7 1.5H3.8c-.8 0-1.5-.7-1.7-1.5L0 19.1c-.2-.8.3-1.5 1.1-1.5h5.3c0-2.1.2-5.4.8-7.5C7.7 4 12.1-.3 17.8 0c.3 0 .6.3.6.6-.1 3-1.1 5.9-3.1 8.1z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'plant-filled-small';

export const PlantSmallFilledIcon = svgIconWrapper(SvgComponent);
