/* @flow */

import type {ReportGap, FilterObject} from 'nutshell-core/types';
import {serializeFilters} from 'nutshell-core';

const CONNECTED_FORM_TYPE = 1;

export type ConnectedFormChartParams = {
    gap: ReportGap,
};

export type ConnectedFormReportPostParams = {
    filter: Object,
    gap: ReportGap,
};

export function getReportPostParams(
    reportParams: ConnectedFormChartParams,
    filters: FilterObject[]
): ConnectedFormReportPostParams {
    const {gap} = reportParams;

    return {
        filter: serializeFilters(filters),
        gap,
        formType: CONNECTED_FORM_TYPE,
    };
}
