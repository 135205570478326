/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {FormField} from '../../form';
import {
    SelectTwoLineIcon,
    type Props as SelectTwoLineProps,
} from '../../select-two-line-icon/select-two-line-icon';

type OwnProps = {|
    title?: string,
    helperText: string,
    ...SelectTwoLineProps,
|};

type Props = {...$Exact<FieldProps>, ...OwnProps};

export function ReduxFormSelectTwoLineIcon({title, helperText, input, meta, ...restProps}: Props) {
    const selectedOption = restProps.options.find((opt) => opt.value === input.value);

    return (
        <FormField
            innerId={input.name}
            label={title}
            helperText={helperText}
            errorMessage={meta.error && meta.submitFailed ? meta.error : null}
        >
            {/* $FlowIgnore I can't get this to work because of the exact prop types */}
            <SelectTwoLineIcon
                {...input}
                {...restProps}
                onBlur={() => {
                    // Who can explain it, who can tell why. Somehow, this
                    // works. If the params are passed through, the value for
                    // this field is reset on blur :shrug:
                    input.onBlur();
                }}
                onChange={(option) => {
                    input.onChange(option.value);
                }}
                value={selectedOption}
            />
        </FormField>
    );
}
