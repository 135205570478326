import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import MapPin from './icon-map-pin.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <MapPin {...validProps} />;
}
SvgComponent.iconName = 'mapPin';

export const MapPinIcon = svgIconWrapper(SvgComponent);
