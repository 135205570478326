/* @flow */

import {Subject} from 'rxjs/Subject';
import 'rxjs/add/operator/map';

let COUNTER = 0;

const notificationsSubject = new Subject();
export const notificationStream = notificationsSubject.map((notification) => {
    return {id: COUNTER, ...notification};
});

export type NotificationOptions = {
    body?: string,
    icon?: 'check' | 'frowny' | 'email',
    onClick?: (SyntheticEvent<HTMLElement>) => void,
};

/**
 * Method to generate a "Growl" style notification within Nutshell. The
 * interface for this fucntion is designed to roughly correspond to native
 * Notifications, https://developer.mozilla.org/en-US/docs/Web/API/Notification.
 * The goal being to eventually make it easy to leverage the native
 * implementation.
 *
 * @param   {string} title - Defines a title for the notification, which will be
 *          shown at the top of the notification window when it is fired.
 *
 * @param   {object} [options] - Options object parameters detailed below
 * @param   {string} [options.body] - A DOMString representing the body text of
 *          the notification, which will be displayed below the title.
 * @param   {string} [options.icon ]- Name of an icon to be displayed as part of
 *          the notification.
 * @param   {function} [options.onClick] - A handler for the click event. It is
 *          triggered each time the user clicks on the notification.
 *
 * @returns {void}
 */
export function addNotification(title: string, options: NotificationOptions = {}) {
    COUNTER++;
    notificationsSubject.next({title, options});
}
