import PropTypes from 'prop-types';
import * as React from 'react';
import classnames from 'classnames';
import _ from 'underscore';
import {CheckIcon} from 'shells/icon';

// Monthly: ns-uub-m, annual: ns-uub-a
const PRO_PLAN_SKU = 'ns-uub';
const STARTER_COLUMN_INDEX = 0;
const PRO_COLUMN_INDEX = 1;

const FEATURES = [
    {
        name: 'Customer management',
        columns: {classic: true, starter: true, pro: true},
    },
    {
        name: 'Pipeline tracking',
        columns: {classic: true, starter: true, pro: true},
    },
    {
        name: 'Sales and forecast reporting',
        columns: {classic: true, starter: true, pro: true},
    },
    {
        name: 'Email and calendar sync',
        columns: {classic: true, starter: true, pro: true},
    },
    {
        name: 'Small business software integration',
        columns: {classic: true, starter: true, pro: true},
    },
    {
        name: 'Phone, live chat, and email support',
        columns: {classic: true, starter: true, pro: true},
    },
    {
        name: 'Custom import help',
        columns: {classic: true, starter: true, pro: true},
    },
    {
        name: 'Team activity reporting',
        columns: {classic: true, starter: false, pro: true},
    },
    {
        name: 'Funnel reporting',
        columns: {classic: false, starter: false, pro: true},
    },
    {
        name: 'Sales process automation',
        columns: {classic: true, starter: false, pro: true},
    },
    {
        name: 'Click-to-call dialing',
        columns: {classic: false, starter: false, pro: true},
    },
];

export class PlanComparisonChart extends React.Component {
    FRONT_END_SETTINGS = window.NutshellFrontendSettings || {};
    INSTANCE_PLAN_SKU = this.FRONT_END_SETTINGS.plans_sku;
    IS_TRIAL = this.INSTANCE_PLAN_SKU === undefined;

    IS_PRO_PLAN = this.IS_TRIAL ? false : this.INSTANCE_PLAN_SKU.indexOf(PRO_PLAN_SKU) !== -1;

    CAN_HAVE_CLASSIC = Number(this.FRONT_END_SETTINGS.can_have_classic) === 1;
    COLUMN_HEADERS = [this.CAN_HAVE_CLASSIC ? 'Classic' : 'Starter', 'Pro'];

    static propTypes = {
        headerTitle: PropTypes.string,
    };

    static defaultProps = {
        headerTitle: undefined,
    };

    render() {
        const {headerTitle} = this.props;

        const headers = this.COLUMN_HEADERS.map((columnHeader, index) => {
            const isStarterColumn = index === STARTER_COLUMN_INDEX;
            const isProColumn = index === PRO_COLUMN_INDEX;

            const isCurrentPlan =
                (isStarterColumn && !this.IS_PRO_PLAN) || (isProColumn && this.IS_PRO_PLAN);

            const classes = classnames({
                'check-column': true,
                'current-plan': isCurrentPlan && !this.IS_TRIAL,
                'pro-plan': isProColumn,
            });

            return (
                <th className={classes} key={columnHeader}>
                    <div>
                        <span className='plan-name'>{columnHeader}</span>
                        {isCurrentPlan && !this.IS_TRIAL ? <span>Your plan</span> : undefined}
                    </div>
                </th>
            );
        });

        const features = FEATURES.map((feature) => {
            const columnKey = this.CAN_HAVE_CLASSIC ? 'classic' : 'starter';
            const isStarterChecked = feature.columns[columnKey];
            const isProChecked = feature.columns.pro;

            const sharedClasses = {'check-column': true};

            const starterColumnClasses = classnames(
                _.extend(sharedClasses, {
                    'current-plan': !this.IS_PRO_PLAN && !this.IS_TRIAL,
                })
            );

            const proColumnClasses = classnames(
                _.extend(sharedClasses, {
                    'current-plan': this.IS_PRO_PLAN && !this.IS_TRIAL,
                })
            );

            return (
                <tr key={feature.name}>
                    <td>{feature.name}</td>

                    <td className={starterColumnClasses}>
                        {isStarterChecked ? (
                            <div>
                                <CheckIcon />
                            </div>
                        ) : (
                            <div />
                        )}
                    </td>

                    <td className={proColumnClasses}>
                        {isProChecked ? (
                            <div>
                                <CheckIcon />
                            </div>
                        ) : (
                            <div />
                        )}
                    </td>
                </tr>
            );
        });

        return (
            <div className='plan-comparison-chart-wrapper'>
                <table>
                    <tbody>
                        <tr>
                            <th className='title'>
                                {headerTitle ? <h2>{headerTitle}</h2> : undefined}
                            </th>
                            {headers}
                        </tr>
                        {features}
                    </tbody>
                </table>
            </div>
        );
    }
}
