/* @flow */
import {createAction} from 'redux-act';

import type {FilterObject} from 'nutshell-core/types';

export type LandingPagesReportDataRequestedAction = {
    type: 'LANDING_PAGES_REPORT_DATA_REQUESTED',
    payload: {
        reportParams: Object,
        filters: FilterObject[],
    },
};

// Landings pages report
export const requestLandingPagesReportData: (
    payload: $PropertyType<LandingPagesReportDataRequestedAction, 'payload'>
) => LandingPagesReportDataRequestedAction = createAction('LANDING_PAGES_REPORT_DATA_REQUESTED');
export const updateLandingPagesReportTableData = createAction(
    'LANDING_PAGES_REPORT_TABLE_DATA_UPDATED'
);
export const updateLandingPagesReportChartData = createAction(
    'LANDING_PAGES_REPORT_CHART_DATA_UPDATED'
);
export const updateLandingPagesSummaryStats = createAction(
    'LANDING_PAGES_REPORT_SUMMARY_DATA_UPDATED'
);
export const updateLandingPagesReportMaps = createAction('LANDING_PAGES_REPORT_MAPS_UPDATED');
export const failLandingPagesReportChartData = createAction(
    'LANDING_PAGES_REPORT_CHART_DATA_FAILED'
);
