/* @flow */

import moment from 'moment';

/**
 * Compare two native dates hours/minutes/seconds while ignoring
 * the day/month/year.
 *
 * We have to be verbose here because moment does not yet support comparing only
 * hours/minutes/seconds (i.e. `m1.isSame(m2, 'seconds')`) while ignoring the
 * actual day/month/year.
 *
 * @param  {Date} d1                         A native date object.
 * @param  {Date} d2                         A native date object.
 * @param  {Boolean} [ignoreSeconds = false] Ignore seconds equality check.
 * @return {Boolean} Return the equality of hours/minutes/seconds
 * between two dates.
 */
export function isEqualTime(d1: Date, d2: Date, ignoreSeconds: boolean = false): boolean {
    const m1 = moment(d1);
    const m2 = moment(d2);

    if (!(m1.isValid() && m2.isValid())) return false;

    return (
        m1.hours() === m2.hours() &&
        m1.minutes() === m2.minutes() &&
        (m1.seconds() === m2.seconds() || ignoreSeconds)
    );
}
