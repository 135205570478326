import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import TVRetro from './icon-tv-retro.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <TVRetro {...validProps} />;
}
SvgComponent.iconName = 'browser';

export const TVRetroIcon = svgIconWrapper(SvgComponent);
