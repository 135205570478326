/* @flow */

import * as React from 'react';

type Props = {
    title?: string,
    children: React.Node,
};

export function Truncated(props: Props) {
    return (
        <div className='truncate' title={props.title}>
            {props.children}
        </div>
    );
}
