/* @flow */

import {unexpectedCase} from 'nutshell-core/reducer-utils';
import {REHYDRATE} from 'redux-persist';
import {ActionTypes, type EmailComposerAction} from './email-composer-actions';
import {CREATE_FROM_SCRATCH_ID} from './sender/types';

export type EmailComposerState = {
    isOpen: boolean,
    wasMinimizedForEntityId: ?string,
    seededWithEmailAddress: ?string,
    selectedSequenceId: string,
    selectedTemplateId: string,
    isPromptingOverride: boolean,
    activeEntityId: ?string,
    showEntityLink: ?boolean,
};

export const defaultEmailComposerState = {
    wasMinimizedForEntityId: null,
    isOpen: false,
    selectedSequenceId: CREATE_FROM_SCRATCH_ID,
    selectedTemplateId: CREATE_FROM_SCRATCH_ID,
    seededWithEmailAddress: null,
    // Are we currently asking the user if they'd like
    // to discard their current email?
    isPromptingOverride: false,
    activeEntityId: null,
    showEntityLink: false,
};

export const reducer = (
    state: EmailComposerState = defaultEmailComposerState,
    action: EmailComposerAction
): EmailComposerState => {
    switch (action.type) {
        case ActionTypes.EMAIL_COMPOSER_MINIMIZED:
            return {
                ...state,
                isOpen: false,
                wasMinimizedForEntityId: action.payload.entityId,
            };
        case ActionTypes.EMAIL_COMPOSER_OPENED_WITH_ID:
            return {
                ...state,
                isOpen: true,
                wasMinimizedForEntityId: null,
                isPromptingOverride: false,
                activeEntityId: action.payload.entityId,
                showEntityLink: action.payload.showEntityLink,
            };
        case ActionTypes.EMAIL_COMPOSER_CLOSED:
            return defaultEmailComposerState;
        case ActionTypes.EMAIL_COMPOSER_SEQUENCE_SELECTED:
            return {
                ...state,
                selectedSequenceId: action.payload,
            };
        case ActionTypes.EMAIL_COMPOSER_TEMPLATE_SELECTED:
            return {
                ...state,
                selectedTemplateId: action.payload,
            };
        case ActionTypes.EMAIL_COMPOSER_ENLARGED:
            return {
                ...state,
                isOpen: true,
                // Can't set wasMinimizedForEntityId to null, will blow away email form values if we do
            };
        case ActionTypes.EMAIL_COMPOSER_OPENED_WITH_ADDRESS:
            return {
                ...state,
                isOpen: true,
                wasMinimizedForEntityId: null,
                isPromptingOverride: false,
                seededWithEmailAddress: action.payload.emailAddress,
                activeEntityId: action.payload.entityId,
            };
        case ActionTypes.EMAIL_COMPOSER_OVERRIDE_PROMPTED:
            return {
                ...state,
                isPromptingOverride: true,
                activeEntityId: action.payload.entityId,
            };
        case ActionTypes.EMAIL_COMPOSER_OVERRIDE_CANCELLED:
            return {
                ...state,
                isPromptingOverride: false,
            };
        case ActionTypes.EMAIL_COMPOSER_OPENED_WITH_NEW_EMAIL:
            return {
                ...state,
                isOpen: true,
                wasMinimizedForEntityId: null,
                isPromptingOverride: false,
            };
        case REHYDRATE: {
            const persistedState = action.payload;
            if (!persistedState || !persistedState.ui || !persistedState.ui.emailComposer) {
                return state;
            }

            // If we aren't minimized, don't try to rehydrate any state
            if (!persistedState.ui.emailComposer.wasMinimizedForEntityId) {
                return state;
            }

            return persistedState.ui.emailComposer;
        }
        default:
            unexpectedCase(action.type);

            return state;
    }
};
