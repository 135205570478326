/* @flow */

import * as React from 'react';

import './page-content-two-column.css';

type Props = {leftContent: React.Node, rightContent: React.Node, withoutOuterPadding?: boolean};

export function PageContentTwoColumn(props: Props) {
    return (
        <div styleName='container'>
            <div
                styleName={
                    props.withoutOuterPadding
                        ? 'left-container--without-outer-padding'
                        : 'left-container'
                }
            >
                {props.leftContent}
            </div>
            <div
                styleName={
                    props.withoutOuterPadding
                        ? 'right-container--without-outer-padding'
                        : 'right-container'
                }
            >
                {props.rightContent}
            </div>
        </div>
    );
}
