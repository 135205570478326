import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import InboundArrow from './icon-inbound-arrow.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <InboundArrow {...validProps} />;
}
SvgComponent.iconName = 'inboundArrow';

export const InboundArrowIcon = svgIconWrapper(SvgComponent);
