/* @flow */
import * as React from 'react';

import {api} from 'nutshell-core/api';

export type UnmappedReportData = {
    bucket: string,
    count: {value: number, percent?: number},
    segments: Object[],
};
export type ReportData = {bucket: string, count: number, percent?: number, segments?: Object[]}[];

export const useGetBucketedReportData = (input: {
    params: any,
    reportUrl: string,
    mapBucketsFunction: (data: UnmappedReportData) => Object,
    limit?: number,
}): {
    isLoading: boolean,
    isError: boolean,
    reportData: ?ReportData,
} => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState<boolean>(false);
    const [reportData, setReportData] = React.useState<?ReportData>(undefined);
    const {params, reportUrl, mapBucketsFunction, limit} = input;

    React.useEffect(() => {
        setIsLoading(true);
        setIsError(false);

        api.get(reportUrl, params)
            .then((res) => res.json())
            .then((json) => {
                const sortedBuckets = getSortedBucketsFromJson(json, mapBucketsFunction, limit);

                setIsLoading(false);

                if (!sortedBuckets) {
                    setIsError(true);
                }

                setReportData(sortedBuckets);
            })
            .catch(() => {
                setIsLoading(false);
                setIsError(true);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportUrl, params]);

    return {isLoading, isError, reportData};
};

export const getSortedBucketsFromJson = (
    json: Object,
    mapBucketsFunction: (data: UnmappedReportData) => ReportData,
    limit?: number
): ?ReportData => {
    if (
        !json ||
        !json.reports ||
        !json.reports[0] ||
        !json.reports[0].data ||
        !Array.isArray(json.reports[0].data)
    ) {
        return undefined;
    }

    const dataArray = json.reports[0].data
        .map(mapBucketsFunction)
        .sort((a, b) => b.count - a.count);

    if (limit) {
        return dataArray.slice(0, limit);
    } else {
        return dataArray;
    }
};
