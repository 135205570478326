/* @flow */

import * as actions from './session-actions';
import * as selectors from './session-selectors';
import {fetchSessionEpic} from './session-epics';
import {reducer} from './session-reducer';
import {isProUser} from './is-pro-user';
import {getDefaultCurrencySymbol} from './get-default-currency-symbol';
import {getInstanceDefaultCurrencyCode} from './get-instance-default-currency-code';
import {ActionTypes, REDUCER_KEY} from './session-types';

export const Session = {
    reducer,
    ActionTypes,
    ...actions,
    ...selectors,
    epics: [fetchSessionEpic],
    isProUser,
    getDefaultCurrencySymbol,
    getInstanceDefaultCurrencyCode,
    REDUCER_KEY,
};

export type {SessionModuleState, SessionAction} from './session-types';
export type {SessionState} from './session-reducer';
