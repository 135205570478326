/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import {motion} from 'framer-motion';
import {Message, type MessageType} from '../message';
import './flash-banner.css';

// Note: This is also used is message-container.css
const ANIMATE_OUT_DURATION = 500;
const ANIMATE_OUT_DURATION_SEC = ANIMATE_OUT_DURATION / 1000;

type Props = {|
    type: MessageType,
    children: React.Node,
    /** Deprecated */
    className?: string,
    /** Milliseconds.  If provided, the banner will hide after this long. */
    duration?: number,
    /** If true, the banner will be positioned absolute and will not push other elements down. */
    isOverlay?: boolean,
    /** If true, the message will have a border around it */
    hasBorder?: boolean,
    /** If true, the content will be aligned to the left edge, instead of centered */
    leftAlignContent?: boolean,
    /** If true, the content will have padding-right set to 0 */
    noRightPadding?: boolean,
    /** Callback function for the close button.  If neither `duration` nor `onHidden` is given, the banner will be permanent */
    onHidden?: () => void,
    animate: boolean,
|};

type State = {
    isAnimatingOut: boolean,
};

/**
This is used to display extra information to the user, usually at the top of the page.
It animates in, and can either hide itself after a set delay, include a close button, or stay displayed.

If any children have an `onClick` prop, the flash banner will close itself when they are clicked.
 */
export class FlashBanner extends React.Component<Props, State> {
    children: React.Node;
    delay: ?TimeoutID;

    static defaultProps = {
        type: 'default',
        animate: true,
    };

    constructor() {
        super();
        this.state = {
            isAnimatingOut: false,
        };
    }

    shouldComponentUpdate(nextProps: Props, nextState: State) {
        return (
            nextProps.children !== this.props.children ||
            nextProps.type !== this.props.type ||
            (nextState.isAnimatingOut && !this.state.isAnimatingOut)
        );
    }

    componentDidMount() {
        // If there is no duration, we show a close button for manually closing
        // the banner.
        if (this.props.duration) {
            // Automatically animate out after N millseconds duration.
            this.waitForDuration(this.props.duration).then(() => {
                this.setState({isAnimatingOut: true});
            });
        }
    }

    UNSAFE_componentWillMount() {
        this.children = this.addCloseToOnClick(this.props.children);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.children !== this.props.children) {
            this.children = this.addCloseToOnClick(nextProps.children);
        }
    }

    componentWillUnmount() {
        this.onAnimationComplete();
    }

    render() {
        const {hasBorder, isOverlay, duration, onHidden} = this.props;
        const classes = classnames({
            container: true,
            'container--absolute': isOverlay,
        });

        const showCloseButton = !duration && onHidden;
        const variants = hasBorder
            ? {
                  visible: {height: 'auto', marginTop: 4, marginBottom: 4},
                  hidden: {height: 0, marginTop: 0, marginBottom: 0},
              }
            : {
                  visible: {height: 'auto'},
                  hidden: {height: 0},
              };

        return (
            <motion.div
                className={this.props.className}
                styleName={classes}
                variants={variants}
                initial={this.props.animate ? 'hidden' : 'visible'}
                exit='hidden'
                animate={this.state.isAnimatingOut && this.props.animate ? 'hidden' : 'visible'}
                transition={{type: 'tween', duration: ANIMATE_OUT_DURATION_SEC, ease: 'easeInOut'}}
                onAnimationComplete={this.onAnimationComplete}
            >
                <Message
                    onClose={
                        showCloseButton
                            ? () => {
                                  this.handleClose();
                              }
                            : undefined
                    }
                    type={this.props.type}
                    hasBorder={this.props.hasBorder}
                    leftAlignContent={this.props.leftAlignContent}
                    noRightPadding={this.props.noRightPadding}
                >
                    {this.children}
                </Message>
            </motion.div>
        );
    }

    waitForDuration = (duration: number = ANIMATE_OUT_DURATION): Promise<void> => {
        clearTimeout(this.delay);

        return new Promise((resolve) => {
            this.delay = setTimeout(resolve, duration);
        });
    };

    onAnimationComplete = () => {
        if (this.state.isAnimatingOut) {
            this.setState({isAnimatingOut: false});
            if (this.props.onHidden) this.props.onHidden();
        }
    };

    // This is returning a promise, so that augmented `onClick` props in children
    // can fire after the animate out is finished
    handleClose = () => {
        if (!this.props.animate && this.props.onHidden) {
            this.props.onHidden();

            return;
        }

        this.setState({
            isAnimatingOut: true,
        });

        // We just want to return a promise that resolves when the animation finishes.
        // ANIMATE_OUT_DURATION is an estimate.
        return this.waitForDuration(ANIMATE_OUT_DURATION);
    };

    /*
     * Searches through children to find onClick props, and augment them
     * with a call to this.handleClose, to hide the flash banner.
     *
     * This is useful for example in error messages with a "retry" link.
     */
    addCloseToOnClick = (children: React.Node) => {
        return React.Children.map(children, (child) => {
            // I don't have a fucking clue how this could be null, but I guess
            // it can be.
            if (child && child.props && child.props.onClick) {
                const originalOnClick = child.props.onClick;
                const onClickWithClose = (e) => {
                    originalOnClick(e, this.handleClose);
                };

                return React.cloneElement(child, {onClick: onClickWithClose});
            }
            if (child && child.props && child.props.children) {
                return React.cloneElement(child, {
                    children: this.addCloseToOnClick(child.props.children),
                });
            }

            return child;
        });
    };
}
