/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {FormField} from 'shells/form';
import type {EntityFormType} from '../../../creator/types';
import {AccountsSelect} from './accounts-select';
import {ContactsSelect} from './contacts-select';
import {ParticipantsSelect} from './participants-select';

type Props = {
    ...FieldProps,
    title?: string,
    helperText?: string,
    isRequired?: boolean,
    isMulti: boolean,
    isReadOnly?: boolean,
    validate: Function,
    entityType: EntityFormType,
};

export function EntitySelectField(props: Props) {
    const {title, helperText, isRequired, validate, entityType, ...restProps} = props;
    const getComponent = () => {
        switch (entityType) {
            case 'contacts':
                return ContactsSelect;
            case 'accounts':
                return AccountsSelect;
            case 'participants':
                return ParticipantsSelect;
            default:
                return ContactsSelect;
        }
    };

    const Component = getComponent();

    return (
        <FormField
            innerId={props.input.name}
            label={title}
            helperText={helperText}
            errorMessage={props.meta.error && props.meta.submitFailed ? props.meta.error : null}
            isRequired={isRequired}
            validate={validate}
        >
            <Component {...restProps} />
        </FormField>
    );
}
