import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import FormPen from './icon-form-pen.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <FormPen {...validProps} />;
}
SvgComponent.iconName = 'formPen';

export const FormPenIcon = svgIconWrapper(SvgComponent);
