import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Fx from './icon-fx.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Fx {...validProps} />;
}
SvgComponent.iconName = 'fx';

export const FxIcon = svgIconWrapper(SvgComponent);
