/* @flow */

import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';
import type {ReportRowData, TimeseriesReportData, ListItem} from 'nutshell-core/types';

import * as actions from './audiences-actions';

type AudienceMemberListState = {
    isLoading: boolean,
    isFirstLoad: boolean,
    errorMessage?: string,
    listItems: Object[],
    meta: Object,
};

const audienceMemberListDefaultState = {
    isLoading: true,
    isFirstLoad: true,
    errorMessage: undefined,
    listItems: [],
    meta: {},
};

const isFilterDrawerOpen = createReducer(
    {
        [actions.toggleShowFilterDrawer]: (state) => !state,
    },
    false
);

export const audienceMemberListReducer = createReducer(
    {
        // $FlowIgnore - this works correctly from redux-act
        [actions.requestAudienceMemberListData]: (state) => {
            return {...state, isLoading: true, errorMessage: undefined};
        },
        [actions.updateAudienceMemberListData]: (state, {meta, listItems}) => {
            return {
                ...state,
                isLoading: false,
                isFirstLoad: false,
                meta: meta,
                listItems: listItems
                    ? listItems.map((item: ListItem) => {
                          return {
                              ...item,
                              id: item.links ? item.links.entity.id : item.id,
                          };
                      })
                    : undefined,
                errorMessage: undefined,
            };
        },
        [actions.failAudienceMemberListData]: (state, err) => {
            return {...state, isLoading: false, isFirstLoad: false, errorMessage: err};
        },
    },
    audienceMemberListDefaultState
);

type AudienceMembershipReportTableData = {
    rows: ?(ReportRowData[]),
    totals: ?ReportRowData,
};
type AudienceMembershipReportState = {
    timeseriesChart: {
        isLoading: boolean,
        isTimedOut: boolean,
        isErrored: boolean,
        chartData: ?TimeseriesReportData,
        prefix: string,
    },
};
const audienceMembershipReportDefaultState = {
    timeseriesChart: {
        isLoading: true,
        isTimedOut: false,
        isErrored: false,
        chartData: null,
        prefix: '$',
    },
};

const audienceMembershipReportReducer = createReducer(
    {
        [actions.updateAudienceMembershipReportTableData]: (
            state,
            payload: AudienceMembershipReportTableData
        ) => {
            return {...state, tableData: payload};
        },
        // $FlowIgnore - this works correctly from redux-act
        [actions.requestAudienceMembershipReportData]: (state) => {
            const newChartState = {
                ...state.timeseriesChart,
                isLoading: true,
                isTimedOut: false,
                isErrored: false,
            };

            return {...state, timeseriesChart: newChartState};
        },
        [actions.updateAudienceMembershipReportChartData]: (state, {chartData}) => {
            const newChartState = {
                ...state.timeseriesChart,
                isLoading: false,
                isTimedOut: false,
                isErrored: false,
                chartData,
            };

            return {...state, timeseriesChart: newChartState};
        },
        [actions.failAudienceMembershipReportChartData]: (state, err) => {
            if (err) {
                if (err.status === 504 || err.status === 502) {
                    const newChartState = {
                        ...state.timeseriesChart,
                        chartData: null,
                        isLoading: false,
                        isTimedOut: true,
                        isErrored: false,
                    };

                    return {...state, timeseriesChart: newChartState};
                } else {
                    const newChartState = {
                        ...state.timeseriesChart,
                        chartData: null,
                        isLoading: false,
                        isTimedOut: false,
                        isErrored: true,
                    };

                    return {...state, timeseriesChart: newChartState};
                }
            }

            return state;
        },
    },
    audienceMembershipReportDefaultState
);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const audiencesReducer = combineReducers({
    audienceMemberList: audienceMemberListReducer,
    audienceMembershipReport: audienceMembershipReportReducer,
    isFilterDrawerOpen,
});

export type AudiencesState = {
    audienceMemberList: AudienceMemberListState,
    audienceMembershipReport: AudienceMembershipReportState,
    isFilterDrawerOpen: boolean,
};
