/* @flow */

import React from 'react';

import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';
import LocationPinSVG from './icon-location-pin.svg';

type Props = {
    color?: string,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <LocationPinSVG {...validProps} color={props.color || colors.black} />;
}
SvgComponent.iconName = 'locationPin';

export const LocationPinIcon = svgIconWrapper(SvgComponent);
