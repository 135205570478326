/* @flow */

import {useGetLimitsByType, getUsageFromLimits} from '../../../../limits';
import type {MonthlyUsage} from '../../../../limits/get-usage-from-limits';

export const useGetPowerAiSummarizationMonthlyUsage = (): {
    monthlyUsage: ?MonthlyUsage,
    isLoading: boolean,
    error?: boolean,
} => {
    const {isLoading, limits, errorMessage} = useGetLimitsByType('AI_TOKENS');

    const monthlyUsage = getUsageFromLimits(limits);

    return {
        monthlyUsage,
        isLoading,
        error: Boolean(errorMessage || (!isLoading && !monthlyUsage)),
    };
};
