/* @flow */

import {formEncodedApi} from 'nutshell-core/api';
import type {GetTeams_teams as Team} from 'nutshell-graphql-types';
import {mapEntityToDisplayOption} from 'nutshell-core/utils';

const MAX_SERVER_RESULTS = 25;

export function getEntities(query: string, teams: ?(Team[])): Promise<Object> {
    // Filter out teams that don't match the query and return remaining teams in mentions format
    const filteredTeams = teams
        ? teams
              .filter((team) => {
                  const queryLength = query.length;

                  return team.name.slice(0, queryLength).toLowerCase() === query.toLowerCase();
              })
              .map((team) => ({...team, value: team.name, description: '', type: 'team'}))
        : [];

    const data = {
        rows: MAX_SERVER_RESULTS,
        fields: [
            {
                name: 'name',
                value: query,
            },
        ],
    };

    return formEncodedApi
        .post('/solr/find-mentionable-entities.json', {
            ...data,
        })
        .then((response) => response.json())
        .then((response: {results: any[]}) => {
            const results: any[] = response.results ? response.results : [];

            return results
                .map((entity) => {
                    return mapEntityToDisplayOption(entity);
                })
                .filter((entity) => entity.value)
                .concat(filteredTeams);
        });
}
