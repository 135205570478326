/* @flow */

import {createReducer} from 'redux-act';
import {combineReducers} from 'redux';
import type {ReportRowData, ComparedActivityReportSummary} from 'nutshell-core/types';

import type {ActivityReportPjaxResponseType} from '../report-types';
import * as actions from './activity-report-actions';

type State = {
    data: ?string,
    hasError: boolean,
    isLoading: boolean,
    numPages: ?number,
};
const defaultState = {
    data: null,
    hasError: false,
    isLoading: true,
    numPages: null,
};

type SummaryState = {
    hasError: boolean,
    isLoading: boolean,
    data: ?ComparedActivityReportSummary<Array<ReportRowData>>,
    totals: ?ComparedActivityReportSummary<ReportRowData>,
};
const defaultSummaryState = {
    ...defaultState,
    totals: null,
};

export const activitiesReducer = createReducer(
    {
        [actions.requestActivityReportActivities]: (state) => {
            return {...state, isLoading: true, hasError: false};
        },
        [actions.updateActivityReportActivities]: (
            state,
            response: ActivityReportPjaxResponseType
        ) => {
            return {
                ...state,
                data: response.table,
                numPages: response.numPages,
                isLoading: false,
                hasError: false,
            };
        },
        [actions.failActivityReportActivities]: (state) => {
            return {...state, isLoading: false, hasError: true};
        },
    },
    defaultState
);

export const summaryReducer = (
    state: SummaryState = defaultSummaryState,
    action: actions.ActivityReportAction
): SummaryState => {
    switch (action.type) {
        case actions.ActionTypes.ACTIVITY_REPORT_FETCH_SUMMARY_REQUEST:
            return {...state, isLoading: true};
        case actions.ActionTypes.ACTIVITY_REPORT_FETCH_SUMMARY_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                totals: action.payload.totals,
                isLoading: false,
                hasError: false,
            };
        case actions.ActionTypes.ACTIVITY_REPORT_FETCH_SUMMARY_FAILURE:
            return {...state, isLoading: false, hasError: true};
        default:
            return state;
    }
};

type LeadOutcomesState = ?{
    lostOrCancelled: number,
    won: number,
    unknown: number,
};

// TODO: clean up action type
export const leadOutcomesReducer = (
    state: LeadOutcomesState = null,
    action: {type: string, payload: Object}
): LeadOutcomesState => {
    switch (action.type) {
        case 'REPORT_ACTIVITY_SUMMARY_STATS_UPDATED':
            return action.payload.leadOutcomes;
        case 'REPORTS_LEADS_REPORT_DATA_REQUESTED':
            return null;
        default:
            return state;
    }
};

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const activityReportReducer = combineReducers({
    activities: activitiesReducer,
    summary: summaryReducer,
    leadOutcomes: leadOutcomesReducer,
});

export type ActivityReportState = {
    detail: State,
    activities: State,
    summary: SummaryState,
    leadOutcomes: LeadOutcomesState,
};
