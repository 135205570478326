/* @flow */

import * as React from 'react';

export type Props = {
    name: string,
    selectedValues: string[],
    onChange: (newValue: string[]) => void,
    children: React.Node,
};

// $FlowIgnore it doesn't know about context yet
export const CheckboxGroupContext = React.createContext('checkboxGroup');

export class CheckboxGroup extends React.Component<Props> {
    render() {
        const {name, onChange, selectedValues} = this.props;

        return (
            <CheckboxGroupContext.Provider value={{name, onChange, selectedValues}}>
                <div className='font-size-normal'>{this.props.children}</div>
            </CheckboxGroupContext.Provider>
        );
    }
}
