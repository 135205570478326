import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox='0 0 12.5 14'>
            <path d='M12.5,0H0V14H1V7H12.5v.12l-2-3.62Z' />
        </svg>
    );
}
SvgComponent.iconName = 'activity-important-icon';

export const ActivityImportantIcon = svgIconWrapper(SvgComponent);
