import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Email from './icon-email.svg';

SvgComponent.prototype.defaultProps = {
    fill: '#211712',
};

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Email {...validProps} />;
}
SvgComponent.iconName = 'email';

export const EmailIcon = svgIconWrapper(SvgComponent);
