/* @flow */

import type {DashType} from './types';

export const ERROR_TYPE_FETCHING = 'fetching';
export const ERROR_TYPE_DELETING = 'deleting';

export const ACTIVITIES = 'ACTIVITIES';
export const FEED = 'FEED';
export const TODOS = 'TODOS';

export type ActivitiesTabKey = 'ACTIVITIES';
export type TodosTabKey = 'TODOS';
export type FeedTabKey = 'FEED';

const dashConstants = {
    leaderboard: {
        entityName: 'leaderboards',
        entityIsArray: false,
        label: 'Leaderboard',
        isConfigurable: true,
        isPro: false,
        byline: "Who's winning the most sales or completing the most activities",
        defaults: {
            name: 'New Leaderboard',
            value: 'type=sales&period=mtd',
        },
        obfuscationMessage: '',
        isDisabled: false,
        reportType: 'dashboard_leaderboard',
        endpoint: 'dashboards/leaderboard',
    },
    quotas: {
        entityName: 'dashboardQuotas',
        entityIsArray: true,
        label: 'Quotas',
        isConfigurable: true,
        isPro: true,
        isDisabled: false,
        obfuscationMessage: 'Quotas are only available to Nutshell Pro customers',
        byline: 'Individual or team performance against sales or activity goals',
        defaults: {
            name: 'New Quotas',
            value: '',
        },
        reportType: 'dashboard_quotas',
        endpoint: 'dashboards/quotas',
    },
    metrics: {
        entityName: 'dashboardMetrics',
        entityIsArray: true,
        label: 'Metrics',
        isConfigurable: true,
        isPro: false,
        byline: 'A quick look at new leads, your current pipeline, and sales',
        defaults: {
            name: 'New Metrics',
            value: 'period=mtd',
        },
        obfuscationMessage: '',
        isDisabled: false,
        reportType: 'dashboard_metrics',
        endpoint: 'dashboards/metrics',
    },
    list: {
        entityName: 'lists',
        entityIsArray: true,
        label: 'List',
        isConfigurable: true,
        isPro: false,
        byline: 'Preview any saved list of leads, companies, or people',
        defaults: {
            name: 'All leads',
            value: 'listId=1000002-filters',
        },
        obfuscationMessage: '',
        isDisabled: false,
        reportType: 'dashboard_list',
        endpoint: 'dashboards/lists',
    },
    listSummaries: {
        entityName: 'listSummaries',
        entityIsArray: true,
        label: 'List Summary',
        isConfigurable: true,
        isPro: false,
        byline: 'Total value or count of saved lists of leads',
        defaults: {
            name: 'My favorite lead lists',
            value: 'listIds=1000032-filters,1000042-filters',
        },
        obfuscationMessage: '',
        isDisabled: false,
        reportType: 'dashboard_listSummaries',
        endpoint: 'dashboards/listsummaries',
    },
    // TODO: Rename the 'funnel' dash to 'pipeline' at the model/data/code
    // layer. We have already renamed all of the user-facing stuff from Funnel to
    // Pipeline, but it would be nice to change the front-end dashes code to
    // match the change since we might one day actually add a funnel card. We don't
    // want a name collision, and we don't want the funnel name to propogate
    // and add confusion. _But_, it's tough to confidently rename this code since
    // there are a few places where we're building up a 'funnel' name constant at
    // runtime (failing the grep test), and we have a few hardcoded 'funnel'
    // strings. So, leaving a note here.
    // See https://nutshell.atlassian.net/browse/NUT-3826
    funnel: {
        entityName: 'funnel',
        entityIsArray: true,
        label: 'Pipeline',
        isConfigurable: true,
        isPro: false,
        byline: 'Quantity and value of open leads in each stage',
        defaults: {
            value: 'period=-rd30',
            name: 'Pipeline',
        },
        obfuscationMessage: '',
        isDisabled: false,
        reportType: 'dashboard_funnel',
        endpoint: 'dashboards/openleads',
    },
    onboarding: {
        reportType: 'dashboard_onboarding',
    },
    iframe: {
        reportType: 'dashboard_iframe',
    },
};

export function getDashDefaults(dashType: DashType) {
    if (!dashType) return undefined;

    return dashConstants[dashType].defaults;
}

export function getDashTypeByEntityName(entityName: string) {
    return Object.keys(dashConstants).filter((type) => {
        return dashConstants[type].entityName && dashConstants[type].entityName === entityName;
    })[0];
}

export function getDashTypeByReportType(reportType: string) {
    return Object.keys(dashConstants).filter((type) => {
        return dashConstants[type].reportType === reportType;
    })[0];
}

export function getDashReportType(dashType: DashType) {
    if (!dashType) return undefined;

    return dashConstants[dashType].reportType;
}

export function getDashLabel(dashType: DashType) {
    return dashConstants[dashType].label;
}

export function getDashByline(dashType: DashType) {
    if (!dashType) return undefined;

    return dashConstants[dashType].byline;
}

export function getDashIsPro(dashType: DashType) {
    if (!dashType) return false;

    return dashConstants[dashType].isPro;
}

export function getDashTypes() {
    // $FlowFixMe upgrading Flow to v0.92.1 on web
    return Object.keys(dashConstants);
}

export function getDashEntityName(dashType: DashType) {
    return dashConstants[dashType].entityName;
}

export function getDashEndpoint(dashType: DashType) {
    return dashConstants[dashType].endpoint;
}

export function getDashEntityIsArray(dashType: DashType) {
    if (!dashType) return undefined;

    return dashConstants[dashType].entityIsArray;
}

export function getDashIsConfigurable(dashType: DashType): boolean {
    if (!dashType) return false;

    return dashConstants[dashType].isConfigurable && !getDashIsGated(dashType);
}

export function getDashIsDisabled(dashType: DashType) {
    if (!dashType) return undefined;

    return Boolean(dashConstants[dashType].isDisabled) || getDashIsGated(dashType);
}

export function getDashIsGated(dashType: DashType) {
    if (!dashType) return undefined;

    return !NutClientConfig.hasQuotas && dashType === 'quotas';
}

export function getDashObfuscationMessage(dashType: DashType) {
    if (!dashType) return '';

    return dashConstants[dashType].obfuscationMessage;
}

export function getIsDashSpecialCard(type: string) {
    return ['onboarding', 'iframe', 'special-card'].includes(type);
}
