import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ZoomText from './icon-zoom-text.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ZoomText {...validProps} />;
}
SvgComponent.iconName = 'zoomText';

export const ZoomTextIcon = svgIconWrapper(SvgComponent);
