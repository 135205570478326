/* @flow */

import * as React from 'react';

import {Popover} from 'shells/popover';

import {PowerAiUsagePopoverContent} from '../power-ai/power-ai-usage-popover/power-ai-usage-popover-content';

import {useGetSessionUser} from '../core/use-get-session-user';

type Props = {
    anchor: {current: any},
    isFirstTranscription: boolean,
    onConfirm: () => void,
    isOutOfCredits: boolean,
    monthlyUsage: ?{
        limit: ?number,
        remaining: number,
    },
};

export function TranscribeInfoPopover(props: Props) {
    const {user} = useGetSessionUser();

    const shouldShowBillingLink = user && user.permissions.canAccessBilling && props.isOutOfCredits;

    return (
        <Popover
            location='top-left'
            anchor={props.anchor.current}
            overlayStyles={{display: 'none'}}
        >
            {props.isFirstTranscription && !shouldShowBillingLink ? (
                <PowerAiUsagePopoverContent
                    title='Let Nutshell do the typing'
                    body='Use Notetaker to assist you in transcribing messages. Tap the button, start speaking your notes, and Nutshell will do the typing.'
                    monthlyUsage={null}
                    shouldShowBillingLink={false}
                    isTooltip={false}
                    acknowledgment={{
                        onClick: props.onConfirm,
                    }}
                />
            ) : null}
            {!props.isFirstTranscription && shouldShowBillingLink ? (
                <PowerAiUsagePopoverContent
                    title='Let Nutshell do the typing'
                    body='Your Notetaker free preview for this month has expired. Upgrade to a Business plan or come back next month!'
                    monthlyUsage={props.monthlyUsage}
                    shouldShowBillingLink={true}
                    isTooltip={false}
                />
            ) : null}
        </Popover>
    );
}
