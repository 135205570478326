/* @flow */

import * as React from 'react';
import {UpgradeArrowIcon} from '../../icon';

import './upsell-button.css';

type Props = {
    onClick: () => void,
    text: string,
};

export function UpsellButton(props: Props) {
    return (
        <button onClick={props.onClick} styleName='upsell-button'>
            <span>{props.text}</span>
            <UpgradeArrowIcon wrapWithDiv={false} size={14} />
        </button>
    );
}
