/* @flow */

import * as React from 'react';

import {IconButton} from 'shells/button';
import {CogIcon} from 'shells/icon';
import {colors} from 'shells/colors';

import './modal-action-button.css';

type Props = {
    onClick: () => void,
    tooltipText: string,
};

export function ModalActionButton(props: Props) {
    return (
        <div styleName='action-button'>
            <IconButton
                icon={() => <CogIcon size={24} color={colors.grey} />}
                onClick={props.onClick}
                noHoverBackground={true}
                tooltipText={props.tooltipText}
            />
        </div>
    );
}
