/* @flow */

import * as React from 'react';
import {colors} from '../colors';

type Props = {
    size: number,
    color?: string,
};

export function LoadingCircle(props: Props) {
    const computedStyles = {
        height: props.size,
        width: props.size,
        borderRadius: props.size / 2,
        backgroundColor: props.color || colors.offWhiteDk,
    };

    return <div style={computedStyles} />;
}
