/* @flow */

import * as React from 'react';
import type {FormattedValue} from 'nutshell-core/types';

import './lead-formatted-value.css';

type Props = {
    formattedValue: FormattedValue,
};

export function LeadFormattedValue(props: Props) {
    const {formattedValue} = props;

    return (
        <span styleName='formatted-value'>
            <span styleName='prefix'>{formattedValue.prefix}</span>
            <span styleName='value'>{formattedValue.formatted}</span>
            <span styleName='suffix'>{formattedValue.suffix}</span>
        </span>
    );
}
