import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import LinkClick from './icon-link-click.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <LinkClick {...validProps} />;
}
SvgComponent.iconName = 'click';

export const LinkClickIcon = svgIconWrapper(SvgComponent);
