import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import List from './icon-list.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <List {...validProps} />;
}
SvgComponent.iconName = 'list';

export const ListIcon = svgIconWrapper(SvgComponent);
