/* @flow */

export const ActionTypes = {
    VISITORS_SIDEBAR_CLOSED: 'VISITORS_SIDEBAR_CLOSED',
    VISITORS_SIDEBAR_OPENED: 'VISITORS_SIDEBAR_OPENED',
};

type VisitorsSidebarClosedAction = {type: 'VISITORS_SIDEBAR_CLOSED'};
type VisitorsSidebarOpenedAction = {
    type: 'VISITORS_SIDEBAR_OPENED',
    payload: {
        visitorId: string,
    },
};

export type VisitorsSidebarAction = VisitorsSidebarOpenedAction | VisitorsSidebarClosedAction;

/*
 * Action creators
 */
export const closeSidebar = (): VisitorsSidebarClosedAction => ({
    type: ActionTypes.VISITORS_SIDEBAR_CLOSED,
});

export const openSidebar = (visitorId: string): VisitorsSidebarOpenedAction => ({
    type: ActionTypes.VISITORS_SIDEBAR_OPENED,
    payload: {visitorId},
});
