/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import CalendarPen from './icon-calendar-pen.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <CalendarPen {...validProps} />;
}
SvgComponent.iconName = 'calendar-pen';

export const CalendarPenIcon = svgIconWrapper(SvgComponent);
