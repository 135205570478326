/* @flow */

import React from 'react';

import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';
import AccountChildOverlaySVG from './icon-account-child-overlay.svg';

type Props = {
    color?: string,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <AccountChildOverlaySVG {...validProps} color={props.color || colors.company} />;
}
SvgComponent.iconName = 'accountChildOverlay';

export const AccountChildOverlayIcon = svgIconWrapper(SvgComponent);
