/* @flow */

import type {ReactionFragment as Reaction, CommentFragment} from 'nutshell-graphql-types';
import {
    type Reactions,
    type Reaction as ReactionType,
    hasUserReacted,
} from 'shells/timeline/reaction-bar';

import type {Comment} from 'shells/timeline/entry/comments/types';

import type {ReactionToggleAction} from './reaction-mutations';

/**
 * Filters array of Reactions into an object keyed by reaction type containing a list
 * of reacting users.
 *
 * @param reactions
 * @returns Reactions
 */
export function getFilteredReactions(reactions: Reaction[]): Reactions {
    const filteredReactions = {};

    reactions.forEach((reaction) => {
        const reactingUser = {id: reaction.creatorUser.id, name: reaction.creatorUser.name};

        if (filteredReactions[reaction.reactionType]) {
            // Reaction type has already been keyed, push reacting user to array
            filteredReactions[reaction.reactionType].push(reactingUser);
        } else {
            // Add reaction type as a key and create array with first reacting user
            filteredReactions[reaction.reactionType] = [reactingUser];
        }
    });

    return filteredReactions;
}

/**
 * Returns the toggle action when a user clicks a reaction button.
 *
 * @param reaction: Reaction, reactions: Reactions, currentUserId: string
 * @returns ReactionToggleAction
 */
export function getToggleAction(
    reaction: ReactionType,
    reactions: Reactions,
    currentUserId: string
): ReactionToggleAction {
    if (reactions[reaction]) {
        return hasUserReacted(reactions[reaction], currentUserId) ? 'delete' : 'create';
    }

    return 'create';
}

/**
 * Takes GraphQl Comment fragment type with unfiltered reactions and returns the filtered type expected
 * by shells components.
 *
 * @param comments
 */
export function getCommentsWithFilteredReactions(comments: CommentFragment[]): Comment[] {
    return comments.map((comment: CommentFragment) => {
        const filteredCommentReactions = getFilteredReactions(comment.reactions);

        return {
            id: comment.id,
            creatorUser: comment.creatorUser,
            bodyHtml: comment.bodyHtml,
            isDeleteable: comment.isDeleteable,
            createdTime: comment.createdTime,
            changeLogId: comment.changeLogEntry
                ? // The comment changeLogEntry could potentially be undefined, but that should never happen
                  comment.changeLogEntry.id
                : undefined,
            reactions: filteredCommentReactions,
        };
    });
}
