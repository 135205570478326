/* @flow */

import * as React from 'react';

import type {EventFragmentSparse} from 'nutshell-graphql-types';
import {getSingularEntityType} from 'nutshell-core/utils/get-singular-entity-type';
import {getApiTypeFromGraphQLTypename} from 'nutshell-core/utils';

import {LinkedEntity} from 'shells/linked-entity';

import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {|event: EventFragmentSparse|};

export function TimelineBannerEntityModified(props: Props) {
    const {event} = props;

    const entityType = getApiTypeFromGraphQLTypename(event.payload.__typename);
    const noun = getSingularEntityType(entityType);

    const getIconVariant = (entity, changeType) => {
        switch (entity) {
            case 'accounts':
                return changeType === 'DELETED' ? 'company-deleted' : 'company-created';
            case 'contacts':
            default:
                return changeType === 'DELETED' ? 'person-deleted' : 'person-created';
        }
    };

    let verb;
    switch (event.changeType) {
        case 'DELETED':
            verb = 'deleted';
            break;
        case 'UNDELETED':
            verb = 'restored';
            break;
        case 'ADDED':
        default:
            verb = 'added';
    }

    const action = (
        <span>
            {verb} a {noun}:&nbsp;
            <span styleName='target'>
                <LinkedEntity
                    type={entityType}
                    useEntityColor={true}
                    shouldClientRoute={true}
                    allowWrap={true}
                    displayInline={true}
                    {...event.payload}
                />
            </span>
        </span>
    );

    return (
        <TimelineBanner
            id={event.id}
            iconVariant={getIconVariant(entityType, event.changeType)}
            timestamp={event.changeTime}
            actor={event.actor}
            action={action}
        />
    );
}
