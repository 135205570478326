/* @flow */

import * as React from 'react';
import {FieldArray} from 'redux-form';

import {FormField} from 'shells/form';

import type {Market} from 'nutshell-core/types';
import {ProductSelect} from './product-select';
import {MarketField} from './market-field';

import './product-select-field.css';

type Props = {
    fieldKey: string,
    title: string,
    isRequired: boolean,
    validate: Function,
    currentMarket: ?Market,
};

export function ProductSelectField(props: Props) {
    return (
        <FormField
            key={props.fieldKey}
            innerId={props.fieldKey}
            label={props.title}
            isRequired={props.isRequired}
            validate={props.validate}
        >
            <div styleName='container'>
                {NutClientConfig.hasMultipleMarkets ? (
                    <div styleName='market'>
                        <MarketField />
                    </div>
                ) : (
                    undefined
                )}
                <FieldArray
                    name={props.fieldKey}
                    component={ProductSelect}
                    isRequired={props.isRequired}
                    autoFocus={false}
                    shouldAnd={true}
                    {...props}
                />
            </div>
        </FormField>
    );
}
