/* @flow */

import * as React from 'react';
import {Session} from 'nutshell-core/session';
import {TextField} from '../textfield/index';

type Props = {
    /** The format type of the input field, currency or number */
    formatType?: 'currency' | 'number' | 'float' | 'percent',
    /** Will default to 0 unless `clearable` is `true` */
    value: ?number,
    /** A new value will be provided when the field is blurred or `enter` is pressed */
    onChange: (?number) => void,
    /** ISO 4217 currency code. Will pull instance default if not provided */
    currencyCode?: string,
    /** If `true`, then value can be `null` */
    clearable?: boolean,
    /** Only shown in `clearable` is `true`, otherwise clearing the value turns it into `0` displayed as currency.  If not provided and clearable is set, we'll show the currency code followed by ellipsis. */
    placeholder?: string,
    /** Overrides the user's machine settings, if provided */
    locale?: string,
    /** If provided, this is called in addition to `onChange` */
    onEnter?: (SyntheticKeyboardEvent<>) => void,
    /** If provided, will be called when component blurs */
    onBlur?: () => void,
    /** if provided, will set the min value of the input */
    min?: number,
    /** if provided, will set the max value of the input */
    max?: number,
};
type State = {
    display: string,
};

/**
 * Use this to collect a currency value from the user as a number, but display it as a currency
 * using a currency code and formatting in the correct locale based on either the user's local
 * machine or an overridden locale.
 */
export class FormattedNumberField extends React.PureComponent<Props, State> {
    textField: ?HTMLInputElement;

    static defaultProps = {
        formatType: 'currency',
        currencyCode: Session.getInstanceDefaultCurrencyCode(),
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            display:
                props.clearable && (props.value === null || typeof props.value === 'undefined')
                    ? ''
                    : this.formatNumber(props.value || 0),
        };
    }

    render() {
        const {
            value, // eslint-disable-line no-unused-vars
            onChange, // eslint-disable-line no-unused-vars
            clearable, // eslint-disable-line no-unused-vars
            locale, // eslint-disable-line no-unused-vars
            onEnter, // eslint-disable-line no-unused-vars
            formatType,
            currencyCode,
            placeholder,
            min,
            max,
            ...restProps
        } = this.props;

        return (
            <TextField
                {...restProps}
                min={min ? String(min) : undefined}
                max={max ? String(max) : undefined}
                style={{
                    textAlign: 'right',
                    paddingRight: formatType === 'percent' ? '24px' : undefined,
                }}
                textFieldRef={(n) => {
                    this.textField = n;
                }}
                value={this.state.display}
                placeholder={placeholder || `${currencyCode || 'Enter a number'}…`}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
            />
        );
    }

    /*
     * Select the text within the input.  It needs to be wrapped in a setImmediate
     * or else it has no effect.
     */
    selectInput = () => {
        setImmediate(() => {
            if (this.textField) this.textField.select();
        });
    };

    /*
     * Apply the formatting specified by locale prop (or user's machine settings,
     * if the prop isn't provided), using the currency code provided (currencyCode),
     * or the user's instance's default market.
     */
    formatNumber = (newValue: string | number): string => {
        // If we have a string that's a currency, we wanna strip out the currency symbol
        // and all the comas so we can parse it as a number correctly
        let value = newValue;
        if (typeof newValue === 'string' && this.props.formatType === 'currency') {
            // if the first character is a currency symbol, remove it
            value = newValue.replace(/^[^0-9]+/, '');
            // replace all comas
            value = value.replace(/,/g, '');
        }

        return Number(value).toLocaleString(
            this.props.locale,
            this.props.formatType === 'currency'
                ? {
                      style: 'currency',
                      currency: this.props.currencyCode,
                  }
                : undefined
        );
    };

    /**
     * This will apply the min and max values to the input field.  If the value
     * is less than the min, it will set the value to the min.  If the value is
     * greater than the max, it will set the value to the max.
     */
    applyMinAndMax = (value: string) => {
        const NumberValue = Number(value);
        let newValue = value;
        if (this.props.min !== undefined && NumberValue < this.props.min) {
            newValue = String(this.props.min);
        }
        if (this.props.max !== undefined && NumberValue > this.props.max) {
            newValue = String(this.props.max);
        }
        this.updateValue(newValue);
    };

    /*
     * This will remove any special formatting, so the user only needs to type
     * in a number, and all the special formatting doesn't get in the way.  It
     * will also select the existing text in the input when they focus it.
     */
    handleFocus = () => {
        if (this.props.value !== null && typeof this.props.value !== 'undefined') {
            this.setState({display: String(this.props.value)});
            // If we have a min or max, we need to apply it here (for display purposes)
            this.applyMinAndMax(String(this.props.value));
        }
        this.selectInput();
    };

    /*
     * We do two main things when the field is blurred:
     *
     * 1. Display the value formatted correctly given the user's locale and the currencyCode.
     * 2. Send the new value in the `onChange` callback.
     *
     * If the user somehow entered an invalid (non-number) value, we'll just reset
     * to the old `value` prop, or if that's null we'll reset to zero.
     *
     * If the user cleared the field out, we will either display a formatted `0`,
     * or clear the field, depending on the `clearable` prop.
     */
    handleBlur = (e: SyntheticInputEvent<>) => {
        const rawValue = e.target.value;
        this.updateValue(rawValue);
        // If we have a min or max, we need to apply it here
        this.applyMinAndMax(rawValue);
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    };

    /*
     * Usually we'll just need to update the displayed value, but if `clearable`
     * is not true, then we'll show a zero (and focus it for convenience).
     */
    handleChange = (newValue: string) => {
        const value = newValue === '' && !this.props.clearable ? '0' : newValue;
        this.setState({display: value});

        if (newValue === '' && !this.props.clearable) {
            // If they're not allowed to clear it out, treat clearing as setting to zero
            this.selectInput();
        }

        this.updateValue(value, false);
    };

    updateValue = (value: string, shouldFormat: boolean = true) => {
        if (isNaN(value)) {
            // Abort the edit and reset
            const display = this.formatNumber(this.props.value || 0);
            this.setState({display});
        } else if (!value && this.props.clearable) {
            // Need to clear out the value
            this.setState({display: ''});
            this.props.onChange(null);
        } else if (shouldFormat) {
            const display = this.formatNumber(value);
            this.setState({display});
        } else {
            this.setState({display: value});
            this.props.onChange(Number(value));
        }
    };

    /*
     * We only want to allow numeric keypresses, and we're using a `type='text'`
     * input field so that we can display things like currency values.  So, this
     * will just check for a number (0-9), an `Enter` keypress (which blurs the field),
     * or a single decimal.  Commas and multiple decimals are not allowed.
     */
    handleKeyPress = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
        const isEnter = e.key === 'Enter';

        if (isEnter) {
            e.currentTarget.blur();

            if (this.props.onEnter) {
                this.props.onEnter(e);
            }

            return;
        }

        const isDot = e.key === '.';
        const isValid =
            (e.charCode >= 48 && e.charCode <= 57) ||
            (isDot &&
                !this.state.display.includes('.') &&
                isDot &&
                this.props.formatType !== 'number');

        if (!isValid) {
            e.preventDefault();
            e.stopPropagation();
        }
    };
}
