/* @flow */

import {createAction} from 'redux-act';
import type {
    ComparedActivityReportSummary,
    ComparisonRangeType,
    ReportRowData,
} from 'nutshell-core/types';

export const ActionTypes = {
    EMAIL_REPORT_FETCH_SUMMARY_REQUEST: 'EMAIL_REPORT_FETCH_SUMMARY_REQUEST',
    EMAIL_REPORT_FETCH_SUMMARY_SUCCESS: 'EMAIL_REPORT_FETCH_SUMMARY_SUCCESS',
    EMAIL_REPORT_FETCH_SUMMARY_FAILURE: 'EMAIL_REPORT_FETCH_SUMMARY_FAILURE',
};

type SummaryRequestPayload = {filters: Object, comparisonType: ?ComparisonRangeType};
type SummaryPayload = {
    data: ComparedActivityReportSummary<Array<ReportRowData>>,
    totals: ComparedActivityReportSummary<ReportRowData>,
};
export type EmailReportSummaryRequestAction = {
    type: 'EMAIL_REPORT_FETCH_SUMMARY_REQUEST',
    payload: SummaryRequestPayload,
};
export type EmailReportSummarySuccessAction = {
    type: 'EMAIL_REPORT_FETCH_SUMMARY_SUCCESS',
    payload: SummaryPayload,
};
export type EmailReportSummaryFailureAction = {
    type: 'EMAIL_REPORT_FETCH_SUMMARY_FAILURE',
    error: true,
    payload: ?mixed,
};

export type EmailReportAction =
    | EmailReportSummaryRequestAction
    | EmailReportSummarySuccessAction
    | EmailReportSummaryFailureAction;

/*------------------------------------------------------------------------------
 Action Creators
 ------------------------------------------------------------------------------*/
export const requestEmailReportActivities = createAction('EMAIL_REPORT_ACTIVITIES_REQUESTED');
export const updateEmailReportActivities = createAction('EMAIL_REPORT_ACTIVITIES_UPDATED');
export const failEmailReportActivities = createAction('EMAIL_REPORT_ACTIVITIES_FAILED');

export const requestEmailReportSummary = (
    payload: SummaryRequestPayload
): EmailReportSummaryRequestAction => ({type: 'EMAIL_REPORT_FETCH_SUMMARY_REQUEST', payload});
export const resolveEmailReportSummary = (
    payload: SummaryPayload
): EmailReportSummarySuccessAction => ({type: 'EMAIL_REPORT_FETCH_SUMMARY_SUCCESS', payload});
export const failEmailReportSummary = (err: mixed): EmailReportSummaryFailureAction => ({
    type: 'EMAIL_REPORT_FETCH_SUMMARY_FAILURE',
    payload: err,
    error: true,
});
