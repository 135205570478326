import moment from 'moment';

/**
 * Check if a date is today
 *
 * @param  {[type]}  targetDate        Moment date object to check
 * @param  {[type]}  [today=moment()]  Moment date object for today's datetime
 * @return {Boolean}                   Is the target date today?
 */
export function isToday(targetDate, today = moment()) {
    return targetDate.isSame(today, 'day');
}
