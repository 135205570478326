/* @flow */

import {reducer} from './app-reducer';
import * as selectors from './app-selectors';
import * as actions from './app-actions';
import * as types from './app-types';

export const App = {
    ...actions,
    ...selectors,
    ...types,
    reducer,
};

export type {AppState} from './app-reducer';
