import {refetchActivity, refetchTodo} from './ui/master-dashboard/dashboard-actions';
import {openModalForType} from './ui/limits/limits-actions';

const $ = window.jQuery;

/**
 * Semi-hacky function to collate legacy event listeners that we care about and
 * dispatch actions accordingly. Since this is rendered on the store, this works
 * at the global level and attempts to keep our store in sync.
 * @param  {Store} store Our Redux already-configured store
 * @return {void}
 */
export function listenToLegacyEvents(store) {
    /**
     * Async action creator that accepts an eventName and an action
     * to eventually fire once the window sees that event fired.
     * @param  {string} name             Legacy event name
     * @param  {function} mapEventToAction redux async action to
     *                                     fire when we hear the event
     *                                     on the window
     * @return {function}                  We'll return an function that allows
     *                                     us to unsubscribe from the event if
     *                                     we want.
     */
    function listenToLegacyEvent(name, mapEventToAction) {
        return function(dispatch) {
            function handleEvent(e, data) {
                // Since our payloads are different shapes, we need to transform
                // them before dispatching our action
                const payload = transformDataToPayload(data, name);
                if (payload) {
                    dispatch(mapEventToAction(payload));
                }
            }

            // Where we actually start listening to the legacy event
            $(window).on(name, handleEvent);

            // We'll return this function so that we have the ability to unsubscribe
            // from the events
            return () => $(window).off(name, handleEvent);
        };
    }

    /**
     * Helper function to transform legacy payloads into useable data.
     * @param  {object|string} data      data to transform
     * @param  {string} eventName        eventName that dictates transformation
     * @return {string}           Final transformed payload for consumption
     */
    function transformDataToPayload(data, eventName) {
        if (eventName === 'entityWasDeleted' && data.entityName === 'Activities') {
            return `${data.entityId}-activities`;
        } else if (eventName === 'DidSubmitSuccessfully') {
            return `${data}-activities`;
        } else if (eventName === 'TaskDidSubmitSuccessfully') {
            return `${data}-tasks`;
        } else if (eventName === 'StepDidSubmitSuccessfully' || eventName === 'limitWasExceeded') {
            // Returning just `data` here because the piece of data sent by
            // our legacy event is already an API-id, so no reason to manually
            // manipulate it here
            return data;
        }
    }

    // This is where we create our listeners that eventually dispatch actions
    //
    // Activity.events.DidSubmitSuccessfully
    store.dispatch(listenToLegacyEvent('DidSubmitSuccessfully', refetchActivity));
    store.dispatch(listenToLegacyEvent('TaskDidSubmitSuccessfully', refetchTodo));
    store.dispatch(listenToLegacyEvent('StepDidSubmitSuccessfully', refetchTodo));
    store.dispatch(listenToLegacyEvent('limitWasExceeded', openModalForType));

    // Nut.events.global.entityWasDeleted
    // NOTE: this event is not activity specific, so we need to ensure we're
    // transforming our payload accordingly
    store.dispatch(listenToLegacyEvent('entityWasDeleted', refetchActivity));
}
