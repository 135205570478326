import PropTypes from 'prop-types';
import * as React from 'react';
import getClasses from '../mixins/get-classes';

export class FeatureLabel extends React.Component {
    static propTypes = {
        type: PropTypes.oneOf(['default', 'light']),
    };

    static defaultProps = {
        type: 'default',
    };

    getClasses = getClasses;

    render() {
        const {type} = this.props;

        let imageSource = 'badge-pro.svg';
        if (type === 'light') {
            imageSource = 'badge-pro-light.svg';
        }

        const classes = this.getClasses('feature-pro-badge', {
            'feature-pro-badge--light': type === 'light',
        });

        return <img className={classes} src={`/include/images/plan-pro/${imageSource}`} />;
    }
}
