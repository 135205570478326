/* @flow */

import {useQuery} from '@apollo/react-hooks';

import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import type {
    GetAccountTypes_accountTypes as AccountType,
    GetAccountTypes as GetAccountTypesQuery,
} from 'nutshell-graphql-types';

import GET_ACCOUNT_TYPES from '../queries/get-account-types.graphql';

export const useGetAccountTypes = (): {
    isLoading: boolean,
    errorMessage: ?string,
    accountTypes: AccountType[],
} => {
    const {loading, data, error} = useQuery<GetAccountTypesQuery, {}>(GET_ACCOUNT_TYPES, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
    });

    return {
        isLoading: loading,
        errorMessage: safelyGetGraphQLErrorMessage(error),
        accountTypes: data && data.accountTypes ? data.accountTypes : [],
    };
};
