/* @flow */

import * as React from 'react';
import './label.css';

type Props = {
    children?: React.Node,
};

/*
 * Component used when a label and label styling is desired, but using
 * an actual <label> breaks HTML accessibility standards
 */
export class PseudoLabel extends React.PureComponent<Props> {
    render() {
        return <span styleName='pseudo-label'>{this.props.children}</span>;
    }
}
