/* @flow */
import * as React from 'react';
import {Field, type FieldProps, type FieldArrayProps} from 'redux-form';

import {Button} from '../../button';
import * as Divider from '../../divider';
import {ErrorMessage} from '../../form';

import {OptionCardField} from './option-card-field';

import './option-card-builder-field.css';

export type CardData = {
    topRow: string,
    bottomRow?: string,
};

type Props<T> = {
    hideAddButton?: boolean,
    addText?: string,
    divider: 'Or' | 'And',
    cardCreator: ({
        meta: $PropertyType<FieldProps, 'meta'>,
        input: $PropertyType<FieldProps, 'input'>,
        onRemove: () => any,
    }) => any,
    getCardData: (T) => CardData,
} & FieldArrayProps;

export function OptionCardBuilderField<T>(props: Props<T>) {
    const showError = Boolean(props.meta.submitFailed && props.meta.error);
    const selected = props.fields.getAll() || [];

    const CardDivider = props.divider === 'And' ? Divider.And : Divider.Or;

    return (
        <div>
            <ul styleName='option-card-builder-field-list'>
                {props.fields.map((item, index) => {
                    return (
                        <li key={item}>
                            {index > 0 ? <CardDivider /> : null}
                            <Field
                                name={item}
                                isCancelable={index > 0}
                                component={OptionCardField}
                                cardCreator={props.cardCreator}
                                getCardData={props.getCardData}
                                onRemove={() => {
                                    props.fields.remove(index);

                                    if (props.fields.length <= 1) {
                                        props.fields.push(null);
                                    }
                                }}
                            />
                        </li>
                    );
                })}
            </ul>
            {selected[selected.length - 1] && !props.hideAddButton ? (
                <Button variant='text-primary' onClick={() => props.fields.push(null)}>
                    {props.addText ? props.addText : '+ Add another'}
                </Button>
            ) : null}
            {showError ? (
                <div styleName='error'>
                    {/* $FlowFixMe upgrading Flow to v0.92.1 on web */}
                    <ErrorMessage>{props.meta.error}</ErrorMessage>
                </div>
            ) : null}
        </div>
    );
}
