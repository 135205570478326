/* @flow */

import * as React from 'react';
import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';

type Props = {
    fill?: string,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} fill={props.fill || colors.grey} viewBox='0 0 22 22'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7.522 15.887v1.874c.078-.064.16-.124.242-.178.537-.355 1.297-.613 2.233-.852h.002l.003-.001a4.738 4.738 0 0 0 1.07-.432l-.11-.957.395-.03v-.39a3.492 3.492 0 0 1-.123-.476c-.443-.321-.627-.858-.654-1.408V13.024a1.068 1.068 0 0 0-.044-.216l-.014-.05a4.025 4.025 0 0 1-.105-.464 1.68 1.68 0 0 1 .1-.898c.025-.061.053-.12.085-.176l-.162-.437-.016-.085.933-.174-.933.174v-.003l-.001-.004-.002-.011-.007-.04a9.605 9.605 0 0 1-.079-.595 7.806 7.806 0 0 1-.024-1.396c.04-.478.157-1.133.551-1.64.132-.17.296-.325.495-.446V2.294h.652V1H.506v1.294h.626l.03 14.887a1.297 1.297 0 0 0 1.296 1.294h2.508v-2.588c0-.358.29-.648.649-.648h1.259c.358 0 .648.29.648.648Zm2.722 1.811c.415-.103.806-.27 1.113-.423.393-.196.65-.372.65-.372.13-.086.065-.646.065-.646l.84-.064s.128.086 0-.839c0 0-.71-.193-.754-1.656 0 0-.537.172-.58-.71-.007-.18-.053-.351-.098-.516-.105-.386-.198-.73.27-1.032l-.344-.925s-.023-.124-.05-.324v.001c-.054-.427-.121-1.199 0-1.838v.001c.133-.693.487-1.23 1.319-1.001-.818-.99 4.666-1.957 5.032 1.161 0 0 .258 1.678 0 2.839 0 0 .796-.107.258 1.44 0 0-.301 1.12-.753.861 0 0 .065 1.398-.645 1.635 0 0 .043.752.043.795l.688.108s-.107.624.022.688c0 0 .796.56 1.763.796 1.85.473 2.474.903 2.474 1.613 0 0 .193.107.215 1.269H7.578c0-1.162.193-1.248.193-1.248 0-.328.133-.596.459-.836.379-.28 1.019-.522 2.014-.777ZM3.058 4.236a.648.648 0 0 0-.648.649v1.939c0 .358.29.648.648.648h1.899c.358 0 .648-.29.648-.648v-1.94a.648.648 0 0 0-.648-.648H3.058Zm0 5.178a.648.648 0 0 0-.648.648v1.94c0 .358.29.648.648.648h1.899c.358 0 .648-.29.648-.648v-1.94a.648.648 0 0 0-.648-.648H3.058Zm4.474-5.178a.648.648 0 0 0-.649.649v1.939c0 .358.29.648.649.648H9.43c.358 0 .649-.29.649-.648v-1.94a.648.648 0 0 0-.649-.648H7.532Zm0 5.178a.648.648 0 0 0-.649.648v1.94c0 .358.29.648.649.648H9.43c.358 0 .649-.29.649-.648v-1.94a.648.648 0 0 0-.649-.648H7.532Z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'name';

export const NameIcon = svgIconWrapper(SvgComponent);
