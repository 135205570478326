/* @flow */

import {combineReducers} from 'redux';
import {ActionTypes, type AppAction} from './app-actions';

/*
 * Changes to this number indicate a breaking change in our API, requiring a refresh of the page before
 * further requests can be made.
 */
type ApiVersionState = $PropertyType<AppState, 'apiVersion'>;
const apiVersionReducer = (state: ApiVersionState = null, action: AppAction): ApiVersionState => {
    switch (action.type) {
        case ActionTypes.APP_SET_API_VERSION:
            return action.payload;
        default:
            return state;
    }
};

/*
 * Here is where we will store the application number from the backend.  This number auto-increments
 * each time we cut a release
 */
type AppVersionState = $PropertyType<AppState, 'appVersion'>;
const appVersionReducer = (state: AppVersionState = null, action: AppAction): AppVersionState => {
    switch (action.type) {
        case ActionTypes.APP_SET_APP_VERSION:
            return action.payload;
        default:
            return state;
    }
};

export type AppState = {
    apiVersion: ?string,
    appVersion: ?string,
};

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const reducer = combineReducers({
    apiVersion: apiVersionReducer,
    appVersion: appVersionReducer,
});
