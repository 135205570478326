/* @flow */

import * as React from 'react';

import {HelpButton} from 'shells/help';

import {OpenableModals, useOpenableModal} from '../../openable-modals';

export function AttributionReportDescription() {
    const {onOpen} = useOpenableModal(OpenableModals.ATTRIBUTION_ONBOARDING_MODAL);

    return (
        <div className='flex'>
            Where do our best leads come from?
            <HelpButton onClick={onOpen} />
        </div>
    );
}
