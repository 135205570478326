/* @flow */

import * as React from 'react';
import {components, type OptionProps} from 'react-select';

import {Avatar} from 'shells/avatar';

import {useGetParticipants} from './hooks/use-get-participants';
import {GraphQLSelectInline} from './graphql-select-inline';

import './graphql-select-options.css';

type Option = {id: string, name: string};

type Props = {
    selectedValues: Option[],
    onChange: (values: Option | Option[]) => any,
    onClearValue?: () => void,
    isMulti: boolean,
    clearable?: boolean,
};

export function GraphQLSelectParticipants(props: Props) {
    const {getParticipants} = useGetParticipants();

    const handleSearch = (search: string) => {
        return getParticipants(search);
    };

    return (
        <GraphQLSelectInline
            placeholder='Type to search for participants…'
            value={props.selectedValues}
            multi={props.isMulti}
            onClearValue={props.onClearValue}
            loadOptions={(search: ?string) => {
                return handleSearch(search || '').then((data) => {
                    return data || [];
                });
            }}
            onChange={props.onChange}
            onBlur={() => {
                props.onChange(props.selectedValues);
            }}
            noOptionsMessage={({inputValue}) => {
                if (inputValue.length) {
                    return `Nothing found matching '${inputValue}'`;
                }

                if (props.selectedValues.length) {
                    return 'Type to search for participants…';
                }

                return null;
            }}
            components={{Option: OptionComponent}}
            labelKey='name'
            valueKey='id'
            clearable={props.clearable}
            shouldStyleValueByType={true}
        />
    );
}

const OptionComponent = (props: OptionProps) => {
    // This is a hack to get around limitations in our old version of flow
    const spreadableProps: $Exact<OptionProps> = (props: any);

    return (
        // $FlowFixMe upgrading Flow to v0.92.1 on web
        <components.Option {...spreadableProps}>
            <div styleName='option-wrapper'>
                <Avatar
                    avatarUrl={props.data.avatarUrl}
                    initials={props.data.initials}
                    type={props.data.type}
                />
                <div styleName='option-label'>{props.data.name}</div>
            </div>
        </components.Option>
    );
};
