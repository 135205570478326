/* @flow */

import {useQuery} from '@apollo/react-hooks';

import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import type {
    GetTerritories_territories_edges_node as Territory,
    GetTerritories as GetTerritoriesQuery,
} from 'nutshell-graphql-types';

import GET_TERRITORIES from '../queries/get-territories.graphql';

export const useGetTerritories = (): {
    isLoading: boolean,
    errorMessage: ?string,
    territories: Territory[],
} => {
    const {loading, data, error} = useQuery<GetTerritoriesQuery, {}>(GET_TERRITORIES, {
        notifyOnNetworkStatusChange: true,
    });

    return {
        isLoading: loading,
        errorMessage: safelyGetGraphQLErrorMessage(error),
        territories:
            data && data.territories && data.territories.edges
                ? data.territories.edges.map((edge) => edge.node)
                : [],
    };
};
