/* @flow */

import * as React from 'react';
import './modal-body.css';

type Props = {
    children?: React.Node,
    as: string,
};

ModalBody.defaultProps = {
    as: 'span',
};

export function ModalBody({children, as: Tag}: Props) {
    return <Tag styleName='modal-body'>{children}</Tag>;
}
