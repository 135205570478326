import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'52 -7.8 52 60.5'}>
            <path d='M84 24.3v20.6c0 .7-.5 1.5-1 1.8l-10 5.9c-.6.3-1 .1-1-.6V24.3C64.8 17.1 53.5 5.3 52.2 1c.1.3-.2-.3-.2-.8 0-3.9 12.2-8 26-8s26 4.1 26 8c0 .5-.3 1.1-.2.8-1.3 4.3-12.6 16.1-19.8 23.3z' />
        </svg>
    );
}
SvgComponent.iconName = 'filter';

export const FilterIcon = svgIconWrapper(SvgComponent);
