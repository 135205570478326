/* @flow */

import * as React from 'react';
import {UP, DOWN, ESC, RETURN} from '../utils/keys';
import {ListView, type SpecialState} from './list-view';

export type Row = {
    rowItem: any,
    shouldDisableSelection?: boolean,
};

type Props = {
    onClose: () => void,
    onSelect: (row: any) => void,
    specialStates?: SpecialState[],
    collection: Row[],
    renderRow: (row: any, isSelected: boolean) => React.Element<*>,
    shouldfadeBottom?: boolean,
};

type State = {
    focusIndex: number,
};

/**
This is a generic, building-block component used to render a list of
items, that can be navigated with the arrow and enter keys.
An array of items is specified as a `row`, containing the row
item and an option of if the row is selectable with the arrow keys, and each
item in the array is mapped into a `renderRow` function.

Finally, an array of `specialStates` can be provided.  These must
be objects with both a `shouldRender` function that returns true
or false, and a `component` prop which is a react element to display
if the `shouldRender` evaluates to `true`.
*/
export class ListViewScrollable extends React.Component<Props, State> {
    list: ?HTMLElement;

    static defaultProps = {
        onClose: () => {},
        onSelect: () => {},
    };

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeydownEvent);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydownEvent);
    }

    constructor() {
        super();
        this.state = {
            // This is set to a negative value initally just so we don't have
            // an initial selection.
            focusIndex: -1,
        };
    }

    handleKeydownEvent = (e: SyntheticKeyboardEvent<*>) => {
        const {collection, onClose, onSelect} = this.props;

        if (e.which === UP) this.scroll(e, -1);
        else if (e.which === DOWN) this.scroll(e, 1);
        else if (e.which === ESC) onClose();
        else if (e.which === RETURN && !e.metaKey && !e.ctrlKey && collection.length) {
            e.preventDefault();
            onSelect(
                collection[this.state.focusIndex] ? collection[this.state.focusIndex].rowItem : null
            );
        }
    };

    scroll(e: SyntheticEvent<*>, delta: number) {
        e.preventDefault();
        e.stopPropagation();

        // We shouldn't be calling this function with 0. But just in case...
        if (delta === 0) {
            return;
        }

        this.setState((prevState) => {
            const scrollableListLength = this.props.collection.length;
            let newFocusIndex = this.determineNextFocusIndex(
                prevState.focusIndex,
                delta,
                scrollableListLength
            );

            // Skip any non-selectable rows
            while (this.props.collection[newFocusIndex].shouldDisableSelection) {
                newFocusIndex = this.determineNextFocusIndex(
                    newFocusIndex,
                    delta,
                    scrollableListLength
                );
            }

            return {
                focusIndex: newFocusIndex,
            };
        });

        this.calculateAndScroll();
    }

    determineNextFocusIndex(first: number, second: number, mod: number): number {
        return (((first + second) % mod) + mod) % mod;
    }

    render() {
        return (
            <ListView
                getListViewRef={(c) => {
                    this.list = c;
                }}
                shouldfadeBottom={this.props.shouldfadeBottom}
                collection={this.props.collection}
                specialStates={this.props.specialStates}
                renderRow={this.renderRow}
            />
        );
    }

    renderRow = (item: Row) => {
        const {renderRow, collection} = this.props;

        return renderRow(item.rowItem, item === collection[this.state.focusIndex]);
    };

    // This will move the scroll position of the list to keep the selected item in view.
    // It's not terribly performant, though, and could/should be optimized.
    calculateAndScroll() {
        if (!this.props.collection.length || !this.list) return;

        const results = this.list.querySelector('ul');
        if (!results) return;

        const scrollParent = getScrollParent(results);
        const listItem = results.children[this.state.focusIndex];
        const itemHeight =
            listItem.offsetHeight ||
            (listItem.firstChild instanceof HTMLElement ? listItem.firstChild.offsetHeight : 0);
        // Don't hide rows in the bottom fade
        const clientHeight = scrollParent.clientHeight - (this.props.shouldfadeBottom ? 64 : 0);

        // If we've gone too far off the bottom, scroll down a bit
        if (listItem.offsetTop > scrollParent.scrollTop + clientHeight - itemHeight) {
            scrollParent.scroll({
                top: listItem.offsetTop + itemHeight - clientHeight,
            });
        }

        // If we've gone too far up, scroll up to show the item
        if (listItem.offsetTop < 0 || listItem.offsetTop < scrollParent.scrollTop) {
            scrollParent.scroll({top: listItem.offsetTop});
        }

        this.forceUpdate();
    }
}

/*
This was taken from https://github.com/nutshellcrm/react-select/blob/209929b230e6d7d30cc0daf6daec30447cc4c3c5/src/utils.js#L127
 */
export function getScrollParent(element: Element): Element {
    let style = getComputedStyle(element);
    const excludeStaticParent = style.position === 'absolute';
    const overflowRx = /(auto|scroll)/;
    const docEl = ((document.documentElement: any): Element); // suck it, flow...

    if (style.position === 'fixed') return docEl;

    for (let parent = element; (parent = parent.parentElement); ) {
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === 'static') {
            // eslint-disable-next-line no-continue
            continue;
        }
        if (overflowRx.test(style.overflow + style.overflowY + style.overflowX)) {
            return parent;
        }
    }

    return docEl;
}
