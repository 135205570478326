/* @flow */

import {createSelector, type OutputSelector} from 'reselect';
import type {Market} from '../types';
import {Collections, type CollectionState} from '../collections';

type MarketsModuleState = {markets: CollectionState<Market>};

/*
 * Given the entire redux state, pull out just the piece of state for the markets reducer.
 */
export const getMarketsState = (state: MarketsModuleState) => state.markets;

/*
 * Get an array of all market models
 */
export const getAll: OutputSelector<*, *, Market[]> = Collections.makeGetModels(getMarketsState);

/**
 * Gets the default market for the current logged in user. Gaurentees to return
 * something - will return a stubbed U.S. market if no markets currently exist
 * in the store, as Nutshell gaurentees at least one "system" market exists
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getDefaultMarket = createSelector([getAll], (markets): ?Market => {
    const defaultMarket = markets.find((market) => market.isDefault) || markets[0];

    return defaultMarket;
});

/*
 * Get the `byId` portion of the markets state
 */
export const getById = (state: MarketsModuleState) => {
    const marketsState = getMarketsState(state);

    return Collections.getById(marketsState);
};
