/* @flow */
'no babel-plugin-flow-react-proptypes';

import * as React from 'react';
import ReactDOM from 'react-dom';

type Props<T> = {
    waitForMount?: boolean,
    ...React.ElementConfig<T>,
};

type State = {
    isMounted: boolean,
};

export function portal<T: React.ComponentType<*>>(WrappedComponent: T) {
    return class extends React.Component<Props<T>, State> {
        node: HTMLElement;
        modalRoot: ?HTMLElement;

        constructor(props: Props<T>) {
            super(props);

            // Create a div to render into
            this.node = document.createElement('div');
            this.node.className = 'portal';

            this.modalRoot = document.getElementById('modal-root');
            if (!this.modalRoot) {
                const newRoot = document.createElement('div');
                newRoot.id = 'modal-root';
                if (document.body) {
                    document.body.appendChild(newRoot);
                    this.modalRoot = newRoot;
                }
            }
            this.state = {
                isMounted: false,
            };
        }

        componentDidMount() {
            if (this.modalRoot) {
                this.modalRoot.appendChild(this.node);
                // eslint-disable-next-line react/no-did-mount-set-state
                this.setState({isMounted: true});
            }
        }

        componentWillUnmount() {
            if (this.modalRoot && this.node) {
                try {
                    this.modalRoot.removeChild(this.node);
                } catch (e) {
                    // not handling error
                }
            }
        }

        render() {
            const {waitForMount, ...componentProps} = this.props;
            // We need to ensure that the portal has been mounted if `waitForMount` is true
            // See https://github.com/reactjs/reactjs.org/pull/275/commits/bca474ddda28307e9a04e0af6f64677505115fd6#r150401648
            if (waitForMount && !this.state.isMounted) return null;

            return ReactDOM.createPortal(<WrappedComponent {...componentProps} />, this.node);
        }
    };
}
