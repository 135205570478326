/* @flow */

import * as React from 'react';

import {GraphQLSelectAudiences} from '../../select/graphql-select-audiences';
import {GraphQLSelectContacts} from '../../select/graphql-select-contacts';
import {GraphQLSelectAccounts} from '../../select/graphql-select-accounts';
import {GraphQLSelectTagsOld} from '../../select/graphql-select-tags-old';

export type CreateableType = 'tags' | 'audiences' | 'contacts' | 'accounts';

export const idRegex = /^[0-9]+-(tags|contacts|accounts|emAudiences)$/i;

export const getAddAnotherText = (type: CreateableType): string => {
    let typeName;
    switch (type) {
        case 'tags':
            typeName = 'tag';
            break;
        case 'contacts':
            typeName = 'person';
            break;
        case 'accounts':
            typeName = 'company';
            break;
        default:
            typeName = 'audience';
    }

    return `+ Add another ${typeName}`;
};

export const getComponent = (type: CreateableType): React.ComponentType<*> => {
    let component;
    switch (type) {
        case 'tags':
            component = GraphQLSelectTagsOld;
            break;
        case 'contacts':
            component = GraphQLSelectContacts;
            break;
        case 'accounts':
            component = GraphQLSelectAccounts;
            break;
        default:
            component = GraphQLSelectAudiences;
    }

    return component;
};
