import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import PlusThin from './icon-plus-thin.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <PlusThin {...validProps} />;
}
SvgComponent.iconName = 'browser';

export const PlusThinIcon = svgIconWrapper(SvgComponent);
