/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import './empty-add-card.css';

type Props = {
    onClick: () => void,
    text: string,
    background: 'white' | 'off-white',
    size: 'small' | 'large',
    withoutIcon?: boolean,
    color?: string,
    isLoading?: boolean,
    height?: number,
};

export function EmptyAddCard(props: Props) {
    const styleNames = classnames({
        'empty-add-card': true,
        'off-white': props.background === 'off-white',
        'variant-small': props.size === 'small',
    });

    const additionalStyles = {
        color: props.color,
        borderColor: props.color,
        height: props.height ? `${props.height}px` : undefined,
    };

    return props.isLoading ? (
        <div
            styleName={
                props.background === 'white' ? 'loading-placeholder--white' : 'loading-placeholder'
            }
        />
    ) : (
        <button styleName={styleNames} style={additionalStyles} onClick={props.onClick}>
            {props.withoutIcon ? null : <div styleName='add-card-icon'>+</div>}
            <div styleName='add-card-label'>{props.text}</div>
        </button>
    );
}
