import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Messages from './icon-messages.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Messages {...validProps} />;
}
SvgComponent.iconName = 'messages';

export const MessagesIcon = svgIconWrapper(SvgComponent);
