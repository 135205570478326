/* @flow */

// Moment sometimes returns `Today` or `Tomorrow` or `Yesterday`, but we don't
// always want them capitalized.
export function uncapitalizeMomentString(dateString: string) {
    if (!dateString || typeof dateString !== 'string') return dateString;

    return dateString
        .replace('Today', 'today')
        .replace('Tomorrow', 'tomorrow')
        .replace('Yesterday', 'yesterday');
}
