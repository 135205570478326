/* @flow */

import * as React from 'react';

import {useRefObserver} from '../utils/hooks/use-ref-observer';

type Props = {|
    buttonRef: any,
    defaultContent: React.Node,
    hoverContent: React.Node,
    forceDefault?: boolean,
|};

export function DynamicButtonContent(props: Props) {
    const {isHovered, isFocused} = useRefObserver(
        props.buttonRef,
        ['isHovered', 'isFocused'],
        props.forceDefault
    );

    return isFocused || isHovered ? props.hoverContent : props.defaultContent;
}
