import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import NextStepIconSvg from './icon-next-step.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <NextStepIconSvg {...validProps} />;
}
SvgComponent.iconName = 'nextStepIcon';

export const NextStepIcon = svgIconWrapper(SvgComponent);
