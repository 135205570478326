/* @flow */

import type {
    DueDateUnit,
    MaterializedEmailSequence as MaterializedEmailSequenceFragment,
} from 'nutshell-graphql-types';
import type {EmailAddressee} from 'nutshell-core/types';
import type {File} from '../../../filestack-file-picker';
import type {Template, TemplateGroup} from '../templates/template-groups/types';

export type EmailComposerFormTemplate = {
    position: number,
    id: string,
    subject?: string,
    body?: string,
    plaintextBody?: string,
    delay?: ?{|
        timespan: number,
        unit: DueDateUnit,
    |},
    expectedSendTime?: number,
    sendAsReply?: boolean,
    missingFieldsMessage?: string,
    // This key will only exist if the user
    // has added attachments whilst sending
    fileAttachments?: File[],
};

export type EmailComposerFormSequenceState = {
    id: string,
    name: string,
    isShared: boolean,
    creatorUserId: ?string,
    emailTemplates: {[templateId: string]: EmailComposerFormTemplate},
    isMaterialized: boolean,
};

export type EmailComposerFormState = {
    fromAddress: Object,
    toAddresses: {value: EmailAddressee}[],
    reply?: {
        toAddresses: {value: EmailAddressee}[],
        replyAllAddresses: {value: EmailAddressee}[],
        isReplyAll: boolean,
    },
    ccAddresses: {emailAddress: string, display: string}[],
    sequences: {[sequenceId: string]: EmailComposerFormSequenceState},
    relatedLeadIds: string[],
};

export type OnSubmitArgs = {
    form: EmailComposerFormState,
    sequenceId: string,
    shouldNotify: boolean,
};

export type InexactMaterializedEmailSequenceFragment = {...MaterializedEmailSequenceFragment};

export const CREATE_FROM_SCRATCH_ID = 'create-from-scratch';

export const FORM_NAME = 'email-drip-form';

export type EmailComposerFormStateNew = {
    fromAddress: Object,
    toAddresses: {value: EmailAddressee}[],
    reply?: {
        toAddresses: {value: EmailAddressee}[],
        replyAllAddresses: {value: EmailAddressee}[],
        isReplyAll: boolean,
    },
    ccAddresses: {emailAddress: string, display: string}[],
    sequences: {[sequenceId: string]: EmailComposerFormSequenceState},
    templateGroups: TemplateGroup[],
    uncategorizedTemplates: Template[],
    recentlySentTemplates: Template[],
    relatedLeadIds: string[],
};
