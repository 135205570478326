/* @flow */

import * as React from 'react';

import {TruncatedGroup} from '../../../limited-group';
import {useResizeObserver} from '../../../utils/hooks';

import type {Participant} from '../../types';

type Props = {|
    participants: Participant[],
    containerRef: {current: null | HTMLElement},
    actorVerbRef: {current: null | HTMLElement},
    showEmailAddressTooltip?: boolean,
|};

export function TimelineHeaderParticipants(props: Props) {
    const {participants, actorVerbRef, containerRef, showEmailAddressTooltip} = props;

    const [containerWidth, setContainerWidth] = React.useState<number>(0);
    const [maxWidthOfParticipants, setMaxWidthOfParticipants] = React.useState<number>(0);

    React.useEffect(() => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.getBoundingClientRect().width);
        }
    }, [containerRef]);

    // Observe any resizing of the window and adjust width accordingly
    const [width] = useResizeObserver(containerRef.current);

    React.useEffect(() => {
        if (typeof width === 'number') {
            setContainerWidth(width);
        }
    }, [width]);

    // Set maxWidthOfParticipants to containerWidth - actorVerbWidth
    React.useEffect(() => {
        const actorVerbWidth = actorVerbRef.current
            ? // Use boundingClientRect + 16px buffer
              actorVerbRef.current.getBoundingClientRect().width + 16
            : undefined;

        if (containerWidth && actorVerbWidth) {
            setMaxWidthOfParticipants(containerWidth - actorVerbWidth);
        }
    }, [containerWidth, actorVerbRef]);

    // Wait until the maxWidth has been calculated before rendering TruncatedGroup
    return maxWidthOfParticipants ? (
        <TruncatedGroup
            maxWidth={Math.abs(maxWidthOfParticipants)}
            participants={participants}
            showEmailAddressTooltip={showEmailAddressTooltip}
        />
    ) : null;
}
