/* @flow */

import type {ActivitiesTabKey, TodosTabKey} from '../master-dashboard/dashboard-constants';

// Action Types
export type SidebarAction =
    | {type: 'SIDEBAR_CALENDAR_ACTIVE_VIEW_UPDATED', payload: ActivitiesTabKey | TodosTabKey}
    | {type: 'SIDEBAR_CALENDAR_DISPLAY_DATE_UPDATED', payload: number};

export const ActionTypes = {
    SIDEBAR_CALENDAR_ACTIVE_VIEW_UPDATED: 'SIDEBAR_CALENDAR_ACTIVE_VIEW_UPDATED',
    SIDEBAR_CALENDAR_DISPLAY_DATE_UPDATED: 'SIDEBAR_CALENDAR_DISPLAY_DATE_UPDATED',
};

// Actions
export const updateSidebarCalendarActiveView = (
    activeView: ActivitiesTabKey | TodosTabKey
): SidebarAction => {
    return {type: ActionTypes.SIDEBAR_CALENDAR_ACTIVE_VIEW_UPDATED, payload: activeView};
};

export const updateSidebarCalendarDisplayDate = (newDate: number): SidebarAction => {
    return {type: ActionTypes.SIDEBAR_CALENDAR_DISPLAY_DATE_UPDATED, payload: newDate};
};
