/**
 * Helper to generate dynamic "select" list for Nutshell-supported "states"
 * Taken from Nut_PostalAddress_Parser
 */

import PropTypes from 'prop-types';

import * as React from 'react';
import {Select} from './select';
import {COUNTRIES_WITH_STATES, US_STATES_FOR_PROSPECTOR} from './select-constants';

export const US_STATES = [
    {id: 'AL', name: 'Alabama'},
    {id: 'AK', name: 'Alaska'},
    {id: 'AS', name: 'American Samoa'},
    {id: 'AZ', name: 'Arizona'},
    {id: 'AR', name: 'Arkansas'},
    {id: 'CA', name: 'California'},
    {id: 'CO', name: 'Colorado'},
    {id: 'CT', name: 'Connecticut'},
    {id: 'DE', name: 'Delaware'},
    {id: 'DC', name: 'District of Colombia'},
    {id: 'FM', name: 'Federated States of Micronesia'},
    {id: 'FL', name: 'Florida'},
    {id: 'GA', name: 'Georgia'},
    {id: 'GU', name: 'Guam'},
    {id: 'HI', name: 'Hawaii'},
    {id: 'ID', name: 'Idaho'},
    {id: 'IL', name: 'Illinois'},
    {id: 'IN', name: 'Indiana'},
    {id: 'IA', name: 'Iowa'},
    {id: 'KS', name: 'Kansas'},
    {id: 'KY', name: 'Kentucky'},
    {id: 'LA', name: 'Louisiana'},
    {id: 'ME', name: 'Maine'},
    {id: 'MH', name: 'Marshall Islands'},
    {id: 'MD', name: 'Maryland'},
    {id: 'MA', name: 'Massachusetts'},
    {id: 'MI', name: 'Michigan'},
    {id: 'MN', name: 'Minnesota'},
    {id: 'MS', name: 'Mississippi'},
    {id: 'MO', name: 'Missouri'},
    {id: 'MT', name: 'Montana'},
    {id: 'NE', name: 'Nebraska'},
    {id: 'NV', name: 'Nevada'},
    {id: 'NH', name: 'New Hampshire'},
    {id: 'NJ', name: 'New Jersey'},
    {id: 'NM', name: 'New Mexico'},
    {id: 'NY', name: 'New York'},
    {id: 'NC', name: 'North Carolina'},
    {id: 'ND', name: 'North Dakota'},
    {id: 'MP', name: 'Northern Mariana Islands'},
    {id: 'OH', name: 'Ohio'},
    {id: 'OK', name: 'Oklahoma'},
    {id: 'OR', name: 'Oregon'},
    {id: 'PW', name: 'Palau'},
    {id: 'PA', name: 'Pennsylvania'},
    {id: 'PR', name: 'Puerto Rico'},
    {id: 'RI', name: 'Rhode Island'},
    {id: 'SC', name: 'South Carolina'},
    {id: 'SD', name: 'South Dakota'},
    {id: 'TN', name: 'Tennessee'},
    {id: 'TX', name: 'Texas'},
    {id: 'UT', name: 'Utah'},
    {id: 'VT', name: 'Vermont'},
    {id: 'VI', name: 'Virgin Islands'},
    {id: 'VA', name: 'Virginia'},
    {id: 'WA', name: 'Washington'},
    {id: 'WV', name: 'West Virginia'},
    {id: 'WI', name: 'Wisconsin'},
    {id: 'WY', name: 'Wyoming'},
    {id: 'AE', name: 'Armed Forces Middle East'},
    {id: 'AA', name: 'Armed Forces America'},
    {id: 'AP', name: 'Armed Forces Pacific'},
];

const STATE_SELECT_VALUES = {
    CA: [
        {id: '*', name: 'Any state'},
        {id: 'AB', name: 'Alberta'},
        {id: 'BC', name: 'British Colombia'},
        {id: 'MB', name: 'Manitoba'},
        {id: 'NB', name: 'New Brunswick'},
        {id: 'NL', name: 'Newfoundland and Labrador'},
        {id: 'NT', name: 'Northwest Territories'},
        {id: 'NS', name: 'Nova Scotia'},
        {id: 'NU', name: 'Nunavut'},
        {id: 'ON', name: 'Ontario'},
        {id: 'PE', name: 'Prince Edward Island'},
        {id: 'QC', name: 'Quebec'},
        {id: 'SK', name: 'Saskatchewan'},
        {id: 'YT', name: 'Yukon'},
    ],
    AU: [
        {id: '*', name: 'Any state'},
        {id: 'ACT', name: 'Australian Capital Territory'},
        {id: 'CX', name: 'Christmas Island'},
        {id: 'CC', name: 'Cocos Island'},
        {id: 'HM', name: 'Heard Island And Mcondald Island'},
        {id: 'NSW', name: 'New South Wales'},
        {id: 'NF', name: 'Norfolk Island'},
        {id: 'NT', name: 'Northern Territory'},
        {id: 'QLD', name: 'Queensland'},
        {id: 'SA', name: 'South Australia'},
        {id: 'TAS', name: 'Tasmania'},
        {id: 'VIC', name: 'Victoria'},
        {id: 'WA', name: 'Western Australia'},
    ],
    BR: [
        {id: '*', name: 'Any state'},
        {id: 'AC', name: 'Acre'},
        {id: 'AL', name: 'Alagoas'},
        {id: 'AP', name: 'Amapá'},
        {id: 'AM', name: 'Amazonas'},
        {id: 'BA', name: 'Bahia'},
        {id: 'CE', name: 'Ceará'},
        {id: 'GO', name: 'Goias'},
        {id: 'ES', name: 'Espírito Santo'},
        {id: 'MA', name: 'Maranhão'},
        {id: 'MT', name: 'Mato Grosso'},
        {id: 'MS', name: 'Mato Grosso do Sul'},
        {id: 'MG', name: 'Minas Gerais'},
        {id: 'PA', name: 'Pará'},
        {id: 'PB', name: 'Paraíba'},
        {id: 'PR', name: 'Paraná'},
        {id: 'PE', name: 'Pernambuco'},
        {id: 'PI', name: 'Piauí'},
        {id: 'RJ', name: 'Rio de Janeiro'},
        {id: 'RN', name: 'Rio Grande do Norte'},
        {id: 'RS', name: 'Rio Grande do Sul'},
        {id: 'RO', name: 'Rondônia'},
        {id: 'RR', name: 'Roraima'},
        {id: 'SP', name: 'São Paulo'},
        {id: 'SC', name: 'Santa Catarina'},
        {id: 'SE', name: 'Sergipe'},
        {id: 'TO', name: 'Tocantins'},
        {id: 'DF', name: 'Distrito Federal'},
    ],
};

const DEFAULT_COUNTRY_VAL = 'US';

export class StateSelect extends React.PureComponent {
    // ISO 3166-1 country codes taken from http://opencountrycodes.appspot.com/
    // includes a lot of defunct countries (e.g. Soviet Union)
    static propTypes = {
        value: PropTypes.string,
        country: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        styleOptions: PropTypes.object,
        useProspectorStateSelector: PropTypes.bool,
    };

    static defaultProps = {
        value: '*',
        useProspectorStateSelector: false,
    };

    render() {
        const stateSelectValues = {
            ...STATE_SELECT_VALUES,
            US: this.props.useProspectorStateSelector
                ? [{id: '*', name: 'Any state'}, ...US_STATES_FOR_PROSPECTOR]
                : [{id: '*', name: 'Any state'}, ...US_STATES],
        };

        // Make sure the country passed is one of the four we support
        const country = COUNTRIES_WITH_STATES.includes(this.props.country)
            ? this.props.country
            : DEFAULT_COUNTRY_VAL;

        const value = stateSelectValues[country].find((countrySelect) => {
            return countrySelect.id === this.props.value;
        });

        return (
            <Select
                selectValues={stateSelectValues[country]}
                onChange={this.props.onChange}
                value={value}
                styleOptions={this.props.styleOptions}
            />
        );
    }
}
