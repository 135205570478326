/* @flow */

import * as React from 'react';
import {FieldArray} from 'redux-form';

import {Body} from 'shells/typography';
import {Stack} from 'shells/layout';

import {AudienceSelect} from './audience-select';

type Props = {
    shouldAnd?: boolean,
};

export function AudienceForm(props: Props) {
    return (
        <Stack spacing={8}>
            <Body>Which audience?</Body>
            <FieldArray name='audiences' component={AudienceSelect} shouldAnd={props.shouldAnd} />
        </Stack>
    );
}
