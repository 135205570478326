/* @flow */

import type {DefaultQueryParams} from '../types';
import {Routing} from '../routing';
import {getTypeFromApiId} from '../utils/get-type-from-api-id';
import {api} from '../api';
import type {
    CollectionApiResponse,
    CollectionName,
    CollectionModelTypeMap,
} from './collections-types';

const DEFAULT_PAGE_LIMIT = 200;
const VALID_COLLECTION_NAMES = [
    'accountTypes',
    'activityTypes',
    'emailSequenceTemplateGroups',
    'competitors',
    'industries',
    'markets',
    'origins',
    'products',
    'sources',
    'stagesets',
    'tags',
    'teams',
    'territories',
    'users',
];

export const ActionTypes = {
    COLLECTION_REQUESTED: 'COLLECTION_REQUESTED',
    COLLECTION_UPDATED: 'COLLECTION_UPDATED',
    COLLECTION_FAILED: 'COLLECTION_FAILED',
    COLLECTIONS_FETCH_BY_ID_REQUEST: 'COLLECTIONS_FETCH_BY_ID_REQUEST',
    COLLECTIONS_FETCH_BY_ID_SUCCESS: 'COLLECTIONS_FETCH_BY_ID_SUCCESS',
    COLLECTIONS_FETCH_BY_ID_FAILURE: 'COLLECTIONS_FETCH_BY_ID_FAILURE',
};

export type RequestAction = {
    type: 'COLLECTION_REQUESTED',
    payload: {queryParams: ?DefaultQueryParams},
    meta: {collectionType: CollectionName},
};
type UpdateAction = {
    type: 'COLLECTION_UPDATED',
    payload: CollectionApiResponse<*>,
    meta: {collectionType: CollectionName},
};
type FailAction = {
    type: 'COLLECTION_FAILED',
    payload: ?Object,
    meta: {collectionType: CollectionName},
    error: true,
};
export type FetchByIdRequestAction = {
    type: 'COLLECTIONS_FETCH_BY_ID_REQUEST',
    payload: {apiId: string},
    meta: {collectionType: CollectionName},
};
type FetchByIdSuccessAction = {
    type: 'COLLECTIONS_FETCH_BY_ID_SUCCESS',
    payload: CollectionApiResponse<*>,
    meta: {collectionType: CollectionName},
};
type FetchByIdFailureAction = {
    type: 'COLLECTIONS_FETCH_BY_ID_FAILURE',
    payload: ?Object,
    meta: {collectionType: CollectionName},
    error: true,
};

export type CollectionAction =
    | RequestAction
    | UpdateAction
    | FailAction
    | FetchByIdRequestAction
    | FetchByIdSuccessAction
    | FetchByIdFailureAction;

// Actions
export const requestCollection = (
    collectionType: CollectionName,
    queryParams?: DefaultQueryParams
): RequestAction => ({
    type: ActionTypes.COLLECTION_REQUESTED,
    payload: {queryParams: queryParams},
    meta: {collectionType},
});
export const updateCollection = <T: CollectionName>(
    collectionType: T,
    response: CollectionApiResponse<$ElementType<CollectionModelTypeMap, T>>
): UpdateAction => ({
    type: ActionTypes.COLLECTION_UPDATED,
    payload: response,
    meta: {collectionType},
});
export const failCollection = (collectionType: CollectionName, error: ?Object): FailAction => ({
    type: ActionTypes.COLLECTION_FAILED,
    payload: error,
    meta: {collectionType},
    error: true,
});

export const fetchById = (apiId: string): ?FetchByIdRequestAction => {
    const collectionType = getTypeFromApiId(apiId);
    if (collectionType && VALID_COLLECTION_NAMES.includes(collectionType)) {
        // Now that we've checked it's a valid collection name, let's cast it to the right type
        const refinedName: CollectionName = (collectionType: any);

        return {
            type: ActionTypes.COLLECTIONS_FETCH_BY_ID_REQUEST,
            payload: {apiId},
            meta: {collectionType: refinedName},
        };
    }
};
export const resolveFetchById = <T: CollectionName>(
    collectionType: T,
    response: CollectionApiResponse<$ElementType<CollectionModelTypeMap, T>>
): FetchByIdSuccessAction => ({
    type: ActionTypes.COLLECTIONS_FETCH_BY_ID_SUCCESS,
    payload: response,
    meta: {collectionType},
});

export const failFetchById = (
    collectionType: CollectionName,
    error: ?Object
): FetchByIdFailureAction => ({
    type: ActionTypes.COLLECTIONS_FETCH_BY_ID_FAILURE,
    payload: error,
    meta: {collectionType},
    error: true,
});

const defaultQueryParams = {
    page: {
        limit: DEFAULT_PAGE_LIMIT,
        page: 0,
    },
};

/**
 * Gets all collection models for instance
 *
 * @param  {string} collectionType - Type of collection fetch
 * @param  {Object} [query]          - Standard query parameters, defaulting to an empty object
 *
 * @return {Function}              Thunk to request collection and dispatch corresponding
 *                                 actions during the process
 */
export function fetchCollection(
    collectionType: CollectionName,
    query: DefaultQueryParams = defaultQueryParams
) {
    return function(dispatch: Function) {
        dispatch(requestCollection(collectionType));

        getCollectionForType(collectionType, query)
            .then((res) => {
                dispatch(updateCollection(collectionType, res));

                // If the repsonse meta has a 'next' key, it will be in the form
                // of a absolute url path.
                if (res.meta && res.meta.next) {
                    // We'll grab that search string, with some safety built in
                    const splitUrlString = res.meta.next.split('?');
                    if (splitUrlString.length === 2) {
                        const nextSearchParams = Routing.deparam(splitUrlString[1]);

                        // Apply our new search parameters for the next page
                        // to the URL, and make an additional request
                        const newQuery = {
                            ...query,
                            ...nextSearchParams,
                        };

                        dispatch(fetchCollection(collectionType, newQuery));
                    }
                }
            })
            .catch(() => dispatch(failCollection(collectionType)));
    };
}

export function getCollectionForType(
    collectionType: CollectionName,
    query: DefaultQueryParams = defaultQueryParams
) {
    return api.get(collectionType, query).then((response) => response.json());
}
