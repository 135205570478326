/* @flow */

const defaultSettings =
    typeof window.NutshellFrontendSettings !== 'undefined' ? window.NutshellFrontendSettings : null;

export function getDefaultCurrencySymbol(
    settings: ?{defaultCurrencySymbol: string} = defaultSettings
) {
    // Not all environments use NutshellFrontendSettings, and returning an empty string isn't ideal,
    // so let's throw a console error to let the developer know something's wrong
    if (!settings) {
        console.error("Cannot determine user's default currency symbol"); // eslint-disable-line no-console

        return '';
    }

    return settings.defaultCurrencySymbol;
}
