/* @flow */

import * as React from 'react';

import type {GetEvent_event_payload_Chat_chatItems as ChatItem} from 'nutshell-graphql-types';
import {FormattedTimestamp} from 'shells/timestamp';

import './timeline-entry-chat.css';

type Props = {
    chatItems: ChatItem[],
};

export function ChatItems(props: Props) {
    const bucketedChatItems = props.chatItems.reduce((previousValue, current) => {
        const actor = current.chatter;
        const currentBucket = previousValue[previousValue.length - 1];
        if (!currentBucket || currentBucket.actorName !== actor) {
            const newBucket = {
                actorName: current.chatter,
                timestamp: current.timestamp,
                items: [current],
            };

            return previousValue.concat([newBucket]);
        } else {
            currentBucket.items.push(current);

            return previousValue;
        }
    }, []);

    return (
        <ul>
            {bucketedChatItems.map((chatBucket, index) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index} className='mb-16'>
                        <div className='flex'>
                            <strong>{chatBucket.actorName}&nbsp;</strong>
                            <span styleName='item-timestamp'>
                                <FormattedTimestamp
                                    datetime={chatBucket.timestamp}
                                    format={FormattedTimestamp.formats.time}
                                />
                            </span>
                        </div>
                        {chatBucket.items.map((item) => {
                            return (
                                <div key={item.timestamp} styleName='item-body'>
                                    {item.body}
                                </div>
                            );
                        })}
                    </li>
                );
            })}
        </ul>
    );
}
