/* @flow */
import * as React from 'react';
import {Observable} from 'rxjs';
import moment from 'moment';
import {uncapitalizeMomentString} from 'nutshell-core/date-time';

type Props = {
    datetime: ?number,
    // Note: 'toNow' is probably not what you want. (https://github.com/moment/moment/issues/2751#issuecomment-159903998)
    // `hourHybrid` will show relative time with 1 hour, then calendar time
    format: 'fromNow' | 'toNow' | 'calendar' | 'hourHybrid',
    children: ({formattedTimestamp: ?string}) => React.Node,
    hideAgoString?: boolean,
    lowercase?: boolean,
};

// Emit a tick once a minute, since we don't really update more often then that.
// But this guarantees we only have a single timer for all mounted timestamps.
const TIMER = Observable.interval(60000);

export class RelativeTimestampTimer extends React.PureComponent<Props> {
    timerSubscription: rxjs$Subscription;

    static defaultProps = {
        format: 'fromNow',
    };

    constructor() {
        super();

        this.timerSubscription = TIMER.subscribe(() => this.forceUpdate());
    }

    componentWillUnmount() {
        this.timerSubscription.unsubscribe();
    }

    render() {
        const {datetime, format, hideAgoString, lowercase} = this.props;
        if (typeof datetime === 'undefined' || datetime === null) {
            return this.props.children({
                formattedTimestamp: null,
            });
        }

        const datetimeValue = moment.unix(datetime);

        let timestamp = null;

        if (datetimeValue.isValid()) {
            if (format === 'fromNow') {
                timestamp = datetimeValue.fromNow(hideAgoString);
            } else if (format === 'toNow') {
                timestamp = datetimeValue.toNow(hideAgoString);
            } else if (format === 'calendar') {
                timestamp = datetimeValue.calendar(hideAgoString);
            } else if (format === 'hourHybrid') {
                timestamp =
                    Math.abs(datetimeValue.diff(moment(), `minutes`)) <= 59
                        ? datetimeValue.fromNow(hideAgoString)
                        : datetimeValue.calendar(hideAgoString);
            }

            if (lowercase && timestamp) {
                timestamp = uncapitalizeMomentString(timestamp);
            }
        }

        return this.props.children({
            formattedTimestamp: timestamp,
        });
    }
}
