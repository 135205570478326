/* @flow */

import * as React from 'react';

type Props = {
    size?: number,
};

export function PhoneConfigured(props: Props) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={props.size}
            viewBox='0 0 267 243'
            fill='none'
        >
            <path
                fill='#F7F7F7'
                d='M68.844 237.868c-5.633-1.229-73.321-12.296-68.339-93.925C5.488 62.315 11.742 37.766 38.058 28.785c26.316-8.981 90.286-51.48 140.483-11.07 50.198 40.408 104.364 76.651 82.869 141.173-21.494 64.521-25.014 86.043-81.096 82.752-56.083-3.294-84.681 2.072-111.47-3.772Z'
            />
            <path
                fill='#E3E3E3'
                d='M150.466 176.163c-.01-2.187.117-2.431-1.671-3.395-1.431-.772-2.988-1.364-4.489-1.967a79.703 79.703 0 0 0-13.172-4.003 80.153 80.153 0 0 0-22.272-1.547c-3.403-2.741-7.179-5.078-10.73-7.541-2.717-1.884-5.654-4.428-8.781-6.014v-33.411c0-5.862-4.753-10.615-10.615-10.615h-.092c-5.855 0-10.602 4.747-10.602 10.602v39.469c-.18 1.671.114 3.308.211 4.921.083 1.38.216 2.721.338 4.089a78.34 78.34 0 0 0 .513 4.314c.406 2.804.933 5.588 1.476 8.367 1.017 5.215 5.03 9.349 8.484 13.108 3.238 3.523 3.95 10.268 4.131 15.433.007.486.012.972.016 1.457.04 4.502.078 26.653.117 31.155 0 0 62.533.961 62.814.961l-.054-38.345c2.675-2.365 4.378-5.897 4.378-9.85v-.709c0-5.493.025-10.986 0-16.479Z'
            />
            <path
                fill='#fff'
                d='M82.17 193.735c.25.382.482.776.697 1.179.871 1.639 1.45 3.425 1.813 5.241.608 3.044.637 6.171.664 9.275.038 4.502.077 26.906.116 31.407 0 0 62.533 1.211 62.814 1.211l-.054-38.847c2.675-2.365 4.378-5.897 4.378-9.85v-.709c0-5.493.025-10.986 0-16.479-.009-2.187.117-2.431-1.671-3.395-1.431-.772-2.988-1.364-4.489-1.967a79.53 79.53 0 0 0-13.172-4.003c-11.935-2.531-24.405-2.333-36.233.721-4.179 1.078-8.396 2.569-11.654 5.513-.355.32-.695.656-1.017 1.009-2.08 2.277-3.22 5.156-3.47 8.214-.055.678-.1 1.38-.053 2.059.04.593-.19 1.213-.306 1.802-.267 1.352-.437 2.746-.236 4.12.145.99.395 1.581 1.014 2.332.307.373.593.763.858 1.167Z'
            />
            <path
                fill='#6A79A8'
                d='m149.299 240.923-.035-24.247-.018-12.407c0-.297-.109-.84-.001-1.12.076-.198.181-.19.379-.383a13.214 13.214 0 0 0 1.939-2.395c2.545-4.027 2.059-8.666 2.065-13.218.004-2.835.009-5.671.003-8.506-.002-1.181.015-2.365-.011-3.546-.013-.589-.019-1.16-.396-1.643-.419-.535-1.157-.847-1.745-1.147-1.121-.572-2.29-1.042-3.458-1.509-2.434-.974-4.892-1.871-7.404-2.625a80.96 80.96 0 0 0-30.937-3.058c-5.036.483-10.088 1.388-14.924 2.893-4.024 1.252-8.11 3.119-10.9 6.388a13.652 13.652 0 0 0-2.899 5.811c-.224.981-.34 1.984-.388 2.988-.074 1.564-.37 3.007-.547 4.557-.122 1.07-.143 2.185.116 3.237.253 1.025.905 1.712 1.5 2.551 1.48 2.085 2.345 4.541 2.818 7.039.697 3.674.611 7.447.63 11.171.012 2.734.02 5.468.029 8.202.018 6.13.03 12.26.059 18.391.004.816.008 1.632.014 2.448.003.271 1.3.064 1.297-.347-.086-10.278-.038-20.556-.114-30.834-.034-4.654-.047-9.497-1.974-13.83-.867-1.952-2.758-3.596-3.075-5.722-.2-1.341-.043-2.73.23-4.05.244-1.184.253-2.216.326-3.418.312-5.121 3.114-9.264 7.54-11.795 4.393-2.512 9.652-3.604 14.594-4.42 10.479-1.732 21.304-1.27 31.615 1.264 5.019 1.234 10.256 2.839 14.852 5.243.563.294 1.322.623 1.637 1.212.314.587.209 1.409.21 2.045.007 2.634.013 5.268.01 7.902-.002 2.747-.009 5.493-.009 8.24-.001 2.26-.183 4.407-1.097 6.504a12.736 12.736 0 0 1-1.594 2.7c-.444.575-1.458 1.241-1.688 1.886-.05.141.001.395.001.542l.015 10.577.038 26.233v.543c.001.268 1.297.067 1.297-.347Z'
            />
            <path
                fill='#fff'
                d='M144.822 100.13c-3.805-4.732-3.053-11.652 1.678-15.457l32.858-26.42c4.708-3.786 11.594-3.038 15.38 1.67l.059.074c3.791 4.714 3.042 11.608-1.672 15.399l-32.847 26.412c-4.731 3.804-11.651 3.053-15.456-1.678Z'
            />
            <path
                fill='#6A79A8'
                d='M145.466 99.956c-4.001-4.975-2.729-11.845 2.075-15.708l8.121-6.53 22.579-18.156c.564-.453 1.121-.918 1.694-1.362 3.56-2.758 8.364-2.71 12.01-.117 5.917 4.207 6.008 12.916.481 17.458-6.419 5.275-12.953 10.416-19.428 15.622l-10.679 8.587c-.824.663-1.646 1.33-2.474 1.989-2.698 2.146-6.067 2.785-9.352 1.7-1.985-.656-3.693-1.882-5.027-3.483-.247-.297-1.373.255-1.296.348 4.28 5.138 11.764 5.365 16.861 1.31 2.69-2.14 5.358-4.308 8.036-6.462l23.611-18.986c1.153-.926 2.269-1.847 3.141-3.067 2.273-3.179 2.676-7.503 1.016-11.042-3.262-6.956-12.254-8.288-18.067-3.747-6.758 5.28-13.373 10.753-20.055 16.127l-11.003 8.847c-.599.481-1.206.955-1.798 1.446-3.045 2.523-4.645 6.43-4.039 10.368a11.016 11.016 0 0 0 2.297 5.206c.241.299 1.376-.248 1.296-.348Z'
            />
            <path
                fill='#E3E3E3'
                d='M97.07 55.706h50.741c4.626 0 8.375 4.43 8.375 9.894v119.836c0 5.464-3.749 9.894-8.375 9.894h-50.74c-4.626 0-8.376-4.43-8.376-9.894V65.599c0-5.464 3.75-9.893 8.375-9.893Z'
            />
            <path
                fill='#E5E5E5'
                d='M98.426 53.448h58.956c5.374 0 9.731 4.43 9.731 9.893v119.837c0 5.464-4.357 9.893-9.731 9.893H98.426c-5.374 0-9.73-4.429-9.73-9.893V63.341c0-5.464 4.356-9.893 9.73-9.893Z'
            />
            <path
                fill='#fff'
                d='M101.298 53.448h58.956c5.374 0 9.731 4.43 9.731 9.893v119.837c0 5.464-4.357 9.893-9.731 9.893h-58.956c-5.375 0-9.731-4.429-9.731-9.893V63.341c0-5.464 4.356-9.893 9.731-9.893Z'
            />
            <path
                fill='#6A79A8'
                d='M101.045 53.8h41.238c5.78 0 11.56-.017 17.34 0 5.084.016 9.308 3.833 9.689 8.96.066.893.028 1.801.028 2.695V176.807c0 3.202.507 7.197-.731 10.224-1.362 3.329-4.482 5.611-8.097 5.688-.372.008-.746 0-1.118 0h-55.333c-2.406 0-4.688.076-6.899-1.159-3.324-1.857-4.936-5.346-4.943-9.044-.008-3.765 0-7.531 0-11.296V73.403c0-3.344-.035-6.69 0-10.033.05-4.977 3.638-9.47 8.826-9.57.465-.008 1.381-.722.512-.705-5.792.11-10.557 4.547-10.635 10.425-.01.789 0 1.578 0 2.367V178.857c0 1.348-.003 2.696 0 4.044.008 3.582 1.533 6.925 4.603 8.907 2.328 1.503 4.833 1.616 7.472 1.616h55.964c3.331 0 6.478-.824 8.858-3.327 2.05-2.157 2.817-4.889 2.817-7.792V70.934c0-2.968.299-6.121-.086-9.077-.671-5.149-5.12-8.703-10.196-8.762-1.849-.021-3.7 0-5.55 0h-53.247c-.468 0-1.383.706-.512.706Z'
            />
            <path fill='#E3E3E3' d='M95.911 175.077h69.729V70H95.911v105.077Z' />
            <path fill='#F9F9F9' d='M95.911 175.077h66.858V70H95.911v105.077Z' />
            <path
                fill='#6A79A8'
                d='M95.642 175.387h69.729c.24 0 .92-.139.92-.468V71.431c0-.376.139-1.074 0-1.428-.017-.045 0-.126 0-.176 0-.134-.311-.12-.376-.12H96.185c-.239 0-.92.137-.92.467v103.488c0 .376-.139 1.074 0 1.428.018.044 0 .126 0 .176 0 .269 1.297.067 1.297-.347V71.431c0-.394.13-1.052 0-1.428-.016-.047 0-.124 0-.176l-.92.468h69.729l-.376-.12V173.661c0 .394-.13 1.051 0 1.428.016.047 0 .124 0 .176l.92-.468H96.185c-.394 0-1.404.589-.543.589Z'
            />
            <path
                fill='#E5E5E5'
                d='M122.786 63.427h11.865c.74 0 1.626.117 2.337-.14 1.62-.586 1.931-3.022.096-3.575-.718-.217-1.664-.08-2.397-.08h-9.462c-.892 0-1.971-.163-2.848.078-2.148.59-1.918 3.426.243 3.716.616.083 1.33-.79.458-.907-1.113-.15-1.262-1.684-.207-1.978.215-.06.529 0 .751 0h10.75c.486 0 1.405-.181 1.868 0 .902.352 1.094 1.51.143 1.976-.134.066-.466 0-.612 0h-12.859c-.725 0-1.008.91-.126.91Z'
            />
            <path
                fill='#6A79A8'
                d='M123.79 63.321h11.865c.742 0 1.602.1 2.331-.094 1.574-.421 2.327-2.785.437-3.396-.706-.228-1.622-.095-2.344-.095h-9.462c-.91 0-1.933-.128-2.834.06-2.013.418-2.438 3.204-.137 3.513.387.052 1.518-.578.802-.674-.827-.111-1.772-1.703-.613-2.2.169-.073.543 0 .722 0H135.376c.581 0 1.295-.114 1.868 0 .805.16 1.584 1.681.552 2.188-.121.06-.386-.015-.516 0-2.233.254-4.645 0-6.891 0h-6.084c-.464 0-1.385.698-.515.698Z'
            />
            <path
                fill='#E5E5E5'
                d='M114.886 63.293c1.008 0 2.284-.46 2.438-1.608.144-1.082-.58-1.865-1.649-1.916-.973-.047-2.232.39-2.482 1.452-.251 1.07.46 2.002 1.551 2.065.29.017.696-.03.917-.246.16-.157.197-.364-.103-.382-.67-.038-1.08-.562-1.108-1.209-.017-.395.118-1.053.607-1.042 1.071.024 1.408 2.244.359 2.244-.244 0-.732.094-.861.335-.134.25.114.307.331.307Z'
            />
            <path
                fill='#6A79A8'
                d='M115.91 63.228c.967 0 2.293-.327 2.563-1.413.262-1.057-.458-1.952-1.53-1.985-.961-.03-2.301.348-2.563 1.413-.264 1.079.461 1.924 1.53 1.985.278.016.693-.07.916-.246.18-.141.202-.25-.071-.265-.683-.04-1.108-.65-1.124-1.292-.006-.24.017-.483.128-.7.078-.155.183-.286.315-.398-.126.106-.119-.03.024.015.655.201 1.091.514 1.123 1.267.011.249-.015.5-.127.725a1.07 1.07 0 0 1-.315.397c.087-.063.155-.014-.024-.014-.286 0-.685.063-.917.245-.168.133-.211.266.072.266Z'
            />
            <path
                fill='#E5E5E5'
                d='M128.665 190.318c2.445 0 4.823-.435 6.491-2.406 1.938-2.292 1.857-5.734-.209-7.919-3.685-3.899-11.808-1.738-12.313 3.844-.323 3.577 2.567 6.373 6.031 6.481.293.009 1.478-.533.834-.553-3.091-.096-5.602-2.601-5.597-5.725.005-3.285 2.599-5.499 5.779-5.378 3.076.116 5.623 2.585 5.598 5.725a5.744 5.744 0 0 1-1.648 3.988c-1.165 1.177-2.564 1.39-4.132 1.39-.29 0-1.472.553-.834.553Z'
            />
            <path
                fill='#6A79A8'
                d='M130.21 190.486c2.621 0 5.077-.694 6.576-3.029 1.606-2.501 1.188-5.879-1.027-7.878-4.061-3.664-11.94-1.154-11.966 4.692-.015 3.487 2.86 6.11 6.251 6.215.592.018 1.338-.862.463-.889-3.235-.1-5.769-2.908-5.36-6.172.402-3.206 3.145-4.751 6.143-4.589 3.148.17 5.522 3.037 5.13 6.166-.207 1.657-1.191 3.198-2.667 4.004-1.084.593-2.221.591-3.412.591-.718 0-1.014.889-.131.889Z'
            />
            <path
                fill='#5CE0A3'
                d='M158.229 122.136c0 15.161-12.29 27.45-27.45 27.45-15.161 0-27.45-12.289-27.45-27.45 0-15.16 12.289-27.45 27.45-27.45 15.16 0 27.45 12.29 27.45 27.45Z'
            />
            <path
                fill='#B9F7BA'
                d='M154.498 123.391c0 13.93-11.293 25.223-25.224 25.223-13.931 0-25.224-11.293-25.224-25.223 0-13.931 11.293-25.224 25.224-25.224 13.931 0 25.224 11.293 25.224 25.224Z'
            />
            <path
                fill='#6A79A8'
                d='M157.585 122.209c-.076 11.11-6.898 21.378-17.375 25.319-10.665 4.013-22.967.451-30.119-8.329-7.069-8.679-8.063-21.219-2.4-30.886 5.769-9.85 17.409-15.062 28.592-12.617 12.342 2.697 21.216 13.944 21.302 26.513.003.58 1.304.323 1.301-.145-.08-11.759-7.552-22.366-18.694-26.239-10.953-3.807-23.564-.289-31.003 8.585-7.536 8.99-8.592 22.091-2.525 32.148 6.03 9.997 17.974 15.113 29.391 12.9 13.047-2.529 22.741-14.096 22.831-27.394.003-.578-1.298-.325-1.301.145Z'
            />
            <path
                fill='#6A79A8'
                d='m117.549 119.928 10.539 10.538c.522.523 1.79.158 2.243-.294l15.109-15.11c1.065-1.065-1.469-1.034-2.079-.425l-15.109 15.109 2.243-.294-10.538-10.539c-.64-.64-3.095.327-2.408 1.015Z'
            />
            <path
                fill='#fff'
                d='M120.874 200.392c.126-1.327.256-2.654.391-3.981.227-2.221.47-4.44.741-6.656.824-6.733-.292-12.597-4.421-18.192-4.386-5.944-10.992-9.717-16.957-13.853-2.716-1.884-5.653-4.427-8.78-6.014v-33.411c0-5.862-4.753-10.614-10.615-10.614h-.093c-5.854 0-10.6 4.746-10.6 10.601v39.469c-.18 1.671.113 3.308.21 4.922.083 1.379.216 2.72.338 4.089a79.16 79.16 0 0 0 .513 4.314c.406 2.803.933 5.587 1.476 8.366 1.017 5.216 5.03 9.349 8.484 13.108 3.245 3.532 3.953 10.3 4.133 15.47l35.18-7.618Z'
            />
            <path
                fill='#6A79A8'
                d='M121.529 200.334a418.51 418.51 0 0 1 1.009-9.614c.317-2.674.512-5.332.212-8.018-.601-5.369-3.147-10.212-6.833-14.113-3.774-3.994-8.454-6.952-12.978-10.005-2.388-1.611-4.647-3.389-7.005-5.041a37.006 37.006 0 0 0-1.968-1.299c-.249-.152-.537-.366-.802-.472.688.275-.959-.586-.662-.141-.046-.07.026-.246 0-.326-.17-.534 0-1.413 0-1.971V119.737c0-2.128-.086-4.177-1.02-6.158-1.822-3.869-5.76-6.308-10.017-6.352-3.935-.041-7.71 1.918-9.85 5.241-1.331 2.067-1.73 4.366-1.73 6.773v33.597c0 1.558.03 3.119 0 4.677-.083 4.396.391 8.89 1 13.246.45 3.218.915 6.534 1.735 9.681.658 2.526 2.014 4.82 3.576 6.888 1.605 2.124 3.575 3.929 5.216 6.013 1.549 1.965 2.308 4.472 2.801 6.89.526 2.577.73 5.21.827 7.835.024.645 1.329.419 1.309-.116-.143-3.866-.445-7.861-1.795-11.519-1.387-3.759-4.504-6.22-6.904-9.314-1.372-1.77-2.601-3.684-3.357-5.804-.825-2.314-1.124-4.864-1.54-7.277a96.978 96.978 0 0 1-1.016-7.46c-.243-2.594-.64-5.291-.599-7.897.122-7.574.056-15.16.056-22.739V122.2c0-3.434-.265-6.845 1.81-9.847 4.744-6.862 15.402-4.754 17.75 3.056.867 2.885.44 6.388.44 9.356v26.989c0 .119.034.239.15.3 3.606 1.88 6.69 4.602 10.042 6.878 3.223 2.187 6.518 4.283 9.55 6.736 6.301 5.097 10.674 11.905 10.669 20.204-.003 4.846-.924 9.756-1.385 14.578-.06.629 1.257.431 1.309-.116Z'
            />
            <path
                fill='#fff'
                d='M153.292 122.007c-3.761-4.473-3.184-11.147 1.289-14.908l20.983-17.642c4.443-3.736 11.075-3.162 14.811 1.282l.112.133c3.723 4.429 3.152 11.037-1.277 14.76l-21.01 17.665c-4.473 3.761-11.148 3.183-14.908-1.29Z'
            />
            <path
                fill='#6A79A8'
                d='M153.897 121.785c-3.712-4.415-3.01-11.023 1.291-14.752 1.586-1.375 3.214-2.703 4.821-4.053l15.614-13.128c1.172-.985 2.422-1.843 3.917-2.266 3.06-.867 6.455.033 8.874 2.038 4.527 3.752 5.21 10.756 1.166 15.134-1.418 1.535-3.205 2.806-4.798 4.145l-8.805 7.403-7.773 6.535c-1.446 1.216-3.003 2.218-4.907 2.542-3.553.603-7.076-.933-9.4-3.598-.213-.245-1.426.208-1.22.444 3.792 4.35 10.345 4.972 15.093 1.785 1.992-1.336 3.773-3.083 5.603-4.622 5.431-4.566 10.892-9.099 16.294-13.699 3.988-3.398 5.17-9.229 2.355-13.766-2.996-4.83-9.09-6.41-14.214-4.178-2.364 1.03-4.317 2.999-6.27 4.64l-9.689 8.147-7.371 6.198c-3.533 2.97-5.291 7.502-3.767 12.021a10.732 10.732 0 0 0 1.966 3.474c.206.246 1.425-.201 1.22-.444Z'
            />
            <path
                fill='#fff'
                d='M162.058 167.669a8.578 8.578 0 0 1 1.046-12.087l17.206-14.466a8.52 8.52 0 0 1 12.004 1.038l.066.079a8.535 8.535 0 0 1-1.041 12.025l-17.195 14.456a8.578 8.578 0 0 1-12.086-1.045Z'
            />
            <path
                fill='#6A79A8'
                d='M162.653 167.393c-2.907-3.457-2.447-8.631.921-11.59 1.383-1.215 2.821-2.372 4.229-3.556 4.185-3.519 8.359-7.052 12.556-10.556 2.958-2.471 6.937-2.891 10.103-.469 3.871 2.963 4.358 8.881.898 12.359-1.181 1.187-2.573 2.204-3.852 3.279l-7.219 6.069-6.129 5.153c-3.537 2.974-8.372 2.879-11.507-.689-.359-.408-1.596.093-1.193.551 3.156 3.59 8.569 4.253 12.501 1.497 1.626-1.139 3.097-2.56 4.614-3.835 4.384-3.687 8.806-7.333 13.155-11.061 3.346-2.868 4.34-7.685 2.009-11.519-2.364-3.891-7.344-5.339-11.502-3.602-2.048.856-3.754 2.609-5.432 4.02l-7.949 6.683c-2.041 1.716-4.098 3.416-6.124 5.149-3.732 3.191-4.514 8.812-1.272 12.668.349.415 1.588-.08 1.193-.551Z'
            />
            <path
                fill='#fff'
                d='M156.356 147.195c-3.834-4.559-3.245-11.364 1.314-15.197l19.28-16.209c4.521-3.801 11.268-3.218 15.069 1.303l.112.134c3.804 4.524 3.22 11.276-1.304 15.079l-19.274 16.205c-4.56 3.834-11.364 3.245-15.197-1.315Z'
            />
            <path
                fill='#6A79A8'
                d='M156.944 146.927c-3.721-4.426-3.074-11.034 1.25-14.786 1.466-1.272 2.973-2.499 4.458-3.748 4.847-4.075 9.679-8.168 14.541-12.226 3.677-3.068 8.86-3.286 12.682-.3 4.561 3.562 5.484 10.454 1.747 14.955-1.335 1.607-3.191 2.899-4.78 4.234l-8.329 7.003c-2.388 2.008-4.771 4.024-7.166 6.025-4.407 3.682-10.62 3.185-14.403-1.157-.335-.385-1.575.101-1.196.537 3.712 4.259 9.975 5.189 14.812 2.283 2.093-1.257 3.941-3.132 5.798-4.694 4.991-4.196 10.047-8.325 14.978-12.592 4.111-3.558 5.187-9.617 2.277-14.276-2.869-4.594-8.687-6.457-13.732-4.578-2.549.949-4.551 2.944-6.6 4.666l-9.246 7.774c-2.292 1.927-4.612 3.826-6.878 5.783-4.574 3.952-5.356 10.939-1.409 15.634.328.39 1.57-.092 1.196-.537ZM164.477 166.301c-1.357-1.533-1.684-3.815-.835-5.673.526-1.151 1.482-1.909 2.403-2.724.763-.677 1.508-1.388 2.296-2.035 1.997-1.64 4.678-1.15 6.329.714 1.282 1.447 1.685 3.541 1.001 5.354-.467 1.238-1.396 2.04-2.35 2.885-.81.718-1.602 1.464-2.434 2.157-2.08 1.732-4.666 1.156-6.41-.678-.216-.227-1.401.246-1.223.433 2.362 2.484 6.222 2.291 8.759.169 1.682-1.407 4.135-3.084 4.934-5.203 1.37-3.628-1.48-7.375-5.258-7.376-2.878 0-4.714 1.834-6.718 3.609-1.088.965-2.208 1.856-2.744 3.265-.717 1.884-.306 4.032 1.027 5.536.203.229 1.401-.232 1.223-.433Z'
            />
            <path
                fill='#6A79A8'
                d='M158.882 145.383c-2.416-2.726-1.911-6.753.709-9.112 1.52-1.369 3.047-3.068 4.802-4.135 4.006-2.437 8.735 1.525 8.705 5.711-.023 3.106-2.292 4.822-4.392 6.682-.862.764-1.715 1.667-2.702 2.274-2.389 1.47-5.331.488-7.122-1.42-.232-.246-1.435.219-1.22.448 2.805 2.988 7.402 2.804 10.409.219 2.054-1.765 5.035-3.745 5.957-6.399 1.492-4.295-1.842-8.716-6.306-8.769-3.473-.041-5.672 2.252-8.075 4.381-1.288 1.142-2.589 2.196-3.221 3.867a6.615 6.615 0 0 0 1.236 6.701c.22.249 1.434-.206 1.22-.448ZM155.571 120.19c-2.424-2.735-1.862-6.642.752-8.983 1.57-1.407 3.248-3.389 5.18-4.3 4.065-1.917 8.521 1.811 8.28 6.038-.17 2.987-2.471 4.622-4.509 6.427-.921.817-1.856 1.826-2.983 2.353-2.356 1.101-5.002.295-6.72-1.535-.335-.357-1.572.13-1.199.526 2.835 3.02 7.354 2.943 10.402.296 2.093-1.818 5.024-3.758 5.964-6.476 1.468-4.246-1.746-8.656-6.137-8.921-3.602-.218-5.909 2.297-8.375 4.482-1.272 1.127-2.498 2.189-3.111 3.84-.853 2.298-.373 4.941 1.257 6.779.324.366 1.564-.114 1.199-.526Z'
            />
        </svg>
    );
}
