import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} fill='#f9f9f9' viewBox='0 0 140 220'>
            <g>
                <path d='M133.12,84.91c-12.1-49.69-44.04-55.9-61.99-55.18c-2.27,0.09-4.56,0.3-6.81,0.63 c-0.13,0.02-0.25,0.02-0.37,0.03c-0.6,0.03-1.33-0.07-2.21-0.3c-2-4.27-4.28-8.11-6.84-11.44C50.06,12.49,42.96,6.52,33.79,0.92 c-0.6-0.37-1.29-0.55-1.98-0.51C31.54,0.42,31.27,0.46,31,0.53c-0.94,0.27-1.73,0.92-2.17,1.79L25,9.78 c-0.77,1.52-0.37,3.39,0.96,4.44c3.42,2.7,6.24,5.43,8.3,8.06c2.7,3.54,5.01,7.82,6.88,12.75c-1.01,0.9-1.93,1.47-2.78,1.77 C19.62,43.56-9.9,62.98,3.29,117.13c0.23,0.92,0.8,1.71,1.6,2.21c0.6,0.36,1.28,0.53,1.97,0.51c0.23,0,0.48-0.04,0.7-0.1l26.18-6.5 l15.72,16.59c6.28,6.63,13.23,4.8,15.45-4.05l5.42-21.62l8.2-2.03l1.97-0.49l15.84,16.71c6.28,6.63,13.23,4.8,15.45-4.05 l5.46-21.79l13.28-3.3c0.91-0.23,1.69-0.81,2.18-1.61C133.19,86.8,133.34,85.83,133.12,84.91z M49.88,87.83 c-2.92,2.74-5.71,4.39-8.51,5.06c-3.24,0.77-6.41,0.16-9.21-1.68c-8.3-5.54-11.14-21.26-11.48-23l-0.89-5.33l5.26,1.03 c2.16,0.47,21.14,4.3,28.19,8.7c1.51,0.93,2.48,2.44,2.62,4.31C56.26,81.15,52.31,85.58,49.88,87.83z M99.7,75.13 c-1.66,2.9-4.23,4.88-7.39,5.63c-2.87,0.68-6.11,0.47-9.95-0.66c-3.18-0.92-8.7-3.09-10.25-7.05c-0.7-1.66-0.54-3.51,0.39-5.03 c4.3-7.1,19.52-19.08,21.24-20.47l4.23-3.28l1.61,5.16C100.05,51.13,104.61,66.44,99.7,75.13z' />
                <path
                    className='jaw'
                    d='M139.85,155.08c-0.29-0.95-0.97-1.73-1.85-2.14l-12.86-5.92c-0.51-0.23-1.04-0.33-1.57-0.3 c-1.11,0.04-2.18,0.64-2.79,1.67c-5.02,8.36-9.93,14-16.05,17.98L91.37,150.9c-5.96-6.91-12.99-5.42-15.62,3.32l-5.86,19.48 c-20.49-3.82-29.86-24.16-34.13-41.71c-0.21-0.91-0.79-1.68-1.57-2.15c-0.58-0.36-1.25-0.53-1.91-0.5 c-0.24,0.01-0.47,0.04-0.71,0.1l-14.39,3.66c-0.89,0.23-1.66,0.81-2.12,1.61c-0.48,0.81-0.6,1.76-0.36,2.66 c10.01,37.65,30.74,57.63,59.96,57.79c3.14,0.54,7.15,3,10.37,4.99c3.43,2.13,5.7,3.52,7.86,3.44c0.23-0.01,0.69-0.08,0.91-0.13 c2.45-0.63,4.03-3.12,6.41-6.9c1.68-2.68,3.58-5.67,5.73-7.91c14.23-5.87,23.99-14.83,33.55-30.76 C140.02,157.04,140.13,156.03,139.85,155.08z'
                />
            </g>
        </svg>
    );
}
SvgComponent.iconName = 'nutshell-logo-mark';

export const NutshellSpookyLogoMarkIcon = svgIconWrapper(SvgComponent);
