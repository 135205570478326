import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Mailchimp from './icon-mailchimp.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Mailchimp {...validProps} />;
}
SvgComponent.iconName = 'mailchimp';

export const MailchimpIcon = svgIconWrapper(SvgComponent);
