/* @flow */

import type Moment from 'moment';
import * as React from 'react';

import chrono from 'chrono-node';
import moment from 'moment';

import {CalendarWrapper} from '../calendar';
import {CannedOptions, type CannedOption} from './canned-options';
import {TimePicker} from './time-picker';
import styles from './date-time-picker.css'; // eslint-disable-line no-unused-vars

const tomorrow = () =>
    moment()
        .startOf('day')
        .add(1, 'day');

type Props = {
    type: 'creator-modal' | 'popover-picker',
    value?: string,
    maxDate?: Date,
    minDate?: Date,
    shouldOmitTime?: boolean,
    onSelectDate: (?Date, boolean) => void,
    onUpdate: (Date, boolean) => void,
    cannedOptions?: CannedOption[],
    hasYearPicker?: boolean,
    persistOnSelect?: boolean,
};

type State = {
    displayDate: Moment,
};

export class DateTimePicker extends React.PureComponent<Props, State> {
    initialState: State;

    static defaultProps = {
        type: 'creator-modal',
        value: '',
        onUpdate: () => {},
        persistOnSelect: true,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            displayDate: tomorrow(),
        };

        this.initialState = {...this.state};
    }

    componentDidMount() {
        this.update(this.props.value);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.value !== this.props.value) {
            this.update(nextProps.value);
        }
    }

    render() {
        const containerStyle = this.props.shouldOmitTime
            ? 'styles.container'
            : 'styles.container-time';

        return (
            <div styleName={`${containerStyle} styles.type-${this.props.type}`}>
                <div styleName='styles.calendar-wrapper'>
                    <CalendarWrapper
                        isLightMode={true}
                        maxDate={this.props.maxDate}
                        minDate={this.props.minDate}
                        momentDisplayDate={this.state.displayDate}
                        onDateClick={this.handleSelectDay}
                        hasYearPicker={this.props.hasYearPicker}
                    />
                </div>

                <CannedOptions
                    type={this.props.type}
                    date={this.state.displayDate.toDate()}
                    onSelect={this.handleSelectDay}
                    options={this.props.cannedOptions}
                />

                {this.props.shouldOmitTime ? (
                    undefined
                ) : (
                    <TimePicker
                        type={this.props.type}
                        date={this.state.displayDate.toDate()}
                        maxDate={this.props.maxDate}
                        minDate={this.props.minDate}
                        onSelect={this.handleSelectTime}
                    />
                )}
            </div>
        );
    }

    update = (value: ?string) => {
        let isValid = false;
        let date = this.state.displayDate.toDate();
        const parsedResult = chrono.parse(value);

        if (value && value.length > 2 && parsedResult.length) {
            isValid = true;
            const displayDate = parsedResult[0].start.date();
            date = displayDate;

            this.setState({displayDate: moment(displayDate)});
        }

        this.props.onUpdate(date, isValid);
    };

    handleSelectDay = (val: Date) => {
        this.setState(
            (prevState) => {
                let date = moment(new Date(val));
                if (this.props.shouldOmitTime) {
                    // set time to noon of selected day to avoid timezone issues when saving
                    date = date.startOf('day').add(moment.duration(12, 'hours'));
                }
                let displayDate = moment(prevState.displayDate);
                if (this.props.shouldOmitTime) {
                    // display time same as saved time
                    displayDate = date.startOf('day').add(moment.duration(12, 'hours'));
                }

                displayDate.set({
                    year: date.year(),
                    month: date.month(),
                    date: date.date(),
                });

                return {displayDate};
            },
            () => this.selectDate()
        );
    };

    handleSelectTime = (date: Date) => {
        this.setState({displayDate: moment(date)}, () => this.selectDate());
    };

    /**
     * @param {Boolean} allowEmpty if true, don't submit empty string
     * @return {undefined}
     */
    selectDate = (allowEmpty?: boolean) => {
        const persist = this.props.persistOnSelect;

        if (this.props.value === '' && !persist && !allowEmpty) {
            this.props.onSelectDate(null, persist || false);

            return;
        }

        this.props.onSelectDate(this.state.displayDate.toDate(), persist || false);
    };

    getCurrentSelection = () => {
        return this.state.displayDate.toDate();
    };
}
