/* @flow */

import * as React from 'react';
import type Moment from 'moment';

import {CalendarHeader} from './calendar-header';
import {DayOfWeekBar} from './day-of-week-bar';
import {CalendarDates} from './calendar-dates';
import {WEEK, MONTH} from './calendar-constants';
import type {DayDisplayFormat} from './day-of-week-bar';

import './calendar.css';

type State = {
    momentDisplayDate: Moment,
};

type Props = {
    momentDisplayDate: Moment,
    displayMode?: WEEK | MONTH,
    onDateClick: (Moment) => void,
    onYearChange?: (Moment) => void,
    selectedDates?: Moment[],
    headerDateFormat?: string,
    dayOfWeekFormat?: DayDisplayFormat,
    isTodayHighlighted?: boolean,
    showAdjacentMonthDates?: boolean,
    minDate?: Object,
    maxDate?: Object,
    width?: number,
    height?: number,
    hoveredDates?: Moment[],
    onDateMouseOver?: (Moment) => void,
    onDateMouseOut?: (Moment) => void,
    comparisonDates?: Moment[],
    useComparisonMode?: boolean,
    hasQuickViews?: boolean,
    quickViews?: Object,
    hasLeftControl?: boolean,
    hasRightControl?: boolean,
    onLeftControlClick?: () => void,
    onRightControlClick?: () => void,
    isLightMode?: boolean,
    hasYearPicker?: boolean,
};

export class Calendar extends React.Component<Props, State> {
    static defaultProps = {
        hasLeftControl: true,
        hasRightControl: true,
        selectedDates: [],
        comparisonDates: [],
    };

    render() {
        return (
            <div styleName='container' style={{width: this.props.width, height: this.props.height}}>
                <CalendarHeader
                    isLightMode={this.props.isLightMode}
                    hasYearPicker={this.props.hasYearPicker}
                    hasLeftControl={this.props.hasLeftControl}
                    hasRightControl={this.props.hasRightControl}
                    dateFormat={this.props.headerDateFormat}
                    momentDisplayDate={this.props.momentDisplayDate}
                    onLeftClick={this.props.onLeftControlClick}
                    onRightClick={this.props.onRightControlClick}
                    onYearChange={this.props.onYearChange}
                />
                <DayOfWeekBar
                    displayMode={this.props.displayMode}
                    startOfWeekInt={this.getStartOfWeek()}
                    dateFormat={this.props.dayOfWeekFormat}
                />
                <CalendarDates
                    momentDisplayDate={this.props.momentDisplayDate}
                    displayMode={this.props.displayMode}
                    hasQuickViews={this.props.hasQuickViews}
                    quickViews={this.props.quickViews}
                    selectedDates={this.props.selectedDates}
                    comparisonDates={this.props.comparisonDates}
                    useComparisonMode={this.props.useComparisonMode}
                    hoveredDates={this.props.hoveredDates}
                    onDateMouseOver={this.props.onDateMouseOver}
                    onDateMouseOut={this.props.onDateMouseOut}
                    onDateClick={this.props.onDateClick}
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                    isTodayHighlighted={this.props.isTodayHighlighted}
                    showAdjacentMonthDates={this.props.showAdjacentMonthDates}
                />
            </div>
        );
    }

    getStartOfWeek = () => {
        if (this.props.displayMode === WEEK) {
            return this.props.momentDisplayDate
                .clone()
                .subtract(3, 'days')
                .day();
        }

        return 0;
    };
}
