/* @flow */

import type {ProductMap, ProductMapPatch} from '../types';

/**
 * Updates the changed productMap's price and quantity, and sets our state
 * with the new updates.
 *
 * @param  {Object[]} productMaps}          initial array of productMaps
 * @param  {string} productMap.id           id of productMap to update,
 * @param  {Object} productMap.price        new price object
 * @param  {number} productMap.quantity     new quantity
 * @return {void}
 */
export function updateProductMaps(productMaps: ProductMap[], {id, price, quantity}: Object) {
    // $FlowFixMe upgrading Flow to v0.92.1
    const newProductMaps = productMaps.map((productMap) => {
        if (productMap.id !== id) return productMap;

        return {
            ...productMap,
            description: getProductMapDescription({...productMap, price, quantity}),
            price,
            quantity,
        };
    });

    return {
        productMaps: newProductMaps,
    };
}

/**
 * Helper function to generate the correct shape of data for including in a
 * "win lead" patch request.
 *
 * @param  {Object[]} productMaps   - Array of full product maps objects to translate
 * @return {Object}                 - Key'd object that includes transformed payload
 */
export function getProductMapDataForWinningLeadPatch(
    productMaps: ProductMap[]
): {|productMaps: ProductMapPatch[]|} {
    const productMapArray = productMaps.map((productMap) => {
        return {
            id: productMap.id,
            price: {
                amount: productMap.price.amount,
                currency: productMap.price.currency,
            },
            quantity: productMap.quantity,
        };
    });

    return {
        productMaps: productMapArray,
    };
}

/**
 * We have an opinionated product *description* for the UI, but we don't
 * want to do that on every client, nor when we generate our API response,
 * since it's a _Nutshell_ UI concern. Therefore, we do it here :)
 * @param  {object} productMap - productMap to extract a useful description from
 * @return {string} - opinionated productMap description to be consumed by Nutshell clients
 */
export function getProductMapDescription(productMap: ProductMap): string {
    if (!productMap.price) return '';
    const {price, quantity, productType, unit} = productMap;

    if (productType === 'product') {
        return `${price.formatted} x ${quantity}`;
    } else {
        // TODO fix this once `period` gets added to the API
        return `${quantity} @ ${price.formatted} / ${unit}`;
    }
}

/**
 * Given an array of productMap object (or product map patch objects), reduce
 * down to a single number value
 * @param  {object} productMaps           - Array of productMaps to generate
 *                                          a single lead value from
 * @return {number}                       - Lead value
 */
export function getLeadValueFromProductMaps(productMaps: Object[]): number {
    return productMaps.reduce((acc, productMap) => {
        return acc + Number(productMap.price.amount) * productMap.quantity;
    }, 0);
}
