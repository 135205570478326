/* @flow */

import * as ramda from 'ramda';

import * as epics from './billing-epics';
import * as actions from './billing-actions';
import * as reducer from './billing-reducer';
import * as selectors from './billing-selectors';
import * as types from './billing-types';

export const Billing = {
    ...actions,
    ...reducer,
    ...selectors,
    ...types,
    epics: ramda.values(epics),
};
