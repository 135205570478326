/**
 * Gets 6-week array for the month of the given day passed.
 *
 * Starting on Sunday, an array of 6, 7-day weeks will be returned, with each
 * week starting on Sunday. The first week will always include _at least_ one
 * date from the month of the passed day.
 *
 * @param  {moment} dateInMonth     Moment object of date in desired month
 * @return {array}                  Array of 6, 7-day arrays of moment objects.
 */
export function getCalendarSixWeeks(dateInMonth) {
    const weekArray = [];
    const firstDayOfMonth = dateInMonth.clone().startOf('month');
    const firstDayOfFirstWeek = firstDayOfMonth.clone().startOf('week');
    const dateMarker = firstDayOfFirstWeek.clone().startOf('week');

    for (let i = 0; i < 6; i++) {
        const dayArray = [];

        for (let j = 0; j < 7; j++) {
            dayArray.push(dateMarker.clone());
            dateMarker.add(1, 'days');
        }

        weekArray.push(dayArray);
    }

    return weekArray;
}
