/* @flow */

import * as React from 'react';

type Props = {
    width?: number,
};

export function ConversationBubbles(props: Props) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={props.width}
            viewBox='0 0 201 171'
            fill='none'
        >
            <path
                fill='#F9F9F9'
                d='M51.89 167.711c-4.25-.867-55.317-8.685-51.558-66.348C4.092 43.701 8.81 26.36 28.663 20.016c19.854-6.345 68.115-36.366 105.986-7.82 9.83 7.41 19.848 14.579 29.417 22.334 13.149 10.657 26.67 22.965 33.131 39.091a50.145 50.145 0 0 1 3.337 23.886c-.989 9.515-2.382 18.772-4.918 28.044l-2.591 9.469c-.517 1.895-1.039 3.8-1.856 5.586-5.251 11.505-11.875 19.053-22.975 25.179-9.631 5.313-21.568 5.175-32.208 4.589-42.31-2.326-63.886 1.465-84.096-2.663Z'
            />
            <path
                fill='#fff'
                d='M159.375 23.685H82.513a4.816 4.816 0 0 0-4.816 4.816v15.39l-5.88 5.881 5.88 5.881v15.39a4.816 4.816 0 0 0 4.816 4.816h76.862a4.816 4.816 0 0 0 4.816-4.816V28.501a4.816 4.816 0 0 0-4.816-4.816Z'
            />
            <path
                fill='#6A79A8'
                d='M159.415 23.32H83.443c-.474 0-.96-.022-1.432.03a5.38 5.38 0 0 0-4.805 5.268c-.081 4.036 0 8.08 0 12.116v2.53c0 .191.01.387 0 .578-.01.165.212-.188-.044.026-1.594 1.338-3 3-4.471 4.471l-1.282 1.282c-.083.084-.13.274-.035.368l2.59 2.591c1.024 1.023 2.013 2.282 3.163 3.163.153.117.125.147.079-.033.021.085.003.215 0 .301-.017.459 0 .921 0 1.38v5c0 2.86-.039 5.724 0 8.586.041 3.098 2.435 5.226 5.465 5.248 3.364.025 6.73 0 10.094 0h65.453c1.664 0 3.249-.134 4.605-1.285 1.81-1.537 1.859-3.55 1.859-5.682V31.329c0-1.429.204-3.157-.269-4.53-.747-2.165-2.769-3.417-4.998-3.48-.563-.015-.734.714-.08.732 2.457.069 4.31 2.102 4.364 4.515.009.413 0 .828 0 1.24v28.022c0 4.393.084 8.796 0 13.19-.053 2.75-2.102 4.476-4.741 4.476H83.98c-1.438 0-2.783.05-4.02-.91-2.402-1.865-1.77-5.486-1.77-8.166v-9.802c0-.6.108-1.005-.31-1.44-1.057-1.098-2.159-2.158-3.237-3.236l-2.384-2.384-.035.367 3.838-3.837c.622-.622 1.332-1.216 1.893-1.894.222-.267.227-.38.234-.723.093-4.195 0-8.401 0-12.597 0-1.658-.218-3.491.757-4.939.912-1.355 2.357-1.877 3.918-1.882 1.925-.006 3.85 0 5.775 0h65.095c1.776 0 3.58.09 5.354 0 .082-.004.165 0 .247 0 .561 0 .737-.731.08-.731Z'
            />
            <path
                fill='#6A79A8'
                d='M152.824 54.951H85.556c-.567 0-.733.746-.076.746h67.268c.566 0 .732-.746.076-.746ZM141.244 60.67H85.556c-.567 0-.733.746-.076.746H141.168c.566 0 .732-.746.076-.746ZM122.381 65.886H85.556c-.567 0-.733.747-.076.747h36.825c.566 0 .732-.746.076-.746ZM157.137 48.816H94.521c-2.881 0-5.822-.171-8.7 0-.041.003-.083 0-.124 0-.414 0-1.01.625-.357.625h62.616c2.881 0 5.822.172 8.7 0 .041-.002.083 0 .124 0 .414 0 1.01-.625.357-.625Z'
            />
            <path
                fill='#CBE7F4'
                d='M131.875 42.223h-44.95a2.308 2.308 0 0 1-2.308-2.308v-5.82a2.308 2.308 0 0 1 2.308-2.307h44.95a2.308 2.308 0 0 1 2.308 2.308v5.819a2.308 2.308 0 0 1-2.308 2.308Z'
            />
            <path
                fill='#fff'
                d='M129.516 36.264H89.438c-1.13 0-1.468 1.484-.154 1.484H129.36c1.13 0 1.468-1.484.155-1.484Z'
            />
            <path
                fill='#6A79A8'
                d='M148.342 61.635a.495.495 0 0 1-.335-.126.435.435 0 0 1-.142-.349.47.47 0 0 1 .481-.468c.132.001.243.047.333.138.09.09.135.202.134.334a.437.437 0 0 1-.143.346.479.479 0 0 1-.328.125Zm-1.525-3.459c.001-.2.066-.401.195-.606.128-.204.316-.373.562-.507.247-.133.534-.2.861-.198.305.001.574.058.806.172.233.113.413.266.539.46.126.194.189.404.188.63a.966.966 0 0 1-.11.47c-.073.134-.16.25-.26.347-.1.097-.28.261-.539.491a2.101 2.101 0 0 0-.172.171.71.71 0 0 0-.097.135.7.7 0 0 0-.049.123 4.93 4.93 0 0 0-.053.216c-.041.218-.166.327-.376.326a.383.383 0 0 1-.275-.108c-.075-.072-.112-.178-.111-.319a1.18 1.18 0 0 1 .084-.458c.055-.129.128-.242.218-.34.091-.097.213-.212.367-.346.135-.117.232-.206.292-.266a.897.897 0 0 0 .152-.199.484.484 0 0 0 .063-.24.561.561 0 0 0-.186-.425.686.686 0 0 0-.483-.175c-.231-.001-.402.057-.511.173-.11.116-.203.287-.279.514-.072.237-.209.355-.408.354a.397.397 0 0 1-.297-.125.381.381 0 0 1-.121-.27Z'
            />
            <path
                fill='#fff'
                d='M64.52 49.773c0 14.961-12.129 27.09-27.09 27.09-14.962 0-27.091-12.129-27.091-27.09 0-14.962 12.129-27.09 27.09-27.09 14.962 0 27.09 12.128 27.09 27.09Z'
            />
            <path
                fill='#F2F2F2'
                d='M23.168 67.562c.176.489.529 1.558.529 1.558s-.514 1.293-.98 2.52l.964-.02c6.359-.11 12.717.116 19.075.174 2.614.024 5.228.06 7.842.09-.135-.43-.274-.86-.417-1.287-.17-.509-.45-1.07-.5-1.61-.048-.542.064-1.266.257-1.778.2-.533.61-.936.901-1.414.205-.336.59-.972.368-1.392-.23-.432-1-.597-1.404-.768-.566-.239-1.153-.404-1.72-.641-.316-.133-.657-.21-.959-.377-.302-.168-.467-.438-.7-.682-.65-.684-1.132-1.502-1.662-2.278-2.932-3.725-6.567-5.377-11.052-3.218-1.242.598-2.369 1.415-3.372 2.356-1.242 1.165-2.066 2.629-3.21 3.872a9.942 9.942 0 0 1-2.344 1.88c-.417.24-.85.45-1.265.694-.256.151-1.09.578-1.066.91.012.182.304.502.392.672.123.24.232.486.323.739Z'
            />
            <path
                fill='#fff'
                d='M24.784 69.116c-.214.68-.769 1.678-.758 2.376.015.916 1.84 1.157 2.543 1.424.983.374 1.944.85 2.958 1.134 2.974.831 5.9.983 8.965.983 1.505 0 2.893-.007 4.367-.352 1.051-.247 2.096-.47 3.152-.706.927-.207 1.771-.704 2.64-1.082.51-.22 1.087-.285 1.58-.55.724-.389.171-1.087-.05-1.747-.397-1.186-.672-1.95-.1-3.102.464-.937 1.728-1.678 1.877-2.758-.782-.547-1.788-1.038-2.686-1.343-.913-.31-1.934-.574-2.649-1.26-.76-.729-1.275-1.617-1.86-2.476-2.932-3.725-6.567-5.378-11.052-3.218-1.243.598-2.37 1.415-3.373 2.356-1.242 1.165-2.066 2.628-3.21 3.872a9.942 9.942 0 0 1-2.344 1.88c-.416.24-.85.45-1.264.694-.257.15-.806.538-.561.764 1.216 1.123 1.825 3.111 1.825 3.111Z'
            />
            <path
                fill='#fff'
                d='m34.977 67.189-3.43-2.396c-1.081-.756-2.508-1.461-3.432-2.398-.868-.88.062-1.62.742-2.514.6-.79 1.253-1.545 1.982-2.22 2.863-2.656 6.484-3.322 9.989-1.475 2.051 1.08 3.712 2.854 5.007 4.752.863 1.267-.199 2.063-1.147 3.052a15.533 15.533 0 0 1-2.846 2.337c-.898.573-1.938 1.068-2.99.906-1.053-.163-2.005-1.251-1.658-2.258l-2.217 2.214Z'
            />
            <path
                fill='#E5E5E5'
                d='M45.158 63.462c-1.074 1.33-2.314 2.46-3.836 3.257-.438.23-1.333.728-1.927.937l-1.655-2.68c-.264-.428-1.034-.225-1.293.09l-1.89 2.296-5.626-4.383c-.534-.415-1.813.333-1.136.86l6.25 4.87c.335.26.935.109 1.186-.197l1.681-2.042.854 1.382c.43.697.608 1.144 1.519.921 2.78-.68 5.353-2.667 7.108-4.843.59-.73-.799-1.01-1.235-.468Z'
            />
            <path
                fill='#6A79A8'
                d='M44.832 62.98c-.984 1.298-2.148 2.426-3.571 3.236-.182.103-2.426 1.069-2.42 1.117.358.175.495.195.41.059l-.043-.073c.008-.076-.157-.27-.196-.338l-.57-.98-.925-1.586c-.172-.296-.76-.011-.903.172l-2.314 2.98.903-.173-5.895-4.867c-.285-.235-1.192.151-.896.395l5.895 4.867c.264.218.71.076.904-.172.77-.994 1.542-1.987 2.313-2.98l-.903.172 1.209 2.076c.344.591.462 1.176 1.233.964 2.67-.733 5.042-2.66 6.68-4.82.28-.368-.7-.327-.91-.05Z'
            />
            <path
                fill='#fff'
                d='M45.355 46.302c.052-8.137-3.489-14.757-7.908-14.786-4.42-.028-8.045 6.546-8.097 14.684l-.01 1.459c-.028 4.512.335 7.658.993 9.85 1.35 4.507 6.74 6.419 6.74 6.419s5.541-1.807 6.983-5.819c.79-2.195 1.259-5.471 1.29-10.348l.009-1.459Z'
            />
            <path
                fill='#BBDFF5'
                d='M43.34 35.536c.694-.697 1.841-1.884 1.87-2.915.39 1.15.478 2.458.832 3.654.197.666.395 1.34.43 2.033.055 1.071-.286 2.133-.8 3.074-.514.94-1.198 1.776-1.882 2.602.114.102.239-.118.237-.271l-.021-1.974c-.127.127-.566.33-.692.456l-.824.824c.233-.425.253-.96.052-1.4l-.225-.003c-.353.454-.879.639-1.384.915l.295-.073a2.64 2.64 0 0 0-.203-.966c-.243-.583-.727-1.038-.965-1.617-.363-.887.069-.797.484-1.538.617-1.103 1.917-1.918 2.796-2.801ZM26.936 35.943c1.041-2.042 2.372-3.895 4.276-5.331 1.85-1.396 4.25-2.579 6.573-2.807 1.725-.168 3.338.567 4.653 1.633 1.11.9 3.061 2.23 1.985 3.71a19.96 19.96 0 0 1-6.336 5.612 19.956 19.956 0 0 1-3.91 1.684c-.598.184-1.296.237-1.77.64-.235.198-.99 1.227-.978 1.526-.006-.145-.477-1.762-.667-1.612a6.414 6.414 0 0 0-1.575 1.814c-.092.158-.218.34-.277.514-.062.184-.023.374-.016.57.008.236.008.472-.002.708-.004.108-.1.527-.044.605-.238-.337-.631-.54-.86-.884-.314-.473-.247-1.073-.319-1.61a15.678 15.678 0 0 0-.324-1.709c-.352.33-.57.763-.654 1.238-.064.367-.075.743-.158 1.106-.114.495-.363 1.01-.186 1.487-.704-.594-.876-1.725-.956-2.591-.09-.965.013-1.938.215-2.883.13-.605.29-1.127.533-1.701.247-.582.51-1.156.797-1.719Z'
            />
            <path
                fill='#6A79A8'
                d='M45.847 46.272c0-3.469-.543-7.017-2.088-10.154-1.053-2.139-2.756-4.346-5.217-4.867-5.772-1.222-8.61 6.484-9.33 10.8-.567 3.389-.478 7.01-.217 10.425.21 2.743.55 5.704 2.325 7.923 1.317 1.647 3.188 2.95 5.14 3.738 1.02.412 2.287-.468 3.164-.95 2.561-1.404 4.503-3.346 5.27-6.215.927-3.473.93-7.13.953-10.7.003-.528-.982-.403-.985.062-.018 2.905-.047 5.823-.526 8.696-.429 2.572-1.244 4.725-3.359 6.374a14.242 14.242 0 0 1-2.685 1.625c-.16.075-1.25.385-1.309.544.074-.2.578.169.067-.104-.252-.134-.527-.23-.783-.357a13.566 13.566 0 0 1-2.556-1.644c-4.365-3.567-3.915-10.099-3.87-15.195.028-3.14.511-6.324 1.792-9.21.922-2.078 2.487-4.555 4.883-5.078 2.643-.577 4.748 1.693 5.932 3.766 1.812 3.17 2.414 6.977 2.414 10.583 0 .53.985.403.985-.062ZM22.564 67.973a7.327 7.327 0 0 0-2.462 2.368c-.13.2.114.328.28.345.23.024.506-.083.635-.28a6.397 6.397 0 0 1 2.169-2.069c.173-.1.243-.31.069-.439-.193-.14-.514-.027-.69.075ZM50.607 66.918a17.836 17.836 0 0 1 4.267 2.947c.18.168 1.05-.2.915-.325a17.838 17.838 0 0 0-4.267-2.948c-.249-.123-1.011.278-.915.326Z'
            />
            <path
                fill='#6A79A8'
                d='M51.29 72.344c-.22-1.504-1.407-2.859-.929-4.444.387-1.286 1.785-1.925 2.075-3.251.016-.032.01-.057-.02-.076a11.76 11.76 0 0 0-2.594-1.311c-.974-.35-1.944-.6-2.742-1.293-.191-.166-1.05.209-.914.327.942.817 2.135 1.03 3.26 1.489.464.19 1.51.52 1.807.934.429.598-.317 1.25-.716 1.69-.683.75-1.283 1.534-1.24 2.607.05 1.274.863 2.35 1.043 3.588.026.181 1.004-.02.97-.26ZM27.232 61.977c-.932.917-1.761 1.919-2.956 2.5-.635.307-1.52.591-1.994 1.145-.67.782.068 1.198.38 1.989.495 1.25.346 2.535-.289 3.699-.24.442.737.392.915.065.453-.83.709-1.707.648-2.662a4.567 4.567 0 0 0-.36-1.507c-.191-.444-.766-1.004-.363-1.496.198-.243.883-.465 1.153-.596.403-.196.827-.355 1.199-.602.929-.617 1.672-1.537 2.46-2.313.418-.411-.526-.486-.793-.222ZM28.953 61.255l1.538-1.669c.157-.17-.135-.24-.251-.242-.224-.003-.489.086-.643.253l-1.538 1.669c-.157.17.135.24.251.242.224.003.489-.086.643-.254ZM46.332 61.025a58.076 58.076 0 0 0-1.403-1.852c-.29-.367-1.206.03-.884.44.48.607.948 1.224 1.403 1.851.275.379 1.192-.014.884-.439ZM34.675 52.686c1.342 2.253 5.198 2.662 6.357-.021.215-.498-.759-.477-.923-.098-.925 2.141-3.59 1.303-4.545-.301-.213-.358-1.141-.004-.89.42Z'
            />
            <path
                fill='#BBDFF5'
                d='M38.475 76.646a18.28 18.28 0 0 0-.013-.207c-.073-1.05-.268-5.802-1.233-5.903l-.354-.038c-.276-.029-.532.256-.572.637a.75.75 0 0 1-.008.054c-.296 1.813-.595 3.627-.886 5.442 1.022.03 2.044.022 3.066.015Z'
            />
            <path
                fill='#6A79A8'
                d='M38.844 76.617c-.064-1.015-.12-2.03-.234-3.04-.1-.889-.132-2.3-.762-3.018-.403-.46-1.287-.45-1.673.027-.472.583-.42 1.677-.534 2.377-.201 1.231-.402 2.462-.6 3.694-.02.13.126.235.243.237 1.053.026 2.106.025 3.16.017.415-.003.557-.538.064-.535-.992.007-1.982.012-2.973-.012.08.08.162.158.243.238.27-1.685.549-3.37.823-5.054.052-.322.135-1.028.582-.668.226.18.313.69.379.944.403 1.57.444 3.236.545 4.847.024.382.757.27.737-.055Z'
            />
            <path
                fill='#BBDFF5'
                d='M36.4 68.075c.15-.202.347-.292.632-.06.408.33.7.925.977 1.364.228.364.337.619-.183.754-.459.12-1.012.082-1.486.12-.758.062-.409-1.17-.262-1.515.09-.207.186-.481.322-.663Z'
            />
            <path
                fill='#6A79A8'
                d='M36.736 68.076c-.097.114.104.21.18.296.102.115.192.24.278.367.147.22.277.45.411.676.065.108.15.215.19.336.06.182.036.165-.108.234-.27.128-.756.056-1.058.067-.219.008-.31 0-.375-.23-.054-.188-.012-.413.026-.6.08-.391.238-.815.456-1.146.173-.263-.55-.187-.673 0-.321.486-.692 1.338-.536 1.941.075.291.293.416.582.432a6.334 6.334 0 0 0 1.62-.116c.296-.065.807-.2.83-.575.023-.348-.373-.792-.544-1.068-.223-.362-.51-.85-.95-.977-.374-.107-.763.082-1.002.362-.114.135.099.188.192.194a.633.633 0 0 0 .481-.193Z'
            />
            <path
                fill='#fff'
                d='M63.493 49.803c-.057 10.833-6.83 20.818-17.059 24.571-10.298 3.78-22.15.48-29.095-7.98-6.938-8.449-7.883-20.662-2.306-30.072 5.6-9.448 16.834-14.395 27.586-12.195 10.523 2.153 18.824 10.914 20.538 21.495.224 1.384.328 2.78.336 4.181.002.529.987.402.984-.062-.058-11.238-7.184-21.5-17.798-25.311-10.608-3.808-22.794-.49-30.025 8.142-7.293 8.706-8.282 21.443-2.394 31.16 5.86 9.67 17.425 14.635 28.483 12.436C53.648 74 62.356 64.996 64.133 54.021c.23-1.416.337-2.846.344-4.28.003-.528-.982-.402-.984.062Z'
            />
            <path
                fill='#6A79A8'
                d='M64.027 49.804c-.056 11.064-6.99 21.279-17.449 25.088-10.534 3.837-22.628.45-29.705-8.22-7.034-8.619-7.976-21.044-2.303-30.62C20.29 26.399 31.776 21.35 42.76 23.61c10.703 2.204 19.15 11.11 20.917 21.872.235 1.43.344 2.873.351 4.322.003.529.987.402.985-.062-.059-11.474-7.346-21.953-18.189-25.828-10.838-3.873-23.278-.458-30.635 8.38-7.389 8.878-8.376 21.826-2.39 31.71 5.953 9.833 17.7 14.908 28.952 12.71 11.14-2.178 20.068-11.344 21.903-22.551.24-1.463.351-2.94.359-4.421.002-.528-.983-.402-.985.062Z'
            />
            <path
                fill='#fff'
                d='M41.242 148.102h76.861a4.816 4.816 0 0 0 4.816-4.816v-15.391l5.881-5.881-5.881-5.881v-15.39a4.816 4.816 0 0 0-4.816-4.816H41.242a4.816 4.816 0 0 0-4.816 4.816v42.543a4.816 4.816 0 0 0 4.816 4.816Z'
            />
            <path
                fill='#6A79A8'
                d='M41.202 148.466h75.971c.475 0 .96.022 1.433-.03a5.381 5.381 0 0 0 4.805-5.268c.081-4.036 0-8.08 0-12.116v-2.529c0-.192-.011-.388 0-.579.009-.165-.212.188.043-.026 1.595-1.338 3-3 4.472-4.472l1.282-1.282c.083-.083.129-.273.035-.367l-2.591-2.591c-1.024-1.024-2.012-2.282-3.163-3.162-.152-.118-.124-.148-.078.032-.022-.085-.003-.215 0-.301.016-.459 0-.921 0-1.38v-4.999c0-2.861.038-5.725 0-8.587-.042-3.098-2.435-5.226-5.465-5.248-3.365-.025-6.73 0-10.095 0H42.398c-1.664 0-3.249.134-4.605 1.285-1.81 1.537-1.859 3.55-1.859 5.682v37.93c0 1.428-.204 3.156.27 4.528.747 2.165 2.769 3.418 4.998 3.48.562.016.734-.713.08-.731-2.458-.069-4.31-2.101-4.364-4.515-.01-.413 0-.828 0-1.241v-28.021c0-4.393-.084-8.796 0-13.189.052-2.75 2.102-4.477 4.74-4.477h74.978c1.438 0 2.784-.05 4.021.91 2.402 1.865 1.77 5.486 1.77 8.166v9.802c0 .599-.108 1.005.31 1.439 1.057 1.099 2.158 2.159 3.237 3.237l2.383 2.384.035-.367-3.837 3.837c-.622.622-1.332 1.216-1.894 1.894-.221.267-.226.379-.234.723-.092 4.195 0 8.401 0 12.597 0 1.658.219 3.491-.756 4.939-.913 1.355-2.357 1.877-3.918 1.882-1.925.006-3.85 0-5.775 0H46.882c-1.776 0-3.58-.09-5.354 0-.081.004-.164 0-.246 0-.561 0-.737.731-.08.731Z'
            />
            <path
                fill='#6A79A8'
                d='M106.547 128.689h-61.95c-.567 0-.733.747-.077.747h61.951c.567 0 .733-.747.076-.747ZM115.276 134.407H47.492c-.9 0-1.872-.103-2.77 0-.04.005-.084 0-.125 0-.567 0-.733.746-.077.746H112.304c.901 0 1.873.104 2.77 0 .041-.004.084 0 .126 0 .566 0 .732-.746.076-.746ZM93.003 139.625H44.597c-.567 0-.733.746-.076.746h48.406c.566 0 .732-.746.076-.746ZM116.177 122.555H53.561c-2.881 0-5.822-.172-8.7 0-.041.002-.083 0-.124 0-.414 0-1.01.625-.357.625h62.616c2.881 0 5.822.171 8.7 0 .041-.003.083 0 .124 0 .414 0 1.01-.625.357-.625Z'
            />
            <path
                fill='#F4CAC1'
                d='M90.915 115.961h-44.95a2.308 2.308 0 0 1-2.308-2.308v-5.819a2.308 2.308 0 0 1 2.308-2.308h44.95a2.308 2.308 0 0 1 2.308 2.308v5.819a2.308 2.308 0 0 1-2.308 2.308Z'
            />
            <path
                fill='#fff'
                d='M88.556 110.002H48.478c-1.13 0-1.468 1.484-.154 1.484H88.4c1.13 0 1.468-1.484.155-1.484Z'
            />
            <path
                fill='#6A79A8'
                d='M111.609 127.324h.457c.209 0 .509-.077.644-.249.139-.179-.122-.228-.248-.228h-.456c-.209 0-.509.077-.644.25-.139.178.121.227.247.227ZM111.62 129.09h.457c.216 0 .513-.07.639-.266.122-.19-.104-.29-.264-.29h-.457c-.216 0-.514.07-.639.266-.122.19.104.29.264.29ZM113.722 129.856c.95-.087 1.574-.818 1.664-1.748.083-.871-.302-1.935-1.285-2.025-.198-.018-.547.015-.685.183-.104.127-.159.27.078.292.587.054.847.729.913 1.237.042.329-.016 1.547-.534 1.594-.445.041-.828.529-.151.467ZM97.155 138.353l-.095-1.509a11.484 11.484 0 0 1-.026-.634c0-.174.047-.31.139-.407a.47.47 0 0 1 .361-.145c.18 0 .301.063.362.189.06.125.09.305.089.54 0 .139-.008.279-.024.422l-.14 1.553a1.21 1.21 0 0 1-.097.425.249.249 0 0 1-.24.147c-.113 0-.192-.048-.236-.144a1.439 1.439 0 0 1-.093-.437Zm.342 2.075a.5.5 0 0 1-.334-.126.436.436 0 0 1-.142-.349c0-.13.046-.241.138-.332a.459.459 0 0 1 .336-.136.468.468 0 0 1 .338.139c.093.092.14.203.139.333a.44.44 0 0 1-.143.346.49.49 0 0 1-.332.125Z'
            />
            <path
                fill='#fff'
                d='M189.808 122.015c0 14.962-12.128 27.09-27.09 27.09-14.961 0-27.09-12.128-27.09-27.09s12.129-27.09 27.09-27.09c14.962 0 27.09 12.128 27.09 27.09Z'
            />
            <path
                fill='#F7E080'
                d='M176.648 145.139c-2.662-7.982-6.14-14.15-10.691-16.453-9.488-4.802-14.628 7.198-17.274 15.922 1.072.478 2.078 1.079 3.128 1.602.113-.002.235.016.365.063 1.415.513 2.804 1.09 4.207 1.634 2.334.34 4.735.403 7.076.574.306.023.615.05.924.076a.97.97 0 0 1 .569-.179c1.763-.022 3.517-.31 5.255-.629 1.45-.537 2.918-1.022 4.378-1.529a58.565 58.565 0 0 0 2.063-1.081Z'
            />
            <path
                fill='#FFEEA7'
                d='M175.324 145.844c-2.55-8.112-5.909-14.417-10.327-16.755-8.67-4.589-13.711 7.059-16.291 15.529-.052.226 2.635 1.307 2.912 1.443 1.814.889 3.804 1.44 5.787 1.831a30.59 30.59 0 0 0 8.736.446c2.984-.278 5.651-1.15 8.46-2.126.242-.122.483-.244.723-.368Z'
            />
            <path
                fill='#fff'
                d='M160.007 138.986a2.672 2.672 0 0 1-1.202.057c-.511-.102-.97-.378-1.403-.668a17.112 17.112 0 0 1-2.113-1.672c-.241-.222-.508-.406-.773-.596-.241-.173-.429-.419-.608-.657-.308-.411-.733-.979-.84-1.492-.048-.228.182-.447.317-.623.187-.244.388-.478.57-.726a17.314 17.314 0 0 1 1.961-2.271c2.841-2.723 6.479-3.454 10.041-1.651 2.085 1.055 3.788 2.815 5.124 4.706.202.286.229.402.123.721a10.44 10.44 0 0 1-.335.882c-.247.563-.555 1.112-.968 1.571-.697.775-1.521 1.46-2.371 2.06-.162.114-.317.284-.496.361-.237.102-.528.13-.782.142a3.927 3.927 0 0 1-1.577-.281 6.993 6.993 0 0 1-1.262-.662c-.337-.217-.697-.698-1.149-.496-.259.117-.494.369-.727.532a6.194 6.194 0 0 1-.872.52c-.211.1-.431.184-.658.243Z'
            />
            <path
                fill='#fff'
                d='M170.491 119.183c-.08-8.201-3.755-14.815-8.209-14.772-4.454.043-8.001 6.727-7.921 14.928l.014 1.47c.044 4.547.461 7.712 1.159 9.91 1.435 4.52 6.896 6.359 6.896 6.359s5.555-1.91 6.943-5.976c.76-2.226 1.18-5.534 1.132-10.449l-.014-1.47Z'
            />
            <path
                fill='#F9AB98'
                d='M170.013 131.45c2.15.248 4.758-.076 6.598-1.13 3.153-1.805 3.091-5.977 2.874-9.147a29.374 29.374 0 0 0-3.002-11.07c-1.274-2.552-2.961-4.954-5.244-6.664-1.265-.948-2.718-1.664-4.256-2.038-1.616-.393-3.044-.089-4.639-.092-2.431-.003-4.797.81-6.981 1.877-1.39.679-2.818 1.562-3.407 2.992-1.565 3.794 3.412 7.469 6.654 7.936 1.781.256 3.636-.103 5.212-.965 1.249-.682 3.151-2.084 3.183-3.652-.045 2.197 1.16 4.368 1.045 6.501-.151 2.779-.464 5.483-.339 8.279.084 1.862-.33 6.87 2.302 7.173Z'
            />
            <path
                fill='#6A79A8'
                d='M155.929 132.695c1.025 1.522 2.415 2.706 4.007 3.607.622.352 1.542 1 2.293 1.005.767.004 1.745-.645 2.38-.982 1.606-.851 3.094-1.944 4.213-3.387.222-.287-.724-.177-.887.034-1.413 1.821-3.509 3.2-5.682 3.959l.57-.06c-2.325-.796-4.552-2.398-5.925-4.436-.182-.269-1.082.093-.969.26Z'
            />
            <path
                fill='#F7E080'
                d='M154.63 136.507c.567.665 1.068 1.367 1.828 1.802.897.513 1.704.857 2.753.931.981.07 1.701-.757 2.331-1.445.344-.377 1.413.093 1.69.316.537.433.897.953 1.601.995.64.037 1.898.344 2.452.009 1.192-.72 2.082-1.878 2.912-2.968.573-.752 1.651.016 1.748.713.137.991-1.089 1.991-1.752 2.584-.803.717-1.747 1.278-2.818 1.47-1.742.311-3.135-.511-4.426-1.502-.989.909-2.257 1.655-3.605 1.523-1.445-.141-2.758-.867-3.882-1.75-.675-.531-1.412-1.098-1.804-1.883-.128-.257-.415-.873-.158-1.118.266-.254.946.108 1.13.323Z'
            />
            <path
                fill='#6A79A8'
                d='M170.12 135.502c-.877 1.22-1.882 2.736-3.326 3.322-1.071.435-1.869.073-2.693-.589-.205-.165-1.324-1.049-1.228-1.177.225-.299-.728-.142-.886.038-.451.511-.901 1.086-1.46 1.487-1.06.761-2.067.287-2.999-.317-1.2-.776-2.182-1.861-3.069-2.968-.167-.208-1.093.118-.908.349 1.244 1.552 2.986 3.55 5.096 3.825 1.819.237 3.137-1.18 4.226-2.414l-.886.038-.122.162c-.035.047-.067.107-.011.155 1.433 1.213 2.647 2.5 4.672 1.928 2.01-.568 3.316-2.256 4.481-3.877.211-.295-.734-.175-.887.038ZM150.367 137.809c-2.106 1.095-4.076 2.392-5.793 4.038-.432.415.505.561.807.272 1.599-1.534 3.449-2.739 5.41-3.759.586-.305.058-.802-.424-.551ZM163.467 126.666c.174.276-.473 1.324-.584 1.517-.394.686-.955 1.284-1.675 1.629-.579.277-1.246-.039-1.731-.367-.344-.234-2.179-1.661-1.429-2.125.107-.067.239-.077.365-.086 1.161-.076 2.346-.094 3.494-.291.259-.045.517-.099.771-.164.187-.047.503-.214.695-.175a.142.142 0 0 1 .094.062Z'
            />
            <path
                fill='#6A79A8'
                d='M177.132 145.01c-1.314-3.92-2.914-7.779-5.194-11.246-.378-.573-.769-1.006-1.263-1.475-.414-.393-.905-.722-.783-1.321.265-1.297.646-2.53.807-3.858.391-3.212.442-6.56.205-9.789-.243-3.323-1.063-6.766-2.893-9.592-1.241-1.917-3.149-3.639-5.555-3.647-2.246-.008-4.109 1.449-5.369 3.198-1.871 2.597-2.743 5.881-3.072 9.025-.349 3.337-.198 6.859.177 10.185.093.826.213 1.649.377 2.464.074.37.157.738.253 1.102.044.171.243.702.264.598-.072.353-.743.905-.975 1.187-.27.33-.541.651-.78 1.004-2.411 3.562-3.888 7.721-5.136 11.808-.128.419.859.302.978-.088 1.076-3.525 2.343-7.047 4.205-10.242.58-.994 1.233-1.868 1.959-2.753.175-.212.49-.475.598-.727.166-.388-.096-.849-.198-1.257-.759-3.055-.848-6.252-.878-9.384-.032-3.265.243-6.534 1.433-9.607.875-2.259 2.413-5.052 4.948-5.725 2.452-.651 4.562 1.278 5.811 3.166 1.784 2.695 2.583 6.021 2.857 9.206.275 3.186.202 6.514-.155 9.688-.154 1.369-.402 2.72-.81 4.038-.341 1.102.892 1.658 1.534 2.403 1.333 1.547 2.309 3.662 3.172 5.492.979 2.077 1.785 4.231 2.514 6.406.138.411 1.104.143.969-.259Z'
            />
            <path
                fill='#fff'
                d='M169.62 145.088c-.3-.596-.423-1.388-.298-2.225.245-1.637 1.35-2.829 2.467-2.662 1.117.167 1.824 1.63 1.579 3.268-.123.819-.46 1.526-.906 2.008l-2.842-.389Z'
            />
            <path
                fill='#6A79A8'
                d='M170.104 144.956a3.856 3.856 0 0 1-.232-2.457c.196-.843.906-2.332 2.005-1.856 1.706.739 1.06 3.65.181 4.698-.39.466.534.592.806.267 1.245-1.485 1.759-5.53-1.016-5.757-2.656-.217-3.557 3.447-2.713 5.365.183.414 1.142.133.969-.26Z'
            />
            <path
                fill='#6A79A8'
                d='M171.095 142.874c-.184 1.79-.641 3.201-1.84 4.577-.243.279.709.191.892-.019 1.226-1.407 1.722-2.859 1.912-4.7.034-.329-.935-.141-.964.142Z'
            />
            <path
                fill='#fff'
                d='M189.25 122.046c-.056 10.833-6.83 20.817-17.059 24.571-10.298 3.778-22.149.478-29.095-7.98-6.938-8.449-7.883-20.663-2.306-30.073 5.6-9.448 16.834-14.395 27.585-12.195 10.524 2.154 18.825 10.915 20.538 21.495.225 1.384.329 2.78.337 4.182.002.528.988.401.984-.063-.059-11.237-7.184-21.5-17.798-25.31-10.608-3.809-22.794-.492-30.025 8.141-7.293 8.706-8.283 21.443-2.394 31.161 5.859 9.67 17.425 14.634 28.483 12.435 10.904-2.168 19.613-11.172 21.391-22.147.23-1.416.337-2.845.343-4.28.004-.528-.982-.402-.984.063Z'
            />
            <path
                fill='#6A79A8'
                d='M189.784 122.045c-.056 11.063-6.989 21.278-17.448 25.087-10.534 3.837-22.628.451-29.705-8.22-7.035-8.618-7.976-21.043-2.303-30.619 5.72-9.655 17.206-14.704 28.189-12.443 10.703 2.205 19.15 11.111 20.916 21.872.235 1.43.345 2.874.351 4.323.004.528.988.401.986-.063-.058-11.474-7.347-21.953-18.189-25.828-10.838-3.873-23.278-.457-30.635 8.381-7.389 8.877-8.376 21.825-2.391 31.709 5.954 9.833 17.702 14.908 28.953 12.709 11.14-2.177 20.068-11.343 21.903-22.55a28.46 28.46 0 0 0 .359-4.421c.002-.528-.984-.402-.986.063Z'
            />
        </svg>
    );
}
