import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Territory from './icon-territory.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Territory {...validProps} />;
}
SvgComponent.iconName = 'territory';

export const TerritoryIcon = svgIconWrapper(SvgComponent);
