/* @flow */

import * as React from 'react';

import type {DisplayOptionEntity} from 'nutshell-core/utils';

import {Body} from '../typography';
import {Avatar} from '../avatar';

import './entity-select-row.css';

type Props = {
    entity: DisplayOptionEntity,
};

export function EntitySelectRow(props: Props) {
    const {entity} = props;

    return (
        <div styleName={entity.isDisabled ? 'row--disabled' : 'row'}>
            <div styleName='avatar'>
                <Avatar
                    avatarUrl={entity.avatarUrl}
                    initials={entity.initials}
                    type={entity.type}
                    size={32}
                />
            </div>
            <div styleName='info'>
                <span>
                    <Body size='small' isBold={true}>
                        {entity.value}
                    </Body>{' '}
                    {entity.isDisabled && entity.disabledText ? (
                        <Body size='small' isItalic={true}>
                            ({entity.disabledText})
                        </Body>
                    ) : (
                        undefined
                    )}
                </span>
                {entity.secondaryInfo ? (
                    <Body size='small' color='person'>
                        {entity.secondaryInfo}
                    </Body>
                ) : (
                    undefined
                )}
                {entity.tertiaryInfo ? (
                    <Body size='small' color='light'>
                        {entity.tertiaryInfo}
                    </Body>
                ) : (
                    undefined
                )}
            </div>
        </div>
    );
}
