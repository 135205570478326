/* @flow */

import type {NutshellState} from '../../store';
import {getUIState as getNutshellUiState} from '../ui-selectors';

export const getTinyIntroState = (state: NutshellState) =>
    getNutshellUiState(state).tinyIntroduction;

export const getUserEmail = (state: NutshellState) => {
    return getTinyIntroState(state).userEmail;
};

export const getSignupToken = (state: NutshellState) => {
    return getTinyIntroState(state).userSignupToken;
};

export const getIsAccountProvisioned = (state: NutshellState) => {
    return getTinyIntroState(state).userAccountProvisionResponse.status;
};

export const getAccountProvisionedResponse = (state: NutshellState) => {
    return getTinyIntroState(state).userAccountProvisionResponse;
};

export const getAccountProvisionedErrorMessage = (state: NutshellState) => {
    return getTinyIntroState(state).userAccountProvisionedErrorMessage;
};
