/* @flow */

import * as React from 'react';
import './inline-text-button.css';

type Props = {
    children: React.Node,
    onClick: (e: SyntheticEvent<*>) => void,
};

export function InlineTextButton(props: Props) {
    return (
        <button styleName='inline-button' type='button' onClick={props.onClick}>
            {props.children}
        </button>
    );
}
