/* @flow */

import * as React from 'react';
import {useQuery} from '@apollo/react-hooks';

import type {
    GetTagsBasic as GetTagsQuery,
    GetTagsVariables as GetTagsQueryVariables,
    TagType,
} from 'nutshell-graphql-types';

import {type ListOption} from 'shells/select-option-components';
import {getColorNameFromTagColor} from 'shells/colors';

import GET_TAGS from './queries/tags.graphql';

// We want to get all tags so don't have to refetch when choosing a drip sequence
// start trigger. Refetch causes problems populating the selected tag card when
// reopening the start trigger sidebar if the selected tag isn't within the first
// batch that is fetched. This query is quick, so we will just get all the tag options
// to populate the list.
const FIRST = 1000;

type Props = {|
    tagType: TagType,
    searchTerm: string,
    children: (ChildrenProps) => React.Node,
|};

type ChildrenProps = {
    options: ListOption[],
    isLoading: boolean,
    refetch: () => Promise<*>,
    fetchMoreTags: () => Promise<*>,
};

export const GraphQLSelectTagsProvider = (props: Props) => {
    const {data, loading, refetch, fetchMore} = useQuery<GetTagsQuery, GetTagsQueryVariables>(
        GET_TAGS,
        {
            variables: {
                first: FIRST,
                input: {
                    entityType: props.tagType,
                    searchTerm: props.searchTerm || undefined,
                },
            },
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true,
        }
    );

    const tags = data && data.tags ? data.tags.edges.map((edge) => edge.node) : [];
    const endCursor = data && data.tags && data.tags.pageInfo && data.tags.pageInfo.endCursor;

    const fetchMoreTags = () => {
        // $FlowIgnore - Unsure what error flow is showing, but this works
        return fetchMore({
            variables: {
                first: FIRST,
                after: endCursor,
            },
            updateQuery: (prev: GetTagsQuery, newResults: {fetchMoreResult: GetTagsQuery}) => {
                const oldTags = prev ? prev.tags : {};
                const oldTagEdges = (oldTags && oldTags.edges) || [];
                const fetchedTags = newResults.fetchMoreResult.tags;
                const fetchedTagEdges = (fetchedTags && fetchedTags.edges) || [];
                const newTags = {
                    ...oldTags,
                    pageInfo: fetchedTags ? fetchedTags.pageInfo : undefined,
                    edges: oldTagEdges.concat(fetchedTagEdges),
                };

                return {tags: newTags};
            },
        });
    };

    // map over the list to get the proper value/label options to pass
    // through to the select component below
    const tagOptions: ListOption[] = tags
        ? tags.map((edge) => ({
              value: edge.id,
              label: edge.name,
              colorName: getColorNameFromTagColor(edge.color),
          }))
        : [];

    return props.children({
        options: tagOptions,
        isLoading: loading,
        refetch,
        fetchMoreTags,
    });
};
