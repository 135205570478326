/* @flow */

import * as React from 'react';

import type {ReactionType} from 'nutshell-graphql-types';

import {ReactionBar, type Reactions} from 'shells/timeline/reaction-bar';

import './timeline-reaction-bar.css';

type Props = {
    reactions: Reactions,
    currentUserId: string,
    onToggleReaction: (reactionType: ReactionType) => any,
};

export function TimelineReactionBar(props: Props) {
    const {reactions, onToggleReaction, currentUserId} = props;

    return Object.keys(reactions).length ? (
        <div styleName='reaction-bar-container'>
            <ReactionBar
                reactions={reactions}
                currentUserId={currentUserId}
                onToggleReaction={onToggleReaction}
            />
        </div>
    ) : null;
}
