/* @flow */

import * as React from 'react';

import {Timestamp} from './timestamp';

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;

type Props = {
    durationSeconds: number,
};

export function DurationTimestamp(props: Props) {
    const {durationSeconds} = props;

    let seconds = durationSeconds;
    let timestamp = '';

    if (seconds >= SECONDS_IN_HOUR) {
        const hours = Math.floor(seconds / SECONDS_IN_HOUR);
        timestamp = timestamp.concat(`${hours}h`);
        seconds %= SECONDS_IN_HOUR;
    }

    if (seconds >= SECONDS_IN_MINUTE) {
        const minutes = Math.floor(seconds / SECONDS_IN_MINUTE);
        timestamp = timestamp.concat(`${timestamp ? ' ' : ''}${minutes}m`);
        seconds %= SECONDS_IN_MINUTE;
    }

    if (seconds) {
        timestamp = timestamp.concat(`${timestamp ? ' ' : ''}${seconds}s`);
    }

    return <Timestamp timestamp={timestamp} />;
}
