import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import GoalLeadFields from './icon-goal-lead-fields.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <GoalLeadFields {...validProps} />;
}
SvgComponent.iconName = 'goal-custom-fields';

export const GoalLeadFieldsIcon = svgIconWrapper(SvgComponent);
