/* @flow */

import * as React from 'react';

import {IconButton} from '../../../button';

import type {EventType} from '../../types';

import type {ToolbarOption} from './timeline-entry-toolbar';

export function getNounForEntryType(entryType: EventType): string {
    switch (entryType) {
        case 'Activity':
            return 'activity';
        case 'Note':
            return 'note';
        case 'Email':
            return 'email';
        default:
            return '';
    }
}

export function renderOptionForToolbar(option: ToolbarOption): React.Node {
    if (option.icon) {
        return (
            <IconButton
                key={option.id}
                icon={option.icon}
                onClick={option.onClick}
                tooltipText={option.tooltipText}
                isDisabled={option.isDisabled}
            />
        );
    }

    // The null case shouldn't ever happen, but flow is confused
    return option.component ? (
        <React.Fragment key={option.id}>{option.component}</React.Fragment>
    ) : null;
}
