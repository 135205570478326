/* @flow */

import * as React from 'react';

import {Popover} from 'shells/popover';
import './file-list.css';

type Props = {
    anchor: any,
    files: {
        filename: string,
        href: string,
    }[],
    onClose: () => any,
};

export class FileList extends React.PureComponent<Props> {
    render() {
        return (
            <Popover
                anchor={this.props.anchor}
                location='bottom-left'
                onClose={this.props.onClose}
                onBlur={this.props.onClose}
            >
                <ul styleName='container'>
                    {this.props.files.map((file) => (
                        <li styleName='option-container' key={file.href}>
                            <a onClick={this.props.onClose} href={file.href}>
                                {file.filename}
                            </a>
                        </li>
                    ))}
                </ul>
            </Popover>
        );
    }
}
