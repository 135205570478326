/* @flow */

import {createSelector} from 'reselect';
import {getUIState} from '../ui-selectors';

const getTasksUIState = createSelector([getUIState], (uiState) => uiState.messages);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getDismissedMessages = createSelector(
    [getTasksUIState],
    (dismissedMessages) => dismissedMessages.dismissedMessageIds
);
