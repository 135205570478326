/* @flow */

import * as React from 'react';

// Example usage:
//
// const [search, setSearch] = React.useState<string>(props.search);
// const debouncedSearch = useDebounce(search, 200);

// React.useEffect(() => {
//     props.callbackFunction(debouncedSearch);
// }, [debouncedSearch]);

// Reusable debounce hook, adapted from the internet
export function useDebounce(value: any, delay: number) {
    const [debouncedValue, setDebouncedValue] = React.useState(value);

    React.useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, [value, delay]);

    return debouncedValue;
}
