/* @flow */

import * as React from 'react';
import {components, type NoticeProps} from 'react-select';

import {colors} from '../colors';
import type {NoOptionsMessageProps} from './select-picker-generic';

export function getNoOptionsMessage(customNoOptionsMessage?: NoOptionsMessageProps) {
    if (customNoOptionsMessage) {
        return ({children, ...props}: {...NoticeProps}) => (
            <components.NoOptionsMessage {...props}>
                {props.selectProps.options && !props.selectProps.options.length ? (
                    <div
                        className='flex flex-dir-col align-center gap-16 text-align-center full-width border-box pb-8'
                        style={{color: colors.grey}}
                    >
                        <div>{customNoOptionsMessage.icon}</div>
                        <div>{customNoOptionsMessage.text}</div>
                        {customNoOptionsMessage.cta}
                    </div>
                ) : (
                    children
                )}
            </components.NoOptionsMessage>
        );
    }

    return components.NoOptionsMessage;
}
