/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {GraphQLSelectParticipants} from '../../select/graphql-select-participants';

type Props = {
    ...FieldProps,
    isMulti: boolean,
};

export function ParticipantsSelect(props: Props) {
    return (
        <GraphQLSelectParticipants
            isMulti={props.isMulti}
            onChange={(newParticipants) => {
                props.input.onChange(newParticipants);
            }}
            selectedValues={props.input.value || []}
            clearable={!props.isMulti}
            onClearValue={() => {
                props.input.onChange(null);
            }}
        />
    );
}
