/* @flow */

import * as React from 'react';

import {Sidebar} from '../sidebar';
import {CloseButton} from '../button';
import {ButtonMover} from '../button-mover';
import {LoadingPage} from '../loading-page';

import type {Person} from './visitor-content/company-people/company-people';
import {VisitorContent, type Company} from './visitor-content/visitor-content';
import {SessionsContent} from './sessions-content/sessions-content';
import {PersonDetailsContent} from './person-details-content/person-details-content';

import './visitor-sidebar.css';

export type ContentView = 'VISITOR' | 'SESSIONS' | {personId: string};

type Props = {|
    onClose: () => any,
    company: ?{|...Company, sessionsTimeline: React.Node|},
    people: ?(Person[]),
    onAddPeople: (peopleIds: string[]) => Promise<*>,
    onAddCompany: (peopleIds: string[], buttonRef: any, onConfirmAdd: () => void) => any,
    isDisabled?: boolean,
    unlocksRemaining: ?number,
|};

export function VisitorSidebar(props: Props) {
    const {company} = props;

    const [contentView, setContentView] = React.useState<ContentView>('VISITOR');

    let sidebarContent = <LoadingPage padding={0} />;
    if (company) {
        const {sessionsTimeline, ...restCompany} = company;
        if (typeof contentView === 'string') {
            switch (contentView) {
                case 'VISITOR':
                    sidebarContent = (
                        <VisitorContent
                            company={{...restCompany}}
                            people={props.people}
                            setContentView={(view) => setContentView(view)}
                            onClose={props.onClose}
                            onAddPeople={props.onAddPeople}
                            onAddCompany={props.onAddCompany}
                            unlocksRemaining={props.unlocksRemaining}
                        />
                    );
                    break;
                case 'SESSIONS':
                    sidebarContent = (
                        <SessionsContent
                            session={company.session}
                            sessionsTimeline={sessionsTimeline}
                            onClickBack={() => setContentView('VISITOR')}
                        />
                    );
                    break;
            }
        } else {
            const selectedPerson = props.people
                ? props.people.find((person) => person.id === contentView.personId)
                : undefined;
            if (selectedPerson) {
                sidebarContent = (
                    <PersonDetailsContent
                        person={selectedPerson}
                        onClickBack={() => setContentView('VISITOR')}
                    />
                );
            }
        }
    }

    return (
        <Sidebar
            isOpen={true}
            preventBodyScroll={false}
            canClickOutsideToClose={true}
            canEscapeToClose={true}
            onClose={props.onClose}
        >
            <div styleName={props.isDisabled ? 'content-container--disabled' : 'content-container'}>
                <div styleName='close-button'>
                    <ButtonMover size='normal' direction={['right', 'up']}>
                        <CloseButton onClick={props.onClose} />
                    </ButtonMover>
                </div>
                {sidebarContent}
            </div>
        </Sidebar>
    );
}
