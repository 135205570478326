/* @flow */

import {ApolloClient} from 'apollo-client';
import {InMemoryCache, IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';
import {HttpLink} from 'apollo-link-http';
import {ApolloLink} from 'apollo-link';
import {onError} from 'apollo-link-error';
import {toIdValue} from 'apollo-utilities';
import schema from '../../schema-graphql.json'; // eslint-disable-line import/extensions

// We get a 401 back from GraphQL when the user trying to make requests doesn't
// have a valid session and therefore is unauthorized to make requests
const RESPONSE_CODE_UNAUTHORIZED = 401;

// We get a 404 back when the user has _never_ established a valid session and therefore
// never got a valid GraphQL schema generated, and can't make requests
const RESPONSE_CODE_SESSION_NEVER_ESTABLISHED = 404;

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: schema.data,
});

// This is a very simple caching translation function to store all of our
// items in the cache by their id.
//
// We'll use this elsewhere to actual resolve some items from the cache
// by a similar mechanism
const cacheNormalizeFunction = (object) => object.id;

// Cache resolvers act as ways to resolve certain queries so that the request
// can find existing data in the cache without having to hit the server.
//
// For example, if we request a list of step templates, we have a list of ids of those
// step templates in our cache. Down the road, if a different query requests just a
// single step template, it won't automatically find it in the cache (you'd think it
// should, but it just auto-resolves in the cache by-query).
//
// So, we'll set up resolvers for those queries to take its input and using
// our resolver function look up in the cache to see if that piece of data
// already exists.
const cacheResolvers = {
    // Query and Mutation are top-level keys built into the InMemoryCache's
    // cache resolver
    // hello world
    Query: {
        // `stepTemplate` is the name of the Nutshell-defined query we're going
        // to resolve
        //
        // `toIdValue` ensures an ID type is returned.
        stepTemplate: (_, {id}) =>
            toIdValue(cacheNormalizeFunction({__typename: 'StepTemplate', id})),
        closedLeadSlackMessage: (_, {id}) =>
            toIdValue(cacheNormalizeFunction({__typename: 'ClosedLeadSlackMessage', id})),
        openLeadSlackMessage: (_, {id}) =>
            toIdValue(cacheNormalizeFunction({__typename: 'OpenLeadSlackMessage', id})),
        stageset: (_, {id}) => toIdValue(cacheNormalizeFunction({__typename: 'Stage', id})),
        stage: (_, {id}) => toIdValue(cacheNormalizeFunction({__typename: 'Stageset', id})),
        // We allow requesting a lead by number, and by id. We want to make sure that leads
        // already requested by number (and therefore exist in the cache) are used when
        // requesting the _same_ lead by id.
        //
        // @see: https://www.apollographql.com/docs/react/advanced/caching#cacheRedirect
        lead: (_, variables, helpers) =>
            helpers.getCacheKey({__typename: 'Lead', id: variables.id}),
    },
};

const cache = new InMemoryCache({
    cacheRedirects: cacheResolvers,
    dataIdFromObject: cacheNormalizeFunction,
    fragmentMatcher: fragmentMatcher,
});

const httpLink = new HttpLink({
    uri: (operation) => `/graphql?${operation.operationName}`,
    credentials: 'same-origin',
});

const errorLink = onError(({operation, graphQLErrors, networkError}) => {
    const {response} = operation.getContext();

    if (response && response.status === RESPONSE_CODE_UNAUTHORIZED) {
        if (typeof window.trackJs !== 'undefined') {
            window.trackJs.console.info(
                'Unauthorized (401) GraphQL Request! Logging request and logging out user…',
                operation
            );
        }

        // Quick Fix: 2022-03-25
        // Expired trial on billing page is getting constant refresh due to this line.
        // window.location.href = '/auth?sessionTimeout=1';
    }

    if (response && response.status === RESPONSE_CODE_SESSION_NEVER_ESTABLISHED) {
        if (typeof window.trackJs !== 'undefined') {
            window.trackJs.console.info(
                'Unauthorized (404) GraphQL Request! Logging request and logging out user…',
                operation
            );
        }

        window.location.href = '/auth?sessionTimeout=1';
    }

    if (typeof window.trackJs !== 'undefined') {
        window.trackJs.console.info('GraphQL Error! Logging request…', operation);

        if (graphQLErrors) {
            graphQLErrors.forEach(({message, path}, index) => {
                window.trackJs.console.info(
                    `Client error ${index}: Message: ${message}, Path: ${path}`
                );
            });
        }

        if (networkError) {
            window.trackJs.console.info('Logging GraphQL server errors…');
            window.trackJs.console.info('Network error:', networkError);
        }
    }
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const nutApolloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, httpLink]),
    cache: cache,
    // Normally, this isn't necessary in development, but there's some
    // bug in the chrome extension that actually needs this to be `true` for
    // it to work anywhere.
    connectToDevTools: true,
});
