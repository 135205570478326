/* @flow */

import * as ramda from 'ramda';
import {getLocationType} from './get-location-type';

type LocationFilter = {|
    selected: string,
    data?: Object,
|};

export function deserializeLocation(value: Object | string): LocationFilter {
    if (typeof value === 'string') {
        return {selected: 'noLocation'};
    } else if (typeof value === 'object' && Number(value.type) !== getLocationType('coordinates')) {
        return {
            selected: 'exactMatch',
            data: Object.assign(
                {selected: getLocationString(Number(value.type))},
                ramda.omit(['type'], value)
            ),
        };
    } else {
        return {
            selected: 'range',
            data: {
                data: {
                    coordinates: value.coordinates,
                    locationString: value.place,
                },
                selected: value.range,
            },
        };
    }
}

function getLocationString(chr) {
    switch (chr) {
        case 0:
            return 'country';
        case 1:
            return 'state';
        case 2:
            return 'postalCode';
        case 3:
            return 'areaCode';
        case 4:
            return 'city';
        case 5:
            return 'coordinates';
    }
}
