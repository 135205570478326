/* @flow */

import type {UpdateDefaultStagesetForOnboardingWizardInputFieldInput} from 'nutshell-graphql-types';
import type {SalesAutomationItemType} from '../../sales-automation/types';

import type {ActiveItem} from './types';

// Action Types
type SetActiveItemAction = {type: 'SALES_AUTOMATION_ACTIVE_ITEM_SET', payload: ActiveItem};
type ClearActiveItemAction = {type: 'SALES_AUTOMATION_ACTIVE_ITEM_CLEARED'};
type OnboardingOptionAddedAction = {
    type: 'SALES_AUTOMATION_ONBOARDING_OPTION_ADDED',
    payload: $Keys<UpdateDefaultStagesetForOnboardingWizardInputFieldInput>,
};
type OnboardingOptionRemovedAction = {
    type: 'SALES_AUTOMATION_ONBOARDING_OPTION_REMOVED',
    payload: $Keys<UpdateDefaultStagesetForOnboardingWizardInputFieldInput>,
};
type SetActiveStageHoverAction = {type: 'SALES_AUTOMATION_HOVERED_STAGE_SET', payload: ?string};
type SetSidebarFormSaveStatus = {
    type: 'SALES_AUTOMATION_SIDEBAR_FORM_SAVE_STATUS_SET',
    payload: boolean,
};

export type SalesAutomationUiAction =
    | SetActiveItemAction
    | ClearActiveItemAction
    | OnboardingOptionAddedAction
    | OnboardingOptionRemovedAction
    | {type: 'SALES_AUTOMATION_SECTION_EXPANSION_TOGGLED', payload: SalesAutomationItemType}
    | SetActiveStageHoverAction
    | SetSidebarFormSaveStatus;

export const ActionTypes = {
    SALES_AUTOMATION_ACTIVE_ITEM_SET: 'SALES_AUTOMATION_ACTIVE_ITEM_SET',
    SALES_AUTOMATION_ACTIVE_ITEM_CLEARED: 'SALES_AUTOMATION_ACTIVE_ITEM_CLEARED',
    SALES_AUTOMATION_SECTION_EXPANSION_TOGGLED: 'SALES_AUTOMATION_SECTION_EXPANSION_TOGGLED',
    SALES_AUTOMATION_ONBOARDING_OPTION_ADDED: 'SALES_AUTOMATION_ONBOARDING_OPTION_ADDED',
    SALES_AUTOMATION_ONBOARDING_OPTION_REMOVED: 'SALES_AUTOMATION_ONBOARDING_OPTION_REMOVED',
    SALES_AUTOMATION_HOVERED_STAGE_SET: 'SALES_AUTOMATION_HOVERED_STAGE_SET',
    SALES_AUTOMATION_SIDEBAR_FORM_SAVE_STATUS_SET: 'SALES_AUTOMATION_SIDEBAR_FORM_SAVE_STATUS_SET',
};

// Actions
export const setActiveItem = (item: ActiveItem): SetActiveItemAction => ({
    type: ActionTypes.SALES_AUTOMATION_ACTIVE_ITEM_SET,
    payload: item,
});

export const newActiveItem = (
    parentId: $PropertyType<ActiveItem, 'parentId'>,
    type: $PropertyType<ActiveItem, 'type'>
): SetActiveItemAction => ({
    type: ActionTypes.SALES_AUTOMATION_ACTIVE_ITEM_SET,
    payload: {type, parentId, entityId: null},
});

export const addNewStage = (): SetActiveItemAction => ({
    type: ActionTypes.SALES_AUTOMATION_ACTIVE_ITEM_SET,
    payload: {type: 'stages', parentId: 'new-stage', entityId: null},
});

export const setHoveredStageId = (stageId: ?string): SetActiveStageHoverAction => ({
    type: ActionTypes.SALES_AUTOMATION_HOVERED_STAGE_SET,
    payload: stageId,
});

export const clearActiveItem = (): ClearActiveItemAction => ({
    type: ActionTypes.SALES_AUTOMATION_ACTIVE_ITEM_CLEARED,
});

export const toggleSectionExpansion = (
    section: SalesAutomationItemType
): SalesAutomationUiAction => {
    return {type: ActionTypes.SALES_AUTOMATION_SECTION_EXPANSION_TOGGLED, payload: section};
};

export const setIsSidebarFormSaving = (isSaving: boolean): SalesAutomationUiAction => {
    return {type: ActionTypes.SALES_AUTOMATION_SIDEBAR_FORM_SAVE_STATUS_SET, payload: isSaving};
};

export const addOnboardingOption = (
    optionType: $PropertyType<OnboardingOptionAddedAction, 'payload'>
): OnboardingOptionAddedAction => ({
    type: ActionTypes.SALES_AUTOMATION_ONBOARDING_OPTION_ADDED,
    payload: optionType,
});

export const removeOnboardingOption = (
    optionType: $PropertyType<OnboardingOptionRemovedAction, 'payload'>
): OnboardingOptionRemovedAction => ({
    type: ActionTypes.SALES_AUTOMATION_ONBOARDING_OPTION_REMOVED,
    payload: optionType,
});
