/* @flow */
import * as ramda from 'ramda';
import {actionTypes} from 'redux-form';
import type {Action} from 'redux-form/lib/types'; // eslint-disable-line import/no-unresolved
import type {ChangeAction} from 'redux-form/lib/actions.types'; // eslint-disable-line import/no-unresolved
import {REHYDRATE} from 'redux-persist';
import {FORM_NAME} from './types';

/*
 * This is a redux-form reducer plugin, which allows us to update all email templates of a sequence
 * when the subject of one of them is changed.  This is necessary because we need to change the
 * subjects of emails that are marked as `sendAsReply`, since they're based on the subject of the
 * email they are in reply to.
 *
 * See https://redux-form.com/7.4.2/docs/api/reducerplugin.md/
 */
export const emailComposerFormReducerPlugin = {
    // This is for dynamically updating reply subjects in the email-sender
    // when the first email in a reply chain's subject changes.
    [FORM_NAME]: (state: any, action: Action) => {
        switch (action.type) {
            case actionTypes.CHANGE: {
                // Cast to a new type, since we now know it's a change action
                const changeAction: ChangeAction = (action: any);
                const fullFieldName = changeAction.meta.field;
                const fieldParts = fullFieldName.split('.');
                const fieldName = fieldParts[fieldParts.length - 1];

                // Make sure we're only doing it for email-sender form, and only
                // when it's the subject that's changed.
                if (changeAction.meta.form !== FORM_NAME || fieldName !== 'subject') {
                    return state;
                }

                const [sequencesKey, sequenceId, emailTemplatesKey, templateId] = fieldParts;

                const emailTemplates = ramda.values(
                    state.values.sequences[sequenceId].emailTemplates
                );

                // If there are fewer than 2 email templates, bail
                if (emailTemplates.length < 2) {
                    return state;
                }

                // If there are no templates marked sendAsReply, bail
                if (!emailTemplates.filter((template) => template.sendAsReply).length) {
                    return state;
                }

                const sortedTemplates = ramda.sortBy(ramda.prop('position'))(emailTemplates);

                // Find the index of the template whose subject is being changed.
                const currentIndex = sortedTemplates.findIndex(
                    (template) => template.id === templateId
                );

                // Just to be safe, bail if not found.
                if (currentIndex === -1) {
                    return state;
                }

                // For as long as the NEXT template is marked sendAsReply,
                // set that template's subject to our computed string.
                for (
                    let index = currentIndex + 1;
                    sortedTemplates[index] && sortedTemplates[index].sendAsReply;
                    index++
                ) {
                    sortedTemplates[index].subject = `Re: ${changeAction.payload}`;
                }

                // Replace the emailTemplates with our newly-updated ones
                return {
                    ...state,
                    values: {
                        ...state.values,
                        [sequencesKey]: {
                            ...state.values.sequences,
                            [sequenceId]: {
                                ...state.values.sequences[sequenceId],
                                // Gotta put it back into an object, keyed by id
                                [emailTemplatesKey]: sortedTemplates.reduce((accum, template) => {
                                    accum[template.id] = template;

                                    return accum;
                                }, {}),
                            },
                        },
                    },
                };
            }

            case REHYDRATE: {
                // Don't allow field registration to persist.  Otherwise fields can seem active when they aren't [NUT-11379].
                const persistedState = action.payload;
                if (!persistedState || !persistedState[FORM_NAME]) {
                    return state;
                }

                persistedState[FORM_NAME].registeredFields = {};

                return persistedState[FORM_NAME];
            }
            default:
                return state;
        }
    },
};
