/* @flow */

import {createSelector, type InputSelector, type OutputSelector} from 'reselect';
import type {User} from '../types';
import {Collections, type CollectionState} from '../collections';

type UsersModuleState = {users: CollectionState<User>};

/*
 * Given the entire redux state, pull out just the piece of state for the users reducer.
 */
const getUsersState = (state: UsersModuleState) => state.users;

/*
 * Get an array of all users models
 */
export const getAll: InputSelector<UsersModuleState, any, User[]> = Collections.makeGetModels(
    getUsersState
);

/*
 * Get the `byId` portion of the users state
 */
export const getById = (state: UsersModuleState) => {
    const usersState = getUsersState(state);

    return Collections.getById(usersState);
};

/*
 * Creates an option array of user addresses for use in our react-select dropdowns.
 */
export const getUserEmailOptions: OutputSelector<
    UsersModuleState,
    null,
    {id: string, name: string}[]
> = createSelector(getAll, (users) => {
    return users.reduce((acc, user) => {
        if (user.emails && user.isEnabled) {
            return acc.concat(
                user.emails.map((email) => ({
                    id: email,
                    name: formatEmail(email, user.name),
                    type: 'users',
                    userName: user.name,
                }))
            );
        }

        return acc;
    }, []);
});

function formatEmail(emailAddress: string, name: string): string {
    return `${name} <${emailAddress}>`;
}
