/* @flow */

import * as React from 'react';

import {EmptyTimelineCell} from './empty-timeline-cell';

import './empty-timeline.css';

// Preset height of first timeline entry header
const timelineEntryHeaderHeightFirst = 60;

// Preset height of timeline entry header (besides first, which includes a top line)
const timelineEntryHeaderHeightNth = 80;

export function EmptyTimeline() {
    const [viewHeight, setViewHeight] = React.useState(0);
    const containerRef = React.useRef(null);

    const heights = [200, 100, 150];
    const widths = [85, 65, 75];

    const componentArray = [];

    let unusedHeight = viewHeight;

    let heightCount = 0;
    let widthCount = 0;

    React.useEffect(() => {
        setViewHeight(containerRef.current ? containerRef.current.clientHeight : 0);
    }, []);

    // Cycle through preset heights
    const getHeight = (): number => {
        const value = heightCount % heights.length;
        heightCount = heightCount + 1;

        return heights[value];
    };

    // Cycle through preset widths
    const getWidth = (): number => {
        const value = widthCount % widths.length;
        widthCount = widthCount + 1;

        return widths[value];
    };

    if (viewHeight > 0) {
        // Get header height, dependent on if it's first cell or not
        const headerHeight = !componentArray.length
            ? timelineEntryHeaderHeightFirst
            : timelineEntryHeaderHeightNth;

        while (unusedHeight > 0) {
            const newHeight = getHeight();
            const newWidth = getWidth();

            unusedHeight = unusedHeight - newHeight - headerHeight;

            componentArray.push(
                <EmptyTimelineCell
                    lineOneWidth='30%'
                    lineTwoWidth='40%'
                    contentHeight={`${newHeight}px`}
                    contentWidth={`${newWidth}%`}
                    noTopLine={!componentArray.length}
                    key={`${heightCount}_timelineEmptyCell`}
                />
            );
        }
    }

    return (
        <div styleName='empty-timeline-container' ref={containerRef}>
            {componentArray}
        </div>
    );
}
