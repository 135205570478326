/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import type {EntityType} from 'nutshell-graphql-types';

import {SelectedFieldCard} from 'shells/form/sidebar-fields/selected-field-card';
import type {ListOptionWithCount} from 'shells/select-option-components';
import {colors} from 'shells/colors';

import 'shells/form/sidebar-fields/select-field.css';

type Props = {
    ...$Exact<FieldProps>,
    onChange: (newValue: ListOptionWithCount) => any,
    onRemove: () => void,
    updateOptions: (newOption: ListOptionWithCount) => void,
    refetch: () => Promise<*>,
    optionFields: ListOptionWithCount[],
    selectedOptions: string[],
    createComponent: React.ComponentType<any>,
    entityType: EntityType,
    autoFocus?: boolean,
};

export function SelectFieldCreateable(props: Props) {
    const inputValue = props.input.value;
    const {autoFocus = true} = props;

    let loading = false;

    const selectedValue = inputValue
        ? props.optionFields.find((field) => field.value === inputValue)
        : undefined;

    if (!selectedValue) {
        loading = true;
    }

    if (inputValue) {
        return (
            <SelectedFieldCard
                name={selectedValue ? selectedValue.label : ''}
                onRemove={props.onRemove}
                isLoading={loading}
                borderColor={
                    selectedValue && selectedValue.colorName
                        ? colors[selectedValue.colorName]
                        : undefined
                }
            />
        );
    }

    // filter out already selected items from the list of available items
    const availableOptions = props.optionFields.filter(
        (audience) => !props.selectedOptions.find((field) => field === audience.value)
    );

    const CreateComponent = props.createComponent;

    return (
        <CreateComponent
            {...props}
            options={availableOptions}
            autoFocus={autoFocus}
            isCreateable={true}
            selectedId={undefined}
        />
    );
}
