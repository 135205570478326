/* @flow */

import * as React from 'react';
import {FieldArray} from 'redux-form';

import {Body} from 'shells/typography';

import {PipelineFields} from './pipeline-fields';

export function LeadStageEnteredForm() {
    return (
        <div>
            <Body>Which pipeline?</Body>
            <FieldArray
                name='stageEntered'
                rerenderOnEveryChange={true}
                component={PipelineFields}
                formType='stageEntered'
            />
        </div>
    );
}
