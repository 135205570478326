/* @flow */

import {useMutation} from '@apollo/react-hooks';

import type {
    SelectCreateContact as SelectCreateContactMutation,
    SelectCreateContactVariables as SelectCreateContactMutationVariables,
    ContactCreateInput,
} from 'nutshell-graphql-types';

import CREATE_CONTACT from '../mutations/create-contact.graphql';
import GET_CONTACTS from '../queries/get-contacts-search-results.graphql';

export function useCreateContact(): (input: ContactCreateInput, searchTerm: ?string) => Promise<*> {
    const [createContact] = useMutation<
        SelectCreateContactMutation,
        SelectCreateContactMutationVariables
    >(CREATE_CONTACT);

    return (input: ContactCreateInput, searchTerm: ?string) => {
        return createContact({
            variables: {input},
            update: (proxy, mutationResults) => {
                const newContact =
                    mutationResults &&
                    mutationResults.data &&
                    mutationResults.data.contactCreate &&
                    mutationResults.data.contactCreate.contact;

                const cacheResult = proxy.readQuery({
                    query: GET_CONTACTS,
                    variables: {query: searchTerm, type: 'CONTACT'},
                });

                if (cacheResult && newContact) {
                    proxy.writeQuery({
                        query: GET_CONTACTS,
                        variables: {query: searchTerm, type: 'CONTACT'},
                        data: {
                            ...cacheResult,
                            search: {
                                __typename: 'SearchResultItemConnection',
                                quickResults: cacheResult.search.quickResults,
                                contactResults: [
                                    ...cacheResult.search.quickResults.contactResults,
                                    newContact,
                                ],
                            },
                        },
                    });
                }
            },
        });
    };
}
