/* @flow */

import type {CollectionName} from './collections-types';

const DISPLAY_NAMES = {
    accountTypes: {singular: 'company type', plural: 'company types', article: 'a'},
    activityTypes: {singular: 'activity type', plural: 'activity types', article: 'an'},
    emailSequenceTemplateGroups: {
        singular: 'template group',
        plural: 'email template groups',
        article: 'a',
    },
    competitors: {singular: 'competitor', plural: 'competitors', article: 'a'},
    industries: {singular: 'industry', plural: 'industries', article: 'an'},
    markets: {singular: 'market', plural: 'markets', article: 'a'},
    origins: {singular: 'origin', plural: 'origins', article: 'an'},
    products: {singular: 'product', plural: 'products', article: 'a'},
    sources: {singular: 'source', plural: 'sources', article: 'a'},
    stagesets: {singular: 'pipeline', plural: 'pipelines', article: 'a'},
    tags: {singular: 'tag', plural: 'tags', article: 'a'},
    teams: {singular: 'team', plural: 'teams', article: 'a'},
    territories: {singular: 'territory', plural: 'territories', article: 'a'},
    users: {singular: 'user', plural: 'users', article: 'a'},
};

function getDisplayName(type: CollectionName) {
    return DISPLAY_NAMES[type];
}

export function getSingularDisplayName(type: CollectionName, opts?: {includeArticle: boolean}) {
    const displayName = getDisplayName(type);

    return opts && opts.includeArticle
        ? `${displayName.article} ${displayName.singular}`
        : displayName.singular;
}

export function getPluaralDisplayName(type: CollectionName) {
    return getDisplayName(type).plural;
}
