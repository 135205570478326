/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {FormField} from 'shells/form';
import {ActivityTypeSelect} from './activity-type-select';

type Props = {
    ...FieldProps,
    title?: string,
    helperText?: string,
    isRequired?: boolean,
    isMulti: boolean,
    validate: Function,
};

export function ActivityTypeSelectField(props: Props) {
    const {title, helperText, isRequired, validate, ...restProps} = props;

    return (
        <FormField
            innerId={props.input.name}
            label={title}
            helperText={helperText}
            errorMessage={props.meta.error && props.meta.submitFailed ? props.meta.error : null}
            isRequired={isRequired}
            validate={validate}
        >
            <ActivityTypeSelect {...restProps} />
        </FormField>
    );
}
