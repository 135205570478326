/* @flow */

import {useDispatch, useSelector} from 'react-redux';

import type {NutshellState} from '../../store';

import type {OpenableModalId} from './openable-modals';
import * as selectors from './openable-modals-selectors';
import * as actions from './openable-modals-actions';

type OpenableModal = {
    isOpen: boolean,
    onOpen: () => void,
    onClose: () => void,
};

export function useOpenableModal(modalId: OpenableModalId, options?: Object): OpenableModal {
    const dispatch = useDispatch();

    return {
        isOpen: useSelector((state: NutshellState) => selectors.getIsModalOpen(state, modalId)),
        onOpen: () => dispatch(actions.openModal(modalId, options)),
        onClose: () => dispatch(actions.closeModal(modalId)),
    };
}
