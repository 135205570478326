import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Template from './icon-template.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Template {...validProps} />;
}
SvgComponent.iconName = 'iconTemplate';

export const TemplateIcon = svgIconWrapper(SvgComponent);
