/* @flow */

import * as React from 'react';

import {TrashIcon, EyeIcon, EyeSlashIcon, DownloadIcon, ReplyAllIcon, ReplyIcon} from '../icon';
import {IconButton} from '../button';
import {Dialog} from '../dialog';
import {Popover} from '../popover';
import {AttachmentList} from '../attachment-list';

import './modal-toolbar.css';

type Props = {
    onConfirmDelete: (e: SyntheticEvent<*>) => void,
    onClickHide: (e: SyntheticEvent<*>) => void,
    attachments?: Array<Object>,
    isShared: boolean,
    isShareable: boolean,
    replyOptions?: {
        onClickReplyAll: (e: SyntheticEvent<*>) => void,
        onClickReply: (e: SyntheticEvent<*>) => void,
    },
};

export function ModalToolbar(props: Props) {
    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState<boolean>(false);
    const [isViewingAttachments, setIsViewingAttachments] = React.useState<boolean>(false);

    const deleteButtonRef = React.useRef(null);
    const attachmentsButtonRef = React.useRef(null);

    const sharingIcons = props.isShared ? (
        <IconButton icon={EyeSlashIcon} text='Hide' onClick={props.onClickHide} />
    ) : (
        <IconButton icon={EyeIcon} text='Unhide' onClick={props.onClickHide} />
    );

    return (
        <div styleName='modal-toolbar' className='flex full-width'>
            <div>
                <IconButton
                    icon={TrashIcon}
                    text='Delete'
                    onClick={() => setIsConfirmingDelete(true)}
                    getButtonRef={deleteButtonRef}
                    variant='danger'
                />
                {props.isShareable ? sharingIcons : undefined}
                {props.attachments && props.attachments.length ? (
                    <IconButton
                        icon={DownloadIcon}
                        text='Attachments'
                        getButtonRef={attachmentsButtonRef}
                        onClick={() => setIsViewingAttachments(true)}
                    />
                ) : (
                    undefined
                )}
            </div>
            {props.replyOptions ? (
                <div>
                    <IconButton
                        icon={ReplyAllIcon}
                        text='Reply all'
                        onClick={props.replyOptions.onClickReplyAll}
                    />
                    <IconButton
                        icon={ReplyIcon}
                        text='Reply'
                        onClick={props.replyOptions.onClickReply}
                    />
                </div>
            ) : (
                undefined
            )}
            {isViewingAttachments ? (
                <Popover
                    anchor={attachmentsButtonRef.current}
                    onBlur={(e) => {
                        e.stopPropagation();
                        setIsViewingAttachments(false);
                    }}
                >
                    {props.attachments ? (
                        <AttachmentList attachments={props.attachments} />
                    ) : (
                        undefined
                    )}
                </Popover>
            ) : (
                undefined
            )}
            {isConfirmingDelete ? (
                <Dialog
                    anchor={deleteButtonRef.current}
                    question={`Are you sure you want to delete this ${
                        props.replyOptions ? 'email' : ''
                    }?`}
                    primaryText={`Yes, delete ${props.replyOptions ? 'email' : ''}`}
                    secondaryText='No, don’t delete'
                    onPrimary={props.onConfirmDelete}
                    onSecondary={() => setIsConfirmingDelete(false)}
                    isWarning={true}
                />
            ) : (
                undefined
            )}
        </div>
    );
}
