import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 16 15'}>
            <path d='M1.8 3.6c1 0 1.8-.8 1.8-1.8S2.8 0 1.8 0 0 .8 0 1.8s.8 1.8 1.8 1.8zm6.2 0c1 0 1.8-.8 1.8-1.8S9 0 8 0 6.2.8 6.2 1.8 7 3.6 8 3.6zm6.2 0c1 0 1.8-.8 1.8-1.8S15.2 0 14.2 0s-1.8.8-1.8 1.8.8 1.8 1.8 1.8zM1.8 9.3c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8S0 6.5 0 7.5s.8 1.8 1.8 1.8zm6.2 0c1 0 1.8-.8 1.8-1.8S9 5.7 8 5.7s-1.8.8-1.8 1.8S7 9.3 8 9.3zm6.2 0c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8-1.8.8-1.8 1.8.8 1.8 1.8 1.8zM1.8 15c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8-1.8.8-1.8 1.8S.8 15 1.8 15zM8 15c1 0 1.8-.8 1.8-1.8S9 11.4 8 11.4s-1.8.8-1.8 1.8S7 15 8 15zm6.2 0c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8-1.8.8-1.8 1.8.8 1.8 1.8 1.8z' />
        </svg>
    );
}
SvgComponent.iconName = 'keypad';

export const CtcKeypadIcon = svgIconWrapper(SvgComponent);
