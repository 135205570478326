/* @flow */

import * as React from 'react';

// Custom hook which mimics a useEffect hook except it
// won't run on the first render of a component
export const useDidMountEffect = (func: Function, deps: any) => {
    const didMount = React.useRef(false);

    React.useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};
