/* @flow */

/**
 * Helper function to determine if our request was a "pagination" request,
 * i.e. loading not the first set of items.
 *
 * @param  {Object} query        - Full query object
 * @return {boolean}             - Was pagination request or not
 */
export function isQueryPaginationRequest(query: Object): boolean {
    return Boolean(query && query.page && query.page.page && query.page.page !== 0);
}
