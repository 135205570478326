/* @flow */

import * as React from 'react';
import moment from 'moment';
import {isToday} from 'nutshell-core/date-time';

import {FormattedTimestamp, RelativeTimestamp} from 'shells/timestamp';

type Props = {
    submissionTime: number,
};

export function FormSubmissionTimestamp(props: Props) {
    if (isToday(moment.unix(props.submissionTime))) {
        return (
            <div className='flex'>
                submitted&nbsp;
                <RelativeTimestamp datetime={props.submissionTime} />
            </div>
        );
    }

    return (
        <div className='flex'>
            submitted on&nbsp;
            <FormattedTimestamp
                datetime={props.submissionTime}
                format={FormattedTimestamp.formats.longMonthDayWithYear}
            />
        </div>
    );
}
