import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ChevronUpSvg from './icon-chevron-up.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ChevronUpSvg {...validProps} />;
}
SvgComponent.iconName = 'chevronUp';

export const ChevronUp = svgIconWrapper(SvgComponent);
