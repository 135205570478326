import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SidebarIconReports from './sidebar-icon-reports.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SidebarIconReports {...validProps} />;
}
SvgComponent.iconName = 'sidebarIconReports';

export const SidebarIconReportsIcon = svgIconWrapper(SvgComponent);
