import PropTypes from 'prop-types';
import * as React from 'react';
import './calendar-year-picker.css';

export class CalendarYearPicker extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        year: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.state = {
            isButton: true,
            year: props.year,
        };
    }

    componentDidUpdate(prevState) {
        if (prevState.isButton !== this.state.isButton && !this.state.isButton) {
            this.yearInput.focus();
        }
    }

    render() {
        if (this.state.isButton) {
            return (
                <button styleName='button' onClick={this.handleButtonClick}>
                    {this.props.year}
                </button>
            );
        } else {
            return (
                <form onSubmit={this.handleSubmit}>
                    <input
                        styleName='input'
                        ref={(node) => {
                            this.yearInput = node;
                        }}
                        name='year'
                        type='number'
                        onChange={this.handleChange}
                        onBlur={this.handleSubmit}
                        onFocus={(textField) => textField.target.select()}
                        value={this.state.year}
                    />
                </form>
            );
        }
    }

    handleChange = (e) => {
        this.setState({year: e.target.value});
    };

    handleButtonClick = (e) => {
        e.stopPropagation();
        this.setState({isButton: false});
    };

    handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        this.setState({isButton: true});

        if (this.state.year) {
            const fourDigitYear =
                this.state.year < 100 ? Number(this.state.year) + 2000 : this.state.year;
            this.props.onChange(fourDigitYear);
        }
    };
}
