/* @flow */

import * as React from 'react';

import {LoadingPage} from '../../../loading-page';

import {AddedContacts, type Contact} from './added-contacts';
import {AddablePeople, type AddablePerson} from './addable-people';

import './company-people.css';

const SETTINGS_HTML_URL_PATH = '/email-marketing/visitors/settings';

export type Person = {
    id: string,
    name: string,
    hasPhone: boolean,
    hasEmail: boolean,
    hasLinkedinUrl: boolean,
    location: ?string,
    jobTitle: ?string,
    position: ?string,
    contact: ?{|htmlUrlPath: string, phone: ?string, email: ?string|},
};

type Props = {|
    people: ?(Person[]),
    selectedPersonIds: string[],
    updateSelectedPersonIds: (personId: string) => void,
    offsetHeightRefs: any[],
    onExpandPersonDetails: (personId: string) => void,
    onClose: () => void,
|};

export function CompanyPeople(props: Props) {
    const [containerHeight, setContainerHeight] = React.useState<string>('100%');

    const containerRef = React.useRef(null);

    React.useEffect(() => {
        // Timeout necessary to get accurate ref values, unclear why useLayoutEffect isn't working as it should here
        setTimeout(() => {
            let heightOffset = 0;
            props.offsetHeightRefs.forEach((ref) => {
                if (ref.current) {
                    heightOffset += ref.current.clientHeight;
                }
            });

            const updatedContainerHeight = `calc(100% - ${heightOffset}px)`;
            if (containerRef.current && containerHeight !== updatedContainerHeight) {
                setContainerHeight(updatedContainerHeight);
            }
        }, 50);
    }, [props.offsetHeightRefs, containerHeight]);

    const addedContacts: Contact[] = [];
    const addablePeople: AddablePerson[] = [];
    if (props.people) {
        props.people.forEach((person) => {
            if (person.contact) {
                addedContacts.push({
                    id: person.id,
                    name: person.name,
                    location: person.location,
                    jobTitle: person.jobTitle,
                    position: person.position,
                    htmlUrlPath: person.contact.htmlUrlPath,
                    email: person.contact.email,
                    phone: person.contact.phone,
                });
            } else {
                addablePeople.push({
                    id: person.id,
                    name: person.name,
                    location: person.location,
                    jobTitle: person.jobTitle,
                    position: person.position,
                    hasEmail: person.hasEmail,
                    hasPhone: person.hasPhone,
                    hasLinkedinUrl: person.hasLinkedinUrl,
                });
            }
        });
    }

    return (
        <div
            ref={containerRef}
            styleName='company-people-container'
            style={{height: containerHeight}}
        >
            {props.people ? (
                <div styleName='list-container'>
                    <AddedContacts contacts={addedContacts} />
                    {!addablePeople.length && addedContacts.length ? null : (
                        <AddablePeople
                            people={addablePeople}
                            selectedPersonIds={props.selectedPersonIds}
                            updateSelectedPersonIds={props.updateSelectedPersonIds}
                            onClickName={props.onExpandPersonDetails}
                            onClose={props.onClose}
                            adjustFiltersUrl={SETTINGS_HTML_URL_PATH}
                        />
                    )}
                </div>
            ) : (
                <LoadingPage padding={0} />
            )}
        </div>
    );
}
