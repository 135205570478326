import {createSelector} from 'reselect';
import moment from 'moment';
import * as ramda from 'ramda';
import _ from 'underscore';
import {Routing} from 'nutshell-core/routing';
import {Session} from 'nutshell-core/session';
import {Users} from 'nutshell-core/users';
import {formatDateForHeader} from 'shells/date-header/format-date-for-header';
import {PAST, TODAY, TOMORROW, FUTURE} from './todos-constants';

const getTodosState = (state) => state.dashboard.todos;
const getUiState = (state) => state.dashboard.ui;
const getTodoConfigState = (state) => state.dashboard.todosConfig;

export const getTodos = createSelector([getTodosState], (todosState) => {
    return todosState.items;
});

export const getTodoIndices = createSelector([getTodosState], (todosState) => {
    return todosState.indices;
});

/**
 * When we create a todo, we'll store it in a tempIds array so we know it's
 * not from the server. This allows us to easily de-dupe on our server response
 * and clean up the temporary array, while also giving us the benefit of
 * immediately adding it to the list and not having to wait for solr.
 */
export const getTempTodoIds = createSelector([getTodosState], (todosState) => {
    return todosState.tempIds;
});

export const getTempTodos = createSelector([getTempTodoIds, getTodos], (tempTodoIds, todoItems) => {
    return tempTodoIds.map((id) => todoItems[id]);
});

export const getTodoIds = createSelector([getTodos], (todos) => {
    return Object.keys(todos);
});

export const getTodoTotalCount = createSelector([getTodosState], (todosState) => {
    return todosState.counts.total || 0;
});

export const getTodoPastCount = createSelector([getTodosState], (todosState) => {
    return todosState.counts.past || 0;
});

export const getTodoTodayCount = createSelector([getTodosState], (todosState) => {
    return todosState.counts.today || 0;
});

export const getTodoTomorrowCount = createSelector([getTodosState], (todosState) => {
    return todosState.counts.tomorrow || 0;
});

export const getTodoFutureCount = createSelector([getTodosState], (todosState) => {
    return todosState.counts.future || 0;
});

export const getTodoTodayHasOverdue = createSelector([getTodos, getTodoIds], (todos, todoIds) => {
    const overdueToday = todoIds.filter((id) => {
        return moment.unix(todos[id].dueTime).isSame(moment(), 'day') && todos[id].isOverdue;
    });

    return overdueToday.length > 0;
});

export const getIsRequesting = createSelector([getUiState], (uiState) => {
    return uiState.todoListIsRequesting;
});

export const getListErrorMessage = createSelector([getUiState], (uiState) => {
    return uiState.todoListErrorMessage;
});

export const getTodoIdBeingConfirmedToDelay = createSelector([getUiState], (uiState) => {
    return uiState.todoIdBeingConfirmedToDelay;
});

export const getTodoIdBeingConfirmedToSkip = createSelector([getUiState], (uiState) => {
    return uiState.todoIdBeingConfirmedToSkip;
});

export const getTodoIdBeingConfirmedToUncomplete = createSelector([getUiState], (uiState) => {
    return uiState.todoIdBeingConfirmedToUncomplete;
});

export const getDisplayDate = createSelector([getTodoConfigState], (todoConfigState) => {
    return todoConfigState.displayDate;
});

export const getPeriodSegmentWasManuallySelected = createSelector(
    [getTodoConfigState],
    (todoConfigState) => {
        return todoConfigState.periodSegmentWasManuallySelected;
    }
);

export const getSelectedPeriodSegment = createSelector([getTodoConfigState], (todoConfigState) => {
    return todoConfigState.periodSegmentSelected;
});

export const getFilter = createSelector([getTodoConfigState], (todoConfigState) => {
    return todoConfigState.filter;
});

export const getQueryString = createSelector([getTodoConfigState], (todoConfigState) => {
    return todoConfigState.queryString;
});

export const getDeparamedFilters = createSelector([getFilter], (filter) => {
    return Routing.deparam(filter);
});

/**
 * A selector that returns an array of IDs that satisfy the current assignee filters. By default, it will be the current
 * user + all of their teams.
 *
 * @param {object} state - full Nutshell state that comes in for each selector in reselect
 * @return {Array} An array of string IDs.
 */
export const getAssigneeFilterIds = createSelector(
    [getDeparamedFilters, Session.getSessionStateUser, (state) => Users.getAll(state, 'users')],
    /**
     * @typedef {object} User
     * @property {string} id                      - The ID of the user.
     * @property {object} links                   - Link relationships by name.
     * @property {string[]|undefined} links.teams - The IDs of the teams associated with this user.
     */

    /**
     * @param {object} deparamedFilters               - The filter object, usually deparamed from the URL.
     * @param {string|null} deparamedFilters.assignee - The ID of the assignee.
     * @param {User} sessionUser                      - The current logged in user.
     * @param {User[]} usersCollection                - A collection of user objects.
     * @returns {string[]} User and team IDs that are included in the current filter set.
     */
    (deparamedFilters, sessionUser, usersCollection) => {
        let user = null;
        if (
            deparamedFilters &&
            deparamedFilters.assignee &&
            deparamedFilters.assignee.data &&
            deparamedFilters.assignee.data.length
        ) {
            user = usersCollection.find((u) => u.id === deparamedFilters.assignee.data[0].data);
        } else {
            user = sessionUser;
        }
        const teamIds = user && user.links && user.links.teams ? user.links.teams : [];
        const userId = user ? [user.id] : [];

        return userId.concat(teamIds);
    }
);

export const getOrderedTodoArray = createSelector(
    [getTodos, getTodoIndices, getTempTodos, getTodoTotalCount],
    (todos, todoIndices, tempTodos, totalTodoCount) => {
        const indexArray =
            totalTodoCount && totalTodoCount - tempTodos.length >= 0
                ? new Array(totalTodoCount - tempTodos.length)
                : [];

        const mapIndexed = ramda.addIndex(ramda.map);
        const todoIds = new Set();
        const todoArray = mapIndexed((undef, idx) => {
            const todoId = todoIndices[idx];

            // If this isn't a valid todo or we have already added it to the array we just skip over it.
            // This is to prevent duplicates from being produced by the same todo appearing in
            // different pages of data.
            if (!todoId || todoIds.has(todoId)) return;

            todoIds.add(todoId);

            return todos[todoId];
        }, indexArray);

        // Tack on our temporary ids - we filter out undefined and order
        // them later, so this is totally cool.
        const todoArrayWithTemps = todoArray.concat(tempTodos);

        return todoArrayWithTemps;
    }
);

export const getTodoList = createSelector(
    [getOrderedTodoArray, getAssigneeFilterIds],
    (orderedTodos, assigneeFilterIds) => {
        // Filter out undefined and non-filter conforming todos from array
        const filteredTodos = orderedTodos.filter((todo) => {
            return (
                todo &&
                (todo.type === 'steps' ||
                    (todo.type === 'tasks' && ramda.contains(todo.assignee.id, assigneeFilterIds)))
            );
        });

        // Create our sortByDueTime function
        const sortByDueTime = ramda.sortBy((todo) => {
            return (
                todo.dueTime ||
                moment()
                    .endOf('day')
                    .unix()
            );
        });
        // Run that on our array of non-undefined filtered todos
        const orderedAndFilteredTodos = sortByDueTime(filteredTodos);

        // Now we'll loop through the orderedTodos, and essentially replace only
        // the todos we know about with our now locally ordered array
        const actuallyOrderedTodos = [];
        let index = 0;
        orderedTodos.forEach((todo) => {
            if (!todo) {
                actuallyOrderedTodos.push(undefined);
            } else {
                actuallyOrderedTodos.push(orderedAndFilteredTodos[index]);
                index++;
            }
        });

        const addHeaders = (list, todo) => {
            const dueTime = todo
                ? todo.dueTime ||
                  moment()
                      .endOf('day')
                      .unix()
                : null;
            const lastHeader = ramda.findLast((item) => item && item.type === 'headers')(list);
            if (
                lastHeader &&
                todo &&
                !moment.unix(dueTime).isSame(moment.unix(lastHeader.date), 'day')
            ) {
                list.push({type: 'headers', date: dueTime});
            } else if (todo && typeof lastHeader === 'undefined') {
                list.push({type: 'headers', date: dueTime});
            }
            list.push(todo);

            return list;
        };

        return ramda.reduce(addHeaders, [], actuallyOrderedTodos);
    }
);

/*
 * Find the row number to scroll to when we want to jump to a period like tomorrow.
 */
export const getTodoListScrollIndex = createSelector(
    [
        getTodoList,
        getSelectedPeriodSegment,
        getTodoPastCount,
        getTodoTodayCount,
        getTodoTomorrowCount,
    ],
    (todoList, periodSegment, pastCount, todayCount, tomorrowCount) => {
        if (periodSegment === PAST) {
            return 0;
        }

        let targetDate;
        switch (periodSegment) {
            case TODAY:
                targetDate = moment();
                break;
            case TOMORROW:
                targetDate = moment().add(1, 'day');
                break;
            case FUTURE:
                targetDate = moment().add(2, 'days');
                break;
        }
        const index = _.findIndex(todoList, (todo) => {
            return (
                todo &&
                todo.type === 'headers' &&
                moment.unix(todo.date).isSameOrAfter(targetDate, 'day')
            );
        });

        // We may not have the index loaded up from the server yet.
        if (index === -1) {
            const headerCount = todoList.filter(
                (todo) => todo && todo.type === 'headers' && moment(todo.date).isBefore(targetDate)
            ).length;
            switch (periodSegment) {
                case TODAY:
                    return pastCount + headerCount;
                case TOMORROW:
                    return pastCount + todayCount + headerCount;
                case FUTURE:
                    return pastCount + todayCount + tomorrowCount + headerCount;
            }
        }

        if (
            periodSegment === TOMORROW &&
            tomorrowCount &&
            moment.unix(todoList[index].date).isSame(moment().add(2, 'days'), 'day')
        ) {
            // Whoops, we skipped straight over tomorrow to the future.  Probably loaded today,
            // then future, then back to tomorrow, but tomorrow isn't loaded.
            // Starting from the index we know is the start of "future", subtract numer of tomorrow
            // days, and tomorrow's header
            return index - tomorrowCount - 1;
        }

        return index;
    }
);

/**
 * "Actual" is a bit of loaded term, but essentially we need to calculate the actual
 * date that's being shown based on scroll position in the list. We have a "target"
 * date in our store that we want to show, but if there isn't any items for that
 * date we need to recalcuate it based on the first to do we know about in the list,
 * as we'll automatically scroll our list to the top. This only occurs on initial
 * load, so it's not really too much of a problem.
 * @param  {Array}                     Array of selectors used to compose this selector
 * @param  {Array} todoList            List of to dos (includes undefined indexes)
 * @param  {Date} displayDate          Javascript date object in our store
 *                                     of the date we're attempting to show to dos for
 * @param  {Number} scrollIndex        Calculated scroll index based on the period
 *                                     segment. If this is -1, we know the date we're
 *                                     trying to show is bad.
 * @return {Date}                      New, "actual" date that's being displayed. We'll
 *                                     default to "Today" if nothing is in the list.
 */
export const getActualDisplayDate = createSelector(
    [getTodoList, getDisplayDate, getTodoListScrollIndex],
    (todoList, displayDate, scrollIndex) => {
        const loadedTodos = todoList.filter((todo) => todo);
        if (!loadedTodos.length || scrollIndex >= 0) {
            // Always return selected date if we have an empty list (loading)
            return displayDate;
        } else {
            return new Date(moment.unix(loadedTodos[0].date));
        }
    }
);

export const getFormattedDisplayDate = createSelector([getActualDisplayDate], (realDisplayDate) => {
    const date = moment(realDisplayDate);

    return formatDateForHeader(date);
});

export const getActivePeriodSegment = createSelector(
    [
        getSelectedPeriodSegment,
        getTodoPastCount,
        getTodoTodayCount,
        getTodoTomorrowCount,
        getTodoFutureCount,
    ],
    (periodSegment, pastCount, todayCount, tomorrowCount, futureCount) => {
        const countForPeriod = {
            [PAST]: pastCount,
            [TODAY]: todayCount,
            [TOMORROW]: tomorrowCount,
            [FUTURE]: futureCount,
        }[periodSegment];

        if (countForPeriod !== 0) {
            return periodSegment;
        } else if (pastCount > 0) {
            return PAST;
        } else if (todayCount > 0) {
            return TODAY;
        } else if (tomorrowCount > 0) {
            return TOMORROW;
        } else if (futureCount > 0) {
            return FUTURE;
        } else {
            return TODAY;
        }
    }
);
