/* @flow */

import {deserializeDate} from './deserialize-date';
import {deserializeStringOrNumber} from './deserialize-string-or-number';
import {deserializeLocation} from './deserialize-location';
import {deserializeMap} from './deserialize-map';
import {deserializeRelationship} from './deserialize-relationship';

export function deserializeUrlParam(value: string | Object, type: string) {
    if (!value) return null;

    if (typeof value === 'object' && value !== null) {
        switch (type) {
            case 'map':
                return deserializeMap(value);
            case 'location':
            case 'state':
                return deserializeLocation(value);
            default:
                return value;
        }
    }

    switch (type) {
        case 'string':
        case 'string-nullable':
        case 'integer':
        case 'rating':
        case 'email':
        case 'text':
        case 'currency':
        case 'customField':
        case 'phone':
            return deserializeStringOrNumber(value);
        case 'date-time':
            return deserializeDate(value);
        case 'relationship':
        case 'enum':
        case 'stage':
            return deserializeRelationship(value);
        case 'boolean':
            return JSON.parse(value);
        case 'location':
        case 'state':
            return deserializeLocation(value);
        case 'emailTemplate':
        case 'emailTemplateMissingFields':
        case 'emailTemplateMissingEmail':
        default:
            return value;
    }
}
