/* @flow */

import {unexpectedCase} from 'nutshell-core/reducer-utils';
import {REHYDRATE} from 'redux-persist';
import {ActionTypes, type GlossaryAction} from './glossary-actions';

export type GlossaryState = {
    glossaryKey: ?string,
    isButtonExpanded: boolean,
};

export const defaultGlossaryState = {
    glossaryKey: null,
    isButtonExpanded: false,
};

export const glossaryReducer = (
    state: GlossaryState = defaultGlossaryState,
    action: GlossaryAction
): GlossaryState => {
    switch (action.type) {
        case ActionTypes.SIDEBAR_OPENED:
            return {
                ...state,
                glossaryKey: action.payload.glossaryKey,
            };
        case ActionTypes.SIDEBAR_CLOSED:
            return defaultGlossaryState;
        case ActionTypes.GLOSSARY_BUTTON_TOGGLE:
            return {
                ...state,
                isButtonExpanded: action.payload.isExpanded,
            };
        case REHYDRATE: {
            const persistedState = action.payload;
            if (!persistedState || !persistedState.ui || !persistedState.ui.glossary) {
                return state;
            }

            return persistedState.ui.glossary;
        }
        default:
            unexpectedCase(action.type);

            return state;
    }
};
