/* @flow */
import {useQuery} from '@apollo/react-hooks';

import type {GetTeams as GetTeamsQuery} from 'nutshell-graphql-types';
import {makeDataSafe, safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import GET_TEAMS from '../queries/get-teams.graphql';

export const useGetTeams = () => {
    const {data, error, loading: isLoading} = useQuery<GetTeamsQuery, {}>(GET_TEAMS, {});

    const {teams} = makeDataSafe(data);

    return {
        teams: teams || [],
        error: safelyGetGraphQLErrorMessage(error),
        isLoading,
    };
};
