/* @flow */

import moment from 'moment';
import type Moment from 'moment';

/*
 * Check if a date is the same year
 *
 * @param  {[type]}  targetDate        Moment date object to check
 * @param  {[type]}  [today=moment()]  Moment date object for this year's datetime
 * @return {Boolean}                   Is the target date this year?
 */
export function isSameYear(targetDate: Moment, today: Moment = moment()): boolean {
    return targetDate.isSame(today, 'year');
}
