import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Booking from './icon-booking.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Booking {...validProps} />;
}
SvgComponent.iconName = 'booking';

export const BookingIcon = svgIconWrapper(SvgComponent);
