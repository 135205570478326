import React from 'react';
import {colors} from 'shells/colors';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Microphone from './icon-microphone.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <Microphone {...validProps} color={validProps.color ? validProps.color : colors.black} />
    );
}
SvgComponent.iconName = 'microphone';

export const MicrophoneIcon = svgIconWrapper(SvgComponent);
