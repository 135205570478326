import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import LightbulbBright from './icon-lightbulb-bright.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <LightbulbBright {...validProps} />;
}
SvgComponent.iconName = 'lightbulb-bright';

export const LightbulbBrightIcon = svgIconWrapper(SvgComponent);
