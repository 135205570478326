import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import GoalLeadValue from './icon-goal-lead-value.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <GoalLeadValue {...validProps} />;
}
SvgComponent.iconName = 'goal-lead-value';

export const GoalLeadValueIcon = svgIconWrapper(SvgComponent);
