import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import MagnifyingGlassDollar from './icon-magnifying-glass-dollar.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <MagnifyingGlassDollar {...validProps} />;
}
SvgComponent.iconName = 'magnifyingGlassDollar';

export const MagnifyingGlassDollarIcon = svgIconWrapper(SvgComponent);
