import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Open from './icon-open.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Open {...validProps} />;
}
SvgComponent.iconName = 'open';

export const OpenIcon = svgIconWrapper(SvgComponent);
