/* @flow */

import type {LimitedType} from 'nutshell-graphql-types';

export const ActionTypes = {
    LIMIT_MODAL_TOGGLED: 'LIMIT_MODAL_TOGGLED',
    ALL_LIMITS_MODAL_TOGGLED: 'ALL_LIMITS_MODAL_TOGGLED',
};

type ToggleAllLimitsLimitModalPayload = {
    isAllLimitsModalOpen: boolean,
    allLimitsModalShouldLinkToBilling?: boolean,
    allLimitsModalTitleText?: string,
};

type ToggleLimitModal = {type: 'LIMIT_MODAL_TOGGLED', payload: ?LimitedType};
type ToggleAllLimitsLimitModal = {
    type: 'ALL_LIMITS_MODAL_TOGGLED',
    payload: ToggleAllLimitsLimitModalPayload,
};

export type LimitAction = ToggleLimitModal | ToggleAllLimitsLimitModal;

export type AllLimitsLimitAction = ToggleAllLimitsLimitModal;

/*
 * Action creators
 */
export const openModalForType = (type: ?LimitedType) => ({
    type: ActionTypes.LIMIT_MODAL_TOGGLED,
    payload: type,
});

export const openAllLimitsModal = (input: {
    allLimitsModalShouldLinkToBilling?: boolean,
    allLimitsModalTitleText?: string,
}) => ({
    type: ActionTypes.ALL_LIMITS_MODAL_TOGGLED,
    payload: {
        isAllLimitsModalOpen: true,
        allLimitsModalShouldLinkToBilling:
            input.allLimitsModalShouldLinkToBilling !== undefined
                ? input.allLimitsModalShouldLinkToBilling
                : true,
        allLimitsModalTitleText: input.allLimitsModalTitleText,
    },
});

export const closeAllLimitsModal = () => ({
    type: ActionTypes.ALL_LIMITS_MODAL_TOGGLED,
    payload: {
        isAllLimitsModalOpen: false,
        allLimitsModalShouldLinkToBilling: false,
        allLimitsModalTitleText: null,
    },
});
