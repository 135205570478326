/* @flow */

import * as React from 'react';
import {getApiTypeFromGraphQLTypename} from 'nutshell-core/utils';

import type {EventFragmentSparse_actor as Actor} from 'nutshell-graphql-types';

import {LinkedEntity} from '../../linked-entity';
import {SpacerGroup} from '../../spacer/spacer-group';

import {TimelineHeaderTimestamp} from '../entry/header';

import '../entry/header/timeline-header.css';

type Props = {
    timestamp: number,
    actor: ?Actor,
    action: React.Element<*>,
    isSmall?: boolean,
    omitActor?: boolean,
};

export function TimelineBannerContent(props: Props) {
    const {timestamp, actor, action, isSmall, omitActor} = props;

    const getDefaultActor = () => {
        if (omitActor) {
            return undefined;
        } else {
            return <span>Someone</span>;
        }
    };

    return (
        <div styleName={isSmall ? 'header-container--small' : 'header-container'}>
            <SpacerGroup mb={4}>
                <TimelineHeaderTimestamp timestamp={timestamp} />
            </SpacerGroup>
            <div styleName='action-text'>
                {actor ? (
                    <span styleName='actor'>
                        <LinkedEntity
                            useEntityColor={true}
                            shouldClientRoute={true}
                            type={getApiTypeFromGraphQLTypename(actor.__typename)}
                            displayInline={true}
                            allowWrap={true}
                            {...actor}
                        />
                    </span>
                ) : (
                    getDefaultActor()
                )}
                &nbsp;
                <span styleName='verb'>{action}</span>
            </div>
        </div>
    );
}
