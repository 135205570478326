/* @flow */

import * as React from 'react';

import {useRefObserver} from 'shells/utils/hooks/use-ref-observer';
import {Stack} from 'shells/layout';
import {HoverPopover, type HoverPopoverLocationEnum} from 'shells/hover-popover';
import {Popover} from 'shells/popover';
import {LoadingIcon} from 'shells/icon';
import {PlugErrorState} from 'shells/illustrations';

import {useGetSessionUser} from '../../core/use-get-session-user';

import {useGetPowerAiSummarizationMonthlyUsage} from './graphql/hooks';
import {PowerAiUsagePopoverContent} from './power-ai-usage-popover-content';

import './power-ai-usage-popover.css';

type Props = {|
    popoverAnchor: any,
    variant: 'timeline' | 'zoom',
    // Pass in to disable button when monthly response limit has been hit
    buttonRef?: any,
    // Location of the popover - default is top
    location?: HoverPopoverLocationEnum,
    isTooltip?: boolean,
|};

export function PowerAiUsagePopover(props: Props) {
    const {buttonRef, location = 'top'} = props;

    const linkContainerRef = React.useRef(null);

    const {monthlyUsage, isLoading, error} = useGetPowerAiSummarizationMonthlyUsage();
    const {user} = useGetSessionUser();

    const {isHovered} = useRefObserver(props.popoverAnchor, ['isHovered']);

    // Only show link to users with billing access who are not on Business plan or have hit their monthly limit
    const shouldShowBillingLink =
        user &&
        user.permissions.canAccessBilling &&
        (!NutClientConfig.hasPowerAiPlan || (monthlyUsage && monthlyUsage.remaining <= 0));

    let title, body;
    switch (props.variant) {
        case 'timeline':
            title = 'Summarize this timeline';
            body = (
                <>
                    Nutshell uses AI to analyze your timeline interactions and provide you with a
                    brief summary.
                </>
            );
            break;
        case 'zoom':
        default:
            title = 'Summarize this transcription';
            body = (
                <>
                    Nutshell uses AI to generate a brief summary of your Zoom meeting
                    transcriptions.
                </>
            );
            break;
    }

    const getLimitsContent = () => {
        if (isLoading) {
            return (
                <div className='flex justify-center'>
                    <LoadingIcon size={24} />
                </div>
            );
        }

        if (monthlyUsage) {
            return (
                <div>
                    Your team has <strong>{monthlyUsage.remaining.toLocaleString()}</strong>{' '}
                    {monthlyUsage.remaining === 1 ? 'summary' : 'summaries'} left out of{' '}
                    <strong>
                        {monthlyUsage.limit ? monthlyUsage.limit.toLocaleString() : 'unlimited'}
                    </strong>{' '}
                    per month.
                </div>
            );
        }

        if (error) {
            return (
                <Stack spacing={8} isHorizontal={true} alignCenter={true}>
                    <div styleName='error-svg-container'>
                        <PlugErrorState />
                    </div>
                    <div>Something went wrong fetching this response. Try again in a bit!</div>
                </Stack>
            );
        }

        return null;
    };

    React.useEffect(() => {
        // If buttonRef is passed in, then we can disable it if no responses remain
        if (buttonRef && buttonRef.current) {
            if (!monthlyUsage || (monthlyUsage && monthlyUsage.remaining === 0)) {
                buttonRef.current.disabled = true;
            } else {
                buttonRef.current.disabled = false;
            }
        }
    }, [buttonRef, monthlyUsage]);

    const popoverContent = (
        <PowerAiUsagePopoverContent
            body={body}
            title={title}
            monthlyUsage={monthlyUsage}
            shouldShowBillingLink={Boolean(shouldShowBillingLink)}
            isTooltip={Boolean(props.isTooltip)}
            linkContainerRef={linkContainerRef}
            limitsContent={getLimitsContent()}
        />
    );

    return props.isTooltip && isHovered ? (
        <Popover anchor={props.popoverAnchor.current} overlayStyles={{display: 'none'}}>
            {popoverContent}
        </Popover>
    ) : (
        <HoverPopover anchorRef={props.popoverAnchor} location={location}>
            {popoverContent}
        </HoverPopover>
    );
}
