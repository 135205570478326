/* @flow */

import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactTooltip from 'react-tooltip';

import {colors} from '../../../../colors';
import {FileIcon, ClockIcon} from '../../../../icon';
import {DurationTimestamp} from '../../../../timestamp/duration-timestamp';
import {Stack} from '../../../../layout';

import './truncated-session-content.css';

const MAX_PAGES_DISPLAYED = 3;

type Page = {
    id: string,
    path: ?string,
};

type Props = {|
    durationSeconds: number,
    pages: Page[],
|};

export function TruncatedSessionContent(props: Props) {
    const {durationSeconds, pages} = props;

    React.useEffect(() => {
        ReactTooltip.rebuild();

        return () => ReactTooltip.hide();
    }, []);

    const pagesList = pages.map((page) => <div key={page.id}>{page.path}</div>);
    const hiddenPages: (?string)[] = pages.slice(1, pages.length - 1).map((page) => page.path);

    return (
        <>
            <div styleName='truncated-session-content'>
                {pagesList.length > MAX_PAGES_DISPLAYED ? (
                    <>
                        {pagesList[0]}
                        {
                            <div
                                styleName='hidden-pages'
                                data-html={true}
                                data-tip={ReactDOMServer.renderToString(
                                    <div>
                                        {hiddenPages.map((page, index) => (
                                            <div key={page ? page : `unknown-page-${index}`}>
                                                {page ? page : <i>Unknown page</i>}
                                            </div>
                                        ))}
                                    </div>
                                )}
                                data-place='top'
                            >
                                {hiddenPages.length} more pages
                            </div>
                        }
                        {pagesList[pagesList.length - 1]}
                    </>
                ) : (
                    pagesList
                )}
            </div>
            <div styleName='info-bar'>
                <Stack spacing={16} isHorizontal={true}>
                    <div
                        data-tip={`${pages.length} page${pages.length !== 1 ? 's' : ''}`}
                        data-place='top'
                    >
                        <Stack spacing={8} isHorizontal={true}>
                            <FileIcon size={15} wrapWithDiv={false} fill={colors.greyLt} />
                            {pages.length}
                        </Stack>
                    </div>
                    <Stack spacing={8} isHorizontal={true}>
                        <ClockIcon size={17} wrapWithDiv={false} />
                        <DurationTimestamp durationSeconds={durationSeconds} />
                    </Stack>
                </Stack>
            </div>
        </>
    );
}
