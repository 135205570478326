import {jsonToModelProp} from './json-to-model-prop';

/**
 * Returns an array of {model}.props.-ready object for the given
 * Nutshell JSON-API structure.
 *
 * @param  {Object[]}  models    - Array of JSON objects to deserialize from the server
 * @param  {Object}    json      - The full JSON-API payload that contains our data \o/
 * @param  {string}    modelType - The type of model being parsed
 * @return {Object[]}              An array of model-prop-ready object
 */
export function jsonToModelPropArray(models, json, modelType) {
    if (!Array.isArray(models)) return [];

    return models
        .filter((model) => Object.keys(model).includes('id'))
        .map((model) => {
            return jsonToModelProp(model.id, json, modelType);
        });
}
