/* @flow */
import moment from 'moment';

export const getTinyTimestamp = (datetime: number): string => {
    const datetimeValue = moment.unix(datetime);
    const today = moment();

    const duration = moment.duration(datetimeValue.diff(today));
    const isPast = duration.asMilliseconds() < 0;
    const weeks = Math.abs(duration.asWeeks());

    if (weeks >= 1) {
        return `${Math.floor(weeks)}w ${isPast ? 'ago' : ''}`;
    } else {
        const days = Math.abs(duration.asDays());

        if (days >= 1) {
            return `${Math.floor(days)}d ${isPast ? 'ago' : ''}`;
        } else {
            const hours = Math.abs(duration.asHours());

            if (hours >= 1) {
                return `${Math.floor(hours)}h ${isPast ? 'ago' : ''}`;
            } else {
                return `${Math.floor(Math.abs(duration.asMinutes()))}min ${isPast ? 'ago' : ''}`;
            }
        }
    }
};
