/* @flow */

import * as React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Pandadoc from './pandadoc.svg';

function SvgComponent(props: any) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Pandadoc {...validProps} />;
}
SvgComponent.iconName = 'pandadoc';

export const PandadocIcon = svgIconWrapper(SvgComponent);
