/* @flow */

import type {BillingState} from './billing-reducer';

export const REDUCER_KEY = 'billing';
export type ReducerKey = 'billing';

export type BillingModuleState = {
    [ReducerKey]: BillingState,
};

export const PLANS = {
    'ns-uu': 'Classic',
    'ns-uua': 'Starter',
    'ns-uub': 'Pro',
    'ns-uuc': 'Pro Plus',
};

export const CYCLES = {
    '-m': 'monthly',
    '-a': 'annually',
};

export const SKUS = {
    'ns-uu-m': {plan: PLANS['ns-uu'], cycle: CYCLES['-m']},
    'ns-uu-a': {plan: PLANS['ns-uu'], cycle: CYCLES['-a']},
    'ns-uua-m': {plan: PLANS['ns-uua'], cycle: CYCLES['-m']},
    'ns-uua-a': {plan: PLANS['ns-uua'], cycle: CYCLES['-a']},
    'ns-uub-m': {plan: PLANS['ns-uub'], cycle: CYCLES['-m']},
    'ns-uub-a': {plan: PLANS['ns-uub'], cycle: CYCLES['-a']},
    'ns-uuc-m': {plan: PLANS['ns-uuc'], cycle: CYCLES['-m']},
    'ns-uuc-a': {plan: PLANS['ns-uuc'], cycle: CYCLES['-a']},
};

export type PlanCode = $Keys<typeof PLANS>;
export type CycleCode = $Keys<typeof CYCLES>;
export type SkuCode = $Keys<typeof SKUS>;

type RecurlyDate = {
    date: string, // e.g. 2018-06-02 03:59:59.000000
    timezone_type: number,
    timezone: string, // e.g. "Z"
};

type DollarDiscount = {
    discount_type: 'dollars',
    discount_in_cents: number,
};

type PercentDiscount = {
    discount_type: 'percent',
    discount_percent: number,
};

export type Discount = PercentDiscount | DollarDiscount;

type TemporalDuration = {
    duration: 'temporal',
    temporal_amount: number,
    temporal_unit: 'day' | 'week' | 'month' | 'year',
    single_use: false,
};

type ForeverDuration = {
    duration: 'forever',
    single_use: false,
};

type SingleUseDuration = {
    duration: 'single_use',
    single_use: true,
};

type Common = {
    applies_to_all_plans: boolean,
    applies_to_non_plan_charges: boolean,
    coupon_code: string,
    coupon_type: 'single_code',
    created_at: RecurlyDate,
    description: string,
    duration: 'forever' | 'temporal' | 'single_use',
    id: number,
    invoice_description: string,
    max_redemptions_per_account: number,
    name: string,
    plan_codes: SkuCode[],
    redeem_by_date?: RecurlyDate,
    redemption_resource: 'account' | 'subscription',
    redemptions: {objectType: 'redemptions'},
    state: 'redeemable',
    updated_at?: RecurlyDate,
};

// This is ugly, but we need to union togehter all of the combinations.
// It's not possible to union them, and then make an intersection.
export type Coupon =
    | (TemporalDuration & DollarDiscount & Common)
    | (TemporalDuration & PercentDiscount & Common)
    | (ForeverDuration & DollarDiscount & Common)
    | (ForeverDuration & PercentDiscount & Common)
    | (SingleUseDuration & DollarDiscount & Common)
    | (SingleUseDuration & PercentDiscount & Common);

export type ProFeatures = {
    multiplePipelines: boolean,
    leadAutomations: boolean,
};
