/* @flow */

import * as React from 'react';

type Props = {
    size?: number,
};

export function PadlockLady(props: Props) {
    return (
        <svg
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 221 268'
            width={props.size}
        >
            <path
                d='M85.192 185.009c-10.23 53.141-12.908 80.311-16.98 81.359-6.485 1.666-10.135-8.442-14.03-85.33l31.01 3.971Z'
                fill='#9C746F'
            />
            <path
                d='M78.898 227.294c-1 5.757-3.822 19.772-4.968 25.504-.695 3.473-1.37 7.022-2.556 10.369-.93 2.63-2.394 4.659-5.383 3.423-2.419-1-3.547-3.817-4.35-6.105-1.875-5.349-2.688-11.108-3.461-16.698-1.67-12.043-2.582-24.191-3.414-36.317-.046-.676 1.8-.303 1.839.257.71 10.381 1.513 20.764 2.744 31.097.734 6.169 1.545 12.392 3.093 18.419.68 2.653 1.46 6.174 3.533 8.153.254.243.6.469.759.543.07.032.206.094.433.046 1.281-.271 1.76-1.717 2.185-2.829 1.233-3.24 1.894-6.727 2.584-10.111 1.215-5.974 4.076-20.241 5.117-26.246.096-.557 1.953-.126 1.845.495Z'
                fill='#0C0F33'
            />
            <path
                d='M50.74 185.009c-10.23 53.141-12.907 80.311-16.979 81.359-6.486 1.666-10.135-8.442-14.03-85.33l31.01 3.971Z'
                fill='#9C746F'
            />
            <path
                d='M47.322 208.619c-2.499 13.941-4.718 27.938-7.387 41.847-.715 3.725-1.43 7.481-2.508 11.124-.44 1.49-.963 3.872-2.327 4.853-1.96 1.41-5.074-.023-6.36-1.678-2.948-3.801-3.57-9.723-4.314-14.285-2.33-14.24-3.187-28.75-4.113-43.132-.019-.295 1.809-.075 1.845.496.83 12.898 1.69 25.84 3.448 38.651.761 5.548 1.401 11.759 3.912 16.86.508 1.034 1.228 2.112 2.113 2.676.232.146.177.126.507.098.401-.032.807-.059 1.16-.28 1.282-.795 1.786-3.118 2.187-4.429 1.007-3.301 1.678-6.71 2.343-10.092 2.816-14.35 5.07-28.812 7.65-43.205.074-.41 1.933.007 1.846.496h-.002Z'
                fill='#0C0F33'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.675 129.278c9.882 3.506 25.614 8.442 33.616 8.622 9.354.21 17.348.088 20.175 0l21.925-3.78.018.266c1.477 20.732.798 41.469-.325 62.304-.015.277-.022.562-.03.851-.034 1.373-.07 2.821-.928 3.734-1.594 1.699-9.54 1.966-13.995 2.116-.904.03-1.664.056-2.198.087-6.42.381-12.846.663-19.274.828-12.853.33-25.72.197-38.555-.557-4.147-.244-4.12-5.292-4.098-9.129.004-.84.008-1.623-.031-2.284-.215-3.537-.344-7.077-.473-10.618-.065-1.79-.13-3.58-.206-5.37a175.398 175.398 0 0 1 .816-26.153 172.119 172.119 0 0 1 3.563-20.917Z'
                fill='#DBDBF2'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='m91.433 134.712-.023-.326c-1.41-19.79-3.245-40.043-11.159-57.65a88.802 88.802 0 0 0-1.515-3.2l-13.468-2.235H39.929c-5.555 7.194-9.744 17.013-13.51 25.838l-.695 1.63c-4.372 10.221-7.852 20.814-10.31 31.643 9.864 3.064 25.804 7.446 33.877 7.604 9.354.184 17.348.077 20.176 0l21.966-3.304Z'
                fill='#62D5CA'
            />
            <path
                d='M82.477 43.575c.901-19.456-7.616-35.658-19.024-36.185-11.408-.53-21.39 14.815-22.291 34.27-.02.452-.124 2.692-.163 3.487-.23 4.927-.28 9.882.142 14.806a56.49 56.49 0 0 0 .734 5.576c.88 4.709-.099 9.416-.13 14.22-.021 3.263 1.412 5.778 3.023 8.69 5.562 10.053 22.69 18.591 32.858 9.168 4.556-4.22 5.525-10.878 3.898-16.502-.608-2.1-1.555-4.106-2.185-6.2-.61-2.022-1.023-4.275-.113-6.238.99-2.134 1.45-4.3 1.823-6.637.791-4.954 1.034-9.964 1.266-14.966l.162-3.487v-.002Z'
                fill='#9C746F'
            />
            <path
                d='M44.135 78.58c.341-.044 4.505 7.575 4.766 7.956a26.76 26.76 0 0 0 2.997 3.612c2.101 2.125 4.573 3.938 7.32 5.127 5.556 2.405 11.593 1.524 15.615-3.204.987-1.159 1.777-2.476 2.39-3.871.559-1.274 2.074-10.32 2.77-11.442.708-1.14 1.786 3.366 1.994 4.064 1.953 7.215-1.208 15.386-4.875 18.213-12.323 9.492-27.86-.409-32.653-9.83-1.179-2.315-2.26-4.396-2.733-6.744.133-2.162.692-3.654 2.409-3.879v-.002Z'
                fill='#62D5CA'
            />
            <path
                d='M85.891 136.323c-13.171 2.657-26.666 3.416-40.022 1.825-10.45-1.245-20.618-3.905-30.625-7.101-1.117-.357-.787-1.666.348-1.304 9.842 3.145 19.84 5.791 30.12 7.015 13.21 1.573 26.52.817 39.546-1.811.931-.188 1.855 1.128.63 1.376h.003ZM66.122 56.22c.417 1.757 1.215 3.527 2.801 3.593 2.611.11 3.677-3.833 4.106-6.238a.666.666 0 0 0-.747-.782 20.71 20.71 0 0 1-5.833.057.665.665 0 0 0-.74.727c.064.672.179 1.656.413 2.646v-.002Z'
                fill='#0C0F33'
            />
            <path
                d='M26.62 25.875c2.53-7.02 6.49-13.352 12.815-18.249C48.317.748 60.47-2.406 71.133 2.106c3.029 1.281 5.8 3.122 8.224 5.341 5.216 4.778 7.048 10.957 7.976 17.773.083.61.154 1.277-.172 1.797-.369.59-1.114.793-1.795.938-6.619 1.405-13.391 1.465-20.056 2.643-2.866.507-5.624.789-8.524 1.041-1.215-1.04-1.557-2.751-1.825-4.328-.176-1.046-.355-2.093-.532-3.139.294 1.729-2.73 6.578-3.434 18.165-1.355 22.32-5.777 45.494-14.932 66.22-3.661 8.288-8.7 16.743-18.602 18.733-6.764 1.359-14.712-1.759-17.461-7.978 10.165-13.566 16.995-29.497 19.78-46.142 1.805-10.784 1.933-21.798 3.421-32.633.693-5.044 1.743-10 3.421-14.657l-.002-.005Z'
                fill='#0C0F33'
            />
            <path
                d='M89.481 121.746c-1.552-14.092-3.677-28.653-8.842-41.932-.947-2.43-2.563-4.812-3.004-7.378-.112-.66-.408-1.467-.348-2.136.062-.709.683-1.704.921-2.37.317-.884.594-1.78.846-2.686 1.885-6.804 2.133-13.923 2.458-20.934.305-6.58-.016-13.093-1.988-19.425-1.715-5.51-4.686-11.243-9.599-14.54-10.823-7.265-20.787 4.785-24.41 14.006-2.785 7.089-3.39 14.533-3.613 22.071-.206 6.951-.284 14.622 2.121 21.262.344.947-1.497.839-1.747.147-2.153-5.945-2.345-12.596-2.288-18.848.064-7.05.333-14.224 2.295-21.035 2.818-9.78 10.447-22.67 22.447-20.87 11.993 1.8 17.227 16.127 18.442 26.546.777 6.658.225 13.614-.332 20.279-.324 3.881-.84 7.767-1.855 11.534a38.773 38.773 0 0 1-.929 2.987c-.24.672-.823 1.497-.9 2.183-.09.775.274 2.07.483 2.822.493 1.768 1.64 3.494 2.34 5.214a82.154 82.154 0 0 1 2.005 5.502c1.339 4.095 2.411 8.274 3.338 12.479 1.834 8.343 3.09 16.809 4.025 25.297.097.868-1.776.58-1.859-.175h-.007Zm-73.139 8.896c-1.935 8.604-3.251 17.334-3.863 26.133-.62 8.905-.326 17.764.002 26.671.137 3.741.46 7.494.45 11.238-.008 2.302-.2 4.067.898 6.176.983 1.892 3.047 1.619 4.91 1.715 2.266.117 4.533.213 6.8.291 4.536.159 9.073.243 13.61.264 10.294.046 27.922-.254 38.2-.8.451-.023 2.12 1.701 1.176 1.751-9.657.514-26.664.81-36.334.812-5.041 0-10.083-.076-15.122-.243a416.565 416.565 0 0 1-6.8-.28c-1.765-.087-3.689.042-5.395-.497-4.789-1.518-3.652-7.919-3.821-11.787-.43-9.807-1.071-19.634-.746-29.453.365-10.94 1.789-21.807 4.19-32.487.107-.474 1.96-.018 1.845.496Z'
                fill='#0C0F33'
            />
            <path
                d='M79.589 81.325c-.53 3.82-1.215 7.868-3.455 11.105-2.217 3.205-6.44 5.86-10.336 5.694-4.28-.18-8.056-2.132-11.463-4.585-3.799-2.737-6.612-7.07-8.847-11.203-.14-.26 1.566-.154 1.82.321 1.85 3.423 4.375 6.32 7.42 8.733 3.778 2.992 8.631 6.11 13.652 4.764 6.827-1.83 8.517-9.233 9.36-15.32.053-.373 1.913.007 1.846.495l.003-.004Z'
                fill='#0C0F33'
            />
            <path
                d='M147.565 88.787c33.376 0 60.431 27.056 60.431 60.432v36.856h-17.433v-36.409c0-23.747-19.251-43.001-43-43.001-23.747 0-43.001 19.252-43.001 43.001v20.235H87.129v-20.682c0-33.376 27.055-60.431 60.431-60.431h.005Z'
                fill='#fff'
            />
            <path
                d='M86.205 157.777c0-4.7-.172-9.443.236-14.129a60.266 60.266 0 0 1 6.103-21.638c6.57-13.157 17.862-24.13 31.476-29.71 13.81-5.66 29.568-6.046 43.658-1.135 13.749 4.791 25.574 14.925 32.936 27.493a60.644 60.644 0 0 1 7.919 23.756c.612 5.402.39 12.264.39 17.69 0 .865-1.857.523-1.857-.188 0-4.113.126-9.624-.072-13.735-.353-7.348-2.042-14.627-5.023-21.353-5.855-13.217-16.5-24.096-29.653-30.12-13.492-6.182-29.132-6.975-43.211-2.314-13.595 4.5-25.285 13.989-32.62 26.273-4.163 6.974-6.861 14.806-7.916 22.858-.715 5.443-.511 12.795-.511 18.272 0 .864-1.857.523-1.857-.188l.002-1.832Z'
                fill='#0C0F33'
            />
            <path
                d='M189.635 158.082c0-7.875.186-15.574-2.545-23.114-3.712-10.26-11.484-17.993-21.326-22.715-20.43-9.806-45.614-1.754-55.729 18.398a42.8 42.8 0 0 0-4.512 17.577c-.122 3.272-.03 8.391-.03 11.663 0 .86-1.857.514-1.857-.19 0-2.944-.037-7.724.002-10.666.074-5.612 1.192-11.176 3.382-16.351 4.301-10.167 12.495-18.496 22.614-22.919 20.829-9.102 46.098-.413 56.787 19.685a43.37 43.37 0 0 1 5.016 18.169c.176 3.542.053 8.945.053 12.49 0 .86-1.857.513-1.857-.19l.002-1.837Zm-64.458 109.056h44.007c27.916 0 50.544-22.628 50.544-50.543v-44.67a9.259 9.259 0 0 0-9.26-9.26H84.662a9.259 9.259 0 0 0-9.26 9.26v45.435c0 27.491 22.285 49.776 49.775 49.776v.002Z'
                fill='#0C0F33'
            />
            <path
                d='M84.928 244.987c8.533 12.545 22.17 20.791 37.439 21.63 5.057.278 10.17.078 15.23.078h23.417c5.841 0 11.706.221 17.476-.884 22.59-4.327 39.573-24.493 40.289-47.426.392-12.55.025-25.161.025-37.716 0-5.149 1.242-12.472-3.437-16.007-2.65-2.004-6.185-1.548-9.322-1.548H88.115c-.938 0-1.875-.018-2.813 0-3.813.074-7.215 2.389-8.474 6.044-.644 1.871-.504 3.874-.504 5.821v32.36c0 5.879-.319 11.917.656 17.734 1.192 7.128 3.966 13.902 7.95 19.918.404.61-1.407.381-1.694-.05-5.956-8.992-8.755-19.086-8.755-29.818v-41.368c0-2.591.08-5.03 1.616-7.296 1.921-2.833 5.041-4.195 8.393-4.232 8.754-.098 17.514 0 26.267 0h90.907c2.742 0 5.491-.05 8.233 0 3.366.062 6.681 1.378 8.772 4.109 1.689 2.205 1.98 4.704 1.98 7.357v41.345c0 8.373-1.302 16.447-4.984 24.052-4.826 9.968-12.928 18.164-22.818 23.139-7.671 3.859-15.948 5.363-24.474 5.363H127.36c-10.7 0-21.131-2.625-30.1-8.611-5.553-3.707-10.278-8.527-14.028-14.038-.41-.601 1.403-.376 1.694.05l.002-.006Z'
                fill='#0C0F33'
            />
            <path
                d='M137.28 240.44h20.439a2.014 2.014 0 0 0 1.972-2.425l-5.523-26.425c3.595-2.013 6.03-5.908 6.03-10.383 0-6.541-5.202-11.842-11.617-11.842-6.415 0-11.617 5.301-11.617 11.842 0 4.475 2.437 8.37 3.994 10.383l-5.548 26.551a1.911 1.911 0 0 0 1.87 2.302v-.003Z'
                fill='#DBDBF2'
            />
            <path
                d='M153.457 211.06c6.949-4.108 7.928-14.537 1.009-19.256-6.883-4.693-15.879.697-16.53 8.57-.17 2.059.241 4.148.993 6.06.687 1.749 1.85 3.102 2.687 4.736.398.777.116 1.383-.067 2.252-.86 4.11-1.719 8.221-2.577 12.334-.818 3.914-1.634 7.825-2.453 11.738-.176.849-.532 1.947.718 2.279 1.254.333 3.042 0 4.333 0h13.586c1.394 0 3.666.557 3.663-1.529 0-.729-.332-1.591-.481-2.3l-5.106-24.425c-.188-.899 1.699-.672 1.869.144l5.172 24.747c.225 1.077.736 2.412.172 3.453-.764 1.405-2.355 1.251-3.714 1.251h-14.994c-2.492 0-7.893.833-7.235-3.28.623-3.9 1.625-7.777 2.432-11.642.961-4.593 1.919-9.185 2.88-13.777.059-.284.259-.754.217-1.041.074.498-.717-.724-.804-.857a22.22 22.22 0 0 1-1.374-2.341c-1.795-3.579-2.476-7.77-.99-11.58 2.99-7.666 12.706-10.239 19.228-5.321 7.11 5.358 6.41 16.346-1.21 20.854-.69.408-2.311-.535-1.424-1.06v-.009ZM86.086 91.316c.562 2.391 1.554 4.881 3.402 6.587 2.04 1.884 5.468 1.3 8.063 1 3.171-.367 6.209-1.853 9.091-3.146 2.728-1.224 5.369-2.641 8.118-3.82 3.982-1.707 8.501-2.87 12.843-3.033 1.667-.061 3.446.477 5.007 1.126 1.209.502 3.632 2.077 3.712 3.5.009.17-.048.29-.199.377-.482.277-.901.16-1.401-.016-.197-.069-.532-.296-.548-.537a2.67 2.67 0 0 0-.009-.126c-.055-.823 1.806-.49 1.852.2.002.041.005.085.009.126l-.548-.537.156.055-1.105-.04c-.181.102-1.025-.503-1.217-.68-.713-.657-1.428-1.047-2.371-1.233-1.224-.24-2.503-.649-3.739-.541-2.286.2-4.546.7-6.754 1.31-5.592 1.542-10.608 4.68-15.982 6.822-4.388 1.749-9.604 2.907-14.184 1.043-3.458-1.407-5.244-5.216-6.048-8.638-.18-.757 1.69-.493 1.852.199v.002Zm28.745 16.5c-12.351 20.776-43.615 27.06-62.898 12.397-5.234-3.98-9.136-9.267-11.7-15.301-.178-.42 1.608-.237 1.83.286 4.305 10.138 12.66 16.911 23.332 19.889 11.138 3.108 23.04 1.747 33.002-3.857 6.03-3.39 11.039-7.939 14.588-13.909.31-.518 2.055.145 1.846.495Z'
                fill='#0C0F33'
            />
        </svg>
    );
}
