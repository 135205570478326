/* @flow */

import type {
    Contact,
    ContactId,
    JsonApiLink,
    TaskId,
    TeamId,
    UnixTimestamp,
    UrlString,
    UserId,
} from '../types';
import type {TasksState} from './tasks-reducer';

export const REDUCER_KEY = 'tasks';
export type ReducerKey = 'tasks';

export type TasksModuleState = {
    [ReducerKey]: TasksState,
};

export type TaskRecurringPeriod = '7' | '30' | '90';

export type Task = {
    id: TaskId,
    type: 'tasks',
    title: string,
    href: UrlString,
    description: ?string,
    recurringPeriod: ?TaskRecurringPeriod,
    dueTime: UnixTimestamp,
    createdTime: UnixTimestamp,
    deletedTime: ?UnixTimestamp,
    completedTime: ?UnixTimestamp,
    isCompleted: boolean,
    isOverdue: boolean,
    isRecurring: boolean,
    htmlUrl: UrlString,
    links: {
        creator: UserId,
        completer: ?UserId,
        assignee: UserId | TeamId,
        relatedEntity: ContactId,
    },
};

export type TasksApiResponse = {
    meta: {
        count?: number,
        total?: number,
    },
    links: {[key: string]: JsonApiLink} | [],
    tasks: Task[],
    relatedEntities?: Contact[],
    users?: Object[],
};
