/* @flow */

import type {AccountId, ListApiResponse} from '../types';
import {type AccountFormValues, type AccountsApiResponse} from './accounts-types';

/*
 * Action types
 */
export const ActionTypes = {
    ACCOUNTS_FETCH_BY_ID_SUCCESS: 'ACCOUNTS_FETCH_BY_ID_SUCCESS',
    ACCOUNTS_FETCH_BY_ID_REQUEST: 'ACCOUNTS_FETCH_BY_ID_REQUEST',
    ACCOUNTS_FETCH_BY_ID_FAILURE: 'ACCOUNTS_FETCH_BY_ID_FAILURE',
    ACCOUNTS_ACCOUNT_QUEUED_FOR_CREATION: 'ACCOUNTS_ACCOUNT_QUEUED_FOR_CREATION',
    ACCOUNTS_ACCOUNT_UNQUEUED_FOR_CREATION: 'ACCOUNTS_ACCOUNT_UNQUEUED_FOR_CREATION',
    ACCOUNTS_QUEUED_ACCOUNTS_CLEARED: 'ACCOUNTS_QUEUED_ACCOUNTS_CLEARED',
    ACCOUNTS_FETCH_LIST_SUCCESS: 'ACCOUNTS_FETCH_LIST_SUCCESS',
};

export type ActionFetchByIdRequest = {type: 'ACCOUNTS_FETCH_BY_ID_REQUEST', payload: AccountId};
export type ActionFetchByIdSuccess = {
    type: 'ACCOUNTS_FETCH_BY_ID_SUCCESS',
    payload: AccountsApiResponse,
};
export type ActionFetchByIdFailure = {
    type: 'ACCOUNTS_FETCH_BY_ID_FAILURE',
    payload: ?Object,
    error: true,
};
export type ActionQueueAccountForCreation = {
    type: 'ACCOUNTS_ACCOUNT_QUEUED_FOR_CREATION',
    payload: AccountFormValues,
};
export type ActionUnqueueAccountForCreation = {
    type: 'ACCOUNTS_ACCOUNT_UNQUEUED_FOR_CREATION',
    payload: string,
};
export type ActionClearQueuedAccounts = {type: 'ACCOUNTS_QUEUED_ACCOUNTS_CLEARED'};

export type FetchAccountListSuccessAction = {
    type: 'ACCOUNTS_FETCH_LIST_SUCCESS',
    payload: ListApiResponse,
};

export type AccountsAction =
    | ActionFetchByIdRequest
    | ActionFetchByIdSuccess
    | ActionFetchByIdFailure
    | ActionQueueAccountForCreation
    | ActionUnqueueAccountForCreation
    | ActionClearQueuedAccounts
    | FetchAccountListSuccessAction;

/*
 * Action creators
 *
 * These functions will be called by our app to create an action which can be dispatched.
 */
export const fetchById = (payload: AccountId): ActionFetchByIdRequest => ({
    type: ActionTypes.ACCOUNTS_FETCH_BY_ID_REQUEST,
    payload,
});
export const resolveFetchById = (payload: AccountsApiResponse): ActionFetchByIdSuccess => ({
    type: ActionTypes.ACCOUNTS_FETCH_BY_ID_SUCCESS,
    payload,
});
export const failFetchById = (payload?: Object): ActionFetchByIdFailure => ({
    type: ActionTypes.ACCOUNTS_FETCH_BY_ID_FAILURE,
    payload,
    error: true,
});
export const queueAccountForCreation = (
    payload: AccountFormValues
): ActionQueueAccountForCreation => ({
    type: ActionTypes.ACCOUNTS_ACCOUNT_QUEUED_FOR_CREATION,
    payload,
});
export const unqueueAccountForCreation = (
    accountName: string
): ActionUnqueueAccountForCreation => ({
    type: ActionTypes.ACCOUNTS_ACCOUNT_UNQUEUED_FOR_CREATION,
    payload: accountName,
});
export const clearQueuedAccounts = (): ActionClearQueuedAccounts => ({
    type: ActionTypes.ACCOUNTS_QUEUED_ACCOUNTS_CLEARED,
});

export const resolveFetchListItems = (
    listApiResponse: ListApiResponse
): FetchAccountListSuccessAction => ({
    type: ActionTypes.ACCOUNTS_FETCH_LIST_SUCCESS,
    payload: listApiResponse,
});

export const publicActionCreators = {
    fetchById,
    queueAccountForCreation,
    unqueueAccountForCreation,
    clearQueuedAccounts,
    resolveFetchListItems,
};
