/* @flow */

import {createSelector} from 'reselect';
import type {NutshellState} from '../../../../../store';

import {getUIState} from '../../../../ui-selectors';

const getVisitorsSidebarState = createSelector([getUIState], (uiState) => uiState.visitorsSidebar);

export const getIsOpen = (state: NutshellState) => {
    return getVisitorsSidebarState(state).isOpen;
};

export const getVisitorId = (state: NutshellState) => {
    return getVisitorsSidebarState(state).visitorId;
};
