/* @flow */

import * as React from 'react';
import {getFileIcon} from '../icon';

import './file-icon.css';

type Props = {
    filename: string,
    filetype: string,
    href: string,
};

export class FileIcon extends React.Component<Props> {
    static defaultProps = {
        filetype: 'unknown',
    };

    render() {
        const IconComponent = getFileIcon(this.props.filetype);

        return (
            <div styleName='file-icon'>
                <a href={this.props.href} download={this.props.filename}>
                    <IconComponent width={24} height={24} />
                </a>
            </div>
        );
    }
}
