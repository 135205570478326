/* @flow */

import {useQuery} from '@apollo/react-hooks';

import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import type {
    GetUsers_users_edges_node as User,
    GetUsers as GetUsersQuery,
    GetUsersVariables as GetUsersQueryVariables,
} from 'nutshell-graphql-types';

import GET_USERS from '../queries/get-users.graphql';

export const useGetUsers = (): {
    isLoading: boolean,
    errorMessage: ?string,
    users: User[],
} => {
    const {loading, data, error} = useQuery<GetUsersQuery, GetUsersQueryVariables>(GET_USERS, {
        variables: {first: 1000}, // Get all users at once so we don't have to refetch
        notifyOnNetworkStatusChange: true,
    });

    return {
        isLoading: loading,
        errorMessage: safelyGetGraphQLErrorMessage(error),
        users: data && data.users ? data.users.edges.map((edge) => edge.node) : [],
    };
};
