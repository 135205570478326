import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SidebarIconReportsOld from './sidebar-icon-reports-old.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SidebarIconReportsOld {...validProps} />;
}
SvgComponent.iconName = 'sidebarIconReportsOld';

export const SidebarIconReportsIconOld = svgIconWrapper(SvgComponent);
