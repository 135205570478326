/* @flow */

import * as React from 'react';

export function ManagingSources() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 238 366'>
            <path
                fill='#fff'
                d='M1 29.163h236v48.948a6.993 6.993 0 0 1-6.993 6.993H7.993A6.993 6.993 0 0 1 1 78.11V29.163Z'
            />
            <path fill='#DCF4FF' d='M1 29.163h236v27.97H1v-27.97Z' />
            <g fillRule='evenodd' clipPath='url(#a)' clipRule='evenodd'>
                <path
                    fill='#3B9ED7'
                    d='M16.297 37.03c3.605 0 6.555 2.95 6.555 6.555 0 3.606-2.95 6.556-6.555 6.556-3.606 0-6.556-2.95-6.556-6.556 0-3.605 2.95-6.556 6.556-6.556Z'
                />
                <path
                    fill='#D9EDF7'
                    d='M19.246 44.24h-2.294v2.295a.647.647 0 0 1-.656.656.647.647 0 0 1-.656-.656v-2.294h-2.294a.647.647 0 0 1-.656-.656c0-.368.287-.655.656-.655h2.294v-2.295c0-.368.287-.655.656-.655.369 0 .655.287.655.655v2.295h2.295c.369 0 .655.287.655.655a.647.647 0 0 1-.655.656Z'
                />
            </g>
            <path
                fill='#A6A3A1'
                d='M1 8.185a6.993 6.993 0 0 1 6.993-6.992h222.014A6.993 6.993 0 0 1 237 8.185v21.852H1V8.185Z'
            />
            <g clipPath='url(#b)'>
                <g clipPath='url(#c)'>
                    <path
                        fill='#fff'
                        d='M16.297 22.17a6.555 6.555 0 0 0 6.555-6.555 6.555 6.555 0 0 0-6.555-6.556 6.555 6.555 0 0 0-6.556 6.556 6.555 6.555 0 0 0 6.556 6.555Zm-3.278-3.277 1.639-4.917 4.917-1.639-1.64 4.917-4.916 1.639Zm4.097-3.278a.819.819 0 1 0-1.637-.002.819.819 0 0 0 1.637.002Z'
                    />
                </g>
            </g>
            <rect
                width='236.874'
                height='84.785'
                x='.563'
                y='.756'
                stroke='#EBE8E8'
                strokeWidth='.874'
                rx='7.43'
            />
            <path
                fill='#fff'
                d='M1 127.059h236v104.889a6.993 6.993 0 0 1-6.993 6.993H7.993A6.993 6.993 0 0 1 1 231.948V127.059Z'
            />
            <path fill='#DCF4FF' d='M1 127.059h236v27.971H1v-27.971Z' />
            <g fillRule='evenodd' clipPath='url(#d)' clipRule='evenodd'>
                <path
                    fill='#3B9ED7'
                    d='M16.297 134.926c3.605 0 6.555 2.95 6.555 6.556 0 3.605-2.95 6.555-6.555 6.555-3.606 0-6.556-2.95-6.556-6.555 0-3.606 2.95-6.556 6.556-6.556Z'
                />
                <path
                    fill='#D9EDF7'
                    d='M19.246 142.137h-2.294v2.295a.647.647 0 0 1-.656.655.647.647 0 0 1-.656-.655v-2.295h-2.294a.647.647 0 0 1-.656-.655c0-.369.287-.656.656-.656h2.294v-2.294c0-.369.287-.656.656-.656.369 0 .655.287.655.656v2.294h2.295c.369 0 .655.287.655.656a.647.647 0 0 1-.655.655Z'
                />
            </g>
            <path
                fill='#A6A3A1'
                d='M1 106.081a6.993 6.993 0 0 1 6.993-6.992h222.014A6.993 6.993 0 0 1 237 106.08v21.852H1v-21.852Z'
            />
            <g clipPath='url(#e)'>
                <path
                    fill='#fff'
                    d='M9.741 110.202c0-.826.735-1.498 1.64-1.498h9.832c.904 0 1.64.672 1.64 1.498v7.492c0 .827-.736 1.499-1.64 1.499H11.38c-.904 0-1.639-.672-1.639-1.499v-7.492Zm1.64.749c0 .415.365.749.819.749.453 0 .819-.334.819-.749 0-.414-.366-.749-.82-.749-.453 0-.819.335-.819.749Zm9.832 0c0-.311-.274-.562-.614-.562h-6.146c-.34 0-.615.251-.615.562 0 .312.274.562.615.562h6.146c.34 0 .614-.25.614-.562Z'
                />
            </g>
            <rect
                width='236.874'
                height='140.726'
                x='.563'
                y='98.652'
                stroke='#EBE8E8'
                strokeWidth='.874'
                rx='7.43'
            />
            <path
                fill='#fff'
                d='M1 280.896h236v76.919a6.993 6.993 0 0 1-6.993 6.992H7.993A6.993 6.993 0 0 1 1 357.815v-76.919Z'
            />
            <path fill='#DCF4FF' d='M1 280.896h236v27.97H1v-27.97Z' />
            <g fillRule='evenodd' clipPath='url(#f)' clipRule='evenodd'>
                <path
                    fill='#3B9ED7'
                    d='M16.297 288.763c3.605 0 6.555 2.95 6.555 6.555 0 3.606-2.95 6.556-6.555 6.556-3.606 0-6.556-2.95-6.556-6.556 0-3.605 2.95-6.555 6.556-6.555Z'
                />
                <path
                    fill='#D9EDF7'
                    d='M19.246 295.974h-2.294v2.294a.647.647 0 0 1-.656.656.647.647 0 0 1-.656-.656v-2.294h-2.294a.647.647 0 0 1-.656-.656c0-.369.287-.655.656-.655h2.294v-2.295c0-.369.287-.655.656-.655.369 0 .655.286.655.655v2.295h2.295c.369 0 .655.286.655.655a.647.647 0 0 1-.655.656Z'
                />
            </g>
            <path
                fill='#A6A3A1'
                d='M1 259.918a6.992 6.992 0 0 1 6.993-6.992h222.014a6.992 6.992 0 0 1 6.993 6.992v21.852H1v-21.852Z'
            />
            <g clipPath='url(#g)'>
                <path
                    fill='#fff'
                    d='M14.07 260.973a.614.614 0 0 0-.868.868l1.408 1.408h-2.82a2.052 2.052 0 0 0-2.048 2.051v6.555c0 1.132.917 2.048 2.049 2.048h9.013a2.047 2.047 0 0 0 2.048-2.048V265.3a2.047 2.047 0 0 0-2.048-2.048h-2.82l1.409-1.409c.24-.24.24-.63 0-.868a.615.615 0 0 0-.868 0l-2.228 2.228-2.228-2.23Zm7.758 5.761c0 .34-.274.614-.615.614a.613.613 0 0 1-.614-.614.613.613 0 1 1 1.229 0Zm-.615 2.663a.613.613 0 0 1-.614-.615c0-.34.274-.614.614-.614.34 0 .615.274.615.614a.613.613 0 0 1-.615.615Zm-9.832-2.868a1.64 1.64 0 0 1 1.639-1.639h4.916a1.64 1.64 0 0 1 1.639 1.639v4.097a1.64 1.64 0 0 1-1.64 1.639H13.02a1.64 1.64 0 0 1-1.64-1.639v-4.097Z'
                />
            </g>
            <rect
                width='236.874'
                height='112.756'
                x='.563'
                y='252.489'
                stroke='#EBE8E8'
                strokeWidth='.874'
                rx='7.43'
            />
            <defs>
                <clipPath id='a'>
                    <path fill='#fff' d='M9.741 37.03h13.111v13.11H9.742z' />
                </clipPath>
                <clipPath id='b'>
                    <path fill='#fff' d='M9.741 9.06h13.111v13.11H9.742z' />
                </clipPath>
                <clipPath id='c'>
                    <path fill='#fff' d='M9.741 9.06h13.111v13.11H9.742z' />
                </clipPath>
                <clipPath id='d'>
                    <path fill='#fff' d='M9.741 134.926h13.111v13.111H9.742z' />
                </clipPath>
                <clipPath id='e'>
                    <path fill='#fff' d='M9.741 107.393h13.111v13.111H9.742z' />
                </clipPath>
                <clipPath id='f'>
                    <path fill='#fff' d='M9.741 288.763h13.111v13.111H9.742z' />
                </clipPath>
                <clipPath id='g'>
                    <path fill='#fff' d='M9.741 260.792h13.111v13.111H9.742z' />
                </clipPath>
            </defs>
        </svg>
    );
}
