/* @flow */

import * as React from 'react';
import moment from 'moment';

import {isToday} from 'nutshell-core/date-time';

import {FormattedTimestamp} from '../../../timestamp';

type Props = {
    timestamp: number,
};

function TimelineHeaderTimestamp(props: Props) {
    const {timestamp} = props;

    const timestampMoment = moment.unix(timestamp);
    const getTimestampFormat = () => {
        if (isToday(timestampMoment)) {
            return FormattedTimestamp.formats.time;
        } else {
            return moment().year() === timestampMoment.year()
                ? FormattedTimestamp.formats.timeWithDate
                : FormattedTimestamp.formats.timeWithDateYear;
        }
    };

    return <FormattedTimestamp datetime={timestamp} format={getTimestampFormat()} />;
}

export default React.memo<Props>(
    TimelineHeaderTimestamp,
    (prevProps, nextProps) => prevProps.timestamp === nextProps.timestamp
);
