/* @flow */

import * as React from 'react';

import classnames from 'classnames';

import moment from 'moment';
import _ from 'underscore';

import {RETURN} from '../utils/keys';
import {TimesCircleIcon, TickCircleIcon} from '../icon';
import {Popover, type LocationEnum} from '../popover';
import {DateTimePicker} from './date-time-picker';
import type {CannedOption} from './canned-options';
import styles from './date-time-picker-popover.css';

const DATE_FORMAT = 'MMMM Do, YYYY';
const TIME_FORMAT = 'h:mm A';
const INPUT_FORMAT_WITH_TIME = `${DATE_FORMAT} [at] ${TIME_FORMAT}`;
const INPUT_FORMAT_WITHOUT_TIME = DATE_FORMAT;

/**
 * Get a date object ignoring the time portion.
 * @param  {Date} date The date to sanitize
 * @return {Date}      The sanitized date
 */
function dateWithoutTime(date: Date): Date {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);

    return d;
}

/*
 * Get a date object ignoring the seconds of the time portion.
 */
function dateWithoutSeconds(date: Date): Date {
    const d = new Date(date);
    d.setSeconds(0, 0);

    return d;
}

/**
 * Validate a given date is greater than the given minDate and less than the
 * given maxDate.
 *
 * @param  {Date} date    The date to check
 * @param  {Date} minDate The min date, or undefined if no min date
 * @param  {Date} maxDate The max date, or undefined if no max date
 * @param  {Boolean} shouldOmitTime Should we ignore time or not
 * @return {Boolean}      return the equality conditions of minDate and maxDate.
 */
function validateMinMax(
    date: Date,
    minDate: ?Date,
    maxDate: ?Date,
    shouldOmitTime: boolean
): boolean {
    if (!(date instanceof Date)) return true;

    const dateCheck = shouldOmitTime ? dateWithoutTime(date) : dateWithoutSeconds(date);

    let isValidMin = true;
    let isValidMax = true;

    if (minDate) {
        const minDateCheck = shouldOmitTime
            ? dateWithoutTime(minDate)
            : dateWithoutSeconds(minDate);
        if (minDateCheck instanceof Date) {
            isValidMin = dateCheck.getTime() >= minDateCheck.getTime();
        }
    }

    if (maxDate) {
        const maxDateCheck = shouldOmitTime
            ? dateWithoutTime(maxDate)
            : dateWithoutSeconds(maxDate);
        if (maxDateCheck instanceof Date) {
            isValidMax = dateCheck.getTime() <= maxDateCheck.getTime();
        }
    }

    return isValidMin && isValidMax;
}

type Props = {
    anchor: HTMLElement,
    location: LocationEnum,
    shouldOmitTime: boolean,
    value: Date,
    minDate?: Date,
    maxDate?: Date,
    displayFormat?: string,
    onCancel: () => void,
    onConfirm: (Date) => void,
    onClear?: () => void,
    cannedOptions?: CannedOption[],
};

type State = {
    displayDate: string,
    isValidSelection: boolean,
    isValidDate: boolean,
    invalidShakeAnimation: boolean,
};

export class DateTimePickerPopover extends React.PureComponent<Props, State> {
    animateInvalidDuration = parseInt(styles.animateInvalidDuration, 10) || 800;

    initialDateParse = true;
    initialRender = true;

    inputRef: ?HTMLInputElement;
    datePickerRef: ?DateTimePicker;

    invalidFeedbackTimeout: ?TimeoutID;

    static defaultProps = {
        location: 'top',
        shouldOmitTime: false,
        value: moment()
            .startOf('minute')
            .toDate(),
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            displayDate: this.getFormattedDate(props.value),
            isValidSelection: true,
            isValidDate: true,
            invalidShakeAnimation: false,
        };

        this.initialDateParse = true;
        this.initialRender = true;
    }

    componentDidMount() {
        this.focusTextfield();
        this.initialRender = false;
    }

    render() {
        const inputStyleName =
            this.state.isValidSelection && this.state.isValidDate
                ? 'styles.textfield'
                : 'styles.textfield--invalid';
        const popoverStyleName = classnames('popover', {
            'styles.invalid-shake': this.state.invalidShakeAnimation,
            'styles.animate-in': this.initialRender,
        });

        return (
            <Popover
                anchor={this.props.anchor}
                location={this.props.location}
                noDefaultStyling={true}
                offsetPosition={15}
                styleName={popoverStyleName}
                onBlur={this.props.onCancel}
            >
                <input
                    autoComplete='off'
                    ref={(node) => {
                        this.inputRef = node;
                    }}
                    styleName={inputStyleName}
                    value={this.state.displayDate}
                    onChange={this.handleChange}
                    onKeyUp={this.handleKeyUp}
                />
                {this.renderActionButtons()}
                <div>
                    <DateTimePicker
                        type='popover-picker'
                        textFieldClassName=''
                        ref={(c) => {
                            this.datePickerRef = c;
                        }}
                        shouldOmitTime={this.props.shouldOmitTime}
                        value={this.state.displayDate}
                        onUpdate={this.handlePickerUpdate}
                        onSelectDate={this.handlePickerSelectDate}
                        cannedOptions={this.props.cannedOptions}
                        maxDate={this.props.maxDate}
                        minDate={this.props.minDate}
                    />
                </div>
            </Popover>
        );
    }

    renderActionButtons = () => {
        const {isValidDate, isValidSelection} = this.state;

        return (
            <div styleName='styles.actions-container'>
                <button
                    onClick={this.handleConfirm}
                    styleName='styles.button-confirm'
                    type='button'
                    disabled={this.state.displayDate && (!isValidSelection || !isValidDate)}
                >
                    <TickCircleIcon style={{height: 20}} size={20} />
                </button>
                <button
                    onClick={this.props.onCancel}
                    styleName='styles.button-cancel'
                    type='button'
                >
                    <TimesCircleIcon />
                </button>
            </div>
        );
    };

    handleChange = (e: SyntheticInputEvent<*>) => {
        this.setState({
            displayDate: e.target.value,
            isValidSelection: true,
            isValidDate: true,
        });
    };

    handleKeyUp = (e: SyntheticKeyboardEvent<*>) => {
        if (e.keyCode === RETURN) {
            this.handleConfirm();
        }
    };

    handleConfirm = _.throttle(
        () => {
            const datePickerRef = this.datePickerRef;
            if (!datePickerRef) {
                // This should never happen, but is needed to make flow happy.
                this.props.onCancel();

                return;
            }
            const value = datePickerRef.getCurrentSelection();

            if (!this.state.displayDate && this.props.onClear) {
                this.props.onClear();

                return;
            }

            if (
                this.state.isValidSelection &&
                validateMinMax(
                    value,
                    this.props.minDate,
                    this.props.maxDate,
                    this.props.shouldOmitTime
                )
            ) {
                this.props.onConfirm(value);
            } else {
                this.renderInvalidSelectionFeedback();
            }
        },
        500,
        {trailing: false, leading: true}
    );

    handlePickerSelectDate = (val: ?Date) => {
        const isValid = val
            ? validateMinMax(val, this.props.minDate, this.props.maxDate, this.props.shouldOmitTime)
            : false;

        if (
            !isValid &&
            val &&
            this.props.maxDate &&
            moment(val).isSame(this.props.maxDate, 'day')
        ) {
            // The current time is too late, but the day is okay, so just use the max.
            this.setState({
                displayDate: val ? this.getFormattedDate(this.props.maxDate) : '',
                isValidSelection: true,
                isValidDate: true,
            });
        } else if (
            !isValid &&
            val &&
            this.props.minDate &&
            moment(val).isSame(this.props.minDate, 'day')
        ) {
            // The current time is too early, but the day is okay, so just use the min.
            this.setState({
                displayDate: val ? this.getFormattedDate(this.props.minDate) : '',
                isValidSelection: true,
                isValidDate: true,
            });
        } else {
            this.setState({
                displayDate: val ? this.getFormattedDate(val) : '',
                isValidSelection: isValid,
                isValidDate: true,
            });
            if (!isValid) this.renderInvalidSelectionFeedback();
        }

        this.focusTextfield();
    };

    handlePickerUpdate = _.debounce((date, isValid) => {
        const isValidSelection = validateMinMax(
            date,
            this.props.minDate,
            this.props.maxDate,
            this.props.shouldOmitTime
        );

        if ((!isValid || !isValidSelection) && !this.initialDateParse) {
            this.renderInvalidSelectionFeedback();
        }

        this.setState({
            isValidDate: isValid,
            isValidSelection: isValidSelection,
        });

        this.initialDateParse = false;
    }, 500);

    renderInvalidSelectionFeedback = _.throttle(
        () => {
            if (this.state.displayDate.length === 0) return;

            this.setState({invalidShakeAnimation: true});

            clearTimeout(this.invalidFeedbackTimeout);
            this.invalidFeedbackTimeout = setTimeout(() => {
                this.setState({invalidShakeAnimation: false});
            }, this.animateInvalidDuration);
        },
        this.animateInvalidDuration,
        {trailing: false, leading: true}
    );

    getFormattedDate = (date: Date) => {
        return moment(date).format(this.getDisplayFormat());
    };

    focusTextfield = () => {
        if (this.inputRef) this.inputRef.select();
    };

    getDisplayFormat = (): string => {
        if (this.props.displayFormat) return this.props.displayFormat;

        return this.props.shouldOmitTime ? INPUT_FORMAT_WITHOUT_TIME : INPUT_FORMAT_WITH_TIME;
    };
}
