/* @flow */

import {unexpectedCase} from 'nutshell-core/reducer-utils';
import {REHYDRATE} from 'redux-persist';
import {ActionTypes, type VisitorsSidebarAction} from './actions';

export type VisitorsSidebarState = {
    isOpen: boolean,
    visitorId: ?string,
};

export const defaultVisitorsSidebarState = {
    isOpen: false,
    visitorId: null,
};

export const visitorsSidebarReducer = (
    state: VisitorsSidebarState = defaultVisitorsSidebarState,
    action: VisitorsSidebarAction
): VisitorsSidebarState => {
    switch (action.type) {
        case ActionTypes.VISITORS_SIDEBAR_OPENED:
            return {
                ...state,
                isOpen: true,
                visitorId: action.payload.visitorId,
            };
        case ActionTypes.VISITORS_SIDEBAR_CLOSED:
            return defaultVisitorsSidebarState;
        case REHYDRATE: {
            const persistedState = action.payload;
            if (!persistedState || !persistedState.ui || !persistedState.ui.visitorSidebar) {
                return state;
            }

            return persistedState.ui.visitorsSidebar;
        }
        default:
            unexpectedCase(action.type);

            return state;
    }
};
