/* @flow */

import type {
    GetSourcesVariables as GetSourcesQueryVariables,
    ChannelType,
} from 'nutshell-graphql-types';

import {CHANNEL_UNKNOWN} from 'nutshell-core/channels/consts';

export const SOURCES_WRAPPER_LIMIT = 5000;
export const LIMIT = 100;

export const getQueryVariables = (input: {
    channel?: ?ChannelType,
    limit?: number,
}): GetSourcesQueryVariables => {
    const first = input.limit ? input.limit : LIMIT;

    if (input.channel === undefined) {
        return {sort: 'NAME_ASC', first};
    } else if (input.channel === null || input.channel === CHANNEL_UNKNOWN) {
        return {
            hasChannel: false,
            sort: 'NAME_ASC',
            first,
        };
    } else {
        return {
            channel: input.channel,
            sort: 'NAME_ASC',
            first,
        };
    }
};
