/* @flow */

import * as React from 'react';

import {useDebouncedSaveValue} from '../utils/hooks';

type CustomSaveStates = {
    initial: string,
    saving: string,
    complete: string,
};

/**
 * This hook uses the value of 'isSaving' to maintain a save state of either: initial, saving, or complete.
 *
 * Returns:
 * toastState: the save state in the form used by components like SavedSettingToast - 'initial', 'saving', 'complete'
 * customSaveState: the save state's custom state string if one has been passed in - uses toastState as default
 * saveFailed(): function to call if save failed to reset state to initial without hitting 'complete'
 *
 * @param isSaving
 * @param resetOnCompleteTimeout - resets the save state to it initial state after the complete state is reached.
 * @param customSaveStates - custom strings for each save state that can be used directly in UI or within save buttons.
 * @returns {{customSaveState: *, toastState: *, saveFailed: (function(): *)}}
 */
export function useManageSaveState(
    isSaving: boolean,
    resetOnCompleteTimeout?: ?number,
    customSaveStates?: CustomSaveStates
) {
    const savedTimeoutRef = React.useRef();

    /* State that manages the current save state */
    const [saveState, setSaveState] = React.useState<'initial' | 'saving' | 'complete'>('initial');
    /* State optionally set by the parent if the save fails to reset the saveState controlled by this component */
    const [saveFailed, setSaveFailed] = React.useState<boolean>(false);
    /* It is possible for the error state to be reported after save state changes, so we should debounce value */
    const debouncedIsSaving = useDebouncedSaveValue(isSaving);

    /** Effect runs when saveFailed changes between true and false */
    React.useEffect(() => {
        // If the save failed, and the saveState is currently saving then we want to reset
        // the saveState back to initial
        if (saveFailed && saveState === 'saving') {
            setSaveState('initial');
            setSaveFailed(false);
        }
    }, [saveFailed]); // eslint-disable-line react-hooks/exhaustive-deps

    /** Effect runs when isSaving changes between true and false */
    React.useEffect(() => {
        if (saveState === 'initial') {
            // When initially setting button state, don't use the debounced value
            if (isSaving) {
                setSaveState('saving');
            }
        } else if (saveState === 'saving') {
            // When the button is in saving state, we will use the debounced save value
            // to briefly wait in case the save error is reported after save state changes
            if (!debouncedIsSaving && !saveFailed) {
                setSaveState('complete');
            }
        }
    }, [debouncedIsSaving]); // eslint-disable-line react-hooks/exhaustive-deps

    /** Effect runs when saveState updates */
    React.useEffect(() => {
        // If the saveState is 'complete' and the state should reset after completion
        if (saveState === 'complete' && resetOnCompleteTimeout) {
            // Clear current savedTimeoutRef if one is currently running (rare, but could happen)
            if (savedTimeoutRef.current) {
                clearTimeout(savedTimeoutRef.current);
            }
            // Set timeout to return toast state back to initial
            savedTimeoutRef.current = setTimeout(() => {
                setSaveState('initial');
            }, resetOnCompleteTimeout);
        }
    }, [saveState]); // eslint-disable-line react-hooks/exhaustive-deps

    /** Effect runs when component unmounts */
    React.useEffect(() => {
        return () => {
            // Clear current savedTimeoutRef if one is currently running
            if (savedTimeoutRef.current) {
                clearTimeout(savedTimeoutRef.current);
            }
        };
    }, []);

    // This just allows the component to return the saveState in custom form that the parent component is using,
    // if they are using one. Otherwise it will return the saveState in default 'initial', 'saving', 'complete' form.
    let customSaveState = saveState;
    if (customSaveStates) {
        customSaveState = customSaveStates[saveState];
    }

    return {toastState: saveState, customSaveState, saveFailed: () => setSaveFailed(true)};
}
