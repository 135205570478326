/* @flow */

import * as React from 'react';

import {getApiTypeFromGraphQLTypename} from 'nutshell-core/utils';
import {LinkedEntity} from 'shells/linked-entity';

import type {EventForTimeline} from '../types';
import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {|event: EventForTimeline|};

export function TimelineBannerAssignment(props: Props) {
    const {event} = props;
    const {payload} = event;

    let action;
    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'Assignment' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (
        payload.__typename === 'Assignment' &&
        (payload.assignmentEntity.__typename === 'Account' ||
            payload.assignmentEntity.__typename === 'Activity' ||
            payload.assignmentEntity.__typename === 'Contact' ||
            payload.assignmentEntity.__typename === 'Lead') &&
        payload.assigneeEntity.name
    ) {
        const assignmentEntityName = payload.assignmentEntity.name;
        const assignmentEntityHtmlUrl = payload.assignmentEntity.htmlUrl;

        const assignmentEntityLink = (
            <strong>
                <LinkedEntity
                    type={getApiTypeFromGraphQLTypename(payload.assignmentEntity.__typename)}
                    useEntityColor={true}
                    shouldClientRoute={true}
                    name={assignmentEntityName}
                    htmlUrl={assignmentEntityHtmlUrl}
                    allowWrap={true}
                    greenLeads={true}
                    displayInline={true}
                />
            </strong>
        );

        const assigneeEntityLink = (
            <strong>
                <LinkedEntity
                    type={getApiTypeFromGraphQLTypename(payload.assigneeEntity.__typename)}
                    useEntityColor={true}
                    shouldClientRoute={true}
                    name={payload.assigneeEntity.name}
                    allowWrap={true}
                    displayInline={true}
                />
            </strong>
        );

        // For reassignments
        if (event.previousAssignee) {
            const previousAssigneeName = event.previousAssignee.name;

            const previousAssigneeEntityLink = (
                <strong>
                    <LinkedEntity
                        type={getApiTypeFromGraphQLTypename(event.previousAssignee.__typename)}
                        useEntityColor={true}
                        shouldClientRoute={true}
                        name={previousAssigneeName}
                        allowWrap={true}
                        displayInline={true}
                    />
                </strong>
            );

            action = (
                <span>
                    reassigned&nbsp;{assignmentEntityLink}&nbsp;to&nbsp;
                    {assigneeEntityLink}&nbsp;from&nbsp;
                    {previousAssigneeEntityLink}
                </span>
            );
        }

        // For added assignments
        if (!event.previousAssignee && event.changeType !== 'DELETED') {
            action = (
                <span>
                    assigned&nbsp;{assignmentEntityLink}&nbsp;to&nbsp;
                    {assigneeEntityLink}
                </span>
            );
        }

        // For deleted assignments
        if (event.changeType === 'DELETED') {
            action = (
                <span>
                    unassigned&nbsp;{assignmentEntityLink}&nbsp;from&nbsp;
                    {assigneeEntityLink}
                </span>
            );
        }
    }

    return action ? (
        <TimelineBanner
            id={event.id}
            iconVariant='assignee-changed'
            timestamp={event.changeTime}
            actor={event.actor}
            action={action}
        />
    ) : null;
}
