/* @flow */

import {REHYDRATE} from 'redux-persist';

import {unexpectedCase} from 'nutshell-core/reducer-utils';

import type {OpenableModal} from './openable-modals';
import {ActionTypes, type OpenableModalsAction} from './openable-modals-actions';

export type OpenableModalsState = {
    openModals: OpenableModal[],
};

export const defaultOpenableModalsState: OpenableModalsState = {
    openModals: [],
};

export const openableModalsReducer = (
    state: OpenableModalsState = defaultOpenableModalsState,
    action: OpenableModalsAction
): OpenableModalsState => {
    switch (action.type) {
        case ActionTypes.MODAL_OPENED:
            return {
                openModals: [...state.openModals, {id: action.id, options: action.options}],
            };
        case ActionTypes.MODAL_CLOSED:
            return {
                openModals: state.openModals.filter(
                    (openModal: OpenableModal) => openModal.id !== action.id
                ),
            };
        case REHYDRATE: {
            return state;
        }
        default:
            unexpectedCase(action.type);

            return state;
    }
};
