/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import {ChevronDownIcon} from '../icon';
import styles from './select.css';

type SelectProps = {
    selectValues: Array<Object>,
    disabledPlaceholderText?: string,
    value: Object,
    valueKey?: string,
    labelKey?: string,
    prefix?: string,
    disabled?: boolean,
    styleOptions?: {
        filled?: boolean,
        color?: 'blue',
        fullWidth?: boolean,
        isDarkGreyOnHover?: boolean,
    },
    isInvalid?: boolean,
    autoFocus?: boolean,
    onChange?: Function,
};

export class Select extends React.Component<SelectProps> {
    selectInputRef: ?HTMLElement;

    static defaultProps = {
        value: {},
        labelKey: 'name',
        valueKey: 'id',
    };

    render() {
        let mappedSelectOptions = this.props.selectValues.map((option) => {
            const label =
                this.props.prefix &&
                option[this.props.valueKey] === this.props.value[this.props.valueKey]
                    ? `${this.props.prefix}${option[this.props.labelKey]}`
                    : option[this.props.labelKey];

            return (
                <option
                    disabled={option.disabled}
                    key={option[this.props.valueKey]}
                    label={label}
                    value={option[this.props.valueKey]}
                >
                    {label}
                </option>
            );
        });

        if (this.props.disabledPlaceholderText) {
            const disabledPlaceholderOption = (
                <option
                    key='disabled-placeholder'
                    disabled={true}
                    label={this.props.disabledPlaceholderText}
                    value=''
                >
                    {this.props.disabledPlaceholderText}
                </option>
            );

            mappedSelectOptions = [disabledPlaceholderOption].concat(mappedSelectOptions);
        }

        const isFilled = this.props.styleOptions && this.props.styleOptions.filled;
        const isBlue = this.props.styleOptions && this.props.styleOptions.color === 'blue';
        const isFullWidth = this.props.styleOptions && this.props.styleOptions.fullWidth;
        const isDarkGreyOnHover =
            this.props.styleOptions && this.props.styleOptions.isDarkGreyOnHover;

        const containerStyle = classnames('container', {
            'styles.container-disabled': this.props.disabled,
            'styles.container-full-width': isFullWidth,
            'styles.container-errored': this.props.isInvalid,
            'styles.filled--grey': isFilled,
            'styles.filled--blue': isFilled && isBlue,
            'styles.filled--grey-darken': isFilled && isDarkGreyOnHover,
        });

        const selectValue = this.props.value[this.props.valueKey] || '';

        return (
            <div styleName={containerStyle}>
                <div styleName='styles.select-container'>
                    <select
                        disabled={this.props.disabled}
                        value={selectValue}
                        ref={(node) => {
                            this.selectInputRef = node;
                        }}
                        onChange={this.handleChange}
                        autoFocus={this.props.autoFocus}
                    >
                        {mappedSelectOptions}
                    </select>
                    <ChevronDownIcon className={styles.arrow} />
                </div>
            </div>
        );
    }

    handleChange = (e: {target: {value: string}}) => {
        const newValue = this.props.selectValues.find((val) => {
            return String(val[this.props.valueKey]) === e.target.value;
        });

        if (typeof this.props.onChange === 'function') {
            this.props.onChange(newValue);
        }
        this.blur();
    };

    focus = () => {
        if (this.selectInputRef) this.selectInputRef.focus();
    };

    blur = () => {
        if (this.selectInputRef) this.selectInputRef.blur();
    };
}
