/* @flow */

import * as React from 'react';
import {RemoveButton} from '../button';

import './two-row-item.css';

export type Props = {|
    topRow: React.Node,
    bottomRow: React.Node,
    onRemove?: () => void,
|};

export function TwoRowItem({topRow, bottomRow, onRemove}: Props) {
    return (
        <div styleName='row'>
            <div styleName='item'>
                <div styleName='top-row'>{topRow}</div>
                <div styleName='bottom-row'>{bottomRow}</div>
            </div>
            <div styleName='buttons'>
                {onRemove ? <RemoveButton size={12} onClick={onRemove} /> : null}
            </div>
        </div>
    );
}
