/* @flow */

import * as React from 'react';

import {Button, SaveButton} from '../../../button';

import {getPeopleText} from '../../helpers';

import './footer-add-buttons.css';

type Props = {|
    companyName: string,
    onAddCompany: ?(buttonRef: any) => any,
    onAddPeople: ?() => Promise<*>,
    numSelectedPeople: number,
    unlocksRemaining: ?number,
|};

export function FooterAddButtons(props: Props) {
    const {onAddCompany, onAddPeople} = props;

    const [isSaving, setIsSaving] = React.useState<boolean>(false);

    const addCompanyButtonRef = React.useRef(null);

    const peopleText = getPeopleText(props.numSelectedPeople);

    let addButton;
    if (onAddCompany) {
        addButton = (
            <Button
                variant='save'
                size='big'
                getButtonRef={addCompanyButtonRef}
                isFullWidth={true}
                onClick={() => {
                    onAddCompany(addCompanyButtonRef.current);
                }}
            >
                Add <strong>{props.companyName}</strong>
                {peopleText ? ` & ${peopleText}` : ''}
            </Button>
        );
    } else if (onAddPeople && peopleText) {
        addButton = (
            <div>
                <SaveButton
                    onClick={() => {
                        setIsSaving(true);
                        onAddPeople()
                            .then(() => {
                                setIsSaving(false);
                            })
                            .catch(() => {
                                setIsSaving(false);
                            });
                    }}
                    buttonText={`Add ${peopleText}`}
                    savingText={`Adding ${peopleText}…`}
                    successText={`Added ${peopleText}`}
                    isSaving={isSaving}
                    resetOnComplete={true}
                    isFullWidth={true}
                    justifyCenter={true}
                    disabled={
                        typeof props.unlocksRemaining === 'number' &&
                        props.unlocksRemaining < props.numSelectedPeople
                    }
                />
                <div styleName='credits-info'>
                    Uses {props.numSelectedPeople}{' '}
                    {props.numSelectedPeople === 1 ? 'credit' : 'credits'}
                </div>
            </div>
        );
    }

    return addButton ? <div styleName='footer-add-buttons'>{addButton}</div> : null;
}
