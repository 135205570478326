/* @flow */

import * as React from 'react';
import {useDispatch} from 'react-redux';

import {colors} from 'shells/colors';
import {WarningIcon} from 'shells/icon';
import {openAllLimitsModal} from './limits-actions';

import './title-bar-limits.css';

export function LimitsWarning() {
    const dispatch = useDispatch();

    const openModal = () => {
        dispatch(openAllLimitsModal({}));
    };

    return (
        <div
            styleName='title-bar-warning'
            onClick={openModal}
            data-tip='Your usage is above some plans’ limits'
        >
            <WarningIcon size={15} color={colors.yellow} />
        </div>
    );
}
