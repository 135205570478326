/* @flow */

import * as React from 'react';
import {withRouter, type ContextRouter} from 'react-router-dom';

import {TaskFormWrapper} from './task-form-wrapper';

import './task-view.css';

type Props = ContextRouter;

/*
 * This component respresents the wrapper around the TaskForm that we
 * show when directly vistiing `app.nutshell.com/tasks/{slug}`
 *
 * This is not shown in a modal, and thus renders the TaskForm directly
 */
class TaskViewComponent extends React.PureComponent<Props> {
    render() {
        let taskId = 'error';
        if (this.props.match.params && this.props.match.params.id) {
            taskId = this.props.match.params.id;
        }

        const isEditing = this.props.match && this.props.match.params.action === 'edit';

        return (
            <div styleName='container'>
                <TaskFormWrapper
                    form={`${taskId}-form`}
                    isReadOnly={!isEditing}
                    taskId={taskId}
                    onCancelClick={this.handleCancelClick}
                    onSaveCallback={this.handleCancelClick}
                />
            </div>
        );
    }

    handleCancelClick = () => {
        this.props.history.replace(this.props.location.pathname.replace(/\/edit/, ''));
    };

    handleEditClick = () => {
        const editLink = this.props.location.pathname.endsWith('/')
            ? `${this.props.location.pathname}edit`
            : `${this.props.location.pathname}/edit`;
        this.props.history.replace(editLink);
    };
}

export const TaskView = withRouter<{||}>(TaskViewComponent);
