/* @flow */

import * as React from 'react';
import {
    mergeStyles,
    // $FlowIgnore this exists
    createFilter,
    type SingleValueProps,
    type MultiValueProps,
    type StylesConfig,
    type ActionMeta,
} from 'react-select';
// $FlowIgnore It exists
import CreatableSelect from 'react-select/lib/Creatable';
// $FlowIgnore It exists
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import {selectPickerGenericStyles} from '../select-picker-generic/select-picker-generic-styles';
import {colors} from '../colors';
import {ValueComponent, type TokenStyle} from './value-component';

import styles from './select-inline.css';

type Props = {|
    options?: Object[],
    value: void | null | Object | Object[],
    onChange: (newValue: any, actionMeta: ActionMeta) => void,
    /** If multi prop is false, and the 'clearable' button is clicked, this fires */
    onClearValue?: () => void,
    labelKey: string,
    valueKey: string,
    separatorKeys: string[],
    autoBlur?: boolean,
    autoFocus?: boolean,
    onFocus?: () => void,
    onBlur?: () => void,
    clearable?: boolean,
    tabSelectsValue?: boolean,
    multi?: boolean,
    placeholder?: string,
    inlineLabel?: string,
    inlineIcon?: React.Node,
    disabled?: boolean,
    tokenizedStyle: TokenStyle,
    errorMessage?: string,
    menuPortalTarget?: HTMLElement,
    menuPortalStyles?: $PropertyType<StylesConfig, 'menuPortal'>,
    styles?: StylesConfig,
    transparentBackground?: boolean,
    onCreate?: (string) => void,
    loadOptions?: (string) => Promise<*>,
    noOptionsMessage?: ({inputValue: string}) => string | null,
    isValidNewOption?: (inputValue: string) => boolean,
    formatCreateLabel: (inputValue: string) => React.Node,
    /** This filter will be applied in addition to the default react-select search filter */
    filterOption: (option: Object, input: string) => boolean,
    defaultOptions?: true | Object[],
    components: Object,
    rightContents?: React.Node,
    hasDropdownIndicator?: boolean,
    menuPlacement?: 'auto' | 'bottom' | 'top',
|};

type State = {
    inputValue: string,
};

/**
 * Use this select component when you want to show selected values as tokens.
 * Can be single or multiple values.
 *
 * If you provide options which do not have an `id` or `name` key,
 * use the `valueKey` and `labelKey` respectively.
 */
export class SelectInlineCreatable extends React.Component<Props, State> {
    focusedOptionId: string = '';

    static defaultProps = {
        placeholder: 'Type to search…',
        valueKey: 'id',
        labelKey: 'name',
        autoFocus: false,
        clearable: false,
        multi: false,
        tabSelectsValue: false,
        transparentBackground: false,
        components: {},
        formatCreateLabel: (input: string) => `Create "${input}"`,
        /** Extra keys that will create new options.  Enter and Tab work by default in react-select Creatable */
        separatorKeys: [],
        filterOption: () => true,
    };

    constructor() {
        super();
        this.state = {
            inputValue: '',
        };
    }

    render() {
        const styleName =
            this.props.inlineLabel || this.props.inlineIcon ? 'container--labeled' : 'container';
        const {labelKey, valueKey} = this.props;

        let Component = CreatableSelect;
        if (this.props.loadOptions) {
            Component = AsyncCreatableSelect;
        }

        const componentStyles = {
            input: (base) => ({
                ...base,
                padding: 0,
                marginBottom: 6,
            }),
            control: (base, state) => ({
                ...base,
                '&:hover': {},
                backgroundColor: this.props.transparentBackground ? undefined : '#fff',
                boxShadow: 'none',
                border: 'none',
                paddingTop: 5,
                marginLeft: this.getMarginLeft(),
                minHeight: 26,
                cursor: state.isDisabled ? 'default' : 'pointer',
            }),
            indicatorsContainer: (base) => ({
                ...base,
                marginTop: -5,
            }),
            indicatorSeparator: (base, state) => ({
                ...base,
                // hide separator when empty
                display: state.hasValue ? 'unset' : 'none',
            }),
            clearIndicator: (base) => ({
                ...base,
                padding: '4px 8px',
            }),
            placeholder: (base) => ({
                ...base,
                // offsets the paddingTop needed for the value
                marginTop: -3,
                color: this.props.errorMessage ? colors.rose : colors.greyLt,
            }),
            option: (base, state) => {
                if (state.isFocused) {
                    this.focusedOptionId = state.data[this.props.valueKey];
                }

                // $FlowIgnore option is there
                return selectPickerGenericStyles.option(base, state);
            },
            menuPortal: (base, state) => {
                if (this.props.menuPortalStyles) {
                    return this.props.menuPortalStyles(base, state);
                }

                return base;
            },
        };

        // Add in styles from props
        const mergedStyles = this.props.styles
            ? mergeStyles(componentStyles, this.props.styles)
            : componentStyles;

        const components = {
            SingleValue: this.getValueComponent,
            MultiValue: this.getMultiValueComponent,
            ...this.props.components,
        };

        if (!this.props.hasDropdownIndicator) {
            components.DropdownIndicator = null;
        }

        if (!this.props.clearable) {
            components.IndicatorSeparator = null;
        }

        return (
            <div className={styles[styleName]}>
                {this.props.inlineLabel ? (
                    <label htmlFor={this.props.inlineLabel}>{this.props.inlineLabel}</label>
                ) : (
                    undefined
                )}
                {this.props.inlineIcon}
                <Component
                    inputId={this.props.inlineLabel}
                    isDisabled={this.props.disabled}
                    options={this.props.options}
                    tabSelectsValue={this.props.tabSelectsValue}
                    defaultOptions={this.props.defaultOptions}
                    isMulti={this.props.multi}
                    value={this.props.value}
                    inputValue={this.state.inputValue}
                    placeholder={this.props.errorMessage || this.props.placeholder}
                    isClearable={this.props.clearable}
                    autoFocus={this.props.autoFocus}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    filterOption={(option, input) => {
                        return (
                            this.props.filterOption(option, input) &&
                            customFilterOption(option, input)
                        );
                    }}
                    blurInputOnSelect={this.props.autoBlur}
                    valueComponent={this.getValueComponent}
                    menuPortalTarget={this.props.menuPortalTarget}
                    onCreateOption={this.props.onCreate}
                    noOptionsMessage={(input) => {
                        // If there are no options found, need to clear out the "focused" option.
                        this.focusedOptionId = '';
                        if (this.props.noOptionsMessage) {
                            return this.props.noOptionsMessage(input);
                        }
                    }}
                    formatCreateLabel={this.props.formatCreateLabel}
                    createOptionPosition='first'
                    loadOptions={this.props.loadOptions}
                    styles={mergedStyles}
                    isValidNewOption={this.isValidNewOption}
                    components={components}
                    onChange={this.handleChange}
                    getOptionLabel={(option) => option[labelKey]}
                    getOptionValue={(option) => option[valueKey]}
                    getNewOptionData={this.getNewOptionData}
                    onKeyDown={this.handleKeyDown}
                    onInputChange={this.handleInputChange}
                    onMenuClose={() => {
                        this.focusedOptionId = '';
                    }}
                    menuPlacement={this.props.menuPlacement}
                />
                {this.props.rightContents ? (
                    <div className={styles['right-contents']}>{this.props.rightContents}</div>
                ) : null}
            </div>
        );
    }

    // Allow creating options that are non-zero length and aren't already selected or existing items
    isValidNewOption = (
        inputValue: string,
        selectValues: Object[],
        selectOptions: Object[]
    ): boolean => {
        const existingNames = selectOptions
            .concat(selectValues)
            .filter(Boolean)
            .map((option) => option[this.props.labelKey])
            .map((name) => name.toLowerCase().trim());

        const isNonZeroAndNew =
            inputValue.trim().length > 0 &&
            !existingNames.includes(inputValue.toLowerCase().trim());

        if (this.props.isValidNewOption) {
            return isNonZeroAndNew && this.props.isValidNewOption(inputValue);
        }

        return isNonZeroAndNew;
    };

    getMultiValueComponent = (propsFromReactSelect: MultiValueProps) => {
        return (
            <ValueComponent
                data={propsFromReactSelect.data}
                labelKey={this.props.labelKey}
                valueKey={this.props.valueKey}
                tokenizedStyle={this.props.tokenizedStyle}
                onRemove={propsFromReactSelect.removeProps.onClick}
            />
        );
    };

    /*
     * This is pretty hacky, but the intention is to mimic what happens in react-select when the
     * user presses Enter or Tab after typing some characters.  Meaning, whatever is focused in the
     * dropdown should be chosen.
     */
    getFocusedOption = (inputValue: string, options: Object[]): ?Object => {
        return options.find((opt) => opt[this.props.valueKey] === this.focusedOptionId);
    };

    // We'll absolutely position our label to avoid any annoying css
    // intricacies built into the select component. If it's an icon,
    // we need less room
    getMarginLeft = () => {
        if (this.props.inlineLabel) return 64;
        else if (this.props.inlineIcon) return 24;
        else return 0;
    };

    // Disabling the next line, eslint bug where it doesn't pick up onRemove
    getValueComponent = (propsFromReactSelect: SingleValueProps) => {
        return (
            <ValueComponent
                data={propsFromReactSelect.data}
                labelKey={this.props.labelKey}
                valueKey={this.props.valueKey}
                tokenizedStyle={this.props.tokenizedStyle}
            />
        );
    };

    /*
     * Take a string (probably input by the user) and turn it into a react-select option object.
     */
    getNewOptionData = (inputValue: string, optionLabel?: string) => {
        // The `optionLabel` comes from `formatCreateLabel`, but we also sometimes call this function
        // manually from a typed separatorKey, so fall back to the value for label in that case.
        return {
            [this.props.labelKey]: optionLabel || inputValue,
            [this.props.valueKey]: inputValue,
            __isNew__: true,
        };
    };

    handleInputChange = (inputValue: string) => {
        // Never set a separator into the actual input value.
        if (this.props.separatorKeys.includes(inputValue)) return;

        this.setState({inputValue});
    };

    /*
     * Called before handleInputChange to determine whether a new option should
     * be created.  React-select will always create a new option if
     * enter or tab is pressed, and we'll also look for the `separatorKeys` prop here.
     */
    handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
        const {inputValue} = this.state;
        const {separatorKeys} = this.props;

        // Don't add an option if a separator was typed as the first thing.
        if (!inputValue) return;

        if (separatorKeys.includes(event.key)) {
            this.handleAddOptionFromInput(inputValue);
        }
    };

    handleAddOptionFromInput = (newOptionLabel: string) => {
        const {value, loadOptions, options, isValidNewOption} = this.props;

        if (!this.props.multi || !Array.isArray(value)) {
            if (!isValidNewOption || isValidNewOption(newOptionLabel)) {
                this.manuallyAddNewSplitOption(newOptionLabel);
            }
        } else if (loadOptions) {
            // First we need to get the options async
            loadOptions(newOptionLabel).then((asyncOptions) => {
                // If there is a focused option in the menu, select it
                const existingOption = this.getFocusedOption(newOptionLabel, asyncOptions);
                if (existingOption) {
                    this.manuallyAddExistingOption(existingOption);
                }

                // Otherwise, if the new option is valid, save it off
                else if (this.isValidNewOption(newOptionLabel, value, asyncOptions)) {
                    this.manuallyAddNewSplitOption(newOptionLabel);
                }
            });
        } else if (options) {
            // If there is a focused option in the menu, select it
            const existingOption = this.getFocusedOption(newOptionLabel, options);
            if (existingOption) {
                this.manuallyAddExistingOption(existingOption);
            }

            // Otherwise, if the new option is valid, save it off
            else if (this.isValidNewOption(newOptionLabel, value, options)) {
                this.manuallyAddNewSplitOption(newOptionLabel);
            }
        }

        // Reset the inputValue so another value can be typed in.
        this.setState({inputValue: ''});
    };

    /*
     * This is called after one of the specified separator keys is pressed and we're manually
     * selecting an existing option that was focused in the dropdown menu.
     */
    manuallyAddExistingOption = (newOption: Object) => {
        const {value, multi} = this.props;
        if (!multi || !Array.isArray(value)) {
            // This shouldn't really happen, because there's no real reason to have separators
            // to create new options if you're only allowing one value.
            this.handleChange(newOption, {
                action: 'select-option',
            });
        } else {
            // Add this new option to the end of existing ones.
            this.handleChange([...value, newOption], {
                action: 'select-option',
            });
        }
    };

    /*
     * This is called from our keydown handler, to add new options that the user has typed in before
     * pressing one of the specified separator keys.  Enter and Tab are not handled here, they're dealt
     * with inside of react-select itself.
     */
    manuallyAddNewSplitOption = (newOptionLabel: string) => {
        const {onCreate, value, multi} = this.props;

        if (!multi || !Array.isArray(value)) {
            // This shouldn't really happen, because there's no real reason to have separators
            // to create new options if you're only allowing one value.
            this.handleChange(this.getNewOptionData(newOptionLabel), {
                action: 'select-option',
            });
        } else if (onCreate) {
            // React-select doesn't call onChange if there's an onCreate, so we'll do the same
            onCreate(newOptionLabel);
        } else {
            // Add this new option to the end of existing ones.
            this.handleChange([...value, this.getNewOptionData(newOptionLabel)], {
                action: 'select-option',
            });
        }
    };

    handleChange = (value: ?Object | Object[], actionMeta: ActionMeta) => {
        if (!value) {
            if (typeof this.props.onClearValue === 'function') {
                this.props.onClearValue();
            }
        } else {
            this.props.onChange(value, actionMeta);
        }
    };
}

/*
    `ignoreAccents: true` causes the stripDiacritics function to be called,
    which is extremely slow, and crashing long lists (1000+)

    https://github.com/JedWatson/react-select/blob/0fcc42d23a39be18d5443ef8365e75dce70a1e72/src/filters.js#L34-L37
    https://nutshell.atlassian.net/browse/NUT-11207
*/
const customFilterOption = (option: Object, input: string) => {
    // If our option is considered a "New" one, we'll
    // short circuit our option filtering and always
    // show it. This is necessary due to a bug in react-select
    // where a custom filter function breaks "+ Create new"
    // option filtering.
    if (option && option.data && option.data.__isNew__) {
        return true;
    }

    // Now use react-select's build in `createFilter` function,
    // but disable ignoreAccents, because it's really slow.
    const createFilterFunction = createFilter({
        ignoreCase: true,
        // `ignoreAccents: true` is very slow!!
        ignoreAccents: false,
        trim: true,
        matchFrom: 'any',
    });

    return createFilterFunction(option, input);
};
