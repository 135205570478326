/* @flow */

import * as React from 'react';
import './description-body.css';

type Props = {
    children?: React.Node,
    as: string,
};

DescriptionBody.defaultProps = {
    as: 'span',
};

export function DescriptionBody({children, as: Tag}: Props) {
    return <Tag styleName='description-body'>{children}</Tag>;
}
