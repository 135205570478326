/* @flow */

import * as React from 'react';
import {ErrorMessage} from 'shells/form';
import {portal} from '../portal';
import {AlertBox} from '../alert-box';
import {Button, SaveButton} from '../button';
import {Header, Subheader} from '../typography';

import './confirmation-dialog.css';

type Props = {
    title?: string,
    message: string | React.Element<*>,
    confirmText?: string | React.Node,
    cancelText?: string,
    onCancel: () => void,
    onConfirm?: () => void,
    isConfirmDestructive?: boolean,
    isSaving?: boolean,
    customSaveText?: {
        initial: string,
        saving: string,
        complete: string,
    },
    errorMessage?: string,
    autoFocusCancelButton?: boolean,
};

function ConfirmationDialogComponent(props: Props) {
    // Destructure and assigning a default works better (in general, but also for Flow) than using defaultProps
    const {
        confirmText = 'Confirm',
        cancelText = 'Cancel',
        isConfirmDestructive = true,
        autoFocusCancelButton = true,
    } = props;

    const cancelButtonRef = React.useRef(undefined);

    React.useLayoutEffect(() => {
        if (cancelButtonRef && cancelButtonRef.current) {
            cancelButtonRef.current.focus();
        }
    }, []);

    const customSaveText = props.customSaveText ? props.customSaveText : {};

    let confirmButton;
    if (props.onConfirm && typeof confirmText === 'string') {
        confirmButton = (
            <SaveButton
                type='button'
                variant={isConfirmDestructive ? 'text-danger' : 'text-primary'}
                onClick={props.onConfirm}
                isSaving={Boolean(props.isSaving)}
                buttonText={customSaveText.initial || confirmText}
                savingText={customSaveText.saving}
                successText={customSaveText.complete}
                disabled={props.errorMessage}
            />
        );
    } else if (confirmText && typeof confirmText !== 'string') {
        // Confirm text is a custom component
        confirmButton = confirmText;
    }

    return (
        <AlertBox onClose={props.onCancel}>
            <div styleName='confirm-box'>
                {props.errorMessage ? (
                    <div styleName='error-message'>
                        <ErrorMessage>{props.errorMessage}</ErrorMessage>
                    </div>
                ) : (
                    undefined
                )}
                <div className='mb-16'>
                    {props.title && <Header>{props.title}</Header>}
                    <Subheader>{props.message}</Subheader>
                </div>
                <div styleName='confirm-buttons'>
                    {props.onCancel ? (
                        <Button
                            variant={isConfirmDestructive ? 'text-primary' : 'text-secondary'}
                            onClick={props.onCancel}
                            getButtonRef={autoFocusCancelButton ? cancelButtonRef : undefined}
                        >
                            {cancelText}
                        </Button>
                    ) : (
                        undefined
                    )}
                    {confirmButton}
                </div>
            </div>
        </AlertBox>
    );
}

export const ConfirmationDialog = portal(ConfirmationDialogComponent);
