/* @flow */

import * as React from 'react';

type Props = {
    width?: number,
};

export function PushingWebsite(props: Props) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 282 162'
            width={props.width}
        >
            <path
                fill='#F7F7F7'
                d='m206.377 150.607-5.446-23.966h-32.584l-5.446 23.966c-6.819.946-17.944 5.526-17.944 11.032h79.363c0-5.506-11.124-10.084-17.943-11.032Z'
            />
            <path
                fill='#fff'
                d='m206.398 150.858-4.772-21.503h-27.075a2.943 2.943 0 0 0-2.877 2.319l-4.57 20.994c-6.351.873-16.716 3.89-16.716 8.969h73.935c0-5.079-11.572-9.905-17.923-10.779h-.002Z'
            />
            <path
                fill='#0C0F33'
                d='M166.969 130.097c-1.561 6.87-3.122 13.743-4.686 20.613l.729-.351a36.701 36.701 0 0 0-12.301 4.117c-2.649 1.461-6.379 3.885-6.379 7.328 0 .078.188.101.222.101h79.363c.241 0 1.026-.083 1.026-.434 0-3.484-3.918-5.935-6.601-7.354-1.97-1.044-4.058-1.876-6.185-2.541a37.063 37.063 0 0 0-2.893-.78c-.351-.08-2.117-.21-2.269-.38-.261-.3-.292-1.321-.375-1.696l-.814-3.683c-.709-3.205-1.417-6.41-2.122-9.618l-1.319-5.974c-.064-.292-1.318.016-1.248.334 1.546 6.999 3.089 13.999 4.634 20.999.019.082.168.093.223.1a34.604 34.604 0 0 1 11.639 3.919c2.538 1.403 6.082 3.722 6.082 7.012l1.026-.434h-78.74c-.15 0-.341.031-.489 0-.031-.008-.077.013-.106 0 .277.119.176-.082.243-.512.378-2.401 2.601-4.174 4.511-5.407 2.603-1.68 5.554-2.864 8.527-3.707a31.683 31.683 0 0 1 4.12-.889c.171-.026.68-.137.729-.351 1.561-6.871 3.122-13.744 4.686-20.614.067-.302-1.16-.116-1.233.209v-.007Z'
            />
            <path
                fill='#F7F7F7'
                d='M197.322 150.53h-26.16c-1.285 0-2.569-.018-3.852.005-.816.016-2.026.246-2.465 1.039-.411.742.351 1.182 1.034 1.169 8.744-.163 17.504-.005 26.248-.005h3.763c.815 0 2.032-.256 2.466-1.04.416-.749-.354-1.17-1.034-1.17v.002Z'
            />
            <path
                fill='#E9E9F6'
                d='M231.897 10.283H65.007A4.108 4.108 0 0 0 60.9 14.39v90.592a4.107 4.107 0 0 0 4.107 4.107h164.305a6.694 6.694 0 0 0 6.694-6.694V14.39a4.107 4.107 0 0 0-4.107-4.107h-.002Z'
            />
            <path
                fill='#fff'
                d='M236.004 20.347H60.89v-6.141a3.92 3.92 0 0 1 3.918-3.919h167.277a3.92 3.92 0 0 1 3.919 3.919v6.141Z'
            />
            <path
                fill='#0C0F33'
                d='M85.652 20H63.501c-.46 0-1.33.693-.492.693h22.152c.46 0 1.329-.693.491-.693ZM233.721 19.999H116.24c-5.402 0-10.848-.204-16.245 0-.078.003-.158 0-.235 0-.46 0-1.329.693-.491.693H216.75c5.402 0 10.848.204 16.245 0 .078-.003.158 0 .235 0 .46 0 1.329-.693.491-.693Z'
            />
            <path
                fill='#fff'
                d='M69.081 17.647a2.332 2.332 0 1 0 0-4.663 2.332 2.332 0 0 0 0 4.663ZM76.078 17.647a2.331 2.331 0 1 0 0-4.663 2.331 2.331 0 0 0 0 4.663ZM83.075 17.647a2.332 2.332 0 1 0 0-4.663 2.332 2.332 0 0 0 0 4.663Z'
            />
            <path
                fill='#fff'
                d='M69.452 17.62a2.331 2.331 0 1 0-.745-4.604 2.331 2.331 0 0 0 .745 4.603Z'
            />
            <path
                fill='#0C0F33'
                d='M72.046 15.272c-.086-1.576-1.29-2.81-2.919-2.773-1.553.036-2.993 1.243-3.016 2.853-.052 3.723 5.74 3.492 5.932-.082.036-.675-1.236-.515-1.27.082-.05.952-.666 1.9-1.741 1.802-.93-.083-1.647-.972-1.654-1.884-.008-.9.625-1.776 1.568-1.8 1.037-.025 1.776.908 1.828 1.882.036.678 1.3.507 1.269-.082l.003.002Z'
            />
            <path
                fill='#fff'
                d='M76.45 17.617a2.332 2.332 0 1 0-.745-4.603 2.332 2.332 0 0 0 .744 4.604Z'
            />
            <path
                fill='#0C0F33'
                d='M79.035 15.146c-.189-3.52-5.93-2.955-5.914.333.018 3.56 5.738 2.91 5.914-.333.01-.21-1.228-.052-1.249.333-.062 1.166-1.03 2.36-2.297 1.628a2.278 2.278 0 0 1-1.117-1.964c.002-1.093.943-2.246 2.117-1.711.827.377 1.25 1.163 1.3 2.047.013.246 1.27.031 1.248-.333h-.002Z'
            />
            <path
                fill='#fff'
                d='M83.459 17.614a2.331 2.331 0 1 0-.768-4.6 2.331 2.331 0 0 0 .768 4.6Z'
            />
            <path
                fill='#0C0F33'
                d='M86.032 15.145c-.191-3.565-5.909-3.112-5.914.29-.005 3.605 5.733 3.166 5.914-.197.028-.52-1.225-.277-1.248.152-.052.97-.683 2.102-1.828 1.859-.94-.202-1.574-1.125-1.574-2.055 0-1.068.837-2.12 1.988-1.755.904.287 1.364 1.142 1.413 2.042.024.41 1.275.155 1.249-.334v-.002ZM90.724 109.45H227.34c.636 0 1.271.016 1.905-.005 4.089-.124 7.353-3.259 7.382-7.379.028-4.195 0-8.393 0-12.588V18.396c0-1.305.015-2.61 0-3.916-.031-2.683-2.014-4.523-4.661-4.56-2.83-.04-5.665 0-8.498 0H91.207c-.478 0-1.321.725-.481.725h137.467c1.041 0 2.083-.016 3.125 0 2.354.036 4.011 1.69 4.066 4.06.093 4.102 0 8.22 0 12.322v70.696c0 1.466.007 2.929 0 4.394-.016 3.433-2.239 6.532-5.894 6.612-2.585.057-5.177 0-7.762 0H91.207c-.478 0-1.321.724-.481.724l-.002-.003Z'
            />
            <path
                fill='#0C0F33'
                d='M85.647 9.922H70.384c-1.766 0-3.55-.075-5.315.008-2.634.124-4.758 2.036-4.794 4.725-.106 7.583 0 15.177 0 22.764v66.488c0 .747-.057 1.554.132 2.277.747 2.875 3.411 3.265 5.895 3.265h18.885c.478 0 1.32-.724.48-.724H71.085c-1.928 0-3.87.067-5.797 0-2.394-.083-3.748-2.039-3.761-4.262-.044-7.695 0-15.39 0-23.082V15.955c0-.54-.021-1.088 0-1.629.07-1.923 1.287-3.577 3.308-3.68.639-.034 1.285 0 1.923 0h18.41c.477 0 1.32-.724.48-.724Z'
            />
            <path
                fill='#fff'
                d='M274.708.488H94.572A6.461 6.461 0 0 0 88.11 6.95v113.227a6.461 6.461 0 0 0 6.462 6.462h180.136a6.461 6.461 0 0 0 6.462-6.462V6.95a6.46 6.46 0 0 0-6.462-6.462ZM272.193 102.4a6.693 6.693 0 0 1-6.694 6.694H101.194a4.107 4.107 0 0 1-4.107-4.107V14.392a4.107 4.107 0 0 1 4.107-4.107h166.892a4.107 4.107 0 0 1 4.107 4.107v88.007Z'
            />
            <path
                fill='#fff'
                d='M177.854 44.623h-34.755a1.81 1.81 0 0 0-1.809 1.81v55.21c0 1 .81 1.81 1.809 1.81h34.755a1.81 1.81 0 0 0 1.809-1.81v-55.21c0-1-.81-1.81-1.809-1.81ZM221.612 24.459H74.772c-.999 0-1.809.81-1.809 1.81v6.495c0 1 .81 1.81 1.81 1.81h146.839a1.81 1.81 0 0 0 1.809-1.81v-6.496c0-.999-.81-1.809-1.809-1.809ZM221.612 78.295h-29.945a1.81 1.81 0 0 0-1.809 1.81V101.4c0 1 .81 1.81 1.809 1.81h29.945a1.81 1.81 0 0 0 1.809-1.81V80.104c0-.999-.81-1.81-1.809-1.81ZM221.612 44.623h-29.945a1.81 1.81 0 0 0-1.809 1.81v21.295c0 1 .81 1.81 1.809 1.81h29.945a1.81 1.81 0 0 0 1.809-1.81V46.432c0-.999-.81-1.809-1.809-1.809ZM129.733 44.76h-54.96c-1 0-1.81.81-1.81 1.81v54.83c0 .999.81 1.81 1.81 1.81h54.96a1.81 1.81 0 0 0 1.809-1.81V46.57c0-1-.81-1.81-1.809-1.81Z'
            />
            <path
                fill='#EBE8E8'
                d='M149.264 74.313h21.707c.369 0 1.354-.553.527-.553h-21.707c-.37 0-1.354.553-.527.553ZM168.43 62.508h-15.299c-.463 0-1.329.695-.489.695h15.3c.462 0 1.328-.695.488-.695ZM168.267 57.336h-15.116c-.369 0-1.354.548-.527.548h15.116c.369 0 1.354-.548.527-.548ZM168.438 51.693h-15.359c-.424 0-1.339.636-.504.636h15.359c.424 0 1.339-.636.504-.636ZM168.378 93.936h-15.299c-.463 0-1.329.695-.489.695h15.3c.462 0 1.328-.695.488-.695ZM168.215 88.766h-15.116c-.369 0-1.354.548-.527.548h15.116c.37 0 1.354-.548.527-.548ZM168.386 83.121h-15.359c-.424 0-1.341.636-.504.636h15.359c.424 0 1.339-.636.504-.636ZM216.199 95.63h-18.856c-.478 0-1.321.725-.48.725h18.856c.478 0 1.32-.724.48-.724ZM215.959 90.332H197.33c-.548 0-1.295.827-.452.827h18.629c.548 0 1.295-.827.452-.827ZM216.212 84.83h-18.928c-.421 0-1.342.633-.507.633h18.929c.421 0 1.341-.633.506-.633ZM216.202 61.973h-18.856c-.463 0-1.329.695-.489.695h18.856c.463 0 1.329-.695.489-.695ZM215.995 56.8h-18.628c-.37 0-1.355.549-.528.549h18.629c.369 0 1.354-.548.527-.548ZM216.21 51.158h-18.929c-.424 0-1.341.636-.504.636h18.929c.424 0 1.341-.636.504-.636ZM113.785 73.633H99.481c-.466 0-1.326.7-.489.7h14.304c.466 0 1.326-.7.489-.7Z'
            />
            <path
                fill='#EBE8E8'
                d='M102.885 85.22V62.644c0-.63-1.261-.431-1.261.106v22.576c0 .63 1.261.432 1.261-.106ZM86.089 95.791c-.887 0-1.835.09-2.683-.214-1.603-.576-2.536-2.094-2.598-3.748-.018-.468 0-.94 0-1.411V58.762c0-.923-.013-1.846 0-2.769.01-.728.132-1.437.491-2.083.882-1.597 2.368-1.737 3.981-1.737.271 0 1.414-.517.806-.517-2.065 0-4.203.09-5.606 1.859-.783.984-.92 2.099-.92 3.29v33.36c0 .664-.026 1.33 0 1.995.062 1.63.961 3.091 2.474 3.763 1.033.458 2.145.38 3.246.38.271 0 1.414-.517.806-.517l.003.005Z'
            />
            <path
                fill='#EBE8E8'
                d='M99.111 52.176h18.114c.856 0 1.719-.036 2.572 0 2.233.093 3.818 1.771 3.9 3.984.063 1.706 0 3.425 0 5.133v29.953c0 .75.034 1.478-.219 2.204-.618 1.768-2.109 2.342-3.797 2.342H99.923c-.272 0-1.414.517-.807.517h18.786c2.846 0 6.411-.258 6.987-3.787.279-1.7.06-3.585.06-5.296V56.403c0-2.898-1.849-4.72-4.756-4.74-6.757-.045-13.514 0-20.268 0-.271 0-1.414.516-.806.516l-.008-.003Z'
            />
            <path
                fill='#fff'
                d='M95.787 39.739h100.419c1.344 0 2.745.113 4.086 0 .062-.006.127 0 .189 0 .706 0 .964-.897.116-.897H100.178c-1.344 0-2.745-.114-4.086 0-.062.005-.127 0-.189 0-.706 0-.964.897-.116.897Z'
            />
            <path
                fill='#0C0F33'
                d='M268.326 9.922H103.854c-1.326 0-2.835-.15-4.107.272-2.042.677-3.262 2.45-3.283 4.567-.008.822 0 1.647 0 2.471v65.33c0 7.36-.033 14.718 0 22.077.013 2.978 2.063 4.8 4.968 4.813 1.685.008 3.371 0 5.056 0h153.828c1.546 0 3.089.008 4.635 0 4.197-.023 7.826-2.916 7.865-7.299.062-6.956 0-13.914 0-20.87V16.545c0-.69.008-1.383 0-2.073-.031-2.6-1.91-4.438-4.49-4.547-.485-.02-1.323.688-.48.724 4.146.176 3.722 4.296 3.722 7.175v78.58c0 2 .036 4.007 0 6.007-.062 3.304-2.394 6.235-5.842 6.32-.305.008-.61 0-.915 0H115.837c-4.676 0-9.354.065-14.03 0-1.585-.021-3.053-.713-3.74-2.236-.388-.858-.357-1.708-.357-2.611V16.617c0-2.476-.158-5.805 3.316-5.968 3.981-.186 8.016 0 12.001 0h154.814c.478 0 1.32-.724.48-.724l.005-.003Z'
            />
            <path
                fill='#0C0F33'
                d='M281.798 120.119V8.829c0-.63.013-1.264 0-1.897-.077-3.932-3.143-6.809-7.03-6.876-4.381-.075-8.77 0-13.152 0H108.654c-4.663 0-9.341-.108-14.002 0-3.851.088-7.077 3.037-7.173 6.95-.013.505 0 1.009 0 1.513v91.028c0 6.878-.142 13.774 0 20.652.08 3.929 3.149 6.798 7.031 6.866.323.005.649 0 .972 0h178.061c.361 0 .723.007 1.085 0 3.857-.088 7.067-3.038 7.173-6.948.015-.621-1.246-.416-1.261.111-.091 3.363-2.549 5.947-5.945 5.976-.548.005-1.099 0-1.647 0H95.523c-.328 0-.656.01-.984-.005-3.42-.158-5.754-3.037-5.798-6.333-.031-2.445 0-4.896 0-7.341V8.157c0-.652-.034-1.321.039-1.967.336-3.097 2.869-5.247 5.927-5.273 1.659-.016 3.318 0 4.98 0h171.586c1.29 0 2.634-.109 3.919.039 3.249.37 5.314 3.215 5.35 6.343.011.845 0 1.69 0 2.533V120.23c0 .623 1.262.414 1.262-.111h-.006Z'
            />
            <path
                fill='#065289'
                d='M52.174 160.663c-3.055-.766-3.255-7.843-3.092-10.96.342-6.472 2.396-13.598-1.385-18.409-4.41-5.611-10.021-9.005-13.899-15.498-1.25-2.099-2.406-4.467-2.421-7.199-.044-7.263 10.918-10.086 15.022-8.243 3.44 1.546 6.075 4.837 8.3 8.352 3.239 5.113 7.426 10.132 8.034 17.07.558 6.397-1.481 13.242-3.415 19.365-.902 2.856-4.17 16.269-7.144 15.524v-.002Z'
            />
            <path
                fill='#065289'
                d='M15.227 120.102c0 .044-.003.088-.005.132-.037.625-.111 1.266-.158 1.897-.1 1.354-.225 2.709-.377 4.058-.3 2.662-.711 5.312-1.254 7.935-.78 3.769-1.776 6.99-3.624 10.29-2.101 3.751-4.397 7.383-6.524 11.115-.465.814-.925 1.667-1.013 2.6-.088.933.302 1.975 1.148 2.378.977.466 2.12-.051 3.065-.574 4.11-2.274 8.044-4.802 11.557-7.909 1.3-1.15 2.59-2.313 3.861-3.497a135.473 135.473 0 0 0 3.226-3.099 87.817 87.817 0 0 0 2.665-2.771c4.805-5.242 8.452-10.797 11.03-17.603.139-.369.346-.71.522-1.065.093-.186.17-.364.289-.532.522-.732.057-1.479-.111-2.231-.254-1.14-.354-2.241-.925-3.29-1.613-2.962-6.01-2.528-8.9-2.575-3.968-.067-7.928.3-11.854.851-.579.08-1.184.176-1.66.517-.514.369-.806.987-.917 1.61-.103.589-.023 1.176-.039 1.763h-.002Z'
            />
            <path
                fill='#0C0F33'
                d='M14.568 120.158c-.427 7.266-1.15 15.114-4.157 21.834-1.437 3.208-3.409 6.198-5.208 9.212-.918 1.538-1.866 3.068-2.717 4.645-.628 1.163-1.204 2.588-.607 3.883 1.28 2.783 4.686.672 6.408-.334a76.1 76.1 0 0 0 7.578-5.076c5.214-3.95 9.99-8.51 14.124-13.581 2.194-2.691 4.213-5.531 5.986-8.519a58.04 58.04 0 0 0 2.551-4.785c.597-1.264.977-2.853 1.737-3.991-.163.114-.326.225-.486.339.07-.021.14-.041.212-.06-.196-.015-.393-.033-.592-.049.858.383 1.717 1.551 2.396 2.205.763.732 1.52 1.466 2.26 2.218 1.406 1.434 2.946 2.957 3.915 4.735 1.887 3.456 1.406 7.713 1.003 11.466-.333 3.107-.69 6.191-.483 9.319.147 2.22.4 5.515 2.37 6.973 3.552 2.634 5.834-5.04 6.545-7.1.775-2.249 1.432-4.534 2.083-6.821.905-3.182 1.975-6.315 2.74-9.536 1.09-4.588 1.773-9.494.721-14.157-.959-4.259-3.321-7.974-5.712-11.559-.287-.429-1.486.024-1.148.53 1.882 2.823 3.748 5.694 4.893 8.907 1.27 3.554 1.424 7.284.975 11.009-.683 5.666-2.616 11.071-4.19 16.53-.628 2.176-1.282 4.347-2.078 6.469-.62 1.652-1.298 3.741-2.585 5.01-1.404 1.385-2.58-1.952-2.833-2.95-.587-2.282-.67-4.719-.566-7.064.256-5.741 2.414-12.585-.778-17.881-1.817-3.014-4.777-5.454-7.29-7.871-.6-.576-1.292-1.574-2.235-1.042-.261.148-.352.329-.491.582-.435.791-.69 1.737-1.057 2.567-5.485 12.35-15.421 22.821-26.564 30.288-1.642 1.101-3.324 2.171-5.07 3.104-1.162.623-2.832 1.073-3.264-.677-.294-1.194.362-2.342.92-3.342 2.097-3.75 4.446-7.354 6.524-11.114 4.048-7.326 4.943-16.212 5.426-24.424.036-.605-1.228-.403-1.259.114l.003-.006Z'
            />
            <path
                fill='#835A55'
                d='M56.108 110.183c.612-.465-.789-4.337-.954-4.973-1.864-7.165-5.211-14.617-9.486-20.753-4.583-6.579-8.99-11.487-13.622-17.823-2.368-3.236-3.797-7.53-5.514-11.156-1.628-3.44-3.018-7.23-5.722-9.974-2.58-2.619-6.604-5.123-10.375-3.567-5.054 2.086-6.362 10.53-2.921 18.866.082.204.48 1.163.615 1.494 1.047 2.536 1.801 5.015 2.91 7.525.292.661 1.078 3.688 1.122 3.954.863 5.315-3.086 32.134 2.856 44.595 0 0 25.021 3.893 41.093-8.191l-.002.003Z'
            />
            <path
                fill='#fff'
                d='M55.154 105.213c-1.864-7.165-5.211-14.616-9.486-20.753-4.18-5.996-8.592-11.117-12.785-16.685-.349 4.154-2.515 6.258-5.55 7.6-4.5 1.99-10.279 1.876-15.045.814 0 8.494-2.577 31.061 2.727 42.186 0 0 25.02 3.893 41.093-8.191.612-.465-.789-4.337-.954-4.973v.002Z'
            />
            <path
                fill='#0C0F33'
                d='M7.413 39.622c-.028-.21-2.15.243-2.357.297-2.614.682-4.557 3.373-3.797 6.07.025.09.232.736.431 1.297.184.52.052 1.088-.318 1.496-2.719 3.006-.977 8.822 3.482 9.704 1.344.266 2.805.227 3.83-.703 1.133-1.024 1.58-2.7.701-4.033 4.57-.375 5.671-2.034 5.84-4.888-.143.654 3.447.858 3.866.825 4.224-.328 6.607-4.766 5.49-8.644a8.225 8.225 0 0 0-2.688-4.076c-2.171-1.763-3.495-.773-5.873-.848-1.424-.044-2.652-.873-4.122-.845-2.322.047-4.61 1.892-4.485 4.345v.003Z'
            />
            <path
                fill='#0C0F33'
                d='M12.898 74.71c-.057-1.61-.6-3.347-1.158-4.846a265.928 265.928 0 0 0-3.37-8.652C6.342 56.3 4.84 49.651 7.875 44.792c4.138-6.625 12.083-.344 14.823 4.267 3.177 5.348 4.72 11.567 8.065 16.816 1.882 2.952 4.21 5.669 6.384 8.406 2.202 2.771 4.405 5.542 6.488 8.406 4.1 5.64 7.367 11.758 9.6 18.373a107.29 107.29 0 0 1 2.06 6.87c.114.429.238.868.287 1.31.09.768-.168.884-.791 1.324a34.86 34.86 0 0 1-3.629 2.225c-4.704 2.508-9.897 4.027-15.15 4.865-6.769 1.08-13.98 1.328-20.784.279l.406.279c-2.414-5.156-2.975-11.114-3.228-16.731-.272-6.005-.031-12.02.248-18.019.114-2.437.256-4.877.26-7.32l-.992.466c4.966 1.336 11.239 1.281 15.954-.998 2.476-1.197 5.216-3.526 5.275-6.52.013-.64-1.253-.449-1.264.102-.052 2.623-2.373 4.647-4.56 5.702-4.316 2.083-10.137 2.109-14.68.886-.3-.08-.993.031-.993.466-.013 6.048-.559 12.086-.618 18.137-.07 6.907.06 14.139 2.073 20.802.212.703.45 1.399.72 2.084.19.475.355 1.269.82 1.527.17.096.5.083.683.106a61.2 61.2 0 0 0 4.526.401c5.86.326 11.773.044 17.55-1.008 3.474-.633 6.897-1.567 10.18-2.877a40.691 40.691 0 0 0 5.086-2.44c.74-.421 1.466-.866 2.176-1.336.57-.375 1.52-.809 1.88-1.406.511-.848-.096-2.342-.339-3.188-.4-1.385-.806-2.773-1.218-4.156a68.229 68.229 0 0 0-5.352-12.867C45.156 81.4 38.302 74.291 32.617 66.37c-2.592-3.613-4.053-7.961-5.893-11.978-1.704-3.72-3.309-7.462-6.467-10.202-2.797-2.424-6.741-4.18-10.355-2.489-2.748 1.285-4.172 4.174-4.686 7.033-1.468 8.168 3.507 15.19 5.66 22.702.313 1.088.72 2.236.758 3.376.023.644 1.282.445 1.264-.1Z'
            />
            <path
                fill='#0C0F33'
                d='M26.538 65.505c.866-.887.23-2.616-.228-3.588-.036-.077-.085-.163-.17-.18-.067-.016-.132.017-.191.048-.88.468-1.756.939-2.634 1.407-.166.087-.344.19-.411.367-.07.183.005.39.093.566.387.783 1.261 1.866 2.22 1.874.483.005.982-.148 1.32-.494ZM27.683 85.667c7.785 2.595 16.444 5.358 24.563 2.481 3.273-1.16 6.09-3.363 7.902-6.34.935-1.538 1.25-3.286 1.354-5.061.067-1.181-.403-6.454 1.701-6.131.646.098 1.256-.828.419-.957-3.691-.566-3.267 4.474-3.368 6.744-.093 2.125-.46 4.058-1.709 5.826-1.147 1.626-2.631 3.02-4.365 4.007-7.93 4.513-17.843 1.194-25.786-1.453-.569-.189-1.466.628-.711.881v.003Z'
            />
            <path
                fill='#0C0F33'
                d='M37.75 74.723c3.392 1.16 6.995 1.473 10.544.998 3.135-.422 6.263-1.225 8.651-3.4 1.962-1.788 3.389-4.179 4.172-6.704.486-1.57.388-3.084.323-4.702-.03-.786-.46-4.841 1.174-4.469.535.122 1.476-.667.72-.837-2.674-.61-3.264 2.039-3.202 4.117.047 1.592.3 3.265.019 4.844-.259 1.45-.918 2.885-1.652 4.151-1.815 3.128-4.44 4.896-7.933 5.697-4.014.923-8.189.804-12.094-.53-.533-.18-1.466.584-.721.838v-.003Z'
            />
        </svg>
    );
}
