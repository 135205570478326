/* @flow */

import * as React from 'react';
import {
    SelectPickerGeneric,
    SelectPickerGenericCreatable,
    SelectPickerGenericMulti,
    type ActionMeta,
    type FooterButtonProps,
} from 'shells/select-picker-generic';

type Props = {
    selectedId: ?string,
    selectedIds?: ?(Object[]),
    onChange: (newValue: Object, actionMeta: ActionMeta) => any,
    onClearValue?: () => void,
    placeholder: string,
    labelKey?: string,
    valueKey?: string,
    autoFocus?: boolean,
    autoBlur?: boolean,
    isCreateable?: boolean,
    isMulti?: boolean,
    createableEntityText?: string,
    isLoading?: boolean,
    noResultsText?: string,
    styles?: Object,
    menuPortalTarget?: HTMLElement,
    menuPlacement?: 'auto' | 'bottom' | 'top',
    models: Object[],
    refetch?: () => Promise<*>,
    shouldRefetch?: boolean,
    defaultsToFirstItem?: boolean,
    hasError?: ?boolean,
    controlIcon?: React.ComponentType<*>,
    inputId?: string,
    hideDividerWhenEmpty?: boolean,
    loadOptions?: (string) => Promise<*>,
    footerButton?: FooterButtonProps,
};

export class GraphQLSelect extends React.PureComponent<Props> {
    static defaultProps = {
        autoFocus: true,
        labelKey: 'name',
        valueKey: 'id',
    };

    componentDidMount() {
        if (this.shouldDefaultToFirstItem(this.props)) {
            this.props.onChange(this.props.models[0], {
                action: 'select-option',
                option: this.props.models[0],
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        // If this currently has no models (aka a loading state),
        // and then gets models, optionally select the first element
        if (!this.props.models.length && this.shouldDefaultToFirstItem(nextProps)) {
            nextProps.onChange(nextProps.models[0], {
                action: 'select-option',
                option: this.props.models[0],
            });
        }

        if (!this.props.shouldRefetch && nextProps.shouldRefetch && nextProps.refetch) {
            nextProps.refetch();
        }
    }

    render() {
        const {
            selectedId,
            selectedIds,
            noResultsText,
            models,
            autoBlur,
            onClearValue,
            defaultsToFirstItem, // eslint-disable-line no-unused-vars
            isCreateable,
            isMulti,
            createableEntityText,
            labelKey,
            valueKey,
            loadOptions,
            refetch, // eslint-disable-line no-unused-vars
            shouldRefetch, // eslint-disable-line no-unused-vars
            hasError,
            controlIcon,
            hideDividerWhenEmpty,
            ...restProps
        } = this.props;

        const selectValue = loadOptions
            ? null
            : models.find((model: Object) => {
                  return model[valueKey] === selectedId;
              });

        // This is an array of selected ids used when isMulti
        const selectValues =
            !loadOptions && selectedIds
                ? selectedIds
                      .map((id) => {
                          return models.find((element) => element[valueKey] === id);
                      })
                      .filter((entry) => entry)
                : [];

        if (isCreateable) {
            return (
                <SelectPickerGenericCreatable
                    options={models}
                    noOptionsMessage={noResultsText ? () => noResultsText : undefined}
                    // $FlowIgnore yeah…
                    value={isMulti ? selectValues : selectValue}
                    formatCreateLabel={(label) =>
                        `+ Create ${createableEntityText || ''} "${label}"`
                    }
                    isDisabled={this.props.isLoading && !models}
                    blurInputOnSelect={autoBlur}
                    onClear={onClearValue}
                    controlIcon={controlIcon}
                    isMulti={false}
                    {...restProps}
                />
            );
        }

        if (isMulti) {
            return (
                <SelectPickerGenericMulti
                    options={models}
                    noOptionsMessage={noResultsText ? () => noResultsText : undefined}
                    value={selectValues}
                    isDisabled={this.props.isLoading && !models}
                    blurInputOnSelect={autoBlur}
                    onClear={onClearValue}
                    isMulti={true}
                    labelKey={labelKey}
                    valueKey={valueKey}
                    hasError={hasError}
                    {...restProps}
                />
            );
        }

        return (
            <SelectPickerGeneric
                options={models}
                noOptionsMessage={noResultsText ? () => noResultsText : undefined}
                value={selectValue || null}
                isDisabled={this.props.isLoading && !models}
                blurInputOnSelect={autoBlur}
                onClear={onClearValue}
                labelKey={labelKey}
                valueKey={valueKey}
                hasError={hasError}
                controlIcon={controlIcon}
                hideDividerWhenEmpty={hideDividerWhenEmpty}
                {...restProps}
            />
        );
    }

    shouldDefaultToFirstItem = (props: Props) => {
        return props.models.length && props.defaultsToFirstItem && !props.selectedId;
    };
}
