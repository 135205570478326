/* @flow */

import React from 'react';

import {svgIconWrapper} from '../svg-icon-wrapper';
import Glossary from './icon-glossary.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Glossary {...validProps} />;
}

SvgComponent.iconName = 'glossary';

export const GlossaryIcon = svgIconWrapper(SvgComponent);
