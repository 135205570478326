/* @flow */
import {Observable} from 'rxjs/Observable';
import type {ActionsObservable} from 'redux-observable';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import _ from 'underscore';

import * as Schema from 'nutshell-core/schema';

import {fetchList} from 'nutshell-core/entities/fetch-list';
import {safelyParseError} from 'nutshell-core/utils';

import {
    updateMcfxCompaniesListData,
    failMcfxCompaniesListData,
    type McfxCompaniesListRequestedAction,
    updateMcfxCompaniesSchema,
    failMcfxCompaniesSchema,
} from './companies-actions';

export const requestMcfxCompaniesListEpic = (action$: ActionsObservable<*>) =>
    action$
        .ofType('MCFXCOMPANIES_LIST_DATA_REQUESTED')
        .switchMap((action: McfxCompaniesListRequestedAction) => {
            const {filters, pageNum = 1, columns, sort, q} = action.payload;

            // Our pagination components aren't 0-based, but this specific
            // request expects page 0 to be the first page. Using Math.max() here
            // in case older URLs with page 0 are still in the wild
            const adjustedPageNum = Math.max(0, pageNum - 1);

            const listRequestStream = Observable.fromPromise(
                fetchList('mcfxcompanies', {
                    filter: filters,
                    sort,
                    q,
                    page: {page: adjustedPageNum},
                    fields: columns,
                })
            );

            const companiesListDataStream = listRequestStream.map((response) =>
                updateMcfxCompaniesListData(response)
            );

            return companiesListDataStream.catch((err) => {
                const safeError = safelyParseError(err);

                return Observable.of(failMcfxCompaniesListData(safeError));
            });
        });

export const requestMcfxCompaniesSchemaEpic = (action$: ActionsObservable<*>) =>
    action$.ofType('MCFXCOMPANIES_SCHEMA_REQUESTED').switchMap(() => {
        const schemaRequestStream = Observable.fromPromise(
            Schema.fetchSchema('mcfxcompanies/list/fields')
        );

        const companiesSchemaDataStream = schemaRequestStream.map((response) => {
            const schemaProps = _.mapObject(response.properties, (val, key) => {
                return {...val, name: key};
            });

            return updateMcfxCompaniesSchema(schemaProps);
        });

        return companiesSchemaDataStream.catch((err) => {
            const safeError = safelyParseError(err);

            return Observable.of(failMcfxCompaniesSchema(safeError));
        });
    });
