/* @flow */

import * as React from 'react';

import {TruncatedElement} from '../../../truncated';

import './truncated-timeline-entry-content.css';

// Will display 6 lines of content
export const MAX_ELEMENT_HEIGHT = 100;

type Props = {
    content: ?(string | React.Node),
};

export function TruncatedTimelineEntryContent(props: Props) {
    const {content} = props;

    return content ? (
        <div styleName='truncated-content-container'>
            {typeof content === 'string' ? (
                <div styleName='truncated-content'>{content}</div>
            ) : (
                <TruncatedElement content={content} maxHeight={MAX_ELEMENT_HEIGHT} />
            )}
        </div>
    ) : null;
}
