/* @flow */

import {fetchOptions} from '../../../entity-form/fetch-options';

export const useGetParticipants = (): ({
    getParticipants: (searchTerm: ?string) => Promise<*>,
}) => {
    const getParticipants = (searchTerm: ?string) => {
        return fetchOptions(['contacts', 'accounts', 'users'])(searchTerm || '');
    };

    return {getParticipants};
};
