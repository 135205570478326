/* @flow */

import type {CollectionState} from '../collections';
import type {User} from '../types';
import * as selectors from './users-selectors';

export type UsersState = CollectionState<User>;

export const Users = {
    ...selectors,
};
