/* @flow */

import {reducer} from './accounts-reducer';
import {makeFetchAccountByIdEpic} from './accounts-epics';
import * as selectors from './accounts-selectors';
import * as actions from './accounts-actions';
import * as types from './accounts-types';

export const Accounts = {
    ...actions,
    ...selectors,
    ...types,
    reducer,
    epics: [makeFetchAccountByIdEpic()],
};

export {fetchSchema} from './accounts-api';

export type {AccountsState} from './accounts-reducer';
export type {Account, AccountFormValues, AccountsApiResponse} from './accounts-types';
export type {
    AccountsAction,
    ActionClearQueuedAccounts,
    ActionFetchByIdFailure,
    ActionFetchByIdRequest,
    ActionFetchByIdSuccess,
    ActionQueueAccountForCreation,
    ActionUnqueueAccountForCreation,
} from './accounts-actions';
