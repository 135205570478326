/* @flow */

import {Quill} from 'react-quill';
import {PlaceholderBlot, type Placeholder} from './placeholder-blot';

const Parchment = Quill.import('parchment');

type ModuleOptions = {
    placeholders: Placeholder[],
};

export class PlaceholderModule {
    placeholders: Placeholder[];
    quill: Quill;

    constructor(quill: any, options: ModuleOptions) {
        this.quill = quill;
        this.placeholders = options.placeholders;
        this.quill.root.addEventListener('click', this.onClick);
    }

    onClick = (e: SyntheticInputEvent<*>) => {
        const blot = Parchment.find(e.target.parentNode);

        if (blot instanceof PlaceholderBlot) {
            const index = this.quill.getIndex(blot);
            this.quill.setSelection(index, blot.length(), Quill.sources.USER);
        }
    };
}
