/* @flow */

import * as React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import CircleWarning from './icon-circle-warning.svg';

type Props = {
    fill?: string,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <CircleWarning {...validProps} />;
}
SvgComponent.iconName = 'circleWarning';

export const CircleWarningIcon = svgIconWrapper(SvgComponent);
