/* @flow */

import * as React from 'react';

export function EmailAirplaneWhite() {
    return (
        <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 245 122'>
            <g clipPath='url(#clip0)'>
                <path
                    d='M97.324 86.872c.806-.236 1.6-.482 2.394-.74'
                    stroke='#0C0F33'
                    strokeWidth='2.79'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                />
                <path
                    d='M106.732 83.762a90.82 90.82 0 0024.309-12.697c6.354-4.861 12.037-13.405 13.648-21.33 2.405-11.733-17.06-10.542-17.172.201-.089 8.578 8.95 17.458 17.205 18.176 7.507.663 21.848-8.678 31.402-16.121'
                    stroke='#0C0F33'
                    strokeWidth='2.79'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeDasharray='4.39 6.58'
                />
                <path
                    d='M178.987 49.712l1.913-1.606'
                    stroke='#0C0F33'
                    strokeWidth='2.79'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                />
                <path
                    d='M186.817 21.33L171.715 6.176l40.831-1.684 30.204-2.807-22.932 37.048-18.458-10.665-14.543 10.665V21.331z'
                    fill='#FAC942'
                    stroke='#000'
                />
                <path
                    d='M186.124 41.617a1.68 1.68 0 01-1.678-1.662v-4.411c0-3.795.067-7.623.145-11.328l.056-3.053-1.745-1.572A130.567 130.567 0 00167.8 7.342a1.69 1.69 0 01-.686-1.868 1.676 1.676 0 011.57-1.218l7.473-.203c21.981-.584 44.746-1.19 66.941-4.042a1.686 1.686 0 011.644.798 1.662 1.662 0 010 1.83 402.206 402.206 0 00-23.111 37.35 1.67 1.67 0 01-1.683.873 1.684 1.684 0 01-.633-.21c-6.421-3.705-12.675-7.488-18.503-11.047a149.62 149.62 0 00-13.491 11.552 1.658 1.658 0 01-1.197.46zM200.667 25.9c.308-.002.61.084.872.247a836.16 836.16 0 0017.899 10.733c5.593-10.25 12.115-20.86 20.371-33.051-21.199 2.48-42.722 3.053-63.574 3.603l-2.439.068c3.983 3.02 7.685 6.343 11.288 9.587l2.315 2.077a1.708 1.708 0 01.571 1.291l-.034 3.829c-.067 3.693-.145 7.499-.145 11.226v.55a134.115 134.115 0 0111.936-9.89 1.74 1.74 0 01.962-.281l-.022.011z'
                    fill='#0C0F33'
                />
                <path
                    d='M201.573 28.853a1.485 1.485 0 01-.873-.292 1.646 1.646 0 01-.649-1.314c0-1.2-.402-.83.134-1.28 0 0 1.79-.628 1.846-.617a78.172 78.172 0 0010.986-6.096 74.278 74.278 0 0112.473-6.736 1.494 1.494 0 011.946.954 1.63 1.63 0 01-.895 2.055 72.074 72.074 0 00-11.969 6.489 74.326 74.326 0 01-12.474 6.736 1.41 1.41 0 01-.525.1zM78.586 50.385c-.212.315-.928 1.213-.928 1.213-7.83 10.104-13.301 18.513-17.25 24.642-5.135 7.96-7.16 6.41-10.728 6.837H39.612c-4.61 0-4.117 1.235-17.698-11.058l-.156-.146c-3.412-3.042-7.215-6.511-11.578-10.373 0 0-1.264-1.18-1.723-1.527a14.706 14.706 0 00-6.824-2.975 1.762 1.762 0 00-1.22.65A1.775 1.775 0 000 58.975c.38 3.682.772 7.353 1.197 11.013 2.024 17.682 4.385 34.208 7.226 50.52a1.788 1.788 0 001.946 1.471l1.466-.158c4.866-.561 8.469-1.122 12.641-1.863 5.94-.999 13.257-2.246 27.24-3.828 3.154-.371 6.298-.719 9.453-1.123l9.564-1.123a730.834 730.834 0 0017.731-2.245 1.79 1.79 0 001.156-.687 1.8 1.8 0 00.354-1.3l-.179-1.426.425-.326a1.81 1.81 0 000-2.514l-.458-.461-.448-.449-.369-3.154c-1.275-11.148-2.405-22.454-3.49-33.355l-.09-.898a3790.51 3790.51 0 00-1.946-19.175 1.779 1.779 0 00-.671-1.224 1.116 1.116 0 00-1.242 0 13.893 13.893 0 00-2.92 3.716z'
                    fill='#0C0F33'
                />
                <path
                    d='M80.477 53.787l.157 1.583c.447 4.334.887 8.675 1.32 13.023 1.118 11.013 2.092 20.96 3.222 30.952l.167 1.493-1.186-.92c-2.986-2.38-6.242-4.615-8.86-6.4-5.056-3.469-9.844-6.736-14.05-11.114l-.347-.36.28-.415 2.103-3.233.123-.18c3.759-5.781 8.95-13.685 16.11-23.16l.961-1.269zM11.187 115.286l-.257-1.526C8.536 99.423 6.522 84.974 4.766 69.605a734.982 734.982 0 01-.772-6.96l-.112-1.123.984.415a11.188 11.188 0 012.63 1.976l.301.27c4.397 3.918 8.278 7.432 11.724 10.553 4.676 4.221 8.222 7.42 11.298 10.104l.493.426-.437.472c-5.157 5.613-9.05 12.226-12.484 18.052-2.148 3.671-4.117 6.972-6.343 10.239l-.861 1.257zM48.17 86.445c3.825-.494 1.51.056 10.258-.763l.95-.079a96.517 96.517 0 0013.906 10.879 96.744 96.744 0 0112.506 9.576l.168.157.258 2.245-.604.079c-4.386.629-9.24 1.246-15.281 1.942-3.166.371-6.332.719-9.498 1.067l-.638.067-8.87.999c-14.107 1.628-21.48 2.863-27.397 3.851-2.93.494-5.694.954-8.871 1.392l-1.41.191.817-1.123c2.573-3.682 4.833-7.521 7.014-11.226 3.557-6.051 7.227-12.35 12.194-17.626l1.465-1.628H48.17z'
                    fill='#ffffff'
                />
                <path
                    d='M83.475 47.938a1.756 1.756 0 00-.671-1.224 1.796 1.796 0 00-1.119-.381h-.235c-8.95 1.19-17.966 2.537-26.714 3.85h-.123c-12.563 1.898-25.528 3.84-38.415 5.322-5.168.595-9.934 1.122-14.543 1.47A1.786 1.786 0 00.07 58.24c-.07.23-.094.473-.07.712l3.882 2.571.985.427c.966.51 1.852 1.16 2.629 1.93l.302.282c4.396 3.918 8.278 7.42 11.723 10.541 4.677 4.233 8.223 7.432 11.299 10.104l.492.416 2.752 2.346.459.36c6.22 5.006 10.068 6.803 13.513 6.398h.135c3.825-.505 6.712-3.67 10.258-8.622l.425-.606 2.047-3.031.28-.427c.67-1.01 1.364-2.088 2.103-3.222l.123-.18c3.759-5.781 8.95-13.696 16.109-23.16l.962-1.257 2.998-5.883z'
                    fill='#0C0F33'
                />
                <path
                    d='M9.878 59.804c2.181-.224 4.418-.46 6.712-.73l38.47-5.298c7.507-1.123 14.778-2.246 22.016-3.211l1.51-.213-.928 1.212c-7.83 10.171-13.267 18.547-17.25 24.699-5.694 8.745-9.128 14.022-12.697 14.493h-.537c-4.597 0-11.679-6.399-25.26-18.692l-.156-.213c-3.412-3.043-7.215-6.512-11.578-10.374L8.457 59.95l1.42-.146z'
                    fill='#ffffff'
                />
            </g>
            <defs>
                <clipPath id='clip0'>
                    <path fill='#fff' d='M0 0h245v122H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
