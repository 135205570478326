/* @flow */

import moment from 'moment';
import type Moment from 'moment';

import {isSameYear} from './same-year';

const TIME_FORMAT = 'h:mma';

const CALENDAR_FORMATS = {
    lastDay: () => `[Yesterday at] ${TIME_FORMAT}`,
    sameDay: () => `[Today at] ${TIME_FORMAT}`,
    nextDay: () => `[Tomorrow at] ${TIME_FORMAT}`,
    lastWeek: () => `[Last] dddd [at] ${TIME_FORMAT}`,
    nextWeek: () => `dddd [at] ${TIME_FORMAT}`,
    sameElse: () => `dddd, MMM D [at] ${TIME_FORMAT}`,
};

const DUE_CALENDAR_FORMATS = {
    lastDay: () => `[yesterday at] ${TIME_FORMAT}`,
    sameDay: () => `[today at] ${TIME_FORMAT}`,
    nextDay: () => `[tomorrow at] ${TIME_FORMAT}`,
    lastWeek: () => `[last] dddd [at] ${TIME_FORMAT}`,
    nextWeek: () => `dddd [at] ${TIME_FORMAT}`,
    sameElse: () => `dddd, MMM D [at] ${TIME_FORMAT}`,
};

const SCHEDULED_MAIL_FORMATS = {
    lastDay: () => '[Yesterday]',
    sameDay: () => '[Today]',
    nextDay: () => '[Tomorrow]',
    lastWeek: () => 'MMMM Do',
    nextWeek: () => 'MMMM Do',
    sameElse: () => 'MMMM Do',
};

const SCHEDULED_MAIL_FORMATS_DIFFERENT_YEAR = {
    lastDay: () => '[Yesterday]',
    sameDay: () => '[Today]',
    nextDay: () => '[Tomorrow]',
    lastWeek: () => 'MMMM Do, YYYY',
    nextWeek: () => 'MMMM Do, YYYY',
    sameElse: () => 'MMMM Do, YYYY',
};

const TIME_THEN_DATE = {
    lastDay: () => `${TIME_FORMAT} [yesterday]`,
    sameDay: () => `${TIME_FORMAT} [today]`,
    nextDay: () => `${TIME_FORMAT} [tomorrow]`,
    lastWeek: () => `${TIME_FORMAT} [last] dddd`,
    nextWeek: () => `${TIME_FORMAT} [next] dddd`,
    sameElse: () => `${TIME_FORMAT} [on] dddd, MMM D`,
};

const TIMELINE_HEADER_FORMATS = {
    lastDay: () => 'MMM D h:mm a',
    sameDay: () => 'h:mm a',
    nextDay: () => 'MMM D h:mm a',
    lastWeek: () => 'MMM D h:mm a',
    nextWeek: () => 'MMM D h:mm a',
    sameElse: () => 'MMM D h:mm a',
};

const TIMELINE_HEADER_FORMATS_DIFFERENT_YEAR = {
    lastDay: () => 'MMM D YYYY',
    sameDay: () => 'MMM D YYYY',
    nextDay: () => 'MMM D YYYY',
    lastWeek: () => 'MMM D YYYY',
    nextWeek: () => 'MMM D YYYY',
    sameElse: () => 'MMM D YYYY',
};

const TIMELINE_HEADER_SCHEDULED_MAIL = {
    sameDay: () => 'h:mm a',
    nextDay: () => '[Tomorrow]',
    lastWeek: () => 'MMM D',
    nextWeek: () => 'MMM D',
    sameElse: () => 'MMM D',
};

const PINNED_TIME_FORMATS = {
    lastDay: () => `[yesterday at] ${TIME_FORMAT}`,
    sameDay: () => `[today at] ${TIME_FORMAT}`,
    sameElse: () => `${TIME_FORMAT} on MMM D`,
};

/**
 * Formats a unix timestamp in the format of "Tuesday, July 22 at 2:30pm", with more
 * friendly formats for dates that are close to today (yesterday, today, tomorrow,
 * last week, Monday, etc.)
 *
 * We currently use this in our slimmed down verison of the timeline
 *
 * @param  {number} timestamp           - Unix timestamp to format
 * @param  {Moment} [now]               - Optional "fromNow" time
 * @return {string}                     - Formatted timestamp
 */
export function formatTimestampForTimeline(timestamp: number, now?: Moment) {
    return moment.unix(timestamp).calendar(now, CALENDAR_FORMATS);
}

/**
 * Formats a unix timestamp into a "Tuesday, July 22 at 2:30pm" string,
 * handling downcasing of words for proximal dates (like "Due yesterday at
 * 2:30pm").
 *
 * @param  {number} timestamp           - Unix timestamp to format
 * @param  {Moment} [now]               - Optional "fromNow" time
 * @return {string}                     - Formatted timestamp
 */
export function formatTimestampToHumanReadable(timestamp: number, now?: Moment) {
    return moment.unix(timestamp).calendar(now, DUE_CALENDAR_FORMATS);
}

/**
 * Formats a unix timestamp into a "Due Tuesday, July 22 at 2:30pm" string,
 * handling downcasing of words for proximal dates (like "Due yesterday at
 * 2:30pm").
 *
 * @param  {number} timestamp           - Unix timestamp to format
 * @param  {Moment} [now]               - Optional "fromNow" time
 * @return {string}                     - Formatted timestamp
 */
export function formatTimestampForDueDate(timestamp: number, now?: Moment) {
    const formattedString = formatTimestampToHumanReadable(timestamp, now);

    return `Due ${formattedString}`;
}

/**
 * Formats a unix timestamp in the format of either "Today", "Tomorrow", or
 * the absolute date (December 19th)
 *
 * We currently use this in our slimmed down verison of the timeline
 *
 * @param  {number} timestamp           - Unix timestamp to format
 * @param  {Moment} [now]               - Optional "fromNow" time
 * @return {string}                     - Formatted timestamp
 */
export function formatTimestampForScheduledMail(timestamp: number, now?: Moment) {
    const timestampMoment = moment.unix(timestamp);
    const format = isSameYear(timestampMoment, now)
        ? SCHEDULED_MAIL_FORMATS
        : SCHEDULED_MAIL_FORMATS_DIFFERENT_YEAR;

    return moment.unix(timestamp).calendar(now, format);
}

export function formatTimestampAsTimeThenDay(timestamp: number, now?: Moment) {
    return moment.unix(timestamp).calendar(now, TIME_THEN_DATE);
}

const SHORT_MONTHS = {
    lastDay: () => `[yesterday at] ${TIME_FORMAT}`,
    sameDay: () => `[today at] ${TIME_FORMAT}`,
    nextDay: () => `[tomorrow at] ${TIME_FORMAT}`,
    lastWeek: () => '[on] MMM Do, YYYY',
    nextWeek: () => '[on] MMM Do, YYYY',
    sameElse: () => '[on] MMM Do, YYYY',
};

export function formatTimestampAsAbbrevMonth(timestamp: number, now?: Moment) {
    return moment.unix(timestamp).calendar(now, SHORT_MONTHS);
}

/**
 * Formats a unix timestamp in the format of the absolute date (December 19)
 * followed by time for this year or by YYYY for a different year
 *
 * We currently use this for the email preview modal header
 *
 * @param  {number} timestamp           - Unix timestamp to format
 * @param  {Moment} [now]               - Optional "fromNow" time
 * @return {string}                     - Formatted timestamp
 */
export function formatTimestampForTimelineHeader(timestamp: number, now?: Moment) {
    const timestampMoment = moment.unix(timestamp);
    const format = isSameYear(timestampMoment, now)
        ? TIMELINE_HEADER_FORMATS
        : TIMELINE_HEADER_FORMATS_DIFFERENT_YEAR;

    return moment.unix(timestamp).calendar(now, format);
}

/**
 * Formats a unix timestamp in the format of the absolute date (December 19)
 *
 * We currently use this for the queued email preview modal header
 *
 * @param  {number} timestamp           - Unix timestamp to format
 * @param  {Moment} [now]               - Optional "fromNow" time
 * @return {string}                     - Formatted timestamp
 */
export function formatTimestampForScheduledMailHeader(timestamp: number, now?: Moment) {
    const format = TIMELINE_HEADER_SCHEDULED_MAIL;

    return moment.unix(timestamp).calendar(now, format);
}

/**
 * Formats a unix timestamp in the format of "Tuesday, July 22 at 2:30pm", with more
 * friendly formats for dates that are close to today (yesterday, today, tomorrow,
 * last week, Monday, etc.)
 *
 * We currently use this in our slimmed down verison of the timeline
 *
 * @param  {number} timestamp           - Unix timestamp to format
 * @param  {Moment} [now]               - Optional "fromNow" time
 * @return {string}                     - Formatted timestamp
 */
export function formatTimestampForPinnedEntry(timestamp: number, now?: Moment) {
    return moment.unix(timestamp).calendar(now, PINNED_TIME_FORMATS);
}
