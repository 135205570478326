/* @flow */

import * as React from 'react';

import {CopyIcon} from '../../icon';
import {Button} from '../button';

type Props = {
    target: ?React.Ref<*>,
    size: 'big' | 'xlarge',
    variant?: 'primary' | 'secondary',
    onCopy?: () => void,
};

export function CopyButton(props: Props) {
    const [target, setTarget] = React.useState(null);
    const buttonRef = React.useRef(null);

    React.useEffect(() => {
        if (props.target && typeof props.target === 'object') {
            // $FlowFixMe upgrading Flow to v0.110.1
            setTarget(props.target.current);
        }
    }, [props.target]);

    const copyToClipboard = () => {
        const body = document.body;
        if (!target || !body) return;

        if ((target.nodeName === 'INPUT' || target.nodeName === 'TEXTAREA') && !target.hidden) {
            target.select();
            document.execCommand('copy');
            target.blur();
        } else {
            const dummyEl = document.createElement('input');
            body.appendChild(dummyEl);
            let value = target.innerText;
            if (target.nodeName === 'INPUT' || target.nodeName === 'TEXTAREA') {
                // These element types will fall through if they are hidden (as hidden elements
                // cannot be selected)
                value = target.value;
            }
            dummyEl.setAttribute('value', value);
            dummyEl.select();
            document.execCommand('copy');
            body.removeChild(dummyEl);
        }

        if (props.onCopy) {
            props.onCopy();
        }
    };

    return (
        <Button
            size={props.size}
            icon={CopyIcon}
            variant={props.variant}
            getButtonRef={buttonRef}
            onClick={copyToClipboard}
            data-tip='Copied!'
            data-event='mousedown'
            data-event-off='mouseout'
        >
            Copy
        </Button>
    );
}
