/* @flow */

import * as React from 'react';
import {getRandomInt} from '../utils/numbers';
import styles from './loading-text.css';

type Props = {
    color: string,
    height: number,
    minWidth: number,
    maxWidth: number,
    width?: number, // Override random widths
};

export class LoadingText extends React.PureComponent<Props> {
    static defaultProps = {
        color: '#e1e1e1', // --off-white-dk
        height: 15,
        minWidth: 30,
        maxWidth: 160,
    };

    render() {
        const calculatedStyles = {
            backgroundColor: this.props.color,
            height: this.props.height,
            width: this.props.width || getRandomInt(this.props.minWidth, this.props.maxWidth),
        };

        return <div className={styles['loading-text-placeholder']} style={calculatedStyles} />;
    }
}
