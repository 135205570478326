/* @flow */

import type {SerializedRelationshipFilter, RelationshipFilter} from './deserialize-relationship';

export function serializeRelationship(
    filterObj: RelationshipFilter | string
): SerializedRelationshipFilter {
    // Note this is for legazy support only. I believe I have removed all of
    // these calls, but in the interestes of not breaking shit, leaving this in
    // place.
    if (typeof filterObj === 'string') return {anyAll: 'any', data: [{data: filterObj}]};

    const {none, data, anyAll} = filterObj;

    return {
        none,
        anyAll,
        data: data.map((item) =>
            item.isNot
                ? item
                : {
                      // There's a chance that data was created as an array of strings,
                      // instead of an array of objects with a `data` key.
                      data: typeof item === 'string' ? item : item.data,
                  }
        ),
    };
}
