/* @flow */

import {useMutation} from '@apollo/react-hooks';

import type {
    McfxViqCompanyUpdateInput,
    McfxViqCompanyUpdate as UpdateMcfxViqCompanyMutation,
    McfxViqCompanyUpdateVariables as UpdateMcfxViqCompanyMutationVariables,
} from 'nutshell-graphql-types';

import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import GET_UNLOCK_INFO from '../../../../people-iq/graphql/queries/get-unlock-info.graphql';
import UPDATE_MCFX_VIQ_COMPANY from '../mutations/update-mcfx-viq-company.graphql';

export function useUpdateMcfxViqCompany(): {
    updateMcfxViqCompany: (input: McfxViqCompanyUpdateInput) => Promise<*>,
    isLoading: boolean,
    error: ?string,
} {
    const [updateMcfxViqCompany, {loading, error}] = useMutation<
        UpdateMcfxViqCompanyMutation,
        UpdateMcfxViqCompanyMutationVariables
    >(UPDATE_MCFX_VIQ_COMPANY, {
        refetchQueries: [{query: GET_UNLOCK_INFO}],
    });

    return {
        updateMcfxViqCompany: (input: McfxViqCompanyUpdateInput) =>
            updateMcfxViqCompany({
                variables: {input},
            }),
        isLoading: loading,
        error: safelyGetGraphQLErrorMessage(error),
    };
}
