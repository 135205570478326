/* @flow */

import {useQuery} from '@apollo/react-hooks';

import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import type {
    GetAudiences_audiences_edges_node as Audience,
    GetAudiences as GetAudiencesQuery,
} from 'nutshell-graphql-types';

import GET_AUDIENCES from '../queries/get-audiences.graphql';

export const useGetAudiences = (): ({
    isLoading: boolean,
    errorMessage: ?string,
    audiences: Audience[],
    refetch: () => Promise<*>,
}) => {
    const {loading, refetch, data, error} = useQuery<GetAudiencesQuery, {}>(GET_AUDIENCES, {
        notifyOnNetworkStatusChange: true,
    });

    return {
        isLoading: loading,
        refetch,
        errorMessage: safelyGetGraphQLErrorMessage(error),
        audiences:
            data && data.audiences && data.audiences.edges
                ? data.audiences.edges.map((edge) => edge.node)
                : [],
    };
};
