/* @flow */

type EntityType = 'ACCOUNT' | 'CONTACT' | 'LEAD';

export function getHumanReadableEntityTypeFromGraphQLEnum(
    entityName: EntityType,
    isPlural?: boolean
): string {
    if (isPlural) {
        switch (entityName) {
            case 'ACCOUNT':
                return 'Companies';
            case 'CONTACT':
                return 'People';
            case 'LEAD':
            default:
                return 'Leads';
        }
    }

    switch (entityName) {
        case 'ACCOUNT':
            return 'Company';
        case 'CONTACT':
            return 'Person';
        case 'LEAD':
        default:
            return 'Lead';
    }
}
