/* @flow */

import {useQuery} from '@apollo/react-hooks';
import {safelyGetGraphQLErrorMessage, makeDataSafe} from 'nutshell-core/utils/graphql-errors';
import type {
    GetLimits as GetLimitsQuery,
    GetLimitsVariables as GetLimitsQueryVariables,
    LimitedType,
} from 'nutshell-graphql-types';

import GET_LIMITS from './graphql/queries/get-limits.graphql';

export const useGetLimitsByType = (type: ?LimitedType) => {
    const {loading: isLoading, data, error, refetch} = useQuery<
        GetLimitsQuery,
        GetLimitsQueryVariables
    >(GET_LIMITS, {
        // $FlowIgnore
        variables: {type},
        fetchPolicy: 'cache-and-network',
        skip: !type,
    });

    const {limits} = makeDataSafe(data);

    return {
        isLoading,
        limits,
        errorMessage: safelyGetGraphQLErrorMessage(error),
        refetch,
    };
};
