/* @flow */

import * as React from 'react';

type Props = {
    content: React.Node,
    maxHeight: number,
};

export function TruncatedElement(props: Props) {
    const {content, maxHeight} = props;

    const [maxElementHeight, setMaxElementHeight] = React.useState<?number>(undefined);
    const elementRef = React.useRef(null);

    React.useLayoutEffect(() => {
        if (elementRef && elementRef.current) {
            setMaxElementHeight(
                elementRef.current.clientHeight <= maxHeight
                    ? elementRef.current.clientHeight
                    : maxHeight
            );
        }
    }, [maxHeight]);

    return (
        <div ref={(r) => (elementRef.current = r)}>
            <div style={{maxHeight: maxElementHeight, overflow: 'hidden'}}>{content}</div>
            {maxElementHeight &&
            elementRef.current &&
            elementRef.current.clientHeight > maxElementHeight ? (
                <div>…</div>
            ) : (
                undefined
            )}
        </div>
    );
}
