/* @flow */

import * as React from 'react';
import {useSelector} from 'react-redux';

import {Products} from 'nutshell-core/products';
import {Markets} from 'nutshell-core/markets';
import {Session} from 'nutshell-core/session';
import type {ProductId, Market} from 'nutshell-core/types';

import {appHistory} from '../../../../../history';
import {SelectPickerGeneric} from '../select-picker-generic';
import {TextField} from '../textfield';
import {CancelButton} from '../button';

import './product-picker.css';

type Props = {|
    input: {
        value: ?Object,
        onChange: (productMap: Object) => any,
    },
    selectedOptions: Object[],
    onRemove: () => any,
    currentMarket: ?Market,
|};

export function ReduxFormProductPicker(props: Props) {
    const {currentMarket, input} = props;
    const products = useSelector((state) => Products.getAll(state));
    const defaultMarket = useSelector((state) => Markets.getDefaultMarket(state));
    const loggedInUser = useSelector((state) => Session.getSessionStateUser(state));

    const [productId, setProductId] = React.useState<?ProductId>(
        (input.value && input.value.links && input.value.links.product) || null
    );
    const [quantity, setQuantity] = React.useState<string>(
        (input.value && input.value.quantity) || ''
    );
    const [price, setPrice] = React.useState<string>((input.value && input.value.price) || '');

    const getCurrencySymbol = () => {
        const market = getCurrentMarket();

        return market ? market.currencySymbol : '';
    };

    // if market changes, update the price to reflect the new market
    React.useEffect(() => {
        if (currentMarket) {
            const selectedProduct = products.find((item) => item.id === productId);
            const marketPrice = selectedProduct && selectedProduct.prices[currentMarket.id];
            const newPrice = marketPrice || {amount: 0};

            handleChangePrice(newPrice.amount.toString());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMarket]);

    React.useEffect(() => {
        if (productId) {
            handleSave();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, quantity, price]);

    const getCurrentMarket = (): ?Market => {
        return props.currentMarket || defaultMarket;
    };

    const handleClearProduct = () => {
        setProductId(null);
        setQuantity('');
        setPrice('');

        if (input.value) {
            props.onRemove();
        }
    };

    const handleKeyDownProductInputs = (event) => {
        // Capture the ENTER key to confirm the product and not submit the form.
        if (event.which === 13) {
            event.preventDefault();
            handleSave();
        }

        // Capture the ESC key to cancel the product picker.
        if (event.which === 27) {
            event.preventDefault();
            handleClearProduct();
        }
    };

    const handleSelectProduct = (value) => {
        const market = getCurrentMarket();
        const existingProductMap = typeof input.value === 'object' ? input.value : undefined;

        const selectedProduct = products.find((item) => item.id === value.id);

        let productPrice;
        if (existingProductMap) {
            productPrice = existingProductMap.price.amount;
        }

        if (selectedProduct && market) {
            const marketPrice = selectedProduct.prices[market.id];
            productPrice = marketPrice ? marketPrice.amount : '0';
        } else {
            productPrice = value.price ? value.price.amount : '0';
        }

        setProductId(value.id);
        setPrice(productPrice || value.price.amount);
        setQuantity(existingProductMap ? existingProductMap.quantity : '1');
    };

    const handleChangeQuantity = (newQuantity: string) => {
        setQuantity(newQuantity);
    };

    const handleBlurQuantity = () => {
        if (quantity === '0') {
            handleClearProduct();
        } else if (quantity === '') {
            setQuantity('1');
        }
    };

    const handleChangePrice = (newPrice: string) => {
        setPrice(newPrice);
    };

    const handleBlurPrice = () => {
        const formattedPrice = Number(price).toLocaleString(undefined, {
            minimumFractionDigits: 2,
        });

        setPrice(formattedPrice);
    };

    const handleSave = () => {
        const existingProductMap = typeof input.value === 'object' ? input.value : undefined;

        const amount = parseFloat(price.replace(/,/g, ''));
        const productPrice = {
            amount,
            formatted: `${getCurrencySymbol()}${price}`,
        };

        const productQuantity = Math.round(Number(quantity || '1'));

        if (existingProductMap) {
            const updatedProductMap = {
                ...existingProductMap,
                quantity: productQuantity,
                price: productPrice,
            };

            input.onChange(updatedProductMap);
        } else {
            const product = products.find((item) => item.id === productId);

            if (!product) return;

            input.onChange({
                type: 'productMaps',
                name: product.name,
                quantity: quantity,
                price: price,
                links: {
                    product: productId,
                },
            });
        }
    };

    // filter out already selected products from the list of available products
    const availableOptions = products.filter(
        (product) =>
            !props.selectedOptions.find(
                (field) => field && field.links && field.links.product === product.id
            )
    );

    return (
        <div styleName='container'>
            <div styleName='select-container'>
                <SelectPickerGeneric
                    value={products.find(({id}) => id === productId) || null}
                    valueKey='id'
                    labelKey='name'
                    maxMenuHeight={200}
                    placeholder='Select a product…'
                    noOptionsMessage={() => 'No products found'}
                    options={availableOptions}
                    onChange={handleSelectProduct}
                    footerButton={
                        // only users with setup access get to see the create new products row
                        loggedInUser && loggedInUser.permissions.canAccessSetup
                            ? {
                                  label: 'Manage products',
                                  onClick: () => {
                                      appHistory.push('/setup/product');
                                  },
                              }
                            : undefined
                    }
                />
            </div>
            <label styleName='product-picker-label'>
                Qty:
                <TextField
                    name='quantity'
                    disabled={!productId}
                    min='0'
                    type='number'
                    value={quantity}
                    onChange={handleChangeQuantity}
                    onBlur={handleBlurQuantity}
                    onKeyDown={handleKeyDownProductInputs}
                    selectOnFocus={true}
                />
            </label>
            <label styleName='product-picker-label--price'>
                Price:
                <TextField
                    disabled={!productId}
                    value={price}
                    onChange={handleChangePrice}
                    onBlur={handleBlurPrice}
                    onKeyDown={handleKeyDownProductInputs}
                    selectOnFocus={true}
                />
            </label>
            <div styleName='cancel-button'>
                {productId && <CancelButton onClick={handleClearProduct} />}
            </div>
        </div>
    );
}
