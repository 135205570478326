/* @flow */

import type {Dispatch} from 'redux';
import type {OutcomesApiResponse} from '../types';
import {api} from '../api';

// Action Types
export type OutcomesAction =
    | {type: 'OUTCOMES_REQUESTED'}
    | {type: 'OUTCOMES_UPDATED', payload: OutcomesApiResponse}
    | {type: 'OUTCOMES_FAILED', payload?: string, error: true};

// Type Constants
export const OUTCOMES_REQUESTED = 'OUTCOMES_REQUESTED';
export const OUTCOMES_UPDATED = 'OUTCOMES_UPDATED';
export const OUTCOMES_FAILED = 'OUTCOMES_FAILED';

// Actions
export const requestOutcomes = (): OutcomesAction => ({type: OUTCOMES_REQUESTED});
export const updateOutcomes = (outcomesResponse: OutcomesApiResponse): OutcomesAction => {
    return {type: OUTCOMES_UPDATED, payload: outcomesResponse};
};
export const failOutcomes = (errorMsg?: string): OutcomesAction => ({
    type: OUTCOMES_FAILED,
    payload: errorMsg,
    error: true,
});

/**
 * Gets all lead outcomes models for instance
 *
 * @return {Function}              Thunk to request outcomes and dispatch corresponding
 *                                 actions during the process
 */
export function fetchOutcomes() {
    return function(dispatch: Dispatch<*>) {
        dispatch(requestOutcomes());

        api.get('outcomes')
            .then((response) => response.json())
            .then((res) => dispatch(updateOutcomes(res)))
            .catch((err) => dispatch(failOutcomes(err.message)));
    };
}
