import React from 'react';

import {svgIconWrapper} from '../svg-icon-wrapper';
import Minus from './icon-minus.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Minus {...validProps} />;
}
SvgComponent.iconName = 'minus';

export const MinusIcon = svgIconWrapper(SvgComponent);
