/* @flow */

import * as React from 'react';

import {Stack} from '../layout';
import {TextField} from '../textfield';
import {Body} from '../typography';

type Props = {
    value: string,
    onChange: (val: string) => void,
    maxLength: number,
    placeholder?: string,
    isMultiLine?: boolean,
};

export function CharacterLimitedTextfield(props: Props) {
    const hasError = props.value.length > props.maxLength;

    return (
        <Stack spacing={8}>
            <TextField
                placeholder={props.placeholder}
                value={props.value}
                onChange={(val) => props.onChange(val)}
                hasError={hasError}
                multiline={props.isMultiLine}
            />
            <div className='text-align-right'>
                <Body color={hasError ? 'error' : undefined} size='small'>
                    {props.value.length} / {props.maxLength} characters
                </Body>
            </div>
        </Stack>
    );
}
