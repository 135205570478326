import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SparkleOpen from './icon-sparkle-open.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SparkleOpen {...validProps} />;
}
SvgComponent.iconName = 'sparkle';

export const SparkleOpenIcon = svgIconWrapper(SvgComponent);
