import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Tags from './icon-tags.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Tags {...validProps} />;
}
SvgComponent.iconName = 'tag';

export const TagsIcon = svgIconWrapper(SvgComponent);
