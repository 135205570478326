/* @flow */

import * as React from 'react';
import {useMutation} from '@apollo/react-hooks';

import type {
    GetTagsBasic as GetTagsQuery,
    SelectCreateTag as SelectCreateTagMutation,
    SelectCreateTagVariables as SelectCreateTagMutationVariables,
    TagType,
} from 'nutshell-graphql-types';

import {type ListOption, ColorNamedOption} from 'shells/select-option-components';

import {GraphQLSelect} from './graphql-select';
import GET_TAGS from './queries/tags.graphql';
import CREATE_TAG from './mutations/create-tag.graphql';

type Props = {
    selectedId: ?string,
    options: ListOption[],
    tagType: TagType,
    onChange: (newValue: ListOption) => any,
    refetch: () => Promise<*>,
    updateOptions: (newOption: ListOption) => void,
    onClearValue?: () => void,
    labelKey?: string,
    valueKey?: string,
    autoFocus?: boolean,
    autoBlur?: boolean,
    isLoading?: boolean,
    noResultsText?: string,
    styles?: Object,
    menuPortalTarget?: HTMLElement,
    defaultsToFirstItem?: boolean,
    isCreateable?: boolean,
    hasError?: ?boolean,
};

export const GraphQLSelectTagsOld = (props: Props) => {
    const [createTag] = useMutation<SelectCreateTagMutation, SelectCreateTagMutationVariables>(
        CREATE_TAG
    );

    return (
        <GraphQLSelect
            {...props}
            onChange={(newValue: ListOption) => {
                if (!props.options.find((option) => option.value === newValue.value)) {
                    const tempNewOption = {
                        value: newValue.value,
                        label: newValue.label,
                    };
                    props.updateOptions(tempNewOption);

                    // This is a created value, need to run our mutation.
                    createTag({
                        variables: {
                            tag: {tag: {name: newValue.label, entityType: props.tagType}},
                        },
                        update: (proxy, mutationResults) => {
                            // Read the data from our cache for this query.
                            const cacheResult: ?GetTagsQuery = proxy.readQuery({
                                query: GET_TAGS,
                                variables: {
                                    first: 1000,
                                    input: {entityType: props.tagType},
                                },
                            });

                            if (!cacheResult || !cacheResult.tags) {
                                return;
                            }

                            const newTag =
                                mutationResults &&
                                mutationResults.data &&
                                mutationResults.data.createTag &&
                                mutationResults.data.createTag.tag;

                            if (!newTag) return;

                            proxy.writeQuery({
                                query: GET_TAGS,
                                variables: {
                                    first: 1000,
                                    input: {entityType: props.tagType},
                                },
                                data: {
                                    tags: {
                                        ...cacheResult.tags,
                                        edges: [
                                            {
                                                cursor: newTag.id,
                                                node: newTag,
                                                __typename: 'TagsEdge',
                                            },
                                        ].concat(cacheResult.tags.edges),
                                    },
                                },
                            });

                            const newTagValue = {
                                value: newTag.id,
                                label: newTag.name,
                            };

                            // Update our selected item to the new item
                            props.onChange(newTagValue);

                            // Update the tag options to include the new one immediately
                            props.updateOptions(newTagValue);

                            // Refetch the tag options from the server
                            props.refetch();
                        },
                    });
                } else {
                    return props.onChange(newValue);
                }
            }}
            isLoading={props.isLoading}
            openMenuOnFocus={true}
            noResultsText='No tags were found'
            placeholder='Select or create a tag'
            valueKey='value'
            labelKey='label'
            components={{
                Option: ColorNamedOption,
            }}
            models={props.options}
            hasError={props.hasError}
        />
    );
};
