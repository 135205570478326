/* @flow */

type StringOrNumberInput = {
    selected: string,
    data: string | string[],
};

export function serializeStringOrNumber({selected, data}: StringOrNumberInput) {
    let filterValue = data;

    if (selected === 'any') {
        filterValue = '*';
    } else if (selected === 'null') {
        filterValue = '-';
    } else if (selected === 'Greater than' && typeof data === 'string') {
        filterValue = '>'.concat(data);
    } else if (selected === 'Less than' && typeof data === 'string') {
        filterValue = '<'.concat(data);
    } else if (selected === 'Equals' && typeof data === 'string') {
        filterValue = `"${data}"`;
    } else if (selected === 'Between' && Array.isArray(data)) {
        filterValue = data.join(' TO ');
    }

    return filterValue;
}
