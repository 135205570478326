/* @flow */

import type {NutshellState} from '../store';

export const getEntityListState = (state: NutshellState) => state.entityList;

export const getIsFilterDrawerOpen = (state: NutshellState) =>
    getEntityListState(state).isFilterDrawerOpen;

const getPersistedParams = (state: NutshellState) => getEntityListState(state).persistedParams;

export const getPersistedLeadsParams = (state: NutshellState) => getPersistedParams(state).leads;
export const getPersistedAccountsParams = (state: NutshellState) =>
    getPersistedParams(state).accounts;
export const getPersistedContactsParams = (state: NutshellState) =>
    getPersistedParams(state).contacts;
