/* @flow */

import * as React from 'react';

import {CheckIcon, TimesIcon, EmailIcon, PhoneIcon, LinkedInIcon} from '../icon';

import './contact-method-statuses.css';

type Props = {
    hasEmail?: boolean,
    hasPhone?: boolean,
    hasLinkedinUrl?: boolean,
};

export function ContactMethodStatuses(props: Props) {
    return (
        <div styleName='container'>
            <div
                styleName={!props.hasEmail && 'missing'}
                data-tooltip-place='top-end'
                data-tip={props.hasEmail ? 'Add to view email address' : 'No email address'}
            >
                {getStatusIcon(props.hasEmail)}
                <EmailIcon size={13} wrapWithDiv={false} />
            </div>
            <div
                styleName={!props.hasPhone && 'missing'}
                data-tooltip-place='top-end'
                data-tip={props.hasPhone ? 'Add to view phone number' : 'No phone number'}
            >
                {getStatusIcon(props.hasPhone)}
                <PhoneIcon size={15} wrapWithDiv={false} />
            </div>
            <div
                styleName={!props.hasLinkedinUrl && 'missing'}
                data-tooltip-place='top-end'
                data-tip={
                    props.hasLinkedinUrl ? 'Add to view LinkedIn profile' : 'No LinkedIn profile'
                }
            >
                {getStatusIcon(props.hasLinkedinUrl)}
                <LinkedInIcon size={15} wrapWithDiv={false} />
            </div>
        </div>
    );
}

const getStatusIcon = (hasMethod: ?boolean) =>
    hasMethod ? (
        <CheckIcon size={11} wrapWithDiv={false} />
    ) : (
        <TimesIcon size={12} wrapWithDiv={false} />
    );
