/* @flow */

import * as React from 'react';

import {MergeFieldsButton, type MergeField} from '../merge-fields';
import type {Props as TextFieldProps} from './textfield';
import {TextField} from './textfield';
import {CharacterLimit} from './character-limit';

import './textfield-with-character-limit.css';

type Props = {
    ...TextFieldProps,
    id?: string,
    limit: number,
    isDisabled?: boolean,
    mergeFieldOptions?: MergeField[],
};

export function TextfieldWithCharacterLimit(props: Props) {
    const [isFocused, setIsFocused] = React.useState(false);
    const {mergeFieldOptions, limit, isDisabled, onChange, value, ...textFieldProps} = props;

    const handleAddMergeField = (mergeFieldValue: string, mergeFieldFallbackText: ?string) => {
        if (onChange) {
            const mergeFieldToInsert = mergeFieldFallbackText
                ? `{${mergeFieldValue}|"${mergeFieldFallbackText}"}`
                : `{${mergeFieldValue}}`;

            onChange(`${value}${mergeFieldToInsert}`);
        }
    };

    return (
        <div styleName='textfield-with-character-limit'>
            <TextField
                multiline={true}
                noBorder={true}
                disabled={isDisabled}
                value={value}
                onChange={onChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                {...textFieldProps}
            />
            {/* If there are merge field options, always show footer (unless disabled) */}
            {(isFocused || mergeFieldOptions) && !isDisabled ? (
                <div styleName='character-limit'>
                    <CharacterLimit limit={limit} length={value.length} />
                    {mergeFieldOptions && (
                        <MergeFieldsButton
                            onAddMergeField={handleAddMergeField}
                            options={mergeFieldOptions}
                        />
                    )}
                </div>
            ) : (
                /* Prevents height jumping when showing character limit */
                <div styleName={isDisabled ? 'character-limit--disabled' : 'character-limit'}>
                    &nbsp;
                </div>
            )}
        </div>
    );
}
