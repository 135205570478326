import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SidebarIconLogout from './sidebar-icon-logout.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SidebarIconLogout {...validProps} />;
}
SvgComponent.iconName = 'sidebarIconLogout';

export const SidebarIconLogoutIcon = svgIconWrapper(SvgComponent);
