/* @flow */

import * as React from 'react';
import moment from 'moment';
import type Moment from 'moment';

import {Calendar} from './calendar';
import {WEEK, MONTH} from './calendar-constants';
import type {DayDisplayFormat} from './day-of-week-bar';

type State = {
    momentDisplayDate: Moment,
};

type Props = {
    momentDisplayDate: Moment,
    displayMode?: WEEK | MONTH,
    onDateClick: (any) => void,
    headerDateFormat?: string,
    dayOfWeekFormat?: DayDisplayFormat,
    isTodayHighlighted?: boolean,
    showAdjacentMonthDates?: boolean,
    minDate?: Object,
    maxDate?: Object,
    width?: number,
    height?: number,
    quickViews?: Object,
    hasLeftControl?: boolean,
    hasRightControl?: boolean,
    isLightMode?: boolean,
    hasYearPicker?: boolean,
    changingMonthUpdatesSelectedDate?: boolean,
    excludePreviousMonths?: boolean,
};

// Higher Order Component that renders a standalone calendar and internally
// manages its display-state.
export class CalendarWrapper extends React.Component<Props, State> {
    static defaultProps = {
        showAdjacentMonthDates: true,
        hasYearPicker: true,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            momentDisplayDate: this.props.momentDisplayDate,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.momentDisplayDate.unix() !== this.props.momentDisplayDate.unix()) {
            this.setState({momentDisplayDate: nextProps.momentDisplayDate});
        }
    }

    render() {
        const selectedDate = this.props.changingMonthUpdatesSelectedDate
            ? this.state.momentDisplayDate
            : this.props.momentDisplayDate;

        const hasLeftControl =
            this.props.excludePreviousMonths &&
            moment(this.state.momentDisplayDate).isSame(new Date(), 'month')
                ? false
                : this.props.hasLeftControl;

        return (
            <Calendar
                momentDisplayDate={this.state.momentDisplayDate}
                displayMode={this.props.displayMode}
                selectedDates={[selectedDate]}
                width={this.props.width}
                height={this.props.height}
                // CalendarHeaderProps
                onLeftControlClick={this.handleLeftControlClick}
                onRightControlClick={this.handleRightControlClick}
                onYearChange={this.handleYearChange}
                hasLeftControl={hasLeftControl}
                hasRightControl={this.props.hasRightControl}
                headerDateFormat={this.props.headerDateFormat}
                isLightMode={this.props.isLightMode}
                hasYearPicker={this.props.hasYearPicker}
                // DayOfWeekBar props
                dayOfWeekFormat={this.props.dayOfWeekFormat}
                // CalendarDates props
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
                isTodayHighlighted={this.props.isTodayHighlighted}
                showAdjacentMonthDates={this.props.showAdjacentMonthDates}
                quickViews={this.props.quickViews}
                hasQuickViews={Boolean(this.props.quickViews)}
                onDateClick={this.handleDateClick}
            />
        );
    }

    handleLeftControlClick = () => {
        if (this.props.displayMode === WEEK) {
            this.updateDisplayDateState(this.state.momentDisplayDate.clone().subtract(1, 'weeks'));
        } else {
            this.updateDisplayDateState(this.state.momentDisplayDate.clone().subtract(1, 'months'));
        }
    };

    handleRightControlClick = () => {
        if (this.props.displayMode === WEEK) {
            this.updateDisplayDateState(this.state.momentDisplayDate.clone().add(1, 'weeks'));
        } else {
            this.updateDisplayDateState(this.state.momentDisplayDate.clone().add(1, 'months'));
        }
    };

    handleYearChange = (newDisplayDate: Moment) => {
        this.updateDisplayDateState(newDisplayDate);
    };

    updateDisplayDateState = (newDisplayDate: Moment) => {
        this.setState({momentDisplayDate: newDisplayDate});

        if (this.props.changingMonthUpdatesSelectedDate) {
            this.props.onDateClick(newDisplayDate);
        }
    };

    handleDateClick = (momentDate: Moment) => {
        this.setState({momentDisplayDate: momentDate});
        this.props.onDateClick(momentDate);
    };
}
