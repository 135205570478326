/* @flow */

import * as React from 'react';

export function Reporting() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 268 296'>
            <g clipPath='url(#a)'>
                <path
                    fill='#0C0F33'
                    d='M12.188 10.594a6.078 6.078 0 0 1-1.172 3.595l3.709 3.711a.939.939 0 0 1-1.327 1.328l-3.71-3.712a6.059 6.059 0 0 1-3.594 1.171A6.093 6.093 0 0 1 0 10.595 6.093 6.093 0 0 1 6.094 4.5a6.093 6.093 0 0 1 6.093 6.094Z'
                />
            </g>
            <path
                fill='#3E415D'
                d='M239 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H35a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h204Z'
            />
            <g clipPath='url(#b)'>
                <path
                    fill='#0C0F33'
                    d='M7.5 55.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Zm.61-11.086v.416c.284.035.568.114.849.193.056.015.108.03.164.047a.634.634 0 0 1-.337 1.222c-.044-.012-.09-.026-.138-.038a7.618 7.618 0 0 0-.618-.155 2.134 2.134 0 0 0-1.195.117c-.322.14-.589.48-.223.715.287.184.639.278.973.369.07.017.138.038.205.056.457.128 1.04.295 1.477.594.568.39.835 1.023.709 1.702-.12.657-.578 1.087-1.125 1.31a2.752 2.752 0 0 1-.739.182v.445a.634.634 0 0 1-1.265 0v-.51c-.425-.097-.841-.231-1.254-.366a.632.632 0 1 1 .398-1.201c.073.023.147.05.22.073.331.111.67.225 1.01.281.499.074.897.03 1.158-.076.351-.14.518-.56.173-.794-.296-.202-.662-.302-1.011-.395a5.103 5.103 0 0 1-.2-.056c-.442-.126-.995-.281-1.411-.548-.572-.366-.862-.975-.739-1.652.118-.639.616-1.064 1.143-1.292.161-.07.334-.126.513-.167v-.472a.634.634 0 0 1 1.265 0H8.11Z'
                />
            </g>
            <path
                fill='#3E415D'
                d='M219 36a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H35a4 4 0 0 1-4-4V40a4 4 0 0 1 4-4h184Z'
            />
            <g fill='#0C0F33' clipPath='url(#c)'>
                <path d='m10.37 81.261-4.7 2.33-.834-1.682 4.702-2.33a.939.939 0 0 1 .833 1.682ZM10.37 86.706l-4.7-2.33-.834 1.681 4.702 2.33a.939.939 0 0 0 .833-1.681Z' />
                <path d='M15 79.588a3.088 3.088 0 1 1-6.176 0 3.088 3.088 0 0 1 6.176 0ZM15 88.412a3.088 3.088 0 1 1-6.176 0 3.088 3.088 0 0 1 6.176 0ZM6.176 84A3.088 3.088 0 1 1 0 84a3.088 3.088 0 0 1 6.176 0Z' />
            </g>
            <path
                fill='#3E415D'
                d='M148 72a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H35a4 4 0 0 1-4-4V76a4 4 0 0 1 4-4h113Z'
            />
            <g clipPath='url(#d)'>
                <path
                    fill='#0C0F33'
                    d='M0 114.375c0-1.034.84-1.875 1.875-1.875h11.25c1.034 0 1.875.841 1.875 1.875v8.437c0 1.035-.84 1.876-1.875 1.876H9.062l-3.624 2.718a.472.472 0 0 1-.493.044.461.461 0 0 1-.258-.419v-2.343H1.876A1.877 1.877 0 0 1 0 122.812v-8.437Zm8.086 1.172a.588.588 0 0 0-.586-.586.588.588 0 0 0-.586.586v.41a2.27 2.27 0 0 0-.65.249c-.408.243-.76.668-.756 1.286.003.595.351.97.723 1.193.323.193.724.316 1.043.41l.05.014c.37.112.639.2.82.314.15.094.17.158.173.24.003.147-.052.234-.172.308-.147.091-.378.146-.627.137-.326-.011-.63-.114-1.029-.249l-.21-.07a.588.588 0 0 0-.742.369.587.587 0 0 0 .37.741c.055.018.116.038.178.062.243.085.524.182.826.246v.434c0 .322.264.586.586.586a.588.588 0 0 0 .586-.586v-.405a2.1 2.1 0 0 0 .68-.263c.419-.261.735-.706.726-1.319-.009-.594-.343-.978-.72-1.218-.337-.211-.76-.34-1.087-.44l-.02-.006c-.376-.114-.642-.196-.83-.307-.152-.091-.155-.144-.155-.197 0-.108.04-.19.181-.272.159-.094.399-.15.63-.147.282.003.592.065.914.153a.586.586 0 1 0 .302-1.134c-.19-.05-.401-.1-.618-.138v-.401Z'
                />
            </g>
            <path
                fill='#3E415D'
                d='M117 108a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H35a4 4 0 0 1-4-4v-16a4 4 0 0 1 4-4h82Z'
            />
            <path
                fill='#0C0F33'
                d='M6.45 171.864H3.365v4.586l-2.476-1.833c.041-.468.282-.902.664-1.184l.572-.424v-1.145c0-.685.555-1.241 1.24-1.241h1.98l1.29-.954a1.452 1.452 0 0 1 1.732-.002l1.29.956h1.98c.686 0 1.241.556 1.241 1.241v1.145l.572.424c.382.282.623.716.664 1.184l-2.476 1.833v-4.586H6.45Zm-5.567 9.099v-5.322l5.625 4.167a1.66 1.66 0 0 0 1.985 0l5.625-4.167v5.322c0 .913-.742 1.655-1.654 1.655H2.537a1.656 1.656 0 0 1-1.654-1.655Zm4.55-7.445h4.135a.415.415 0 0 1 0 .828H5.432a.415.415 0 0 1 0-.828Zm0 1.655h4.135c.228 0 .414.186.414.413a.415.415 0 0 1-.414.414H5.432a.415.415 0 0 1-.413-.414c0-.227.186-.413.413-.413Z'
            />
            <path
                fill='#33C252'
                d='M264 164a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H35a4 4 0 0 1-4-4v-16a4 4 0 0 1 4-4h229Z'
            />
            <g clipPath='url(#e)'>
                <g clipPath='url(#f)'>
                    <path
                        fill='#0C0F33'
                        d='M7.5 219.5A7.5 7.5 0 0 0 15 212a7.5 7.5 0 0 0-7.5-7.5A7.5 7.5 0 0 0 0 212a7.5 7.5 0 0 0 7.5 7.5Zm-3.75-3.75 1.875-5.625 5.625-1.875-1.875 5.625-5.625 1.875ZM8.438 212a.937.937 0 1 0-1.876 0 .937.937 0 1 0 1.875 0Z'
                    />
                </g>
            </g>
            <path
                fill='#33C252'
                d='M178 200a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H35a4 4 0 0 1-4-4v-16a4 4 0 0 1 4-4h143Z'
            />
            <path
                fill='#0C0F33'
                d='M0 243.714C0 242.769.84 242 1.875 242h11.25c1.034 0 1.875.769 1.875 1.714v8.572c0 .945-.84 1.714-1.875 1.714H1.875C.841 254 0 253.231 0 252.286v-8.572Zm1.875.857c0 .475.419.858.938.858.518 0 .937-.383.937-.858 0-.474-.419-.857-.938-.857-.518 0-.937.383-.937.857Zm11.25 0c0-.356-.313-.642-.703-.642H5.39c-.39 0-.704.286-.704.642 0 .357.314.643.704.643h7.03c.39 0 .704-.286.704-.643Z'
            />
            <path
                fill='#33C252'
                d='M90 236a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H35a4 4 0 0 1-4-4v-16a4 4 0 0 1 4-4h55Z'
            />
            <g clipPath='url(#g)'>
                <path
                    fill='#0C0F33'
                    d='M4.952 276.707a.703.703 0 0 0-.993.993l1.61 1.611H2.346A2.347 2.347 0 0 0 0 281.657v7.499a2.343 2.343 0 0 0 2.344 2.344h10.311a2.343 2.343 0 0 0 2.343-2.344v-7.499a2.342 2.342 0 0 0-2.343-2.343H9.431l1.61-1.611a.704.704 0 0 0-.993-.994L7.5 279.258l-2.548-2.551Zm8.876 6.591a.702.702 0 0 1-.703.703.702.702 0 0 1-.703-.703c0-.39.313-.703.703-.703.39 0 .703.313.703.703Zm-.703 3.046a.701.701 0 0 1-.703-.703c0-.389.313-.703.703-.703.39 0 .703.314.703.703 0 .39-.314.703-.703.703Zm-11.25-3.281c0-1.034.842-1.874 1.876-1.874h5.624c1.034 0 1.875.84 1.875 1.874v4.687c0 1.034-.84 1.875-1.875 1.875H3.751a1.877 1.877 0 0 1-1.875-1.875v-4.687Z'
                />
            </g>
            <path
                fill='#33C252'
                d='M155 272a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H35a4 4 0 0 1-4-4v-16a4 4 0 0 1 4-4h120Z'
            />
            <defs>
                <clipPath id='a'>
                    <path fill='#fff' d='M0 4.5h15v15H0z' />
                </clipPath>
                <clipPath id='b'>
                    <path fill='#fff' d='M0 40.5h15v15H0z' />
                </clipPath>
                <clipPath id='c'>
                    <path fill='#fff' d='M0 76.5h15v15H0z' />
                </clipPath>
                <clipPath id='d'>
                    <path fill='#fff' d='M0 112.5h15v15H0z' />
                </clipPath>
                <clipPath id='e'>
                    <path fill='#fff' d='M0 204.5h15v15H0z' />
                </clipPath>
                <clipPath id='f'>
                    <path fill='#fff' d='M0 204.5h15v15H0z' />
                </clipPath>
                <clipPath id='g'>
                    <path fill='#fff' d='M0 276.5h15v15H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
