/**
 * Check if a date is between two other dates (inclusive)
 * (necessary until we upgrade moment to 2.13.0+)
 * @param  {[type]}  targetDate      Moment date object to check
 * @param  {[type]}  startDate       Moment date object at start of range
 * @param  {[type]}  endDate         Moment date object at end of range
 * @return {Boolean}                 Is the target date between the two other dates?
 */
export function isBetween(targetDate, startDate, endDate) {
    return !targetDate.isBefore(startDate, 'day') && !targetDate.isAfter(endDate, 'day');
}
