import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ColumnPicker from './icon-column-picker.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ColumnPicker {...validProps} />;
}
SvgComponent.iconName = 'columnPicker';

export const ColumnPickerIcon = svgIconWrapper(SvgComponent);
