/* @flow */

import * as React from 'react';

import {FieldRow} from './field-row';

import './view-submission-data.css';

export type SubmittedField = {
    id: string,
    label: string,
    isRequired: boolean,
    value: ?(string | string[] | Object[]),
};

type Props = {
    submittedFields: SubmittedField[],
};

export function ViewSubmissionData(props: Props) {
    const {submittedFields} = props;

    return (
        <dl styleName='view-submission-data'>
            {submittedFields.map((field) => {
                // Render a single field containing subfields as a list of multiple individual fields
                if (
                    Array.isArray(field.value) &&
                    field.value[0] &&
                    typeof field.value[0] === 'object'
                ) {
                    return field.value.map((subfield: Object) => (
                        <FieldRow
                            key={field.id + subfield.name}
                            label={subfield.label}
                            isRequired={field.isRequired}
                            value={subfield.value}
                        />
                    ));
                }

                return (
                    <FieldRow
                        key={field.id}
                        label={field.label}
                        isRequired={field.isRequired}
                        value={field.value}
                    />
                );
            })}
        </dl>
    );
}
