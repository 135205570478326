/* @flow */

import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import classnames from 'classnames';

import './icon-button.css';

type Props = {
    // TODO: Make this work, maybe needs newer than flow 0.53?
    // ...HTMLButtonElement,
    icon: React.ComponentType<*>,
    text?: string | number,
    onClick: (e: SyntheticEvent<*>) => void,
    getButtonRef?: any,
    variant?: 'primary' | 'danger',
    noHoverBackground?: boolean,
    tooltipText?: string,
    isSmall?: boolean,
    isDisabled?: boolean,
};

export const IconButton = (props: Props) => {
    React.useEffect(() => {
        ReactTooltip.rebuild();

        return () => ReactTooltip.hide();
    });

    const {
        icon: Icon,
        variant,
        noHoverBackground,
        getButtonRef,
        tooltipText,
        isSmall,
        isDisabled,
        ...htmlButtonProps
    } = props;

    const buttonStyles = classnames('icon-button', {'icon-button--small': isSmall}, variant, {
        'no-hover-bg': noHoverBackground,
        disabled: isDisabled, // done this way to allow hover text but style as disabled
    });

    return (
        <button
            type='button'
            styleName={buttonStyles}
            onClick={props.onClick}
            {...htmlButtonProps}
            ref={getButtonRef}
            data-tip={tooltipText}
        >
            <Icon wrapWithDiv={false} />
            {props.text ? <span styleName='button-text'>{props.text}</span> : undefined}
        </button>
    );
};
