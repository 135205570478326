/* @flow */

import * as React from 'react';

import {type SpecialState} from './list-view';

export function renderSpecialState(specialState: SpecialState) {
    return specialState.centerVertically ? (
        <div className='flex align-center justify-center full-height'>{specialState.component}</div>
    ) : (
        specialState.component
    );
}
