import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ArrowUpDown from './icon-arrow-up-down.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ArrowUpDown {...validProps} />;
}
SvgComponent.iconName = 'arrowUpDownIcon';

export const ArrowUpDownIcon = svgIconWrapper(SvgComponent);
