import PropTypes from 'prop-types';
import * as React from 'react';
import _ from 'underscore';
import {portal} from 'shells/portal';

import getClasses from '../mixins/get-classes';

const STYLES = {
    position: 'fixed',
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    opacity: 0,
    animation: 'fadeIn 500ms ease-in 0s forwards',
};

export class ComponentOverlayMaskComponent extends React.Component {
    static propTypes = {
        isClosing: PropTypes.bool,
        node: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
        zIndex: PropTypes.number,
    };

    static defaultProps = {
        isClosing: false,
        zIndex: 999,
    };

    getClasses = getClasses;

    UNSAFE_componentWillMount() {
        $(window)
            .on('resize', this.handleWindowResize)
            .on('scroll', this.handleWindowScroll);
    }

    componentWillUnmount() {
        $(window)
            .off('resize', this.handleWindowResize)
            .off('scroll', this.handleWindowScroll);
    }

    handleWindowResize = _.debounce(() => this.forceUpdate(), 500);
    handleWindowScroll = _.debounce(() => this.forceUpdate(), 500);

    render() {
        const {node} = this.props;

        if (!node || node.length === 0) return null;

        if (_.isArray(node) && node.length) {
            const masks = node.map((n, key) => this.renderMask(n, key));

            return <div>{masks}</div>;
        }

        // HTMLCollections
        if (!_.isArray(node) && node.length) {
            const masks = Object.keys(node).map((key) => {
                return this.renderMask(node[key], key);
            });

            return <div>{masks}</div>;
        }

        return this.renderMask(node);
    }

    renderMask = (node, key) => {
        const {height, width, top, left} = node.getBoundingClientRect();

        const positionStyles = {
            height: `${height}px`,
            width: `${width}px`,
            top: `${top}px`,
            left: `${left}px`,
            zIndex: this.props.zIndex,
        };

        let styles = {...STYLES, ...positionStyles};

        if (this.props.isClosing) {
            styles = {...styles, animation: 'fadeOut 500ms ease-in 0s forwards'};
            this.enableStateSelected(node);
        } else {
            // Disable selected states since they like to resize/move things
            this.disableStateSelected(node);
        }

        if (!_.isUndefined(key)) {
            return (
                <div
                    key={key}
                    className={this.getClasses('ui-component-overlay-mask')}
                    style={styles}
                />
            );
        }

        return <div className={this.getClasses('ui-component-overlay-mask')} style={styles} />;
    };

    enableStateSelected = (node) => {
        const disabledSelected = node.querySelector('.state-selected-disabled');
        if (disabledSelected) {
            disabledSelected.classList.add('state-selected');
            disabledSelected.classList.remove('state-selected-disabled');
        }
    };

    disableStateSelected = (node) => {
        const selected = node.querySelector('.state-selected');
        if (selected) {
            selected.classList.add('state-selected-disabled');
            selected.classList.remove('state-selected');
        }
    };
}

export const ComponentOverlayMask = portal(ComponentOverlayMaskComponent);
