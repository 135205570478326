/* @flow */

import {type OutputSelector} from 'reselect';
import type {Team} from '../types';
import {Collections, type CollectionState} from '../collections';

type TeamsModuleState = {teams: CollectionState<Team>};

/*
 * Given the entire redux state, pull out just the piece of state for the teams reducer.
 */
const getTeamsState = (state: TeamsModuleState) => state.teams;

/*
 * Get an array of all teams models
 */
export const getAll: OutputSelector<*, *, Team[]> = Collections.makeGetModels(getTeamsState);

/*
 * Get the `byId` portion of the teams state
 */
export const getById = (state: TeamsModuleState) => {
    const teamsState = getTeamsState(state);

    return Collections.getById(teamsState);
};
