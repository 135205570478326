import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import LeadLost from './icon-lead-lost.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <LeadLost {...validProps} />;
}
SvgComponent.iconName = 'leadLost';

export const LeadLostIcon = svgIconWrapper(SvgComponent);
