/* @flow */

import {Observable} from 'rxjs/Observable';
import type {ActionsObservable} from 'redux-observable';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/of';

import type {TasksApiResponse} from '../types';

import * as apiMethods from './tasks-api';
import {
    ActionTypes,
    resolveFetchTasksForContact,
    failFetchTasksForContact,
    resolveFetchById,
    failFetchById,
    resolveCreateTask,
    failCreateTask,
    resolveRequestCompleteTask,
    failRequestCompleteTask,
    resolveRequestUncompleteTask,
    failRequestUncompleteTask,
    type TasksAction,
    type FetchTasksForContactRequestAction,
    type FetchByIdRequestAction,
    type CompleteTaskRequestAction,
    type UncompleteTaskRequestAction,
    type CreateTaskRequestAction,
} from './tasks-actions';

/*
 * These functions are epic factories, so that we can inject the method doing an actual `fetch()`
 * call, because some clients (gmail extension) need to provide their own methods.
 * By default, if no argument is provided, we'll use the standard api methods in this directory.
 */

export const makeFetchTasksForContactEpic = (
    // $FlowFixMe upgrading Flow to v0.92.1
    fetchTasksByRelatedId = apiMethods.fetchTasksByRelatedId
) => (action$: ActionsObservable<TasksAction>) =>
    action$
        .ofType(ActionTypes.TASKS_FETCH_FOR_CONTACT_REQUEST)
        .switchMap((action: FetchTasksForContactRequestAction) => {
            const contactId = action.payload;

            return Observable.fromPromise(fetchTasksByRelatedId(contactId))
                .map((response: TasksApiResponse) =>
                    resolveFetchTasksForContact(response, contactId)
                )
                .catch((error) => Observable.of(failFetchTasksForContact(error)));
        });

// $FlowFixMe upgrading Flow to v0.92.1
export const makeFetchTaskByIdEpic = (fetch = apiMethods.fetch) => (
    action$: ActionsObservable<TasksAction>
) =>
    action$
        .ofType(ActionTypes.TASKS_FETCH_BY_ID_REQUEST)
        .switchMap((action: FetchByIdRequestAction) => {
            return Observable.fromPromise(fetch(action.payload))
                .map((response: TasksApiResponse) => resolveFetchById(response))
                .catch((error) => Observable.of(failFetchById(action.payload, error)));
        });

// $FlowFixMe upgrading Flow to v0.92.1
export const makeRequestCompleteTaskEpic = (requestApiComplete = apiMethods.requestApiComplete) => (
    action$: ActionsObservable<TasksAction>
) =>
    action$
        .ofType(ActionTypes.TASKS_COMPLETE_TASK_REQUEST)
        .switchMap((action: CompleteTaskRequestAction) => {
            return Observable.fromPromise(requestApiComplete(action.payload))
                .map((response: TasksApiResponse) => resolveRequestCompleteTask(response))
                .catch((error) => Observable.of(failRequestCompleteTask(error)));
        });

export const makeRequestUncompleteTaskEpic = (
    // $FlowFixMe upgrading Flow to v0.92.1
    requestApiUncomplete = apiMethods.requestApiUncomplete
) => (action$: ActionsObservable<TasksAction>) =>
    action$
        .ofType(ActionTypes.TASKS_UNCOMPLETE_TASK_REQUEST)
        .switchMap((action: UncompleteTaskRequestAction) => {
            return Observable.fromPromise(requestApiUncomplete(action.payload))
                .map((response: TasksApiResponse) => resolveRequestUncompleteTask(response))
                .catch((error) => Observable.of(failRequestUncompleteTask(error)));
        });

// $FlowFixMe upgrading Flow to v0.92.1
export const makeCreateTaskEpic = (create = apiMethods.create) => (
    action$: ActionsObservable<TasksAction>
) =>
    action$
        .ofType(ActionTypes.TASKS_CREATE_TASK_REQUEST)
        .mergeMap((action: CreateTaskRequestAction) => {
            const body = action.payload;

            return Observable.fromPromise(create(body))
                .map((response: TasksApiResponse) => resolveCreateTask(response))
                .catch((error) => Observable.of(failCreateTask(error)));
        });
