import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Copy from './icon-copy.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Copy {...validProps} />;
}
SvgComponent.iconName = 'copy';

export const CopyIcon = svgIconWrapper(SvgComponent);
