import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ProBadge from './pro-badge.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ProBadge {...validProps} />;
}
SvgComponent.iconName = 'proBadge';

export const ProBadgeIcon = svgIconWrapper(SvgComponent);

// We want this icon to be a non-square rectangle, so need to
// override default squaring that happens in our icons.
ProBadgeIcon.defaultProps = {
    height: null,
    wrapWithDiv: true,
};
