/* @flow */

import * as React from 'react';
import {Link, type LocationShape} from 'react-router-dom';

import './page-title-breadcrumbs.css';

export type Breadcrumb = {
    to: string | LocationShape,
    text: string,
};

type Props = {
    title: string,
    breadcrumbs: Breadcrumb[],
};

export function PageTitleBreadcrumbs(props: Props): React.Node {
    return (
        <div styleName='breadcrumbs'>
            {props.breadcrumbs.map((breadcrumb: Breadcrumb, index: number): React.Node => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <span styleName='breadcrumb' key={index}>
                        <Link to={breadcrumb.to}>{breadcrumb.text}</Link>
                        <span styleName='divider'>&gt;</span>
                    </span>
                );
            })}
            <span styleName='breadcrumb'>{props.title}</span>
        </div>
    );
}
