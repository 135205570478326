/* @flow */

import * as React from 'react';
import {Body} from '../typography';
import './success-message.css';

type Props = {
    as: string,
    children: React.Node,
};

SuccessMessage.defaultProps = {
    as: 'div',
};

export function SuccessMessage({children, as: Tag}: Props) {
    return (
        <Tag styleName='success'>
            <Body>{children}</Body>
        </Tag>
    );
}
