import {createAction} from 'redux-act';
import _ from 'underscore';
import moment from 'moment';

import {jsonToModelProp, jsonToModelPropArray} from 'nutshell-core';
import {api} from 'nutshell-core/api';

import {Session} from 'nutshell-core/session';
import {Tasks} from 'nutshell-core/tasks';
import {safelyParseError} from 'nutshell-core/utils';

import * as selectors from '../sidebar/todos/todos-selectors';

/*------------------------------------------------------------------------------
Action Creators
------------------------------------------------------------------------------*/

export const updateTodoFilter = createAction('DASHBOARD_RIGHT_PANE_TODO_FILTER_UPDATED');
export const updateTodoQueryString = createAction('DASHBOARD_RIGHT_PANE_TODO_QUERY_STRING_UPDATED');
export const updateTodoDisplayDate = createAction('DASHBOARD_RIGHT_PANE_TODO_DATE_UPDATED');

export const updateTodoCounts = createAction('DASHBOARD_RIGHT_PANE_TODO_COUNTS_UPDATED');
export const updateTodoPeriodSegmentSelected = createAction(
    'DASHBOARD_TODOS_PERIOD_SEGMENT_SELECTED'
);
export const updatePeriodSegmentWasManuallySelected = createAction(
    'DASHBOARD_TODOS_PERIOD_SEGMENT_WAS_MANUALLY_SELECTED'
);

export const requestTodosForDay = createAction('DASHBOARD_RIGHT_PANE_TODOS_DAY_REQUESTED');
export const updateTodosForDay = createAction('DASHBOARD_RIGHT_PANE_TODOS_DAY_UPDATED');
export const failTodosForDay = createAction('FETCH_DASHBOARD_RIGHT_PANE_TODOS_DAY_FAILED');

export const updateTodoIndices = createAction('DASHBOARD_RIGHT_PANE_TODO_INDICES_UPDATED');

export const requestTodoData = createAction('DASHBOARD_RIGHT_PANE_TODO_DATA_REQUESTED');
export const updateTodoData = createAction('DASHBOARD_RIGHT_PANE_TODO_DATA_UPDATED');
export const failTodoData = createAction('DASHBOARD_RIGHT_PANE_TODO_DATA_FAILED');

export const updateTodoDataById = createAction('DASHBOARD_TODO_COMPLETE_BY_ID_UPDATED');

export const clearTodoError = createAction('DASHBOARD_TODO_ERROR_CLEARED');
export const clearTodoErrorById = createAction('DASHBOARD_TODO_ERROR_BY_ID_CLEARED');
export const clearTodoListError = createAction('DASHBOARD_TODO_LIST_ERROR_CLEARED');

/*------------------------------------------------------------------------------
Async Action Creators
------------------------------------------------------------------------------*/

export function updateDueTime(dispatch, task, date) {
    const newDueTime = moment(new Date(date)).unix();
    const newTask = _.clone(task);
    newTask.dueTime = newDueTime;
    dispatch(updateTodoDataById(newTask));

    api.put(task.href, {
        data: {
            dueTime: newDueTime,
        },
    }).catch((err) => {
        dispatch(
            failTodoCompleteDataById({
                id: task.id,
                errorMessage: err.statusText,
            })
        );
        dispatch(updateTodoDataById(task));
    });
}

export const requestTodoCompleteData = createAction('DASHBOARD_TODO_COMPLETE_DATA_REQUESTED');
export const failTodoCompleteData = createAction('DASHBOARD_TODO_COMPLETE_DATA_FAILED');
export const requestTodoCompleteDataById = createAction('DASHBOARD_TODO_COMPLETE_BY_ID_REQUESTED');
export const failTodoCompleteDataById = createAction('DASHBOARD_TODO_COMPLETE_BY_ID_FAILED');

export function completeTask(task) {
    return function(dispatch) {
        dispatch(requestTodoCompleteDataById(task.id));

        Tasks.requestApiComplete(task)
            .then((json) => {
                const newTasks = json.tasks;
                if (newTasks.length) {
                    const newTask = jsonToModelProp(json.tasks[0].id, json);
                    dispatch(updateTodoDataById(newTask));
                }
            })
            .catch((err) => {
                dispatch(failTodoCompleteDataById({id: task.id, errorMessage: err.statusText}));
            });
    };
}

export function completeStep(step) {
    return function(dispatch) {
        dispatch(requestTodoCompleteDataById(step.id));

        api.post(`${step.href}/complete`)
            .then((res) => res.json())
            .then((json) => {
                const newSteps = json.steps;
                if (newSteps.length) {
                    const newStep = jsonToModelProp(json.steps[0].id, json);
                    dispatch(updateTodoDataById(newStep));
                }
            })
            .catch((err) => {
                const safeErrorMessage = safelyParseError(err);
                dispatch(failTodoCompleteDataById({id: step.id, errorMessage: safeErrorMessage}));
            });
    };
}

export const requestTodoUncompleteDataById = createAction(
    'DASHBOARD_TODO_UNCOMPLETE_BY_ID_REQUESTED'
);
export const failTodoUncompleteDataById = createAction('DASHBOARD_TODO_UNCOMPLETE_BY_ID_FAILED');
export const requestTodoUncompleteData = createAction('DASHBOARD_TODO_UNCOMPLETE_REQUESTED');
export const failTodoUncompleteData = createAction('DASHBOARD_TODO_UNCOMPLETE_FAILED');
export function uncompleteTodo(dispatch, todo) {
    dispatch(requestTodoUncompleteDataById(todo.id));
    const patchArray = [
        {
            op: 'replace',
            path: `/${todo.type}/0/isCompleted`,
            value: false,
        },
    ];

    api.patch(todo.href, patchArray)
        .then((res) => res.json())
        .then((json) => {
            const newTodos = json[todo.type];
            if (newTodos.length) {
                const newTodo = jsonToModelProp(json[todo.type][0].id, json);
                dispatch(updateTodoDataById(newTodo));
            }
        })
        .catch((err) => {
            dispatch(failTodoUncompleteDataById({id: todo.id, errorMessage: err.statusText}));
        });
}

export const requestTodoDelayDataById = createAction('DASHBOARD_TODO_DELAY_BY_ID_REQUESTED');
export const failTodoDelayDataById = createAction('DASHBOARD_TODO_DELAY_BY_ID_FAILED');
export const requestTodoDelayData = createAction('DASHBOARD_TODO_DELAY_REQUESTED');
export const failTodoDelayData = createAction('DASHBOARD_TODO_DELAY_FAILED');
export function delayStep(dispatch, step, delayId) {
    requestTodoDelayDataById(step.id);

    api.post(`${step.href}/delay`, {
        delay: delayId,
    })
        .then((res) => res.json())
        .then((json) => {
            const newSteps = json[step.type];
            if (newSteps.length) {
                const newTodo = jsonToModelProp(json[step.type][0].id, json);
                dispatch(updateTodoDataById(newTodo));
            }
        })
        .catch((err) => {
            dispatch(failTodoDelayDataById({id: step.id, errorMessage: err.statusText}));
        });
}

export const requestTodoSkipDataById = createAction('DASHBOARD_TODO_SKIP_BY_ID_REQUESTED');
export const failTodoSkipDataById = createAction('DASHBOARD_TODO_SKIP_BY_ID_FAILED');
export const requestTodoSkipData = createAction('DASHBOARD_TODO_SKIP_REQUESTED');
export const failTodoSkipData = createAction('DASHBOARD_TODO_SKIP_FAILED');
export function skipStep(dispatch, step) {
    requestTodoSkipDataById(step.id);
    const patchArray = [
        {
            op: 'replace',
            path: '/steps/0/isSkipped',
            value: true,
        },
    ];

    api.patch(step.href, patchArray)
        .then((res) => res.json())
        .then((json) => {
            const newSteps = json[step.type];
            if (newSteps.length) {
                const newTodo = jsonToModelProp(json[step.type][0].id, json);
                dispatch(updateTodoDataById(newTodo));
            }
        })
        .catch((err) => {
            dispatch(failTodoSkipDataById({id: step.id, errorMessage: err.statusText}));
        });
}

const INITIAL_ROWS_TO_FETCH = 50;
export function fetchTodosByIndex(startIndex, endIndex) {
    return function(dispatch, getState) {
        const state = getState();

        const filterObj = {...selectors.getDeparamedFilters(state)};
        if (!filterObj.assignee) {
            const sessionUserId = Session.getSessionStateUserId(state);

            // If we don't have an assignee, and we don't have a session user id,
            // we're going to be populating the sidebar with _every_ todo.
            if (!sessionUserId) {
                return;
            } else {
                filterObj.assignee = Session.getSessionStateUserId(state);
            }
        }

        // Hack since our filters need to have Sentence case text, but our query needs lowercase
        //
        // This first case is for the "new-school" filters that are arrays of data objects
        if (filterObj && filterObj.type && Array.isArray(filterObj.type.data)) {
            filterObj.type = {
                anyAll: '',
                data: filterObj.type.data.map((item) => {
                    return {
                        data:
                            typeof item === 'string' // It shouldn't be, but anything is possible
                                ? item
                                : item.data.toLowerCase(),
                    };
                }),
            };
            // This second case is our "old-school" filter format, which was just strings
        } else if (filterObj && typeof filterObj.type === 'string') {
            filterObj.type = filterObj.type.toLowerCase();
        }

        const noIndicesSpecified =
            typeof startIndex === 'undefined' && typeof endIndex === 'undefined';
        const pageParam = noIndicesSpecified
            ? {limit: INITIAL_ROWS_TO_FETCH}
            : {startIndex, endIndex};
        const queryString = selectors.getQueryString(state);
        const queryObj = makeQueryObj({filterObj, page: pageParam, q: queryString});

        dispatch(requestTodosForDay());

        return api
            .get('todos', queryObj)
            .then((res) => res.json())
            .then(
                (json) => {
                    const modelJson = jsonToModelPropArray(json.todos, json, 'todos');
                    const counts = getTodoCounts(json);
                    const page = getPageInfo(json);
                    const indices = _.range(page.startIndex, page.endIndex + 1);
                    const ids = modelJson.map((todo) => todo.id);
                    const todoIdsByIndex = _.object(indices, ids);
                    dispatch(updateTodoCounts(counts));
                    dispatch(updateTodoIndices(todoIdsByIndex));
                    dispatch(updateTodosForDay(modelJson));
                },
                (err) => {
                    dispatch(failTodosForDay(err));
                }
            );
    };
}

export function refetchTodo(id) {
    const type = id.split('-')[1];

    return function(dispatch) {
        api.get(`${type}/${id}`)
            .then((res) => res.json())
            .then(
                (json) => {
                    const todo = jsonToModelProp(id, json, type);
                    dispatch(updateTodoDataById(todo));
                },
                () => {} // Ignore error for now
            );
    };
}

export function updateTodoFilterForm(filter, dispatch) {
    dispatch(updateTodoFilter(filter));
}

/*------------------------------------------------------------------------------
Helper functions
------------------------------------------------------------------------------*/

function makeQueryObj({filterObj, q, dueTimeQuery, limit, sparseFields, page}) {
    const filter = {...filterObj};
    if (dueTimeQuery) {
        filter.dueTime = dueTimeQuery;
    }

    return {
        filter: filter,
        fields: sparseFields ? {tasks: 'dueTime', steps: 'dueTime'} : null,
        limit: limit,
        page: page,
        q: q,
        onlyDue: filter.onlyDue === 'true' ? 1 : 0,
        onlyHotLeads: filter.onlyHotLeads === 'true' ? 1 : 0,
    };
}

function getTodoCounts(json) {
    return {
        total: json.meta.count,
        past: json.meta.facets.past,
        today: json.meta.facets.today,
        tomorrow: json.meta.facets.tomorrow,
        future: json.meta.facets.future,
    };
}

function getPageInfo(json) {
    return json.meta.page;
}
