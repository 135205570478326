/* @flow */

import * as React from 'react';

import type {NotifyMessage} from 'nutshell-core/types';

import {RemoveButton} from '../button';
import {LoadingIcon} from '../icon';

import './notify-processing.css';

type Props = {
    notify: NotifyMessage,
    onAcknowledgeExport: (notify: NotifyMessage) => any,
};

export function NotifyProcessing(props: Props) {
    const notify = props.notify;
    const completionPercentage = notify.percentComplete;
    const completionBackground = completionPercentage
        ? {width: `${completionPercentage}%`}
        : {display: 'none'};

    const handleAcknowledgeExport = () => {
        props.onAcknowledgeExport(props.notify);
    };

    return (
        <li styleName='notify-processing'>
            <RemoveButton size={10} onClick={handleAcknowledgeExport} />
            <div styleName='text-container'>
                <span styleName='completion-background' style={completionBackground} />
                <span styleName='notify-text'>Processing {notify.type}…</span>
                <div styleName='icon'>
                    <LoadingIcon size={15} fill='#fff' wrapWithDiv={false} />
                </div>
            </div>
        </li>
    );
}
