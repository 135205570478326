/* @flow */

import * as React from 'react';
import {CheckboxGroupContext} from './checkbox-group';
import {Checkbox} from './checkbox';

import './checkbox-for-group.css';

type Props = {
    disabled?: boolean,
    value: string,
    children: React.Node,
    hasError?: boolean,
    isLarge?: boolean,
};

export class CheckboxForGroup extends React.Component<Props> {
    static defaultProps = {
        isLarge: true,
    };

    render() {
        return (
            <CheckboxGroupContext.Consumer>
                {({selectedValues, onChange}) => {
                    const checked = selectedValues.includes(this.props.value);

                    return (
                        <div styleName='checkbox'>
                            <Checkbox
                                label={this.props.children}
                                isLarge={this.props.isLarge}
                                hasError={this.props.hasError}
                                type='checkbox'
                                role='checkbox'
                                checked={checked}
                                aria-checked={checked}
                                disabled={this.props.disabled}
                                onChange={(nowChecked: boolean) =>
                                    this.handleChange(nowChecked, selectedValues, onChange)
                                }
                            />
                        </div>
                    );
                }}
            </CheckboxGroupContext.Consumer>
        );
    }

    handleChange = (checked: boolean, previousValues: string[], onChange: Function) => {
        if (checked) {
            onChange(previousValues.concat([this.props.value]));
        } else {
            onChange(previousValues.filter((item) => item !== this.props.value));
        }
    };
}
