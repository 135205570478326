/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import type {TagType} from 'nutshell-graphql-types';

import {FormField} from 'shells/form';
import {TagSelectSingle} from './tag-select-single';

type Props = {
    ...FieldProps,
    fieldKey: string,
    title: string,
    isRequired: boolean,
    validate: Function,
    tagType: TagType,
};

export function TagSelectField(props: Props) {
    return (
        <FormField
            key={props.fieldKey}
            innerId={props.fieldKey}
            label={props.title}
            isRequired={props.isRequired}
            validate={props.validate}
        >
            <TagSelectSingle {...props} name={props.fieldKey} />
        </FormField>
    );
}
