/* @flow */

import * as React from 'react';

import {ButtonCard} from 'shells/card/button-card';

import './tiny-grey-card.css';

type Props = {
    icon: React.Element<*>,
    text: string,
    onClick: any,
    isDisabled?: boolean,
    onHover?: any,
    onBlur?: any,
};

export function TinyGreyCard(props: Props) {
    return (
        <ButtonCard onClick={props.onClick} isDisabled={props.isDisabled}>
            <div
                styleName={props.isDisabled ? 'card-content--disabled' : 'card-content'}
                onMouseEnter={(e) => {
                    props.onHover ? props.onHover(e) : undefined;
                }}
                onMouseLeave={() => {
                    props.onBlur ? props.onBlur() : undefined;
                }}
            >
                <div styleName='card-icon'>{props.icon}</div>
                <p styleName='card-text'>{props.text}</p>
            </div>
        </ButtonCard>
    );
}
