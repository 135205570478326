/* @flow */

import * as React from 'react';

import {ReactionBar, AddReactionButton} from '../../reaction-bar';
import type {Reaction, Reactions} from '../../reaction-bar';
import {
    type EmailEngagementProps,
    EmailEngagement,
    ZoomMeetingData,
    type ZoomMeetingDataProps,
    ThreadData,
    type ThreadDataProps,
} from './index';

import './timeline-entry-footer.css';

// EmailEngagementReactionBarProps
export type Props = {|
    // Email engagement props
    emailEngagement?: {...EmailEngagementProps},
    disableReactions?: boolean,
    zoomMeetingData?: ZoomMeetingDataProps,
    threadData?: ThreadDataProps,
|};

type OwnProps = {|
    ...Props,
    reactions?: Reactions,
    alwaysShowAddButton?: boolean,
    currentUserId?: string,
    onToggleReaction?: (reaction: Reaction) => any,
|};

export function TimelineEntryFooter(props: OwnProps) {
    const {
        emailEngagement: emailEngagementProps,
        zoomMeetingData: zoomMeetingDataProps,
        threadData: threadDataProps,
        alwaysShowAddButton,
        disableReactions,
        currentUserId,
        onToggleReaction,
        reactions,
    } = props;

    const hasEmailEngagement = Boolean(
        emailEngagementProps &&
            (emailEngagementProps.attachments.length ||
                emailEngagementProps.opens ||
                emailEngagementProps.clicks)
    );
    const hasZoomMeetingData = Boolean(
        zoomMeetingDataProps &&
            (zoomMeetingDataProps.recordingUrl || zoomMeetingDataProps.transcript)
    );
    const hasThreadData = Boolean(
        threadDataProps && (threadDataProps.messageCount || threadDataProps.replyCount)
    );

    const hasReactions = Boolean(reactions && Object.keys(reactions).length);
    const canRenderReactionBar = Boolean(
        !disableReactions && hasReactions && onToggleReaction && currentUserId
    );

    return hasEmailEngagement ||
        hasReactions ||
        hasZoomMeetingData ||
        hasThreadData ||
        alwaysShowAddButton ? (
        <div
            styleName='timeline-footer-bar'
            className={`flex ${
                hasEmailEngagement || hasZoomMeetingData || hasThreadData
                    ? 'justify-sb'
                    : 'justify-end'
            }`}
        >
            {hasEmailEngagement ? (
                <div styleName='left-container'>
                    <EmailEngagement {...emailEngagementProps} />
                </div>
            ) : (
                undefined
            )}
            {hasZoomMeetingData ? <ZoomMeetingData {...zoomMeetingDataProps} /> : undefined}
            {hasThreadData ? <ThreadData {...threadDataProps} /> : undefined}
            {canRenderReactionBar ? (
                <ReactionBar
                    // $FlowIgnore - hasReactions checks for this
                    reactions={reactions}
                    // $FlowIgnore - canRenderReactionBar checks for this
                    currentUserId={currentUserId}
                    // $FlowIgnore - canRenderReactionBar checks for this
                    onToggleReaction={onToggleReaction}
                />
            ) : (
                undefined
            )}
            {alwaysShowAddButton && !hasReactions && onToggleReaction ? (
                <AddReactionButton onSelectReaction={onToggleReaction} />
            ) : (
                undefined
            )}
        </div>
    ) : null;
}
