/* @flow */

import * as React from 'react';

import {File} from '../files';
import type {Attachment} from './types';

import './attachment-list.css';

type Props = {
    attachments: Attachment[],
};

export function AttachmentList(props: Props) {
    return (
        <div className='attachments-container'>
            {props.attachments.map((file) => (
                <div styleName='file' key={file.fileId}>
                    <File
                        id={file.fileId}
                        filename={file.name}
                        filetype={file.fileType}
                        href={file.href}
                        withDownloadIcon={false}
                        withNoBorder={true}
                    />
                </div>
            ))}
        </div>
    );
}
