import moment from 'moment';

/**
 * Check if a date is within the past week
 *
 * @param  {[type]}  targetDate        Moment date object to check
 * @param  {[type]}  number            Number of units to subtract from today
 * @param  {[type]}  unit              Unit of time to subtract from today
 * @return {Boolean}                   Is the target date within the past number of time units?
 */
export function isWithinPastTimespan(targetDate, number, unit) {
    const now = moment();

    return targetDate.isAfter(now.clone().subtract(number, unit));
}
