import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import HtmlImage from './icon-html-image.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <HtmlImage {...validProps} />;
}
SvgComponent.iconName = 'html-image';

export const HtmlImageIcon = svgIconWrapper(SvgComponent);
