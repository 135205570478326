import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import GoalActivityLogged from './icon-goal-activity-logged.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <GoalActivityLogged {...validProps} />;
}
SvgComponent.iconName = 'goal-activity-logged';

export const GoalActivityLoggedIcon = svgIconWrapper(SvgComponent);
