/* @flow */

import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';
import type {ReportRowData, TimeseriesReportData} from 'nutshell-core/types';
import type {LandingPagesStats} from '../../reports/report-summary';

import * as actions from './pages-actions';

type LandingPagesReportTableData = {
    rows: ?(ReportRowData[]),
    totals: ?ReportRowData,
    hasError: boolean,
};
type LandingPagesReportState = {
    timeseriesChart: {
        isLoading: boolean,
        isTimedOut: boolean,
        isErrored: boolean,
        chartData: ?TimeseriesReportData,
        prefix: string,
    },
    tableData: LandingPagesReportTableData,
};
const landingPagesReportDefaultState = {
    timeseriesChart: {
        isLoading: true,
        isTimedOut: false,
        isErrored: false,
        chartData: null,
        prefix: '$',
    },
    tableData: {rows: undefined, totals: undefined},
};

const defaultStat = {value: 0, formatted: '0', prefix: '$', suffix: ''};
const defaultPageSummaryStats: LandingPagesStats = {
    views: {mean: defaultStat, sum: defaultStat},
};

type SummaryStatsState = {
    isLoading: boolean,
    stats: LandingPagesStats,
};
const summaryStatsDefaultState = {
    isLoading: true,
    stats: defaultPageSummaryStats,
};

const landingPagesReportReducer = createReducer(
    {
        [actions.updateLandingPagesReportTableData]: (
            state,
            payload: LandingPagesReportTableData
        ) => {
            return {...state, tableData: payload};
        },
        // $FlowIgnore - this works correctly from redux-act
        [actions.requestLandingPagesReportData]: (state) => {
            const newChartState = {
                ...state.timeseriesChart,
                isLoading: true,
                isTimedOut: false,
                isErrored: false,
            };

            const newTableState = {
                ...state.tableData,
                isLoading: true,
                isTimedOut: false,
                isErrored: false,
            };

            return {...state, timeseriesChart: newChartState, tableData: newTableState};
        },
        [actions.updateLandingPagesReportChartData]: (state, {chartData}) => {
            const newChartState = {
                ...state.timeseriesChart,
                isLoading: false,
                isTimedOut: false,
                isErrored: false,
                chartData,
            };

            return {...state, timeseriesChart: newChartState};
        },
        [actions.failLandingPagesReportChartData]: (state, err) => {
            if (err) {
                if (err.status === 504 || err.status === 502) {
                    const newChartState = {
                        ...state.timeseriesChart,
                        chartData: null,
                        isLoading: false,
                        isTimedOut: true,
                        isErrored: false,
                    };

                    return {...state, timeseriesChart: newChartState};
                } else {
                    const newChartState = {
                        ...state.timeseriesChart,
                        chartData: null,
                        isLoading: false,
                        isTimedOut: false,
                        isErrored: true,
                    };

                    return {
                        ...state,
                        tableData: {...state.tableData, hasError: true},
                        timeseriesChart: newChartState,
                    };
                }
            }

            return state;
        },
    },
    landingPagesReportDefaultState
);

export const summaryStatsReducer = createReducer(
    {
        // $FlowIgnore - this works correctly from redux-act
        [actions.requestLandingPagesReportData]: (state) => {
            return {...state, isLoading: true};
        },
        [actions.updateLandingPagesSummaryStats]: (state, {stats}) => {
            return {...state, isLoading: false, stats: stats};
        },
    },
    summaryStatsDefaultState
);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const landingPagesReducer = combineReducers({
    landingPagesReport: landingPagesReportReducer,
    summaryStats: summaryStatsReducer,
});

export type LandingPagesState = {
    landingPagesReport: LandingPagesReportState,
    summaryStats: SummaryStatsState,
};
