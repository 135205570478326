/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import {OverlappingAvatars} from 'shells/overlapping-avatars/overlapping-avatars';
import type {Participant, Lead, TimelineEntryStatus} from 'shells/timeline/types';
import {TimelineEntryStatusBar, TruncatedTimelineEntryContent} from 'shells/timeline/entry/content';

import {TimelineEntryExpandedContentWrapper} from './timeline-entry-expanded-content-wrapper';

import './timeline-entry-content.css';

// TimelineEntryContentProps
export type Props = {|
    subject?: string | React.Node,
    subjectTitle?: string,
    // This will be passed in if used in a modal, not if in the main timeline
    htmlContent?: string,
    // Content passed in this prop is in a displayable format
    content?: ?(string | React.Node),
    // Content that is truncated in a custom way
    truncatedContent?: React.Node,
    // Content that needs to be placed in a rich-textarea to be displayed properly
    status?: TimelineEntryStatus,
    participants?: ?(Participant[]),
    relatedLeads?: Lead[],
    metadata?: React.Node,
    isExpanded?: boolean,
    maxHeight?: number,
    zoomTranscriptSummary?: React.Node,
|};

type OwnProps = {|
    ...Props,
    changeLogEntryId: string,
    onClickOpen?: (e: SyntheticEvent<*>) => void,
    actions?: React.Node,
|};

export function TimelineEntryContent(props: OwnProps) {
    const {
        changeLogEntryId,
        subject,
        subjectTitle,
        content,
        truncatedContent,
        status,
        participants,
        relatedLeads,
        metadata,
        onClickOpen,
        actions,
        isExpanded,
        htmlContent,
        maxHeight,
        zoomTranscriptSummary,
    } = props;

    const ref = React.useRef();
    const [width, setWidth] = React.useState<number>(0);

    React.useLayoutEffect(() => {
        if (ref.current) {
            setWidth(ref.current.clientWidth + 20);
        } else {
            setWidth(0);
        }
    }, [participants, actions, metadata]);

    const shouldCenterAlign = Boolean((subject && !status) || (!subject && status));
    const shouldDisplayRowReverse = !subject && !status && (participants || actions);

    return (
        <div
            onClick={onClickOpen}
            styleName={classnames({
                'timeline-entry-content': true,
                'timeline-entry-content--row-view': shouldDisplayRowReverse,
                'timeline-entry-content--expandable': onClickOpen,
            })}
        >
            {status || subject || participants || actions || metadata ? (
                <div
                    styleName='content-heading-container'
                    className={shouldCenterAlign ? 'align-center' : undefined}
                >
                    {status || subject ? (
                        <div style={{width: `calc(100% - ${width}px`}}>
                            {status ? (
                                <div styleName='status-bar-container'>
                                    <TimelineEntryStatusBar status={status} />
                                </div>
                            ) : (
                                undefined
                            )}
                            {subject ? (
                                <div
                                    styleName='subject'
                                    className={onClickOpen ? 'truncate' : undefined}
                                    title={subjectTitle || subject}
                                >
                                    {subject}
                                </div>
                            ) : (
                                undefined
                            )}
                        </div>
                    ) : (
                        undefined
                    )}
                    {participants || actions || metadata ? (
                        <div styleName='participant-actions-container' ref={ref}>
                            {participants ? (
                                <OverlappingAvatars
                                    avatars={participants}
                                    relatedLeads={relatedLeads}
                                    size={28}
                                    maxDisplayed={4}
                                    withTooltip={true}
                                />
                            ) : (
                                undefined
                            )}
                            {actions}
                            {metadata}
                        </div>
                    ) : (
                        undefined
                    )}
                </div>
            ) : (
                undefined
            )}
            {onClickOpen && !isExpanded ? (
                truncatedContent || <TruncatedTimelineEntryContent content={content} />
            ) : (
                <TimelineEntryExpandedContentWrapper
                    changeLogEntryId={changeLogEntryId}
                    content={content}
                    maxHeight={maxHeight}
                    htmlContent={htmlContent}
                />
            )}
            {zoomTranscriptSummary}
        </div>
    );
}
