/* @flow */

import * as React from 'react';

import moment from 'moment';

import {isBetween} from 'nutshell-core/date-time';

import {FormattedTimestamp} from './formatted-timestamp';
import {RelativeTimestamp} from './relative-timestamp';

type Props = {|
    timestamp: number,
    relativeWithinDays: number,
    withTooltip?: boolean,
|};

export function RelativeTimestampWithinDays(props: Props) {
    const today = moment();
    const timestamp = moment.unix(props.timestamp);
    const relativeToDate = moment(timestamp).add(props.relativeWithinDays, 'days');

    return isBetween(today, timestamp, relativeToDate) ? (
        <RelativeTimestamp
            className='inline-block'
            datetime={props.timestamp}
            data-tip={
                props.withTooltip
                    ? `${timestamp.format(FormattedTimestamp.formats.shortDayMonthYearWithAtTime)}`
                    : undefined
            }
        />
    ) : (
        <FormattedTimestamp
            className='inline-block'
            datetime={props.timestamp}
            format={FormattedTimestamp.formats.longMonthDayWithYear}
        />
    );
}
