/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import './checkbox-button.css';

type Props = {
    checked: boolean,
    onChange: (newCheckedState: boolean, e: SyntheticInputEvent<*>) => void,
    label: string,
    size: 'small' | 'normal',
    disabled?: boolean,
    getButtonRef?: (?HTMLElement) => void,
    noPadding?: boolean,
    icon?: React.ComponentType<*>,
};

/**
A stylized checkbox input that tries to mimic a standard "nutshell button" with the "secondary" styling.
Should only be used for boolean fields next to other filter capsules,
such as the graduated filters on the entity list.
 */
export class CheckboxButton extends React.PureComponent<Props> {
    static defaultProps = {
        size: 'normal',
    };

    render() {
        const Icon = this.props.icon;
        const styleName = classnames(
            this.props.checked ? 'checked' : 'unchecked',
            this.props.size,
            {
                disabled: this.props.disabled,
                'no-padding': this.props.noPadding,
            }
        );

        return (
            <label ref={this.props.getButtonRef} styleName={styleName}>
                <div className='flex align-center'>
                    <span styleName='checkbox-container'>
                        <input
                            checked={this.props.checked}
                            disabled={this.props.disabled}
                            type='checkbox'
                            onChange={this.handleChange}
                        />
                    </span>
                    <span styleName='label'>
                        <span>{this.props.label}</span>
                        {Icon ? <Icon size={14} alignCenter={true} /> : undefined}
                    </span>
                </div>
            </label>
        );
    }

    handleChange = (e: SyntheticInputEvent<*>) => {
        this.props.onChange(e.target.checked, e);
    };
}
