/* @flow */

import React from 'react';

import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';

type Props = {
    color?: string,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox='0 0 55 55' fill={props.color || colors.navy}>
            <path d='M12.6,45.3c2.1-0.6,4.1-1.5,5.9-2.7c0.4-0.2,0.2-2.2,0.2-2.2l2.8-0.2c0,0,0.5,0.3,0-2.8c0,0-2.4-0.7-2.6-5.6 c0,0-1.8,0.6-1.9-2.4c-0.1-2-1.6-3.8,0.6-5.2l-1.1-3.1c0,0-2.3-12.5,4.3-10.6c-2.8-3.3,15.7-6.6,16.9,4c0,0,0.9,5.7,0,9.6 c0,0,2.7-0.3,0.9,4.9c0,0-1,3.7-2.5,2.9c0,0,0.2,4.7-2.2,5.5c0,0,0.2,2.5,0.2,2.7l2.3,0.3c0,0-0.3,2.1,0.1,2.3 c1.8,1.2,3.8,2.1,5.9,2.7c1.6,0.4,3.1,0.9,4.6,1.4C57.7,36,57.6,18.6,46.8,7.9S18.6-2.6,7.9,8.2c-10.6,10.7-10.6,27.9,0,38.6 C9.4,46.2,11,45.7,12.6,45.3z' />
        </svg>
    );
}
SvgComponent.iconName = 'contacts-circle';

export const ContactsCircleIcon = svgIconWrapper(SvgComponent);
