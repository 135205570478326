/* @flow */

import * as React from 'react';

import {FooterBar} from 'shells/modal';
import {Button, SaveButton} from 'shells/button';
import {ButtonMover} from 'shells/button-mover';

import './default-footer.css';

type Props = {
    onCancel: () => void,
    isSaving: boolean,
    errorSaving?: boolean,
    buttonText?: string,
    savingText?: string,
};

export function DefaultFooter(props: Props) {
    return (
        <div styleName='default-footer' id='create-entity-footer'>
            <FooterBar
                left={
                    <ButtonMover size='big' direction='left'>
                        <Button variant='text' size='big' onClick={props.onCancel}>
                            Cancel
                        </Button>
                    </ButtonMover>
                }
                right={
                    <SaveButton
                        id='save-entity'
                        buttonText={props.buttonText}
                        savingText={props.savingText}
                        isSaving={props.isSaving}
                        errorSaving={props.errorSaving}
                    />
                }
            />
        </div>
    );
}
