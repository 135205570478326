/* @flow */

import {createReducer} from 'redux-act';
import {combineReducers} from 'redux';

import type {ListSchemaResponse} from 'nutshell-core/schema/schema-types';

import type {ConnectedFormsAction} from './types';
import * as actions from './connected-forms-actions';

type SchemaState = ?ListSchemaResponse;

export const defaultConnectedFormsState = {
    activeFieldId: null,
    connectedFormSchema: null,
    timeseriesChart: {
        isLoading: true,
        isTimedOut: false,
        isErrored: false,
        chartData: null,
        prefix: '$',
    },
};

const activeFieldReducer = (state = null, action) => {
    switch (action.type) {
        case 'ACTIVE_FIELD_ID_SET':
            return action.payload.activeFieldId;
        default:
            return state;
    }
};

const schemaReducer = (state: SchemaState = null, action: ConnectedFormsAction): SchemaState => {
    switch (action.type) {
        case 'CONNECTED_FORMS_SCHEMA_UPDATED':
            return action.payload;
        default:
            return state;
    }
};

export const reportReducer = createReducer(
    {
        // $FlowIgnore - this works correctly from redux-act
        [actions.requestReportData]: (state) => {
            const newChartState = {
                ...state.timeseriesChart,
                isLoading: true,
                isTimedOut: false,
                isErrored: false,
            };

            return {...state, timeseriesChart: newChartState};
        },
        [actions.updateReportChartData]: (state, {chartData}) => {
            const newChartState = {
                ...state.timeseriesChart,
                isLoading: false,
                isTimedOut: false,
                isErrored: false,
                chartData,
            };

            return {...state, timeseriesChart: newChartState};
        },
        [actions.failReportChartData]: (state, err) => {
            if (err) {
                if (err.status === 504 || err.status === 502) {
                    const newChartState = {
                        ...state.timeseriesChart,
                        chartData: null,
                        isLoading: false,
                        isTimedOut: true,
                        isErrored: false,
                    };

                    return {...state, timeseriesChart: newChartState};
                } else {
                    const newChartState = {
                        ...state.timeseriesChart,
                        chartData: null,
                        isLoading: false,
                        isTimedOut: false,
                        isErrored: true,
                    };

                    return {...state, timeseriesChart: newChartState};
                }
            }

            return state;
        },
    },
    defaultConnectedFormsState
);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const connectedFormsReducer = combineReducers({
    connectedFormsReport: reportReducer,
    connectedFormSchema: schemaReducer,
    activeFieldId: activeFieldReducer,
});
