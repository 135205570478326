import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Collapse from './icon-collapse.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Collapse {...validProps} />;
}
SvgComponent.iconName = 'collapse';

export const CollapseIcon = svgIconWrapper(SvgComponent);
