import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import HtmlBold from './icon-html-bold.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <HtmlBold {...validProps} />;
}
SvgComponent.iconName = 'html-bold';

export const HtmlBoldIcon = svgIconWrapper(SvgComponent);
