/* @flow */

// Note: We are following [MDN's strategy][mdn] for creating custom error types
// because our babel compiler doesn't seem to play well with ES2015 class
// definitions and asserting specific error objects within tests. To verify,
// replace the constructor and prototype definitions below with an ES2015 class
// definition and watch some tests fail.
//
// [mdn]: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Custom_Error_Types

// Error object used by clients - this is not complete.
export type JsonApiErrorObject = {
    name: string,
    message: string,
    status: string,
    stack: string,
};

export type HttpErrorObject = {
    httpResponse: Response,
    message: string,
    name: string,
    stack: string,
};

/**
 * A generic Error object for HTTP-layer errors.
 *
 * @param {Response} httpResponse - A fetch-API `Response` object for placing
 *     onto the error as the `httpResponse` property.
 * @param {string} [message] - An optional message string for the error object.
 * @return {void}
 */
export function HttpError(httpResponse: Response, message?: string) {
    this.name = 'HttpError';
    this.message = typeof message === 'string' ? message : 'HTTP Error';
    this.stack = new Error().stack;
    this.httpResponse = httpResponse;
}
HttpError.prototype = Object.create(Error.prototype);
HttpError.prototype.constructor = HttpError;

/**
 * A JSON-API-compliant Error-like object for use when the Nutshell REST API
 * gives us back an error. For the server-side error, see the REST API response
 * model in nutshellcrm/nutshell at symfony/src/Nutshell/RestBundle/Response/Model/Error.php.
 *
 * @param {Response} httpResponse - A fetch-API `Response` object for placing
 *     onto the error as the `httpResponse` property.
 * @param {object} jsonBody - The fully resolved HTTP response body in an
 *     application/json format that hopefully includes a top-level `errors` key
 *     which points to an array of JSON-API error resources.
 * @return {void}
 */
export function JsonApiError(httpResponse: Response, jsonBody: Object) {
    const defaultJsonError = {
        type: 'errors',
        detail: 'We apologize, but there was a Nutshell error. Please try again later.',
        href: 'http://nutshell.com',
        status: '400',
        title: 'Something went wrong',
    };
    this.jsonBody = jsonBody || {errors: [defaultJsonError]};
    this.jsonApiErrors = this.jsonBody.errors || [defaultJsonError];
    this.firstJsonApiError =
        this.jsonApiErrors.length > 0 ? this.jsonApiErrors[0] : defaultJsonError;

    this.name = this.firstJsonApiError.title;
    this.message = this.firstJsonApiError.detail;
    this.stack = new Error().stack;
    this.httpResponse = httpResponse;
}
JsonApiError.prototype = Object.create(HttpError.prototype);
JsonApiError.prototype.constructor = JsonApiError;

/**
 * An async function for use within a fetch-API promise chain that forwards
 * throw a `Response` objectif it has a happy-path HTTP status code, otherwise
 * throw an `Error` with any Nutshell REST API error payload.
 *
 * @param {Response} response A fetch-API Response object, see
 *     https://developer.mozilla.org/en-US/docs/Web/API/Response
 * @throws {HttpError} An error for generic HTTP-layer errors
 * @throws {JsonApiError} An error when the Reponse includes a Nutshell REST
 *     API error object, much like the JSON-API error object standard (see
 *     nutshellcrm/nutshell & symfony/src/Nutshell/RestBundle/Response/Model/Error.php).
 * @return {Response} A happy-path `Response` object
 */
export function httpErrorCheck(response: Response) {
    // $FlowFixMe upgrading Flow to v0.92.1
    return new Promise((resolve, reject) => {
        if (response.ok) {
            return resolve(response);
        } else {
            // A response can only be read once, so we'll clone it before reading, so we can
            // provide the copy to the newly-thrown errors.
            const httpResponse = response.clone();
            response
                .json()
                .then((json) => {
                    if (json.errors && json.errors.length > 0) {
                        reject(new JsonApiError(httpResponse, json));
                    } else if (json.error) {
                        // Some of our Zend Controllers return a singular 'error' key
                        reject(new HttpError(httpResponse, json.error));
                    } else if (json.isError && json.message) {
                        // Some of our Zend Controllers return an isError/message combo
                        reject(new HttpError(httpResponse, json.message));
                    } else {
                        reject(new HttpError(httpResponse, 'Unknown HTTP Error'));
                    }
                })
                .catch(() => {
                    reject(new HttpError(httpResponse, 'Unknown HTTP Error'));
                });
        }
    });
}
