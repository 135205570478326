/* @flow */

import {createAction} from 'redux-act';
import _ from 'underscore';
import type {Dispatch} from 'redux';

import {jsonToModelPropArray} from 'nutshell-core';
import {api} from 'nutshell-core/api';
import * as Schema from 'nutshell-core/schema';
import * as Leads from 'nutshell-core/leads';
import * as Accounts from 'nutshell-core/accounts';
import * as Contacts from 'nutshell-core/contacts';

import type {NutshellState} from '../../../store';
import {getDashes, getDashOrderById, getDashById} from '../dashboard-selectors';
import {getIsDashSpecialCard} from '../dashboard-constants';
import {fetchDashData} from './action-utils';

const $ = window.jQuery;

type StateGetter = () => NutshellState;

/*------------------------------------------------------------------------------
Action Creators

`createAction` actually creates action creators (but createActionCreator sounded
clunky).  So, these exports are action creators.  When called, they will return
a plain JavaScript object.  The action object will have a `type` key, equal to
the string that was passed to `createAction`.  So, for instance:

```
const action = toggleEditMode();
console.log(action); // -> { type: 'DASHBOARD_EDIT_MODE_TOGGLED' }
```

If an argument is given when calling an action creator, it will be put in a
`payload` property on the action:

```
const action = startDashDrag('quota');
console.log(action); // -> { type: 'DASH_DRAG_STARTED', payload: 'quota' }
```

The actions that these action creators return should be passed as the argument
to the dispatch method of the store, and they will then pass through the store's
reducer function.

------------------------------------------------------------------------------*/

export const addNewDash = createAction('DASHBOARD_ADD_NEW_DASH_SELECTED');
export const updateDashOrder = createAction('DASHBOARD_DASH_ORDER_UPDATED');
export const failDashOrder = createAction('DASHBOARD_DASH_ORDER_FAILED');
export const hoverDash = createAction('DASHBOARD_DASH_HOVERED');
export const saveLeftScrollPosition = createAction('DASHBOARD_LEFT_SCROLL_POSITION_CHANGED');
export const resizeRightPaneWidth = createAction('DASHBOARD_RIGHT_PANE_WIDTH_RESIZED');
export const changeRightPaneColumnCount = createAction('DASHBOARD_RIGHT_PANE_COLUMN_COUNT_CHANGED');
export const snapSidebarOpen = createAction('DASHBOARD_RIGHT_PANE_SNAPPED_OPEN');
export const unsnapSidebarOpen = createAction('DASHBOARD_RIGHT_PANE_UNSNAPPED_OPEN');
export const toggleConfigureDash = createAction('DASHBOARD_CONFIGURE_DASH_TOGGLED');
export const toggleDeleteDash = createAction('DASHBOARD_DELETE_DASH_TOGGLED');

// Dashboard right pane
export const changeRightPaneActiveView = createAction('DASHBOARD_RIGHT_PANE_ACTIVE_VIEW_CHANGED');

export const updateDashBaseData = createAction('DASHBOARD_DASH_BASE_DATA_UPDATED');

export const dismissDashComponentFailure = createAction(
    'DASHBOARD_DASH_COMPONENT_FAILURE_DISMISSED'
);
export const dismissDashComponentFailureById = createAction(
    'DASHBOARD_DASH_COMPONENT_FAILURE_BY_ID_DISMISSED'
);

// Kick off requests for schema and all dashes
export const requestDashboardData = createAction('DASHBOARD_DATA_REQUESTED');
export const updateDashboardData = createAction('DASHBOARD_DATA_UPDATED');
export const failDashboardData = createAction('DASHBOARD_DATA_FAILED');
export function fetchDashboardData() {
    return function(dispatch: ThunkDispatch) {
        dispatch(requestDashboardData());

        return Promise.all([
            dispatch(fetchSavedDashes()),
            dispatch(fetchSavedLists()),
            dispatch(fetchDashboardSchema()),
            dispatch(fetchLeadsSchema()),
            dispatch(fetchContactsSchema()),
        ]).then(
            () => {
                dispatch(updateDashboardData());
                dispatch(fetchDashboardComponentData());
            },
            (err) => {
                dispatch(failDashboardData(err));
            }
        );
    };
}

// Get /rest/dashes
export const requestDashesData = createAction('DASHBOARD_SAVED_DASHES_REQUESTED');
export const updateDashesData = createAction('DASHBOARD_SAVED_DASHES_UPDATED');
export const failDashesData = createAction('DASHBOARD_SAVED_DASHES_FAILED');
export function fetchSavedDashes(rethrowError: boolean = false) {
    return function(dispatch: Dispatch<*>) {
        dispatch(requestDashesData());

        return api
            .get('dashes')
            .then((res) => res.json())
            .then(
                (json) => {
                    const savedDashes = jsonToModelPropArray(json.dashes, json, 'dashes');
                    dispatch(updateDashesData(savedDashes));
                },
                (err) => {
                    dispatch(failDashesData(err));
                    // If we need to use this error elsewhere (i.e. in a Promise.all
                    // call) we'll have to re-throw it here.
                    if (rethrowError) throw err;
                }
            );
    };
}

// Get /rest/lists
export const requestSavedListsData = createAction('DASHBOARD_SAVED_LISTS_REQUESTED');
export const updateSavedListsData = createAction('DASHBOARD_SAVED_LISTS_UPDATED');
export const failSavedListsData = createAction('DASHBOARD_SAVED_LISTS_FAILED');
export function fetchSavedLists(rethrowError: boolean = false) {
    return function(dispatch: Dispatch<*>) {
        dispatch(requestSavedListsData());

        return api
            .get('filters')
            .then((res) => res.json())
            .then(
                (json) => {
                    const savedLists = jsonToModelPropArray(json.filters, json);
                    dispatch(updateSavedListsData(savedLists));
                },
                (err) => {
                    dispatch(failSavedListsData(err));
                    // If we need to use this error elsewhere (i.e. in a Promise.all
                    // call) we'll have to re-throw it here.
                    if (rethrowError) throw err;
                }
            );
    };
}

export const requestDashboardSchema = createAction('DASHBOARD_SCHEMA_REQUESTED');
export const updateDashboardSchema = createAction('DASHBOARD_SCHEMA_UPDATED');
export const failDashboardSchema = createAction('DASHBOARD_SCHEMA_FAILED');
export function fetchDashboardSchema(rethrowError: boolean = false) {
    return function(dispatch: Dispatch<*>) {
        dispatch(requestDashboardSchema());

        return Schema.fetchForDashboard()
            .then((res) => {
                dispatch(updateDashboardSchema(res.properties));
            })
            .catch((err) => {
                dispatch(failDashboardSchema(err));
                // If we need to use this error elsewhere (i.e. in a Promise.all
                // call) we'll have to re-throw it here.
                if (rethrowError) throw err;
            });
    };
}

export const requestLeadsSchema = createAction('LEADS_SCHEMA_REQUESTED');
export const updateLeadsSchema = createAction('LEADS_SCHEMA_UPDATED');
export const failLeadsSchema = createAction('LEADS_SCHEMA_FAILED');
export function fetchLeadsSchema(rethrowError: boolean = false) {
    return function(dispatch: Dispatch<*>) {
        dispatch(requestLeadsSchema());

        return Leads.fetchSchema()
            .then((res) => {
                const schemaProps = _.mapObject(res.properties, (val, key) => {
                    return {...val, name: key};
                });
                dispatch(updateLeadsSchema(schemaProps));
            })
            .catch((err) => {
                dispatch(failLeadsSchema(err));
                // If we need to use this error elsewhere (i.e. in a Promise.all
                // call) we'll have to re-throw it here.
                if (rethrowError) throw err;
            });
    };
}

export const requestContactsSchema = createAction('CONTACTS_SCHEMA_REQUESTED');
export const updateContactsSchema = createAction('CONTACTS_SCHEMA_UPDATED');
export const failContactsSchema = createAction('CONTACTS_SCHEMA_FAILED');
export function fetchContactsSchema(rethrowError: boolean = false) {
    return function(dispatch: Dispatch<*>) {
        dispatch(requestContactsSchema());

        return Contacts.fetchSchema()
            .then((res) => {
                const schemaProps = _.mapObject(res.properties, (val, key) => {
                    return {...val, name: key};
                });
                dispatch(updateContactsSchema(schemaProps));
            })
            .catch((err) => {
                dispatch(failContactsSchema(err));
                // If we need to use this error elsewhere (i.e. in a Promise.all
                // call) we'll have to re-throw it here.
                if (rethrowError) throw err;
            });
    };
}

export const requestAccountsSchema = createAction('ACCOUNTS_SCHEMA_REQUESTED');
export const updateAccountsSchema = createAction('ACCOUNTS_SCHEMA_UPDATED');
export const failAccountsSchema = createAction('ACCOUNTS_SCHEMA_FAILED');
export function fetchAccountsSchema(rethrowError: boolean = false) {
    return function(dispatch: Dispatch<*>) {
        dispatch(requestAccountsSchema());

        return Accounts.fetchSchema()
            .then((res) => {
                const schemaProps = _.mapObject(res.properties, (val, key) => {
                    return {...val, name: key};
                });
                dispatch(updateAccountsSchema(schemaProps));
            })
            .catch((err) => {
                dispatch(failAccountsSchema(err));
                // If we need to use this error elsewhere (i.e. in a Promise.all
                // call) we'll have to re-throw it here.
                if (rethrowError) throw err;
            });
    };
}

export function dashWasDropped({id, index}: {id: string, index: number}) {
    return function(dispatch: Dispatch<*>, getState: StateGetter) {
        const oldDashOrderById = getDashOrderById(getState());
        const newDashOrderById = insert(id, oldDashOrderById, index);
        persistDashOrder(dispatch, oldDashOrderById, newDashOrderById);
    };
}

export function dashWasAdded(id: string) {
    return function(dispatch: Dispatch<*>, getState: StateGetter) {
        const oldDashOrderById = getDashOrderById(getState());
        const newDashOrderById = oldDashOrderById.concat(id);

        // Locally updateDashOrder, but don't hit server, as our createAction
        // in the symfony controller already updates the order as a side-effect.
        dispatch(updateDashOrder(newDashOrderById));
    };
}

export function dashWasDeleted(id: string) {
    return function(dispatch: Dispatch<*>, getState: StateGetter) {
        const oldDashOrderById = getDashOrderById(getState());
        const newDashOrderById = _.without(oldDashOrderById, id);

        // Locally updateDashOrder, but don't hit server, as our deleteAction
        // in the symfony controller already updates the order as a side-effect.
        dispatch(updateDashOrder(newDashOrderById));
    };
}

export const requestDashComponentData = createAction('DASHBOARD_DASH_COMPONENT_DATA_REQUESTED');
export const updateDashComponentData = createAction('DASHBOARD_DASH_COMPONENT_DATA_UPDATED');
export const failDashComponentData = createAction('DASHBOARD_DASH_COMPONENT_DATA_FAILED');
export const failDashComponentDataById = createAction('DASHBOARD_DASH_COMPONENT_DATA_BY_ID_FAILED');
export const requestDashComponentDataById = createAction(
    'DASHBOARD_DASH_COMPONENT_DATA_BY_ID_REQUESTED'
);
export const updateDashComponentDataById = createAction(
    'DASHBOARD_DASH_COMPONENT_DATA_BY_ID_UPDATED'
);
export function fetchDashboardComponentData(dashId: ?string) {
    return function(dispatch: Dispatch<*>, getState: StateGetter) {
        const state = getState();
        let dashes;
        if (dashId) {
            const dash = getDashById(state, dashId);
            dashes = dash ? [dash] : [];
        } else {
            dispatch(requestDashComponentData());
            dashes = getDashes(state);
        }
        dashes.forEach((dash) => {
            if (!getIsDashSpecialCard(dash.type)) {
                dispatch(requestDashComponentDataById(dash.id));
                fetchDashData(dash.type, dash.value).then(
                    (dashData) => {
                        dashData.dashId = dash.id;
                        dispatch(updateDashComponentDataById(dashData));
                    },
                    (err) => {
                        // These errors are all different types, depending
                        // on the action, so this gets ugly
                        let errorsToDispatch = err.responseText;
                        if (err.jsonApiErrors) {
                            errorsToDispatch = err.jsonApiErrors;
                        } else if (dash.type === 'quotas') {
                            errorsToDispatch = JSON.parse(err.responseText).errors;
                        }

                        dispatch(
                            failDashComponentDataById({
                                dashId: dash.id,
                                errors: errorsToDispatch,
                            })
                        );
                    }
                );
            }
        });
    };
}

export const requestDeleteDash = createAction('DASHBOARD_DASH_DELETE_REQUESTED');
export const updateDeleteDash = createAction('DASHBOARD_DASH_DELETE_UPDATED');
export const failDeleteDashById = createAction('DASHBOARD_DASH_DELETE_BY_ID_FAILED');
export function deleteDash(dispatch: ThunkDispatch, dashId: string) {
    dispatch(requestDeleteDash(dashId));

    $.ajax({
        type: 'DELETE',
        url: `/rest/dashes/${dashId}`,
    }).then(
        () => {
            dispatch(updateDeleteDash());
            dispatch(dashWasDeleted(dashId));
        },
        (err) => {
            dispatch(failDeleteDashById({dashId, err}));
        }
    );
}

function persistDashOrder(dispatch, oldDashOrderById, newDashOrderById) {
    $.ajax({
        type: 'POST',
        url: '/rest/dashes/persist',
        data: {
            dashIds: newDashOrderById,
        },
    }).fail(() =>
        dispatch(
            failDashOrder({
                errorMessage: 'Unable to rearrange dashes',
                dashOrderById: oldDashOrderById,
            })
        )
    );
}

function insert(item, arr, idx) {
    const newArr = _.without(arr, item);
    const arrStart = newArr.slice(0, idx);
    const arrEnd = newArr.slice(idx);

    return arrStart.concat(item).concat(arrEnd);
}
