/* @flow */

import * as React from 'react';

import {Button} from 'shells/button';
import {ButtonMover} from 'shells/button-mover';

import {PowerAiUsagePopover} from './power-ai-usage-popover/power-ai-usage-popover';

type Props = {|
    variant: 'timeline' | 'zoom',
|};

export function SummaryInfoButton(props: Props) {
    const buttonContainerRef = React.useRef(null);

    return (
        <>
            <div ref={buttonContainerRef}>
                <ButtonMover direction='left' size='normal'>
                    <Button
                        variant='text-primary'
                        onClick={(e: SyntheticEvent<*>) => {
                            e.stopPropagation();
                        }}
                    >
                        What’s this?
                    </Button>
                </ButtonMover>
            </div>
            <PowerAiUsagePopover
                popoverAnchor={buttonContainerRef}
                variant={props.variant}
                location='top-right'
            />
        </>
    );
}
