/* @flow */

import * as React from 'react';
import {
    ContactIcon,
    AccountIcon,
    AccountsCircleIcon,
    AccountParentIcon,
    AccountParentCircleIcon,
    ContactsCircleIcon,
} from '../icon';
import {colors} from '../colors';
import {LeadAvatar} from './lead-avatar';

import './avatar.css';

export const DEFAULT_SIZE = 30;

type Props = {|
    avatarUrl: ?string,
    circular: boolean,
    // border adds a 2 px solid border to the avatar.
    border?: boolean,
    initials?: ?string,
    size: number,
    type?: string,
    /** Leads should also provide their status, for the alt tag */
    status?: string,
    isDisabled?: boolean,
    isParent?: boolean,
    tooltipText?: string,
    color?: string,
|};

/**
 * A simple graphical representation of a company, person, lead, user or team.
 */
export class Avatar extends React.PureComponent<Props> {
    static defaultProps = {
        circular: true,
        size: DEFAULT_SIZE,
    };

    render() {
        // Guard against missing or empty url AND initials (we need at least one of them)
        const needsPlaceholder =
            !this.props.avatarUrl &&
            !this.props.initials &&
            !['contacts', 'accounts'].includes(this.props.type); // contacts and accounts (including mcfxCompanies) have icons to fallback on

        if (needsPlaceholder) {
            // Provide a placeholder div, to prevent other elements from shifting around
            return <div style={{height: this.props.size, width: this.props.size}} />;
        }

        if (this.props.type === 'leads' || this.props.type === 'leadStatus') {
            return (
                <LeadAvatar
                    avatarUrl={this.props.avatarUrl}
                    size={this.props.size}
                    status={this.props.status}
                />
            );
        }

        let style: Object = {
            width: `${this.props.size}px`,
            height: `${this.props.size}px`,
            lineHeight: `${this.props.size}px`,
            fontSize: Math.floor(this.props.size / 2 - this.props.size / 15),
        };

        if (!this.props.initials) style = Object.assign(style, {backgroundColor: 'transparent'});
        if (this.props.circular)
            style = Object.assign(style, {borderRadius: `${this.props.size}px`});

        const typeClassName = this.props.type ? `type-${this.props.type}` : '';

        return (
            <div
                styleName={`${
                    this.props.border ? 'container--border' : 'container'
                } ${typeClassName}`}
                style={style}
                data-tip={this.props.tooltipText}
            >
                {this.renderAvatarContents(style)}
            </div>
        );
    }

    // This sucks, but will get fixed once we have a more consistent set of icons, and leads have avatarUrls
    renderAvatarContents(style: Object) {
        let initials, avatarImg, entityIcon;

        if (this.props.initials) {
            initials = (
                <div
                    style={style}
                    styleName={this.props.isDisabled ? 'initials--disabled' : 'initials'}
                >
                    {this.props.initials}
                </div>
            );
        }

        if (this.props.avatarUrl) {
            const params = 'width=100&height=100';
            // Url may or may not already have a query string
            const avatarUrl: string = this.props.avatarUrl;
            const url = avatarUrl.includes('?')
                ? `${avatarUrl}&${params}`
                : `${avatarUrl}?${params}`;
            avatarImg = <img style={style} alt='avatar' src={url} />;
        }

        // Users don't have SVG entity icons… yet
        if (['contacts', 'accounts'].includes(this.props.type) && !this.props.initials) {
            if (this.props.type === 'contacts') {
                entityIcon = this.props.circular ? (
                    <ContactsCircleIcon
                        size={this.props.size}
                        fill={this.props.color || colors.navyDk}
                    />
                ) : (
                    <ContactIcon size={this.props.size} />
                );
            } else if (this.props.type === 'accounts' && !this.props.isParent) {
                entityIcon = this.props.circular ? (
                    <AccountsCircleIcon
                        size={this.props.size}
                        color={this.props.color || colors.company}
                    />
                ) : (
                    <AccountIcon size={this.props.size} fill={this.props.color} />
                );
            } else {
                entityIcon = this.props.circular ? (
                    <AccountParentCircleIcon
                        size={this.props.size}
                        color={this.props.color || colors.company}
                    />
                ) : (
                    <AccountParentIcon size={this.props.size} color={this.props.color} />
                );
            }
        }

        if (!initials && !avatarImg && !entityIcon) return null;

        return (
            <>
                {avatarImg ? avatarImg : undefined}
                {initials ? initials : entityIcon}
            </>
        );
    }
}
