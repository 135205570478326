import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Inbox from './icon-inbox.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Inbox {...validProps} />;
}
SvgComponent.iconName = 'inbox';

export const InboxIcon = svgIconWrapper(SvgComponent);
