/* @flow */
import {Observable} from 'rxjs/Observable';
import type {ActionsObservable} from 'redux-observable';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';

import {fetchList} from 'nutshell-core/entities/fetch-list';
import {safelyParseError} from 'nutshell-core/utils';
import {transformDataForReport} from 'nutshell-core/json-to-report-data';

import {
    updateAudienceMemberListData,
    failAudienceMemberListData,
    updateAudienceMembershipReportTableData,
    updateAudienceMembershipReportChartData,
    failAudienceMembershipReportChartData,
    type AudienceMembershipReportDataRequestedAction,
    type AudienceMemberListRequestedAction,
} from './audiences-actions';

import {getMembershipReportPostParams} from './audience-utils';

export const requestAudienceMemberListEpic = (action$: ActionsObservable<*>) =>
    action$
        .ofType('AUDIENCE_MEMBER_LIST_DATA_REQUESTED')
        .switchMap((action: AudienceMemberListRequestedAction) => {
            const {filters, pageNum = 1, columns, sort, q} = action.payload;

            // Our pagination components aren't 0-based, but this specific
            // request expects page 0 to be the first page. Using Math.max() here
            // in case older URLs with page 0 are still in the wild
            const adjustedPageNum = Math.max(0, pageNum - 1);

            const listRequestStream = Observable.fromPromise(
                fetchList('contacts', {
                    filter: filters,
                    sort,
                    q,
                    page: {page: adjustedPageNum},
                    fields: columns,
                })
            );

            const audienceMemberListDataStream = listRequestStream.map((response) =>
                updateAudienceMemberListData(response)
            );

            return audienceMemberListDataStream.catch((err) => {
                const safeError = safelyParseError(err);

                return Observable.of(failAudienceMemberListData(safeError));
            });
        });

export const requestAudienceMembershipReportDataEpic = (action$: ActionsObservable<*>) =>
    action$
        .ofType('AUDIENCE_MEMBERSHIP_REPORT_DATA_REQUESTED')
        .switchMap((action: AudienceMembershipReportDataRequestedAction) => {
            const {reportParams, filters} = action.payload;
            const params = getMembershipReportPostParams(reportParams, filters);

            const requestStream = Observable.fromPromise(fetchData(params));
            const reportDataStream = requestStream.map(transformDataForReport);

            const reportTableDataStream = reportDataStream.map(({tableData}) =>
                updateAudienceMembershipReportTableData(tableData)
            );

            const reportChartDataStream = reportDataStream
                .map((data) => data.chartData)
                .filter((chartData) => Boolean(chartData))
                .map((chartData) => updateAudienceMembershipReportChartData({chartData}));

            return reportChartDataStream
                .merge(reportTableDataStream)
                .catch((err) => Observable.of(failAudienceMembershipReportChartData(err)));
        });

function fetchData(params) {
    return Promise.all([fetchAudienceMembershipReportData(params)]);
}

function fetchAudienceMembershipReportData(params: Object): Promise<any> {
    const url = '/rest/audiences/report';
    const ajaxParams = {
        url,
        dataType: 'json',
        data: params,
    };

    return ($.ajax(ajaxParams): any); // This is how we have to convince flow that the response is a ReportsResponse
}
