/* @flow */

import {unexpectedCase} from 'nutshell-core/reducer-utils';

import {ActionTypes, type MessagesAction} from './messages-actions';

export type MessagesState = {
    dismissedMessageIds: string[],
};

const defaultMessagesState = {
    dismissedMessageIds: [],
};

export const reducer = (
    state: MessagesState = defaultMessagesState,
    action: MessagesAction
): MessagesState => {
    switch (action.type) {
        case ActionTypes.MESSAGE_DISMISSED:
            return {
                ...state,
                dismissedMessageIds: state.dismissedMessageIds.concat([action.payload]),
            };
        case 'persist/REHYDRATE': {
            const persistedState = action.payload;

            if (!persistedState || !persistedState.ui || !persistedState.ui.messages) {
                return state;
            }

            return {
                ...state,
                dismissedMessageIds: persistedState.ui.messages.dismissedMessageIds,
            };
        }
        default:
            unexpectedCase(action.type);

            return state;
    }
};
