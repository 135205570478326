
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"EntityAttributeValueFileFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"EntityAttributeValueFile"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fileId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"size"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"href"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fileType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"eavEntity"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Account"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AccountFragment"},"directives":[]}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Contact"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ContactFragment"},"directives":[]}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Lead"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadFragment"},"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":339}};
    doc.loc.source = {"body":"fragment EntityAttributeValueFileFragment on EntityAttributeValueFile {\n    fileId\n    name\n    size\n    href\n    fileType\n    eavEntity {\n        ... on Account {\n            ...AccountFragment\n        }\n        ... on Contact {\n            ...ContactFragment\n        }\n        ... on Lead {\n            ...LeadFragment\n        }\n    }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
