/* @flow */

import moment from 'moment';

/**
 * Gets the fiscal year relative to a date, and given a fiscalYearStart
 * @param  {Moment} relativeToDate      Moment object to get fiscal year relative to
 * @param  {string} fiscalYearStart:    Settings-like string ({Month}-{Day}) of the fiscalYearStart
 * @return {Object}                     Tuple object of fiscal year start and end moment objects
 */
export function getFiscalYearRelativeToDate(relativeToDate: Object, fiscalYearStart: string) {
    const fiscalYearSplit = fiscalYearStart.split('-');
    const month = fiscalYearSplit[0];
    const day = fiscalYearSplit[1];

    const startOfFiscalYear = moment()
        .utc()
        .month(Number(month) - 1) // Month is a 0-index based function
        .date(Number(day)) // Date is not
        .startOf('day')
        .year(relativeToDate.year());

    // We need to determine if the fiscal year starts before or after our relative
    // date, so that the quarters we generate are guarenteed to include the
    // relative date.
    if (startOfFiscalYear.isAfter(relativeToDate)) {
        startOfFiscalYear.subtract(1, 'years');
    }

    const endOfFiscalYear = startOfFiscalYear
        .clone()
        .add(1, 'years')
        .subtract(1, 'days')
        .endOf('day');

    return {startOfFiscalYear, endOfFiscalYear};
}
