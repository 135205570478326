import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import AddReaction from './icon-add-reaction.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <AddReaction {...validProps} />;
}
SvgComponent.iconName = 'addReaction';

export const AddReactionIcon = svgIconWrapper(SvgComponent);
