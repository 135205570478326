/* @flow */

import * as React from 'react';
import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';

type Props = {
    fill?: string,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} fill={props.fill || colors.grey} viewBox='0 0 22 22'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M1 4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4Zm13.334 9.37a.74.74 0 0 1-.741.741H6.185a.74.74 0 0 1-.74-.74v-.741c0-.41.331-.741.74-.741h7.408c.409 0 .74.332.74.74v.741Zm1.259-3.703a.74.74 0 0 0 .74-.741v-.74a.74.74 0 0 0-.74-.741H6.186a.74.74 0 0 0-.741.74v.741c0 .41.331.74.74.74h9.408Z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'longText';

export const LongTextIcon = svgIconWrapper(SvgComponent);
