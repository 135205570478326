/* @flow */

import {combineReducers} from 'redux';
import type {SkuCode} from './billing-types';
import {ActionTypes, type BillingAction} from './billing-actions';

const baseCouponState = {
    status: 'unknown',
    description: null,
    validPlans: [],
    appliesToAllPlans: true,
    duration: undefined,
    discount_type: undefined,
    discount_in_cents: undefined,
    discount_percent: undefined,
};

type CouponState = $PropertyType<BillingState, 'coupon'>;
const couponReducer = (state: CouponState = null, action: BillingAction): CouponState => {
    switch (action.type) {
        case ActionTypes.BILLING_CHECK_COUPON_REQUEST: {
            return {
                ...baseCouponState,
                status: 'loading',
            };
        }
        case ActionTypes.BILLING_CHECK_COUPON_SUCCESS: {
            if (!action.payload) {
                return {
                    ...baseCouponState,
                    status: 'invalid',
                };
            }

            const discount_in_cents =
                action.payload.discount_type === 'dollars'
                    ? action.payload.discount_in_cents
                    : undefined;
            const discount_percent =
                action.payload.discount_type === 'percent'
                    ? action.payload.discount_percent
                    : undefined;

            return {
                ...state,
                status: action.payload.state,
                description: action.payload.description,
                validPlans: action.payload.plan_codes,
                appliesToAllPlans: action.payload.applies_to_all_plans,
                duration: action.payload.duration,
                discount_in_cents,
                discount_percent,
                discount_type: action.payload.discount_type,
            };
        }
        case ActionTypes.BILLING_CHECK_COUPON_FAILURE: {
            // If we can't get any info about the coupon, just pretend it doesn't exist.
            return null;
        }
        case ActionTypes.BILLING_CLEAR_COUPON: {
            return null;
        }
        default:
            return state;
    }
};

export type BillingState = {
    coupon: ?{
        status: 'invalid' | 'inactive' | 'redeemable' | 'unknown' | 'loading',
        description: ?string,
        validPlans: SkuCode[],
        appliesToAllPlans: boolean,
        duration: 'forever' | 'temporal' | 'single_use' | void,
        discount_in_cents: ?number,
        discount_percent: ?number,
        discount_type: 'percent' | 'dollars' | void,
    },
};

// $FlowFixMe upgrading Flow to v0.92.1
export const reducer = combineReducers({
    coupon: couponReducer,
});
