/* @flow */

import * as tinyIntroActions from './tiny-intro-actions';
import * as tinyIntroSelectors from './tiny-intro-selector';
import {tinyIntroReducer} from './tiny-intro-reducer';

export const TinyIntroduction = {
    tinyIntroReducer,
    ...tinyIntroActions,
    ...tinyIntroSelectors,
};

export type {TinyIntroState} from './tiny-intro-reducer';
