/* @flow */

import {createSelector} from 'reselect';
import {REDUCER_KEY, type BillingModuleState} from './billing-types';

export const getBillingState = (state: BillingModuleState) => state[REDUCER_KEY];

export const getCouponState = (state: BillingModuleState) => {
    return getBillingState(state).coupon;
};

export const getCouponDescription = (state: BillingModuleState) => {
    const coupon = getCouponState(state);
    if (!coupon) return null;

    return coupon.description;
};

// $FlowFixMe upgrading Flow to v0.92.1
export const getCouponValidPlans = createSelector([getCouponState], (coupon) => {
    if (!coupon) return [];

    return coupon.validPlans;
});

// $FlowFixMe upgrading Flow to v0.92.1
export const getCouponDiscountPercent = createSelector([getCouponState], (coupon) => {
    if (!coupon || coupon.discount_type !== 'percent') return null;

    return coupon.discount_percent;
});

// $FlowFixMe upgrading Flow to v0.92.1
export const getCouponDiscountInCents = createSelector([getCouponState], (coupon) => {
    if (!coupon || coupon.discount_type !== 'dollars') return null;

    return coupon.discount_in_cents;
});

// $FlowFixMe upgrading Flow to v0.92.1
export const getCouponDuration = createSelector([getCouponState], (coupon) => {
    if (!coupon) return null;

    return coupon.duration;
});

export const isCouponLoading = (state: BillingModuleState) => {
    const coupon = getCouponState(state);
    if (!coupon) return false;

    return coupon.status === 'loading';
};

export const isCouponRedeemable = (state: BillingModuleState) => {
    const coupon = getCouponState(state);
    if (!coupon) return false;

    return coupon.status === 'redeemable';
};

export const isCouponExpired = (state: BillingModuleState) => {
    const coupon = getCouponState(state);
    if (!coupon) return false;

    return coupon.status === 'inactive';
};

export const isCouponInvalid = (state: BillingModuleState) => {
    // First we have to check if a coupon has been entered
    const coupon = getCouponState(state);
    if (!coupon) return false;

    return coupon.status === 'invalid';
};

export const isCouponApplicableToAllPlans = (state: BillingModuleState) => {
    // First we have to check if a coupon has been entered
    const coupon = getCouponState(state);
    if (!coupon) return false;

    return coupon.appliesToAllPlans;
};
