/* @flow */

import * as React from 'react';

type Props = {
    inputRef?: (?HTMLInputElement) => mixed,
};

/*
 * Component responsible for rendering a cross-browser hidden form
 * submission element, that also allows for RETURN-key submission
 * of forms.
 *
 * Simply rendering an `<input type='submit' hidden={true}` /> will
 * not work in Safari, as it doesn't handle the hidden element.
 *
 * refs: https://stackoverflow.com/a/6602788
 */
export class CrossBrowserHiddenFormSubmit extends React.PureComponent<Props> {
    render() {
        return (
            <input
                type='submit'
                ref={this.props.inputRef}
                tabIndex='-1'
                style={{
                    border: '0 none',
                    overflow: 'hidden',
                    height: 0,
                    width: 0,
                    padding: 0,
                    margin: 0,
                }}
            />
        );
    }
}
