/* @flow */

export function getApiTypeFromGraphQLTypename(__typename: string) {
    switch (__typename) {
        case 'Account':
            return 'accounts';
        case 'Contact':
            return 'contacts';
        case 'Lead':
            return 'leads';
        case 'Origin':
            return 'origins';
        case 'User':
            return 'users';
        default:
            return 'contacts';
    }
}
