/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import './saving-icon.css';

type Props = {
    isSaving: boolean,
    size: number,
    fill: string,
};

type State = {
    isDoneAnimating: boolean,
};

export class SavingIcon extends React.PureComponent<Props, State> {
    static defaultProps = {
        size: 16,
        fill: '#fff',
    };

    constructor() {
        super();
        this.state = {
            isDoneAnimating: false,
        };
    }

    UNSAFE_componentWillReceiveProps(prevProps: Props) {
        if (prevProps.isSaving && !this.props.isSaving) {
            setTimeout(() => {
                this.setState({
                    isDoneAnimating: true,
                });
            });
        }
    }

    render() {
        return (
            <div style={{width: this.props.size}}>
                <svg
                    version='1.1'
                    xmlns='http://www.w3.org/2000/svg'
                    xlinkHref='http://www.w3.org/1999/xlink'
                    xmlSpace='preserve'
                    x='0px'
                    y='0px'
                    viewBox='0, 0, 100, 100'
                    id='checkmark'
                >
                    <g transform=''>
                        <circle
                            styleName={this.getPathStylenames()}
                            fill='none'
                            stroke={this.props.fill}
                            strokeWidth='4'
                            strokeMiterlimit='10'
                            cx='50'
                            cy='50'
                            r='44'
                        />
                        <circle
                            styleName={this.getFillStylenames()}
                            fill='none'
                            stroke={this.props.fill}
                            strokeWidth='4'
                            strokeMiterlimit='10'
                            cx='50'
                            cy='50'
                            r='44'
                        />
                        <polyline
                            styleName={this.getCheckStylenames()}
                            fill='none'
                            stroke={this.props.fill}
                            strokeWidth='8'
                            strokeLinecap='round'
                            strokeMiterlimit='10'
                            points='70,35 45,65 30,52'
                        />
                    </g>
                </svg>
            </div>
        );
    }

    getPathStylenames() {
        return classnames({
            path: true,
            'path-complete': !this.props.isSaving && this.state.isDoneAnimating,
        });
    }

    getFillStylenames() {
        return classnames({
            fill: true,
            'fill-complete': !this.props.isSaving,
            success: !this.props.isSaving && this.state.isDoneAnimating,
        });
    }

    getCheckStylenames() {
        return classnames({
            check: true,
            'check-complete': !this.props.isSaving,
            success: !this.props.isSaving && this.state.isDoneAnimating,
        });
    }
}
