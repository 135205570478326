/* @flow */

import * as React from 'react';

import {DurationTimestamp} from '../../../../timestamp/duration-timestamp';

import './session-content.css';

export type Page = {
    id: string,
    durationSeconds: number,
    path: ?string,
    pageTitle: ?string,
    fullyQualifiedUrl: ?string,
};

type Props = {|
    durationSeconds: number,
    source: {
        medium: string,
        source: string,
    },
    pages: Page[],
|};

export function SessionContent(props: Props) {
    const {durationSeconds, pages} = props;
    const {source, medium} = props.source;

    return (
        <div>
            <dl styleName='utm-data-container'>
                <div styleName='utm-data'>
                    <dt>
                        <strong>utm_source</strong>
                    </dt>
                    <dd>{source}</dd>
                </div>
                <div styleName='utm-data'>
                    <dt>
                        <strong>utm_medium</strong>
                    </dt>
                    <dd>{medium}</dd>
                </div>
            </dl>
            <div styleName='page-data-container'>
                <div styleName='page-data'>
                    <strong>
                        {pages.length} page{pages.length !== 1 ? 's' : ''}
                    </strong>
                    <strong>
                        <DurationTimestamp durationSeconds={durationSeconds} />
                    </strong>
                </div>
                {pages.map((page) => {
                    const innerContents = (
                        <>
                            <div>
                                <div>{page.path ? page.path : <i>Unknown page</i>}</div>
                                <div styleName='page-title'>
                                    {page.pageTitle ? page.pageTitle : <i>No page title</i>}
                                </div>
                            </div>
                            <DurationTimestamp durationSeconds={page.durationSeconds} />
                        </>
                    );

                    return page.fullyQualifiedUrl ? (
                        <a
                            key={page.id}
                            rel='noreferrer noopener'
                            target='_blank'
                            href={page.fullyQualifiedUrl}
                            styleName='page-data'
                        >
                            {innerContents}
                        </a>
                    ) : (
                        <div key={page.id} styleName='page-data'>
                            {innerContents}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
