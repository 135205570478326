/* @flow */

import {createSelector} from 'reselect';
import type {AccountId} from '../types';
import type {AccountsState} from './accounts-reducer';

// The root state will include more keys, but this is what we really care about here
type AccountsModuleState = {accounts: AccountsState};

/*
 * Given the entire redux state, pull out just the piece of state for the accounts reducer.
 */
export const getAccountsState = (state: AccountsModuleState): AccountsState => state.accounts;

/*
 * Get the `byId` portion of the contacts state
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getById = createSelector([getAccountsState], (accountsState) => accountsState.byId);

/*
 * Retrieve a single account by id, by passing an accountId
 */
export const getAccount = (state: AccountsModuleState, accountId: AccountId) => {
    return getById(state)[accountId];
};

export function getTempAccounts(state: AccountsModuleState) {
    const tempAccounts = getAccountsState(state).tempByName;

    return Object.keys(tempAccounts).length ? tempAccounts : null;
}

export function getListItemMap(state: AccountsModuleState) {
    return getAccountsState(state).listItemsById;
}
