import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Note from './icon-note.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Note {...validProps} />;
}
SvgComponent.iconName = 'note';

export const NoteIcon = svgIconWrapper(SvgComponent);
