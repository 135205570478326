/* @flow */

import * as React from 'react';

import './message-container.css';

type Props = {
    direction?: 'normal' | 'reverse',
    children: React.Node,
};

export function MessageContainer(props: Props) {
    return (
        <div
            styleName={
                props.direction === 'reverse' ? 'message-container--reverse' : 'message-container'
            }
        >
            {props.children}
        </div>
    );
}
