/* @flow */

import * as React from 'react';
import {AccentBorder} from 'shells/accent-border';
import {Card} from 'shells/card';
import {colors} from 'shells/colors';
import {TwoRowItem, type Props as TwoRowItemProps} from './two-row-item';

export function TwoRowItemCard(props: TwoRowItemProps) {
    // Needed because TwoRowItem needs an exact set of props
    const sealedProps = Object.freeze({...props});

    return (
        <Card hasBorder={true}>
            <AccentBorder color={colors.green}>
                {React.createElement(TwoRowItem, sealedProps)}
            </AccentBorder>
        </Card>
    );
}
