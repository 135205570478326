/* @flow */

import * as React from 'react';

import {LoadingPage} from 'shells/loading-page';

import {FlashMessages} from '../flash-messages';
import {ScrollShadow} from './scroll-shadow';

import './form-wrapper.css';

type Props = {
    icon: React.ComponentType<*>,
    footer: React.Element<any>,
    /** If full height, we do not include shadows */
    isFullHeight?: boolean,
    children?: any,
    isLoading?: boolean,
};

type State = {
    scrollShadowTop: boolean,
    scrollShadowBtm: boolean,
};

export class FormWrapper extends React.PureComponent<Props, State> {
    scrollContainerRef: ?HTMLElement;

    constructor(props: Props) {
        super(props);
        this.state = {
            scrollShadowBtm: true,
            scrollShadowTop: false,
        };
    }

    render() {
        const Icon = this.props.icon;

        // The first child is treated differently, because it's put next to the icon
        const firstChild = React.Children.map(this.props.children, (child, i) => {
            // Ignore all but the first child
            if (i !== 0) return;

            return child;
        });
        const restChildren = React.Children.map(this.props.children, (child, i) => {
            // Ignore the first child, or if the child doesn't exist for
            // some reason (ternaries can produce `undefined`)
            if (i < 1 || !child) return;

            return React.cloneElement(child);
        });

        if (this.props.isLoading) return <LoadingPage />;

        return (
            <div styleName='form-content'>
                <div
                    ref={(node) => {
                        this.scrollContainerRef = node;
                    }}
                    styleName='scroll-container'
                    onScroll={this.handleScroll}
                >
                    <div
                        id='entity-form-body'
                        styleName={this.props.isFullHeight ? 'form-body--full-height' : 'form-body'}
                    >
                        <div styleName='flex-row'>
                            <div styleName='icon-container'>
                                <Icon styleName='icon' size={75} />
                            </div>
                            <div styleName='name-field'>{firstChild}</div>
                        </div>
                        {restChildren}
                    </div>
                </div>

                {this.props.footer}
                {!this.props.isFullHeight ? (
                    <React.Fragment>
                        <ScrollShadow orientation='top' isActive={this.state.scrollShadowTop} />
                        <ScrollShadow orientation='bottom' isActive={this.state.scrollShadowBtm} />
                    </React.Fragment>
                ) : null}

                <FlashMessages />
            </div>
        );
    }

    handleScroll = () => {
        const scrollContainerNode = this.scrollContainerRef;

        if (!scrollContainerNode) return;

        const {offsetHeight, scrollHeight, scrollTop} = scrollContainerNode;

        const state = {
            scrollShadowTop: scrollTop > 0,
            scrollShadowBtm: scrollHeight - offsetHeight !== scrollTop,
        };

        // Avoid calling setState on every mousewheel tick.
        const updateShadows =
            state.scrollShadowTop !== this.state.scrollShadowTop ||
            state.scrollShadowBtm !== this.state.scrollShadowBtm;

        if (updateShadows) this.setState(state);
    };
}
