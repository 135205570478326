import PropTypes from 'prop-types';
import * as React from 'react';
import {ChevronLeftIcon, ChevronRightIcon} from '../icon';
import {CalendarYearPicker} from './calendar-year-picker';
import './calendar-header.css';

export class CalendarHeader extends React.Component {
    static propTypes = {
        hasLeftControl: PropTypes.bool.isRequired,
        hasRightControl: PropTypes.bool.isRequired,
        hasYearPicker: PropTypes.bool,
        dateFormat: PropTypes.string.isRequired,
        momentDisplayDate: PropTypes.object.isRequired,
        onYearChange: PropTypes.func,
        onLeftClick: PropTypes.func,
        onRightClick: PropTypes.func,
        isLightMode: PropTypes.bool,
    };

    static defaultProps = {
        dateFormat: 'MMMM',
    };

    render() {
        const momentDisplayDate = this.props.hasYearPicker
            ? this.props.momentDisplayDate.format(this.props.dateFormat)
            : `${this.props.momentDisplayDate.format(
                  this.props.dateFormat
              )} ${this.props.momentDisplayDate.get('year')}`;

        return (
            <div styleName={this.props.isLightMode ? 'container-light' : 'container'}>
                {this.props.hasLeftControl ? (
                    <div styleName='button-control' onClick={this.props.onLeftClick} type='button'>
                        <ChevronLeftIcon />
                    </div>
                ) : (
                    <div styleName='button' />
                )}
                <div styleName='month'>
                    {momentDisplayDate}
                    {this.props.hasYearPicker ? (
                        <CalendarYearPicker
                            year={this.props.momentDisplayDate.get('year')}
                            onChange={this.handleYearChange}
                        />
                    ) : (
                        undefined
                    )}
                </div>
                {this.props.hasRightControl ? (
                    <div styleName='button-control' onClick={this.props.onRightClick} type='button'>
                        <ChevronRightIcon />
                    </div>
                ) : (
                    <div styleName='button' />
                )}
            </div>
        );
    }

    handleYearChange = (newYear) => {
        const newDisplayDate = this.props.momentDisplayDate.clone().set({year: newYear});
        this.props.onYearChange(newDisplayDate);
    };
}
