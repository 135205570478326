/* @flow */

import * as React from 'react';

import {colors, type ColorName} from '../../colors';

import {DEFAULT_ICON_SIZE, DEFAULT_SUB_ICON_SIZE, TIMELINE_ENTRY_ICONS} from './consts';
import type {TimelineEntryIconVariant} from './types';

import styles from './timeline-entry-icon.css';

const DEFAULT_ICON_FILL: ColorName = 'white';
const SMALL_ICON_SIZE_OFFSET = parseInt(styles.smallIconSizeOffset, 10);
const LAST_ENTRY_SMALL_LINE_HEIGHT_OFFSET = parseInt(styles.lastEntrySmallLineHeightOffset, 10);
const LAST_ENTRY_LINE_HEIGHT_OFFSET = parseInt(styles.lastEntryLineHeightOffset, 10);
const LAST_ENTRY_SMALL_LEFT_PADDING_OFFSET = parseInt(styles.lastEntrySmallLeftPaddingOffset, 10);
const LAST_ENTRY_LEFT_PADDING_OFFSET = parseInt(styles.lastEntryLeftPaddingOffset, 10);

type Props = {|
    variant: TimelineEntryIconVariant,
    gutterContainerRef?: any,
    isSmall?: boolean,
    isLastEntry?: boolean,
    onClickOpen?: (e: SyntheticEvent<*>) => void,
    hasGutterLine?: boolean,
|};

export function TimelineEntryIcon(props: Props) {
    const {variant, gutterContainerRef, isSmall, isLastEntry, hasGutterLine = true} = props;

    const [gutterLineHeight, setGutterLineHeight] = React.useState<number>(0);

    // Sets the gutter height on mount
    React.useEffect(() => {
        if (hasGutterLine && gutterContainerRef && gutterContainerRef.current) {
            setGutterLineHeight(gutterContainerRef.current.clientHeight);
        }
    }, [hasGutterLine, gutterContainerRef]);

    // Updates the gutter height if component re-renders & container ref height changes
    if (
        hasGutterLine &&
        gutterContainerRef &&
        gutterContainerRef.current &&
        gutterContainerRef.current.clientHeight !== gutterLineHeight
    ) {
        setGutterLineHeight(gutterContainerRef.current.clientHeight);
    }

    const {icon: Icon, subIcon: SubIcon, backgroundColor, fill, size} = TIMELINE_ENTRY_ICONS[
        variant
    ];

    const iconFill = fill ? colors[fill] : colors[DEFAULT_ICON_FILL];
    const iconSize = size ? size : DEFAULT_ICON_SIZE;
    const iconSizeSmall = iconSize - SMALL_ICON_SIZE_OFFSET;
    const subIconSizeSmall = DEFAULT_SUB_ICON_SIZE - SMALL_ICON_SIZE_OFFSET;

    const lastEntryGutterLineHeight = isSmall
        ? gutterLineHeight - LAST_ENTRY_SMALL_LINE_HEIGHT_OFFSET
        : gutterLineHeight - LAST_ENTRY_LINE_HEIGHT_OFFSET;

    return (
        <div
            styleName={isSmall ? 'timeline-icon--small' : 'timeline-icon'}
            onClick={props.onClickOpen}
        >
            <div
                styleName={props.onClickOpen ? 'icon-container--clickable' : 'icon-container'}
                style={{color: iconFill, backgroundColor: colors[backgroundColor]}}
            >
                <Icon size={isSmall ? iconSizeSmall : iconSize} color={iconFill} fill={iconFill} />
            </div>
            {SubIcon ? (
                <div
                    styleName={isSmall ? 'sub-icon-container--small' : 'sub-icon-container'}
                    style={{backgroundColor: colors[backgroundColor]}}
                >
                    <SubIcon
                        size={isSmall ? subIconSizeSmall : DEFAULT_SUB_ICON_SIZE}
                        color={iconFill}
                        fill={iconFill}
                    />
                </div>
            ) : (
                undefined
            )}
            {gutterLineHeight ? (
                <div
                    styleName={isSmall ? 'gutter-line--small' : 'gutter-line'}
                    style={{
                        height: isLastEntry ? lastEntryGutterLineHeight : gutterLineHeight,
                    }}
                />
            ) : (
                undefined
            )}
            {gutterLineHeight && isLastEntry ? (
                <div
                    styleName={isSmall ? 'end-circle--small' : 'end-circle'}
                    style={{
                        position: 'absolute',
                        left: isSmall
                            ? LAST_ENTRY_SMALL_LEFT_PADDING_OFFSET
                            : LAST_ENTRY_LEFT_PADDING_OFFSET,
                    }}
                />
            ) : (
                undefined
            )}
        </div>
    );
}
