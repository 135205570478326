/* @flow */

import {useMutation} from '@apollo/react-hooks';

import type {
    CreateReaction as CreateReactionMutation,
    CreateReactionVariables as CreateReactionMutationVariables,
    DeleteReaction as DeleteReactionMutation,
    DeleteReactionVariables as DeleteReactionMutationVariables,
    ReactionType,
    SessionUser,
} from 'nutshell-graphql-types';

import EVENT_FRAGMENT_SPARSE from '../graphql/fragments/event-fragment-sparse.graphql';
import CREATE_REACTION from './graphql/mutations/create-reaction.graphql';
import DELETE_REACTION from './graphql/mutations/delete-reaction.graphql';

export type ReactionToggleAction = 'create' | 'delete';

function useCreateReaction(): ({
    changeLogId: string,
    reactionType: ReactionType,
    user: SessionUser,
    parentChangeLogId: string,
}) => Promise<*> {
    const [createReaction] = useMutation<CreateReactionMutation, CreateReactionMutationVariables>(
        CREATE_REACTION
    );

    return (reactionInput: {
        changeLogId: string,
        reactionType: ReactionType,
        user: SessionUser,
        parentChangeLogId: string,
    }) => {
        return createReaction({
            variables: {
                input: {
                    changeLogId: reactionInput.changeLogId,
                    userId: reactionInput.user.id,
                    type: reactionInput.reactionType,
                },
            },
            optimisticResponse: {
                __typename: 'Mutation',
                reactionCreate: {
                    __typename: 'ReactionCreatePayload',
                    reaction: {
                        __typename: 'Reaction',
                        id: 'temp',
                        createdTime: '',
                        creatorUser: {
                            __typename: 'User',
                            id: reactionInput.user.id,
                            name: reactionInput.user.name,
                        },
                        reactionType: reactionInput.reactionType,
                    },
                },
            },
            update: (proxy, mutationResults) => {
                const eventFragment = proxy.readFragment({
                    id: reactionInput.parentChangeLogId,
                    fragmentName: 'EventFragmentSparse',
                    fragment: EVENT_FRAGMENT_SPARSE,
                });

                if (eventFragment) {
                    const newReaction =
                        mutationResults &&
                        mutationResults.data &&
                        mutationResults.data.reactionCreate &&
                        mutationResults.data.reactionCreate.reaction;

                    if (!newReaction) return;

                    let updatedData = {};

                    // If the parentChangeLogId and changeLogId are different, then the reaction
                    // is being added to a comment.
                    if (reactionInput.parentChangeLogId !== reactionInput.changeLogId) {
                        // Find the event's comment with a matching changeLogId
                        const commentIndex = eventFragment.comments.findIndex(
                            (comment) =>
                                comment.changeLogEntry &&
                                comment.changeLogEntry.id === reactionInput.changeLogId
                        );

                        if (commentIndex !== -1) {
                            const updatedComments = [...eventFragment.comments];

                            // Add the new reaction to this comment's list of reactions
                            updatedComments[commentIndex].reactions.push({
                                ...newReaction,
                                __typename: 'Reaction',
                            });

                            updatedData = {
                                comments: updatedComments,
                            };
                        }
                    } else {
                        // Otherwise, we update the top-level reactions for the event
                        updatedData = {
                            reactions: eventFragment.reactions.concat({
                                ...newReaction,
                                __typename: 'Reaction',
                            }),
                        };
                    }

                    proxy.writeFragment({
                        id: reactionInput.parentChangeLogId,
                        fragment: EVENT_FRAGMENT_SPARSE,
                        fragmentName: 'EventFragmentSparse',
                        data: {
                            ...eventFragment,
                            __typename: 'TimelineEvent',
                            ...updatedData,
                        },
                    });
                }
            },
        });
    };
}

function useDeleteReaction(): ({
    changeLogId: string,
    reactionType: ReactionType,
    user: SessionUser,
    parentChangeLogId: string,
}) => Promise<*> {
    const [deleteReaction] = useMutation<DeleteReactionMutation, DeleteReactionMutationVariables>(
        DELETE_REACTION
    );

    return (reactionInput: {
        changeLogId: string,
        reactionType: ReactionType,
        user: SessionUser,
        parentChangeLogId: string,
    }) => {
        return deleteReaction({
            variables: {
                input: {
                    changeLogId: reactionInput.changeLogId,
                    userId: reactionInput.user.id,
                    type: reactionInput.reactionType,
                },
            },
            optimisticResponse: {
                reactionDelete: {
                    __typename: 'ReactionDeletePayload',
                    reactionDeleted: true,
                },
            },
            update: (proxy) => {
                const eventFragment = proxy.readFragment({
                    id: reactionInput.parentChangeLogId,
                    fragmentName: 'EventFragmentSparse',
                    fragment: EVENT_FRAGMENT_SPARSE,
                });

                if (eventFragment) {
                    let updatedData = {};

                    // If the parentChangeLogId and changeLogId are different, then the reaction
                    // is for a comment.
                    if (reactionInput.parentChangeLogId !== reactionInput.changeLogId) {
                        const commentIndex = eventFragment.comments.findIndex(
                            (comment) =>
                                comment.changeLogEntry &&
                                comment.changeLogEntry.id === reactionInput.changeLogId
                        );

                        if (commentIndex !== -1) {
                            const updatedComments = [...eventFragment.comments];

                            // Remove the reaction
                            updatedComments[commentIndex].reactions = updatedComments[
                                commentIndex
                            ].reactions.filter(
                                (reaction) =>
                                    reaction.reactionType !== reactionInput.reactionType ||
                                    reaction.creatorUser.id !== reactionInput.user.id
                            );

                            updatedData = {
                                comments: updatedComments,
                            };
                        }
                    } else {
                        updatedData = {
                            reactions: eventFragment.reactions.filter(
                                (reaction) =>
                                    reaction.reactionType !== reactionInput.reactionType ||
                                    reaction.creatorUser.id !== reactionInput.user.id
                            ),
                        };
                    }

                    proxy.writeFragment({
                        id: reactionInput.parentChangeLogId,
                        fragment: EVENT_FRAGMENT_SPARSE,
                        fragmentName: 'EventFragmentSparse',
                        data: {
                            ...eventFragment,
                            __typename: 'TimelineEvent',
                            ...updatedData,
                        },
                    });
                }
            },
        });
    };
}

export function useToggleReaction(): ({
    changeLogId: string,
    reactionType: ReactionType,
    user: SessionUser,
    toggleAction: ReactionToggleAction,
    parentChangeLogId?: string,
}) => Promise<*> {
    const createReaction = useCreateReaction();
    const deleteReaction = useDeleteReaction();

    return (toggleReactionInput: {
        changeLogId: string,
        reactionType: ReactionType,
        user: SessionUser,
        toggleAction: ReactionToggleAction,
        parentChangeLogId?: string,
    }) => {
        const {changeLogId, reactionType, user, toggleAction} = toggleReactionInput;

        // If a parentChangeLogId is passed in, then that will be the timeline entry we will
        // update the cache for with the mutation results. Otherwise, the changeLogId is that entry.
        const parentChangeLogId = toggleReactionInput.parentChangeLogId
            ? toggleReactionInput.parentChangeLogId
            : changeLogId;

        return toggleAction === 'create'
            ? createReaction({changeLogId, reactionType, user, parentChangeLogId})
            : deleteReaction({changeLogId, reactionType, user, parentChangeLogId});
    };
}
