/* @flow */

import {createReducer} from 'redux-act';

import * as actions from './chat-actions';

export const defaultChatState = {
    timeseriesChart: {
        isLoading: true,
        isTimedOut: false,
        isErrored: false,
        chartData: null,
        prefix: '$',
    },
};

export const chatReducer = createReducer(
    {
        // $FlowIgnore - this works correctly from redux-act
        [actions.requestReportData]: (state) => {
            const newChartState = {
                ...state.timeseriesChart,
                isLoading: true,
                isTimedOut: false,
                isErrored: false,
            };

            return {...state, timeseriesChart: newChartState};
        },
        [actions.updateReportChartData]: (state, {chartData}) => {
            const newChartState = {
                ...state.timeseriesChart,
                isLoading: false,
                isTimedOut: false,
                isErrored: false,
                chartData,
            };

            return {...state, timeseriesChart: newChartState};
        },
        [actions.failReportChartData]: (state, err) => {
            if (err) {
                if (err.status === 504 || err.status === 502) {
                    const newChartState = {
                        ...state.timeseriesChart,
                        chartData: null,
                        isLoading: false,
                        isTimedOut: true,
                        isErrored: false,
                    };

                    return {...state, timeseriesChart: newChartState};
                } else {
                    const newChartState = {
                        ...state.timeseriesChart,
                        chartData: null,
                        isLoading: false,
                        isTimedOut: false,
                        isErrored: true,
                    };

                    return {...state, timeseriesChart: newChartState};
                }
            }

            return state;
        },
    },
    defaultChatState
);
