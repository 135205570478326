/* @flow */

import type {ListItem, LeadId} from '../types';

type ListItemsState = {
    [id: LeadId]: ListItem,
};

/**
 * Helper to take an array of list items and bucket them by entity id
 * @param  {Object} prevState      - Our previous "byId" state
 * @param  {ListItem[]} listItems  - Array of list items to bucket
 *
 * @return {Object}                - New bucketed object of list items by entity id
 */
export function reduceListItemsByEntityId(prevState: ListItemsState, listItems: Array<ListItem>) {
    return listItems.reduce((newState, listItem: ListItem) => {
        newState[listItem.links.entity.id] = listItem;

        return newState;
    }, prevState);
}
