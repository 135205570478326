import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import PauseCircleIconSvg from './icon-pause-circle.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <PauseCircleIconSvg {...validProps} />;
}
SvgComponent.iconName = 'pause-circleIcon';

export const PauseCircleIcon = svgIconWrapper(SvgComponent);
