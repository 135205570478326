/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import {getSingularEntityType} from 'nutshell-core/utils/get-singular-entity-type';

import {RemoveButton} from '../button';
import {colors, type ColorName} from '../colors';

import './entity-pill.css';

const STYLED_PILL_TYPES = [
    'leads',
    'users',
    'teams',
    'contacts',
    'accounts',
    // straight color overrides
    'dark',
    'light',
    'colorName',
];

export class EntityPill extends React.Component<{
    name?: string | React.Node,
    sublabel?: string | React.Node,
    type: string,
    htmlUrl?: string,
    isLinkable: boolean,
    onRemove?: () => any,
    isSmall?: boolean,
    removeButtonTooltip?: string,
    colorName?: ColorName,
    tooltipText?: ?string,
}> {
    static defaultProps = {
        isLinkable: true,
        type: 'default',
    };

    render() {
        const entityName =
            this.props.name &&
            ((typeof this.props.name === 'string' && this.props.name.length) ||
                // typeof on React.Node returns an object :(
                typeof this.props.name === 'object') ? (
                <span>{this.props.name}</span>
            ) : (
                <span styleName='nameless'>Unnamed {getSingularEntityType(this.props.type)}</span>
            );

        const clickNavigatesToEntity = this.props.isLinkable && this.props.htmlUrl;

        const classes = classnames('container', {
            [`container-${this.props.type}`]: STYLED_PILL_TYPES.includes(this.props.type),
            'container-small': this.props.isSmall,
            'container-default': !STYLED_PILL_TYPES.includes(this.props.type),
            'container-unclickable': !this.props.onRemove && !clickNavigatesToEntity,
            'container-linkable': clickNavigatesToEntity,
        });

        const styles = {
            ...(this.props.colorName ? {backgroundColor: colors[this.props.colorName]} : {}),
        };

        return (
            <div styleName={classes} style={styles} data-tip={this.props.tooltipText}>
                {this.props.isLinkable && this.props.htmlUrl ? (
                    <a
                        styleName='entity-container'
                        onMouseDown={(e) => e.stopPropagation()}
                        href={this.props.htmlUrl}
                    >
                        {entityName}
                        <div styleName='sublabel'>{this.props.sublabel}</div>
                    </a>
                ) : (
                    <span styleName='entity-container'>
                        {entityName}
                        <div styleName='sublabel'>{this.props.sublabel}</div>
                    </span>
                )}
                {this.props.onRemove ? (
                    <RemoveButton
                        onClick={this.handleRemove}
                        size={this.props.isSmall ? 7 : 9}
                        tooltip={this.props.removeButtonTooltip}
                    />
                ) : null}
            </div>
        );
    }

    handleRemove = (e: SyntheticEvent<*>) => {
        e.stopPropagation();

        if (this.props.onRemove) {
            this.props.onRemove();
        }
    };
}
