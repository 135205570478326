import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Cancel from './icon-cancel.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Cancel {...validProps} />;
}
SvgComponent.iconName = 'cancel';

export const CancelIcon = svgIconWrapper(SvgComponent);
