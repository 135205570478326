import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SidebarIconContacts from './sidebar-icon-contacts.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SidebarIconContacts {...validProps} />;
}
SvgComponent.iconName = 'sidebarIconContacts';

export const SidebarIconContactsIcon = svgIconWrapper(SvgComponent);
