/* @flow */

import * as ramda from 'ramda';
import {createSelector} from 'reselect';
import type {ContactId, TaskId, Task} from '../types';

import {Teams} from '../teams';
import {Users} from '../users';
import type {TasksState} from './tasks-reducer';
import type {TasksModuleState} from './tasks-types';

/*
 * Helper: Get the tasks related to a particular contact
 *
 * This allows other selectors to take an argument of contactId, and return just the tasks
 * associated with that contact.
 */
export const getTasksForContact = (state: TasksModuleState, contactId: ?ContactId) => {
    return createSelector(
        [getAllTasks],
        // $FlowFixMe upgrading Flow to v0.92.1
        (allTasks) => allTasks.filter((task) => contactId && task.links.relatedEntity === contactId)
    )(state);
};

export const getTasksState = (state: TasksModuleState): TasksState => state.tasks;

/*
 * Get the `byId` portion of the tasks state
 */
const getById = (state: TasksModuleState) => getTasksState(state).byId;

/*
 * Get the `errors` portion of the tasks state
 */
const getErrors = (state: TasksModuleState) => getTasksState(state).errors;

// $FlowFixMe upgrading Flow to v0.92.1
export const getAllTasks = createSelector([getTasksState], (tasksState): Task[] =>
    ramda.values(tasksState.byId)
);

/*
 * Get a particular task, using its api id.
 */
export const getTask = (state: TasksModuleState, taskId: TaskId) => getById(state)[taskId];

/*
 * Given a task id, return a boolean of whether it has an error
 */
export const isTaskErrored = (state: TasksModuleState, taskId: TaskId) =>
    Boolean(getErrors(state)[taskId]);

/*
 * Get an array of overdue tasks for a particular contact
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getOverdueTasksForContact = createSelector([getTasksForContact], (tasksForContact) => {
    return tasksForContact.filter((task) => task.isOverdue);
});

/*
 * Get an array of tasks for a particular contact that are not overdue
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getUpcomingTasksForContact = createSelector(
    [getTasksForContact],
    (tasksForContact) => {
        return tasksForContact.filter((task) => !task.isOverdue);
    }
);

// $FlowFixMe upgrading Flow to v0.92.1
export const contactHasTasks = createSelector([getTasksForContact], (tasksForContact) => {
    return tasksForContact.length > 0;
});

/*
 * This creates a function which takes a task id, and returns a selector which in turn
 * will provide an assignee object.
 *
 * We haven't used selectors to combine elements from different reducers too often,
 * so maybe there is a better way to do this, but it does seem to work decently well.
 */
export const getAssigneeGetter = function(taskId: TaskId) {
    // $FlowFixMe upgrading Flow to v0.92.1
    return createSelector(
        [getById, Teams.getById, Users.getById],
        (tasksById, teamsById, usersById) => {
            const task = tasksById[taskId];
            if (task) {
                const assigneeId = task.links.assignee;
                const user = usersById[assigneeId];
                const team = teamsById[assigneeId];

                return user || team || null;
            }

            return null;
        }
    );
};
