/* @flow */

import * as React from 'react';
import type Moment from 'moment';
import {getCalendarWeeks, getCalendarSixWeeks, getCalendarSevenDays} from 'nutshell-core';
import {isToday, isBetween, isEqualDate} from 'nutshell-core/date-time';
import {CalendarDay} from './calendar-day';
import {WEEK, MONTH} from './calendar-constants';
import {CalendarDayPlaceholder} from './calendar-day-placeholder';
import './calendar-dates.css';

type CalendarDisplayMode = WEEK | MONTH;
type Props = {
    momentDisplayDate: Moment,
    displayMode: CalendarDisplayMode,
    selectedDates: Moment[],
    comparisonDates: Moment[],
    quickViews: Object,
    hoveredDates: Moment[],
    onDateClick: (Moment) => any,
    isTodayHighlighted: boolean,
    minDate?: Moment,
    maxDate?: Moment,
    onDateMouseOver?: (Moment) => any,
    onDateMouseOut?: (Moment) => any,
    showAdjacentMonthDates?: boolean,
    useComparisonMode?: boolean,
    hasRoundedDates?: boolean,

    // One-off hack to prevent showing week mode selection in the middle
    alwaysStartOnSunday?: boolean,
};

export class CalendarDates extends React.Component<Props> {
    static defaultProps = {
        hoveredDates: [],
        selectedDates: [],
        comparisonDates: [],
        displayMode: MONTH,
        isTodayHighlighted: true,
    };

    render() {
        return (
            <div styleName='container'>
                {this.props.displayMode === WEEK
                    ? this.getElementsForWeek()
                    : this.getElementsForMonth()}
            </div>
        );
    }

    getElementsForWeek() {
        const daysToDisplay = getCalendarSevenDays(
            this.props.momentDisplayDate,
            this.props.alwaysStartOnSunday
        );

        return <div styleName='week'>{this.getDayElements(daysToDisplay)}</div>;
    }

    getElementsForMonth() {
        const weekArray = this.props.showAdjacentMonthDates
            ? getCalendarSixWeeks(this.props.momentDisplayDate)
            : getCalendarWeeks(this.props.momentDisplayDate);

        return weekArray.map((week) => {
            const firstValidDateInWeek = week.find((day) => day);

            return (
                <div key={firstValidDateInWeek.toString()} styleName='week'>
                    {this.getDayElements(week)}
                </div>
            );
        });
    }

    getDayElements(week: Moment[]): React.Element<*>[] {
        return week.map((day, i) => {
            if (!day) {
                return (
                    <CalendarDayPlaceholder key={i} /> // eslint-disable-line react/no-array-index-key
                );
            }

            const isFirstDayOfRow = i === 0 || !week[i - 1];
            const isLastDayOfRow = i === 6 || !week[i + 1];

            return (
                <CalendarDay
                    key={day.toString()}
                    momentDate={day}
                    quickViews={this.getQuickViewsForDay(day)}
                    isToday={Boolean(isToday(day) && this.props.isTodayHighlighted)}
                    isDisabled={this.isDateDisabled(day)}
                    isOtherMonth={this.isDateOtherMonth(day)}
                    onClick={this.props.onDateClick}
                    onMouseOver={this.props.onDateMouseOver}
                    onMouseOut={this.props.onDateMouseOut}
                    isSelected={this.isDateSelected(day)}
                    isSelectedForComparison={this.isDateSelectedForComparison(day)}
                    isFirstDayOfRow={isFirstDayOfRow}
                    isLastDayOfRow={isLastDayOfRow}
                    isStartOfSelectedRange={this.isDateStartOfSelectedRange(day)}
                    isEndOfSelectedRange={this.isDateEndOfSelectedRange(day)}
                    isPartOfSelectedRange={this.isDatePartOfSelectedRange(day)}
                    isStartOfSelectedComparisonRange={this.isDateStartOfSelectedComparisonRange(
                        day
                    )}
                    isEndOfSelectedComparisonRange={this.isDateEndOfSelectedComparisonRange(day)}
                    isPartOfSelectedComparisonRange={this.isDatePartOfSelectedComparisonRange(day)}
                    isStartOfHoveredRange={this.isDateStartOfHoveredRange(day)}
                    isEndOfHoveredRange={this.isDateEndOfHoveredRange(day)}
                    isPartOfHoveredRange={this.isDatePartOfHoveredRange(day)}
                    hasRoundedDates={this.props.hasRoundedDates}
                />
            );
        });
    }

    isDateSelected = (momentDate: Moment) => {
        if (!this.props.selectedDates.length || this.props.selectedDates.length !== 1) {
            return false;
        } else {
            return isEqualDate(momentDate, this.props.selectedDates[0]);
        }
    };

    isDateSelectedForComparison = (momentDate: Moment) => {
        if (!this.props.comparisonDates.length || this.props.comparisonDates.length !== 1) {
            return false;
        } else {
            return isEqualDate(momentDate, this.props.comparisonDates[0]);
        }
    };

    isDateOtherMonth = (momentDate: Moment) => {
        return !momentDate.isSame(this.props.momentDisplayDate, 'month');
    };

    isDateDisabled = (momentDate: Moment) => {
        if (this.props.minDate && momentDate.isBefore(this.props.minDate, 'day')) {
            return true;
        }

        if (this.props.maxDate && momentDate.isAfter(this.props.maxDate, 'day')) {
            return true;
        }

        return false;
    };

    isDatePartOfSelectedRange = (momentDate: Moment) => {
        if (!this.props.selectedDates.length || this.props.selectedDates.length === 1) {
            return false;
        } else {
            return isBetween(momentDate, this.props.selectedDates[0], this.props.selectedDates[1]);
        }
    };

    isDateStartOfSelectedRange = (momentDate: Moment) => {
        if (!this.isDatePartOfSelectedRange(momentDate)) return false;

        return isEqualDate(momentDate, this.props.selectedDates[0]);
    };

    isDateEndOfSelectedRange = (momentDate: Moment) => {
        if (!this.isDatePartOfSelectedRange(momentDate)) return false;

        return isEqualDate(momentDate, this.props.selectedDates[1]);
    };

    isDatePartOfSelectedComparisonRange = (momentDate: Moment) => {
        if (
            !this.props.comparisonDates.length ||
            this.props.comparisonDates.length === 1 ||
            !this.props.useComparisonMode
        ) {
            return false;
        } else {
            return isBetween(
                momentDate,
                this.props.comparisonDates[0],
                this.props.comparisonDates[1]
            );
        }
    };

    isDateStartOfSelectedComparisonRange = (momentDate: Moment) => {
        if (!this.isDatePartOfSelectedComparisonRange(momentDate)) return false;

        return isEqualDate(momentDate, this.props.comparisonDates[0]);
    };

    isDateEndOfSelectedComparisonRange = (momentDate: Moment) => {
        if (!this.isDatePartOfSelectedComparisonRange(momentDate)) return false;

        return isEqualDate(momentDate, this.props.comparisonDates[1]);
    };

    /**
     * Determines if momentDate is part of a hovered range
     * @param  {moment} momentDate Date of current calendar date being hovered
     * @return {bool}        If date is part of hovered range
     */
    isDatePartOfHoveredRange = (momentDate: Moment) => {
        if (!this.props.hoveredDates.length || this.props.hoveredDates.length === 1) {
            return false;
        } else {
            return isBetween(momentDate, this.props.hoveredDates[0], this.props.hoveredDates[1]);
        }
    };

    isDateStartOfHoveredRange = (momentDate: Moment) => {
        if (!this.isDatePartOfHoveredRange(momentDate)) return false;

        return isEqualDate(momentDate, this.props.hoveredDates[0]);
    };

    isDateEndOfHoveredRange = (momentDate: Moment) => {
        if (!this.isDatePartOfHoveredRange(momentDate)) return false;

        return isEqualDate(momentDate, this.props.hoveredDates[1]);
    };

    getQuickViewsForDay = (day: Moment) => {
        if (!this.props.quickViews) {
            return undefined;
        }

        // Ensure we return an empty array if no quickViews at that index exist
        // so our day component at least knows to style itself like quickViews
        // _might_ exist
        return this.props.quickViews[day.format('YYYY-MM-DD')]
            ? this.props.quickViews[day.format('YYYY-MM-DD')]
            : [];
    };
}
