/* @flow */

import {formatNumberWithMetrixPrefix} from 'nutshell-core/utils/format-number-with-metric-prefix';

/**
 * Helper to map table data to channel buckets to display with the attribution report
 *
 * @param  {Object} tableData - Table data to map to channel buckets
 * @return {Object} tableData - Not exactly the same type, but works to display the needed info
 */
export function mapTableDataToChannelBuckets(
    tableData: Object
): {
    rows: {title: string, totalsByReport: Object}[],
    totals: {title: string, totalsByReport: Object},
} {
    let channelBuckets = [];

    if (tableData && tableData.rows) {
        const buckets = tableData ? tableData.rows.map((row) => row.segments) : [];
        channelBuckets = [].concat(...buckets).reduce((acc, seg) => {
            const segTotals = seg.totalsByReport[0];
            const segCount = segTotals.count ? segTotals.count.value : 0;
            const segTotal = segTotals.total ? segTotals.total.value : 0;
            const segPrefix = segTotals.total ? segTotals.total.prefix : '';

            const bucket = acc.find((channelBucket) => channelBucket.title === seg.title);

            if (bucket) {
                bucket.totalsByReport[0].count = formatNumberWithMetrixPrefix(
                    bucket.totalsByReport[0].count.value + segCount,
                    ''
                );
                bucket.totalsByReport[0].total = formatNumberWithMetrixPrefix(
                    bucket.totalsByReport[0].total.value + segTotal,
                    segPrefix
                );
            } else {
                return acc.concat({
                    title: seg.title,
                    totalsByReport: [
                        {
                            id: segTotals.id,
                            total: formatNumberWithMetrixPrefix(segTotal, segPrefix),
                            count: formatNumberWithMetrixPrefix(segCount, ''),
                            winRate: 0,
                        },
                    ],
                });
            }

            return acc;
        }, []);
    }

    return {
        rows: channelBuckets,
        totals: {title: 'Totals', totalsByReport: tableData ? tableData.totals.totalsByReport : {}},
    };
}
