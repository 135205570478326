/* @flow */

import * as React from 'react';

import type {ColorName} from '../colors';

import {EntityPill} from './entity-pill';

type Props = {
    value: Object,
    sublabel?: string | React.Node,
    onRemove?: (value: Object) => any,
    type?: string,
    colorName?: ColorName,
    tooltipText?: ?string,
};

export class EntityPillSelectWrapperSmall extends React.Component<Props> {
    render() {
        return (
            <EntityPill
                {...this.props.value}
                sublabel={this.props.sublabel}
                type={this.props.type}
                isSmall={true}
                onRemove={this.props.onRemove ? this.handleRemove : undefined}
                colorName={this.props.colorName}
            />
        );
    }

    handleRemove = () => {
        if (typeof this.props.onRemove === 'function') {
            this.props.onRemove(this.props.value);
        }
    };
}
