/* @flow */

import moment from 'moment';
import {createSelector} from 'reselect';
import _ from 'underscore';
import * as ramda from 'ramda';

import type {SerializedRelationshipFilter} from 'nutshell-core/deserialize-relationship';
import {Session} from 'nutshell-core/session';
import {Routing} from 'nutshell-core/routing';
import {formatDateForHeader} from 'shells/date-header/format-date-for-header';

const getActivitiesState = (state) => state.dashboard.activities;
const getUiState = (state) => state.dashboard.ui;
const getActivityConfigState = (state) => state.dashboard.activitiesConfig;

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getActivities = createSelector([getActivitiesState], (activitiesState) => {
    return activitiesState.items;
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getTotalOverdueCount = createSelector([getActivitiesState], (activitiesState) => {
    return activitiesState.totalOverdueCount;
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getOverdueItemIds = createSelector([getUiState], (uiState) => {
    return uiState.activityOverdueItems;
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getActivityListItemIds = createSelector([getUiState], (uiState) => {
    return uiState.activityListItems;
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getActivityCalendarItemIds = createSelector([getUiState], (uiState) => {
    return uiState.activityCalendarItems;
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getIsRequesting = createSelector([getUiState], (uiState) => {
    return uiState.activityListIsRequesting;
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getOverdueListIsRequesting = createSelector([getUiState], (uiState) => {
    return uiState.activityOverdueListIsRequesting;
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getCanUseFilters = createSelector(
    [getIsRequesting, getOverdueListIsRequesting],
    (isRequesting, isOverdueRequesting) => {
        return !isRequesting && !isOverdueRequesting;
    }
);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getListErrorMessage = createSelector([getUiState], (uiState) => {
    return uiState.activityListErrorMessage;
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getCalendarErrorMessage = createSelector([getUiState], (uiState) => {
    return uiState.calendarListErrorMessage;
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getCalendarDisplayMode = createSelector([getUiState], (uiState) => {
    return uiState.activityCalendarDisplayMode;
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getHasImportantActivities = createSelector([getUiState], (uiState) => {
    return uiState.hasImportantActivities;
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getActivityNextLink = createSelector(
    [getActivityConfigState],
    (activityConfigState) => {
        return activityConfigState.nextUrl;
    }
);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const shouldLoadMoreActivities = createSelector([getActivityNextLink], (nextLink) => {
    return typeof nextLink === 'string' || typeof nextLink === 'undefined';
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getActivityPreviousLink = createSelector(
    [getActivityConfigState],
    (activityConfigState) => {
        return activityConfigState.previousUrl;
    }
);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getDisplayDate = createSelector([getActivityConfigState], (activityConfigState) => {
    return activityConfigState.displayDate;
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getScrolledToDate = createSelector([getActivityConfigState], (activityConfigState) => {
    return activityConfigState.scrolledToDate;
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getFormattedDisplayDateForList = createSelector(
    [getScrolledToDate],
    (scrolledToDate) => {
        const date = moment(scrolledToDate);

        return formatDateForHeader(date, 'ddd, MMMM Do');
    }
);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getFilter = createSelector([getActivityConfigState], (activityConfigState) => {
    return activityConfigState.filter;
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getOverdueListIsExpanded = createSelector([getUiState], (uiState) => {
    return uiState.activityOverdueListIsExpanded;
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getDeparamedFilters = createSelector(
    [getFilter, Session.getSessionStateUserId],
    (filter, sessionUserId) => {
        const deparamedFilters = Routing.deparam(filter);

        // We don't seed our participant filter, but we always want one, so if it
        // doesn't exist we want to default it to the sessionUserId
        if (!deparamedFilters.participant) {
            deparamedFilters.participant = {
                anyAll: 'any',
                data: [{data: sessionUserId}],
            };
        }

        return deparamedFilters;
    }
);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getCalendarItems = createSelector(
    [getActivityCalendarItemIds, getActivities, getDeparamedFilters],
    (activityCalendarItemIds, activities, deparamedFilters) => {
        return activityCalendarItemIds
            .map((id) => activities[id])
            .filter((activity) => activityFilterFunc(activity, deparamedFilters));
    }
);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getAllActivities = createSelector(
    [getActivityListItemIds, getActivities],
    (activityIds, activityItems) => {
        return activityIds.map((id) => activityItems[id]).filter((item) => item);
    }
);

/**
 * Selector that takes in all activities and expands those that are AllDay and span multiple days.
 * The selector takes each AllDay activity, and creates a new activity for each day of the activity after the startTime.
 * Creating these additional acitivites with the same id, but different startTime allows the UI to display the activity
 * on all of the days that it exists on.
 *
 * @param {array(activities)}    - Array of upcoming activities
 * @return {Function}            - Selector to expand AllDay activities into an activity for each day the
 *                                 AllDay activity spans
 */
// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getExpandedActivities = createSelector([getAllActivities], (activities) => {
    return activities.reduce((accum, activity) => {
        const startDay = moment.unix(activity.startTime);
        const endDay = moment.unix(activity.endTime);

        // If an activity does not have an endTime (it always should)
        // don't attempt to expand the activity into multiple days,
        // as it'll go on forever.
        if (!startDay.isSame(endDay, 'day') && endDay.isValid()) {
            const currDay = startDay.clone().add(1, 'd');
            accum.push(activity);
            while (!currDay.isAfter(endDay, 'day')) {
                const newActivity = {
                    ...activity,
                    startTime: currDay.unix(),
                };
                // This allows the UI to correctly tag events spanning the entire day in an
                // activity with a range of days but not labeled as AllDay
                if (!currDay.isSame(endDay, 'day')) {
                    newActivity.isAllDay = true;
                } else if (!activity.isAllDay) {
                    // Add field so react knows to label this as the final day
                    newActivity.isLastDay = true;
                }
                accum.push(newActivity);
                currDay.add(1, 'd');
            }
        } else {
            accum.push(activity);
        }

        return accum;
    }, []);
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getActivityItems = createSelector(
    [getExpandedActivities, getDeparamedFilters, getDisplayDate],
    (activities, deparamedFilters, displayDate) => {
        return activities
            .filter((activity) => activityFilterFunc(activity, deparamedFilters))
            .filter((activity) => activity.startTime * 1000 >= displayDate);
    }
);

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getSortedActivities = createSelector([getActivityItems], (activities) => {
    return _.sortBy(activities, 'startTime');
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getActivityList = createSelector([getSortedActivities], (sortedActivities) => {
    const addHeaders = (list, activity) => {
        const startTime = activity.startTime;
        const lastHeader = ramda.findLast((item) => item && item.type === 'headers')(list);
        if (
            lastHeader &&
            activity &&
            !moment.unix(startTime).isSame(moment.unix(lastHeader.date), 'day')
        ) {
            list.push({type: 'headers', date: startTime});
        } else if (activity && typeof lastHeader === 'undefined') {
            list.push({type: 'headers', date: startTime});
        }
        list.push(activity);

        return list;
    };

    return ramda.reduce(addHeaders, [], sortedActivities);
});

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const getOverdueItems = createSelector(
    [getOverdueItemIds, getActivities],
    (overdueIds, activities) => {
        return overdueIds
            .map((id) => {
                return activities[id];
            })
            .filter((activity) => {
                return activity && activity.isOverdue && !activity.deletedTime;
            })
            .sort((a, b) => {
                if (a.startTime > b.startTime) {
                    return 1;
                } else if (a.startTime < b.startTime) {
                    return -1;
                } else {
                    return 0;
                }
            });
    }
);

const activityFilterFunc = (activity, filters) => {
    if (!activity) return false;
    if (activity.deletedTime) return false;

    if (filters.status && _.has(activity, 'isLogged')) {
        // Account for old (string) and new (object) types of filters
        const statusFilter = getStatusValue(filters.status);
        if (
            (statusFilter === 'logged' && !activity.isLogged) ||
            (statusFilter === 'scheduled' && activity.isLogged)
        ) {
            return false;
        }
    }

    if (filters.activityType && filters.activityType.data && activity.links) {
        const activityTypes = filters.activityType.data.map((item) => item.data);
        if (!activityTypes.includes(activity.links.activityType)) {
            return false;
        }
    }

    if (filters.participant && Array.isArray(filters.participant.data)) {
        const participantFilterIds = filters.participant.data.map((item) => item.data);
        const hasFilteredParticipant = activity.participants.some((participant) => {
            return participantFilterIds.includes(participant.id);
        });
        if (!hasFilteredParticipant) return false;
    }

    return true;
};

/**
 * Helper function to deal with status filter being either a simple string or a
 * SerializedRelationshipFilter.
 *
 * @param  {string|Object} statusFilter The status filter
 * @return {string}                     The value of the status filter
 */
const getStatusValue = (statusFilter: string | SerializedRelationshipFilter) => {
    if (typeof statusFilter === 'string') return statusFilter;

    return statusFilter.data[0].data;
};
