/* @flow */

import type {Url} from '../types';

/**
 * Extract a hyperlinkable url from a nutshell url object.
 *
 * Converts twitter handles to actual urls.
 *
 * @param  {[type]} url [description]
 * @return {[type]}     [description]
 */
export function getUrlHref(url: Url): string {
    if (url.name.toLowerCase() === 'twitter') {
        return `https://twitter.com/${url.value.replace('@', '')}`;
    }

    return url.value;
}
