/* @flow */

import type {FilterObject} from './types';

/**
 * Takes the current filters and a new filterObj and either updates
 * the existing version of that filter or tacks it on as a new filter to
 * our filters state.
 *
 * @param  {array} currentFilters  Already existing array of filters
 * @param  {object} filterObj  New filter object to apply to existing filters
 *
 * @return {array} New, updated array of filters
 */
export function applyFilterSubmission(
    currentFilters: Array<FilterObject>,
    filterObj: FilterObject
): Array<FilterObject> {
    const newFilterName = Object.keys(filterObj)[0];

    const filterAlreadyExists = Boolean(
        currentFilters.find((filter) => {
            return Object.keys(filter)[0] === newFilterName;
        })
    );

    if (filterAlreadyExists) {
        // if filter exists but is now being assigned an empty string, remove it
        if (filterObj[newFilterName] === '') {
            return currentFilters.filter((filter) => {
                return Object.keys(filter)[0] !== Object.keys(filterObj)[0];
            });
        }

        return currentFilters.map((filter) => {
            return Object.keys(filter)[0] === Object.keys(filterObj)[0] ? filterObj : filter;
        });
    }

    return currentFilters.concat([filterObj]);
}
