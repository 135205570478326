import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox='0 0 2 10'>
            <path d='M0 0h2v10H0z' fill='currentColor' fillRule='nonzero' />
        </svg>
    );
}
SvgComponent.iconName = 'line-vertical-icon';

export const LineVerticalIcon = svgIconWrapper(SvgComponent);
