import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'28 -40 100 100'} fill='currentColor'>
            <path d='M128 55.8V47c0-4.2 0-4.2-4.2-4.2H32.2c-4.2 0-4.2 0-4.2 4.2v8.8c0 4.2 0 4.2 4.2 4.2h91.7c4.1 0 4.1 0 4.1-4.2zm0-82.9v-8.8c0-4.2 0-4.2-4.2-4.2H32.2C28-40 28-40 28-35.8v8.8c0 4.2 0 4.2 4.2 4.2h91.7c4.1-.1 4.1-.1 4.1-4.3zm0 42.5V4.6c0-4.2 0-4.2-4.2-4.2H32.2C28 .4 28 .4 28 4.6v10.8c0 4.2 0 4.2 4.2 4.2h91.7c4.1 0 4.1 0 4.1-4.2z' />
        </svg>
    );
}
SvgComponent.iconName = 'rows';

export const RowsIcon = svgIconWrapper(SvgComponent);
