/* @flow */

import {useMutation} from '@apollo/react-hooks';

import type {
    EditTag as EditTagMutation,
    EditTagVariables as EditTagMutationVariables,
    EditTagInput,
} from 'nutshell-graphql-types';

import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import {bustManagedCollectionCache} from '../../../../util/bust-managed-collection-cache';
import {nutApolloClient} from '../../../../apollo-client';

import UPDATE_TAG from '../mutations/update-tag.graphql';
import TAG_BASIC_FRAGMENT from '../fragments/tag-basic.graphql';

export function useUpdateTag(): {
    updateTag: (EditTagInput) => Promise<*>,
    isUpdating: boolean,
    errorMessage: ?string,
} {
    const [editTag, {loading: isUpdating, error}] = useMutation<
        EditTagMutation,
        EditTagMutationVariables
    >(UPDATE_TAG);

    return {
        updateTag: (input: EditTagInput) =>
            editTag({
                variables: {tag: input},
                optimisticResponse: () => {
                    const cacheResult = nutApolloClient.readFragment({
                        id: input.tag.id,
                        fragment: TAG_BASIC_FRAGMENT,
                        fragmentName: 'TagBasic',
                    });

                    if (cacheResult) {
                        return {
                            editTag: {
                                __typename: 'TagEditPayload',
                                tag: {
                                    ...cacheResult,
                                    name: input.tag.name,
                                    color: input.tag.color,
                                },
                            },
                        };
                    } else {
                        return {};
                    }
                },
                update: (proxy, mutationResults) => {
                    const updatedTag =
                        mutationResults &&
                        mutationResults.data &&
                        mutationResults.data.editTag &&
                        mutationResults.data.editTag.tag;

                    const cacheResult = proxy.readFragment({
                        id: input.tag.id,
                        fragment: TAG_BASIC_FRAGMENT,
                        fragmentName: 'TagBasic',
                    });

                    if (cacheResult && updatedTag) {
                        proxy.writeFragment({
                            id: input.tag.id,
                            fragment: TAG_BASIC_FRAGMENT,
                            fragmentName: 'TagBasic',
                            data: updatedTag,
                        });
                    }
                },
            }).then(() => {
                bustManagedCollectionCache('tags');
            }),
        isUpdating,
        errorMessage: safelyGetGraphQLErrorMessage(error),
    };
}
