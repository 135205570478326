/* @flow */

import * as React from 'react';
import _ from 'underscore';
import {CloseButton} from 'shells/button';
import {TickCircleIcon, FrownyIcon, EmailIcon} from 'shells/icon';
import {type NotificationOptions} from '../../notifications/index';
import getClassesMixin from '../mixins/get-classes';

export type NotificationObj = {
    id: number,
    title: string,
    options: NotificationOptions,
};

type Props = {
    notification: NotificationObj,
    onClose: (NotificationObj) => void,
};

type State = {
    isOpening: boolean,
    isClosing: boolean,
};

export class Notification extends React.Component<Props, State> {
    openTimeout: ?TimeoutID;
    closeTimeout: ?TimeoutID;
    getClasses = getClassesMixin;

    constructor(props: Props) {
        super(props);
        this.state = {
            isOpening: true,
            isClosing: false,
        };

        this.openTimeout = setTimeout(() => this.setState({isOpening: false}), 1600);
        this.closeTimeout = setTimeout(() => this.handleClose(), 8000);
    }

    componentWillUnmount() {
        clearTimeout(this.openTimeout);
        clearTimeout(this.closeTimeout);
    }

    render() {
        const {
            title,
            options: {body},
        } = this.props.notification;

        const classes = this.getClasses('ui-notifications__notification', {
            'ui-notifications__notification--on-animate-in': this.state.isOpening,
            'ui-notifications__notification--on-animate-out': this.state.isClosing,
        });

        return (
            <li
                className={classes}
                onClick={this.handleClick}
                onMouseOver={this.handleMouseOver}
                onMouseOut={this.handleMouseOut}
            >
                {this.renderIcon()}
                <div className='ui-notification__content'>
                    <div className='ui-notification__title'>{title}</div>
                    <div className='ui-notification__body'>{body}</div>
                </div>
                <CloseButton className='ui-notification__close' onClick={this.handleClose} />
            </li>
        );
    }

    renderIcon = () => {
        const iconName = this.props.notification.options.icon;
        let icon;
        switch (iconName) {
            case 'frowny':
                icon = <FrownyIcon size={55} />;
                break;
            case 'check':
                icon = <TickCircleIcon size={55} />;
                break;
            case 'email':
                icon = <EmailIcon size={55} fill='#fff' />;
                break;
            default:
                return undefined;
        }

        return <div className='ui-notification__icon'>{icon}</div>;
    };

    handleClose = (e?: SyntheticEvent<*>) => {
        if (e && e.stopPropagation) e.stopPropagation();
        clearTimeout(this.closeTimeout);
        clearTimeout(this.openTimeout);
        // Wait half a second after state is set for animation before we remove the node
        this.setState({isClosing: true});
        _.delay(() => this.props.onClose(this.props.notification), 700);
    };

    handleClick = (e: SyntheticEvent<HTMLElement>) => {
        const {options} = this.props.notification;
        this.handleClose(e);
        if (options && options.onClick) options.onClick(e);
    };

    handleMouseOver = () => {
        clearTimeout(this.closeTimeout);
    };

    handleMouseOut = () => {
        this.closeTimeout = setTimeout(() => this.handleClose(), 7000);
    };
}
