/* @flow */

import type {AppState} from './app-reducer';

export const REDUCER_KEY = 'app';
export type ReducerKey = 'app';

export type AppModuleState = {
    [ReducerKey]: AppState,
};
