/* @flow */

import type {ReactingUser} from './types';

/**
 * Given a list of reacting users and the current user's id, will return true if that user
 * has reacted.
 *
 * @param reactingUsers
 * @param currentUserId
 * @returns boolean
 */
export function hasUserReacted(reactingUsers: ReactingUser[], currentUserId: string): boolean {
    return Boolean(reactingUsers.find((user) => user.id === currentUserId));
}

/**
 * Given a list of reacting users and the current user's id, will return a readable list of names
 * for all reacting users, subbing 'you' for the current user if it is in the list.
 *
 * @param reactingUsers
 * @param currentUserId
 * @returns string
 */
export function getListOfReactingUsers(
    reactingUsers: ReactingUser[],
    currentUserId: string
): string {
    let list = '';

    reactingUsers.forEach((user, index) => {
        const isLastItem = index === reactingUsers.length - 1;

        // Is the last item, but not the only item
        if (isLastItem && reactingUsers.length > 1) {
            list = list.concat('and ');
        }

        if (user.id === currentUserId) {
            list = list.concat(index === 0 ? 'You' : 'you');
        } else {
            list = list.concat(user.name);
        }

        // Is not the last item
        if (!isLastItem) {
            if (reactingUsers.length > 2) {
                // If more than two items, separate with a ', '
                list = list.concat(', ');
            } else if (reactingUsers.length === 2) {
                // If exactly two items, separate with a ' '
                list = list.concat(' ');
            }
        }
    });

    return list;
}
