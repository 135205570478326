/* @flow */

import * as React from 'react';

export function MessageAudienceGraph() {
    return (
        <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 251 436'>
            <g clipPath='url(#clip0)'>
                <path
                    d='M152.308 343.404c-.486.504-1.19.659-1.763.245-1.214-.614-2.358-1.301-3.574-2.056a1.492 1.492 0 111.508-2.574 53.613 53.613 0 003.43 1.917 1.585 1.585 0 01.674 2.039c-.139.144-.205.357-.275.429zm-10.022-6.605c-.555.575-1.404.591-2.049.108a27.205 27.205 0 01-3.16-2.63c-.576-.555-.663-1.473-.108-2.049.624-.648 1.542-.735 2.118-.18a25.598 25.598 0 003.016 2.491c.645.483.805 1.47.18 2.118.072.07.003.142.003.142zm-8.639-8.329l-.139.144c-.627.507-1.62.383-2.126-.244-.797-1.046-1.666-2.162-2.394-3.28-.437-.699-.242-1.622.385-2.129.699-.437 1.622-.242 2.129.386a25.083 25.083 0 002.319 3.069c.437.699.312 1.55-.174 2.054zm-6.339-10.139c-.139.144-.278.288-.419.291-.771.368-1.625.101-1.992-.671a61.743 61.743 0 01-1.626-3.789c-.226-.774.111-1.699.884-1.926.844-.298 1.628.041 1.926.884a27.473 27.473 0 001.551 3.579c.293.56.093 1.2-.324 1.632zm-3.814-11.316a1.872 1.872 0 01-.699.437c-.846.157-1.633-.324-1.79-1.169a67.529 67.529 0 01-.851-4.016c-.157-.846.395-1.563 1.241-1.72.846-.157 1.563.396 1.72 1.241.237 1.34.471 2.538.777 3.805.08.494-.051 1.062-.398 1.422zm-1.704-11.921c-.277.288-.557.435-.982.442a1.546 1.546 0 01-1.581-1.385 39.79 39.79 0 01-.075-4.101c-.016-.848.609-1.496 1.457-1.511.849-.016 1.497.609 1.512 1.457.024 1.273-.022 2.617.073 3.959.08.494-.126.851-.404 1.139zm.418-11.96c-.347.36-.769.509-1.265.448-.851-.126-1.36-.895-1.304-1.674.185-1.488.373-2.835.704-4.044.198-.781.967-1.29 1.818-1.165.781.198 1.29.967 1.164 1.818-.262 1.136-.449 2.483-.637 3.831-.136.285-.272.57-.48.786z'
                    fill='#0C0F33'
                />
            </g>
            <path
                d='M151.687 423L151 384h22.657l.343 39h-22.313zM185.716 423L185 367h23.642l.358 56h-23.284zM217.776 423L217 342h25.612l.388 81h-25.224z'
                fill='#7373C7'
            />
            <path
                d='M205.661 425.155c1.24 0 2.481-.095 3.817-.095.763 0 1.336-.668 1.336-1.432.095-3.244-.382-52.74-.287-55.985v-.858c0-.764-.572-1.432-1.336-1.527-.858 0-1.431.572-1.526 1.336v.859c-.096 2.862.381 51.976.286 54.744-5.344.191-5.688 0-10.936-.096-4.199-.095-8.493-.19-12.692-.19.287-5.153.191-1.402.096-6.555 0-3.435-.382-45.136-.287-48.571 0-.764-.572-1.432-1.431-1.432-.763 0-1.431.573-1.431 1.432-.096 3.53.286 45.231.286 48.667.095 5.534.095 2.26-.191 7.89 0 .382.096.763.382 1.05.286.286.668.477 1.05.477h.572c4.58-.096 9.161 0 13.646.095 4.485.096 4.065.191 8.646.191zM153.63 425.3h-3.817c-.763 0-1.336-.668-1.431-1.336 0-3.34-.191-34.659-.382-37.999v-.859c0-.763.573-1.431 1.336-1.526.859 0 1.527.667 1.527 1.431v.859c.191 2.862.286 33.8.381 36.567 5.344.096 10.688-.095 15.937-.286 4.198-.191 1.111-.056 5.309-.151.01-4.5 0-.632 0-5.785 0-3.435 0-27.96-.095-31.395 0-.763.573-1.431 1.431-1.431.764 0 1.432.572 1.432 1.431.095 3.531.095 28.055.095 31.49 0 5.535 0 1.261.477 6.891 0 .381-.095.763-.381 1.049-.287.287-.668.478-1.05.478h-.573c-4.58 0-2.16.19-6.645.381-4.295 0-8.97.191-13.551.191zM220.788 425.346h-3.817c-.764 0-1.336-.668-1.432-1.336-.095-3.245-.286-77.551-.477-80.796v-.858c0-.764.573-1.432 1.336-1.527.859-.096 1.431.572 1.527 1.336v.859c.191 2.862.286 76.787.381 79.554 5.344.096 10.688-.095 15.937-.286 4.198-.191 3.058-.197 7.257-.292 0-3.5.052-1.491.052-6.644 0-3.435 0-69.947-.095-73.382 0-.764.573-1.432 1.431-1.432.764 0 1.432.573 1.432 1.432.095 3.531.031 70.043.095 73.477.085 4.549.096 3.049.477 7.891 0 .381-.095.763-.381 1.049-.287.287-.668.478-1.05.478h-.573c-4.58 0-4.16.19-8.645.381-4.199-.095-8.875.096-13.455.096z'
                fill='#0C0F33'
            />
            <path
                d='M149.29 385.583a1.304 1.304 0 01-1.336-1.336c-.095-.763.573-1.431 1.336-1.527 4.199-.668 6.108-.668 12.024-.286 5.726.382 5.737.286 11.558.286.764 0 1.432.668 1.432 1.432 0 .763-.668 1.431-1.432 1.431-5.916 0-5.928.096-11.653-.286-5.726-.382-9.543-.382-11.642.191-.191.095-.287.095-.287.095zM183.369 368.025c-.764 0-1.431-.572-1.431-1.336 0-.763.572-1.431 1.335-1.527 4.867-.286 3.149.382 8.016.096 10.688-.668 6.165-.477 17.139-.382.763 0 1.431.668 1.431 1.431 0 .764-.668 1.432-1.431 1.432-10.879-.096-6.356-.382-16.948.286-4.867.382-3.149-.191-8.111 0zM216.398 344.359c-.668 0-1.24-.477-1.431-1.145-.096-.763.381-1.622 1.145-1.622 17.844-1.431 23.093-1.431 23.57-1.431 2.958 0 .63.095 3.111.381a1.544 1.544 0 011.336 1.527 1.542 1.542 0 01-1.527 1.336c-2.481-.191-.057-.382-2.92-.382-.382 0-10.402.096-22.998 1.432-.095-.096-.191-.096-.286-.096zM150.045 353.763c-.46-.654-.29-1.629.365-2.09 2.863-2.012 6.496-4.321 10.549-5.336-1.627-3.012-3.1-6.499-2.944-10.276.007-.86.686-1.46 1.546-1.453.86.006 1.459.685 1.453 1.545-.157 3.777 1.759 7.198 3.616 10.537.206.467.272.909.036 1.442-.212.394-.703.739-1.146.805-4.259.549-8.358 3.063-11.385 5.191-.655.46-1.63.29-2.09-.365z'
                fill='#0C0F33'
            />
            <g clipPath='url(#clip1)'>
                <path
                    d='M162.4 226.8c-7.6 0-15-6.7-15-11.7 0-.5-.1-.9-.2-1.5s-.2-1.4-.2-2.3c0-8.2 7.8-13.8 15.1-13.8.5 0 1 0 1.5.1 5.3.5 9.8 3.3 12.7 7.8 1.1 1.7 1.5 5.2 1.5 6.6-.1 9.1-9 14.8-15.4 14.8zm-.3-26.3c-5.8 0-12.1 4.3-12.1 10.8 0 .7.1 1.3.2 1.9.1.6.2 1.2.2 1.9 0 2.9 5.5 8.7 12 8.7 5 0 12.4-4.6 12.4-11.8 0-1.4-.4-4-1-5-2.4-3.7-6.1-6-10.4-6.4-.5-.1-.9-.1-1.3-.1z'
                    fill='#0C0F33'
                />
                <path
                    d='M153 247c-2 0-3.3-.3-4.3-1.2-1.4-1.3-1.5-3.4-1.5-6.4 0-7.2 4.1-13.6 9.9-15.8.8-.3 1.6.1 1.9.9.3.8-.1 1.6-.9 1.9-4.7 1.7-7.9 7-7.9 12.9 0 1.9 0 3.7.5 4.2.7.6 3.5.4 7.4.1 3.9-.3 9.6-.8 17.6-.8-.8-8.3-3.1-13.9-7.3-17.6-.6-.5-.7-1.5-.1-2.1.5-.6 1.5-.7 2.1-.1 6.7 5.9 8 14.9 8.4 21.3 0 .4-.1.8-.4 1.1-.3.3-.7.5-1.1.5-8.7 0-14.7.5-19 .8-2.1.2-3.9.3-5.3.3z'
                    fill='#0C0F33'
                />
                <path
                    d='M163.6 233.5c-1.5 0-2.3-1.2-3.2-2.4-.8-1.2-1.8-2.6-3.6-3.4-.8-.3-1.1-1.2-.7-2 .3-.8 1.2-1.1 2-.7 2.6 1.2 3.9 3.1 4.8 4.4.2.4.6.8.8 1 .5-.3 1.7-1.6 4.6-6.1.4-.7 1.4-.9 2.1-.5.7.4.9 1.4.5 2.1-3.8 5.8-5.5 7.5-7.3 7.6zM176.3 213.8c-.1 0-.1 0 0 0-4.4-.3-8.1-2.4-9.8-5.6-5.8 0-10.9-.3-15.5-.9-.8-.1-1.4-.9-1.3-1.7.1-.8.8-1.4 1.7-1.3 4.7.6 10 .9 16.1.9.6 0 1.2.4 1.4.9 1.3 3.2 4.7 4.5 7.5 4.6.8.1 1.5.8 1.4 1.6-.1.9-.7 1.5-1.5 1.5z'
                    fill='#0C0F33'
                />
                <path
                    d='M109.6 243.4c-1.5-6.3-1.1-12.9-2.9-19.1-.5-1.6-3.2-1.9-3.6 0-1.1 6.6-.1 13.5-.9 20.1-.6 5 8.8 4.8 7.4-1z'
                    fill='#FAC942'
                />
                <path
                    d='M105.4 223.2c-8 0-17.4-6.6-17.4-15.1 0-7.6 7.1-14.5 15.5-15 .7 0 1.3-.1 1.9-.1 10 0 16.2 5.3 16.2 13.9.1 10-8.4 16.3-16.2 16.3zm0-27.7c-.6 0-1.2 0-1.8.1-6.9.4-13.1 6.2-13.1 12.5 0 7 8.2 12.6 14.9 12.6 8.2 0 13.7-7.1 13.7-13.8 0-7.2-5.1-11.4-13.7-11.4z'
                    fill='#0C0F33'
                />
                <path
                    d='M91.1 250c-.6 0-1.1-.4-1.2-1-1-4.1-1.5-9.8-1.5-12.4 0-8.9 3.2-14.7 9.4-17.3.6-.3 1.4 0 1.6.7.3.6 0 1.4-.7 1.6-5.1 2.2-7.7 7.2-7.7 15 0 2.2.4 6.9 1.1 10.7 2.4-.2 4.8-.5 7.1-.7 5.8-.6 11.8-1.3 17.8-1.4V242c0-7-.8-14.9-6.6-20.5-.5-.5-.5-1.3 0-1.8s1.3-.5 1.8 0c6.5 6.2 7.4 14.8 7.4 22.3 0 1.3 0 2.7-.1 4.5 0 .7-.6 1.2-1.3 1.2-6.3 0-12.7.7-18.8 1.4-2.6.3-5.4.6-8.2.9h-.1z'
                    fill='#0C0F33'
                />
                <path
                    d='M105.5 230.6c-4.4 0-4.9-4.5-5.2-7.2 0-.4-.1-.8-.1-1.1-.1-.7.4-1.3 1.1-1.4.7-.1 1.3.4 1.4 1.1.1.4.1.8.1 1.2.4 3.5.9 4.9 2.7 4.9 2.7 0 2.7-1 2.7-1.4 0-1.4-.2-2.7-.5-4.2l1.2-.3 1.2-.2c.3 1.7.6 3.2.6 4.8.1 1-.4 3.8-5.2 3.8z'
                    fill='#0C0F33'
                />
                <path
                    d='M100.8 248.3c-.6 0-1.1-.4-1.2-1-.3-1.1-.4-2.1-.4-3 0-1 .7-10.8 2.4-15.9.2-.7.9-1 1.6-.8.7.2 1 .9.8 1.6-1.6 4.7-2.3 14.1-2.3 15.1 0 .8.1 1.5.3 2.4.2.7-.2 1.4-.9 1.5-.1.1-.2.1-.3.1zM112.8 246.8c-.5 0-1.1-.4-1.2-.9-.8-2.7-1.5-5.3-2.3-7.9-.8-2.6-1.5-5.2-2.3-7.9-.2-.7.2-1.4.9-1.6.7-.2 1.4.2 1.6.9.8 2.7 1.5 5.3 2.3 7.9.8 2.6 1.5 5.2 2.3 7.9.2.7-.2 1.4-.9 1.6-.2-.1-.3 0-.4 0zM92.2 215.9c-.3 0-.6-.1-.8-.3-.5-.5-.6-1.3-.1-1.8 4.1-4.6 4.8-7.4 5.1-9.1.6-2.7 1.5-3.5 5.6-3.5h5c4.6 0 8.5-.3 8.7-.4h.1c1.1 0 2.2.1 3.6.4.7.2 1.1.8 1 1.5-.2.7-.8 1.1-1.5 1-1.2-.3-2.1-.4-3-.4-.6.1-4.4.4-8.9.4h-5c-2.7 0-2.7 0-3.1 1.6-.4 1.9-1.2 5.2-5.7 10.3-.3.2-.6.3-1 .3z'
                    fill='#0C0F33'
                />
                <path
                    d='M124 195.2s-5.1-9.2 4.5-12.5c9.6-3.3 12.6 5.8 12.6 6.8s0 3-1 6c0 0 11.5 4.4 12.3 15.7.7 11.3.6 13.2-5.3 21.3 0 0 6 4 7 17s1 12 0 12-39 2-39 2-2.4 1.1-3.2-2.5c-.8-3.5-3.2-9-1.5-17.8 1.7-8.8 4.7-9.7 4.7-9.7s-9.4-8.8-9.2-16.4c.2-7.6 3-20.2 18.1-21.9z'
                    fill='#DBDBF2'
                />
                <path
                    d='M113.3 206.6c-.2.6-.4 1.2-.1 1.7.4.8 1.6.8 2.5.7 5-.5 10-.9 14.9-1.1 2.5-.1 5.3-.1 7.2 1.5.8.7 1.3 1.6 1.9 2.5 1.8 2.8 4.2 5.2 7 7 .4.3.9.5 1.2.3.2-.2.3-.5.4-.8 1-4.4-.2-9.1-2.8-12.7-2.7-3.6-6.7-6-11.1-7-.6-.1-1.3-.2-1.8-.6-.5-.4-.7-1.2-.3-1.7.3-.3.7-.4 1-.5 2.5-.8 3.9-4 2.8-6.4-1.1-2.4-4.3-3.5-6.6-2.2-2.3 1.3-3.1 4.6-1.6 6.8.6.9 1.5 1.8 1 2.7-.2.4-.6.6-1 .7-5.2 2.6-12.2 3-14.6 9.1z'
                    fill='#7373C7'
                />
                <path
                    d='M131.9 198.2c-4 0-6.4-2.6-6.4-7.1 0-3.1 1.5-5.1 4.2-5.5.4-.1.8-.1 1.2-.1 3.9 0 6.7 2.9 6.7 6.8.1 3.3-2.4 5.9-5.7 5.9zm-1.7-9.7c-.5.1-1.7.2-1.7 2.5 0 3.7 1.7 4.2 3.4 4.2s2.8-1.2 2.8-2.9c0-1.9-1.2-3.8-3.7-3.8-.2-.1-.5-.1-.8 0z'
                    fill='#0C0F33'
                />
                <path
                    d='M130.4 235.4c-12.8 0-20.5-9.9-20.5-19.4 0-10 7.7-18.5 17.2-19.1h1.6c12.3 0 21.5 7.9 21.5 18.3.1 12.4-10.2 20.2-19.8 20.2zm-3.1-35.6c-7.9.5-14.4 7.7-14.4 16.1 0 8.1 6.5 16.4 17.5 16.4 10.1 0 16.9-8.9 16.9-17.3 0-7.6-6.4-15.3-18.6-15.3-.4.1-.9.1-1.4.1z'
                    fill='#0C0F33'
                />
                <path
                    d='M115.5 260.5c-.7 0-1.3-.5-1.5-1.2-.6-2.6-.8-5.1-.8-8.4 0-9.8 3.1-16.2 9.1-19.1.7-.3 1.6 0 2 .7.3.7 0 1.6-.7 2-4.9 2.3-7.4 7.8-7.4 16.4 0 2.5.2 4.5.5 6.5 6.4-.1 12.9-.5 19.2-.8 4.4-.2 9-.5 13.5-.6-.1-6.7-1.6-18-10.5-22.9-.7-.4-1-1.3-.6-2s1.3-1 2-.6c10.5 5.7 12 18.8 12 26.1v.9c0 .8-.6 1.4-1.4 1.5-5 .2-10 .4-14.9.7-6.7.3-13.6.7-20.5.8z'
                    fill='#0C0F33'
                />
                <path
                    d='M131.1 242.2c-2.9 0-5.2-2.5-7-7.3-.2-.7 0-1.4.6-1.8.6-.4 1.4-.3 1.8.2l.2.2c.3.3.5.7.4 1.1 1.2 2.9 2.6 4.5 3.9 4.5 1 0 1.3-2.5 1.6-4.1.1-.5.1-1 .2-1.4.1-.8.9-1.3 1.7-1.2.8.1 1.3.9 1.2 1.7-.1.4-.1.8-.2 1.3-.2 2.6-.8 6.8-4.4 6.8zM147.9 221.8h-.3c-1.2-.2-3.3-2.2-10.1-9.4-.9-1-1.9-2-2.4-2.5-3.9 0-7.8.4-11.5.8-3.3.3-6.8.7-10.2.8-.8.1-1.5-.6-1.5-1.4 0-.8.6-1.5 1.4-1.5 3.3-.1 6.7-.4 10-.8 4-.4 8.2-.8 12.4-.8.7 0 .7 0 4 3.5 2.4 2.6 7.4 7.9 8.6 8.5.7.2 1.2 1 1 1.7 0 .6-.7 1.1-1.4 1.1z'
                    fill='#0C0F33'
                />
            </g>
            <g clipPath='url(#clip2)'>
                <path
                    d='M7.216 56.74l5.903-8.527 2.952 12.464 14.103 6.56 24.6 8.199 34.766-29.19-.329-7.872 5.576 4.264 7.564 43.584-86.936 12.5-8.2-41.981zM73.468 25.583L44.278 7.216 25.759 32.16l47.71-6.577z'
                    fill='#DBDBF2'
                />
                <path
                    d='M16.72 60.959L12.43 34.39l30.474-4.775L86.519 24.2l3.398 22.052-13.72 11.95-21.33 17.19-22.735-7.423-15.413-7.01z'
                    fill='#FAC942'
                />
                <path
                    d='M6.003 56.62c1.373 14.271 5.171 28.16 7.88 42.265.284 1.649 2.875 1.328 2.59-.322C13.767 84.458 9.969 70.57 8.596 56.3c-.206-1.66-2.72-1.347-2.592.322z'
                    fill='#0C0F33'
                />
                <path
                    d='M16.209 100.281c10.415-.25 21.155-3.028 31.473-4.71 17.259-2.945 34.792-4.32 51.982-7.817 1.629-.363 1.315-2.891-.324-2.608-16.886 3.38-34.056 5.11-51.128 7.631-10.651 1.564-21.597 4.607-32.327 4.896-1.67.047-1.345 2.654.324 2.608z'
                    fill='#0C0F33'
                />
                <path
                    d='M94.112 43.035c3.424 14.096 4.424 28.573 5.728 42.932.128 1.669 2.719 1.347 2.592-.322-1.304-14.359-2.304-28.836-5.729-42.932-.363-1.64-2.954-1.318-2.591.322zM17.593 97.943c6.205-9.114 11.978-18.496 19.312-26.867 1.257-1.44-1.04-3.24-2.297-1.8-7.344 8.292-13.107 17.752-19.312 26.866-1.09 1.5 1.207 3.3 2.297 1.8z'
                    fill='#0C0F33'
                />
                <path
                    d='M74.132 63.887c8.59 6.876 16.344 14.739 25.552 20.816 1.394.95 2.974-1.091 1.58-2.042-9.207-6.077-16.972-14.018-25.551-20.815-1.325-1.04-2.906 1.002-1.58 2.041zM10.845 35.883c1.845 8.435 2.286 17.125 4.523 25.512.442 1.63 2.788.616 2.347-1.014-2.12-8.08-2.531-16.533-4.357-24.81-.274-1.57-2.876-1.328-2.513.312z'
                    fill='#0C0F33'
                />
                <path
                    d='M12.524 35.273c24.346-3.023 48.585-6.914 72.931-9.937 1.65-.205 1.326-2.812-.324-2.607-24.346 3.023-48.584 6.914-72.93 9.937-1.729.214-1.405 2.822.323 2.607z'
                    fill='#0C0F33'
                />
                <path
                    d='M85.347 24.467l2.56 20.622c.108.869 2.7.547 2.592-.322l-2.56-20.622c-.284-1.65-2.798-1.337-2.592.322zM9.901 54.612a59.161 59.161 0 013.82-5.85c1.03-1.33-1.002-2.923-2.033-1.592-1.58 2.041-2.906 4.212-4.143 6.451-.785 1.381 1.57 2.372 2.356.991zM26.582 31.602c4.91-8.632 12.538-15.276 18.154-23.354.943-1.4-1.089-2.993-2.041-1.672-5.754 8.256-13.441 15.067-18.478 23.956-.854 1.47 1.58 2.451 2.366 1.07z'
                    fill='#0C0F33'
                />
                <path
                    d='M43.39 8.335a2979.32 2979.32 0 0126.8 16.73c1.384.871 2.964-1.17 1.58-2.041a2977.453 2977.453 0 00-26.798-16.73c-1.463-.861-3.044 1.18-1.581 2.041zM89.017 38.613c.471.583 1.011 1.077 1.551 1.572.726.712 1.463 1.503 2.385 1.87 1.512.615 2.572-1.763.982-2.368-.334-.12.089.069-.088-.07-.177-.138-.353-.277-.52-.336-.364-.356-.717-.633-1.08-.99a19.99 19.99 0 01-1.188-1.216c-.461-.504-1.207-.732-1.787-.26-.589.395-.716 1.293-.255 1.798zM6.318 57.224c7.823 3.28 14.852 7.863 22.735 10.976a311.926 311.926 0 0023.815 8.115c1.57.447 2.63-1.931.982-2.368-7.618-2.264-15.118-4.863-22.647-7.699-3.515-1.328-7.108-2.647-10.504-4.311-4.427-2.259-8.688-5.1-13.4-7.082-1.511-.614-2.493 1.754-.981 2.369z'
                    fill='#0C0F33'
                />
                <path
                    d='M55.243 76.18c7-3.356 12.39-8.758 18.133-13.884 7.177-6.427 14.57-12.399 21.472-19.112 1.218-1.114-.402-3.24-1.531-2.056C87.2 47.102 80.7 52.561 74.22 58.18c-6.41 5.53-12.155 11.938-19.91 15.629-1.59.679-.57 3.12.932 2.371z'
                    fill='#0C0F33'
                />
            </g>
            <g clipPath='url(#clip3)'>
                <path
                    d='M113.309 93.673c.521-.467 1.235-.57 1.777-.117 1.166.7 2.258 1.467 3.417 2.308a1.49 1.49 0 01.384 2.074c-.461.683-1.39.846-2.074.384a53.36 53.36 0 00-3.283-2.158 1.585 1.585 0 01-.526-2.082c.149-.134.231-.342.305-.409zm9.522 7.309c.595-.535 1.443-.49 2.052.039a27.362 27.362 0 012.963 2.849c.534.596.556 1.517-.039 2.052-.67.601-1.592.623-2.126.028a25.477 25.477 0 00-2.83-2.701c-.609-.529-.697-1.525-.027-2.126-.067-.075.007-.141.007-.141zm8.019 8.927l.149-.134c.662-.46 1.643-.266 2.103.396.72 1.101 1.507 2.276 2.152 3.444.386.729.125 1.635-.537 2.095-.729.386-1.635.125-2.095-.537a25.138 25.138 0 00-2.093-3.228c-.386-.729-.199-1.568.321-2.036zm5.595 10.568c.148-.134.297-.268.438-.26.796-.312 1.628.016 1.94.812a62.346 62.346 0 011.349 3.896c.17.788-.232 1.687-1.021 1.857-.862.237-1.62-.157-1.857-1.02a27.347 27.347 0 00-1.29-3.68c-.252-.58-.006-1.205.441-1.605zm2.991 11.561a1.87 1.87 0 01.729-.386c.855-.096 1.605.44 1.701 1.295.214 1.286.421 2.713.561 4.066.096.855-.507 1.531-1.362 1.627-.855.095-1.531-.507-1.626-1.362a61.288 61.288 0 00-.502-3.851c-.044-.498.127-1.055.499-1.389zm.844 12.012c.297-.267.587-.393 1.011-.371a1.547 1.547 0 011.477 1.496 39.734 39.734 0 01-.219 4.095c-.045.848-.715 1.449-1.562 1.404-.848-.046-1.449-.715-1.404-1.563.068-1.271.211-2.608.212-3.954-.044-.498.187-.84.485-1.107zm-1.276 11.899a1.427 1.427 0 011.294-.356c.84.187 1.292.99 1.18 1.763-.291 1.472-.575 2.802-.992 3.983-.254.766-1.057 1.218-1.897 1.032-.765-.254-1.218-1.057-1.031-1.897.343-1.114.627-2.445.911-3.775.156-.275.312-.55.535-.75z'
                    fill='#0C0F33'
                />
            </g>
            <path
                d='M129.047 159.689c.618-.509 1.603-.414 2.112.203 2.227 2.7 4.809 6.145 6.133 10.108 2.878-1.855 6.241-3.592 10.019-3.727.858-.059 1.508.571 1.567 1.429.06.859-.571 1.508-1.429 1.568-3.778.135-7.041 2.307-10.227 4.417-.449.241-.885.341-1.435.146-.408-.181-.79-.644-.89-1.08-.875-4.204-3.698-8.098-6.052-10.952-.509-.617-.415-1.603.202-2.112z'
                fill='#0C0F33'
            />
            <defs>
                <clipPath id='clip0'>
                    <path
                        fill='#fff'
                        transform='rotate(-46.049 401.23 31.616)'
                        d='M0 0h31.3v68.8H0z'
                    />
                </clipPath>
                <clipPath id='clip1'>
                    <path fill='#fff' transform='translate(88 182)' d='M0 0h90.9v81.6H0z' />
                </clipPath>
                <clipPath id='clip2'>
                    <path
                        fill='#fff'
                        transform='rotate(-7.077 92.793 5.738)'
                        d='M0 0h93.147v90.523H0z'
                    />
                </clipPath>
                <clipPath id='clip3'>
                    <path
                        fill='#fff'
                        transform='rotate(138.068 53.89 102.18)'
                        d='M0 0h31.3v68.8H0z'
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
