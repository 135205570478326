/* @flow */

import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import {CloseIcon} from '../../icon';
import styles from './remove-button.css';

type Props = {
    size?: number,
    className?: string,
    variant?: 'light' | 'dark',
    onClick: (event: SyntheticEvent<*>) => void,
    getButtonRef?: any,
    tooltip?: string,
    isDisabled?: boolean,
};

export class RemoveButton extends React.PureComponent<Props> {
    static defaultProps = {
        size: 14,
    };

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        ReactTooltip.hide();
    }

    render() {
        const variant = this.props.variant === 'dark' ? styles.dark : styles.light;
        const className = this.props.className
            ? `${this.props.className} ${styles.btn} ${variant}`
            : `${styles.btn} ${variant}`;

        return (
            <button
                data-tip={this.props.tooltip}
                onClick={this.props.onClick}
                type='button'
                ref={this.props.getButtonRef}
                className={className}
                disabled={this.props.isDisabled}
            >
                <CloseIcon size={this.props.size} wrapWithDiv={false} />
            </button>
        );
    }
}
