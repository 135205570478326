/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {EntityPillSelectWrapperSmall} from 'shells/entity-pill';

import {GraphQLSelectContacts} from '../../select/graphql-select-contacts';

type Props = {
    ...FieldProps,
    isMulti: boolean,
    isReadOnly?: boolean,
};

export function ContactsSelect(props: Props) {
    if (props.isReadOnly) {
        return (
            <EntityPillSelectWrapperSmall
                type='contacts'
                value={{id: 'none', name: 'Person name'}}
            />
        );
    }

    return (
        <GraphQLSelectContacts
            isMulti={props.isMulti}
            onChange={(newContacts) => {
                props.input.onChange(newContacts);
            }}
            selectedValues={props.input.value || []}
            clearable={!props.isMulti}
            onClearValue={() => {
                props.input.onChange(null);
            }}
        />
    );
}
