/* @flow */

import * as React from 'react';

import {PlugErrorState} from 'shells/illustrations';
import {Card} from 'shells/card';
import {EmptyStateSmall} from 'shells/empty-states';

import './special-states.css';

export function TimelineErrorState() {
    return (
        <div styleName='outer-container'>
            <Card hasBorder={true} fitHeight={true} fitContent={true}>
                <div styleName='inner-container'>
                    <EmptyStateSmall
                        title='Oh no! Something went wrong'
                        subtitle='There was an error retrieving these timeline entries'
                        icon={
                            <div style={{width: '100px'}}>
                                <PlugErrorState />
                            </div>
                        }
                    />
                </div>
            </Card>
        </div>
    );
}
