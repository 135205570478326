import moment from 'moment';

export function serializeDate({selected, data}) {
    if (selected === 'current') {
        return `${getDurationChar(data.selected)}`;
    } else if (selected === 'todate') {
        return `${getDurationChar(data.selected)}td`;
    } else if (selected === 'within') {
        return `-${getDurationChar(data.selected)}${data.data}`;
    } else if (selected === 'next') {
        return `+${getDurationChar(data.selected)}${data.data}`;
    } else if (selected === 'moreThan') {
        return `>-${getDurationChar(data.selected)}${data.data}`;
    } else if (selected === 'lessThan') {
        // we add an r here to say that we want to use dates relative to the current
        // time rather than rounding to the end of the day
        return `<-r${getDurationChar(data.selected)}${data.data}`;
    } else if (selected === 'between') {
        const startOfDay = moment
            .unix(data.startDate)
            .startOf('day')
            .unix();
        const endOfDay = moment
            .unix(data.endDate)
            .endOf('day')
            .unix();

        return `${startOfDay} TO ${endOfDay}`;
    } else if (selected === 'never') {
        return '-';
    } else if (selected === 'any') {
        return '*';
    }
}

function getDurationChar(period) {
    switch (period) {
        case 'minutes':
        case 'minute':
            return 'M';
        case 'hours':
        case 'hour':
            return 'h';
        case 'days':
        case 'day':
            return 'd';
        case 'weeks':
        case 'week':
            return 'w';
        case 'months':
        case 'month':
            return 'm';
        case 'quarters':
        case 'quarter':
            return 'q';
        case 'years':
        case 'year':
            return 'y';
    }
}
