/* @flow */

import {api} from '../api';
import * as Schema from '../schema';
import type {ContactId, ContactsApiResponse, SearchEmailApiResponse} from '../types';

export function fetchContactByEmail(email: string) {
    return api
        .get(`internal/searchemail?email=${encodeURIComponent(email)}`)
        .then((response: Response): Promise<SearchEmailApiResponse> => response.json());
}

export function fetchContactById(contactId: ContactId) {
    return api
        .get(`contacts/${contactId}`)
        .then((response: Response): Promise<ContactsApiResponse> => response.json());
}

export function fetchContactCustomFields(id: ContactId) {
    return api.get(`contacts/${id}/customfields`).then((response) => response.json());
}

export function fetchSchema() {
    return Schema.fetchSchema('contacts/list/fields');
}
