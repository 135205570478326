/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import {Link as RoutedLink} from 'react-router-dom';

import {Link} from '../link';

import {TwoLine} from './two-line';

import './two-line-mini-card.css';

type Props = {
    icon?: React.ComponentType<*>,
    iconSize?: number,
    iconColor?: string,
    topRow: string | React.Node,
    bottomRow?: string | React.Node,
    isGrey?: boolean,
    isThumbnail?: boolean,
    to?: string,
    onClick?: () => void,
    borderColor?: string,
    width?: number,
};

/**
 * This is a way to display the TwoLine shell on a mini card, optionally wrapped
 * in a Link to make it clickable. Currently used in DripSequencePreview and in
 * ConnectedFormsPreview.
 */
export function TwoLineMiniCard(props: Props) {
    const content = (
        <TwoLine
            icon={props.icon}
            iconSize={props.iconSize}
            iconColor={props.iconColor}
            topRow={props.topRow}
            bottomRow={props.bottomRow}
            isGrey={props.isGrey}
            isLargeWithSmallBottomRow={true}
            hasBoldTopRow={true}
            shouldCenterSingleLine={true}
        />
    );

    const styleName = classnames('container', {
        'container--grey': props.isGrey,
        'container--no-hover': !props.onClick && !props.to,
        'container--with-shadow': props.onClick && !props.isGrey,
    });

    return (
        <div
            styleName={styleName}
            style={{borderColor: props.borderColor, minWidth: props.width, width: props.width}}
        >
            <div styleName='inner-container'>
                {!props.isThumbnail && props.to ? (
                    <Link
                        as={RoutedLink}
                        to={props.to}
                        onClick={props.onClick}
                        preventUnderline={true}
                    >
                        {content}
                    </Link>
                ) : (
                    <div onClick={props.onClick}>{content}</div>
                )}
            </div>
        </div>
    );
}
