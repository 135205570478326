import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'-468.8 268.8 24 24'}>
            <path d='M-451.1 277.6l-6.8 7.9c-.4.4-1.3.4-1.6-.1l-3.2-4.7c-.3-.5-.2-1.1.3-1.5.4-.3 1.1-.2 1.3.3l2.4 3.5 6-6.8c.4-.4 1.1-.5 1.4-.1.6.4.6 1.1.2 1.5z' />
            <path d='M-456.8 268.8c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12zm0 21.7c-5.4 0-9.8-4.4-9.8-9.8s4.4-9.8 9.8-9.8 9.8 4.4 9.8 9.8-4.4 9.8-9.8 9.8z' />
        </svg>
    );
}
SvgComponent.iconName = 'confirm';

export const ConfirmIcon = svgIconWrapper(SvgComponent);
