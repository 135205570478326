/* @flow */

import {unexpectedCase} from 'nutshell-core/reducer-utils';

import {ActionTypes, type CreatorTasksAction} from './tasks-actions';

export type CreatorTasksState = {
    isReadOnly: boolean,
};

const defaultCreatorTasksState = {
    isReadOnly: true,
};

export const reducer = (
    state: CreatorTasksState = defaultCreatorTasksState,
    action: CreatorTasksAction
): CreatorTasksState => {
    switch (action.type) {
        case ActionTypes.TASK_EDIT_ENABLED:
            return {isReadOnly: false};
        case ActionTypes.TASK_EDIT_CANCELLED:
            return {isReadOnly: true};
        // Prevent any rehydration
        // $FlowFixMe upgrading Flow to v0.92.1 on web
        case 'persist/REHYDRATE': {
            return state;
        }
        default:
            unexpectedCase(action.type);

            return state;
    }
};
