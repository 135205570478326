import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import PhoneCall from './icon-phone-call.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <PhoneCall {...validProps} />;
}
SvgComponent.iconName = 'phoneCall';

export const PhoneCallIcon = svgIconWrapper(SvgComponent);
