import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Ticket from './icon-ticket.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Ticket {...validProps} />;
}
SvgComponent.iconName = 'ticket';

export const TicketIcon = svgIconWrapper(SvgComponent);
