/* @flow */

import {getDurationMinutesText} from 'nutshell-core/get-duration-minutes-text';

export const getSessionDetails = (
    numVisits: number,
    totalVisitDuration: number
): {|numVisits: number, totalVisitDuration: string|} => {
    return {
        numVisits,
        totalVisitDuration:
            totalVisitDuration < 60
                ? `${totalVisitDuration} second${totalVisitDuration === 1 ? '' : 's'}`
                : getDurationMinutesText(Math.round(totalVisitDuration / 60)),
    };
};

export const getPeopleText = (numPeople: number): ?string => {
    if (!numPeople) return null;

    return numPeople === 1 ? '1 person' : `${numPeople} people`;
};
