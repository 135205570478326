import PropTypes from 'prop-types';
import * as React from 'react';
import _ from 'underscore';

import {AsteriskIcon, HashtagIcon} from 'shells/icon';
import {Popover} from 'shells/popover';
import getClasses from '../mixins/get-classes';

const KEYBAD_BUTTONS = [
    [{digit: '1'}, {digit: '2', letters: 'ABC'}, {digit: '3', letters: 'DEF'}],
    [
        {digit: '4', letters: 'GHI'},
        {digit: '5', letters: 'JKL'},
        {digit: '6', letters: 'MNO'},
    ],
    [
        {digit: '7', letters: 'PQRS'},
        {digit: '8', letters: 'TUV'},
        {digit: '9', letters: 'WXYZ'},
    ],
    [{digit: '*', display: <AsteriskIcon />}, {digit: '0'}, {digit: '#', display: <HashtagIcon />}],
];

export class KeypadPopover extends React.Component {
    static propTypes = {
        anchor: PropTypes.object.isRequired,

        onDigitPress: PropTypes.func.isRequired,
    };

    render() {
        const keys = KEYBAD_BUTTONS.map((buttonRow) => {
            const row = buttonRow.map((btn) => {
                return (
                    <KeypadButton
                        digit={btn.display || btn.digit}
                        key={btn.digit}
                        letters={btn.letters}
                        onClick={() => {
                            this.props.onDigitPress(btn.digit);
                        }}
                    />
                );
            });

            return (
                <div key={buttonRow[0].digit} className='row'>
                    {row}
                </div>
            );
        });

        return (
            <Popover location='bottom' offsetPosition={5} anchor={this.props.anchor}>
                <div className='ui-phone-call-keypad'>{keys}</div>
            </Popover>
        );
    }
}

class KeypadButton extends React.Component {
    static propTypes = {
        digit: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        letters: PropTypes.string,

        onClick: PropTypes.func.isRequired,
    };

    getClasses = getClasses;

    constructor(props) {
        super(props);
        this.state = {
            buttonWasPressed: false,
        };
    }

    render() {
        const {digit, letters} = this.props;

        const classes = this.getClasses('keypad-button', {
            'animate-button-press': this.state.buttonWasPressed,
        });

        return (
            <button className={classes} onClick={this.handleClick} type='button'>
                <div className='inner-wrapper'>
                    <div className='keypad-button__digit'>{digit}</div>
                    {letters ? <div className='keypad-button__letters'>{letters}</div> : undefined}
                </div>
            </button>
        );
    }

    handleClick = () => {
        this.props.onClick();
        // Toggle on and off the buttonWasPressed state, with a small delay, to show
        // the button press css animation.
        this.setState({buttonWasPressed: true});
        _.delay(() => this.setState({buttonWasPressed: false}), 400);
    };
}
