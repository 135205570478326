/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import CloudSlash from './icon-cloud-slash.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <CloudSlash {...validProps} />;
}
SvgComponent.iconName = 'cloudSlash';

export const CloudSlashIcon = svgIconWrapper(SvgComponent);
