/* @flow */

import * as React from 'react';

import {Modal} from 'shells/modal';
import {Button} from 'shells/button';

import getClasses from '../mixins/get-classes';
import {PlanComparisonChart} from './plan-comparison-chart';

type Props = {
    onModalClose: () => any,
    isOpen: boolean,
};

export class FeatureUnavailableModal extends React.Component<Props> {
    getClasses = getClasses;

    render() {
        return (
            <Modal
                className={this.getClasses('ui-modal--nutshell-plan-comparison')}
                isOpen={this.props.isOpen}
                noPadding={true}
                onClose={this.props.onModalClose}
            >
                <div className='scroll-container'>
                    <h1>Sell smarter</h1>
                    <h2>
                        <em>Nutshell Pro</em> is for teams that want to sell smarter, track
                        activities, and streamline their sales process.
                    </h2>
                    <PlanComparisonChart />
                    <Button variant='save' size='big' onClick={this.handleLearnMore}>
                        Learn more about Nutshell Pro
                    </Button>
                </div>
            </Modal>
        );
    }

    handleLearnMore = () => {
        this.props.onModalClose();
        window.open('https://www.nutshell.com/pro/', '_blank');
    };
}
