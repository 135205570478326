/* @flow */

import * as React from 'react';
import './button-group.css';

type Props = {
    children: React.Node,
    style?: any,
};

export function ButtonGroup(props: Props) {
    return (
        <div styleName='button-group' className='align-center' style={props.style}>
            {props.children}
        </div>
    );
}
