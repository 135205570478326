/* @flow */

import type {ReportGap, FilterObject} from 'nutshell-core/types';
import {serializeFilters} from 'nutshell-core';

export type EditionEngagementChartParams = {
    gap: ReportGap,
    editionId: string,
};

export type EditionEngagementReportPostParams = {
    filter: Object,
    gap: ReportGap,
    editionId: string,
};

export function getEngagementReportPostParams(
    reportParams: EditionEngagementChartParams,
    filters: FilterObject[]
): EditionEngagementReportPostParams {
    const {gap, editionId} = reportParams;

    return {
        filter: serializeFilters(filters),
        gap,
        editionId,
    };
}
