import moment from 'moment';

/**
 * Check if a date is yesterday
 *
 * @param  {[type]}  targetDate        Moment date object to check
 * @param  {[type]}  [today=moment()]  Moment date object for today's datetime
 * @return {Boolean}                   Is the target date yesterday?
 */
export function isYesterday(targetDate, today = moment()) {
    return targetDate.isSame(today.clone().subtract(1, 'day'), 'day');
}
