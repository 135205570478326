/* @flow */

import * as React from 'react';

import {Link as RoutedLink} from 'react-router-dom';

import {Link} from '../../../link';
import {Stack} from '../../../layout';

import {PersonCardContent} from './person-card-content';

import './added-contacts.css';

export type Contact = {|
    id: string,
    name: string,
    htmlUrlPath: string,
    email: ?string,
    phone: ?string,
    location: ?string,
    jobTitle: ?string,
    position: ?string,
|};

type Props = {|
    contacts: Contact[],
|};

export function AddedContacts(props: Props) {
    return props.contacts.length ? (
        <div styleName='added-contacts-list'>
            <div styleName='list-header'>
                People <span>({props.contacts.length})</span>
            </div>
            <Stack spacing={8}>
                {props.contacts.map((contact) => (
                    <Link
                        key={contact.id}
                        as={RoutedLink}
                        newTab={true}
                        preventUnderline={true}
                        to={contact.htmlUrlPath}
                    >
                        <PersonCardContent
                            id={contact.id}
                            name={contact.name}
                            email={contact.email}
                            phone={contact.phone}
                            location={contact.location}
                            jobTitle={contact.jobTitle}
                            position={contact.position}
                        />
                    </Link>
                ))}
            </Stack>
        </div>
    ) : null;
}
