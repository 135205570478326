/* @flow */

import {combineReducers} from 'redux';

import type {UpdateDefaultStagesetForOnboardingWizardInputFieldInput} from 'nutshell-graphql-types';
import type {ActiveItem} from './types';
import {ActionTypes as UiActionTypes, type SalesAutomationUiAction} from './ui-actions';

// This reducer is responsible for ui-level concerns, such as which tab
// is selected, is the form open, etc.

type ActiveItemState = $PropertyType<SalesAutomationUiState, 'activeItem'>;
export const activeItem = (
    state: ActiveItemState = null,
    action: SalesAutomationUiAction
): ActiveItemState => {
    switch (action.type) {
        case UiActionTypes.SALES_AUTOMATION_ACTIVE_ITEM_SET:
            return action.payload;
        case UiActionTypes.SALES_AUTOMATION_ACTIVE_ITEM_CLEARED:
            return null;
        default:
            return state;
    }
};

type HoveredStageIdState = $PropertyType<SalesAutomationUiState, 'hoveredStageId'>;
export const hoveredStageId = (
    state: HoveredStageIdState = null,
    action: SalesAutomationUiAction
): HoveredStageIdState => {
    switch (action.type) {
        case UiActionTypes.SALES_AUTOMATION_HOVERED_STAGE_SET:
            return action.payload;
        default:
            return state;
    }
};

type IsSidebarFormSavingState = $PropertyType<SalesAutomationUiState, 'isSidebarFormSaving'>;
export const isSidebarFormSaving = (
    state: IsSidebarFormSavingState = false,
    action: SalesAutomationUiAction
): IsSidebarFormSavingState => {
    switch (action.type) {
        case UiActionTypes.SALES_AUTOMATION_SIDEBAR_FORM_SAVE_STATUS_SET:
            return action.payload;
        case UiActionTypes.SALES_AUTOMATION_ACTIVE_ITEM_CLEARED:
            return false;
        default:
            return state;
    }
};

type CollapsedSectionsState = $PropertyType<SalesAutomationUiState, 'collapsedSections'>;
const collapsedSections = (
    state: CollapsedSectionsState = [],
    action: SalesAutomationUiAction
): CollapsedSectionsState => {
    switch (action.type) {
        case UiActionTypes.SALES_AUTOMATION_SECTION_EXPANSION_TOGGLED: {
            const sectionType = action.payload;

            return state.includes(sectionType)
                ? state.filter((sectionName) => sectionName !== sectionType)
                : state.concat([sectionType]);
        }

        default:
            return state;
    }
};

type OnboardingWizzardState = $PropertyType<SalesAutomationUiState, 'onboardingWizard'>;
const onboardingWizard = (
    // $FlowFixMe upgrading Flow to v0.92.1 on web
    state: OnboardingWizzardState = {},
    action: SalesAutomationUiAction
): OnboardingWizzardState => {
    switch (action.type) {
        case UiActionTypes.SALES_AUTOMATION_ONBOARDING_OPTION_ADDED: {
            const option = action.payload;

            return {...state, [option]: true};
        }
        case UiActionTypes.SALES_AUTOMATION_ONBOARDING_OPTION_REMOVED:
            return {...state, [action.payload]: false};
        default:
            return state;
    }
};

export type SalesAutomationUiState = {
    collapsedSections: string[],
    activeItem: ?ActiveItem,
    hoveredStageId: ?string,
    isSidebarFormSaving: boolean,
    onboardingWizard: UpdateDefaultStagesetForOnboardingWizardInputFieldInput,
};

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const uiReducer = combineReducers({
    activeItem,
    hoveredStageId,
    collapsedSections,
    onboardingWizard,
    isSidebarFormSaving,
});
