/* @flow */

import * as React from 'react';
import {Field} from 'redux-form';
import {useSelector} from 'react-redux';

import {Markets} from 'nutshell-core/markets';

import {ReduxFormSelectNew} from 'shells/redux-form-fields';

import {getFieldKey} from '../../../entity-form/forms/helpers';

const ENTITY_FORM_TYPE = 'leads';

export function MarketField() {
    const markets = useSelector((state) => Markets.getAll(state));

    const selectValues = markets
        ? markets.map((market) => ({...market, label: `${market.name} (${market.currency})`}))
        : [];

    return (
        <Field
            key={getFieldKey(false, 'market', ENTITY_FORM_TYPE)}
            name={getFieldKey(false, 'market', ENTITY_FORM_TYPE)}
            component={ReduxFormSelectNew}
            labelKey='label'
            valueKey='id'
            selectValues={selectValues}
            isInline={true}
        />
    );
}
