import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import EnvelopeIconSvg from './icon-envelope.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <EnvelopeIconSvg {...validProps} />;
}
SvgComponent.iconName = 'envelopeIcon';

export const EnvelopeIcon = svgIconWrapper(SvgComponent);
