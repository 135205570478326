
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"EmailQueuedFragmentSparse"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"EmailQueued"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"subject"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"bodyExcerpt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"attachments"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fileId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"href"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fileType"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"to"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"NutEmailAddressFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"from"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"NutEmailAddressFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"canRetry"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isFailed"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"htmlUrl"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":360}};
    doc.loc.source = {"body":"#import \"../nut-email-address-fragment.graphql\"\n\nfragment EmailQueuedFragmentSparse on EmailQueued {\n    id\n    subject\n    bodyExcerpt\n    attachments {\n        fileId\n        name\n        href\n        fileType\n    }\n    to {\n        ...NutEmailAddressFragment\n    }\n    from {\n        ...NutEmailAddressFragment\n    }\n    canRetry\n    isFailed\n    htmlUrl\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../nut-email-address-fragment.graphql").definitions));


      module.exports = doc;
    
