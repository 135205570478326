/* @flow */

type MapFilter = {|
    bounds: [Array<string>, Array<string>],
    latitude: string,
    longitude: string,
    radius: string,
|};

export function deserializeMap(value: Object): MapFilter {
    const radius = value.radius;
    const latitude = value.latitude;
    const longitude = value.longitude;
    const sw = value.southWest ? value.southWest.split(',').map((v) => v.trim()) : ['0', '0'];
    const ne = value.northEast ? value.northEast.split(',').map((v) => v.trim()) : ['0', '0'];

    return {
        bounds: [sw, ne],
        latitude: latitude,
        longitude: longitude,
        radius: radius,
    };
}
