/* @flow */

import type {ReportGap, FilterObject} from 'nutshell-core/types';
import {serializeFilters} from 'nutshell-core';

export type FormEngagementChartParams = {
    gap: ReportGap,
    formId?: string,
    formType?: number,
};

export type FormEngagementReportPostParams = {
    filter: Object,
    gap: ReportGap,
    formId: ?string,
};

export function getEngagementReportPostParams(
    reportParams: FormEngagementChartParams,
    filters: FilterObject[]
): FormEngagementReportPostParams {
    const {gap, formId, formType} = reportParams;

    return {
        filter: serializeFilters(filters),
        gap,
        formId,
        formType,
    };
}
