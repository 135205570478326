/* @flow */

import {useQuery} from '@apollo/react-hooks';
import {safelyGetGraphQLErrorMessage, makeDataSafe} from 'nutshell-core/utils/graphql-errors';
import type {GetAllLimits as GetAllLimitsQuery} from 'nutshell-graphql-types';

import GET_ALL_LIMITS from './graphql/queries/get-all-limits.graphql';

export const useGetAllLimits = () => {
    const {loading: isLoading, data, error, refetch} = useQuery<GetAllLimitsQuery, {}>(
        GET_ALL_LIMITS,
        {
            fetchPolicy: 'cache-and-network',
        }
    );

    const {allLimits} = makeDataSafe(data);

    return {
        isLoading,
        allLimits,
        refetch,
        errorMessage: safelyGetGraphQLErrorMessage(error),
    };
};
