/* @flow */

// Also supports 'Due date unit' format, e.g. 'MM', 'dd', 'ww', 'MM', 'yy', 'bzd', 'bzh'
export function getDurationString(chr: string, isPlural: boolean = true): string {
    switch (chr) {
        case 'M':
        case 'mm':
            return isPlural ? 'minutes' : 'minute';
        case 'h':
        case 'HH':
            return isPlural ? 'hours' : 'hour';
        case 'd':
        case 'dd':
            return isPlural ? 'days' : 'day';
        case 'w':
        case 'ww':
            return isPlural ? 'weeks' : 'week';
        case 'm':
            return isPlural ? 'months' : 'month';
        case 'q':
            return isPlural ? 'quarters' : 'quarter';
        case 'y':
            return isPlural ? 'years' : 'year';
        case 'bzd':
            return isPlural ? 'business days' : 'business day';
        case 'bzh':
            return isPlural ? 'business hours' : 'business hour';
        default:
            return 'months';
    }
}
