/* @flow */
/* eslint-disable react/no-danger */

import * as React from 'react';
import {Query} from 'react-apollo';
import {makeDataSafe} from 'nutshell-core/utils/graphql-errors';
import {Modal} from 'shells/modal';
import {colors} from 'shells/colors';
import {LoadingIcon} from 'shells/icon';
import {FlashBanner} from 'shells/flash-banner';
import {ErrorMessage} from 'shells/form';

import type {
    GetConstantContactEmailCampaignActivityPreview as GetConstantContactEmailCampaignActivityPreviewQuery,
    ConstantContactEmailCampaignActivityPreview as ConstantContactEmailCampaignActivityPreviewFragment,
} from 'nutshell-graphql-types';

import GET_CONSTANT_CONTACT_EMAIL_CAMPAIGN_ACTIVITY_PREVIEW from '../graphql/queries/get-constant-contact-email-campaign-activity-preview.graphql';

import './constant-contact-email-preview-modal.css';

type Props = {
    onClose: () => any,
    activityId: string,
};

class ConstantContactEmailCampaignActivityPreviewQuery extends Query<
    GetConstantContactEmailCampaignActivityPreviewQuery,
    {}
> {}

export class ConstantContactEmailPreviewModal extends React.PureComponent<Props> {
    render() {
        return (
            <ConstantContactEmailCampaignActivityPreviewQuery
                query={GET_CONSTANT_CONTACT_EMAIL_CAMPAIGN_ACTIVITY_PREVIEW}
                variables={{id: this.props.activityId}}
            >
                {({data, loading}) => {
                    const {constantContactEmailCampaignActivityPreview: preview} = makeDataSafe(
                        data
                    );

                    return (
                        <Modal
                            isOpen={true}
                            onClose={this.props.onClose}
                            headerText='Email preview'
                        >
                            <FlashBanner
                                animate={false}
                                type='info'
                                leftAlignContent={true}
                                hasBorder={true}
                            >
                                {`Below is a preview of the email ${
                                    preview ? `"${preview.subject}"` : ''
                                } you sent via Constant Contact.`}
                            </FlashBanner>
                            {this.renderModalContent(preview, loading)}
                        </Modal>
                    );
                }}
            </ConstantContactEmailCampaignActivityPreviewQuery>
        );
    }

    renderModalContent = (
        preview: ?ConstantContactEmailCampaignActivityPreviewFragment,
        isLoading: boolean
    ) => {
        if (preview) {
            return (
                <div
                    styleName='email-container'
                    dangerouslySetInnerHTML={{
                        __html: preview.previewHtmlContent,
                    }}
                />
            );
        }

        if (isLoading) {
            return (
                <div styleName='container'>
                    <LoadingIcon fill={colors.offWhiteDk} />
                </div>
            );
        }

        return (
            <div styleName='container'>
                <ErrorMessage>
                    Whoops! We couldn’t load that email, something went wrong.
                </ErrorMessage>
            </div>
        );
    };
}
