/* @flow */

import {combineReducers} from 'redux';
import type {ReportRowData, ComparedActivityReportSummary} from 'nutshell-core/types';

import * as actions from './email-report-actions';

type State = {
    data: ?string,
    hasError: boolean,
    isLoading: boolean,
    numPages: ?number,
};
const defaultState = {
    data: null,
    hasError: false,
    isLoading: true,
    numPages: null,
};

type SummaryState = {
    hasError: boolean,
    isLoading: boolean,
    data: ?ComparedActivityReportSummary<Array<ReportRowData>>,
    totals: ?ComparedActivityReportSummary<ReportRowData>,
};
const defaultSummaryState = {
    ...defaultState,
    totals: null,
};

export const summaryReducer = (
    state: SummaryState = defaultSummaryState,
    action: actions.EmailReportAction
): SummaryState => {
    switch (action.type) {
        case actions.ActionTypes.EMAIL_REPORT_FETCH_SUMMARY_REQUEST:
            return {...state, isLoading: true};
        case actions.ActionTypes.EMAIL_REPORT_FETCH_SUMMARY_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                totals: action.payload.totals,
                isLoading: false,
                hasError: false,
            };
        case actions.ActionTypes.EMAIL_REPORT_FETCH_SUMMARY_FAILURE:
            return {...state, isLoading: false, hasError: true};
        default:
            return state;
    }
};

type LeadOutcomesState = ?{
    lostOrCancelled: number,
    won: number,
    unknown: number,
};

export const leadOutcomesReducer = (
    state: LeadOutcomesState = null,
    action: {type: string, payload: Object}
): LeadOutcomesState => {
    switch (action.type) {
        case 'REPORT_EMAIL_SUMMARY_STATS_UPDATED':
            return null;
        case 'REPORTS_LEADS_REPORT_DATA_REQUESTED':
            return null;
        default:
            return state;
    }
};

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const emailReportReducer = combineReducers({
    summary: summaryReducer,
    leadOutcomes: leadOutcomesReducer,
});

export type EmailReportState = {
    detail: State,
    summary: SummaryState,
    leadOutcomes: LeadOutcomesState,
};
