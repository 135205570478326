/* @flow */

import * as React from 'react';
import './lead-avatar.css';

type Props = {
    avatarUrl: ?string,
    status?: string,
    /** This is clunky, but size will set the width of the logo, but an explicit width or height can be provided to override that */
    size: number,
    width?: number,
    height?: number,
    /** A value of `true` will render a span as the wrapping div,
    falsey value renders a div to vertically center the icon (important for open lead chevrons) */
    inline?: boolean,
};

LeadAvatar.defaultProps = {
    squareContainer: true,
};

export function LeadAvatar(props: Props) {
    let maxDimension = props.size;

    if (typeof props.width !== 'undefined' && typeof props.height !== 'undefined') {
        maxDimension = Math.max(props.width, props.height, props.size);
    }

    const containerStyle = {
        width: maxDimension,
        height: maxDimension,
    };
    const altText = props.status ? `${props.status} lead icon` : 'lead icon';

    const NodeType = props.inline ? 'span' : 'div';

    return (
        <NodeType
            styleName={props.inline ? undefined : 'lead-avatar'}
            style={props.inline ? undefined : containerStyle}
        >
            <img
                width={props.size || props.width}
                height={props.height}
                src={props.avatarUrl}
                alt={altText}
            />
        </NodeType>
    );
}
