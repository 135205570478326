/* @flow */

import * as React from 'react';

import {Stack} from '../layout';

import './empty-timeline-cell.css';

type Props = {
    lineOneWidth: string,
    lineTwoWidth: string,
    contentWidth: string,
    contentHeight: string,
    noTopLine?: boolean,
};

export function EmptyTimelineCell(props: Props) {
    return (
        <Stack spacing={8}>
            {!props.noTopLine ? <div styleName='top-line' /> : undefined}
            <div styleName='container'>
                <div styleName='profile' />
                <div styleName='entryHeader'>
                    <Stack spacing={8}>
                        <div styleName='row' style={{width: props.lineOneWidth}} />
                        <div styleName='row' style={{width: props.lineTwoWidth}} />
                    </Stack>
                </div>
            </div>
            <div styleName='container'>
                <div styleName='line' />
                <div
                    styleName='content'
                    style={{height: props.contentHeight, width: props.contentWidth}}
                />
            </div>
        </Stack>
    );
}
