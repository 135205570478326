/* @flow */

import type {
    AccountId,
    Address,
    AssignableId,
    Contact,
    ContactId,
    CustomFieldAttribute,
    Email,
    FileId,
    FollowupId,
    JsonApiLink,
    OriginId,
    Phone,
    TagId,
    TaskId,
    TerritoryId,
    Url,
    UrlString,
    UserId,
} from '../types';

import type {EavValue} from '../eav/types';
import type {AccountsState} from './accounts-reducer';

export const REDUCER_KEY = 'accounts';
export type ReducerKey = 'accounts';

export type AccountsModuleState = {
    [ReducerKey]: AccountsState,
};

type AccountLinks = {
    contacts: ContactId[],
    creator: UserId,
    owner: ?AssignableId,
    territory: ?TerritoryId,
    tags: TagId[],
    origin: ?OriginId,
    files: FileId[],
    followup: ?FollowupId,
    recurringTask: ?TaskId,
};

export type Account = {
    id: AccountId,
    type: 'accounts',
    name: string,
    description: ?string,
    emails: Email[],
    addresses: Address[],
    phones: Phone[],
    urls: Url[],
    avatarUrl: UrlString,
    initials: string,
    htmlUrl: UrlString,
    links: AccountLinks,
    customFields?: CustomFieldAttribute[],
};

export type AccountFormValues = {
    name: string,
};

export type AccountCreationFormValues = {
    id?: string,
    name?: string,
    description?: ?string,
    links?: {|
        contacts: {
            id: string,
        },
    |},
    emails?: EavValue[],
    phones?: EavValue[],
    addresses?: EavValue[],
    urls?: EavValue[],
};

// Responses from /rest/accounts
export type AccountsApiResponse = {
    meta: {
        count?: number,
        total?: number,
    },
    links: {[key: string]: JsonApiLink} | [],
    contacts?: Contact[],
    accounts: Account[],
};
