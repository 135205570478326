import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 48 48.1'} fill='currentColor'>
            <path d='M40.4 0H7.6C3.4 0 0 3.2 0 7.2v33.7c0 4 3.4 7.2 7.6 7.2h32.8c4.2 0 7.6-3.2 7.6-7.2V7.2c0-4-3.4-7.2-7.6-7.2zm-.8 41H8.2c-.6-.1-1.2-.7-1.2-1.3V13.4c0-.8.6-1.4 1.3-1.4h31.4c.7 0 1.2.6 1.2 1.3v26.3c.1.8-.5 1.4-1.3 1.4zm-4.8-22h-2.6C31 19 30 20 30 21.1v2.7c0 1.2 1 2.1 2.2 2.1h2.6c1.2 0 2.2-1 2.2-2.1v-2.7c0-1.2-1-2.1-2.2-2.1zm-18 9h-2.6C13 28 12 29 12 30.1v2.7c0 1.2 1 2.1 2.2 2.1h2.6c1.2 0 2.2-1 2.2-2.1v-2.7c0-1.2-1-2.1-2.2-2.1zm6.4-2h2.6c1.2 0 2.2-1 2.2-2.1v-2.7c0-1.2-1-2.1-2.2-2.1h-2.6c-1.2-.1-2.2.9-2.2 2v2.7c0 1.3 1 2.2 2.2 2.2zm2.6 2h-2.6C22 28 21 29 21 30.1v2.7c0 1.2 1 2.1 2.2 2.1h2.6c1.2 0 2.2-1 2.2-2.1v-2.7c0-1.2-1-2.1-2.2-2.1z' />
        </svg>
    );
}
SvgComponent.iconName = 'activity';

export const ActivityIcon = svgIconWrapper(SvgComponent);
