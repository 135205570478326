/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import VisitorIq from './icon-visitor-iq.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <VisitorIq {...validProps} />;
}

SvgComponent.iconName = 'visitor-iq';

export const VisitorIqIcon = svgIconWrapper(SvgComponent);
