/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import {type LocationShape} from 'react-router-dom';

import {Link} from 'shells/link';

import './link-card.css';

type Props = {
    children: React.Node,
    as?: string | React.ComponentType<*>,
    href?: string,
    to?: string | LocationShape,
    newTab?: boolean,
    /** If true, the card will size vertically according to the content, not the parent's height */
    fitHeight?: boolean,
    isFullWidth?: boolean,
    isFlex?: boolean,
    preventUnderline?: boolean,
    hasBorder?: boolean,
    hasShadow?: boolean,
    backgroundColor?: string,
    getCardRef?: any,
    onClick?: () => void,
};

export function LinkCard(props: Props) {
    const {
        children,
        as,
        fitHeight,
        isFullWidth,
        isFlex,
        backgroundColor,
        getCardRef,
        hasBorder = true,
        hasShadow = true,
        onClick,
        ...restProps
    } = props;

    const styleNames = classnames({
        'link-card': true,
        'link-card--bordered': hasBorder,
        'link-card--shadowed': hasShadow,
        'link-card--fitHeight': fitHeight,
        'link-card--fullWidth': isFullWidth,
        'link-card--flex': isFlex,
    });

    return (
        <div styleName={styleNames} style={{backgroundColor}} ref={getCardRef} onClick={onClick}>
            <Link as={as} {...restProps}>
                {children}
            </Link>
        </div>
    );
}
