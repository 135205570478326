/* @flow */

export const ActionTypes = {
    TASK_EDIT_ENABLED: 'TASK_EDIT_ENABLED',
    TASK_EDIT_CANCELLED: 'TASK_EDIT_CANCELLED',
};

type TaskEditEnabled = {type: 'TASK_EDIT_ENABLED'};
type TaskEditCancelled = {type: 'TASK_EDIT_CANCELLED'};

export type CreatorTasksAction = TaskEditEnabled | TaskEditCancelled;

/*
 * Action creators
 *
 * These functions will be called by our app to create an action which can be dispatched.
 */
export const editTask = (): CreatorTasksAction => ({type: ActionTypes.TASK_EDIT_ENABLED});

export const cancelEditTask = (): CreatorTasksAction => ({type: ActionTypes.TASK_EDIT_CANCELLED});
