/* @flow */

import * as React from 'react';

export function IdentifyingCompanies() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 270 315'>
            <rect width='269' height='314' x='.5' y='.5' fill='#fff' rx='3.5' />
            <path
                fill='#211712'
                d='M11.406 16.001v-.87h-.908v-4.863h.908v-.87H8.53v.87h.899v4.863h-.9V16h2.877ZM13.705 16.001V12.71c0-.577.53-.852 1.07-.852.633 0 .926.388.926 1.154v2.99h1.032V12.88c0-1.211-.596-1.911-1.6-1.911-.756 0-1.172.397-1.38.927h-.048v-.814h-1.031v4.92h1.031ZM21.256 16.001h1.03V9h-1.03v2.895h-.048c-.208-.586-.728-.927-1.381-.927-1.24 0-1.958.955-1.958 2.573 0 1.618.719 2.574 1.958 2.574.653 0 1.173-.35 1.381-.928h.048v.814Zm-1.117-.776c-.71 0-1.182-.501-1.182-1.258v-.851c0-.757.473-1.259 1.182-1.259.625 0 1.117.34 1.117.842v1.684c0 .502-.492.842-1.117.842ZM26.741 16.001h1.031v-4.92h-1.031v3.293c0 .577-.52.851-1.05.851-.634 0-.946-.397-.946-1.144v-3h-1.032v3.132c0 1.211.597 1.902 1.609 1.902.814 0 1.201-.445 1.381-.928h.038v.814ZM30.81 16.115c1.183 0 1.949-.634 1.949-1.58 0-.795-.483-1.268-1.561-1.429l-.454-.057c-.52-.085-.757-.255-.757-.634 0-.369.265-.615.832-.615.53 0 .947.256 1.202.559l.634-.606c-.445-.492-.946-.785-1.836-.785-1.078 0-1.825.558-1.825 1.514 0 .908.614 1.343 1.617 1.466l.454.057c.511.066.7.303.7.615 0 .416-.302.662-.898.662-.587 0-1.032-.265-1.391-.7l-.662.605c.463.578 1.069.928 1.996.928ZM36.12 16.001v-.842h-.955v-3.236h1.031v-.842h-1.031V9.738h-.927v.86c0 .341-.114.483-.454.483h-.417v.842h.767v3.009c0 .672.378 1.07 1.088 1.07h.898ZM38.346 16.001v-3.14c0-.512.454-.786 1.268-.786h.407v-.994h-.275c-.785 0-1.21.445-1.353.947h-.047v-.947h-1.031v4.92h1.031ZM43.054 13.976l-.313 1.07h-.047l-.284-1.07-.983-2.895h-1.032l1.788 5.138-.283.832h-.833v.842h.596c.766 0 1.06-.255 1.296-.917l2.072-5.895h-.993l-.984 2.895Z'
            />
            <path
                fill='#FE7933'
                d='M13.822 39.015a2.911 2.911 0 1 1-5.822 0 2.911 2.911 0 0 1 5.822 0Z'
            />
            <path
                fill='#F1F1F1'
                d='M18.189 36.837a2.489 2.489 0 0 1 2.488-2.489h144.214a2.489 2.489 0 0 1 2.489 2.49v4.354a2.489 2.489 0 0 1-2.489 2.489H20.677a2.489 2.489 0 0 1-2.488-2.489v-4.355Z'
            />
            <path
                fill='#FBC942'
                d='M13.822 68.125a2.911 2.911 0 1 1-5.822 0 2.911 2.911 0 0 1 5.822 0Z'
            />
            <path
                fill='#F1F1F1'
                d='M18.189 65.948a2.489 2.489 0 0 1 2.488-2.489h174.78a2.489 2.489 0 0 1 2.489 2.489v4.355a2.489 2.489 0 0 1-2.489 2.488H20.677a2.489 2.489 0 0 1-2.488-2.488v-4.355Z'
            />
            <path
                fill='#33C252'
                d='M13.822 97.236a2.911 2.911 0 1 1-5.822 0 2.911 2.911 0 0 1 5.822 0Z'
            />
            <path
                fill='#F1F1F1'
                d='M18.189 95.058a2.489 2.489 0 0 1 2.488-2.489h140.576a2.488 2.488 0 0 1 2.488 2.49v4.354a2.488 2.488 0 0 1-2.488 2.489H20.677a2.489 2.489 0 0 1-2.488-2.489v-4.355Z'
            />
            <path
                fill='#62D5CA'
                d='M13.822 126.346a2.91 2.91 0 1 1-5.822 0 2.91 2.91 0 0 1 5.822 0Z'
            />
            <path
                fill='#F1F1F1'
                d='M18.189 124.169a2.489 2.489 0 0 1 2.488-2.489h58.338a2.489 2.489 0 0 1 2.489 2.489v4.355a2.488 2.488 0 0 1-2.489 2.488H20.677a2.488 2.488 0 0 1-2.488-2.488v-4.355Z'
            />
            <path
                fill='#337DA8'
                d='M13.822 155.457a2.911 2.911 0 1 1-5.823 0 2.911 2.911 0 0 1 5.823 0Z'
            />
            <path
                fill='#F1F1F1'
                d='M18.189 153.279a2.488 2.488 0 0 1 2.488-2.488h124.565a2.488 2.488 0 0 1 2.488 2.488v4.355a2.488 2.488 0 0 1-2.488 2.489H20.677a2.489 2.489 0 0 1-2.488-2.489v-4.355Z'
            />
            <path
                fill='#3B9ED7'
                d='M13.822 184.567a2.91 2.91 0 1 1-5.822 0 2.91 2.91 0 0 1 5.822 0Z'
            />
            <path
                fill='#F1F1F1'
                d='M18.189 182.39a2.489 2.489 0 0 1 2.488-2.489h160.225a2.489 2.489 0 0 1 2.489 2.489v4.355a2.488 2.488 0 0 1-2.489 2.488H20.677a2.488 2.488 0 0 1-2.488-2.488v-4.355Z'
            />
            <path
                fill='#065289'
                d='M13.822 213.678a2.911 2.911 0 1 1-5.823 0 2.911 2.911 0 0 1 5.823 0Z'
            />
            <path
                fill='#F1F1F1'
                d='M18.189 211.5a2.488 2.488 0 0 1 2.488-2.488H68.1a2.488 2.488 0 0 1 2.489 2.488v4.355a2.489 2.489 0 0 1-2.489 2.489H20.677a2.489 2.489 0 0 1-2.488-2.489V211.5Z'
            />
            <path
                fill='#3D3B8A'
                d='M13.822 242.788a2.91 2.91 0 1 1-5.822 0 2.91 2.91 0 0 1 5.822 0Z'
            />
            <path
                fill='#F1F1F1'
                d='M18.189 240.611a2.489 2.489 0 0 1 2.488-2.489H175.08a2.489 2.489 0 0 1 2.489 2.489v4.355a2.488 2.488 0 0 1-2.489 2.488H20.677a2.488 2.488 0 0 1-2.488-2.488v-4.355Z'
            />
            <path
                fill='#0C0F33'
                d='M13.822 271.899a2.911 2.911 0 1 1-5.823 0 2.911 2.911 0 0 1 5.823 0Z'
            />
            <path
                fill='#F1F1F1'
                d='M18.189 269.721a2.488 2.488 0 0 1 2.488-2.488h84.538a2.489 2.489 0 0 1 2.489 2.488v4.355a2.49 2.49 0 0 1-2.489 2.489H20.677a2.489 2.489 0 0 1-2.488-2.489v-4.355Z'
            />
            <path
                fill='#DBDFE4'
                d='M13.822 301.009a2.91 2.91 0 1 1-5.822 0 2.91 2.91 0 0 1 5.822 0Z'
            />
            <path
                fill='#F1F1F1'
                d='M18.189 298.832a2.489 2.489 0 0 1 2.488-2.489h136.937a2.488 2.488 0 0 1 2.488 2.489v4.355a2.488 2.488 0 0 1-2.488 2.488H20.677a2.488 2.488 0 0 1-2.488-2.488v-4.355Z'
            />
            <path
                fill='#EBE8E8'
                fillRule='evenodd'
                d='M270 25H0v-1h270v1ZM270 54H0v-1h270v1ZM270 83H0v-1h270v1ZM270 112H0v-1h270v1ZM270 141H0v-1h270v1ZM270 170H0v-1h270v1ZM270 199H0v-1h270v1ZM270 228H0v-1h270v1ZM270 257H0v-1h270v1ZM270 286H0v-1h270v1Z'
                clipRule='evenodd'
            />
            <rect width='269' height='314' x='.5' y='.5' stroke='#EBE8E8' rx='3.5' />
        </svg>
    );
}
