/* @flow */

import * as React from 'react';

import './coming-soon-text.css';

type Props = {
    children: React.Node,
};

export function ComingSoonText(props: Props) {
    return (
        <div className='flex flex-dir-col align-center'>
            <span styleName='coming-soon'>Coming soon!</span>
            <div>{props.children}</div>
        </div>
    );
}
