import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 88 72.26'}>
            <path
                className='surface'
                d='M32.7,72.26a2.58,2.58,0,0,1-1.84-.76L.76,41.35a2.61,2.61,0,0,1,0-3.68L15,23.38a2.66,2.66,0,0,1,3.68,0l14,14L69.29.74A2.66,2.66,0,0,1,73,.74L87.24,15a2.61,2.61,0,0,1,0,3.67h0L34.54,71.49A2.58,2.58,0,0,1,32.7,72.26Z'
            />
            <path
                className='border'
                d='M32.7,72.26a2.58,2.58,0,0,1-1.84-.76L.76,41.35a2.61,2.61,0,0,1,0-3.68L15,23.38a2.66,2.66,0,0,1,3.68,0l14,14L69.29.74A2.66,2.66,0,0,1,73,.74L87.24,15a2.61,2.61,0,0,1,0,3.67h0L34.54,71.49A2.58,2.58,0,0,1,32.7,72.26ZM3.16,39.51,32.7,69.09,84.84,16.87,71.13,3.14,34.54,39.79a2.6,2.6,0,0,1-3.68,0l-14-14Z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'todo-empty-state-icon';

export const TodoEmptyStateIcon = svgIconWrapper(SvgComponent);
