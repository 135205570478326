/* @flow */

import * as React from 'react';
import _ from 'underscore';
import {TextField} from '../textfield';

type Props = {
    value: string,
    onChange: (newVal: string) => void,
    delay: number,
    readOnly?: boolean,
    placeholder?: string,
};

type State = {
    value: string,
};

export class TextFieldDebounced extends React.Component<Props, State> {
    applyChange: (string) => string;

    static defaultProps = {
        value: '',
        onChange: () => {},
        delay: 200,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            value: props.value,
        };

        this.applyChange = _.debounce((val: string) => props.onChange(val), props.delay);
    }

    render() {
        return <TextField {...this.props} value={this.state.value} onChange={this.handleChange} />;
    }

    handleChange = (newValue: string) => {
        if (this.state.value !== newValue) {
            this.setState({value: newValue});
        }

        this.applyChange(newValue);
    };
}
