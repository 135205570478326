/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import HtmlLink from './icon-html-link.svg';

type Props = {
    fill?: string,
};
function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <HtmlLink {...validProps} fill={props.fill} />;
}
SvgComponent.iconName = 'html-link';

export const HtmlLinkIcon = svgIconWrapper(SvgComponent);
