import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import PersonCheck from './icon-person-check.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <PersonCheck {...validProps} />;
}
SvgComponent.iconName = 'person-check';

export const PersonCheckIcon = svgIconWrapper(SvgComponent);
