import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SidebarIconSearch from './sidebar-icon-search.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SidebarIconSearch {...validProps} />;
}
SvgComponent.iconName = 'sidebarIconSearch';

export const SidebarIconSearchIcon = svgIconWrapper(SvgComponent);
