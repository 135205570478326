/* @flow */

import React, {Component} from 'react';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';

import {Button} from 'shells/button';

import './editable-label.css';

type SavingStatus = 'SAVING' | 'SUCCESS' | 'FAILURE';

type Props = {
    value: string,
    onSaveNewValue: (string) => Promise<*>,
    placeholder: string,
    allowSavingEmpty: boolean,
    shouldAllowUndo?: boolean,
    required?: boolean,
    tooltip?: ?string,
    customStyle?: Object,
    isWhite?: boolean,
};

type State = {
    isEditing: boolean,
    editedValue: string,
    savingStatus?: SavingStatus,
    lastValue?: string,
};

export class EditableLabel extends Component<Props, State> {
    input: ?HTMLInputElement;
    activeTimeout: ?TimeoutID;

    static defaultProps = {
        placeholder: 'Add name',
        allowSavingEmpty: false,
        shouldAllowUndo: true,
        required: false,
        tooltip: 'Rename',
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            isEditing: false,
            editedValue: props.value || '',
            lastValue: undefined,
        };
    }

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                isEditing: false,
            });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevState.isEditing !== this.state.isEditing && this.state.isEditing && this.input) {
            if (this.activeTimeout) {
                clearTimeout(this.activeTimeout);
            }
            this.input.select();
        }
        if (
            (prevState.savingStatus !== this.state.savingStatus &&
                this.state.savingStatus === 'SUCCESS') ||
            this.state.savingStatus === 'FAILURE'
        ) {
            this.activeTimeout = setTimeout(() => {
                this.setState({savingStatus: undefined, lastValue: undefined});
            }, 6000);
        }
    }

    render() {
        const labelStyleName = classnames('editable-label', {
            'editable-label--white': this.props.isWhite,
            'editable-label--required': this.props.required,
            'editable-label--failure': this.state.savingStatus === 'FAILURE',
            'editable-label--saving': this.state.savingStatus === 'SAVING',
        });

        return this.state.isEditing ? (
            <div styleName={'editable-label-container'}>
                <input
                    styleName={
                        this.props.isWhite ? 'editable-label-input--white' : 'editable-label-input'
                    }
                    ref={(node: ?HTMLInputElement) => {
                        this.input = node;
                    }}
                    value={this.state.editedValue}
                    onKeyDown={this.handleKeydown}
                    placeholder={this.props.placeholder}
                    onChange={this.handleChange}
                    onBlur={this.handleSubmit}
                />
            </div>
        ) : (
            <div styleName={'editable-label-container'}>
                <button
                    data-tip={this.props.tooltip ? this.props.tooltip : undefined}
                    styleName={labelStyleName}
                    onClick={this.toggleEdit}
                    style={this.props.customStyle}
                >
                    {this.props.value}
                </button>
                {this.props.shouldAllowUndo &&
                this.state.lastValue &&
                this.state.savingStatus === 'SUCCESS' ? (
                    <Button
                        variant='text-primary'
                        size='big'
                        onClick={() => {
                            if (this.state.lastValue) {
                                this.saveNewValue(this.state.lastValue);
                                // Remove undo button once it's been clicked
                                this.setState({lastValue: undefined});
                            }
                        }}
                    >
                        Undo
                    </Button>
                ) : null}
            </div>
        );
    }

    handleChange = (e: SyntheticInputEvent<*>) => {
        this.setState({editedValue: e.target.value});
    };

    handleSubmit = () => {
        if (
            (!this.props.allowSavingEmpty && !this.state.editedValue.length) ||
            this.props.value === this.state.editedValue
        ) {
            this.setState({isEditing: false});
        } else {
            this.saveNewValue(this.state.editedValue);
        }
    };

    saveNewValue = (newValue: string) => {
        this.setState({savingStatus: 'SAVING', isEditing: false});
        this.props
            .onSaveNewValue(newValue)
            .then(() => {
                this.setSavingStatusWithTimeout('SUCCESS');
            })
            .catch(() => {
                this.setSavingStatusWithTimeout('FAILURE');
            });
    };

    setSavingStatusWithTimeout = (status: SavingStatus) => {
        if (this.activeTimeout) {
            clearTimeout(this.activeTimeout);
        }
        this.setState({savingStatus: status});
    };

    toggleEdit = () => {
        this.setState((prevState: State) => {
            return {
                editedValue: this.props.value,
                isEditing: !prevState.isEditing,
                lastValue: prevState.isEditing ? undefined : this.props.value,
                // If we started editing, clear any saving status since we're going to cancel the timeout
                savingStatus: !prevState.isEditing ? undefined : prevState.savingStatus,
            };
        });
    };

    handleKeydown = (e: SyntheticKeyboardEvent<*>) => {
        if (e.key === 'Escape') {
            this.toggleEdit();
        }
        if (e.key === 'Enter') {
            this.handleSubmit();
        }
    };
}
