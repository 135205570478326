/* @flow */

import * as React from 'react';

import {Button} from '../button';
import {Popover} from '../popover';
import {Body} from '../typography';
import {SelectPickerGeneric} from '../select-picker-generic';
import {TextField} from '../textfield';
import {Label} from '../form';
import {Stack} from '../layout';

type FallbackConfig = {
    defaultValue: string,
    description: string | React.Node,
};

export type MergeField = {
    value: string,
    label: string,
    description?: string | React.Node,
    fallbackText: ?FallbackConfig,
};

type Props = {
    onAddMergeField: (value: string, fallbackText: ?string) => void,
    options: MergeField[],
};

export function MergeFieldsButton(props: Props) {
    const [isAddingMergeField, setIsAddingMergeField] = React.useState<boolean>(false);
    const [selectedMergeField, setSelectedMergeField] = React.useState<?MergeField>(null);
    const [fallbackText, setFallbackText] = React.useState<?string>(null);
    const buttonRef = React.useRef(null);

    React.useEffect(() => {
        if (selectedMergeField && selectedMergeField.fallbackText) {
            setFallbackText(selectedMergeField.fallbackText.defaultValue || '');
        }
    }, [selectedMergeField]);

    const handleAddMergeField = () => {
        if (selectedMergeField) {
            props.onAddMergeField(selectedMergeField.value, fallbackText);
        }

        setSelectedMergeField(null);
        setFallbackText(null);
        setIsAddingMergeField(false);
    };

    return (
        <>
            <Button
                onClick={() => setIsAddingMergeField(!isAddingMergeField)}
                getButtonRef={buttonRef}
            >
                Insert merge field
            </Button>
            {isAddingMergeField && (
                <Popover
                    anchor={buttonRef.current}
                    location='left'
                    onBlur={() => setIsAddingMergeField(false)}
                    onClose={() => setIsAddingMergeField(false)}
                >
                    <div className='pad-16' style={{width: '300px'}}>
                        <Stack spacing={16}>
                            <Body size='large' isBold={true}>
                                Add a merge field
                            </Body>
                            <Stack spacing={4}>
                                <SelectPickerGeneric
                                    options={props.options}
                                    value={selectedMergeField}
                                    onChange={(val) => setSelectedMergeField(val)}
                                />
                                {selectedMergeField && selectedMergeField.description}
                            </Stack>
                            {selectedMergeField && selectedMergeField.fallbackText ? (
                                <>
                                    <Label isRequired={true} htmlFor='fallbackText'>
                                        Fallback text
                                    </Label>
                                    <Stack spacing={4}>
                                        <TextField
                                            key={selectedMergeField.value}
                                            name='fallbackText'
                                            value={fallbackText || ''}
                                            focusOnMount={true}
                                            onChange={(val) => setFallbackText(val)}
                                            placeholder='ex. friend'
                                        />
                                        {selectedMergeField.fallbackText.description}
                                    </Stack>
                                </>
                            ) : null}
                            <div className='flex justify-sb'>
                                <Button
                                    variant='text-secondary'
                                    onClick={() => setIsAddingMergeField(false)}
                                    noPadding={true}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant='save'
                                    onClick={handleAddMergeField}
                                    disabled={
                                        !selectedMergeField ||
                                        (selectedMergeField.fallbackText && !fallbackText)
                                    }
                                >
                                    Add
                                </Button>
                            </div>
                        </Stack>
                    </div>
                </Popover>
            )}
        </>
    );
}
