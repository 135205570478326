/* @flow */

import startsWith from 'underscore.string/startsWith';
import type {DateFilterType} from './types';
import {
    isRange,
    isMagicDateValue,
    isRelativeDateValue,
    isCurrentPeriodValue,
    isToDatePeriodValue,
} from './utils/period-checks';
import {getDurationString} from './utils/get-duration-string';

type DateFilter = {|
    selected: DateFilterType,
    data?: PeriodData | MagicValueData | GreaterOrLessThanData,
|};

type PeriodData = {|
    startDate: number,
    endDate: number,
|};

type MagicValueData = {
    selected: string,
    data: string,
};

type GreaterOrLessThanData = string;

export function deserializeDate(value: string): DateFilter {
    // This shouldn't happen but just in case.
    if (typeof value !== 'string') return value;

    if (value === '+') return {selected: 'any'};
    else if (value === '-') return {selected: 'never'};
    else if (isToDatePeriodValue(value)) {
        const durationString = getDurationString(value.charAt(0)) || '';

        return {
            selected: 'todate',
            data: {
                selected: durationString.substr(0, durationString.length - 1),
                data: '',
            },
        };
    } else if (isCurrentPeriodValue(value)) {
        const durationString = getDurationString(value) || '';

        return {
            selected: 'current',
            data: {
                selected: durationString.substr(0, durationString.length - 1),
                data: '',
            },
        };
    } else if (isRange(value)) {
        const data = deserializeDateRange(value);

        return {
            selected: 'between',
            data,
        };
    } else if (isMagicDateValue(value)) {
        let selectedValue = 'within';
        if (startsWith(value, '>')) {
            selectedValue = 'moreThan';
        } else if (startsWith(value, '<')) {
            selectedValue = 'lessThan';
        } else if (startsWith(value, '+')) {
            selectedValue = 'next';
        }

        const isRelativeDate = isRelativeDateValue(value);

        const magicValue =
            selectedValue !== 'within' && selectedValue !== 'next'
                ? value.substring(2)
                : value.substring(1);

        // if we have a relativeDate we need to shift over one more spot in the string
        // i.e. <-rM30 for 30 minutes vs >-M30 for greater than values
        const slicePosition = isRelativeDate ? 2 : 1;
        const durationString = isRelativeDate
            ? getDurationString(magicValue.substr(1, 1))
            : getDurationString(magicValue.substr(0, 1));

        return {
            selected: selectedValue,
            data: {
                selected: durationString,
                data: magicValue.slice(slicePosition),
            },
        };
    } else if (startsWith(value.trim(), '<')) {
        return {
            selected: 'lessThan',
            data: value.slice(1),
        };
    } else if (startsWith(value.trim(), '>')) {
        return {
            selected: 'greaterThan',
            data: value.slice(1),
        };
    }

    return {selected: 'any'};
}

export function deserializeDateRange(rangeString: string): PeriodData {
    const splitStr = rangeString.split(' ');

    return {
        startDate: Number(splitStr[0]), // Expects unix timestamps
        endDate: Number(splitStr[2]),
    };
}
