import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Text from './icon-text.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Text {...validProps} />;
}
SvgComponent.iconName = 'text';

export const TextIcon = svgIconWrapper(SvgComponent);
