import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SolidClock from './icon-clock-solid.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SolidClock {...validProps} />;
}
SvgComponent.iconName = 'solidClock';

export const SolidClockIcon = svgIconWrapper(SvgComponent);
