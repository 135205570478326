/* @flow */

import * as React from 'react';

import {Body} from '../typography';

type Props = {|
    limit: number,
    length: number,
|};

const SHOW_WARNING_THRESHOLD = 16;

export function CharacterLimit(props: Props) {
    const remainingCharacters = props.limit - props.length;

    if (remainingCharacters < 0) {
        return (
            <Body size='small' color='error'>
                {props.length} / {props.limit} character limit exceeded
            </Body>
        );
    }

    const shouldShowWarning = remainingCharacters < SHOW_WARNING_THRESHOLD;

    return (
        <div className='flex align-center'>
            {shouldShowWarning && (
                <div className='mr-4'>
                    <Body size='small' color='error'>
                        {props.limit - props.length} characters remaining
                    </Body>
                </div>
            )}
            <Body size='small' color='light'>
                {props.length} / {props.limit} characters
            </Body>
        </div>
    );
}
