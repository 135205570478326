/* @flow */

import type {ContactId, LeadId} from '../types';
import type {LeadsAction} from '../leads/leads-types';
import {ActionTypes as LeadsActionTypes} from '../leads/leads-constants';

export type RelatedLeadsState = {
    [id: ContactId]: {
        isLoading: boolean,
        items: LeadId[],
    },
};

const defaultRelatedLeadsState = {
    isLoading: false,
    items: [],
};
export const relatedLeadsReducer = (
    state: RelatedLeadsState = {},
    action: LeadsAction
): RelatedLeadsState => {
    switch (action.type) {
        case LeadsActionTypes.LEADS_FETCH_LIST_FOR_CONTACT_REQUEST: {
            const newState = state[action.payload]
                ? {...state[action.payload], isLoading: true}
                : {...defaultRelatedLeadsState, isLoading: true};

            return {...state, [action.payload]: newState};
        }
        case LeadsActionTypes.LEADS_FETCH_LIST_FOR_CONTACT_FAILURE: {
            const newState = state[action.payload.contactId]
                ? {...state[action.payload.contactId], isLoading: false}
                : {...defaultRelatedLeadsState, isLoading: false};

            return {...state, [action.payload.contactId]: newState};
        }
        case LeadsActionTypes.LEADS_FETCH_LIST_FOR_CONTACT_SUCCESS: {
            const leadIds = action.payload.response.listItems.map((item) => item.links.entity.id);
            const newState = {
                isLoading: false,
                items: leadIds,
            };

            return {...state, [action.payload.contactId]: newState};
        }
        default:
            return state;
    }
};
