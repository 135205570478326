/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import './accent-border.css';

type Props = {
    children: React.Node,
    color: string,
    location?: 'top' | 'left' | 'bottom' | 'right',
};

AccentBorder.defaultProps = {
    location: 'left',
};

/**
 * Renders a border along an edge of a container, typically used inside a `<Card>` component.
 */
export function AccentBorder({color, location, children}: Props) {
    const accentBorderStyle = {borderColor: color};

    const styleName = classnames('accent-border', {
        top: location === 'top',
        left: location === 'left',
        bottom: location === 'bottom',
        right: location === 'right',
    });

    return (
        <div styleName={styleName} style={accentBorderStyle}>
            {children}
        </div>
    );
}
