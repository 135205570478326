/* @flow */

import * as React from 'react';
import {FocusTrapZone} from '@fluentui/react/lib/FocusTrapZone';

import './alert-box.css';

type Props = {
    onClose: (e?: SyntheticEvent<*>) => any,
    children?: any,
    trapFocus: {
        // Whether to disable the FocusTrapZone's focus trapping behavior.
        disabled?: boolean,
        // If false (the default), the trap zone will restore focus to the element
        // which activated it once the trap zone is unmounted or disabled.
        // Set to true to disable this behavior.
        ignoreExternalFocusing?: boolean,
        // Sets the element to focus on when exiting the FocusTrapZone.
        elementToFocusOnDismiss?: React.Node,
    },
    maxWidth?: number,
};

AlertBox.defaultProps = {
    trapFocus: {
        disabled: false,
    },
};

export function AlertBox(props: Props) {
    return (
        <FocusTrapZone
            className='full-height full-width'
            isClickableOutsideFocusTrap={true}
            disabled={props.trapFocus.disabled}
            ignoreExternalFocusing={props.trapFocus.ignoreExternalFocusing}
            elementToFocusOnDismiss={props.trapFocus.elementToFocusOnDismiss}
        >
            <div styleName='container'>
                <div styleName='backdrop' onClick={props.onClose} />
                <div styleName='input-container' style={{maxWidth: props.maxWidth || 448}}>
                    {props.children}
                </div>
            </div>
        </FocusTrapZone>
    );
}
