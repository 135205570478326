/* @flow */

import * as React from 'react';

import {colors} from '../../colors';
import {Card} from '../../card';
import type {File as FileType} from '../types';
import {File} from '../../files';

type Props = {|
    files: FileType[],
|};

export function TimelineBannerFilesCard(props: Props) {
    const files = props.files.map((file) => (
        <File
            key={file.fileId}
            id={file.fileId}
            filename={file.name}
            filetype={file.fileType}
            href={file.href}
            withNoBorder={true}
        />
    ));

    return (
        <Card hasBorder={true} hasShadow={true} fitHeight={true} leftLineColor={colors.grey}>
            <div className='pad-8'>{files}</div>
        </Card>
    );
}
