/* @flow */

import * as React from 'react';

import {colors} from '../../colors';

import './scrollable-container-with-shadow.css';

const DEFAULT_GRADIENT_SHADOW_SIZE = 25;

type ScrollDirection = 'horizontal' | 'vertical';

type Props = {|
    // Scroll direction
    direction: ScrollDirection,
    // Height for vertical, width for horizontal
    maxContainerSize: number,
    // Height of gradient shadow for vertical, width for horizontal
    gradientShadowSize?: number,
    // True if gradiant should show at opposite end of container when content is fully scrolled
    showReverseGradientShadow?: boolean,
    // The scrollable content
    children: React.Node,
|};

/**
 * A wrapper component that places children within a scrollable container in the direction
 * specified, and displays a gradient shadow in the scroll direction until the end of content
 * is reached.
 *
 * An optional prop, showReverseGradientShadow, will display the gradient shadow at the beginning of
 * content when the end has been reached.
 */
export function ScrollableContainerWithShadow(props: Props) {
    const {direction, showReverseGradientShadow, maxContainerSize, children} = props;
    const gradientSize = props.gradientShadowSize
        ? props.gradientShadowSize
        : DEFAULT_GRADIENT_SHADOW_SIZE;

    const [shouldShowShadow, setShouldShowShadow] = React.useState<boolean>(true);
    const [canContentScroll, setCanContentScroll] = React.useState<boolean>(false);

    const contentRef = React.useRef();

    /*
     * Effect to determine if shadow should be displayed. When actual content is smaller than
     * the maximum container size, we do not want to display a shadow as there will be no content to
     * scroll through.
     */
    React.useLayoutEffect(() => {
        if (contentRef && contentRef.current) {
            if (contentRef && contentRef.current) {
                const {scrollHeight, scrollWidth} = contentRef.current;

                setCanContentScroll(
                    (direction === 'vertical' && scrollHeight > maxContainerSize) ||
                        (direction === 'horizontal' && scrollWidth > maxContainerSize)
                );
            }
        }
    }, [direction, maxContainerSize, children]);

    function handleOnScroll() {
        if (contentRef && contentRef.current) {
            const {
                // For vertical scroll
                scrollTop,
                scrollHeight,
                clientHeight,
                // For horizontal scroll
                scrollLeft,
                scrollWidth,
                clientWidth,
            } = contentRef.current;

            let visibleContent;
            let scrollDistance;

            if (direction === 'vertical') {
                visibleContent = clientHeight;
                scrollDistance = scrollHeight - scrollTop;
            } else {
                visibleContent = clientWidth;
                scrollDistance = scrollWidth - scrollLeft;
            }

            // Show the gradient if scroll distance does not equal visible content + 8px buffer
            setShouldShowShadow(scrollDistance >= visibleContent + 8);
        }
    }

    return (
        <div styleName='scrollable-container-with-shadow'>
            <div
                styleName='scrollable-container'
                style={{
                    maxHeight: direction === 'vertical' ? maxContainerSize : '100%',
                    maxWidth: direction === 'horizontal' ? maxContainerSize : '100%',
                }}
                onScroll={canContentScroll ? handleOnScroll : undefined}
                ref={(ref) => (contentRef.current = ref)}
            >
                {children}
            </div>
            {canContentScroll ? (
                <GradientShadow
                    direction={direction}
                    gradientSize={gradientSize}
                    shouldShowGradient={shouldShowShadow}
                    showReverseGradient={showReverseGradientShadow}
                />
            ) : (
                undefined
            )}
        </div>
    );
}

const GradientShadow = (gradientProps: {
    direction: ScrollDirection,
    gradientSize: number,
    shouldShowGradient: boolean,
    showReverseGradient?: boolean,
}) => {
    const {direction, gradientSize, shouldShowGradient, showReverseGradient} = gradientProps;

    // If we aren't showing either gradient, return
    if (!shouldShowGradient && !showReverseGradient) return null;

    // Gradient style, positioning
    let gradientStyles;
    let reverseGradientStyles;
    // Gradient fade direction
    let gradientDirection;
    let reverseGradientDirection;

    if (direction === 'vertical') {
        // Main gradient
        gradientStyles = {
            width: '100%',
            height: gradientSize,
            right: 0,
            bottom: 0,
        };
        gradientDirection = 'bottom';

        // Optional reverse gradient
        reverseGradientStyles = {
            ...gradientStyles,
            top: 0,
        };
        reverseGradientDirection = 'top';
    } else {
        // Main gradient
        gradientStyles = {
            width: gradientSize,
            height: '100%',
            top: 0,
            right: 0,
        };
        gradientDirection = 'right';

        // Optional reverse gradient
        reverseGradientStyles = {
            ...gradientStyles,
            left: 0,
        };
        reverseGradientDirection = 'left';
    }

    const shouldShowReverseGradient = showReverseGradient && !shouldShowGradient;

    return (
        <div
            styleName='gradient-shadow'
            style={{
                ...(shouldShowReverseGradient ? reverseGradientStyles : gradientStyles),
                background: `linear-gradient(to ${
                    shouldShowReverseGradient ? reverseGradientDirection : gradientDirection
                }, rgba(255, 255, 255, 0.001), ${colors.white})`,
            }}
        />
    );
};
