/* @flow */

import * as React from 'react';
import {Checkbox} from '../checkbox/checkbox';
import type {Props as CheckboxProps} from '../checkbox/checkbox';

import './conditional-checkbox-input.css';

type Props = {
    ...CheckboxProps,
    children: React.Node,
    isConditionalAlignedWithLabel?: boolean,
};

/* Responsible for rendering a checkbox component that when enabled
conditionally shows content beneath it, keeping a consistent checkbox
gutter */
export function ConditionalCheckboxInput(props: Props) {
    const {children, isConditionalAlignedWithLabel = true, ...checkboxProps} = props;

    return (
        <React.Fragment>
            <Checkbox {...checkboxProps} isLarge={true} />
            {checkboxProps.checked ? (
                <div
                    styleName={
                        isConditionalAlignedWithLabel
                            ? 'conditional-container--aligned'
                            : 'conditional-container'
                    }
                >
                    {children}
                </div>
            ) : (
                undefined
            )}
        </React.Fragment>
    );
}
