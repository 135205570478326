/* @flow */

import {createSelector} from 'reselect';
import * as ramda from 'ramda';

import type {NutshellSharedState} from '../store';

export const getStagesState = (state: NutshellSharedState) => state.stages;

// $FlowFixMe upgrading Flow to v0.92.1
export const getIsLoading = createSelector([getStagesState], (stagesState) => {
    return stagesState.isLoading;
});

// $FlowFixMe upgrading Flow to v0.92.1
export const getErrorMessage = createSelector([getStagesState], (stagesState) => {
    return stagesState.errorMessage;
});

// $FlowFixMe upgrading Flow to v0.92.1
export const getAllStages = createSelector([getStagesState], (stagesState) => {
    return stagesState.byStageset;
});

/**
 * Memoized selector that returns an array of stages that pertain to the
 * passed stageset id.
 * @param  {string} stagesetId          - Id of stageset to get related stages
 * @return {LeadStage[]}                - Array of LeadStages related to the
 *                                        given stage
 */
export const getStagesByStageset = ramda.memoizeWith<string, Function, string>(
    ramda.identity,
    (stagesetId: string) => {
        return createSelector([getAllStages], (stages) => ramda.values(stages[stagesetId]));
    }
);
