import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 31.5 47'}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                fill='#1FB790'
                d='M27.2 23.9c-2.3 1.6-4.9 2.3-7.6 2-1 2.6-.2 6.3.2 7.6h4.6c.8 0 1.2.7 1.1 1.5l-2.1 10.5c-.2.8-.9 1.5-1.7 1.5h-7.3c-.8 0-1.5-.7-1.7-1.5L10.5 35c-.2-.8.3-1.5 1.1-1.5h4.9c.2-2 .8-6.5 1.9-9.1 1.3-6.6 6.5-10.7 12.7-9.6.3.1.6.4.5.8-.6 3.2-2.1 6.3-4.4 8.3zm-10.9-3.3C9.2 16 7.6 7.5 12.2.3c.2-.4.8-.3 1.1-.1 3.2 2 5.7 5.2 6.6 8.9v.2c.6 3.9-.5 7.9-2.6 11.2-.2.4-.7.3-1 .1zm-6.4-2.5s.1 0 .1.1c2.8 1.8 4.7 4.7 5.6 8 .1.4-.2.7-.5.8C8.3 29.1 2 25.4 0 18.3c-.1-.4.2-.7.5-.8 3.1-.9 6.5-.8 9.4.6z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'plant-filled-large';

export const PlantBigFilledIcon = svgIconWrapper(SvgComponent);
