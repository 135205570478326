/* @flow */

import type {Reaction, EmojiReaction} from './types';

/**
 * Collection of all possible reactions
 */
export const REACTIONS: {[key: Reaction]: EmojiReaction} = {
    THUMBS_UP: {
        unicode: '\u{1F44D}',
        label: 'Thumbs up',
    },
    THUMBS_DOWN: {
        unicode: '\u{1F44E}',
        label: 'Thumbs down',
    },
    SMILEY_FACE: {
        unicode: '\u{1F604}',
        label: 'Smile',
    },
    PARTY_POPPER: {
        unicode: '\u{1F389}',
        label: 'Party popper',
    },
};
