import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Source from './icon-tag-goal.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Source {...validProps} />;
}
SvgComponent.iconName = 'goalTag';

export const GoalTagIcon = svgIconWrapper(SvgComponent);
