/* @flow */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {PhoneCallContainer} from '../ui/phone-call/phone-call-container';

type PhoneCallParameters = {
    entityId: string,
    activityId?: string,
    phoneNumber: {
        E164: string,
        numberFormatted: string,
    },
    shouldRecord?: boolean,
};

export function call(parameters: PhoneCallParameters) {
    const overlay = document.getElementById('phone-call-overlay');

    // There is already an active overlay lets just bail
    if (!overlay || overlay.childElementCount) {
        logError('Attempted to place call while overlay is open');

        return;
    }

    const phoneCallContainer = (
        <PhoneCallContainer
            {...parameters}
            onClose={() => {
                ReactDOM.unmountComponentAtNode(overlay);
            }}
        />
    );
    ReactDOM.render(phoneCallContainer, overlay);
}

function logError(errorMessage) {
    if (typeof window.trackJs !== 'undefined') {
        window.trackJs.track(errorMessage);
    }
}
