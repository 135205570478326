import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import OutboundCall from './icon-outbound-call.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <OutboundCall {...validProps} />;
}
SvgComponent.iconName = 'outboundCall';

export const OutboundCallIcon = svgIconWrapper(SvgComponent);
