import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Chat from './icon-chat.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Chat {...validProps} />;
}
SvgComponent.iconName = 'chat';

export const ChatIcon = svgIconWrapper(SvgComponent);
