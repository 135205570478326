/* @flow */

import * as React from 'react';
import {colors} from '../colors';

import './feature-usage-box.css';

type Props = {
    currentUsage: number,
    maxUsage: ?number,
    hasCustomLimit: boolean,
    isCurrentPlan: boolean,
};

export function FeatureUsageBox(props: Props) {
    return (
        <div styleName={props.isCurrentPlan ? 'feature-usage-box' : 'feature-usage-box--next-plan'}>
            <div styleName='usage-bars'>
                <Bar
                    fill={colors.blue}
                    total={props.currentUsage}
                    // Arbitrarily putting the current, limited usage at 25% of "unlimited" for visual clarity
                    percent={props.maxUsage ? props.currentUsage / props.maxUsage : 0.25}
                    text='Used so far'
                />
                <Bar
                    fill={props.isCurrentPlan ? colors.orange : colors.upgrade}
                    total={props.maxUsage || 'Unlimited'}
                    percent={1}
                    text={decidePlanTextBasedOnCustomLimitAndUpgrade(
                        props.isCurrentPlan,
                        props.hasCustomLimit
                    )}
                />
            </div>
        </div>
    );
}

function decidePlanTextBasedOnCustomLimitAndUpgrade(
    isCurrentPlan: boolean,
    hasCustomLimit: boolean
) {
    if (isCurrentPlan !== null) {
        if (isCurrentPlan) {
            return hasCustomLimit ? 'Custom limit' : 'Plan limit';
        }
    }

    return 'With upgrade';
}

type BarProps = {
    fill: string,
    text: string,
    total: number | string,
    percent: number,
};

function Bar(props: BarProps) {
    return (
        <div styleName='bar-container'>
            {props.total}
            <div
                styleName='bar'
                style={{backgroundColor: props.fill, height: `${props.percent * 100}%`}}
            />
            <div styleName='subtext'>{props.text}</div>
        </div>
    );
}
