/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import User from './icon-user.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <User {...validProps} />;
}

SvgComponent.iconName = 'user';

export const UserIcon = svgIconWrapper(SvgComponent);
