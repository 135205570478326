/* @flow */

export const NON_NULL_ERROR_MESSAGE = 'This value is required';

export function nonNull(value: any): ?string {
    if (!value) {
        return NON_NULL_ERROR_MESSAGE;
    }

    if (Array.isArray(value) && value.length === 0) {
        return NON_NULL_ERROR_MESSAGE;
    }
}
