/* @flow */
import {httpErrorCheck} from '../utils/errors';
import {Routing} from '../routing';
import {mergeHeaders} from './merge-headers';
import {defaultOptions} from './default-options';

export function post(url: string, body: Object, options?: RequestOptions = {}) {
    const postDefaultHeaders = mergeHeaders({'Content-Type': 'application/json'});
    const headers = mergeHeaders(options.headers, postDefaultHeaders);
    const contentType = headers.get('Content-Type');

    return fetch(url, {
        ...defaultOptions,
        ...options,
        method: 'POST',
        headers,
        body:
            contentType && contentType.includes('json')
                ? JSON.stringify(body)
                : Routing.param(body),
    }).then(httpErrorCheck);
}
