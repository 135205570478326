/* @flow */
import {httpErrorCheck} from '../utils/errors';

import {defaultOptions, defaultHeaders} from './default-options';
import {mergeHeaders} from './merge-headers';

export function patch(url: string, body: Object[], options: RequestOptions): Promise<Response> {
    const defaultPatchHeaders = new Headers(defaultHeaders);
    defaultPatchHeaders.set('content-type', 'application/json-patch+json');

    return fetch(url, {
        ...defaultOptions,
        ...options,
        method: 'PATCH',
        headers: mergeHeaders(options.headers, defaultPatchHeaders),
        body: JSON.stringify(body),
    }).then(httpErrorCheck);
}
