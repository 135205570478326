/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import './button-card.css';

type Props = {
    // Not currently possible with flow
    // ...HTMLButtonElement,
    onClick?: (e: SyntheticEvent<*>) => void,
    children: React.Node,
    autoFocus?: boolean,
    isSelected?: boolean,
    /** If true, the card will size vertically according to the content, not the parent's height */
    fitHeight?: boolean,
    /** If true, the card's border will not look "clickable" */
    isDisabled?: boolean,
    isFullWidth?: boolean,
    needsAttention?: boolean,
    isTransparent?: boolean,
    isFlex?: boolean,
    /** If true, the card's opacity will be reduced if it is disabled */
    showDisabledOverlay?: boolean,
    /** If true, the card's opacity will be reduced if it isSelected is false */
    showUnselectedOverlay?: boolean,
};

ButtonCard.displayName = 'ButtonCard';

/**
 * This component uses our simple card styling, but acts
 * as a form element, and thus contains hover/active/focus states.
 *
 * This is usually used to select a single rich option, usually as the first selection in a series of forms.
 */
export function ButtonCard({
    children,
    onClick,
    isSelected,
    fitHeight,
    isFullWidth,
    needsAttention,
    isDisabled,
    isTransparent,
    isFlex,
    showDisabledOverlay,
    showUnselectedOverlay,
    ...otherProps
}: Props) {
    const styleNames = classnames({
        'button-card': true,
        'button-card--selected': isSelected,
        'button-card--fitHeight': fitHeight,
        'button-card--fullWidth': isFullWidth,
        'button-card--transparent': isTransparent,
        'button-card--flex': isFlex,
        'button-card--needsAttention': needsAttention,
        'button-card--noClick': !onClick,
        'button-card--disabled': isDisabled,
        'button-card--disabled-overlay':
            (isDisabled && showDisabledOverlay) || (!isSelected && showUnselectedOverlay),
    });

    return (
        <button
            {...otherProps}
            onClick={onClick}
            disabled={isDisabled}
            type='button'
            styleName={styleNames}
        >
            {children}
        </button>
    );
}
