/* @flow */

import * as React from 'react';
import {ArrowRightIcon} from '../../icon';

import './see-all-button.css';

type Props = {
    onClick: () => void,
    text: string,
};

export function SeeAllButton(props: Props) {
    return (
        <button onClick={props.onClick} styleName='see-all-button'>
            <div onClick={props.onClick} styleName='arrow'>
                <ArrowRightIcon wrapWithDiv={false} size={30} />
            </div>
            <span>{props.text}</span>
        </button>
    );
}
