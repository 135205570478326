/* @flow */

import type {ReportGap, FilterObject} from 'nutshell-core/types';
import {serializeFilters} from 'nutshell-core';

export type LandingPagesChartParams = {
    gap: ReportGap,
    pageId?: string,
};

export type LandingPagesReportPostParams = {
    filter: Object,
    gap: ReportGap,
};

export function getLandingPagesReportPostParams(
    reportParams: LandingPagesChartParams,
    filters: FilterObject[]
): LandingPagesReportPostParams {
    const {gap, pageId} = reportParams;

    return {
        filter: serializeFilters(filters),
        gap,
        pageId,
    };
}
