/* @flow */

import * as React from 'react';
import {Button} from '../button';
import {ChevronDownIcon, ChevronRightIcon} from '../../icon';

type Props = {
    isShown: boolean,
    onToggle: (newState: boolean) => void,
    showText?: string,
    hideText?: string,
    noPadding?: boolean,
    variant?: 'text-primary' | 'text-secondary',
    size?: number,
};

export function ShowHideButton(props: Props) {
    const {variant = 'text-primary', size = 11} = props;

    return (
        <Button onClick={props.onToggle} variant={variant} noPadding={props.noPadding}>
            {props.isShown ? (
                <>
                    {props.showText && <span className='mr-4'>{props.showText}</span>}
                    <ChevronDownIcon size={size} />
                </>
            ) : (
                <>
                    {props.hideText && <span className='mr-4'>{props.hideText}</span>}
                    <ChevronRightIcon size={size} />
                </>
            )}
        </Button>
    );
}
