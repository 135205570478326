/* @flow */
import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import {SortIcon} from '../icon';
import {CloseButton, Button} from '../button';
import {Select} from '../select';
import {RadioGroup, Radio} from '../radio';
import {CheckmarkSubmitButton} from '../../../../../ui/base/form-components/checkmark-submit-button';
import {Popover, type LocationEnum} from './popover';

import styles from './popover-sort-deprecated.css';

const ASCENDING = 'ascending';
const DESCENDING = 'descending';

export type Direction = 'ascending' | 'descending';
export type SortField = 'value' | 'closedTime' | 'createdTime' | 'nextActivityStartTime';
type FieldOptions = {
    id: string,
    label: string,
};

type Props = {
    location: LocationEnum,
    activeSort: {
        field: string,
        direction?: Direction,
    },
    buttonSize: number,
    fields: FieldOptions[],
    onChangeSort: (string) => any,
    title: string,
    allowDescending: boolean,
};

type State = {
    isOpen: boolean,
    direction?: Direction,
    field: string,
};

export class PopoverSortDeprecated extends React.Component<Props, State> {
    sortPopoverAnchor: ?HTMLElement;

    static defaultProps = {
        location: 'bottom',
        title: 'Sort by…',
        buttonSize: 24,
        allowDescending: true,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            isOpen: false,
            direction: props.activeSort.direction,
            field: props.activeSort.field,
        };
    }

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    render() {
        const currentValue =
            this.props.fields.find((opt) => opt.id === this.state.field) || this.props.fields[0];

        return (
            <div className={styles.container}>
                <div styleName='sort-button'>
                    <Button
                        variant='text'
                        getButtonRef={(node) => {
                            this.sortPopoverAnchor = node;
                        }}
                        onClick={this.handleToggleOpen}
                        data-tip={
                            this.props.activeSort.direction
                                ? `Sorted by ${
                                      this.props.activeSort.direction
                                  } ${currentValue.label.toLowerCase()}`
                                : `Sorted by ${currentValue.label.toLowerCase()}`
                        }
                        data-place='top'
                    >
                        <SortIcon
                            size={this.props.buttonSize}
                            fill='#fff'
                            ascending={this.props.activeSort.direction === 'ascending'}
                        />
                    </Button>
                </div>

                {this.state.isOpen ? (
                    <Popover
                        className='ui-popover'
                        anchor={this.sortPopoverAnchor}
                        location={this.props.location}
                        onBlur={this.handleToggleOpen}
                    >
                        <div className='toolbar'>
                            <h1 className='ui-header ui-header--md'>{this.props.title}</h1>
                        </div>
                        <div className={styles['inner-wrapper']}>
                            <CloseButton
                                className='ui-btn__popover-close'
                                onClick={this.handleToggleOpen}
                            />
                            <div>
                                <Select
                                    selectValues={this.props.fields}
                                    value={currentValue}
                                    labelKey={'label'}
                                    onChange={this.handleChangeField}
                                />
                                {this.props.allowDescending ? (
                                    <RadioGroup
                                        name='sort-direction'
                                        selectedValue={this.state.direction}
                                        onChange={this.handleChangeDirection}
                                    >
                                        <Radio value={ASCENDING}>Ascending</Radio>
                                        <Radio value={DESCENDING}>Descending</Radio>
                                    </RadioGroup>
                                ) : (
                                    undefined
                                )}
                            </div>
                            <div className={styles['submit-button']}>
                                <CheckmarkSubmitButton
                                    className='ui-popover__submit-button'
                                    disabled={false}
                                    commandEnterSubmit={true}
                                    onClick={this.handleChangeSort}
                                />
                            </div>
                        </div>
                    </Popover>
                ) : (
                    undefined
                )}
            </div>
        );
    }

    handleChangeDirection = (direction: Direction) => {
        this.setState({direction});
    };

    handleChangeField = (newFieldOption: {id: SortField, label: string}) => {
        this.setState({
            field: newFieldOption.id,
        });
    };

    handleToggleOpen = () => {
        ReactTooltip.hide();
        this.setState((state) => ({
            isOpen: !state.isOpen,
            // Reset field and direction to props.  Technically only necessary when opening, but doesn't hurt on close
            field: this.props.activeSort.field,
            direction: this.props.activeSort.direction,
        }));
    };

    handleChangeSort = () => {
        const newSort =
            this.state.direction === 'descending' ? `-${this.state.field}` : this.state.field;
        this.props.onChangeSort(newSort);
        this.setState((state) => ({isOpen: !state.isOpen}));
    };
}
