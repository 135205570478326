/* @flow */

import {useMutation} from '@apollo/react-hooks';
import type {RequestTranscribeToken as TranscribeTokenRequestMutation} from 'nutshell-graphql-types';

import REQUEST_TRANSCRIBE_TOKEN from './graphql/request-transcribe-token.graphql';

export const useRequestTranscribeToken = () => {
    const [requestTokenRespond] = useMutation<TranscribeTokenRequestMutation, {}>(
        REQUEST_TRANSCRIBE_TOKEN
    );

    return () => {
        return requestTokenRespond();
    };
};
