import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Comment from './icon-comment.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Comment {...validProps} />;
}
SvgComponent.iconName = 'comment';

export const CommentIcon = svgIconWrapper(SvgComponent);
