/* @flow */

import * as React from 'react';

export function UnsubscribeDoodle() {
    return (
        <svg
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            width='200'
            height='139'
            viewBox='0 0 200 139'
        >
            <g clipPath='url(#clip0)'>
                <path
                    d='M42.815 15.363c-4.855 33.23-5.42 66.863-7.934 100.416-.35 3.908 4.193 4.535 4.542.628 2.514-33.553 3.08-67.187 7.934-100.416.486-3.888-3.919-4.497-4.542-.628z'
                    fill='#0C0F33'
                />
                <path
                    d='M37.888 119.398c17.16 4.219 35.925 4.235 53.534 5.946 29.509 2.637 58.8 8.1 88.433 9.755 2.822.103 3.385-4.388.579-4.631-29.083-1.723-57.931-6.41-86.955-9.694-18.104-2.065-37.32-1.715-55.011-6.008-2.752-.664-3.332 3.967-.58 4.632zM45.737 17.088c17.158 4.229 35.926 4.236 53.535 5.947 29.51 2.635 64.868 7.673 94.502 9.322 2.823.103 3.388-4.406.582-4.65-29.085-1.716-64-5.976-93.024-9.261-18.105-2.066-37.324-1.706-55.013-6.008-2.753-.666-3.335 3.984-.582 4.65z'
                    fill='#0C0F33'
                />
                <path
                    d='M192.96 30.379c-1.268 33.854-6.878 67.299-11.907 100.636-.626 3.861 3.972 4.494 4.598.632 5.03-33.337 10.64-66.781 11.908-100.636.207-3.92-4.39-4.552-4.599-.632zM41.183 116.008c14.192-13.806 37.733-37.376 53.454-49.3 2.696-2.052-.288-6.414-2.984-4.362C75.951 74.12 52.392 97.84 38.2 111.646c-2.448 2.239.535 6.601 2.983 4.362z'
                    fill='#0C0F33'
                />
                <path
                    d='M139.602 68.223c11.091 16.568 29.419 45.58 41.876 60.974 1.872 2.385 5.363-.618 3.492-3.003-12.457-15.393-30.768-44.555-41.877-60.974-1.72-2.516-5.212.487-3.491 3.003zM44.565 19.409C55.842 29.958 65.212 42.78 76.664 53.016c11.472 10.068 23.16 19.489 35.198 28.28 2.362 1.685 5.204-2.661 2.708-4.364-11.413-8.366-22.477-17.361-33.48-26.856-5.133-4.441-10.399-8.902-15.183-13.973-6.194-6.791-11.844-14.692-18.633-21.059-2.188-1.999-4.897 2.366-2.709 4.365z'
                    fill='#0C0F33'
                />
                <path
                    d='M114.331 81.358c13.141-3.457 24.582-11.928 36.476-19.654 14.874-9.699 29.892-18.354 44.446-28.78 2.534-1.694.855-6.877-1.566-4.996-12.909 9.29-26.21 17.332-39.552 25.709-13.188 8.228-25.685 18.6-40.232 22.372-2.947.613-2.405 6.148.428 5.35z'
                    fill='#0C0F33'
                />
                <path
                    d='M10.062 112.15C8.063 98.692 21.34 89.306 29.708 85.939c6.663-1.36 13.435 1.313 18.52 5.83 14.463 16.487 5.288 29.824.622 34.726-20.565 15.069-36.365 1.969-38.788-14.345z'
                    fill='#DBDBF2'
                    stroke='#000'
                />
                <path
                    d='M22.525 114.788c-1.272.04-2.528.031-3.723-.023-.537-.026-1.005-1.292-1.037-2.831-.032-1.538.365-2.851.917-2.739a46.89 46.89 0 003.665.025c4.723-.256 9.427-.818 14.085-1.682 3.878-.635 7.892-1.293 11.833-1.573.53-.016 1.009 1.2 1.063 2.713.054 1.514-.333 2.813-.874 2.849-3.906.279-7.904.936-11.765 1.571a111.05 111.05 0 01-14.164 1.69z'
                    fill='#0C0F33'
                />
                <path
                    d='M32.645 135.592c-16.258-1.59-26.41-15.127-25.311-26.363 1.293-13.218 12.37-24.144 25.108-24.766 1.07-.03 2.141-.058 3.066.033 12.161 1.19 24.303 12.119 22.764 27.848-1.357 13.879-13.07 24.477-25.627 23.248zm2.475-47.131c-.793-.078-1.718-.168-2.524-.114-10.73.552-20.185 9.903-21.297 21.27-1.1 11.236 10.63 20.924 21.734 22.01 10.178.996 20.149-8.171 21.274-19.671C55.6 98.738 45.43 89.47 35.12 88.461z'
                    fill='#0C0F33'
                />
            </g>
            <defs>
                <clipPath id='clip0'>
                    <path fill='#fff' transform='rotate(5.587 7 143.453)' d='M0 0h189v139H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
