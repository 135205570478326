/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import type {SavedSettingToastState} from '../saved-setting-toast';
import {TextField, type Props as TextFieldProps} from '../textfield/textfield';
import {FormField} from '../form';

type OwnProps = {|
    title?: string,
    subtitle?: string,
    placeholder?: string,
    helperText?: string,
    multiline?: boolean,
    isRequired?: boolean,
    focusOnMount?: boolean,
    noMargin?: boolean,
    tooltipInfo?: string,
    saveToastState?: SavedSettingToastState,
    errorMessage?: string,
    ...$Exact<TextFieldProps>,
|};

type Props = {...$Exact<FieldProps>, ...OwnProps};

export function ReduxFormTextField({
    title,
    subtitle,
    helperText,
    isRequired,
    noMargin,
    input,
    meta,
    tooltipInfo,
    saveToastState,
    errorMessage,
    ...restProps
}: Props) {
    return (
        <FormField
            innerId={input.name}
            label={title}
            sublabel={subtitle}
            helperText={helperText}
            errorMessage={errorMessage || (meta.error && meta.submitFailed ? meta.error : null)}
            isRequired={isRequired}
            noMargin={noMargin}
            tooltipInfo={tooltipInfo}
            saveToastState={saveToastState}
        >
            <TextField {...input} {...restProps} />
        </FormField>
    );
}
