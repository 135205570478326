/* @flow */

import * as React from 'react';

import {EmptyStateLarge} from 'shells/empty-states';
import {Button} from 'shells/button';
import {ButtonMover} from 'shells/button-mover';
import {Link} from 'shells/link';
import {PlugErrorState} from 'shells/illustrations';

import './error-state.css';

type Props = {
    errorMessage?: ?string,
    size?: 'big' | 'small',
};

export function ErrorState(props: Props) {
    const isSmall = props.size === 'small';

    return (
        <div styleName='container'>
            <EmptyStateLarge
                title={props.errorMessage ? props.errorMessage : 'Oh no, something went wrong'}
                subtitle={
                    <div styleName={isSmall ? 'subtitle-container--small' : 'subtitle-container'}>
                        Please&nbsp;
                        <ButtonMover
                            size={isSmall ? 'normal' : 'xlarge'}
                            direction={['left', 'right', 'up', 'down']}
                        >
                            <Button
                                size={isSmall ? 'normal' : 'xlarge'}
                                variant='text-primary'
                                onClick={() => {
                                    location.reload();
                                }}
                            >
                                try again
                            </Button>
                        </ButtonMover>
                        &nbsp;or&nbsp;
                        <Link
                            size={isSmall ? 'normal' : 'big'}
                            href='https://www.nutshell.com/support'
                            variant='primary'
                        >
                            contact support.
                        </Link>
                    </div>
                }
                icon={
                    <div style={{width: isSmall ? '150px' : '284px'}}>
                        <PlugErrorState />
                    </div>
                }
            />
        </div>
    );
}
