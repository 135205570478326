/* @flow */

import type {Outcome, OutcomeId} from '../types';
import type {OutcomesAction} from './outcomes-actions';
import {OUTCOMES_REQUESTED, OUTCOMES_UPDATED, OUTCOMES_FAILED} from './outcomes-actions';

export type OutcomesState = {
    isLoading: boolean,
    byId: {
        // $NutshellClientFlowIgnore upgrading Flow to v0.92.1 on web
        [OutcomeId]: Outcome,
    },
    errorMessage: ?string,
};

const outcomesDefaultState = {
    isLoading: false,
    byId: {},
    errorMessage: null,
};

export const reducer = (
    state: OutcomesState = outcomesDefaultState,
    action: OutcomesAction
): OutcomesState => {
    switch (action.type) {
        case OUTCOMES_REQUESTED: {
            return {...state, isLoading: true, errorMessage: null};
        }
        case OUTCOMES_UPDATED: {
            const newOutcomes = action.payload.outcomes.reduce(
                (accum, outcome: Outcome) => {
                    accum[outcome.id] = outcome;

                    return accum;
                },
                {...state.byId}
            );

            return {
                ...state,
                // $NutshellClientFlowIgnore upgrading Flow to v0.92.1
                byId: newOutcomes,
                isLoading: false,
            };
        }
        case OUTCOMES_FAILED: {
            return {
                ...state,
                errorMessage: 'We’re having trouble getting your lead outcomes right now.',
                isLoading: false,
            };
        }
        default:
            return state;
    }
};
