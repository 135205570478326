/* @flow */

import * as React from 'react';

type Props = {
    width?: number,
};

export function FormFields(props: Props) {
    return (
        <svg
            width={props.width}
            viewBox='0 0 157 108'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect y='11.1685' width='45.4257' height='9.52475' rx='2.93069' fill='#EBE8E8' />
            <rect
                x='0.5'
                y='24.124'
                width='156'
                height='25.3762'
                rx='2.43069'
                fill='white'
                stroke='#EBE8E8'
            />
            <rect y='58' width='82.0594' height='9.52475' rx='2.93069' fill='#EBE8E8' />
            <rect
                x='0.5'
                y='70.9556'
                width='156'
                height='25.3762'
                rx='2.43069'
                fill='white'
                stroke='#EBE8E8'
            />
        </svg>
    );
}
