/* @flow */

import type {EventType, EventActionType, ChangeType} from '../../types';

/**
 * Helper to get the "verb" text for a timeline entry in Nutshell.
 *
 * "Verb" is a loaded term here, but the idea here is that each _type_ of
 * timeline event has unique text for joining together both the "actor"
 * and the "participants" of the entry.
 *
 * As an example, Chris[0] wrote a note on[1] Bob Smith[2]
 * [0] - actor
 * [1] - verb
 * [2] - participants
 *
 * This is just a bunch of case/switch statements, which occasionally
 * depend on the presence of a 'participant'
 *
 * @param  {Object} event         - Object containing the type of event, the action, and boolean indicating whether the event has participants
 * @return {string}               - Verb text for timeline entry
 */
export function getTimelineHeaderVerb(event: {
    type: EventType,
    action: EventActionType,
    hasParticipants: boolean,
    numVisits?: number,
    isPinnedThread?: boolean,
    isChatThread?: boolean,
}): string {
    const {type, action, hasParticipants, numVisits, isPinnedThread, isChatThread} = event;

    switch (type) {
        case 'Note':
            return getTimelineVerbForNotes(action);
        case 'Activity':
            return getTimelineVerbForActivities(action, hasParticipants);
        case 'Email':
            return getTimelineVerbForEmails(action);
        case 'AutomatedEmail':
            return 'automatically emailed';
        case 'AutomatedReply':
            return 'automatically followed up with';
        case 'FailedEmail':
            return 'tried to email';
        case 'TicketMessage':
            return getTimelineVerbForTicketMessages(action, hasParticipants);
        case 'NutshellMarketingEmail':
            return 'was sent';
        case 'Chat':
            return getTimelineVerbForChats(action, hasParticipants);
        case 'Session':
            return getTimelineVerbForSiteVisits(numVisits);
        case 'Thread':
            return getTimelineVerbForThreads(action, isPinnedThread, isChatThread);
        case 'ZoomMeeting':
            return 'logged a Zoom meeting with';
        case 'Account':
        case 'Contact':
        case 'Lead':
        case 'Comment':
        case 'Origin':
        default:
            return getDefaultTimelineActionVerb(action);
    }
}

function getDefaultTimelineActionVerb(action: EventActionType) {
    return {
        cancel: 'cancelled',
        click: 'clicked',
        create: 'added',
        delete: 'deleted',
        log: 'logged',
        lose: 'lost',
        reopen: 'reopened',
        update: 'updated',
        win: 'won',
        merge: 'merged',
        submit: 'submitted',
        comment: 'commented',
        upload: 'uploaded',
        hiddenEmailSync: 'synced',
        mailchimp: '',
        followup: 'followed up',
        stageChange: 'changed stage',
        automationFire: 'fired automation',
        instantiate: 'instantiated',
        map: 'mapped',
        undelete: 'undeleted',
        submissionMap: 'submitted',
        view: 'opened',
        visited: 'visited',
    }[action];
}

function getTimelineVerbForActivities(action: string, hasParticipants: boolean): string {
    let baseText;
    switch (action) {
        case 'cancel':
            baseText = 'cancelled an activity';
            break;
        case 'create':
            baseText = 'scheduled an activity';
            break;
        case 'delete':
            baseText = 'deleted an activity';
            break;
        case 'log':
        case 'update':
            baseText = 'logged an activity';
            break;
        default:
            baseText = 'logged an activity';
            break;
    }

    return hasParticipants ? `${baseText} with` : baseText;
}

function getTimelineVerbForNotes(action: string) {
    switch (action) {
        case 'create':
            return 'wrote a note on';
        case 'delete':
            return 'deleted a note on';
        case 'update':
            return 'updated a note on';
        default:
            return 'wrote a note on';
    }
}

function getTimelineVerbForThreads(
    action: string,
    isPinnedThread?: boolean,
    isChatThread?: boolean
) {
    if (isChatThread) {
        return 'chatted with';
    }

    if (isPinnedThread) {
        return 'has an open text thread with';
    }

    switch (action) {
        case 'update':
            return 'closed a text thread with';
        case 'reopen':
            return 'reopened a text thread with';
        default:
            return 'has an open text thread with';
    }
}

function getTimelineVerbForTicketMessages(action: string, hasParticipants: boolean): string {
    let baseText;
    switch (action) {
        case 'create':
            baseText = 'responded to a ticket';
            break;
        case 'delete':
            baseText = 'deleted a ticket';
            break;
        case 'update':
            baseText = 'updated a ticket';
            break;
        default:
            baseText = 'responded to a ticket';
            break;
    }

    return hasParticipants ? `${baseText} with` : baseText;
}

function getTimelineVerbForEmails(action: string) {
    switch (action) {
        case 'create':
            return 'emailed';
        case 'delete':
            return 'deleted an email to';
        default:
            return 'emailed';
    }
}

function getTimelineVerbForChats(action: string, hasParticipants: boolean) {
    switch (action) {
        case 'create':
            return hasParticipants ? 'chatted with' : 'chatted in';
        case 'delete':
            return 'deleted a chat with';
        default:
            return 'chatted with';
    }
}

function getTimelineVerbForSiteVisits(numVisits?: number) {
    return numVisits ? `visited ${numVisits} page${numVisits !== 1 ? 's' : ''} on` : 'visited';
}

/**
 * This function gets the timeline event action for the header as a string from
 * the changeType of the event
 *
 * @param changeType
 * @returns {string}
 */
export function getActionFromChangeType(changeType: ChangeType): EventActionType {
    switch (changeType) {
        case 'ADDED':
            return 'create';
        case 'DELETED':
        case 'HARD_DELETED':
            return 'delete';
        case 'EDITED':
            return 'update';
        case 'MERGED':
            return 'merge';
        case 'COMMENTED':
            return 'comment';
        case 'FILE_UPLOADED':
            return 'upload';
        case 'HIDDEN_EMAIL_SYNCED':
            return 'hiddenEmailSync';
        case 'MAILCHIMP':
            return 'mailchimp';
        case 'FOLLOWUP':
            return 'followup';
        case 'STAGE_CHANGED':
            return 'stageChange';
        case 'AUTOMATION_FIRED':
            return 'automationFire';
        case 'INSTANTIATED':
            return 'instantiate';
        case 'MAPPED':
            return 'map';
        case 'UNDELETED':
            return 'undelete';
        case 'FORM_SUBMISSION_MAPPED':
            return 'submissionMap';
        case 'CLICKED':
            return 'click';
        case 'VIEWED':
            return 'view';
        case 'VISITED':
            return 'visited';
        case 'REOPENED':
            return 'reopen';
        default:
            return 'create';
    }
}
