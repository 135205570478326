/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import {Button} from '../button';
import {Link} from '../link';
import {SlideshowControls} from '../carousel';
import {fadeDuration} from './onboarding-page.css';

const FADE_DURATION = parseInt(fadeDuration, 10);

type SinglePageProps = {
    type: 'singlePage',
    bodyId?: string,
    bodyComponent: ?React.Node,
    artImage: ?React.Element<any>,
    artBackgroundColor: string,
    buttonText: string, // Optional
    primaryAction?: {
        href?: string,
        onClick?: () => void,
    },
    onClose: () => void,
    bottomLeftContent?: React.Node,
    primaryActionDisabled?: boolean,
    actionButtonOnRight?: boolean,
};

type MultiPageProps = {
    type: 'multiPage',
    bodyComponent: ?React.Node,
    bodyId?: string,
    artImage: ?React.Element<any>,
    artBackgroundColor: string,
    buttonText: string, // Optional
    nextButtonDisabled?: boolean, // Optional
    finalButtonText: string, // Optional
    finalButtonDisabled?: boolean, // Optional
    currentPage: number, // Optional
    pageCount: number,
    onNext: () => void,
    onGotoPage: (number) => void,
    onClose: () => void,
    onFinish: () => void,
};

type Props = SinglePageProps | MultiPageProps;

type State = {
    isBodyFadingOut: boolean,
    bodyComponent: ?React.Node,
};

/**
This component is typically rendered inside a `<Modal>` component,
and is used to present information to the user in a modal.  It can be
a single page, or include controlls for interacting with multiple pages.
This component itself only takes a single `artImage` and `bodyComponent`,
so if you want to use multiple pages, it's up to the parent to control
those props and change them as needed.
*/
export class OnboardingPage extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isBodyFadingOut: false,
            bodyComponent: props.bodyComponent,
        };
    }

    static defaultProps = {
        buttonText: 'Next',
        finalButtonText: 'Finish',
        currentPage: 0,
    };

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (
            (nextProps.bodyComponent !== this.props.bodyComponent && !nextProps.bodyId) ||
            // Because we're now passing in rendered components, and not
            // components to be rendered, the above check is always true. This
            // causes a fade far too often. Checking the body id value fixes
            // that
            (this.props.type === 'multiPage' &&
                nextProps.bodyId &&
                this.props.bodyId !== nextProps.bodyId)
        ) {
            this.setState({isBodyFadingOut: true});
            setTimeout(() => {
                this.setState({
                    isBodyFadingOut: false,
                    bodyComponent: nextProps.bodyComponent,
                });
            }, FADE_DURATION);
        } else if (nextProps.bodyComponent !== this.props.bodyComponent) {
            // This allows us to re-render the body component without the fade
            // if body id does not change but the prop changes
            this.setState({bodyComponent: nextProps.bodyComponent});
        }
    }

    render() {
        const body = this.state.bodyComponent;
        const bodyStyleName = classnames('body', {'is-fading-out': this.state.isBodyFadingOut});

        let primaryButton = (
            <Button
                variant='primary'
                autoFocus={true}
                onClick={this.handlePrimaryButtonClick}
                disabled={this.props.primaryActionDisabled || false}
            >
                {this.props.buttonText}
            </Button>
        );
        const isLastPage =
            this.props.type === 'multiPage' && this.props.currentPage === this.props.pageCount - 1;
        if (this.props.type === 'multiPage') {
            if (isLastPage) {
                primaryButton = (
                    <Button
                        variant='primary'
                        onClick={this.handlePrimaryButtonClick}
                        disabled={this.props.finalButtonDisabled}
                    >
                        {this.props.finalButtonText}
                    </Button>
                );
            } else {
                primaryButton = (
                    <Button
                        variant='primary'
                        onClick={this.handlePrimaryButtonClick}
                        disabled={this.props.nextButtonDisabled}
                    >
                        {this.props.buttonText}
                    </Button>
                );
            }
        }
        if (this.props.primaryAction && typeof this.props.primaryAction.href === 'string') {
            primaryButton = (
                <Link href={this.props.primaryAction.href} size='small' variant='primary'>
                    {this.props.buttonText}
                </Link>
            );
        }

        let secondaryButton;
        if (this.props.type === 'multiPage' && this.props.currentPage >= 1) {
            secondaryButton = (
                <Button variant='text-secondary' onClick={this.handleSecondaryButtonClick}>
                    Back
                </Button>
            );
        }
        if (this.props.type === 'singlePage' && this.props.primaryAction && this.props.onClose) {
            secondaryButton = (
                <Button variant='text-secondary' onClick={this.props.onClose}>
                    Close
                </Button>
            );
        }

        return (
            <div styleName='onboarding-page'>
                <div styleName='left' style={{backgroundColor: this.props.artBackgroundColor}}>
                    {this.props.artImage}
                </div>
                <div styleName='right'>
                    {body ? <div styleName={bodyStyleName}>{body}</div> : null}
                    <div styleName='controls'>
                        {this.props.type === 'multiPage' ? (
                            <SlideshowControls
                                slideCount={this.props.pageCount}
                                selectedSlide={this.props.currentPage}
                                onControlClick={this.props.onGotoPage}
                            />
                        ) : (
                            this.props.bottomLeftContent
                        )}
                        <div styleName={this.props.actionButtonOnRight ? 'links--spaced' : 'links'}>
                            {secondaryButton}
                            {primaryButton}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handlePrimaryButtonClick = () => {
        if (this.props.type === 'multiPage') {
            if (this.props.currentPage === this.props.pageCount - 1) {
                // Final page
                this.props.onFinish();
            } else {
                this.props.onNext();
            }
        } else if (this.props.primaryAction && this.props.primaryAction.onClick) {
            this.props.primaryAction.onClick();
        } else {
            this.props.onClose();
        }
    };

    handleSecondaryButtonClick = () => {
        if (this.props.type === 'multiPage' && this.props.currentPage >= 1) {
            this.props.onGotoPage(this.props.currentPage - 1);
        }
    };
}
