import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import CompanyType from './icon-company-type.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <CompanyType {...validProps} />;
}
SvgComponent.iconName = 'company-type';

export const CompanyTypeIcon = svgIconWrapper(SvgComponent);
