/* @flow */

import * as React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SortAscendSvg from './sort-ascend.svg';
import SortDescendSvg from './sort-descend.svg';

type Props = {
    ascending?: boolean,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;
    delete validProps.ascending;

    if (props.ascending === true) {
        return <SortAscendSvg {...validProps} />;
    }

    return <SortDescendSvg {...validProps} />;
}
SvgComponent.iconName = 'sort';

export const SortIcon = svgIconWrapper(SvgComponent);
