import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import HtmlAttachment from './icon-html-attachment.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <HtmlAttachment {...validProps} />;
}
SvgComponent.iconName = 'html-attachment';

export const HtmlAttachmentIcon = svgIconWrapper(SvgComponent);
