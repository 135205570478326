/* @flow */

import moment from 'moment';

/**
 * Helper to take an array of items and bucket them by id.
 * Does not mutate the provided state.
 *
 * @param  {Object[]} items        - Array of items to bucket
 * @param  {Object} prevState      - Our previous "byId" state
 *
 * @return {Object}                - New bucketed object of list items by entity id
 */
export function addItemsToByIdState<I: {id: string}, S: Object>(items: I[], prevState: S): S {
    const newItems: S = items.reduce(
        (acc, item) => {
            if (item && item.id) {
                acc[item.id] = item;
            }

            return acc;
        },
        {...prevState}
    );

    return newItems;
}

/**
 * Used to rehydrate persisted dates based on the time of day the
 * persisted value is checked.
 *
 * In theory, we want to show the same date until the date-barrier changes.
 * The idea here is that users expect "today" to be the first thing that
 * shows up every morning, but if they change the calendar _during_ the
 * day, it should show that updated day.
 *
 * @param  {Object} persistedState    Big 'ole persisted state
 * @param  {dateProp} dateProp        Key to check the date + lastUpdated
 * @return {number}                   Returned date value
 */
export function getRehydratedValueForDateProperty(persistedState: Object, dateProp: string) {
    const lastUpdated = persistedState.lastUpdated;
    const datePropValue = persistedState[dateProp];

    // If we don't have a persistedDate, return today's date
    if (!datePropValue)
        return moment()
            .startOf('day')
            .valueOf();

    // If we don't have a lastUpdated time, just return today
    if (!lastUpdated)
        return moment()
            .startOf('day')
            .valueOf();

    // If we have both, check to see if the lastUpdated's dayOfYear is the same
    // as today. If it's not, we should update it to today
    if (moment(lastUpdated).dayOfYear() !== moment().dayOfYear()) {
        return moment()
            .startOf('day')
            .valueOf();
    }

    return datePropValue;
}

/**
 * A no-op function that's used for Flow type hinting, in that if any non-null
 * option in a switch case statement is passed into it (i.e., an _unhandled_ case
 * in a union type) we'll get a static flow error, e.g.,:
 *
 * ```
 * unexpectedCase(action.type);
 *                ^^^^^^^^^^^ string literal `ENTITIES_MAP_SHOWS_USER_LOCATION`.
 *                This type is incompatible with the expected param type of null
 * ````
 *
 * Note, if we find that we like this pattern, we can extract this function and
 * use it within more reducers.
 *
 * @see http://ouicar.github.io/2016/08/08/exhaustive-switch.html
 *
 * @param {null} impossible - A parameter that shouldn't have anything passed
 *                            into it, so we'll get a static Flow error if it
 *                            receives a value.
 *
 * @return {void}
 */
// eslint-disable-next-line no-unused-vars
export function unexpectedCase(impossible: null) {
    // Intentionally left blank
}
