/* @flow */

import * as React from 'react';

import './distribution-bar.css';

type DefinedSection = {
    type: 'defined',
    color: string,
    percent: number,
    label: string,
    labelColor: string,
};

type AutoCalculatedSection = {
    type: 'remainder',
    color: string,
    label: string,
    labelColor: string,
};

type Props = {
    sections: DefinedSection[],
    remainder: AutoCalculatedSection,
};

export function DistributionBar({sections, remainder}: Props) {
    const totalPercent = sections.reduce((acc, section) => acc + section.percent, 0);
    const remainderPercent = 100 - totalPercent;

    return (
        <div styleName='distribution-bar-container'>
            {sections.map((section) => (
                <div
                    key={section.color}
                    styleName='bar-section'
                    style={{width: `${section.percent + 10}%`, backgroundColor: section.color}}
                >
                    <div
                        className='flex flex-dir-col align-center'
                        style={{color: section.labelColor}}
                    >
                        <span className='font-bold'>{`${section.percent}%`}</span> {section.label}
                    </div>
                </div>
            ))}
            <div
                styleName='bar-section'
                style={{width: `${remainderPercent - 20}%`, backgroundColor: remainder.color}}
            >
                <div
                    className='flex flex-dir-col align-center'
                    style={{color: remainder.labelColor}}
                >
                    <span className='font-bold'>{`${remainderPercent}%`}</span> {remainder.label}
                </div>
            </div>
        </div>
    );
}
