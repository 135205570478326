/* @flow */
import {httpErrorCheck} from '../utils/errors';

import {defaultOptions} from './default-options';

export function put(url: string, body: Object, options?: RequestOptions = {}) {
    // Using the `Object` type here to prevent flow from thinking this is a `Headers` type
    const headers: Object = {
        ...defaultOptions.headers,
        ...options.headers,
        'Content-Type': 'application/json',
    };

    return fetch(url, {
        ...defaultOptions,
        ...options,
        method: 'PUT',
        headers,
        body: JSON.stringify(body),
    }).then(httpErrorCheck);
}
