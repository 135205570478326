/**
 * Gets arrays of weeks, starting on Sunday, for the month of the given day passed.
 *
 * Each array will always have 7 entries, but can include null values if the given
 * day does not fall in the current month. i.e. if the first week's array might start
 * with 3 null values if the first day of the month is a Wednesday.
 *
 * @param  {moment} dateInMonth      Date to get the weeks of the month for
 * @return {Array}                   Array of arrays with the given moment dates, length 4-6
 *                                   depending on the month and year
 */
export function getCalendarWeeks(dateInMonth) {
    const weekArray = [];
    const firstDayOfMonth = dateInMonth.clone().startOf('month');
    const dateMarker = firstDayOfMonth.clone().startOf('week');

    while (
        dateMarker.isBefore(firstDayOfMonth, 'month') ||
        dateMarker.isSame(firstDayOfMonth, 'month')
    ) {
        const dayArray = [];
        for (let i = 0; i < 7; i++) {
            if (dateMarker.isSame(firstDayOfMonth, 'month')) {
                dayArray.push(dateMarker.clone());
            } else {
                dayArray.push(null);
            }
            dateMarker.add(1, 'days');
        }

        weekArray.push(dayArray);
    }

    return weekArray;
}
