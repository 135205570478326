/* @flow */

import * as React from 'react';

import {TruncatedSessionContent, SessionMetadata} from 'shells/timeline/entry/content/session';

import {appHistory} from '../../../history';
import type {EventForTimeline} from '../types';
import {
    getEntryTypeFromPayload,
    getSessionDeviceTypeFromSessionBrowser,
    getActorForSession,
} from '../helpers';

import {TimelineEntry} from './timeline-entry';

type Props = {|event: EventForTimeline|};

export function TimelineEntrySession(props: Props) {
    const {id, actor: sessionOrigin, changeType, payload} = props.event;

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'Session' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (payload.__typename === 'Session') {
        const totalDuration = payload.visits.totalDuration || 0;

        const pages = payload.visits.edges.map((edge) => ({
            id: edge.node.id,
            path: edge.node.url.path,
            fullyQualifiedUrl: edge.node.url.fullyQualifiedUrl,
        }));

        return (
            <TimelineEntry
                id={id}
                iconVariant='session'
                entryType={getEntryTypeFromPayload(payload)}
                changeType={changeType}
                timestamp={payload.startTime}
                actor={getActorForSession(payload, sessionOrigin)}
                entity={payload.url}
                numVisits={payload.visits.totalCount}
                subject={`Via ${payload.source.source}`}
                status={{primary: {level: 'info--grey', text: 'Site visit'}}}
                truncatedContent={
                    <TruncatedSessionContent durationSeconds={totalDuration} pages={pages} />
                }
                metadata={
                    <SessionMetadata
                        locationSummary={payload.locationSummary}
                        deviceType={getSessionDeviceTypeFromSessionBrowser(payload.browser)}
                        platformType={
                            payload.browser && payload.browser.platform
                                ? payload.browser.platform
                                : undefined
                        }
                    />
                }
                disableComments={true}
                disableReactions={true}
                onClickOpen={() => {
                    appHistory.push(`#${payload.htmlUrlPath}`);
                }}
            />
        );
    }

    return null;
}
