/* @flow */

import * as React from 'react';

export function Audience() {
    return (
        <svg
            width='125'
            height='105'
            viewBox='0 0 125 105'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fill='#0C0F33'
                d='M121.4 30.3c-4-5.7-10.2-9.3-17.5-10-.7-.1-1.4-.1-2.1-.1-6.3 0-12.9 2.8-16.9 7.6.8 1.2 1.5 2.4 2.1 3.8 3-4.7 9.1-7.6 14.8-7.6.5 0 1.1 0 1.8.1 5.9.5 11 3.4 14.3 8.2.8 1.3 1.4 4.6 1.4 6.4 0 9.2-10.2 15.1-17.1 15.1-5.4 0-10.3-2.7-13.4-5.7-.1 1.7-.2 3.2-.6 4.7 3.8 2.8 8.9 4.9 14 4.9 8.8 0 21-7.3 21.2-18.9.1-1.8-.5-6.3-2-8.5z'
            />
            <g fill='#0C0F33'>
                <path d='M82.7 66.1c.9.8 2 2.1 3.1 3.9C87 64 91 59 96.4 57.1c1.1-.4 1.7-1.4 1.2-2.4-.4-1-1.5-1.5-2.6-1.1-5.7 2-10.1 6.7-12.3 12.5zM113.3 52.8c-.8-.8-2.2-.6-2.9.1-.8.8-.7 2 .1 2.7 5.8 4.7 8.9 11.9 10 22.5-11 0-18.8.6-24.2 1-2.6.2-4.9.3-6.6.4.3 1.2.6 2.5.8 4 1.7 0 3.8-.2 6.1-.4 5.9-.4 14.2-1 26.1-1 .5 0 1.1-.3 1.5-.6.4-.4.5-.9.5-1.4-.4-8.3-2.2-19.8-11.4-27.3z' />
            </g>
            <path
                fill='#0C0F33'
                d='M104 66.2c-2.1 0-3.2-1.5-4.4-3.1-1.1-1.5-2.5-3.3-5-4.3-1.1-.4-1.5-1.5-1-2.6.4-1 1.7-1.4 2.8-.9 3.6 1.5 5.4 4 6.6 5.6.3.5.8 1 1.1 1.3.7-.4 2.3-2 6.3-7.8.5-.9 1.9-1.1 2.9-.6 1 .5 1.2 1.8.7 2.7-5.2 7.4-7.6 9.6-10 9.7zM121.6 37.1c-3.9-.1-8.5-1.8-10.3-5.9-.3-.6-1.1-1.1-1.9-1.1-8.4 0-15.7-.4-22.1-1.1-.5-.1-1 0-1.3.3.7 1.1 1.3 2.4 1.7 3.7 6.1.7 12.8 1 20.4 1 2.3 4.1 7.4 6.8 13.5 7.2 1.1 0 1.9-.8 2.1-1.9-.1-1.2-1-2.1-2.1-2.2z'
            />
            <path
                fill='#FAC942'
                d='M25.7 54.5c-.7-2-4.4-2.4-5 0-1.5 8.4-.1 17.2-1.2 25.7-.8 6.4 12.1 6.1 10.2-1.3-2.1-8.1-1.5-16.5-4-24.4z'
            />
            <path
                fill='#0C0F33'
                d='M25.3 49.8c-.5 0-.9.1-1.4.1-9.2 0-20.5-7.2-20.5-16.1 0-8 8.5-15.5 18-16 .8-.1 1.7-.1 2.5-.1 5.5 0 10 1.2 13.2 3.4 1-.6 2.1-1.1 3.3-1.6-3.9-3.2-9.5-5-16.5-5-.8 0-1.7.1-2.6.1C9.8 15.2 0 24.1 0 33.8 0 44.6 12.9 53 23.9 53c.9 0 1.8-.1 2.7-.2-.5-.9-.9-2-1.3-3z'
            />
            <path
                fill='#0C0F33'
                d='M30.3 81.6c-5 .4-10 .9-14.9 1.3-3.2.3-6.5.6-9.8.9C4.7 79 4.1 73 4.1 70.2c0-10 3.6-16.3 10.6-19.2 1-.3 1.4-1.3 1-2-.3-.9-1.4-1.3-2.2-.9C5 51.4.5 58.8.5 70.2c0 3.3.7 10.6 2.1 15.8.1.8.8 1.3 1.7 1.3h.1c3.9-.4 7.7-.8 11.3-1.1 4.7-.5 9.5-1 14.3-1.3l.3-3.3z'
            />
            <path
                fill='#0C0F33'
                d='M30.9 59.4c-1.2-1.5-2.3-3.1-3.4-4.8.1 1 .2 1.9.2 2.9 0 .5 0 1.8-3.7 1.8-2.5 0-3.2-1.8-3.7-6.3 0-.5 0-1-.1-1.5-.1-.9-1-1.5-1.9-1.4-1 .1-1.7.9-1.5 1.8 0 .4.1.9.1 1.4.4 3.4 1.1 9.2 7.2 9.2 4.4 0 6.2-1.6 6.8-3.1z'
            />
            <path
                fill='#0C0F33'
                d='M20.9 58.7c-1-.3-1.9.1-2.2 1-2.3 6.5-3.3 19-3.3 20.3 0 1.1.1 2.4.6 3.8.1.8.8 1.3 1.7 1.3.1 0 .3 0 .4-.1 1-.1 1.5-1 1.2-1.9-.3-1.1-.4-2-.4-3.1 0-1.3 1-13.3 3.2-19.3.2-.9-.2-1.8-1.2-2zM32.9 71.6c-.1-.2-.1-.4-.2-.6-1.1-3.3-2.1-6.6-3.2-10.1-.3-.9-1.2-1.4-2.2-1.1-1 .3-1.5 1.1-1.2 2 1.1 3.4 2.1 6.8 3.2 10.1.6 1.8 1.2 3.7 1.8 5.6.5-2.4 1.2-4.3 1.8-5.9zM29.6 28.1c.8-1.1 1.7-2.2 2.7-3.3-1.8.1-3.9.1-6.2.1h-6.8c-5.6 0-6.9 1-7.7 4.5-.4 2.2-1.4 5.7-7 11.6-.7.6-.6 1.7.1 2.3.3.3.7.4 1.1.4.6 0 1-.1 1.4-.4 6.2-6.5 7.3-10.7 7.8-13.2.5-2 .5-2 4.3-2h10.3z'
            />
            <path
                fill='#7373C7'
                d='M34.8 31.8c-.3.8-.6 1.5-.1 2.2.6 1 2.2 1 3.4.9 6.9-.6 13.8-1.1 20.5-1.4 3.4-.1 7.3-.1 9.9 1.9 1.1.9 1.8 2 2.6 3.2 2.5 3.6 5.8 6.6 9.6 8.9.6.4 1.2.6 1.7.4.3-.3.4-.6.6-1 1.4-5.6-.3-11.6-3.9-16.2-3.7-4.6-9.2-7.7-15.3-8.9-.8-.2-1.8-.3-2.5-.8s-1-1.5-.4-2.2c.4-.4 1-.5 1.4-.6 3.4-1 5.4-5.1 3.9-8.2-1.5-3.1-5.9-4.5-9.1-2.8s-4.3 5.9-2.2 8.7c.8 1.1 2.1 2.3 1.4 3.4-.3.5-.8.8-1.4.9-7.2 3.3-16.8 3.9-20.1 11.6z'
            />
            <path
                fill='#0C0F33'
                d='M60.4 21.1c-5.5 0-8.8-3.3-8.8-9.1 0-4 2.1-6.5 5.8-7 .6-.1 1.1-.1 1.7-.1 5.4 0 9.2 3.7 9.2 8.7 0 4.2-3.4 7.5-7.9 7.5zM58 8.7c-.7.1-2.3.3-2.3 3.2 0 4.7 2.3 5.4 4.7 5.4 2.3 0 3.9-1.5 3.9-3.7 0-2.4-1.7-4.9-5.1-4.9-.3-.1-.8-.1-1.2 0z'
            />
            <path
                fill='#0C0F33'
                d='M58.3 68.6c-17.6 0-28.2-12.6-28.2-24.8 0-12.8 10.6-23.6 23.7-24.4H56c16.9 0 29.6 10.1 29.6 23.4.1 15.9-14.1 25.8-27.3 25.8zM54 23.2c-10.8.6-19.8 9.8-19.8 20.5 0 10.3 8.9 20.9 24.1 20.9 13.9 0 23.2-11.4 23.2-22.1C81.5 32.9 72.7 23 56 23c-.6.2-1.3.2-2 .2z'
            />
            <path
                fill='#0C0F33'
                d='M37.8 100.7c-1 0-1.8-.6-2.1-1.5-.8-3.3-1.1-6.5-1.1-10.7 0-12.5 4.3-20.7 12.5-24.4 1-.4 2.2 0 2.8.9.4.9 0 2-1 2.6-6.7 2.9-10.2 10-10.2 20.9 0 3.2.3 5.7.7 8.3 8.8-.1 17.7-.6 26.4-1 6.1-.3 12.4-.6 18.6-.8-.1-8.6-2.2-23-14.4-29.3-1-.5-1.4-1.7-.8-2.6.5-.9 1.8-1.3 2.8-.8 14.4 7.3 16.5 24 16.5 33.3v1.1c0 1-.8 1.8-1.9 1.9-7 .4-13.9.7-20.6 1.1-9.2.4-18.7.9-28.2 1z'
            />
            <path
                fill='#0C0F33'
                d='M59.3 77.3c-4 0-7.2-3.2-9.6-9.3-.3-.9 0-1.8.8-2.3s1.9-.4 2.5.3l.3.3c.4.4.7.9.6 1.4 1.7 3.7 3.6 5.7 5.4 5.7 1.4 0 1.8-3.2 2.2-5.2.1-.6.1-1.3.3-1.8.1-1 1.2-1.7 2.3-1.5 1.1.1 1.8 1.1 1.7 2.2-.1.5-.1 1-.3 1.7-.5 3.1-1.3 8.5-6.2 8.5zM82.4 51.3H82c-1.7-.3-4.5-2.8-13.9-12-1.2-1.3-2.6-2.6-3.3-3.2-5.4 0-10.7.5-15.8 1-4.5.4-9.4.9-14 1-1.1.1-2.1-.8-2.1-1.8s.8-1.9 1.9-1.9c4.5-.1 9.2-.5 13.8-1 5.5-.5 11.3-1 17.1-1 1 0 1 0 5.5 4.5C74.5 40.2 81.4 47 83 47.8c1 .3 1.7 1.3 1.4 2.2-.1.6-1.1 1.3-2 1.3z'
            />
        </svg>
    );
}
