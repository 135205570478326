/* @flow */

import {useMutation} from '@apollo/react-hooks';

import type {
    EmailGlobalBlocklistAdd as EmailGlobalBlocklistAddMutation,
    EmailGlobalBlocklistAddVariables as EmailGlobalBlocklistAddMutationVariables,
    EmailGlobalBlocklistAddInput,
} from 'nutshell-graphql-types';

import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import BLOCKLIST_ADD from './mutations/email-global-blocklist-add.graphql';

export function useUpdateEmailBlocklist(): {
    onBlocklistEmail: (EmailGlobalBlocklistAddInput) => Promise<*>,
    isLoading: boolean,
    error: ?string,
} {
    const [emailGlobalBlocklistAdd, {loading: isLoading, error}] = useMutation<
        EmailGlobalBlocklistAddMutation,
        EmailGlobalBlocklistAddMutationVariables
    >(BLOCKLIST_ADD);

    return {
        onBlocklistEmail: (input: EmailGlobalBlocklistAddInput) =>
            emailGlobalBlocklistAdd({
                variables: {input},
            }),
        isLoading,
        error: safelyGetGraphQLErrorMessage(error),
    };
}
