/* @flow */

// import type {RelatedEmailAddresses} from 'nutshell-graphql-types';

// Recreated from the above type, which is not available to nutshell-core
type RelatedEmailAddresses = {
    primary: ?EmailAddressEav,
};

type EmailAddressEav = {
    __typename: 'EmailAddressEav',
    isPrimary: boolean,
    value: {
        __typename: 'EmailAddressValue',
        address: string,
        display: ?string,
        formatted: string,
    },
};

export function getPrimaryEmailAddress(emailAddresses: RelatedEmailAddresses): ?string {
    if (emailAddresses.primary) {
        const emailAddressObject = emailAddresses.primary.value;

        return emailAddressObject ? emailAddressObject.address : null;
    }

    return null;
}
