/* @flow */

import {useMutation} from '@apollo/react-hooks';

import type {
    DeleteTag as DeleteTagMutation,
    DeleteTagVariables as DeleteTagMutationVariables,
    DeleteTagInput,
} from 'nutshell-graphql-types';

import {bustManagedCollectionCache} from '../../../../util/bust-managed-collection-cache';

import GET_TAGS from '../queries/tags.graphql';
import DELETE_TAG from '../mutations/delete-tag.graphql';

import {GET_TAGS_LIMIT} from './use-get-tags';

export function useDeleteTag(): {
    deleteTag: (DeleteTagInput) => Promise<*>,
    isDeleting: boolean,
} {
    const [deleteTag, {loading}] = useMutation<DeleteTagMutation, DeleteTagMutationVariables>(
        DELETE_TAG
    );

    return {
        deleteTag: (input: DeleteTagInput) =>
            deleteTag({
                variables: {input},
                update: (proxy, mutationResults) => {
                    const deletedTag =
                        mutationResults &&
                        mutationResults.data &&
                        mutationResults.data.deleteTag &&
                        mutationResults.data.deleteTag.tag;

                    const entityType = deletedTag ? deletedTag.tagType : undefined;

                    const cacheResult = proxy.readQuery({
                        query: GET_TAGS,
                        variables: {
                            first: GET_TAGS_LIMIT,
                            input: {
                                entityType,
                            },
                        },
                    });

                    if (cacheResult && deletedTag) {
                        proxy.writeQuery({
                            query: GET_TAGS,
                            variables: {
                                first: GET_TAGS_LIMIT,
                                input: {
                                    entityType,
                                },
                            },
                            data: {
                                tags: {
                                    ...cacheResult.tags,
                                    edges: cacheResult.tags.edges.filter(
                                        (edge) => edge.node.id !== deletedTag.id
                                    ),
                                },
                            },
                        });
                    }
                },
            }).then(() => {
                bustManagedCollectionCache('tags');
            }),
        isDeleting: loading,
    };
}
