/* @flow */

import * as React from 'react';
import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';

type Props = {
    fill?: string,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} fill={props.fill || colors.person} viewBox={'-232 385.9 70 70'}>
            <path d='M-164.9 448c0-3.3-2.9-5.3-11.5-7.5-4.5-1.1-8.2-3.7-8.2-3.7-.6-.3-.1-3.2-.1-3.2l-3.2-.5c0-.2-.2-3.7-.2-3.7 3.3-1.1 3-7.6 3-7.6 2.1 1.2 3.5-4 3.5-4 2.5-7.2-1.2-6.7-1.2-6.7 1.2-5.4 0-13.2 0-13.2-1.7-14.5-27.2-10-23.4-5.4-9.1-2.5-5.9 14.7-5.9 14.7l1.6 4.3c-3.1 2-.9 4.4-.8 7.2.2 4.1 2.7 3.3 2.7 3.3.2 6.8 3.5 7.7 3.5 7.7.6 4.3 0 3.9 0 3.9l-3.9.3s.3 2.6-.3 3c0 0-3.8 2.6-8.2 3.7-8.6 2.2-11.5 4.2-11.5 7.5 0 0-.9.4-.9 5.8h66c-.1-5.4-1-5.9-1-5.9z' />
        </svg>
    );
}
SvgComponent.iconName = 'contact';

export const ContactIcon = svgIconWrapper(SvgComponent);
