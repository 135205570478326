/* @flow */

import type {GetLimits_limits as Limits} from 'nutshell-graphql-types';

export type MonthlyUsage = {
    limit: ?number,
    remaining: number,
    currentUsage: number,
    allTimeUsage: ?number,
};

export const getUsageFromLimits = (limits: ?Limits): ?MonthlyUsage => {
    if (!limits) {
        return null;
    }

    const monthlyUsage = {
        limit: limits.currentPlanLimit ? limits.currentPlanLimit : null,
        // dont show negative numbers
        remaining: Math.max(
            limits.currentPlanLimit ? limits.currentPlanLimit - limits.currentUsage : Infinity,
            0
        ),
        currentUsage: limits.currentUsage,
        allTimeUsage: limits.allTimeUsage,
    };

    return monthlyUsage;
};
