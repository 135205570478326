import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SidebarIconSetup from './sidebar-icon-setup.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SidebarIconSetup {...validProps} />;
}
SvgComponent.iconName = 'sidebarIconSetup';

export const SidebarIconSetupIcon = svgIconWrapper(SvgComponent);
