/* @flow */

import type {Coupon} from './billing-types';

export const ActionTypes = {
    BILLING_CHECK_COUPON_REQUEST: 'BILLING_CHECK_COUPON_REQUEST',
    BILLING_CHECK_COUPON_SUCCESS: 'BILLING_CHECK_COUPON_SUCCESS',
    BILLING_CHECK_COUPON_FAILURE: 'BILLING_CHECK_COUPON_FAILURE',
    BILLING_CLEAR_COUPON: 'BILLING_CLEAR_COUPON',
};

export type CheckCouponRequestAction = {type: 'BILLING_CHECK_COUPON_REQUEST', payload: string};
export type CheckCouponSuccessAction = {type: 'BILLING_CHECK_COUPON_SUCCESS', payload: ?Coupon};
export type CheckCouponFailureAction = {type: 'BILLING_CHECK_COUPON_FAILURE', error: true};
export type ClearCouponAction = {type: 'BILLING_CLEAR_COUPON'};

export type BillingAction =
    | CheckCouponRequestAction
    | CheckCouponSuccessAction
    | CheckCouponFailureAction
    | ClearCouponAction;

export const checkCoupon = (couponCode: string): CheckCouponRequestAction => ({
    type: 'BILLING_CHECK_COUPON_REQUEST',
    payload: couponCode,
});
export const resolveCheckCoupon = (couponDetails: ?Coupon): CheckCouponSuccessAction => ({
    type: 'BILLING_CHECK_COUPON_SUCCESS',
    payload: couponDetails,
});
export const failCheckCoupon = (): CheckCouponFailureAction => ({
    type: 'BILLING_CHECK_COUPON_FAILURE',
    error: true,
});
export const clearCoupon = (): ClearCouponAction => ({type: 'BILLING_CLEAR_COUPON'});
