/* @flow */

import {unexpectedCase} from 'nutshell-core/reducer-utils';
import * as ramda from 'ramda';

import {ActionTypes, type CreatorAction} from './creator-actions';
import type {FlashMessage} from './types';

export type CreatorState = {
    flashMessages: {[messageId: string]: FlashMessage},
};

const defaultCreatorState = {
    flashMessages: {},
};

export const reducer = (
    state: CreatorState = defaultCreatorState,
    action: CreatorAction
): CreatorState => {
    switch (action.type) {
        case ActionTypes.GLOBAL_CREATE_NEW_FLASH_BANNER_PUSHED: {
            return {
                ...state,
                flashMessages: {
                    ...state.flashMessages,
                    [action.payload.id]: action.payload.flashMessage,
                },
            };
        }
        case ActionTypes.GLOBAL_CREATE_NEW_FLASH_BANNER_REMOVED: {
            return {
                ...state,
                flashMessages: ramda.omit([action.payload], state.flashMessages),
            };
        }
        // Prevent any rehydration
        // $FlowFixMe upgrading Flow to v0.92.1 on web
        case 'persist/REHYDRATE': {
            return state;
        }
        default:
            unexpectedCase(action.type);

            return state;
    }
};
