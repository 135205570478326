/* @flow */

import * as React from 'react';
import type {FieldArrayProps} from 'redux-form';

import {useGetStagesets} from '../../select/hooks/use-get-stagesets';
import {TriggerLeadFields} from './trigger-lead-fields';

type PipelineFieldsProps = {
    fields: $PropertyType<FieldArrayProps, 'fields'>,
    meta: $PropertyType<FieldArrayProps, 'meta'>,
    formType: 'stageEntered' | 'leadClosed',
};

export function PipelineFields(props: PipelineFieldsProps): React.Node {
    const {stagesets} = useGetStagesets();

    return stagesets.length ? (
        <TriggerLeadFields {...props} formType={props.formType} optionFields={stagesets} />
    ) : null;
}
