/* @flow */

import * as React from 'react';

import {Button} from '../../../button';

import './show-hide-button.css';

type Props = {|
    icon: React.Node,
    buttonText: string,
    onClick: () => void,
    getButtonRef?: () => any,
|};

export function ShowHideButton(props: Props) {
    const {icon, buttonText, onClick, getButtonRef} = props;

    return (
        <Button variant='text-secondary' size='small' onClick={onClick} getButtonRef={getButtonRef}>
            <div styleName='button-content'>
                <div styleName='icon'>{icon}</div>
                {buttonText}
            </div>
        </Button>
    );
}
