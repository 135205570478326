import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Checkmark from './icon-checkmark.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Checkmark {...validProps} />;
}
SvgComponent.iconName = 'checkmark';

export const CheckmarkIcon = svgIconWrapper(SvgComponent);
