/* @flow */

import * as React from 'react';

import {Stack} from 'shells/layout';
import {Button} from 'shells/button';
import {colors} from 'shells/colors';
import {LoadingIcon} from 'shells/icon';

import {SummaryInfoButton} from '../summary-info-button';

import {useGetEntitySummary} from '../../power-ai/timeline-summary/graphql/hooks/use-get-entity-summary';
import {useGenerateEntitySummary} from '../timeline-summary/graphql/hooks/use-generate-entity-summary';
import {SummaryButtons} from './summary-buttons';

import './transcript-summary.css';

type Props = {
    zoomMeetingId: string,
    onAdd: () => Promise<*>,
    onReplace: ?() => Promise<*>,
    onCancel: () => void,
};

export function TranscriptSummary(props: Props) {
    const {entitySummary, isRefetching, startPolling, stopPolling} = useGetEntitySummary(
        props.zoomMeetingId
    );
    const {generateEntitySummary, isLoading: isGeneratingSummary} = useGenerateEntitySummary();

    const handleGenerateSummary = () => {
        generateEntitySummary(
            {entityId: props.zoomMeetingId},
            entitySummary ? entitySummary.id : undefined
        );
    };

    React.useEffect(() => {
        // Start polling if there is no entitySummary yet or the entitySummary status is in progress
        if (isGeneratingSummary || (entitySummary && entitySummary.status === 'IN_PROGRESS')) {
            startPolling(5000); // 5 seconds
        } else if (entitySummary && entitySummary.status !== 'IN_PROGRESS') {
            stopPolling();
        }

        // Cleanup on unmount
        return () => stopPolling();
    }, [isGeneratingSummary, entitySummary, startPolling, stopPolling]);

    const isLoadingState =
        // refetching after initial generation
        (!entitySummary && isRefetching) ||
        // regenerating a new summary
        (isGeneratingSummary && entitySummary);

    const isInProgress =
        // initial generation
        isGeneratingSummary ||
        // summary generation is in progress
        (entitySummary && entitySummary.status === 'IN_PROGRESS');

    let transcriptSummaryContent;
    let showButtons = false;
    if (isLoadingState) {
        transcriptSummaryContent = (
            <div className='flex justify-center'>
                <LoadingIcon size={48} fill={colors.orangeLt} />
            </div>
        );
    } else if (isInProgress) {
        transcriptSummaryContent = <div>AI is generating a new summary of this transcript…</div>;
    } else if (entitySummary) {
        switch (entitySummary.status) {
            case 'ERRORED':
                transcriptSummaryContent = (
                    <Stack spacing={4} isHorizontal={true}>
                        <div>There was a problem generating your AI summary.</div>
                        <Button
                            noPadding={true}
                            variant='text-primary'
                            onClick={handleGenerateSummary}
                        >
                            Try again
                        </Button>
                    </Stack>
                );
                break;
            case 'COMPLETE':
                transcriptSummaryContent = <div>{entitySummary.summary}</div>;
                showButtons = true;
                break;
        }
    }

    if (transcriptSummaryContent) {
        return (
            <div styleName='container'>
                {transcriptSummaryContent}
                {showButtons ? (
                    <div styleName='footer'>
                        <SummaryInfoButton variant='zoom' />
                        <SummaryButtons
                            onAdd={props.onAdd}
                            onReplace={props.onReplace}
                            onCancel={props.onCancel}
                        />
                    </div>
                ) : (
                    undefined
                )}
            </div>
        );
    }

    return <></>;
}
