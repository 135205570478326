/* @flow */

export const DEFAULT_BUCKET_NAME = 'base';
export const CUSTOM_BUCKET_NAME = 'Custom fields';
export const FILTER_MODE_BUCKET_NAME = 'Filter modes';

const TODAY = {id: 'd', value: 'Today'};
const YESTERDAY = {id: 'd-1', value: 'Yesterday'};
const LAST_7_DAYS = {id: '-d7', value: 'Last 7 days'};
const LAST_30_DAYS = {id: '-d30', value: 'Last 30 days'};
const LAST_90_DAYS = {id: '-d90', value: 'Last 90 days'};

// We use this as the default in some places when there's an invalid filter
export const LAST_365_DAYS = {id: '-d365', value: 'Last 365 days'};

const NEXT_7_DAYS = {id: '+d7', value: 'Next 7 days'};
const NEXT_30_DAYS = {id: '+d30', value: 'Next 30 days'};
const NEXT_90_DAYS = {id: '+d90', value: 'Next 90 days'};
const NEXT_365_DAYS = {id: '+d365', value: 'Next 365 days'};

const LAST_WEEK = {id: 'w-1', value: 'Last week'};
const LAST_MONTH = {id: 'm-1', value: 'Last month'};
const LAST_QUARTER = {id: 'q-1', value: 'Last quarter'};
const LAST_YEAR = {id: 'y-1', value: 'Last year'};
const LAST_FISCAL_YEAR = {id: 'fy-1', value: 'Last fiscal year'};

const NEXT_WEEK = {id: 'w+1', value: 'Next week'};
const NEXT_MONTH = {id: 'm+1', value: 'Next month'};
const NEXT_QUARTER = {id: 'q+1', value: 'Next quarter'};
const NEXT_YEAR = {id: 'y+1', value: 'Next year'};
const NEXT_FISCAL_YEAR = {id: 'fy+1', value: 'Next fiscal year'};

const THIS_WEEK = {id: 'w', value: 'This week'};
const THIS_MONTH = {id: 'm', value: 'This month'};
const THIS_QUARTER = {id: 'q', value: 'This quarter'};
const THIS_YEAR = {id: 'y', value: 'This year'};
const THIS_FISCAL_YEAR = {id: 'fy', value: 'This fiscal year'};

const FIRST_HOUR = {id: '+h1', value: 'First hour'};
const FIRST_4_HOURS = {id: '+h4', value: 'First 4 hours'};
const FIRST_12_HOURS = {id: '+h12', value: 'First 12 hours'};
const FIRST_24_HOURS = {id: '+d1', value: 'First 24 hours'};
const FIRST_48_HOURS = {id: '+d2', value: 'First 48 hours'};
const FIRST_7_DAYS = {id: '+d7', value: 'First 7 days'};

const CUSTOM = {id: 'custom', value: 'Custom'};

export const DATE_RANGES_FUTURE = [
    NEXT_7_DAYS,
    NEXT_30_DAYS,
    NEXT_90_DAYS,
    NEXT_365_DAYS,
    THIS_WEEK,
    THIS_MONTH,
    THIS_QUARTER,
    THIS_YEAR,
    THIS_FISCAL_YEAR,
    NEXT_WEEK,
    NEXT_MONTH,
    NEXT_QUARTER,
    NEXT_YEAR,
    NEXT_FISCAL_YEAR,
    CUSTOM,
];

export const DATE_RANGES_PAST = [
    TODAY,
    YESTERDAY,
    LAST_7_DAYS,
    LAST_30_DAYS,
    LAST_90_DAYS,
    LAST_365_DAYS,
    THIS_WEEK,
    THIS_MONTH,
    THIS_QUARTER,
    THIS_YEAR,
    THIS_FISCAL_YEAR,
    LAST_WEEK,
    LAST_MONTH,
    LAST_QUARTER,
    LAST_YEAR,
    LAST_FISCAL_YEAR,
    CUSTOM,
];

export const DATE_RANGES_MINUTES = [
    FIRST_HOUR,
    FIRST_4_HOURS,
    FIRST_12_HOURS,
    FIRST_24_HOURS,
    FIRST_48_HOURS,
    FIRST_7_DAYS,
];

export const DATE_RANGES_FORMS = [
    TODAY,
    LAST_7_DAYS,
    LAST_30_DAYS,
    LAST_90_DAYS,
    LAST_365_DAYS,
    THIS_WEEK,
    THIS_MONTH,
    THIS_QUARTER,
    THIS_YEAR,
    THIS_FISCAL_YEAR,
    LAST_WEEK,
    LAST_MONTH,
    LAST_QUARTER,
];

export const DATE_RANGES_ANALYTICS = [
    THIS_MONTH,
    LAST_MONTH,
    THIS_QUARTER,
    LAST_7_DAYS,
    LAST_30_DAYS,
    LAST_90_DAYS,
];

export const DATE_RANGES_DASHBOARD = [LAST_7_DAYS, LAST_30_DAYS, LAST_90_DAYS];

export const DATE_RANGES_ATTRIBUTION = [
    LAST_7_DAYS,
    LAST_30_DAYS,
    LAST_90_DAYS,
    LAST_365_DAYS,
    THIS_WEEK,
    THIS_MONTH,
    THIS_QUARTER,
    THIS_YEAR,
    THIS_FISCAL_YEAR,
    LAST_WEEK,
    LAST_MONTH,
    LAST_QUARTER,
    LAST_YEAR,
    LAST_FISCAL_YEAR,
];
