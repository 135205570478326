import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Audience from './icon-audience.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Audience {...validProps} />;
}
SvgComponent.iconName = 'audience';

export const AudienceIcon = svgIconWrapper(SvgComponent);
