/* @flow */

import {createSelector} from 'reselect';
import * as ramda from 'ramda';
import type {OutcomeStatus, Outcome} from '../types';

import type {NutshellSharedState} from '../store';
import type {OutcomesState} from './index';

export const getOutcomesState = (state: NutshellSharedState): OutcomesState => state.outcomes;

// $FlowFixMe upgrading Flow to v0.92.1
export const getIsLoading = createSelector(
    [getOutcomesState],
    (outcomesState: OutcomesState): boolean => {
        return outcomesState.isLoading;
    }
);

// $FlowFixMe upgrading Flow to v0.92.1
export const getErrorMessage = createSelector(
    [getOutcomesState],
    (outcomesState: OutcomesState): ?string => {
        return outcomesState.errorMessage;
    }
);

// $FlowFixMe upgrading Flow to v0.92.1
export const getAllOutcomes = createSelector(
    [getOutcomesState],
    (outcomesState: OutcomesState): Object => {
        return outcomesState.byId;
    }
);

/**
 * Memoized selector that returns an array of outcomes that pertain to the
 * passed status id.
 * @param  {string} status                - Status to get related outcomes by
 * @return {Outcome[]}                    - Array of Outcomes related to the
 *                                          given status
 */
export const getOutcomesForStatus = ramda.memoizeWith<OutcomeStatus, Function, OutcomeStatus>(
    ramda.identity,
    (status: OutcomeStatus) => {
        return createSelector([getAllOutcomes], (outcomes): Outcome[] =>
            Object.keys(outcomes)
                .map((outcomeId) => outcomes[outcomeId])
                .filter((outcome) => outcome && outcome.status === status)
        );
    }
);
