/* @flow */

import * as React from 'react';
import {CheckIcon} from '../../icon';
import styles from './checkmark-button.css';

type Props = {
    className?: string,
    isDisabled?: boolean,
    onClick: () => void,
};

export class CheckmarkButton extends React.PureComponent<Props> {
    render() {
        const className = this.props.className
            ? `${this.props.className} ${styles.container}`
            : styles.container;

        return (
            <button
                type='button'
                disabled={this.props.isDisabled}
                onClick={this.props.onClick}
                className={className}
            >
                <CheckIcon />
            </button>
        );
    }
}
