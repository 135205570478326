import moment from 'moment';

/**
 * Check if a date is after tomorrow
 *
 * @param  {[type]}  targetDate        Moment date object to check
 * @param  {[type]}  [today=moment()]  Moment date object for today's datetime
 * @return {Boolean}                   Is the target date after tomorrow?
 */
export function isAfterTomorrow(targetDate, today = moment()) {
    return targetDate.isAfter(today.clone().add(1, 'day'), 'day');
}
