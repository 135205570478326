/* @flow */

import * as React from 'react';

import type {EventFragmentSparse} from 'nutshell-graphql-types';

import {LinkedEntity} from 'shells/linked-entity';
import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {|event: EventFragmentSparse|};

export function TimelineBannerAccountRelationship(props: Props) {
    const {event} = props;

    const payload = event.payload;

    // dont show removed actions?
    if (
        payload.__typename !== 'AccountRelationshipChange' ||
        (payload.action !== 'PARENT_ADDED' && payload.action !== 'CHILD_ADDED')
    ) {
        return null;
    }

    const parentAdded = payload.action === 'PARENT_ADDED';
    const entity = payload.entityAdded;

    const action = (
        <span>
            <span>
                {parentAdded ? 'designated a parent company: ' : 'added a child company: '}
                <LinkedEntity
                    type='accounts'
                    useEntityColor={true}
                    name={entity.name}
                    htmlUrl={entity.htmlUrl}
                    allowWrap={true}
                    displayInline={true}
                    greenLeads={true}
                />
            </span>
        </span>
    );

    return (
        <TimelineBanner
            id={event.id}
            iconVariant={parentAdded ? 'account-parent-added' : 'account-child-added'}
            timestamp={event.changeTime}
            actor={event.actor}
            action={action}
        />
    );
}
