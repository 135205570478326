/* @flow */
import Mousetrap from 'mousetrap';

Mousetrap.bind('c c', () => {
    const event = new CustomEvent('client-navigate', {
        detail: {
            type: 'replace',
            to: {
                hash: '/create/accounts',
                search: location.search,
                state: {
                    modal: true,
                },
            },
        },
    });
    document.dispatchEvent(event);

    return false;
});

Mousetrap.bind('c p', () => {
    const event = new CustomEvent('client-navigate', {
        detail: {
            type: 'replace',
            to: {
                hash: '/create/contacts',
                search: location.search,
                state: {
                    modal: true,
                },
            },
        },
    });
    document.dispatchEvent(event);

    return false;
});

Mousetrap.bind('c l', () => {
    const event = new CustomEvent('client-navigate', {
        detail: {
            type: 'replace',
            to: {
                hash: '/create/leads',
                search: location.search,
                state: {
                    modal: true,
                },
            },
        },
    });
    document.dispatchEvent(event);

    return false;
});

Mousetrap.bind('c t', () => {
    const event = new CustomEvent('client-navigate', {
        detail: {
            type: 'replace',
            to: {
                hash: '/create/tasks',
                search: location.search,
                state: {
                    modal: true,
                },
            },
        },
    });
    document.dispatchEvent(event);

    return false;
});

Mousetrap.bind('c a', () => {
    const event = new CustomEvent('client-navigate', {
        detail: {
            type: 'replace',
            to: {
                hash: '/create/activities',
                search: location.search,
                state: {
                    modal: true,
                },
            },
        },
    });
    document.dispatchEvent(event);

    return false;
});
