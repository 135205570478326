/* @flow */

import * as React from 'react';
import {colors} from '../../colors';
import {svgIconWrapper} from '../svg-icon-wrapper';

type Props = {
    fill?: string,
};

function SvgComponent(props: Props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} fill={props.fill || colors.grey} viewBox='0 0 22 22'>
            <path d='M8.952 7.232v9.306H6.439V7.232H3.315V5h8.76v2.232H8.953ZM16.704 16.538c-.849 0-1.493-.215-1.934-.645-.43-.43-.645-1.058-.645-1.884V9.76h-1.223V7.86h.612c.33 0 .556-.078.678-.232.12-.165.181-.396.181-.694V5.512h2.199V7.86h1.719v1.9h-1.72v4.877h1.588v1.9h-1.455Z' />
        </svg>
    );
}
SvgComponent.iconName = 'textBlock';

export const TextBlockIcon = svgIconWrapper(SvgComponent);
