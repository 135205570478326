import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import MicrosoftTeams from './icon-microsoft-teams.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <MicrosoftTeams {...validProps} />;
}
SvgComponent.iconName = 'microsoftTeams';

export const MicrosoftTeamsIcon = svgIconWrapper(SvgComponent);
