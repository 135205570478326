/* @flow */
import {createAction} from 'redux-act';

import type {FilterObject} from 'nutshell-core/types';

export type AudienceMemberListRequestedAction = {
    type: 'AUDIENCE_MEMBER_LIST_DATA_REQUESTED',
    payload: {
        filters: FilterObject[],
        pageNum: number,
        columns: string[],
        sort: string,
        q: string,
    },
};

export type AudienceMembershipReportDataRequestedAction = {
    type: 'AUDIENCE_MEMBERSHIP_REPORT_DATA_REQUESTED',
    payload: {
        reportParams: Object,
        filters: FilterObject[],
    },
};

export const toggleShowFilterDrawer = createAction('AUDIENCE_MEMBER_LIST_FILTER_DRAWER_TOGGLED');

// Audience people list
export const requestAudienceMemberListData: (
    payload: $PropertyType<AudienceMemberListRequestedAction, 'payload'>
) => AudienceMemberListRequestedAction = createAction('AUDIENCE_MEMBER_LIST_DATA_REQUESTED');
export const updateAudienceMemberListData = createAction('AUDIENCE_MEMBER_LIST_DATA_UPDATED');
export const failAudienceMemberListData = createAction('AUDIENCE_MEMBER_LIST_DATA_FAILED');

// Audience membership report
export const requestAudienceMembershipReportData: (
    payload: $PropertyType<AudienceMembershipReportDataRequestedAction, 'payload'>
) => AudienceMembershipReportDataRequestedAction = createAction(
    'AUDIENCE_MEMBERSHIP_REPORT_DATA_REQUESTED'
);
export const updateAudienceMembershipReportTableData = createAction(
    'AUDIENCE_MEMBERSHIP_REPORT_TABLE_DATA_UPDATED'
);
export const updateAudienceMembershipReportChartData = createAction(
    'AUDIENCE_MEMBERSHIP_REPORT_CHART_DATA_UPDATED'
);
export const updateAudienceMembershipReportMaps = createAction(
    'AUDIENCE_MEMBERSHIP_REPORT_MAPS_UPDATED'
);
export const failAudienceMembershipReportChartData = createAction(
    'AUDIENCE_MEMBERSHIP_REPORT_CHART_DATA_FAILED'
);
