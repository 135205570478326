/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {Session} from 'nutshell-core/session';

import {FormattedNumberField} from 'shells/formatted-number-field';
import {FormField} from '../form';

import './number.css';

type Props = {
    ...$Exact<FieldProps>,
    title?: string,
    placeholder?: string,
    helperText?: string,
    isRequired?: boolean,
    formatType?: 'currency' | 'number' | 'float' | 'percent',
};

export function ReduxFormNumberField(props: Props) {
    const formatValue = (newValue: ?number) => {
        if (!newValue) return null;
        if (props.formatType === 'currency') {
            return newValue.toLocaleString(undefined, {
                style: 'currency',
                currency: Session.getInstanceDefaultCurrencyCode(),
            });
        } else {
            return newValue;
        }
    };

    return (
        <FormField
            innerId={props.input.name}
            label={props.title}
            helperText={props.helperText}
            errorMessage={props.meta.error && props.meta.submitFailed ? props.meta.error : null}
            isRequired={props.isRequired}
        >
            <div styleName='container'>
                <FormattedNumberField
                    {...props.input}
                    formatType={props.formatType || 'number'}
                    onChange={(value: ?number) => {
                        props.input.onChange(formatValue(value));
                    }}
                    placeholder='0'
                    clearable={true}
                    min={props.formatType === 'percent' ? 0 : undefined}
                    max={props.formatType === 'percent' ? 100 : undefined}
                />
                {props.formatType === 'percent' ? <div styleName='percent'>%</div> : null}
            </div>
        </FormField>
    );
}
