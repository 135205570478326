/* @flow */

import {useQuery} from '@apollo/react-hooks';

import type {
    GetAccountsSearchResults as GetAccountsSearchResultsQuery,
    GetAccountsSearchResultsVariables as GetAccountsSearchResultsQueryVariables,
} from 'nutshell-graphql-types';

import {makeDataSafe} from 'nutshell-core/utils/graphql-errors';

import GET_ACCOUNTS_SEARCH_RESULTS from '../queries/get-accounts-search-results.graphql';

export const useGetAccounts = (
    query: ?string
): ({
    isLoading: boolean,
    accounts: Object[],
    refetch: () => Promise<*>,
    fetchMore: (searchTerm: ?string) => Promise<*>,
}) => {
    const {data, loading, refetch, fetchMore} = useQuery<
        GetAccountsSearchResultsQuery,
        GetAccountsSearchResultsQueryVariables
    >(GET_ACCOUNTS_SEARCH_RESULTS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            query,
            limit: 20,
        },
        notifyOnNetworkStatusChange: true,
    });

    const {search} = makeDataSafe(data);
    const quickResults = search ? search.quickResults : null;

    const accountResults = quickResults ? quickResults.accountResults : [];

    return {
        accounts: accountResults,
        isLoading: loading,
        refetch,
        fetchMore: (searchTerm: ?string) => {
            // $FlowIgnore
            return fetchMore({
                query: GET_ACCOUNTS_SEARCH_RESULTS,
                variables: {
                    query: searchTerm,
                    limit: 20,
                },
                updateQuery: (previousResult, {fetchMoreResult}) => {
                    if (!fetchMoreResult || !previousResult) return;

                    return {
                        search: {
                            __typename: 'SearchResultItemConnection',
                            ...fetchMoreResult.search,
                        },
                    };
                },
            });
        },
    };
};
