import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} xmlns='http://www.w3.org/2000/svg' viewBox={'0 0 162.74 119.65'}>
            <rect fill='#ffc900' y='75.66' width='74' height='8' />
            <rect fill='#ffd740' x='32' y='90.66' width='42' height='6' />
            <rect fill='#ffe47f' x='44' y='104.66' width='30' height='4' />
            <polygon
                fill='#fff'
                points='90.32 21.72 61.63 24.38 33.89 26.96 54.82 45.35 76.47 64.36 70.13 92.47 64 119.65 87.96 105.43 90.32 21.72'
            />
            <polyline
                fill='#ffc900'
                points='112.74 0 126.95 33.01 162.74 36.33 135.74 60.05 143.64 95.11 112.74 76.76 81.84 95.11 89.74 60.05 62.74 36.33 98.52 33.01 112.74 0'
            />
        </svg>
    );
}
SvgComponent.iconName = 'quota-reached';

export const QuotaReachedIcon = svgIconWrapper(SvgComponent);
