/* @flow */

import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';

import {SelectMenu, type SelectMenuOption} from '../select-menu';

import './action-menu.css';

export type {SelectMenuOption};

type ActionMenuProps = {|
    onClick?: () => any,
    options: SelectMenuOption[],
    getRef?: (?HTMLElement) => void,
    size?: 'normal' | 'small' | 'tiny',
    hasHoverBackground?: boolean,
    as?: 'div' | 'button',
|};

type ActionMenuState = {
    isOpen: boolean,
    tooltipText: ?string,
};

export class ActionMenu extends React.PureComponent<ActionMenuProps, ActionMenuState> {
    anchor: ?HTMLElement;
    static defaultProps = {
        size: 'normal',
        as: 'button',
    };

    constructor() {
        super();
        this.state = {
            isOpen: false,
            tooltipText: null,
        };
    }

    componentDidUpdate(prevProps: ActionMenuProps, prevState: ActionMenuState) {
        if (this.state.tooltipText && this.state.tooltipText !== prevState.tooltipText) {
            ReactTooltip.show(this.anchor);
            setTimeout(() => {
                ReactTooltip.hide(this.anchor);
                this.setState({tooltipText: null});
            }, 2000);
        }
    }

    render() {
        const dotsContainerStyle = classnames({
            'dots-container': true,
            'dots-container--hover': this.props.hasHoverBackground,
            'dots-container--tiny': this.props.size === 'tiny',
        });

        const dotStyle = classnames({
            dot: true,
            'dot--small': this.props.size === 'small',
            'dot--tiny': this.props.size === 'tiny',
        });

        const Tag = this.props.as;

        return (
            <>
                {/* $FlowIgnore this works fine */}
                <Tag
                    onClick={this.handleDotsClick}
                    styleName={dotsContainerStyle}
                    ref={(node) => {
                        this.anchor = node;
                        if (this.props.getRef) {
                            this.props.getRef(node);
                        }
                    }}
                    data-tip={this.state.tooltipText}
                    data-delay-show='0'
                    data-place='bottom'
                >
                    <div styleName={dotStyle} />
                    <div styleName={dotStyle} />
                    <div styleName={dotStyle} />
                </Tag>
                {this.state.isOpen ? (
                    <SelectMenu
                        options={this.props.options}
                        anchor={this.anchor}
                        location='bottom-left'
                        customStyles={{marginLeft: -16}}
                        onClose={this.handleMenuClose}
                    />
                ) : (
                    undefined
                )}
            </>
        );
    }

    handleDotsClick = (e: Event) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.props.onClick) {
            this.props.onClick();
        }

        this.setState(toggleOpen);
    };

    handleMenuClose = (e?: SyntheticEvent<*>, tooltipText?: ?string = null) => {
        if (e && !e.isPropagationStopped()) {
            e.stopPropagation();
        }

        this.setState({
            isOpen: false,
            tooltipText: tooltipText,
        });
    };
}

function toggleOpen(prevState) {
    return {
        isOpen: !prevState.isOpen,
    };
}
