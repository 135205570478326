import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Eye from './icon-eye.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Eye {...validProps} />;
}
SvgComponent.iconName = 'eye';

export const EyeIcon = svgIconWrapper(SvgComponent);
