/* @flow */

import {combineReducers} from 'redux';

import {creatorReducer, type CreatorState} from './creator';
import {
    emailComposerReducer,
    type EmailComposerState,
    emailReplyReducer,
    type EmailReplyState,
} from './email';
import {tasksReducer, type CreatorTasksState} from './tasks';
import {messagesReducer, type MessagesState} from './messages';
import {sidebarReducer, type SidebarState} from './sidebar/sidebar-reducer';
import {SalesAutomationUi, type SalesAutomationUiState} from './sales-automation';
import {TinyIntroduction, type TinyIntroState} from './auth';
import {limitsReducer, type LimitsState} from './limits';
import {glossaryReducer, type GlossaryState} from './glossary/redux';
import {onboardingReducer, type OnboardingState} from './onboarding/onboarding-checklist/redux';
import {connectedFormsReducer, type ConnectedFormsState} from './connected-forms';
import {peopleIqReducer, type PeopleIqState} from './people-iq/redux';
import {
    visitorsSidebarReducer,
    type VisitorsSidebarState,
} from './email-marketing/visitors/visitors-sidebar';
import {chatReducer, type ChatState, smsReducer, type SMSState} from './inbox/dashboard/report';
import {openableModalsReducer, type OpenableModalsState} from './openable-modals';

export type UiState = {
    creator: CreatorState,
    tasks: CreatorTasksState,
    salesAutomation: SalesAutomationUiState,
    tinyIntroduction: TinyIntroState,
    sidebar: SidebarState,
    emailComposer: EmailComposerState,
    emailReply: EmailReplyState,
    messages: MessagesState,
    connectedForms: ConnectedFormsState,
    openableModals: OpenableModalsState,
    visitorsSidebar: VisitorsSidebarState,
    limits: LimitsState,
    glossary: GlossaryState,
    onboarding: OnboardingState,
    peopleIq: PeopleIqState,
    chat: ChatState,
    sms: SMSState,
};

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const uiReducer = combineReducers({
    creator: creatorReducer,
    tasks: tasksReducer,
    salesAutomation: SalesAutomationUi.uiReducer,
    tinyIntroduction: TinyIntroduction.tinyIntroReducer,
    sidebar: sidebarReducer,
    emailComposer: emailComposerReducer,
    emailReply: emailReplyReducer,
    messages: messagesReducer,
    connectedForms: connectedFormsReducer,
    openableModals: openableModalsReducer,
    visitorsSidebar: visitorsSidebarReducer,
    limits: limitsReducer,
    glossary: glossaryReducer,
    onboarding: onboardingReducer,
    peopleIq: peopleIqReducer,
    chat: chatReducer,
    sms: smsReducer,
});
