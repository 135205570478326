/* @flow */

/**
 * Hand-rolled function to safely parse an error that comes back from the
 * server. In a perfect world, we'd always have structured JsonAPI error
 * 'objects' to deal with, but since we're still inconsistent, this is just
 * a nice way to prevent blowing up our world if we don't get back the
 * type of error we expect.
 *
 * @param  {string} err - Error responseText from the server, sometimes
 *                        a big 'ole string we can parse, sometimes nothing
 * @return {string}       Parsed string. Returning an empty string indicates
 *                        that a default error message should be displayed.
 */
export function safelyParseError(err: {
    responseText: string,
    statusText?: string,
    message?: string,
}): string {
    if (!err) return '';

    const getErrorMessage = () => {
        if (err.statusText) {
            return err.statusText;
        } else if (err.message) {
            return err.message;
        } else return '';
    };

    if (!err.responseText || !err.responseText.length) {
        return getErrorMessage();
    }

    try {
        const parsedResponseText = JSON.parse(err.responseText);
        const errors = parsedResponseText.errors;

        if (Array.isArray(errors) && errors.length > 0 && errors[0].title) {
            return errors[0].title;
        }
    } catch (e) {
        return '';
    }

    return '';
}
