/* @flow */

import * as React from 'react';

import {Card} from '../../../card';
import {colors} from '../../../colors';
import {Stack} from '../../../layout';
import {Button} from '../../../button';
import {Popover} from '../../../popover';
import {SparkleIcon} from '../../../icon';
import {ButtonMover} from '../../../button-mover';

import './summary-content.css';

type Props = {
    title?: string,
    content: string | React.Node,
    popoverContent?: string | React.Node,
};

export function SummaryContent(props: Props) {
    const [isViewingInfo, setIsViewingInfo] = React.useState(false);
    const buttonRef = React.useRef(null);

    const defaultPopoverContent = (
        <>
            <strong>Timeline summaries</strong> use artificial intelligence to analyze your
            interactions, highlight important moments, and provide key insights. This AI-powered
            tool enables you to quickly understand your progress, identify patterns, and make
            informed decisions.
        </>
    );

    return (
        <>
            <div styleName='timeline-summary'>
                <Card hasBorder={true} hasShadow={true} leftLineColor={colors.aiBlue}>
                    <div className='pad-16'>
                        <div className='flex justify-sb align-center mb-8'>
                            <Stack spacing={4} isHorizontal={true} alignCenter={true}>
                                <SparkleIcon size={17} alignCenter={true} />
                                <div styleName='header-title'>
                                    {props.title || 'Timeline summary'}
                                </div>
                            </Stack>
                            <ButtonMover size='normal' direction='right'>
                                <Button
                                    getButtonRef={buttonRef}
                                    variant='text-primary'
                                    onClick={() => {
                                        setIsViewingInfo(true);
                                    }}
                                >
                                    What is this?
                                </Button>
                            </ButtonMover>
                        </div>
                        {props.content}
                    </div>
                </Card>
            </div>
            {isViewingInfo ? (
                <Popover
                    anchor={buttonRef.current}
                    bodyColor='dark'
                    location='top-left'
                    onBlur={() => {
                        setIsViewingInfo(false);
                    }}
                >
                    <div styleName='popover-content'>
                        {props.popoverContent || defaultPopoverContent}
                    </div>
                </Popover>
            ) : null}
        </>
    );
}
