/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import type {SavedSettingToastState} from '../../saved-setting-toast';
import {SelectPickerGeneric, type SelectPickerGenericProps} from '../../select-picker-generic';
import {FormField} from '../../form';

type OwnProps = {|
    title?: string,
    helperText: string,
    autoFocus?: boolean,
    isMulti?: boolean,
    isRequired?: boolean,
    tooltipInfo?: string,
    saveToastState?: SavedSettingToastState,
    errorMessage?: string,
    ...SelectPickerGenericProps,
|};

type Props = {...$Exact<FieldProps>, ...OwnProps};

export function ReduxFormSelectPickerGeneric(props: Props) {
    const {
        autoFocus = true,
        title,
        tooltipInfo,
        helperText,
        isRequired,
        saveToastState,
        input,
        meta,
        errorMessage,
        ...restProps
    } = props;

    // In some places we pass in a value - if not, we can determine the value here
    const getSelectedValue = () => {
        if (restProps.value) {
            return restProps.value;
        } else if (restProps.options) {
            return restProps.options.find((field) => field.value === props.input.value);
        }
    };

    return (
        <FormField
            innerId={input.name}
            label={title}
            helperText={helperText}
            errorMessage={errorMessage || (meta.error && meta.submitFailed ? meta.error : null)}
            isRequired={isRequired}
            tooltipInfo={tooltipInfo}
            saveToastState={saveToastState}
        >
            {/* $FlowIgnore I can't get this to work because of the exact prop types */}
            <SelectPickerGeneric
                autoFocus={autoFocus}
                {...input}
                {...restProps}
                value={getSelectedValue()}
                onBlur={() => {
                    // Who can explain it, who can tell why. Somehow, this
                    // works. If the params are passed through, the value for
                    // this field is reset on blur :shrug:
                    input.onBlur();
                }}
                onChange={(option) => {
                    input.onChange(option.value);
                }}
            />
        </FormField>
    );
}
