/* @flow */

import * as React from 'react';

import type {
    GetEvent_event_payload_ConstantContactContactTrackingActivity_ctctContact as CTCTContact,
    ConstantContactContactTrackingActivityType,
} from 'nutshell-graphql-types';

import type {EventForTimeline} from '../types';
import {ConstantContactEmailPreviewModal} from '../../core/constant-contact/constant-contact-email-preview/constant-contact-email-preview-modal';
import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {|event: EventForTimeline|};

export function TimelineBannerConstantContact(props: Props) {
    const [activeModalId, setActiveModalId] = React.useState<?string>(null);

    const {event} = props;
    const {payload} = event;

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'ConstantContact' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (payload.__typename === 'ConstantContactContactTrackingActivity') {
        const linkText = payload.emailSubject || payload.campaignName;
        const action = (
            <span>
                {getVerb(payload.trackingActivityType)}&nbsp;
                {linkText ? (
                    <span
                        styleName='target--button'
                        role='button'
                        tabIndex='0'
                        onClick={() => setActiveModalId(payload.id)}
                    >
                        {linkText}
                    </span>
                ) : null}
            </span>
        );

        return (
            <>
                <TimelineBanner
                    id={event.id}
                    iconVariant='constant-contact'
                    timestamp={event.changeTime}
                    actor={getActor(payload.ctctContact)}
                    action={action}
                />
                {activeModalId ? (
                    <ConstantContactEmailPreviewModal
                        onClose={() => {
                            setActiveModalId(null);
                        }}
                        activityId={activeModalId}
                    />
                ) : (
                    undefined
                )}
            </>
        );
    }

    return null;
}

function getActor(ctctContact: CTCTContact) {
    if (ctctContact.contacts.length) {
        return ctctContact.contacts[0];
    }

    return null;
}

function getVerb(interactionType: ConstantContactContactTrackingActivityType) {
    switch (interactionType) {
        case 'SENDS':
            return 'was sent';
        case 'OPENS':
            return 'opened';
        case 'CLICKS':
            return 'clicked';
        case 'BOUNCES':
            return 'had an email bounce';
        case 'OPTOUTS':
            return 'unsubscribed from';
        case 'FORWARDS':
            return 'forwarded';
        default:
            return 'interacted with';
    }
}
