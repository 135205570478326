/* @flow */

export type Entity = {
    entityId: string,
    entityName: 'Contacts' | 'Accounts' | 'Users',
    name: string,
    avatarUrl?: string,
    initials: string,
    personName: string[],
    primaryEmailText?: ?string,
    primaryPhoneText?: ?string,
    primaryAddressRegion?: ?string,
    url: string[],
    parentAccountId?: ?string,
    childAccountIds?: string[],
};

export type DisplayOptionEntity = {
    id: string,
    value: any,
    type: string,
    avatarUrl: ?string,
    initials: string,
    description: string,
    secondaryInfo: any,
    tertiaryInfo?: any,
    parentAccountId?: ?string,
    childAccountIds?: ?(string[]),
    isDisabled?: boolean,
    disabledText?: string,
};

// Extracts unique information from the entity to help with identification.
function getTertiaryInfo(entity: Entity): ?string {
    if (entity.entityName === 'Contacts') {
        if (entity.primaryEmailText) {
            return entity.primaryEmailText;
        } else if (entity.primaryPhoneText) {
            return entity.primaryPhoneText;
        }
    } else if (entity.entityName === 'Accounts') {
        if (entity.primaryAddressRegion) {
            return entity.primaryAddressRegion;
        } else if (entity.url && entity.url.length > 0) {
            return entity.url[0];
        }
    }

    return null;
}

function getDescription(entityName: ?string): string {
    switch (entityName) {
        case 'Users':
            return 'Nutshell User';
        case 'Accounts':
            return 'Company';
        case 'Leads':
            return 'Lead';
        case 'Teams':
            return 'Team';
        case 'Contacts':
        default:
            return 'Person';
    }
}

function getType(entityName: ?string): string {
    return entityName ? entityName.toLowerCase() : '';
}

function getSecondaryInfo(entity: Entity): ?string {
    return entity.personName && entity.personName.length ? entity.personName[0] : undefined;
}

function getId(entityId: string, entityName: string) {
    return `${entityId}-${getType(entityName)}`;
}

export function mapEntityToDisplayOption(entity: Entity): Object {
    return {
        id: getId(entity.entityId, entity.entityName),
        value: entity.name,
        type: getType(entity.entityName),
        avatarUrl: entity.avatarUrl,
        initials: entity.initials,
        description: getDescription(entity.entityName),
        secondaryInfo: getSecondaryInfo(entity),
        tertiaryInfo: getTertiaryInfo(entity),
        parentAccountId: entity.parentAccountId,
        childAccountIds: entity.childAccountIds || [],
    };
}
