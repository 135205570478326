/* @flow */

import * as React from 'react';

import {Divider} from '../../layout';
import {SubSidebarHeader} from '../../sidebar';
import {colors} from '../../colors';

import type {Session} from '../visitor-content/session-details/session-details';

import './sessions-content.css';

type Props = {|
    session: Session,
    sessionsTimeline: React.Node,
    onClickBack: () => void,
|};

export function SessionsContent(props: Props) {
    const headerRef = React.useRef(null);
    const timelineRef = React.useRef(null);

    React.useLayoutEffect(() => {
        if (headerRef && headerRef.current && timelineRef && timelineRef.current) {
            // Height needs to be calculated to account for header
            timelineRef.current.style.height = `calc(100% - ${headerRef.current.clientHeight}px)`;
        }
    }, []);

    return (
        <div styleName='sessions-content'>
            <SubSidebarHeader
                title='Site visits'
                subtitle={
                    <>
                        {props.session.totalVisitDuration}
                        <Divider variant='dot' spacing={4} color={colors.greyLt} />
                        {props.session.numVisits} {props.session.numVisits === 1 ? 'page' : 'pages'}
                    </>
                }
                onClickBack={props.onClickBack}
                getRef={headerRef}
            />
            <div styleName='sessions-timeline-container' ref={timelineRef}>
                {props.sessionsTimeline}
            </div>
        </div>
    );
}
