import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 100 100'}>
            <g className='background-fill'>
                <path d='M36,100H64V36H36Z' />
                <path d='M0,100H27V68H0Z' />
                <path d='M73,0V100h27V0Z' />
            </g>
            <g className='outline'>
                <path d='M36,100H64V36H36Zm3-61H61V97H39Z' />
                <path d='M0,100H27V68H0ZM3,71H24V97H3Z' />
                <path d='M73,0V100h27V0ZM97,97H76V3H97Z' />
            </g>
        </svg>
    );
}
SvgComponent.iconName = 'reports';

export const ReportsIcon = svgIconWrapper(SvgComponent);
