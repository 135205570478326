import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import ExternalLink from './icon-external-link.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ExternalLink {...validProps} />;
}
SvgComponent.iconName = 'external-link';

export const ExternalLinkIcon = svgIconWrapper(SvgComponent);
