/* @flow */

import * as React from 'react';
import {LoadingIcon} from '../icon';
import {colors} from '../colors';

type Props = {
    size?: number,
    padding?: number,
};

export function LoadingPage(props: Props) {
    return (
        <div
            style={{padding: typeof props.padding === 'number' ? `${props.padding}px` : '24px'}}
            className='full-height full-width flex align-center justify-center border-box'
        >
            <LoadingIcon size={props.size} fill={colors.orangeLt} />
        </div>
    );
}
