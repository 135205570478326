import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox='0 0 55 55'>
            <g>
                <path
                    d='M36.8,20.3H18.2c0,0,0,0-0.1,0c-0.5,0-0.8,0.4-0.8,0.9v15.6c0,0,0,0,0,0.1c0,0.5,0.4,0.8,0.9,0.8h18.5
                    c0,0,0,0,0.1,0c0.5,0,0.8-0.4,0.8-0.9V21.1c0,0,0,0,0-0.1C37.6,20.6,37.2,20.2,36.8,20.3z M23.9,30.5v2.6c0,0.5-0.3,0.9-0.8,0.9
                    c0,0,0,0-0.1,0h-2.6c-0.5,0-0.9-0.3-0.9-0.8c0,0,0,0,0-0.1v-2.6c0-0.5,0.3-0.9,0.8-0.9c0,0,0,0,0.1,0H23
                    C23.5,29.7,23.9,30,23.9,30.5C23.9,30.5,23.9,30.5,23.9,30.5z M29.7,30.5v2.6c0,0.5-0.3,0.9-0.8,0.9c0,0,0,0-0.1,0h-2.6
                    c-0.5,0-0.9-0.3-0.9-0.8c0,0,0,0,0-0.1v-2.6c0-0.5,0.3-0.9,0.8-0.9c0,0,0,0,0.1,0h2.6C29.3,29.7,29.7,30,29.7,30.5
                    C29.7,30.5,29.7,30.5,29.7,30.5z M29.7,24.8v2.6c0,0.5-0.3,0.9-0.8,0.9c0,0,0,0-0.1,0h-2.6c-0.5,0-0.9-0.3-0.9-0.8c0,0,0,0,0-0.1
                    v-2.6c0-0.5,0.3-0.9,0.8-0.9c0,0,0,0,0.1,0h2.6C29.3,23.9,29.7,24.2,29.7,24.8C29.7,24.7,29.7,24.7,29.7,24.8z M35.5,24.8v2.6
                    c0,0.5-0.3,0.9-0.8,0.9c0,0,0,0-0.1,0H32c-0.5,0-0.9-0.3-0.9-0.8c0,0,0,0,0-0.1v-2.6c0-0.5,0.3-0.9,0.8-0.9c0,0,0,0,0.1,0h2.6
                    C35.1,23.9,35.4,24.2,35.5,24.8C35.5,24.7,35.5,24.7,35.5,24.8z'
                />
                <path
                    d='M27.5,0C12.3,0,0,12.3,0,27.5S12.3,55,27.5,55S55,42.7,55,27.5S42.7,0,27.5,0z M41.2,41.1
                    C41.3,41.1,41.3,41.1,41.2,41.1c0,0.5-0.4,0.9-0.9,0.9H14.6c0,0,0,0-0.1,0c-0.5,0-0.8-0.4-0.8-0.9V13.9c0,0,0,0,0-0.1
                    c0-0.5,0.4-0.8,0.9-0.8h25.8c0,0,0,0,0.1,0c0.5,0,0.8,0.4,0.8,0.9V41.1z'
                />
            </g>
        </svg>
    );
}
SvgComponent.iconName = 'activities-circle';

export const ActivitiesCircleIcon = svgIconWrapper(SvgComponent);
