/* @flow */

import * as React from 'react';

import {useMutation} from '@apollo/react-hooks';

import type {
    DeleteEmail as DeleteEmailMutation,
    DeleteEmailVariables as DeleteEmailMutationVariables,
    UpdateEmail as UpdateEmailMutation,
    UpdateEmailVariables as UpdateEmailMutationVariables,
    RetryFailedEmail as RetryFailedEmailMutation,
    RetryFailedEmailVariables as RetryFailedEmailMutationVariables,
} from 'nutshell-graphql-types';

import DELETE_EMAIL from '../../../email/mailbox/graphql/mutations/delete-email.graphql';
import UPDATE_EMAIL from '../../../email/mailbox/graphql/mutations/update-email.graphql';
import RETRY_FAILED_EMAIL from '../../../email/mailbox/graphql/mutations/retry-failed-email.graphql';

type Props = {
    children: ({
        updateEmail: (emailId: string, shouldShare: boolean) => Promise<*>,
        retryFailedEmail: (emailId: string) => Promise<*>,
        deleteEmail: (emailId: string, allByAddress?: ?string) => Promise<*>,
    }) => React.Node,
};

export function EmailMutationWrapper(props: Props) {
    const [updateEmail] = useMutation<UpdateEmailMutation, UpdateEmailMutationVariables>(
        UPDATE_EMAIL
    );

    const [retryFailedEmail] = useMutation<
        RetryFailedEmailMutation,
        RetryFailedEmailMutationVariables
    >(RETRY_FAILED_EMAIL);

    const [deleteEmail] = useMutation<DeleteEmailMutation, DeleteEmailMutationVariables>(
        DELETE_EMAIL
    );

    return props.children({
        updateEmail: (emailId: string, shouldShare: boolean) => {
            return updateEmail({
                variables: {
                    payload: {
                        emailId,
                        shouldShare,
                    },
                },
            }).then(
                (mutationResults) =>
                    mutationResults &&
                    mutationResults.data &&
                    mutationResults.data.emailShareAndCreateContacts &&
                    mutationResults.data.emailShareAndCreateContacts.email
            );
        },
        retryFailedEmail: (emailId: string) => {
            return retryFailedEmail({
                variables: {
                    payload: {
                        emailId,
                    },
                },
            }).then(
                (mutationResults) =>
                    mutationResults &&
                    mutationResults.data &&
                    mutationResults.data.failedEmailRetry &&
                    mutationResults.data.failedEmailRetry.email
            );
        },
        deleteEmail: (emailId: string, allByAddress?: ?string) => {
            return deleteEmail({
                variables: {
                    payload: {
                        email: {
                            id: emailId,
                        },
                        allByAddress,
                    },
                },
            }).then(
                (mutationResult) =>
                    mutationResult &&
                    mutationResult.data &&
                    mutationResult.data.emailDelete &&
                    mutationResult.data.emailDelete.email
            );
        },
    });
}
