/* @flow */

import * as React from 'react';

import './gradient-wrapper.css';

type Props = {
    children: React.Node,
};

/* Wrapper around a grid to add gradients to the top
and bottom for ~- pIzZazZ -~ */
export function GradientWrapper(props: Props) {
    return <div styleName='gradient-wrapper'>{props.children}</div>;
}
