/* @flow */

import * as React from 'react';

type Props = {
    width?: number,
};

export function NoMessages(props: Props) {
    return (
        <svg
            width={props.width}
            viewBox='0 0 151 150'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M65.4305 142.168C65.1064 143.219 64.5523 144.571 63.5334 144.646C62.6973 144.708 61.6802 143.874 61.161 143.159C60.8421 142.72 60.5655 142.248 60.3033 141.768C58.5154 138.493 53.3821 121.505 52.151 116.608C50.9813 111.954 49.8937 107.275 48.9135 102.57C48.0477 98.4139 46.2111 87.4977 47.4829 83.2926C47.9896 81.6176 67.0238 82.1012 68.2331 84.0912C69.8718 86.789 68.1565 98.2944 68.2287 101.526C68.3153 105.405 67.502 108.819 67.5152 112.623C67.5291 116.59 66.9955 137.089 65.4305 142.168Z'
                fill='white'
            />
            <path
                d='M71.0643 86.199C72.5317 85.0067 74.1493 84.8077 75.7257 84.0012C77.0288 83.3353 78.1163 82.6466 79.5774 82.4232C82.4551 81.9842 88.5106 81.2414 88.9243 82.3115C89.4969 83.7909 89.0709 86.0855 88.9566 87.619C88.7655 90.1947 88.5027 92.7686 88.117 95.3172C87.0949 102.06 85.0394 108.358 83.1175 114.816C80.5881 123.313 78.1216 131.993 74.8198 140.126C74.185 141.69 72.8168 145.121 70.9407 144.595C69.1847 144.103 68.8448 139.31 68.6022 137.211C68.2126 133.841 67.8961 130.459 67.653 127.069C67.2671 121.69 67.0659 116.292 67.0505 110.894C67.0437 108.481 67.163 106.338 66.8789 103.951C66.5606 101.277 66.3643 98.5588 66.6371 95.8671C67.017 92.1166 68.4303 88.34 71.0643 86.199Z'
                fill='white'
            />
            <path
                d='M63.4459 144.998C62.4365 144.998 61.3675 144.036 60.8791 143.364C60.5175 142.867 60.218 142.34 59.9974 141.936C58.161 138.572 52.9483 121.21 51.8129 116.692C51.6297 115.963 51.448 115.232 51.2729 114.518C51.2269 114.33 51.3415 114.141 51.5287 114.096C51.7164 114.05 51.9051 114.165 51.9509 114.351C52.1258 115.064 52.3071 115.795 52.4901 116.522C53.7848 121.673 58.8811 138.434 60.6102 141.6C60.8207 141.986 61.1058 142.489 61.4439 142.954C61.9512 143.652 62.8632 144.344 63.5081 144.297C64.1201 144.253 64.6548 143.501 65.0974 142.066C66.4422 137.701 67.0722 122.225 67.1666 115.414C67.1693 115.222 67.3251 115.069 67.5156 115.069C67.5173 115.069 67.5188 115.069 67.5206 115.069C67.7134 115.072 67.8675 115.23 67.8648 115.423C67.77 122.267 67.1318 137.834 65.7647 142.271C65.4181 143.396 64.8124 144.901 63.5596 144.994C63.5219 144.996 63.4839 144.998 63.4459 144.998Z'
                fill='#5D7183'
            />
            <path
                d='M71.3344 145C71.1763 145 71.0137 144.978 70.8467 144.931C69.166 144.46 68.68 141.196 68.3261 137.894C68.3011 137.661 68.278 137.445 68.2556 137.251C67.9425 134.542 67.3855 122.791 67.1662 115.428C67.1604 115.236 67.312 115.075 67.5047 115.07C67.7006 115.066 67.8584 115.215 67.8641 115.408C68.0831 122.757 68.6379 134.477 68.9492 137.172C68.9718 137.366 68.9951 137.585 69.0204 137.82C69.2311 139.786 69.6694 143.876 71.0352 144.259C72.5908 144.695 73.9106 141.439 74.4746 140.049L74.4966 139.995C77.4454 132.732 79.754 124.938 81.9866 117.4C82.252 116.505 82.5173 115.609 82.7826 114.716C82.8603 114.457 82.9004 114.323 82.9773 114.064C83.0331 113.879 83.2277 113.774 83.4128 113.829C83.5969 113.885 83.7017 114.08 83.6467 114.264C83.569 114.522 83.5289 114.657 83.4521 114.916C83.1867 115.808 82.9214 116.703 82.6561 117.599C80.4182 125.152 78.1052 132.962 75.1435 140.258L75.1216 140.311C74.4868 141.876 73.2193 145 71.3344 145Z'
                fill='#5D7183'
            />
            <path
                d='M40.4459 110.286C40.3061 103.441 40.4723 96.5793 40.6959 89.7382C41.1086 77.1113 42.5608 64.6049 47.8213 52.904C49.3205 49.5699 51.2576 46.3361 52.4799 42.8859C54.959 35.8913 53.1012 24.5919 55.0356 17.3877C57.0079 10.0413 64.6795 7.49883 70.2077 10.9621C74.6456 13.742 75.0382 20.1572 75.0537 24.5866C75.0752 30.7312 75.1987 34.5236 78.1396 40.1645C88.5592 60.1501 92.0137 81.1184 92.7102 88.5494C92.8761 90.3204 94.3398 110.401 94.2472 110.432C80.0231 115.079 54.4513 115.452 40.4459 110.286Z'
                fill='white'
            />
            <path
                d='M49.2891 64.247C48.3357 67.8622 51.9583 71.0986 55.801 70.567C60.4037 69.9299 62.0312 63.3777 58.2649 60.6388C55.4767 58.6113 51.483 60.1169 49.8506 62.9125C49.59 63.3585 49.4054 63.8054 49.2891 64.247Z'
                fill='#DFE2EA'
            />
            <path
                d='M68.2685 85.5583C67.3153 89.1735 70.9378 92.4099 74.7805 91.8783C79.3836 91.2412 81.0106 84.689 77.2444 81.9502C74.4563 79.9226 70.4625 81.4273 68.8302 84.2229C68.5695 84.6698 68.385 85.1167 68.2685 85.5583Z'
                fill='#DFE2EA'
            />
            <path
                d='M45.1802 81.3305C44.5734 83.6321 46.8794 85.6919 49.3256 85.3541C52.2556 84.9483 53.2916 80.7771 50.8941 79.0341C49.1191 77.7432 46.5768 78.7016 45.5377 80.4812C45.3718 80.7649 45.2543 81.0494 45.1802 81.3305Z'
                fill='#DFE2EA'
            />
            <path
                d='M75.1209 67.6038C74.3919 70.3689 77.1626 72.8442 80.1013 72.4374C83.6214 71.9504 84.866 66.9387 81.9857 64.8449C79.8534 63.2939 76.7986 64.4451 75.5504 66.5826C75.3511 66.9248 75.2099 67.2661 75.1209 67.6038Z'
                fill='#DFE2EA'
            />
            <path
                d='M74.2625 43.297C72.5224 44.2614 70.6934 45.0086 68.858 45.767C68.756 45.8098 68.6657 45.8098 68.5898 45.7836C68.983 47.8539 71.249 49.4119 73.6267 49.0828C76.827 48.6403 78.1459 44.4587 76.1951 42.1283C75.5563 42.5254 74.9197 42.933 74.2625 43.297Z'
                fill='#DFE2EA'
            />
            <path
                d='M58.1043 103.802C57.3752 106.567 60.1457 109.042 63.0846 108.635C66.6046 108.148 67.8493 103.138 64.969 101.043C62.8366 99.4918 59.7822 100.643 58.5339 102.781C58.3345 103.123 58.1933 103.464 58.1043 103.802Z'
                fill='#DFE2EA'
            />
            <path
                d='M79.395 106.123C78.7884 108.424 81.0944 110.484 83.54 110.146C86.47 109.74 87.506 105.569 85.1084 103.826C83.334 102.535 80.7915 103.494 79.752 105.273C79.5862 105.557 79.4692 105.842 79.395 106.123Z'
                fill='#DFE2EA'
            />
            <path
                d='M51.6928 45.6047C51.2616 47.2404 52.9004 48.7049 54.6387 48.464C56.7207 48.176 57.4569 45.2119 55.7532 43.9734C54.4919 43.0561 52.6853 43.7369 51.9469 45.0016C51.829 45.2032 51.7456 45.4057 51.6928 45.6047Z'
                fill='#DFE2EA'
            />
            <path
                d='M85.3989 91.5205C84.9677 93.1561 86.6068 94.6198 88.3446 94.3798C90.4271 94.0918 91.1629 91.1277 89.4592 89.8883C88.1979 88.971 86.3912 89.6518 85.6528 90.9165C85.535 91.119 85.4521 91.3206 85.3989 91.5205Z'
                fill='#DFE2EA'
            />
            <path
                d='M48.3779 15.5103C46.7739 16.816 45.0426 17.9559 43.1867 18.9239C40.9103 20.1109 37.7006 21.4515 36.6695 24.0097C35.9026 25.9116 36.9212 27.048 38.8931 27.2443C40.4425 27.3997 41.9244 26.6561 43.3042 25.9343C40.2339 27.9854 36.9173 29.5337 33.9071 31.7079C32.8356 32.4821 31.6854 33.5347 31.8056 34.8517C31.9181 36.085 33.1838 36.9526 34.4166 37.0521C35.6494 37.1524 36.8482 36.6785 37.9941 36.2124C35.3503 37.3619 32.7377 38.8151 30.428 40.5398C28.3157 42.117 24.2425 47.0448 29.6629 47.5179C31.8531 47.7099 34.0992 47.1993 35.9917 46.0795C34.2138 47.8758 31.3448 47.9831 29.2048 49.3272C28.4464 49.8029 27.7683 50.4619 27.4484 51.298C27.1284 52.1351 27.2276 53.165 27.8436 53.8152C29.2169 55.2641 34.4351 54.8085 36.2157 54.585C39.5621 54.1635 42.7939 52.8691 45.5489 50.9297C50.0884 47.7335 53.3453 42.9191 55.2476 37.7006C57.1498 32.4829 57.7642 26.8655 57.7068 21.3119C59.1522 22.1236 61.0731 21.3424 62.0775 20.0227C63.0819 18.7031 63.3978 16.9993 63.6844 15.3654C63.9089 17.3013 64.1335 19.2363 64.358 21.1713C68.7148 20.5167 73.1236 20.2104 77.5293 20.254C77.3948 20.254 77.5293 16.7785 76.5395 13.68C75.3642 10.0029 72.5323 7.08511 68.9445 5.69996C65.4024 4.33315 60.2462 5.0794 56.9925 6.97426C54.678 8.32275 53.3925 10.5301 51.6243 12.4511C50.6081 13.5552 49.5256 14.5755 48.3779 15.5103Z'
                fill='#FF988D'
            />
            <path
                d='M66.9076 114.389C56.361 114.389 46.9325 113.051 40.3254 110.613C40.1907 110.563 40.1001 110.437 40.0972 110.293C40.0619 108.565 40.0447 106.734 40.046 104.854C40.0461 104.661 40.2024 104.505 40.3951 104.505C40.3952 104.505 40.3953 104.505 40.3954 104.505C40.5882 104.505 40.7443 104.661 40.7442 104.854C40.7429 106.644 40.7585 108.387 40.7906 110.04C47.3108 112.397 56.5604 113.691 66.907 113.691C67.0259 113.691 67.1445 113.69 67.2638 113.69C77.3247 113.661 87.0041 112.384 93.8827 110.182C93.8312 109.292 93.6689 106.492 93.654 106.265C93.4472 103.203 93.2272 100.144 93.0396 97.5551C92.5857 91.2892 91.9818 84.0205 90.5634 77.0476C87.8394 63.6622 83.5557 51.3077 77.8301 40.326C74.8827 34.6719 74.7267 30.8525 74.7049 24.5884C74.688 19.7365 74.1406 13.8372 70.0227 11.258C67.4081 9.61976 64.27 9.32475 61.413 10.4472C58.463 11.6063 56.2614 14.1688 55.3731 17.4776C54.4812 20.8004 54.4034 25.1252 54.3281 29.3077C54.2405 34.1805 54.15 39.2201 52.8094 43.0029C51.9448 45.4424 50.7698 47.7798 49.6335 50.0403C48.9531 51.3941 48.2493 52.794 47.623 54.1958C44.1269 62.0187 42.1352 71.2513 41.355 83.2524C41.3425 83.4444 41.1749 83.5902 40.984 83.578C40.7916 83.5657 40.6458 83.399 40.6582 83.207C41.4439 71.1213 43.4545 61.8119 46.9855 53.9104C47.6184 52.4947 48.3256 51.0877 49.0096 49.727C50.1362 47.4856 51.3013 45.1683 52.1512 42.7699C53.4539 39.0936 53.5434 34.1125 53.6301 29.2954C53.706 25.0702 53.7845 20.7018 54.6987 17.297C55.645 13.7717 57.9993 11.0381 61.1577 9.79694C64.2232 8.59246 67.5895 8.90929 70.3933 10.6662C74.8004 13.4269 75.3857 19.5549 75.4031 24.5858C75.4246 30.7417 75.5766 34.493 78.4497 40.0031C84.205 51.0423 88.5106 63.4597 91.2477 76.9088C92.6748 83.9218 93.2805 91.2176 93.7361 97.5045C93.9237 100.094 94.1437 103.154 94.3506 106.219C94.3689 106.49 94.5862 110.251 94.5958 110.411C94.6054 110.57 94.5068 110.714 94.3558 110.763C87.396 113.038 77.522 114.358 67.2658 114.388C67.1464 114.388 67.0267 114.389 66.9076 114.389Z'
                fill='#5D7183'
            />
            <path
                d='M65.0558 33.0712C64.8717 33.0712 64.7177 32.9272 64.7074 32.7413C64.6618 31.9069 64.6818 30.3777 65.3305 29.5171C65.7921 28.9035 66.5415 28.5797 67.238 28.6923C67.9094 28.8006 68.9845 29.3295 68.6426 31.6616C68.6145 31.8528 68.4363 31.9846 68.2465 31.9566C68.0558 31.9287 67.9237 31.7515 67.9516 31.5604C68.1462 30.2337 67.8688 29.5005 67.1273 29.3818C66.689 29.3111 66.1913 29.5346 65.8883 29.9369C65.3539 30.6465 65.3721 32.1076 65.4047 32.7029C65.4153 32.8958 65.2677 33.0598 65.0751 33.0703C65.0687 33.0712 65.0622 33.0712 65.0558 33.0712Z'
                fill='#5D7183'
            />
            <path
                d='M63.6086 46.8397C59.6712 46.8397 55.9736 45.225 53.6951 42.4382C53.573 42.2889 53.5951 42.069 53.7444 41.9468C53.8935 41.8246 54.1135 41.8464 54.2356 41.9956C55.4546 43.4873 57.1434 44.6507 59.1195 45.3603C61.0159 46.0411 63.0921 46.282 65.123 46.0577C69.6136 45.5611 74.1153 43.6452 76.8708 41.0574C77.0121 40.9256 77.233 40.9326 77.3648 41.0731C77.4966 41.2136 77.4896 41.4344 77.3491 41.5662C74.4885 44.2527 69.8331 46.2392 65.1997 46.7516C64.6671 46.8109 64.1355 46.8397 63.6086 46.8397Z'
                fill='#5D7183'
            />
            <path
                d='M70.7489 58.1103C70.5407 56.1474 70.3054 54.2097 70.2595 52.2459C70.2384 51.3417 69.9093 50.3031 69.058 50.0011C68.6489 49.8553 68.1991 49.9138 67.769 49.9731C66.2329 50.187 58.5874 49.9897 58.6815 52.4746C58.7275 53.6904 59.1816 55.4744 59.3086 56.7836C59.5457 59.2275 60.4109 68.1066 61.6259 69.0693C63.0427 70.1908 71.1935 68.7935 71.5347 68.5168C71.8759 68.2401 71.0526 60.9749 70.7489 58.1103Z'
                fill='white'
            />
            <path
                d='M68.4636 50.2594C68.2475 50.2594 68.0237 50.2899 67.8157 50.3187C67.5093 50.3615 66.9966 50.3877 66.3475 50.42C64.1386 50.5317 58.9658 50.7927 59.029 52.4615C59.0538 53.1161 59.2068 53.9723 59.3547 54.8006C59.474 55.4674 59.5972 56.1578 59.6548 56.7496C59.7715 57.9541 60.0451 60.7741 60.4443 63.4301C61.1005 67.7932 61.6399 68.6355 61.8412 68.7952C62.3359 69.1871 64.1907 69.2578 66.8032 68.9855C68.9598 68.7603 70.7823 68.4016 71.2535 68.2558C71.3832 67.2809 70.7719 61.6033 70.5052 59.1254C70.4661 58.7632 70.4306 58.4332 70.4003 58.147C70.3724 57.8842 70.344 57.6224 70.3157 57.3597C70.1355 55.6926 69.9492 53.9697 69.909 52.2538C69.8911 51.4839 69.6269 50.5736 68.9396 50.3301C68.7926 50.2777 68.6304 50.2594 68.4636 50.2594ZM64.0791 69.8495C62.7287 69.8495 61.8343 69.6802 61.4078 69.3425C61.0274 69.0413 60.4422 68.1118 59.7538 63.5339C59.3519 60.8605 59.0771 58.0265 58.9598 56.8168C58.905 56.253 58.7842 55.5774 58.6674 54.9237C58.5152 54.0718 58.3578 53.1912 58.3312 52.4877C58.242 50.1293 63.2939 49.8754 66.3124 49.7226C66.9443 49.6912 67.4435 49.6659 67.7195 49.6275C68.1452 49.5681 68.6749 49.4948 69.1734 49.672C69.843 49.9094 70.5713 50.7089 70.6071 52.238C70.6466 53.9234 70.8312 55.6324 71.0099 57.2846C71.0383 57.5473 71.0667 57.81 71.0947 58.0736C71.1249 58.359 71.1604 58.689 71.1993 59.0503C72.1611 67.9835 72.0436 68.5517 71.7531 68.7873C71.431 69.0492 68.7792 69.4742 67.0197 69.6645C65.8755 69.7876 64.8941 69.8495 64.0791 69.8495Z'
                fill='#5D7183'
            />
            <path
                d='M78.6113 78.8587C78.0352 78.8587 77.4504 78.8098 76.863 78.7103C74.6849 78.3428 72.5995 77.3225 70.9906 75.837C68.0792 73.1505 66.32 68.9846 66.0367 64.1074C66.0256 63.9145 66.1725 63.7495 66.3651 63.7382C66.5558 63.7268 66.7226 63.8743 66.7338 64.0663C67.0064 68.762 68.6864 72.7604 71.4641 75.3238C72.9747 76.7185 74.9332 77.676 76.9791 78.0216C79.1315 78.3856 81.1581 78.0531 82.84 77.0598C84.3849 76.1469 85.6347 74.6788 86.4552 72.8119C87.2163 71.0793 87.5811 69.1007 87.5095 67.0906C87.369 63.115 85.5815 58.037 84.1065 54.4777C84.0323 54.2996 84.1169 54.0954 84.295 54.0212C84.473 53.9479 84.6773 54.0317 84.7515 54.2097C86.2483 57.8231 88.0629 62.9858 88.2078 67.0662C88.282 69.181 87.8971 71.2644 87.0941 73.0929C86.2152 75.0943 84.8667 76.674 83.1952 77.6603C81.8581 78.4502 80.274 78.8587 78.6113 78.8587Z'
                fill='#5D7183'
            />
            <path
                d='M58.6798 88.9405C58.1666 91.5423 57.1851 95.5947 55.9064 97.9138C54.201 101.007 51.813 103.685 48.2002 104.32C44.4779 104.975 40.6814 103.808 37.5087 101.88C35.8449 100.868 34.5274 99.7118 34.1555 97.6921C33.7259 95.3591 34.9755 92.9074 36.3078 91.0788C37.8848 88.9152 39.4605 86.9086 40.6339 84.4726C42.459 87.2062 51.4544 93.6658 58.6798 88.9405Z'
                fill='#DFE2EA'
            />
            <path
                d='M53.4256 90.6093C52.2399 90.6093 50.9797 90.4627 49.6785 90.165C46.1083 89.3481 42.8752 87.5152 40.9204 85.2197C40.8198 85.3742 40.7759 85.4492 40.7495 85.4946C40.6841 85.6072 40.664 85.6282 40.5335 85.7687C40.4022 85.9101 40.1812 85.9179 40.0401 85.787C39.8989 85.6552 39.8909 85.4344 40.0222 85.293C40.1199 85.1883 40.1199 85.1883 40.1462 85.1429C40.1967 85.0565 40.2906 84.895 40.6059 84.4359C40.6689 84.3443 40.7721 84.2875 40.8837 84.284C40.996 84.2823 41.1014 84.3312 41.1698 84.4193C42.9864 86.7646 46.2254 88.6586 49.8342 89.4842C53.5414 90.3326 56.8703 89.9084 58.968 88.3208C61.7576 86.2095 67.3424 77.2335 72.247 66.978C74.7471 61.7508 76.7391 56.8674 78.0073 52.8551C79.4274 48.3637 79.8891 45.0968 79.3418 43.407C79.2825 43.2228 79.3828 43.0265 79.5661 42.9671C79.7503 42.9078 79.9467 43.0081 80.006 43.1914C80.6065 45.0461 80.1579 48.3689 78.6733 53.0655C77.3955 57.1083 75.3908 62.024 72.8769 67.28C67.9299 77.6237 62.2575 86.707 59.3894 88.8776C57.88 90.0201 55.8021 90.6093 53.4256 90.6093Z'
                fill='#5D7183'
            />
            <path
                d='M48.5 97.6616C46.6077 97.6616 44.7881 97.1972 43.2425 96.6893C40.6507 95.8365 37.7567 93.9434 37.1338 91.6383C36.7798 90.3274 37.1611 89.0295 38.2674 87.7814C38.3954 87.6374 38.6159 87.6243 38.7603 87.7517C38.9046 87.88 38.9178 88.1008 38.79 88.2448C37.8494 89.3062 37.519 90.3867 37.808 91.4559C38.1949 92.8882 40.1289 94.9297 43.4608 96.0259C46.1486 96.9101 48.3986 97.1606 50.339 96.7914C52.7765 96.3279 54.7672 94.9061 56.4252 92.4439C57.0485 91.5187 57.9718 89.8412 58.3532 88.8183C58.4205 88.6376 58.6214 88.546 58.8022 88.6132C58.9829 88.6804 59.0747 88.882 59.0074 89.0627C58.5958 90.1668 57.6471 91.8801 57.0043 92.8341C54.4933 96.5618 51.4114 97.6616 48.5 97.6616Z'
                fill='#5D7183'
            />
            <path
                d='M46.1034 104.848C43.2565 104.849 40.2112 103.931 37.3269 102.178C35.7474 101.218 34.2283 100.018 33.8116 97.7558C33.4512 95.7981 34.1635 93.5533 35.929 91.085C36.041 90.9279 36.2591 90.8912 36.416 91.0038C36.5728 91.1155 36.6091 91.3337 36.4969 91.4908C34.8459 93.7994 34.1735 95.8653 34.4984 97.6293C34.8652 99.621 36.249 100.706 37.6895 101.581C41.1728 103.699 44.8837 104.549 48.1393 103.976C51.1387 103.449 53.5792 101.41 55.6002 97.7454C56.9361 95.3224 57.8534 91.3206 58.2811 89.1517C58.3185 88.9623 58.502 88.8392 58.6913 88.8759C58.8805 88.9134 59.0036 89.0976 58.9663 89.2861C58.5306 91.4961 57.5932 95.5773 56.2117 98.0823C54.0821 101.944 51.4812 104.098 48.2603 104.664C47.5587 104.787 46.8375 104.848 46.1034 104.848Z'
                fill='#5D7183'
            />
            <path
                d='M44.1146 93.5096C44.5162 93.1771 45.1911 93.1963 45.3321 93.8221C45.5915 94.9742 43.5649 95.2378 43.8284 93.9731C43.8679 93.7828 43.9747 93.6248 44.1146 93.5096Z'
                fill='#5D7183'
            />
            <path
                d='M44.6924 93.6449C44.574 93.6449 44.4406 93.6929 44.3371 93.7784C44.337 93.7784 44.337 93.7784 44.337 93.7784C44.2756 93.8291 44.1969 93.9163 44.1703 94.0438C44.1326 94.2245 44.1591 94.3466 44.2487 94.406C44.3855 94.4968 44.6485 94.4723 44.823 94.3519C44.9785 94.2454 45.0352 94.0927 44.9916 93.8989C44.9491 93.7104 44.8434 93.6746 44.8088 93.6624C44.7731 93.6501 44.7337 93.6449 44.6924 93.6449ZM44.4687 95.1584C44.2479 95.1584 44.0343 95.1016 43.8621 94.9873C43.5263 94.7638 43.3894 94.3685 43.4867 93.9015C43.5403 93.644 43.6806 93.4154 43.8923 93.2399C44.2193 92.9702 44.6676 92.8768 45.0347 93.0016C45.3634 93.1142 45.59 93.3787 45.6727 93.7462C45.7788 94.2175 45.6092 94.6591 45.2188 94.9279C44.9964 95.0807 44.7277 95.1584 44.4687 95.1584Z'
                fill='#5D7183'
            />
            <path
                d='M38.9758 87.0613C38.9276 87.0613 38.8786 87.0517 38.8318 87.0299C38.6562 86.9505 38.5784 86.7436 38.658 86.5682C39.6685 84.3391 38.467 81.759 37.6609 80.4193C37.2693 79.769 36.8232 79.1266 36.3917 78.5061C35.5329 77.2702 34.6449 75.9924 34.1064 74.5427C32.9153 71.3342 33.5045 67.4101 35.7233 63.7757C37.4756 60.9051 39.9539 58.5511 42.3506 56.2748C42.5932 56.0444 42.8355 55.814 43.0769 55.5836C43.4526 55.2248 43.8443 54.8591 44.2231 54.5048C46.549 52.3297 48.954 50.0814 50.2184 47.2901C50.2979 47.1147 50.5051 47.037 50.6805 47.1165C50.8561 47.1959 50.9339 47.4027 50.8544 47.5782C49.5325 50.496 47.0759 52.7932 44.7 55.0145C44.3225 55.368 43.9322 55.7328 43.5591 56.0889C43.3173 56.3193 43.0745 56.5506 42.8315 56.781C40.4677 59.0259 38.0234 61.3476 36.3192 64.1397C34.2127 67.5899 33.6447 71.2932 34.761 74.2992C35.2687 75.6668 36.1311 76.9071 36.9651 78.1072C37.403 78.7374 37.8558 79.3894 38.2591 80.0597C39.1359 81.5164 40.4362 84.3364 39.2939 86.8562C39.2355 86.9854 39.1086 87.0613 38.9758 87.0613Z'
                fill='#5D7183'
            />
            <path
                d='M124.281 23.8692C124.319 21.5449 123.747 19.1298 122.241 17.3598C120.237 15.0058 116.984 14.1828 113.92 13.7874C108.407 13.0752 102.771 13.33 97.3451 14.5354C95.301 14.9901 93.1739 15.6456 91.7678 17.1983C90.312 18.806 89.8948 21.0727 89.537 23.2128C88.4442 29.744 87.3506 36.2753 86.2578 42.8073C92.5036 35.9706 101.947 36.7256 110.353 36.5947C112.789 36.5563 115.225 36.4524 117.65 36.2115C118.711 36.1051 119.858 35.9366 120.776 35.3527C121.84 34.678 122.182 33.34 122.612 32.2123C123.625 29.5555 124.233 26.7171 124.281 23.8692Z'
                fill='#DFE2EA'
            />
            <path
                d='M86.2581 43.1565C86.2066 43.1565 86.1542 43.1442 86.1053 43.1207C85.9665 43.0535 85.888 42.9016 85.9142 42.7497L89.1933 23.1552C89.525 21.1696 89.9387 18.6987 91.5097 16.9635C92.997 15.3218 95.2558 14.6427 97.2694 14.195C102.033 13.1363 106.92 12.8055 111.793 13.2104C111.985 13.2262 112.128 13.3955 112.112 13.5875C112.096 13.7795 111.927 13.9218 111.735 13.9061C106.931 13.5063 102.116 13.8336 97.4213 14.8766C94.0234 15.6316 92.7884 16.5926 92.0273 17.4322C90.5933 19.0155 90.1988 21.3747 89.882 23.2704L86.7861 41.7661C89.2221 39.4287 92.2857 37.8943 96.1138 37.0887C99.8459 36.3023 103.827 36.2849 107.677 36.2674C108.557 36.263 109.468 36.2595 110.348 36.2456C113.128 36.2019 115.438 36.0806 117.615 35.8642C118.595 35.7664 119.713 35.6154 120.59 35.0586C121.397 34.5453 121.754 33.5556 122.099 32.5972C122.161 32.4253 122.223 32.2542 122.286 32.0884C123.332 29.3434 123.887 26.5757 123.932 23.8639C123.955 22.4709 123.747 21.1224 123.33 19.966C123.264 19.7844 123.358 19.5846 123.539 19.5191C123.721 19.4536 123.921 19.547 123.986 19.7286C124.432 20.9645 124.655 22.3985 124.63 23.8753C124.583 26.6691 124.014 29.5162 122.939 32.3371C122.877 32.4995 122.816 32.6653 122.756 32.8338C122.389 33.8523 121.973 35.0071 120.964 35.6477C120.005 36.2569 118.822 36.4454 117.684 36.5589C115.488 36.7771 113.159 36.9002 110.359 36.9438C109.475 36.9578 108.563 36.9613 107.68 36.9656C99.9734 37.0006 92.0029 37.0363 86.5155 43.0421C86.4483 43.1163 86.3541 43.1565 86.2581 43.1565Z'
                fill='#5D7183'
            />
            <path
                d='M99.1114 25.3818C98.5633 24.0289 96.5951 25.373 97.529 26.2912C97.7437 26.5016 98.0675 26.5792 98.3634 26.5278C98.8792 26.4387 99.3086 25.8662 99.1114 25.3818Z'
                fill='#5D7183'
            />
            <path
                d='M98.3381 25.1566C98.071 25.1566 97.7612 25.3617 97.6617 25.6052C97.5962 25.7641 97.632 25.9028 97.7743 26.0416C97.8965 26.1629 98.1051 26.2188 98.3041 26.1839C98.4795 26.1533 98.6541 26.025 98.7475 25.8566C98.7867 25.7859 98.8435 25.6488 98.7885 25.5127C98.6916 25.2735 98.545 25.1575 98.3399 25.1566C98.339 25.1566 98.339 25.1566 98.3381 25.1566ZM98.1889 26.8917C97.8467 26.8917 97.5142 26.766 97.2846 26.54C96.9399 26.2013 96.8421 25.7641 97.0158 25.3399C97.2218 24.8371 97.79 24.4583 98.3381 24.4583C98.339 24.4583 98.3399 24.4583 98.3407 24.4583C98.833 24.4601 99.2319 24.7481 99.4352 25.25C99.5574 25.5511 99.5295 25.8863 99.3576 26.1952C99.162 26.547 98.8042 26.8062 98.4228 26.8717C98.3451 26.8856 98.2665 26.8917 98.1889 26.8917Z'
                fill='#5D7183'
            />
            <path
                d='M113.446 26.2144C114.202 24.9663 111.947 24.1904 111.771 25.4891C111.731 25.7867 111.862 26.0931 112.081 26.2999C112.459 26.6604 113.175 26.6621 113.446 26.2144Z'
                fill='#5D7183'
            />
            <path
                d='M112.545 25.167C112.494 25.167 112.446 25.1731 112.4 25.1845C112.234 25.2273 112.144 25.339 112.118 25.5362C112.095 25.7064 112.175 25.9072 112.322 26.0468C112.451 26.169 112.658 26.2319 112.849 26.2057C112.928 26.1952 113.072 26.1586 113.148 26.0337C113.282 25.8129 113.287 25.6253 113.164 25.4603C113.033 25.284 112.774 25.167 112.545 25.167ZM112.765 26.91C112.422 26.91 112.081 26.78 111.841 26.5531C111.532 26.2598 111.373 25.8339 111.426 25.442C111.492 24.9628 111.783 24.6224 112.226 24.5089C112.753 24.3728 113.397 24.6023 113.724 25.0431C114.018 25.4385 114.026 25.9316 113.745 26.3951C113.578 26.6726 113.292 26.8507 112.942 26.8978C112.884 26.9057 112.824 26.91 112.765 26.91Z'
                fill='#5D7183'
            />
            <path
                d='M105.751 26.6019C107.171 26.2694 106.149 24.1153 105.099 24.8965C104.858 25.0754 104.73 25.3844 104.736 25.6846C104.743 26.2074 105.242 26.7206 105.751 26.6019Z'
                fill='#5D7183'
            />
            <path
                d='M105.567 25.0754C105.483 25.0754 105.398 25.1094 105.308 25.1766C105.169 25.2796 105.082 25.4769 105.085 25.6794C105.088 25.8574 105.187 26.0494 105.34 26.169C105.403 26.2179 105.529 26.2956 105.672 26.2624C105.923 26.2031 106.06 26.0765 106.092 25.8731C106.134 25.6078 105.98 25.2692 105.755 25.133C105.692 25.0946 105.63 25.0754 105.567 25.0754ZM105.599 26.9685C105.359 26.9685 105.121 26.8838 104.911 26.7189C104.593 26.4719 104.392 26.0774 104.387 25.6898C104.38 25.2639 104.573 24.8519 104.891 24.6163C105.278 24.3283 105.725 24.2986 106.117 24.536C106.582 24.8179 106.868 25.4393 106.782 25.9822C106.705 26.4684 106.359 26.8184 105.831 26.9415C105.831 26.9415 105.831 26.9415 105.831 26.9415C105.754 26.9598 105.676 26.9685 105.599 26.9685Z'
                fill='#5D7183'
            />
            <path
                d='M113.722 17.6033C114.77 19.9197 117.564 21.3206 120.026 20.693C122.49 20.0664 124.287 17.4244 123.886 14.9133C123.587 13.0315 122.156 11.4072 120.369 10.7491C118.853 10.1905 116.013 10.1207 114.717 11.3383C113.142 12.8177 112.87 15.7215 113.722 17.6033Z'
                fill='white'
            />
            <path
                d='M114.04 17.4593C115.024 19.6334 117.671 20.933 119.94 20.3552C122.263 19.7635 123.912 17.296 123.541 14.9683C123.27 13.2663 121.947 11.7022 120.249 11.0773C118.825 10.5527 116.124 10.496 114.956 11.5923C113.745 12.7295 113.088 15.3575 114.04 17.4593ZM118.811 21.1923C117.93 21.1923 117.031 20.9802 116.189 20.5647C114.935 19.9459 113.946 18.9448 113.403 17.7464C112.54 15.8385 112.705 12.7487 114.478 11.0834C115.3 10.3118 116.542 10.084 117.439 10.029C118.505 9.96446 119.646 10.1111 120.49 10.4218C122.448 11.1427 123.916 12.884 124.231 14.8583C124.656 17.5273 122.77 20.3544 120.113 21.0317C119.689 21.139 119.253 21.1923 118.811 21.1923Z'
                fill='#5D7183'
            />
            <path
                d='M118.595 13.8258C118.472 13.8258 118.366 13.8581 118.268 13.9235C117.954 14.133 117.721 14.6768 117.576 15.54C117.487 16.0628 117.556 16.5437 117.769 16.8946C117.997 17.269 118.429 17.4959 118.798 17.4331C119.092 17.3833 119.383 17.1564 119.598 16.8117C120.022 16.1326 120.031 15.2144 119.621 14.5275C119.407 14.1679 119.104 13.9209 118.79 13.8494C118.721 13.8336 118.656 13.8258 118.595 13.8258ZM118.675 18.1418C118.097 18.1418 117.504 17.8014 117.173 17.2577C116.872 16.7637 116.77 16.1117 116.887 15.423C117.003 14.7379 117.232 13.7769 117.879 13.3431C118.188 13.1363 118.547 13.0778 118.945 13.1686C119.451 13.2838 119.904 13.639 120.22 14.1697C120.761 15.0756 120.749 16.2862 120.191 17.1817C119.87 17.6958 119.405 18.038 118.914 18.1217C118.835 18.1348 118.756 18.1418 118.675 18.1418Z'
                fill='#5D7183'
            />
        </svg>
    );
}
