/* @flow */

import * as React from 'react';
import {connect} from 'react-redux';
import {Link as RouterLink, withRouter, type ContextRouter} from 'react-router-dom';
import {Dialog} from 'shells/dialog';
import {Button} from 'shells/button';
import {ButtonMover} from 'shells/button-mover';
import {Link} from 'shells/link';
import {FooterBar} from 'shells/modal';
import type {Task as TaskFragment} from 'nutshell-graphql-types';
import {refetchTodo} from '../../master-dashboard/dashboard-actions';
import {CompleteTaskButton} from './complete-task-button';

import '../default-footer/default-footer.css';

// Have to specify type for ownProps to satisify flow
// eslint-disable-next-line no-unused-vars
function mapDispatchToProps(dispatch, ownProps: RoutedProps): DispatchProps {
    return {
        refetchTaskForRedux: (id) => {
            dispatch(refetchTodo(id));
        },
    };
}

type DispatchProps = {|
    refetchTaskForRedux: (id: string) => any,
|};

type OwnProps = {|
    onUpdateTask: () => any,
    onToggleTaskCompletion: (isCompleting: boolean) => any,
    task: TaskFragment,
|};

type RoutedProps = {|...ContextRouter, ...OwnProps|};

type Props = {...RoutedProps, ...DispatchProps};

export class TaskFooterComponent extends React.PureComponent<
    Props,
    {
        deleteDialog: ?React.Element<any>,
    }
> {
    deleteButton: ?HTMLElement;

    constructor(props: Props) {
        super(props);
        this.state = {
            deleteDialog: undefined,
        };
    }

    render() {
        const isDeleted = Boolean(this.props.task.deletedTime);
        const editLink = this.props.location.pathname.endsWith('/')
            ? `${this.props.location.pathname}edit`
            : `${this.props.location.pathname}/edit`;

        return (
            <div styleName='task-footer'>
                <FooterBar
                    left={
                        <div styleName='delete-btn'>
                            <ButtonMover size='normal' direction='left'>
                                <Button
                                    variant='text-danger'
                                    getButtonRef={(node) => {
                                        this.deleteButton = node;
                                    }}
                                    disabled={isDeleted}
                                    onClick={this.handleDelete}
                                >
                                    {isDeleted ? 'This task is deleted.' : 'Delete'}
                                </Button>
                            </ButtonMover>
                        </div>
                    }
                    right={
                        <div styleName='right-btn-group'>
                            {isDeleted ? null : (
                                <Link
                                    as={RouterLink}
                                    variant='button-secondary'
                                    size='big'
                                    to={{
                                        pathname: editLink,
                                        state: {
                                            modal:
                                                this.props.location.state &&
                                                this.props.location.state.modal,
                                        },
                                    }}
                                >
                                    Edit
                                </Link>
                            )}
                            <CompleteTaskButton
                                disabled={isDeleted}
                                isActive={Boolean(this.props.task.completedTime)}
                                onClick={this.handleToggleTaskCompletion}
                            />
                        </div>
                    }
                />
                {this.state.deleteDialog}
            </div>
        );
    }

    handleToggleTaskCompletion = () => {
        const isCompleting = !this.props.task.completedTime;

        const patchArray = [
            {
                op: 'replace',
                path: '/tasks/0/isCompleted',
                value: isCompleting,
            },
        ];

        $.ajax({
            type: 'PATCH',
            url: this.props.task.href,
            contentType: 'application/json-patch+json',
            data: JSON.stringify(patchArray),
        }).then((res) => {
            this.props.onToggleTaskCompletion(isCompleting);

            const task = res && res.tasks && res.tasks[0];
            if (task) {
                this.props.refetchTaskForRedux(task.id);
            }
        });
    };

    handleDelete = () => {
        const options = {
            question: 'Are you sure you want to delete this task?',
            onYes: () => {
                $.ajax({
                    type: 'DELETE',
                    url: this.props.task.href,
                }).then((res) => {
                    this.props.onUpdateTask();

                    const task = res && res.tasks && res.tasks[0];
                    if (task) {
                        this.props.refetchTaskForRedux(task.id);
                    }
                });

                this.setState({deleteDialog: undefined});
            },
            onNo: () => {
                this.setState({deleteDialog: undefined});
            },
        };

        this.setState({
            deleteDialog: (
                <Dialog
                    anchor={this.deleteButton}
                    location='top'
                    isWarning={true}
                    question={options.question}
                    onPrimary={options.onYes}
                    onSecondary={options.onNo}
                />
            ),
        });
    };
}

const connector = connect<Props, RoutedProps, _, _, _, _>(null, mapDispatchToProps);
export const TaskFooter = withRouter<OwnProps>(connector(TaskFooterComponent));
