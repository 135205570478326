/* @flow */

import startsWith from 'underscore.string/startsWith';
import endsWith from 'underscore.string/endsWith';
import type {AnyAll} from './types';

type RelationshipFilterItem = {
    data: string,
    isNot: boolean,
};

type SerializedRelationshipFilterItem =
    | RelationshipFilterItem
    | {|
          data: string,
      |};

export type SerializedRelationshipFilter = {|
    none?: boolean,
    anyAll: AnyAll,
    data: SerializedRelationshipFilterItem[],
|};

export type RelationshipFilter = {|
    none?: boolean,
    anyAll: AnyAll,
    data: RelationshipFilterItem[],
|};

export function deserializeRelationship(
    value: string | SerializedRelationshipFilter
): RelationshipFilter {
    // We previously used a much simpler serialization scheme like:
    // ?filter[tags]=foo,bar or ?filter[tags]=(foo,bar)
    // this takes that older form and makes it conform to the new form
    if (typeof value === 'string') return deserializeLegacyEncoding(value);

    return {
        none: value.none ? value.none : false,
        anyAll: value.anyAll,
        data: value.data
            ? value.data.map((item: SerializedRelationshipFilterItem): RelationshipFilterItem => {
                  const isNot = typeof item.isNot === 'string' ? item.isNot === 'true' : item.isNot;

                  if (!item.isNot) {
                      return {
                          isNot: false,
                          data: item.data,
                      };
                  }

                  return {
                      ...item,
                      isNot,
                  };
              })
            : [],
    };
}

export function deserializeLegacyEncoding(value: string): RelationshipFilter {
    let ids = value;
    let anyAll = 'any';

    if (startsWith(value, '(') && endsWith(value, ')')) {
        // Strip parentheses
        ids = value.substr(1, value.length - 2);

        // Flip flag to use all
        anyAll = 'all';
    }

    return {
        data: ids.split(',').map((item) => ({
            data: decodeURIComponent(item),
            // This filter form did not support negation
            isNot: false,
        })),
        anyAll: anyAll,
    };
}
