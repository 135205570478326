/* @flow */

import type {
    ContactId,
    CustomFieldsApiResponse,
    SearchEmailApiResponse,
    EmailAddress,
    ListApiResponse,
} from '../types';
import type {ListSchemaResponse} from '../schema/schema-types';

import {
    type ContactCreationFormValues,
    type ContactsApiResponse,
    type EditableContact,
} from './contacts-types';

/*
 * Action types
 */
export const ActionTypes = {
    CONTACTS_FETCH_BY_EMAIL_ADDRESS_SUCCESS: 'CONTACTS_FETCH_BY_EMAIL_ADDRESS_SUCCESS',
    CONTACTS_FETCH_BY_EMAIL_ADDRESS_REQUEST: 'CONTACTS_FETCH_BY_EMAIL_ADDRESS_REQUEST',
    CONTACTS_FETCH_BY_EMAIL_ADDRESS_FAILURE: 'CONTACTS_FETCH_BY_EMAIL_ADDRESS_FAILURE',
    CONTACTS_FETCH_BY_ID_SUCCESS: 'CONTACTS_FETCH_BY_ID_SUCCESS',
    CONTACTS_FETCH_BY_ID_REQUEST: 'CONTACTS_FETCH_BY_ID_REQUEST',
    CONTACTS_FETCH_BY_ID_FAILURE: 'CONTACTS_FETCH_BY_ID_FAILURE',
    CONTACTS_CREATE_CONTACT_REQUEST: 'CONTACTS_CREATE_CONTACT_REQUEST',
    CONTACTS_CREATE_CONTACT_SUCCESS: 'CONTACTS_CREATE_CONTACT_SUCCESS',
    CONTACTS_CREATE_CONTACT_FAILURE: 'CONTACTS_CREATE_CONTACT_FAILURE',
    CONTACTS_EDIT_CONTACT_REQUEST: 'CONTACTS_EDIT_CONTACT_REQUEST',
    CONTACTS_EDIT_CONTACT_SUCCESS: 'CONTACTS_EDIT_CONTACT_SUCCESS',
    CONTACTS_EDIT_CONTACT_FAILURE: 'CONTACTS_EDIT_CONTACT_FAILURE',
    CONTACTS_FETCH_CUSTOM_FIELDS_SUCCESS: 'CONTACTS_FETCH_CUSTOM_FIELDS_SUCCESS',
    CONTACTS_FETCH_CUSTOM_FIELDS_REQUEST: 'CONTACTS_FETCH_CUSTOM_FIELDS_REQUEST',
    CONTACTS_FETCH_CUSTOM_FIELDS_FAILURE: 'CONTACTS_FETCH_CUSTOM_FIELDS_FAILURE',
    CONTACTS_FETCH_LIST_SUCCESS: 'CONTACTS_FETCH_LIST_SUCCESS',
    CONTACTS_LIST_FETCH_SCHEMA_REQUEST: 'CONTACTS_LIST_FETCH_SCHEMA_REQUEST',
    CONTACTS_LIST_FETCH_SCHEMA_SUCCESS: 'CONTACTS_LIST_FETCH_SCHEMA_SUCCESS',
    CONTACTS_LIST_FETCH_SCHEMA_FAILURE: 'CONTACTS_LIST_FETCH_SCHEMA_FAILURE',
};

export type ActionFetchByEmailRequest = {
    type: 'CONTACTS_FETCH_BY_EMAIL_ADDRESS_REQUEST',
    payload: EmailAddress[],
};
export type ActionFetchByEmailSuccess = {
    type: 'CONTACTS_FETCH_BY_EMAIL_ADDRESS_SUCCESS',
    payload: SearchEmailApiResponse,
};
export type ActionFetchByEmailFailure = {
    type: 'CONTACTS_FETCH_BY_EMAIL_ADDRESS_FAILURE',
    payload: ?Object,
    error: true,
};

export type ActionFetchByIdRequest = {type: 'CONTACTS_FETCH_BY_ID_REQUEST', payload: ContactId};
export type ActionFetchByIdSuccess = {
    type: 'CONTACTS_FETCH_BY_ID_SUCCESS',
    payload: ContactsApiResponse,
};
export type ActionFetchByIdFailure = {
    type: 'CONTACTS_FETCH_BY_ID_FAILURE',
    payload: ?Object,
    error: true,
};

export type ActionCreateContactRequest = {
    type: 'CONTACTS_CREATE_CONTACT_REQUEST',
    payload: ContactCreationFormValues,
};
export type ActionCreateContactSuccess = {
    type: 'CONTACTS_CREATE_CONTACT_SUCCESS',
    payload: {response: ContactsApiResponse},
};
export type ActionCreateContactFailure = {
    type: 'CONTACTS_CREATE_CONTACT_FAILURE',
    payload: {error: ?Object},
    error: true,
};

export type ActionEditContactRequest = {
    type: 'CONTACTS_EDIT_CONTACT_REQUEST',
    payload: EditableContact,
};
export type ActionEditContactSuccess = {
    type: 'CONTACTS_EDIT_CONTACT_SUCCESS',
    payload: {response: ContactsApiResponse},
};
export type ActionEditContactFailure = {
    type: 'CONTACTS_EDIT_CONTACT_FAILURE',
    payload: {error: ?Object},
    error: true,
};

export type ActionFetchCustomFieldsRequest = {
    type: 'CONTACTS_FETCH_CUSTOM_FIELDS_REQUEST',
    payload: ContactId,
};
export type ActionFetchCustomFieldsSuccess = {
    type: 'CONTACTS_FETCH_CUSTOM_FIELDS_SUCCESS',
    payload: {response: CustomFieldsApiResponse, contactId: ContactId},
};
export type ActionFetchCustomFieldsFailure = {
    type: 'CONTACTS_FETCH_CUSTOM_FIELDS_FAILURE',
    payload: ?Object,
    error: true,
};

export type FetchContactListSuccessAction = {
    type: 'CONTACTS_FETCH_LIST_SUCCESS',
    payload: ListApiResponse,
};

export type ActionFetchContactsListSchemaRequest = {type: 'CONTACTS_LIST_FETCH_SCHEMA_REQUEST'};
export type ActionFetchContactsListSchemaSuccess = {
    type: 'CONTACTS_LIST_FETCH_SCHEMA_SUCCESS',
    payload: ListSchemaResponse,
};
export type ActionFetchContactsListSchemaFailure = {
    type: 'CONTACTS_LIST_FETCH_SCHEMA_FAILURE',
    payload: ?Object,
    error: true,
};

export type ContactsAction =
    | ActionFetchByEmailRequest
    | ActionFetchByEmailSuccess
    | ActionFetchByEmailFailure
    | ActionFetchByIdRequest
    | ActionFetchByIdSuccess
    | ActionFetchByIdFailure
    | ActionCreateContactRequest
    | ActionCreateContactSuccess
    | ActionCreateContactFailure
    | ActionEditContactRequest
    | ActionEditContactSuccess
    | ActionEditContactFailure
    | ActionFetchCustomFieldsRequest
    | ActionFetchCustomFieldsSuccess
    | ActionFetchCustomFieldsFailure
    | FetchContactListSuccessAction;

export type ContactListSchemaAction =
    | ActionFetchContactsListSchemaRequest
    | ActionFetchContactsListSchemaSuccess
    | ActionFetchContactsListSchemaFailure;

/*
 * Action creators
 *
 * These functions will be called by our app to create an action which can be dispatched.
 */
export const fetchByEmailAddress = (payload: EmailAddress[]): ActionFetchByEmailRequest => ({
    type: ActionTypes.CONTACTS_FETCH_BY_EMAIL_ADDRESS_REQUEST,
    payload,
});
export const resolveFetchByEmailAddress = (
    payload: SearchEmailApiResponse
): ActionFetchByEmailSuccess => ({
    type: ActionTypes.CONTACTS_FETCH_BY_EMAIL_ADDRESS_SUCCESS,
    payload,
});
export const failFetchByEmailAddress = (payload?: Object): ActionFetchByEmailFailure => ({
    type: ActionTypes.CONTACTS_FETCH_BY_EMAIL_ADDRESS_FAILURE,
    payload,
    error: true,
});

export const fetchById = (payload: ContactId): ActionFetchByIdRequest => ({
    type: ActionTypes.CONTACTS_FETCH_BY_ID_REQUEST,
    payload,
});
export const resolveFetchById = (payload: ContactsApiResponse): ActionFetchByIdSuccess => ({
    type: ActionTypes.CONTACTS_FETCH_BY_ID_SUCCESS,
    payload,
});
export const failFetchById = (payload?: Object): ActionFetchByIdFailure => ({
    type: ActionTypes.CONTACTS_FETCH_BY_ID_FAILURE,
    payload,
    error: true,
});

export const createContact = (payload: ContactCreationFormValues): ActionCreateContactRequest => ({
    type: ActionTypes.CONTACTS_CREATE_CONTACT_REQUEST,
    payload,
});
export const resolveCreateContact = (
    response: ContactsApiResponse
): ActionCreateContactSuccess => ({
    type: ActionTypes.CONTACTS_CREATE_CONTACT_SUCCESS,
    payload: {response},
});
export const failCreateContact = (error?: Object): ActionCreateContactFailure => ({
    type: ActionTypes.CONTACTS_CREATE_CONTACT_FAILURE,
    payload: {error},
    error: true,
});

export const editContact = (payload: EditableContact): ActionEditContactRequest => ({
    type: ActionTypes.CONTACTS_EDIT_CONTACT_REQUEST,
    payload,
});
export const resolveEditContact = (response: ContactsApiResponse): ActionEditContactSuccess => ({
    type: ActionTypes.CONTACTS_EDIT_CONTACT_SUCCESS,
    payload: {response},
});
export const failEditContact = (error?: Object): ActionEditContactFailure => ({
    type: ActionTypes.CONTACTS_EDIT_CONTACT_FAILURE,
    payload: {error},
    error: true,
});

export const fetchCustomFields = (payload: ContactId): ActionFetchCustomFieldsRequest => ({
    type: ActionTypes.CONTACTS_FETCH_CUSTOM_FIELDS_REQUEST,
    payload,
});
export const resolveFetchCustomFields = ({
    response,
    contactId,
}: {
    response: CustomFieldsApiResponse,
    contactId: ContactId,
}): ActionFetchCustomFieldsSuccess => ({
    type: ActionTypes.CONTACTS_FETCH_CUSTOM_FIELDS_SUCCESS,
    payload: {response, contactId},
});
export const failFetchCustomFields = (payload?: Object): ActionFetchCustomFieldsFailure => ({
    type: ActionTypes.CONTACTS_FETCH_CUSTOM_FIELDS_FAILURE,
    payload,
    error: true,
});

export const resolveFetchListItems = (
    listApiResponse: ListApiResponse
): FetchContactListSuccessAction => ({
    type: ActionTypes.CONTACTS_FETCH_LIST_SUCCESS,
    payload: listApiResponse,
});

export const fetchContactsListSchema = (): ActionFetchContactsListSchemaRequest => ({
    type: ActionTypes.CONTACTS_LIST_FETCH_SCHEMA_REQUEST,
});
export const resolveFetchContactsListSchema = (
    payload: ListSchemaResponse
): ActionFetchContactsListSchemaSuccess => ({
    type: ActionTypes.CONTACTS_LIST_FETCH_SCHEMA_SUCCESS,
    payload,
});
export const failFetchContactsListSchema = (
    payload?: Object
): ActionFetchContactsListSchemaFailure => ({
    type: ActionTypes.CONTACTS_LIST_FETCH_SCHEMA_FAILURE,
    payload,
    error: true,
});
