/* @flow */

import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import {MinimizeIcon} from '../../icon';
import './minimize-button.css';

type Props = {
    size?: number,
    tooltip?: string,
    getRef?: (?HTMLButtonElement) => void,
    onClick: (e: SyntheticEvent<>) => void,
    isMaximizeButton?: boolean,
    variant?: 'dark' | 'medium' | 'light',
};

export class MinimizeButton extends React.PureComponent<Props> {
    static defaultProps = {
        size: 14,
        variant: 'dark',
    };

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        ReactTooltip.hide();
    }

    render() {
        let tooltip = this.props.isMaximizeButton ? 'Enlarge' : 'Minimize';
        if (this.props.tooltip) {
            tooltip = this.props.tooltip;
        }

        const containerTypeStyles = this.props.isMaximizeButton
            ? 'container--maximize'
            : 'container--minimize';

        let containerColorStyles = '';
        switch (this.props.variant) {
            case 'light':
                containerColorStyles = 'container--light';
                break;
            case 'medium':
                containerColorStyles = 'container--medium';
                break;
            case 'dark':
                containerColorStyles = 'container';
        }

        const styleName = `${containerTypeStyles} ${containerColorStyles}`;

        return (
            <button
                data-tip={tooltip}
                onClick={this.props.onClick}
                ref={this.props.getRef}
                styleName={styleName}
            >
                <MinimizeIcon size={this.props.size} />
            </button>
        );
    }
}
