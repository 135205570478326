import * as ramda from 'ramda';
import {getLocationType} from './get-location-type';

export function serializeLocation({selected, data}) {
    if (selected === 'noLocation') {
        return '-';
    } else if (selected === 'exactMatch') {
        return Object.assign(
            {type: getLocationType(data.selected)},
            ramda.omit(['selected'], data)
        );
    } else if (selected === 'range') {
        return {
            type: getLocationType('coordinates'),
            range: data.selected,
            coordinates: data.data.coordinates,
            place: data.data.locationString,
        };
    }
}
