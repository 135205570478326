/* @flow */

import * as React from 'react';

import {CaretDown, CaretRight} from '../../../icon';
import {ScrollableContainerWithShadow} from '../../../layout';

import type {Reaction, Reactions} from '../../reaction-bar/types';
import type {Comment} from './types';
import {TimelineComment} from './timeline-comment';
import {ShowHideButton} from './show-hide-button';
import {AddAComment} from './add-a-comment';

import './timeline-comments.css';

const MAX_NUM_SHOWN = 3;

// TimelineCommentProps
export type Props = {|
    comments?: Comment[],
    onAddComment?: (value: string) => Promise<*>,
    onDeleteComment?: (commentId: string) => Promise<*>,
|};

type OwnProps = {|
    ...Props,
    isAddingComment?: boolean,
    cancelAddingComment?: () => void,
    maxNumShown?: number,
    getShowHideButtonRef?: () => any,
    commentSectionMaxHeight?: number,
    alwaysShowAddComment?: boolean,
    currentUserId?: string,
    onToggleReaction?: (changeLogId: string, reaction: Reaction, reactions: Reactions) => any,
    limitCommentHeight?: boolean,
|};

export function TimelineComments(props: OwnProps) {
    const {
        comments,
        onAddComment,
        onDeleteComment,
        isAddingComment,
        cancelAddingComment,
        getShowHideButtonRef,
        commentSectionMaxHeight,
        alwaysShowAddComment,
        currentUserId,
        onToggleReaction,
        limitCommentHeight,
    } = props;
    const maxNumShown = props.maxNumShown ? props.maxNumShown : MAX_NUM_SHOWN;

    const [shouldShowAllComments, setShouldShowAllComments] = React.useState<boolean>(false);
    const [offsetHeight, setOffsetHeight] = React.useState<number>(0);
    const showHideContainerRef = React.useRef();
    const addCommentContainerRef = React.useRef();

    let numExtraComments;

    if (comments && comments.length) {
        // Only hiding comments if there are more than one
        if (comments.length > maxNumShown + 1) {
            numExtraComments = comments.length - maxNumShown;
        } else if (!shouldShowAllComments) {
            setShouldShowAllComments(true);
        }
    }

    if (!onAddComment || !onDeleteComment) {
        return null;
    }

    const Comments = () =>
        comments
            ? comments
                  // this filters out comments that are just deleted
                  .filter((comment) => comment.id)
                  .sort((a, b) => a.createdTime - b.createdTime)
                  .filter(
                      (submission, index) =>
                          shouldShowAllComments || index >= comments.length - maxNumShown
                  )
                  .map((comment) => (
                      <TimelineComment
                          key={comment.id}
                          comment={comment}
                          currentUserId={currentUserId}
                          onDeleteComment={
                              comment.isDeleteable ? () => onDeleteComment(comment.id) : undefined
                          }
                          onToggleReaction={
                              comment.changeLogId && onToggleReaction
                                  ? (reaction: Reaction) => {
                                        onToggleReaction(
                                            // $FlowIgnore - flow doesn't believe the check happening directly above
                                            comment.changeLogId,
                                            reaction,
                                            comment.reactions
                                        );
                                    }
                                  : undefined
                          }
                          limitCommentHeight={limitCommentHeight}
                      />
                  ))
            : null;

    if (
        showHideContainerRef &&
        showHideContainerRef.current &&
        addCommentContainerRef &&
        addCommentContainerRef.current
    ) {
        const combinedHeight =
            showHideContainerRef.current.clientHeight +
            addCommentContainerRef.current.clientHeight +
            2; // Added 2 for borders
        if (combinedHeight !== offsetHeight) setOffsetHeight(combinedHeight);
    }

    return (
        <div styleName='timeline-comments'>
            {numExtraComments ? (
                <div
                    styleName='show-hide-container'
                    ref={(ref) => (showHideContainerRef.current = ref)}
                >
                    <ShowHideButton
                        onClick={() =>
                            setShouldShowAllComments(
                                (prevShouldShowAllComments) => !prevShouldShowAllComments
                            )
                        }
                        getButtonRef={getShowHideButtonRef}
                        icon={
                            shouldShowAllComments ? (
                                <CaretDown size={18} />
                            ) : (
                                <CaretRight size={18} />
                            )
                        }
                        buttonText={
                            !shouldShowAllComments
                                ? `Show ${numExtraComments} older comments`
                                : `Hide ${numExtraComments} older comments`
                        }
                    />
                </div>
            ) : (
                undefined
            )}
            {commentSectionMaxHeight ? (
                <ScrollableContainerWithShadow
                    direction='vertical'
                    maxContainerSize={commentSectionMaxHeight - offsetHeight}
                    showReverseGradientShadow={true}
                >
                    <Comments />
                </ScrollableContainerWithShadow>
            ) : (
                <Comments />
            )}
            {alwaysShowAddComment || isAddingComment ? (
                <div ref={(ref) => (addCommentContainerRef.current = ref)}>
                    <AddAComment
                        onAddComment={onAddComment}
                        cancelAddingComment={cancelAddingComment}
                        isAddingComment={isAddingComment}
                    />
                </div>
            ) : (
                undefined
            )}
        </div>
    );
}
