import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import AdminLock from './icon-admin-lock.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <AdminLock {...validProps} />;
}
SvgComponent.iconName = 'admin-lock';

export const AdminLockIcon = svgIconWrapper(SvgComponent);
