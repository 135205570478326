/* @flow */

import * as React from 'react';
import {CampaignIcon, EnvelopeIcon} from './index';

type Props = {
    messageCount: number,
    size: number,
};

EmailSequenceIcon.prototype.defaultProps = {
    size: 15,
};

export function EmailSequenceIcon(props: Props) {
    const {size, messageCount} = props;

    return messageCount > 1 ? (
        <CampaignIcon size={size} wrapWithDiv={false} />
    ) : (
        <EnvelopeIcon size={size} wrapWithDiv={false} />
    );
}
