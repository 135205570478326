/* @flow */

export const ActionTypes = {
    MESSAGE_DISMISSED: 'MESSAGE_DISMISSED',
};

type MessageDismissed = {type: 'MESSAGE_DISMISSED', payload: string};

export type MessagesAction = MessageDismissed | {type: 'persist/REHYDRATE', payload: Object};

/*
 * Action creators
 *
 * These functions will be called by our app to create an action which can be dispatched.
 */
export const dismissMessage = (messageId: string): MessagesAction => ({
    type: ActionTypes.MESSAGE_DISMISSED,
    payload: messageId,
});
