/* @flow */

import {getProductMapDescription} from './leads/leads-product-map-helpers';

const EMAIL_ADDRESS_TO = 'to';

/**
 * Returns a Lead.props.message-ready object for the given
 * Nutshell JSON-API structure.
 *
 * @param {string}   id        - The JSON-API resource identifier that maps to the lead we want
 *                               (e.g., "123-leads")
 * @param {object}   json      - The full JSON-API payload that contains our data \o/
 * @param {string}   modelType - The type of model being parsed
 * @return {?object}             A message-prop-ready object (see Lead.propTypes for more info).
 */
export function jsonToModelProp(id: string, json: Object, modelType?: string) {
    if (!id || typeof id !== 'string') return null;

    // Use modelType if provided, otherwise naively get type from modelId
    const type = modelType || id.split('-')[1];
    // Filter out results to only objects with ids of passed
    // Model id
    if (!json[type]) return {id, type};
    const results = json[type].filter((obj) => obj.id === id);

    // If empty results, return a sparse object of id and type.
    if (!results.length) return {id, type};

    // Now we'll do the heavy work and deserialize our json
    // By grabbing related links and creating one big 'ole
    // Model object with denormalized links as top-level keys.
    const model = deserializeJsonLinks(json, results[0], type);

    // /////////////////////////////////////////////////////////////////
    //
    // From this point on in this function, we have hacks which allow
    // our client to more easily consume this data. This is ugly,
    // and hopefully we be replaced / abstracted in a better way in
    // the future!
    //
    // /////////////////////////////////////////////////////////////////

    // For event models, the information we'd like to display in the client
    // is actually burried two-levels deep in the links.
    //
    // For example, the `event` model contains links to a `payload` model,
    // which then contains links to the actual `contact/account` etc
    // that we'd like to display in the UI.
    //
    // For this reason, we'll recursively call `jsonToModelProp` on the payload
    // model so that we can denormalize the _payload's_ links as well onto the
    // event model
    if (model.type === 'events' && model.payloads.length) {
        model.payloads.forEach((payload) => {
            jsonToModelProp(payload.id, json, 'payloads');
        });
    }

    // Notes are a bit of an oddball, since they have a `parent` instead of
    // a particular entity type that is in the `payload`.  So we'll look for
    // the model of the matching `parentType`, and manually shove it onto the `parent`
    // of the model.
    if (model.type === 'notes' && !model.parent) {
        model.parent = json[model.parentType].find((p) => p.id === model.links.parent);
    }

    // Emails and ticketMessages have a _third_ level of links that we'd like
    // to denormalize. (Again, this is ugly, but it works for our purposes
    // right now). For this reason, we'll check to see if we have `from`
    // and `to` keys on our email/ticketMessage models, and denormalize
    // those links.
    if (model.type === 'emails' || model.type === 'ticketMessages') {
        // `from` is always a singular key
        if (model.from) {
            jsonToModelProp(model.from.id, json, 'emailAddresses');
        }

        // `to` is an array of models, so we'll loop through them
        if (model.to && model.to.length) {
            model.to.forEach((toModel) => {
                jsonToModelProp(toModel.id, json, 'emailAddresses');
            });
        }
    }

    // For these type of models, we'll aggregate contacts, accounts, leads, and users
    // under one `participants` key so that our clients can more easily consume them
    if (model.type === 'activities' || model.type === 'emailAddresses' || model.type === 'chats') {
        return aggregateModelEntitiesToParticipantsKey(model);
    }

    return model;
}

/**
 * Takes a json response, a chunk of modelJson, and a desired type,
 * then extracts the top-level links from that full json response
 * and denormalizes the related compoundGroups by adding them to
 * the passed chunk of modelJson
 *
 * @param  {object} json – full json payload from response
 * @param  {object} modelJson - json for desired model
 * @param  {string} type - API-type of model to be added to
 * @return {object} - denormalized json response in the form
 *                    of one large model object of passed API-type
 */
function deserializeJsonLinks(json: Object, modelJson: Object, type: string) {
    const linkKeysForType = getLinkKeysForType(json.links, type);

    // Assuming our modelJson actually _has_ links,
    // We'll loop through them and start to do work
    if (modelJson.links) {
        linkKeysForType.forEach((key) => {
            updateModelJsonForKey(json, modelJson, key);
        });
    }

    return modelJson;
}

/**
 * Gets all relevant link keys based on model type. We don't care about
 * all `contacts.*`, `accounts.*`, etc keys when we have a lead model
 * so we'll filter those out.
 *
 * @param  {array} links - Array of top-level link objects from original json payload
 * @param  {string} type - API-type of model
 * @return {array} Array of links relevant to model (`leads.competitors, leads.creator`, …)
 */
function getLinkKeysForType(links, type) {
    const linkKeys = Object.keys(links || {});
    const linkKeysForType = linkKeys.filter((link) => {
        return link.split('.')[0] === type;
    });

    return linkKeysForType;
}

/**
 * Using the top-level link key, we'll break apart the key to get
 * the linkType and linkKey, and then deserialize the related compound
 * group (if it exists)
 *
 * example linkKeys: 'leads.owner', 'leads.contacts'
 * example linkTypes: 'owners', 'contacts'
 *
 * @param  {object} json - Full json payload from response
 * @param  {object} modelJson - json for just the model we're building
 * @param  {string} key - top-level json links key (i.e. leads.products)
 * @return {void}
 */
function updateModelJsonForKey(json, modelJson, key) {
    const linkType = json.links[key].type;

    // Extract actual jsonKey we'll use for our denormalized
    // Model object from link key
    const linkKey = key.split('.')[1];

    // Get links for key from baseModel `links`, if they exist
    const modelLinks = modelJson.links[linkKey];

    // If the key matches a link on the model, we'll check for a compound group
    // And do some more work
    if (modelLinks) {
        modelJson[linkKey] = deserializeCompoundGroup(json, modelJson, linkType, linkKey);
        // Otherwise, we'll just return an empty object as is the contract with our client
        // That we'll always have _something_ for each possible link key
    } else {
        modelJson[linkKey] = getEmptyResultForKey(linkKey);
    }
}

/**
 * Using a linkType and linkKey, we'll check for a related compoundGroup
 * and shove that guy onto our base `model`. If one doesn't exist, we'll return
 * the empty result based on the plurality of the json link.
 *
 * This method is also responsible for doing any other necessary transformation,
 * such as pulling `href` attributes from the json.links object, or adding opinionated
 * UI properties to our model object.
 *
 * @param  {object} json - Full json payload from response
 * @param  {object} modelJson - json from repsonse for just our model
 * @param  {string} linkType - type for json link (i.e. `creators`)
 * @param  {string} linkKey - key for json link (i.e. `creator`)
 * @return {array|object} - deserialized object or array
 */
export function deserializeCompoundGroup(
    json: Object,
    modelJson: Object,
    linkType: string,
    linkKey: string
) {
    const modelLinkIds = modelJson.links[linkKey];
    if (!json[linkType]) {
        return getEmptyResultForKey(linkKey, modelLinkIds);
    }

    // Const modelLinkIds = modelJson.links[linkKey];
    const validLinksForModel = json[linkType].filter((link) => {
        if (Array.isArray(modelLinkIds)) {
            return modelLinkIds.includes(link.id);
        } else {
            return link.id === modelLinkIds;
        }
    });

    // If no compound group, we'll just return our empty type
    if (!validLinksForModel) {
        return getEmptyResultForKey(linkKey);
    } else if (linkKey === 'productMaps') {
        const productMaps = validLinksForModel;
        productMaps.forEach((productMap) => {
            productMap.description = getProductMapDescription(productMap);
        });

        return productMaps;
    } else if (linkKey === 'stageset') {
        const stageset = validLinksForModel[0];
        const stagesetHref = json.links['leads.stageset'].href;
        stageset.href = stagesetHref.replace('{leads.id}', modelJson.id);

        return stageset;
    } else {
        return isKeyTypeSingular(linkKey) ? validLinksForModel[0] : validLinksForModel;
    }
}

/**
 * Takes a linkKey (i.e. `creator`, `products`) and determines if it's
 * a plural json key or not, naively by checking if it ends in an 's'
 * @param  {string}   key - Key name to check
 * @return {Boolean}        True if key is singular
 */
function isKeyTypeSingular(key) {
    if (key === EMAIL_ADDRESS_TO) return false;

    return key.substring(key.length - 1, key.length) !== 's';
}

/**
 * We always want to return _something_ for each key, but it depends on what
 * kind of link we're dealing with (plural or not).
 * @param  {string}        key     - (i.e. `creator`, `products`)
 * @param  {string[]}      linkIds - Array of keys of top-level link objects from original json payload
 * @return {object|array}            Empty object or array
 */
function getEmptyResultForKey(key, linkIds) {
    if (isKeyTypeSingular(key)) {
        return linkIds ? linkIds : {};
    } else {
        return linkIds && linkIds.length ? linkIds : [];
    }
}

function aggregateModelEntitiesToParticipantsKey(genericModel) {
    let participants = [];

    if (genericModel.users) {
        participants = participants.concat(genericModel.users);
    }

    if (genericModel.contacts) {
        participants = participants.concat(genericModel.contacts);
    }

    if (genericModel.accounts) {
        participants = participants.concat(genericModel.accounts);
    }

    if (genericModel.leads) {
        participants = participants.concat(genericModel.leads);
    }

    if (participants.length > 0) {
        genericModel.participants = participants;
    }

    return genericModel;
}
