import {createSelector} from 'reselect';

const getUiState = (state) => state.dashboard.ui;
const getDashesState = (state) => state.dashboard.dashes;

export const getIsLoading = (state) => state.dashboard.isLoading;
export const getDashboardSchema = (state) => state.dashboard.schema;
export const getLeadsSchema = (state) => state.dashboard.leadsSchema;
export const getContactsSchema = (state) => state.dashboard.contactsSchema;
export const getAccountsSchema = (state) => state.dashboard.accountsSchema;
export const getSavedLists = (state) => state.dashboard.savedLists.items;
export const getDashById = (state, dashId) => state.dashboard.dashes.items[dashId];

export const getSavedLeadLists = createSelector([getSavedLists], (savedLists) => {
    return savedLists.filter((list) => list.reportType === 'entity_list_leads');
});

export const getSavedCompanyLists = createSelector([getSavedLists], (savedLists) => {
    return savedLists.filter((list) => list.reportType === 'entity_list_accounts');
});

export const getSavedPeopleLists = createSelector([getSavedLists], (savedLists) => {
    return savedLists.filter((list) => list.reportType === 'entity_list_contacts');
});

export const getDashOrderById = createSelector([getUiState], (uiState) => {
    return uiState.dashOrderById;
});

export const getDashes = createSelector(
    [getDashesState, getDashOrderById],
    (dashes, dashOrderById) => {
        return dashOrderById.map((id) => dashes.items[id]).filter((dash) => dash);
    }
);

export const getLeftScrollTop = createSelector([getUiState], (uiState) => {
    return uiState.leftScrollTop;
});

export const getRightPaneActiveView = createSelector([getUiState], (uiState) => {
    return uiState.activeView;
});

export const getRightPaneWidth = createSelector([getUiState], (uiState) => {
    return uiState.rightPaneWidth;
});

export const getDashIdBeingConfigured = createSelector([getUiState], (uiState) => {
    return uiState.dashIdBeingConfigured;
});

export const getDashIdBeingConfirmedToDelete = createSelector([getUiState], (uiState) => {
    return uiState.dashIdBeingConfirmedToDelete;
});

export const getDashIdBeingDeleted = createSelector([getUiState], (uiState) => {
    return uiState.dashIdBeingDeleted;
});

export const getIsSelectingDashType = createSelector([getUiState], (uiState) => {
    return uiState.isSelectingNewDashType;
});

export const getIsSidebarSnappedOpen = createSelector([getUiState], (uiState) => {
    return uiState.rightPaneIsSnappedOpen;
});

export const getIsSidebarTwoColumns = createSelector([getUiState], (uiState) => {
    return uiState.rightPaneIsTwoColumn;
});

export const getIsAddingNewDash = createSelector(
    [getDashIdBeingConfigured],
    (dashIdBeingConfigured) => {
        return Boolean(dashIdBeingConfigured && dashIdBeingConfigured.startsWith('new-'));
    }
);
