/* @flow */

import * as React from 'react';

import type {EventFragmentSparse} from 'nutshell-graphql-types';
import {getApiTypeFromGraphQLTypename} from 'nutshell-core/utils';
import {getSingularEntityType} from 'nutshell-core/utils/get-singular-entity-type';

import {LinkedEntity} from 'shells/linked-entity';

import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {|event: EventFragmentSparse|};

export function TimelineBannerEntityMerged(props: Props) {
    const {event} = props;

    const entityType = getApiTypeFromGraphQLTypename(event.payload.__typename);
    const singularEntityType = getSingularEntityType(entityType);

    const getIconVariant = (entity) => {
        switch (entity) {
            case 'accounts':
                return 'company-created';
            case 'leads':
                return 'lead-opened';
            case 'contacts':
            default:
                return 'person-created';
        }
    };

    const mergedEntityInfo =
        (event.changes[0] && event.changes[0].newValue && JSON.parse(event.changes[0].newValue)) ||
        {};

    // the lead has an id and the name is also a number, but this lets us link to the lead page through the number
    // so basically for person or company, we have person/:id or company/:id, but for lead, we have lead/id/:id
    const urlEntityPrefix = singularEntityType === 'lead' ? 'lead/id' : singularEntityType;

    const entity = mergedEntityInfo ? (
        <>
            <span styleName='target'>
                <LinkedEntity
                    type={entityType}
                    useEntityColor={true}
                    shouldClientRoute={true}
                    allowWrap={true}
                    displayInline={true}
                    htmlUrlPath={`/${urlEntityPrefix}/${mergedEntityInfo.id}`}
                    name={mergedEntityInfo.name}
                />
            </span>
            <span> into</span>
        </>
    ) : (
        <span>a {singularEntityType} into</span>
    );

    const action = (
        <span>
            merged {entity}&nbsp;
            <span styleName='target'>
                <LinkedEntity
                    type={entityType}
                    useEntityColor={true}
                    shouldClientRoute={true}
                    allowWrap={true}
                    displayInline={true}
                    {...event.payload}
                />
            </span>
        </span>
    );

    return (
        <TimelineBanner
            id={event.id}
            iconVariant={getIconVariant(entityType)}
            timestamp={event.changeTime}
            actor={event.actor}
            action={action}
        />
    );
}
