import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 24.1 40.8'}>
            <path
                fill='#D4D4D4'
                d='M2 1.1c2.9 1.4 5.2 3.7 6.4 6.5v.2c.9 3 .8 6.4-.5 9.6C1.9 14.5-.6 7.7 2 1.1m19.5 8.3c.5 0 1 0 1.5.1-.5 3-1.9 5.6-3.9 7.4-1.7 1.2-3.7 1.8-5.8 1.8-.3 0-.7 0-1-.1l-.5-.1V18.2c1.1-5.3 4.9-8.8 9.7-8.8m-9.9 18.9h5.8s.1.1 0 .3l-2.1 10.5c-.1.4-.4.7-.7.7H7.2c-.3 0-.6-.3-.7-.7L4.4 28.5v-.3H11.6zM1.9 0c-.3 0-.5.1-.6.4-3.1 7.2-.4 14.9 6.6 18 .1.1.3.1.4.1.2 0 .5-.1.6-.4 1.4-3.3 1.8-7.1.7-10.6 0-.1 0-.1-.1-.1C8.1 4 5.3 1.5 2.3.1 2.1 0 2 0 1.9 0zm19.6 8.4c-5.2 0-9.5 3.9-10.6 9.8-1 2.6-1.6 7.1-1.9 9.1H4.5c-.8 0-1.2.7-1.1 1.5l2.1 10.5c.2.8.9 1.5 1.7 1.5h7.3c.8 0 1.5-.7 1.7-1.5l2.1-10.5c.2-.8-.3-1.5-1.1-1.5h-4.9c-.4-1.3-1.1-5-.2-7.6.4 0 .8.1 1.1.1 2.3 0 4.5-.7 6.4-2 2.3-2 3.8-5.1 4.3-8.3.1-.4-.2-.7-.5-.8-.5-.2-1.2-.3-1.9-.3z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'plant-outlined-medium';

export const PlantMediumOutlinedIcon = svgIconWrapper(SvgComponent);
