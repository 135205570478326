/* @flow */

import * as React from 'react';

import {Field, type FieldArrayProps} from 'redux-form';

import type {TagType} from 'nutshell-graphql-types';

import {Line} from 'shells/divider';
import {Stack} from 'shells/layout';

import {nonNull} from 'shells/form/validation';
import {AddAnotherButton} from 'shells/form';
import {Button} from 'shells/button';

import {SelectFieldCreateable} from '../../select/select-field-createable';

import {getAddAnotherText, getComponent, idRegex} from './createable-field-helpers';

import './forms.css';

type Props = {
    ...FieldArrayProps,
    setNewItem: (?string) => void,
    setOptions: (Object[]) => void,
    options: Object[],
    refetch: () => Promise<*>,
    shouldAnd?: boolean,
    tagType?: TagType,
    isLoading: boolean,
    type: 'tags' | 'audiences' | 'contacts' | 'accounts',
    autoFocus?: boolean,
    isRequired?: boolean,
    onInputChange?: (newInputValue: string) => void,
};

export function CreateableField(props: Props) {
    const {
        fields,
        setNewItem,
        setOptions,
        options,
        refetch,
        shouldAnd,
        isLoading,
        type,
        tagType,
        autoFocus,
        isRequired = true,
    } = props;

    // We show the add another button once a field has been added
    const shouldShowAddAnother = fields.get(fields.length - 1);

    // We show the cancel button if there is already at least one selected field but they haven't added the next one yet
    const shouldShowCancel = !fields.get(fields.length - 1) && fields.length > 1;

    return (
        <Stack spacing={8}>
            {fields.map((member, index) => (
                <div key={member}>
                    <Field
                        name={member}
                        component={SelectFieldCreateable}
                        autoFocus={autoFocus}
                        onRemove={() => {
                            fields.remove(index);
                        }}
                        props={{
                            onChange: (newValue) => {
                                setNewItem(newValue.value);
                            },
                            onInputChange: (newValue) => {
                                if (props.onInputChange) {
                                    props.onInputChange(newValue);
                                }
                            },
                        }}
                        createComponent={getComponent(type)}
                        optionFields={options}
                        selectedOptions={fields.getAll()}
                        validate={isRequired ? [nonNull, isApiId] : [isApiId]}
                        isLoading={isLoading}
                        refetch={refetch}
                        updateOptions={(newOption) => setOptions(options.concat(newOption))}
                        tagType={tagType}
                        shouldAnd={shouldAnd}
                    />
                    {index !== fields.length - 1 ? (
                        <Line text={shouldAnd ? 'and' : 'or'} />
                    ) : (
                        undefined
                    )}
                </div>
            ))}
            {shouldShowAddAnother ? (
                <AddAnotherButton
                    text={getAddAnotherText(type)}
                    onClick={() => {
                        fields.push(null);
                    }}
                />
            ) : (
                undefined
            )}
            {shouldShowCancel ? (
                <div styleName='cancel-button'>
                    <Button onClick={() => fields.remove(-1)}>Cancel</Button>
                </div>
            ) : (
                undefined
            )}
        </Stack>
    );
}

const isApiId = (value) => {
    return value && !idRegex.test(value) ? 'Creating item' : undefined;
};
