import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Newsletter from './icon-new-timeline.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Newsletter {...validProps} />;
}
SvgComponent.iconName = 'newTimeline';

export const NewTimelineIcon = svgIconWrapper(SvgComponent);
