/* @flow */

import * as React from 'react';

import type {EmailFragmentSparse as Email} from 'nutshell-graphql-types';

import {ConfirmationDialog} from 'shells/confirmation-dialog';
import {TickCircleIcon} from 'shells/icon';
import {colors} from 'shells/colors';
import {Stack} from 'shells/layout';

type Props = {|
    email: Email,
    onCancel: (e?: SyntheticEvent<*>) => void,
    onBlocklistEmail: (address: string) => any,
    onDeleteEmail: (emailId: string, allByAddress?: ?string) => any,
    selectedAddress: string,
|};

export function AddBlocklistedEmailConfirmation(props: Props) {
    const [isSaving, setIsSaving] = React.useState(false);

    const {email, selectedAddress, onBlocklistEmail, onDeleteEmail} = props;

    const onSubmitAdd = () => {
        setIsSaving(true);
        onBlocklistEmail(selectedAddress).then(() => {
            // Second call for deleting the email to prompt an optimistic removal on
            // the timeline
            onDeleteEmail(email.id);
        });
    };

    const onCancel = () => {
        // Still need to remove the email from the timeline, just don't add to blocklist
        onDeleteEmail(email.id);
        props.onCancel();
    };

    return (
        <ConfirmationDialog
            message={
                <Stack spacing={16}>
                    <div className='flex'>
                        <div className='mr-8' style={{marginTop: '1px'}}>
                            <TickCircleIcon color={colors.green} size={16} />
                        </div>
                        All emails with {selectedAddress} are being removed.
                    </div>
                    <div>
                        Would you like to add <strong>{selectedAddress}</strong> to the company
                        blocklist and prevent emails to and from this address from syncing?
                    </div>
                </Stack>
            }
            onConfirm={onSubmitAdd}
            onCancel={onCancel}
            confirmText='Add to blocklist'
            cancelText='No, don’t add'
            isSaving={isSaving}
            isConfirmDestructive={false}
        />
    );
}
