/* @flow */

import * as React from 'react';
import {Body} from '../typography';
import {Stack} from '../layout';

type Props = {
    title: string,
    subtitle?: string,
    cta?: React.Node,
};

export function EmptyStateMarketing(props: Props) {
    return (
        <div className='background-offwhite full-width full-height flex flex-dir-col align-center justify-center'>
            <div className='mb-32'>
                <Stack spacing={8} alignCenter={true}>
                    <Body isBold={true}>{props.title}</Body>
                    <Body color='light'>{props.subtitle}</Body>
                </Stack>
            </div>
            {props.cta}
        </div>
    );
}
