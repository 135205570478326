/* @flow */

import naturalCmp from 'underscore.string/naturalCmp';
import * as Schema from './schema';
import {DEFAULT_BUCKET_NAME, CUSTOM_BUCKET_NAME, FILTER_MODE_BUCKET_NAME} from './constants';

/**
 * Gets the sort order of the buckets for display. This will generally be:
 * base, base custom fields, filter modes, n1, n1 custom fields, n2, n2 custom fields, …
 * @param {Object} bucketFields An object with string keys.
 * @returns {Array<string>} An array of strings to specify the order of buckets.
 * @see bucketSchemaFields
 */
export function getSortedBucketKeys(bucketFields: {
    [bucketName: string]: $Shape<Schema.FieldProperties>[],
}): Array<string> {
    return Object.keys(bucketFields).sort((a: string, b: string) => {
        if (a === DEFAULT_BUCKET_NAME) {
            return -1;
        } else if (b === DEFAULT_BUCKET_NAME) {
            return 1;
        } else if (a === CUSTOM_BUCKET_NAME) {
            return -1;
        } else if (b === CUSTOM_BUCKET_NAME) {
            return 1;
        } else if (a === FILTER_MODE_BUCKET_NAME) {
            return -1;
        } else if (b === FILTER_MODE_BUCKET_NAME) {
            return 1;
        }

        return naturalCmp(a, b);
    });
}

/**
 * Since our schema does not differentiate custom fields, this function will
 * figure out the name to use for a given SchemaField object. This can return
 * the displayable name or the DEFAULT_BUCKET_NAME constant.
 * @param {Object} field The SchemaField
 * @returns {string} The bucket name of the SchemaField.
 */
export function getSchemaFieldBucketName(field: Schema.FieldProperties): string {
    let bucketName = DEFAULT_BUCKET_NAME;
    if (field.isCustomField) {
        bucketName = CUSTOM_BUCKET_NAME;
        if (field.subtitle && typeof field.subtitle === 'string' && field.subtitle.length) {
            bucketName = `${field.subtitle} ${CUSTOM_BUCKET_NAME.toLowerCase()}`;
        }
    } else if (field.isFilterModeField) {
        bucketName = FILTER_MODE_BUCKET_NAME;
    } else if (field.bucket && typeof field.bucket === 'string') {
        bucketName = field.bucket;
    }

    return bucketName;
}

function sortByCaseInsensitiveTitle(fields: Schema.FieldProperties[]) {
    return fields.sort((a, b) => {
        const aSortName = (a.sortTitle || a.title).toLowerCase();
        const bSortName = (b.sortTitle || b.title).toLowerCase();

        return aSortName > bSortName ? 1 : -1;
    });
}

export function bucketSchemaFields(fields: Schema.FieldProperties[]) {
    const sortedFilterableFields = sortByCaseInsensitiveTitle(fields);

    return sortedFilterableFields.reduce((memo, field) => {
        const bucketName = getSchemaFieldBucketName(field);

        if (!memo[bucketName]) {
            memo[bucketName] = [field];
        } else {
            memo[bucketName].push(field);
        }

        return memo;
    }, {});
}

export function getFilterableFields(
    schema: Schema.Properties,
    filterFunction: (field: string) => boolean = (fieldName) => Boolean(fieldName)
) {
    // $FlowFixMe upgrading Flow to v0.92.1
    return Object.keys(schema)
        .filter(filterFunction)
        .map((fieldName) => schema[fieldName])
        .filter((field) => field.title && field.isFilterable);
}
