import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} viewBox={'0 0 525 600'}>
            <path d='M312.73,108.68A18.7,18.7,0,0,1,295.15,86.6L308,19.23a16,16,0,0,0,.34-3.12C308.32,1.86,288.36,0,262.5,0s-45.82,1.86-45.82,16.11a16.79,16.79,0,0,0,.34,3.12l12.74,66.92a18.7,18.7,0,0,1-18,22.09C88.11,112.58.85,153.08.85,227v28.4A13.6,13.6,0,0,0,14.45,269H239.53c8.91,0,13.6,6.56,13.6,13.6a12.55,12.55,0,0,1-10.79,12.66L75.41,327.57c-6.57.94-10.78,6.56-10.78,13.13v57.21C64.63,507.63,150.44,600,262.5,600c109.73,0,198.35-89.09,198.35-198.34V282.09A13.21,13.21,0,0,1,474.45,269h36.1a13.6,13.6,0,0,0,13.6-13.6V227C524.15,152.89,436.87,115.3,312.73,108.68Z' />
        </svg>
    );
}
SvgComponent.iconName = 'nutshell-logo-mark';

export const NutshellLogoMarkIcon = svgIconWrapper(SvgComponent);
