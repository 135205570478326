/* @flow */

import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import {getApiTypeFromGraphQLTypename} from 'nutshell-core/utils';

import type {
    EventFragmentSparse_actor as Actor,
    NoteFragment_entity as Entity,
    SubmissionFragment_formVersion_form as Form,
    EmailInteractionFragmentSparse as EmailInteraction,
    SessionFragment_url as Url,
    EmailFragmentSparse as Email,
} from 'nutshell-graphql-types';

import {LinkedEntity} from '../../../linked-entity';
import {Link} from '../../../link';
import type {Participant, EventType, ChangeType} from '../../types';

import {TimelineHeaderParticipants} from './timeline-header-participants';
import {getActionFromChangeType, getTimelineHeaderVerb} from './helpers';

import './timeline-header.css';

// TimelineHeaderActionTextProps
export type Props = {|
    actor: Actor,
    participants?: ?(Participant[]),
    entity?: ?Entity | Form | EmailInteraction | Email | Url,
    entryType: EventType,
    changeType: ChangeType,
    showEmailAddressTooltip?: boolean,
    actorEmailAddress?: string,
    numVisits?: number,
    isPinnedThread?: boolean,
    isChatThread?: boolean,
|};

// We don't want to show the actor as a participant in the list as well
function getParticipantListForHeader(participants: ?(Participant[]), actor: Actor): Participant[] {
    if (!participants) return [];

    return participants.filter((participant) => {
        // $FlowIgnore don't need to worry about id-less actors
        return participant.id !== actor.id;
    });
}

export function TimelineHeaderActionText(props: Props) {
    const {
        actor,
        participants,
        entity,
        entryType,
        changeType,
        showEmailAddressTooltip,
        actorEmailAddress,
        numVisits,
        isPinnedThread,
        isChatThread,
    } = props;

    const containerRef = React.useRef(null);
    const actorVerbRef = React.useRef(null);

    const action = getActionFromChangeType(changeType);
    const participantsForHeader = getParticipantListForHeader(participants, actor);
    const hasParticipants = participantsForHeader.length > 0;

    React.useEffect(() => {
        ReactTooltip.rebuild();

        return () => ReactTooltip.hide();
    }, []);

    const getEntityToDisplay = (
        entityToDisplay: Entity | Form | EmailInteraction | Email | Url
    ) => {
        if (entityToDisplay.__typename === 'Url') {
            return (
                <span>
                    {entityToDisplay.host
                        ? entityToDisplay.host
                        : entityToDisplay.fullyQualifiedUrl}
                </span>
            );
        }

        if (entityToDisplay.__typename === 'Form') {
            return (
                <Link href={entityToDisplay.htmlUrlPath} variant='primary' newTab={true}>
                    {entityToDisplay.name}
                </Link>
            );
        }

        if (entityToDisplay.__typename === 'EmailInteraction') {
            if (entityToDisplay.emailDelivery && entityToDisplay.emailDelivery.edition) {
                const htmlUrlPath = entityToDisplay.emailDelivery.edition.htmlUrlPath;
                const name = entityToDisplay.emailDelivery.edition.name;

                if (entityToDisplay.type === 'CLICKED') {
                    return (
                        <>
                            {entityToDisplay.link ? (
                                <span>
                                    <Link href={entityToDisplay.link} variant='primary'>
                                        {entityToDisplay.link}
                                    </Link>
                                    &nbsp;from&nbsp;
                                </span>
                            ) : (
                                <span>a link from&nbsp;</span>
                            )}
                            <span>
                                <Link href={htmlUrlPath} variant='primary' newTab={true}>
                                    {name}
                                </Link>
                            </span>
                        </>
                    );
                } else {
                    return (
                        <Link href={htmlUrlPath} variant='primary' newTab={true}>
                            {name}
                        </Link>
                    );
                }
            }
        }

        if (entityToDisplay.__typename === 'Email') {
            const href = entityToDisplay.htmlUrlPath;
            // The title is the name of the edition but if we don't have that for some reason,
            // we default to the subject of the email
            const emailTitle = entityToDisplay.edition
                ? entityToDisplay.edition.name
                : entityToDisplay.subject;

            return (
                <Link href={href} newTab={true} variant='primary'>
                    {emailTitle}
                </Link>
            );
        }

        return (
            <LinkedEntity
                displayInline={true}
                allowWrap={true}
                shouldClientRoute={true}
                useEntityColor={true}
                type={getApiTypeFromGraphQLTypename(entityToDisplay.__typename)}
                greenLeads={true}
                {...entityToDisplay}
            />
        );
    };

    return (
        <div ref={containerRef} styleName='action-text'>
            <span ref={actorVerbRef}>
                <span
                    styleName='actor'
                    data-tip={
                        showEmailAddressTooltip && actorEmailAddress ? actorEmailAddress : undefined
                    }
                    data-place='bottom'
                >
                    {actor ? (
                        <LinkedEntity
                            allowWrap={true}
                            shouldClientRoute={true}
                            displayInline={true}
                            useEntityColor={true}
                            type={getApiTypeFromGraphQLTypename(actor.__typename)}
                            {...actor}
                        />
                    ) : (
                        'Unknown actor'
                    )}
                </span>
                <span styleName='verb'>
                    &nbsp;
                    {getTimelineHeaderVerb({
                        type: entryType,
                        action,
                        hasParticipants,
                        numVisits,
                        isPinnedThread,
                        isChatThread,
                    })}
                    &nbsp;
                </span>
            </span>
            {hasParticipants ? (
                <span styleName='participants'>
                    <TimelineHeaderParticipants
                        participants={participantsForHeader}
                        containerRef={containerRef}
                        actorVerbRef={actorVerbRef}
                        showEmailAddressTooltip={showEmailAddressTooltip}
                    />
                </span>
            ) : (
                undefined
            )}
            {entity ? <span styleName='target'>{getEntityToDisplay(entity)}</span> : undefined}
        </div>
    );
}
