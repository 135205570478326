/* @flow */

import {useQuery} from '@apollo/react-hooks';

import type {
    GetSources as GetSourcesQuery,
    GetSourcesVariables as GetSourcesQueryVariables,
    GetSources_sources_edges_node as Source,
    ChannelType,
} from 'nutshell-graphql-types';

import {makeDataSafe, safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';
import GET_SOURCES from './graphql/queries/get-sources.graphql';
import {getQueryVariables} from './helpers';

export const useGetSources = (input?: {channel?: ?ChannelType, limit?: number}) => {
    const queryVariables: GetSourcesQueryVariables = input ? getQueryVariables(input) : {};

    const {data, error, loading, fetchMore} = useQuery<GetSourcesQuery, GetSourcesQueryVariables>(
        GET_SOURCES,
        {
            variables: queryVariables,
            fetchPolicy: 'cache-and-network',
        }
    );

    const {sources} = makeDataSafe(data);

    const sourceNodes: Source[] = sources ? sources.edges.map((edge) => edge.node) : [];
    const totalCount: ?number = sources ? sources.totalCount : undefined;

    const fetchMoreRows = () => {
        // $FlowIgnore - Unsure what error flow is showing, but this works
        return fetchMore({
            variables: {
                ...queryVariables,
                first: 5000, // fetchMore should return all remaining sources
                after: sources ? sources.pageInfo.endCursor : null,
            },
            updateQuery: (
                prev: GetSourcesQuery,
                newResults: {fetchMoreResult: GetSourcesQuery}
            ) => {
                const oldSources = prev.sources;
                const oldSourceEdges = prev.sources ? prev.sources.edges : [];
                const fetchedSourceEdges = newResults.fetchMoreResult.sources
                    ? newResults.fetchMoreResult.sources.edges
                    : [];
                const pageInfo = newResults.fetchMoreResult.sources
                    ? newResults.fetchMoreResult.sources.pageInfo
                    : undefined;

                const newSourceEdges = oldSourceEdges.concat(fetchedSourceEdges);

                return {
                    sources: {
                        ...oldSources,
                        pageInfo: pageInfo,
                        edges: newSourceEdges,
                    },
                };
            },
        });
    };

    const hasNextPage = sources ? sources.pageInfo.hasNextPage : false;

    return {
        sources: sourceNodes,
        totalCount,
        error: safelyGetGraphQLErrorMessage(error),
        isLoading: loading,
        hasNextPage,
        fetchMore: fetchMoreRows,
    };
};
