/* @flow */

import {
    useGetAccountTypes,
    useGetCompetitors,
    useGetIndustries,
    useGetTerritories,
    useGetUsers,
} from '../../core/select/hooks';

import {useGetSources} from '../../setup/sources/use-get-sources';

export function getFetcherFunctionForRelationship(relationshipKey: string) {
    switch (relationshipKey) {
        case 'assignee':
        case 'owner':
        case 'primaryAccountOwner':
            return useGetUsers;
        case 'accountType':
            return useGetAccountTypes;
        case 'competitors':
            return useGetCompetitors;
        case 'industry':
            return useGetIndustries;
        case 'sources':
            return useGetSources;
        case 'territory':
            return useGetTerritories;
    }
}

export function getFetcherFunctionReturnKey(relationshipKey: string) {
    switch (relationshipKey) {
        case 'assignee':
        case 'owner':
        case 'primaryAccountOwner':
            return 'users';
        case 'accountType':
            return 'accountTypes';
        case 'competitors':
            return 'competitors';
        case 'industry':
            return 'industries';
        case 'sources':
            return 'sources';
        case 'territory':
            return 'territories';
    }
}

export function getIsMulti(relationshipKey: string) {
    switch (relationshipKey) {
        case 'competitors':
        case 'sources':
            return true;
        default:
            return false;
    }
}
