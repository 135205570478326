import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import QRCode from './icon-qr-code.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <QRCode {...validProps} />;
}
SvgComponent.iconName = 'alignRight';

export const QRCodeIcon = svgIconWrapper(SvgComponent);
