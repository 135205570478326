/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {SelectNew} from 'shells/select-new';

type Props = {
    ...FieldProps,
    selectValues: Object[],
    isInline?: boolean,
};

export function ReduxFormSelectNew(props: Props) {
    const {input, ...rest} = props;

    return <SelectNew {...input} {...rest} isTiny={true} />;
}
