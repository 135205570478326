/* @flow */

import {Quill} from 'react-quill';

const Embed = Quill.import('blots/embed');

export type Placeholder = {
    id: string,
    label: string,
};

export class PlaceholderBlotCustom extends Embed {
    static blotName = 'placeholder-custom';
    static tagName = 'span';
    static className = 'ql-placeholder-blot-custom';

    placeholderValue: ?Placeholder;

    constructor(domNode: Node, value?: Placeholder) {
        super(domNode, value);
        this.placeholderValue = value;
    }

    static create(value: Placeholder) {
        const node: HTMLElement = super.create(value);
        node.setAttribute('data-id', value.id);
        node.setAttribute('data-label', value.label);
        node.setAttribute('spellcheck', 'false');

        node.addEventListener('click', function() {
            const blot = Quill.find(node);
            // Get quill instance
            let scrollBlot = Quill.find(node);
            while (scrollBlot.parent) {
                scrollBlot = scrollBlot.parent;
            }
            const quill = Quill.find(scrollBlot.domNode.parentNode);

            quill.options.modules.placeholder.customPlaceholderClickHandler(quill, blot);
        });

        const label = value.label;
        node.innerHTML = label;

        return node;
    }

    value() {
        return this.placeholderValue ? `[${this.placeholderValue.id}]` : null;
    }

    static value(domNode: HTMLElement) {
        return domNode.dataset;
    }

    length(): number {
        return 1;
    }
}
