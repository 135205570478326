/* @flow */
import type {LeadId, OutcomeId} from '../types';
import {api} from '../api';

/**
 * Closes a lead with the given outcome and additional data. This is a special method for now
 * because it's a non-compliant endpoint that handles the side-effects of additional
 * competitors, etc. We could potentially handle this with multiple patches, but this way we
 * keep it as an atomic operation.
 *
 * @param  {string} leadId      - The ID of the lead.
 * @param  {string} outcomeId   - The ID of the outcome to close the lead with
 * @param  {object} data        - additional data for the request
 *
 * @return {Promise} 	- Deferred object that should resolve to API response
 */
export function closeLead(leadId: LeadId, outcomeId: OutcomeId, data: Object = {}) {
    return api
        .post(`leads/${leadId}/status`, {
            outcomeId,
            ...data,
        })
        .then((response) => response.json());
}
