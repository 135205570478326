/* @flow */

import type {ReportGap, FilterObject} from 'nutshell-core/types';
import {serializeFilters} from 'nutshell-core';

export type SiteAnalyticsChartParams = {
    gap: ReportGap,
};

export type SiteAnalyticsReportPostParams = {
    filter: Object,
    gap: ReportGap,
};

export function getSiteAnalyticsReportPostParams(
    reportParams: SiteAnalyticsChartParams,
    filters: FilterObject[]
): SiteAnalyticsReportPostParams {
    const {gap} = reportParams;

    return {
        filter: serializeFilters(filters),
        gap,
    };
}
