/* @flow */

import camelCase from 'lodash/camelCase';
// eslint-disable-next-line import/no-commonjs
const cssColors = require('./css-colors');

export const colors: {[colorName: ColorName]: string} = Object.keys(cssColors).reduce(
    (accum, colorName: string) => {
        const cleanedColorName = colorName.replace(/^--(color-)?/, '');
        accum[camelCase(cleanedColorName)] = cssColors[colorName];

        return accum;
    },
    {}
);

// This is annoying, but since we're camelCasing programatically, flow doesn't know the literal
// color name values.  It's still worth making them in a loop, but this gives us a bit more safety
// as well as better autocomplete in editors.
export type ColorName =
    | 'orange'
    | 'orangeDk'
    | 'orangeLt'
    | 'orangeXlt'
    | 'orangeAttention'
    | 'peach'
    | 'peachLt'
    | 'grey'
    | 'greyDk'
    | 'greyLt'
    | 'greyBlue'
    | 'black'
    | 'white'
    | 'offWhite'
    | 'offWhiteDk'
    | 'offWhiteMd'
    | 'offWhiteCream'
    | 'offWhiteSidebar'
    | 'formBorder'
    | 'label'
    | 'plum'
    | 'plumDk'
    | 'plumLt'
    | 'plumXlt'
    | 'onboardingHelpLt'
    | 'onboardingHelp'
    | 'onboardingHelpDk'
    | 'navy'
    | 'navyDk'
    | 'navyLt'
    | 'blue'
    | 'blueDk'
    | 'blueLt'
    | 'blueXlt'
    | 'blueBg'
    | 'nutshellPro'
    | 'aiBlue'
    | 'aiHover'
    | 'support'
    | 'supportHover'
    | 'focusBlue'
    | 'slateBlue'
    | 'slateBlueLt'
    | 'green'
    | 'greenDk'
    | 'greenLt'
    | 'greenXlt'
    | 'rose'
    | 'roseDk'
    | 'roseLt'
    | 'red'
    | 'redDk'
    | 'redLt'
    | 'sidebarRed'
    | 'brown'
    | 'brownLt'
    | 'yellow'
    | 'yellowLt'
    | 'error'
    | 'warning'
    | 'dotcomTeal'
    | 'dotcomPurple'
    | 'modalOverlay'
    | 'listHovered'
    | 'listSelected'
    | 'flashBannerErrorText'
    | 'flashBannerErrorBackground'
    | 'textHot'
    | 'textAccounts'
    | 'textContacts'
    | 'textUsers'
    | 'placeholder'
    | 'productBgHovered'
    | 'backgroundHot'
    | 'tinyIntroLogin'
    | 'tinyIntroSignup'
    | 'tinyIntroTeal'
    | 'tinyIntroOffWhite'
    | 'activity'
    | 'task'
    | 'person'
    | 'company'
    | 'lead'
    | 'reportGreen'
    | 'reportBlue'
    | 'reportRed'
    | 'reportOrange'
    | 'reportOrangeBg'
    | 'reportNavy'
    | 'sidebarPrimary'
    | 'sidebarHovered'
    | 'buttonSecondaryDk'
    | 'upgrade'
    | 'skeletonGrey'
    | 'tagGreyLt'
    | 'tagGrey'
    | 'tagRed'
    | 'tagRedLt'
    | 'tagOrange'
    | 'tagOrangeLt'
    | 'tagYellow'
    | 'tagYellowLt'
    | 'tagGreen'
    | 'tagGreenLt'
    | 'tagBlue'
    | 'tagBlueLt'
    | 'tagPurple'
    | 'tagPurpleLt'
    | 'overdue'
    | 'success'
    | 'successBg';
