/* @flow */

import * as React from 'react';

import {colors} from 'shells/colors';
import {SparkleIcon, SparkleOpenIcon} from 'shells/icon';
import {DynamicButtonContent} from 'shells/dynamic-button-content';
import {Stack} from 'shells/layout';

type Props = {|
    buttonText: string | React.Node,
    buttonRef: any,
|};

export function AiButtonContent(props: Props) {
    const {buttonText, buttonRef} = props;

    const ButtonContent = (contentProps: {icon: React.Node}) => (
        <Stack spacing={8} alignCenter={true} isHorizontal={true}>
            <div className='flex align-center'>{contentProps.icon}</div>
            <div>{buttonText}</div>
        </Stack>
    );

    return (
        <DynamicButtonContent
            buttonRef={buttonRef}
            defaultContent={
                <ButtonContent
                    icon={<SparkleOpenIcon size={15} wrapWithDiv={false} fill={colors.white} />}
                />
            }
            hoverContent={
                <ButtonContent
                    icon={<SparkleIcon size={15} wrapWithDiv={false} fill={colors.white} />}
                />
            }
        />
    );
}
