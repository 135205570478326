/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import './checkbox.css';

export type Props = {
    // TODO: Figure out how to make this work.  Might need a newer flow than 0.53
    // ...HTMLInputElement,
    label: React.Node,
    checked: boolean,
    /** Should a larger box be shown (true), or the browser-default style (false)? */
    isLarge?: boolean,
    /** Should the checkbox be top aligned (aligned with the first line of text) */
    isTopAligned?: boolean,
    /** This should only be used to do things like stopPropagation */
    onClick?: (SyntheticInputEvent<*>) => void,
    onChange?: (boolean, SyntheticInputEvent<*>) => any,
    /** Sometimes we need to get the underlying node ref. */
    checkboxRef?: (?HTMLElement) => void,
    /** When there's an error (for example in validation), set this to true to get a red border */
    hasError?: boolean,
    helpComponent?: React.Node,
    customCheckboxStyle?: Object,
};

/**
 * The `isLarge` variant of checkboxes should be used for new UI development.
 *
 * Note: all valid props of an `input` element are also valid for this component.
 */
export class Checkbox extends React.Component<Props> {
    static displayName = 'Checkbox';
    uniqueId: string;

    static defaultProps = {
        checked: false,
        label: '',
        checkboxRef: () => {},
    };

    constructor() {
        super();
        this.uniqueId = uniqueId('checkbox_');
    }

    render() {
        const {
            label,
            // eslint-disable-next-line no-unused-vars
            onChange,
            checkboxRef,
            isLarge,
            hasError,
            helpComponent,
            customCheckboxStyle,
            isTopAligned,
            ...restProps
        } = this.props;

        const containerStyle = classnames(isLarge ? 'is-large' : 'container', {
            'has-error': hasError,
            'is-top-aligned': isTopAligned,
        });

        return (
            <div styleName={containerStyle}>
                <input
                    {...restProps}
                    type='checkbox'
                    id={this.uniqueId}
                    styleName='checkbox'
                    onChange={this.handleChange}
                    style={customCheckboxStyle}
                />
                <label htmlFor={this.uniqueId} onClick={this.props.onClick} ref={checkboxRef}>
                    {label}
                </label>
                {helpComponent || undefined}
            </div>
        );
    }

    handleChange = (e: SyntheticInputEvent<*>) => {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(e.target.checked, e);
        }
    };
}
