/* @flow */

import * as React from 'react';
import {Link} from 'react-router-dom';
import {Routing} from 'nutshell-core/routing';
import './linked-entity.css';

const typeMap = {
    accounts: 'company',
    contacts: 'person',
    leads: 'lead',
};

const STYLED_PILL_TYPES = {
    leads: 'container-leads',
    greenLeads: 'container-leads--green',
    users: 'container-users',
    teams: 'container-teams',
    contacts: 'container-contacts',
    accounts: 'container-accounts',
};

type Props = {
    name?: string | React.Element<*>,
    type: string,
    isEnabled?: boolean,
    htmlUrl?: ?string,
    htmlUrlPath?: string,
    isLinkable: boolean,
    shouldClientRoute?: boolean,
    stopPropagation?: boolean,
    useEntityColor?: boolean,
    allowWrap?: boolean,
    displayInline?: boolean,
    greenLeads?: boolean,
};

/**
 * A textual link to an entity, styled with the appropriate color for the entity type.
 */
export class LinkedEntity extends React.PureComponent<Props> {
    static defaultProps = {
        isLinkable: true,
        useEntityColor: true,
        isEnabled: true,
    };

    render() {
        const hasName =
            (this.props.name && typeof this.props.name === 'object') ||
            (this.props.name && typeof this.props.name === 'string' && this.props.name.length);

        const entityName = hasName ? (
            this.props.name
        ) : (
            <span styleName='nameless'>Unnamed {typeMap[this.props.type]}</span>
        );

        let containerStyleType =
            this.props.useEntityColor && STYLED_PILL_TYPES[this.props.type]
                ? STYLED_PILL_TYPES[this.props.type]
                : 'container-default';

        if (this.props.type === 'leads') {
            containerStyleType = this.props.greenLeads
                ? STYLED_PILL_TYPES.greenLeads
                : STYLED_PILL_TYPES.leads;
        }

        const link =
            (this.props.htmlUrl && Routing.hasWorkingUrl() && this.isClientRoutable()) ||
            this.props.htmlUrlPath ? (
                <Link
                    to={
                        this.props.htmlUrl
                            ? new URL(this.props.htmlUrl).pathname
                            : // $FlowIgnore - we are already checking it right above
                              this.props.htmlUrlPath
                    }
                    onClick={this.handleClick}
                >
                    {entityName}
                </Link>
            ) : (
                <a onClick={this.handleClick} href={this.props.htmlUrl}>
                    {entityName}
                </a>
            );

        const Tag = this.props.displayInline ? 'span' : 'div';

        let className = '';
        if (!this.props.allowWrap) {
            className += 'truncate';
        }
        if (!this.props.isEnabled) {
            className += ' strikethrough';
        }

        return (
            <Tag styleName={containerStyleType} className={className}>
                {this.props.isLinkable && (this.props.htmlUrl || this.props.htmlUrlPath)
                    ? link
                    : entityName}
            </Tag>
        );
    }

    isClientRoutable = () => {
        return (
            (this.props.type === 'leads' ||
                this.props.type === 'contacts' ||
                this.props.type === 'accounts') &&
            this.props.shouldClientRoute
        );
    };

    handleClick = (e: Event) => {
        if (this.props.stopPropagation) e.stopPropagation();
    };
}
