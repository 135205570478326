/* @flow */

import type {
    Account,
    AccountId,
    Address,
    AssignableId,
    ContactId,
    CustomFieldAttribute,
    Email,
    FileId,
    FollowupId,
    JsonApiLink,
    Phone,
    TagId,
    TaskId,
    TerritoryId,
    Url,
    UrlString,
    UserId,
} from '../types';
import type {EavValue} from '../eav/types';

import type {State as ContactsState} from './contacts-reducer';

export const REDUCER_KEY = 'contacts';
export type ReducerKey = 'contacts';

export type ContactsModuleState = {
    [ReducerKey]: ContactsState,
};

type Job = {[id: AccountId]: {title: string}};

type ContactLinks = {
    accounts: AccountId[],
    creator: UserId,
    owner: ?AssignableId,
    territory: ?TerritoryId,
    tags: TagId[],
    files: FileId[],
    followup: ?FollowupId,
    recurringTask: ?TaskId,
};

type BaseContact = {
    id: ContactId,
    type: 'contacts',
    name: string,
    description?: ?string,
    jobs: Job[],
    jobTitle: string,
    avatarUrl: UrlString,
    initials: string,
    htmlUrl: UrlString,
    links: ContactLinks,
};

// TODO: This should probably be lifted to a higher context if we begin using
// on accounts or leads
export type CustomFieldValue =
    | string
    | number
    | {currencyShortname: string, amount: ?number, formatted?: string};
export type EditableContact = BaseContact & {
    emails: EavValue[],
    addresses: EavValue[],
    phones: EavValue[],
    urls: EavValue[],
    customFields?: {
        [key: string]: ?CustomFieldValue,
    },
};

// $FlowFixMe upgrading Flow to v0.110.1
export type Contact = $Subtype<
    BaseContact & {
        emails: Email[],
        addresses: Address[],
        phones: Phone[],
        urls: Url[],
        customFields?: CustomFieldAttribute[],
    }
>;

// Responses from /rest/contacts
export type ContactsApiResponse = {
    meta: {
        count?: number,
        total?: number,
    },
    links: {[key: string]: JsonApiLink} | [],
    contacts: Contact[],
    accounts?: Account[],
};

export type ContactCreationFormValues = {
    id?: string,
    name?: string,
    description?: ?string,
    links?: {
        accounts: AccountId[],
    },
    emails?: EavValue[],
    phones?: EavValue[],
    addresses?: EavValue[],
    urls?: EavValue[],
    customFields?: {
        [key: string]: ?CustomFieldValue,
    },
};
