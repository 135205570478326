/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {ReduxFormSelectPickerGeneric} from 'shells/redux-form-fields';

import {useGetStagesets} from '../../select/hooks/use-get-stagesets';
import {AUTO_DISTRIBUTE_OPTION} from './consts';

type Props = {
    ...FieldProps,
    includeAutoDistribute?: boolean,
};

// Redux-based pipeline select that renders as a dropdown
// (does not turn into a "card" when it has a value)
export function PipelineSelectSingle(props: Props) {
    const {includeAutoDistribute = false} = props;
    const {stagesets} = useGetStagesets();

    const stagesetOptions = stagesets.map((stageset) => {
        return {
            isDisabled: !stageset.canAccess,
            label: stageset.name,
            value: stageset.id,
        };
    });

    const options = includeAutoDistribute
        ? [AUTO_DISTRIBUTE_OPTION, ...stagesetOptions]
        : stagesetOptions;

    React.useEffect(() => {
        if (!props.input.value) {
            props.input.onChange(AUTO_DISTRIBUTE_OPTION.value);
        }
    }, [props.input]);

    let selectedValue = options.find((field) => field.value === props.input.value);
    if (!selectedValue && includeAutoDistribute) {
        selectedValue = AUTO_DISTRIBUTE_OPTION;
    }

    return (
        // $FlowIgnore this is because of the way we're passing the props through
        <ReduxFormSelectPickerGeneric
            {...props}
            onRemove={() => props.input.onChange(null)}
            options={options}
            placeholder='Select a pipeline'
            value={selectedValue}
        />
    );
}
