/* @flow */

import * as React from 'react';
import {LoadingIcon} from 'shells/icon';
import './loading-screen.css';

export class EntityFormLoadingScreen extends React.Component<{}> {
    render() {
        return (
            <div styleName='container'>
                <div styleName={'bars'}>
                    <LoadingIcon size={35} />
                </div>
                <div styleName={'text'}>Loading</div>
            </div>
        );
    }
}
